export const emissionConsts = {
	common: {
		emissionStatus: {
			active: "active",
			cancelled: "cancelled",
			invitationsSent: "invitationsSent",
			partiallyClosed: "partiallyClosed",
			completed: "completed",
			registered: "registered",
			preparationsApproved: "preparationsApproved",
		},
		emissionTypes: {
			preferential: "preferential",
			directed: "directed",
		},
		shareTypes: {
			ordinaryShare: "ordinary%20share",
			preferenceShare: "preference%20share",
			nonDividendShare: "non-dividend%20share",
		},
		documentTypes: {
			proposalFromBoard: "proposal-from-board",
			boardDecision: "board-decision",
			noticeGeneralMeeting: "notice-general-meeting",
			firstAppendix: "first-appendix",
			secondAppendix: "second-appendix",
			thirdAppendix: "third-appendix",
			fourthAppendix: "fourth-appendix",
			minutesOfTheMeeting: "minutes-of-the-meeting",
			authorization: "authorization",
			form822: "form-822",
			bankStatement: "bank-statement",
			boardMinutes: "board-minutes",
			caseReceived: "case-received",
			paymentToRegistrationOffice: "payment-to-registration-office",
			caseRegistered: "case-registered",
			additions: "additions",
		},
		decisionTypes: {
			annual: "annual",
			extraOrdinary: "extraordinary",
			authorizing: "authorizing",
			retrospective: "retrospective",
		},
		shareDisclaimers: {
			preEmption: "pre-emption",
			redemptionDisclaimers: "redemption-disclaimers",
			redemptionReservation: "redemption-reservation",
			consentDisclaimers: "consent-disclaimers",
			conversionDisclaimer: "conversion-disclaimer",
		},
		newShareCountTypes: {
			specific: "specific",
			highest: "highest",
			range: "range",
		},
		exceedingAmountTypes: {
			freeSharePremiumReserve: "freeSharePremiumReserve",
			tiedSharePremiumReserve: "tiedSharePremiumReserve",
			bothSharePremiumReserve: "bothSharePremiumReserve",
		},
		allocationTypes: {
			payment: "payment",
			decision: "decision",
		},
		paymentTypes: {
			cash: "cash",
			nonMonetary: "nonMonetary",
			setOff: "setOff",
			sharePremium: "sharePremium",
		},
		overSubscriptionType: {
			percentualOwnership: "percentualOwnership",
			subscriptionVolume: "subscriptionVolume",
			equalOverAllocation: "equalOverAllocation",
			proRata: "proRata",
			theBoard: "theBoard",
		},
	},
	marketing: {
		permissions: {
			investors: "investors",
			shareHolders: "share-holders",
			dealFlow: "deal-flow",
			invited: "invited",
		},
		diagramWhenToShare: {
			immediately: "immediately",
			afterNumberOfDays: "afterNumberOfDays",
			afterAchievedAmount: "afterAchievedAmount",
		},
	},
	invitation: {
		interestedOption: {
			yes: "yes",
			no: "no",
			doNotKnow: "do-not-know",
		},
		inviteeStatus: {
			currentShareholder: "current-shareholder",
			potentialShareholder: "potential-shareholder",
			dealFlow: "deal-flow",
		},
		investorType: {
			private: "private",
			company: "company",
			insurance: "insurance",
			dealflow: "dealflow",
		},
	},
	submission: {
		creator: {
			invitee: "invitee",
			admin: "admin",
			unknown: "unknown",
		},
		emptyPrivate: {
			city: "",
			country: "",
			email: "",
			fullName: "",
			phone: "",
			ssn: "",
			street: "",
			zipCode: "",
		},
		emptyCompany: {
			city: "",
			contactPerson: {
				email: "",
				fullName: "",
				phone: "",
			},
			country: "",
			name: "",
			organizationNumber: "",
			street: "",
			zipCode: "",
		},
		emptyCapitalInsurance: {
			beneficiary: {
				city: "",
				country: "",
				email: "",
				fullName: "",
				phone: "",
				ssn: "",
				street: "",
				zipCode: "",
			},
			depositoryInstitution: "",
			insuranceCompany: "",
			insuranceNumber: "",
		},
	},
	closure: {
		type: {
			partial: "partialClosure",
			definite: "definiteClosure",
		},
		emissionClosureAttachmentDocumentType: {
			partialClosureReport: "emissionPartialClosureReport",
			definiteClosureReport: "emissionDefiniteClosureReport",
		},
		closureStatus: {
			closurePending: "closurePending",
			closureOngoing: "closureOngoing",
		},
	},
	templates: {
		settlementNote: "settlementnote",
		shareRegisterUpdated: "shareregisterupdated",
		receipt: "receipt",
		cancellationInformation: "cancellationInformation",
	},
	public: {
		emissionError: {
			closed: "emission-closed",
			cancelled: "emission-cancelled",
			notFound: "emission-not-found",
			unknown: "emission-unknown-error",
		},
	},
	tabs: {
		preparations: "Preparations",
		allocation: "Allocation",
		invitation: "Invitation",
		marketing: "Marketing",
		payment: "Payment",
		registration: "Registration",
		subscriptionForm: "SubscriptionForm",
		subscriptionList: "SubscriptionList",
	},
};
