import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import { object } from "prop-types";

import Text from "../../../../../dumb-components/shared/text/text";
import Tooltip from "../../../../../dumb-components/shared/tooltip/tooltip";
import DropdownIconItem from "../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import RollCallModalContainer from "../../agm/rollcall-modal.container";

import { openModal } from "../../../../../actions/modals.actions";

import { MEETINGS_ROLLCALL_MODAL } from "../../../../../constants/modals";
import {
	MEETING_TYPE_SMART,
	MEETING_STATUS_PROTOCOL_NEEDS_SIGNING,
} from "/shared/constants";

const TOOLTIP_STATES = {
	noAttendees: {
		tid: "meetings.standard_meeting.toolbar.agm.rollcall.tooltip.no_attendees",
		delayShow: "instant",
	},
	signingInProgress: {
		tid: "meetings.protocol.attendee.toolbar.attendance_register.tooltip.signing_in_progress",
		delayShow: "instant",
	},
	protocolPublished: {
		tid: "meetings.protocol.attendee.toolbar.attendance_register.tooltip.protocol_already_published",
		delayShow: "instant",
	},
};

class RollCallButtonContainer extends Component {
	static propTypes = {
		dropdownRef: object,
	};

	onOpenModal = () => {
		const { dropdownRef, openModal } = this.props;

		dropdownRef && dropdownRef.onToggleMenu();

		openModal(MEETINGS_ROLLCALL_MODAL);
	};

	getTooltipActiveState = () => {
		const { hasAttendees, meetingStatus, protocolPublished } = this.props;

		if (!hasAttendees) {
			return "noAttendees";
		}
		if (meetingStatus === MEETING_STATUS_PROTOCOL_NEEDS_SIGNING) {
			return "signingInProgress";
		}
		if (protocolPublished) {
			return "protocolPublished";
		}
	};

	renderRollcallModalContainer = () => {
		const { isSmartMeeting } = this.props;
		const props = {};

		if (isSmartMeeting) {
			props.hideInfo = true;
			props.hideMoreActions = true;
		}

		return <RollCallModalContainer {...props} />;
	};

	render = () => {
		const { rollcallDone } = this.props;

		const activeState = this.getTooltipActiveState();
		const icon = rollcallDone ? "faUserCheck" : "faUserMinus";
		const color = rollcallDone ? "green" : "persianRed";

		return (
			<>
				<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
					<DropdownIconItem
						icon={icon}
						iconColor={color}
						onClick={this.onOpenModal}
						disabled={Boolean(activeState)}
					>
						<Text
							tid="meetings.general.toolbar.dropdown.rollcall"
							color={color}
						/>
					</DropdownIconItem>
				</Tooltip>

				{this.renderRollcallModalContainer()}
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		hasAttendees:
			store.meetings.getIn(["meeting", "attendees"], Map()).size > 0,
		isSmartMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) === MEETING_TYPE_SMART,
		rollcallDone: store.meetings.getIn(
			["meeting", "metadata", "rollcallDone"],
			false,
		),
		meetingStatus: store.meetings.getIn(["meeting", "status"]),
		protocolPublished: store.meetings.getIn([
			"meeting",
			"computedValues",
			"protocolPublished",
		]),
	};
};

const mapActionsToProps = {
	openModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(RollCallButtonContainer);
