// Node modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, object } from "prop-types";
import { withRouter } from "../../../interfaces/router";
import { List, Map } from "immutable";
import { Margin } from "styled-components-spacing";

// Helpers
import i18nhelper from "../../../components/helpers/i18n.helper";

// Actions
import {
	listGroups,
	updateGroupLocal,
	updateSelectedGroupId,
	fetchDefaultPermissions,
} from "../../../actions/groups.actions";
import {
	clearMeetingsList,
	clearMeeting,
} from "../../../actions/meetings.actions";

// Components
import Breadcrumbs from "../../../dumb-components/shared/breadcrumbs/breadcrumbs";
import BreadcrumbsItem from "../../../dumb-components/shared/breadcrumbs/breadcrumbs-item";
import Text from "../../../dumb-components/shared/text/text";
import history, { getQuery } from "../../../interfaces/history";

import {
	LIVE_GROUP_CREATE,
	LIVE_GROUP_UPDATE,
	LIVE_GROUP_DELETE,
} from "../../../constants/live-update";
import withResolvedProps from "../../../hocs/withResolvedProps";
import useSubscriptionHelper from "../../../hooks/useSubscriptionHelper";

class BreadcrumbsContainer extends Component {
	static propTypes = {
		groupId: string,
		basePath: string,
		onCreateGroup: func,
		onManageGroup: func,
		location: object.isRequired,
	};

	state = {
		groupStack: List(),
		dropdownOpen: Map(),
	};

	componentDidMount = () => {
		const { listGroups } = this.props;
		listGroups();
		this.storeSelectedGroup();
		this.stackGroups();
		document.addEventListener("mousedown", this.handleMouseDown, false);
	};

	componentWillUnmount = () => {
		document.removeEventListener("mousedown", this.handleMouseDown, false);
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.location !== this.props.location) {
			this.storeSelectedGroup();
		}

		if (prevProps.groupId !== this.props.groupId) {
			this.stackGroups();
		}

		if (prevProps.groups !== this.props.groups) {
			this.stackGroups();
		}

		this.checkLiveUpdateEvents();
	};

	checkLiveUpdateEvents = () => {
		const { audit, groupId, groups, listGroups, basePath, history } =
			this.props;
		const groupCreate = audit.get(LIVE_GROUP_CREATE, Map());
		const groupUpdate = audit.get(LIVE_GROUP_UPDATE, Map());
		const groupDelete = audit.get(LIVE_GROUP_DELETE, Map());

		// Group was created, edited or deleted so update list
		if (
			groupCreate.get("refresh") === true ||
			groupUpdate.get("refresh") === true ||
			groupDelete.get("refresh") === true
		) {
			listGroups();
		}

		if (
			groupDelete.get("refresh") === true &&
			groupDelete.get("objId") === groupId
		) {
			const currentGroupParentId = groups.has(groupId)
				? groups.getIn([groupId, "parent"])
				: null;

			if (currentGroupParentId) {
				history.push({
					pathname: basePath,
					search: `?group=${groupId}`,
				});
			} else {
				history.push(basePath);
			}
		}
	};

	storeSelectedGroup = () => {
		const { updateSelectedGroupId } = this.props;
		const query = getQuery();
		const { group } = query;

		updateSelectedGroupId(group || null);
	};

	stackGroups = () => {
		const { groupId, groups } = this.props;
		let stack = List();
		let currentGroupId = groupId;

		while (groups.get(currentGroupId)) {
			const parentId = groups.getIn([currentGroupId, "parent"]);
			stack = stack.unshift(currentGroupId);
			currentGroupId = parentId;
		}

		this.setState({ groupStack: stack });
	};

	handleMouseDown = (e) => {
		if (!this.node.contains(e.target)) {
			this.closeAllDropdowns();
		}
	};

	toggleDropdown = (groupId) => {
		this.setState((prevState) => {
			let { dropdownOpen } = prevState;
			dropdownOpen = dropdownOpen.map((obj, objId) => {
				if (groupId !== objId) {
					obj = false;
				}

				return obj;
			});
			dropdownOpen = dropdownOpen.set(groupId, !dropdownOpen.get(groupId));

			return { dropdownOpen };
		});
	};

	onMenuItemClick = (parentGroupId, group) => {
		const { clearMeetingsList, clearMeeting, basePath, history } = this.props;
		const groupId = group.get("id");
		this.toggleDropdown(parentGroupId);
		clearMeetingsList();
		clearMeeting();

		history.push({
			pathname: `${basePath}/`,
			search: `?group=${groupId}`,
		});
	};

	closeAllDropdowns = () => {
		this.setState({ dropdownOpen: Map() });
	};

	onCreateGroup = (groupId) => {
		const { onCreateGroup, fetchDefaultPermissions } = this.props;

		fetchDefaultPermissions(groupId);

		this.toggleDropdown(groupId);
		onCreateGroup && onCreateGroup(groupId === "root" ? null : groupId);
	};

	onManageGroup = (managedGroupId) => {
		const { onManageGroup, history, basePath, groupId, meeting } = this.props;
		const selectedmeetingid = meeting ? meeting.get("id") : "";

		this.toggleDropdown(managedGroupId);

		// If managing group that is not currently selected, redirect to fetch its details.
		if (managedGroupId !== groupId) {
			history.push({
				pathname: `${basePath}/${selectedmeetingid}`,
				search: `?group=${managedGroupId}`,
			});
		}

		onManageGroup && onManageGroup(managedGroupId);
	};

	onClickSelectedGroup = (groupId) => {
		const { basePath, history, clearMeeting } = this.props;

		clearMeeting();

		if (groupId === "root") {
			history.push(basePath);
			return;
		}

		history.push({
			pathname: basePath,
			search: `?group=${groupId}`,
		});
	};

	renderBreadcrumbs = (groupId, index) => {
		const {
			mapParentToChildren,
			basePath,
			i18n,
			company,
			params,
			groups,
			simpleMeetingsEnabled,
			smartMeetingsEnabled,
			meetingsTrialActive,
		} = this.props;

		const { dropdownOpen, groupStack } = this.state;
		let children = List();
		const childrenIds = mapParentToChildren.get(groupId, List());
		let currentGroup;

		const canManageGroup = groups && groups.getIn([groupId, "ALLOW_UPDATE"]);
		const canCreateGroup = groups && groups.getIn([groupId, "ALLOW_CREATE"]);
		const subscriptionActive =
			meetingsTrialActive || simpleMeetingsEnabled || smartMeetingsEnabled;
		const createManageTooltipTid = !subscriptionActive
			? "tooltip.subscription.upgrade.starter_plus"
			: undefined;

		if (groupId === "root") {
			currentGroup = Map({
				name: <Text tid="groups.root" />,
				id: groupId,
			});
		} else {
			currentGroup = groups.get(groupId);
		}

		childrenIds.forEach((childId) => {
			let child = groups.get(childId);
			if (child) {
				child = child.set("selected", groupStack.get(index + 1) === childId);
				children = children.push(child);
			}
		});

		children = children.map((obj) => {
			obj = obj.set(
				"name",
				i18nhelper.getTranslatedValue(
					obj.get("name"),
					i18n.language,
					company.region,
				),
			);

			if (obj.get("description")) {
				obj = obj.set(
					"description",
					i18nhelper.getTranslatedValue(
						obj.get("description"),
						i18n.language,
						company.region,
					),
				);
			}

			return obj;
		});
		children = children.sort((a, b) =>
			a.get("name").localeCompare(b.get("name")),
		);

		return (
			<BreadcrumbsItem
				key={currentGroup && currentGroup.get("id")}
				basePath={basePath}
				params={params}
				isOpen={dropdownOpen.get(groupId)}
				itemName={
					groupId !== "root"
						? i18nhelper.getTranslatedValue(
								currentGroup && currentGroup.get("name"),
								i18n.language,
								company.region,
						  )
						: currentGroup && currentGroup.get("name")
				}
				isRoot={groupId === "root"}
				items={children}
				tooltipTidRight="tooltip.meetings.breadcrumbs.dropdown"
				onBtnClick={this.onClickSelectedGroup.bind(
					this,
					currentGroup && currentGroup.get("id"),
					params.id,
				)}
				onToggleMenu={this.toggleDropdown.bind(
					this,
					currentGroup && currentGroup.get("id"),
				)}
				onMenuItemClick={this.onMenuItemClick.bind(
					this,
					currentGroup && currentGroup.get("id"),
				)}
				onCreateItem={this.onCreateGroup.bind(
					this,
					currentGroup && currentGroup.get("id"),
				)}
				onManageItem={this.onManageGroup.bind(
					this,
					currentGroup && currentGroup.get("id"),
				)}
				menuItemLeftComponent={(group) => {
					return <Text singleLine={true}>{group.get("name")}</Text>;
				}}
				menuItemSelected={(group) => {
					return group.get("selected");
				}}
				createItemTid="group.create_group"
				manageItemTid="group.manage_group"
				canManageItem={subscriptionActive && canManageGroup}
				canCreateItem={subscriptionActive && canCreateGroup}
				createTooltipTid={createManageTooltipTid}
				manageTooltipTid={createManageTooltipTid}
				onMouseEnterDrop={this.toggleDropdown.bind(
					null,
					currentGroup && currentGroup.get("id"),
				)}
				onMouseLeaveDrop={this.closeAllDropdowns}
				scrollbarAlwaysVisible={true}
			/>
		);
	};

	render = () => {
		const { groupStack } = this.state;
		return (
			<div ref={(node) => (this.node = node)}>
				<Margin all={3}>
					<Breadcrumbs>
						{this.renderBreadcrumbs("root", -1)}
						{groupStack && groupStack.map(this.renderBreadcrumbs)}
					</Breadcrumbs>
				</Margin>
			</div>
		);
	};
}

function mapStoreToProps(store, ownProps = {}) {
	return {
		params: ownProps.params || ownProps.match?.params || {},
		history: history,
		meeting: store.meetings.get("meeting"),
		groups: store.groups.get("groups"),
		mapParentToChildren: store.groups.get("mapParentToChildren"),
		currentGroup: store.groups.get("currentGroup"),
		groupId: store.groups.get("selectedGroupId"),
		company: store.company.company,
		i18n: store.i18n,
		audit: store.audit.get("groups"),
	};
}

const mapActionsToProps = {
	listGroups,
	updateGroupLocal,
	updateSelectedGroupId,
	fetchDefaultPermissions,
	clearMeetingsList,
	clearMeeting,
};

const BreadcrumbsContainerConnected = connect(
	mapStoreToProps,
	mapActionsToProps,
)(BreadcrumbsContainer);

const BreadcrumbsContainerWithRouter = withRouter(
	BreadcrumbsContainerConnected,
);

export default withResolvedProps(() => {
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;

	return {
		simpleMeetingsEnabled: subscriptionHelper?.simpleMeetingsEnabled,
		smartMeetingsEnabled: subscriptionHelper?.smartMeetingsEnabled,
		meetingsTrialActive: subscriptionHelper?.meetingsTrialActive,
	};
})(BreadcrumbsContainerWithRouter);
