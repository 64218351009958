import React, { Component } from "react";
import { connect } from "react-redux";
import { string, func } from "prop-types";

import {
	addInfoNotification,
	addErrorNotification,
} from "../../../actions/notify.actions";

import Modal from "../../../dumb-components/shared/modal/modal";
import FooterRightControls from "../../../dumb-components/shared/modal/footer-right-controls";
import { TransparentButton } from "../../../dumb-components/shared/button-v2";
import HandleEmailTemplateContainer from "../handle-email-template.container";

import { closeModal } from "../../../actions/modals.actions";

const INITIAL_STATE = {
	isLoading: false,
};

class NotifySingleUserModalContainer extends Component {
	state = INITIAL_STATE;

	static propTypes = {
		btnSubmitTid: string,
		btnCloseTid: string,
		modalTitle: string,
		onSubmit: func.isRequired,
		templateName: string.isRequired,
		modalName: string.isRequired,
		successNotificatonTid: string,
		errorNotificationTid: string.isRequired,
	};

	static defaultProps = {
		btnSubmitTid: "generic.send",
		btnCloseTid: "generic.form.close",
		modalTitle: "notify_single_user.generic.modal.title",
	};

	componentDidUpdate = (prevProps) => {
		const { isOpen } = this.props;

		if (!isOpen && isOpen !== prevProps.isOpen) {
			this.setState(INITIAL_STATE);
		}
	};

	submit = () => {
		const { onSubmit, workingTemplate } = this.props;

		onSubmit && onSubmit(workingTemplate, this.onFinish, this.onError);
	};

	onFinish = () => {
		const { successNotificatonTid, addInfoNotification } = this.props;
		if (successNotificatonTid) {
			addInfoNotification({ tid: successNotificatonTid });
		}
		this.closeModal();
	};

	onError = () => {
		const { addErrorNotification, errorNotificationTid } = this.props;

		addErrorNotification({ tid: errorNotificationTid });
		this.stopLoading();
	};

	closeModal = () => {
		this.props.closeModal();
	};

	stopLoading = () => {
		this.setState({ isLoading: false });
	};

	renderFooterComponent = () => {
		const { btnSubmitTid, btnCloseTid } = this.props;
		const { isLoading } = this.state;

		return (
			<FooterRightControls>
				<TransparentButton
					tid={btnSubmitTid}
					onClick={this.submit}
					isLoading={isLoading}
				/>
				<TransparentButton
					tid={btnCloseTid}
					onClick={this.closeModal}
					textColor="midGrey"
					disabled={isLoading}
				/>
			</FooterRightControls>
		);
	};

	render = () => {
		const { templateName, isOpen, modalTitle } = this.props;
		const { isLoading } = this.state;

		if (!isOpen) {
			return null;
		}

		return (
			<Modal
				isOpen={true}
				hSize="lg"
				footerComponent={this.renderFooterComponent()}
				title={modalTitle}
			>
				<HandleEmailTemplateContainer
					templateName={templateName}
					disabled={isLoading}
				/>
			</Modal>
		);
	};
}

const mapStoreToProps = (store, ownProps) => {
	const { modalName } = ownProps;

	return {
		isOpen:
			modalName && store.modals.getIn(["activeModal", "name"]) === modalName,
		workingTemplate: store.emailTemplates.get("workingTemplate"),
	};
};

const mapActionsToProps = {
	closeModal,
	addInfoNotification,
	addErrorNotification,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(NotifySingleUserModalContainer);
