import React from "react";
import { string, object, bool, func } from "prop-types";
import styled, { css } from "styled-components";

import TransparentInput from "../../shared/input/transparent-input";

const StyledTaskCreatorTemplate = css`
	display: flex;
	align-items: center;
	flex: 1;
`;

const StyledTaskCreator = styled.div`
	${StyledTaskCreatorTemplate}
	flex-direction: row;
	height: 50px;
	background-color: transparent;
	padding: ${(props) => props.theme.spacing[3]};
`;

const TaskCreator = ({
	fieldName,
	value,
	onChange,
	placeholderTid,
	onFocus,
	onBlur,
	onArrowUp,
	onArrowDown,
	onEnter,
	onEsc,
	i18n,
	inline,
	autofocus,
	editorref,
	readOnly,
}) => {
	const StyledTextAreaComponent = (
		<TransparentInput
			fieldName={fieldName}
			value={value}
			onChange={onChange}
			placeholder={placeholderTid}
			onFocus={onFocus}
			onBlur={onBlur}
			onArrowUp={onArrowUp}
			onArrowDown={onArrowDown}
			onEnter={onEnter}
			onEsc={onEsc}
			i18n={i18n}
			inputRef={editorref}
			autofocus={autofocus}
			readOnly={readOnly}
		/>
	);

	if (inline) {
		return StyledTextAreaComponent;
	}

	return <StyledTaskCreator>{StyledTextAreaComponent}</StyledTaskCreator>;
};

TaskCreator.defaultProps = {
	inline: false,
	autofocus: false,
	noBadgeSpace: false,
};

TaskCreator.propTypes = {
	fieldName: string,
	value: string,
	onChange: func,
	placeholderTid: string,
	onFocus: func,
	onBlur: func,
	onArrowUp: func,
	onArrowDown: func,
	onEnter: func,
	onEsc: func,
	i18n: object,
	inline: bool,
	autofocus: bool,
	editorref: func,
	badge: object,
	noBadgeSpace: bool,
	readOnly: bool,
};

export default TaskCreator;
