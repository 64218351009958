import React, { Component } from "react";
import { connect } from "react-redux";

import DropdownIconItem from "../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";

class PreviewProtocolContainer extends Component {
	redirect = () => {
		const { meetingId, urlAlias } = this.props;

		window.open(`/${urlAlias}/protocols/${meetingId}`, "_blank");
	};

	render = () => {
		return (
			<DropdownIconItem
				icon="faExternalLinkAlt"
				tid="meetings.smart_meetings.protocol.toolbar.dropdown.preview_protocol"
				onClick={() => this.redirect()}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meetingId: store.meetings.getIn(["meeting", "id"]),
		urlAlias: store.company.company?.urlAlias,
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(PreviewProtocolContainer);
