import React from "react";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";

const DealFlowInvitationDescModal = (props) => {
	const { t } = useTranslation();
	return (
		<Dialog
			maxWidth={"md"}
			fullWidth={true}
			open={props.open}
			onClose={props.close}
		>
			<DialogContent sx={{ px: 6, py: 4, minWidth: "60rem" }}>
				<IconButton
					aria-label="close"
					onClick={props.close}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[600],
					}}
				>
					<CloseIcon />
				</IconButton>
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Typography sx={{ textAlign: "center", pb: 2 }} variant="h4">
						{t("emissions.invitations.header.invite-via-dealflow.modal")}
					</Typography>
					<Box sx={{ textAlign: "left" }}>
						{t("emissions.invitations.invite-via-dealflow-desc-2")}
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
							mt: 7,
						}}
					>
						<Button
							disabled={props.preventChanges}
							onClick={props.close}
							variant={"contained"}
							sx={{ fontWeight: "bold" }}
						>
							{t("emissions.invitations.close_button")}
						</Button>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default DealFlowInvitationDescModal;
