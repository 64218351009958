// ---------------------------------------------------------------------------------------
// ----------------------------------- Multi Select --------------------------------------
// ---------------------------------------------------------------------------------------
import React from "react";
import { string, bool, func, oneOf, number } from "prop-types";
import styled from "styled-components";
import ReactSelect from "react-select-next";
import { fromJS, List, Map } from "immutable";
import ImmutableProps from "react-immutable-proptypes";
import StyledSelectTemplate from "./select.template";
import {
	setupCustomComponents,
	parseValue,
	setupFilters,
} from "./select.helpers";
import Text from "../../text/text";

const StyledSelect = styled(ReactSelect)`
	${StyledSelectTemplate}
`;

const MultiSelect = (props) => {
	const {
		placeholderTid,
		openMenuOnClick,
		optionsLabel,
		optionsValue,
		isDisabled,
		noOptionsMessage,
		closeMenuOnSelect,
		fieldName,
		onChange,
		isLoading,
		labelIsTid,
		hideSelectedOptions,
		filterType,
		clearAlternativeRenderer,
		hideDropdownIndicator,
		placeholderRenderer,
		selectRef,
		firstDummyLabelTid,
		formatOptionLabel,
		dropdownIndicatorAlternativeRenderer,
		maxMenuHeight,
		menuPortalTarget,
	} = props;
	const customComponentsConfig = {
		labelIsTid,
		placeholderTid,
		clearAlternativeRenderer,
		hideDropdownIndicator,
		placeholderRenderer,
		dropdownIndicatorAlternativeRenderer,
	};
	let { options, value } = props;

	// NULL !== undefined that's why default props don't help
	if (!options) {
		options = List();
	}

	// Check if dummy label should be added to options and value
	// Because this component doesn't keep it's state we need to add the
	// dummy object to options every time it rerenders
	if (firstDummyLabelTid && value.size >= 1) {
		const dummyOptionsObject = Map({
			[optionsValue]: firstDummyLabelTid,
			[optionsLabel]: firstDummyLabelTid,
			multiValueDummyLabel: true,
		});

		options = options.insert(0, dummyOptionsObject);

		// Add dummy object if it's not in the value
		if (!value.includes(firstDummyLabelTid)) {
			value = value.insert(0, firstDummyLabelTid);
		}
	}

	const _onChange = (selectedValues, action) => {
		// Remove first value in case dummyLabel is set

		/**
		 * Empty the array (to remove the dummy label tid) when last option was removed.
		 * selectedValues is checked for 1 (instaed of 2 which might sound more logical)
		 * because the dummy label we add is never passed outside of this component.
		 */
		if (
			firstDummyLabelTid &&
			selectedValues.length === 1 &&
			action.action === "remove-value"
		) {
			selectedValues = [];
		}

		selectedValues = selectedValues.filter((val) => {
			return !val.multiValueDummyLabel;
		});

		// Return only VALUES
		selectedValues = selectedValues.map((val) => {
			return val[optionsValue];
		});

		if (fieldName) {
			onChange && onChange(fieldName, fromJS(selectedValues), fromJS(action));
		} else {
			onChange && onChange(fromJS(selectedValues), fromJS(action));
		}
	};

	const _setupFilters = (option, input) => {
		return setupFilters(filterType, option, input);
	};

	return (
		<StyledSelect
			classNamePrefix="react-select"
			components={setupCustomComponents(customComponentsConfig)}
			getOptionLabel={(val) => {
				return val[optionsLabel];
			}}
			getOptionValue={(val) => {
				return val[optionsValue];
			}}
			openMenuOnClick={openMenuOnClick}
			options={options.toJS()}
			filterOption={filterType && _setupFilters}
			ignoreAccents={false}
			isMulti={true}
			placeholder={placeholderTid}
			isDisabled={isDisabled || isLoading}
			isLoading={isLoading}
			noOptionsMessage={noOptionsMessage}
			closeMenuOnSelect={closeMenuOnSelect}
			hideSelectedOptions={hideSelectedOptions}
			placeholderRenderer={placeholderRenderer}
			formatOptionLabel={formatOptionLabel}
			onChange={_onChange}
			value={parseValue(value, options, optionsValue)}
			ref={selectRef}
			maxMenuHeight={maxMenuHeight}
			menuPortalTarget={menuPortalTarget}
			closeMenuOnScroll={
				menuPortalTarget &&
				((event) => {
					if (event.srcElement.className.includes("react-select")) {
						return false;
					}

					return true;
				})
			}
		/>
	);
};

MultiSelect.defaultProps = {
	closeMenuOnSelect: false,
	isDisabled: false,
	openMenuOnClick: true,
	optionsLabel: "label",
	optionsValue: "value",
	placeholderTid: "",
	isLoading: false,
	hideSelectedOptions: true,
	hideDropdownIndicator: false,
	labelIsTid: false,
	maxMenuHeight: 170,
	noOptionsMessage: () => {
		return <Text tid="select.default_no_options" />;
	},
};

MultiSelect.propTypes = {
	closeMenuOnSelect: bool,
	fieldName: string,
	optionsLabel: string,
	optionsValue: string,
	placeholderTid: string,
	labelIsTid: bool,
	isDisabled: bool,
	onChange: func,
	openMenuOnClick: bool,
	noOptionsMessage: func,
	options: ImmutableProps.list,
	value: ImmutableProps.list,
	isLoading: bool,
	hideSelectedOptions: bool,
	filterType: oneOf(["word-match"]),
	clearAlternativeRenderer: func,
	hideDropdownIndicator: bool,
	placeholderRenderer: func,
	onSetRef: func,
	firstDummyLabelTid: string,
	dropdownIndicatorAlternativeRenderer: func,
	maxMenuHeight: number,
};

export default MultiSelect;
