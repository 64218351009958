import React, { Component } from "react";
import { connect } from "react-redux";

import Alert from "../../../../../dumb-components/onboarding/alert";
import Badge from "../../../../../dumb-components/shared/badge/badge-new";
import DismissControls from "../dismiss-controls.container";
import Text from "../../../../../dumb-components/shared/text/text";

import { getIsDismissed } from "../helpers";
const ALERT_ID = "afeea236-a174-4a1a-bd8a-e0dd25bc5106";

class CreateAgmHowToAlert extends Component {
	render = () => {
		const { isDismissed } = this.props;

		if (isDismissed) {
			return null;
		}

		return (
			<Alert
				badgeComponent={
					<Badge
						isStatic
						bgColor="green"
						textColor="white"
						tid="generic.beta"
					/>
				}
				controlsComponent={<DismissControls alertId={ALERT_ID} />}
			>
				<Text
					color="white"
					tid="company.dashboard.panel.beta_agm.information"
				/>
			</Alert>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		isDismissed: getIsDismissed(store.user.get("userObj"), ALERT_ID),
	};
};

const mapActionsToProps = {};

export default connect(mapStoreToProps, mapActionsToProps)(CreateAgmHowToAlert);
