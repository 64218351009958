import React, { Component } from "react";
import { connect } from "react-redux";
import { func } from "prop-types";
import { Map, List } from "immutable";

import documentsHelper from "../../../components/helpers/documents.helper";
import DndSortList from "../../../dumb-components/documents/documents-dnd-sort/documents-dnd-sort-list";
import DndSortListItem, {
	ListHeader,
} from "../../../dumb-components/documents/documents-dnd-sort/documents-dnd-sort-list-item";
import InfoAndWarningHeader from "../../../dumb-components/documents/document-list/info-and-warning-header";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";
import Icon from "../../../dumb-components/shared/icon/icon";

import { reorderDocumentsToMerge } from "../../../actions/documents.actions";

const WarningComponent = ({ tid }) => (
	<Tooltip tid={tid} delayShow="instant" place="left">
		<Icon
			icon="faExclamationTriangle"
			size="sml"
			color="yellowMid"
			type="solid"
		/>
	</Tooltip>
);

class DocumentsSortListContainer extends Component {
	state = {
		documents: List(),
	};

	static propTypes = {
		onChange: func,
	};

	componentDidMount = () => {
		const { documentsToMerge } = this.props;

		if (documentsToMerge.size > 0) {
			this.parseDocumentsList();
		}
	};

	componentDidUpdate = (prevProps) => {
		const { documentsToMerge } = this.props;
		if (prevProps.documentsToMerge !== documentsToMerge) {
			this.parseDocumentsList();
		}
	};

	parseDocumentsList = () => {
		let { documentsToMerge } = this.props;

		let latestOrderIndex = 0;
		documentsToMerge = documentsToMerge.map((document, index) => {
			if (document.has("orderIndexMergeDocuments")) {
				latestOrderIndex = document.get("orderIndexMergeDocuments");
			} else {
				latestOrderIndex = 100 * (index + 1);
				document = document.set("orderIndexMergeDocuments", latestOrderIndex);
			}

			return document;
		});

		documentsToMerge = documentsToMerge.sort((a, b) => {
			if (
				a.get("orderIndexMergeDocuments") === b.get("orderIndexMergeDocuments")
			) {
				return 0;
			} else {
				return (
					a.get("orderIndexMergeDocuments") - b.get("orderIndexMergeDocuments")
				);
			}
		});

		this.setState({ documents: documentsToMerge });
	};

	getDocumentIsNotPDF = (doc) => {
		const ext = doc.getIn(["file", "ext"]);
		return !documentsHelper.getDocumentIsPdf(ext);
	};

	handleDragEnd = (result) => {
		const { reorderDocumentsToMerge } = this.props;
		const { documents } = this.state;
		const { source, destination } = result;

		if (!destination) {
			return;
		}

		reorderDocumentsToMerge(documents, source.index, destination.index);
	};

	handleGetControlsData = (doc) => {
		const { primaryDocument } = this.props;
		const primaryDocIsNotPDF = this.getDocumentIsNotPDF(primaryDocument);
		const currentDocIsNotPDF = this.getDocumentIsNotPDF(doc);
		const docId = doc.get("id");
		const primaryDocId = primaryDocument.get("id");
		const notRenderingPrimaryDocument = docId !== primaryDocId;
		let component, tooltipTid, isDisabled;

		if (primaryDocIsNotPDF && notRenderingPrimaryDocument) {
			component = (
				<WarningComponent tid="meetings.protocol.merge_modal.document.list.warning.tooltip.primary_not_pdf" />
			);
		} else if (currentDocIsNotPDF && notRenderingPrimaryDocument) {
			component = (
				<WarningComponent tid="esign.advanced_modal.attachments.tooltip.incorrect_file_format" />
			);
		}

		if (docId === primaryDocId) {
			tooltipTid =
				"esign.advanced_modal.attachments.tooltip.primary_doc_must_be_merged";
			isDisabled = true;
		}

		return { component, tooltipTid, isDisabled };
	};

	renderListHeader = () => {
		return <ListHeader />;
	};

	renderListItem = (doc, index) => {
		const { onChange } = this.props;

		return (
			<DndSortListItem
				getControlsData={this.handleGetControlsData}
				onChange={onChange}
				doc={doc}
				key={index}
				index={index}
				draggableIdProp="id"
			/>
		);
	};

	render = () => {
		const { documents } = this.state;

		return (
			<DndSortList
				documentsList={documents}
				renderListItem={this.renderListItem}
				renderListHeader={this.renderListHeader}
				onDragEnd={this.handleDragEnd}
				headerComponent={<InfoAndWarningHeader documents={documents} />}
				droppableId="attachments"
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		documentsToMerge: store.documents.get("documentsToMerge") || List(),
		primaryDocument: store.documents.get("document") || Map(),
	};
};

const mapActionsToProps = {
	reorderDocumentsToMerge,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(DocumentsSortListContainer);
