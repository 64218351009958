/**
 * Modal reducer
 * @module reducers/modal.reducer
 */

import {
	COMPANY_RESET_REDUCER,
	HIDE_MODAL,
	SHOW_MODAL,
} from "../actions/types";

/** The initial state object for menu. */
const INITIAL_STATE = {
	modalType: null,
	modalProps: {},
};

/** Export the reducer */
export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case SHOW_MODAL:
			return {
				modalType: action.payload.modalType,
				modalProps: action.payload.modalProps,
			};
		case HIDE_MODAL:
			return INITIAL_STATE;
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE;
		}
		default:
			return state;
	}
}
