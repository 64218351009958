import React, { Component } from "react";
import { connect } from "react-redux";
import { object } from "prop-types";
import { Map } from "immutable";

import { saveMeeting } from "../../../../../actions/meetings.actions";
import DropdownIconItem from "../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import Tooltip from "../../../../../dumb-components/shared/tooltip/tooltip";
import ConfirmContainer from "../../../../shared/confirm.container";

import { MEETING_TYPE_STANDARD } from "/shared/constants";

class AssignSecretaryDropdownItemContainer extends Component {
	static propTypes = {
		dropdownRef: object,
	};

	state = {
		isConfirmOpen: false,
	};

	shouldShow = () => {
		const { meeting, userId, isStandardMeeting } = this.props;

		if (!userId || meeting.size === 0) {
			return false;
		}

		const canChangeSecretary = meeting.getIn([
			"computedValues",
			"canChangeSecretary",
		]);
		const meetingIsFinished = meeting.getIn([
			"computedValues",
			"meetingIsFinished",
		]);
		const protocolPublished = meeting.getIn([
			"computedValues",
			"protocolPublished",
		]);
		const isSecretary = meeting.get("secretary") === userId;
		const isModerator = meeting.getIn(["computedValues", "isModerator"]);
		const meetingIsStarted = meeting.getIn([
			"computedValues",
			"meetingIsStarted",
		]);

		if (isStandardMeeting) {
			return (
				canChangeSecretary &&
				meetingIsStarted &&
				!protocolPublished &&
				!isModerator
			);
		}

		return (
			canChangeSecretary &&
			meetingIsFinished &&
			!protocolPublished &&
			!isSecretary
		);
	};

	actAsSecretary = () => {
		const { meeting, userId, dropdownRef } = this.props;
		const actingAsSecretary = meeting.get("actingAsSecretary");

		if (actingAsSecretary && actingAsSecretary !== userId) {
			dropdownRef?.onToggleMenu();
			this.setState({ isConfirmOpen: true });
			return;
		}

		dropdownRef?.onToggleMenu();
		this.doActAsSecretary();
	};

	doActAsSecretary = () => {
		const { userId, saveMeeting } = this.props;
		let { meeting } = this.props;

		meeting = meeting.set("actingAsSecretary", userId);

		saveMeeting(meeting);
		this.closeConfirm();
	};

	stopActAsSecretary = () => {
		const { saveMeeting, dropdownRef } = this.props;
		let { meeting } = this.props;

		dropdownRef && dropdownRef.onToggleMenu();

		meeting = meeting.set("actingAsSecretary", null);

		saveMeeting(meeting);
	};

	closeConfirm = () => {
		this.setState({ isConfirmOpen: false });
	};

	render = () => {
		const { meetingIsDeleted, actingAsSecretary, isStandardMeeting } =
			this.props;
		const { isConfirmOpen } = this.state;

		if (!this.shouldShow()) {
			return null;
		}

		let tid;

		if (isStandardMeeting) {
			tid = !actingAsSecretary
				? "meeting.general.summary.act_as_moderator"
				: "meeting.general.summary.stop_act_as_moderator";
		} else {
			tid = !actingAsSecretary
				? "meeting.general.summary.act_as_secretary"
				: "meeting.general.summary.stop_act_as_secretary";
		}

		let tooltipTid;
		if (isStandardMeeting) {
			tooltipTid =
				!actingAsSecretary &&
				"meeting.general.summary.act_as_moderator.tooltip";
		} else {
			tooltipTid =
				!actingAsSecretary &&
				"meeting.general.summary.act_as_secretary.tooltip";
		}

		const fn = !actingAsSecretary
			? this.actAsSecretary
			: this.stopActAsSecretary;

		return (
			<>
				<Tooltip tid={tooltipTid} maxWidth="200px">
					<DropdownIconItem
						icon="faUnlock"
						onClick={fn}
						tid={tid}
						disabled={meetingIsDeleted}
					/>
				</Tooltip>
				<ConfirmContainer
					title={
						isStandardMeeting
							? "meeting.act_as_moderator.confirm.title"
							: "meeting.act_as_secretary.confirm.title"
					}
					message={
						isStandardMeeting
							? "meetings.act_as_moderator.confirm.message"
							: "meetings.act_as_secretary.confirm.message"
					}
					onConfirm={this.doActAsSecretary}
					onDecline={this.closeConfirm}
					isOpen={isConfirmOpen}
				/>
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	const userId = store.user.getIn(["userObj", "id"]);
	return {
		userId,
		meeting: store.meetings.get("meeting", Map()) || Map(),
		meetingIsDeleted: store.meetings.getIn(["meeting", "isDeleted"]),
		actingAsSecretary:
			store.meetings.getIn(["meeting", "actingAsSecretary"]) === userId,
		isStandardMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) ===
			MEETING_TYPE_STANDARD,
	};
};

const mapActionsToProps = {
	saveMeeting,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(AssignSecretaryDropdownItemContainer);
