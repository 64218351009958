import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { string, bool, object } from "prop-types";
import { Map, List } from "immutable";
import { Margin } from "styled-components-spacing";
import {
	saveDocument,
	updateDocumentLocal,
	storeDocumentsToMerge,
	prependDocumentToMerge,
	clearDocumentsToMerge,
} from "../../../actions/documents.actions";
import {
	fetchMultipleAttachedDocuments,
	fetchAttachedDocument,
	updateAttachedDocumentLocal,
} from "../../../actions/attachments.actions";
import documentsHelper from "../../../components/helpers/documents.helper";
import { flattenAgendaItems } from "../../../components/helpers/meeting.helper";
import ESignSignatoriesPanelContainer from "../../documents/esign-signatories-panel.container";
import NotifyAboutSignedProtocolContainer from "./components/document-request-esign-modal/notify-about-signed-protocol.container";
import PublishOrEsignProtocolPanelContainer from "../meeting/publish-or-esign-protocol/publish-or-esign-protocol-panel.container";
import PublishWithoutEsignPanelContainer from "../meeting/publish-or-esign-protocol/publish-without-esign-panel.container";
import {
	EVENT_TYPE_DOCUMENT_SIGN,
	EVENT_TYPE_DOCUMENT_SIGNING_FINALIZED,
	OBJ_TYPE_MEETING,
	PDFKIND_PROTOCOL,
	MEETING_TYPE_SMART,
	MEETING_TYPE_STANDARD,
	OBJ_TYPE_MEETING_AGENDA,
	OBJ_TYPE_MEETING_ATTACHMENT,
	OBJ_TYPE_MEETING_PROTOCOL,
} from "/shared/constants";

class ProtocolEsigningContainer extends Component {
	state = {
		selectableAttendees: null,
	};

	static propTypes = {
		documentId: string,
		readOnly: bool,
		leftControlsComponent: object,
	};

	componentDidMount = () => {
		const { meetingId, attendees } = this.props;

		// if (documentId) {
		// 	fetchAttachedDocument(OBJ_TYPE_MEETING, meetingId, documentId, true)
		// }

		if (attendees) {
			this.parseAttendees();
		}

		if (meetingId) {
			this.fetchAttachments();
		}
	};

	componentDidUpdate = (prevProps) => {
		const { attendees, document, isSmartMeeting } = this.props;
		// if (prevProps.documentId !== documentId) {
		// 	fetchAttachedDocument(OBJ_TYPE_MEETING, meetingId, documentId, true)
		// }

		if (prevProps.attendees !== attendees) {
			this.parseAttendees();
		}

		if (isSmartMeeting && !prevProps.document && document) {
			this.fetchAttachments();
		}

		this.checkLiveUpdateEvents();
	};

	componentWillUnmount = () => {
		const { clearDocumentsToMerge } = this.props;
		clearDocumentsToMerge();
	};

	checkLiveUpdateEvents = () => {
		const { audit, fetchAttachedDocument, documentId, meetingId } = this.props;

		const DOCUMENT_SIGN = audit.get(EVENT_TYPE_DOCUMENT_SIGN, Map());
		const DOCUMENT_FINALIZED = audit.get(
			EVENT_TYPE_DOCUMENT_SIGNING_FINALIZED,
			Map(),
		);

		// Document was signed by someone, fetch updated document
		if (
			DOCUMENT_SIGN.get("r") === true &&
			documentId === DOCUMENT_SIGN.get("objId")
		) {
			fetchAttachedDocument(OBJ_TYPE_MEETING, meetingId, documentId, true);
		}

		// Document was signed by someone, fetch updated document
		if (
			DOCUMENT_FINALIZED.get("r") === true &&
			documentId === DOCUMENT_FINALIZED.get("objId")
		) {
			fetchAttachedDocument(OBJ_TYPE_MEETING, meetingId, documentId, true);
		}
	};

	fetchAttachments = () => {
		const {
			meetingId,
			agendaItems,
			fetchMultipleAttachedDocuments,
			storeDocumentsToMerge,
			isSmartMeeting,
		} = this.props;
		let { document } = this.props;
		let objIds = List([]);

		if (meetingId) {
			if (isSmartMeeting) {
				const fakeMeeting = Map({
					id: meetingId,
					agendaItems,
				});
				const agendaItemsFlatten = flattenAgendaItems(fakeMeeting);

				if (agendaItemsFlatten) {
					objIds = agendaItemsFlatten.map((agendaItem) =>
						agendaItem.get("objId"),
					);
				}
			}

			const objIdForProtocolDoc = `${meetingId}$${OBJ_TYPE_MEETING_PROTOCOL}`;
			objIds = objIds.push(meetingId);
			objIds = objIds.push(`${meetingId}$${OBJ_TYPE_MEETING_AGENDA}`);
			objIds = objIds.push(`${meetingId}$${OBJ_TYPE_MEETING_ATTACHMENT}`);
			objIds = objIds.push(objIdForProtocolDoc);
			fetchMultipleAttachedDocuments(objIds, (response) => {
				let documents = response?.get("documents");

				if (!documents) {
					return;
				}

				// if (isSmartMeeting && document) {
				// 	document = document.set('isBeingGenerated', true)
				// 	document = document.setIn(['metadata', 'toBeMerged'], true)
				// 	documents = documents.unshift(document)
				// } else if (isStandardMeeting) {
				const index = documents.findIndex((doc) =>
					doc
						.get("links")
						.some(
							(link) =>
								link.get("objType") === OBJ_TYPE_MEETING_PROTOCOL &&
								link.get("objId") === objIdForProtocolDoc,
						),
				);

				if (index >= 0) {
					document = documents.get(index);

					// if (isSmartMeeting && document) {
					// 	document = document.setIn(['metadata', 'isBeingGenerated'], true)
					// }

					if (!document.hasIn(["metadata", "toBeMerged"])) {
						document = document.setIn(["metadata", "toBeMerged"], true);
					}

					// Place the protocol in the beginning of the stack only if the protocol has not been moved
					if (!document.has("orderIndexMergeDocuments")) {
						documents = documents.remove(index).insert(0, document);
					}
				}
				// }

				storeDocumentsToMerge(documents);
			});
		}
	};

	parseAttendees = () => {
		const { attendees } = this.props;
		let selectableAttendees = Map();

		attendees &&
			attendees.forEach((attendee, attendeeId) => {
				if (!attendee.get("isInvestor") && !attendee.get("isGuest")) {
					selectableAttendees = selectableAttendees.set(
						attendeeId,
						Map({ userId: attendeeId }),
					);
				} else if (attendee.get("isInvestor")) {
					selectableAttendees = selectableAttendees.set(
						attendeeId,
						Map({
							userId: attendeeId,
							investmentId: attendee.get("investmentId"),
							isInvestor: true,
							isActiveInvonoCompany: attendee.get("isActiveInvonoCompany"),
						}),
					);
				} else if (attendee.get("isGuest")) {
					selectableAttendees = selectableAttendees.set(
						attendeeId,
						Map({
							userId: attendeeId,
							isGuest: true,
							name: attendee.get("name"),
							email: attendee.get("email"),
							index: attendee.get("index"),
						}),
					);
				}
			});

		this.setState({ selectableAttendees });
	};

	doDebounce = debounce((document) => {
		const { saveDocument } = this.props;
		saveDocument(document);
	}, 1000);

	updateDocumentLocal = (document) => {
		const { updateDocumentLocal } = this.props;
		updateDocumentLocal(document);
		this.doDebounce(document);
	};

	onChange = (field, val) => {
		let { document } = this.props;

		document = document.set(field, val);
		this.updateDocumentLocal(document);
	};

	onGenerateFileInit = () => {
		const { prependDocumentToMerge } = this.props;
		let { document } = this.props;

		document = document.setIn(["metadata", "isBeingGenerated"], true);
		prependDocumentToMerge(document);
	};

	onGenereteFileComplete = (document) => {
		const { prependDocumentToMerge } = this.props;

		if (!document) {
			return;
		}

		document = document.removeIn(["metadata", "isBeingGenerated"]);
		document = document.setIn(["metadata", "toBeMerged"], true);

		prependDocumentToMerge(document);
	};

	onSendSignRequest = (err, document) => {
		const { objId, updateAttachedDocumentLocal } = this.props;

		!err && updateAttachedDocumentLocal(objId, document);
	};

	onDeleteSignedDocument = (err, document) => {
		const { objId, updateAttachedDocumentLocal } = this.props;

		!err && updateAttachedDocumentLocal(objId, document);
	};

	renderShareWithComponent = ({ goToUsersModal }) => {
		return (
			<NotifyAboutSignedProtocolContainer goToUsersModal={goToUsersModal} />
		);
	};

	renderSignProtocolPanel = () => {
		const {
			document,
			readOnly,
			leftControlsComponent,
			meetingId,
			isSmartMeeting,
		} = this.props;
		const { selectableAttendees } = this.state;
		const willGenerateFile = isSmartMeeting && !document?.mergedDocumentData;
		const skipFilesView =
			document && documentsHelper.documentPendingEsigning(document);

		return (
			<ESignSignatoriesPanelContainer
				onChange={this.onChange}
				readOnly={readOnly}
				forceReadOnly={readOnly}
				noPanel={true}
				willGenerateFile={willGenerateFile}
				typeOfFileToGenerate={PDFKIND_PROTOCOL}
				fileObjOwnerId={meetingId}
				onGenereteFileInit={this.onGenerateFileInit}
				onGenereteFileComplete={this.onGenereteFileComplete}
				leftControlsComponent={leftControlsComponent}
				selectableUsersFromExternalSource={selectableAttendees}
				renderShareWithComponent={this.renderShareWithComponent}
				skipFilesView={skipFilesView}
				marginTop={0}
				marginRight={0}
				marginBottom={0}
				marginLeft={0}
				objType={OBJ_TYPE_MEETING}
				onRequestSigning={this.fetchAttachments}
				onSendSignRequest={this.onSendSignRequest}
				onDeleteSignedDocument={this.onDeleteSignedDocument}
			/>
		);
	};

	renderPublishProtocolPanel = () => {
		return <PublishWithoutEsignPanelContainer />;
	};

	render = () => {
		const { isStandardMeeting } = this.props;
		let topMargin = 0;
		let bottomMargin = 0;

		if (isStandardMeeting) {
			topMargin = 4;
		}

		return (
			<Margin top={topMargin} bottom={bottomMargin}>
				<PublishOrEsignProtocolPanelContainer
					signProtocolPanelComponent={this.renderSignProtocolPanel()}
					publishProtocolPanelComponent={this.renderPublishProtocolPanel()}
				/>
			</Margin>
		);
	};
}

const mapStoreToProps = (store, ownProps) => {
	const meetingId = store.meetings.getIn(["meeting", "id"]);

	return {
		document: store.documents.get("document", Map()),
		audit: store.audit.get("documents"),
		meetingId,
		attendees: store.meetings.getIn(["meeting", "attendees"]),
		isSmartMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) === MEETING_TYPE_SMART,
		isStandardMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) ===
			MEETING_TYPE_STANDARD,
		agendaItems: store.meetings.getIn(["meeting", "agendaItems"]),
		objId: ownProps.objId || meetingId,
	};
};

const mapActionsToProps = {
	saveDocument,
	updateDocumentLocal,
	fetchAttachedDocument,
	storeDocumentsToMerge,
	fetchMultipleAttachedDocuments,
	prependDocumentToMerge,
	clearDocumentsToMerge,
	updateAttachedDocumentLocal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ProtocolEsigningContainer);
