import { func, string } from "prop-types";
import React, { createRef, useState } from "react";
import LangaugeSwitcherMenu from "../../../dumb-components/navbar/language-switcher-menu/language-switcher-menu";
import DropdownItem from "../../../dumb-components/shared/dropdown-item/dropdown-item";
import Text from "../../../dumb-components/shared/text/text";
import DropdownMenuContainer from "../../shared/dropdown-menu.container";

const languageOptions = [
	{ label: "language_switcher.language.swedish", value: "sv" },
	{ label: "language_switcher.language.english", value: "en" },
];

function LanguageSwitcher({ initialLanguage, onChange }) {
	const dropdownMenuRef = createRef();
	const [language, setLanguage] = useState(initialLanguage);

	const _onChange = (value) => {
		setLanguage(value);
		onChange(value);
		dropdownMenuRef.current.onToggleMenu();
	};

	return (
		<DropdownMenuContainer
			halignMenu="right"
			ref={dropdownMenuRef}
			renderRaw={<LangaugeSwitcherMenu language={language} />}
		>
			{languageOptions.map((lang, index) => (
				<DropdownItem key={index} onClick={() => _onChange(lang.value)}>
					<Text tid={lang.label} />
				</DropdownItem>
			))}
		</DropdownMenuContainer>
	);
}

LanguageSwitcher.propTypes = {
	initialLanguage: string,
	onChange: func.isRequired,
};

export { LanguageSwitcher };
