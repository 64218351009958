import React, { forwardRef, useImperativeHandle, useState } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../../mui-components/tooltip/tooltip";
import { bool, func, object, oneOfType, string } from "prop-types";

const filter = createFilterOptions();

const ComboBox = forwardRef((props, ref) => {
	const [value, setValue] = useState({ label: props.value ?? "" });
	const [error, setError] = useState(null);
	const { t } = useTranslation();
	const options =
		typeof props.options === "function" ? props.options(props) : props.options;

	useImperativeHandle(ref, () => ({
		getValue() {
			return value?.label ?? "";
		},

		isCancelBeforeStart() {
			return false;
		},

		isCancelAfterEnd() {
			if (typeof props.validate === "function") {
				return !props.validate(value);
			}

			return false;
		},
	}));

	const onChange = (event, newValue) => {
		setError(null);
		if (
			typeof props.validate === "function" &&
			(typeof newValue === "string" ||
				(!newValue && "inputValue" in (newValue ?? {})))
		) {
			let valid = true;

			if (typeof newValue === "string") {
				valid = props.validate(newValue);
			} else if (!newValue && "inputValue" in (newValue ?? {})) {
				valid = props.validate(newValue.inputValue);
			}

			if (!valid) {
				setError(props.errorTid);
			}
		}

		if (typeof newValue === "string") {
			setValue({ label: newValue });
		} else if (newValue?.inputValue) {
			setValue({ label: newValue.inputValue });
		} else {
			setValue(newValue);
		}
	};

	const getOptionLabel = (option) => {
		if (typeof option === "string") {
			return option;
		}

		if (option.inputValue) {
			return option.inputValue;
		}

		return option.label ?? "";
	};

	const filterOptions = (options, params) => {
		const filteredOptions = filter(options, params);
		const { inputValue } = params;
		const isExisting = options.some((option) => inputValue === option.label);

		if (inputValue !== "" && !isExisting) {
			filteredOptions.push({
				inputValue,
				label: t("combobox.add_person", { name: inputValue }),
			});
		}

		return filteredOptions;
	};

	return (
		<Autocomplete
			options={options}
			filterOptions={filterOptions}
			getOptionLabel={getOptionLabel}
			onChange={onChange}
			value={value}
			sx={{
				width: "100%",
				...(props.useRegularInput
					? {
							"& input": {
								width: "100%",
								height: `${props.node.rowHeight - 2}px !important`,
							},
					  }
					: {}),
			}}
			freeSolo={true}
			handleHomeEndKeys={true}
			clearOnBlur={true}
			selectOnFocus={true}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.id ?? option.label}>
						{option.label}
					</li>
				);
			}}
			renderInput={(params) => {
				return (
					<Tooltip title={error} hideTooltip={!error} open>
						{!props.useRegularInput ? (
							<TextField {...params} label="Person" autoFocus />
						) : (
							<div ref={params.InputProps.ref}>
								<input
									type="text"
									{...params.inputProps}
									ref={(inputRef) => {
										if (!inputRef) {
											return;
										}
										inputRef.focus();
										params.inputProps.ref.current = inputRef;
									}}
								/>
							</div>
						)}
					</Tooltip>
				);
			}}
		/>
	);
});

ComboBox.propTypes = {
	options: oneOfType([object, func]),
	validate: func,
	errorTid: string,
	useRegularInput: bool,
};

ComboBox.displayName = "ComboBox";

export { ComboBox };
