import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { string, object } from "prop-types";
import { List } from "immutable";

export default class Field extends Component {
	static propTypes = {
		className: string,
		tid: string,
		values: object,
		helpTextTid: string,
	};

	static defaultProps = {
		className: "col-md-12",
		clearable: false,
	};

	render = () => {
		let { tid, helpTextTid, className, errors, name, flex, values } =
			this.props;
		const wrapperClassName = className || "col-md-12";
		const hasError = errors ? errors.get(name, List()).size > 0 : false;

		return (
			<div
				className={`form-group ${wrapperClassName} ${
					hasError ? "has-error" : ""
				}`}
			>
				{tid && (
					<label className="control-label">
						<FormattedMessage id={tid} values={values} />
					</label>
				)}
				{flex ? (
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						{this.props.children}
					</div>
				) : (
					<>{this.props.children}</>
				)}
				{helpTextTid && (
					<span className="help-block">
						<FormattedMessage id={helpTextTid} />
					</span>
				)}
			</div>
		);
	};
}
