import req from "../modules/request.module";
import { fetchCompany } from "./company.actions";
import { fromJS } from "immutable";
import {
	USER_MANAGEMENT_FETCH_USERS,
	USER_MANAGEMENT_FETCH_ROLES,
	USER_MANAGEMENT_FETCH_ROLES_CACHE,
	USER_MANAGEMENT_FETCH_RESOURCES,
	USER_MANAGEMENT_FETCH_SELECTED_USER,
	USER_MANAGEMENT_FETCH_SELECTED_ROLE,
	USER_MANAGEMENT_FETCH_SELECTED_ROLE_PERMISSIONS,
	USER_MANAGEMENT_CHANGED_SELECTED_ROLE_VALUE,
	USER_MANAGEMENT_CHANGED_PERMISSIONS_BUNDLES,
	USER_MANAGEMENT_FETCH_SELECTED_ROLE_PERMISSIONS_START,
	USER_MANAGEMENT_REQUEST_PENDING,
	USER_MANAGEMENT_FETCH_USERS_IN_ROLES_CACHE,
	USER_MANAGEMENT_RESET_CACHE,
} from "./types";
import { fetchMenu } from "./menu.actions";

/**
 * Action for fetching users that have access to a company.
 * @param {String} companyId — the company id
 *
 * TODO: Why get users from 'permissions' and not from 'users'?
 */
export function fetchUsers() {
	return function (dispatch) {
		req
			.get("/permissions/acl/users")
			.then((response) => {
				dispatch({ type: USER_MANAGEMENT_FETCH_USERS, payload: response.data });
			})
			.catch(() => {});
	};
}

/**
 * Action for fetching a single user that have access to a company.
 * @param {String} id — user id
 * @param {String} companyId — company id
 */
export function fetchUser(id, companyId) {
	return function (dispatch) {
		req
			.get(`/permissions/acl/users/${id}`, { companyId })
			.then((response) => {
				dispatch({
					type: USER_MANAGEMENT_FETCH_SELECTED_USER,
					payload: response.data,
				});
			})
			.catch(() => {});
	};
}

/**
 * Action for creating a new user for a company.
 * @param {Object} user — user details
 * @param {String} companyId — the company id
 */
export function createUser(user, companyId, mailTemplate, callback) {
	return function (dispatch) {
		return req
			.post("/permissions/acl/users/", { ...user, mailTemplate })
			.then(() => {
				if (companyId) {
					dispatch(fetchCompany(companyId));
					dispatch(fetchUsers(companyId));
				}
				callback && callback();
			})
			.catch((e) => {
				callback && callback(e);
				throw e;
			});
	};
}

export function remindUsers(userIds, mailTemplate, callback, errorCallback) {
	return function () {
		return req
			.post("/companies/users/remind", { userIds, mailTemplate })
			.then(() => {
				callback?.();
			})
			.catch(() => {
				errorCallback?.();
			});
	};
}

/**
 * Action for editing user.
 * @param {Object} user — user details
 * @param {String} companyId — company id
 */
export function editUser(userId, user, companyId, userIsActive, callback) {
	return function (dispatch) {
		req
			.put(`/permissions/acl/users/${userId}`, { ...user, userIsActive })
			.then(() => {
				dispatch(fetchUsers(companyId));
				callback && callback();
			})
			.catch((e) => {
				callback && callback(e);
			});
	};
}

/**
 * Action for removing a user from a company.
 * @param {String} userId — user id
 * @param {String} companyId — company id
 */
export function removeUserFromCompany(userId, companyId, callback) {
	return function (dispatch) {
		req
			.delete(`/permissions/acl/users/${userId}`)
			.then(() => {
				dispatch(fetchUsers(companyId));
				callback && callback();
			})
			.catch((e) => {
				callback && callback(e);
			});
	};
}

/**
 * Action for fetching roles for a company.
 */
export function fetchRoles(callback) {
	return async function (dispatch) {
		try {
			const response = await req.get("/permissions/acl/roles");

			dispatch({ type: USER_MANAGEMENT_FETCH_ROLES, payload: response.data });
			callback && callback(response.data);
		} catch (error) {
			console.log(error);
		}
	};
}

export function resetRolesAndUsersCache() {
	return { type: USER_MANAGEMENT_RESET_CACHE };
}

export function fetchRolesCache(roleIds) {
	return function (dispatch, getState) {
		const cache = getState().company.userManagement.rolesCache;
		roleIds = roleIds.filter((id) => {
			return !cache.has(id);
		});
		if (roleIds.size > 0) {
			req
				.post("/permissions/roles/array", roleIds)
				.then((response) => {
					dispatch({
						type: USER_MANAGEMENT_FETCH_ROLES_CACHE,
						payload: fromJS(response.data),
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
}

export function fetchUsersInRolesCache(roleIds, successCallback) {
	return function (dispatch, getState) {
		const cache = getState().company.userManagement.usersInRoleCache;
		roleIds = roleIds.filter((id) => {
			return !cache.has(id);
		});
		if (roleIds.size > 0) {
			req
				.post("/permissions/roles/array/users", roleIds)
				.then((response) => {
					successCallback && successCallback(roleIds);
					dispatch({
						type: USER_MANAGEMENT_FETCH_USERS_IN_ROLES_CACHE,
						payload: fromJS(response.data),
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
}

/**
 * Action for fetching role description
 * @param {String} roleId — a roles id
 */
export function fetchRoleDetails(roleId) {
	return function (dispatch) {
		dispatch({ type: USER_MANAGEMENT_FETCH_SELECTED_ROLE, payload: {} });

		req
			.get(`/permissions/acl/role/${roleId}`)
			.then((response) => {
				dispatch({
					type: USER_MANAGEMENT_FETCH_SELECTED_ROLE,
					payload: response.data,
				});
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

/**
 * Action for fetching a roles permissions
 * @param {String} roleId — a roles id
 */
export function fetchRolePermissions(roleId) {
	return function (dispatch) {
		req
			.get(`/permissions/roles/${roleId}/resources`)
			.then((response) => {
				dispatch({
					type: USER_MANAGEMENT_FETCH_SELECTED_ROLE_PERMISSIONS,
					payload: fromJS(response.data),
				});
			})
			.catch(() => {});
	};
}

/**
 * Action for creating a new role for a company.
 * @param {Object} role — role details
 */
export function createRole(role, callback) {
	return function (dispatch) {
		req
			.post("/permissions/acl/roles/", role)
			.then(() => {
				dispatch(fetchRoles());
				callback && callback();
			})
			.catch((e) => {
				callback && callback(e);
			});
	};
}

/**
 * Action for editing an existing role.
 * @param {Object} roleDetails — new role description including existing role:s id
 */
export function editRole(roleDetails, callback) {
	return function (dispatch) {
		req
			.put(`/permissions/acl/role/${roleDetails.id}`, roleDetails)
			.then(() => {
				dispatch(fetchRoles());
				callback && callback();
			})
			.catch((e) => {
				callback && callback(e);
			});
	};
}

/**
 * Action for deleting a role.
 * @param {String} id — a role id
 */
export function deleteRole(id, callback) {
	return function (dispatch) {
		req
			.delete(`/permissions/acl/role/${id}`)
			.then(() => {
				dispatch(fetchRoles());
				dispatch({ type: USER_MANAGEMENT_RESET_CACHE });
				callback && callback();
			})
			.catch((e) => {
				callback && callback(e);
			});
	};
}

/**
 * Action for fetching resources for a company.
 */
export function fetchResources(id) {
	return function (dispatch) {
		req
			.post("/permissions/acl/resources", { id })
			.then((response) => {
				dispatch({
					type: USER_MANAGEMENT_FETCH_RESOURCES,
					payload: fromJS(response.data),
				});
			})
			.catch(() => {});
	};
}

/**
 * Action for allowing a resources for a role.
 */
export function allow(permission, resource, originalResource, role) {
	return function (dispatch) {
		req
			.post("/permissions/acl/allow", {
				role,
				permission,
				resource,
				originalResource,
			})
			.then(() => {
				dispatch(fetchRolePermissions(role));
				dispatch(fetchMenu("main")); // just for dev
			})
			.catch(() => {
				dispatch(fetchRolePermissions(role));
			});
	};
}

/**
 * Action to remove allow a resources for a role.
 */
export function removeAllow(permission, resource, role) {
	return function (dispatch) {
		req
			.post("/permissions/acl/removeAllow", {
				role: role,
				permission: permission,
				resource: resource,
			})
			.then(() => {
				dispatch(fetchRolePermissions(role));
				dispatch(fetchMenu("main")); // just for dev
			})
			.catch(() => {
				dispatch(fetchRolePermissions(role));
			});
	};
}

export function transferOwnership(userId, companyId, callback) {
	return function (dispatch) {
		req
			.post("/permissions/transfer-ownership", { userId })
			.then(() => {
				dispatch(fetchUsers(companyId));
				dispatch(fetchCompany(companyId));
				callback && callback();
			})
			.catch((e) => {
				callback && callback(e);
				console.log(e);
			});
	};
}

/**
 * Action for changing selected role in the access tab.
 */
export function changeSelectedRoleValue(role) {
	return function (dispatch) {
		dispatch({
			type: USER_MANAGEMENT_CHANGED_SELECTED_ROLE_VALUE,
			payload: role,
		});
		dispatch(fetchRolePermissions(role));
	};
}

export function fetchPermissionsBundles() {
	return function (dispatch) {
		req.get("/permissions/bundles").then((response) => {
			dispatch({
				type: USER_MANAGEMENT_CHANGED_PERMISSIONS_BUNDLES,
				payload: fromJS(response.data),
			});
		});
	};
}
export function updateCurrentRolePermissions(roleId, resourceId, bundleId) {
	return function (dispatch) {
		dispatch({ type: USER_MANAGEMENT_REQUEST_PENDING, payload: true });

		dispatch({
			type: USER_MANAGEMENT_FETCH_SELECTED_ROLE_PERMISSIONS_START,
			payload: fromJS({
				resourceId,
				bundleId,
			}),
		});

		req
			.put(`/permissions/roles/${roleId}/resources/${resourceId}/permissions`, {
				bundleId,
			})
			.then((response) => {
				// Band-aid fix before production release.
				// Solves the problem of switches living their own life when duing multiple request in short amount of time.
				// We might want to implement isDirty here in the future.

				dispatch({
					type: USER_MANAGEMENT_FETCH_SELECTED_ROLE_PERMISSIONS,
					payload: fromJS(response.data),
				});
				dispatch({ type: USER_MANAGEMENT_REQUEST_PENDING, payload: false });
			});
	};
}
