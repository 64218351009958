/**
 * Notify reducer
 * @module reducers/notify.reducer
 */

import {
	NOTIFY_FILE_ENTERED_GLOBAL,
	NOTIFY_RETURN_URL,
	NOTIFY_SOCKET_ID,
} from "../actions/types";

/** The initial state object for notify. */
const INITIAL_STATE = {
	fileEnteredWebsiteGlobal: false,
	returnUrl: null,
	socketId: null,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case NOTIFY_FILE_ENTERED_GLOBAL:
			return { ...state, fileEnteredWebsiteGlobal: action.payload };
		case NOTIFY_RETURN_URL:
			return { ...state, returnUrl: action.payload };
		case NOTIFY_SOCKET_ID:
			return { ...state, socketId: action.payload };
	}

	return state;
}
