import { useQuery } from "@tanstack/react-query";
import { useRouteMatch } from "react-router-dom";
import apiClient from "../modules/request.module.v2";
import { ROUTES } from "@/routes";

const reservedRoutes = Object.values(ROUTES).map((route) => route.substring(1));

export default () => {
	const routeMatch = useRouteMatch("/:companyId");
	const routeName = routeMatch?.params?.companyId;
	const urlAlias = !reservedRoutes.includes(routeName) ? routeName : null;

	const query = useQuery({
		enabled: !!urlAlias,
		staleTime: Infinity, // No need to refetch this data once we have the company ID
		queryKey: ["companyIdByAlias", urlAlias],
		queryFn: () => {
			return apiClient.get(`companies/${urlAlias}?alias=true`);
		},
	});

	return query?.data?.data?.id;
};
