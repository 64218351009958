import React, { useState, useRef, useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import styled from "@mui/styled-engine";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

import {
	PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
	PERSONAL_TRANSACTION_TYPE_LEVELING,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
	PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
	PERSONAL_TRANSACTION_TYPE_SPLIT,
	PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
	PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES,
	PERSONAL_TRANSACTION_TYPE_SNAPSHOT,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
	NUMBER_FORMAT_INTEGER,
	PERSONAL_TRANSACTION_TYPE_GENESIS,
} from "/shared/constants";
import { Form, FormSpy } from "react-final-form";
import { TextField, Select, DatePicker, Autocomplete } from "mui-rff";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { array, bool, func, object } from "prop-types";
import NumberFormatCustom from "../../../dumb-components/shared/format-number-input/number-format-custom";
import { useTranslation } from "react-i18next";
import MultiCondition from "../../shared/final-form/final-form-condition";
import { TRANSACTION_TYPE_MENU_ITEMS } from "../../../constants/add-transaction";
import { getMergedShareType } from "../../../components/helpers/shares";
import Tooltip from "../../../mui-components/tooltip/tooltip";
import IconButton from "../../../mui-components/button/icon-button";
import LinkTransactionGridContainer from "../../../containers/investments/my-records/link-transaction-grid.container";
import {
	setIsDirty,
	retainDirtyOnInitialize,
	resetModified,
} from "../../shared/final-form/mutators/link-transaction-mutator";
import { ShareClassWithTooltip } from "../../shared/final-form/share-class-tooltip";
import AddTransactionDialog from "./dialog/add-transaction-dialog";
import localeFormatNumber from "../../../modules/format-number";
import ScrollView from "../../shared/layout/scroll-view/scroll-view";
import ReadOnlyPreview from "./read-only-preview";
import { InvestmentsContext } from "../../../containers/investments/investment.container";
import { fromJS } from "immutable";
import { formatTransactionData } from "../../../components/helpers/my-records-helpers";
import { useFieldTranslation } from "./hooks/useFieldTranslation";

const StyledYellowBox = styled(Box)(({ theme, noGap }) => ({
	display: "flex",
	backgroundColor: theme.palette.highlight.yellow.main,
	padding: theme.spacing(4),
	gap: noGap ? 0 : theme.spacing(2),
	borderRadius: "8px",
}));

const StyledPrevValueContainer = styled(Grid)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "end",
	width: "100%",
	height: "100%",
	background: theme.palette.highlight.yellow.dark,
	borderRadius: "4px",
	paddingRight: "14px",
}));

export default function AddTransactionModal({
	onSaveData,
	initialValues,
	onClose,
	isEditTransactionModalOpen,
	onDelete,
	onUpdate,
	getTooltipActiveState,
	shareTypeNameOptions,
	previousTransactionValues,
}) {
	const { selectedInvestment } = useContext(InvestmentsContext);
	const [transactionType, setTransactionType] = useState();
	const [isPreview, setIsPreview] = useState(false);
	const [linkedTransaction, setLinkedTransaction] = useState();
	const [transactionProps, setTransactionProps] = useState();

	const { t } = useTranslation();

	const transactionSetupRef = useRef();
	const sharesSetupRef = useRef();
	const transactionValuesRef = useRef();

	const { translateFieldLabel } = useFieldTranslation();

	const validate = (values) => {
		const errors = {};
		const isConversionOfShares =
			values.transactionType === PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES;
		const isSnapshot =
			values.transactionType === PERSONAL_TRANSACTION_TYPE_SNAPSHOT;

		if (!values.transactionType) {
			errors.transactionType = t(
				"investments.add_transaction_modal.validation.required.transaction_type",
			);
		}

		if (!values.date) {
			errors.date = t(
				"investments.add_transaction_modal.validation.required.date",
			);
		}

		if (values.date?.invalid) {
			errors.date = t(
				"investments.add_transaction_modal.validation.invalid.date",
			);
		}

		let shareTypes = [];

		if (isConversionOfShares) {
			const isObjectHasLabelFrom =
				typeof values.conversionOfSharesNameFrom === "object" &&
				values.conversionOfSharesNameFrom?.label;

			const hasNoNameFrom = isObjectHasLabelFrom
				? !values.conversionOfSharesNameFrom.label
				: !values.conversionOfSharesNameFrom;

			const isObjectHasLabelTo =
				typeof values.conversionOfSharesNameTo === "object" &&
				values.conversionOfSharesNameTo?.label;

			const hasNoNameTo = isObjectHasLabelTo
				? !values.conversionOfSharesNameTo.label
				: !values.conversionOfSharesNameTo;

			if (hasNoNameFrom) {
				errors.conversionOfSharesNameFrom = t(
					"investments.add_transaction_modal.validation.conversion_of_shares_name_from.required",
				);
			}

			if (hasNoNameTo) {
				errors.conversionOfSharesNameTo = t(
					"investments.add_transaction_modal.validation.conversion_of_shares_name_to.required",
				);
			}

			const shareTypeNameAndClassFrom =
				!hasNoNameFrom &&
				getMergedShareType(
					values.conversionOfSharesNameFrom,
					values.conversionOfSharesClassFrom,
				);
			const shareTypeNameAndClassTo =
				!hasNoNameTo &&
				getMergedShareType(
					values.conversionOfSharesNameTo,
					values.conversionOfSharesClassTo,
				);

			if (
				!hasNoNameFrom &&
				!hasNoNameTo &&
				shareTypeNameAndClassFrom === shareTypeNameAndClassTo
			) {
				errors.conversionOfSharesNameFrom = t(
					"investments.add_transaction_modal.validation.conversion_of_shares.duplicates",
				);
				errors.conversionOfSharesClassFrom = t(
					"investments.add_transaction_modal.validation.conversion_of_shares.duplicates",
				);
				errors.conversionOfSharesNameTo = t(
					"investments.add_transaction_modal.validation.conversion_of_shares.duplicates",
				);
				errors.conversionOfSharesClassTo = t(
					"investments.add_transaction_modal.validation.conversion_of_shares.duplicates",
				);
			}
		}

		if (!isConversionOfShares && !isSnapshot) {
			values.shareTypes.forEach((shareType, index) => {
				if (shareType === undefined) {
					return;
				}
				const isObjectHasLabel =
					typeof shareType.shareTypeName === "object" &&
					shareType.shareTypeName?.label;

				const hasNoName = isObjectHasLabel
					? !isObjectHasLabel
					: shareType && !shareType.shareTypeName;

				if (shareType === null || hasNoName) {
					shareTypes[index] = {
						shareTypeName: t(
							"investments.add_transaction_modal.validation.required.share_type_name",
						),
						errorType: "noShareTypeName",
					};

					return;
				}

				if (values.shareTypes.length > 1) {
					const uniqueValues = Array.from(
						new Set(
							values.shareTypes.map((v) => {
								if (v !== null && (v.shareTypeName || v.shareTypeName)) {
									return getMergedShareType(v.shareTypeName, v.shareTypeClass);
								}
							}),
						),
					);

					if (uniqueValues.length < values.shareTypes.length) {
						const shareTypeNameAndClass = getMergedShareType(
							shareType.shareTypeName,
							shareType.shareTypeClass,
						);

						if (
							values.shareTypes.find(
								(valueShareType, i) =>
									shareTypeNameAndClass ===
										getMergedShareType(
											valueShareType?.shareTypeName,
											valueShareType?.shareTypeClass,
										) && index !== i,
							)
						) {
							shareTypes[index] = {
								shareTypeName: t(
									"investments.add_transaction_modal.validation.error.share_type_name",
								),
								shareTypeClass: t(
									"investments.add_transaction_modal.validation.error.share_type_name",
								),
								errorType: "duplicates",
							};
						}
					}
				}
			});
		}

		if (shareTypes.length > 0) {
			errors.shareTypes = shareTypes;
		}

		return errors;
	};

	const setAreaFocus = (shouldFocus, errors) => {
		if (!shouldFocus) {
			return;
		}

		const fieldsInFirstBlock = ["transactionType", "date"];
		const fieldsInSecondBlock = [
			"conversionOfSharesNameFrom",
			"conversionOfSharesClassFrom",
			"conversionOfSharesNameTo",
			"conversionOfSharesClassTo",
			"shareTypes",
		];
		const errorKeys = Object.keys(errors);
		const focusFirstBlock = errorKeys.some((key) =>
			fieldsInFirstBlock.includes(key),
		);
		const focusSecondBlock = errorKeys.some((key) =>
			fieldsInSecondBlock.includes(key),
		);

		switch (true) {
			case focusFirstBlock:
				transactionSetupRef?.current?.focus();
				break;
			case focusSecondBlock:
				sharesSetupRef?.current?.focus();
				setTimeout(() => sharesSetupRef?.current?.blur());
				break;
		}
	};

	const previewTransactionCallback = (
		id,
		_transactionProps,
		_transactionType,
	) => {
		const getlinkedTransaction = selectedInvestment
			.get("transactions")
			.find((transaction) => {
				return transaction.get("id") === id;
			});

		if (!getlinkedTransaction) {
			return;
		}
		const selectedTransactionType = _transactionType;
		const userId = selectedInvestment.get("id");
		const transactionValues = formatTransactionData(
			selectedTransactionType,
			getlinkedTransaction,
			userId,
		);

		setLinkedTransaction(fromJS(transactionValues));
		setTransactionProps(_transactionProps);
		setIsPreview(true);
	};

	const onClosePreview = () => {
		setIsPreview(false);
	};

	return (
		<Form
			onSubmit={onSaveData}
			mutators={{
				...arrayMutators,
				setIsDirty,
				retainDirtyOnInitialize,
				resetModified,
			}}
			initialValues={initialValues}
			keepDirtyOnReinitialize={true}
			validate={validate}
		>
			{(formProps) => {
				const getTransactionTypeValue =
					formProps.form.getFieldState("transactionType")?.value;

				const errorsShareTypes = formProps.errors.shareTypes;
				const shareTypeErrorNoName =
					errorsShareTypes &&
					errorsShareTypes.find(
						(shareType) => shareType?.errorType === "noShareTypeName",
					)
						? true
						: false;

				return (
					<AddTransactionDialog
						isEditTransactionModalOpen={isEditTransactionModalOpen}
						onClose={onClose}
						onConfirm={formProps.handleSubmit}
						disabledConfirm={
							!formProps.values.transactionType ||
							!formProps.values.date ||
							formProps.values.date?.invalid ||
							(isEditTransactionModalOpen && !formProps.dirty)
						}
						onDelete={onDelete}
						onUpdate={onUpdate}
						tooltipActiveState={getTooltipActiveState(formProps)}
						isPreview={isPreview}
						onClosePreview={onClosePreview}
					>
						<FormSpy
							subscription={{ submitFailed: true, dirtySinceLastSubmit: true }}
							onChange={(...args) => setAreaFocus(...args, formProps.errors)}
						/>
						<ScrollView alwaysShow disabled={!isPreview}>
							<Box
								sx={{ display: "flex", flexDirection: "row", gap: 4, flex: 1 }}
							>
								<Box
									sx={{
										flex: 1,
										display: isPreview ? "flex" : "unset",
										flexDirection: isPreview ? "column" : "unset",
										maxWidth: isPreview ? "48.484%" : undefined,
									}}
								>
									<ScrollView alwaysShow disabled={isPreview}>
										<Typography variant={"body1"} sx={{ pb: 1 }}>
											{t(
												"investments.my_records.modal.add_transaction.title.my_transaction",
											)}
										</Typography>

										<Box
											sx={{
												display: "flex",
												gap: 2,
												flexDirection: "column",
												flex: isPreview ? 1 : "unset",
											}}
										>
											{/* First yellow background block */}

											<StyledYellowBox
												tabIndex={-1}
												ref={transactionSetupRef}
												sx={{ flexDirection: "column" }}
											>
												<FormControl fullWidth sx={{ flexGrow: 1 }}>
													<Select
														name="transactionType"
														label={translateFieldLabel("transactionType")}
														onChange={(selectProps) => {
															const targetValue = selectProps.target.value;
															formProps.form.setConfig(
																"keepDirtyOnReinitialize",
																false,
															);
															formProps.form.restart({
																shareTypes: [{}],
															});
															formProps.form.setConfig(
																"keepDirtyOnReinitialize",
																true,
															);
															formProps.form.change(
																"transactionType",
																targetValue,
															);
															setTransactionType(targetValue);
														}}
														disabled={isPreview}
													>
														{TRANSACTION_TYPE_MENU_ITEMS.sort((a, b) =>
															t(a.textTid) > t(b.textTid) ? 1 : -1,
														).map((transactionType) => (
															<MenuItem
																key={transactionType.value}
																value={transactionType.value}
															>
																{t(transactionType.textTid)}
															</MenuItem>
														))}
													</Select>
												</FormControl>

												{/*TRANSACTION START*/}
												<FormControl
													fullWidth
													sx={{ width: "50%", minWidth: "143px" }}
												>
													<DatePicker
														label={translateFieldLabel("date")}
														name="date"
													/>
												</FormControl>
											</StyledYellowBox>

											<MultiCondition
												condition={({ transactionType }) => transactionType}
											>
												{/* Second yellow background block */}

												<StyledYellowBox
													tabIndex={-1}
													sx={{ flexDirection: "column" }}
													ref={sharesSetupRef}
												>
													<Box>
														<Typography variant="h4">
															{t(
																"own_ownership_registration.yellow_box.title.values_this_transaction",
															)}
														</Typography>
														<Typography variant="body2">
															{t(
																"own_ownership_registration.yellow_box.body.values_this_transaction",
															)}
														</Typography>
													</Box>

													<MultiCondition
														condition={({ transactionType }) =>
															[
																PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
																PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
																PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
															].includes(transactionType)
														}
													>
														<Box display="flex" gap={2}>
															<FormControl fullWidth>
																<TextField
																	name={"transferFrom"}
																	label={translateFieldLabel(
																		"transferFrom",
																		getTransactionTypeValue,
																	)}
																/>
															</FormControl>
															<FormControl fullWidth>
																<TextField
																	name={"transferTo"}
																	label={translateFieldLabel(
																		"transferTo",
																		getTransactionTypeValue,
																	)}
																/>
															</FormControl>
														</Box>
													</MultiCondition>

													<MultiCondition
														condition={({ transactionType }) =>
															[
																PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES,
															].includes(transactionType)
														}
													>
														<Box display="flex" gap={2}>
															<FormControl fullWidth sx={{ flexGrow: 1 }}>
																<Autocomplete
																	name={"conversionOfSharesNameFrom"}
																	freeSolo
																	options={shareTypeNameOptions.map(
																		(option) => option,
																	)}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			name={"conversionOfSharesNameFrom"}
																			label={translateFieldLabel(
																				"conversionOfSharesNameFrom",
																				getTransactionTypeValue,
																			)}
																		/>
																	)}
																/>
															</FormControl>
															<FormControl fullWidth sx={{ width: "40%" }}>
																<Tooltip
																	title={t(
																		"investments.add_transaction_modal.textfield.share_class.tooltip.optional",
																	)}
																>
																	<ShareClassWithTooltip
																		name={"conversionOfSharesClassFrom"}
																		label={translateFieldLabel(
																			"conversionOfSharesClassFrom",
																			getTransactionTypeValue,
																		)}
																	/>
																</Tooltip>
															</FormControl>
														</Box>
														<Box display="flex" gap={2}>
															<FormControl fullWidth sx={{ flexGrow: 1 }}>
																<Autocomplete
																	name={"conversionOfSharesNameTo"}
																	freeSolo
																	options={shareTypeNameOptions.map(
																		(option) => option,
																	)}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			name={"conversionOfSharesNameTo"}
																			label={translateFieldLabel(
																				"conversionOfSharesNameTo",
																				getTransactionTypeValue,
																			)}
																		/>
																	)}
																/>
															</FormControl>
															<FormControl fullWidth sx={{ width: "40%" }}>
																<Tooltip
																	title={t(
																		"investments.add_transaction_modal.textfield.share_class.tooltip.optional",
																	)}
																>
																	<ShareClassWithTooltip
																		name={"conversionOfSharesClassTo"}
																		label={translateFieldLabel(
																			"conversionOfSharesClassTo",
																			getTransactionTypeValue,
																		)}
																	/>
																</Tooltip>
															</FormControl>
														</Box>
													</MultiCondition>

													<MultiCondition
														condition={({ transactionType }) =>
															[PERSONAL_TRANSACTION_TYPE_SPLIT].includes(
																transactionType,
															)
														}
													>
														<Box display="flex" gap={2}>
															<FormControl fullWidth>
																<TextField
																	name="splitQuotaFrom"
																	label={translateFieldLabel(
																		"splitQuotaFrom",
																		getTransactionTypeValue,
																	)}
																/>
															</FormControl>
															<FormControl fullWidth>
																<TextField
																	name="splitQuotaTo"
																	label={translateFieldLabel(
																		"splitQuotaTo",
																		getTransactionTypeValue,
																	)}
																/>
															</FormControl>
														</Box>
													</MultiCondition>

													<MultiCondition
														condition={({ transactionType }) =>
															[PERSONAL_TRANSACTION_TYPE_SNAPSHOT].includes(
																transactionType,
															)
														}
													>
														<FormControl fullWidth>
															<TextField
																name="capitalInvested"
																label={translateFieldLabel(
																	"capitalInvested",
																	getTransactionTypeValue,
																)}
																InputProps={{
																	inputComponent: NumberFormatCustom,
																	inputProps: {
																		floatFormat: true,
																	},
																}}
															/>
														</FormControl>
														<FormControl fullWidth sx={{ mb: -2 }}>
															<TextField
																name="numOfTotalShares"
																label={translateFieldLabel(
																	"numOfTotalShares",
																	getTransactionTypeValue,
																)}
																InputProps={{
																	inputComponent: NumberFormatCustom,
																}}
															/>
														</FormControl>
													</MultiCondition>

													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															gap: "16px",
														}}
													>
														<FieldArray name="shareTypes">
															{({ fields }) =>
																fields.map((fieldName, index) => {
																	return (
																		<div
																			key={fieldName}
																			style={{
																				marginTop: index === 1 ? 52 : 0,
																			}}
																		>
																			<Box display="flex" gap={2}>
																				<MultiCondition
																					condition={({ transactionType }) =>
																						[
																							PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
																							PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
																							PERSONAL_TRANSACTION_TYPE_LEVELING,
																							PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
																							PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
																							PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
																							PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
																							PERSONAL_TRANSACTION_TYPE_SPLIT,
																							PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
																							PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
																							PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
																							PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
																							PERSONAL_TRANSACTION_TYPE_GENESIS,
																						].includes(transactionType)
																					}
																				>
																					<FormControl fullWidth>
																						<Autocomplete
																							name={`${fieldName}.shareTypeName`}
																							freeSolo
																							options={shareTypeNameOptions.map(
																								(option) => option,
																							)}
																							renderInput={(params) => (
																								<TextField
																									{...params}
																									name={`${fieldName}.shareTypeName`}
																									label={translateFieldLabel(
																										"shareTypes.shareTypeName",
																										getTransactionTypeValue,
																									)}
																								/>
																							)}
																						/>
																					</FormControl>
																					<FormControl fullWidth>
																						<Tooltip
																							title={t(
																								"investments.add_transaction_modal.textfield.share_class.tooltip.optional",
																							)}
																						>
																							<ShareClassWithTooltip
																								name={`${fieldName}.shareTypeClass`}
																								label={translateFieldLabel(
																									"shareTypes.shareTypeClass",
																									getTransactionTypeValue,
																								)}
																							/>
																						</Tooltip>
																					</FormControl>
																				</MultiCondition>
																			</Box>

																			<Box
																				display="flex"
																				gap={2}
																				sx={{
																					mt:
																						transactionType === "snapshot"
																							? 0
																							: 2,
																				}}
																			>
																				<MultiCondition
																					condition={({ transactionType }) =>
																						[
																							PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
																							PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
																							PERSONAL_TRANSACTION_TYPE_LEVELING,
																							PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
																							PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
																							PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
																							PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
																							PERSONAL_TRANSACTION_TYPE_SPLIT,
																							PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
																							PERSONAL_TRANSACTION_TYPE_GENESIS,
																						].includes(transactionType)
																					}
																				>
																					<FormControl fullWidth>
																						<TextField
																							name={`${fieldName}.latestPrice`}
																							label={translateFieldLabel(
																								"shareTypes.latestPrice",
																								getTransactionTypeValue,
																							)}
																							InputProps={{
																								inputComponent:
																									NumberFormatCustom,
																								inputProps: {
																									floatFormat: true,
																								},
																							}}
																						/>
																					</FormControl>
																				</MultiCondition>

																				<MultiCondition
																					condition={({ transactionType }) =>
																						[
																							PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
																							PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
																							PERSONAL_TRANSACTION_TYPE_LEVELING,
																							PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
																							PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
																							PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
																							PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
																							PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
																							PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
																							PERSONAL_TRANSACTION_TYPE_GENESIS,
																						].includes(transactionType)
																					}
																				>
																					<FormControl fullWidth>
																						<TextField
																							name={`${fieldName}.votesPerShare`}
																							label={translateFieldLabel(
																								"shareTypes.votesPerShare",
																								getTransactionTypeValue,
																							)}
																							InputProps={{
																								inputComponent:
																									NumberFormatCustom,
																							}}
																						/>
																					</FormControl>
																				</MultiCondition>
																			</Box>

																			<MultiCondition
																				condition={({ transactionType }) =>
																					[
																						PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
																						PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
																						PERSONAL_TRANSACTION_TYPE_LEVELING,
																						PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
																						PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
																						PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
																						PERSONAL_TRANSACTION_TYPE_GENESIS,
																					].includes(transactionType)
																				}
																			>
																				<Box
																					display="flex"
																					gap={2}
																					sx={{ mt: 2 }}
																				>
																					<FormControl fullWidth>
																						<TextField
																							name={`${fieldName}.totalSharesEmitted`}
																							label={translateFieldLabel(
																								"shareTypes.totalSharesEmitted",
																								getTransactionTypeValue,
																							)}
																							InputProps={{
																								inputComponent:
																									NumberFormatCustom,
																							}}
																						/>
																					</FormControl>
																				</Box>
																			</MultiCondition>
																			{/*TRANSACTION END*/}

																			{/*MY PURCHASE START*/}
																			<MultiCondition
																				condition={({ transactionType }) =>
																					[
																						PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
																						PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
																						PERSONAL_TRANSACTION_TYPE_LEVELING,
																						PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
																						PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
																						PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
																						PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
																						PERSONAL_TRANSACTION_TYPE_SPLIT,
																						PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
																						PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
																						PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES,
																						PERSONAL_TRANSACTION_TYPE_GENESIS,
																					].includes(transactionType)
																				}
																			>
																				<FormControl fullWidth>
																					<TextField
																						sx={{
																							mt:
																								transactionType ===
																								PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES
																									? -2
																									: transactionType ===
																									  PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL
																									? 0
																									: 2,
																						}}
																						name={`${fieldName}.myShares`}
																						label={translateFieldLabel(
																							"shareTypes.myShares",
																							getTransactionTypeValue,
																						)}
																						InputProps={{
																							inputComponent:
																								NumberFormatCustom,
																						}}
																					/>
																				</FormControl>
																			</MultiCondition>
																			<MultiCondition
																				condition={({ transactionType }) =>
																					[
																						PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
																						PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
																						PERSONAL_TRANSACTION_TYPE_SPLIT,
																					].includes(transactionType)
																				}
																			>
																				<FormControl fullWidth>
																					<TextField
																						sx={{
																							mt: 2,
																						}}
																						name={`${fieldName}.purchasePrice`}
																						label={translateFieldLabel(
																							"shareTypes.purchasePrice",
																							getTransactionTypeValue,
																						)}
																						InputProps={{
																							inputComponent:
																								NumberFormatCustom,
																							inputProps: {
																								floatFormat: true,
																							},
																						}}
																					/>
																				</FormControl>
																			</MultiCondition>
																			{/*MY PURCHASE END*/}

																			{index > 0 && (
																				<Box
																					sx={{
																						display: "flex",
																						flexDirection: "row-reverse",
																						mt: 2,
																					}}
																				>
																					<IconButton
																						onClick={() => fields.remove(index)}
																						color="#6c6c6c"
																						hoverColor="#000000"
																						icon={"faTrashAlt"}
																						noBorder
																					/>
																				</Box>
																			)}
																		</div>
																	);
																})
															}
														</FieldArray>
													</Box>

													<MultiCondition
														condition={({ transactionType }) =>
															[
																PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
																PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
																PERSONAL_TRANSACTION_TYPE_LEVELING,
																PERSONAL_TRANSACTION_TYPE_SPLIT,
																PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
																PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
																PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
																PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
																PERSONAL_TRANSACTION_TYPE_GENESIS,
															].includes(transactionType)
														}
													>
														<Card
															variant="dark"
															sx={{
																mt:
																	transactionType === "transferOther" ? -2 : 0,
															}}
														>
															<CardContent variant="flex">
																<Box>
																	<Typography variant="body2">
																		<FormattedMessage
																			id={
																				"investments.add_transaction_modal.add_new_share_type"
																			}
																		/>
																	</Typography>
																</Box>
																<Box>
																	<Tooltip
																		title={
																			"investments.add_transaction_modal.add_share_type.tooltip"
																		}
																		hideTooltip={!shareTypeErrorNoName}
																	>
																		<IconButton
																			onClick={() => {
																				formProps.form.mutators.push(
																					"shareTypes",
																					{},
																				);
																				//const hasValueIsLinked = formProps.values?.link?.isLinked
																				//if (hasValueIsLinked) {
																				//	formProps.form.mutators.removeLinkMutator()
																				//}
																			}}
																			disabled={shareTypeErrorNoName}
																			icon={"faPlus"}
																			color={"text.secondary"}
																			delay
																		/>
																	</Tooltip>
																</Box>
															</CardContent>
														</Card>
													</MultiCondition>
												</StyledYellowBox>
											</MultiCondition>

											{/*COMPANY VALUE START*/}

											<MultiCondition
												condition={({ transactionType }) =>
													[
														PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
														PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
														PERSONAL_TRANSACTION_TYPE_LEVELING,
														PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
														PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
														PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
														PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
														PERSONAL_TRANSACTION_TYPE_SPLIT,
														PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
														PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
														PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
														PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES,
														PERSONAL_TRANSACTION_TYPE_SNAPSHOT,
														PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
														PERSONAL_TRANSACTION_TYPE_GENESIS,
													].includes(transactionType)
												}
											>
												{/* Third yellow background block */}

												<StyledYellowBox
													tabIndex={-1}
													ref={transactionValuesRef}
													sx={{ mt: isPreview ? "auto" : "unset" }}
												>
													<Grid container flex columns={2} spacing={2}>
														<Grid item xs={2}>
															<Typography variant="h4">
																{t(
																	"own_ownership_registration.yellow_box.title.values_after_this_transaction",
																)}
															</Typography>
															<Typography variant="body2">
																{t(
																	"own_ownership_registration.yellow_box.body.values_after_this_transaction",
																)}
															</Typography>
														</Grid>

														<Grid item xs={1}>
															<Typography variant="body2">
																<FormattedHTMLMessage id="own_ownership_registration.form.previous_value_title" />
															</Typography>
														</Grid>
														<Grid item xs={1}>
															<Typography variant="body2">
																<FormattedHTMLMessage id="own_ownership_registration.form.current_value_title" />
															</Typography>
														</Grid>

														<Grid item xs={1}>
															<StyledPrevValueContainer>
																<Typography>
																	{previousTransactionValues?.numOfTotalCompanyShares
																		? localeFormatNumber(
																				previousTransactionValues.numOfTotalCompanyShares,
																				NUMBER_FORMAT_INTEGER,
																		  )
																		: 0}
																</Typography>
															</StyledPrevValueContainer>
														</Grid>

														<Grid item xs={1}>
															<FormControl fullWidth>
																<TextField
																	name="numOfTotalCompanyShares"
																	label={translateFieldLabel(
																		"numOfTotalCompanyShares",
																		getTransactionTypeValue,
																	)}
																	InputProps={{
																		inputComponent: NumberFormatCustom,
																	}}
																/>
															</FormControl>
														</Grid>

														<Grid item xs={1}>
															<StyledPrevValueContainer>
																<Typography>
																	{previousTransactionValues?.totalVotes
																		? localeFormatNumber(
																				previousTransactionValues.totalVotes,
																				NUMBER_FORMAT_INTEGER,
																		  )
																		: 0}
																</Typography>
															</StyledPrevValueContainer>
														</Grid>

														<Grid item xs={1}>
															<FormControl fullWidth>
																<TextField
																	name="totalVotes"
																	label={translateFieldLabel(
																		"totalVotes",
																		getTransactionTypeValue,
																	)}
																	InputProps={{
																		inputComponent: NumberFormatCustom,
																	}}
																/>
															</FormControl>
														</Grid>

														<Grid item xs={1}>
															<StyledPrevValueContainer>
																<Typography>
																	{previousTransactionValues?.myTotalVotes
																		? localeFormatNumber(
																				previousTransactionValues.myTotalVotes,
																				NUMBER_FORMAT_INTEGER,
																		  )
																		: 0}
																</Typography>
															</StyledPrevValueContainer>
														</Grid>

														<Grid item xs={1}>
															<FormControl fullWidth>
																<TextField
																	name="myTotalVotes"
																	label={translateFieldLabel(
																		"myTotalVotes",
																		getTransactionTypeValue,
																	)}
																	InputProps={{
																		inputComponent: NumberFormatCustom,
																	}}
																/>
															</FormControl>
														</Grid>

														<Grid item xs={1}>
															<StyledPrevValueContainer>
																<Typography>
																	{previousTransactionValues?.companyTotalValue
																		? localeFormatNumber(
																				previousTransactionValues.companyTotalValue,
																				NUMBER_FORMAT_INTEGER,
																		  )
																		: 0}
																</Typography>
															</StyledPrevValueContainer>
														</Grid>

														<Grid item xs={1}>
															<FormControl fullWidth>
																<TextField
																	name="companyTotalValue"
																	label={translateFieldLabel(
																		"companyTotalValue",
																		getTransactionTypeValue,
																	)}
																	InputProps={{
																		inputComponent: NumberFormatCustom,
																		inputProps: {
																			floatFormat: true,
																		},
																	}}
																/>
															</FormControl>
														</Grid>

														<Grid item xs={1}>
															<StyledPrevValueContainer>
																<Typography>
																	{previousTransactionValues?.myTotalValue
																		? localeFormatNumber(
																				previousTransactionValues.myTotalValue,
																				NUMBER_FORMAT_INTEGER,
																		  )
																		: 0}
																</Typography>
															</StyledPrevValueContainer>
														</Grid>

														<Grid item xs={1}>
															<FormControl fullWidth>
																<TextField
																	name="myTotalValue"
																	label={translateFieldLabel(
																		"myTotalValue",
																		getTransactionTypeValue,
																	)}
																	InputProps={{
																		inputComponent: NumberFormatCustom,
																		inputProps: {
																			floatFormat: true,
																		},
																	}}
																/>
															</FormControl>
														</Grid>
													</Grid>
												</StyledYellowBox>
											</MultiCondition>
										</Box>
									</ScrollView>
								</Box>

								{!isPreview ? (
									<Box sx={{ flex: 1 }}>
										{!formProps.values.transactionType ? (
											<StyledYellowBox
												noGap
												sx={{ mt: "27.5px", flexDirection: "column" }}
											>
												<Typography variant="body2" gutterBottom>
													<FormattedHTMLMessage id="investments.my_records.modal.add_transaction.initial_info.paragraph1" />
												</Typography>
												<Typography variant="body2" gutterBottom>
													<FormattedHTMLMessage id="investments.my_records.modal.add_transaction.initial_info.paragraph2" />
												</Typography>
												<Typography variant="body2" gutterBottom>
													<FormattedHTMLMessage id="investments.my_records.modal.add_transaction.initial_info.paragraph3" />
												</Typography>
												<Typography variant="body2" gutterBottom>
													<FormattedHTMLMessage id="investments.my_records.modal.add_transaction.initial_info.paragraph4" />
												</Typography>
												<Typography variant="body2">
													<FormattedHTMLMessage id="investments.my_records.modal.add_transaction.initial_info.paragraph5" />
												</Typography>
											</StyledYellowBox>
										) : (
											<>
												<Typography variant={"body1"} sx={{ pb: 1 }}>
													{t(
														"investments.my_records.modal.add_transaction.title.company_transactions",
													)}
												</Typography>
												<LinkTransactionGridContainer
													previewTransactionCallback={
														previewTransactionCallback
													}
												/>
											</>
										)}
									</Box>
								) : (
									<>
										{formProps.values.transactionType && (
											<Box
												sx={{
													flex: 1,
													display: "flex",
													flexDirection: "column",
												}}
											>
												<Typography variant={"body1"} sx={{ pb: 1 }}>
													{t(
														"investments.my_records.modal.add_transaction.title.preview",
													)}
												</Typography>

												<ReadOnlyPreview
													transactionType={formProps.values.transactionType}
													fieldData={linkedTransaction}
													transactionProps={transactionProps}
												/>
											</Box>
										)}
									</>
								)}
							</Box>
						</ScrollView>
					</AddTransactionDialog>
				);
			}}
		</Form>
	);
}

AddTransactionModal.propTypes = {
	onSaveData: func,
	initialValues: object,
	onClose: func,
	isEditTransactionModalOpen: bool,
	onDelete: func,
	onUpdate: func,
	getTooltipActiveState: func,
	footerSaveBtnTooltipStates: object,
	shareTypeNameOptions: array,
	previousTransactionValues: object,
};

AddTransactionModal.defaultProps = {};
