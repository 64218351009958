import React, { Component } from "react";
import { connect } from "react-redux";
import { List, Map } from "immutable";
import { listAllMeetingsByCompanies } from "../../actions/meetings.actions";
import { MEETINGS_STATUS_DRAFT } from "../../constants/meetings";
import MeetingListByCompanies from "../../dumb-components/meetings/meeting-list-by-companies/meeting-list-by-companies";
import { fetchSimpleUsers } from "../../actions/usersCache.actions";
import Moment from "../../modules/moment.module";

import {
	LIVE_MEETINGS_CREATE,
	LIVE_MEETINGS_DELETE,
} from "../../constants/live-update";
import { EVENT_TYPE_MEETINGS_UPDATE } from "/shared/constants";

class MeetingListByCompaniesContainer extends Component {
	state = {
		meetingTypes: List(),
		selectedFilter: "show_all",
	};

	componentDidMount() {
		const { listAllMeetingsByCompanies } = this.props;

		listAllMeetingsByCompanies();
	}

	componentDidUpdate(prevProps) {
		const { meetings, fetchSimpleUsers, user, listAllMeetingsByCompanies } =
			this.props;

		if (user !== prevProps.user) {
			listAllMeetingsByCompanies();
		}
		if (meetings && prevProps.meetings !== meetings) {
			let secretariesIds = List();

			meetings.forEach((meeting) => {
				const secretaryId = meeting.get("secretary");
				if (secretaryId) {
					secretariesIds = secretariesIds.push(secretaryId);
				}
			});

			fetchSimpleUsers(secretariesIds);
		}

		this.checkLiveUpdateEvents();
	}

	checkLiveUpdateEvents = () => {
		const { audit, listAllMeetingsByCompanies } = this.props;
		const meetingCreate = audit.get(LIVE_MEETINGS_CREATE, Map());
		const meetingUpdate = audit.get(EVENT_TYPE_MEETINGS_UPDATE, Map());
		const meetingDelete = audit.get(LIVE_MEETINGS_DELETE, Map());

		if (
			meetingCreate.get("refresh") === true ||
			meetingUpdate.get("refresh") === true ||
			meetingDelete.get("refresh") === true
		) {
			listAllMeetingsByCompanies();
		}
	};

	meetingIsDraft = (meeting) => {
		return meeting.get("status") === MEETINGS_STATUS_DRAFT;
	};

	meetingIsFinished = (meeting) => {
		const MEETING_FINISHED_STATUSES = [
			"MEETING-FINISHED",
			"PROTOCOL-NEEDS-FEEDBACK",
			"PROTOCOL-NEEDS-SIGNING",
			"PROTOCOL-PUBLISHED",
		];

		if (MEETING_FINISHED_STATUSES.includes(meeting.get("status"))) {
			return true;
		}

		return false;
	};

	getMeetings = () => {
		const { meetings, userId } = this.props;
		const { selectedFilter } = this.state;

		if (!userId || !meetings || meetings.size === 0) {
			return meetings;
		}

		const companyMeetings =
			meetings &&
			meetings.filter((meeting) => {
				if (selectedFilter === "draft") {
					return this.meetingIsDraft(meeting);
				}

				const today = Moment();
				const startDate = Moment(meeting.get("startDate"));
				const hasDate = meeting.get("startDate");
				const meetingOngoing =
					meeting.get("status") === "MEETING-STARTED" &&
					hasDate &&
					startDate.isSame(today, "day");

				if (meetingOngoing) {
					return true;
				}

				if (selectedFilter === "today") {
					return (
						Moment(meeting.get("startDate")).isSame(today, "day") && hasDate
					);
				} else if (selectedFilter === "7d") {
					return (
						startDate.isBetween(
							Moment(today).subtract(1, "days"),
							Moment(today).add(7, "days"),
							"day",
						) && hasDate
					);
				} else if (selectedFilter === "30d") {
					return (
						startDate.isBetween(
							Moment(today).subtract(1, "days"),
							Moment(today).add(30, "days"),
							"day",
						) && hasDate
					);
				} else if (selectedFilter === "90d") {
					return (
						startDate.isBetween(
							Moment(today).subtract(1, "days"),
							Moment(today).add(90, "days"),
							"day",
						) && hasDate
					);
				} else if (selectedFilter === "show_all") {
					return startDate.isSameOrAfter(Moment(today), "day");
				}

				return (
					meeting.get("secretary") === userId && this.meetingIsDraft(meeting)
				);
			});

		return (
			companyMeetings &&
			companyMeetings.filter((meeting) => !this.meetingIsFinished(meeting))
		);
	};

	onFilter = (filter) => {
		this.setState({ selectedFilter: filter });
	};

	render() {
		const { usersCache, meetings, userId, companiesAccessRights } = this.props;
		const { selectedFilter } = this.state;
		const filteredMeetings = this.getMeetings();
		const isAdministration =
			meetings &&
			meetings.some(
				(meeting) =>
					meeting.get("chairman") === userId ||
					meeting.get("secretary") === userId,
			);

		return (
			<div className="col-md-12">
				<MeetingListByCompanies
					meetings={filteredMeetings}
					selectedFilter={selectedFilter}
					usersCache={usersCache}
					isAdministration={isAdministration}
					onFilter={this.onFilter}
					userId={userId}
					companiesAccessRights={companiesAccessRights}
				/>
			</div>
		);
	}
}

const mapStoreToProps = (store) => {
	return {
		meetings: store.meetings.get("listByCompanies"),
		i18n: store.i18n,
		usersCache: store.usersCache.get("usersCache", Map()),
		user: store.user.get("userObj"),
		userId: store.user.getIn(["userObj", "id"]),
		audit: store.audit.get("meetings"),
		companiesAccessRights: store.company.companiesAccessRights,
	};
};

const mapActionsToProps = {
	listAllMeetingsByCompanies,
	fetchSimpleUsers,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingListByCompaniesContainer);
