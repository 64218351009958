import React, { PureComponent } from "react";
import { bool, func, object } from "prop-types";
import styled from "styled-components";

import Modal from "../../shared/modal/modal";
import Button from "../../shared/button/button";
import Checkbox from "../../shared/checkbox/checkbox";
import Text from "../../shared/text/text";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding: ${(props) => props.theme.spacing[5]} 0;
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const CheckboxWrapper = styled.div`
	display: flex;
	align-items: center;

	> :first-child {
		margin-right: ${(props) => props.theme.spacing[3]};
	}
`;

export default class StartMeetingModal extends PureComponent {
	static propTypes = {
		dismissPermanently: bool,
		onChange: func,
		startMeetingComponent: object,
	};

	renderBody = () => {
		const { onChange, dismissPermanently } = this.props;

		return (
			<Wrapper>
				<InfoWrapper>
					<Text tid="meetings.smart.start_meeting_modal.text_1" tag="p" />
					<Text tid="meetings.smart.start_meeting_modal.text_2" tag="p" />
					<Text tid="meetings.smart.start_meeting_modal.text_3" tag="p" />
				</InfoWrapper>

				<CheckboxWrapper>
					<Checkbox
						fieldName="dismissPermanently"
						mode="modern-big"
						onChange={onChange}
						checked={dismissPermanently}
					/>
					<Text tid="meetings.smart.start_meeting_modal.dismiss.info" />
				</CheckboxWrapper>
			</Wrapper>
		);
	};

	renderFooter = () => {
		const { onCancel, startMeetingComponent } = this.props;

		return (
			<div>
				{startMeetingComponent && startMeetingComponent}
				<Button tid="generic.form.close" onClick={onCancel} />
			</div>
		);
	};

	render = () => {
		return (
			<Modal
				isOpen={true}
				hSize="md"
				vSize={50}
				title="meetings.smart.start_meeting_modal.title"
				scrollableContent={false}
				footerComponent={this.renderFooter()}
			>
				{this.renderBody()}
			</Modal>
		);
	};
}
