import { useState } from "react";
import { List, Map } from "immutable";
import { getShareTypeLabel } from "../../../../components/helpers/shares";
import localeFormatNumber from "../../../../modules/format-number";
import {
	NUMBER_FORMAT_CURRENCY,
	NUMBER_FORMAT_PERCENTAGE,
	NUMBER_FORMAT_INTEGER,
	NUMBER_FORMAT_QUANTITY,
	NUMBER_FORMAT_TIMES,
	PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
	PERSONAL_TRANSACTION_TYPE_LEVELING,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
	PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
	PERSONAL_TRANSACTION_TYPE_SPLIT,
	PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
	PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
	PERSONAL_TRANSACTION_TYPE_GENESIS,
} from "/shared/constants";
import {
	MY_TOTAL_SHARES_GRID_TOOLTIP,
	NUMBER_OF_TOTAL_COMPANY_SHARES_GRID_TOOLTIP,
	POST_MONEY_GRID_TOOLTIP,
	TOTAL_VOTES_TOOLTIP,
	MY_SHARES_TOOLTIP,
	INVESTED_AMOUNT_GRID_TOOLTIP,
} from "../../../../constants/grid-tooltip";
import Moment from "../../../../modules/moment.module";
import { portfolioFormatDecimals } from "../../../../components/helpers/portfolio-format-decimals.helper";
import { useTranslation } from "react-i18next";
import { formatOrgPretty } from "/shared/helpers/helpers";

export function useInvestmentsPortfolioRowData() {
	const groups = {
		ungroupedItems: ["open", "companyOrgNumber"],
		ownershipInCompany: [
			"myNumOfShares",
			"totalNumOfSharesInTheCompany",
			"mySharesPercentageOfCompanyTotal",
		],
		votesInCompany: [
			"myNumOfVotes",
			"totalNumOfCompanyVotes",
			"myVotesPercentageOfCompanyTotal",
		],
		totalValueMySharesGroup: [
			"myInvestedAmount",
			"totalValueMyShares",
			"valueDevelopmentMyShares",
			"valueDevelopmentPercentage",
		],
		myAveragePricePerShare: ["averagePaidPerShare"],
		totalCompanyValueGroup: ["totalCompanyValue"],
	};

	const tooltips = {
		totalNumOfSharesInTheCompany: NUMBER_OF_TOTAL_COMPANY_SHARES_GRID_TOOLTIP,
		myNumOfShares: MY_TOTAL_SHARES_GRID_TOOLTIP,
		totalCompanyValue: POST_MONEY_GRID_TOOLTIP,
		totalNumOfCompanyVotes: TOTAL_VOTES_TOOLTIP,
	};

	const { t } = useTranslation();
	const [rowData, setRows] = useState();

	const getRows = (data, isGridModalOpen) => {
		let getAllShareTypes = List();
		data.map((company) => {
			const shareTypes = company.getIn(["details", "types"], List());
			shareTypes.forEach((shareType) => {
				getAllShareTypes = getAllShareTypes.push(shareType.get("type"));
			});
		});

		let mappedShareTypes = List();

		getAllShareTypes.toSet().map((shareType) => {
			mappedShareTypes = mappedShareTypes.push(
				Map({
					title: getShareTypeLabel(shareType),
					shareTypeRow: "shareTypeRow",
				}),
			);
		});

		let mappedNumOfTotalCompanyShares = List();
		let mappedPostMoney = List();
		let mappedTotalVotes = List();
		let mappedMyTotalShares = List();

		const formattedRowData = data.map((company) => {
			const companyNameAsIndex = company.get("name")?.replaceAll(".", "");

			const companyInvestment = company.get("investment");
			const investorTypeOfOwner = companyInvestment
				? companyInvestment.get("investorTypeOfOwner")
				: company.getIn([
						"personalInvestment",
						"personalInvestmentType",
						"type",
				  ]);
			const isViaCompany = investorTypeOfOwner === "company";

			const details = company.get("details");

			const roundMyTotalShares = portfolioFormatDecimals(
				isViaCompany,
				details.get("numOfTotalShares"),
			);
			const roundOwnershipPercentage = portfolioFormatDecimals(
				isViaCompany,
				details.get("ownershipPercentage"),
				"percentage",
			);
			const roundMyTotalVotes = portfolioFormatDecimals(
				isViaCompany,
				details.get("myTotalVotes"),
			);
			const roundVotesPercentage = portfolioFormatDecimals(
				isViaCompany,
				details.get("votingRightPercentage"),
				"percentage",
			);
			const getValuePerformancePercentage = details.get(
				"valuePerformancePercentage",
			);

			if (isGridModalOpen) {
				details.get("types", List()).forEach((shareType) => {
					mappedShareTypes.find((item) => {
						if (
							item.get("title") === getShareTypeLabel(shareType.get("type"))
						) {
							const mappedItemIndex = mappedNumOfTotalCompanyShares.findIndex(
								(mappedItem) => {
									if (mappedItem.get("title") === item.get("title")) {
										return mappedItem;
									}
								},
							);

							if (mappedItemIndex !== -1) {
								mappedNumOfTotalCompanyShares =
									mappedNumOfTotalCompanyShares.setIn(
										[mappedItemIndex, companyNameAsIndex],
										shareType.get("totalShares"),
									);

								mappedPostMoney = mappedPostMoney.setIn(
									[mappedItemIndex, companyNameAsIndex],
									localeFormatNumber(
										shareType.get("latestPrice"),
										NUMBER_FORMAT_CURRENCY,
									),
								);

								mappedTotalVotes = mappedTotalVotes.setIn(
									[mappedItemIndex, companyNameAsIndex],
									localeFormatNumber(
										shareType.get("votesPerShare"),
										NUMBER_FORMAT_INTEGER,
									),
								);
								if (shareType.get("shares") > 0) {
									mappedMyTotalShares = mappedMyTotalShares.setIn(
										[mappedItemIndex, companyNameAsIndex],
										localeFormatNumber(
											shareType.get("shares"),
											NUMBER_FORMAT_INTEGER,
										),
									);
								}
							} else {
								let totalCompanySharesItem = item;
								totalCompanySharesItem = totalCompanySharesItem.set(
									"field",
									"totalNumOfSharesInTheCompany",
								);
								totalCompanySharesItem = totalCompanySharesItem.set(
									"table",
									t("investments.portfolio.grid.row_title.ownershipInCompany"),
								);
								totalCompanySharesItem = totalCompanySharesItem.set(
									companyNameAsIndex,
									shareType.get("totalShares"),
								);
								mappedNumOfTotalCompanyShares =
									mappedNumOfTotalCompanyShares.push(totalCompanySharesItem);

								let mappedPostMoneyItem = item;
								mappedPostMoneyItem = mappedPostMoneyItem.set(
									"field",
									"totalCompanyValue",
								);
								mappedPostMoneyItem = mappedPostMoneyItem.set(
									"table",
									t(
										"investments.portfolio.grid.row_title.totalCompanyValueGroup",
									),
								);
								mappedPostMoneyItem = mappedPostMoneyItem.set(
									companyNameAsIndex,
									localeFormatNumber(
										shareType.get("latestPrice"),
										NUMBER_FORMAT_CURRENCY,
									),
								);
								mappedPostMoney = mappedPostMoney.push(mappedPostMoneyItem);

								let mappedTotalVotesItem = item;
								mappedTotalVotesItem = mappedTotalVotesItem.set(
									"field",
									"totalNumOfCompanyVotes",
								);
								mappedTotalVotesItem = mappedTotalVotesItem.set(
									"table",
									t("investments.portfolio.grid.row_title.votesInCompany"),
								);
								mappedTotalVotesItem = mappedTotalVotesItem.set(
									companyNameAsIndex,
									localeFormatNumber(
										shareType.get("votesPerShare"),
										NUMBER_FORMAT_INTEGER,
									),
								);
								mappedTotalVotes = mappedTotalVotes.push(mappedTotalVotesItem);

								if (shareType.get("shares") > 0) {
									let mappedMyTotalSharesItem = item;
									mappedMyTotalSharesItem = mappedMyTotalSharesItem.set(
										"field",
										"myNumOfShares",
									);
									mappedMyTotalSharesItem = mappedMyTotalSharesItem.set(
										"table",
										t(
											"investments.portfolio.grid.row_title.ownershipInCompany",
										),
									);
									mappedMyTotalSharesItem = mappedMyTotalSharesItem.set(
										companyNameAsIndex,
										localeFormatNumber(
											shareType.get("shares"),
											NUMBER_FORMAT_INTEGER,
										),
									);
									mappedMyTotalShares = mappedMyTotalShares.push(
										mappedMyTotalSharesItem,
									);
								}
							}
						}
					});
				});
			}

			const rows = {
				open: {
					data: {
						link: company.get("link"),
					},
					suppressContextMenu: true,
				},
				companyOrgNumber: formatOrgPretty(
					company.getIn([
						"investment",
						"investedInCompanyInformation",
						"orgNumber",
					]) || company.getIn(["personalInvestment", "orgNumber"]),
				),
				companyName: companyNameAsIndex,
				myNumOfShares: localeFormatNumber(
					details.get("numOfTotalShares"),
					NUMBER_FORMAT_INTEGER,
					roundMyTotalShares && { round: roundMyTotalShares },
				),
				totalNumOfSharesInTheCompany: localeFormatNumber(
					details.get("numOfTotalCompanyShares"),
					NUMBER_FORMAT_INTEGER,
				),
				mySharesPercentageOfCompanyTotal: localeFormatNumber(
					details.get("ownershipPercentage"),
					NUMBER_FORMAT_PERCENTAGE,
					roundOwnershipPercentage && { round: roundOwnershipPercentage },
				),
				myNumOfVotes: localeFormatNumber(
					details.get("myTotalVotes"),
					NUMBER_FORMAT_INTEGER,
					roundMyTotalVotes && { round: roundMyTotalVotes },
				),
				totalNumOfCompanyVotes: localeFormatNumber(
					details.get("totalVotes"),
					NUMBER_FORMAT_INTEGER,
				),
				myVotesPercentageOfCompanyTotal: localeFormatNumber(
					details.get("votingRightPercentage"),
					NUMBER_FORMAT_PERCENTAGE,
					roundVotesPercentage && { round: roundVotesPercentage },
				),
				myInvestedAmount: localeFormatNumber(
					details.get("capitalInvested"),
					NUMBER_FORMAT_CURRENCY,
				),
				totalValueMyShares: localeFormatNumber(
					details.get("myPostMoney"),
					NUMBER_FORMAT_CURRENCY,
				),
				valueDevelopmentMyShares: localeFormatNumber(
					details.get("valuePerformance"),
					NUMBER_FORMAT_CURRENCY,
				),
				valueDevelopmentPercentage: getValuePerformancePercentage
					? localeFormatNumber(
							getValuePerformancePercentage,
							NUMBER_FORMAT_PERCENTAGE,
					  )
					: "0 %",
				averagePaidPerShare: localeFormatNumber(
					details.get("averagePaidPricePerShare"),
					NUMBER_FORMAT_CURRENCY,
				),
				//lastTransactionPrice: , this is new... Add this to group totalCompanyValueGroup
				totalCompanyValue: localeFormatNumber(
					details.get("postMoney"),
					NUMBER_FORMAT_CURRENCY,
				),
				details: details.get("types")?.toJS(),
			};

			return rows;
		});

		const rows = [];

		Object.entries(groups).forEach(([table, value]) => {
			value.forEach((field) => {
				let row;
				if (field === "open") {
					row = {
						field: "open",
						renderer: "investmentButton",
					};
				} else if (field === "companyOrgNumber") {
					row = {
						field: "orgNumber",
						title: t("investments.overview.row.organization_number"),
					};
				} else {
					row = {
						field,
						table: t(`investments.portfolio.grid.row_title.${table}`),
						title: t(`investments.portfolio.grid.row_title.${field}`),
					};
				}

				formattedRowData.forEach((portfolioData) => {
					const value = portfolioData[field];
					row[portfolioData.companyName] = value;

					if (tooltips[field]) {
						row.rowId = tooltips[field];
						const newObj = {
							[portfolioData.companyName]: portfolioData.details,
						};

						row.details = {
							...row.details,
							...newObj,
						};
					}
				});

				rows.push(row);
			});
		});

		if (isGridModalOpen) {
			let myTotalSharesIndex = 2;
			let numOfTotalCompanySharesIndex = 3;
			let totalVotesIndex = 6;
			let postMoneyIndex = 13;

			mappedPostMoney = mappedPostMoney.toJS();

			mappedPostMoney.forEach((item) => {
				rows.splice(postMoneyIndex, 0, item);
				postMoneyIndex += 1;
			});

			mappedTotalVotes = mappedTotalVotes.toJS();

			mappedTotalVotes.forEach((item) => {
				rows.splice(totalVotesIndex, 0, item);
				totalVotesIndex += 1;
			});

			mappedNumOfTotalCompanyShares = mappedNumOfTotalCompanyShares.toJS();

			mappedNumOfTotalCompanyShares.forEach((item) => {
				rows.splice(numOfTotalCompanySharesIndex, 0, item);
				numOfTotalCompanySharesIndex += 1;
			});

			mappedMyTotalShares = mappedMyTotalShares.toJS();

			mappedMyTotalShares.forEach((item) => {
				rows.splice(myTotalSharesIndex, 0, item);
				myTotalSharesIndex += 1;
			});
		}

		return rows;
	};

	const setRowData = (data, isGridModalOpen) => {
		if (data) {
			const rows = getRows(data, isGridModalOpen);
			setRows(rows);
		} else {
			setRows();
		}
	};

	return [rowData, setRowData];
}

export function useMyRecordsRowData() {
	const groups = {
		ungroupedItems: ["open"],
		general: ["transactionDate"],
		entireTransaction: [
			"numOfNewSharesTotal",
			"transactionAmountTotal",
			"numOfCompanySharesAfterTransaction",
			"companyValueTotal",
			"numberOfCompanyVotesAfterTransaction",
		],
		myTransaction: ["numberOfSharesMyShare", "transactionAmountMyShare"],
		myValue: [
			"numberOfSharesMineAccumulated",
			"transactionAmountAccumulated",
			"totalValueMyShares",
			"valueDevelopmentMyShares",
		],
		attachments: ["numberOfAttachments"],
	};

	const tooltips = {
		numberOfSharesMyShare: MY_SHARES_TOOLTIP,
		transactionAmountMyShare: INVESTED_AMOUNT_GRID_TOOLTIP,
	};

	const { t } = useTranslation();
	const [rowData, setRows] = useState();

	const getRows = (data) => {
		let shareTypeValues = Map();
		let getAllShareTypes = List();

		data.forEach((transaction) => {
			const shareTypes = transaction.getIn(["handlerData", "types"], List());
			shareTypes.forEach((shareType) => {
				const shareTypeNameAndClass = getShareTypeLabel(
					shareType.get("shareType"),
				);

				getAllShareTypes = getAllShareTypes.push(shareTypeNameAndClass);
			});
		});

		const mappedShareTypes = getAllShareTypes.toSet().map((shareType) => {
			return shareType;
		});

		const formattedRowData = data.map((transaction) => {
			const transactionTypeAndIndex = `${transaction.get(
				"transactionType",
			)}&${transaction.get("id")}`;

			mappedShareTypes.forEach((shareType) => {
				const handlerDataTypes = transaction.getIn(["handlerData", "types"]);
				const matchingShareType =
					handlerDataTypes &&
					handlerDataTypes.find((handlerShareType) => {
						const handlerDataShareType = handlerShareType.get("shareType");
						const shareTypeNameAndClass =
							getShareTypeLabel(handlerDataShareType);

						return shareType === shareTypeNameAndClass;
					});

				let price = "-";
				let votes = "-";
				let hasShareTypeValue = false;

				if (matchingShareType) {
					switch (transaction.get("transactionType")) {
						case PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES: {
							votes = localeFormatNumber(
								matchingShareType.get("votesPerShare"),
								NUMBER_FORMAT_INTEGER,
							);
							hasShareTypeValue = true;
							break;
						}
						case PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES:
						case PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL:
						case PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER: {
							hasShareTypeValue = true;
							break;
						}
						case PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE:
						case PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES:
						case PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE:
						case PERSONAL_TRANSACTION_TYPE_LEVELING:
						case PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS:
						case PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE:
						case PERSONAL_TRANSACTION_TYPE_GENESIS:
						case PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY:
						case PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL: {
							price = localeFormatNumber(
								matchingShareType.get("latestPrice"),
								NUMBER_FORMAT_CURRENCY,
							);
							votes = localeFormatNumber(
								matchingShareType.get("votesPerShare"),
								NUMBER_FORMAT_INTEGER,
							);
							hasShareTypeValue = true;
							break;
						}
						case PERSONAL_TRANSACTION_TYPE_SPLIT: {
							price = localeFormatNumber(
								matchingShareType.get("latestPrice"),
								NUMBER_FORMAT_CURRENCY,
							);
							hasShareTypeValue = true;
							break;
						}
						default:
							break;
					}
				}

				shareTypeValues = shareTypeValues.setIn(
					[shareType, transactionTypeAndIndex, "latestPrice"],
					price,
				);
				shareTypeValues = shareTypeValues.setIn(
					[shareType, transactionTypeAndIndex, "votesPerShare"],
					votes,
				);
				shareTypeValues = shareTypeValues.setIn(
					[shareType, transactionTypeAndIndex, "hasShareTypeValue"],
					hasShareTypeValue,
				);
			});

			let _numOfNewSharesTotal =
				transaction.getIn(["balance", "issuedSharesThisTransaction"]) &&
				localeFormatNumber(
					transaction.getIn(["balance", "issuedSharesThisTransaction"]),
					NUMBER_FORMAT_INTEGER,
				);
			let _transactionAmountTotal =
				transaction.getIn(["balance", "issuedValueThisTransaction"]) &&
				localeFormatNumber(
					transaction.getIn(["balance", "issuedValueThisTransaction"]),
					NUMBER_FORMAT_CURRENCY,
				);
			let _numberOfSharesMyShare =
				transaction.getIn(["balance", "numOfTotalSharesDiff"]) &&
				localeFormatNumber(
					transaction.getIn(["balance", "numOfTotalSharesDiff"]),
					NUMBER_FORMAT_INTEGER,
				);
			let _transactionAmountMyShare =
				transaction.getIn(["balance", "capitalInvestedDiff"]) &&
				localeFormatNumber(
					transaction.getIn(["balance", "capitalInvestedDiff"]),
					NUMBER_FORMAT_CURRENCY,
				);

			switch (transaction.get("transactionType")) {
				case PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES:
				case PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES:
				case PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER: {
					_numOfNewSharesTotal = "-";
					_transactionAmountTotal = "-";
					_numberOfSharesMyShare = "-";
					_transactionAmountMyShare = "-";
					break;
				}
				case PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL:
				case PERSONAL_TRANSACTION_TYPE_SPLIT:
				case PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY:
				case PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL: {
					_numOfNewSharesTotal = "-";
					_transactionAmountTotal = "-";
					break;
				}
				case PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE: {
					_transactionAmountMyShare = "-";
					break;
				}
				default:
					break;
			}

			const rows = {
				transactionType: transactionTypeAndIndex,
				open: {
					data: {
						transactionId: transaction.get("id"),
					},
					suppressContextMenu: true,
				},
				transactionDate: Moment(new Date(transaction.get("date"))).format(
					"YYYY-MM-DD",
				),
				numOfNewSharesTotal: _numOfNewSharesTotal,
				transactionAmountTotal: _transactionAmountTotal,
				numOfCompanySharesAfterTransaction: transaction.getIn([
					"shareData",
					"numOfTotalCompanyShares",
				])
					? localeFormatNumber(
							transaction.getIn(["shareData", "numOfTotalCompanyShares"]),
							NUMBER_FORMAT_INTEGER,
					  ) || "formValueNotFilled"
					: "formValueNotFilled",
				companyValueTotal: transaction.getIn(["shareData", "postMoney"])
					? localeFormatNumber(
							transaction.getIn(["shareData", "postMoney"]),
							NUMBER_FORMAT_CURRENCY,
					  ) || "formValueNotFilled"
					: "formValueNotFilled",
				numberOfCompanyVotesAfterTransaction: transaction.getIn([
					"shareData",
					"totalVotes",
				])
					? localeFormatNumber(
							transaction.getIn(["shareData", "totalVotes"]),
							NUMBER_FORMAT_INTEGER,
					  ) || "formValueNotFilled"
					: "formValueNotFilled",
				numberOfSharesMyShare: _numberOfSharesMyShare,
				transactionAmountMyShare: _transactionAmountMyShare,
				transactionAmountAccumulated: localeFormatNumber(
					transaction.getIn(["balance", "capitalInvested"]),
					NUMBER_FORMAT_CURRENCY,
				),
				numberOfSharesMineAccumulated: localeFormatNumber(
					transaction.getIn(["balance", "numOfTotalShares"]),
					NUMBER_FORMAT_INTEGER,
				),
				totalValueMyShares: transaction.getIn(["shareData", "myPostMoney"])
					? localeFormatNumber(
							transaction.getIn(["shareData", "myPostMoney"]),
							NUMBER_FORMAT_CURRENCY,
					  ) || "formValueNotFilled"
					: "formValueNotFilled",
				valueDevelopmentMyShares: localeFormatNumber(
					transaction.getIn(["balance", "valuePerformance"]),
					NUMBER_FORMAT_CURRENCY,
				),
				numberOfAttachments: {
					data: {
						transactionId: transaction.get("id"),
					},
				},
				details: transaction.getIn(["handlerData", "types"], List()).toJS(),
			};

			return rows;
		});

		const rows = [];

		Object.entries(groups).forEach(([table, value]) => {
			value.forEach((field) => {
				let row;
				if (field === "open") {
					row = {
						field: "open",
						renderer: "editTransactionBtn",
					};
				} else if (field === "transactionDate") {
					row = {
						title: t("investments.my_records.grid.row_title.transactionDate"),
						table: t("investments.my_records.grid.row_title.general"),
						transposedTitleRowTooltipTid:
							"investments.my_records.grid.row.title.tooltip.transactionDate",
						field,
						renderer: "renderDate",
					};
				} else if (field === "numberOfAttachments") {
					row = {
						field,
						title: t(
							"investments.my_records.grid.row_title.numberOfAttachments",
						),
						table: t("investments.my_records.grid.row_title.attachments"),
						renderer: "renderAttachments",
					};
				} else {
					row = {
						field,
						table: t(`investments.my_records.grid.row_title.${table}`),
						title: t(`investments.my_records.grid.row_title.${field}`),
						transposedTitleRowTooltipTid: `investments.my_records.grid.row.title.tooltip.${field}`,
						...(field === "numberOfSharesMyShare" && {
							bodyTid: t(
								`investments.my_records.grid.row.title.tooltip.${field}`,
							),
						}),
						...(field === "transactionAmountMyShare" && {
							bodyTid: t(
								"investments.my_records.cell_value.tooltip.body.invested_amount",
							),
						}),
					};
				}

				formattedRowData.forEach((portfolioData) => {
					const value = portfolioData[field];
					row[portfolioData.transactionType] = value;

					if (tooltips[field]) {
						row.rowId = tooltips[field];
						const newObj = {
							[portfolioData.transactionType]: portfolioData.details,
						};

						row.details = {
							...row.details,
							...newObj,
						};
					}
				});

				rows.push(row);
			});
		});

		mappedShareTypes.map((item) => {
			let shareTypeData = Map({
				table: t("investments.my_records.grid.row_title.general"),
				title: item,
				transposedTitleRowTooltipTid:
					"investments.my_records.grid.row.title.tooltip.share_type_name",
			});

			let latestPrice = Map({
				table: t("investments.my_records.grid.row_title.general"),
				title: t(
					"investments.my_records.left_side_column.title.price_per_share",
				),
				cellClass: "cellValueIndent",
				transposedTitleRowTooltipTid:
					"investments.my_records.grid.row.title.tooltip.price_per_share",
			});

			let votesPerShare = Map({
				table: t("investments.my_records.grid.row_title.general"),
				title: t("investments.my_records.left_side_column.title.voting_value"),
				cellClass: "cellValueIndent",
				transposedTitleRowTooltipTid:
					"investments.my_records.grid.row.title.tooltip.voting_value",
			});

			const shareTypes = shareTypeValues.get(item);

			shareTypes.forEach((item, key) => {
				latestPrice = latestPrice.set(key, item.get("latestPrice"));
				votesPerShare = votesPerShare.set(key, item.get("votesPerShare"));

				if (item.get("hasShareTypeValue")) {
					shareTypeData = shareTypeData.set(key, "renderTickCell");
				} else {
					shareTypeData = shareTypeData.set(key, "-");
				}
			});

			rows.splice(2, 0, shareTypeData.toJS());
			rows.splice(3, 0, latestPrice.toJS());
			rows.splice(4, 0, votesPerShare.toJS());
		});

		return rows;
	};

	const setRowData = (data) => {
		if (data) {
			const rows = getRows(data);
			setRows(rows);
		} else {
			setRows();
		}
	};

	return [rowData, setRowData];
}

export function useKeyFiguresRowData() {
	const groups = {
		overview: [
			"oms",
			"anst",
			"omsAnst",
			"lonAnst",
			"res",
			"avkast",
			"balOmsl",
			"egetKap",
			"soliditet",
			"kassalikv",
		],
		grundstruktur: {
			avkastning: ["avEgetKap", "skuldranta", "riskbuffert", "sysGrad"],
			resultat: ["rorelseMarg", "nettoMarg"],
			intakter_kostnader: ["netOms", "netOmsA", "perKostOms"],
			kapital: ["kapOmsH", "rorNetOms", "vinstMarg"],
		},
		tillaggsstruktur: {
			resultat: ["foradGrad", "ranTGrad", "forVarA", "rorResA", "netResA"],
			intakter_kostnader: ["perKFor", "perKostA", "avskNet", "finNetO"],
			kapital: [
				"imAnltO",
				"mAnltO",
				"mskInvO",
				"finAnltO",
				"lagPagO",
				"kundFO",
				"ovrKFO",
				"kaKoPO",
				"kortSkO",
				"egetKaO",
				"obesResO",
				"avsLangSO",
			],
		},
	};

	const { t } = useTranslation();
	const [rowData, setRows] = useState();

	const translateUnit = (unit) => {
		let displayedUnit = "";
		switch (unit) {
			case NUMBER_FORMAT_CURRENCY:
				displayedUnit = "TKR";
				break;
			case NUMBER_FORMAT_PERCENTAGE:
				displayedUnit = "%";
				break;
			case NUMBER_FORMAT_QUANTITY:
				displayedUnit = "st";
				break;
			case NUMBER_FORMAT_TIMES:
				displayedUnit = "ggr";
				break;
		}

		return displayedUnit;
	};

	const getRows = (data) => {
		const rows = [];

		Object.entries(groups).forEach(([table, value]) => {
			if (Array.isArray(value)) {
				value.forEach((field) => {
					const displayedUnit = translateUnit(data[0][field].unit);

					const row = {
						field,
						unit: data[0][field].unit,
						table: t(`investments.general.key_figures_grid.titles.${table}`),
						title: t(`investments.general.key_figures_grid.titles.${field}`, {
							unit: displayedUnit,
						}),
					};

					data.forEach((nyckeltal) => {
						const value = nyckeltal[field];
						row[nyckeltal.period_to] = value;
					});

					rows.push(row);
				});
			} else {
				Object.entries(value).forEach(([group, value]) => {
					value.forEach((field) => {
						const displayedUnit = translateUnit(data[0][field].unit);

						const row = {
							field,
							unit: data[0][field].unit,
							table: t(`investments.general.key_figures_grid.titles.${table}`),
							group: t(`investments.general.key_figures_grid.titles.${group}`),
							title: t(`investments.general.key_figures_grid.titles.${field}`, {
								unit: displayedUnit,
							}),
						};

						data.forEach((nyckeltal) => {
							const value = nyckeltal[field];
							row[nyckeltal.period_to] = value;
						});

						rows.push(row);
					});
				});
			}
		});

		return rows;
	};

	const setRowData = (data) => {
		if (data) {
			const rows = getRows(data);
			setRows(rows);
		} else {
			setRows();
		}
	};

	return [rowData, setRowData];
}
