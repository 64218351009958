import { List } from "immutable";

export const getIsDismissed = (userObj, ALERT_ID) => {
	return (
		!userObj ||
		userObj
			.getIn(["siteSettings", "dismissedOnboardingAlerts"], List())
			.some((aId) => aId === ALERT_ID)
	);
};
