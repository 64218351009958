import React from "react";
import { connect } from "react-redux";
import { string } from "prop-types";
import { List, Map } from "immutable";
import { closeModal, openModal } from "../../../actions/modals.actions";
import {
	MEETING_TEMPLATES_IMPORTANT_INFORMATION_MODAL,
	MEETING_TEMPLATES_MODAL,
	PROTOCOL_PDF_OPTIONS_MODAL,
} from "../../../constants/modals";
import { deleteMeetingsPerminantly } from "../../../actions/meetings.actions";
import { downloadGroupMatrix } from "../../../actions/groups.actions";

import MeetingTemplatesModalContainer from "../meeting-templates/meeting-templates-modal.container";
import IconDrawer from "../../../dumb-components/shared/icon-drawer/icon-drawer";
import ProtocolPdfOptionsModal from "../protocol-pdf-options/protocol-pdf-options-modal.container";
import ConfirmContainer from "../../shared/confirm.container";
import history from "../../../interfaces/history";
import TemplateInformationModal from "../../../dumb-components/meetings/modals/template-information-modal";
import useSubscriptionHelper from "../../../hooks/useSubscriptionHelper";

const TEMPLATE_TOOLTIP_STATES = {
	default: {
		tid: "meetings.templates.tooltip.manage_meeting_templates",
	},
	noSubscription: {
		tid: "meetings.templates.tooltip.manage_meeting_templates.no_sub",
		delayShow: "short",
	},
};

const PROTOCOL_TOOLTIP_STATES = {
	default: {
		tid: "meetings.protocol_pdf_options.tooltip.manage_protocol_pdf_options",
	},
	noSubscription: {
		tid: "meetings.protocol_pdf_options.tooltip.manage_protocol_pdf_options.no_sub",
		delayShow: "short",
	},
};

const DELETE_MEETINGS_TOOLTIP_STATES = {
	default: {
		tid: "meetings.perminantly_delete_meetings.tooltip.default",
	},
	disabled: {
		tid: "meetings.perminantly_delete_meetings.tooltip.disabled",
		delayShow: "instant",
	},
};

const DOWNLOAD_GROUP_MATRIX_TOOLTIP_STATES = {
	default: {
		tid: "meetings.download_group_structure.tooltip.default",
	},
};

const CONFIRM_PERMINANTLY_DELETE_MEETINGS_MODAL =
	"CONFIRM_PERMINANTLY_DELETE_MEETINGS_MODAL";

const MeetingDrawerContainer = ({
	basePath,
	downloadGroupMatrix,
	openModal,
	closeModal,
	activeModal,
	confirmDeleteMeetingsModalOpen,
	meetingId,
	groupId,
}) => {
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	const subHasSmartMeetings = subscriptionHelper?.smartMeetingsEnabled;

	const getDrawerObject = () => {
		const templateActiveState = !subHasSmartMeetings && "noSubscription";
		const protocolActiveState = !subHasSmartMeetings && "noSubscription";
		const deleteMeetingsActiveState = "default";

		return List([
			Map({
				icon: "faCog",
			}),
			Map({
				icon: "faListAlt",
				disabled: !subHasSmartMeetings,
				tooltipStates: TEMPLATE_TOOLTIP_STATES,
				tooltipActiveState: templateActiveState,
				onClick: () => onModalOpen(MEETING_TEMPLATES_MODAL),
			}),
			Map({
				icon: "faFilePdf",
				disabled: !subHasSmartMeetings,
				tooltipStates: PROTOCOL_TOOLTIP_STATES,
				tooltipActiveState: protocolActiveState,
				onClick: () => onModalOpen(PROTOCOL_PDF_OPTIONS_MODAL),
			}),
			Map({
				icon: "faFileSpreadsheet",
				tooltipStates: DOWNLOAD_GROUP_MATRIX_TOOLTIP_STATES,
				onClick: () => downloadGroupMatrix(true),
			}),
			Map({
				icon: "faTrash",
				disabled: false,
				tooltipStates: DELETE_MEETINGS_TOOLTIP_STATES,
				tooltipActiveState: deleteMeetingsActiveState,
				onClick: () => onModalOpen(CONFIRM_PERMINANTLY_DELETE_MEETINGS_MODAL),
			}),
		]);
	};

	const onModalOpen = (modalName) => {
		openModal(modalName);
	};

	const onModalClose = (isMeetingTemplateModalAndFirstTemplate) => {
		if (isMeetingTemplateModalAndFirstTemplate === true) {
			openModal(MEETING_TEMPLATES_IMPORTANT_INFORMATION_MODAL);
		} else {
			closeModal();
		}
	};

	const redirectIfCurrentMeetingWasDeleted = (currentMeetingWasDeleted) => {
		if (!currentMeetingWasDeleted) {
			return null;
		}

		const includeGroup = groupId ? `?group=${groupId}` : "";

		history.push(`${basePath}/${includeGroup}`);
	};

	const doDeleteMeetings = () => {
		onModalClose();
		deleteMeetingsPerminantly(meetingId, redirectIfCurrentMeetingWasDeleted);
	};

	const renderTemplatesModal = () => {
		const modalIsOpen = activeModal
			? activeModal.get("name") === MEETING_TEMPLATES_MODAL
			: false;
		const openCreateView =
			modalIsOpen && activeModal
				? activeModal.getIn(["options", "openCreateView"], false)
				: false;

		return (
			<MeetingTemplatesModalContainer
				isOpen={modalIsOpen}
				onClose={onModalClose}
				onSave={onModalClose}
				openCreateView={openCreateView}
				closeModalWhenCreateViewIsCanceled={
					activeModal &&
					activeModal.getIn(
						["options", "closeModalWhenCreateViewIsCanceled"],
						false,
					)
				}
			/>
		);
	};

	const renderManagePdfModal = () => {
		return <ProtocolPdfOptionsModal onClose={onModalClose} />;
	};

	const renderConfirmDeleteMeetingsModal = () => {
		return (
			<ConfirmContainer
				title="meetings.perminantly_delete_meetings.confirm.title"
				question="meetings.perminantly_delete_meetings.confirm.question"
				onConfirm={doDeleteMeetings}
				onDecline={onModalClose}
				isOpen={confirmDeleteMeetingsModalOpen}
			/>
		);
	};

	const renderTemplateInformationModal = () => {
		const modalIsOpen = activeModal
			? activeModal.get("name") ===
			  MEETING_TEMPLATES_IMPORTANT_INFORMATION_MODAL
			: false;

		return (
			modalIsOpen && (
				<TemplateInformationModal
					primaryTid={"meetings.smart.template_information_modal.body"}
				/>
			)
		);
	};

	const drawer = getDrawerObject();

	return (
		<>
			<IconDrawer drawer={drawer} />

			{renderTemplatesModal()}
			{renderManagePdfModal()}
			{renderConfirmDeleteMeetingsModal()}
			{renderTemplateInformationModal()}
		</>
	);
};

MeetingDrawerContainer.propTypes = {
	basePath: string,
};

const mapStoreToProps = (store) => {
	return {
		history: history,
		activeModal: store.modals.get("activeModal"),
		confirmDeleteMeetingsModalOpen:
			store.modals.getIn(["activeModal", "name"]) ===
			CONFIRM_PERMINANTLY_DELETE_MEETINGS_MODAL,
		meetingId: store.meetings.getIn(["meeting", "id"]),
		groupId: store.groups.get("selectedGroupId"),
		companiesAccessRights: store.company.companiesAccessRights,
	};
};

const mapActionsToProps = {
	closeModal,
	openModal,
	deleteMeetingsPerminantly,
	downloadGroupMatrix,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingDrawerContainer);
