import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { getFullShareTypeLabel } from "../../../helpers/shares";
import Moment from "../../../../modules/moment.module";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import {
	NUMBER_FORMAT_CURRENCY,
	NUMBER_FORMAT_INTEGER,
} from "/shared/constants";

class SplitView extends Component {
	componentDidMount = () => {};

	renderShareType = (shareType, index) => {
		const { tmpTransaction } = this.props;
		const name = getFullShareTypeLabel(shareType.get("type"));
		const currentNumOfShares = shareType.get("numOfShares");

		let beforeValue;
		let afterValue;
		let newNumOfShares;
		let dividable = true;

		if (tmpTransaction) {
			beforeValue = tmpTransaction.getIn(["handlerData", "before"]);
			afterValue = tmpTransaction.getIn(["handlerData", "after"]);
		}

		if (beforeValue && afterValue && beforeValue !== afterValue) {
			dividable = currentNumOfShares % beforeValue === 0;

			newNumOfShares =
				((currentNumOfShares - (currentNumOfShares % beforeValue)) *
					afterValue) /
				beforeValue;
		}

		return (
			<div className="list__item" key={index}>
				<span className="list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left">
					{name}
				</span>
				<span className="list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right">
					{localeFormatNumber(currentNumOfShares, NUMBER_FORMAT_INTEGER)}
				</span>
				<span className="list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right">
					{newNumOfShares && (
						<span className={!dividable ? "text--error" : ""}>
							{newNumOfShares &&
								!isNaN(newNumOfShares) &&
								localeFormatNumber(newNumOfShares, NUMBER_FORMAT_INTEGER)}
						</span>
					)}
				</span>
			</div>
		);
	};

	render = () => {
		const { transaction, tmpTransaction } = this.props;
		if (!transaction) {
			return null;
		}

		const currentDate = transaction.get("date");
		const currentShareCapital = transaction.getIn([
			"shareData",
			"shareCapital",
		]);
		const currentQuota = transaction.getIn(["shareData", "quotaValue"]);
		const currentShareTypes = transaction.getIn(["shareData", "types"]);

		let newDate;
		let newShareCapital;
		let newQuota;
		let transactionBegan = false;

		if (tmpTransaction) {
			const beforeValue = tmpTransaction.getIn(["handlerData", "before"]);
			const afterValue = tmpTransaction.getIn(["handlerData", "after"]);

			if (beforeValue !== afterValue) {
				newDate = tmpTransaction.get("date");
				newShareCapital = currentShareCapital;
				newQuota = (currentQuota * beforeValue) / afterValue;
				transactionBegan = beforeValue && afterValue;
			}
		}

		return (
			<div className="i-panel i-panel--white">
				<div className="i-panel__body i-panel__body i-panel__body--pad-hor">
					<div className="list__list-header">
						<div className="list__list-header__text-area list__list-header__text-area--x3 list__list-header__text-area--pad-lft list__list-header__text-area--pad-right">
							&nbsp;
						</div>
						<div className="list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right">
							<span>{Moment(currentDate).format("L")}</span>
						</div>
						<div className="list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right">
							{newDate && Moment(newDate).format("L")}
						</div>
					</div>
					<div className="list list--table list--striped">
						<div className="list__item">
							<span className="list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left">
								<FormattedMessage id="share_capital" />
							</span>
							<span className="list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right">
								{currentShareCapital &&
									localeFormatNumber(
										currentShareCapital,
										NUMBER_FORMAT_CURRENCY,
									)}
							</span>
							<span className="list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right">
								{transactionBegan && newShareCapital && newShareCapital !== null
									? localeFormatNumber(newShareCapital, NUMBER_FORMAT_CURRENCY)
									: ""}
							</span>
						</div>
						{currentShareTypes && currentShareTypes.map(this.renderShareType)}
						<div className="list__item">
							<span className="list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left">
								<FormattedMessage id="quota_value" />
							</span>
							<span className="list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right">
								{currentQuota &&
									localeFormatNumber(currentQuota, NUMBER_FORMAT_CURRENCY)}
							</span>
							<span className="list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right">
								{transactionBegan && newQuota && !isNaN(newQuota)
									? localeFormatNumber(newQuota, NUMBER_FORMAT_CURRENCY)
									: ""}
							</span>
						</div>
					</div>
					<FormattedHTMLMessage id="shares.transactions.split.split_information" />
				</div>
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get("transaction"),
		tmpTransaction: state.transaction.getIn(["tmpTransaction", "SPLIT"]),
		i18n: state.i18n,
	};
}

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(SplitView);
