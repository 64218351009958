import React, { Component } from "react";
import { connect } from "react-redux";
import { fromJS, List, Map } from "immutable";
import debounce from "lodash/debounce";
import {
	updateCompanyProfile,
	updateCompanyLocal,
	companyProfileIsDirty,
} from "../../../actions/company.actions";

import Content from "../../../dumb-components/shared/layout/content/content";
import SplitView from "../../../dumb-components/shared/layout/split-view/split-view";
import ScrollView from "../../../dumb-components/shared/layout/scroll-view/scroll-view";

// Page specific components
import CompanyProfileHeader from "../../../dumb-components/company-profile/header";
import CompanyLogoContainer from "./company-logo.container";
import CompanyDetailsLeftContainer from "./company-details-left.container";
import CompanyDetailsRightContainer from "./company-details-right.container";

class CompanyProfileContainer extends Component {
	updateCompanyLocal = (company) => {
		const { updateCompanyLocal, companyProfileIsDirty } = this.props;
		updateCompanyLocal(company);
		companyProfileIsDirty();
		this.doDebounce(company);
	};

	doDebounce = debounce((company) => {
		const {
			updateCompanyProfile,
			company: { id },
		} = this.props;

		updateCompanyProfile(id, company);
	}, 1000);

	onChange = (field, value) => {
		let { company } = this.props;
		const path = field.split(".");

		// Convert to immutable for easier manipulation
		company = fromJS(company);

		// Check if company has correct addresses object
		if (path[0] === "addresses") {
			const addresses = company.get("addresses");
			if (!addresses || addresses.size === 0) {
				company = company.set("addresses", List([Map({ type: "padr" })]));
			}
		}

		// Set data
		company = company.setIn(path, value);

		// Convert to JS as we're not using immutable for company object
		company = company.toJS();

		this.updateCompanyLocal(company);
	};

	renderHeader = () => {
		return <CompanyProfileHeader linkCode={this.props.linkCode} />;
	};

	renderLeftComponent = () => {
		return (
			<ScrollView showOnHover autoHide>
				<CompanyLogoContainer type="main" />

				<CompanyLogoContainer
					type="thumbnail"
					title="company.profile.thumbnail"
					uploadDesc="upload.company.thumbnail.description"
					aspectRatio={1}
				/>

				<CompanyDetailsLeftContainer onChange={this.onChange} />
			</ScrollView>
		);
	};

	renderRightComponent = () => {
		return (
			<ScrollView showOnHover autoHide>
				<CompanyDetailsRightContainer onChange={this.onChange} />
			</ScrollView>
		);
	};

	render = () => {
		return (
			<Content header={this.renderHeader} tightenHeader>
				<SplitView
					leftComponent={this.renderLeftComponent}
					rightComponent={this.renderRightComponent}
				/>
			</Content>
		);
	};
}

const mapStoreToProps = (store) => {
	const { company } = store.company;

	return {
		company,
		linkCode: company && company.linkCode,
	};
};

const mapActionsToProps = {
	updateCompanyLocal,
	updateCompanyProfile,
	companyProfileIsDirty,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(CompanyProfileContainer);
