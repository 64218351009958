import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../interfaces/router";
import BreadcrumbsContainer from "./breadcrumbs.container";
import FolderDetailsContainer from "./folder-details.container";

class FoldersContainer extends Component {
	state = {
		showFolderDetails: false,
		folderDetailsMode: "create",
		folderId: null,
		parentFolderId: null,
	};

	onCreateFolder = (folderId) => {
		this.setState({
			showFolderDetails: true,
			folderDetailsMode: "create",
			parentFolderId: folderId,
		});
	};

	onManageFolders = (folderId) => {
		this.setState({
			showFolderDetails: true,
			folderDetailsMode: "manage",
			folderId: folderId,
		});
	};

	onModalClose = () => {
		this.setState({
			showFolderDetails: false,
			folderId: null,
		});
	};

	render = () => {
		const {
			basePath,
			match: { params },
		} = this.props;
		const { showFolderDetails, folderDetailsMode, folderId, parentFolderId } =
			this.state;

		return (
			<>
				<BreadcrumbsContainer
					basePath={basePath}
					params={params}
					onCreateFolder={this.onCreateFolder}
					onManageFolders={this.onManageFolders}
				/>
				<FolderDetailsContainer
					basePath={basePath}
					folderId={folderId}
					parentFolderId={parentFolderId}
					isOpen={showFolderDetails}
					mode={folderDetailsMode}
					onClose={this.onModalClose}
				/>
			</>
		);
	};
}

function mapStoreToProps(store) {
	return {
		selectedFolderId: store.folders.get("selectedFolderId"),
		currentFolder: store.folders.get("currentFolder"),
	};
}

const mapActionsToProps = {};

const FoldersContainerWithRouter = withRouter(
	connect(mapStoreToProps, mapActionsToProps)(FoldersContainer),
);
export default FoldersContainerWithRouter;
