import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import WhiteSection from "../../common/white-section";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import { marketingPermissionConsts } from "../marketing/marketing";
import subscriptionListSvg from "../../../../assets/images/emissions/subscription-list.svg";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import SubscriptionsGrid from "./subscriptions-grid";
import SubmissionModal from "./submission-modal";
import {
	getSubmissions,
	getSubmissionFormOptions,
	getEmissionStatistics,
} from "../../../../actions/emissions.actions";
import { useDispatch, useSelector } from "react-redux";
import { emissionConsts } from "../../../../constants/emissions";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import {
	NUMBER_FORMAT_CURRENCY,
	NUMBER_FORMAT_INTEGER,
} from "/shared/constants.json";
import { summarize } from "../../utils/calculations";
import StatCard from "../subscription-list/statcards";
import EmissionDiagram from "../../common/emission-diagram";
import { emissionIsReadOnly } from "../../utils/emission-status-helper";
import { SubscriptionModalAdminProvider } from "./subscription-modal-admin.provider";

const SubscriptionList = ({ emission }) => {
	const { t } = useTranslation();
	const [submissionModal, setSubmissionModal] = useState(null);
	const [loadingSubmissions, setLoadingSubmissions] = useState(true);
	const submissions = useSelector((state) => state.emissions.submissions);
	const dispatch = useDispatch();
	const {
		common: { newShareCountTypes },
		tabs: { subscriptionList },
	} = emissionConsts;
	const shareCountType = emission.newShareCountType;
	const totals = summarize(useSelector((state) => state.emissions.submissions));
	const price = useSelector((state) => state.emissions.current.pricePerShare);
	const preventChanges = emissionIsReadOnly(emission, subscriptionList);

	useEffect(() => {
		async function fetchSubmissions() {
			try {
				await dispatch(getSubmissions(emission.companyId, emission.id));
				await dispatch(
					getSubmissionFormOptions(emission.companyId, emission.id),
				);
				await dispatch(getEmissionStatistics(emission.companyId, emission.id));
			} finally {
				setLoadingSubmissions(false);
			}
		}

		fetchSubmissions();
	}, []);

	return (
		<Box>
			{submissionModal !== null && (
				<SubscriptionModalAdminProvider>
					<SubmissionModal
						open={true}
						onClose={() => setSubmissionModal(null)}
						initialInvitee={submissionModal.inviteeId || ""}
						readOnly={submissionModal.readOnly}
					/>
				</SubscriptionModalAdminProvider>
			)}

			<Grid container columnSpacing={2}>
				<Grid item xs={6}>
					<DiagramCard
						sx={{ height: "100%" }}
						label={t("emissions.subscriptions.achieved-amount")}
						emission={emission}
					/>
				</Grid>
				<Grid item xs={6}>
					{shareCountType !== newShareCountTypes.range && (
						<Grid container rowSpacing={1}>
							<Grid item xs={12}>
								<StatCard
									label={t("emissions.allocation.registered-interest")}
									mainValue={localeFormatNumber(
										totals.registeredInterest,
										NUMBER_FORMAT_INTEGER,
									)}
									secondaryValue={localeFormatNumber(
										totals.registeredInterest * price,
										NUMBER_FORMAT_CURRENCY,
									)}
									suffix={t("emissions.allocation.shares")}
								/>
							</Grid>
							<Grid item xs={12}>
								<StatCard
									label={t("emissions.allocation.volume-left-until-full")}
									mainValue={localeFormatNumber(
										emission.newShareCountMax - totals.registeredInterest,
										NUMBER_FORMAT_INTEGER,
									)}
									secondaryValue={localeFormatNumber(
										(emission.newShareCountMax - totals.registeredInterest) *
											price,
										NUMBER_FORMAT_CURRENCY,
									)}
									info
									suffix={t("emissions.allocation.shares")}
								/>
							</Grid>
						</Grid>
					)}
					{shareCountType === newShareCountTypes.range && (
						<Grid container rowSpacing={1}>
							<Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
								<Grid item xs={6}>
									<StatCard
										label={t("emissions.allocation.registered-interest")}
										mainValue={localeFormatNumber(
											totals.registeredInterest,
											NUMBER_FORMAT_INTEGER,
										)}
										secondaryValue={localeFormatNumber(
											totals.registeredInterest * price,
											NUMBER_FORMAT_CURRENCY,
										)}
										suffix={t("emissions.allocation.shares")}
									/>
								</Grid>
								<Divider flexItem orientation={"vertical"} variant={"middle"} />
								<Grid item xs={6}>
									<StatCard
										label={t("emissions.allocation.volume-left-until-full")}
										mainValue={localeFormatNumber(
											emission.newShareCountMax - totals.registeredInterest,
											NUMBER_FORMAT_INTEGER,
										)}
										secondaryValue={localeFormatNumber(
											(emission.newShareCountMax - totals.registeredInterest) *
												price,
											NUMBER_FORMAT_CURRENCY,
										)}
										info
										suffix={t("emissions.allocation.shares")}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<StatCard
									companyId={emission.companyId}
									emissionId={emission.id}
									sx={{ pb: 7.5 }}
									label={t("emissions.allocation.partialClosures")}
									showPartialClosureList
									mainValue={localeFormatNumber(
										emission.closures?.length ?? 0,
										NUMBER_FORMAT_INTEGER,
									)}
									desc={localeFormatNumber(
										totals.amountOfPartialClosures,
										NUMBER_FORMAT_INTEGER,
									)}
									suffix={t("emissions.allocation.quantity")}
								/>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
			{submissions.length === 0 && !loadingSubmissions && (
				<WhiteSection sx={{ mt: 4 }}>
					<Grid container>
						<Grid item xs={12} sx={{ height: "6rem" }}>
							<Box sx={{ display: "flex", justifyContent: "space-between" }}>
								<Typography variant={"h4"} sx={{ mb: 6 }}>
									{t("emissions.tabs.subscriptions")}
								</Typography>
								<Button
									disabled={preventChanges}
									onClick={() =>
										setSubmissionModal({ readOnly: false, inviteeId: "" })
									}
									variant={"contained"}
									sx={{ fontWeight: "bold", alignSelf: "center" }}
								>
									<Typography variant={"h5"}>
										{t("emissions.subscription-list.add-submission-manually")}
									</Typography>
								</Button>
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box sx={{ pl: 3 }}>
								<img src={subscriptionListSvg} style={{ height: "300px" }} />
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									height: "100%",
								}}
							>
								<Typography
									variant={"body2"}
									sx={{ alignSelf: "center", color: "grey.600" }}
								>
									{t("emissions.subscription-list.no-submissions-yet")}
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</WhiteSection>
			)}

			{submissions.length !== 0 && !loadingSubmissions && (
				<WhiteSection sx={{ mt: 4 }}>
					<Grid container>
						<Grid item xs={12} sx={{ height: "80rem" }}>
							<Box sx={{ display: "flex", justifyContent: "space-between" }}>
								<Typography variant={"h4"} sx={{ mb: 6 }}>
									{t("emissions.tabs.subscriptions")}
								</Typography>
								<Button
									disabled={preventChanges}
									onClick={() =>
										setSubmissionModal({ readOnly: false, inviteeId: "" })
									}
									variant={"contained"}
									sx={{ fontWeight: "bold", alignSelf: "center" }}
								>
									<Typography variant={"h5"}>
										{t("emissions.subscription-list.add-submission-manually")}
									</Typography>
								</Button>
							</Box>
							<Box sx={{ height: "100%" }}>
								<SubscriptionsGrid
									emission={emission}
									submissions={submissions}
									setAddSubmissionModalOpen={setSubmissionModal}
									preventChanges={preventChanges}
								/>
							</Box>
						</Grid>
					</Grid>
				</WhiteSection>
			)}
		</Box>
	);
};

const DiagramCard = (props) => {
	const { t } = useTranslation();
	const permissions = useSelector(
		(state) => state.emissions.current.marketingDiagramSettings?.permissions,
	);
	const statistics = useSelector((state) => state.emissions.statistics);
	const currentEmission = useSelector((state) => state.emissions.current);

	return (
		<WhiteSection
			sx={{
				px: 0,
				py: 0,
				...props.sx,
			}}
		>
			<Box
				sx={{
					display: "flex",
					borderBottom: 1,
					borderBottomColor: "divider",
					flexDirection: "column",
				}}
			>
				<Box sx={{ display: "flex", mt: "32px", ml: "40px" }}>
					<Typography
						sx={{
							textTransform: "uppercase",
							color: "secondary.500",
							pb: 1,
						}}
					>
						{props.label}
					</Typography>
				</Box>
				<EmissionDiagram
					prefix={t("emissions.subscriptions.generic-of")}
					suffix="SEK"
					statistics={statistics}
					currentEmission={currentEmission}
					{...props}
				/>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "center", mt: "20px" }}>
				<Typography variant="body2Italic" sx={{ color: "grey.500", mr: "4px" }}>
					{t("emissions.subscriptions.competence-diagram")}
				</Typography>
				{permissions?.map((p) => {
					return (
						<Box key={p}>
							{p === marketingPermissionConsts.shareHolders && (
								<Typography
									variant="body2Italic"
									sx={{ color: "grey.500", mr: "4px" }}
								>
									{t(
										"emissions.marketing-new-emission.permissions.share-holders",
									)}
									,
								</Typography>
							)}
							{p === marketingPermissionConsts.investors && (
								<Typography
									variant="body2Italic"
									sx={{ color: "grey.500", mr: "4px" }}
								>
									{t("emissions.invitations.own-investors")},
								</Typography>
							)}
							{p === marketingPermissionConsts.dealFlow && (
								<Typography
									variant="body2Italic"
									sx={{ color: "grey.500", mr: "4px" }}
								>
									{t("emissions.invitations.dealflow-investors")},
								</Typography>
							)}
						</Box>
					);
				})}
			</Box>
		</WhiteSection>
	);
};

export default SubscriptionList;
