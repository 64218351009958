/**
 * Profile
 * @module components/user/profile
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import debounce from "lodash/debounce";
import { Map } from "immutable";
import {
	saveUser,
	saveProfileImage,
	deleteProfileImage,
	updateUser,
	userProfileIsDirty,
	changeUserPassword,
	changeUserEmail,
	cancelAccount,
	verifyEmailCode,
} from "../../../../actions/user.actions";
import { resetOnboardingModals } from "../../../../actions/notifications.actions";
import { resetOnboardingAlerts } from "../../../../actions/user.actions";
import {
	addErrorNotification,
	addInfoNotification,
	clearNotifications,
} from "../../../../actions/notify.actions";

import Text from "../../../../dumb-components/shared/text/text";
import { removeUserFromCache } from "../../../../actions/usersCache.actions";
import Breadcrumbs from "../../../../dumb-components/shared/breadcrumbs/breadcrumbs";
import BreadcrumbsItem from "../../../../dumb-components/shared/breadcrumbs/breadcrumbs-item";
import ScrollView from "../../../../dumb-components/shared/layout/scroll-view/scroll-view";
import Box from "../../../../dumb-components/shared/layout/box/box";
import RequestSupportDropdownContainer from "../../../../containers/user-profile/request-support-dropdown.container";
import history from "../../../../interfaces/history";
import { routeHoc } from "../../../../interfaces/router";
import ProfileImageField from "./profileImage-field";
import InformationField from "./information-field";
import PasswordField from "./password-field";
import TerminateSubscriptionField from "./terminate-subscription-field";
import CompanyField from "./company-field";
import ContactDetailsField from "./contact-details-field";
import SettingsField from "./settings-field";
import EmailPanel from "./email-panel";

class Profile extends Component {
	state = {
		currentPassword: "",
		newPassword: "",
		passwordVerify: "",
		email: "",
		pendingEmailChange: false,
		emailChangeDone: false,
		errors: Map(),
		isConfirmOpen: false,
		code: "",
		resetOnboardingIsLoading: false,
	};

	componentDidMount = () => {
		const { user } = this.props;
		this.setEmail(user);
	};

	componentDidUpdate = (prevProps) => {
		if (
			(prevProps.user &&
				this.props.user &&
				prevProps.user.get("email") !== this.props.user.get("email")) ||
			(!prevProps.user && this.props.user)
		) {
			this.setEmail(this.props.user);
		}
	};

	onChange = (path, val) => {
		const user = this.props.user.setIn(path, val);
		this.debounce(user);
	};

	setEmail = (user) => {
		if (user) {
			this.setState({ email: user.get("email") });
		}
	};

	doDebounce = debounce(() => {
		const { saveUser, removeUserFromCache } = this.props;
		let { user } = this.props;

		if (user.get("companies")) {
			user = user.remove("companies");
		}

		saveUser("me", user.toJS());
		removeUserFromCache(user.get("id"));
	}, 1000);

	debounce = (user) => {
		const { userProfileIsDirty, updateUser } = this.props;
		userProfileIsDirty();
		updateUser(user);
		this.doDebounce();
	};

	resetOnboardingModals = () => {
		const {
			resetOnboardingModals,
			resetOnboardingAlerts,
			addInfoNotification,
		} = this.props;

		this.setState({ resetOnboardingIsLoading: true });

		resetOnboardingAlerts();
		resetOnboardingModals((e) => {
			if (!e) {
				addInfoNotification({
					tid: "users.profile.settings.onboarding.notify.success",
				});
			}

			this.setState({ resetOnboardingIsLoading: false });
		});
	};

	render = () => {
		const {
			user,
			saveProfileImage,
			deleteProfileImage,
			removeUserFromCache,
			addErrorNotification,
			addInfoNotification,
			clearNotifications,
			changeUserPassword,
			companies,
			history,
			cancelAccount,
			language,
			resetOnboardingModals,
			resetOnboardingIsLoading,
			resetOnboardingAlerts,
			messages,
		} = this.props;

		if (!user) {
			return null;
		}

		return (
			<div className="flex flex-col h-full p-md">
				<div className="i-content__header i-content__header--cr-specific">
					<div className="i-content__page-header">
						<Breadcrumbs>
							<BreadcrumbsItem
								itemName={<Text tid="user.profile.breadcrumbs.title" />}
								isStatic={true}
							/>
						</Breadcrumbs>
					</div>
					<div className="i-content__status-bar">
						<div className="status-bar status-bar--minimal-vert-margins">
							<div>
								<h4>{user.get("linkCode")}</h4>{" "}
								<em>
									<FormattedMessage id={"invono_id_description"} />
								</em>
							</div>
						</div>
					</div>
					<Box customMarginLeft={8} customMarginRight={8}>
						<RequestSupportDropdownContainer />
					</Box>
				</div>
				<div className="i-content__body">
					<ScrollView noLeftMargin={true} autoHide={true} showOnHover={true}>
						<ProfileImageField
							user={user}
							saveProfileImage={saveProfileImage}
							deleteProfileImage={deleteProfileImage}
							removeUserFromCache={removeUserFromCache}
						/>
						<InformationField user={user} onChange={this.onChange} />
						<EmailPanel user={user} />

						<PasswordField
							user={user}
							changeUserPassword={changeUserPassword}
							debounce={this.debounce}
							addErrorNotification={addErrorNotification}
							addInfoNotification={addInfoNotification}
							clearNotifications={clearNotifications}
						/>
						<TerminateSubscriptionField
							user={user}
							companies={companies}
							cancelAccount={cancelAccount}
							history={history}
						/>
					</ScrollView>
					<ScrollView autoHide={true} showOnHover={true}>
						<CompanyField user={user} companyEmail={user.get("companyEmail")} />
						<ContactDetailsField
							user={user}
							onChange={this.onChange}
							language={language}
						/>
						<SettingsField
							user={user}
							messages={messages}
							debounce={this.debounce}
							resetOnboardingModals={resetOnboardingModals}
							resetOnboardingIsLoading={resetOnboardingIsLoading}
							resetOnboardingAlerts={resetOnboardingAlerts}
						/>
					</ScrollView>
				</div>
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		history: history,
		errorMessage: state.user.get("error"),
		user: state.user.get("userObj"),
		companies: state.company.companies,
		messages: state.i18n.messages,
		language: state.i18n.language,
	};
}

const mapActionsToProps = {
	saveUser,
	updateUser,
	saveProfileImage,
	deleteProfileImage,
	userProfileIsDirty,
	changeUserPassword,
	addErrorNotification,
	addInfoNotification,
	clearNotifications,
	changeUserEmail,
	cancelAccount,
	removeUserFromCache,
	verifyEmailCode,
	resetOnboardingModals,
	resetOnboardingAlerts,
};

export default connect(mapStateToProps, mapActionsToProps)(routeHoc(Profile));
