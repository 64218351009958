import React from "react";
import Box from "@mui/material/Box";
import { DropDown } from "../../../../mui-components/dropdown/DropDown";
import IconButton from "../../../../mui-components/button/icon-button";
import { ColumnViewMenuItem } from "./menu-items/ColumnViewMenuItem";
import { FloatingFilterMenuItem } from "./menu-items/FloatingFilterMenuItem";
import { TeamsFilterMenuItem } from "./menu-items/TeamsFilterMenuItem";
import { usePeopleModalContext } from "../people-modal.context";
import {
	TAB_TEAMS_AND_SHAREHOLDES,
	TAB_MY_PERSONAL_CONTACTS,
} from "../../select-user-modal/select-user-modal.constants";
import NotificationBadge from "../../../../dumb-components/shared/notification-badge/notification-badge";
import Tooltip from "../../../../mui-components/tooltip/tooltip";
import { ShareholderMenuItem } from "./menu-items/ShareholderMenuItem";

export const HeaderMenu = () => {
	const {
		showColumnToggle,
		activeTab,
		filters,
		isFloatingFilterActive,
		filterByRoleDisabled,
		filterByShareholderDisabled,
	} = usePeopleModalContext();
	const isViewingMyPersonalContacts = activeTab === TAB_MY_PERSONAL_CONTACTS;

	const getNotificationBadgeProps = () => {
		const filterActive =
			typeof filters !== "undefined" &&
			Object.keys(filters).length > 0 &&
			!Object.values(filters).every((value) => value === null);

		if (activeTab === TAB_TEAMS_AND_SHAREHOLDES && filterActive) {
			return {
				active: true,
				animated: false,
				color: "green",
			};
		} else if (isFloatingFilterActive) {
			return {
				active: true,
				animated: false,
				color: "green",
			};
		} else {
			return {
				active: false,
			};
		}
	};

	return (
		<Box sx={{ display: "flex", alignItems: "center", marginRight: 6 }}>
			<NotificationBadge {...getNotificationBadgeProps()} x={18} y={6}>
				<DropDown
					button={({ params }) => (
						<IconButton size="sml" noBorder icon="faFilter" {...params} />
					)}
					alignMenu="right"
				>
					<FloatingFilterMenuItem />
					{!filterByRoleDisabled && <TeamsFilterMenuItem />}
					{!filterByShareholderDisabled && <ShareholderMenuItem />}
				</DropDown>
			</NotificationBadge>
			{showColumnToggle && (
				<DropDown
					disabled={isViewingMyPersonalContacts}
					button={({ params }) => (
						<Tooltip
							title="people_modal.view_menu.view_all_columns.tooltip.disabled_my_personal_contacts"
							hideTooltip={!isViewingMyPersonalContacts}
						>
							<IconButton
								size="sml"
								noBorder
								icon="faEllipsisV"
								disabled={isViewingMyPersonalContacts}
								{...params}
							/>
						</Tooltip>
					)}
					alignMenu="right"
				>
					<ColumnViewMenuItem />
				</DropDown>
			)}
		</Box>
	);
};
