import React, { PureComponent } from "react";
import styled, { css } from "styled-components";
import { func, string, oneOf } from "prop-types";
import Text from "../../../shared/text/text";

const StyledTable = styled.div`
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

const StyledTableHeader = styled.div`
	background-color: ${(props) =>
		props.mode === "light" ? "transparent" : props.theme.colors.blueLight};
	min-height: 45px;
	padding-top: ${(props) =>
		props.mode === "light" ? props.theme.spacing[3] : props.theme.spacing[4]};
	padding-right: ${(props) => props.theme.spacing[4]};
	padding-bottom: ${(props) =>
		props.mode === "light" ? props.theme.spacing[3] : props.theme.spacing[4]};
	padding-left: ${(props) => props.theme.spacing[4]};
	border-bottom: 1px solid #ddd;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const StyledTableHeaderLeft = styled.div`
	display: flex;
	flex: 1;
`;

const StyledTableHeaderRight = styled.div``;

const StyledTableBody = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;

	${(props) =>
		props.noHeader &&
		css`
			border-top: 1px solid #ddd;
		`}
`;

export default class ProtocolTable extends PureComponent {
	static propTypes = {
		headerLeftTid: string,
		headerLeftText: string,
		renderLeftHeader: func,
		headerRightTid: string,
		headerRightText: string,
		renderRightHeader: func,
		mode: oneOf(["light"]),
	};

	render = () => {
		const {
			headerLeftTid,
			headerLeftText,
			renderLeftHeader,
			headerRightTid,
			headerRightText,
			renderRightHeader,
			children,
			mode,
		} = this.props;
		const headerTextColor = mode === "light" ? "lightGrey" : "midGrey";
		const noHeader = !(
			headerLeftTid ||
			headerLeftText ||
			renderLeftHeader ||
			headerRightTid ||
			headerRightText ||
			renderRightHeader
		);

		return (
			<StyledTable>
				{(headerLeftTid ||
					headerLeftText ||
					renderLeftHeader ||
					headerRightTid ||
					headerRightText ||
					renderRightHeader) && (
					<StyledTableHeader mode={mode}>
						{(headerLeftTid || headerLeftText || renderLeftHeader) && (
							<StyledTableHeaderLeft>
								{headerLeftTid && (
									<Text
										tid={headerLeftTid}
										fontSize="18px"
										color={headerTextColor}
									/>
								)}
								{headerLeftText && (
									<Text fontSize="18px" color={headerTextColor}>
										{headerLeftText}
									</Text>
								)}
								{renderLeftHeader && renderLeftHeader()}
							</StyledTableHeaderLeft>
						)}
						{(headerRightTid || headerRightText || renderRightHeader) && (
							<StyledTableHeaderRight>
								{headerRightTid && (
									<Text
										tid={headerRightTid}
										fontSize="18px"
										color={headerTextColor}
									/>
								)}
								{headerRightText && (
									<Text fontSize="18px" color={headerTextColor}>
										{headerRightText}
									</Text>
								)}
								{renderRightHeader && renderRightHeader()}
							</StyledTableHeaderRight>
						)}
					</StyledTableHeader>
				)}

				<StyledTableBody noHeader={noHeader}>{children}</StyledTableBody>
			</StyledTable>
		);
	};
}
