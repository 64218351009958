import React, { PureComponent } from "react";
import memberNotificationIcon from "../../../../assets/images/onboarding/member-notification.png";
import folderIcon from "../../../../assets/images/onboarding/folder.png";

import LeftRightLayout, {
	Header,
	Entry,
} from "../sub-modules/left-right-layout";
import Button from "../../../shared/button/button";

/**
 * Props:
 * - onNext
 * - onDismiss
 */

export class FirstStepBody extends PureComponent {
	render = () => {
		return (
			<LeftRightLayout image={memberNotificationIcon}>
				<Header tid="onboarding.modals.documents.uploaded.1.header" />

				<Entry
					titleTid="onboarding.modals.documents.uploaded.1.subtitle.1"
					subTextTid="onboarding.modals.documents.uploaded.1.text.1"
				/>

				<Entry
					titleTid="onboarding.modals.documents.uploaded.1.subtitle.2"
					subTextTid="onboarding.modals.documents.uploaded.1.text.2"
				/>

				<Entry
					titleTid="onboarding.modals.documents.uploaded.1.subtitle.3"
					subTextTid="onboarding.modals.documents.uploaded.1.text.3"
					bottomComponent={
						<Button
							tid="onboarding.modals.documents.uploaded.1.button.next"
							onClick={this.props.onNext}
							mode="primary"
						/>
					}
				/>
			</LeftRightLayout>
		);
	};
}

export class SecondStepBody extends PureComponent {
	render = () => {
		const { onDismiss } = this.props;

		return (
			<LeftRightLayout image={folderIcon}>
				<Header tid="onboarding.modals.documents.uploaded.2.header" />

				<Entry
					titleTid="onboarding.modals.documents.uploaded.2.subtitle.1"
					subTextTid="onboarding.modals.documents.uploaded.2.text.1"
				/>

				<Entry
					titleTid="onboarding.modals.documents.uploaded.2.subtitle.2"
					subTextTid="onboarding.modals.documents.uploaded.2.text.2"
				/>

				<Entry
					titleTid="onboarding.modals.documents.uploaded.2.subtitle.3"
					subTextTid="onboarding.modals.documents.uploaded.2.text.3"
					bottomComponent={
						<Button
							tid="onboarding.modals.documents.uploaded.2.button.dismiss"
							onClick={onDismiss}
							mode="primary"
						/>
					}
				/>
			</LeftRightLayout>
		);
	};
}
