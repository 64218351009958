import Box from "@mui/material/Box";
import React from "react";

const EmissionDisplayValueList = (props) => {
	return (
		<Box sx={{ display: "flex", flexDirection: "column", rowGap: 0.5, mt: 4 }}>
			{props.children}
		</Box>
	);
};

export default EmissionDisplayValueList;
