import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import RichTextEditor from "../../../../dumb-components/shared/editorv2/rich-text-editor";

const SendReceiptToAllModal = (props) => {
	const { t } = useTranslation();
	const [emailSubject, setEmailSubject] = useState(
		props.emailSubject ? props.emailSubject : "",
	);
	const [emailMessage, setEmailMessage] = useState(
		props.emailMessage ? props.emailMessage : "",
	);

	const sendEmailClicked = async () => {
		props.sendEmail(emailSubject, emailMessage);
		props.closeAll();
	};

	const handleSubjectChange = (event) => {
		setEmailSubject(event.target.value);
	};

	const handleMessageChange = (event) => {
		setEmailMessage(event);
	};

	function amountOfReceiptsNotSent() {
		return props.closure?.allocations.filter(
			(a) => (a["receiptDocId_sv"] || a["receiptDocId_en"]) && !a.receiptSent,
		).length;
	}

	return (
		<Dialog open={props.open}>
			<IconButton
				aria-label="close"
				onClick={() => {
					props.closeAll();
					props.close();
				}}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[600],
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent sx={{ px: 6, minWidth: "60rem", minHeight: "30rem" }}>
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Typography sx={{ textAlign: "center", pb: 2 }} variant="h4">
						{t("emissions.payment.send-receipt-toAll", {
							amount: amountOfReceiptsNotSent(),
						})}
					</Typography>
					<Typography sx={{ textAlign: "flex-start", pb: 4 }} variant="body2">
						{t("emissions.payment.send-receipt-toAll-pop-up.desc2")}
					</Typography>
					<TextField
						label={t("subject")}
						sx={{ mb: "20px" }}
						value={emailSubject}
						onChange={handleSubjectChange}
					/>
					<RichTextEditor
						label={t("emissions.invitations.message")}
						value={emailMessage}
						onChange={handleMessageChange}
					/>
					<Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
						<Button
							variant={"text"}
							sx={{
								color: "#757575",
								fontWeight: "bold",
								alignSelf: "center",
								mr: "16px",
							}}
							onClick={() => {
								props.closeAll();
								props.close();
							}}
						>
							<Typography variant={"h5"}>
								{t("dialog.generic.cancel")}
							</Typography>
						</Button>
						<Button
							onClick={sendEmailClicked}
							variant={"contained"}
							sx={{ fontWeight: "bold", alignSelf: "center", ml: "16px" }}
						>
							<Typography variant={"h5"}>
								{t("emissions.payment.send-receipt-toAll-button")}
							</Typography>
						</Button>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default SendReceiptToAllModal;
