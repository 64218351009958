import React, { Component } from "react";
import PropTypes, { string, number, array, bool } from "prop-types";
import ReactSelect from "react-select";

export default class Select extends Component {
	static propTypes = {
		className: string,
		value: PropTypes.oneOfType([string, number]),
		options: array,
		clearable: bool,
		simpleValue: bool,
		multi: bool,
		disabled: bool,
	};

	static defaultProps = {
		className: "",
		clearable: false,
		simpleValue: false,
		multi: false,
		disabled: false,
	};

	render = () => {
		let {
			className,
			value,
			options,
			onSelect,
			placeholder,
			noResultsText,
			simpleValue,
			multi,
			optionComponent,
			valueComponent,
			disabled,
		} = this.props;
		let props = {};

		if (optionComponent) {
			props.optionComponent = optionComponent;
		}

		if (valueComponent) {
			props.valueComponent = valueComponent;
		}

		return (
			<ReactSelect
				{...props}
				value={value}
				options={options}
				onChange={onSelect}
				placeholder={placeholder}
				clearable={false}
				noResultsText={noResultsText}
				className={className}
				simpleValue={simpleValue}
				multi={multi}
				disabled={disabled}
			/>
		);
	};
}
