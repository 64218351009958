import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../actions/modals.actions";
import { addErrorNotification } from "../../../../actions/notify.actions";
import {
	downloadPersonalAttachment,
	listPersonalAttachments,
	openPersonalAttachment,
	uploadPersonalAttachment,
	deletePersonalAttachment,
} from "../../../../actions/personal-attachments.actions";
import { PERSONAL_ATTACHMENTS_MODAL } from "../../../../constants/modals";
import Attachments from "../../../../dumb-components/shared/attachments/attachments-v2";
import MUIDialog from "../../../../mui-components/dialog/mui-dialog";
import { useTranslation } from "react-i18next";
import DropDownMenuContainer from "../../../shared/dropdown-menu.container";
import { OutlinedButton } from "../../../../dumb-components/shared/button-v2";
import DropdownIconItem from "../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";

function PersonalAttachments() {
	const { t } = useTranslation();
	const [isUploading, setIsUploading] = useState(false);
	const {
		open,
		options: { objType, objId, multiple },
	} = useSelector((state) => {
		const open =
			state.modals.getIn(["activeModal", "name"]) ===
			PERSONAL_ATTACHMENTS_MODAL;
		const options = open
			? state.modals.getIn(["activeModal", "options"]).toJS()
			: {};

		return { open, options };
	});
	const attachments = useSelector((state) =>
		state.personalAttachments.getIn(["attachments", objType, objId]),
	);
	const fileEnteredWindow = useSelector(
		(state) => state.notify.fileEnteredWebsiteGlobal,
	);
	const dispatch = useDispatch();
	const dropdownRef = createRef();

	useEffect(() => {
		dispatch(listPersonalAttachments(objType, objId));

		return () => {};
	}, []);

	useEffect(() => {
		dispatch(listPersonalAttachments(objType, objId));

		return () => {};
	}, [objType, objId]);

	const upload = (filesToUpload, fileRejections) => {
		if (fileRejections && fileRejections.length > 0) {
			dispatch(
				addErrorNotification({ tid: "attachments.error.files_rejected" }),
			);
		}

		if (!filesToUpload || filesToUpload.length === 0) {
			return;
		}

		setIsUploading(true);

		dispatch(
			uploadPersonalAttachment(objType, objId, filesToUpload, () =>
				setIsUploading(false),
			),
		);
	};

	const onOpenFile = ({ documentId }) => {
		dispatch(openPersonalAttachment(documentId));
	};

	const onDownloadFile = ({ documentId }) => {
		dispatch(downloadPersonalAttachment(documentId));
	};

	const onClose = () => dispatch(closeModal());

	const onDelete = (documentId) => {
		dropdownRef.current[documentId].onToggleMenu();
		dispatch(deletePersonalAttachment(objType, objId, documentId));
	};

	const renderDropDown = ({ documentId }) => {
		return (
			<DropDownMenuContainer
				halignMenu="right"
				ref={(ref) =>
					dropdownRef.current
						? (dropdownRef.current[documentId] = ref)
						: (dropdownRef.current = { [documentId]: ref })
				}
				renderRaw={
					<OutlinedButton
						icon="faEllipsisV"
						onClick={() => dropdownRef.current[documentId].onToggleMenu()}
					/>
				}
				openDirection="up"
			>
				<DropdownIconItem
					icon="faTrashAlt"
					tid="delete_file"
					onClick={() => onDelete(documentId)}
				/>
			</DropDownMenuContainer>
		);
	};

	return (
		<MUIDialog
			isOpen={open}
			onClose={onClose}
			title={t("personal_attachments.modal.title")}
			confirmButton={false}
			closeButtonTid="generic.form.close"
			maxWidth="sm"
			fullWidth
		>
			<Attachments
				key={objId}
				objId={objId}
				documents={attachments}
				fileEnteredWindow={fileEnteredWindow}
				isDragDisabled={true}
				isUploading={isUploading}
				multiple={multiple}
				onDrop={upload}
				onOpenFile={onOpenFile}
				onDownloadFile={onDownloadFile}
				descriptionTid="personal_attachments.upload_description"
				documentDropdownRenderer={renderDropDown}
				nakedStyle
			/>
		</MUIDialog>
	);
}

export { PersonalAttachments };
