import { css } from "styled-components";

export const StyledListHeaderTemplate = css`
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	height: 34px;
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeLight};
`;
