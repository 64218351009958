import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool } from "prop-types";

import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import { OutlinedButton } from "../../../dumb-components/shared/button-v2";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";

class AttachmentsDropdownMenuContainer extends Component {
	dropdownRef = null;

	static propTypes = {
		readOnly: bool,
		renderChildren: func,
	};

	setDropdownRef = (ref) => {
		this.dropdownRef = ref;
	};

	toggleDropdown = () => {
		this.dropdownRef && this.dropdownRef.onToggleMenu();
	};

	render = () => {
		const { readOnly, renderChildren, openDirection } = this.props;

		return (
			<DropdownMenuContainer
				openDirection={openDirection}
				halignMenu="right"
				ref={this.setDropdownRef}
				disabled={readOnly}
				renderRaw={
					<Tooltip active={Boolean(readOnly)} activeState="hasNoPermissions">
						<OutlinedButton
							icon="faEllipsisV"
							onClick={this.toggleDropdown}
							disabled={readOnly}
						/>
					</Tooltip>
				}
			>
				{renderChildren({ toggleDropdown: this.toggleDropdown })}
			</DropdownMenuContainer>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(AttachmentsDropdownMenuContainer);
