import React, { useState } from "react";
import { useField, useForm } from "react-final-form";
import { useTranslation } from "react-i18next";
import { TextField } from "mui-rff";
import { FieldArray } from "react-final-form-arrays";
import { validateEmail } from "../../modules/validation.module";

function EmailField({ fieldArrayName, label, name, index, push, remove }) {
	const { t } = useTranslation();
	const field = useField(name);
	const [addedNewField, setAddedNewField] = useState(false);
	const form = useForm();

	return (
		<TextField
			name={name}
			label={t(label)}
			onChange={(event) => {
				const { value } = event.target;

				if (validateEmail(value) && !addedNewField) {
					push(fieldArrayName, "");
					setAddedNewField(true);
				} else if (value.length === 0) {
					setAddedNewField(false);
					if (form.getState().values.emails.length > 1) {
						setTimeout(() => remove(fieldArrayName, index));
					}
				}

				field.input.onChange(value);
			}}
		/>
	);
}

function EmailFields({ name, label, push, remove }) {
	return (
		<FieldArray name={name}>
			{({ fields }) =>
				fields.map((field, index) => (
					<EmailField
						key={index}
						fieldArrayName={name}
						label={label}
						name={field}
						index={index}
						push={push}
						remove={remove}
					/>
				))
			}
		</FieldArray>
	);
}

export { EmailFields };
