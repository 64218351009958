import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listInvestors } from "../../../../actions/investors.actions";
import { CheckboxInHeaderRenderer } from "../../../../dumb-components/shared/ag-grid/renderers/checkbox-in-header.renderer";
import { CheckboxRenderer } from "../../../../dumb-components/shared/ag-grid/renderers/checkbox.renderer";
import { ProfileImageRenderer } from "../../../../dumb-components/shared/ag-grid/renderers/profile-image.renderer";
import { TextOnlyTooltip } from "../../../../dumb-components/shared/ag-grid/tooltips/text-only-tooltip";
import { useTranslation } from "react-i18next";
import { usePeopleModalContext } from "../people-modal.context";
import { EmailCellRenderer } from "../../../../dumb-components/shared/ag-grid/renderers/email.cell-renderer";
import { RolesTooltipComponent } from "../tooltips/RolesTooltipComponent";
import AgGrid from "../../../../dumb-components/shared/ag-grid/ag-grid";
import investmentApi from "/shared/helpers/investment.helper";
import { validateEmail } from "../../../../modules/validation.module";
import { fromJS, Map } from "immutable";
import {
	INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE,
	INVESTOR_TYPE_OF_OWNER_PRIVATE,
	INVESTOR_TYPE_OF_OWNER_COMPANY,
} from "/shared/constants";
import { bool, func } from "prop-types";
import { makePersonAndAppendToPeopleList } from "../helpers";
import { UnselectableRenderer } from "../../../../dumb-components/shared/ag-grid/renderers/unselectable.renderer";
import { formatSsnPretty } from "/shared/helpers/helpers";

const InvestorsGrid = ({
	disabledFunction,
	onSetSelectedRowsOnMount,
	initiallySelectAll,
	usePagination,
}) => {
	const {
		selectedPeople,
		setSelectedPeople,
		floatingFilter,
		showGridInExtendedView,
	} = usePeopleModalContext();
	const dispatch = useDispatch();
	const investors = useSelector((state) => state.investors.get("list"));
	const companyMembers = useSelector((state) => state.company.company.users);
	const { t } = useTranslation();
	const gridRef = useRef();
	const [rowData, setRowData] = useState();
	const isMounting = useRef(true);

	useEffect(() => {
		dispatch(listInvestors("true"));
	}, []);

	useEffect(() => {
		if (investors) {
			const investorsJs = investors
				.toJS()
				.map((investor) => {
					const email = investmentApi.getInvestorEmail(investor);
					const reference = investmentApi.getInvestorSsn(investor);
					const name = investmentApi.getInvestorName(investor);
					const phone = investmentApi.getInvestorTelephone(investor);

					const isCompanyMember = checkIfInvestorIsMemberOfCompany(investor);
					let isSelectable = true;
					let tooltipTid;
					let restrictionIcon;
					let restrictionIconSize = 21;

					if (!validateEmail(email)) {
						isSelectable = false;
						tooltipTid = "people_modal.grid.selected.tooltip.email_is_missing";
						restrictionIcon = "faExclamationTriangle";
						restrictionIconSize = 17;
					} else if (typeof disabledFunction === "function") {
						const result = disabledFunction(fromJS(investor));

						if (typeof result === "boolean") {
							isSelectable = !result;
							tooltipTid = "people_modal.grid.selected.tooltip.not_selectable";
							restrictionIcon = "faExclamationSquare";
						} else if (typeof result === "object") {
							isSelectable = !result.isDisabled;
							tooltipTid =
								!isSelectable &&
								(result.tooltipTid ??
									"people_modal.grid.selected.tooltip.not_selectable");
							restrictionIcon = result.icon ?? "faExclamationSquare";
						}
					}

					return {
						id:
							isCompanyMember &&
							investor.investorTypeOfOwner === INVESTOR_TYPE_OF_OWNER_PRIVATE
								? investor.investorId
								: investor.id,
						investmentId: investor.id,
						isInvestor: true, // just for clarity
						selectable: isSelectable,
						tooltipTidOnSelectedColumn: tooltipTid,
						image: null,
						reference: formatSsnPretty(reference),
						name,
						phone,
						email,
						isCompanyMember,
						investorTypeOfOwner: investor.investorTypeOfOwner,
						investorStatus: investor.details?.hasShares
							? "CURRENT"
							: "NON_SHAREHOLDER",
						hasShares: !!investor.details?.hasShares,
						isDeletable: investor.isDeletable,
						restrictionIcon: restrictionIcon,
						restrictionIconSize: restrictionIconSize,
					};
				})
				.sort((a) => {
					if (a.investorStatus === "CURRENT") {
						return -1;
					} else {
						return 1;
					}
				});

			setRowData(investorsJs);
		}
	}, [investors]);

	const checkIfInvestorIsMemberOfCompany = (investor) => {
		if (!investor.computed.isActiveUser) {
			return false;
		}

		return companyMembers.includes(investor.investorId);
	};

	const getInvestorTypeOfOwner = ({ data: { investorTypeOfOwner } }) => {
		let text;
		switch (investorTypeOfOwner) {
			case INVESTOR_TYPE_OF_OWNER_PRIVATE:
				text = t("people_modal.investor_type_of_owner.private");
				break;
			case INVESTOR_TYPE_OF_OWNER_COMPANY:
				text = t("people_modal.investor_type_of_owner.company");
				break;
			case INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE:
				text = t("people_modal.investor_type_of_owner.capital_insurance");
				break;
		}

		return text;
	};

	const getInvestorStatus = ({ data: { investorStatus } }) => {
		let text;
		switch (investorStatus) {
			case "NON_SHAREHOLDER":
				text = t("people_modal.grid.investor_status.non_shareholder");
				break;
			case "CURRENT":
				text = t("people_modal.grid.investor_status.current");
				break;
		}

		return text;
	};

	const onSelectionChanged = ({ api }) => {
		let newSelectedPeople = selectedPeople;

		api.forEachNode((node) => {
			const isSelected = node.isSelected();
			const { id } = node.data;
			const isAlreadyASelectedPerson = selectedPeople.has(id);

			if (isSelected) {
				if (!isAlreadyASelectedPerson) {
					newSelectedPeople = makePersonAndAppendToPeopleList(
						newSelectedPeople,
						node.data,
						true,
					);
				}
			} else {
				newSelectedPeople = newSelectedPeople.remove(id);
			}
		});

		setSelectedPeople(newSelectedPeople);

		const newRowData = rowData.map((person) => {
			const node = api.getRowNode(person.id);
			person.selected = node.selected;
			return person;
		});
		setRowData(newRowData);
	};

	const isRowSelectable = (rowNode) => {
		if (!rowNode.data) {
			return true;
		}

		return rowNode.data.selectable;
	};

	const setSelectedRows = () => {
		let peopleToAddInitially = Map();

		gridRef.current.api.forEachNode((node) => {
			if (isMounting.current === true && node.selectable === true) {
				if (initiallySelectAll) {
					node.setSelected(true, false, true);
					node.setDataValue("selected", true);
					peopleToAddInitially = makePersonAndAppendToPeopleList(
						peopleToAddInitially,
						node.data,
						true,
					);
				} else if (typeof onSetSelectedRowsOnMount === "function") {
					const rowIsSelected = onSetSelectedRowsOnMount(node.data);

					if (rowIsSelected) {
						node.setSelected(rowIsSelected, false, true);
						node.setDataValue("selected", rowIsSelected);
						peopleToAddInitially = makePersonAndAppendToPeopleList(
							peopleToAddInitially,
							node.data,
							true,
						);
					}
				}
			} else {
				const rowIsSelected =
					selectedPeople && selectedPeople.has(node.data.id);
				node.setSelected(rowIsSelected, false, true);
				node.setDataValue("selected", rowIsSelected);
			}
		});

		if (peopleToAddInitially.size > 0) {
			setSelectedPeople(peopleToAddInitially);
		}

		isMounting.current = false;
	};

	const columnDefs = () => {
		return [
			{
				field: "selected",
				headerName: "",
				cellRendererSelector: ({ node, data }) => {
					if (!node.selectable) {
						return {
							component: UnselectableRenderer,
							params: {
								icon: data.restrictionIcon,
								iconSize: data.restrictionIconSize,
							},
						};
					}

					return {
						component: CheckboxRenderer,
					};
				},
				headerComponent: CheckboxInHeaderRenderer,
				headerComponentParams: {
					filteredOnly: true,
				},
				lockVisible: true,
				minWidth: 38,
				tooltipField: "selected",
				tooltipComponent: TextOnlyTooltip,
				tooltipComponentParams: {
					tooltipTid: ({ data }) => {
						return data.tooltipTidOnSelectedColumn;
					},
				},
				cellClass: "checkbox-cell",
				headerClass: "checkbox-cell",
				filter: false,
			},
			{
				field: "image",
				headerName: "",
				minWidth: 47,
				maxWidth: 47,
				width: 47,
				cellClass: "profile-image-cell",
				headerClass: "profile-image-cell",
				cellRenderer: ProfileImageRenderer,
				filter: false,
			},
			{
				field: "reference",
				headerName: t("people_modal.grid.headers.reference"),
				minWidth: 150,
			},
			{
				field: "name",
				flex: 1,
				minWidth: showGridInExtendedView ? 350 : 232,
				filter: "agTextColumnFilter",
				sort: "asc",
				sortIndex: 1,
			},
			{
				field: "phone",
				headerName: t("generic.telephone"),
				minWidth: 150,
				filter: "agTextColumnFilter",
				hide: !showGridInExtendedView,
			},
			{
				field: "email",
				headerName: t("people_modal.grid.headers.email"),
				minWidth: showGridInExtendedView ? 320 : 241,
				cellRenderer: EmailCellRenderer,
				filter: "agTextColumnFilter",
			},
			{
				field: "isCompanyMember",
				headerName: t("people_modal.grid.headers.is_member"),
				minWidth: 162,
				valueGetter: ({ data: { isCompanyMember } }) =>
					isCompanyMember === true ? t("generic.yes") : t("generic.no"),
				filter: "agSetColumnFilter",
				tooltipComponent: RolesTooltipComponent,
				tooltipField: "isCompanyMember",
				hide: !showGridInExtendedView,
			},
			{
				field: "investorTypeOfOwner",
				headerName: t("people_modal.grid.headers.investor_type_of_owner"),
				minWidth: 170,
				valueGetter: getInvestorTypeOfOwner,
				filter: "agSetColumnFilter",
			},
			{
				field: "investorStatus",
				headerName: t("people_modal.grid.headers.investor_status"),
				minWidth: 188,
				valueGetter: getInvestorStatus,
				filter: "agSetColumnFilter",
				sort: "desc",
				sortIndex: 0,
			},
		];
	};

	const getRowId = useCallback((params) => params.data.id, []);

	return (
		<AgGrid
			gridRef={gridRef}
			rowData={rowData}
			columnDefs={columnDefs()}
			mode="panel"
			rowSelection="multiple"
			onRowDataChanged={setSelectedRows}
			isRowSelectable={isRowSelectable}
			forcedHeight="100%"
			hideColumnMenu={true}
			floatingFilter={floatingFilter}
			suppressRowClickSelection={true}
			rowsPerPage={50}
			noPagination={!usePagination}
			onSelectionChanged={onSelectionChanged}
			suppressContextMenu={true}
			onFilterChanged={({ api }) => api.refreshHeader()}
			getRowId={getRowId}
			enableCopyCellText
		/>
	);
};

InvestorsGrid.propTypes = {
	disabledFunction: func,
	onSetSelectedRowsOnMount: func,
	initiallySelectAll: bool,
};

export { InvestorsGrid };
