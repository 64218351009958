import { SCREEN_MESSAGE_SHOW, SCREEN_MESSAGE_HIDE } from "../actions/types";

const INITIAL_STATE = {
	activeMessage: null,
};

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case SCREEN_MESSAGE_SHOW: {
			return { ...state, activeMessage: payload };
		}
		case SCREEN_MESSAGE_HIDE: {
			return { ...state, activeMessage: null };
		}
		default:
			return state;
	}
}
