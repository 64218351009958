import React, { forwardRef } from "react";

import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useTranslation } from "react-i18next";
import { bool, func, number, object, oneOf, string } from "prop-types";
import styled from "@mui/styled-engine";
import Icon from "../../../../app/dumb-components/shared/icon/icon";
import NotificationBadge from "../../../dumb-components/shared/notification-badge/notification-badge";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
	paddingBlock: theme.spacing(1),

	"&.Mui-disabled": {
		pointerEvents: "auto",
	},
}));

const MenuItemIcon = forwardRef((props, ref) => {
	const {
		onClick,
		icon,
		listItemTid,
		listItemTidValues,
		disabled,
		iconType,
		notificationBadge,
		notificationBadgeAnimate,
		notificationBadgeColor,
		notificationBadgeHorizontalPos,
		...other
	} = props;
	const { t } = useTranslation();

	const adjustedMenuItemProps = {
		disabled: disabled,
		onClick: disabled ? undefined : onClick,
		...other,
	};

	const notificationBadgeProps = {
		active: notificationBadge,
		animated: notificationBadgeAnimate,
		color: notificationBadgeColor,
	};

	return (
		<StyledMenuItem ref={ref} {...adjustedMenuItemProps}>
			{typeof icon !== "undefined" ? (
				props.notificationBadge ? (
					<NotificationBadge
						{...notificationBadgeProps}
						x={
							notificationBadgeHorizontalPos
								? notificationBadgeHorizontalPos
								: 15
						}
					>
						<ListItemIcon>
							<Icon
								icon={icon}
								type={iconType}
								color={disabled ? "muted" : "midGrey"}
								size="sml"
							/>
						</ListItemIcon>
					</NotificationBadge>
				) : (
					<ListItemIcon>
						<Icon
							icon={icon}
							type={iconType}
							color={disabled ? "muted" : "midGrey"}
							size="sml"
						/>
					</ListItemIcon>
				)
			) : null}

			<ListItemText disableTypography sx={{ pr: 1, pl: 1 }}>
				{t(listItemTid, listItemTidValues)}
			</ListItemText>
		</StyledMenuItem>
	);
});

MenuItemIcon.propTypes = {
	onClick: func,
	icon: string,
	listItemTid: string,
	listItemTidValues: object,
	disabled: bool,
	iconType: oneOf(["light", "solid"]),
	notificationBadge: bool,
	notificationBadgeAnimate: bool,
	notificationBadgeColor: string,
	notificationBadgeHorizontalPos: number,
};

MenuItemIcon.defaultProps = {
	disabled: false,
	iconType: "light",
};

MenuItemIcon.displayName = "MenuItemIcon";

export default MenuItemIcon;
