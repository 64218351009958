import req from "../modules/request.module";
import { fromJS } from "immutable";
import { ATTENDEES_FILTER, ATTENDEES_RESET_FILTER } from "./types";

export function filterAttendees(
	meetingId,
	filterData,
	attendeesFilteredInClient,
) {
	return function (dispatch) {
		req
			.post(`/meetings/meeting/${meetingId}/attendees/filter`, { filterData })
			.then((response) => {
				dispatch({
					type: ATTENDEES_FILTER,
					payload: {
						meetingId,
						attendeesFilteredInClient,
						filteredAttendees: fromJS(response.data),
					},
				});
			});
	};
}

export function resetAttendeesFilter(meetingId) {
	return { type: ATTENDEES_RESET_FILTER, payload: { meetingId } };
}
