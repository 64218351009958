import React, { memo, useCallback } from "react";
import { func, array, string } from "prop-types";
import update from "immutability-helper";
import useUpdatableState from "@landisdesign/use-updatable-state";
import SortableItem from "./sortable-item";

const Sortable = ({ onDragging, onDragEnd, sortableId, ...props }) => {
	const [items, setItems] = useUpdatableState(props.items);

	const findItem = useCallback(
		(id) => {
			const item = items.find((i) => i.id === id);
			return {
				item,
				index: items.indexOf(item),
			};
		},
		[items],
	);

	const moveItem = useCallback(
		(id, atIndex) => {
			const { item, index } = findItem(id);
			setItems(
				update(items, {
					$splice: [
						[index, 1],
						[atIndex, 0, item],
					],
				}),
			);
		},
		[findItem, items, setItems],
	);

	const removeItem = useCallback(
		(id) => {
			const index = findItem(id).index;
			setItems(
				update(items, {
					$splice: [[index, 1]],
				}),
			);
		},
		[findItem, items, setItems],
	);

	const _onDragEnd = useCallback(() => {
		onDragEnd && onDragEnd(items);
	}, [items]);

	const _onDragging = useCallback(
		(item) => {
			onDragging && onDragging(item);
		},
		[items],
	);

	const renderItem = (item) => {
		const { id, type } = item;
		return (
			<SortableItem
				key={id}
				id={id}
				type={type}
				sortableId={sortableId}
				findItem={findItem}
				moveItem={moveItem}
				removeItem={removeItem}
				onDragging={_onDragging}
				onDragEnd={_onDragEnd}
			>
				{props.children}
			</SortableItem>
		);
	};

	return <>{items.map(renderItem)}</>;
};

Sortable.propTypes = {
	items: array,
	onDragging: func,
	onDragEnd: func,
	sortableId: string.isRequired,
};

export default memo(Sortable);
