import React, { Component } from "react";
import { connect } from "react-redux";

import OnboardingModal from "../../../../../dumb-components/onboarding/modal";
import HeaderControls from "../../header-controls.container";
import WelcomeMember from "../../../../../dumb-components/onboarding/modals/dashboard/welcome-member";

import { closeModal } from "../../../../../actions/modals.actions";
import { markOnboardAsDismiss } from "../../../../../actions/notifications.actions";
import history from "../../../../../interfaces/history";

class WelcomeUserOnboardModal extends Component {
	goToFirstCompany = () => {
		const { firstCompany, history } = this.props;

		// If there's no firstCompany, something went badly wrong.
		// Just close the modal at this time without navigating
		// anwyhere. This case should never happen.
		if (firstCompany) {
			history.push(`/${firstCompany.get("urlAlias")}`);
		}

		this.dismissPermanently();
	};

	dismissPermanently = () => {
		const { markOnboardAsDismiss, notificationId, closeModal } = this.props;
		markOnboardAsDismiss(notificationId);
		closeModal();
	};

	controlsComponent = () => {
		return <HeaderControls />;
	};

	render = () => {
		const { firstCompany } = this.props;

		return (
			<OnboardingModal dropdownComponent={this.controlsComponent()}>
				<WelcomeMember
					companyName={firstCompany && firstCompany.get("name")}
					onGoToCompany={this.goToFirstCompany}
				/>
			</OnboardingModal>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		firstCompany: store.company.companies.last(),
		notificationId: store.modals.getIn([
			"activeModal",
			"options",
			"notificationId",
		]),
	};
};

const mapActionsToProps = {
	markOnboardAsDismiss,
	closeModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(WelcomeUserOnboardModal);
