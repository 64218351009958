import React, { useRef } from "react";
import { func } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import AddAttendees from "./AddAttendees";
import { OutlinedButton } from "../../../../dumb-components/shared/button-v2";
import DropdownMenuContainer from "../../../shared/dropdown-menu.container";
import DropdownIconItem from "../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";

const MainControls = ({ attendees, onAddAttendees }) => {
	const dropdownRef = useRef(null);

	const toggleDropdown = () => {
		dropdownRef.current.onToggleMenu();
	};

	return (
		<AddAttendees attendees={attendees} onAddAttendees={onAddAttendees}>
			{({ openAttendeesModal }) => (
				<>
					<OutlinedButton icon="faPlus" onClick={openAttendeesModal} />
					<DropdownMenuContainer
						halignMenu="right"
						ref={dropdownRef}
						onClick={toggleDropdown}
						withPortal
						renderRaw={
							<OutlinedButton
								icon="faEllipsisV"
								disabled={attendees.size === 0}
								leftMargin
							/>
						}
					>
						<DropdownIconItem
							icon="faCog"
							tid="meetings.attendees.panel.controls.dropdown.edit_attendees"
							onClick={() => {
								toggleDropdown();
								openAttendeesModal();
							}}
						/>
					</DropdownMenuContainer>
				</>
			)}
		</AddAttendees>
	);
};

MainControls.propTypes = {
	attendees: immutablePropTypes.map,
	onAddAttendees: func,
};

export default MainControls;
