import { fromJS, Map } from "immutable";
import uuid from "uuid/v1";
import req from "../modules/request.module";
import {
	MEETING_TEMPLATES_LIST,
	MEETING_TEMPLATES_UPDATE_LOCAL,
	MEETING_TEMPLATES_CREATE,
	MEETING_TEMPLATES_CREATE_LOCAL,
	MEETING_TEMPLATES_FETCH,
	MEETING_TEMPLATES_SAVE,
	MEETING_TEMPLATES_SAVE_START,
	MEETING_TEMPLATES_DELETE,
	MEETING_TEMPLATES_DIRTY,
	MEETING_TEMPLATES_SELECT,
} from "./types";
import { addErrorNotification } from "./notify.actions";
import {
	LIVE_MEETING_TEMPLATES_CREATE,
	LIVE_MEETING_TEMPLATES_UPDATE,
	LIVE_MEETING_TEMPLATES_DELETE,
} from "../constants/live-update";
import { setLiveRequest, resetLiveRequest } from "./live-update.actions";

/**
 * Action for fetch a meeting template
 */
export function fetchMeetingTemplate(id) {
	return function (dispatch) {
		return req
			.get(`/meetings/templates/${id}`)
			.then((response) => {
				dispatch({
					type: MEETING_TEMPLATES_FETCH,
					payload: fromJS(response.data),
				});
			})
			.catch(() => {
				dispatch(
					meetingTemplateError(
						"meetings.templates.error.load_meeting_template",
					),
				);
			});
	};
}

export function selectMeetingTemplate(id) {
	return {
		type: MEETING_TEMPLATES_SELECT,
		payload: id,
	};
}

export function updateMeetingTemplateLocal(meetingTemplate, callback) {
	return function (dispatch) {
		dispatch({
			type: MEETING_TEMPLATES_DIRTY,
			payload: meetingTemplate.get("id"),
		});
		dispatch({
			type: MEETING_TEMPLATES_UPDATE_LOCAL,
			payload: meetingTemplate,
		});
		callback && callback();
	};
}

/**
 * Action for saving a meeting template
 */
export function saveMeetingTemplate(meetingTemplate, callback) {
	return function (dispatch) {
		dispatch({
			type: MEETING_TEMPLATES_SAVE_START,
			payload: meetingTemplate.get("id"),
		});
		return req
			.put(
				`/meetings/templates/${meetingTemplate.get("id")}`,
				{ template: meetingTemplate.toJS() },
				{ onlyLatest: true },
			)
			.then((response) => {
				dispatch({
					type: MEETING_TEMPLATES_SAVE,
					payload: fromJS(response.data),
				});
				callback && callback();
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes("onlyLatest:true")) {
					dispatch(
						meetingTemplateError(
							"meetings.templates.error.save_meeting_template",
						),
					);
				}
			});
	};
}

export function createMeetingTemplateLocal(meetingTemplate, callback) {
	return function (dispatch) {
		// create the meeting id local for speedup
		meetingTemplate = meetingTemplate.set("id", uuid());

		dispatch({
			type: MEETING_TEMPLATES_CREATE_LOCAL,
			payload: Map({ meetingTemplate }),
		});
		callback && callback(meetingTemplate);
	};
}

/**
 * Action for creating a meeting template
 */
export function createMeetingTemplate(meetingTemplate, callback) {
	return function (dispatch) {
		return req
			.post(`/meetings/templates/`, { template: meetingTemplate.toJS() })
			.then((response) => {
				meetingTemplate = fromJS(response.data);
				dispatch({
					type: MEETING_TEMPLATES_CREATE,
					payload: fromJS(meetingTemplate),
				});
				callback && callback(meetingTemplate);
			})
			.catch(() => {
				dispatch(
					meetingTemplateError(
						"meetings.templates.error.create_meeting_template",
					),
				);
			});
	};
}

/**
 * Action for fetching a list of meetings
 */
export function listMeetingTemplates(groupId, callback) {
	return function (dispatch) {
		return req
			.get(`/meetings/templates/`)
			.then((response) => {
				dispatch({
					type: MEETING_TEMPLATES_LIST,
					payload: fromJS(response.data),
				});
				callback && callback();
			})
			.catch(() => {
				dispatch(
					meetingTemplateError(
						"meetings.templates.error.load_meeting_templates",
					),
				);
			});
	};
}

/**
 * Action for deleting a meeting template
 * @param {String} id — meeting template id
 */
export function deleteMeetingTemplate(id, callback) {
	return function (dispatch) {
		return req
			.delete(`/meetings/templates/${id}`)
			.then(() => {
				dispatch({ type: MEETING_TEMPLATES_DELETE, payload: id });
				callback && callback();
			})
			.catch((error) => {
				if (!error.response.data.translationId) {
					dispatch(
						meetingTemplateError(
							"meetings.templates.error.delete_meeting_template",
						),
					);
				}
			});
	};
}

/**
 * Action for dispatching an meeting template error
 * @param {String} error — error message
 */
function meetingTemplateError(error) {
	return addErrorNotification({
		tid: error,
	});
}

// ================ AUDIT ================

export function socketEventMeetingTemplates(eventObj) {
	const { eventName, objId, metadata } = eventObj;

	return function (dispatch) {
		switch (eventName) {
			case LIVE_MEETING_TEMPLATES_CREATE:
			case LIVE_MEETING_TEMPLATES_UPDATE:
			case LIVE_MEETING_TEMPLATES_DELETE:
				dispatch(
					setLiveRequest(["meetingTemplates", eventName], {
						refresh: true,
						objId,
						metadata,
					}),
				);
				dispatch(resetLiveRequest(["meetingTemplates", eventName]));
				break;
		}
	};
}
