import React, { PureComponent } from "react";
import { func, bool } from "prop-types";
import { map, list } from "react-immutable-proptypes";
import { List } from "immutable";
import DocumentsDetailedList from "../../documents/documents-detailed-list/documents-detailed-list";

export default class DocumentList extends PureComponent {
	static propTypes = {
		onOpenFileInViewer: func,
		onDownloadFile: func,
		hideProposalColumn: bool,
		attachments: map,
		agendaItems: list,
	};

	static defaultProps = {
		hideProposalColumn: false,
	};

	render = () => {
		const {
			attachments,
			onOpenFileInViewer,
			onDownloadFile,
			hideProposalColumn,
			agendaItems,
		} = this.props;

		if (!attachments || attachments?.size === 0) {
			return null;
		}

		let fileObjects = List();
		attachments.forEach((documents) => {
			documents.forEach((doc) => {
				let proposals = List();
				doc.get("links") &&
					doc.get("links").forEach((link) => {
						const agendaItem = agendaItems.find(
							(item) => item.get("objId") === link.get("objId"),
						);

						if (agendaItem) {
							proposals = proposals.push(agendaItem.get("proposal"));
						}
					});

				let file = doc.get("file");
				file = file.set("id", doc.get("id")); // Set doc ID to file
				file = file.set("proposal", proposals.join(", "));
				fileObjects = fileObjects.push(file);
			});
		});

		return (
			<DocumentsDetailedList
				hideProposalColumn={hideProposalColumn}
				documents={fileObjects}
				onOpenFile={onOpenFileInViewer}
				onDownloadFile={onDownloadFile}
			/>
		);
	};
}
