import React from "react";
import Card from "@mui/material/Card";

const WhiteSection = (props) => {
	return (
		<Card
			elevation={0}
			sx={{ px: 5, py: 4, ...props.sx }}
			className={props.name || ""}
		>
			{props.children}
		</Card>
	);
};

export default WhiteSection;
