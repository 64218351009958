import React from "react";
import { Route, Switch, routeHoc } from "../../interfaces/router";
import InvestmentContainer from "../investments/investment.container";
import { DealFlowContainer } from "./DealFlowContainer";

const DealflowRoute = ({ match }) => {
	return (
		<div className="i-content i-content--pad-right-half">
			<div className="i-content__container">
				<Switch>
					<Route
						path={`${match.path}/:companyName`}
						render={(props) => (
							<InvestmentContainer inDealflow={true} {...props} />
						)}
					/>
					<Route
						exact
						path={`${match.path}/`}
						render={(props) => <DealFlowContainer {...props} />}
					/>
				</Switch>
			</div>
		</div>
	);
};

export default routeHoc(DealflowRoute);
