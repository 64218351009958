import React, { useRef, useContext } from "react";
import { FormattedMessage } from "react-intl";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TransactionHistoryChart from "../sharebook/transaction-history-chart";
import SharebookMyOwnershipGridContainer from "../../../containers/investments/sharebook/sharebook-my-ownership-grid.container";
import SharebookCompanyLedgerGridContainer from "./sharebook-company-ledger-grid.container";
import { getQuery } from "../../../interfaces/history";
import req from "../../../modules/request.module";
import TransactionHistoryGridContainer from "./transaction-history-grid.container";
import { InvestmentsContext } from "../investment.container";
import { useDispatch, useSelector } from "react-redux";
import { CAP_TABLE_READ_MORE_MODAL } from "../../../constants/modals";
import { openModal } from "../../../actions/modals.actions";
import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import IconButton from "../../../mui-components/button/icon-button";
import Link from "@mui/material/Link";
import { ReadMoreModal } from "../modals/read-more-modal";

export default function InvestmentSharebookContainer() {
	const { selectedInvestment } = useContext(InvestmentsContext);
	const dispatch = useDispatch();
	const name = useSelector(
		(state) =>
			state.capTableDetails.get("aliasOfTheInvestedCompany") ||
			state.capTableDetails.getIn([
				"combinedSingleInvestmentAndAllTransactions",
				"investedInCompanyInformation",
				"name",
			]),
	);
	const buttonRef = useRef();

	const query = getQuery();
	const { viaInvestmentId } = query;
	const isReadMoreModalOpen = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) === CAP_TABLE_READ_MORE_MODAL,
	);

	const createPdf = (withHistory) => {
		const win = window.open(
			`https://${window.location.hostname}/assets/build/misc/generating-pdf.html`,
			"_blank",
		);
		const investedInCompanyId = selectedInvestment.get("investedInCompanyId");
		const id = selectedInvestment.get("id");
		const isCompany =
			selectedInvestment.get("investorTypeOfOwner") === "company";

		req
			.get(
				`/shares/pdf/my-certificates/${id}?companyId=${investedInCompanyId}&history=${withHistory}${
					isCompany ? "&isCompany=true" : ""
				}`,
			)
			.then(() => {
				win.location = `/api/pdf/shares/share-register-${id}`;
			});

		buttonRef && buttonRef.current.onToggleMenu();
	};

	const openReadMoreModal = () => {
		dispatch(openModal(CAP_TABLE_READ_MORE_MODAL));
	};

	return (
		<>
			<Grid container spacing={2} pb={2}>
				<Grid item lg={9} md={12}>
					<Card borderradius={"true"}>
						<CardContent>
							<Typography variant="body1">
								<FormattedMessage
									id={"investments.sharebook.secondary-card.title"}
									values={{ companyName: name }}
								/>
							</Typography>
							<Typography variant="body2">
								<FormattedMessage
									id={"investments.sharebook.secondary-card.paragraph"}
									values={{
										link: (
											<Link
												sx={{ pb: 0.3 }}
												onClick={openReadMoreModal}
												component={"button"}
												color={"positive.main"}
											>
												<FormattedMessage
													id={"investments.information_panel.link.read_more"}
												/>
											</Link>
										),
									}}
								/>
							</Typography>
						</CardContent>
					</Card>
				</Grid>

				<Grid item lg={3} md={12}>
					<Card variant="dark">
						<CardContent variant="flex">
							<Box>
								<Typography variant="body1">
									<FormattedMessage id={"generic.reports"} />
								</Typography>
								<Typography variant="body2">
									<FormattedMessage
										id={"investments.sharebook.dropdown_menu.download_pdf"}
									/>
								</Typography>
							</Box>

							<DropdownMenuContainer
								halignMenu="right"
								ref={buttonRef}
								noMaxWidth={true}
								renderRaw={<IconButton icon="faPrint" color="white" />}
							>
								<DropdownIconItem
									tid="investments.sharebook.dropdown_item.download_pdf.share_certificate"
									icon="faFileCertificate"
									onClick={() => createPdf(false)}
								/>
								<DropdownIconItem
									tid="investments.sharebook.dropdown_item.download_pdf.share_certificate_with_history"
									icon="faFileCertificate"
									onClick={() => createPdf(true)}
								/>
							</DropdownMenuContainer>
						</CardContent>
					</Card>
				</Grid>

				<Grid item xs={12}>
					<SharebookMyOwnershipGridContainer />
				</Grid>

				{viaInvestmentId && (
					<Grid item xs={12}>
						<SharebookMyOwnershipGridContainer
							viaInvestmentId={viaInvestmentId}
						/>
					</Grid>
				)}

				<Grid item xs={12}>
					<SharebookCompanyLedgerGridContainer />
				</Grid>

				<Grid item xs={12}>
					<TransactionHistoryGridContainer />
				</Grid>

				<Grid item xs={12}>
					<TransactionHistoryChart
						title={"investments.sharebook.transaction_history_grid_chart.title"}
						downloadExcelTid={
							"investments.sharebook.emission_history_chart.dropdown_item.download_chart"
						}
					/>
				</Grid>
			</Grid>
			{isReadMoreModalOpen && (
				<ReadMoreModal
					title={"investments.sharebook.white_panel.read_more.modal.title"}
					contentFile="share-register.txt"
				/>
			)}
		</>
	);
}
