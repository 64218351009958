import {
	addErrorNotification,
	addInfoNotification,
} from "@/components/framework/notify";

export default () => {
	const notify = (type = "info", title, message) => {
		if (!["info", "error"].includes(type)) {
			throw new Error(`useNotifications: Invalid notification type: ${type}`);
		}

		if (type === "error") {
			addErrorNotification({ tid: message });
		} else {
			addInfoNotification({ tid: message });
		}
	};

	return {
		notify,
	};
};
