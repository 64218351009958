import React, { PureComponent } from "react";
import styled from "styled-components";

const StyledDivider = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.border};
	height: 0px;
	margin: ${({ theme }) => theme.spacing[3]} 0;
`;

export default class Divider extends PureComponent {
	static propTypes = {};

	static defaultProps = {};

	render = () => {
		return <StyledDivider />;
	};
}
