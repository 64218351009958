import { fromJS } from "immutable";

import {
	TAGS_CREATE_TAG,
	TAGS_LIST_REMOTE,
	TAGS_SET_SORT,
	COMPANY_RESET_REDUCER,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	tags: [],
	sort: {},
});

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case TAGS_LIST_REMOTE:
			return state.set("tags", payload);
		case TAGS_CREATE_TAG:
			return state.update("tags", (tags) => {
				tags = tags.push(payload);
				tags = tags.sort((a, b) => a.get("name").localeCompare(b.get("name")));
				return tags;
			});
		case TAGS_SET_SORT:
			return state.setIn(["sort", payload.get("section")], payload.get("tags"));
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE;
		}
		default:
			return state;
	}
}
