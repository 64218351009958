import { SCREEN_MESSAGE_SHOW, SCREEN_MESSAGE_HIDE } from "./types";

/**
 * Action to show a message
 */
export function showScreenMessage(componentId, componentProps) {
	return {
		type: SCREEN_MESSAGE_SHOW,
		payload: { componentId, componentProps },
	};
}

/**
 * Action to hide a message
 */
export function hideScreenMessage() {
	return {
		type: SCREEN_MESSAGE_HIDE,
	};
}
