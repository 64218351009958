import React, { Component } from "react";
import { connect } from "react-redux";
import { string, object } from "prop-types";
import Text from "../../../dumb-components/shared/text/text";
import Box from "../../../dumb-components/shared/layout/box/box";
import Tabs from "../../../dumb-components/shared/tabs/tabs";

class ProtocolTabsContainer extends Component {
	static propTypes = {
		location: object,
		match: string,
	};

	getParentBasePath = () => {
		const {
			meeting,
			location: { pathname },
		} = this.props;
		const path = pathname;
		const pathArray = path.split("protocols/");
		return `${pathArray[0]}protocols/${meeting && meeting.get("id")}`;
	};

	getTabs = () => {
		const { meeting } = this.props;
		const isDisabled = meeting && meeting.get("templateId") === "LEGACY";

		return [
			{
				path: "protocol",
				disabled: isDisabled,
				tabContentRenderer() {
					return (
						<Box alignItems="center" valign="center">
							<Text tid="meetings.protocol_view.tabs.protocol" />
						</Box>
					);
				},
			},
			{
				path: "documents",
				tabContentRenderer() {
					return (
						<Box alignItems="center" valign="center">
							<Text tid="meetings.protocol_view.tabs.documents" />
						</Box>
					);
				},
			},
			{
				path: "tasks",
				disabled: isDisabled,
				tabContentRenderer() {
					return (
						<Box alignItems="center" valign="center">
							<Text tid="meetings.protocol_view.tabs.tasks" />
						</Box>
					);
				},
			},
			{
				path: "notes",
				disabled: isDisabled,
				tabContentRenderer() {
					return (
						<Box alignItems="center" valign="center">
							<Text tid="meetings.protocol_view.tabs.my_notes" />
						</Box>
					);
				},
			},
		];
	};

	render = () => {
		const { match } = this.props;
		const basePath = this.getParentBasePath();
		const tabs = this.getTabs();

		return <Tabs basePath={basePath} match={match} tabs={tabs} />;
	};
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get("meeting"),
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ProtocolTabsContainer);
