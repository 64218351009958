import React from "react";
import { node, oneOf, string, bool } from "prop-types";
import styled, { css } from "styled-components";
import Text from "../text/text";

const StyledLineSeparatorLine = styled.div`
	border-bottom: 1px dashed ${(props) => props.theme.colors[props.lineColor]};
	text-align: center;
	height: 10px;
	margin-bottom: 8px;
`;

const StyledLineSeparatorText = styled.span`
	background: ${(props) => props.theme.colors[props.bgColor]};
	padding: 0 ${(props) => props.theme.spacing[5]};
`;

const StyledLinetWrapper = styled.div`
	height: 16px;
	width: 100%;

	${(props) =>
		props.margin &&
		css`
			margin: ${(props) => props.theme.spacing[5]} 0;
		`}
`;

const LineSeparator = (props) => {
	return (
		<StyledLinetWrapper margin={props.margin}>
			<StyledLineSeparatorLine {...props}>
				<StyledLineSeparatorText {...props}>
					{props.tid ? (
						<Text tid={props.tid} color={props.textColor} />
					) : (
						<Text color={props.textColor}>{props.children}</Text>
					)}
				</StyledLineSeparatorText>
			</StyledLineSeparatorLine>
		</StyledLinetWrapper>
	);
};

const avaibleColors = [
	"java",
	"black",
	"white",
	"lightGrey",
	"midGrey",
	"darkGrey",
	"solitudeLight",
	"solitudeMid",
	"solitudeDark",
	"purpleLight",
	"purpleMid",
	"yellowLight",
	"yellowMid",
	"catalinaBlue",
	"persianRed",
	"limeade",
	"gamboge",
	"red",
	"green",
	"orange",
	"active",
	"dodgerBlue",
	"lightestGrey",
	"muted",
	"disabled",
];

LineSeparator.defaultProps = {
	textColor: "midGrey",
	lineColor: "lightGrey",
	bgColor: "solitudeLight",
};

LineSeparator.propTypes = {
	tid: string,
	children: node,
	textColor: oneOf(avaibleColors),
	lineColor: oneOf(avaibleColors),
	bgColor: oneOf(avaibleColors),
	margin: bool,
};

export default LineSeparator;
