import React, { Component } from "react";
import { connect } from "react-redux";
import { string, object, oneOf } from "prop-types";
import { List, Map, fromJS } from "immutable";
import { withRouter } from "../../../interfaces/router";
import {
	fetchMultipleAttachedDocuments,
	selectAttachment,
} from "../../../actions/attachments.actions";
import {
	fetchMeeting,
	patchMeetingLocal,
} from "../../../actions/meetings.actions";
import MeetingsDocumentsList from "../../../dumb-components/meetings/documents-list/documents-list";
import ProtocolDocumentsList from "../../../dumb-components/meetings/protocol-documents-list/protocol-documents-list";
import { flattenAgendaItems } from "../../../components/helpers/meeting.helper";
import DropzoneContainer from "../../shared/dropzone.container";
import DocumentsItemDropdownContainer from "./meetings-document-item-dropdown.container";
import ManageAttachmentModalContainer from "../meeting/standard/manage-attachment-modal.container";
import history from "../../../interfaces/history";

import {
	LIVE_MEETINGS_AGENDA_UPDATE,
	LIVE_MEETINGS_AGENDA_DELETE,
} from "../../../constants/live-update";

import {
	EVENT_TYPE_DOCUMENT_UPDATE,
	EVENT_TYPE_ATTACHED_DOCUMENT_CREATE,
	OBJ_TYPE_MEETING,
	OBJ_TYPE_MEETING_PROTOCOL,
} from "/shared/constants";

const exludeObjTypes = [OBJ_TYPE_MEETING_PROTOCOL];

class MeetingDocumentsListContainer extends Component {
	state = {
		attachments: Map(),
	};

	static propTypes = {
		basePath: string,
		location: object,
		viewMode: oneOf(["meeting", "protocol"]),
		params: object,
	};

	static defaultProps = {
		viewMode: "meeting",
	};

	componentDidMount = () => {
		const { meeting } = this.props;

		if (meeting) {
			// Create flat list out of all agenda items
			const agendaItems = this.flattenAgendaItems();
			// If there are agendaItems, parse them and fetch the documents
			this.fetchDocuments(agendaItems);
		}
	};

	componentDidUpdate = (prevProps) => {
		const { meeting, attachments } = this.props;

		if (meeting !== prevProps.meeting) {
			const agendaItems = this.flattenAgendaItems();
			this.fetchDocuments(agendaItems);
		}

		// todo: select the first item in list
		if (attachments !== prevProps.attachments) {
			this.parseAttachments();
		}

		this.checkLiveUpdateEvents();
	};

	checkLiveUpdateEvents = () => {
		const { meetingAudit, documentAudit, meeting, fetchMeeting } = this.props;
		const { documents } = this.state;
		const agendaUpdated = meetingAudit.get(LIVE_MEETINGS_AGENDA_UPDATE, Map());
		const agendaDeleted = meetingAudit.get(LIVE_MEETINGS_AGENDA_DELETE, Map());
		const documentUpdated = documentAudit.get(
			EVENT_TYPE_DOCUMENT_UPDATE,
			Map(),
		);
		const documentCreated = documentAudit.get(
			EVENT_TYPE_ATTACHED_DOCUMENT_CREATE,
			Map(),
		);

		const agendaItems = this.flattenAgendaItems();

		const objIds = [];
		documents &&
			documents.forEach((doc) => {
				if (doc.get("creatorObjId")) {
					objIds.push(doc.get("creatorObjId"));
				}
			});

		if (
			documentCreated.get("r") === true &&
			objIds.includes(documentCreated.getIn(["metadata", "objId"]))
		) {
			this.fetchDocuments(agendaItems);
		}

		if (
			agendaUpdated.get("refresh") === true ||
			agendaDeleted.get("refresh") === true
		) {
			meeting && fetchMeeting(meeting.get("id"));
		}

		if (documentUpdated.get("r") === true && agendaItems) {
			this.fetchDocuments(agendaItems);
		}
	};

	// goToFirstItem = () => {
	// 	const { sortedDocuments } = this.state
	// 	const { basePath } = this.props
	// 	let documentId
	//
	// 	if (!sortedDocuments) {
	// 		return
	// 	}
	//
	// 	if (sortedDocuments.get('general') && sortedDocuments.get('general').size > 0) {
	// 		documentId = sortedDocuments.getIn(['general', 0, 'id'])
	// 	} else if (sortedDocuments.get('topics') && sortedDocuments.get('topics').size > 0) {
	// 		documentId = sortedDocuments.getIn(['topics', 0, 'id'])
	// 	}
	//
	// 	documentId && this.onDocumentClick(`${basePath}/${documentId}`, documentId)
	// }

	fetchDocuments = (agendaItems) => {
		const { fetchMultipleAttachedDocuments, isSimpleMode } = this.props;
		let objIds = List();

		agendaItems.forEach((item) => {
			const objId = item.get("objId");

			if (objId && !(isSimpleMode && item.get("isSuggested"))) {
				objIds = objIds.push(objId);
			}
		});

		if (objIds.size === 0) {
			return;
		}

		fetchMultipleAttachedDocuments(objIds);
	};

	parseAttachments = () => {
		let { attachments } = this.props;

		if (!attachments) {
			return;
		}

		const agendaItems = this.flattenAgendaItems();
		let objIds = agendaItems.map((item) => item.get("objId"));

		let newAttachments = Map();

		attachments.forEach((docs, objId) => {
			if (!objIds.includes(objId)) {
				return;
			}

			docs = docs
				.filter((doc) => {
					const shouldBeExculdedByObjType =
						doc
							.get("links")
							?.some((link) => exludeObjTypes.includes(link.get("objType"))) ||
						false;

					if (shouldBeExculdedByObjType) {
						return false;
					}

					return true;
				})
				.sort((a, b) => a.get("title", "").localeCompare(b.get("title", "")));

			if (docs.size > 0) {
				newAttachments = newAttachments.set(objId, docs);
			}
		});

		this.setState({ attachments: newAttachments });
	};

	flattenAgendaItems = () => {
		const { meeting, i18n } = this.props;
		const meetingId = meeting.get("id");
		let agendaItems = Map();

		let flatAgendaItems = flattenAgendaItems(meeting);
		flatAgendaItems = flatAgendaItems.filter(
			(obj) => !obj.get("isSuggested") && !obj.get("archived"),
		);

		// Insert "General" (attachments from meeting) at the beginning
		flatAgendaItems = flatAgendaItems.unshift(
			Map({
				proposal: i18n.messages["meetings.documents.section.general"],
				isGeneral: true,
				objId: meetingId,
			}),
		);

		flatAgendaItems.forEach((item) => {
			agendaItems = agendaItems.set(item.get("objId"), item);
		});

		return agendaItems;
	};

	onDocumentClick = (objId, path, attachmentId) => {
		const { selectAttachment, history } = this.props;

		selectAttachment(objId, attachmentId);
		history.push(`${path}?objId=${objId}`);
	};

	onUpload = () => {
		const { patchMeetingLocal } = this.props;
		const { meeting } = this.props;
		const numOfDocuments =
			meeting.getIn(["computedValues", "numOfDocuments"]) + 1;
		const dataToUpdate = fromJS({
			computedValues: { numOfDocuments },
		});

		patchMeetingLocal(dataToUpdate);
	};

	onBadgeClick = (docId, agendaItemId) => {
		const {
			basePath,
			location: { search },
			history,
		} = this.props;
		const pathname = basePath.replace("/documents", `/agenda/${agendaItemId}`);

		history.push({ pathname, search });
	};

	renderDropzone = () => {
		const { meeting } = this.props;

		return (
			<DropzoneContainer
				fieldName="attachedDocuments"
				onUpload={this.onUpload}
				objId={meeting && meeting.get("id")}
				objType={OBJ_TYPE_MEETING}
				mode="attachment"
				multiple
			/>
		);
	};

	renderDropdown = (documentId, document, objId, objType) => {
		const {
			basePath,
			location: { search },
		} = this.props;
		const { attachments } = this.state;

		return (
			<DocumentsItemDropdownContainer
				basePath={basePath}
				search={search}
				documentId={documentId}
				document={document}
				attachments={attachments}
				objId={objId}
				objType={objType}
			/>
		);
	};

	renderMeetingsDocumentsList = () => {
		const { basePath, location, meeting, isSimpleMode } = this.props;
		const { attachments } = this.state;
		const currentUrl = location.pathname;
		const meetingId = meeting.get("id");
		const agendaItems = this.flattenAgendaItems();

		return (
			<>
				<MeetingsDocumentsList
					basePath={basePath}
					currentUrl={currentUrl}
					attachments={attachments}
					agendaItems={agendaItems}
					meetingId={meetingId}
					onDocumentClick={this.onDocumentClick}
					onBadgeClick={this.onBadgeClick}
					renderDropzone={isSimpleMode ? undefined : this.renderDropzone}
					renderDropdown={this.renderDropdown}
				/>
			</>
		);
	};

	renderProtocolDocumentsList = () => {
		const { onOpenFileInViewer, onDownloadFile } = this.props;
		let { attachments } = this.state;

		if (!attachments || attachments?.size === 0) {
			return true;
		}

		attachments = attachments.sort((a, b) =>
			a.get("title", "").localeCompare(b.get("title", "")),
		);
		const agendaItems = this.flattenAgendaItems();

		return (
			<ProtocolDocumentsList
				attachments={attachments}
				agendaItems={agendaItems}
				onOpenFileInViewer={onOpenFileInViewer}
				onDownloadFile={onDownloadFile}
			/>
		);
	};

	render = () => {
		const { viewMode, meeting, activeModalObjId } = this.props;
		const meetingId = meeting?.get("id");
		const objId = activeModalObjId ? activeModalObjId : meetingId;

		return (
			<>
				{viewMode === "meeting" && this.renderMeetingsDocumentsList()}
				{viewMode === "protocol" && this.renderProtocolDocumentsList()}
				<ManageAttachmentModalContainer
					objId={objId}
					doNotUnselectAttachmentOnClose={true}
				/>
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meeting: store.meetings.get("meeting", Map()) || Map(),
		isSimpleMode: store.meetings.getIn([
			"meeting",
			"computedValues",
			"isSimpleMode",
		]),
		i18n: store.i18n,
		meetingAudit: store.audit.get("meetings"),
		documentAudit: store.audit.get("documents"),
		attachment: store.attachments.get("attachment"),
		attachments: store.attachments.get("attachments"),
		activeModalObjId: store.modals.getIn(["activeModal", "options", "objId"]),
	};
};

const mapActionsToProps = {
	fetchMultipleAttachedDocuments,
	selectAttachment,
	fetchMeeting,
	patchMeetingLocal,
};

export default withRouter(
	connect(mapStoreToProps, mapActionsToProps)(MeetingDocumentsListContainer),
);
