import React, { Component } from "react";
import { connect } from "react-redux";
import ShareTypesTable from "../../../framework/share-types";
import { List } from "immutable";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../../../actions/notify.actions";
import sharesValidator from "../../../helpers/shares.validator";
import { Button } from "@/components/ui/button";
import i18n from "../../../../i18n";
import { Trans } from "react-i18next";

const { t } = i18n;

class ShareTypes extends Component {
	constructor(props) {
		super(props);
		this.step = 1;
	}

	onChange(transaction) {
		this.props.onChange(transaction);
	}

	goToNext() {
		const { setupType } = this.props;
		const numOfTotalShares = this.props.transaction.getIn([
			"handlerData",
			"initialShareData",
			"numOfTotalShares",
		]);
		let types = this.props.transaction.getIn(
			["handlerData", "initialShareData", "types"],
			List(),
		);
		try {
			sharesValidator.validateShareTypes(types, numOfTotalShares, setupType);
			this.props.onNext(this.step + 1);
			this.props.jumpToStep(this.step + 1);
		} catch (e) {
			this.props.addErrorNotification({
				text: e.message,
			});
		}
	}

	goToPrevious() {
		this.props.onPrevious(this.step - 1);
		this.props.jumpToStep(this.step - 1);
	}

	render() {
		const { setupType } = this.props;
		return (
			<div className="h-full flex flex-col gap-12">
				<div className="max-w-screen-lg self-center text-center flex flex-col gap-4">
					<Trans i18nKey="share_types_information" />
				</div>
				<div className="i-panel i-panel--white">
					<div className="i-panel__body">
						<ShareTypesTable
							setupType={setupType}
							onChange={this.onChange.bind(this)}
							transaction={this.props.transaction}
						/>
					</div>
				</div>
				<div className="flex justify-center gap-4">
					<Button variant="outline" onClick={this.goToPrevious.bind(this)}>
						{t("previous")}
					</Button>
					<Button onClick={this.goToNext.bind(this)}>{t("next")}</Button>
				</div>
			</div>
		);
	}
}

export default connect(null, { addErrorNotification, addInfoNotification })(
	ShareTypes,
);
