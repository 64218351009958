import React, { PureComponent } from "react";
import { bool, func } from "prop-types";
import styled, { css } from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import moment from "../../../modules/moment.module";
import Text from "../../shared/text/text";
import Icon from "../../shared/icon/icon";
import ProfileImage from "../../shared/profile-image/profile-image";
import Button from "../../shared/button/button";
import { AVAIBLE_TASK_STATUS } from "../../../constants/tasks";

import DndDragHandlers, {
	hoverStyling,
} from "../../shared/dnd-drag-handlers/dnd-drag-handlers";
import DropdownMenuContainer from "../../../containers/shared/dropdown-menu.container";
import { setRef } from "../../../components/helpers/refs.helper";
import ButtonTransparentIcon from "../../shared/button/src/button-transparent-icon";
import DropdownIconItem from "../../shared/dropdown-item/dropdown-icon-item";

const StyledButtonWrapper = styled.div`
	padding: ${(props) => props.theme.spacing[3]};
	text-align: right;
	display: none;

	/* Display the edit button if task is being edited */
	${(props) =>
		props.isEditing &&
		css`
			display: block;
		`}
`;

const StyledButtonWrapperSpacing = styled.div`
	min-width: 79px;
`;

const StyledTask = styled.div`
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	height: 50px;

	${(props) =>
		props.onClick &&
		css`
			cursor: pointer;
		`}

	:hover ${StyledButtonWrapper} {
		display: block;
	}
`;

const StyledDndDragHandlers = styled(DndDragHandlers)`
	border-radius: var(--border-radius) 0 0 var(--border-radius);
	${StyledTask}:hover & {
		${hoverStyling}
	}
`;

const StyledUserImageWrapper = styled.div`
	width: 50px;
	height: 100%;
`;

const StyledStatus = styled.div`
	width: 50px;
	height: 100%;
	background-color: ${(props) => {
		if (props.overdue) {
			return props.theme.colors.red;
		}

		return props.theme.colors[props.statusColor];
	}};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const StyledTitle = styled.div`
	flex: 1;
	padding: ${(props) => props.theme.spacing[3]}
		${(props) => (props.hasBadge ? "144px" : props.theme.spacing[4])}
		${(props) => props.theme.spacing[3]} ${(props) => props.theme.spacing[4]};
	height: 100%;
	display: flex;
	flex-direction: column;
	min-width: 0;
	justify-content: center;
`;

const StyledListItem = styled.div`
	display: flex;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.6);
	border-radius: var(--border-radius);
	border: 1px solid hsl(var(--border-edge));
	margin-bottom: ${(props) => props.theme.spacing[2]};

	:hover {
		background-color: hsl(var(--background));
	}

	${(props) => {
		if (props.active) {
			return css`
				border: 2px solid hsl(var(--primary));
				background-color: hsl(var(--background));
			`;
		}
	}}

	${(props) =>
		!props.withDnd &&
		css`
			:last-child {
				margin-bottom: 16px;
			}
		`}
`;

const StyledDraggableWrapper = styled.div`
	:last-child {
		margin-bottom: 14px;
		/*2px is already used in Task item */
	}
`;

const StyledDropdownWrapper = styled.div`
	margin-right: ${(props) => props.theme.spacing[3]};
`;

export default class TaskList extends PureComponent {
	static propTypes = {
		withDnd: bool,
		isDeleted: bool,
		renderDropdown: func,
		onDeleteTask: func,
	};

	static defaultProps = {
		withDnd: true,
	};

	moreActionBtnRefs = {};

	getStatusIcon = (isOverdue) => {
		const { status } = this.props;

		if (isOverdue && status === "to_do") {
			return null;
		}

		switch (status) {
			case "to_do":
				return "faTimes";
			case "done":
				return "faCheck";
			default:
				return null;
		}
	};

	renderMoreActionButton = (taskId) => {
		const { onEditClick, onDeleteTask } = this.props;

		return (
			<DropdownMenuContainer
				ref={setRef.bind(this, this.moreActionBtnRefs, taskId)}
				halignMenu="right"
				noMaxWidth
				buttonNoHorizontalPadding
				inline
				renderRaw={<ButtonTransparentIcon icon="faEllipsisV" size="sml" />}
			>
				<DropdownIconItem
					icon="faCogs"
					tid="meetings.tasks.dropdown.manage_task"
					onClick={() => {
						onEditClick && onEditClick(taskId);
					}}
				/>

				<DropdownIconItem
					icon="faTrashAlt"
					tid="meetings.tasks.dropdown.delete_task"
					onClick={() => {
						onDeleteTask && onDeleteTask(taskId);
					}}
				/>
			</DropdownMenuContainer>
		);
	};

	renderTaskComponent = (provided) => {
		const {
			title,
			status,
			taskId,
			dueAt,
			profileImage,
			index,
			projectId,
			active,
			onEditClick,
			showEditButton,
			onTaskClick,
			isEditing,
			isDragDisabled,
			taskEditorComponent,
			renderBadge,
			isDeleted,
			withDnd,
			assignedToUserId,
			renderDropdown,
			showMoreAction,
		} = this.props;

		const completed = status === "done";
		const isRequired = status !== "not_required";
		const overdue =
			dueAt && !completed && isRequired
				? moment(dueAt).diff(moment(), "days") < 0
				: false;
		const statusIcon = this.getStatusIcon(overdue);
		const taskStatusObject = AVAIBLE_TASK_STATUS.find(
			(s) => s.get("value") === status,
		);
		let statusColor = "solitudeDark";

		if (taskStatusObject) {
			statusColor = taskStatusObject.get("statusColor");
		}

		const badge = renderBadge && renderBadge(taskId, projectId);

		return (
			<StyledListItem
				active={active}
				hoverColor="lightestGrey"
				id={taskId}
				withDnd={withDnd}
			>
				<StyledTask
					onClick={() => {
						onTaskClick && onTaskClick(taskId);
					}}
				>
					<StyledDndDragHandlers
						isDragDisabled={isDragDisabled}
						dragHandleProps={provided && provided.dragHandleProps}
					/>

					<StyledStatus
						status={status || null}
						overdue={overdue}
						statusColor={statusColor}
					>
						{statusIcon && (
							<Icon icon={statusIcon} color="white" type="solid" />
						)}
					</StyledStatus>
					<StyledUserImageWrapper>
						<ProfileImage
							image={
								profileImage
									? `/api/users/public/images/${profileImage}-80x80?userId=${assignedToUserId}`
									: null
							}
						/>
					</StyledUserImageWrapper>
					<StyledTitle hasBadge={badge !== null}>
						{!isEditing && (
							<React.Fragment>
								{title && <Text singleLine={true}>{title}</Text>}
								{!title && (
									<Text
										singleLine
										tid="tasks.list.write_a_name"
										color="muted"
									/>
								)}
							</React.Fragment>
						)}
						{badge}
						{isEditing &&
							taskEditorComponent(
								projectId,
								title,
								index,
								undefined,
								isDeleted,
							)}
					</StyledTitle>
					{showEditButton && (
						<StyledButtonWrapperSpacing>
							<StyledButtonWrapper isEditing={isEditing}>
								<Button
									onClick={() => {
										onEditClick && onEditClick(taskId);
									}}
									tid="generic.edit"
								/>
							</StyledButtonWrapper>
						</StyledButtonWrapperSpacing>
					)}
				</StyledTask>
				{renderDropdown && (
					<StyledDropdownWrapper>
						{renderDropdown(taskId)}
					</StyledDropdownWrapper>
				)}
				{showMoreAction && (
					<StyledDropdownWrapper>
						{this.renderMoreActionButton(taskId)}
					</StyledDropdownWrapper>
				)}
			</StyledListItem>
		);
	};

	render = () => {
		const { index, taskId, isDragDisabled, withDnd } = this.props;

		if (withDnd) {
			return (
				<Draggable
					draggableId={taskId}
					index={index}
					isDragDisabled={isDragDisabled}
				>
					{(provided) => (
						<StyledDraggableWrapper
							ref={provided.innerRef}
							{...provided.draggableProps}
						>
							{this.renderTaskComponent(provided)}
						</StyledDraggableWrapper>
					)}
				</Draggable>
			);
		}

		return this.renderTaskComponent();
	};
}
