import React, { PureComponent } from "react";
import { string, object, oneOfType, bool, func, number } from "prop-types";
import styled from "styled-components";
import Text from "../../shared/text/text";
import ProfileImage from "../../shared/profile-image/profile-image";
import IconButton from "../../shared/button/src/button-transparent-icon";

import DndDragHandlers, {
	hoverStyling,
} from "../../shared/dnd-drag-handlers/dnd-drag-handlers";

const StyledAgendaItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	height: 50px;
	cursor: ${(props) => (props.disabled ? "default" : "pointer")};
	width: 100%;
	border: ${(props) =>
		props.active
			? "2px solid hsl(var(--primary))"
			: "1px solid hsl(var(--border-edge))"};
	background-color: ${(props) =>
		props.active ? "hsl(var(--background))" : "transparent"};
	border-radius: var(--border-radius);
`;

const StyledDndDragHandlers = styled(DndDragHandlers)`
	${StyledAgendaItem}:hover & {
		${hoverStyling}
	}
`;

const StyledUserImageWrapper = styled.div`
	min-width: 50px;
	height: 100%;
`;

const StyledContent = styled.div`
	overflow: hidden;
	flex: 0 1 auto;
	padding: ${(props) => props.theme.spacing[3]}
		${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[3]}
		${(props) => props.theme.spacing[4]};
	height: 100%;
	display: flex;
	flex-direction: column;
	width: 1000px;
`;

const StyledTitle = styled.div`
	display: flex;
	flex-direction: row;
`;

const StyledSubtitle = styled.div`
	display: flex;
`;

const StyledInputWrapper = styled.div`
	display: flex;
	flex: 1;
`;

const StyledProgress = styled.div`
	min-width: 44px;
	max-width: 44px;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0 ${(props) => props.theme.spacing[4]} 0 0;
`;

const StyledDropdownWrapper = styled.div`
	> :first-child {
		margin-right: ${(props) => props.theme.spacing[3]};
	}
`;

export default class AgendaItem extends PureComponent {
	static propTypes = {
		id: string,
		num: oneOfType([string, number]),
		outcome: string,
		presenterName: string,
		presetnterId: string,
		presenterProfileImage: string,
		duration: number,
		name: string,
		itemType: string,
		progress: string,
		hasUnreadFeedback: bool,
		isSuggestion: bool,
		isDragDisabled: bool,
		active: bool,
		dragHandleProps: object,
		onClick: func.isRequired,
		renderStatus: func,
		renderDuration: func,
		renderEditor: func,
		parentIndex: number,
		itemIndex: number,
		inEditMode: bool,
		level: number,
		showProgressIcon: bool,
		isSecretary: bool,
		onProgressIconClick: func,
		renderDropdown: func,
		numOfSubTopics: number,
	};

	onClick = () => {
		const { id, onClick } = this.props;

		onClick && onClick(id);
	};

	onProgressIconClick = () => {
		const { onProgressIconClick } = this.props;

		onProgressIconClick && onProgressIconClick("done");
	};

	getProgressData = () => {
		const { progress, hasUnreadFeedback } = this.props;

		if (hasUnreadFeedback) {
			return {
				icon: "faCommentAltExclamation",
				color: "orange",
				clickFn: this.onProgressIconClick,
			};
		}

		if (progress === "done") {
			return {
				icon: "faCheck",
				color: "green",
				faded: false,
			};
		}

		return {
			icon: "faCheck",
			color: "muted",
			faded: true,
			clickFn: this.onProgressIconClick,
		};
	};

	render = () => {
		const {
			num,
			presenterName,
			presenterProfileImage,
			name,
			itemType,
			isSuggestion,
			isDragDisabled,
			dragHandleProps,
			renderStatus,
			renderDuration,
			renderEditor,
			inEditMode,
			level,
			showProgressIcon,
			presenterId,
			renderDropdown,
			numOfSubTopics,
			active,
		} = this.props;

		console.log(this.props);

		let { hideProfile, hideDuration, hideOutcome } = this.props;
		const isCategory = itemType === "category";
		const styledContentCategoryProps = {
			indent: level > 1 ? (level - 1) * 50 : 0,
			isCategory,
		};
		const progressData = this.getProgressData();
		const presenterTextComponent = presenterName ? (
			<Text color="muted" singleLine>
				{presenterName}
			</Text>
		) : (
			<Text color="muted" tid="meetings.agenda.item.presenter.none_selected" />
		);
		const itemSubtitleTid = isSuggestion
			? "meetings.agenda_item.suggested_by"
			: "meetings.presenter";
		hideOutcome = hideOutcome || isCategory;
		hideProfile = hideProfile || isCategory;
		hideDuration = hideDuration || isCategory;

		return (
			<StyledAgendaItem active={active}>
				<StyledDndDragHandlers
					isDragDisabled={isDragDisabled}
					dragHandleProps={dragHandleProps}
				/>

				{!hideOutcome && renderStatus && renderStatus()}

				{!hideProfile && (
					<StyledUserImageWrapper>
						<ProfileImage
							image={
								presenterProfileImage
									? `/api/users/public/images/${presenterProfileImage}-80x80?userId=${presenterId}`
									: null
							}
						/>
					</StyledUserImageWrapper>
				)}

				{!hideDuration && renderDuration && renderDuration()}

				<StyledContent onClick={this.onClick} {...styledContentCategoryProps}>
					{!inEditMode && isSuggestion && (
						<StyledTitle>
							<Text singleLine>{name}</Text>
						</StyledTitle>
					)}
					{inEditMode && renderEditor && isSuggestion && (
						<StyledTitle>
							<StyledInputWrapper>{renderEditor()}</StyledInputWrapper>
						</StyledTitle>
					)}
					{!inEditMode && !isSuggestion && (
						<StyledTitle>
							<Text singleLine>
								{num}. {name}
							</Text>
						</StyledTitle>
					)}
					{inEditMode && renderEditor && !isSuggestion && (
						<StyledTitle>
							<Text tag="div">{num}.&nbsp;</Text>
							<StyledInputWrapper>{renderEditor()}</StyledInputWrapper>
						</StyledTitle>
					)}
					<StyledSubtitle>
						{isCategory ? (
							<Text
								color="muted"
								tid={
									numOfSubTopics > 1
										? "meetings.contains_multiple_sub_topics"
										: "meetings.contains_sub_topic"
								}
								singleLine
							/>
						) : (
							<span>
								<Text color="muted" tid={itemSubtitleTid} />
								<Text color="muted" singleLine>
									&nbsp;|{" "}
								</Text>
								{presenterTextComponent}
							</span>
						)}
					</StyledSubtitle>
				</StyledContent>

				{!isCategory && showProgressIcon && (
					<StyledProgress>
						<IconButton
							icon={progressData.icon}
							size="sm"
							type="solid"
							color={progressData.color}
							faded={progressData.faded}
							onClick={progressData.clickFn}
						/>
					</StyledProgress>
				)}

				{renderDropdown && (
					<StyledDropdownWrapper>{renderDropdown()}</StyledDropdownWrapper>
				)}
			</StyledAgendaItem>
		);
	};
}
