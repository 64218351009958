import React, { forwardRef } from "react";
import NumberFormat from "react-number-format";

const NumberFormatPercentage = (props, ref) => {
	const { onChange, ...other } = props;

	const MIN_LIMIT = 0;
	const MAX_LIMIT = 100;

	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			isNumericString
			decimalSeparator={","}
			isAllowed={(values) => {
				const { value } = values;

				let leadingZero = false;
				if (
					value.split("").length > 1 &&
					value.charAt(0) === "0" &&
					value.charAt(1) !== "."
				) {
					leadingZero = true;
				}

				let leadingNegative = false;
				if (value.split("").length > 1 && value.charAt(0) === "-") {
					leadingNegative = true;
				}

				let isHundredAndDecimal = false;
				if (value === "100.") {
					isHundredAndDecimal = true;
				}

				return (
					value >= MIN_LIMIT &&
					value <= MAX_LIMIT &&
					!leadingZero &&
					!leadingNegative &&
					!isHundredAndDecimal
				);
			}}
			onValueChange={(values) => {
				onChange({
					target: {
						value: values.value,
					},
				});
			}}
		/>
	);
};

export default forwardRef(NumberFormatPercentage);
