import React, { Component } from "react";
import { connect } from "react-redux";
import { func } from "prop-types";
import { ButtonTransparentIcon } from "../../dumb-components/shared/button";
import Tooltip from "../../dumb-components/shared/tooltip/tooltip";

const BTN_STATES = {
	default: {
		tid: "tooltip.investors.action.advanced_filter",
	},
	hasAppliedFilters: {
		tid: "tooltip.investors.action.advanced_filter.active",
		delayShow: "instant",
	},
};

class ShareholdersAdvancedFilterBtnContainer extends Component {
	static propTypes = {
		onClick: func,
	};

	state = {
		iconColor: "midGrey",
	};

	componentDidMount = () => {
		this.colorIcon();
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.hasAppliedFilters !== this.props.hasAppliedFilters) {
			this.colorIcon();
		}
	};

	onClick = () => {
		const { onClick } = this.props;

		onClick && onClick();
	};

	colorIcon = () => {
		const { hasAppliedFilters } = this.props;
		const iconColor = hasAppliedFilters ? "java" : "midGrey";
		this.setState({ iconColor });
	};

	render = () => {
		const { hasAppliedFilters } = this.props;
		const { iconColor } = this.state;

		return (
			<Tooltip states={BTN_STATES} activeState={hasAppliedFilters}>
				<ButtonTransparentIcon
					onClick={this.onClick}
					size="sml"
					icon="faSlidersH"
					color={iconColor}
					notificationBadge={hasAppliedFilters}
				/>
			</Tooltip>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		hasAppliedFilters: store.investors.get("hasAppliedFilters"),
		filterBy: store.investors.get("filterBy"),
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ShareholdersAdvancedFilterBtnContainer);
