import React, { useEffect, useState } from "react";
import { AgChartsReact } from "ag-charts-react";
import { array } from "prop-types";
import { MUItheme } from "../../../../../MUItheme";
import theme from "../../../../../theme";

const AgGridChart = ({
	series,
	axes,
	data,
	showNavigator,
	howManyToShow,
	singleFillColor,
	chartRef,
	themeOverrides,
	labelFormatter,
	legend,
	themePalette,
}) => {
	const [options, setOptions] = useState();

	useEffect(() => {
		const fontSize = 13;
		const fontWeight = 600;
		const fontFamily = theme.fontFamilies.openSans;
		let primaryColor = MUItheme.palette.chart.primary;
		let secondaryColor = MUItheme.palette.chart.secondary;

		if (singleFillColor) {
			primaryColor = undefined;
			secondaryColor = MUItheme.palette.chart.primary;
		}

		const labelKeysAndValues = {
			fontSize: fontSize,
			fontWeight: fontWeight,
			fontFamily: fontFamily,
			formatter: labelFormatter,
		};

		let _legend = {
			enabled: false,
		};

		if (legend) {
			_legend = legend;

			_legend.item.label = {
				..._legend.item.label,
				fontSize: fontSize,
				fontWeight: fontWeight,
				fontFamily: fontFamily,
			};
		}

		if (axes.length === 2) {
			axes[1].label = {
				color: secondaryColor,
				...labelKeysAndValues,
			};
		} else if (axes.length === 3) {
			axes[1].label = {
				color: secondaryColor,
				...labelKeysAndValues,
			};
			axes[2].label = {
				color: primaryColor,
				...labelKeysAndValues,
			};
		}

		setOptions({
			autoSize: true,
			data: data,
			padding: {
				top: 32,
				right: 29,
				bottom: 33,
				left: 24,
			},
			theme: {
				...(themePalette
					? themePalette
					: {
							palette: {
								fills: [primaryColor, secondaryColor],
								strokes: [primaryColor, secondaryColor],
							},
					  }),
				themeOverrides,
			},
			series: series,
			axes: axes,
			navigator: {
				enabled: showNavigator ? showNavigator : false,
				height: 3,
				strokeWidth: 0,
				min: howManyToShow,
				max: 1,
				mask: {
					fill: "6c6c6c",
					strokeWidth: 0.5,
					fillOpacity: 0.7,
				},
				minHandle: {
					height: 14,
					gripLineLength: 4,
				},
				maxHandle: {
					height: 14,
					gripLineLength: 4,
				},
			},
			legend: _legend,
		});
	}, [data]);

	return !options ? null : (
		<div ref={chartRef} style={{ height: "100%" }}>
			<AgChartsReact options={options} />
		</div>
	);
};

AgGridChart.propTypes = {
	data: array,
};

AgGridChart.defaultProps = {};

export default AgGridChart;
