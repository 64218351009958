import React, { useCallback, useEffect, useState, useRef } from "react";

import { fromJS } from "immutable";
import {
	validateEmail,
	isRequired,
} from "../../../../modules/validation.module";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
	updateContactInAddressBook,
	removeContactFromAddressBook,
} from "../../../../actions/user.actions";
import { EmailCellRenderer } from "../../../../dumb-components/shared/ag-grid/renderers/email.cell-renderer";
import { func } from "prop-types";
import AgGrid from "../../../../dumb-components/shared/ag-grid/ag-grid";
import { usePeopleModalContext } from "../people-modal.context";
import { TAB_MY_PERSONAL_CONTACTS } from "../../select-user-modal/select-user-modal.constants";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AddContactButton } from "./AddContactButton";
import { DropDown } from "../../../../mui-components/dropdown/DropDown";
import MenuItemIcon from "../../../../mui-components/dropdown/menu/menu-item-icon";
import IconButton from "../../../../mui-components/button/icon-button";
import { UnselectableRenderer } from "../../../../dumb-components/shared/ag-grid/renderers/unselectable.renderer";
import { CheckboxRenderer } from "../../../../dumb-components/shared/ag-grid/renderers/checkbox.renderer";
import { TextOnlyTooltip } from "../../../../dumb-components/shared/ag-grid/tooltips/text-only-tooltip";
import { CheckboxInHeaderRenderer } from "../../../../dumb-components/shared/ag-grid/renderers/checkbox-in-header.renderer";
import { ComboBox } from "../../../../dumb-components/shared/ag-grid/editors/ComboBox";

function PersonalContactsGrid({
	filterFunction,
	disableFunction,
	usePagination,
}) {
	const {
		activeTab,
		setSelectedPersonalContacts,
		selectedPersonalContacts,
		floatingFilter,
		canCopyToMultipleCompanyAddressbooks,
		copyPeopleToCompanyContacts,
		personalContactsHasBeenCopied,
		setPersonalContactsHasBeenCopied,
		setIsFloatingFilterActive,
	} = usePeopleModalContext();
	const addressbook = useSelector((state) =>
		state.user.getIn(["userObj", "addressBook"]),
	);
	const companyName = useSelector((state) => state.company.company?.name);
	const prevAndCurrentCompanies = useSelector(
		(state) => state.company.companies,
	).toJS();
	const numOfCurrentCompanies = prevAndCurrentCompanies.filter(
		(company) => company.isCanceled === false,
	).length;
	const { t } = useTranslation();
	const gridRef = useRef();
	const [rowData, setRowData] = useState();
	const dispatch = useDispatch();

	useEffect(() => {
		if (addressbook) {
			let _addressbook = addressbook.toJS();

			if (typeof filterFunction === "function") {
				_addressbook = filterFunction(_addressbook);
			}

			_addressbook = _addressbook.map((contact) => {
				let isSelectable = true;
				let tooltipTid;
				let restrictionIcon;
				let restrictionIconSize = 21;

				if (!validateRow({ data: contact })) {
					isSelectable = false;
					tooltipTid =
						"people_modal.grid.selected.tooltip.email_or_name_is_missing";
					restrictionIcon = "faExclamationTriangle";
					restrictionIconSize = 17;
				} else if (typeof disableFunction === "function") {
					const result = disableFunction(fromJS(contact));

					if (typeof result === "boolean") {
						isSelectable = !result;
						tooltipTid =
							!isSelectable &&
							"people_modal.grid.selected.tooltip.not_selectable";
						restrictionIcon = "faExclamationSquare";
					} else {
						isSelectable = !result.isDisabled;
						tooltipTid =
							!isSelectable &&
							(result.tooltip ??
								"people_modal.grid.selected.tooltip.not_selectable");
						restrictionIcon = result.icon ?? "faExclamationSquare";
					}
				}

				contact.selectable = isSelectable;
				contact.tooltipTidOnSelectedColumn = tooltipTid;
				contact.selected =
					isSelectable &&
					selectedPersonalContacts &&
					selectedPersonalContacts.some((person) => person.id === contact.id);
				contact.restrictionIcon = restrictionIcon;
				contact.restrictionIconSize = restrictionIconSize;
				return contact;
			});

			setRowData(_addressbook);
		}
		return () => {};
	}, [addressbook]);

	useEffect(() => {
		if (personalContactsHasBeenCopied) {
			gridRef.current.api.forEachNode((node) => {
				node.setSelected(false);
			});

			// reset to false so that new contacts can be copied
			setPersonalContactsHasBeenCopied(false);
		}
	}, [personalContactsHasBeenCopied]);

	const validateRow = (rowNode) => {
		const { name, email } = rowNode.data;

		return isRequired(name) && validateEmail(email);
	};

	const isRowSelectable = (rowNode) => {
		if (!rowNode.data) {
			return true;
		}

		return rowNode.data.selectable;
	};

	const onCellValueChanged = (params) => {
		const { node, data } = params;

		dispatch(updateContactInAddressBook(data.id, data));

		const rowIsSelectable = isRowSelectable(node);
		if (rowIsSelectable) {
			node.setRowSelectable(true);
			node.setSelected(true);
		}
	};

	const onSelectionChanged = ({ api }) => {
		setSelectedPersonalContacts(
			api.getSelectedNodes().map((contact) => contact.data),
		);

		const newRowData = rowData.map((row) => {
			const node = api.getRowNode(row.id);
			row.selected = node.selected;
			return row;
		});
		setRowData(newRowData);
	};

	let copyButtonTid = "";
	if (canCopyToMultipleCompanyAddressbooks) {
		copyButtonTid =
			numOfCurrentCompanies === 1
				? "people_modal.grid.more_actions.save_contact_to_my_company_contacts.dropdown_item"
				: "people_modal.grid.more_actions.save_contact_to_company_contacts";
	} else {
		copyButtonTid =
			"people_modal.grid.more_actions.save_contact_to_my_company_contacts.dropdown_item";
	}

	const columnDefs = () => {
		const defs = [
			{
				field: "selected",
				headerName: "",
				cellRendererSelector: ({ node, data }) => {
					if (!node.selectable) {
						return {
							component: UnselectableRenderer,
							params: {
								icon: data.restrictionIcon,
								iconSize: data.restrictionIconSize,
							},
						};
					}

					return {
						component: CheckboxRenderer,
					};
				},
				headerComponent: CheckboxInHeaderRenderer,
				headerComponentParams: {
					filteredOnly: true,
				},
				lockVisible: true,
				minWidth: 38,
				tooltipField: "selected",
				tooltipComponent: TextOnlyTooltip,
				tooltipComponentParams: {
					tooltipTid: ({ data }) => {
						return data.tooltipTidOnSelectedColumn;
					},
				},
				cellClass: "checkbox-cell",
				headerClass: "checkbox-cell",
				filter: false,
			},
			{
				field: "name",
				headerName: t("people_modal.grid.headers.name"),
				flex: 1,
				minWidth: 306,
				filter: "agTextColumnFilter",
				editable: true,
				cellEditor: ComboBox,
				cellEditorParams: {
					validate(value) {
						return isRequired(value);
					},
					errorTid: "people_modal.grid.errors.name",
					options: () => {
						return rowData.map((contact) => ({
							id: contact.id,
							label: contact.name,
						}));
					},
					useRegularInput: true,
				},
				onCellValueChanged,
				singleClickEdit: true,
			},
			{
				field: "phone",
				headerName: t("people_modal.grid.headers.phone"),
				minWidth: 150,
				filter: "agTextColumnFilter",
				editable: true,
				cellEditor: ComboBox,
				cellEditorParams: {
					options: () => {
						return rowData
							.filter((contact) => !!contact.phone)
							.map((contact) => ({ id: contact.id, label: contact.phone }));
					},
					useRegularInput: true,
				},
				onCellValueChanged,
				singleClickEdit: true,
			},
			{
				field: "email",
				headerName: t("people_modal.grid.headers.email"),
				minWidth: 336,
				cellRenderer: EmailCellRenderer,
				filter: "agTextColumnFilter",
				editable: true,
				cellEditor: ComboBox,
				cellEditorParams: {
					validate(value) {
						return validateEmail(value);
					},
					errorTid: "people_modal.grid.errors.email",
					options: () => {
						return rowData.map((contact) => ({
							id: contact.id,
							label: contact.email,
						}));
					},
					useRegularInput: true,
				},
				onCellValueChanged,
				singleClickEdit: true,
			},
			{
				field: "tags",
				headerName: t("people_modal.grid.headers.category"),
				minWidth: 200,
				filter: "agTextColumnFilter",
				editable: true,
				cellEditor: ComboBox,
				cellEditorParams: {
					options: () => {
						return rowData
							.filter((contact) => !!contact.tags)
							.map((contact) => ({ id: contact.id, label: contact.tags }));
					},
					useRegularInput: true,
				},
				onCellValueChanged,
				singleClickEdit: true,
			},
			{
				colId: "actions",
				minWidth: 36,
				width: 36,
				maxWidth: 36,
				sortable: false,
				filter: false,
				type: "rightAligned",
				cellRenderer: (params) => {
					return (
						<DropDown
							alignMenu="right"
							button={({ params }) => (
								<IconButton noBorder icon="faEllipsisV" {...params} />
							)}
						>
							<MenuItemIcon
								icon="faSave"
								listItemTid={copyButtonTid}
								listItemTidValues={{ companyName: companyName }}
								onClick={() => {
									copyPeopleToCompanyContacts([params.data]);
								}}
							/>
							<MenuItemIcon
								icon="faTrashAlt"
								listItemTid="people_modal.grid.more_action.delete_contact"
								onClick={() =>
									dispatch(removeContactFromAddressBook(params.data.id))
								}
							/>
						</DropDown>
					);
				},
				lockVisible: true,
				cellClass: "more-actions",
				headerClass: "more-actions",
			},
		];

		return defs;
	};

	const getRowId = useCallback((params) => params.data.id, []);

	if (activeTab !== TAB_MY_PERSONAL_CONTACTS) {
		return null;
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				height: "100%",
			}}
		>
			<Box sx={{ mb: 1, display: "flex" }}>
				<Box sx={{ flex: 1 }}>
					<div>
						<Typography variant="body1">
							{t("people_modal.personal_contacts.informative_text.title")}
						</Typography>
						<Typography variant="body2">
							{t("people_modal.personal_contacts.informative_text.text")}
						</Typography>
					</div>
				</Box>
				<AddContactButton />
			</Box>
			<Box sx={{ flex: 1 }}>
				<AgGrid
					gridRef={gridRef}
					rowData={rowData}
					columnDefs={columnDefs()}
					mode="panel"
					rowSelection={"multiple"}
					isRowSelectable={isRowSelectable}
					forcedHeight="100%"
					floatingFilter={floatingFilter}
					hideColumnMenu={true}
					suppressRowClickSelection={true}
					getRowId={getRowId}
					onSelectionChanged={onSelectionChanged}
					noPagination={!usePagination}
					onFilterChanged={({ api }) => {
						// Why  are we doing a refreshHeader on a onFilterChange? It onfucuses the advanced filter.
						//api.refreshHeader();

						if (Object.keys(api.getFilterModel()).length > 0) {
							setIsFloatingFilterActive(true);
						} else if (Object.keys(api.getFilterModel()).length === 0) {
							setIsFloatingFilterActive(false);
						}
					}}
					suppressContextMenu
					stopEditingWhenCellsLoseFocus
				/>
			</Box>
		</Box>
	);
}

PersonalContactsGrid.propTypes = {
	disableFunction: func,
	filterFunction: func,
};

export { PersonalContactsGrid };
