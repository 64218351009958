import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default () => {
	return useQuery({
		queryKey: ["buildVersion"],
		staleTime: 1000 * 60, // 1 minute
		refetchInterval: 1000 * 60 * 3, // 3 minutes
		queryFn: async () => {
			return await axios.get("/version");
		},
	});
};
