import { fromJS, Map } from "immutable";

import {
	TRANSACTION_UPDATE_TEMP,
	TRANSACTION_SAVE_TEMP,
	TRANSACTION_SAVE_TEMP_START,
	TRANSACTION_TEMP_DIRTY,
	TRANSACTION_FETCH_TEMP,
	TRANSACTION_FETCH,
	TRANSACTION_LIST,
	TRANSACTION_CREATE,
	TRANSACTION_INITIAL_EMISSION_LEVELING,
	TRANSACTION_INITIAL_ADJUSTMENT_TRANSACTION,
	TRANSACTION_CLEAR_TEMPORARY_TRANSACTIONS,
	TRANSACTION_CLEAR_TEMPORARY_TRANSACTION,
	COMPANY_RESET_REDUCER,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	tmpTransaction: {}, // {},
	tmpIsDirty: false,
	transaction: {},
	transactions: [],
	currentTransactionType: null,
	levelingEmissionTransaction: null,
	adjustmentTransaction: null,
});

export default function (state = INITIAL_STATE, action) {
	const { payload } = action;

	switch (action.type) {
		case TRANSACTION_UPDATE_TEMP: {
			const type = payload.get("type");
			const tempTransaction = Map({ [type]: payload });

			return state
				.set("tmpTransaction", tempTransaction)
				.set("currentTransactionType", payload.get("type"));
		}
		case TRANSACTION_SAVE_TEMP_START:
			return state.set("tmpIsDirty", false);
		case TRANSACTION_TEMP_DIRTY:
			return state.set("tmpIsDirty", true);
		case TRANSACTION_SAVE_TEMP:
			if (!state.get("tmpIsDirty")) {
				const type = payload.get("type");
				const tempTransaction = Map({ [type]: payload });

				return state
					.set("tmpTransaction", tempTransaction)
					.set("currentTransactionType", payload.get("type"));
			} else {
				return state;
			}
		case TRANSACTION_FETCH_TEMP: {
			const type = payload.get("type");
			const tempTransaction = Map({ [type]: payload });
			return state
				.set("tmpTransaction", tempTransaction)
				.set("currentTransactionType", payload.get("type"));
		}
		case TRANSACTION_CREATE:
			return state.set("transaction", payload);
		case TRANSACTION_FETCH:
			return state.set("transaction", payload);
		case TRANSACTION_INITIAL_EMISSION_LEVELING:
			return state.setIn(["levelingEmissionTransaction"], payload);
		case TRANSACTION_INITIAL_ADJUSTMENT_TRANSACTION:
			return state.set("adjustmentTransaction", payload);
		case TRANSACTION_CLEAR_TEMPORARY_TRANSACTION:
			return state
				.removeIn(["tmpTransaction", payload])
				.set("currentTransactionType", null);
		case TRANSACTION_CLEAR_TEMPORARY_TRANSACTIONS:
			return state.set("tmpTransaction", payload);
		case TRANSACTION_LIST:
			return state.set("transactions", payload);
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE;
		}
		default:
			return state;
	}
}
