import React, { Component } from "react";
import { connect } from "react-redux";
import { string, func, bool } from "prop-types";
import TransparentInput from "../../../dumb-components/shared/input/transparent-input";

class AgendaItemEditorContainer extends Component {
	static propTypes = {
		fieldName: string,
		value: string,
		placeholderTid: string,
		onChange: func,
		onFocus: func,
		onBlur: func,
		onArrowUp: func,
		onArrowDown: func,
		onArrowRight: func,
		onArrowLeft: func,
		onEnter: func,
		onEsc: func,
		onTab: func,
		onShiftTab: func,
		autofocus: bool,
		readOnly: bool,
	};

	static defaultProps = {
		readOnly: false,
	};

	onEnter = () => {
		const { onEnter } = this.props;

		onEnter && onEnter();
		this.clearValue();
	};

	onKeyDown = (e) => {
		const {
			onArrowUp,
			onArrowDown,
			onArrowRight,
			onArrowLeft,
			onEsc,
			onTab,
			onShiftTab,
			readOnly,
		} = this.props;

		// Shift
		if (e.keyCode === 16) {
			e.preventDefault();
			this.shiftIsPressed = true;
		}

		// Tab while shift is down
		if (onShiftTab && e.keyCode === 9 && this.shiftIsPressed) {
			e.preventDefault();
			onShiftTab();
			return;
		}

		if (onArrowUp && e.keyCode === 38) {
			e.preventDefault();
			onArrowUp(-1);
		}

		if (onArrowDown && e.keyCode === 40) {
			e.preventDefault();
			onArrowDown(1);
		}

		if (!readOnly && onArrowRight && e.keyCode === 39) {
			e.preventDefault();
			onArrowRight();
		}

		if (!readOnly && onArrowLeft && e.keyCode === 37) {
			e.preventDefault();
			onArrowLeft();
		}

		if (onEsc && e.keyCode === 27) {
			e.preventDefault();
			onEsc();
		}

		if (onTab && e.keyCode === 9) {
			e.preventDefault();
			onTab();
		}
	};

	onKeyUp = (e) => {
		const { value, readOnly } = this.props;

		// Shift
		if (e.keyCode === 16) {
			e.preventDefault();
			this.shiftIsPressed = false;
		}

		if (!readOnly && e.keyCode === 13) {
			e.preventDefault();
			this.onEnter(value);
		}
	};

	clearValue = () => {
		this.setState({ value: "" });
	};

	render() {
		const {
			fieldName,
			placeholderTid,
			onFocus,
			onBlur,
			i18n,
			autofocus,
			readOnly,
			onChange,
			value,
		} = this.props;

		return (
			<TransparentInput
				fieldName={fieldName}
				value={value || ""}
				onChange={onChange}
				placeholderTid={placeholderTid}
				onKeyUp={this.onKeyUp}
				onKeyDown={this.onKeyDown}
				onFocus={onFocus}
				onBlur={onBlur}
				i18n={i18n}
				autofocus={autofocus}
				readOnly={readOnly}
			/>
		);
	}
}

const mapStoreToProps = (store) => {
	return {
		i18n: store.i18n,
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(AgendaItemEditorContainer);
