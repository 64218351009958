import React, { Component } from "react";
import { connect } from "react-redux";
import { string, object } from "prop-types";

import Tooltip from "../../../../../dumb-components/shared/tooltip/tooltip";
import { ButtonTransparentIcon } from "../../../../../dumb-components/shared/button";

import {
	shouldOpenAgendaTab,
	getIsSigningRequested,
} from "../../../../../components/helpers/meeting.helper";
import history from "../../../../../interfaces/history";

class OpenSmartMeetingContainer extends Component {
	static propTypes = {
		location: object,
		basePath: string,
	};

	onOpen = () => {
		const {
			location: { pathname, search },
			meeting,
			userId,
			urlAlias,
			history,
		} = this.props;

		if (!meeting || !userId) {
			return;
		}

		const goToSection = meeting.getIn(["computedValues", "goToSection"]);
		const openAgendaTab = shouldOpenAgendaTab(meeting, userId);

		if (goToSection === "meeting") {
			// Redirect to agenda-tab
			if (openAgendaTab) {
				history.push(`${pathname}/meeting/agenda`);
			} else {
				// Proceed to info tab
				history.push(`${pathname}/meeting/info${search && search}`);
			}
		} else if (goToSection === "protocol-review") {
			const isSigningRequested = getIsSigningRequested(meeting);

			// If signing was requested, push user to signing tab
			if (isSigningRequested) {
				history.push(`${pathname}/protocol/sign-off${search && search}`);
			} else {
				history.push(`${pathname}/protocol/review${search && search}`);
			}
		} else if (goToSection === "protocol") {
			window.open(`/${urlAlias}/protocols/${meeting.get("id")}`, "_blank");
		}
	};

	render = () => {
		return (
			<Tooltip tid="meeting.general.toolbar.tooltip.open">
				<ButtonTransparentIcon
					icon="faExpandArrowsAlt"
					size="sml"
					onClick={this.onOpen}
				/>
			</Tooltip>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meeting: store.meetings.get("meeting"),
		userId: store.user.getIn(["userObj", "id"]),
		urlAlias: store.company.company?.urlAlias,
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(OpenSmartMeetingContainer);
