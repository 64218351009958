import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string } from "prop-types";
import { map } from "react-immutable-proptypes";

import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";

import { selectAttachment } from "../../../actions/attachments.actions";
import { openModal } from "../../../actions/modals.actions";

import { OBJ_TYPE_MEETING_PROTOCOL } from "/shared/constants";
import { MEETINGS_ATTACHMENT_SETTINGS_MODAL } from "../../../constants/modals";

class ManageFileItemContainer extends Component {
	static propTypes = {
		objType: string.isRequired,
		objId: string.isRequired,
		document: map.isRequired,
		onClick: func.isRequired,
	};

	openModal = () => {
		const { selectAttachment, document, objId, openModal, onClick } =
			this.props;
		const documentId = document.get("id");

		selectAttachment(objId, documentId);
		openModal(MEETINGS_ATTACHMENT_SETTINGS_MODAL);

		onClick && onClick();
	};

	render = () => {
		const { objType } = this.props;
		const disabled = objType === OBJ_TYPE_MEETING_PROTOCOL;

		return (
			<Tooltip
				tid="meetings.standard_meetings.attachments.manage_file.tooltip.disabled"
				delayShow="instant"
				active={disabled}
			>
				<DropdownIconItem
					icon="faCogs"
					tid="attachments.manage_file"
					onClick={this.openModal}
					disabled={disabled}
				/>
			</Tooltip>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {
	selectAttachment,
	openModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ManageFileItemContainer);
