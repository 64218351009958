import React, { Component } from "react";
import { connect } from "react-redux";
import CommentsList from "../../dumb-components/shared/comments/comments-list";
import DropdownMenuContainer from "../../containers/shared/dropdown-menu.container";
import DropdownIconItem from "../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import CommentsCreatorContainer from "./comments-creator.container";
import CommentsEditContainer from "./comments-edit.container";
import { bool, oneOf, func, string, number } from "prop-types";
import { Map, List } from "immutable";
import {
	updateLocal,
	fetchConversation,
	deleteComment,
} from "../../actions/comments.actions";
import { fetchSimpleUsers } from "../../actions/usersCache.actions";

// Audit
import { LIVE_CONVERSATION_UPDATE } from "../../constants/live-update";

class CommentsListContainer extends Component {
	state = {
		editingCommentId: null,
		showAllComments: false,
	};

	static propTypes = {
		objId: string,
		objType: string,
		objTitle: string,
		objUrl: string,
		includeCreator: bool,
		panelType: oneOf(["inline"]),
		onSetRef: func,
		selected: bool,
		withoutPanel: bool,
		placeholderTid: string,
		submitButtonTid: string,
		readOnly: bool,
		onCommentCreated: func,
		onCommentDeleted: func,
		maxVisibleComments: number,
	};

	static defaultProps = {
		includeCreator: true,
		readOnly: false,
	};

	componentDidMount = () => {
		const { fetchConversation, objId } = this.props;
		this.fetchUsers();

		objId && fetchConversation(objId);
	};

	componentDidUpdate = (prevProps) => {
		const { objId, fetchConversation, comments, audit } = this.props;
		const CONVERSATION_UPDATE = audit.get(LIVE_CONVERSATION_UPDATE);

		if (objId && prevProps.objId !== objId) {
			fetchConversation(objId);
		}

		if (prevProps.comments !== comments) {
			this.fetchUsers();
		}

		if (CONVERSATION_UPDATE && CONVERSATION_UPDATE.get("r") === true) {
			const changedConversationId = CONVERSATION_UPDATE.get("objId");

			if (changedConversationId === objId) {
				fetchConversation(objId);
			}
		}
	};

	fetchUsers = () => {
		const { objId, fetchSimpleUsers } = this.props;
		let { comments } = this.props;
		let userIds = List();

		if (!comments) {
			return;
		}

		comments = comments.getIn([objId, "comments"], List());

		comments.forEach((comment) => {
			userIds = userIds.push(comment.get("createdBy"));
		});

		fetchSimpleUsers(userIds);
	};

	onChange = (path, val) => {
		const { updateLocal, objId } = this.props;
		let { comments } = this.props;
		comments = comments.get(objId);
		comments = comments.setIn(path, val);
		updateLocal(comments);
	};

	onEditComment = (comment) => {
		const commentId = comment.get("id");
		this.setState((prevState) => {
			return { ...prevState, editingCommentId: commentId };
		});
	};

	onDeleteComment = (comment) => {
		const { objId, deleteComment, objType, onCommentDeleted } = this.props;
		const commentId = comment.get("id");
		deleteComment(objId, commentId);

		onCommentDeleted && onCommentDeleted(objType, objId, commentId);
	};

	onCommentCancel = () => {
		this.setState((prevState) => {
			return { ...prevState, editingCommentId: null };
		});
	};

	onCommentSave = () => {
		this.setState({ editingCommentId: null });
	};

	onCommentCreated = (objType, objId, commentId) => {
		const { onCommentCreated } = this.props;
		onCommentCreated && onCommentCreated(objType, objId, commentId);

		this.setState({ editingCommentId: null });
	};

	onToggleShowAllComments = () => {
		this.setState((prevState) => {
			return { ...prevState, showAllComments: !prevState.showAllComments };
		});
	};

	render = () => {
		const { editingCommentId, showAllComments } = this.state;
		const {
			objType,
			objId,
			comments,
			usersCache,
			currentUserId,
			includeCreator,
			panelType,
			onSetRef,
			selected,
			withoutPanel,
			placeholderTid,
			submitButtonTid,
			readOnly,
			objTitle,
			objUrl,
			maxVisibleComments,
		} = this.props;

		return (
			<CommentsList
				withoutPanel={withoutPanel}
				selected={selected}
				ref={onSetRef ? onSetRef : null}
				comments={comments.getIn([objId, "comments"])}
				editingCommentId={editingCommentId}
				usersCache={usersCache}
				panelType={panelType}
				showAllComments={showAllComments}
				onToggleShowAllComments={this.onToggleShowAllComments}
				currentUserId={currentUserId}
				maxVisibleComments={maxVisibleComments}
				btnActionsRenderer={(comment) => {
					// Don't render the more actions dropdown if a comment is currently being edited
					if (editingCommentId) {
						return null;
					}

					// If the comments are read only, don't render the dropdown menu
					if (readOnly) {
						return null;
					}

					return (
						<DropdownMenuContainer
							btnMode="transparent-icon"
							halignMenu="right"
							transparentIconButtonSize="sm"
							btnIcon="faChevronDown"
						>
							<DropdownIconItem
								icon="faPenAlt"
								onClick={this.onEditComment.bind(this, comment)}
								tid="generic.edit"
							/>

							<DropdownIconItem
								icon="faTrashAlt"
								onClick={this.onDeleteComment.bind(this, comment)}
								tid="generic.delete"
							/>
						</DropdownMenuContainer>
					);
				}}
				commentsEditorRenderer={(comment, index) => {
					return (
						<CommentsEditContainer
							comment={comment}
							index={index}
							onChange={this.onChange}
							objId={objId}
							onCommentCancel={this.onCommentCancel}
							onCommentSave={this.onCommentSave}
							objTitle={objTitle}
							objUrl={objUrl}
						/>
					);
				}}
				bottomCreatorRenderer={() => {
					if (!includeCreator) {
						return null;
					}

					return (
						<CommentsCreatorContainer
							objId={objId}
							objType={objType}
							onCommentCreated={this.onCommentCreated}
							placeholderTid={placeholderTid}
							btnTid={submitButtonTid}
							objTitle={objTitle}
							objUrl={objUrl}
						/>
					);
				}}
			/>
		);
	};
}

function mapStoreToProps(store) {
	return {
		comments: store.comments.get("comments"),
		usersCache: store.usersCache.get("usersCache", Map()),
		currentUserId: store.user.getIn(["userObj", "id"]),
		audit: store.audit.get("comments"),
	};
}

const mapActionsToProps = {
	updateLocal,
	fetchSimpleUsers,
	fetchConversation,
	deleteComment,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(CommentsListContainer);
