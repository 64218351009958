import React, { PureComponent } from "react";
import { func } from "prop-types";
import Block from "../block/block";

export default class PersonalNotes extends PureComponent {
	static propTypes = {
		renderEditor: func.isRequired,
	};

	render = () => {
		const { renderEditor } = this.props;

		return (
			<Block titleTid="meetings.protocol.personal_notes.title" noBorderBottom>
				{renderEditor()}
			</Block>
		);
	};
}
