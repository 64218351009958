import React, { Component } from "react";
import { connect } from "react-redux";
import { string } from "prop-types";
import { Map, fromJS } from "immutable";

import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";

import {
	saveMeeting,
	remindNotifyFeedbackee,
	patchMeeting,
} from "../../../actions/meetings.actions";

class FeedbackeeItemDropdownContainer extends Component {
	static propTypes = {
		feedbackeeId: string,
	};

	dropdownRef = null;

	toggleDropdown = () => {
		this.dropdownRef && this.dropdownRef.onToggleMenu();
	};

	remindRequestFeedback = (feedbackeeId) => {
		const { remindNotifyFeedbackee, meeting } = this.props;
		const meetingId = meeting.get("id");

		this.toggleDropdown();

		remindNotifyFeedbackee(meetingId, feedbackeeId);
	};

	removeFeedbackee = (feedbackeeId) => {
		const { patchMeeting, meeting } = this.props;
		this.toggleDropdown();

		patchMeeting(
			meeting.get("id"),
			fromJS({ feedbackees: { [feedbackeeId]: "__DELETE__" } }),
		);
	};

	render = () => {
		const { feedbackees, feedbackeeId } = this.props;
		const feedbackee = feedbackees.get(feedbackeeId);

		if (!feedbackee) {
			return null;
		}

		const remindRequestFeedbackDisabled =
			!feedbackee.get("notified") && !feedbackee.get("done");

		return (
			<DropdownMenuContainer
				btnIcon="faEllipsisV"
				halignMenu="right"
				btnMode="transparent-icon"
				transparentIconButtonSize="sml"
				tooltipActiveState="btnMoreActions"
				ref={(r) => (this.dropdownRef = r)}
			>
				<DropdownIconItem
					icon="faPaperPlane"
					onClick={this.remindRequestFeedback.bind(null, feedbackeeId)}
					disabled={remindRequestFeedbackDisabled}
					tid="meetings.protocol.feedback.list.remind_feedback"
				/>

				<DropdownIconItem
					icon="faTrashAlt"
					onClick={this.removeFeedbackee.bind(null, feedbackeeId)}
					tid="meetings.protocol.feedback.list.remove_user"
				/>
			</DropdownMenuContainer>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		feedbackees: store.meetings.getIn(["meeting", "feedbackees"], Map()),
		meeting: store.meetings.get("meeting"),
	};
};

const mapActionsToProps = {
	saveMeeting,
	remindNotifyFeedbackee,
	patchMeeting,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(FeedbackeeItemDropdownContainer);
