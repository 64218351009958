import React, { Component } from "react";
import { connect } from "react-redux";

import MergedCollectionOverview from "../../../dumb-components/collections/shared-modal/merged-collection-overview";

class MergedCollectionOverviewContainer extends Component {
	render = () => {
		const { collection } = this.props;

		return <MergedCollectionOverview collection={collection} />;
	};
}

const mapStoreToProps = (store) => {
	return {
		collection: store.sharedCollections.get("selectedCollection"),
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MergedCollectionOverviewContainer);
