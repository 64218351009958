import React, { PureComponent } from "react";
import { object } from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]}
		${(props) => props.theme.spacing[6]};
	height: 100%;
	justify-content: space-around;
`;

export default class VotingProgressView extends PureComponent {
	static propTypes = {
		children: object,
	};

	render = () => {
		const { children } = this.props;

		return <Wrapper>{children}</Wrapper>;
	};
}
