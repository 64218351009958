import React, { PureComponent } from "react";
import { string, func } from "prop-types";
import styled from "styled-components";
import { StyledWrapperCss } from "./item.template";
import { formatSsnPretty } from "../../../components/helpers/users";

import CompanyLogo from "./logo";
import Text from "../../shared/text/text";

const StyledWrapper = styled.div`
	${StyledWrapperCss};

	:hover {
		background: ${(props) => props.theme.colors.lightestGrey}};
	}
`;

const StyledInformation = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: ${(props) => props.theme.spacing[4]};
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

export default class CompanyItem extends PureComponent {
	static propTypes = {
		name: string,
		orgNumber: string,
		onSelect: func,
		imageUrl: string,
	};

	static defaultProps = {
		name: "-",
		orgNumber: "-",
	};

	render = () => {
		const { onSelect, name, orgNumber, imageUrl } = this.props;

		return (
			<StyledWrapper onClick={onSelect && onSelect}>
				<CompanyLogo imageUrl={imageUrl} />

				<StyledInformation>
					<Text bold={600} singleLine>
						{name}
					</Text>
					<Text singleLine>{formatSsnPretty(orgNumber)}</Text>
				</StyledInformation>
			</StyledWrapper>
		);
	};
}
