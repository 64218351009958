import React, { PureComponent } from "react";
import { bool } from "prop-types";
import styled, { css } from "styled-components";

import ScrollView from "../layout/scroll-view/scroll-view";

const StyledInnerWrapper = styled.div`
	padding: ${(props) => props.theme.spacing[5]}
		${(props) => props.theme.spacing[6]};

	${(props) =>
		props.fullHeight &&
		css`
			height: 100%;
		`}
`;

export default class ModalInnerWrapper extends PureComponent {
	static propTypes = {
		noScrollView: bool,
		fullHeight: bool,
	};

	render = () => {
		const { noScrollView, fullHeight } = this.props;

		if (noScrollView) {
			return (
				<StyledInnerWrapper fullHeight={fullHeight}>
					{this.props.children}
				</StyledInnerWrapper>
			);
		}

		return (
			<ScrollView noLeftMargin noRightMargin alwaysShow>
				<StyledInnerWrapper fullHeight={fullHeight}>
					{this.props.children}
				</StyledInnerWrapper>
			</ScrollView>
		);
	};
}
