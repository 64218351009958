import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import { listTasksByCompanies } from "../../actions/tasks.actions";
import TaskListByCompanies from "../../dumb-components/tasks/task-list-by-companies/task-list-by-companies";
import { listProjectNamesForUserTasks } from "../../actions/projects.actions";
import Moment from "../../modules/moment.module";
import TaskCreateEditModalContainer from "./task-create-edit-modal.container";

import {
	LIVE_TASK_UPDATE,
	LIVE_TASK_DELETE,
	LIVE_TASK_DELETE_MULTIPLE,
	LIVE_TASK_EXTERNAL_DELETE_MULTIPLE,
} from "../../constants/live-update";

const SHOW_ONLY_TASK_FILTER = ["to_do", "on_hold", "in_progress"];

class TaskListByCompaniesContainer extends Component {
	state = {
		selectedFilter: "show_all",
		isModalOpen: false,
		selectedTask: null,
	};

	componentDidMount = () => {
		const { listTasksByCompanies } = this.props;
		listTasksByCompanies();
	};

	componentDidUpdate = (prevProps) => {
		const { listTasksByCompanies, user } = this.props;

		if (user !== prevProps.user) {
			listTasksByCompanies();
		}

		this.checkLiveUpdateEvents();
	};

	checkLiveUpdateEvents = () => {
		const { audit, listTasksByCompanies, tasks } = this.props;
		const taskUpdate = audit.get(LIVE_TASK_UPDATE, Map());
		const taskDelete = audit.get(LIVE_TASK_DELETE, Map());
		const taskDeleteMultiple = audit.get(LIVE_TASK_DELETE_MULTIPLE, Map());
		const taskExternalDeleteMultiple = audit.get(
			LIVE_TASK_EXTERNAL_DELETE_MULTIPLE,
			Map(),
		);

		if (taskUpdate.get("refresh") === true) {
			listTasksByCompanies();
		}

		if (
			taskDelete.get("refresh") === true &&
			tasks &&
			tasks.find((obj) => obj.get("id") === taskDelete.get("objId"))
		) {
			listTasksByCompanies();
		}

		if (taskDeleteMultiple.get("refresh") === true && tasks) {
			const taskIds = tasks.map((obj) => obj.get("id"));
			const deletedTaskIds = taskDeleteMultiple.get("objIds");
			let found = false;

			taskIds &&
				taskIds.forEach((id) => {
					if (deletedTaskIds.includes(id)) {
						found = true;
					}
				});

			if (found) {
				listTasksByCompanies();
			}
		}

		if (taskExternalDeleteMultiple.get("refresh") === true && tasks) {
			const taskIds = tasks.map((obj) => obj.get("id"));
			const deletedTaskIds = taskExternalDeleteMultiple.get("objIds");
			let found = false;

			taskIds &&
				taskIds.forEach((id) => {
					if (deletedTaskIds.includes(id)) {
						found = true;
					}
				});

			if (found) {
				listTasksByCompanies();
			}
		}
	};

	getTasks = () => {
		const { tasks } = this.props;
		const { selectedFilter } = this.state;
		let filterEndDate;
		const endOfToday = Moment().endOf("day");

		if (!tasks || tasks.size === 0) {
			return tasks;
		}

		if (selectedFilter === "today") {
			filterEndDate = endOfToday;
		} else if (selectedFilter === "7d") {
			filterEndDate = Moment().add(7, "days").endOf("day");
		} else if (selectedFilter === "30d") {
			filterEndDate = Moment().add(30, "days").endOf("day");
		} else if (selectedFilter === "90d") {
			filterEndDate = Moment().add(90, "days").endOf("day");
		}

		// Filter by status
		let filteredTasks = tasks.filter((task) => {
			return SHOW_ONLY_TASK_FILTER.includes(task.get("status"));
		});

		// Filter tasks by active Filter
		filteredTasks = filteredTasks.filter((task) => {
			const dueAt = Moment(task.get("dueAt"));

			if (selectedFilter === "no_due_date") {
				return !task.get("dueAt");
			}

			// Overdue
			if (selectedFilter === "overdue") {
				return dueAt.diff(endOfToday, "days") < 0;
			}
			// Show All
			if (selectedFilter === "show_all") {
				return task;
			}
			// Filter by date and do not include overdue tasks
			return (
				task.get("dueAt") &&
				dueAt < filterEndDate &&
				dueAt.diff(endOfToday, "days") >= 0
			);
		});

		return filteredTasks;
	};

	onFilter = (filter) => {
		this.setState({ selectedFilter: filter });
	};

	onOpenTask = (task) => {
		this.setState({
			isModalOpen: true,
			selectedTask: task,
		});
	};

	onCloseModal = () => {
		this.setState({ isModalOpen: false });
	};

	render = () => {
		const { i18n, projects } = this.props;
		const { selectedFilter, isModalOpen, selectedTask } = this.state;
		const tasks = this.getTasks();
		const taskId = selectedTask && selectedTask.get("id");
		const objId = selectedTask && selectedTask.getIn(["links", 0, "objId"]);
		const objType = selectedTask && selectedTask.getIn(["links", 0, "objType"]);
		const company =
			selectedTask && selectedTask.has("companyInformation")
				? selectedTask.get("companyInformation").toJS()
				: undefined;
		const taskMode = objId && objType ? "external" : "normal";

		return (
			<div className="col-md-12">
				<TaskListByCompanies
					tasks={tasks}
					projects={projects}
					userLang={i18n.language}
					onFilter={this.onFilter}
					selectedFilter={selectedFilter}
					onOpenTask={this.onOpenTask}
				/>

				<TaskCreateEditModalContainer
					isOpen={isModalOpen}
					onClose={this.onCloseModal}
					taskId={taskId}
					objId={objId}
					objType={objType}
					company={company}
					mode={taskMode}
				/>
			</div>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		tasks: store.tasks.get("listByCompanies"),
		i18n: store.i18n,
		user: store.user.get("userObj"),
		projects: store.projects.get("projectNamesUserTasks"),
		audit: store.audit.get("tasks"),
	};
};

const mapActionsToProps = {
	listTasksByCompanies,
	listProjectNamesForUserTasks,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(TaskListByCompaniesContainer);
