import { string, arrayOf, shape, bool, func } from "prop-types";
import React from "react";
import ModalTabsHeader from "../../../dumb-components/shared/modal/modal-tabs-header";
import { usePeopleModalContext } from "./people-modal.context";
import {
	TAB_TEAMS_AND_SHAREHOLDES,
	TAB_EXTERNAL_USERS,
	TAB_MY_PERSONAL_CONTACTS,
	TAB_SHAREHOLDERS,
} from "../select-user-modal/select-user-modal.constants";
import styled from "styled-components";
import { HeaderMenu } from "./header-menu/HeaderMenu";

const StyledDropDownWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const HeaderButtons = () => {
	return (
		<StyledDropDownWrapper>
			<HeaderMenu />
		</StyledDropDownWrapper>
	);
};

/**
 *
 * @param {Object} config
 * @param {boolean} config.membersDisabled
 * @param {boolean} config.companyContactsDisabled
 * @param {boolean} config.myPersonalContactsDisabled
 * @param {boolean} config.shareholdersDisabled
 * @param {string} config.membersTabTooltip
 * @param {string} config.companyContactsTabTooltip
 * @param {string} config.myPersonalContactsTabTooltip
 * @param {string} config.shareholdersTabTooltip
 */
function getAllTabs(config = {}) {
	const {
		membersDisabled,
		membersTabTooltip,
		companyContactsDisabled,
		companyContactsTabTooltip,
		myPersonalContactsDisabled,
		myPersonalContactsTabTooltip,
		shareholdersDisabled,
		shareholdersTabTooltip,
	} = config;
	const tabs = [
		{
			path: TAB_TEAMS_AND_SHAREHOLDES,
			disabled: membersDisabled,
			tooltip: membersTabTooltip,
			tabName: TAB_TEAMS_AND_SHAREHOLDES,
			title: "select_user_modal.tab.teams_and_shareholders",
		},
		{
			path: TAB_EXTERNAL_USERS,
			disabled: companyContactsDisabled,
			tooltip: companyContactsTabTooltip,
			tabName: TAB_EXTERNAL_USERS,
			title: "select_user_modal.tab.external_users",
		},
		{
			path: TAB_MY_PERSONAL_CONTACTS,
			disabled: myPersonalContactsDisabled,
			tooltip: myPersonalContactsTabTooltip,
			tabName: TAB_MY_PERSONAL_CONTACTS,
			title: "people_modal.tab.my_personal_contacts",
		},
		{
			path: TAB_SHAREHOLDERS,
			disabled: shareholdersDisabled,
			tooltip: shareholdersTabTooltip,
			tabName: TAB_SHAREHOLDERS,
			title: "people_modal.tab.shareholders",
		},
	];

	return tabs;
}

export function getDefaultTabs(config = {}) {
	const allTabs = getAllTabs(config);
	const defaultTabs = [
		TAB_TEAMS_AND_SHAREHOLDES,
		TAB_EXTERNAL_USERS,
		TAB_MY_PERSONAL_CONTACTS,
	];

	return allTabs.filter((tab) => defaultTabs.includes(tab.tabName));
}

export function getOnlyTabs(tabKeys) {
	const allTabs = getAllTabs();
	return allTabs.filter((tab) => tabKeys.includes(tab.tabName));
}

function PeopleModalHeader({ tabs, title }) {
	const { activeTab, setActiveTab } = usePeopleModalContext();

	return (
		<ModalTabsHeader
			tabs={tabs}
			activeTab={activeTab}
			onChange={setActiveTab}
			title={title}
			showFilterButton={true}
			renderFilterButton={() => <HeaderButtons />}
		/>
	);
}

PeopleModalHeader.propTypes = {
	title: string,
	onTabChange: func,
	tabs: arrayOf(
		shape({
			path: string.isRequired,
			disabled: bool,
			tooltip: string,
			tabName: string.isRequired,
			title: string.isRequired,
		}),
	),
};

export { PeopleModalHeader };
