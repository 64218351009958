import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "../../../modules/moment.module";
import Text from "../../shared/text/text";
import SimpleDarkInfoPanel from "../../shared/dark-info-panel/simple-dark-info-panel";
import { getUserName } from "../../../components/helpers/users";

const StyledWrapper = styled.div`
	background-color: ${(props) => props.theme.colors.solitudeMid};
	width: 100%;
	margin-bottom: ${(props) => props.theme.spacing[4]};
	padding-bottom: ${(props) => props.theme.spacing[4]};
`;

const StyledSimpleDarkInfoPanel = styled(SimpleDarkInfoPanel)`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin: ${(props) => props.theme.spacing[4]};
`;

const StyledMeetingInfoWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

const StyledMeetingRolesWrapper = styled.div`
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

const StyledTextWrapper = styled.div`
	display: flex;
`;

const StyledMeetingLinkWrapper = styled.div``;

class MeetingStandardPanel extends Component {
	getModeratorName = (attendee) => {
		const { investors, usersCache } = this.props;
		const name = getUserName(attendee, investors, usersCache);
		return name ? name : undefined;
	};

	renderModeratorRoles = (moderatorId) => {
		const { chairmanId, secretaryId } = this.props;
		const moderatorIsChairman = chairmanId === moderatorId;
		const moderatorIsSecretary = secretaryId === moderatorId;

		if (!moderatorIsChairman && !moderatorIsSecretary) {
			return null;
		}

		return (
			<Text color="white">
				{" "}
				(
				{moderatorIsChairman && (
					<Text color="white" tid="meetings.standard.panel.chairman" />
				)}
				{moderatorIsChairman && moderatorIsSecretary && <>{" | "}</>}
				{moderatorIsSecretary && (
					<Text color="white" tid="meetings.standard.panel.secretary" />
				)}
				)
			</Text>
		);
	};

	renderModerator = (moderatorId) => {
		const { attendees } = this.props;
		const attendee = attendees?.get(moderatorId);

		if (!attendee) {
			return null;
		}

		const moderatorName = this.getModeratorName(attendee);

		return (
			<>
				<StyledTextWrapper>
					<Text color="white" bold={600}>
						{moderatorName}
						{this.renderModeratorRoles(moderatorId)}
					</Text>
				</StyledTextWrapper>
			</>
		);
	};

	render = () => {
		const {
			meetingName,
			meetingStartDate,
			meetingEndDate,
			companyId,
			meetingId,
			userId,
			meetingHasModerator,
			moderatorIds,
		} = this.props;
		const moderatorTid =
			moderatorIds?.size > 1
				? "meetings.standard.panel.persons_assigned_as_moderators"
				: "meetings.standard.panel.person_assigned_as_moderator";

		return (
			<StyledWrapper>
				<StyledSimpleDarkInfoPanel>
					<StyledMeetingInfoWrapper>
						<Text color="white" bold={600} fontSize="16px">
							{meetingName}
						</Text>
						<Text>
							{meetingStartDate && (
								<Text color="white" bold={600} textTransform="capitalize">
									{moment(meetingStartDate).format("dddd")}{" "}
									{moment(meetingStartDate).format("LL")}
								</Text>
							)}
							{meetingStartDate && meetingEndDate && (
								<Text color="white" bold={600} textTransform="capitalize">
									{" "}
									@ {moment(meetingStartDate).format("LT")} -{" "}
									{moment(meetingEndDate).format("LT")}
								</Text>
							)}
							{meetingStartDate && !meetingEndDate && (
								<Text color="white" bold={600} textTransform="capitalize">
									{" "}
									@ {moment(meetingStartDate).format("LT")}
								</Text>
							)}
						</Text>
					</StyledMeetingInfoWrapper>

					{meetingHasModerator && (
						<StyledMeetingRolesWrapper>
							<Text color="white" tid={moderatorTid} />
							{moderatorIds.map(this.renderModerator)}
						</StyledMeetingRolesWrapper>
					)}

					<StyledMeetingLinkWrapper>
						<Text
							color="white"
							tid="meetings.standard.panel.members_attending_meeting"
						/>
						<StyledTextWrapper>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={`/public/meeting/${companyId}/${meetingId}/${userId}?source=email`}
								itemProp="url"
							>
								<Text
									color="white"
									bold={600}
									hasUnderline
									tid="meetings.standard.panel.link_to_meeting_agenda"
								/>
							</a>
						</StyledTextWrapper>
					</StyledMeetingLinkWrapper>
				</StyledSimpleDarkInfoPanel>
			</StyledWrapper>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meetingName: store.meetings.getIn(["meeting", "name"]),
		meetingStartDate: store.meetings.getIn(["meeting", "startDate"]),
		meetingEndDate: store.meetings.getIn(["meeting", "endDate"]),
		companyId: store.company.company.id,
		meetingId: store.meetings.getIn(["meeting", "id"]),
		userId: store.user.getIn(["userObj", "id"]),
		moderatorIds: store.meetings.getIn(["meeting", "moderators"]),
		usersCache: store.usersCache.get("usersCache"),
		investors: store.investors.get("list"),
		chairmanId: store.meetings.getIn(["meeting", "chairman"]),
		secretaryId: store.meetings.getIn(["meeting", "secretary"]),
		meetingHasModerator: store.meetings.getIn([
			"meeting",
			"computedValues",
			"hasModerator",
		]),
		attendees: store.meetings.getIn(["meeting", "attendees"]),
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingStandardPanel);
