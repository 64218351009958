import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { PREPAID_OPTIONS } from "../constants";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import { NUMBER_FORMAT_CURRENCY } from "/shared/constants";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../mui-components/checkbox/checkbox";
import { bool, func, number } from "prop-types";

const PrepaidForm = ({
	onAmountChanged,
	onTermsChanged,
	termsAccepted,
	onAutoRefillChange,
	autoRefill,
	amountToPay,
	amount,
}) => {
	const { t } = useTranslation();

	return (
		<Box>
			<TextField
				label={t("subscriptions.prepaid_modal.label.top_up_amount")}
				value={PREPAID_OPTIONS.find(
					(option) => option.get("value") === amount,
				)?.get("value")}
				onChange={(event) => onAmountChanged(event.target.value)}
				fullWidth
				select
				margin="normal"
			>
				{PREPAID_OPTIONS.map((option, index) => (
					<MenuItem
						key={index}
						value={option.get("value")}
						disabled={amountToPay > option.get("value")}
					>
						{localeFormatNumber(option.get("label"), NUMBER_FORMAT_CURRENCY)}
					</MenuItem>
				))}
			</TextField>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={autoRefill}
							onChange={(event) => onAutoRefillChange(event.target.checked)}
						/>
					}
					label={t("subscriptions.prepaid.autorefill")}
				/>
			</FormGroup>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={termsAccepted}
							onChange={(event) => onTermsChanged(event.target.checked)}
						/>
					}
					label={t(
						"subscriptions.prepaid_modal.label.authority_to_accept_payment",
					)}
				/>
			</FormGroup>
		</Box>
	);
};

PrepaidForm.propTypes = {
	onAmountChanged: func.isRequired,
	onTermsChanged: func.isRequired,
	onAutoRefillChange: func.isRequired,
	amount: number,
	termsAccepted: bool,
	autoRefill: bool,
	amountToPay: number,
};

export { PrepaidForm };
