import React, { Component } from "react";
import { connect } from "react-redux";
import { string } from "prop-types";
import { map } from "react-immutable-proptypes";

import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";

import {
	selectAttachment,
	deleteAttachedDocument,
} from "../../../actions/attachments.actions";
import { openModal } from "../../../actions/modals.actions";
import { MEETINGS_ATTACHMENT_SETTINGS_MODAL } from "../../../constants/modals";
import history from "../../../interfaces/history";

class MeetingsDropdownItemContainer extends Component {
	static propTypes = {
		basePath: string,
		search: string,
		documentId: string.isRequired,
		document: map,
		objId: string,
		objType: string,
	};

	static defaultProps = {};

	dropdownRef = null;

	deleteDocument = () => {
		const {
			deleteAttachedDocument,
			objId,
			objType,
			documentId,
			basePath,
			search,
		} = this.props;

		this.dropdownRef && this.dropdownRef.onToggleMenu();

		deleteAttachedDocument(documentId, objType, objId, () => {
			this.props.history.push({
				pathname: basePath,
				search,
			});
		});
	};

	manageFile = () => {
		const { openModal, document, objId, selectAttachment } = this.props;

		this.dropdownRef && this.dropdownRef.onToggleMenu();

		const documentId = document.get("id");

		selectAttachment(objId, documentId);

		openModal(MEETINGS_ATTACHMENT_SETTINGS_MODAL, { objId });
	};

	render = () => {
		const { meeting } = this.props;
		const hasExtendedRights = meeting?.getIn([
			"computedValues",
			"hasExtendedRights",
		]);
		const isDisabled = !hasExtendedRights;

		return (
			<DropdownMenuContainer
				btnIcon="faEllipsisV"
				halignMenu="right"
				transparentIconButtonSize="sml"
				btnMode="transparent-icon"
				ref={(r) => (this.dropdownRef = r)}
				withPortal
			>
				<Tooltip activeState="hasNoPermissions" active={isDisabled}>
					<DropdownIconItem
						icon="faCogs"
						tid="attachments.manage_file"
						onClick={this.manageFile}
					/>
				</Tooltip>

				<Tooltip activeState="hasNoPermissions" active={isDisabled}>
					<DropdownIconItem
						tid="delete_file"
						icon="faTrashAlt"
						disabled={isDisabled}
						onClick={this.deleteDocument}
					/>
				</Tooltip>
			</DropdownMenuContainer>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meeting: store.meetings.get("meeting"),
	};
};

const mapActionsToProps = {
	openModal,
	selectAttachment,
	deleteAttachedDocument,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingsDropdownItemContainer);
