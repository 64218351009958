import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func } from "prop-types";
import { fetchAndCacheInvestors } from "../../../actions/investors.actions";
import { fetchSimpleUsers } from "../../../actions/usersCache.actions";
import { List } from "immutable";
import PublishedDocSharedList from "../../../dumb-components/shared/published-document-shared-list-modal/published-document-shared-list";
import Modal from "../../../dumb-components/shared/modal/modal";
import ScrollView from "../../../dumb-components/shared/layout/scroll-view/scroll-view";
import FooterRightControls from "../../../dumb-components/shared/modal/footer-right-controls";
import TransparentButton from "../../../dumb-components/shared/button-v2/src/buttons/transparent-button";
import { StyledModalContentWrapper } from "../../../dumb-components/shared/select-user-modal/select-user-modal";

class SharedWithListContainer extends Component {
	state = {
		listRef: null,
		scrollbarRef: null,
		sharedWithPeopleList: List(),
	};

	static propType = {
		isOpen: bool,
		onCancel: func,
	};

	componentDidMount = () => {
		this.fetchUsersAndInvestors();
		this.fetchUserAndInvestorNames();
	};

	componentDidUpdate = (prevProps) => {
		const { shareWith, usersCache, investors } = this.props;

		if (prevProps.shareWith !== shareWith) {
			this.fetchUsersAndInvestors();
		}

		if (prevProps.usersCache !== usersCache) {
			this.fetchUserAndInvestorNames();
		}

		if (prevProps.investors !== investors) {
			this.fetchUserAndInvestorNames();
		}
	};

	fetchUsersAndInvestors = () => {
		const { fetchAndCacheInvestors, fetchSimpleUsers, shareWith } = this.props;

		if (!shareWith) {
			return;
		}

		const shareHolderIds = shareWith
			.filter((person) => {
				if (person.get("isInvestor")) {
					return true;
				}
			})
			.map((person) => {
				return person.get("userId");
			});

		const userIds = shareWith
			.filter((person) => {
				if (!person.get("isInvestor") && !person.get("isGuest")) {
					return true;
				}
			})
			.map((person) => {
				return person.get("userId");
			});

		fetchAndCacheInvestors(shareHolderIds);
		fetchSimpleUsers(userIds.toList());
	};

	fetchUserAndInvestorNames = () => {
		const { usersCache, investors, shareWith } = this.props;
		const sharedWithList = shareWith?.toList();
		let nameList = List();

		sharedWithList &&
			sharedWithList.forEach((item, index) => {
				const sharedWithObject = sharedWithList.get(index);
				const isUser =
					!sharedWithObject.get("isInvestor") &&
					!sharedWithObject.get("isGuest");
				const isInvestor = sharedWithObject.get("isInvestor") === true;
				const isGuest = sharedWithObject.get("isGuest") === true;

				if (isUser && usersCache.has(sharedWithObject.get("userId"))) {
					const name = usersCache.getIn([
						sharedWithObject.get("userId"),
						"name",
					]);
					nameList = nameList.push(name);
				} else if (
					isInvestor &&
					investors.has(sharedWithObject.get("investmentId"))
				) {
					const name = investors.getIn([
						sharedWithObject.get("investmentId"),
						"investorInformation",
						"name",
					]);
					nameList = nameList.push(name);
				} else if (isGuest) {
					const name = sharedWithObject.get("name");
					nameList = nameList.push(name);
				}
			});

		const sortedNames = nameList.sort();

		this.setState({ sharedWithPeopleList: sortedNames });
	};

	onScroll = ({ target }) => {
		const { listRef } = this.state;
		const { scrollTop } = target;
		listRef?.scrollTo(scrollTop);
	};

	setListRef = (ref) => {
		this.setState({ listRef: ref });
	};

	setScrollbarRef = (ref) => {
		this.setState({ scrollbarRef: ref });
	};

	renderModalButtons = () => {
		const { onCancel } = this.props;

		return (
			<FooterRightControls>
				<TransparentButton
					tid="meetings.protocol.shared_with_list.modal.footer.button.close"
					textColor="midGrey"
					onClick={onCancel}
				/>
			</FooterRightControls>
		);
	};

	render = () => {
		const { isOpen } = this.props;
		const { listRef, scrollbarRef, sharedWithPeopleList } = this.state;

		return (
			<Modal
				hSize="md"
				isOpen={isOpen}
				footerComponent={this.renderModalButtons()}
				title="meetings.protocol.shared_with_list.modal.title"
				scrollableContent={false}
			>
				<StyledModalContentWrapper>
					<ScrollView
						onScroll={listRef && this.onScroll}
						scrollbarRef={this.setScrollbarRef}
						noLeftMargin
						showOnHover
					>
						<PublishedDocSharedList
							setListRef={this.setListRef}
							scrollbarRef={scrollbarRef}
							sharedWithPeopleList={sharedWithPeopleList}
						/>
					</ScrollView>
				</StyledModalContentWrapper>
			</Modal>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		investors: store.investors.get("investorsCache"),
		usersCache: store.usersCache.get("usersCache"),
		shareWith: store.documents.getIn(["document", "shareWith"]),
	};
};

const mapActionsToProps = {
	fetchAndCacheInvestors,
	fetchSimpleUsers,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(SharedWithListContainer);
