import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import AgGrid from "../../../dumb-components/shared/ag-grid/ag-grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { GRID_MODAL } from "../../../constants/modals";
import { GRID_MODE_PANEL, GRID_MODE_MODAL } from "/shared/constants";
import ExportExcelButton from "../../../dumb-components/investment/shareholders/export-excel-button";
import { InvestmentsContext } from "../investment.container";
import {
	columnTypes,
	useMyRecordsColumnDefs,
} from "../../../dumb-components/shared/ag-grid/column-defs/column-defs";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../actions/modals.actions";
import GridPanelRightButtons from "../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons";
import { useMyRecordsRowData } from "../../../dumb-components/shared/ag-grid/transposed-grid/transposed-grid-row-data";
import Text from "../../../dumb-components/shared/text/text";
import {
	updateTransactionsOrder,
	updateTransactionDate,
} from "../../../actions/my-records.actions";

const MyTransactionsGridContainer = () => {
	const { selectedPersonalInvestment } = useContext(InvestmentsContext);
	const [transactions, setTransactions] = useState();
	const id = "myTransactions";

	const closeDatePicker = (transactionId) => {
		const index = transactionsData.findIndex((transaction) => {
			return transaction.get("id") === transactionId;
		});
		setTransactionsData(
			transactionsData.setIn([index, "openDatePicker"], false),
		);
	};

	const openDatePicker = (transactionId) => {
		const index = transactionsData.findIndex((transaction) => {
			return transaction.get("id") === transactionId;
		});
		setTransactionsData(
			transactionsData.setIn([index, "openDatePicker"], true),
		);
	};

	const gridRef = useRef();
	const [colDefs, setColDefs] = useMyRecordsColumnDefs(
		closeDatePicker,
		openDatePicker,
	);
	const [transactionsData, setTransactionsData] = useState(null);
	const colTypes = columnTypes();
	const [allGridColumns, setAllGridColumns] = useState();
	const [movableColumnsDisabled, setMovableColumnsDisabled] = useState(false);
	const [movedIndexAndColumn, setMovedIndexAndColumn] = useState();
	const [myRecordsRowData, setMyRecordsRowData] = useMyRecordsRowData();

	const gridOption = "shareholder";

	const dispatch = useDispatch();

	const isGridModalOpen = useSelector((state) => {
		return state.modals.getIn(["activeModal", "options"]) === gridOption;
	});

	useEffect(() => {
		const myPersonalTransactions =
			selectedPersonalInvestment.get("transactions");
		if (myPersonalTransactions) {
			setTransactions(myPersonalTransactions);
		}
	}, [selectedPersonalInvestment]);

	useEffect(() => {
		if (!transactions) {
			return;
		}
		const newTransactions = transactions.map((transaction) => {
			const emissionValue =
				transaction.get("totalCompanySharesDiff") *
				transaction.get("latestPrice");
			transaction = transaction.set("totalEmissionValue", emissionValue);

			const investedAmount =
				transaction.get("myNumOfSharesDiff") * transaction.get("latestPrice");
			transaction = transaction.set("investedAmount", investedAmount);

			transaction = transaction.set(
				"sharebookRegDate",
				"TODO: Show after link to transaction",
			);
			transaction = transaction.set(
				"regDateBolagsverket",
				"TODO: Show after link to bolagsverket established if possible",
			);

			return transaction;
		});

		setTransactionsData(newTransactions);
	}, [transactions]);

	useEffect(() => {
		if (transactionsData) {
			setColDefs(transactionsData, id, onChangeDate);
			setMyRecordsRowData(transactionsData);
		}
	}, [transactionsData]);

	const exportAsExcel = useCallback(() => {
		gridRef.current.api.exportDataAsExcel();
	});

	const openGridModal = useCallback(() => {
		dispatch(openModal(GRID_MODAL, gridOption));
	});

	const modalLeftHeader = {
		tid: "investments.my_records.grid.title",
	};

	const onDragStopped = (event) => {
		const newColumnOrder = event.columnApi.getAllGridColumns();
		const mappedNewColumnOrder = newColumnOrder.map((column) => {
			return column.colId.split("&")[1];
		});
		mappedNewColumnOrder.shift();

		const mappedAllGridColumns = allGridColumns.map((column) => {
			return column.colId.split("&")[1];
		});
		mappedAllGridColumns.shift();

		if (
			JSON.stringify(mappedNewColumnOrder) !==
			JSON.stringify(mappedAllGridColumns)
		) {
			setMovableColumnsDisabled(true);
			const filteredColumnOrder = mappedNewColumnOrder.filter(
				(item) => item !== undefined,
			);
			dispatch(
				updateTransactionsOrder(
					selectedPersonalInvestment.get("id"),
					filteredColumnOrder,
					movedIndexAndColumn.toIndex,
					movedIndexAndColumn.columnId,
					() => {
						setAllGridColumns([...newColumnOrder]);
						setMovableColumnsDisabled(false);
					},
				),
			);
		}
	};

	const onColumnMoved = (params) => {
		if (params.source === "uiColumnDragged") {
			setMovedIndexAndColumn({
				toIndex: params.toIndex - 2,
				columnId: params.column.colId.split("&")[1],
			});
		}
	};

	const onChangeDate = (value, personalTransactionId) => {
		const date = value.toUTC().toISO();
		const personalInvestmentId = selectedPersonalInvestment.get("id");
		dispatch(
			updateTransactionDate(personalInvestmentId, personalTransactionId, date),
		);
	};

	const onGridReady = (params) => {
		const columns = params.columnApi.getAllGridColumns();

		setAllGridColumns([...columns]);

		const lastColumn = columns[columns.length - 1];

		params.api.ensureColumnVisible(lastColumn.colId);
		params.columnApi.resetColumnState();
	};

	const onGridColumnsChanged = (event) => {
		const lastPrimaryOrder =
			gridRef.current.columnApi.columnModel.lastPrimaryOrder;

		if (!lastPrimaryOrder) {
			return null;
		}

		const lastTransactionsRef =
			gridRef.current.columnApi.columnModel.lastPrimaryOrder.slice(2);

		if (transactions.size > lastTransactionsRef.length) {
			const transactionIds = transactions.map((transaction) => {
				return transaction.get("transactionType") + "&" + transaction.get("id");
			});

			const lastTransactionsRefIds = lastTransactionsRef.map((item) => {
				return item.colId;
			});

			const newTransaction = transactionIds.filter((transactionId) => {
				return lastTransactionsRefIds.indexOf(transactionId) === -1;
			});

			event.api.ensureColumnVisible(newTransaction.toJS()[0]);
			event.columnApi.resetColumnState();
		}
	};

	if (!transactions || transactions.size < 1) {
		return null;
	}

	return (
		<>
			<Card>
				<CardHeader
					title={<Text tid={"investments.my_records.grid.title"} />}
					action={
						<GridPanelRightButtons
							exportAsExcel={exportAsExcel}
							openGridModal={openGridModal}
							downloadExcelTid={
								"investments.my_records.own_ownership_registration.grid.dropdown_item.download_excel_list"
							}
							noFilter={true}
						/>
					}
				/>
				<CardContent variant="panel">
					<AgGrid
						mode={isGridModalOpen ? GRID_MODE_MODAL : GRID_MODE_PANEL}
						isGridModalOpen={isGridModalOpen}
						rowData={myRecordsRowData}
						columnDefs={colDefs}
						rightHeaderComponent={() => {
							return (
								<ExportExcelButton
									exportAsExcel={exportAsExcel}
									noFilter={true}
								/>
							);
						}}
						modalLeftHeader={modalLeftHeader}
						gridRef={gridRef}
						columnTypes={colTypes}
						rowsPerPage={myRecordsRowData && myRecordsRowData.length}
						suppressMovableColumns={movableColumnsDisabled}
						onDragStopped={onDragStopped}
						onColumnMoved={onColumnMoved}
						onGridReady={onGridReady}
						groupDisplayType="groupRows"
						isGroupOpenByDefault={() => true}
						groupRowRenderer="agGroupCellRenderer"
						groupRowRendererParams={{ suppressCount: true }}
						groupedGrid
						onGridColumnsChanged={onGridColumnsChanged}
					/>
				</CardContent>
			</Card>
		</>
	);
};

MyTransactionsGridContainer.defaultProps = {
	staticRows: 13,
};

export default MyTransactionsGridContainer;
