import React, { PureComponent } from "react";
import { bool, func, string } from "prop-types";
import { list, map } from "react-immutable-proptypes";
import { List } from "immutable";
import styled from "styled-components";

import ScrollView from "../../shared/layout/scroll-view/scroll-view";
import ModernFilePreview from "../../shared/modern-file-preview/modern-file-preview";
import Text from "../../shared/text/text";

import CenteredLoading from "../../shared/modal/centered-loading";
import SimpleDocListItem from "../shared/simple-doc-list-item";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const StyledModernFilePreview = styled(ModernFilePreview)`
	background: ${({ theme }) => theme.colors.java};
	padding: ${({ theme }) => theme.spacing[4]};
	border-radius: ${({ theme }) => theme.spacing[3]};
	margin-bottom: ${({ theme }) => theme.spacing[5]};
`;

const DocumentsListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: ${({ theme }) => theme.spacing[4]};
	height: 100%;
`;

const StyledInfoText = styled(Text)`
	margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export default class GenerateMergedDocument extends PureComponent {
	static propTypes = {
		isLoading: bool,
		onPreview: func,
		isError: bool,
		onRetry: func,
		affectedDocuments: list,
		fileReference: map,
		affectedDocumentsInfoTid: string,
		showFilePreview: bool,
	};

	static defaultProps = {
		affectedDocumentsInfoTid: "documents.merge_pdf.modal.info.merged_files",
		showFilePreview: true,
		affectedDocuments: List(),
	};

	renderAffectedDocument = (document, index) => {
		return (
			<SimpleDocListItem
				file={document.get("file")}
				title={document.get("title")}
				key={index}
			/>
		);
	};

	render = () => {
		const {
			isLoading,
			isError,
			onPreview,
			onRetry,
			affectedDocuments,
			affectedDocumentsInfoTid,
			fileReference,
			showFilePreview,
		} = this.props;

		if (isLoading) {
			return (
				<CenteredLoading
					size="xxl"
					tid="esign.request_esign_advanced.generate.generating_pdf"
				/>
			);
		}

		if (isError) {
			return (
				<Wrapper>
					<Text
						tid="documents.document.esign.preview_slide.btn.retry"
						onClick={onRetry}
						hasUnderline
					/>
				</Wrapper>
			);
		}

		return (
			<Wrapper>
				{showFilePreview && fileReference && (
					<StyledModernFilePreview
						fileReference={fileReference}
						onOpenFile={onPreview}
					/>
				)}

				<DocumentsListWrapper>
					{affectedDocuments && affectedDocuments.size > 0 && (
						<>
							<StyledInfoText tid={affectedDocumentsInfoTid} />

							<ScrollView noLeftMargin noRightMargin alwaysShow>
								{affectedDocuments.map(this.renderAffectedDocument)}
							</ScrollView>
						</>
					)}
				</DocumentsListWrapper>
			</Wrapper>
		);
	};
}
