import React, { PureComponent } from "react";
import { bool, object, number } from "prop-types";
import styled, { css } from "styled-components";
import { map } from "react-immutable-proptypes";

import Panel from "../../shared/panel/panel";
import DarkInfoPanel from "../../shared/dark-info-panel/dark-info-panel";
import Text from "../../shared/text/text";

const InnerWrapper = styled.div`
	margin-left: ${(props) => props.theme.spacing[props.innerMarginLeft]};
	margin-top: ${(props) => props.theme.spacing[props.innerMarginTop]};
	margin-right: ${(props) => props.theme.spacing[props.innerMarginRight]};
	margin-bottom: ${(props) => props.theme.spacing[props.innerMarginBottom]};
`;

const StyledListWrapper = styled.div`
	display: flex;
	flex-direction: column;

	${(props) =>
		props.topMargin &&
		css`
			margin-top: ${(props) => props.theme.spacing[4]};
		`}
`;
const WhiteText = (props) => <Text color="white" {...props} />;

export default class ProtocolFeedbackPanel extends PureComponent {
	static propTypes = {
		noPanel: bool,
		headerRightComponent: object,
		bottomComponent: object,
		feedbackeesListComponent: object,
		summaryFeedbackeesListComponent: object,
		feedbackees: map,
		showSummary: bool,
		innerMarginLeft: number,
		innerMarginTop: number,
		innerMarginRight: number,
		innerMarginBottom: number,
	};

	static defaultProps = {
		innerMarginLeft: 0,
		innerMarginTop: 0,
		innerMarginRight: 0,
		innerMarginBottom: 0,
		noPanel: true,
	};

	getDarkInfoPanelProps = () => {
		const { headerRightComponent } = this.props;

		return {
			headerRightComponent,
			headerLeftComponent: this.getInfoText(),
			footerComponent: this.renderBottomComponent(),
		};
	};

	getInnerWrapperProps = () => {
		const {
			innerMarginTop,
			innerMarginRight,
			innerMarginBottom,
			innerMarginLeft,
		} = this.props;

		return {
			innerMarginTop,
			innerMarginRight,
			innerMarginBottom,
			innerMarginLeft,
		};
	};

	getHasNoFeedbackees = () => {
		const { feedbackees } = this.props;
		return !feedbackees.size;
	};

	getEveryoneNotified = () => {
		const { feedbackees } = this.props;
		return feedbackees.every((f) => f.get("notified"));
	};

	getSomeNotified = () => {
		const { feedbackees } = this.props;
		return feedbackees.some((f) => f.get("notified"));
	};

	getInfoText = () => {
		const { showSummary } = this.props;
		const noFeedbackees = this.getHasNoFeedbackees();
		const everyoneNotified = this.getEveryoneNotified();
		const someNotified = this.getSomeNotified();

		if (showSummary) {
			return <WhiteText tid="meetings.feedbackees.panel.info_text.summary" />;
		}

		if (noFeedbackees) {
			return <WhiteText tid="meeting.smart.feedback.panel.info_text.pre_add" />;
		}

		if (!everyoneNotified && !someNotified) {
			return (
				<WhiteText tid="meeting.smart.feedback.panel.info_text.pre_notify" />
			);
		}

		if (someNotified && !everyoneNotified) {
			return (
				<WhiteText tid="meeting.smart.feedback.panel.info_text.some_notified" />
			);
		}

		if (everyoneNotified) {
			return (
				<WhiteText tid="meeting.smart.feedback.panel.info_text.all_notified" />
			);
		}
	};

	renderBottomComponent = () => {
		const { bottomComponent } = this.props;
		const noFeedbackees = this.getHasNoFeedbackees();
		const allNotified = this.getEveryoneNotified();

		if (noFeedbackees) {
			return null;
		}

		if (allNotified) {
			return null;
		}

		return bottomComponent;
	};

	renderAttendeesList = () => {
		const {
			showSummary,
			feedbackees,
			feedbackeesListComponent,
			summaryFeedbackeesListComponent,
		} = this.props;
		const hasFeedbackees = feedbackees.size;

		return (
			<StyledListWrapper topMargin={hasFeedbackees}>
				{showSummary
					? summaryFeedbackeesListComponent
					: feedbackeesListComponent}
			</StyledListWrapper>
		);
	};

	renderContent = () => {
		const innerWrapperProps = this.getInnerWrapperProps();
		const darkInfoPanelProps = this.getDarkInfoPanelProps();

		return (
			<InnerWrapper {...innerWrapperProps}>
				<DarkInfoPanel {...darkInfoPanelProps} />

				{this.renderAttendeesList()}
			</InnerWrapper>
		);
	};

	render = () => {
		const { noPanel } = this.props;

		if (noPanel) {
			return this.renderContent();
		}

		return (
			<Panel
				title="meetings.standard_meeting.feedbackees_panel.title"
				marginBottom
			>
				{this.renderContent()}
			</Panel>
		);
	};
}
