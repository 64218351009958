import { fromJS } from "immutable";

export const DEFAULT_ORDER_VALUE = "investorName";
export const DEFAULT_SHOWBY_VALUE = "numOfShares";

// Transaction Limitations
export const SHARES_TRANSACTION_LIMITATIONS = [
	{ value: "pre-emption", label: "transfer-limitation.pre-emption" },
	{
		value: "redemption-reservation",
		label: "transfer-limitation.redemption-reservation",
	},
	{
		value: "redemption-disclaimers",
		label: "transfer-limitation.redemption-disclaimers",
	},
	{
		value: "consent-disclaimers",
		label: "transfer-limitation.consent-disclaimers",
	},
	{
		value: "conversion-disclaimer",
		label: "transfer-limitation.conversion-disclaimer",
	},
];

// Default share classes
export const SHARES_DEFAULT_CLASSES = [
	{ value: "ordinary%20share", label: "share_class.ordinary%20share" },
	{ value: "preference%20share", label: "share_class.preference%20share" },
	{ value: "non-dividend%20share", label: "share_class.non-dividend%20share" },
];

export const getLabel = (list, value) => {
	let res = null;
	list.forEach((v) => {
		if (v.value === value) {
			res = v.label;
		}
	});
	return res || value;
};

// Default share types
export const SHARES_DEFAULT_TYPES = [
	{ value: "A", label: "A" },
	{ value: "B", label: "B" },
	{ value: "C", label: "C" },
	{ value: "D", label: "D" },
];

export const SHARES_POSSIBLE_INVESTOR_STATUSES = fromJS([
	"NEW",
	"ARCHIVED",
	"CURRENT",
]);

export const FILTER_FIELDS = fromJS([
	[
		{
			size: 1 / 2,
			fieldName: "investorStatus",
			labelTid: "investors.filter.label.status",
			options: [
				{ value: "current", label: "investors.filter.status.current" },
				{ value: "archived", label: "investors.filter.status.archived" },
				{ value: "new", label: "investors.filter.status.new" },
			],
			labelIsTid: true,
			isClearable: true,
			placeholder: "select_placeholder",
			renderer: "select",
		},
		{
			size: 1 / 2,
			fieldName: "order",
			labelTid: "investors.filter.label.order",
			options: [
				{ value: "showByAsc", label: "investors.filter.order.asc" },
				{ value: "showByDesc", label: "investors.filter.order.desc" },
				{ value: "investorName", label: "investors.filter.order.alphabetical" },
			],
			labelIsTid: true,
			renderer: "select",
		},
	],
	[
		{
			size: 1 / 2,
			fieldName: "showBy",
			labelTid: "investors.filter.label.show_by",
			options: [
				{
					value: "numOfShares",
					label: "investors.filter.show_by.num_of_hares",
				},
				{
					value: "ownershipPercentage",
					label: "investors.filter.show_by.ownership_percentage",
				},
			],
			labelIsTid: true,
			renderer: "select",
		},
		{
			size: 1 / 2,
			fieldName: "investorTypeOfOwner",
			labelTid: "investors.filter.label.investor_type_of_owner",
			options: [
				{
					value: "private",
					label: "investors.filter.investor_type_of_owner.private",
				},
				{
					value: "company",
					label: "investors.filter.investor_type_of_owner.company",
				},
				{
					value: "capitalInsurance",
					label: "investors.filter.investor_type_of_owner.capital_insurance",
				},
			],
			labelIsTid: true,
			isClearable: true,
			placeholder: "select_placeholder",
			renderer: "select",
		},
	],
	[
		{
			size: 1 / 2,
			fieldName: "registeredUser",
			labelTid: "investors.filter.label.registered_user",
			options: [
				{ value: "yes", label: "investors.filter.registered_user.yes" },
				{ value: "no", label: "investors.filter.registered_user.no" },
			],
			labelIsTid: true,
			isClearable: true,
			placeholder: "select_placeholder",
			renderer: "select",
		},
	],
]);
