// Node modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../interfaces/router";
import history from "../../interfaces/history";
import { string, bool, func, oneOf } from "prop-types";
import ImmutableProps from "react-immutable-proptypes";
import Box from "../../dumb-components/shared/layout/box/box";
import Text from "../../dumb-components/shared/text/text";
import { Map, fromJS } from "immutable";

// Actions
import {
	fetchProject,
	createProject,
	editProject,
	deleteProject,
	updateProjectLocal,
} from "../../actions/projects.actions";
import { clearTask, clearTasksList } from "../../actions/tasks.actions";

// Validations
import { isRequired } from "../../modules/validation.module";

// HOC:s
import immutableForm from "../../components/framework/immutable-form";

// Components
import DropdownMenuContainer from "../shared/dropdown-menu.container";
import DropdownItem from "../../dumb-components/shared/dropdown-item/dropdown-item";
import DropdownIconItem from "../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import ObjectPermissionsContainer from "../shared/object-permissions.contrainer";
import FooterRightControls from "../../dumb-components/shared/modal/footer-right-controls";
import { TransparentButton } from "../../dumb-components/shared/button-v2";
import Tooltip from "../../dumb-components/shared/tooltip/tooltip";
import ModalItemForm from "../../dumb-components/shared/modal/modal-form/modal-item-form";
import ManageFolderModal, {
	StyledColoredContentWrapper,
} from "../../dumb-components/documents/manage-folder/manage-folder-modal";

import {
	LIVE_PROJECT_UPDATE,
	LIVE_PROJECT_DELETE,
} from "../../constants/live-update";

const TAB_INFO = "info";
const TAB_PERMISSIONS = "permissions";

const TABS = [
	{
		tabName: TAB_INFO,
		title: "documents.folder.create_manage.tab.info.title",
	},
	{
		tabName: TAB_PERMISSIONS,
		title: "documents.folder.create_manage.tab.info.permissions",
	},
];

class ProjectsFormContainer extends Component {
	state = {
		activeTab: TAB_INFO,
	};

	static propTypes = {
		projectId: string,
		parentProjectId: string,
		mode: oneOf(["create", "manage"]),
		isOpen: bool,
		onClose: func,
		basePath: string,
		selectedProjectId: string,
		project: ImmutableProps.map,
	};

	static defaultProps = {
		mode: "create",
		isOpen: false,
		project: Map(),
	};

	componentDidMount = () => {
		const { fetchProject, projectId } = this.props;

		if (projectId) {
			fetchProject(projectId);
		}
	};

	componentDidUpdate = (prevProps) => {
		const { fetchProject, projectId } = this.props;

		this.checkLiveUpdateEvents(prevProps);

		if (this.props.projectId && prevProps.projectId !== this.props.projectId) {
			fetchProject(projectId);
		}
	};

	checkLiveUpdateEvents = () => {
		const { audit, projectId, fetchProject } = this.props;
		const projectUpdate = audit.get(LIVE_PROJECT_UPDATE);
		const projectDelete = audit.get(LIVE_PROJECT_DELETE);

		if (projectUpdate && projectUpdate.get("refresh") === true) {
			const objId = projectUpdate.get("objId");

			if (projectId === objId) {
				fetchProject(projectId);
			}
		}

		if (projectDelete && projectDelete.get("refresh") === true) {
			const objId = projectDelete.get("objId");

			if (projectId === objId) {
				this.closeModal();
			}
		}
	};

	onChange = (field, value) => {
		const { updateProjectLocal, resetErrors, company } = this.props;
		let { project } = this.props;

		project = project || Map();

		if (field === "name" || field === "description") {
			let entry = project.get(field, Map());
			entry = entry.set(company.region.language.main, value);
			project = project.set(field, entry);
		} else {
			project = project.set(field, value);
		}

		resetErrors(field);
		updateProjectLocal(project);
	};

	onSubmit = () => {
		const { mode } = this.props;

		if (mode === "create") {
			return this.createProject();
		}

		return this.updateProject();
	};

	createProject = () => {
		const {
			createProject,
			parentProjectId,
			basePath,
			clearTask,
			clearTasksList,
		} = this.props;
		let project = this.props.project || Map();

		project = project.set("parent", parentProjectId);

		if (this.validate(project)) {
			createProject(
				project,
				(newProject) => {
					this.closeModal();
					clearTask();
					clearTasksList();

					history.push({
						pathname: basePath,
						search: `?project=${newProject.get("id")}`,
					});
				},
				this.closeModal,
			);
		}
	};

	updateProject = () => {
		const { editProject, project } = this.props;

		if (this.validate(project)) {
			editProject(
				project,
				() => {
					this.closeModal();
				},
				this.closeModal,
			);
		}
	};

	validate = (project) => {
		const { validate, validateField, company } = this.props;

		if (
			validate(project) &&
			validateField(
				project.getIn(["name", company.region.language.main]),
				"name",
			)
		) {
			return true;
		}

		return false;
	};

	deleteProject = () => {
		const { project, deleteProject, selectedProjectId, basePath } = this.props;

		if (project.get("id") === selectedProjectId) {
			const parentProjectId = project.get("parent");
			const goto = { pathname: basePath };

			if (parentProjectId) {
				goto.search = `?project=${parentProjectId}`;
			}

			history.push(goto);
		}

		deleteProject(project.get("id"), this.closeModal, this.closeModal);
	};

	closeModal = (clearProject = true) => {
		const { onClose, updateProjectLocal, resetErrors } = this.props;

		// Reset any errors so they don't appear upon next modal opening
		resetErrors("name");

		if (clearProject) {
			updateProjectLocal(null);
		}

		this.setState({ activeTab: TAB_INFO });
		onClose && onClose();
	};

	onTabChange = (tab) => {
		this.setState({ activeTab: tab });
	};

	renderFooterComponent = () => {
		const { mode, project, mapParentToChildren, tasks } = this.props;
		const tasksInProject =
			project &&
			tasks &&
			tasks.filter((obj) => {
				return obj.get("projectId") === project.get("id");
			});
		const deleteBtnDisabled =
			project &&
			mapParentToChildren.get(project.get("id")) &&
			mapParentToChildren.get(project.get("id")).size > 0
				? true
				: tasksInProject && tasksInProject.size > 0;

		if (mode === "create") {
			return (
				<FooterRightControls>
					<TransparentButton
						tid="generic.form.create"
						onClick={this.onSubmit}
					/>
					<TransparentButton
						tid="generic.form.cancel"
						textColor="midGrey"
						onClick={this.closeModal}
					/>
				</FooterRightControls>
			);
		}

		return (
			<FooterRightControls>
				<TransparentButton tid="generic.form.save" onClick={this.onSubmit} />
				<DropdownMenuContainer
					inline
					halignMenu="right"
					renderRaw={
						<TransparentButton
							tid="modal.footer.dropdown_menu.more_options"
							textColor="midGrey"
						/>
					}
					openDirection="up"
				>
					<DropdownIconItem
						icon="faTimes"
						tid="modal.footer.dropdown_menu.cancel_and_close"
						onClick={this.closeModal}
					/>
					<DropdownItem divider />
					<Tooltip
						tid="tasks.categories.delete_category.tooltip"
						delayShow="instant"
						active={deleteBtnDisabled}
					>
						<DropdownIconItem
							icon="faTrashAlt"
							tid="tasks.category.delete_category"
							disabled={deleteBtnDisabled}
							onClick={this.deleteProject}
						/>
					</Tooltip>
				</DropdownMenuContainer>
			</FooterRightControls>
		);
	};

	renderTabTitle = (titleTid) => {
		return (
			<Box alignItems="center" valign="center">
				<Text tid={titleTid} />
			</Box>
		);
	};

	renderInfoTab = () => {
		const { errors, company } = this.props;
		let { project } = this.props;

		if (project) {
			project = project.set(
				"name",
				project.getIn(["name", company.region.language.main]),
			);
			project = project.set(
				"description",
				project.getIn(["description", company.region.language.main]),
			);
		}

		return (
			<StyledColoredContentWrapper type="secondary">
				<ModalItemForm
					item={project}
					errors={errors}
					onChange={this.onChange}
					nameTid="tasks.category.form.name"
				/>
			</StyledColoredContentWrapper>
		);
	};

	renderPermissionsTab = () => {
		const { project } = this.props;

		return (
			<StyledColoredContentWrapper type="secondary">
				<ObjectPermissionsContainer
					permissions={project && project.get("permissions")}
					onChange={this.onChange}
					type="transparent"
					noTitle={true}
					infoTextTid="tasks.category.create_manage.team-access.info"
				/>
			</StyledColoredContentWrapper>
		);
	};

	render = () => {
		const { mode, isOpen } = this.props;
		const { activeTab } = this.state;
		const titleTid =
			mode === "create"
				? "tasks.category.create_category"
				: "tasks.category.manage_category";

		return (
			<ManageFolderModal
				isOpen={isOpen}
				titleTid={titleTid}
				footerComponent={this.renderFooterComponent()}
				tabs={TABS}
				activeTab={activeTab}
				onTabChange={this.onTabChange}
			>
				{activeTab === TAB_INFO && this.renderInfoTab()}
				{activeTab === TAB_PERMISSIONS && this.renderPermissionsTab()}
			</ManageFolderModal>
		);
	};
}

function mapStoreToProps(store) {
	return {
		project: store.projects.get("currentProject", Map()),
		selectedProjectId: store.projects.get("selectedProjectId"),
		mapParentToChildren: store.projects.get("mapParentToChildren", Map()),
		tasks: store.tasks.get("allTasks"),
		company: store.company.company,
		i18n: store.i18n,
		audit: store.audit.get("projects"),
	};
}

const mapActionsToProps = {
	fetchProject,
	createProject,
	editProject,
	deleteProject,
	updateProjectLocal,
	clearTask,
	clearTasksList,
};

const validators = fromJS({
	name: {
		tid: "breadcrumbs.item.form.name",
		rules: [{ func: isRequired, message: "validation.is_required" }],
	},
});

const ProjectsFormContainerImmutableForm = immutableForm(
	ProjectsFormContainer,
	"projectDetails",
	validators,
);

export default withRouter(
	connect(
		mapStoreToProps,
		mapActionsToProps,
	)(ProjectsFormContainerImmutableForm),
);
