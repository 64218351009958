import React, { useState, useEffect } from "react";
import { List, Map } from "immutable";
import Panel from "../../../../dumb-components/panel";
import Field from "../../../../dumb-components/fields/field";
import { Select } from "../../../../dumb-components/shared/select/index";
import {
	connectEmailToCompany,
	disconnectEmailFromCompany,
} from "../../../../actions/user.actions";
import { fetchMyInvestmentsSimple } from "../../../../actions/investors.actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Text from "../../../../dumb-components/shared/text/text";
import { getDefaultEmail } from "../../../../components/helpers/users.js";

const DEFAULT_EMAIL = "__default__";

export default function CompanyField({ user, companyEmail }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const companies = useSelector((state) => {
		return state.company.companies;
	});
	const [selectedCompany, setSelectedCompany] = useState();
	const [selectedCompanyEmail, setCompanyEmail] = useState();
	const [investedInCompanies, setInvestedInCompanies] = useState();
	const [companyOptions, setCompanyOptions] = useState(List());
	const userId = user.get("id");
	const emails = user.get("emails");
	const selectedEmail =
		selectedCompanyEmail?.get(selectedCompany) || DEFAULT_EMAIL;
	const defaultEmail = getDefaultEmail(user);

	useEffect(() => {
		if (companies.size > 0) {
			setSelectedCompany(companies.getIn([0, "id"]));
		}
	}, [companies]);

	useEffect(() => {
		setCompanyEmail(companyEmail || Map());
	}, [companyEmail]);

	const getCompanyOptions = (investedInCompanies) => {
		if (!companies) {
			return List();
		}

		const filteredInvestedInCompanies = investedInCompanies?.filter((inv) => {
			return !companies.some(
				(company) => inv.get("investedInCompanyId") === company.get("id"),
			);
		});

		let options = List();

		if (filteredInvestedInCompanies?.size > 0) {
			filteredInvestedInCompanies.forEach((inv) => {
				options = options.push(
					Map({
						label: inv.getIn(["investedInCompanyInformation", "name"]),
						value: inv.getIn(["investedInCompanyInformation", "id"]),
					}),
				);
			});
		}

		companies.forEach((company) => {
			if (company.get("isCanceled")) {
				return null;
			}

			options = options.push(
				Map({
					label: company.get("name"),
					value: company.get("id"),
				}),
			);
		});

		options = options.sort((a, b) => {
			return a.get("label").localeCompare(b.get("label"));
		});

		return options;
	};

	useEffect(() => {
		dispatch(
			fetchMyInvestmentsSimple((investments) => {
				setInvestedInCompanies(investments);
			}),
		);
	}, []);

	useEffect(() => {
		if (companies && investedInCompanies) {
			const companyOptions = getCompanyOptions(investedInCompanies);
			setCompanyOptions(companyOptions);
		}
	}, [companies, investedInCompanies]);

	const onChangeCompany = (companyId) => {
		setSelectedCompany(companyId);
	};

	const onChangeEmail = (emailId) => {
		if (emailId === DEFAULT_EMAIL || emailId === null) {
			setCompanyEmail(selectedCompanyEmail.remove(selectedCompany));
			dispatch(disconnectEmailFromCompany(userId, selectedCompany));
			return;
		}

		setCompanyEmail(selectedCompanyEmail.set(selectedCompany, emailId));
		dispatch(connectEmailToCompany(userId, emailId, selectedCompany));
	};

	const getOptions = () => {
		if (!emails) {
			return List();
		}

		let options = List([
			Map({
				label: t("users.profile.company_field.default_email"),
				value: DEFAULT_EMAIL,
				defaultEmail,
			}),
		]);
		emails.forEach((email) => {
			if (!email.get("verified")) {
				return null;
			}

			options = options.push(
				Map({
					label: email.get("email"),
					value: email.get("id"),
				}),
			);
		});

		return options;
	};

	const formatOptionLabel = (option) => {
		if (option.defaultEmail) {
			return (
				<>
					<Text> {option.label} </Text>{" "}
					<Text color={"muted"}> {option.defaultEmail} </Text>
				</>
			);
		}
		return <Text> {option.label} </Text>;
	};

	return (
		<Panel tid="user_profile.link_email_to_company">
			<Field tid="generic.company">
				<Select
					onChange={onChangeCompany}
					options={companyOptions}
					value={selectedCompany}
				/>
			</Field>
			<Field tid="generic.email">
				<Select
					onChange={onChangeEmail}
					options={getOptions()}
					value={selectedEmail}
					formatOptionLabel={formatOptionLabel}
					isClearable
				/>
			</Field>
		</Panel>
	);
}
