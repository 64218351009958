import React, { useState } from "react";
import MUITreeItem from "@mui/lab/TreeItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import { FormattedMessage } from "react-intl";
import {
	uploadPersonalDocumentsFolder,
	deletePersonalDocumentsFolder,
} from "../../actions/personal-documents.actions";
import { useDispatch } from "react-redux";
import AddFolderModal from "../../dumb-components/personal-documents/add-folder-modal";
import { MODAL_TYPES } from "../../constants/personal-documents";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import FolderDeleteOutlinedIcon from "@mui/icons-material/FolderDeleteOutlined";
import TreeItemContextMenu from "./tree-item-context.menu";
import { useConfirmContext } from "../../containers/shared/confirm/confirm.context";
import { useTranslation } from "react-i18next";

const TreeFolderItem = ({
	name,
	isRoot,
	absolutePath,
	handleBinaryFileDrop,
	handleDocumentDrop,
	handleFolderDrop,
	children,
	nodeId,
	onDragStart,
}) => {
	const dispatch = useDispatch();
	const confirm = useConfirmContext();
	const { t } = useTranslation();

	const [isDataHovered, setIsDataHovered] = useState(false);
	const [activeModalType, setActiveModalType] = useState(null);

	const addFolder = async (folderName) => {
		await dispatch(uploadPersonalDocumentsFolder(absolutePath, folderName));
		setActiveModalType(null);
	};

	const deleteFolder = async () => {
		const result = await confirm.showConfirmation(
			t("personal-documents.confirm.remove-title", { x: name }),
			t("personal-documents.confirm.delete-folder-description"),
		);

		if (!result) {
			return;
		}

		dispatch(deletePersonalDocumentsFolder(absolutePath));
	};

	const label = isRoot ? <FormattedMessage id={name} /> : name;
	const contextMenuOptions = [
		{
			icon: <CreateNewFolderOutlinedIcon />,
			label: "personal-documents.add-folder",
			onClick: () => setActiveModalType(MODAL_TYPES.ADD_FOLDER),
		},
		...(!isRoot
			? [
					{
						icon: <FolderDeleteOutlinedIcon />,
						label: "personal-documents.remove-folder",
						onClick: deleteFolder,
					},
			  ]
			: []),
	];

	return (
		<>
			<MUITreeItem
				onFocusCapture={(e) => e.stopPropagation()}
				draggable={!isRoot}
				onDragStart={onDragStart}
				label={
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							py: 1,
							pl: 0.5,
							pr: 0,
						}}
					>
						<Box
							sx={{ display: "flex", alignItems: "center", fontSize: "48px" }}
						>
							<FolderIcon
								fontSize={"inherit"}
								sx={{ mr: 1 }}
								color={isDataHovered ? "warning" : "inherit"}
							/>
						</Box>
						<Typography variant={"body2"} sx={{ flex: "auto" }}>
							{isDataHovered ? <strong>{label}</strong> : label}
						</Typography>
						<TreeItemContextMenu options={contextMenuOptions} />
					</Box>
				}
				nodeId={nodeId}
				onDrop={(e) => {
					e.stopPropagation();
					e.preventDefault();

					setIsDataHovered(false);

					if (!e.dataTransfer.files.length) {
						const dropData = JSON.parse(e.dataTransfer.getData("drop-data"));

						switch (dropData.type) {
							case "file": {
								handleDocumentDrop(e);
								return;
							}
							case "folder": {
								handleFolderDrop(e);
								return;
							}
							default: {
								throw new Error("Something unknown dropped in the file tree");
							}
						}
					}

					handleBinaryFileDrop(e.dataTransfer.files);
				}}
				onDragOver={(e) => {
					e.preventDefault();
					e.stopPropagation();

					setIsDataHovered(true);
				}}
				onDragLeave={() => setIsDataHovered(false)}
			>
				{children}
			</MUITreeItem>
			{activeModalType === MODAL_TYPES.ADD_FOLDER && (
				<AddFolderModal
					open={activeModalType === MODAL_TYPES.ADD_FOLDER}
					onAccept={addFolder}
					onCancel={() => setActiveModalType(null)}
				/>
			)}
		</>
	);
};

export default TreeFolderItem;
