import React from "react";
import { Switch, Route } from "../../interfaces/router";
import ShareRegister from "../../components/company/shares/share-register";
import Investors from "../../components/company/shares/investors";
import Shares from "../../components/company/shares/shares";
import Transactions from "../../components/company/shares/transactions";
import ShareholderPreview from "./shareholder-preview.container";
import EmissionsForAdmin from "../emissions/admin";
import EmissionViewForAdmin from "../emissions/admin/view";

const SharesRoutesContainer = ({ match }) => {
	return (
		<Switch>
			<Route
				exect
				path={`${match.path}/share-register`}
				component={ShareRegister}
			/>
			<Route
				path={`${match.path}/register-of-shareholders/preview`}
				render={(props) => <ShareholderPreview {...props} />}
			/>
			<Route
				path={`${match.path}/register-of-shareholders/:id?`}
				component={Investors}
			/>
			<Route path={`${match.path}/setup/:setupType?`} component={Shares} />
			<Route path={`${match.path}/transactions`} component={Transactions} />
			<Route
				exact
				path="/:company/shares/cap-table/emission/:id/:tab"
				component={EmissionViewForAdmin}
			/>
			<Route
				exact
				path="/:company/shares/cap-table/emission/:id"
				component={EmissionViewForAdmin}
			/>
			<Route
				exact
				path="/:company/shares/cap-table/emission"
				component={EmissionsForAdmin}
			/>
		</Switch>
	);
};

export default SharesRoutesContainer;
