import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Input from "../../../../dumb-components/fields/input";
import NumericInput from "../../../../dumb-components/fields/numeric-input";
import { List, Map } from "immutable";
import { string, object } from "prop-types";
import Icon from "../../../../dumb-components/shared/icon/icon";
import Alert from "../../../../dumb-components/shared/alert/alert";
import Text from "../../../../dumb-components/shared/text/text";
import Select from "../../../../dumb-components/fields/select";
import Button from "../../../../dumb-components/shared/button/button";

/**
 * Props:
 * - values: [tmpTransaction.sequences]
 * - balances: [transaction.balances]
 * - sequences: [transaction.sequences]
 * - investmentIdFrom: [tmpTransaction.investmentIdFrom]
 * - investmentIdTo: [tmpTransaction.investmentIdTo]
 */

class TransferOfSharesField extends Component {
	static propTypes = {
		intl: intlShape.isRequired,
		investmentIdFrom: string,
		investmentIdTo: string,
		balances: object,
		sequences: object,
		values: object,
	};

	static defaultProps = {
		balances: List(),
		sequences: List(),
	};

	state = {
		expandedRows: [],
	};

	parseValue = () => {
		let { values } = this.props;
		const { sequences, onChange, investmentIdFrom } = this.props;
		values = List();
		sequences &&
			sequences.forEach((seq, index) => {
				if (seq.get("investmentId") === investmentIdFrom) {
					values = values.push(
						Map({
							sequenceId: seq.get("id"),
							shareType: seq.get("type"),
							sequenceFrom: seq.get("sequenceFrom"),
							sequenceTo: seq.get("sequenceTo"),
							data: 0,
							index,
							hasPledge: seq.get("pledgeIds", List()).size > 0,
							amount: seq.get("sequenceTo") - seq.get("sequenceFrom") + 1,
							method: "end",
						}),
					);
				}
			});
		onChange(values);
	};

	componentDidMount = () => {
		const { values, investmentIdFrom } = this.props;
		if (!values && investmentIdFrom) {
			this.parseValue();
		}
	};

	componentDidUpdate = (prevProps) => {
		const { values, investmentIdFrom } = this.props;

		if (
			investmentIdFrom &&
			prevProps.investmentIdFrom !== investmentIdFrom &&
			!values
		) {
			this.parseValue();
		}
		if (
			prevProps.investmentIdFrom !== investmentIdFrom &&
			prevProps.investmentIdFrom &&
			investmentIdFrom
		) {
			this.parseValue();
		}
		if ((!values || values.size === 0) && investmentIdFrom) {
			this.parseValue();
		}
	};

	shouldComponentUpdate = (nextProps, nextState) => {
		const { values, investmentIdFrom, investmentIdTo, balances, sequences } =
			this.props;
		const { expandedRows } = this.state;

		let shouldUpdate = false;

		if (nextProps.investmentIdFrom !== investmentIdFrom) {
			shouldUpdate = true;
		}

		if (nextProps.values !== values) {
			shouldUpdate = true;
		}

		if (nextState.expandedRows !== expandedRows) {
			shouldUpdate = true;
		}

		if (nextProps.investmentIdTo !== investmentIdTo) {
			shouldUpdate = true;
		}

		if (nextProps.balances !== balances) {
			shouldUpdate = true;
		}

		if (nextProps.sequences !== sequences) {
			shouldUpdate = true;
		}

		return shouldUpdate;
	};

	getShareTypeName = (type) => {
		const { i18n } = this.props;
		const [shareClass, shareType] = type.split("$");
		return (
			(i18n.messages[`share_class.${shareClass}`] ||
				decodeURIComponent(shareClass)) +
			" " +
			(i18n.messages[`share_type.${shareType}`] ||
				decodeURIComponent(shareType))
		);
	};

	getAmountByShareType = (type) => {
		const { values } = this.props;
		let amount = 0;
		values &&
			values.forEach((val) => {
				if (val && val.get("shareType") === type) {
					let data = val.get("data");
					if (data) {
						data = data.toString();
						data = data.replace(/\s/g, "");
						const dataArr = data.split(",");

						dataArr.forEach((d) => {
							let [from, to] = d.split("-");
							from = parseInt(from);
							to = parseInt(to);
							amount += to - from + 1;
						});
					}
				}
			});
		return amount > 0 ? amount : null;
	};

	getPriceByShareType = (type) => {
		const { values } = this.props;
		const seq =
			values &&
			values.find((s) => {
				return (s && s.get("shareType")) === type;
			});
		return seq && seq.get("price", null);
	};

	toggleRow = (index) => {
		const expandedRows = [...this.state.expandedRows];
		const rowExpanded = expandedRows ? expandedRows.indexOf(index) : -1;

		if (rowExpanded >= 0) {
			expandedRows.splice(rowExpanded, 1);
		} else {
			expandedRows.push(index);
		}

		this.setState({ expandedRows });
	};

	formatNumerInput = (num) => {
		const { formatNumber } = this.props.intl;

		if (isNaN(num)) {
			return num;
		}
		//return num;
		return formatNumber(num);
	};

	onChangeNumOfShares = (type, val) => {
		let { values } = this.props;
		const { onChange } = this.props;
		// values = values.filter((seq) => {
		// 	return seq.get('shareType') === type;
		// });

		values = values.map((seq) => {
			if (seq && seq.get("shareType") === type) {
				seq = seq.set("used", 0);
				return seq.set("data", "");
			} else {
				return seq;
			}
		});

		const numOfShares = val ? parseInt(val) : 0;
		let sharesLeft = numOfShares;
		values.reverse().forEach((seq) => {
			if (sharesLeft === 0 || seq.get("shareType") !== type) {
				return;
			}

			const amount = seq.get("amount");
			const seqFrom = seq.get("sequenceFrom");
			const seqTo = seq.get("sequenceTo");

			const index = values.findIndex((s) => {
				return s.get("sequenceFrom") === seq.get("sequenceFrom");
			});

			let data = null;
			if (amount > sharesLeft) {
				switch (seq.get("method")) {
					case "end":
						data = `${seqTo - sharesLeft + 1}-${seqTo}`;
						break;
					case "start":
						data = `${seqFrom}-${seqFrom + sharesLeft - 1}`;
						break;
				}
				values = values.setIn([index, "used"], sharesLeft);
				sharesLeft = 0;
			} else {
				data = `${seqFrom}-${seqTo}`;
				values = values.setIn([index, "used"], amount);
				sharesLeft -= amount;
			}

			values = values.setIn([index, "data"], data);
		});
		onChange(values);
	};

	updateMethod = (values) => {
		return values.map((val) => {
			const method = val.get("method");
			if (method === "end" || method === "start") {
				const used = val.get("used");
				const method = val.get("method");
				const seqFrom = val.get("sequenceFrom");
				const seqTo = val.get("sequenceTo");

				let data = null;

				if (used > 0) {
					switch (method) {
						case "end":
							data = `${seqTo - used + 1}-${seqTo}`;
							break;
						case "start":
							data = `${seqFrom}-${seqFrom + used - 1}`;
							break;
					}
				}
				val = val.set("data", data);
			}
			return val;
		});
	};

	onChangeSequence = (index, field, val) => {
		let { values } = this.props;

		values = values.setIn([index, field], val);
		values = this.updateMethod(values);
		this.props.onChange(values);
	};

	onChangePrice = (type, val) => {
		let { values, onChange } = this.props;
		values = values.map((seq) => {
			if (seq.get("shareType") === type) {
				seq = seq.set("price", val);
			}
			return seq;
		});
		onChange(values);
	};

	getSequencesbyShareType = (type) => {
		const investmentIdFrom = this.props.investmentIdFrom;
		let sequences = Map();

		this.props.sequences.forEach((seq, index) => {
			if (
				seq.get("type") === type &&
				seq.get("investmentId") === investmentIdFrom
			) {
				seq = seq.set("index", index);
				sequences = sequences.set(seq.get("index"), seq);
			}
		});

		return sequences;
	};

	renderTransactionLimitations = (sequence) => {
		const { i18n } = this.props;
		let transactionLimitations = sequence.get("transactionLimitations");

		if (transactionLimitations) {
			if (
				!Array.isArray(transactionLimitations) &&
				transactionLimitations.split
			) {
				transactionLimitations = transactionLimitations.split(",");
			}

			return transactionLimitations
				.map((str) => {
					return i18n.messages[`transfer-limitation.${str}`];
				})
				.join(", ");
		}

		return null;
	};

	renderSequence = (sequence, index) => {
		const { values, i18n } = this.props;
		const seqIndex = values.findIndex((s) => {
			return s.get("index") === sequence.get("index");
		});
		const data = values.getIn([seqIndex, "data"]);
		const hasPledge = sequence.get("pledgeIds", List()).size > 0;
		// const isAuto = sequence.get('isAuto');

		const options = [
			{ value: "start", label: i18n.messages["shares.transfer.method.start"] },
			{ value: "end", label: i18n.messages["shares.transfer.method.end"] },
			{
				value: "custom",
				label: i18n.messages["shares.transfer.method.custom"],
			},
		];

		const method = values.getIn([seqIndex, "method"]);
		return (
			<div className="list__item" key={index}>
				<div
					style={{ flex: "1" }}
					className="list__item__text-area list__item__text-area--x2 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left"
				>
					<span>
						{sequence.get("sequenceFrom")} - {sequence.get("sequenceTo")}{" "}
						{hasPledge && <Icon type="solid" size="xs" icon="faExclamation" />}
					</span>
				</div>

				<div className="list__item__text-area" style={{ flex: "1" }}>
					<span className="list__item__input list__item__input--pad-right">
						<Input
							type="text"
							disabled={method !== "custom"}
							className="form-control text--align-right"
							value={data}
							onChange={(val) => {
								this.onChangeSequence(seqIndex, "data", val);
							}}
						/>
					</span>
				</div>
				<div className="list__item__text-area" style={{ width: "100px" }}>
					<span className="list__item__input list__item__input--pad-right">
						<Select
							placeholder={this.props.i18n.messages["select_placeholder"]}
							value={method}
							options={options}
							simpleValue
							onSelect={(val) => {
								this.onChangeSequence(seqIndex, "method", val);
							}}
						/>
					</span>
				</div>
			</div>
		);
	};

	checkIfAuto = (sequences, type) => {
		let isAuto = true;
		sequences &&
			sequences.forEach((seq) => {
				if (
					seq &&
					seq.get("shareType") === type &&
					seq.get("method") === "custom"
				) {
					isAuto = false;
				}
			});
		return isAuto;
	};

	renderShareType = (shareType, index) => {
		const { values } = this.props;
		const { expandedRows } = this.state;
		const type = shareType.get("type");
		const shareTypeName = this.getShareTypeName(type);
		const rowExpanded = expandedRows ? expandedRows.indexOf(index) >= 0 : false;
		const expandIcon = rowExpanded ? "faChevronUp" : "faChevronDown";
		const expandableRowClasses = rowExpanded
			? "list__item__expandable clearfix toggle toggle--pos-rel toggle--on"
			: "list__item__expandable clearfix toggle toggle--pos-rel";
		const amount = this.getAmountByShareType(type);
		const price = this.getPriceByShareType(type);
		let sequences = this.getSequencesbyShareType(type);
		if (sequences.size === 0) {
			return null;
		}

		const isAuto = this.checkIfAuto(values, type);
		sequences = sequences.map((seq) => {
			seq = seq.set("isAuto", isAuto);
			return seq;
		});

		let hasPledge = false;
		values &&
			values.forEach((seq) => {
				if (seq && seq.get("hasPledge")) {
					hasPledge = true;
				}
			});

		return (
			<div key={index}>
				<div className="list__item">
					<div className="list__item__body">
						<span className="list__item__text">
							{shareTypeName}{" "}
							{hasPledge && (
								<Icon type="solid" size="xs" icon="faExclamation" />
							)}
						</span>
					</div>
					<div className="list__item__text-area list__item__text-area--x2">
						<span className="list__item__input list__item__input--pad-right">
							<NumericInput
								type="text"
								className="form-control text--align-right"
								value={amount}
								disabled={!isAuto}
								onChange={(val) => {
									this.onChangeNumOfShares(type, val);
								}}
							/>
						</span>
					</div>
					<div className="list__item__text-area list__item__text-area--x2">
						<span className="list__item__input list__item__input--pad-right">
							<NumericInput
								type="text"
								className="form-control text--align-right"
								allowDecimals
								value={price}
								onChange={(val) => {
									val !== 0 && val !== undefined
										? this.onChangePrice(type, val)
										: null;
								}}
							/>
						</span>
					</div>
					<div className="list__item__text-area list__item__text-area--sm">
						<span className="list__item__input list__item__input--pad-right text--align-right">
							<Button onClick={this.toggleRow.bind(this, index)}>
								<Icon icon={expandIcon} size="xs" type="solid" />
							</Button>
						</span>
					</div>
				</div>
				<div className={expandableRowClasses}>
					<div className="form-group col-md-12">
						<div className="list__list-header">
							<div
								className="list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-lft list__list-header__text-area--pad-right"
								style={{ flex: "1" }}
							>
								<FormattedMessage id="sequences" />
							</div>
							<div
								className="list__list-header__text-area list__list-header__text-area--pad-right"
								style={{ flex: "1" }}
							>
								<FormattedMessage id="nr_to_transfer" />
							</div>
							<div
								className="list__list-header__text-area list__list-header__text-area--pad-right"
								style={{ width: "100px" }}
							>
								<FormattedMessage id="shares.transfer.method" />
							</div>
						</div>
						<div className="list list--striped list--table">
							{sequences.toList().map(this.renderSequence)}
							<div className="help-block">
								<p>
									<strong>
										<FormattedMessage id="shares.transfer_of_shares.information" />
									</strong>
									<br />
									<FormattedMessage id="shares.transfer_of_shares.sequence_information" />
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	renderAlert = () => {
		const { values } = this.props;
		let hasPledge = false;

		values.forEach((seq) => {
			if (seq && seq.get("hasPledge")) {
				hasPledge = true;
			}
		});

		if (!hasPledge) {
			return null;
		}

		return (
			<Alert mode="info" icon="faExclamation" iconType="solid">
				<Text
					tag="p"
					color="white"
					tid="shares.transactions.transfer_of_shares.pledge_information"
				/>
			</Alert>
		);
	};

	render = () => {
		if (!this.props.balances) {
			return null;
		}

		const shareTypes = this.props.balances.getIn([
			this.props.investmentIdFrom,
			"types",
		]);

		if (!shareTypes || !this.props.investmentIdTo) {
			return null;
		}

		return (
			<div>
				<div className="list__list-header">
					<div className="list__list-header__body list__list-header__text-area--pad-lft list__list-header__text-area--pad-right">
						<FormattedMessage id="shares.class_of_shares" />
					</div>
					<div className="list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right">
						<FormattedMessage id="number_of_shares" />
					</div>
					<div className="list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right">
						<FormattedMessage id="price_per_share" />
					</div>
					<div className="list__list-header__text-area list__list-header__text-area--sm list__list-header__text-area--pad-right">
						&nbsp;
					</div>
				</div>
				<div className="list list--striped list--table">
					{shareTypes.map(this.renderShareType)}
				</div>
				{this.renderAlert()}
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		i18n: state.i18n,
	};
}

const TransferOfSharesFieldConnected = connect(mapStateToProps)(
	TransferOfSharesField,
);
const TransferOfSharesFieldInjectIntl = injectIntl(
	TransferOfSharesFieldConnected,
);
export default TransferOfSharesFieldInjectIntl;
