import React, { Component } from "react";
import { connect } from "react-redux";

import history from "../../../interfaces/history";
import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import MenuLabel from "../../../dumb-components/company/nav-dropdown/menu-label";
import List from "../../../dumb-components/company/nav-dropdown/list";
import CompanyItem from "../../../dumb-components/company/nav-dropdown/item";
import AddCompanyItemContainer from "./add-company-item.container";

import { resetCompanyReducer } from "../../../actions/company.actions";

const ITEM_HEIGHT = 60;
const HEADER_HEIGHT = 40;

class CompanyDropdownMenuContainer extends Component {
	state = {
		maxHeight: 0,
	};

	componentDidMount = () => {
		this.calculateMaxHeight();
		window.addEventListener("resize", this.calculateMaxHeight);
	};

	componentDidUmount = () => {
		window.addEventListener("resize", this.calculateMaxHeight);
	};

	calculateMaxHeight = () => {
		const { innerHeight } = window;

		const otherMargins = 30;
		const addCompanyItem = 60;

		const availableHeight =
			innerHeight - HEADER_HEIGHT - otherMargins - addCompanyItem;

		let companyHalfs = Math.floor(availableHeight / (ITEM_HEIGHT / 2));

		if (companyHalfs % 2 === 0) {
			companyHalfs = companyHalfs - 1;
		}

		const maxHeight = companyHalfs * (ITEM_HEIGHT / 2);

		this.setState({ maxHeight });
	};

	onSelectCompany = (urlAlias) => {
		const { resetCompanyReducer, companyUrlAlias } = this.props;
		this.dropdownRef && this.dropdownRef.onToggleMenu();

		if (urlAlias !== companyUrlAlias) {
			resetCompanyReducer();
		}

		this.props.history.push(`/${urlAlias}`);
	};

	onSetDropdownRef = (ref) => {
		this.dropdownRef = ref;
	};

	onSetScrollbarRef = (ref) => {
		this.scrollbarRef = ref;
	};

	onCloseMenu = () => {
		this.scrollbarRef && this.scrollbarRef.scrollToTop();
	};

	getOrgNumber = (orgNumber) => {
		if (
			orgNumber.indexOf("demo=") === 0 ||
			orgNumber.indexOf("userdemo=") === 0
		) {
			return "556987-2335";
		}

		return orgNumber;
	};

	renderMenuComponent = () => {
		const { companyName } = this.props;
		return <MenuLabel companyName={companyName} />;
	};

	renderItem = (company) => {
		const filename = company.getIn(["image", "thumbnail", "filename"]);
		const companyId = company.get("id");
		const imageUrl = filename
			? `/api/companies/public/images/${filename}-100?companyId=${companyId}`
			: null;

		return (
			<CompanyItem
				key={company.get("id")}
				name={company.get("name")}
				imageUrl={imageUrl}
				orgNumber={this.getOrgNumber(company.get("orgNumber"))}
				onSelect={this.onSelectCompany.bind(null, company.get("urlAlias"))}
			/>
		);
	};

	renderAddCompanyItem = () => {
		return <AddCompanyItemContainer dropdownRef={this.dropdownRef} />;
	};

	render = () => {
		const { maxHeight } = this.state;
		const { companies } = this.props;

		if (!companies.size) {
			return <AddCompanyItemContainer />;
		}

		return (
			<DropdownMenuContainer
				ref={this.onSetDropdownRef}
				onClose={this.onCloseMenu}
				noMaxWidth={true}
				alwaysRenderDropdownItems
				renderRaw={this.renderMenuComponent()}
			>
				<List
					companiesSize={companies.size}
					maxHeight={maxHeight}
					onSetScrollbarRef={this.onSetScrollbarRef}
					renderAddCompanyItem={this.renderAddCompanyItem}
				>
					{companies.map(this.renderItem)}
				</List>
			</DropdownMenuContainer>
		);
	};
}

const mapStoreToProps = (store) => ({
	history: history,
	companyName: store.company.company && store.company.company.name,
	companyUrlAlias: store.company.company?.urlAlias,
	companies: store.company.companies?.filter(
		(company) => !company.get("isCanceled"),
	),
});

const mapActionsToProps = {
	resetCompanyReducer,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(CompanyDropdownMenuContainer);
