import React from "react";
import immutablePropTypes from "react-immutable-proptypes";
import { func, string } from "prop-types";
import { Map } from "immutable";
import AttendeesPanel from "../../../dumb-components/meetings/attendees-panel/attendees-panel";
import { useSelector } from "react-redux";
import MainControls from "./attendees-panel-components/MainControls";
import AttendeesListContainer from "./attendees-panel-components/AttendeesListContainer";

const NextMeetingAttendeesPanel = ({
	attendees,
	onAddAttendees,
	onRemoveAttendee,
	scrollableTarget,
	permissions,
}) => {
	const hasMeetingPassed = useSelector((state) =>
		state.meetings.getIn(["meeting", "computedValues", "meetingIsFinished"]),
	);

	const renderHeaderRightComponent = () => {
		return (
			<MainControls attendees={attendees} onAddAttendees={onAddAttendees} />
		);
	};

	const renderAttendeesList = () => {
		return (
			<AttendeesListContainer
				permissions={permissions}
				attendees={attendees}
				scrollableTarget={scrollableTarget}
				onRemoveAttendee={onRemoveAttendee}
			/>
		);
	};

	return (
		<AttendeesPanel
			headerRightComponent={renderHeaderRightComponent()}
			invitedAttendeesListComponent={renderAttendeesList()}
			attendees={attendees}
			isStandardMeeting={false}
			hasMeetingPassed={hasMeetingPassed}
			rollcallDone={false}
		/>
	);
};

NextMeetingAttendeesPanel.propTypes = {
	scrollableTarget: string,
	attendees: immutablePropTypes.map,
	onAddAttendees: func,
	onRemoveAttendee: func,
	permissions: immutablePropTypes.map,
};

NextMeetingAttendeesPanel.defaultProps = {
	attendees: Map(),
};

export default NextMeetingAttendeesPanel;
