import React, { Component } from "react";
import { connect } from "react-redux";
import { map } from "react-immutable-proptypes";
import { func } from "prop-types";

import DropdownIconItem from "../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import Tooltip from "../../../../dumb-components/shared/tooltip/tooltip";

import MoveFileComponent from "../move-file-component.container";

class MoveFileItemContainer extends Component {
	static propTypes = {
		document: map.isRequired,
		onClick: func,
	};

	render = () => {
		const { document, onClick } = this.props;
		const isDeleted = document.get("isDeleted");

		if (isDeleted) {
			return null;
		}

		return (
			<MoveFileComponent
				document={document}
				renderComponent={({
					TOOLTIP_STATES,
					activeState,
					isDisabled,
					tooltipValues,
					openMoveModal,
				}) => (
					<Tooltip
						states={TOOLTIP_STATES}
						activeState={activeState}
						active={isDisabled}
						values={tooltipValues}
					>
						<DropdownIconItem
							tid="documents.document.toolbar.item.move_document"
							icon="faCodeBranch"
							disabled={isDisabled}
							onClick={() => {
								onClick && onClick();
								openMoveModal();
							}}
						/>
					</Tooltip>
				)}
			/>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MoveFileItemContainer);
