import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import { styled } from "@mui/system";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import Typography from "@mui/material/Typography";
import { useInvitedAsFilter } from "../../common/invited-as-filter-hook";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import { NUMBER_FORMAT_INTEGER } from "/shared/constants.json";
import {
	deleteReceipts,
	generateReceipts,
} from "../../../../actions/emissions.actions";
import { emissionConsts } from "../../../../constants/emissions";
import {
	OpenClosureDocuments,
	SendReceiptContextMenu,
} from "./send-receipt-context-menu";

const AgGridStyle = styled("div")(({ theme }) => ({
	height: "80rem",
	"& .ag-header": {
		borderRadius: 5,
		backgroundColor: theme.palette.blue["100"],
	},
	"& .ag-cell": {
		display: "flex",
		alignItems: "center",
	},
}));

const PaymentGrid = ({ submissions, closure, preventChanges }) => {
	const { t } = useTranslation();
	const invitedAsFilter = useInvitedAsFilter();
	const { investorType } = emissionConsts.invitation;

	const allocationsWithNameAndSsn = closure.allocations.map((a) => {
		let subscriber = submissions.filter((s) => a.inviteeId === s.inviteeId);
		a.closureId = closure.id;
		a.subscriptionSlipDocId_sv = subscriber.map(
			(s) => s.submission.documentId_sv,
		);
		a.subscriptionSlipDocId_en = subscriber.map(
			(s) => s.submission.documentId_en,
		);
		a.name = subscriber.map((s) => s.subscriber);
		let type = subscriber.map((s) => s.submission.type);
		if (type[0] === investorType.private) {
			a.ssn = subscriber.map((s) => s.submission.private.ssn);
			return { ...a };
		} else if (type[0] === investorType.company) {
			a.ssn = subscriber.map((s) => s.submission.company.organizationNumber);
			return { ...a };
		} else if (type[0] === investorType.insurance) {
			a.ssn = subscriber.map((s) => s.submission.insurance.beneficiary.ssn);
			return { ...a };
		}
		return { ...a };
	});

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				{invitedAsFilter.renderFilterButtons()}
			</Box>
			<AgGridStyle className={"ag-theme-material"}>
				<AgGridReact
					gridOptions={{
						onGridReady(event) {
							invitedAsFilter.setGridApi(event.api);
							event.api.sizeColumnsToFit();
						},
						onGridSizeChanged(event) {
							event.api.sizeColumnsToFit();
						},
						suppressScrollOnNewData: true,
						getLocaleText: (params) => t(`ag-grid.locale.${params.key}`),
					}}
					rowHeight={56}
					defaultColDef={{
						suppressMovable: true,
						cellStyle: {
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
							overflow: "hidden",
							border: "none",
						},
					}}
					components={{
						ssnRenderer: RefSsnOrgNumberCell,
						subscriberRenderer: SubscriberCell,
						paymentRenderer: (params) => {
							const customParams = {
								...params,
								preventChanges: preventChanges,
							};
							return <PaymentCell {...customParams} />;
						},
						sharesRenderer: SharesCell,
						sumRenderer: SumCell,
						actionsRenderer: ActionsCell,
					}}
					getContextMenuItems={true}
					rowData={allocationsWithNameAndSsn}
					columnDefs={[
						{
							field: "invitedAs",
							filter: "agSetColumnFilter",
							hide: true,
						},
						{
							field: "ssn",
							headerName: t("emissions.subscriptions.grid.column_header.ssn"),
							suppressMenu: true,
							cellRenderer: "ssnRenderer",
							editable: false,
						},
						{
							field: "subscriber",
							headerName: t("emissions.pdf.header.name"),
							suppressMenu: true,
							cellRenderer: "subscriberRenderer",
							editable: false,
							sortable: true,
							sort: "asc",
							comparator: (...args) =>
								args[2].data.name[0]?.localeCompare(args[3].data.name[0]),
						},
						{
							field: "allocatedShares",
							headerName: t("emissions.payment.allocated-shares"),
							cellRenderer: "sharesRenderer",
							suppressMenu: true,
						},
						{
							field: "allocatedSum",
							headerName: t("emissions.payment.allocated-sum"),
							cellRenderer: "sumRenderer",
							suppressMenu: true,
							editable: false,
						},
						{
							field: "paid",
							headerName: t("emissions.payment.have-paid"),
							suppressMenu: true,
							cellRenderer: "paymentRenderer",
							editable: false,
						},
						!preventChanges
							? {
									suppressMenu: true,
									cellRenderer: "actionsRenderer",
									pinned: "right",
									width: 100,
							  }
							: {},
					]}
				/>
			</AgGridStyle>
		</Box>
	);
};

const RefSsnOrgNumberCell = (props) => {
	return (
		<Tooltip title={props.data.ssn ?? ""}>
			<Typography
				variant={"body2"}
				sx={{
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					overflow: "hidden",
				}}
			>
				{props.data.ssn}
			</Typography>
		</Tooltip>
	);
};

const SubscriberCell = (props) => {
	return (
		<Tooltip title={props.data.name}>
			<Typography
				variant={"body2"}
				sx={{
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					overflow: "hidden",
				}}
			>
				{props.data.name}
			</Typography>
		</Tooltip>
	);
};

const SharesCell = (props) => {
	return (
		<Typography>
			{localeFormatNumber(props.data.allocatedShares, NUMBER_FORMAT_INTEGER)}
		</Typography>
	);
};

const SumCell = (props) => {
	const price = useSelector((state) => state.emissions.current.pricePerShare);

	return (
		<Typography>
			{localeFormatNumber(props.data.allocatedShares * price)}
		</Typography>
	);
};

const PaymentCell = (props) => {
	const [checked, setChecked] = useState(props.data.paymentCompleted ?? false);
	const emission = useSelector((state) => state.emissions.current);
	const dispatch = useDispatch();

	if (props.data.receiptSent) {
		return (
			<Checkbox
				defaultChecked
				disabled
				sx={{
					"&.Mui-disabled": {
						color: `${!props.preventChanges ? "#00C853" : "lightgray"}`,
					},
				}}
			/>
		);
	}

	const handleChange = async (event) => {
		setChecked(event.target.checked);

		if (!checked) {
			await dispatch(
				generateReceipts(
					emission.id,
					emission.companyId,
					props.data.inviteeId,
					props.data.closureId,
				),
			);
		} else {
			await dispatch(
				deleteReceipts(
					emission.id,
					emission.companyId,
					props.data.inviteeId,
					props.data.closureId,
				),
			);
		}
	};

	return (
		<Checkbox
			disabled={props.preventChanges}
			checked={checked}
			onChange={handleChange}
		/>
	);
};

const ActionsCell = (props) => {
	const langCode = useSelector((s) => s.i18n.language).substring(0, 2);

	return (
		<>
			<OpenClosureDocuments
				paymentCompleted={props.data.paymentCompleted}
				subscriptionSlipDocId={props.data["subscriptionSlipDocId_" + langCode]}
				settlementDocId={props.data.settlementDocId}
				receiptDocId={props.data["receiptDocId_" + langCode]}
				closureId={props.data.closureId}
				inviteeId={props.data.inviteeId}
			/>
			<SendReceiptContextMenu
				paymentCompleted={props.data.paymentCompleted}
				receiptSent={props.data.receiptSent}
				investmentId={props.data.investmentId}
				closureId={props.data.closureId}
			/>
		</>
	);
};

export default PaymentGrid;
