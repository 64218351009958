import React, { Component } from "react";
import { connect } from "react-redux";

import ManageView from "../../../dumb-components/meetings/voting/manage-modal/manage";

const START_VOTING_BUTTON = "startVotingButton";

import { MEETING_VOTING_DRAFT } from "/shared/constants";
import { saveVoting } from "../../../actions/votings.actions";
import { setComponentLoading } from "../../../actions/notify.actions";

class VotingManageContainer extends Component {
	state = {
		step: 1,
	};

	componentDidMount = () => {
		this.setCorrectStep(this.props.voting);
	};

	updateVotingLocal = (voting) => {
		const { setComponentLoading, saveVoting } = this.props;
		setComponentLoading && setComponentLoading(START_VOTING_BUTTON, true);
		saveVoting(voting, () => {
			setComponentLoading && setComponentLoading(START_VOTING_BUTTON, false);
		});
	};

	// Chek what data has partially been filled in
	setCorrectStep = (voting) => {
		const topicRef = voting.get("topicRef");
		const question = voting.get("question");

		if (topicRef && question) {
			this.setState({ step: 3 });
		} else if (!topicRef) {
			this.setState({ step: 1 });
		} else if (!question) {
			this.setState({ step: 2 });
		}
	};

	onChange = (field, value) => {
		let { voting } = this.props;

		if (field === "timer" && value === "none") {
			value = null;
		}

		voting = voting.set(field, value);

		this.setCorrectStep(voting);

		this.updateVotingLocal(voting);
	};

	render = () => {
		const { voting } = this.props;

		return (
			<ManageView
				topicRef={voting.get("topicRef", "")}
				question={voting.get("question", "")}
				timer={voting.get("timer", 0)}
				status={voting.get("status", MEETING_VOTING_DRAFT)}
				step={this.state.step}
				onChange={this.onChange}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		voting: store.votings.get("voting"),
	};
};

const mapActionsToProps = {
	saveVoting,
	setComponentLoading,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(VotingManageContainer);
