import React, { PureComponent } from "react";
import { string, oneOf, func } from "prop-types";
import styled from "styled-components";
import Moment from "../../../modules/moment.module";

import Modal from "../../shared/modal/modal";
import Text from "../../shared/text/text";
import FooterRightControls from "../../shared/modal/footer-right-controls";
import { TransparentButton } from "../../shared/button-v2";
import ColoredContentWrapper from "../../shared/colored-content-wrapper/colored-content-wrapper";

import {
	MEETING_REDIRECT_PROTOCOL_SIGN,
	MEETING_REDIRECT_PROTOCOL_FEEDBACK,
	MEETING_REDIRECT_PROTOCOL_OPEN,
} from "/shared/constants";

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	display: flex;
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
`;

const InfoRow = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

const BodyText = styled.div`
	display: flex;
	margin-top: ${(props) => props.theme.spacing[5]};
`;

export default class RedirectToProtocolComponent extends PureComponent {
	static propTypes = {
		action: oneOf(["leave-feedback", "sign-protocol"]),
		onOpen: func,
		onClose: func,
		meetingName: string,
		meetingStartDate: string,
	};

	static defaultProps = {
		meetingName: "-",
	};

	getData = () => {
		const { action } = this.props;
		let titleTid, bodyTid;

		if (action === MEETING_REDIRECT_PROTOCOL_FEEDBACK) {
			titleTid = "meetings.redirect_to_protocol.modal.title.feedback";
			bodyTid = "meetings.redirect_to_protocol.feedback";
		} else if (action === MEETING_REDIRECT_PROTOCOL_SIGN) {
			titleTid = "meetings.redirect_to_protocol.modal.title.sign";
			bodyTid = "meetings.redirect_to_protocol.sign";
		} else if (action === MEETING_REDIRECT_PROTOCOL_OPEN) {
			titleTid = "meetings.redirect_to_protocol.modal.title.open";
			bodyTid = "meetings.redirect_to_protocol.open";
		}

		return { titleTid, bodyTid };
	};

	renderFooter = () => {
		const { onOpen, onClose } = this.props;

		return (
			<FooterRightControls>
				<TransparentButton tid="generic.open" onClick={onOpen && onOpen} />
				<TransparentButton
					tid="generic.close"
					textColor="midGrey"
					onClick={onClose && onClose}
				/>
			</FooterRightControls>
		);
	};

	renderBody = (bodyTid) => {
		const { meetingName, meetingStartDate } = this.props;
		const dateText = meetingStartDate
			? Moment(meetingStartDate).format("LLL")
			: "-";

		return (
			<StyledColoredContentWrapper type="secondary">
				<InfoRow>
					<Text tid="meetings.general.label.meeting_name" bold={600} />
					<Text>{meetingName}</Text>
				</InfoRow>
				<InfoRow>
					<Text tid="meetings.generic.start_date" bold={600} />
					<Text>{dateText}</Text>
				</InfoRow>
				<BodyText>
					<Text tid={bodyTid} />
				</BodyText>
			</StyledColoredContentWrapper>
		);
	};

	render = () => {
		const { titleTid, bodyTid } = this.getData();

		return (
			<Modal
				isOpen={true}
				title={titleTid}
				hSize="md"
				vSize={75}
				footerComponent={this.renderFooter()}
			>
				{this.renderBody(bodyTid)}
			</Modal>
		);
	};
}
