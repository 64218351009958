import React from "react";
import { string } from "prop-types";
import Panel from "../../shared/panel/panel";
import PaymentMethods from "@/components/subscriptions/paymentMethods";
import BillingDetails from "@/components/subscriptions/billingDetails";
import TaxDetails from "@/components/subscriptions/taxDetails";
import { CreditCard } from "lucide-react";

const PaymentDetailsPanel = () => (
	<>
		<Panel
			icon={<CreditCard />}
			title="subscriptions.payment_methods.plural"
			marginBottom
		>
			<div className="p-6">
				<PaymentMethods />
			</div>
		</Panel>
		<Panel title="subscriptions.billing_details" marginBottom>
			<div className="p-6">
				<BillingDetails />
			</div>
		</Panel>
		<Panel title="subscriptions.tax_details" marginBottom>
			<div className="p-6">
				<TaxDetails />
			</div>
		</Panel>
	</>
);

PaymentDetailsPanel.propTypes = {
	companyId: string,
};

export default PaymentDetailsPanel;
