import React, { PureComponent } from "react";
import { bool, func } from "prop-types";
import styled from "styled-components";
import Modal from "../../shared/modal/modal";
import ColoredContentWrapper from "../../shared/colored-content-wrapper/colored-content-wrapper";
import Icon from "../../shared/icon/icon";
import Text from "../../shared/text/text";
import FooterRightControls from "../../shared/modal/footer-right-controls";
import TransparentButton from "../../shared/button-v2/src/buttons/transparent-button";

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-top: ${(props) => props.theme.spacing[5]};
`;
const StyledIconWrapper = styled.div`
	padding-bottom: ${(props) => props.theme.spacing[3]};
`;

const StyledTextWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing[4]};
`;

export default class ViaGuiWarningModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		onCancel: func,
	};

	renderModalButtons = () => {
		const { onCancel } = this.props;

		return (
			<FooterRightControls>
				<TransparentButton
					tid="new_share_issue.via_gui.warning_modal.footer.button.upload_excel"
					onClick={onCancel}
				/>
				<TransparentButton
					tid="new_share_issue.via_gui.warning_modal.footer.button.close"
					textColor="midGrey"
					onClick={onCancel}
				/>
			</FooterRightControls>
		);
	};

	render = () => {
		const { isOpen } = this.props;

		return (
			<Modal
				hSize="md"
				vSize={50}
				isOpen={isOpen}
				footerComponent={this.renderModalButtons()}
				title={"new_share_issue.via_gui.warning_modal.title"}
				scrollableContent={false}
			>
				<StyledColoredContentWrapper type="secondary">
					<StyledIconWrapper>
						<Icon
							icon="faExclamationTriangle"
							size="normal"
							color="yellowMid"
							type="solid"
						/>
					</StyledIconWrapper>
					<Text tid="new_share_issue.via_gui.warning_modal.info_1" />
					<StyledTextWrapper>
						<Text tid="new_share_issue.via_gui.warning_modal.info_2" />
					</StyledTextWrapper>
				</StyledColoredContentWrapper>
			</Modal>
		);
	};
}
