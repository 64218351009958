import React, { PureComponent } from "react";
import styled from "styled-components";
import { bool, func, string } from "prop-types";
import Text from "../../shared/text/text";
import moment from "../../../modules/moment.module";

const StyledWrapper = styled.span`
	cursor: pointer;
`;

export default class StartMeetingMessage extends PureComponent {
	static propTypes = {
		isAdmin: bool,
		onClick: func,
		startDate: string,
		isStandardMeeting: bool,
		templateId: string,
	};

	render = () => {
		const { isAdmin, onClick, startDate, isStandardMeeting, templateId } =
			this.props;

		const standardMeeting = isAdmin && isStandardMeeting;
		const smartMeeting = isAdmin && !isStandardMeeting && templateId;

		if (standardMeeting || smartMeeting) {
			return (
				<StyledWrapper onClick={onClick}>
					<Text
						color="white"
						tid="meetings.start_meeting_message.click_to"
						fontSize="16px"
					/>
					&nbsp;
					<Text
						color="white"
						bold={700}
						fontSize="16px"
						tid="meetings.start_meeting_message.start_the_meeting"
						hasUnderline
					/>
					&nbsp;
					<Text color="white" fontSize="16px">
						@
					</Text>
					&nbsp;
					<Text color="white" fontSize="16px">
						{moment(startDate).format("LT")}
					</Text>
				</StyledWrapper>
			);
		}

		return (
			<span>
				<Text
					color="white"
					tid="meetings.start_meeting_message.meeting_starts_at"
					fontSize="16px"
				/>
				&nbsp;
				<Text color="white" fontSize="16px">
					{moment(startDate).format("LT")}
				</Text>
			</span>
		);
	};
}
