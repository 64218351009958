import React, { PureComponent } from "react";
import { string, bool } from "prop-types";
import { list } from "react-immutable-proptypes";
import styled from "styled-components";
import { List as ImmutableList } from "immutable";

import Panel from "../../../shared/panel/panel";
import List from "../../../shared/list/list";
import Text from "../../../shared/text/text";
import Icon from "../../../shared/icon/icon";
import GenericInfoListItem from "./generic-info-list-item";

const InnerWrapper = styled.div`
	padding: ${(props) => props.theme.spacing[4]}
		${(props) => props.theme.spacing[4]};
`;

const StyledLoadingWrapper = styled.div`
	display: flex;
	width: 100%;
	min-height: 100px;
	align-items: center;
	justify-content: center;
`;

export default class CompanyDashboardGenericInfoPanel extends PureComponent {
	static propTypes = {
		title: string,
		data: list,
		isLoading: bool,
		noItemsTid: string,
		icon: Panel.propTypes.icon,
	};

	static defaultProps = {
		data: ImmutableList(),
		isLoading: false,
	};

	renderContent = () => {
		const { isLoading, data, noItemsTid, children } = this.props;

		if (isLoading) {
			return (
				<StyledLoadingWrapper>
					<Icon icon="faSpinner" size="xl" spin />
				</StyledLoadingWrapper>
			);
		}

		if (!children && data.size === 0 && noItemsTid) {
			return <Text tid={noItemsTid} />;
		}

		if (!children && data) {
			return <List>{data.map(this.renderListItem)}</List>;
		}

		if (children) {
			return <List>{children}</List>;
		}
	};

	renderListItem = (item, index) => {
		return (
			<GenericInfoListItem
				key={index}
				label={item.get("title")}
				value={item.get("value")}
			/>
		);
	};

	render = () => {
		const { title, icon } = this.props;

		return (
			<Panel icon={icon} mode="light" title={title} className="h-full">
				<InnerWrapper>{this.renderContent()}</InnerWrapper>
			</Panel>
		);
	};
}
