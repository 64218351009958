import { useEffect, useState } from "react";
import api from "../modules/request.module.v2";

const COLLECT_INTERVAL = 1000;
const BANK_ID_INFO_INITIAL_STATE = {
	orderRef: null,
	desktopAppLink: null,
	qrLink: null,
	bankIdMessage: null,
	status: null,
};

export default (options = {}) => {
	const [bankIdInfo, setBankIdInfo] = useState(BANK_ID_INFO_INITIAL_STATE);

	const collectFn =
		options.collectFn ??
		((orderRef) => api.post("/users/public/bankid/collect", { orderRef }));

	const collect = async () => {
		if (!bankIdInfo.orderRef) {
			console.info("useBankId: No orderRef found. Collecting stopped.");
			return;
		}

		if (bankIdInfo.status !== "pending") {
			console.info("useBankId: No pending status found. Collecting stopped.");
			return;
		}

		const collectResponse = await collectFn(bankIdInfo.orderRef);

		if (!collectResponse?.data) {
			console.info("useBankId: No data in response. Collecting stopped.");
			return;
		}

		if (collectResponse.data.status === "complete") {
			console.info("useBankId: Auth is complete. Collecting stopped.");

			options.onBankIdAuthComplete?.({
				...collectResponse.data,
				orderRef: bankIdInfo.orderRef,
			});

			return setBankIdInfo(BANK_ID_INFO_INITIAL_STATE);
		}

		setBankIdInfo({
			orderRef: bankIdInfo.orderRef,
			desktopAppLink: collectResponse.data.desktopAppLink,
			qrLink: collectResponse.data.qrLink,
			bankIdMessage: collectResponse.data.bankIdMessage,
			status: collectResponse.data.status,
		});
	};

	// Trigger the collection process once orderRef is set
	useEffect(() => {
		if (bankIdInfo.orderRef) {
			const collectTimeout = window.setTimeout(collect, COLLECT_INTERVAL);
			return () => clearTimeout(collectTimeout);
		}
	}, [bankIdInfo.orderRef, bankIdInfo.qrLink]);

	// Function that triggers the bankId authentication
	const initBankIdAuth = (initialRequestPromise) => {
		initialRequestPromise.then((response) => {
			setBankIdInfo({
				orderRef: response.data.orderRef,
				desktopAppLink: response.data.desktopAppLink,
				qrLink: response.data.qrLink,
				bankIdMessage:
					response.data.bankIdMessage ??
					"auth.bankid.status.OUTSTANDING_TRANSACTION",
				status: response.data.status ?? "pending",
			});
		});
	};

	const cancelBankId = async () => {
		await api.post("/users/public/bankid/cancel", {
			orderRef: bankIdInfo.orderRef,
		});
		resetBankId();
	};

	const resetBankId = () => {
		setBankIdInfo(BANK_ID_INFO_INITIAL_STATE);
	};

	return { bankIdInfo, initBankIdAuth, cancelBankId, resetBankId };
};
