import React, { Component } from "react";
import { connect } from "react-redux";
import { string, oneOf } from "prop-types";

import RedirectToProtocolModal from "../../dumb-components/meetings/redirect-to-protocol-modal/redirect-to-protocol-modal";
import { PROTOCOL_REDIRECT_VALID_ACTIONS } from "../../constants/meetings";
import history, { getQuery } from "../../interfaces/history";
import { generateQuery } from "../../components/helpers/query-helper";

class RedirectToProtocolModalContainer extends Component {
	state = {
		isOpen: false,
	};

	static propTypes = {
		source: oneOf(["email"]),
		action: oneOf(["leave-feedback", "sign-protocol"]),
		basePath: string,
	};

	static defaultProps = {};

	componentDidMount() {
		this.openModalIfNeeded();
	}

	componentDidUpdate(prevProps) {
		const { meetingId } = this.props;

		if (prevProps.meetingId !== meetingId) {
			this.openModalIfNeeded();
		}
	}

	openModalIfNeeded = () => {
		const { source, action, meetingId } = this.props;

		// Meeting not yet fetched
		if (!meetingId || !source || !action) {
			return;
		}

		if (
			source === "email" &&
			PROTOCOL_REDIRECT_VALID_ACTIONS.includes(action)
		) {
			this.setState({ isOpen: true });
		}
	};

	removeQueries = () => {
		const queries = ["source", "action"];
		const { location } = history;
		const query = getQuery(location.search);
		queries.forEach((q) => delete query[q]);
		location.search = generateQuery(query);
		history.replace(location);
	};

	onOpen = () => {
		const { basePath, meetingId } = this.props;

		window.open(
			`${basePath.replace("meetings", `protocols/${meetingId}`)}`,
			"_blank",
		);

		this.closeModal();
	};

	closeModal = () => {
		this.setState({ isOpen: false }, () => {
			this.removeQueries();
		});
	};

	render() {
		const { action, meetingName, meetingStartDate } = this.props;
		const { isOpen } = this.state;

		if (!isOpen) {
			return null;
		}

		return (
			<RedirectToProtocolModal
				action={action}
				meetingName={meetingName}
				meetingStartDate={meetingStartDate}
				onOpen={this.onOpen}
				onClose={this.closeModal}
			/>
		);
	}
}

const mapStoreToProps = (store) => {
	return {
		meetingId: store.meetings.getIn(["meeting", "id"]),
		meetingName: store.meetings.getIn(["meeting", "name"]),
		meetingStartDate: store.meetings.getIn(["meeting", "startDate"]),
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(RedirectToProtocolModalContainer);
