import React, { Component } from "react";
import { connect } from "react-redux";
import { string } from "prop-types";

import { saveAgendaItem } from "../../../actions/meetings.actions";

import Alert from "../../../dumb-components/shared/alert/alert";
import StatusPanel from "../../../dumb-components/shared/status-panel/status-panel";
import AgendaTopicStatusDropdownButton from "../../../dumb-components/shared/color-progress-select/agenda-topic-status-dropdown-button";

import StartMeetingContainer from "./start-meeting.container";
import EndMeetingContainer from "./end-meeting.container";
import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import StatusDropdownItem from "../../../dumb-components/shared/color-progress-select/agenda-topic-status-dropdown-item";

import { MEETINGS_OUTCOMES } from "../../../constants/meetings";
import Box from "@mui/material/Box";

class StatusPanelContainer extends Component {
	dropdownRef = null;

	static propTypes = {
		basePath: string,
	};

	onChange = (field, val) => {
		const { saveAgendaItem } = this.props;
		let { agendaItem } = this.props;

		this.dropdownRef && this.dropdownRef.onToggleMenu();

		agendaItem = agendaItem.set(field, val);

		saveAgendaItem(agendaItem);
	};

	renderDropdownItem = (item, index) => {
		const statusLabelTid = item?.get("label");
		const statusColor = item?.get("statusColor");
		const statusValue = item?.get("value");

		return (
			<StatusDropdownItem
				key={index}
				statusLabelTid={statusLabelTid}
				statusColor={statusColor}
				onClick={this.onChange.bind(null, "outcome", statusValue)}
			/>
		);
	};

	renderComponent = () => {
		const { agendaItem, meetingIsStarted, meetingIsFinished, basePath } =
			this.props;
		const internalType = agendaItem?.get("internalType");
		const outcomeValue = agendaItem?.get("outcome");
		let outcomeConstant = MEETINGS_OUTCOMES.find(
			(obj) => obj.get("value") === outcomeValue,
		);

		if (!outcomeConstant) {
			outcomeConstant = MEETINGS_OUTCOMES.first();
		}

		const outcomeColor = outcomeConstant.get("statusColor");
		const outcomeTid = outcomeConstant.get("label");

		if (
			(internalType === "open" && meetingIsStarted) ||
			(internalType === "close" && meetingIsFinished)
		) {
			return null;
		}

		if (internalType === "open") {
			return <StartMeetingContainer mode="button" />;
		}

		if (internalType === "close") {
			return <EndMeetingContainer mode="button" basePath={basePath} />;
		}

		return (
			<Box sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
				<DropdownMenuContainer
					ref={(r) => (this.dropdownRef = r)}
					halignMenu="right"
					renderRaw={
						<AgendaTopicStatusDropdownButton
							statusLabelTid={outcomeTid}
							statusColor={outcomeColor}
							outcomeValue={outcomeValue}
						/>
					}
				>
					{MEETINGS_OUTCOMES.map(this.renderDropdownItem)}
				</DropdownMenuContainer>
			</Box>
		);
	};

	render() {
		const { agendaItem, meetingIsStarted, meetingIsFinished } = this.props;
		const internalType = agendaItem?.get("internalType");
		const outcome = agendaItem?.get("outcome");
		const hasSubTopics =
			agendaItem?.has("agendaItems") && agendaItem.get("agendaItems")?.size > 0;
		const agendaSubItems = agendaItem?.get("agendaItems");

		let infoTid;
		let passedSubTopics;
		let allSubTopics;
		if (hasSubTopics) {
			passedSubTopics = agendaSubItems.filter(
				(item) => item.get("outcome") === "passed",
			).size;
			allSubTopics = agendaSubItems.size;
			infoTid =
				"meetings.agenda.status_panel.agenda_topic.completed_sub_topics";
		} else if (internalType === "open") {
			infoTid = meetingIsStarted
				? "meetings.agenda.status_panel.meeting_started"
				: "meetings.agenda.status_panel.click_to_open_meeting";
		} else if (internalType === "close") {
			if (!meetingIsStarted) {
				return (
					<Alert
						mode="warning"
						tid="meetings.agenda.status_panel.close_meeting.not_started"
						icon="faExclamationTriangle"
						iconType="solid"
					/>
				);
			}
			infoTid = meetingIsFinished
				? "meetings.agenda.status_panel.meeting_close"
				: "meetings.agenda.status_panel.click_to_close_meeting";
		} else if (!meetingIsStarted && outcome === "todo") {
			return null;
		} else {
			infoTid =
				outcome === "todo" || !outcome
					? "meetings.agenda.status_panel.select_status_to_update_progress" //Välj agendapunktens status
					: "meetings.agenda.status_panel.status_updated"; //Statusen uppdaterad
		}

		return (
			<StatusPanel
				passedSubTopics={passedSubTopics}
				allSubTopics={allSubTopics}
				infoTid={infoTid}
				dropdownComponent={!hasSubTopics ? this.renderComponent() : undefined}
			/>
		);
	}
}

const mapStoreToProps = (store) => {
	return {
		agendaItem: store.meetings.get("agendaItem"),
		isSecretary:
			store.meetings.getIn(["meeting", "secretary"]) ===
			store.user.getIn(["userObj", "id"]),
		meetingIsStarted: store.meetings.getIn([
			"meeting",
			"computedValues",
			"meetingIsStarted",
		]),
		meetingIsFinished: store.meetings.getIn([
			"meeting",
			"computedValues",
			"meetingIsFinished",
		]),
	};
};

const mapActionsToProps = {
	saveAgendaItem,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(StatusPanelContainer);
