import React, { PureComponent } from "react";
import { bool, func } from "prop-types";
import { map, list } from "react-immutable-proptypes";
import styled, { css } from "styled-components";

import Tooltip from "../../shared/tooltip/tooltip";
import List from "../../shared/list/list";
import ListItem from "../../shared/list-item/list-item";
import Text from "../../shared/text/text";
import Select from "../../shared/select/src/select";
import { StyledListHeaderTemplate } from "../../shared/list/list-header.template";
import DropdownMenuContainer from "../../../containers/shared/dropdown-menu.container";
import DropdownIconItem from "../../shared/dropdown-item/dropdown-icon-item";

const StyledList = styled(List)`
	margin-top: ${(props) => props.theme.spacing[4]};
	flex: 0;
	height: 0;
`;

const InnerWrapper = styled.div`
	padding-bottom: ${(props) => props.theme.spacing[5]};
`;

const StyledListItem = styled(ListItem)`
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeDark};
	margin-bottom: 0px;
	min-height: 50px;
`;

const EllipsisTemplate = css`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

/* Table Layout */
const StyledHeader = styled.div`
	${StyledListHeaderTemplate}
	${EllipsisTemplate}
`;

const StyledName = styled.div`
	${EllipsisTemplate}
	margin: 0 ${(props) => props.theme.spacing[3]};
	width: 175px;
`;

const StyledPermission = styled.div`
	margin: 0 ${(props) => props.theme.spacing[3]};
	width: 200px;
`;

const StyledButtonWrapper = styled.div`
	display: flex;
	flex: 1;
	width: 50px;
	justify-content: flex-end;
	margin: 0 ${(props) => props.theme.spacing[3]};
`;

export default class FolderShareList extends PureComponent {
	static propTypes = {
		sharedWithCompanies: map,
		onUnshareCompany: func,
		readOnly: bool,
		permissionsOptions: list,
		onChange: func,
	};

	static defaultProps = {};

	onChange = (companyId, field, val) => {
		const { onChange } = this.props;

		onChange(companyId, field, val);
	};

	renderHeader = () => {
		return (
			<StyledHeader>
				<StyledName>
					<Text tid="generic.name" />
				</StyledName>
				<StyledPermission>
					<Text tid="generic.permission" />
				</StyledPermission>
				<StyledButtonWrapper />
			</StyledHeader>
		);
	};

	renderListItem = (companyDataArray) => {
		const { onUnshareCompany, readOnly, permissionsOptions } = this.props;
		const companyId = companyDataArray[0];
		const companyData = companyDataArray[1];
		const companyName = companyData.get("name");
		const disabled = companyData.get("disabled", false);
		const parentFolderName = companyData.get("parentFolderName");
		const menuPortalTarget = document.body;

		return (
			<StyledListItem key={companyId} striped>
				<StyledName>
					<Text>{companyName}</Text>
				</StyledName>
				<StyledPermission>
					<Tooltip
						active={disabled}
						tid="folders.folder_sharring.folder_permissions_inherited"
						values={{ folderName: parentFolderName }}
						delayShow="instant"
					>
						<Select
							fieldName="sharingPermissions"
							options={permissionsOptions}
							value={companyData.get("sharingPermissions")}
							onChange={this.onChange.bind(this, companyId)}
							isDisabled={readOnly || disabled}
							menuPortalTarget={menuPortalTarget}
							labelIsTid
						/>
					</Tooltip>
				</StyledPermission>
				<StyledButtonWrapper>
					<Tooltip
						active={disabled}
						tid="folders.folder_sharring.cant_remove_company"
						delayShow="instant"
					>
						<DropdownMenuContainer
							btnIcon="faEllipsisV"
							halignMenu="right"
							transparentIconButtonSize="sml"
							btnMode="transparent-icon"
							withPortal
						>
							<DropdownIconItem
								icon="faTrashAlt"
								tid="generic.delete"
								onClick={onUnshareCompany.bind(null, companyId)}
								disabled={readOnly || disabled}
							/>
						</DropdownMenuContainer>
					</Tooltip>
				</StyledButtonWrapper>
			</StyledListItem>
		);
	};

	renderList = () => {
		const { sharedWithCompanies } = this.props;

		if (sharedWithCompanies.size) {
			return (
				<StyledList header={this.renderHeader}>
					<InnerWrapper>
						{sharedWithCompanies.entrySeq().map(this.renderListItem)}
					</InnerWrapper>
				</StyledList>
			);
		}
	};

	render = () => {
		return <>{this.renderList()}</>;
	};
}
