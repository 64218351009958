import React from "react";
import { bool, oneOf, func, string } from "prop-types";
import styled, { css } from "styled-components";
import Icon from "../../icon/icon";
import { StyledButtonTemplate } from "./button.template";

import NotificationBadge from "../../notification-badge/notification-badge";

const StyledTransparentButton = styled(StyledButtonTemplate)`
	border: none;
	background: transparent;
	padding: 0;
	margin-left: ${(props) =>
		props.noHorizontalMargin ? 0 : props.theme.spacing[2]};
	margin-right: ${(props) =>
		props.noHorizontalMargin ? 0 : props.theme.spacing[2]};

	${(props) =>
		props.borderColor &&
		css`
			border: 1px solid ${(props) => props.theme.colors[props.borderColor]};

			${(props) =>
				!props.disabled &&
				css`
					:hover {
						border: 1px solid ${(props) => props.theme.colors[props.hoverColor]};
					}
				`}
		`}

	${(props) =>
		props.autoUnsetWidthHeight &&
		props.size === "sm" &&
		css`
			min-width: unset;
			height: unset;
		`};

	& + button {
		margin-left: 0;
	}

	:active {
		box-shadow: unset;
	}

	/* Applying CSS to all children due to items sometimes being wrapped in*/
	:disabled {
		> span > :hover {
			color: inherit;
		}

		> span:hover {
			* {
				color: inherit;
			}
		}
	}

	> span {
		* {
			transition: all 0.3s;
		}

		/* Fill out span inside button so onhover triggers before coursor appears on the icon
		 * in case it doesn't fill out all the space inside a button
		 */
		height: 100%;
		width: 100%;
	}

	> span:hover {
		* {
			color: ${(props) => props.theme.colors[props.hoverColor]};
		}
	}
`;

const StyledChildrenWrapper = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: ${(props) => (props.isLoading ? "hidden" : "visible")};
	color: ${(props) => props.theme.colors.midGrey};
`;

const StyledSpinnerWrapper = styled.div`
	position: absolute;
	top: 0px;
	left: 0;
	height: 36px;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ButtonTransparentIcon = ({
	disabled,
	isLoading,
	onClick,
	color,
	icon,
	hoverColor,
	size,
	noHorizontalMargin,
	btnRef,
	notificationBadge,
	notificationBadgeColor,
	notificationBadgeAnimate,
	faded,
	type,
	borderColor,
	autoUnsetWidthHeight,
	x,
}) => {
	const buttonProps = {
		disabled: disabled || isLoading,
		onClick,
		ref: btnRef,
		noHorizontalMargin,
		hoverColor,
		size,
		borderColor,
		autoUnsetWidthHeight,
	};

	const iconProps = {
		icon,
		color,
		size,
		faded,
		type,
	};

	const notificationBadgeProps = {
		active: notificationBadge,
		animated: notificationBadgeAnimate,
		color: notificationBadgeColor,
	};

	return (
		<StyledTransparentButton {...buttonProps}>
			<StyledChildrenWrapper isLoading={isLoading} disabled={disabled}>
				<NotificationBadge {...notificationBadgeProps} x={x ? x : 15}>
					<Icon {...iconProps} />
				</NotificationBadge>
			</StyledChildrenWrapper>
			{isLoading && (
				<StyledSpinnerWrapper>
					<Icon icon="faSpinner" type="solid" spin />
				</StyledSpinnerWrapper>
			)}
		</StyledTransparentButton>
	);
};

ButtonTransparentIcon.defaultProps = {
	icon: "faCalendarAlt",
	hoverColor: "java",
	disabled: false,
	isLoading: false,
	size: "normal",
	noHorizontalMargin: false,
	notificationBadge: false,
	notificationBadgeAnimate: true,
	autoUnsetWidthHeight: true,
};

ButtonTransparentIcon.propTypes = {
	disabled: bool,
	icon: string,
	color: string,
	hoverColor: string,
	isLoading: bool,
	onClick: func,
	size: oneOf(["sm", "sml", "normal", "lg", "xl"]),
	noHorizontalMargin: bool,
	btnRef: func,
	notificationBadge: bool,
	notificationBadgeColor: string,
	notificationBadgeAnimate: bool,
	faded: bool,
	type: oneOf(["light", "solid", "brand"]),
	autoUnsetWidthHeight: bool,
};

export default ButtonTransparentIcon;
