import React, { PureComponent } from "react";
import { string, object, oneOfType, bool } from "prop-types";
import styled from "styled-components";

import Panel from "../../shared/panel/panel";
import Text from "../../shared/text/text";
import { Award } from "lucide-react";

const Wrapper = styled.div`
	display: flex;
	padding: ${(props) => props.theme.spacing[4]};
`;

const StyledInfo = styled.div`
	flex: 1;
	margin-right: ${(props) => props.theme.spacing[4]};
`;

const StyledText = styled.div`
	flex: 1;
`;

const StyledButtonWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing[4]};
	text-align: right;
`;

const HeaderComponent = ({ tid }) => (
	<div className="flex items-center gap-2">
		<Award />
		<Text tag="h4" color="catalinaBlue" tid={tid} />
	</div>
);

export default class DashboardUpgradePanel extends PureComponent {
	static propTypes = {
		panelTitle: string,
		text: string,
		upgradeButton: oneOfType([object, bool]),
		logo: string,
		tidLink: object,
	};

	render = () => {
		const { panelTitle, text, upgradeButton, tidLink } = this.props;
		const hasUpgradeButton = Boolean(upgradeButton);

		return (
			<Panel
				className="h-full"
				mode="light"
				leftComponent={() => <HeaderComponent tid={panelTitle} />}
			>
				<Wrapper hasUpgradeButton={hasUpgradeButton}>
					<StyledInfo>
						<StyledText>
							{tidLink ? (
								<Text tid={text} values={{ tidLink: tidLink }} />
							) : (
								<Text tid={text} />
							)}
						</StyledText>
						{upgradeButton && (
							<StyledButtonWrapper>{upgradeButton}</StyledButtonWrapper>
						)}
					</StyledInfo>
				</Wrapper>
			</Panel>
		);
	};
}
