import React from "react";
import styled, { css } from "styled-components";
import { bool, string } from "prop-types";
import Text from "../../../shared/text/text";

const StyledProtocolTitle = styled.div`
	min-height: 45px;
	padding-top: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[4]};
	padding-bottom: ${(props) => props.theme.spacing[3]};
	padding-left: ${(props) => props.theme.spacing[4]};
	display: flex;
	flex-direction: row;
	align-items: center;

	${(props) =>
		!props.noBorderBottom &&
		css`
			border-bottom: 1px solid #ddd;
		`};
`;

const StyledProtocolTitleLeft = styled.div`
	display: flex;
	flex: 1;
`;

const StyledProtocolTitleRight = styled.div``;

const ProtocolTitle = ({ textLeft, textRight, noBorderBottom }) => {
	return (
		<StyledProtocolTitle noBorderBottom={noBorderBottom}>
			<StyledProtocolTitleLeft>
				<Text fontSize="18px" color="lightGrey">
					{textLeft}
				</Text>
			</StyledProtocolTitleLeft>

			<StyledProtocolTitleRight>
				<Text fontSize="18px" color="lightGrey">
					{textRight}
				</Text>
			</StyledProtocolTitleRight>
		</StyledProtocolTitle>
	);
};

ProtocolTitle.propTypes = {
	textLeft: string,
	textRight: string,
	noBorderBottom: bool,
};

ProtocolTitle.defaultProps = {
	noBorderBottom: false,
};

export default ProtocolTitle;
