import React, { PureComponent } from "react";
import { func, bool, string } from "prop-types";
import styled, { css } from "styled-components";
import Grid from "styled-components-grid";
import { Padding } from "styled-components-spacing";

import Input from "../../shared/input/input";
import Panel from "../../shared/panel/panel";
import Label from "../../shared/label/label";

const ExpendableContent = styled.div`
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.3s ease-in;

	${(props) =>
		props.isExpanded &&
		css`
			max-height: 420px;
		`}
`;

export default class WebLocationPanel extends PureComponent {
	static propTypes = {
		appName: string,
		appUrl: string,
		appPassword: string,
		readOnly: bool,
		onChange: func,
		marginBottom: bool,
		withoutPanel: bool,
		isExpanded: bool,
		renderEditor: func,
	};

	static defaultProps = {
		marginBottom: false,
		withoutPanel: false,
	};

	renderContent = () => {
		const {
			onChange,
			readOnly,
			appName,
			appUrl,
			appPassword,
			isExpanded,
			renderEditor,
		} = this.props;

		return (
			<Padding all={3}>
				<Grid>
					<Grid.Unit size={1}>
						<Padding all={3}>
							<Label tid="meetings.location.web.label.app.name">
								<Input
									fieldName="appName"
									disabled={readOnly}
									placeholderTid="meetings.location.web.label.app.name"
									onChange={onChange}
									value={appName ? appName : ""}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
				</Grid>

				<ExpendableContent isExpanded={isExpanded}>
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid="meetings.location.web.label.app.url">
									<Input
										fieldName="appUrl"
										disabled={readOnly}
										placeholderTid="meetings.location.web.label.app.url"
										onChange={onChange}
										value={appUrl ? appUrl : ""}
									/>
								</Label>
							</Padding>
						</Grid.Unit>

						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid="meetings.location.web.label.app.password">
									<Input
										fieldName="appPassword"
										disabled={readOnly}
										placeholderTid="meetings.location.web.label.app.password"
										onChange={onChange}
										value={appPassword ? appPassword : ""}
									/>
								</Label>
							</Padding>
						</Grid.Unit>

						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid="meetings.location.web.label.additional_info">
									{renderEditor && renderEditor()}
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>
				</ExpendableContent>
			</Padding>
		);
	};

	render = () => {
		const { marginBottom, withoutPanel } = this.props;

		if (withoutPanel) {
			return this.renderContent();
		}

		return (
			<Panel title="meetings.location.type.web" marginBottom={marginBottom}>
				{this.renderContent()}
			</Panel>
		);
	};
}
