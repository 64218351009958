import React from "react";
import Box from "../layout/box/box";
import BankID from "./bankid";

const StepsBankID = ({ bankIdInfo, logoSize }) => {
	return (
		<Box valign="center" alignItems="center" fullHeight>
			<BankID
				bankIdInfo={bankIdInfo}
				size={logoSize}
				mode="dark"
				fill={false}
				forceShow
				coloredLogo
				noMarginTop
			/>
		</Box>
	);
};

StepsBankID.propTypes = {
	bankIdInfo: BankID.propTypes.bankIdInfo,
	logoSize: BankID.propTypes.size,
};

StepsBankID.defaultProps = {
	bankIdInfo: BankID.defaultProps.bankIdInfo,
	logoSize: BankID.defaultProps.size,
};

export default StepsBankID;
