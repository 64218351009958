import React from "react";
import NumericInput from "../../../dumb-components/fields/numeric-input";

/**
 * Format value as an NumberFormat input field
 */
export function numberFormatInputFieldFormatter(value, column) {
	const thousandSeparator = column.get("thousandSeparator", " ");
	const decimalSeparator = column.get("decimalSeparator", ",");
	const decimalScale = column.get("decimalScale");
	const readOnly = column.get("readOnly", false);
	const inputClassName = column.get("inputClassName");

	return (
		<NumericInput
			type="text"
			thousandSeparator={thousandSeparator}
			decimalSeparator={decimalSeparator}
			decimalScale={decimalScale}
			value={value}
			disabled={readOnly}
			className={`form-control ${inputClassName}`}
		/>
	);
}
