import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import AutoSave from "../../forms/AutoSave";
import { sendSettlementNotes } from "../../../../actions/emissions.actions";
import { addErrorNotification } from "../../../../actions/notify.actions";
import RichTextEditor from "../../../../dumb-components/shared/editorv2/rich-text-editor";

const SendSettlementNotesModal = (props) => {
	const dispatch = useDispatch();
	const emission = useSelector((state) => state.emissions.current);
	const { t } = useTranslation();
	const onSubmit = (values) => {
		if (typeof props.onSubmit === "function") {
			return props.onSubmit(values);
		}
	};
	const [message, setMessage] = useState("");
	const [subject, setSubject] = useState("");
	const [validSubject, setValidSubject] = useState(false);
	const handleMessageChange = (event) => {
		setMessage(event);
	};
	const handleSubjectChange = (event) => {
		if (subject.length === 0) {
			setValidSubject(false);
		} else {
			setValidSubject(true);
		}
		setSubject(event.target.value);
	};
	const handleSubmit = async () => {
		if (validSubject) {
			await dispatch(
				sendSettlementNotes(
					emission.companyId,
					emission.id,
					emission.closures[emission.closures.length - 1].id,
					subject,
					message,
				),
			);
			props.closeAll();
		} else {
			await dispatch(
				addErrorNotification({
					text: t(
						"emissions.payment.payment-send-settlement-notes-error.notification",
					),
				}),
			);
		}
	};

	return (
		<Dialog scroll="paper" open={props.open} onClose={props.close}>
			<ArrowBackIosRoundedIcon
				sx={{ cursor: "pointer", mt: "16px", ml: "16px" }}
				onClick={props.close}
			/>
			<DialogContent
				sx={{
					px: 6,
					minWidth: "60rem",
					minHeight: "30rem",
					maxHeight: "60rem",
				}}
			>
				<IconButton
					aria-label="close"
					onClick={() => {
						props.closeAll();
						props.close();
					}}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[600],
					}}
				>
					<CloseIcon />
				</IconButton>
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Typography
						component={"span"}
						variant={"body2"}
						sx={{ display: "flex", color: "grey.600", my: 2 }}
					>
						{t("emissions.payment.step2-2")}
					</Typography>
					<Typography sx={{ textAlign: "flex-start", pb: 2 }} variant="h4">
						{t("emissions.payment.send-settlement-note-header")}
					</Typography>
					<Typography sx={{ textAlign: "flex-start", pb: 4 }} variant="body2">
						{t("emissions.payment.send-settlement-note.desc1")}
					</Typography>
					<Typography
						sx={{ textAlign: "flex-start", pb: 4 }}
						variant="subtitle1"
					>
						{t("emissions.payment.message-to-subscriber")}
					</Typography>
					<Form
						onSubmit={onSubmit}
						initialValues={{
							...emission,
						}}
					>
						{({ handleSubmit, valid }) => {
							return (
								<form onSubmit={handleSubmit}>
									<AutoSave
										save={(values) => {
											if (valid && !values.signSubmissionNote) {
												onSubmit(values);
											}
										}}
									/>
									<TextField
										name={"subject"}
										label={t("subject")}
										sx={{ mb: "20px" }}
										value={subject}
										onChange={handleSubjectChange}
									/>
									<RichTextEditor
										label={t("message")}
										value={message}
										onChange={handleMessageChange}
									/>
								</form>
							);
						}}
					</Form>
					<Box sx={{ display: "flex", mt: "24px", justifyContent: "flex-end" }}>
						<Button
							variant={"outlined"}
							sx={{
								fontWeight: "bold",
								alignSelf: "center",
								py: "1rem",
								mr: "8px",
							}}
						>
							<Typography
								variant={"h5"}
								onClick={() => {
									props.closeAll();
									props.close();
								}}
							>
								{t("dialog.generic.cancel")}
							</Typography>
						</Button>
						<Button
							onClick={handleSubmit}
							variant={"contained"}
							sx={{ fontWeight: "bold", alignSelf: "center", ml: "8px" }}
						>
							<Typography variant={"h5"}>
								{t("emissions.payment.send-settlement-notes")}
							</Typography>
						</Button>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default SendSettlementNotesModal;
