import React, { PureComponent } from "react";
import { object, func, string } from "prop-types";

import Modal from "../../shared/modal/modal";
import FooterRightControls from "../../shared/modal/footer-right-controls";
import { TransparentButton } from "../../shared/button-v2";

export default class AttendeeManageFunctionsModal extends PureComponent {
	static propTypes = {
		children: object,
		onClose: func,
		name: string,
	};

	renderFooter = () => (
		<FooterRightControls>
			<TransparentButton
				tid="generic.form.close"
				textColor="midGrey"
				onClick={this.props.onClose}
			/>
		</FooterRightControls>
	);

	render = () => {
		const { children, name } = this.props;

		return (
			<Modal
				title="meetings.attendee.manage.functions.name.title"
				titleValues={{ name }}
				isOpen={true}
				hSize="md"
				vSize={100}
				noBodyMargin={true}
				scrollableContent={false}
				footerComponent={this.renderFooter()}
			>
				{children}
			</Modal>
		);
	};
}
