import { useEffect, useState } from "react";
import useSubscription from "@/hooks/useSubscription";
import useUpdateSubscription from "@/hooks/useUpdateSubscription";
import useSubscriptionRefillPrepaid from "@/hooks/useSubscriptionRefillPrepaid";

export const usePrepaid = () => {
	const [amount, setAmount] = useState("");
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [autoRefill, setAutoRefill] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { data: subscriptionQuery } = useSubscription();
	const subscription = subscriptionQuery?.data;
	const companyId = subscription?.companyId;

	const { mutateAsync: saveSubscription } = useUpdateSubscription(companyId);

	const { mutateAsync: refillPrepaid } =
		useSubscriptionRefillPrepaid(companyId);

	const saveButtonDisabled =
		!amount || isNaN(amount) || termsAccepted === false || isSubmitting;

	const PREPAID_MODAL_TOOLTIP_STATES = {
		amountNotSelected: {
			title: "subscriptions.prepaid_modal.tooltip.amount_not_selected",
			delayShow: "instant",
		},
		termsNotAccepted: {
			title: "subscriptions.prepaid_modal.tooltip.terms_not_accepted",
			delayShow: "instant",
		},
	};

	const activeTooltipState = (() => {
		if (amount === "" || isNaN(amount)) {
			return "amountNotSelected";
		} else if (!termsAccepted) {
			return "termsNotAccepted";
		}
	})();

	const savePrepaidBalance = async () => {
		await saveSubscription({
			prepaidSelectedAmount: amount,
			prepaidAutoRefill: autoRefill,
		});
		await refillPrepaid();
	};

	useEffect(() => {
		if (subscription) {
			setAutoRefill(subscription?.prepaidAutoRefill);
		}
	}, [subscription?.prepaidAutoRefill]);

	useEffect(() => {
		if (amount === "" && subscription) {
			setAmount(subscription?.prepaidSelectedAmount);
		}
	}, [subscription?.prepaidSelectedAmount]);

	return {
		amount,
		setAmount,
		termsAccepted,
		setTermsAccepted,
		autoRefill,
		setAutoRefill,
		isSubmitting,
		setIsSubmitting,
		subscription,
		activeTooltipState,
		saveButtonDisabled,
		savePrepaidBalance,
		tooltipStates: PREPAID_MODAL_TOOLTIP_STATES,
	};
};
