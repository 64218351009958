import React from "react";
import { func, bool } from "prop-types";
import styled from "styled-components";
import { Padding } from "styled-components-spacing";
import Panel from "../../shared/panel/panel";
import DarkInfoPanel from "../../shared/dark-info-panel/dark-info-panel";
import { OutlinedButton } from "../../shared/button-v2";
import Text from "../../shared/text/text";
import { SquareCheckBig } from "lucide-react";

const StyledDarkInfoPanel = styled(DarkInfoPanel)`
	margin: ${(props) => props.theme.spacing[4]};
	margin-bottom: 0;
`;

const TaskPlugin = ({
	renderListContainer,
	renderModalContainer,
	marginBottom,
	legacyPanel,
	onClickAddNewTask,
}) => {
	let rightMargin = 0;

	if (renderListContainer) {
		rightMargin = 4;
	}
	return (
		<Panel
			icon={<SquareCheckBig />}
			marginBottom={marginBottom}
			title="tasks.plugin.title"
			legacyMode={legacyPanel}
		>
			<Padding bottom={4}>
				<StyledDarkInfoPanel
					headerLeftComponent={
						<Text tid="meetings.tasks.panel.info" color="white" />
					}
					headerRightComponent={
						<OutlinedButton
							icon="faPlus"
							onClick={() => {
								onClickAddNewTask && onClickAddNewTask(null, null);
							}}
						/>
					}
				/>
			</Padding>
			<Padding right={rightMargin}>
				{renderListContainer && renderListContainer()}
			</Padding>
			{renderModalContainer && renderModalContainer()}
		</Panel>
	);
};

TaskPlugin.propTypes = {
	renderListContainer: func,
	renderModalContainer: func,
	marginBottom: bool,
	legacyPanel: bool,
	onClickAddNewTask: func,
};

TaskPlugin.defaultProps = {
	marginBottom: false,
};

export default TaskPlugin;
