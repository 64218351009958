import companyHelper from "/shared/helpers/company.helper";
import meetingHelpers from "/shared/meetings/helpers";

export const SYNA_COMPANY_ROLES = companyHelper.companyRoles;

export const MEETING_ROLES_ADMINISTRATIVE = meetingHelpers.administrativeRoles;

export const getMeetingRolesExtendedAsOptions = (withGroup) => {
	const options = [];

	if (withGroup) {
		options.push({
			label: "meeting.attendee.details.functions.group.meeting",
			options: [],
		});
	}

	for (let key in MEETING_ROLES_ADMINISTRATIVE) {
		if (
			Object.prototype.hasOwnProperty.call(MEETING_ROLES_ADMINISTRATIVE, key)
		) {
			options[0].options.push({
				value: key,
				label: MEETING_ROLES_ADMINISTRATIVE[key],
			});
		}
	}
	return options;
};

export const getSynaCompanyRolesAsOptions = () => {
	const options = [];

	for (let key in SYNA_COMPANY_ROLES) {
		if (Object.prototype.hasOwnProperty.call(SYNA_COMPANY_ROLES, key)) {
			options.push({ value: key, label: SYNA_COMPANY_ROLES[key] });
		}
	}

	return options.sort((a, b) => {
		return a.label.localeCompare(b.label);
	});
};
