import { connect } from "react-redux";
import { func } from "prop-types";
import { map } from "react-immutable-proptypes";
import DocumentsHelper from "../../../components/helpers/documents.helper";
import { addDocumentToCollection } from "../../../actions/collections.actions";
import { openModal } from "../../../actions/modals.actions";
import {
	ADD_DOCUMENT_TO_COLLECTION_MODAL,
	ADD_EXISTING_DOCUMENT_TO_COLLECTION_MODAL,
} from "../../../constants/modals";
import useSubscriptionHelper from "../../../hooks/useSubscriptionHelper";

const TOOLTIP_STATES = {
	isSharedFromCompany: {
		tid: "documents.list.dropdown.item.add_to_collection.disabled.shared_from_company",
		delayShow: "instant",
	},
};

const VERSION_NORMAL = "NORMAL";

const AddDocumentToCollectionComponentContainer = ({
	document,
	children,
	documentInRoot,
	openModal,
	addDocumentToCollection,
}) => {
	const { data: subscriptionsHelperQuery } = useSubscriptionHelper();
	const subscriptionsHelper = subscriptionsHelperQuery?.data;
	const collectionsIsEnabled = subscriptionsHelper?.collectionsEnabled;

	const getTooltipData = () => {
		const isSharedFromCompany = Boolean(document.get("isSharedFromCompanyId"));
		let activeState;

		if (isSharedFromCompany) {
			activeState = "isSharedFromCompany";
		}

		return { activeState, isDisabled: Boolean(activeState) };
	};

	const addToCollection = () => {
		const isSigned = DocumentsHelper.getDocumentIsSigned(document);

		if (documentInRoot && isSigned) {
			openModal(ADD_EXISTING_DOCUMENT_TO_COLLECTION_MODAL, {
				documentId: document.get("id"),
				sharedVersion: documentInRoot.get("sharedVersion"),
			});
			return;
		}

		if (isSigned) {
			openModal(ADD_DOCUMENT_TO_COLLECTION_MODAL, {
				documentId: document.get("id"),
			});
			return;
		}

		addDocumentToCollection(document.get("id"), VERSION_NORMAL);
	};

	const isDeleted = document.get("isDeleted");

	if (isDeleted || !collectionsIsEnabled) {
		return null;
	}

	const { activeState, isDisabled } = getTooltipData();

	return children({
		addToCollection,
		TOOLTIP_STATES,
		activeState,
		isDisabled,
	});
};

AddDocumentToCollectionComponentContainer.propTypes = {
	document: map.isRequired,
	children: func.isRequired,
};

const mapStoreToProps = (store, ownProps) => {
	return {
		documentInRoot: store.collections
			.getIn(["collections", 0, "documents"])
			?.find((d) => d.get("id") === ownProps.document.get("id")),
	};
};

const mapActionsToProps = {
	addDocumentToCollection,
	openModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(AddDocumentToCollectionComponentContainer);
