// Authentication
export const AUTH_USER = "AUTH_USER";

// Users Cache
export const USER_CACHE_ADD_USERS_TO_CACHE = "USER_CACHE_ADD_USERS_TO_CACHE";

// Meeting
export const MEETINGS_FETCH_MEETING = "MEETINGS_FETCH_MEETING";
export const MEETINGS_SAVE_ATTENDEE = "MEETINGS_SAVE_ATTENDEE";
export const MEETINGS_SAVE_ATTENDEES = "MEETINGS_SAVE_ATTENDEES";

//Shares
export const INVESTORS_LIST = "INVESTORS_LIST";

// Notify
export const NOTIFY_FILE_ENTERED_GLOBAL = "NOTIFY_FILE_ENTERED_GLOBAL";
export const NOTIFY_RETURN_URL = "NOTIFY_RETURN_URL";
export const NOTIFY_SOCKET_ID = "NOTIFY_SOCKET_ID";

// Documents
export const DOCUMENT_ESIGN_VERIFY_FETCH = "DOCUMENT_ESIGN_VERIFY_FETCH";
export const DOCUMENT_ESIGN_LOADING = "DOCUMENT_ESIGN_LOADING";
export const DOCUMENT_ESIGN_SIGN_FETCH = "DOCUMENT_ESIGN_SIGN_FETCH";
export const DOCUMENT_FETCH = "DOCUMENT_FETCH";
export const DOCUMENTS_FETCH_ATTACHMENTS = "DOCUMENTS_FETCH_ATTACHMENTS";
export const DOCUMENTS_UPDATE_ATTACHMENT = "DOCUMENTS_UPDATE_ATTACHMENT";
export const DOCUMENTS_APPEND_ATTACHMENT = "DOCUMENTS_APPEND_ATTACHMENT";
export const DOCUMENTS_REMOVE_ATTACHMENT = "DOCUMENTS_REMOVE_ATTACHMENT";

// Attachments
export const ATTACHMENTS_FETCH = "ATTACHMENTS_FETCH";
export const ATTACHMENTS_UPDATE = "ATTACHMENTS_UPDATE";
export const ATTACHMENTS_DELETE = "ATTACHMENTS_DELETE";
export const ATTACHMENTS_CREATE = "ATTACHMENTS_CREATE";
export const ATTACHMENTS_SELECT = "ATTACHMENTS_SELECT";
export const ATTACHMENTS_UNSELECT = "ATTACHMENTS_UNSELECT";
export const ATTACHMENTS_GET = "ATTACHMENTS_GET";

// Users
export const USERS_SET_USER_ID = "USERS_SET_USER_ID";

// Voting
export const VOTING_VOTE = "VOTING_VOTE";
export const VOTING_LIST = "VOTING_LIST";
export const VOTING_UPDATE_LOCAL = "VOTING_UPDATE_LOCAL";
export const VOTING_VOTE_USER_UPDATE_LOCAL = "VOTING_VOTE_USER_UPDATE_LOCAL";
export const VOTING_FETCH = "VOTING_FETCH";

// Live updates
export const LIVE_REQUEST = "LIVE_REQUEST";
export const LIVE_REQUEST_RESET = "LIVE_REQUEST_RESET";

// Collections
export const SHARED_COLLECTIONS_FETCH_DOCUMENTS =
	"SHARED_COLLECTIONS_FETCH_DOCUMENTS";
export const SHARED_COLLECTIONS_FETCH_COLLECTION =
	"SHARED_COLLECTIONS_FETCH_COLLECTION";

//Subscription-slip
export const SUBSCRIPTION_SLIP_SET_DISPLAY_DATA =
	"SUBSCRIPTION_SLIP_SET_DISPLAY_DATA";
export const SUBSCRIPTION_SLIP_SET_INSURANCE_OPTIONS =
	"SUBSCRIPTION_SLIP_SET_INSURANCE_OPTIONS";
export const SUBSCRIPTION_SLIP_SET_DEPOSITORY_OPTIONS =
	"SUBSCRIPTION_SLIP_SET_DEPOSITORY_OPTIONS";
export const SUBSCRIPTION_SLIP_SET_SUBMISSION =
	"SUBSCRIPTION_SLIP_SET_SUBMISSION";
export const SUBSCRIPTION_SLIP_LEAVE_LANDINGPAGE =
	"SUBSCRIPTION_SLIP_LEAVE_LANDINGPAGE";
export const SUBSCRIPTION_SLIP_ERROR_LANDINGPAGE =
	"SUBSCRIPTION_SLIP_ERROR_LANDINGPAGE";
