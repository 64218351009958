import { createContext, useContext } from "react";

/*
Context definition:

value = {
	values: map,
	children: node
}
*/

export const CopyContactModalContext = createContext({});

export function useCopyContactModalContext() {
	return useContext(CopyContactModalContext);
}
