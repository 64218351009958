import React, { Component } from "react";
import { connect } from "react-redux";
import { string, oneOf, object, bool } from "prop-types";
import { Map, List } from "immutable";
import { toggleMenu } from "../../../../../components/helpers/refs.helper";
import { openModal } from "../../../../../actions/modals.actions";
import {
	inviteAttendees,
	notifyAttendee,
	remindAttendeesAboutMeeting,
	remindAttendeeAboutMeeting,
} from "../../../../../actions/meetings.actions";

import DropdownIconItem from "../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import ButtonWithIcon from "../../../../../dumb-components/shared/button/src/button-with-icon";
import Tooltip from "../../../../../dumb-components/shared/tooltip/tooltip";

// import AttendeesConfirmInviteModal from '../../../../../dumb-components/meetings/attendees-confirm-invite/attendees-confirm-invite' //TODO REMOVE

import { MEETING_TYPE_STANDARD } from "/shared/constants";
import {
	ATTENDEES_CONFIRM_INVITE_MODAL,
	MEETINGS_SMART_INVITE_NO_TEMPLATE_MODAL,
} from "../../../../../constants/modals";
import TemplateInformationModal from "../../../../../dumb-components/meetings/modals/template-information-modal";

const NOTIFY_TOOLTIP_STATES_ALL = {
	default: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.invite.tooltip",
	},
	update: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.invite.tooltip.update",
	},
	disabled: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.invite.tooltip.disabled",
		delayShow: "instant",
	},
	noAttendeesAdded: {
		tid: "meetings.standard_meeting.attendees_panel.tooltip.invite.no_attendees",
		delayShow: "instant",
	},
	meetingIsDeleted: {
		tid: "meetings.standard_meeting.toolbar.share_protocol.tooltip.meeting_is_deleted",
		delayShow: "instant",
	},
	meetingPassed: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.invite.tooltip.meeting_passed",
		delayShow: "instant",
	},
	remind: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.send_reminder.tooltip",
	},
	inviteNewAttendees: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.invite_new_only.tooltip",
	},
	noModeratorAdded: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.no_moderator.tooltip",
		delayShow: "instant",
	},
};

const NOTIFY_TOOLTIP_STATES_SINGLE = {
	default: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.invite_single.tooltip",
	},
	update: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.invite_single.tooltip.update",
	},
	disabled: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.invite_single.tooltip.disabled",
		delayShow: "instant",
	},
	meetingIsDeleted: {
		tid: "meetings.standard_meeting.toolbar.share_protocol.tooltip.meeting_is_deleted",
		delayShow: "instant",
	},
	meetingPassed: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.invite.tooltip.meeting_passed",
		delayShow: "instant",
	},
	remind: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.send_reminder.tooltip",
	},
	inviteNewAttendees: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.invite_new_only.tooltip",
	},
	noModeratorAdded: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.no_moderator.tooltip",
		delayShow: "instant",
	},
};

class InviteAttendeesContainer extends Component {
	static propTypes = {
		type: oneOf(["dropdown", "button"]),
		dropdownRef: object,
		mode: oneOf(["ALL", "SINGLE"]),
		renderNullIfButtonDisabled: bool,
		userId: string, // only needed if mode === SINGLE,
	};

	static defaultProps = {
		type: "button",
		mode: "ALL",
	};

	getAction = () => {
		const {
			meeting,
			remindAttendeesAboutMeeting,
			remindAttendeeAboutMeeting,
			mode,
			userId,
			inviteAttendees,
			notifyAttendee,
		} = this.props;

		const updateIsNeeded = this.getUpdateIsNeeded();
		const notificationIsNeeded = this.getNotificationIsNeeded();
		const getProxyNotificationNeeded = this.getProxyNotificationNeeded();

		/*Send invite*/
		if (updateIsNeeded || notificationIsNeeded || getProxyNotificationNeeded) {
			if (mode === "ALL") {
				return { func: inviteAttendees, arg: [true] };
			}
			if (mode === "SINGLE") {
				return { func: notifyAttendee, arg: [userId, true] };
			}
		}

		/*Send reminder*/
		const meetingId = meeting.get("id");

		if (mode === "ALL") {
			return { func: remindAttendeesAboutMeeting, arg: [meetingId] };
		}

		if (mode === "SINGLE") {
			return { func: remindAttendeeAboutMeeting, arg: [userId] };
		}
	};
	runAction = () => {
		const { func, arg } = this.getAction();
		func(...arg);
	};

	onClick = () => {
		const {
			meeting,
			dropdownRef,
			isStandardMeeting,
			type,
			hasPhysicalLocation,
			hasPhoneLocation,
			hasWebLocation,
			openModal,
			templateId,
		} = this.props;
		const hasStartDate = meeting.get("startDate");
		const agendaIsUploaded = meeting.getIn([
			"metadata",
			"uploadedDocumentTypes",
			"meetingAgenda",
		]);
		const needsConfirmation =
			hasStartDate &&
			agendaIsUploaded &&
			(hasPhysicalLocation || hasPhoneLocation || hasWebLocation);

		if (type === "dropdown") {
			toggleMenu(dropdownRef);
		}

		let missingFields = List();
		if (!hasStartDate) {
			missingFields = missingFields.push("startDate");
		}
		if (!agendaIsUploaded) {
			missingFields = missingFields.push("agenda");
		}
		if (!hasPhysicalLocation && !hasWebLocation && !hasPhoneLocation) {
			missingFields = missingFields.push("location");
		}

		if (!needsConfirmation && isStandardMeeting) {
			openModal(ATTENDEES_CONFIRM_INVITE_MODAL, {
				missingFields,
				action: this.getAction(),
			});
			return;
		}

		if (!isStandardMeeting && !templateId) {
			openModal(MEETINGS_SMART_INVITE_NO_TEMPLATE_MODAL);
			return;
		}

		// If no confirmation modal is needed, just run the action
		this.runAction();
	};

	getNotificationIsNeeded = () => {
		const { meeting, mode, userId } = this.props;
		const attendees = meeting.get("attendees");

		if (mode === "ALL") {
			return attendees
				? attendees.some((obj) => obj.get("notified") === false)
				: false;
		}

		return attendees && attendees.getIn([userId, "notified"]) === false;
	};

	getUpdateIsNeeded = () => {
		const { meeting, mode, userId } = this.props;
		const attendees = meeting.get("attendees");

		if (mode === "ALL") {
			return attendees
				? attendees.some((obj) => obj.get("notifiedAboutChanges") === false)
				: false;
		}

		return (
			attendees && attendees.getIn([userId, "notifiedAboutChanges"]) === false
		);
	};

	getProxyNotificationNeeded = () => {
		const { meeting, mode, userId } = this.props;
		const attendees = meeting.get("attendees");

		if (mode === "ALL") {
			return attendees
				? attendees.some((obj) => obj.get("notifyProxyInvite") === true)
				: false;
		}

		return attendees && attendees.getIn([userId, "notifyProxyInvite"]) === true;
	};

	getHasAttendeesAdded = () => {
		const { meeting } = this.props;
		const attendees = meeting.get("attendees");
		return attendees && attendees.size > 0 ? true : false;
	};

	getHasInvitedAttendees = () => {
		const { meeting } = this.props;
		let attendees = meeting.get("attendees");
		attendees =
			attendees &&
			attendees.filter((attendee) => {
				return !attendee.get("isProxy");
			});
		return attendees
			? attendees.some((obj) => obj.get("notified") === true)
			: false;
	};

	getTooltipStates = () => {
		const { mode } = this.props;

		if (mode === "ALL") {
			return NOTIFY_TOOLTIP_STATES_ALL;
		}

		if (mode === "SINGLE") {
			return NOTIFY_TOOLTIP_STATES_SINGLE;
		}
	};

	isDisabled = () => {
		const { meetingIsDeleted, hasMeetingPassed, type } = this.props;
		const hasAttendeesAdded = this.getHasAttendeesAdded();
		const notificationIsNeeded = this.getNotificationIsNeeded();
		const updateIsNeeded = this.getUpdateIsNeeded();
		const proxyNotificationNeeded = this.getProxyNotificationNeeded();
		const allNotifiedInButtonMode =
			type === "button" &&
			!notificationIsNeeded &&
			!updateIsNeeded &&
			!proxyNotificationNeeded;
		return (
			meetingIsDeleted ||
			hasMeetingPassed ||
			!hasAttendeesAdded ||
			allNotifiedInButtonMode
		);
	};

	getTextTid = () => {
		const { mode } = this.props;
		const updateIsNeeded = this.getUpdateIsNeeded();
		const notificationIsNeeded = this.getNotificationIsNeeded();
		const hasInvitedAttendees = this.getHasInvitedAttendees();
		const proxyNotificationNeeded = this.getProxyNotificationNeeded();

		if (mode === "SINGLE" && proxyNotificationNeeded) {
			return "meetings.standard_meeting.attendees_panel.proxyInvite_single";
		}

		if (
			notificationIsNeeded &&
			((!hasInvitedAttendees && mode === "ALL") || mode === "SINGLE")
		) {
			return "meetings.standard_meeting.attendees_panel.toolbar.invite";
		}

		if (
			notificationIsNeeded &&
			hasInvitedAttendees &&
			mode === "ALL" &&
			!updateIsNeeded
		) {
			return "meetings.standard_meeting.attendees_panel.toolbar.invite_new_only";
		}

		if (updateIsNeeded) {
			return mode === "ALL"
				? "meetings.standard_meeting.attendees_panel.toolbar.update"
				: "meetings.standard_meeting.attendees_panel.toolbar.update_single";
		}

		if (proxyNotificationNeeded && hasInvitedAttendees) {
			return mode === "ALL"
				? "meetings.standard_meeting.attendees_panel.proxyInvite_multiple"
				: "meetings.standard_meeting.attendees_panel.proxyInvite_single";
		}

		return hasInvitedAttendees
			? "meetings.standard_meeting.attendees_panel.toolbar.send_reminder"
			: "meetings.standard_meeting.attendees_panel.toolbar.invite";
	};

	wrapWithTooltip = (children) => {
		const {
			meetingIsDeleted,
			mode,
			hasMeetingPassed,
			isSimpleMode,
			isStandardMeeting,
		} = this.props;
		const notificationIsNeeded = this.getNotificationIsNeeded();
		const updateIsNeeded = this.getUpdateIsNeeded();
		const noAttendeesAdded = !this.getHasAttendeesAdded();
		const isDisabled = this.isDisabled();
		const hasInvitedAttendees = this.getHasInvitedAttendees();

		let activeState;
		if (isSimpleMode) {
			activeState = "hasNoPermissions";
		} else if (meetingIsDeleted) {
			activeState = "meetingIsDeleted";
		} else if (hasMeetingPassed) {
			activeState = "meetingPassed";
		} else if (!this.isStandardAndHasModerator() && isStandardMeeting) {
			activeState = "noModeratorAdded";
		} else if (notificationIsNeeded && !hasInvitedAttendees) {
			activeState = "default";
		} else if (notificationIsNeeded && hasInvitedAttendees && !updateIsNeeded) {
			activeState = mode === "ALL" ? "inviteNewAttendees" : "default";
		} else if (updateIsNeeded) {
			activeState = "update";
		} else if (noAttendeesAdded) {
			activeState = "noAttendeesAdded";
		} else if (isDisabled) {
			activeState = "disabled";
		} else {
			activeState = "remind";
		}

		return (
			<Tooltip states={this.getTooltipStates()} activeState={activeState}>
				{children}
			</Tooltip>
		);
	};

	isStandardAndHasModerator = () => {
		const { isStandardMeeting, meetingHasModerator } = this.props;

		if (isStandardMeeting && meetingHasModerator) {
			return true;
		}

		return false;
	};

	renderDropdown = () => {
		const { isSimpleMode, isStandardMeeting } = this.props;
		const isDisabled = this.isDisabled();

		return this.wrapWithTooltip(
			<DropdownIconItem
				icon="faPaperPlane"
				tid={this.getTextTid()}
				onClick={this.onClick}
				disabled={
					isSimpleMode ||
					isDisabled ||
					(!this.isStandardAndHasModerator() && isStandardMeeting)
				}
			/>,
		);
	};

	renderButton = () => {
		const {
			renderNullIfButtonDisabled,
			isSimpleMode,
			isStandardMeeting,
			meetingIsStarted,
			meetingHasProtocol,
		} = this.props;
		const isDisabled = this.isDisabled();
		const standardStartedAndProtocolUploaded =
			isStandardMeeting && meetingIsStarted && meetingHasProtocol;
		const disabledBtn =
			isSimpleMode ||
			isDisabled ||
			(!this.isStandardAndHasModerator() && isStandardMeeting);

		if (
			(renderNullIfButtonDisabled && isDisabled) ||
			standardStartedAndProtocolUploaded
		) {
			return null;
		}

		if (disabledBtn) {
			return null;
		}

		return this.wrapWithTooltip(
			<ButtonWithIcon
				mode="primary"
				color="white"
				iconColor="white"
				icon="faPaperPlane"
				tid={this.getTextTid()}
				onClick={this.onClick}
			/>,
		);
	};

	render = () => {
		const { type, isInviteNoTemplateModalOpen } = this.props;

		return (
			<>
				{isInviteNoTemplateModalOpen && (
					<TemplateInformationModal
						primaryTid={
							"meetings.smart.invitation.template_information_modal.body"
						}
						onConfirm={() => {
							this.runAction();
						}}
						secondaryFooterBtn
					/>
				)}
				{type === "dropdown" && this.renderDropdown()}
				{type === "button" && this.renderButton()}
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	const smartMeeetingIsFinished = store.meetings.getIn([
		"meeting",
		"computedValues",
		"meetingIsFinished",
	]);
	const standardMeetingIsFinished = store.meetings.getIn([
		"meeting",
		"computedValues",
		"hasMeetingPassed",
	]);

	return {
		meeting: store.meetings.get("meeting", Map()) || Map(),
		meetingIsDeleted: store.meetings.getIn(["meeting", "isDeleted"]),
		hasMeetingPassed: standardMeetingIsFinished || smartMeeetingIsFinished,
		isStandardMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) ===
			MEETING_TYPE_STANDARD,
		hasPhysicalLocation: store.meetings.getIn([
			"meeting",
			"computedValues",
			"hasPhysicalLocation",
		]),
		hasPhoneLocation: store.meetings.getIn([
			"meeting",
			"computedValues",
			"hasPhoneLocation",
		]),
		hasWebLocation: store.meetings.getIn([
			"meeting",
			"computedValues",
			"hasWebLocation",
		]),
		isSimpleMode: store.meetings.getIn(
			["meeting", "computedValues", "isSimpleMode"],
			false,
		),
		meetingHasModerator: store.meetings.getIn(
			["meeting", "computedValues", "hasModerator"],
			false,
		),
		meetingHasProtocol: store.meetings.getIn(["meeting", "protocolData"])
			? true
			: false,
		meetingIsStarted: store.meetings.getIn(
			["meeting", "computedValues", "meetingIsStarted"],
			false,
		),
		templateId: store.meetings.getIn(["meeting", "templateId"]),
		isInviteNoTemplateModalOpen:
			store.modals.getIn(["activeModal", "name"]) ===
			MEETINGS_SMART_INVITE_NO_TEMPLATE_MODAL,
	};
};

const mapActionsToProps = {
	inviteAttendees,
	notifyAttendee,
	openModal,
	remindAttendeesAboutMeeting,
	remindAttendeeAboutMeeting,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(InviteAttendeesContainer);
