import { fromJS, Map, List, isImmutable, merge } from "immutable";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import req from "../modules/request.module";
import {
	MEETINGS_LIST,
	MEETINGS_SET_VISIBLE,
	MEETINGS_UPDATE_LOCAL,
	MEETINGS_CREATE,
	MEETINGS_CREATE_LOCAL,
	MEETINGS_FETCH,
	MEETINGS_SAVE,
	MEETINGS_DELETE,
	MEETINGS_LIST_BY_COMPANIES,
	MEETINGS_SET_FILTERS,
	MEETINGS_UNSET_FILTERS,
	MEETINGS_AGENDA_ITEM_FETCH,
	MEETINGS_AGENDA_ITEM_UPDATE,
	MEETINGS_LIST_PREVIOUS_MEETINGS,
	MEETINGS_FETCH_PREVIOUS_MEETING,
	MEETINGS_SET_PROTOCOL_FILTERS,
	MEETINGS_SET_DEFAULT_FILTERS,
	MEETINGS_UPDATE_DEFAULT_FILTER,
	MEETINGS_RESET_DEFAULT_VALUES,
	MEETINGS_HARD_DELETE,
	MEETINGS_UPDATE_PUBLISHING_TYPE,
	DOCUMENTS_FETCH_REMOTE,
	MEETINGS_ATTENDEES_HAS_BEEN_CHANGED,
} from "./types";
import { addErrorNotification, addInfoNotification } from "./notify.actions";
import uuid from "uuid/v1";
import {
	createAttendeeObject,
	normalizeAttendeeObject,
	normalizeFeedbackeeObject,
} from "../components/helpers/meeting.helper.pure";
import {
	MEETINGS_STATUS_STARTED,
	MEETINGS_STATUS_FINISHED,
} from "../constants/meetings";
import {
	LIVE_MEETINGS_CREATE,
	LIVE_MEETINGS_UPDATE,
	LIVE_MEETINGS_DELETE,
	LIVE_MEETINGS_AGENDA_CREATE,
	LIVE_MEETINGS_AGENDA_UPDATE,
	LIVE_MEETINGS_AGENDA_DELETE,
	LIVE_MEETINGS_ATTENDEE_ADD,
	LIVE_MEETINGS_ATTENDEE_REMOVE,
	LIVE_MEETINGS_OPEN,
	LIVE_MEETINGS_CLOSE,
	LIVE_MEETINGS_FEEDBACKEE_DONE,
	LIVE_MEETINGS_SIGNEE_DONE,
	LIVE_MEETINGS_PROTOCOL_PUBLISHED,
	LIVE_MEETINGS_AGENDA_SOFT_DELETE,
} from "../constants/live-update";
import {
	EVENT_TYPE_ATTENDEE_VERIFIED,
	EVENT_TYPE_MEETINGS_UPDATE,
	EVENT_TYPE_ATTENDEE_RESPONDED_INVITE,
	__DELETE__,
} from "/shared/constants";
import { setLiveRequest, resetLiveRequest } from "./live-update.actions";

export function setFilteredMeetings(meetings, hasAppliedFilters) {
	return {
		type: MEETINGS_SET_VISIBLE,
		payload: { meetings, hasAppliedFilters },
	};
}

/**
 * Action for fetching a meeting
 */
export function fetchMeeting(id, nextMeetingId, callback) {
	return function (dispatch) {
		return req
			.get(
				`/meetings/meetings/${id}${
					nextMeetingId ? `?nextMeeting=${nextMeetingId}` : ""
				}`,
			)
			.then((response) => {
				dispatch({ type: MEETINGS_FETCH, payload: fromJS(response.data) });
				callback && callback(fromJS(response.data));
			})
			.catch((err) => {
				!err?.__CANCEL__ &&
					dispatch(meetingsError("meetings.error.load_meeting"));
			});
	};
}

// Action for fetching multiple meetings
export function fetchMultipleMeetings(meetingIds, callback) {
	return function (dispatch) {
		return req
			.post("/meetings/$/multiple", { meetingIds })
			.then((response) => {
				callback && callback(fromJS(response.data));
			})
			.catch((e) => {
				console.log(e);
				dispatch(meetingsError("meetings.error.load_meetings"));
			});
	};
}

// Action for deleting a proxy
export function deleteProxy(meetingId, proxyInviterId, callback) {
	return function (dispatch) {
		return req
			.delete(`/meetings/${meetingId}/proxy/${proxyInviterId}`)
			.then((response) => {
				dispatch({ type: MEETINGS_SAVE, payload: fromJS(response.data) });
				callback && callback(fromJS(response.data));
			})
			.catch((e) => {
				console.log(e);
				dispatch(meetingsError("meetings.error.save_meeting"));
			});
	};
}

// Action for creating a proxy
export function createProxy(
	meetingId,
	proxyInviterId,
	selectedUsers,
	callback,
) {
	const selectedUser = selectedUsers.first();
	const isGuest = selectedUser.get("isGuest");
	let proxyData = Map();

	//TVÅ FALL: EXTERNAL, VI behöver skicka med data för att skapa upp en ny proxy
	//ATTENDEE: Vi Behöver skicka med ID för att länka till rätt proxy
	if (isGuest) {
		proxyData = proxyData.set("email", selectedUser.get("email"));
		proxyData = proxyData.set("name", selectedUser.get("name"));
	} else {
		proxyData = proxyData.set("proxyForAttendeeId", selectedUser.get("userId"));
		const proxyForAttendeeType = selectedUser.get("investmentId")
			? "Investor"
			: "User";
		proxyData = proxyData.set("proxyForAttendeeType", proxyForAttendeeType);
	}

	proxyData = proxyData.set("skipEmail", true);
	proxyData = proxyData.set("proxyAddedByModerator", true);

	return function (dispatch) {
		return req
			.post(`/meetings/${meetingId}/proxy/${proxyInviterId}`, proxyData.toJS())
			.then((response) => {
				dispatch({ type: MEETINGS_SAVE, payload: fromJS(response.data) });
				callback && callback(fromJS(response.data));
			})
			.catch((e) => {
				console.log(e);
				dispatch(meetingsError("meetings.error.save_meeting"));
			});
	};
}

export function updateMeetingLocal(meeting, callback) {
	return function (dispatch) {
		if (meeting) {
			dispatch({ type: MEETINGS_UPDATE_LOCAL, payload: meeting });
		}
		callback && callback();
	};
}

/*
 * Helper function for saveMeeting. This is the debounce function, it cannot be declared within saveMeeting
 */
const _saveRemotely = debounce((dispatch, meeting, callback, errorCallback) => {
	return req
		.put(
			`/meetings/meetings/${meeting.get("id")}`,
			{ meeting: meeting.toJS() },
			{ onlyLatest: true },
		)
		.then((response) => {
			const updatedMeeting = fromJS(response.data);
			const computedValuesChanged = !isEqual(
				meeting.get("computedValues"),
				updatedMeeting.get("computedValues"),
			);
			const protocolDataChanged = !isEqual(
				meeting.get("protocolData"),
				updatedMeeting.get("protocolData"),
			);
			const templateLockedChanged =
				meeting.get("templateLocked") !== updatedMeeting.get("templateLocked");

			if (
				computedValuesChanged ||
				protocolDataChanged ||
				templateLockedChanged
			) {
				dispatch({ type: MEETINGS_SAVE, payload: updatedMeeting });
			}

			callback && callback();
		})
		.catch((e) => {
			if (!e || !e.message || !e.message.includes("onlyLatest:true")) {
				errorCallback && errorCallback(e);
				dispatch(meetingsError("meetings.error.save_meeting"));
			}
		});
}, 1000);

/**
 * Action for saving a meeting
 */
export function saveMeeting(meeting, callback) {
	return function (dispatch) {
		dispatch(updateMeetingLocal(meeting));
		_saveRemotely(dispatch, meeting, callback);
	};
}

export function patchMeeting(meetingId, meetingData) {
	return function (dispatch) {
		if (meetingData.size === 0) {
			return;
		}

		dispatch(patchMeetingLocal(meetingData));
		const meetingDataJS = meetingData?.toJS();

		return req
			.patch(
				`/meetings/meetings/${meetingId}`,
				{ meetingData: meetingDataJS },
				{ onlyLatest: true },
			)
			.then((response) => {
				const meeting = fromJS(response.data);
				dispatch({ type: MEETINGS_SAVE, payload: meeting });
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes("onlyLatest:true")) {
					dispatch(meetingsError("meetings.error.save_meeting"));
				}
			});
	};
}

export function patchMeetingLocal(meetingData) {
	return function (dispatch, getState) {
		let meeting = getState().meetings.get("meeting");

		// Build keyPath for use later when we update the meeting object
		// A keyPath is a path to a property seperated by dot, ie attendees.2A9EA0C2-9334-415D-9785-13B90F51E5A3.status or agendaItems.0.agendaItems.1.presenter
		const keyPathBuilder = (obj) => {
			const keyPath = {};
			const recurse = (obj, current) => {
				for (const key in obj) {
					const value = obj[key];
					const newKey = current ? `${current}.${key}` : key;

					if (
						value &&
						typeof value === "object" &&
						Object.keys(value).length > 0
					) {
						recurse(value, newKey);
					} else {
						keyPath[newKey] = value;
					}
				}
			};

			recurse(obj);
			return fromJS(keyPath);
		};

		// 1. Build keyPath
		const keyPath = keyPathBuilder(meetingData.toJS());

		// 2. Update meeting object
		keyPath.forEach((val, key) => {
			const path = key.split(".");

			if (val === __DELETE__ || val === undefined) {
				meeting = meeting.removeIn(path);
			} else {
				meeting = meeting.setIn(path, val);
			}
		});

		// 3. Update store
		dispatch({ type: MEETINGS_UPDATE_LOCAL, payload: meeting });
	};
}

// Unpublish published protocol
// Can send in meeting or meetingId directly
export function unpublishProtocol(meeting, callback) {
	const meetingId = Map.isMap(meeting) ? meeting.get("id") : meeting;

	return function (dispatch) {
		return req
			.post(`/meetings/meetings/${meetingId}/unpublish`, { onlyLatest: true })
			.then((response) => {
				const responseData = fromJS(response.data);
				const meeting = responseData.get("meeting");
				const document = responseData.get("document");

				if (meeting) {
					dispatch({ type: MEETINGS_SAVE, payload: meeting });
				}

				if (document) {
					dispatch({ type: DOCUMENTS_FETCH_REMOTE, payload: document });
				}

				callback && callback();
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes("onlyLatest:true")) {
					dispatch(meetingsError("meetings.error.save_meeting"));
				}
			});
	};
}

// Archive Meeting / Protocol
export function toggleArchiveMeeting(meeting, callback) {
	return function (dispatch) {
		return req
			.post(`/meetings/meetings/${meeting.get("id")}/archive/toggle`, {
				onlyLatest: true,
			})
			.then((response) => {
				dispatch({ type: MEETINGS_SAVE, payload: fromJS(response.data) });
				callback && callback();
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes("onlyLatest:true")) {
					dispatch(meetingsError("meetings.error.save_meeting"));
				}
			});
	};
}

export function createMeetingLocal(meeting, callback) {
	return function (dispatch) {
		// create the meeting id local for speedup
		meeting = meeting.set("id", uuid());

		dispatch({ type: MEETINGS_CREATE_LOCAL, payload: Map({ meeting }) });
		callback && callback(meeting);
	};
}

/**
 * Action for creating a meeting
 */
export function createMeeting(meeting, callback) {
	return function (dispatch) {
		return req
			.post(`/meetings/meetings/`, { meeting: meeting.toJS() })
			.then((response) => {
				meeting = fromJS(response.data);
				dispatch({ type: MEETINGS_CREATE, payload: fromJS(meeting) });
				callback && callback(meeting);
			})
			.catch((e) => {
				console.log(e);
				dispatch(meetingsError("meetings.error.create_meeting"));
			});
	};
}

/**
 * Action for fetching a list of meetings
 */
export function listMeetings(selectedGroupId, callback) {
	return function (dispatch) {
		return req
			.get(
				`/meetings/meetings/${
					selectedGroupId ? `?group=${selectedGroupId}` : ""
				}`,
			)
			.then((response) => {
				dispatch({ type: MEETINGS_LIST, payload: fromJS(response.data) });
				callback && callback();
			})
			.catch((err) => {
				!err?.__CANCEL__ &&
					dispatch(meetingsError("meetings.error.load_meetings"));
			});
	};
}

/**
 * Action for fetching all meetings in all the companies the user is a member of
 */
export function listAllMeetingsByCompanies() {
	return function (dispatch) {
		return req
			.get(`/meetings/meetings/companies`)
			.then((response) => {
				dispatch({
					type: MEETINGS_LIST_BY_COMPANIES,
					payload: fromJS(response.data),
				});
			})
			.catch(() => {
				dispatch(meetingsError("meetings.error.load_meetings"));
			});
	};
}

/**
 * Action for clearing meetings list
 */
export function clearMeetingsList() {
	return function (dispatch) {
		dispatch({ type: MEETINGS_LIST, payload: null });
	};
}

/**
 * Action for clearing selected meeting
 */
export function clearMeeting() {
	return function (dispatch) {
		dispatch({ type: MEETINGS_FETCH, payload: null });
	};
}

/**
 * Action for deleting a meeting
 * @param {String} id — meeting id
 */
export function deleteMeeting(id, meetingType, callback) {
	return function (dispatch) {
		return req
			.delete(`/meetings/meetings/${id}`)
			.then(() => {
				dispatch({ type: MEETINGS_DELETE, payload: { id, meetingType } });
				dispatch(
					addInfoNotification({
						tid: "meetings.notifications.info.meeting_deleted",
					}),
				);
				callback && callback();
			})
			.catch(() => {
				dispatch(meetingsError("meetings.error.delete_meeting"));
			});
	};
}

// Transfer meeting to another group
export function transferMeeting(meetingIds, groupId, currentGroupId, callback) {
	return function () {
		const parts = { meetingIds, groupId };
		return req.post(`/meetings/meetings/transfer`, parts).then(() => {
			callback && callback();
		});
	};
}

export function setFilterBy(val) {
	return {
		type: MEETINGS_SET_FILTERS,
		payload: val,
	};
}

export function unsetFilterBy(sources) {
	return {
		type: MEETINGS_UNSET_FILTERS,
		payload: sources,
	};
}

export function updateDefaultFilter(source, values) {
	return {
		type: MEETINGS_UPDATE_DEFAULT_FILTER,
		payload: Map({ source, values }),
	};
}

export function resetDefaultFiltervalues() {
	return {
		type: MEETINGS_RESET_DEFAULT_VALUES,
	};
}

export function meetingsSetDefaultFilterBy(defaultFilters) {
	return function (dispatch) {
		let filters = Map();
		defaultFilters &&
			defaultFilters.forEach((val, key) => {
				const source = key.split("$")[1];
				dispatch(setFilterBy(fromJS({ source, values: [val] })));
				filters = filters.set(source, List([val]));
			});
		dispatch({
			type: MEETINGS_SET_DEFAULT_FILTERS,
			payload: filters,
		});
	};
}

/**
 * Action for dispatching an meeting error
 * @param {String} error — error message
 */
function meetingsError(error) {
	return addErrorNotification({
		tid: error,
	});
}

/**
 * Action for creating an agenda item
 */
export function createAgendaItem(
	parentIndex,
	insertAtIndex,
	agendaItem,
	callback,
) {
	return function (dispatch, getState) {
		let agendaItems = getState().meetings.getIn(
			["meeting", "agendaItems"],
			List(),
		);
		let parentItem;
		agendaItem = agendaItem.set("id", uuid());
		agendaItem = agendaItem.set("progress", "todo");
		agendaItem = agendaItem.set("outcome", "todo");

		if (parentIndex >= 0) {
			parentItem = agendaItems.get(parentIndex);
			let itemsInParentItem = parentItem.get("agendaItems", List());
			itemsInParentItem = itemsInParentItem.insert(insertAtIndex, agendaItem);
			parentItem = parentItem.set("agendaItems", itemsInParentItem);
			agendaItems = agendaItems.set(parentIndex, parentItem);
		} else {
			agendaItems = agendaItems.insert(insertAtIndex, agendaItem);
		}

		let meeting = getState().meetings.get("meeting");
		meeting = meeting.set("agendaItems", agendaItems);
		dispatch(saveMeeting(meeting));

		callback && callback(agendaItem, agendaItems);
	};
}

/**
 * Action for fetching an item from the agenda
 */
export function fetchAgendaItem(id) {
	return {
		type: MEETINGS_AGENDA_ITEM_FETCH,
		payload: id,
	};
}

export function updateAgendaItemLocal(agendaItem, callback) {
	return function (dispatch, getState) {
		const agendaItemId = agendaItem.get("id");
		let agendaItems = getState().meetings.getIn(
			["meeting", "agendaItems"],
			List(),
		);

		agendaItems = agendaItems.map((item) => {
			if (item.get("id") === agendaItemId) {
				return agendaItem;
			} else if (item.get("agendaItems")) {
				const subitems = item.get("agendaItems", List()).map((subitem) => {
					if (subitem.get("id") === agendaItemId) {
						return agendaItem;
					}

					return subitem;
				});

				item = item.set("agendaItems", subitems);
				return item;
			}

			return item;
		});

		const payload = Map({ agendaItem, agendaItems });
		dispatch({ type: MEETINGS_AGENDA_ITEM_UPDATE, payload });
		callback && callback(payload);
	};
}

export function saveAgendaItem(agendaItem, callback) {
	return function (dispatch, getState) {
		dispatch(
			updateAgendaItemLocal(agendaItem, (payload) => {
				const agendaItems = payload.get("agendaItems");
				const newOutcome = agendaItem.get("outcome");
				let meeting = getState().meetings.get("meeting");

				meeting = meeting.set("agendaItems", agendaItems);

				if (
					newOutcome === "open" &&
					meeting.get("status") !== MEETINGS_STATUS_STARTED
				) {
					meeting = meeting.set("status", MEETINGS_STATUS_STARTED);
				}

				if (
					newOutcome === "closed" &&
					meeting.get("status") !== MEETINGS_STATUS_FINISHED
				) {
					meeting = meeting.set("status", MEETINGS_STATUS_FINISHED);
				}

				callback && callback();
				dispatch(saveMeeting(meeting));
			}),
		);
	};
}

/**
 * Action for reordering agenda items
 */
export function reorderAgendaItem(itemId, sourceIndex, destinationIndex) {
	return function (dispatch, getState) {
		let meeting = getState().meetings.get("meeting");
		let oldItem;
		let parentIndex = -1;
		let agendaItems = meeting.get("agendaItems", List());
		let currentItemAtDestinationIndex;

		// Find the item that is moved
		agendaItems.forEach((item, index) => {
			if (item.get("id") === itemId) {
				oldItem = item;
				currentItemAtDestinationIndex = agendaItems.get(destinationIndex);
			} else if (item.has("agendaItems")) {
				item.get("agendaItems", List()).forEach((subitem) => {
					if (subitem.get("id") === itemId) {
						oldItem = subitem;
						parentIndex = index;
					}
				});
			}
		});

		// Convert item to postItem if needed
		if (currentItemAtDestinationIndex) {
			if (currentItemAtDestinationIndex.get("postItem")) {
				oldItem = oldItem.set("postItem", true);
			} else {
				oldItem = oldItem.remove("postItem");
			}
		}

		// Convert suggested item to ordinary item
		oldItem = oldItem.remove("isSuggested");

		// Add item as a subitem
		if (parentIndex >= 0) {
			let items = agendaItems.getIn([parentIndex, "agendaItems"]);
			items = items.delete(sourceIndex).insert(destinationIndex, oldItem);
			agendaItems = agendaItems.setIn([parentIndex, "agendaItems"], items);
		} else {
			agendaItems = agendaItems
				.delete(sourceIndex)
				.insert(destinationIndex, oldItem);
		}

		meeting = meeting.set("agendaItems", agendaItems);

		// Remove next line. updating the local store is now handled within the saveMeeting function
		// dispatch(updateMeetingLocal(meeting));
		dispatch(saveMeeting(meeting));
	};
}

/**
 * Convert a first level item to a second level item
 */
export function convertAgendaItemToSubitem(itemId) {
	return function (dispatch, getState) {
		let meeting = getState().meetings.get("meeting");
		let agendaItems = meeting.get("agendaItems", List());
		let newParentIndex;
		let itemIndex;
		let oldItem;

		agendaItems.forEach((item, index) => {
			if (item.get("id") === itemId) {
				itemIndex = index;
				newParentIndex = index - 1;
				oldItem = item;
				return;
			}
		});

		let newParentItem = agendaItems.get(newParentIndex);
		const canConvert =
			!oldItem.get("internalType") && !newParentItem.get("internalType");

		if (oldItem && canConvert) {
			// Get items from the old item so that we can concat them with the new sub item
			const oldItemItems = oldItem.get("agendaItems", List());
			oldItem = oldItem.delete("agendaItems");

			// Unset itemType if it was category
			if (oldItem.get("itemType") === "category") {
				oldItem = oldItem.set("itemType", oldItem.get("oldItemType"));
				oldItem = oldItem.delete("oldItemType");
			}

			// Change item type ofthe parent item to category
			if (newParentItem.get("itemType") !== "category") {
				newParentItem = newParentItem.set(
					"oldItemType",
					newParentItem.get("itemType"),
				);
				newParentItem = newParentItem.set("itemType", "category");
			}

			agendaItems = agendaItems.set(newParentIndex, newParentItem);

			// Push the new item to it parents sub items
			let items = agendaItems.getIn([newParentIndex, "agendaItems"], List());
			items = items.push(oldItem);
			items = items.concat(oldItemItems);

			agendaItems = agendaItems
				.delete(itemIndex)
				.setIn([newParentIndex, "agendaItems"], items);
			meeting = meeting.set("agendaItems", agendaItems);

			// Remove next line. updating the local store is now handled within the saveMeeting function
			// dispatch(updateMeetingLocal(meeting));
			dispatch(saveMeeting(meeting));
		}
	};
}

/**
 * Convert a sub item to first level item
 */
export function convertAgendaItemToSuperItem(parentIndex, itemId) {
	return function (dispatch, getState) {
		let meeting = getState().meetings.get("meeting");
		let agendaItems = meeting.get("agendaItems", List());
		let itemIndex;
		let oldItem;

		agendaItems.getIn([parentIndex, "agendaItems"]).forEach((item, index) => {
			if (item.get("id") === itemId) {
				oldItem = item;
				itemIndex = index;
				return;
			}
		});

		// Delete sub item and insert the item at fist level
		let parentItem = agendaItems.get(parentIndex);
		parentItem = parentItem.deleteIn(["agendaItems", itemIndex]);

		if (parentItem.get("agendaItems").size === 0) {
			parentItem = parentItem.set(
				"itemType",
				parentItem.get("oldItemType", null),
			);
			parentItem = parentItem.delete("agendaItems");
		}

		agendaItems = agendaItems
			.set(parentIndex, parentItem)
			.insert(parentIndex + 1, oldItem);

		meeting = meeting.set("agendaItems", agendaItems);
		dispatch(saveMeeting(meeting));
	};
}

/**
 * Convert an item to previous meeting item
 */
export function convertAgendaItemToPreviousMeetingItem(itemId, callback) {
	return function (dispatch, getState) {
		let meeting = getState().meetings.get("meeting");
		let agendaItems = meeting.get("agendaItems", List());
		let agendaItem;

		agendaItems = agendaItems.map((item) => {
			if (item.get("id") === itemId) {
				item = item.set("internalType", "previousMeeting");
				item = item.set("internalData", Map());
				item = item.remove("postItem");
				item = item.set("proposal", "Föregående möte");
				agendaItem = item;
			}

			return item;
		});

		meeting = meeting.set("agendaItems", agendaItems);
		// Remove next line. updating the local store is now handled within the saveMeeting function
		// dispatch(updateMeetingLocal(meeting));
		dispatch(saveMeeting(meeting));
		callback && callback(agendaItem);
	};
}

/**
 * Convert an item to schedule next meeting item
 */
export function convertAgendaItemToScheduleNextMeetingItem(itemId, callback) {
	return function (dispatch, getState) {
		let meeting = getState().meetings.get("meeting");
		let agendaItems = meeting.get("agendaItems", List());
		let agendaItem;

		agendaItems = agendaItems.map((item) => {
			if (item.get("id") === itemId) {
				item = item.set("internalType", "nextMeeting");
				item = item.set("internalData", Map());
				item = item.set("postItem", true);
				item = item.set("proposal", "Boka nästa möte");
				agendaItem = item;
			}

			return item;
		});

		meeting = meeting.set("agendaItems", agendaItems);
		// Remove next line. updating the local store is now handled within the saveMeeting function
		// dispatch(updateMeetingLocal(meeting));
		dispatch(saveMeeting(meeting));
		callback && callback(agendaItem);
	};
}

/**
 * Action for deleting an agenda item local
 */
export function deleteAgendaItemLocal(itemId) {
	return function (dispatch, getState) {
		let meeting = getState().meetings.get("meeting");
		let agendaItems = meeting.get("agendaItems", List());
		let parentIndex = -1,
			itemIndex;

		agendaItems.forEach((item, index) => {
			if (item.get("id") === itemId) {
				itemIndex = index;
			} else if (item.get("agendaItems")) {
				item.get("agendaItems", List()).forEach((subitem, subindex) => {
					if (subitem.get("id") === itemId) {
						itemIndex = subindex;
						parentIndex = index;
					}
				});
			}
		});

		if (parentIndex >= 0) {
			let parentItem = agendaItems.get(parentIndex);
			parentItem = parentItem.deleteIn(["agendaItems", itemIndex]);

			if (parentItem.get("agendaItems").size === 0) {
				parentItem = parentItem.set(
					"itemType",
					parentItem.get("oldItemType", null),
				);
				parentItem = parentItem.delete("agendaItems");
			}

			agendaItems = agendaItems.set(parentIndex, parentItem);
		} else {
			agendaItems = agendaItems.delete(itemIndex);
		}

		meeting = meeting.set("agendaItems", agendaItems);
		dispatch(updateMeetingLocal(meeting));
	};
}

/**
 * Action for deleting agena item
 */
export function deleteAgendaItem(meetingId, itemId, callback) {
	return function (dispatch) {
		return req
			.delete(`/meetings/meetings/${meetingId}/agenda/${itemId}`)
			.then((response) => {
				dispatch({ type: MEETINGS_FETCH, payload: fromJS(response.data) });
				callback && callback();
			})
			.catch(() => {
				dispatch(meetingsError("meetings.error.delete_agenda_item"));
			});
	};
}

/**
 * Action for adding an attendee
 */
export function addAttendee(userId, isGuest = false) {
	return function (dispatch, getState) {
		const company = getState().company.company;
		let meeting = getState().meetings.get("meeting");
		let attendees = meeting.get("attendees", Map());
		let attendee = createAttendeeObject(userId, isGuest);

		if (
			company &&
			company.representatives &&
			Array.isArray(company.representatives)
		) {
			const representative = company.representatives.find(
				(obj) => obj.id === userId,
			);

			if (representative) {
				attendee = attendee.set("roles", fromJS(representative.roles));
			}
		}

		attendees = attendees.set(userId, attendee);

		// Improves Responsiveness
		meeting = meeting.setIn(["computedValues", "attendeesWarning"], true);
		meeting = meeting.set("attendees", attendees);
		dispatch(saveMeeting(meeting));
	};
}

export function addMultipleAttendees(attendees) {
	return function (dispatch, getState) {
		const company = getState().company.company;
		const meeting = getState().meetings.get("meeting");

		const currentAttendees = meeting.get("attendees");
		if (currentAttendees) {
			// Set deleted attendees to undefined so they can be removed from store
			currentAttendees.forEach((currentAttendee, currentAttendeeId) => {
				if (!attendees.has(currentAttendeeId)) {
					attendees = attendees.set(currentAttendeeId, __DELETE__);
				}
			});

			// Filter out current attendees so we only update store with new ones
			attendees = attendees.filter((attendee) => {
				if (attendee === __DELETE__) {
					return true;
				}

				if (
					attendee.has("userId") &&
					currentAttendees.has(attendee.get("userId"))
				) {
					return false;
				}

				return true;
			});
		}

		let newAttendees = attendees?.filter((attendee) => attendee !== __DELETE__);
		const attendeesToDelete = attendees?.filter(
			(attendee) => attendee === __DELETE__,
		);

		if (newAttendees) {
			// Put all guests in its own list
			let guests = newAttendees.filter(
				(attendee) => attendee.get("isGuest") === true,
			);

			// Put all users in its own list
			const users = newAttendees.filter(
				(attendee) =>
					!attendee.has("isGuest") || attendee.get("isGuest") === false,
			);

			// Remove the empty external object. It is only there because it is needed in the modal to create an empty row in the external invites of the select user modal.
			guests = guests.filter(
				(guest) => guest.get("name") && guest.get("email"),
			);

			newAttendees = users.concat(guests);

			newAttendees = newAttendees.map((attendee, userId) => {
				return normalizeAttendeeObject(userId, attendee, company);
			});
		}
		attendees = merge(attendeesToDelete, newAttendees);

		if (attendees.size > 0) {
			let meetingData = fromJS({ attendees });

			if (meeting.getIn(["metadata", "rollcallDone"])) {
				meetingData = meetingData.setIn(
					["metadata", "rollcallDone"],
					__DELETE__,
				);
			}

			dispatch(patchMeeting(meeting.get("id"), meetingData));
		}
	};
}

/**
 * Add multiple feedbackees
 */
export function addMultipleFeedbackees(feedbackees) {
	return function (dispatch, getState) {
		const meeting = getState().meetings.get("meeting");

		const currentFeedbackees = meeting.get("feedbackees");
		if (currentFeedbackees) {
			// Set deleted feedbackees to undefined so they can be removed from store
			currentFeedbackees.forEach((currentFeedbackee, currentFeedbackeeId) => {
				if (!feedbackees.has(currentFeedbackeeId)) {
					feedbackees = feedbackees.set(currentFeedbackeeId, __DELETE__);
				}
			});

			// Filter out current feedbackees so we only update store with new ones
			feedbackees = feedbackees.filter((feedbackee) => {
				if (feedbackee === __DELETE__) {
					return true;
				}

				if (
					feedbackee.has("userId") &&
					currentFeedbackees.has(feedbackee.get("userId"))
				) {
					return false;
				}

				return true;
			});
		}

		let newFeedbackees = feedbackees?.filter(
			(feedbackee) => feedbackee !== __DELETE__,
		);
		const feedbackeesToDelete = feedbackees?.filter(
			(feedbackee) => feedbackee === __DELETE__,
		);

		if (newFeedbackees) {
			// Put all guests in its own list
			let guests = newFeedbackees.filter(
				(feedbackee) => feedbackee.get("isGuest") === true,
			);

			// Put all users in its own list
			const users = newFeedbackees.filter(
				(feedbackee) =>
					!feedbackee.has("isGuest") || feedbackee.get("isGuest") === false,
			);

			// Remove the empty external object. It is only there because it is needed in the modal to create an empty row in the external invites of the select user modal.
			guests = guests.filter(
				(guest) => guest.get("name") && guest.get("email"),
			);

			newFeedbackees = users.concat(guests);

			newFeedbackees = newFeedbackees.map((feedbackee, userId) => {
				return normalizeFeedbackeeObject(userId, feedbackee);
			});
		}
		feedbackees = merge(feedbackeesToDelete, newFeedbackees);

		if (feedbackees.size > 0) {
			dispatch(patchMeeting(meeting.get("id"), fromJS({ feedbackees })));
		}
	};
}

/**
 * Action for fetching an attendee
 */
export function fetchAttendee(userId, callback) {
	return function (dispatch, getState) {
		const meeting = getState().meetings.get("meeting");
		const attendee = meeting.getIn(["attendees", userId]);

		callback && callback(attendee);
	};
}

/**
 * Action for saving an attendee
 */
export function saveAttendee(userId, attendee) {
	return function (dispatch, getState) {
		const meeting = getState().meetings.get("meeting");

		const dataToUpdate = Map({
			attendees: {
				[userId]: attendee,
			},
		});

		dispatch(patchMeeting(meeting.get("id"), dataToUpdate));
	};
}

/**
 * Action for removing an attendee
 */
export function deleteAttendee(userId) {
	return function (dispatch, getState) {
		let dataToUpdate = Map();
		let meeting = getState().meetings.get("meeting");

		dataToUpdate = dataToUpdate.setIn(["attendees", userId], __DELETE__);

		if (meeting.get("chairman") === userId) {
			dataToUpdate = dataToUpdate.set("chairman", "");
		}

		if (meeting.get("secretary") === userId) {
			dataToUpdate = dataToUpdate.set("secretary", "");
		}

		// Remove attendee from any assigned agendaitems
		if (meeting.get("agendaItems")) {
			meeting = meeting.update("agendaItems", (agendaItems) => {
				return agendaItems.map((item, index) => {
					// Parent / Current
					if (item.get("presenter") === userId) {
						dataToUpdate = dataToUpdate.setIn(
							["agendaItems", index, "presenter"],
							__DELETE__,
						);
					}

					// Child
					if (item.get("agendaItems")) {
						item = item.update("agendaItems", (subAgendaItem) => {
							return subAgendaItem.map((subItem, subItemIndex) => {
								if (subItem.get("presenter") === userId) {
									dataToUpdate = dataToUpdate.setIn(
										[
											"agendaItems",
											index,
											"agendaItems",
											subItemIndex,
											"presenter",
										],
										__DELETE__,
									);
								}

								return subItem;
							});
						});
					}

					return item;
				});
			});
		}

		dispatch(patchMeeting(meeting.get("id"), dataToUpdate));
	};
}

export function inviteAttendees(withAgenda, notes) {
	return function (dispatch, getState) {
		const meetingId = getState().meetings.getIn(["meeting", "id"]);

		return req
			.post(`/meetings/meeting/${meetingId}/invite?withAgenda=${withAgenda}`, {
				notes,
			})
			.then((response) => {
				dispatch(updateMeetingLocal(fromJS(response.data)));
				dispatch(
					addInfoNotification({
						tid: "meetings.attendees.notify.attendees_notified",
					}),
				);
			});
	};
}

export function notifyAttendee(attendeeId, withAgenda) {
	return function (dispatch, getState) {
		const meetingId = getState().meetings.getIn(["meeting", "id"]);

		return req
			.post(
				`/meetings/meeting/${meetingId}/invite?attendeeId=${attendeeId}&withAgenda=${withAgenda}`,
			)
			.then((response) => {
				dispatch(updateMeetingLocal(fromJS(response.data)));

				dispatch(
					addInfoNotification({
						tid: "meetings.attendees.notify.attendee_notified",
					}),
				);
			});
	};
}

export function sendMeetingConfirmation(attendeeId) {
	return function (dispatch, getState) {
		const meetingId = getState().meetings.getIn(["meeting", "id"]);

		return req
			.post(`/meetings/meeting/${meetingId}/send-confirmation/${attendeeId}`)
			.then((response) => {
				dispatch(updateMeetingLocal(fromJS(response.data)));

				dispatch(
					addInfoNotification({
						tid: "meetings.attendees.notify.attendee_notified",
					}),
				);
			})
			.catch((e) => {
				console.log("e", e);
			});
	};
}

/**
 * Remind all attendees that have not responded to
 * the meeting invitation
 */
export function remindAttendeesAboutMeeting(meetingId) {
	return function (dispatch) {
		return req
			.post(`/meetings/meeting/${meetingId}/remind`)
			.then(() => {
				dispatch(
					addInfoNotification({
						tid: "meetings.notification.attendees_reminded.message",
					}),
				);
			})
			.catch(() => {
				dispatch(meetingsError("meetings.error.remind_attendee"));
			});
	};
}

/**
 * Action for remind attendee about meeting
 */
export function remindAttendeeAboutMeeting(attendeeId) {
	return function (dispatch, getState) {
		const meetingId = getState().meetings.getIn(["meeting", "id"]);

		return req
			.post(`/meetings/meeting/${meetingId}/remind?attendeeId=${attendeeId}`)
			.then(() => {
				dispatch(
					addInfoNotification({
						tid: "meetings.notification.attendee_reminded.message",
					}),
				);
			})
			.catch(() => {
				dispatch(meetingsError("meetings.error.remind_attendee"));
			});
	};
}

/**
 * Action for sending notification to feedbackees
 */
export function notifyFeedbackees(meetingId, callback) {
	return function (dispatch) {
		return req
			.post(`/meetings/meeting/${meetingId}/requestfeedback`)
			.then((response) => {
				callback && callback({ error: false });
				dispatch(updateMeetingLocal(fromJS(response.data)));
			})
			.catch((e) => {
				callback && callback({ error: true });
				console.log(e);
			});
	};
}

/**
 * Action for sending notification to feedbackees
 */
export function remindNotifyFeedbackee(meetingId, feedbackeeId) {
	return function (dispatch) {
		return req
			.post(`/meetings/meeting/${meetingId}/remindfeedback/${feedbackeeId}`)
			.then((response) => {
				dispatch(updateMeetingLocal(fromJS(response.data)));
			});
	};
}

/**
 * Action for seting meeting moderator
 */
export function setMeetingModerator(meetingId, moderator, successCallback) {
	return function (dispatch, getState) {
		let meeting = getState().meetings.get("meeting");
		meeting = meeting.update("moderators", (moderators) =>
			moderators.push(moderator),
		);
		dispatch(updateMeetingLocal(fromJS(meeting)));
		return req
			.put(`/meetings/meeting/${meetingId}/moderator`, { moderator })
			.then((response) => {
				// we dispatch updateMeetingLocal to update computedValues with new data
				dispatch(updateMeetingLocal(fromJS(response.data)));
				successCallback && successCallback(moderator);
			});
	};
}

/**
 * Action for seting meeting moderator
 */
export function unsetMeetingModerator(meetingId, moderator, successCallback) {
	return function (dispatch, getState) {
		let meeting = getState().meetings.get("meeting");
		meeting = meeting.update("moderators", (moderators) =>
			moderators.filter((id) => id !== moderator),
		);
		dispatch(updateMeetingLocal(fromJS(meeting)));
		return req
			.put(`/meetings/meeting/${meetingId}/moderator`, {
				moderator,
				isRemoving: true,
			})
			.then((response) => {
				// we dispatch updateMeetingLocal to update computedValues with new data
				dispatch(updateMeetingLocal(fromJS(response.data)));
				successCallback && successCallback(moderator);
			});
	};
}

export function signProtocol(meetingId, ssn, bankidCallback, callback) {
	return function (dispatch) {
		const verify = (orderRef) => {
			return function (dispatch) {
				req
					.post(`/meetings/meeting/${meetingId}/signprotocol/complete`, {
						orderRef,
					})
					.then((response) => {
						const { meeting } = response.data;
						dispatch({ type: MEETINGS_SAVE, payload: fromJS(meeting) });
						callback && callback();
					})
					.catch((e) => {
						callback && callback(e);
						console.log(e);
					});
			};
		};

		const collect = (orderRef) => {
			return function (dispatch) {
				req
					.post("/users/public/bankid/collect", { orderRef })
					.then((response) => {
						const { status } = response.data;

						if (status === "pending") {
							setTimeout(() => {
								bankidCallback(response.data);
								dispatch(collect(orderRef));
							}, 500);
						} else if (status === "complete") {
							dispatch(verify(orderRef));
						} else {
							callback && callback(new Error("Failed to collect bankid"));
						}
					})
					.catch((e) => {
						callback && callback(e);
						console.log(e);
					});
			};
		};

		const data = { ssn };
		return req
			.post(`/meetings/meeting/${meetingId}/signprotocol/init`, data)
			.then((response) => {
				const { orderRef } = response.data;

				if (orderRef !== "bypass") {
					dispatch(collect(orderRef));
				} else {
					dispatch(verify(orderRef));
				}
			})
			.catch((e) => {
				callback && callback(e);
				throw e;
			});
	};
}

/**
 * Action for marking leaving feedback as done
 */
export function markFeedbackAsDone(meetingId) {
	return function (dispatch) {
		return req
			.put(`/meetings/meeting/${meetingId}/finishfeedback`)
			.then((response) => {
				dispatch({ type: MEETINGS_SAVE, payload: fromJS(response.data) });
			});
	};
}

export function changePublishingType(meetingId, publishingType, callback) {
	return function (dispatch) {
		dispatch({
			type: MEETINGS_UPDATE_PUBLISHING_TYPE,
			payload: publishingType,
		});

		return req
			.put(`/meetings/meeting/${meetingId}/publishingType/${publishingType}`)
			.then((response) => {
				dispatch({ type: MEETINGS_SAVE, payload: fromJS(response.data) });
				callback && callback();
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function publishProtocolWithoutEsign({
	meetingId,
	notifyAttendees,
	notifyInvestors,
	callback,
}) {
	return function (dispatch) {
		return req
			.post(`/meetings/meetings/${meetingId}/publish`, {
				notifyAttendees,
				notifyInvestors,
			})
			.then((response) => {
				dispatch({ type: MEETINGS_SAVE, payload: fromJS(response.data) });
				callback && callback({ data: response.data });
			})
			.catch((e) => {
				callback && callback({ error: e });
				console.log(e);
			});
	};
}

/**
 * Action for creating a pdf out of protocol
 */
export function createProtocolPdf(meetingId) {
	return function () {
		const win = window.open(
			`https://${window.location.hostname}/assets/build/misc/generating-pdf.html`,
			"_blank",
		);
		req.get(`/meetings/meeting/${meetingId}/pdf`).then((response) => {
			win.location = `/api/pdf/minutes/${response.data.pdfFilename}`;
		});
	};
}

export function setProtocolFilter(filter, active) {
	return {
		type: MEETINGS_SET_PROTOCOL_FILTERS,
		payload: Map({ filter, active }),
	};
}

// ================ PREVIOUS MEETING ================

/**
 * Action for fetching a list of PREVIOUS meetings
 */
export function listPreviousMeetings(selectedGroupId, callback) {
	return function (dispatch) {
		return req
			.get(
				`/meetings/meetings/${
					selectedGroupId ? `?group=${selectedGroupId}` : ""
				}`,
			)
			.then((response) => {
				let previousMeetings = fromJS(response.data);

				if (previousMeetings && previousMeetings.size > 0) {
					previousMeetings = previousMeetings.filter(
						(meeting) => !meeting.get("isDeleted"),
					);
				}

				dispatch({
					type: MEETINGS_LIST_PREVIOUS_MEETINGS,
					payload: previousMeetings,
				});
				callback && callback();
			})
			.catch(() => {
				dispatch(meetingsError("meetings.error.load_previous_meetings"));
			});
	};
}

/**
 * Action for fetching a single PREVIOUS meeting
 */
export function fetchPreviousMeeting(meetingId, prevMeetingId, callback) {
	return function (dispatch) {
		return req
			.get(
				`/meetings/meetings/${meetingId}/previousmeeting/${prevMeetingId}?softError=true`,
			)
			.then((response) => {
				callback && callback(fromJS(response.data));
			})
			.catch((e) => {
				console.error(e);
				dispatch(meetingsError("meetings.error.load_previous_meeting"));
			});
	};
}

/**
 * Action for clearing selected previous meeting
 */
export function clearPreviousMeeting() {
	return function (dispatch) {
		dispatch({ type: MEETINGS_FETCH_PREVIOUS_MEETING, payload: null });
	};
}

export function savePreviousMeeting(
	meetingId,
	previousMeetingId,
	previousMeeting,
	callback,
) {
	return function (dispatch) {
		return req
			.put(
				`/meetings/meetings/${meetingId}/previousmeeting/${previousMeetingId}?softError=true`,
				{
					previousMeeting,
				},
			)
			.then((response) => {
				callback && callback(fromJS(response.data));
			})
			.catch(() => {
				dispatch(meetingsError("meetings.error.save_previous_meeting"));
			});
	};
}

/**
 * Action for saving a meeting
 */
export function recoverMeeting(meetingId, callback) {
	return function (dispatch) {
		return req
			.put(`/meetings/meetings/${meetingId}/recover`)
			.then((response) => {
				dispatch({ type: MEETINGS_SAVE, payload: fromJS(response.data) });
				dispatch(
					addInfoNotification({
						tid: "meetings.notifications.info.meeting_recovered",
					}),
				);
				callback && callback();
			})
			.catch((err) => {
				if (err) {
					dispatch(meetingsError("meetings.error.recover_meeting"));
				}
			});
	};
}

/**
 * Action for delete pending meetings perminantly
 */
export function deleteMeetingsPerminantly(currentSelectedMeetingId, callback) {
	return function (dispatch) {
		return req
			.delete(`/meetings/meetings/hard-delete`)
			.then((response) => {
				const deletedMeetings = fromJS(response.data);

				if (!deletedMeetings) {
					return;
				}

				const selectedMeetingWasDeleted = deletedMeetings.some(
					(meetingId) => meetingId === currentSelectedMeetingId,
				);

				dispatch({
					type: MEETINGS_HARD_DELETE,
					payload: Map({
						selectedMeetingWasDeleted,
						meetingsToDelete: deletedMeetings,
					}),
				});
				dispatch(
					addInfoNotification({
						tid: "meetings.notifications.info.meeting_deleted_perminantly",
					}),
				);
				callback && callback(selectedMeetingWasDeleted);
			})
			.catch((e) => {
				console.log("Error in hard-delete", e);
				dispatch(meetingsError("meetings.error.perminantly_delete_meetings"));
			});
	};
}

function setAttendeeVerified(meetingId, attendees) {
	return function (dispatch, getState) {
		const meeting = getState().meetings.get("meeting");

		if (meeting.get("id") === meetingId) {
			if (!isImmutable(attendees)) {
				attendees = fromJS(attendees);
			}

			dispatch(patchMeetingLocal(attendees));
		}
	};
}

export function countMeetings(callback) {
	return function (dispatch) {
		return req
			.get(`/meetings/meetings/count`)
			.then((response) => {
				callback(fromJS(response.data));
			})
			.catch(() => {
				dispatch(meetingsError("meetings.error.perminantly_delete_meetings"));
			});
	};
}

export function attendeesChanged(attendees, prevAttendees, meetingId) {
	return function (dispatch) {
		const anyAttendeesToUpdate =
			(attendees && attendees.size > 0) ||
			(prevAttendees && prevAttendees.size > 0);
		const attendeesHasChanged = !isEqual(
			attendees?.toJS(),
			prevAttendees?.toJS(),
		);
		const shouldTriggerUpdate = anyAttendeesToUpdate && attendeesHasChanged;
		if (shouldTriggerUpdate) {
			dispatch({
				type: MEETINGS_ATTENDEES_HAS_BEEN_CHANGED,
				payload: { meetingId, changed: true },
			});
			setTimeout(() => {
				dispatch({
					type: MEETINGS_ATTENDEES_HAS_BEEN_CHANGED,
					payload: { meetingId, changed: false },
				});
			});
		}
	};
}

// ================ LIVE UPDATING ================

export function meetingPushLiveUpdate(meetingId) {
	return function () {
		return req.post(`/meetings/meeting/${meetingId}/live-update/push`);
	};
}

export function socketEventMeetings(eventObj) {
	const { eventName, objId, metadata, data } = eventObj;

	return function (dispatch, getState) {
		switch (eventName) {
			case LIVE_MEETINGS_CREATE:
			case LIVE_MEETINGS_UPDATE:
			case LIVE_MEETINGS_DELETE:
			case LIVE_MEETINGS_AGENDA_CREATE:
			case LIVE_MEETINGS_AGENDA_UPDATE:
			case LIVE_MEETINGS_AGENDA_DELETE:
			case LIVE_MEETINGS_ATTENDEE_ADD:
			case LIVE_MEETINGS_ATTENDEE_REMOVE:
			case LIVE_MEETINGS_OPEN:
			case LIVE_MEETINGS_CLOSE:
			case LIVE_MEETINGS_FEEDBACKEE_DONE:
			case LIVE_MEETINGS_SIGNEE_DONE:
			case LIVE_MEETINGS_PROTOCOL_PUBLISHED:
			case EVENT_TYPE_MEETINGS_UPDATE:
				dispatch(
					setLiveRequest(["meetings", eventName], {
						refresh: true,
						objId,
						metadata,
					}),
				);
				dispatch(resetLiveRequest(["meetings", eventName]));
				break;
			case LIVE_MEETINGS_AGENDA_SOFT_DELETE:
				dispatch(
					addInfoNotification({ tid: "meetings.agenda.soft_deleted.message" }),
				);
				break;
			case EVENT_TYPE_ATTENDEE_RESPONDED_INVITE: {
				const meeting = getState().meetings.get("meeting");

				if (meeting.get("id") === objId) {
					// todo create updateAttendeesLocal and dispatch it
					dispatch(patchMeetingLocal(fromJS(data)));
				}
				break;
			}
			case EVENT_TYPE_ATTENDEE_VERIFIED:
				dispatch(setAttendeeVerified(objId, fromJS(data)));
				break;
		}
	};
}
