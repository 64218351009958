import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import useUpdateStripeTaxId from "@/hooks/useUpdateStripeTaxId";
import useStripeTaxId from "@/hooks/useStripeTaxId";

import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import useCompanyFromUrl from "@/hooks/useCompanyFromUrl";
import LoadingPanel from "../loadingPanel";

// Source: https://stripe.com/docs/invoicing/customer/tax-ids
const taxIdsByCountryCode = {
	AU: ["au_abn"],
	AT: ["eu_vat"],
	BE: ["eu_vat"],
	BR: ["br_cnpj", "br_cpf"],
	BG: ["eu_vat"],
	CA: ["ca_bn", "ca_qst"],
	CL: ["cl_tin"],
	HR: ["eu_vat"],
	CY: ["eu_vat"],
	CS: ["eu_vat"],
	DK: ["eu_vat"],
	EE: ["eu_vat"],
	FI: ["eu_vat"],
	FR: ["eu_vat"],
	DE: ["eu_vat"],
	GR: ["eu_vat"],
	HK: ["hk_br"],
	HU: ["eu_vat"],
	IN: ["in_gst"],
	ID: ["id_npwp"],
	IE: ["eu_vat"],
	IT: ["eu_vat"],
	JP: ["jp_cn", "jp_rn"],
	KR: ["kr_brn"],
	LV: ["eu_vat"],
	LI: ["li_uid"],
	LT: ["eu_vat"],
	LU: ["eu_vat"],
	MY: ["my_frp", "my_itn", "my_sst"],
	MT: ["eu_vat"],
	MX: ["mx_rfc"],
	NL: ["eu_vat"],
	NZ: ["nz_gst"],
	NO: ["no_vat"],
	PL: ["eu_vat"],
	PT: ["eu_vat"],
	RO: ["eu_vat"],
	RU: ["ru_inn", "ru_kpp"],
	SA: ["sa_vat"],
	SG: ["sg_gst", "sg_uen"],
	SK: ["eu_vat"],
	SI: ["eu_vat"],
	ZA: ["za_vat"],
	ES: ["es_cif", "eu_vat"],
	SE: ["eu_vat"],
	CH: ["ch_vat"],
	TW: ["tw_vat"],
	TH: ["th_vat"],
	AE: ["ae_trn"],
	GB: ["gb_vat", "eu_vat"],
	US: ["us_ein"],
};

// Source: https://stripe.com/docs/invoicing/customer/tax-ids
const exampleTaxIds = {
	ad_nrt: "A-123456-Z",
	ar_cuit: "12-3456789-01",
	au_abn: "12345678912",
	au_arn: "123456789123",
	eu_vat: "SE123456789123",
	bh_vat: "123456789012345",
	bo_tin: "123456789",
	br_cnpj: "01.234.456/5432-10",
	br_cpf: "123.456.789-87",
	bg_uic: "123456789",
	ca_bn: "123456789",
	ca_gst_hst: "123456789RT0002",
	ca_pst_bc: "PST-1234-5678",
	ca_pst_mb: "123456-7",
	ca_pst_sk: "1234567",
	ca_qst: "1234567890TQ1234",
	cl_tin: "12.345.678-K",
	cn_tin: "123456789012345678",
	co_nit: "123.456.789-0",
	cr_tin: "1-234-567890",
	do_rcn: "123-4567890-1",
	ec_ruc: "1234567890001",
	eg_tin: "123456789",
	sv_nit: "1234-567890-123-4",
	eu_oss_vat: "EU123456789",
	ge_vat: "123456789",
	de_stn: "1234567890",
	hk_br: "12345678",
	hu_tin: "12345678-1-23",
	is_vat: "123456",
	in_gst: "12ABCDE3456FGZH",
	id_npwp: "12.345.678.9-012.345",
	il_vat: "000012345",
	jp_cn: "1234567891234",
	jp_rn: "12345",
	jp_trn: "T1234567891234",
	kz_bin: "123456789012",
	ke_pin: "P000111111A",
	li_uid: "CHE123456789",
	my_frp: "12345678",
	my_itn: "C 1234567890",
	my_sst: "A12-3456-78912345",
	mx_rfc: "ABC010203AB9",
	nz_gst: "123456789",
	ng_tin: "12345678-0001",
	no_vat: "123456789MVA",
	no_voec: "1234567",
	om_vat: "OM1234567890",
	pe_ruc: "12345678901",
	ph_tin: "123456789012",
	ro_tin: "1234567890123",
	ru_inn: "1234567891",
	ru_kpp: "123456789",
	sa_vat: "123456789012345",
	rs_pib: "123456789",
	sg_gst: "M12345678X",
	sg_uen: "123456789F",
	si_tin: "12345678",
	za_vat: "4123456789",
	kr_brn: "123-45-67890",
	es_cif: "A12345678",
	ch_vat: "CHE-123.456.789 MWST",
	tw_vat: "12345678",
	th_vat: "1234567891234",
	tr_tin: "0123456789",
	ua_vat: "123456789",
	ae_trn: "123456789012345",
	gb_vat: "GB123456789",
	us_ein: "12-3456789",
	uy_ruc: "123456789012",
	ve_rif: "A-12345678-9",
	vn_tin: "1234567890",
};

const TaxDetails = () => {
	const { t } = useTranslation();
	const [taxIdType, setTaxIdType] = useState();
	const [taxIdNumber, setTaxIdNumber] = useState();
	const { data: companyRequest, isLoading: isCompanyLoading } =
		useCompanyFromUrl();

	const company = companyRequest?.data;
	const companyId = company?.id;

	useEffect(() => {
		setTaxIdType("");
		setTaxIdNumber("");
	}, [companyId]);

	const { data: taxIdRequest, isLoading: isLoadingTaxId } = useStripeTaxId();

	const { mutate: updateStripeTaxId, isPending: isUpdatingCustomerPending } =
		useUpdateStripeTaxId(companyId);

	const currentTaxIdNumber = taxIdRequest?.data?.value;
	const currentTaxIdType = taxIdRequest?.data?.type;
	const companyCountryCode = company?.country;
	const taxIdOptions = taxIdsByCountryCode[companyCountryCode] ?? [];

	useEffect(() => {
		if (currentTaxIdType) setTaxIdType(currentTaxIdType);
		else if (companyCountryCode) setTaxIdType(taxIdOptions[0]);
	}, [companyCountryCode, currentTaxIdType]);

	useEffect(() => {
		if (currentTaxIdNumber) setTaxIdNumber(currentTaxIdNumber);
	}, [currentTaxIdNumber]);

	if (isCompanyLoading || isLoadingTaxId) return <LoadingPanel />;

	return (
		<div>
			<label>{t("subscriptions.vat_number.description")}</label>
			<div className="flex gap-4">
				{taxIdOptions?.length > 1 ? (
					<Select value={taxIdType} onValueChange={setTaxIdType}>
						<SelectTrigger className="flex-1">
							<SelectValue />
						</SelectTrigger>
						<SelectContent>
							{taxIdOptions.map((taxIdType) => (
								<SelectItem key={taxIdType} value={taxIdType}>
									{t(`tax_id.${taxIdType}`)}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				) : (
					<Input className="flex-1" value={t(`tax_id.${taxIdType}`)} readOnly />
				)}
				<Input
					className="flex-1"
					placeholder={t("subscriptions.vat_number.placeholder", {
						exampleVatNumber: exampleTaxIds[taxIdType],
					})}
					type="text"
					value={taxIdNumber}
					onChange={(evt) => setTaxIdNumber(evt.target.value)}
				/>
				<Button
					variant="secondary"
					isLoading={isUpdatingCustomerPending}
					onClick={() =>
						updateStripeTaxId({ type: taxIdType, value: taxIdNumber })
					}
				>
					{t("generic.form.save")}
				</Button>
			</div>
		</div>
	);
};

export default TaxDetails;
