import React, { PureComponent } from "react";
import styled, { css } from "styled-components";
import { func } from "prop-types";
import Moment from "../../../modules/moment.module";
import Text from "../../shared/text/text";
import i18nhelper from "../../../components/helpers/i18n.helper";
import ListItem from "../../shared/list-item/list-item";
import Loader from "../../shared/loader/loader";
import BadgeOld from "../../shared/badge/badge";
import FileIcon from "../../shared/file-icon/file-icon";
import ToggleSwitch from "../../shared/toggle-switch/toggle-switch";
import documentsHelper from "../../../components/helpers/documents.helper";

const StyledListItem = styled(ListItem)`
	margin-bottom: ${(props) => props.theme.spacing[2]};

	:last-child {
		margin-bottom: 0;
	}
`;

const StyledDocumentItemTemplate = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
`;

const StyledDocumentItem = styled.div`
	${StyledDocumentItemTemplate};
	border: 1px solid ${(props) => props.theme.colors.solitudeDark};
	width: 100%;
`;

const StyledDocumentItemLink = styled.div`
	${StyledDocumentItemTemplate};
	cursor: pointer;
	width: 100%;
	overflow: auto;
	text-overflow: ellipsis;
`;

const StyledStatus = styled.div`
	width: 16px;
	height: 100%;
	background-color: ${(props) => {
		const statusColor = documentsHelper.getStatusColor(props.status);
		return props.theme.colors[statusColor];
	}};
`;

const StyledDate = styled.div`
	text-align: center;
	padding-left: ${(props) => props.theme.spacing[4]};
	padding-right: ${(props) => props.theme.spacing[4]};
	border-right: 1px solid ${(props) => props.theme.colors.active};
	width: 105px;
`;

const StyledTitle = styled.div`
	flex: 1;
	padding: ${(props) => props.theme.spacing[3]}
		${(props) => (props.hasBadge ? "144px" : props.theme.spacing[4])}
		${(props) => props.theme.spacing[3]} ${(props) => props.theme.spacing[4]};
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	min-width: 0;

	/* Make cursor pointer when hovering over badge.badge.
	 * Badge is the only div element inside titles
	 */
	> div {
		cursor: pointer;
	}
`;

const StyledDropdownWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-right: ${(props) => props.theme.spacing[2]};
`;

const StyledFileIconWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 36px;
	min-width: 36px;
	margin: ${(props) => props.theme.spacing[2]};
	margin-right: 0;
`;

const StyledToggleSwitchWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 48px;
	min-width: 48px;
`;

export class DocumentListItem extends PureComponent {
	static propTypes = {
		renderDropdown: func,
	};

	render() {
		const {
			onDocumentClick,
			date,
			id,
			folderId,
			isUploading,
			status,
			title,
			folderName,
			to,
			active,
			folderPath,
			renderBadge,
			renderDropdown,
		} = this.props;
		const formattedDate = date ? Moment(date).format("L") : null;
		const hasBadge = Boolean(folderName);
		const hasDropdown = Boolean(renderDropdown) && false; //always false to get the padding back.

		return (
			<StyledListItem id={id} active={active} hoverColor="lightestGrey">
				<StyledDocumentItemLink
					onClick={() => {
						onDocumentClick && onDocumentClick(to, id);
					}}
				>
					<StyledStatus status={status} />
					<StyledDate>
						{!isUploading && <Text>{formattedDate}</Text>}
						{isUploading && (
							<Loader width="35px" height="25px" barWidth="5px" />
						)}
					</StyledDate>
					<StyledTitle hasBadge={hasBadge}>
						<Text singleLine={true}>{title}</Text>
						{renderBadge &&
							renderBadge(id, folderId, folderPath, folderName, {
								hasDropdown,
							})}
					</StyledTitle>
				</StyledDocumentItemLink>

				{renderDropdown && (
					<StyledDropdownWrapper>{renderDropdown(id)}</StyledDropdownWrapper>
				)}
			</StyledListItem>
		);
	}
}

export class DocumentListItemInvestor extends PureComponent {
	onToggleSwitchChange = (documentId, checked) => {
		this.props.onToggleSwitchChange(documentId, checked);
	};

	render = () => {
		const {
			userLang,
			region,
			onBadgeClick,
			index,
			folderPath,
			lastModified,
			validFrom,
			createdAt,
			id,
			folderId,
			folderName,
			title,
			file,
			renderDropdown,
			showToggleSwitch,
			isToggleSwitchChecked,
			isToggleSwitchDisabled,
			links,
			signedIsMirrored,
		} = this.props;
		const ext = file?.get("ext");

		let formattedDate = lastModified ? Moment(lastModified).format("L") : null;

		if (validFrom && !formattedDate) {
			formattedDate = Moment(validFrom).format("L");
		}

		if (createdAt && !formattedDate) {
			formattedDate = Moment(createdAt).format("L");
		}

		return (
			<StyledListItem hoverColor="lightestGrey" key={index}>
				<StyledDocumentItem>
					<StyledFileIconWrapper>
						<FileIcon ext={ext} />
					</StyledFileIconWrapper>
					<StyledDate>
						<Text>{formattedDate}</Text>
					</StyledDate>
					<StyledTitle>
						<Text singleLine={true}>{title}</Text>
						{folderName && (
							<BadgeOld
								linkText={folderPath}
								onClick={onBadgeClick && onBadgeClick.bind(null, id, folderId)}
							>
								{i18nhelper.getTranslatedValue(folderName, userLang, region)}
							</BadgeOld>
						)}
					</StyledTitle>
					{showToggleSwitch && (
						<StyledToggleSwitchWrapper>
							<ToggleSwitch
								checked={isToggleSwitchChecked({ links })}
								onChange={this.onToggleSwitchChange.bind(this, id)}
								disabled={isToggleSwitchDisabled && isToggleSwitchDisabled(id)}
							/>
						</StyledToggleSwitchWrapper>
					)}
					{renderDropdown && (
						<StyledDropdownWrapper>
							{renderDropdown(id, signedIsMirrored)}
						</StyledDropdownWrapper>
					)}
				</StyledDocumentItem>
			</StyledListItem>
		);
	};
}
