import React from "react";
import { string } from "prop-types";
import styled from "styled-components";
import Icon from "../icon/icon";

const StyledImage = styled.img`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
`;

const StyledIconizedImage = styled.div`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	background-color: ${(props) => props.theme.colors.solitudeMid};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const ProfileImage = ({ image, width, height, mode, size }) => {
	if (!image) {
		return (
			<StyledIconizedImage width={width} height={height}>
				<Icon
					icon="faMehBlank"
					size={size}
					color={mode === "darker" ? "lightGrey" : "white"}
				/>
			</StyledIconizedImage>
		);
	}

	return <StyledImage src={image} width={width} height={height} />;
};

ProfileImage.defaultProps = {
	width: "100%",
	height: "100%",
	size: "xl",
};

ProfileImage.propTypes = {
	image: string,
	width: string,
	height: string,
	mode: string,
	size: string,
};

export default ProfileImage;
