/**
 * ActivitiesLog
 * @module components/framework/activities-log
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { oneOf, bool } from "prop-types";
import moment from "../../modules/moment.module";
import Panel from "../../dumb-components/shared/panel/panel";
import { Padding } from "styled-components-spacing";

class ActivitiesLog extends Component {
	static propTypes = {
		panelType: oneOf(["inline"]),
		marginBottom: bool,
	};

	renderActivity(activity, index) {
		activity = JSON.parse(JSON.stringify(activity));

		const translationValues = activity.translationValues;
		const { date, translationId } = activity;
		const { i18n } = this.props;

		if (translationValues) {
			Object.keys(translationValues).forEach((key) => {
				if (translationValues[key].indexOf("tid:") !== -1) {
					const tid = translationValues[key].substr(4);
					translationValues[key] = i18n.messages[tid];
				}
			});
		}

		const func = (
			<FormattedMessage id={translationId} values={translationValues} />
		);

		return (
			<tr key={index}>
				<td className="function">{func}</td>
				<td className="date">
					{moment(date).format("L")} {moment(date).format("LT")}
				</td>
			</tr>
		);
	}

	renderActivities(activities) {
		return (
			<table className="table table--activities">
				<thead>
					<tr>
						<th className="function">
							<FormattedMessage id="generic.activity_function" />
						</th>
						<th className="date">
							<FormattedMessage id="generic.activity_date_time" />
						</th>
					</tr>
				</thead>
				<tbody>
					{activities.reverse().map(this.renderActivity.bind(this))}
				</tbody>
			</table>
		);
	}

	render() {
		const { activities, disabled, panelType, marginBottom } = this.props;

		if (!activities) {
			return null;
		}

		if (disabled === true) {
			return null;
		}

		const panelMode = panelType === "inline" ? "light" : undefined;

		return (
			<Panel
				title="generic.activity"
				type={panelType}
				mode={panelMode}
				marginBottom={marginBottom}
			>
				<Padding all={3}>{this.renderActivities(activities)}</Padding>
			</Panel>
		);
	}
}

function mapStateToProps(state) {
	return { i18n: state.i18n };
}

export default connect(mapStateToProps)(ActivitiesLog);
