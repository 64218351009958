import React from "react";
import ImmutableProps from "react-immutable-proptypes";
import { bool, func, object } from "prop-types";
import styled from "styled-components";
import { Padding } from "styled-components-spacing";
import Grid from "styled-components-grid";
import Moment from "../../../modules/moment.module";
import Modal from "../../shared/modal/modal";
import ScrollView from "../../shared/layout/scroll-view/scroll-view";
import Text from "../../shared/text/text";
import TextArea from "../../shared/input/textarea";
import Panel from "../../shared/panel/panel";
import { Select } from "../../shared/select";
import Datepicker from "../../shared/datepicker/datepicker";
import Label from "../../shared/label/label";
import { AVAIBLE_TASK_STATUS } from "../../../constants/tasks";
import ColorProgressSelect from "../../shared/color-progress-select/color-progress-select";

const StyledMainTaskModalWrapper = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
`;

const StyledContentLeft = styled.div`
	border-right: 1px solid ${(props) => props.theme.colors.border};
	display: flex;
	flex-direction: column;
	flex: 2;
`;

const StyledContentRight = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0 ${(props) => props.theme.spacing[6]};
`;

const TaskCreateEditModal = ({
	task,
	isOpen,
	onChange,
	i18n,
	errors,
	users,
	tagsSelectorRenderer,
	fileUploadRenderer,
	commentsListRenderer,
	controlButtonsRenderer,
	activityPanelRenderer,
	renderAssigneeContainer,
	renderEditorContainer,
}) => {
	const getTitle = () => {
		const taskId = task.get("taskId");

		if (taskId) {
			return (
				<Text fontSize="18px" bold={600}>
					ID #{taskId}
				</Text>
			);
		}

		return <Text fontSize="18px" bold={600} tid="task" />;
	};

	const renderTaskDetails = () => {
		return (
			<>
				<Grid>
					<Grid.Unit size={1}>
						<Padding vertical={3}>
							<Label tid="tasks.details.label.assigned_to">
								{renderAssigneeContainer && renderAssigneeContainer()}
								{!renderAssigneeContainer && users && (
									<Select
										fieldName="assigne"
										options={users}
										onChange={onChange}
										value={task ? task.get("assigne") : null}
										menuPortalTarget={document.body}
									/>
								)}
							</Label>
						</Padding>
					</Grid.Unit>
				</Grid>

				<Grid>
					<Grid.Unit size={{ xs: 1, lg: 1 / 2 }}>
						<Padding vertical={3} right={{ lg: 3 }}>
							<Label tid="tasks.details.label.task_status">
								<ColorProgressSelect
									fieldName="status"
									options={AVAIBLE_TASK_STATUS}
									onChange={onChange}
									value={task ? task.get("status") : null}
									menuPortalTarget={document.body}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
					<Grid.Unit size={{ xs: 1, lg: 1 / 2 }}>
						<Padding vertical={3} left={{ lg: 3 }}>
							<Label tid="tasks.details.label.due_date">
								<Datepicker
									fieldName="dueAt"
									onChange={onChange}
									value={task && task.get("dueAt") && Moment(task.get("dueAt"))}
									calendarPlacement="bottom-end"
									language={i18n.language}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
				</Grid>
			</>
		);
	};

	return (
		<Modal
			hSize="xl"
			isOpen={isOpen}
			rawTitle={getTitle()}
			footerComponent={controlButtonsRenderer()}
			scrollableContent={false}
			noBodyMargin
		>
			<StyledMainTaskModalWrapper>
				<StyledContentLeft>
					<ScrollView alwaysShow>
						<Padding vertical={4} horizontal={6} right="6-scrollOffset">
							<Padding bottom={3}>
								<Label tid="tasks.modal.title_placeholder">
									<TextArea
										fieldName="title"
										onChange={onChange}
										error={errors && errors.get("title")}
										placeholderTid="tasks.modal.title_placeholder"
										fontSize="large"
										value={task && task.get("title")}
									/>
								</Label>
							</Padding>
							<Padding top={3} bottom={4}>
								<Label tid="generic.description">
									{renderEditorContainer && renderEditorContainer()}
								</Label>
							</Padding>

							<Padding vertical={3}>
								<Panel
									title="tasks.edit_create_modal.panel.general_information"
									type="inline"
									mode="light"
								>
									{renderTaskDetails()}
								</Panel>
							</Padding>

							<Padding vertical={3}>
								<Panel title="generic.tags" type="inline" mode="light">
									{tagsSelectorRenderer && tagsSelectorRenderer()}
								</Panel>
							</Padding>

							<Padding vertical={3}>
								{fileUploadRenderer && fileUploadRenderer()}
							</Padding>

							{activityPanelRenderer && (
								<Padding vertical={3}>{activityPanelRenderer()}</Padding>
							)}
						</Padding>
					</ScrollView>
				</StyledContentLeft>

				<StyledContentRight>
					<ScrollView alwaysShow>
						<Padding vertical={3}>
							{commentsListRenderer && commentsListRenderer()}
						</Padding>
					</ScrollView>
				</StyledContentRight>
			</StyledMainTaskModalWrapper>
		</Modal>
	);
};

TaskCreateEditModal.defaultProps = {
	isOpen: false,
};

TaskCreateEditModal.propTypes = {
	task: ImmutableProps.map,
	isOpen: bool,
	onChange: func,
	i18n: object,
	errors: object,
	users: ImmutableProps.list,
	tagsSelectorRenderer: func,
	fileUploadRenderer: func,
	commentsListRenderer: func,
	controlButtonsRenderer: func,
	activityPanelRenderer: func,
	renderAssigneeContainer: func,
	renderEditorContainer: func,
};

export default TaskCreateEditModal;
