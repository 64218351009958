import { fromJS } from "immutable";

import {
	SHARED_COLLECTIONS_FETCH_DOCUMENTS,
	SHARED_COLLECTIONS_FETCH_COLLECTION,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	documents: [],
	collection: null,
});

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case SHARED_COLLECTIONS_FETCH_DOCUMENTS:
			return state.set("documents", payload);

		case SHARED_COLLECTIONS_FETCH_COLLECTION:
			return state.set("collection", payload);

		default:
			return state;
	}
}
