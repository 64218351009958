import React, { Component } from "react";
import { connect } from "react-redux";
import { string, object, oneOf } from "prop-types";
import { List, Map } from "immutable";
import { withRouter } from "../../../interfaces/router";
import debounce from "lodash/debounce";
import {
	listExternalTasksMultiple,
	updateTaskLocal,
	saveExternalTask,
	createExternalTask,
	createTaskLocal,
	fetchLatestOrderIndex,
	createExternalTaskLocal,
	updateExternalTasksLocal,
} from "../../../actions/tasks.actions";
import { fetchSimpleUsers } from "../../../actions/usersCache.actions";
import {
	fetchMeeting,
	updateMeetingLocal,
} from "../../../actions/meetings.actions";
import TasksList from "../../../dumb-components/meetings/tasks-list/tasks-list";
import ProtocolTasksList from "../../../dumb-components/meetings/protocol-tasks-list/protocol-tasks-list";
import { flattenAgendaItems } from "../../../components/helpers/meeting.helper";
import TaskCreatorContainer from "../../tasks/task-creator.container";
import MeetingsTaskItemDropdownContainer from "./meetings-task-item-dropdown.container";
import history from "../../../interfaces/history";

import {
	LIVE_MEETINGS_AGENDA_UPDATE,
	LIVE_MEETINGS_AGENDA_DELETE,
	LIVE_TASK_EXTERNAL_CREATE,
	LIVE_TASK_EXTERNAL_UPDATE,
	LIVE_TASK_EXTERNAL_DELETE,
} from "../../../constants/live-update";

import { OBJ_TYPE_MEETING } from "/shared/constants";
import { MEETING_GROUPS_ID } from "../../../constants/meetings";

class MeetingsTasksListContainer extends Component {
	state = {
		mapObjIdToTasks: Map(),
		tasksMetadata: Map(),
		flatAgendaItems: List(),
		sortedTasks: Map(),
		mapTaskIdToSection: Map(),
		taskInEditMode: null,
	};

	static propTypes = {
		basePath: string,
		location: object,
		viewMode: oneOf(["protocol", "meeting"]),
	};

	static defaultProps = {
		viewMode: "meeting",
	};

	componentDidMount = () => {
		const {
			meeting,
			match: {
				params: { taskId },
			},
		} = this.props;
		const { mapObjIdToTasks } = this.state;

		if (meeting) {
			// Create flat list out of all agenda items
			const flatAgendaItems = this.flattenAgendaItems();
			// If there are agendaItems, parse them and fetch the tasks
			this.fetchTasks(flatAgendaItems);
		}

		if (mapObjIdToTasks && !taskId) {
			this.goToFirstItem();
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		const {
			meeting,
			usersCache,
			tasks,
			match: {
				params: { taskId },
			},
			location: { pathname },
		} = this.props;
		const { sortedTasks, mapObjIdToTasks } = this.state;

		if (prevProps.meeting !== meeting) {
			const flatAgendaItems = this.flattenAgendaItems();
			this.fetchTasks(flatAgendaItems);
		}

		if (sortedTasks && prevProps.usersCache !== usersCache) {
			const tasksMetadata = this.getTasksMetadata(sortedTasks);
			this.setState({ tasksMetadata });
		}

		if (tasks !== prevProps.tasks) {
			const flatAgendaItems = this.flattenAgendaItems();
			this.processTasks(tasks, flatAgendaItems);
		}

		// In protocol view we do not want to select the first task that's why we check for the pathname.
		if (
			(!prevState.mapObjIdToTasks || prevState.mapObjIdToTasks.size === 0) &&
			mapObjIdToTasks &&
			!taskId &&
			!pathname.includes("/protocols/")
		) {
			this.goToFirstItem();
		}

		this.checkLiveUpdateEvents();
	};

	componentWillUnmount = () => {
		const { updateExternalTasksLocal } = this.props;
		updateExternalTasksLocal(null);
	};

	checkLiveUpdateEvents = () => {
		const { meetingAudit, tasksAudit, meeting, fetchMeeting } = this.props;
		const { flatAgendaItems } = this.state;
		const agendaUpdated = meetingAudit.get(LIVE_MEETINGS_AGENDA_UPDATE, Map());
		const agendaDeleted = meetingAudit.get(LIVE_MEETINGS_AGENDA_DELETE, Map());
		const taskCreated = tasksAudit.get(LIVE_TASK_EXTERNAL_CREATE, Map());
		const taskUpdated = tasksAudit.get(LIVE_TASK_EXTERNAL_UPDATE, Map());
		const taskDeleted = tasksAudit.get(LIVE_TASK_EXTERNAL_DELETE, Map());

		if (
			agendaUpdated.get("refresh") === true ||
			agendaDeleted.get("refresh") === true
		) {
			meeting && fetchMeeting(meeting.get("id"));
		}

		if (
			taskCreated.get("refresh") === true ||
			taskUpdated.get("refresh") === true ||
			taskDeleted.get("refresh") === true
		) {
			this.fetchTasks(flatAgendaItems);
		}
	};

	goToFirstItem = () => {
		const { tasks } = this.props;
		const { mapObjIdToTasks } = this.state;
		const task =
			mapObjIdToTasks &&
			mapObjIdToTasks.first() &&
			mapObjIdToTasks.first().first() &&
			tasks.getIn(["tasksMap", mapObjIdToTasks.first().first()]);
		task && !task.get("createdLocalOnly") && this.onTaskClick(task.get("id"));
	};

	getTasksMetadata = (sortedTasks) => {
		const { usersCache } = this.props;
		let tasksMetadata = Map();

		sortedTasks.forEach((group) => {
			group.forEach((task) => {
				let metadata = Map();
				const assignedTo = task.get("assigne");
				const taskId = task.get("id");
				const user = usersCache.get(assignedTo);

				if (assignedTo && user) {
					metadata = metadata.set(
						"profileImage",
						user.getIn(["image", "filename"]),
					);
					metadata = metadata.set("assignedToUserId", user.get("id"));
				}

				tasksMetadata = tasksMetadata.set(taskId, metadata);
			});
		});

		return tasksMetadata;
	};

	fetchTasks = (flatAgendaItems) => {
		const { listExternalTasksMultiple } = this.props;
		let agendaIds = List();

		flatAgendaItems &&
			flatAgendaItems.forEach((agendaItem) => {
				agendaIds = agendaIds.push(agendaItem.get("objId"));
			});

		listExternalTasksMultiple(agendaIds);
	};

	processTasks = (tasks, flatAgendaItems) => {
		let mapTaskIdToSection = Map();
		const mapObjIdToTasks = tasks.get("mapObjIdToTasks");

		let tasksMap = tasks.get("tasksMap");
		let userIds = List();

		tasksMap.forEach((task) => {
			const assigne = task.get("assigne");

			if (assigne) {
				userIds = userIds.push(assigne);
			}
		});

		tasksMap = tasksMap.map((task) => {
			const agendaItemLink = task
				.get("links", List())
				.find((obj) => obj.get("objType") === OBJ_TYPE_MEETING);
			const objId = agendaItemLink.get("objId");
			const agendaItem = flatAgendaItems.find(
				(agendaItem) => agendaItem.get("objId") === objId,
			);

			task = task.set("proposal", agendaItem.get("proposal"));

			if (agendaItem.get("isGeneral")) {
				task = task.set("meetingId", agendaItem.get("id"));
			} else {
				task = task.set("agendaItemId", agendaItem.get("id"));
			}

			return task;
		});

		// Group tasks into two lists: 'general' and 'topics'
		// Sort lists by orderIndex
		const sortedTasks = flatAgendaItems
			.groupBy((obj) => (obj.get("isGeneral") ? "general" : "topics"))
			.map((group, index) => {
				let _tasks = Map();

				group.forEach((agendaItem) => {
					const taskIds = mapObjIdToTasks.get(agendaItem.get("objId"), List());
					taskIds.forEach((taskId) => {
						mapTaskIdToSection = mapTaskIdToSection.set(taskId, index);

						if (tasksMap.has(taskId)) {
							_tasks = _tasks.set(taskId, tasksMap.get(taskId));
						}
					});
				});

				return _tasks.sortBy((obj) => obj.get("orderIndex"));
			});

		// Fetch update usersCache
		fetchSimpleUsers(userIds);

		// Update tasks tasksMetadata
		const tasksMetadata = this.getTasksMetadata(sortedTasks);

		this.setState({
			mapObjIdToTasks,
			tasksMetadata,
			sortedTasks,
			mapTaskIdToSection,
		});
	};

	flattenAgendaItems = () => {
		const { meeting, i18n } = this.props;
		const meetingId = meeting.get("id");

		let flatAgendaItems = flattenAgendaItems(meeting);
		flatAgendaItems = flatAgendaItems.filter(
			(obj) => !obj.get("isSuggested") && !obj.get("archived"),
		);

		// Insert "General" (attachments from meeting) at the beginning
		flatAgendaItems = flatAgendaItems.unshift(
			Map({
				proposal: i18n.messages["meetings.tasks.section.general"],
				isGeneral: true,
				objId: meetingId,
			}),
		);

		this.setState({ flatAgendaItems });

		return flatAgendaItems;
	};

	doDebounce = debounce((task) => {
		const { saveExternalTask } = this.props;
		const objId = task.getIn(["links", 0, "objId"]);
		saveExternalTask(objId, task);
	}, 1000);

	updateTaskLocal = (task) => {
		const { updateTaskLocal } = this.props;
		updateTaskLocal(task);
		this.doDebounce(task);
	};

	onChange = (fieldName, val) => {
		let { task } = this.props;

		if (!fieldName || !task) {
			return;
		}

		task = task.set(fieldName, val);

		this.updateTaskLocal(task);
	};

	onChangeInProtocol = (taskId, fieldName, val) => {
		const { tasks, updateExternalTasksLocal, saveExternalTask } = this.props;
		let task = tasks.getIn(["tasksMap", taskId]);
		task = task.set(fieldName, val);

		updateExternalTasksLocal(tasks.setIn(["tasksMap", taskId], task));
		saveExternalTask(task.getIn(["links", 0, "objId"]), task);
	};

	onTaskClick = (taskId, selectedTask) => {
		const { basePath, updateTaskLocal, history } = this.props;
		const { sortedTasks, mapTaskIdToSection } = this.state;

		if (!selectedTask) {
			const section = mapTaskIdToSection.get(taskId);
			selectedTask = sortedTasks.getIn([section, taskId]);
		}

		updateTaskLocal(selectedTask, () => {
			history.push(`${basePath}/${taskId}`);
		});
		this.setState({ taskInEditMode: taskId });
	};

	onBadgeClick = (taskId) => {
		const {
			basePath,
			location: { search },
			history,
		} = this.props;
		const { sortedTasks, mapTaskIdToSection } = this.state;
		const section = mapTaskIdToSection.get(taskId);
		const task = sortedTasks.getIn([section, taskId]);
		let pathname;

		if (task.get("agendaItemId")) {
			const agendaItemId = task.get("agendaItemId");
			pathname = basePath.replace("/tasks", `/agenda/${agendaItemId}`);
		} else {
			pathname = basePath.replace("/tasks", "/info");
		}

		history.push({ pathname, search });
	};

	resetTaskInEditMode = () => {
		this.timeout = setTimeout(() => {
			this.setState({ taskInEditMode: null });
		}, 500);
	};

	createTask = (insertAtIndex, title) => {
		const {
			meeting,
			createExternalTask,
			createExternalTaskLocal,
			fetchLatestOrderIndex,
			updateMeetingLocal,
		} = this.props;
		let { sortedTasks, mapTaskIdToSection } = this.state;
		const objType = OBJ_TYPE_MEETING;
		const objId = meeting.get("id");
		const projectId = meeting.get("groupId")
			? meeting.get("groupId")
			: MEETING_GROUPS_ID;
		let task = Map({
			title,
			projectId,
			orderIndex: insertAtIndex,
			description: "",
			links: List([
				Map({
					objId,
					objType: OBJ_TYPE_MEETING,
				}),
			]),
		});

		this.timeout && clearTimeout(this.timeout);

		if (insertAtIndex === null) {
			fetchLatestOrderIndex((orderIndex) => {
				task = task.set("orderIndex", orderIndex);
				createExternalTaskLocal(orderIndex, task, objId, (newTask) => {
					const newTaskId = newTask.get("id");
					sortedTasks = sortedTasks.setIn(["general", newTaskId], newTask);
					mapTaskIdToSection = mapTaskIdToSection.set(newTaskId, "general");
					this.setState({ sortedTasks, mapTaskIdToSection });
					createExternalTask(objType, objId, newTask, () => {
						this.onTaskClick(newTaskId, newTask);
						updateMeetingLocal(
							meeting.updateIn(
								["computedValues", "numOfTasks"],
								(numOfTasks) => ++numOfTasks,
							),
						);
					});
				});
			});
		} else {
			createExternalTaskLocal(insertAtIndex, task, objId, (newTask) => {
				const newTaskId = newTask.get("id");
				sortedTasks = sortedTasks.setIn(["general", newTaskId], newTask);
				mapTaskIdToSection = mapTaskIdToSection.set(newTaskId, "general");
				this.setState({ sortedTasks, mapTaskIdToSection });
				createExternalTask(objType, objId, newTask, () => {
					this.onTaskClick(newTaskId, newTask);
					updateMeetingLocal(
						meeting.updateIn(
							["computedValues", "numOfTasks"],
							(numOfTasks) => ++numOfTasks,
						),
					);
				});
			});
		}
	};

	renderTaskEditor = (projectId, title, index, badge) => {
		return (
			<TaskCreatorContainer
				fieldName="title"
				value={title}
				onChange={this.onChange}
				onFocus={() => {
					this.timeout && clearTimeout(this.timeout);
				}}
				onBlur={this.resetTaskInEditMode}
				badge={badge}
				noBadgeSpace={true}
				inline
				autofocus
			/>
		);
	};

	renderTaskDropdown = (taskId) => {
		const { location, basePath, isExternal } = this.props;

		return (
			<MeetingsTaskItemDropdownContainer
				basePath={basePath}
				isExternal={isExternal}
				location={location}
				taskId={taskId}
			/>
		);
	};

	renderMeetingsTasksList = () => {
		const { basePath, location } = this.props;
		const {
			tasksMetadata,
			flatAgendaItems,
			mapObjIdToTasks,
			sortedTasks,
			taskInEditMode,
		} = this.state;
		const currentUrl = location.pathname + location.search;

		return (
			<TasksList
				basePath={basePath}
				currentUrl={currentUrl}
				tasks={sortedTasks}
				mapObjIdToTasks={mapObjIdToTasks}
				tasksMetadata={tasksMetadata}
				flatAgendaItems={flatAgendaItems}
				onTaskClick={this.onTaskClick}
				onBadgeClick={this.onBadgeClick}
				onClickAddNewTask={this.createTask}
				taskInEditMode={taskInEditMode}
				taskEditorComponent={this.renderTaskEditor}
				renderTaskDropdown={this.renderTaskDropdown}
			/>
		);
	};

	renderProtocolTasksList = () => {
		const { usersCache, meeting, userObj } = this.props;
		const { mapObjIdToTasks, sortedTasks } = this.state;
		const isSecretary =
			userObj && meeting && userObj.get("id") === meeting.get("secretary");
		let tasks = List();
		sortedTasks.forEach((group) => (tasks = tasks.concat(group.toList())));

		return (
			<ProtocolTasksList
				usersCache={usersCache}
				tasks={tasks}
				mapObjIdToTasks={mapObjIdToTasks}
				onChange={this.onChangeInProtocol}
				readOnly={!isSecretary}
			/>
		);
	};

	render = () => {
		const { viewMode } = this.props;

		if (viewMode === "meeting") {
			return this.renderMeetingsTasksList();
		}

		if (viewMode === "protocol") {
			return this.renderProtocolTasksList();
		}
	};
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meeting: store.meetings.get("meeting", Map()) || Map(),
		usersCache: store.usersCache.get("usersCache"),
		task: store.tasks.get("task"),
		tasks: store.tasks.get("listByObjects"),
		userObj: store.user.get("userObj"),
		tasksAudit: store.audit.get("tasks"),
		meetingAudit: store.audit.get("meetings"),
		i18n: store.i18n,
	};
};

const mapActionsToProps = {
	listExternalTasksMultiple,
	updateTaskLocal,
	saveExternalTask,
	fetchSimpleUsers,
	fetchMeeting,
	createExternalTask,
	createTaskLocal,
	fetchLatestOrderIndex,
	updateMeetingLocal,
	createExternalTaskLocal,
	updateExternalTasksLocal,
};

export default withRouter(
	connect(mapStoreToProps, mapActionsToProps)(MeetingsTasksListContainer),
);
