import req from "../modules/request.module";
import { fromJS } from "immutable";

import {
	CUSTOM_MEETING_FUNCTIONS_LIST,
	CUSTOM_MEETING_FUNCTIONS_SAVE,
} from "./types";

export function listCustomMeetingFunctions(callback) {
	return function (dispatch) {
		return req
			.get(`/meetings/functions`)
			.then((response) => {
				const customFunctions = fromJS(response.data);
				dispatch({
					type: CUSTOM_MEETING_FUNCTIONS_LIST,
					payload: customFunctions,
				});
				callback && callback(customFunctions);
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function createCustomMeetingFunction(newFunction, callback) {
	return function (dispatch) {
		return req
			.post(`/meetings/functions`, { newFunction: newFunction.toJS() })
			.then((response) => {
				const createdFunction = fromJS(response.data);
				dispatch({
					type: CUSTOM_MEETING_FUNCTIONS_SAVE,
					payload: createdFunction,
				});
				callback && callback(createdFunction);
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function editCustomMeetingFunction(functionData, callback) {
	const functionId = functionData.get("id");

	return function (dispatch) {
		return req
			.put(`/meetings/functions/${functionId}`, {
				functionData: functionData.toJS(),
			})
			.then((response) => {
				const modifiedFunction = fromJS(response.data);
				dispatch({
					type: CUSTOM_MEETING_FUNCTIONS_SAVE,
					payload: modifiedFunction,
				});
				callback && callback(modifiedFunction);
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function deleteCustomMeetingFunction(functionId, callback) {
	return function (dispatch) {
		return req
			.delete(`/meetings/functions/${functionId}`)
			.then((response) => {
				const deletedFunction = fromJS(response.data);
				dispatch({
					type: CUSTOM_MEETING_FUNCTIONS_SAVE,
					payload: deletedFunction,
				});
				callback && callback(deletedFunction);
			})
			.catch((e) => {
				console.log(e);
			});
	};
}
