import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "../../../modules/moment.module";
import { fromJS } from "immutable";
import Cookies from "universal-cookie";
import debounce from "lodash/debounce";
import {
	updateTransaction,
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
} from "../../../actions/transaction.actions";
import { fetchMenu } from "../../../actions/menu.actions";

import ShareCapital from "./shares-setup/share-capital";
import ShareTypes from "./shares-setup/share-types";
import AddShareholders from "./shares-setup/add-shareholders";
import ShareDistributionA from "./shares-setup/share-distribution-a";
import ShareDistributionB from "./shares-setup/share-distribution-b";
import ShareOverview from "./shares-setup/share-overview";
import history from "../../../interfaces/history";
import { Button } from "@/components/ui/button";
import StepFlow from "@/components/stepFlow";

import i18n from "../../../i18n";
import Breadcrumbs from "@/components/breadcrumbs";
import withResolvedProps from "@/hocs/withResolvedProps";
import useDialogModal from "@/hooks/useDialogModal";
import { Undo2 } from "lucide-react";

const { t } = i18n;

const initialTransaction = fromJS({
	type: "GENESIS",
	handlerData: {},
	investors: {},
});

const de = debounce((func, transaction) => {
	func("GENESIS", transaction.toJS());
}, 1000);
class SharesSetup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentStep:
				new Cookies().get(
					"sharesSetupCurrentStep" +
						("/" + this.props.location.pathname.split("/")[1] + "/"),
				) || 0,
		};
	}

	componentDidMount() {
		this.fetchTemporaryTransaction();
	}

	fetchTemporaryTransaction() {
		this.props.fetchTemporaryTransaction("GENESIS");
	}

	onChange(transaction) {
		this.props.updateTransaction("GENESIS", transaction);
		de(this.props.saveTemporaryTransaction, transaction);
	}

	onNext(nextStep) {
		const companyPath = "/" + this.props.location.pathname.split("/")[1] + "/";
		new Cookies().set("sharesSetupCurrentStep" + companyPath, nextStep, {
			path: "/",
			expires: Moment().add(1, "year").toDate(),
		});
		this.setState({ currentStep: nextStep });
	}

	onPrevious(nextStep) {
		const companyPath = "/" + this.props.location.pathname.split("/")[1] + "/";
		new Cookies().set("sharesSetupCurrentStep" + companyPath, nextStep, {
			path: "/",
			expires: Moment().add(1, "year").toDate(),
		});
		this.setState({ currentStep: nextStep });
	}

	onCreateTransaction(transaction) {
		const { createTransaction, location, fetchMenu, history } = this.props;
		createTransaction(transaction, () => {
			this.onChange(initialTransaction);
			this.setState({ currentStep: 0 });
			const companyPath = "/" + location.pathname.split("/")[1] + "/";
			new Cookies().set("sharesSetupCurrentStep" + companyPath, 0, {
				path: "/",
				expires: Moment().add(1, "year").toDate(),
			});
			new Cookies().set("sharesSetupCurrentOption" + companyPath, 0, {
				path: "/",
				expires: Moment().add(1, "year").toDate(),
			});
			fetchMenu("main");
			history.push(
				location.pathname.split("shares")[0] + "shares/share-register",
			);
		});
	}

	async cancelWizard() {
		if (
			!(await this.props.confirm(
				t("shares_setup.cancel_confirm.submit"),
				t("shares_setup.cancel_confirm.body"),
				{
					variant: "warning",
					submitText: t("shares_setup.cancel_confirm.submit"),
				},
			))
		)
			return;

		this.onChange(initialTransaction);
		this.setState({ currentStep: 0 });
		const companyPath = "/" + this.props.location.pathname.split("/")[1] + "/";
		new Cookies().set("sharesSetupCurrentStep" + companyPath, 0, {
			path: "/",
			expires: Moment().add(1, "year").toDate(),
		});
		new Cookies().set("sharesSetupCurrentOption" + companyPath, 0, {
			path: "/",
			expires: Moment().add(1, "year").toDate(),
		});
		this.props.history.push(
			this.props.location.pathname.split("shares")[0] + "shares/setup",
		);
	}

	render() {
		const {
			match: { params },
			transaction,
		} = this.props;
		const { setupType } = params;
		const { currentStep } = this.state;

		const steps = [
			{
				name: "shareCapital",
				title: t("share_capital_and_number_of_shares"),
				component: (
					<ShareCapital
						setupType={setupType}
						transaction={transaction}
						onChange={this.onChange.bind(this)}
						onNext={this.onNext.bind(this)}
						onPrevious={this.onPrevious.bind(this)}
					/>
				),
			},
			{
				name: "shareTypes",
				title: t("shares_setup_optional"),
				component: (
					<ShareTypes
						setupType={setupType}
						transaction={transaction}
						onChange={this.onChange.bind(this)}
						onNext={this.onNext.bind(this)}
						onPrevious={this.onPrevious.bind(this)}
					/>
				),
			},
			{
				name: "shareholders",
				title: t("add_shareholders"),
				component: (
					<AddShareholders
						setupType={setupType}
						transaction={transaction}
						onChange={this.onChange.bind(this)}
						onNext={this.onNext.bind(this)}
						onPrevious={this.onPrevious.bind(this)}
					/>
				),
			},
		];

		if (setupType === "a") {
			// if "a"
			steps.push({
				name: "shareDistributionA",
				title: t("shares.assign_shares_to_shareholders"),
				component: (
					<ShareDistributionA
						setupType={setupType}
						transaction={transaction}
						onChange={this.onChange.bind(this)}
						onNext={this.onNext.bind(this)}
						onPrevious={this.onPrevious.bind(this)}
					/>
				),
			});
		} else if (setupType === "b") {
			//if "b"
			steps.push({
				name: "shareDistributionB",
				title: t("shares.create_share_sequences_for_the_share_register"),
				component: (
					<ShareDistributionB
						setupType={setupType}
						transaction={transaction}
						onChange={this.onChange.bind(this)}
						onNext={this.onNext.bind(this)}
						onPrevious={this.onPrevious.bind(this)}
					/>
				),
			});
		}

		steps.push({
			name: "shareOverview",
			title: t("review_information"),
			component: (
				<ShareOverview
					setupType={setupType}
					transaction={transaction}
					onChange={this.onChange.bind(this)}
					onCreateTransaction={this.onCreateTransaction.bind(this)}
					onPrevious={this.onPrevious.bind(this)}
				/>
			),
		});

		const title = setupType === "a" ? "option_a" : "option_b";
		const subtitle = setupType === "a" ? "option_a_info" : "option_b_info";

		return (
			<div className="flex flex-col gap-12 bg-background p-6 rounded border-edge border h-full">
				<div className="flex justify-between w-full">
					<div className="">
						<Breadcrumbs
							items={[
								{
									label: t("service.shares.setup"),
									onClick: this.cancelWizard.bind(this),
								},
								{ label: t(title) },
							]}
						/>

						<div className="py-4">{t(subtitle)}</div>
					</div>
					<div className="">
						<Button variant="ghost" onClick={this.cancelWizard.bind(this)}>
							<Undo2 /> {t("generic.form.cancel")}
						</Button>
					</div>
				</div>
				<StepFlow steps={steps} initialStepIndex={+currentStep} />
				{this.props.dialogElement}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		history: history,
		transaction: state.transaction.getIn(
			["tmpTransaction", "GENESIS"],
			initialTransaction,
		),
		company: state.company.company,
	};
}

const SharesSetupConnected = connect(mapStateToProps, {
	updateTransaction,
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	fetchMenu,
})(SharesSetup);

export default withResolvedProps(() => {
	const { dialogElement, confirm } = useDialogModal();

	return { dialogElement, confirm };
})(SharesSetupConnected);
