import React from "react";
import DropdownIconItem from "../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import { openModal } from "../../../../actions/modals.actions";
import { COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS } from "../../../../constants/modals";
import { useDispatch } from "react-redux";

export const CopyFileToPersonalDocuments = ({ document, onClick }) => {
	const dispatch = useDispatch();
	const isDeleted = document.get("isDeleted");

	if (isDeleted) {
		return null;
	}

	return (
		<DropdownIconItem
			icon="faCopy"
			tid="personal-documents.copy-file-to-personal-documents"
			onClick={() => {
				onClick?.();
				dispatch(
					openModal(COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS, {
						document,
						context: "documents",
					}),
				);
			}}
		/>
	);
};
