import React, { Component } from "react";
import { connect } from "react-redux";
import { List, Map, fromJS } from "immutable";
import { string, func, bool, object } from "prop-types";
import { Margin } from "styled-components-spacing";
import LineSeparator from "../../../dumb-components/shared/line-separator/line-separator";
import {
	createMeetingLocal,
	createMeeting,
	listMeetings,
	clearMeeting,
	saveMeeting,
} from "../../../actions/meetings.actions";
import { listMeetingTemplates } from "../../../actions/meeting-templates.actions";
import { listGroups } from "../../../actions/groups.actions";
import folderHelper from "../../../components/helpers/folder.helper";
import MeetingItemContainer from "./meeting-item.container";
import MeetingList from "../../../dumb-components/meetings/meeting-list/meeting-list";
import history, { getQuery } from "../../../interfaces/history";

import {
	LIVE_MEETINGS_CREATE,
	LIVE_MEETINGS_DELETE,
	LIVE_GROUP_UPDATE,
} from "../../../constants/live-update";

import { EVENT_TYPE_MEETINGS_UPDATE } from "/shared/constants";

class MeetingsListContainer extends Component {
	static propTypes = {
		basePath: string,
		querystr: string,
		onMeetingClick: func,
		hasAppliedFilters: bool,
		paramsId: string,
		location: object,
	};

	state = {
		groupDescendents: [],
		meetingsMetadata: Map(),
		meetingsInGroup: List(),
		meetingsInSubGroups: List(),
	};

	componentDidMount = () => {
		const { listMeetings, clearMeeting, paramsId, listMeetingTemplates } =
			this.props;
		//we are using the query instead of selectedGroupId because it is not yet set when the component mounts.
		const query = getQuery();
		listMeetings(query.group);
		listMeetingTemplates(query.group);

		// Needed for the toolbar to dissapear if you leave the page with selected task
		// by ex. navigating to Tasks. First we need to check if task was "linked"
		// to somebody (href/url link)
		if (!paramsId) {
			clearMeeting();
		}
	};

	componentDidUpdate = (prevProps) => {
		const {
			listMeetingTemplates,
			listMeetings,
			selectedGroupId,
			paramsId,
			meetings,
			meetingTemplates,
			groups,
			meeting,
			dateProp,
		} = this.props;
		if (paramsId && prevProps.paramsId !== paramsId) {
			this.onMeetingClick(paramsId);
		}

		if (prevProps.selectedGroupId !== selectedGroupId) {
			listMeetings(selectedGroupId);
			listMeetingTemplates(selectedGroupId);
		}

		if (prevProps.meetings !== meetings) {
			this.parseMeetings();
			this.parseMetadata();
		}

		if (
			prevProps.meetingTemplates !== meetingTemplates ||
			prevProps.groups !== groups
		) {
			this.parseMetadata();
		}

		this.checkLiveUpdateEvents();

		if (meeting && prevProps.meeting !== meeting) {
			if (
				!prevProps.meeting ||
				prevProps.meeting.get("id") !== meeting.get("id") ||
				(dateProp &&
					dateProp.getIn(["values", 0]) &&
					prevProps.meeting.get(dateProp.getIn(["values", 0])) !==
						meeting.get(dateProp.getIn(["values", 0])))
			) {
				setTimeout(() => {
					const panelNode = document.getElementById(meeting.get("id"));
					const rect = panelNode && panelNode.getBoundingClientRect();
					//The 150 offset on top might need to be slightly adjusted, if things end up not refocusing sometimes try upping this value.
					const isElementInViewport =
						rect &&
						rect.top >= 150 &&
						rect.left >= 0 &&
						rect.bottom <=
							(window.innerHeight || document.documentElement.clientHeight) &&
						rect.right <=
							(window.innerWidth || document.documentElement.clientWidth);
					if (!isElementInViewport) {
						this.props.scrollbarRef.scrollTop(panelNode && panelNode.offsetTop);
					}
				}, 200);
			}
		}
	};

	checkLiveUpdateEvents = () => {
		const { audit, selectedGroupId, listMeetings, listGroups, groupAudit } =
			this.props;
		const meetingCreate = audit.get(LIVE_MEETINGS_CREATE, Map());
		const meetingUpdate = audit.get(EVENT_TYPE_MEETINGS_UPDATE, Map());
		const meetingDelete = audit.get(LIVE_MEETINGS_DELETE, Map());
		const GROUP_UPDATE = groupAudit.get(LIVE_GROUP_UPDATE, Map());

		// Folder was changed, checked because of permission updates
		if (GROUP_UPDATE && GROUP_UPDATE.get("refresh") === true) {
			listMeetings(selectedGroupId);
		}

		// Meeting was created, edited or deleted
		if (
			meetingCreate.get("refresh") === true ||
			meetingUpdate.get("refresh") === true ||
			meetingDelete.get("refresh") === true
		) {
			listMeetings(selectedGroupId);
			listGroups();
		}
	};

	parseMeetings = () => {
		const { selectedGroupId, meetings, mapParentToChildren } = this.props;
		const groupDescendents = [];

		folderHelper.getDescendentsIds(
			groupDescendents,
			selectedGroupId,
			mapParentToChildren,
		);
		this.setState({ groupDescendents });

		if (!meetings) {
			return null;
		}

		let meetingsInGroup = meetings;
		let meetingsInSubGroups = List();

		if (selectedGroupId) {
			meetingsInGroup = meetings.filter(
				(meeting) => meeting.get("groupId") === selectedGroupId,
			);
			meetingsInSubGroups = meetings.filter(
				(meeting) =>
					meeting.get("groupId") !== null &&
					meeting.get("groupId") !== "" &&
					meeting.get("groupId") !== selectedGroupId &&
					groupDescendents.indexOf(meeting.get("groupId")) >= 0,
			);
		}

		this.setState({ meetingsInGroup, meetingsInSubGroups });
	};

	parseMetadata = () => {
		const { meetings, groups, meetingTemplates, i18n, selectedGroupId } =
			this.props;
		let meetingsMetadata = Map();

		if (meetings) {
			meetings.forEach((meeting) => {
				const metadata = {};
				const meetingId = meeting.get("id");
				const groupId = meeting.get("groupId");
				const meetingTemplateId = meeting.get("templateId");

				metadata.groupPath = folderHelper.getPath(groupId, groups);

				if (groupId && groupId !== "" && groupId !== selectedGroupId) {
					metadata.groupName = groups && groups.getIn([groupId, "name"]);
				}

				metadata.groupId = groupId;
				metadata.isSecretary = meeting.getIn(["computedValues", "isSecretary"]);
				metadata.meetingType = meeting.get("meetingType");

				if (meetingTemplateId && meetingTemplates) {
					let templateName = "";

					if (meetingTemplateId === "LEGACY") {
						templateName =
							i18n.messages["meeting.general.template_option.legacy"];
					} else {
						const meetingTemplate = meetingTemplates.find(
							(obj) => obj.get("id") === meetingTemplateId,
						);

						if (meetingTemplate) {
							templateName = meetingTemplate.get("name");
						}
					}

					metadata.template = templateName;
				}

				meetingsMetadata = meetingsMetadata.set(meetingId, fromJS(metadata));
			});

			this.setState({ meetingsMetadata });
		}
	};

	updateMeetingLocal = (meeting) => {
		const { saveMeeting } = this.props;
		saveMeeting(meeting);
	};

	onChange = (field, value) => {
		let { meeting } = this.props;

		if (!field || !meeting) {
			return;
		}

		meeting = meeting.set(field, value);
		this.updateMeetingLocal(meeting);
	};

	onBadgeClick = (meetingId, groupId) => {
		const { basePath, history } = this.props;
		history.push({
			pathname: `${basePath}/${meetingId}`,
			search: `?group=${groupId}`,
		});
	};

	onMeetingClick = (meetingId) => {
		const {
			querystr,
			basePath,
			meetings,
			onMeetingClick,
			clearMeeting,
			history,
		} = this.props;

		const clickedItem = meetings?.find?.((obj) => obj.get("id") === meetingId);

		// As this function is triggered on paramsId change, it even runs when an advanced filter is changed.
		// Before selecting the meeting, let's check if it exists in the list
		if (!clickedItem) {
			clearMeeting();
			history.push({ pathname: basePath, search: querystr });
			return;
		}

		onMeetingClick && onMeetingClick();

		history.push({ pathname: `${basePath}/${meetingId}`, search: querystr });
	};

	onNav = (index, direction) => {
		const { meetings } = this.props;
		const newIndex = index + direction;
		const meeting = meetings.get(newIndex);

		if (meeting) {
			this.onMeetingClick(meeting.get("id"));
		}
	};

	renderMeetingItem = (meetingProps) => {
		/**
		 * meetingProps:
		 * - key
		 * - id
		 * - name
		 * - startDate
		 * - endDate
		 * - status
		 * - listIndex
		 */
		const {
			i18n,
			company,
			basePath,
			meeting,
			location: { pathname },
		} = this.props;
		const { meetingsMetadata } = this.state;
		const groupId = meetingsMetadata.getIn([meetingProps.id, "groupId"]);
		const groupName = meetingsMetadata.getIn([meetingProps.id, "groupName"]);
		const groupPath = meetingsMetadata.getIn([meetingProps.id, "groupPath"]);
		const active = pathname === `${basePath}/${meetingProps.id}`;
		const readOnly =
			meeting &&
			(meeting.getIn(["computedValues", "isSimpleMode"]) ||
				meeting.get("isDeleted"));
		const isSecretary = meetingsMetadata.getIn([
			meetingProps.id,
			"isSecretary",
		]);
		const meetingType = meetingsMetadata.getIn([
			meetingProps.id,
			"meetingType",
		]);

		return (
			<MeetingItemContainer
				{...meetingProps}
				userLang={i18n.language}
				region={company.region}
				active={active}
				groupId={groupId}
				groupPath={groupPath}
				groupName={groupName}
				onBadgeClick={this.onBadgeClick}
				onMeetingClick={this.onMeetingClick}
				onArrowUp={this.onNav.bind(this, meetingProps.listIndex)}
				onArrowDown={this.onNav.bind(this, meetingProps.listIndex)}
				onChange={this.onChange}
				onFocus={() => this.timeout && clearTimeout(this.timeout)}
				readOnly={readOnly}
				isSecretary={isSecretary}
				meetingType={meetingType}
			/>
		);
	};

	render = () => {
		const { selectedGroupId, basePath, paramsId } = this.props;
		const { meetingsMetadata, meetingsInGroup, meetingsInSubGroups } =
			this.state;
		const displaySubMeetings = meetingsInSubGroups.size > 0 && selectedGroupId;

		return (
			<div>
				<MeetingList
					meetings={meetingsInGroup}
					renderMeetingComponent={this.renderMeetingItem}
					basePath={basePath}
					currentUrl={`${basePath}/${paramsId}`}
					meetingsMetadata={meetingsMetadata}
				/>

				{displaySubMeetings && (
					<Margin vertical={5}>
						<LineSeparator
							tid="meetings.list.sub_category_tasks"
							bgColor="solitudeLight"
						/>
					</Margin>
				)}

				{displaySubMeetings && (
					<MeetingList
						meetings={meetingsInSubGroups}
						renderMeetingComponent={this.renderMeetingItem}
						basePath={basePath}
						currentUrl={`${basePath}/${paramsId}`}
						meetingsMetadata={meetingsMetadata}
					/>
				)}
			</div>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meetings: store.meetings.get("visibleMeetings"),
		meeting: store.meetings.get("meeting"),
		hasAppliedFilters: store.meetings.get("hasAppliedFilters"),
		selectedGroupId: store.groups.get("selectedGroupId"),
		mapParentToChildren: store.groups.get("mapParentToChildren"),
		groups: store.groups.get("groups"),
		meetingTemplates: store.meetingTemplates.get("list"),
		i18n: store.i18n,
		company: store.company.company,
		audit: store.audit.get("meetings"),
		groupAudit: store.audit.get("groups"),
		dateProp: store.meetings
			.get("filterBy", List())
			.find((obj) => obj.get("source") === "dateProp"),
	};
};

const mapActionsToProps = {
	createMeetingLocal,
	createMeeting,
	listMeetings,
	clearMeeting,
	saveMeeting,
	listMeetingTemplates,
	listGroups,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingsListContainer);
