// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vBuXByDRLEcYYa44XRlW {\n\tdisplay: block;\n\t-o-object-fit: cover;\n\t   object-fit: cover;\n\twidth: auto;\n\theight: 75px;\n}\n", "",{"version":3,"sources":["webpack://./containers/advertising/company-adverts/CompanyAdvertsModal/companyadvertsmodal.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,oBAAiB;IAAjB,iBAAiB;CACjB,WAAW;CACX,YAAY;AACb","sourcesContent":[".img {\n\tdisplay: block;\n\tobject-fit: cover;\n\twidth: auto;\n\theight: 75px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": "vBuXByDRLEcYYa44XRlW"
};
export default ___CSS_LOADER_EXPORT___;
