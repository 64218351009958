import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import api from "../../modules/request.module.v2";
import { closeModal } from "../../actions/modals.actions";
import { fetchCompanyData } from "../../actions/syna.actions";
import { COMPANY_VERIFICATION_MODAL } from "../../constants/modals";
import { Padding } from "styled-components-spacing";
import ScrollView from "../../dumb-components/shared/layout/scroll-view/scroll-view";
import Button from "../../dumb-components/shared/button/button";
import Icon from "../../dumb-components/shared/icon/icon";
import Box from "../../dumb-components/shared/layout/box/box";
import RepresentativesTable from "../../components/user/account-wizard/representatives-table";
import StepsModal from "../../dumb-components/shared/modal/steps-modal";
import StepsBankID from "../../dumb-components/shared/bankid/steps-bankid";
import {
	companyChanged,
	fetchUserCompanies,
	signAndActivateCompany,
} from "../../actions/company.actions";
import Text from "../../dumb-components/shared/text/text";
import useBankId from "../../hooks/useBankId";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../actions/notify.actions";
import { fetchMenu } from "../../actions/menu.actions";
import { func } from "prop-types";

const CompanyVerifyModalContainer = ({
	isOpen,
	closeModal,
	fetchCompanyData,
	addInfoNotification,
	addErrorNotification,
	companyChanged,
	fetchUserCompanies,
	fetchMenu,
	company,
}) => {
	const [allowedRepresentativesToSign, setAllowedRepresentativesToSign] =
		useState([]);
	const [showLoader, setShowLoader] = useState(true);
	const [step, setStep] = useState(0);

	useEffect(() => {
		const fetchData = () => {
			fetchCompanyData(
				company.orgNumber,
				({ allowedRepresentativesToSign }) => {
					setAllowedRepresentativesToSign(allowedRepresentativesToSign);
					setShowLoader(false);
				},
			);
		};

		if (isOpen) {
			fetchData();
		}

		return () => {
			resetState();
		};
	}, [isOpen]);

	const { initBankIdAuth, bankIdInfo, resetBankId } = useBankId({
		onBankIdAuthComplete: async ({ orderRef }) => {
			api
				.post("/companies/verify/complete", {
					orderRef,
					orgNumber: company.orgNumber,
				})
				.then((response) => {
					const { company } = response.data;

					companyChanged(company);

					addInfoNotification({
						tid: "company.univerified.verify.notify.success",
					});

					fetchUserCompanies("me");
					fetchMenu("main");
				})
				.catch((error) => {
					addErrorNotification({ tid: error.response?.data?.translationId });
					resetState();
				});
		},
	});

	const resetState = () => {
		resetBankId();
		setStep(0);
	};

	const onAuthorise = () => {
		initBankIdAuth(
			api.post("/companies/verify/init", {
				orgNumber: company.orgNumber,
			}),
		);
		setStep(1);
	};

	const renderModalBodyStepOne = () => {
		if (showLoader) {
			return (
				<Box alignItems="center" valign="center" fullHeight>
					<Icon icon="faSpinner" size="xl" spin />
				</Box>
			);
		}

		return (
			<ScrollView fillContent noLeftMargin noRightMargin alwaysShow>
				<Padding horizontal={6}>
					<Padding vertical={5}>
						<Text tid="company_wizard.step.4.description" />
					</Padding>
					<RepresentativesTable
						companyRepresentatives={allowedRepresentativesToSign}
						onClickAuthorise={onAuthorise}
					/>
				</Padding>
			</ScrollView>
		);
	};

	const renderModalFooterStepOne = () => {
		return <Button tid="generic.close" onClick={closeModal} />;
	};

	const renderModalBodyStepTwo = () => {
		return <StepsBankID bankIdInfo={bankIdInfo} />;
	};

	const renderModalFooterStepTwo = () => {
		return <Button onClick={closeModal} tid="generic.form.cancel" />;
	};

	const getModalSteps = () => {
		return [
			{
				body: renderModalBodyStepOne(),
				footer: {
					component: renderModalFooterStepOne(),
				},
			},
			{
				body: renderModalBodyStepTwo(),
				footer: {
					component: renderModalFooterStepTwo(),
				},
			},
		];
	};

	return (
		<StepsModal
			step={step}
			steps={getModalSteps()}
			isOpen={isOpen}
			hSize="xl"
			title="company.univerified.verify.modal.title"
		/>
	);
};

const mapStoreToProps = (store) => {
	return {
		isOpen:
			store.modals.getIn(["activeModal", "name"]) ===
			COMPANY_VERIFICATION_MODAL,
		company: store.company.company,
	};
};

const mapDispatchToProps = {
	companyChanged,
	addInfoNotification,
	addErrorNotification,
	fetchUserCompanies,
	fetchMenu,
	closeModal,
	fetchCompanyData,
	signAndActivateCompany,
};

CompanyVerifyModalContainer.propTypes = {
	companyChanged: func.isRequired,
	addInfoNotification: func.isRequired,
	addErrorNotification: func.isRequired,
	fetchUserCompanies: func.isRequired,
	fetchMenu: func.isRequired,
	closeModal: func.isRequired,
	fetchCompanyData: func.isRequired,
};

export default connect(
	mapStoreToProps,
	mapDispatchToProps,
)(CompanyVerifyModalContainer);
