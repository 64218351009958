import React, { forwardRef } from "react";
import { IconButton as MuiIconButton, alpha, useTheme } from "@mui/material";
import PropTypes, { bool, string } from "prop-types";
import Icon from "../../dumb-components/shared/icon/icon";
import NotificationBadge from "../../dumb-components/shared/notification-badge/notification-badge";

const IconButton = forwardRef((props, ref) => {
	const {
		disabled,
		onClick,
		variant,
		color,
		hoverColor,
		delay,
		icon,
		iconColor,
		background,
		size,
		type,
		rotate,
		noBorder,
		noInlinePadding,
		notificationBadge,
		notificationBadgeColor,
		notificationBadgeAnimate,
		x,
		...other
	} = props;
	const theme = useTheme();

	const adjustedIconButtonProps = {
		disabled: disabled,
		component: disabled ? "div" : undefined,
		onClick: disabled ? undefined : onClick,
		variant: variant,
	};

	let colorOnHover;
	if (hoverColor) {
		colorOnHover = hoverColor;
	} else if (theme.palette[color] && typeof theme.palette[color] === "string") {
		colorOnHover = alpha(theme.palette[color], 0.75);
	} else if (theme.palette[color] && theme.palette[color].main) {
		colorOnHover = alpha(theme.palette[color].main, 0.75);
	} else {
		colorOnHover = "#d9d9d9";
	}

	const notificationBadgeProps = {
		active: notificationBadge,
		animated: notificationBadgeAnimate,
		color: notificationBadgeColor,
	};

	return (
		<MuiIconButton
			sx={{
				borderWidth: noBorder ? "0px" : "1px",
				borderStyle: "solid",
				borderRadius: "0",
				width: noInlinePadding ? "auto" : "36px",
				height: "36px",
				color: color,
				transition: delay && "300ms color, 300ms border",
				paddingInline: noInlinePadding ? "0" : "8px",

				"& span": {
					color: iconColor || "inherit",
				},

				"&:hover": {
					color: colorOnHover,
					backgroundColor: background || "transparent",
				},

				"&.Mui-disabled": {
					pointerEvents: "auto",
					color: "#a5a5a5",
					border: noBorder ? "none" : "1px solid #a5a5a5 !important",
				},

				"&.Mui-disabled *": {
					opacity: 1,
				},
			}}
			ref={ref}
			{...other}
			{...adjustedIconButtonProps}
		>
			<NotificationBadge {...notificationBadgeProps} x={x ? x : 15}>
				<Icon
					icon={icon}
					faded={disabled}
					color={color}
					size={size}
					type={type}
					rotate={rotate}
				/>
			</NotificationBadge>
		</MuiIconButton>
	);
});

IconButton.propTypes = {
	color: PropTypes.string,
	noInlinePadding: bool,
	...MuiIconButton.propTypes,
	notificationBadge: bool,
	notificationBadgeColor: string,
	notificationBadgeAnimate: bool,
};

IconButton.defaultProps = {
	color: "primary",
	...MuiIconButton.defaultProps,
	notificationBadge: false,
	notificationBadgeAnimate: true,
};

IconButton.displayName = "IconButton";

export default IconButton;
