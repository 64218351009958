import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import { closeModal } from "../../../actions/modals.actions";
import MUIDialog from "../../../mui-components/dialog/mui-dialog";
import { ReadMoreModalContent } from "../../shared/ReadMoreModalContent";

function ReadMoreModal({ title, contentFile }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const onClose = () => {
		dispatch(closeModal());
	};

	return (
		<MUIDialog
			isOpen={true}
			onClose={onClose}
			title={t(title)}
			confirmButton={false}
			closeButtonTid="generic.form.close"
			maxWidth="lg"
			fullWidth
		>
			<ReadMoreModalContent contentFile={contentFile} />
		</MUIDialog>
	);
}

ReadMoreModal.propTypes = {
	title: string,
	contentFile: string.isRequired,
};

export { ReadMoreModal };
