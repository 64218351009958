import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Trans, useTranslation } from "react-i18next";

import PrepaidPanel from "../../dumb-components/subscriptions/prepaid-panel/prepaid-panel";

import {
	PRODUCT_ACCOUNT_PLAN_FREE_ID,
	SUBSCRIPTION_ESIGN_BANKID_COST,
} from "/shared/constants";

import useDefaultPaymentMethod from "@/hooks/useDefaultPaymentMethod";
import useSubscriptionHelper from "@/hooks/useSubscriptionHelper";
import useCompanyIdFromUrl from "@/hooks/useCompanyIdFromUrl";
import useSubscriptionRefillPrepaid from "@/hooks/useSubscriptionRefillPrepaid";
import useUpdateSubscription from "@/hooks/useUpdateSubscription";
import useDialogModal from "@/hooks/useDialogModal";
import { CreditCard } from "lucide-react";

const PrepaidContainer = ({
	subscription,
	onChangeAutorefill,
	companyDueForCancellation,
}) => {
	const { t } = useTranslation();
	const { alert, confirm, dialogElement } = useDialogModal();
	const companyId = useCompanyIdFromUrl();

	const { mutateAsync: refillPrepaid, isPending: isRefillingPrepaid } =
		useSubscriptionRefillPrepaid(companyId);

	const { mutateAsync: updateSubscription } = useUpdateSubscription(companyId);

	const {
		data: defaultPaymentMethod,
		isLoading: isLoadingDefaultPaymentMethod,
	} = useDefaultPaymentMethod();

	const { data: subscriptionHelperRequest } = useSubscriptionHelper();

	const subscriptionHelper = subscriptionHelperRequest?.data;

	const hasValidPaymentMethod = Boolean(
		!isLoadingDefaultPaymentMethod && defaultPaymentMethod,
	);

	const onChangeSubscription = async (field, value) => {
		const subscriptionChanges = {
			prepaidAutoRefill: subscription.prepaidAutoRefill,
			prepaidSelectedAmount: subscription.prepaidSelectedAmount,
			[field]: value,
		};

		// Turn off auto refill if amount is 0
		if (field === "prepaidSelectedAmount" && value <= 0) {
			subscriptionChanges.prepaidAutoRefill = false;
		}

		await updateSubscription(subscriptionChanges);
	};

	const onClickPurchase = async () => {
		if (
			await confirm(
				t("subscriptions.confirm_purchase.title"),
				t("subscriptions.confirm_purchase.question"),
				{
					submitText: (
						<>
							<CreditCard size={16} />
							{t("subscriptions.purchase")}
						</>
					),
				},
			)
		) {
			const prepaidResponse = await refillPrepaid();

			await alert(
				t("subscriptions.prepaid_esigning.purchase_confirmation.title"),
				<Trans
					i18nKey="subscriptions.prepaid_esigning.purchase_confirmation.message"
					values={{ prepaidAmount: prepaidResponse?.data?.prepaidAmount }}
				/>,
				{ variant: "success" },
			);
		}
	};

	const subscribingToFreeAccount =
		!subscription.accountPlanId ||
		subscription.accountPlanId === PRODUCT_ACCOUNT_PLAN_FREE_ID;

	const prepaidAmount = subscription.prepaidAmount ?? 0;
	const prepaidSelectedAmount = subscription.prepaidSelectedAmount;
	const prepaidAutoRefill = subscription.prepaidAutoRefill;
	const canPurchase =
		prepaidSelectedAmount > 0 &&
		hasValidPaymentMethod > 0 &&
		subscriptionHelper?.eSigningEnabled;

	const warning =
		!hasValidPaymentMethod && !companyDueForCancellation
			? t("subscriptions.warning.complete_card_and_address_details")
			: null;

	const isFreeOfCharge = subscription?.isFreeOfCharge;

	if (subscribingToFreeAccount) {
		return null;
	}

	return (
		<>
			<PrepaidPanel
				prepaidAmount={prepaidAmount}
				prepaidSelectedAmount={prepaidSelectedAmount}
				prepaidAutoRefill={prepaidAutoRefill}
				onChangeSubscription={onChangeSubscription}
				isLoading={isRefillingPrepaid}
				onClickPurchase={onClickPurchase}
				isFreeOfCharge={isFreeOfCharge}
				warning={warning}
				canPurchase={canPurchase}
				disabled={isFreeOfCharge || companyDueForCancellation}
				eSigningEnabled={subscriptionHelper?.eSigningEnabled}
				onChangeAutorefill={onChangeAutorefill}
				pricePerBankIdSigning={SUBSCRIPTION_ESIGN_BANKID_COST}
			/>
			{dialogElement}
		</>
	);
};

const mapStoreToProps = (store) => {
	return {
		companyDueForCancellation:
			store.company.company && store.company.company.toBeCanceled,
	};
};

export default injectIntl(connect(mapStoreToProps)(PrepaidContainer));
