import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import Icon from "../../shared/icon/icon";
import { useTheme } from "@mui/material";
import { formatOrgPretty } from "/shared/helpers/helpers";
import { InvestmentsContext } from "../../../containers/investments/investment.container";

const StyledButton = styled.div`
	cursor: pointer;
`;

const StyledEditorWrapper = styled.div`
	display: flex;
	align-items: center;

	.inline-editor-button-group {
		padding: ${(props) => props.theme.spacing[4]}
			${(props) => props.theme.spacing[4]};
	}
`;

export default function CompanyPanel({
	companyName,
	orgNumber,
	street,
	city,
	postalCode,
	onCancelEditingCompanyName,
}) {
	const [editing, setEditing] = useState(false);
	const [value, setValue] = useState(companyName);
	const theme = useTheme();
	const { inDealflow } = useContext(InvestmentsContext);

	const startEditing = () => {
		setEditing(true);
	};

	const changeValue = (event) => {
		setValue(event.target.value);
	};

	const cancelEditing = (params) => {
		const storedValue = value;
		setEditing(false);
		onCancelEditingCompanyName?.(storedValue, params);
	};

	const keyPress = (event) => {
		if (event.keyCode === 27) {
			cancelEditing({ reason: "abort" });
			return;
		}

		if (event.keyCode === 13) {
			changeValue(event);
			cancelEditing({ reason: "cancel" });
			return;
		}
	};

	street = street ? street : "";
	postalCode = postalCode ? postalCode : "";
	city = city ? city : "";

	const getAddress = () => {
		let address = "";

		if (street) {
			address += street;
		}

		if (postalCode) {
			if (address) {
				address += ", ";
			}
			address += postalCode;
		}

		if (city) {
			if (address) {
				address += ", ";
			}
			address += city;
		}
		return address;
	};

	return (
		<Card borderradius={"true"}>
			<CardContent>
				{/* 	<InlineEditor
					ContentComponent={Typography}
					contentComponentProps={{ variant: 'h4' }}
					initialValue={companyName}
					tooltipTid='investments.alias.tooltip.default'
					onCancelEditing={onCancelEditingCompanyName}
					onReset={onResetCompanyName}
				/> */}

				{companyName && (
					<Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
						{!editing ? (
							<>
								<Typography variant="h4">{value}</Typography>
								{!inDealflow && (
									<StyledButton onClick={startEditing}>
										<Icon
											icon="faPenAlt"
											size="xs"
											color={theme.palette.positive.main}
										/>
									</StyledButton>
								)}
							</>
						) : (
							<StyledEditorWrapper>
								<TextField
									value={value}
									onKeyDown={keyPress}
									onChange={changeValue}
									onBlur={() => cancelEditing({ reason: "cancel" })}
									variant="standard"
									autoFocus
									inputProps={{ maxLength: 50 }}
								/>
							</StyledEditorWrapper>
						)}
					</Box>
				)}
				{orgNumber && (
					<Typography variant="body2">
						<FormattedMessage id="investments.general.company_information.organization_number" />
						{formatOrgPretty(orgNumber)}
					</Typography>
				)}

				{getAddress() ? (
					<Typography variant="body2">
						<FormattedMessage id="investments.general.company_information.address" />
						{getAddress()}
					</Typography>
				) : null}
			</CardContent>
		</Card>
	);
}
