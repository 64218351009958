import React from "react";
import Grid from "@mui/material/Grid";

export default function InvestmentBuySellContainer() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}></Grid>
		</Grid>
	);
}
