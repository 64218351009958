import React, { Component } from "react";
import { connect } from "react-redux";
import { List, Map } from "immutable";
import { bool, func } from "prop-types";
import uuid from "uuid";
import { FILTER_FIELDS } from "../../constants/tasks";
import moment from "../../modules/moment.module";
import {
	setFilterBy,
	unsetFilterBy,
	updateDefaultFilter,
	resetDefaultFiltervalues,
} from "../../actions/tasks.actions";
import { updateUser, saveUser } from "../../actions/user.actions";
import ObjectFilterContainer from "../shared/object-filter.container";
import CompanyMembersSelectContainer from "../company/company-members-select.container";
import AssigneeContainer from "../shared/assignee.container";
import { FACTORY_DEFAULT_FILTER_VALUES } from "../../constants/tasks";

class TasksAdvancedFilterContainer extends Component {
	static propTypes = {
		isOpen: bool,
		onClose: func,
	};

	state = {
		fields: null,
	};

	componentDidMount = () => {
		this.parseFields(FILTER_FIELDS, null);
	};

	componentDidUpdate = (prevProps) => {
		if (this.props.tasks !== prevProps.tasks) {
			this.parseFields(this.state.fields, this.props.tasks);
		}

		if (prevProps.filterBy !== this.props.filterBy) {
			this.parseFields(this.state.fields, this.props.tasks);
		}
	};

	getRowIndex = (fields, fieldName) => {
		return fields.findIndex((row) => {
			let found = false;

			row.forEach((obj) => {
				if (obj.get("fieldName") === fieldName) {
					found = true;
				}
			});

			return found;
		});
	};

	getFieldIndex = (fields, rowIndex, fieldName) => {
		return fields
			.get(rowIndex)
			.findIndex((obj) => obj.get("fieldName") === fieldName);
	};

	parseFields = (fields, tasksList) => {
		if (tasksList && tasksList.size > 0) {
			const dateRowIndex = this.getRowIndex(fields, "date");
			const dateFieldIndex = this.getFieldIndex(fields, dateRowIndex, "date");
			let minDate;
			let maxDate;

			tasksList.forEach((task) => {
				const dueAt = task.get("dueAt");

				if (
					(!minDate && dueAt) ||
					(minDate && dueAt && moment(dueAt).isBefore(minDate))
				) {
					minDate = dueAt;
				}

				if (
					(!maxDate && dueAt) ||
					(maxDate && dueAt && moment(dueAt).isAfter(maxDate))
				) {
					maxDate = dueAt;
				}
			});

			fields = fields.setIn([dateRowIndex, dateFieldIndex, "minDate"], minDate);
			fields = fields.setIn([dateRowIndex, dateFieldIndex, "maxDate"], maxDate);
		}

		// Order field
		const orderRowIndex = this.getRowIndex(fields, "order");
		const orderFieldIndex = this.getFieldIndex(fields, orderRowIndex, "order");

		const datePropfilter = this.props.filterBy.find(
			(item) => item.get("source") === "dateProp",
		);
		let orderField = fields.getIn([orderRowIndex, orderFieldIndex]);

		if (datePropfilter.getIn(["values", 0]) !== "orderIndex") {
			orderField = orderField.setIn(["options", 0, "isDisabled"], false);
			orderField = orderField.setIn(["options", 1, "isDisabled"], false);
			orderField = orderField.setIn(["options", 2, "isDisabled"], false);
			orderField = orderField.setIn(["options", 3, "isDisabled"], true);
		} else {
			orderField = orderField.setIn(["options", 0, "isDisabled"], true);
			orderField = orderField.setIn(["options", 1, "isDisabled"], false);
			orderField = orderField.setIn(["options", 2, "isDisabled"], true);
			orderField = orderField.setIn(["options", 3, "isDisabled"], false);
		}

		fields = fields.setIn([orderRowIndex, orderFieldIndex], orderField);

		// Date range field
		const dateIntervalRowIndex = this.getRowIndex(fields, "date");
		const dateIntervalFieldIndex = this.getFieldIndex(
			fields,
			dateIntervalRowIndex,
			"date",
		);
		let dateIntervalField = fields.getIn([
			dateIntervalRowIndex,
			dateIntervalFieldIndex,
		]);

		if (datePropfilter && datePropfilter.get("values").includes("orderIndex")) {
			dateIntervalField = dateIntervalField.set("isDisabled", true);
		} else {
			dateIntervalField = dateIntervalField.set("isDisabled", false);
		}

		fields = fields.setIn(
			[dateIntervalRowIndex, dateIntervalFieldIndex],
			dateIntervalField,
		);

		// Created By field
		const createdByRowIndex = this.getRowIndex(fields, "createdBy");
		const createdByFieldIndex = this.getFieldIndex(
			fields,
			createdByRowIndex,
			"createdBy",
		);
		let createdByField = fields.getIn([createdByRowIndex, createdByFieldIndex]);

		createdByField = createdByField.set(
			"renderFn",
			this.renderCompanyMembersSelect,
		);
		fields = fields.setIn(
			[createdByRowIndex, createdByFieldIndex],
			createdByField,
		);

		// Assignee field
		const assigneeRowIndex = this.getRowIndex(fields, "assigne");
		const assigneeFieldIndex = this.getFieldIndex(
			fields,
			assigneeRowIndex,
			"assigne",
		);
		let assigneeField = fields.getIn([assigneeRowIndex, assigneeFieldIndex]);

		assigneeField = assigneeField.set("renderFn", this.renderAssigneeContainer);
		fields = fields.setIn(
			[assigneeRowIndex, assigneeFieldIndex],
			assigneeField,
		);

		this.setState({ fields });
	};

	onChange = (fieldName, val) => {
		const { setFilterBy } = this.props;
		let valueIsEmpty = !val && fieldName !== "showOnlyTrashed";

		if (fieldName === "date") {
			val = val.filter((value) => {
				return value !== null;
			});

			valueIsEmpty = val.size === 0;
		}

		if (fieldName === "dateProp" && val !== "orderIndex") {
			this.onChange("order", "datePropDesc");
		} else if (fieldName === "dateProp" && val === "orderIndex") {
			this.onChange("order", "orderIndex");
		}

		setFilterBy(
			Map({
				source: fieldName,
				values: !valueIsEmpty ? List([val]) : null,
			}),
		);
	};

	onClose = () => {
		const { onClose } = this.props;

		onClose && onClose();
	};

	onClearAll = () => {
		const { unsetFilterBy } = this.props;
		const { fields } = this.state;
		let sources = List();

		fields.forEach((row) => {
			row.forEach((field) => {
				sources = sources.push(field.get("fieldName"));
			});
		});

		unsetFilterBy(sources);
	};

	onSetDefaultValue = (fieldName, val) => {
		const { updateUser, saveUser, updateDefaultFilter, company } = this.props;
		let { userObj } = this.props;
		const id = `${company.id}-tasks$${fieldName}`;

		userObj = userObj.setIn(["siteSettings", "pinedFilters", id], val);
		updateUser(userObj);
		saveUser(userObj.get("id"), userObj.toJS());
		updateDefaultFilter(fieldName, List([val]));
	};

	resetDefaultValues = () => {
		const { updateUser, saveUser, resetDefaultFiltervalues, company } =
			this.props;
		let { userObj } = this.props;

		FACTORY_DEFAULT_FILTER_VALUES.forEach((val, key) => {
			const id = `${company.id}-tasks$${key}`;

			if (userObj.hasIn(["siteSettings", "pinedFilters", id])) {
				userObj = userObj.setIn(
					["siteSettings", "pinedFilters", id],
					val.get(0),
				);
			}
		});

		updateUser(userObj);
		saveUser(userObj.get("id"), userObj.toJS());
		resetDefaultFiltervalues();
	};

	renderAssigneeContainer = (
		fieldName,
		pinable,
		placeholder,
		value,
		defaultValue,
		onChange,
		field,
	) => {
		const { projects, selectedProjectId } = this.props;
		const project = projects.get(selectedProjectId);
		const prependOptions = List([
			Map({
				value: "show_all",
				label: "tasks.filter.assigne.show_all",
			}),
		]);

		if (project) {
			return (
				<AssigneeContainer
					fieldName={fieldName}
					onChange={onChange}
					value={value}
					isClearable={field.get("isClearable")}
					permissions={
						project && project.get("permissions")
							? project.get("permissions")
							: Map()
					}
					isRoot={!project || !project.get("permissions")}
					includeNoneOption={field.get("includeNoneOption")}
					noneOptionValue={field.get("noneOptionValue")}
					noneOptionLabelTid={field.get("noneOptionLabelTid")}
					prependOptions={prependOptions}
				/>
			);
		} else {
			return this.renderCompanyMembersSelect(
				fieldName,
				pinable,
				placeholder,
				value,
				defaultValue,
				onChange,
				field,
				prependOptions,
			);
		}
	};

	renderCompanyMembersSelect = (
		fieldName,
		pinable,
		placeholder,
		value,
		defaultValue,
		onChange,
		field,
		prependOptions,
	) => {
		const { defaultFilters } = this.props;

		return (
			<CompanyMembersSelectContainer
				fieldName={fieldName}
				onChange={onChange}
				value={value}
				placeholderTid={placeholder}
				isClearable={field.get("isClearable")}
				includeNoneOption={field.get("includeNoneOption")}
				noneOptionValue={field.get("noneOptionValue")}
				noneOptionLabelTid={field.get("noneOptionLabelTid")}
				prependOptions={prependOptions}
				defaultValue={defaultFilters.getIn([fieldName, 0])}
			/>
		);
	};

	render = () => {
		const { isOpen, company, filterBy, defaultFilters } = this.props;
		const { fields } = this.state;
		const id = `${company.id}-tasks`;

		return (
			<ObjectFilterContainer
				key={uuid()}
				id={id}
				fields={fields}
				values={filterBy}
				defaultValues={defaultFilters}
				title="tasks.tasks_filter"
				isOpen={isOpen}
				onChange={this.onChange}
				onClose={this.onClose}
				onClearAll={this.onClearAll}
				onSetDefaultValue={this.onSetDefaultValue}
				onResetDefaultValues={this.resetDefaultValues}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		tasks: store.tasks.get("list"),
		projects: store.projects.get("projects"),
		selectedProjectId: store.projects.get("selectedProjectId"),
		filterBy: store.tasks.get("filterBy"),
		defaultFilters: store.tasks.get("defaultFilters"),
		company: store.company.company,
		companyMembers: store.company.members,
		i18n: store.i18n,
		userObj: store.user.get("userObj"),
	};
};

const mapActionsToProps = {
	setFilterBy,
	unsetFilterBy,
	updateUser,
	saveUser,
	updateDefaultFilter,
	resetDefaultFiltervalues,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(TasksAdvancedFilterContainer);
