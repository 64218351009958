import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import { string, func } from "prop-types";
import DropdownMenuContainer from "../shared/dropdown-menu.container";
import DropdownIconItem from "../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import DropdownItem from "../../dumb-components/shared/dropdown-item/dropdown-item";
import { ButtonTransparentIcon } from "../../dumb-components/shared/button";
import Tooltip from "../../dumb-components/shared/tooltip/tooltip";

import DocumentRequestSignButton from "./toolbar-items/document-request-sign-button";
import OpenOrDownloadComponent from "./generic/open-or-download-file-component.container";

import MoveFileItemContainer from "./generic/dropdown-items/move-file-item.container";
import DeleteFileItem from "./generic/dropdown-items/delete-file-item.container";
import AddToCollectionComponent from "./generic/add-document-to-collection-component.container";
import RecoverFileDropDownItem from "./generic/dropdown-items/recover-file.container";
import { openModal } from "../../actions/modals.actions";
import { CopyFileToPersonalDocuments } from "./generic/dropdown-items/copy-file-to-personal-documents";

class ToolbarContainer extends Component {
	static propTypes = {
		basePath: string,
		querystr: string,
		onDeleteDocument: func,
	};

	closeMoreActionsDropdown = () => {
		this.moreActionBtnRef && this.moreActionBtnRef.onToggleMenu();
	};

	renderWebViewer = () => {
		const { document } = this.props;

		return (
			<OpenOrDownloadComponent document={document} mode="OPEN">
				{({ TOOLTIP_STATES, activeState, isDisabled, openOrDownload }) => (
					<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
						<ButtonTransparentIcon
							onClick={openOrDownload}
							icon="faExpandArrowsAlt"
							size="sml"
							disabled={isDisabled}
						/>
					</Tooltip>
				)}
			</OpenOrDownloadComponent>
		);
	};

	renderDownload = () => {
		const { document } = this.props;

		return (
			<OpenOrDownloadComponent document={document} mode="DOWNLOAD">
				{({ TOOLTIP_STATES, activeState, isDisabled, openOrDownload }) => (
					<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
						<ButtonTransparentIcon
							onClick={openOrDownload}
							icon="faArrowAltToBottom"
							size="sml"
							disabled={isDisabled}
						/>
					</Tooltip>
				)}
			</OpenOrDownloadComponent>
		);
	};

	renderMoreActions = () => {
		const { document, basePath, querystr } = this.props;
		const isDeleted = document.get("isDeleted");

		return (
			<DropdownMenuContainer
				btnIcon="faEllipsisH"
				halignMenu="right"
				btnMode="transparent-icon"
				noMaxWidth={true}
				ref={(ref) => (this.moreActionBtnRef = ref)}
			>
				<AddToCollectionComponent document={document}>
					{({ TOOLTIP_STATES, activeState, isDisabled, addToCollection }) => (
						<Tooltip
							states={TOOLTIP_STATES}
							activeState={activeState}
							active={isDisabled}
						>
							<DropdownIconItem
								tid="documents.list.dropdown.item.add_to_collection"
								icon="faReceipt"
								disabled={isDisabled}
								onClick={() => {
									this.closeMoreActionsDropdown();
									addToCollection();
								}}
							/>
						</Tooltip>
					)}
				</AddToCollectionComponent>

				<CopyFileToPersonalDocuments
					document={document}
					onClick={this.closeMoreActionsDropdown}
				/>

				<MoveFileItemContainer
					document={document}
					onClick={this.closeMoreActionsDropdown}
				/>

				{!isDeleted && <DropdownItem divider />}

				<DeleteFileItem
					document={document}
					basePath={basePath}
					querystr={querystr}
					onClick={this.closeMoreActionsDropdown}
				/>

				<RecoverFileDropDownItem
					document={document}
					basePath={basePath}
					querystr={querystr}
					onClick={this.closeMoreActionsDropdown}
				/>
			</DropdownMenuContainer>
		);
	};

	render = () => {
		const { params, document } = this.props;

		if (!params.id) {
			return <div />;
		}

		if (document.get("isMeeting")) {
			return <div />;
		}

		const isDeleted = document.get("isDeleted");
		return (
			<div className="flex h-16 justify-end">
				<DocumentRequestSignButton mode="toolbar" disabled={isDeleted} />
				{this.renderWebViewer()}
				{this.renderDownload()}
				{this.renderMoreActions()}
			</div>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		document: store.documents.get("document", Map()) || Map(),
	};
};

const mapActionsToProps = {
	openModal,
};

export default connect(mapStoreToProps, mapActionsToProps)(ToolbarContainer);
