import React, { PureComponent } from "react";
import { string } from "prop-types";
import styled from "styled-components";

const StyledSquare = styled.div`
	display: flex;
	align-self: center;
	background: ${(props) => props.theme.colors[props.bgColor]};
	width: ${(props) => props.size};
	height: ${(props) => props.size};
`;

export default class Square extends PureComponent {
	static propTypes = {
		bgColor: string,
		size: string,
	};

	static defaultProps = {
		bgColor: "java",
		size: "10px",
	};

	render = () => {
		const { bgColor, size } = this.props;

		return <StyledSquare bgColor={bgColor} size={size} />;
	};
}
