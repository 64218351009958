import React, { PureComponent } from "react";
import { func } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import styled, { css } from "styled-components";
import Text from "../../../shared/text/text";
import ShowMoreBox from "../../../../components/framework/show-more-box";

const StyledTable = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const StyledRow = styled.div`
	display: flex;
`;

const StyledName = styled.div`
	min-width: 20%;
	padding-right: ${(props) => props.theme.spacing[3]};
	padding-bottom: ${(props) => props.theme.spacing[3]};

	${(props) =>
		props.noPaddingBottom &&
		css`
			padding-bottom: 0;
		`}
`;

const StyledRole = styled.div`
	display: flex;
	flex: 1;
	padding-right: ${(props) => props.theme.spacing[3]};
	padding-bottom: ${(props) => props.theme.spacing[3]};

	${(props) =>
		props.noPaddingBottom &&
		css`
			padding-bottom: 0;
		`}
`;

export default class AttendeesTable extends PureComponent {
	static propTypes = {
		attendees: immutablePropTypes.map,
		renderRoleDisplay: func,
	};

	renderAttendee = (attendee, index) => {
		const { attendees, renderRoleDisplay } = this.props;
		const noPaddingBottom = index + 1 === attendees.size;
		const roleDisplayProps = {
			attendeeId: attendee.get("userId"),
			roles: attendee.get("roles"),
		};

		return (
			<StyledRow key={index}>
				<StyledName noPaddingBottom={noPaddingBottom}>
					<Text>{attendee.get("name")}</Text>
				</StyledName>
				<StyledRole noPaddingBottom={noPaddingBottom}>
					<Text color="lightGrey">{renderRoleDisplay(roleDisplayProps)}</Text>
				</StyledRole>
			</StyledRow>
		);
	};

	render = () => {
		const { attendees } = this.props;

		if (!attendees || attendees.size === 0) {
			return null;
		}

		return (
			<StyledTable>
				{attendees.size > 5 && (
					<ShowMoreBox closedFixedHeight={132}>
						{attendees.toList().map(this.renderAttendee)}
					</ShowMoreBox>
				)}
				{attendees.size <= 5 && attendees.toList().map(this.renderAttendee)}
			</StyledTable>
		);
	};
}
