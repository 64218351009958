import React from "react";
import { func, string, bool, number } from "prop-types";
import ImmutableProps from "react-immutable-proptypes";
import styled from "styled-components";

// Subcomponents
import { MultiSelect } from "../select";
import Icon from "../icon/icon";
import Text from "../text/text";
import { Margin } from "styled-components-spacing";

const StyledFilterByWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;

	> div:nth-child(1) {
		margin-right: -1px;
	}
`;

const StyledCounterWrapper = styled.div`
	margin-right: ${(props) => props.theme.spacing[3]};
`;

const FilterBy = (props) => {
	const {
		dropdownRenderer,
		options,
		value,
		selectedFilterByItem,
		optionsLabel,
		optionsValue,
		onChange,
		closeMenuOnSelect,
		counterLeftValue,
		counterRightValue,
		selectRef,
	} = props;

	const renderCounter = () => {
		return (
			<StyledCounterWrapper>
				<Text color="lightGrey">
					{counterLeftValue} / {counterRightValue}
				</Text>
			</StyledCounterWrapper>
		);
	};

	const renderPlaceholder = () => {
		return (
			<span>
				<Margin horizontal={2} inline>
					<Icon icon="faSearch" size="sm" color="placeholder" />
				</Margin>
				<Text
					tid="component.filterby.placeholder.filter_by"
					color="placeholder"
				/>
				&nbsp;
				<Text
					tid={`component.filterby.item.${selectedFilterByItem}`}
					color="placeholder"
					bold={600}
				/>
			</span>
		);
	};

	const formatOptionLabel = (option) => {
		const parts = option.label && option.label.split("|");
		if (!(parts && parts[1])) {
			return option.label;
		} else {
			return (
				<Text>
					<Text>{parts[0]}</Text>
					<Text color="muted">|{parts[1]}</Text>
				</Text>
			);
		}
	};

	return (
		<StyledFilterByWrapper>
			{dropdownRenderer && dropdownRenderer()}
			<MultiSelect
				placeholderRenderer={renderPlaceholder}
				optionsLabel={optionsLabel}
				optionsValue={optionsValue}
				options={options}
				formatOptionLabel={formatOptionLabel}
				value={value}
				onChange={onChange}
				hideDropdownIndicator={true}
				openMenuOnClick={false}
				closeMenuOnSelect={closeMenuOnSelect}
				filterType="word-match"
				clearAlternativeRenderer={renderCounter}
				dropdownIndicatorAlternativeRenderer={
					!value || (value && value.size === 0) ? renderCounter : undefined
				}
				selectRef={selectRef}
				firstDummyLabelTid={`component.filterby.item.${selectedFilterByItem}`}
			/>
		</StyledFilterByWrapper>
	);
};

FilterBy.defaultProps = {
	optionsLabel: "label",
	optionsValue: "value",
};

FilterBy.propTypes = {
	dropdownRenderer: func,
	onChange: func,
	options: ImmutableProps.list,
	value: ImmutableProps.list,
	selectedFilterByItem: string,
	closeMenuOnSelect: bool,
	counterLeftValue: number,
	counterRightValue: number,
	selectRef: func,
	firstDummyLabelTid: string,
};

export default FilterBy;
