import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { string, func, bool } from "prop-types";
import { Map, List } from "immutable";
import { map } from "react-immutable-proptypes";
import FolderShareControls from "../../dumb-components/documents/folder-share-controls/folder-share-controls";
import SynaSearchContainer from "../syna/search.container";
import { getCompanyIdFromOrgNr } from "../../actions/company.actions";
import FolderShareList from "../../dumb-components/documents/folder-share-list-companies/folder-share-list-companies";
import GoToBilling from "../shared/go-to-billing-component.container";
import Text from "../../dumb-components/shared/text/text";
import permissionsHelper from "../../components/helpers/permissions.helper";
import {
	SHARING_PERMISSION_READ_ONLY,
	SHARING_PERMISSION_OWN_DOCUMENTS_ACCESS,
} from "/shared/constants";
import useSubscriptionHelper from "@/hooks/useSubscriptionHelper";

const ShareFolderControlsContainer = ({
	onChange,
	sharedWithCompanies: initialSharedWithCompanies,
	folderId,
	parentFolderId,
	listOnly,
	folder,
	folders,
	mapParentToChildren,
	region,
	getCompanyIdFromOrgNr,
	companiesAccessRights,
}) => {
	const { data: subscriptionsHelperQuery } = useSubscriptionHelper();
	const subscriptionsHelper = subscriptionsHelperQuery?.data;
	const folderSharingEnabled = subscriptionsHelper?.folderSharingEnabled;

	const [searchedCompanyData, setSearchedCompanyData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [sharedWithCompanies, setSharedWithCompanies] = useState(
		Map(initialSharedWithCompanies),
	);

	const synaSearchRef = useRef(null);

	useEffect(() => {
		parseSharedWithCompanies();
	}, []);

	useEffect(() => {
		if (folders && folderId !== undefined) {
			parseSharedWithCompanies();
		}
	}, [folders, folderId]);

	useEffect(() => {
		parseSharedWithCompanies();
	}, [folder]);

	const parseSharedWithCompanies = () => {
		let updatedSharedWithCompanies = Map();

		const parent =
			parentFolderId ||
			mapParentToChildren.findKey((children) => children.includes(folderId));

		let currentParent = parent;
		while (currentParent) {
			const currentFolder = folders.find(
				(folder) => folder.get("id") === currentParent,
			);
			if (currentFolder.has("sharedWithCompanies")) {
				currentFolder.get("sharedWithCompanies").forEach((data, companyId) => {
					data = data.set(
						"parentFolderName",
						currentFolder.getIn(["name", region.language.main]),
					);
					data = data.set("disabled", true);
					updatedSharedWithCompanies = updatedSharedWithCompanies.set(
						companyId,
						data,
					);
				});
			}

			currentParent = mapParentToChildren.findKey((children) =>
				children.includes(currentParent),
			);
		}

		setSharedWithCompanies(updatedSharedWithCompanies);
	};

	const onChangeSynaSearch = (data) => {
		const companyOrgNumber = data && data.getIn(["company", "orgNumber"]);

		if (!companyOrgNumber) {
			return;
		}

		setIsLoading(true);

		getCompanyIdFromOrgNr(companyOrgNumber, (companyFromDatabase) => {
			const foundCompanyId = companyFromDatabase.get("id");

			if (!foundCompanyId) {
				setSearchedCompanyData(data.get("company"));
				setIsLoading(false);
			} else {
				shareWithCompany(companyFromDatabase);
				setSearchedCompanyData(null);
				setIsLoading(false);
			}
		});
	};

	const shareWithCompany = (company) => {
		const companyId = company.get("id");

		onChange(
			"sharedWithCompanies",
			sharedWithCompanies.set(
				companyId,
				Map({
					name: company.get("name"),
					sharingPermissions: SHARING_PERMISSION_READ_ONLY,
				}),
			),
		);
	};

	const handleChange = (companyId, field, val) => {
		let updatedSharedWithCompanies = sharedWithCompanies.setIn(
			[companyId, field],
			val,
		);
		setSharedWithCompanies(updatedSharedWithCompanies);
		onChange("sharedWithCompanies", updatedSharedWithCompanies);
	};

	const unshareCompany = (companyId) => {
		let updatedSharedWithCompanies = sharedWithCompanies.delete(companyId);
		setSharedWithCompanies(updatedSharedWithCompanies);
		onChange("sharedWithCompanies", updatedSharedWithCompanies);
	};

	const renderSynaSearch = () => {
		const isAdmin = permissionsHelper.getIsAdminForSelectedCompany(
			companiesAccessRights,
		);

		return (
			<SynaSearchContainer
				onChange={onChangeSynaSearch}
				onSetRef={(r) => (synaSearchRef.current = r)}
				isLoading={isLoading}
				disabled={!isAdmin || !folderSharingEnabled}
				withPortal
			/>
		);
	};

	const renderSubscriptionLinkComponent = () => {
		return (
			<GoToBilling
				renderComponent={(goToSubscription) => {
					return (
						<>
							<br />
							<Text
								tid="documents.folder.create_manage.sharing.alert.upgrade_link"
								onClick={goToSubscription}
								hasUnderline
							/>
						</>
					);
				}}
			/>
		);
	};

	return (
		<>
			{listOnly ? (
				<FolderShareList
					companyData={searchedCompanyData}
					sharedWithCompanies={sharedWithCompanies}
					onUnshareCompany={unshareCompany}
					readOnly={
						!permissionsHelper.getIsAdminForSelectedCompany(
							companiesAccessRights,
						)
					}
					permissionsOptions={List([
						Map({
							value: SHARING_PERMISSION_READ_ONLY,
							label: "folder_sharing.permission.read_only",
						}),
						Map({
							value: SHARING_PERMISSION_OWN_DOCUMENTS_ACCESS,
							label: "folder_sharing.permission.own_documents_access",
						}),
					])}
					onChange={handleChange}
				/>
			) : (
				<FolderShareControls
					companyData={searchedCompanyData}
					sharedWithCompanies={sharedWithCompanies}
					synaSearchComponent={renderSynaSearch()}
					showSubscriptionMessage={!folderSharingEnabled}
					subscriptionLinkComponent={renderSubscriptionLinkComponent()}
				/>
			)}
		</>
	);
};

ShareFolderControlsContainer.propTypes = {
	onChange: func,
	sharedWithCompanies: map,
	folderId: string,
	parentFolderId: string,
	listOnly: bool,
};

ShareFolderControlsContainer.defaultProps = {
	sharedWithCompanies: Map(),
};

const mapStoreToProps = (store) => {
	return {
		sharedWithCompanies: store.folders.getIn([
			"currentFolder",
			"sharedWithCompanies",
		]),
		folder: store.folders.get("currentFolder"),
		folders: store.folders.get("folders"),
		mapParentToChildren: store.folders.get("mapParentToChildren"),
		companiesAccessRights: store.company.companiesAccessRights,
		region: store.company.company.region,
	};
};

const mapActionsToProps = {
	getCompanyIdFromOrgNr,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ShareFolderControlsContainer);
