import {
	NOTIFY_FILE_ENTERED_GLOBAL,
	NOTIFY_RETURN_URL,
	NOTIFY_SOCKET_ID,
} from "../actions/types";

export function changeFileEnteredGlobal(bool) {
	return { type: NOTIFY_FILE_ENTERED_GLOBAL, payload: bool };
}

export function notifySetReturnUrl(returnUrl) {
	return { type: NOTIFY_RETURN_URL, payload: returnUrl };
}

/**
 * Notify about socket ID
 */
export function notifySocketId(id) {
	return function (dispatch, getState) {
		const state = getState();
		const userId = state.users.get("userId");

		if (userId) {
			dispatch(updateSocketId(userId));
		}
		const meetingId = state.meetings?.getIn(["meeting", "id"]);
		if (meetingId) {
			dispatch(socketUpdateMeetingId(meetingId));
		}

		dispatch({ type: NOTIFY_SOCKET_ID, payload: id });
		return;
	};
}

export function updateSocketId(userId) {
	return function (dispatch) {
		dispatch({
			type: "updateSocketId",
			payload: { userId },
			isSocketAction: true,
		});
	};
}

export function socketUpdateMeetingId(meetingId) {
	return function (dispatch) {
		dispatch({
			type: "updateMeetingId",
			payload: { newMeetingId: meetingId },
			isSocketAction: true,
		});
	};
}
