import React, { PureComponent } from "react";
import immutablePropTypes from "react-immutable-proptypes";
import { string, bool, func, oneOf } from "prop-types";
import styled from "styled-components";
import Icon from "../../shared/icon/icon";
import Text from "../text/text";
import DropdownMenuMenu from "../dropdown-menu/dropdown-menu-menu";
import DropdownItem from "../dropdown-item/dropdown-item";

const StyledWrapper = styled.div`
	display: flex;
	max-width: 50px;
	min-width: 50px;
	flex-basis: 7%;
	height: 100%;
	position: relative;
`;

const StyledStatus = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${(props) => props.theme.colors[props.statusColor]};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	cursor: ${(props) => (props.readOnly ? "default" : "pointer")};
`;

export default class DropdownStatusButton extends PureComponent {
	static propTypes = {
		onToggleMenu: func,
		statusColor: string,
		statusIcon: string,
		options: immutablePropTypes.list,
		value: string,
		showValueInMenu: bool,
		isOpen: bool,
		halignMenu: oneOf(["left", "right"]),
		onSetRef: func,
		formatOption: func,
		readOnly: bool,
		openModal: func,
	};

	static defaultProps = {
		statusColor: "solitudeDark",
		statusIcon: "faTimes",
	};

	openRollCallModal = () => {};

	render = () => {
		const {
			onToggleMenu,
			statusColor,
			statusIcon,
			isOpen,
			halignMenu,
			onSetRef,
			readOnly,
			openModal,
		} = this.props;

		return (
			<StyledWrapper ref={(ref) => onSetRef && onSetRef(ref)}>
				<StyledStatus
					statusColor={statusColor}
					onClick={onToggleMenu}
					readOnly={readOnly}
				>
					{statusIcon && <Icon icon={statusIcon} color="white" type="solid" />}
				</StyledStatus>
				{!readOnly && (
					<DropdownMenuMenu isOpen={isOpen} halignMenu={halignMenu} scrollbars>
						<DropdownItem onClick={openModal}>
							<Text tid="meetings.attendees.open_rollcall" />
						</DropdownItem>
					</DropdownMenuMenu>
				)}
			</StyledWrapper>
		);
	};
}
