import React, { Component } from "react";
import { connect } from "react-redux";
import { List } from "immutable";
import MoveItemModal from "../../dumb-components/shared/move-item-modal/move-item-modal";
import ItemsTreeContainer from "../shared/item-tree.container";
import {
	transferFolder,
	updateFolderLocal,
	listFolders,
} from "../../actions/folders.actions";
import { closeModal } from "../../actions/modals.actions";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../actions/notify.actions";
import folderHelper from "../../components/helpers/folder.helper";
import { DOCUMENT_MOVE_FOLDER_MODAL } from "../../constants/modals";

class FolderMoveContainer extends Component {
	state = {
		folderToMoveTo: null,
		folders: List(),
	};

	componentDidMount = () => {
		const { folders } = this.props;

		if (folders) {
			this.parseFolders();
		}
	};

	componentDidUpdate = (prevProps) => {
		const { folders, isOpen } = this.props;

		if (
			(folders && folders !== prevProps.folders) ||
			(isOpen && !prevProps.isOpen)
		) {
			this.parseFolders();
		}
	};

	parseFolders = () => {
		const { folders, folder, mapParentToChildren } = this.props;
		let parsedFolders = List();
		const childrenIds = [];

		if (!folder) {
			this.setState({ folders });
			return;
		}

		folderHelper.getDescendentsIds(
			childrenIds,
			folder.get("id"),
			mapParentToChildren,
		);

		parsedFolders = folders.map((item) => {
			if (
				item.get("id") === folder.get("id") ||
				childrenIds.includes(item.get("id"))
			) {
				item = item.set("disabled", true);
			}

			return item;
		});

		this.setState({ folders: parsedFolders });
	};

	onFolderMove = () => {
		const {
			transferFolder,
			updateFolderLocal,
			folder,
			listFolders,
			addErrorNotification,
		} = this.props;
		const { folderToMoveTo } = this.state;

		if (!folderToMoveTo) {
			addErrorNotification({
				tid: "documents.move_folder.error.no_folder_selected.message",
			});
			return;
		}

		const moveToFolderId = folderToMoveTo.get("id") || null;

		updateFolderLocal(folder.set("parent", moveToFolderId));
		transferFolder(folder.get("id"), moveToFolderId, () => listFolders());

		this.onClose();
	};

	onClose = () => {
		const { closeModal } = this.props;

		closeModal();
		this.setState({ folderToMoveTo: null });
	};

	onFolderSelected = (folder) => {
		this.setState({ folderToMoveTo: folder });
	};

	renderItemsTreeContainer = () => {
		const { folder } = this.props;
		const { folderToMoveTo, folders } = this.state;
		const parentFolder =
			folderToMoveTo ||
			(folder &&
				folders &&
				folders.find((obj) => obj.get("id") === folder.get("id")));

		return (
			<ItemsTreeContainer
				items={folders}
				onItemSelected={this.onFolderSelected}
				selectedItem={parentFolder}
				renderHoverText={true}
				permission="ALLOW_CREATE"
			/>
		);
	};

	render = () => {
		const { isOpen } = this.props;

		if (!isOpen) {
			return null;
		}

		return (
			<MoveItemModal
				titleTid="documents.generic.folders"
				isOpen={true}
				onMove={this.onFolderMove}
				onClose={this.onClose}
				itemTreeContainerRenderer={this.renderItemsTreeContainer}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		folders: store.folders.get("folders"),
		mapParentToChildren: store.folders.get("mapParentToChildren"),
		folder: store.modals.getIn(["activeModal", "options", "folder"]),
		isOpen:
			store.modals.getIn(["activeModal", "name"]) ===
			DOCUMENT_MOVE_FOLDER_MODAL,
	};
};

const mapActionsToProps = {
	transferFolder,
	updateFolderLocal,
	listFolders,
	closeModal,
	addErrorNotification,
	addInfoNotification,
};

export default connect(mapStoreToProps, mapActionsToProps)(FolderMoveContainer);
