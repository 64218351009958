import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { number } from "prop-types";

export default class ShowMoreBox extends Component {
	state = {
		open: false,
	};

	static propTypes = {
		closedHeight: number,
		btnMarginTop: number,
		closedFixedHeight: number,
	};

	static defaultProps = {
		showMoreText: "generic.show_more",
		showLessText: "generic.show_less",
	};

	componentDidMount = () => {};

	toggleText = () => {
		this.setState((prevState) => {
			return { open: !prevState.open };
		});
	};

	render = () => {
		const {
			showMoreText,
			showLessText,
			children,
			closedHeight,
			btnMarginTop,
			closedFixedHeight,
		} = this.props;
		const { open } = this.state;

		if (children.size <= 3) {
			return children;
		}

		return (
			<div className="show-more-box">
				<div
					className={`show-more-box__content ${
						open
							? `show-more-box__content--open`
							: `show-more-box__content--closed`
					}`}
					style={{
						maxHeight: !open && closedHeight ? closedHeight + "px" : null,
						height:
							!open && closedFixedHeight ? `${closedFixedHeight}px` : null,
					}}
				>
					{children}
				</div>
				{!open && <div className="show-more-box__fade"></div>}
				<div
					className={`show-more-box__button ${
						open
							? `show-more-box__button--open`
							: `show-more-box__button--closed`
					}`}
					style={{
						marginTop: open && btnMarginTop ? btnMarginTop + "px" : null,
					}}
				>
					<div className="show-more-box__button-text" onClick={this.toggleText}>
						<FormattedMessage id={open ? showLessText : showMoreText} />
					</div>
				</div>
			</div>
		);
	};
}
