import React, { Component } from "react";
import { connect } from "react-redux";
import { func, oneOf } from "prop-types";
import { List } from "immutable";
import moment from "../../../modules/moment.module";
import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import Button from "../../../dumb-components/shared/button/button";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";
import ConfirmContainer from "../../shared/confirm.container";
import StartMeetingMessage from "../../../dumb-components/meetings/screen-message/start-meeting-message";
import {
	MEETING_STATUS_MEETING_STARTED,
	MEETING_TYPE_STANDARD,
} from "/shared/constants";
import {
	saveMeeting,
	updateAgendaItemLocal,
	updateMeetingLocal,
} from "../../../actions/meetings.actions";
import { getIsStartDateInFuture } from "../../../components/helpers/meeting.helper";

const TOOLTIP_STATES = {
	notSecretaryStart: {
		tid: "smart_meeting.dropdown.tooltip.start.only_secretary",
		delayShow: "instant",
	},
	noMeetingTemplate: {
		tid: "meetings.smart.toolbar.rocket.dropdown.tooltip.no_template",
		delayShow: "instant",
	},
};

class StartMeetingContainer extends Component {
	state = {
		isConfirmOpen: false,
		isLoading: false,
	};

	static propTypes = {
		mode: oneOf(["dropdown", "button", "screenMessage"]),
		btnType: oneOf(["primary", "branded"]),
		onClick: func,
	};

	static defaultProps = {
		btnType: "branded",
	};

	isStartDateInFuture = () => {
		const { startDate } = this.props;
		return getIsStartDateInFuture(startDate);
	};

	onStartMeeting = () => {
		const { isStandardMeeting } = this.props;
		const startDateIsInFuture = this.isStartDateInFuture();

		if (startDateIsInFuture) {
			this.setState({ isConfirmOpen: true });
			return;
		}

		if (isStandardMeeting) {
			this.doStartStandardMeeting();
		} else {
			this.doStartMeeting(false);
		}
	};

	startMeetingCallback = () => {
		this.setState({ isLoading: false });
	};

	doStartMeeting = (changeStartDate = true) => {
		const { saveMeeting, updateAgendaItemLocal, onClick } = this.props;
		let { meeting, agendaItem } = this.props;
		const now = moment();
		const startDate =
			meeting.get("startDate") && moment(meeting.get("startDate"));
		const endDate = meeting.get("endDate") && moment(meeting.get("endDate"));
		const shouldChangeStartDate = !startDate || changeStartDate;
		const diff = startDate
			? endDate && endDate.diff(startDate)
			: endDate && endDate.diff(now);

		this.setState({ isLoading: true, isConfirmOpen: false });

		agendaItem = agendaItem.setIn(
			["internalData", "openedAt"],
			now.toISOString(),
		);
		agendaItem = agendaItem.set("outcome", "passed");

		updateAgendaItemLocal(agendaItem, (payload) => {
			if (shouldChangeStartDate) {
				meeting = meeting.set("startDate", now.toISOString());
			}
			if (shouldChangeStartDate && diff) {
				meeting = meeting.set("endDate", now.add(diff).toISOString());
			}
			if (!endDate) {
				meeting = meeting.set("endDate", now.add(1, "hours").toISOString());
			}
			meeting = meeting.set("agendaItems", payload.get("agendaItems"));
			meeting = meeting.set("status", MEETING_STATUS_MEETING_STARTED);
			meeting = meeting.setIn(["computedValues", "meetingIsStarted"], true);
			saveMeeting(meeting, this.startMeetingCallback);
		});

		onClick && onClick();
	};

	doStartStandardMeeting = () => {
		const { saveMeeting } = this.props;
		let { meeting } = this.props;
		meeting = meeting.set("startDate", moment().toISOString());
		meeting = meeting.set("status", MEETING_STATUS_MEETING_STARTED);

		saveMeeting(meeting);
	};

	onCloseConfirm = () => {
		this.setState({ isConfirmOpen: false });
	};

	getTooltipActiveState = () => {
		const { isSecretary, meetingIsStarted, isStandardMeeting, templateId } =
			this.props;

		if (!isSecretary && !isStandardMeeting && !meetingIsStarted) {
			return "notSecretaryStart";
		}

		if (!templateId && !isStandardMeeting && !meetingIsStarted) {
			return "noMeetingTemplate";
		}
	};

	renderButton = () => {
		const {
			isSecretary,
			meetingIsStarted,
			btnType,
			isStandardMeeting,
			templateId,
		} = this.props;
		const { isLoading } = this.state;
		const btnTid = !meetingIsStarted
			? "meetings.agenda.open_meeting_button.open_meeting"
			: "meetings.agenda.open_meeting_button.meeting_is_opened";
		const btnDisabled = () => {
			if (!isSecretary || meetingIsStarted) {
				return true;
			}

			if (!templateId && !isStandardMeeting) {
				return true;
			}
		};

		const activeState = this.getTooltipActiveState();

		if (btnType === "primary") {
			return (
				<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
					<Button
						mode={btnType}
						disabled={btnDisabled()}
						isLoading={isLoading}
						onClick={this.onStartMeeting}
						tid={btnTid}
					/>
				</Tooltip>
			);
		}

		return (
			<Button
				mode="jaded"
				minWidth="200px"
				disabled={btnDisabled()}
				isLoading={isLoading}
				onClick={this.onStartMeeting}
				tid={btnTid}
			/>
		);
	};

	renderDropdown = () => {
		const {
			isSimpleMode,
			meetingIsStarted,
			isSecretary,
			templateId,
			isStandardMeeting,
		} = this.props;
		const { isLoading } = this.state;
		const btnTid = !meetingIsStarted
			? "meetings.agenda.open_meeting_button.open_meeting"
			: "meetings.agenda.open_meeting_button.meeting_is_opened";
		const activeState = this.getTooltipActiveState();

		if (meetingIsStarted) {
			return null;
		}

		const btnDisabled = () => {
			if (isSimpleMode || !isSecretary || isLoading) {
				return true;
			}

			if (!templateId && !isStandardMeeting) {
				return true;
			}
		};

		return (
			<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
				<DropdownIconItem
					icon="faPlayCircle"
					onClick={this.onStartMeeting}
					disabled={btnDisabled()}
					tid={btnTid}
				/>
			</Tooltip>
		);
	};

	renderConfirmContainer = () => {
		const { startDate, isStandardMeeting } = this.props;
		const { isConfirmOpen } = this.state;

		return (
			<ConfirmContainer
				title="meetings.start_meeting.confirm.title"
				message={{
					tid: "meetings.start_meeting.confirm.message",
					values: { startDate: moment(startDate).format("LT") },
				}}
				onConfirm={
					isStandardMeeting ? this.doStartStandardMeeting : this.doStartMeeting
				}
				onDecline={this.onCloseConfirm}
				isOpen={isConfirmOpen}
			/>
		);
	};

	renderScreenMessage = () => {
		const {
			startDate,
			isSecretary,
			isModerator,
			isStandardMeeting,
			templateId,
		} = this.props;

		return (
			<>
				<StartMeetingMessage
					startDate={startDate}
					isAdmin={isSecretary || isModerator}
					onClick={this.onStartMeeting}
					isStandardMeeting={isStandardMeeting}
					templateId={templateId}
				/>

				{this.renderConfirmContainer()}
			</>
		);
	};

	render() {
		const { mode } = this.props;

		return (
			<>
				{mode === "dropdown" && this.renderDropdown()}
				{mode === "button" && this.renderButton()}
				{mode === "screenMessage" && this.renderScreenMessage()}
				{this.renderConfirmContainer()}
			</>
		);
	}
}

const mapStoreToProps = (store) => {
	const meeting = store.meetings.get("meeting");
	const agendaItem =
		meeting &&
		meeting
			.get("agendaItems", List())
			.find((item) => item.get("internalType") === "open");

	return {
		meeting,
		agendaItem,
		isSecretary:
			store.meetings.getIn(["meeting", "secretary"]) ===
			store.user.getIn(["userObj", "id"]),
		meetingIsStarted: store.meetings.getIn([
			"meeting",
			"computedValues",
			"meetingIsStarted",
		]),
		startDate: store.meetings.getIn(["meeting", "startDate"]),
		isSimpleMode: store.meetings.getIn([
			"meeting",
			"computedValues",
			"isSimpleMode",
		]),
		isStandardMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) ===
			MEETING_TYPE_STANDARD,
		isModerator: store.meetings.getIn([
			"meeting",
			"computedValues",
			"isModerator",
		]),
		templateId: store.meetings.getIn(["meeting", "templateId"]),
	};
};

const mapActionsToProps = {
	saveMeeting,
	updateAgendaItemLocal,
	updateMeetingLocal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(StartMeetingContainer);
