import React, { Component } from "react";
import { connect } from "react-redux";
import CompanyAccounts from "../../dumb-components/company/company-accounts/company-accounts";
import CompanyListContainer from "../../containers/company/company-list.container";
import { fetchUserCompanies } from "../../actions/company.actions";
import { updateUser, saveUser } from "../../actions/user.actions";
import {
	EVENT_TYPE_COMPANY_MEMBER_ADDED,
	EVENT_TYPE_COMPANY_MEMBER_UPDATE,
	EVENT_TYPE_COMPANY_MEMBER_REMOVE,
	EVENT_TYPE_COMPANY_UPDATE,
} from "/shared/constants";
import { openModal } from "../../actions/modals.actions";
import { func } from "prop-types";
import { COMPANY_ADD_NEW_COMPANY_MODAL } from "../../constants/modals";

const COMPANY_ACCOUNT_INTRO_BLOCK = "880448b8-4a4c-11e9-8646-d663bd873d93";

class CompanyAccountsContainer extends Component {
	static propTypes = {
		openModal: func.isRequired,
	};

	state = {
		companyWizardOpen: false,
	};

	componentDidUpdate() {
		this.checkLiveUpdateEvents();
	}

	checkLiveUpdateEvents = () => {
		const { auditCompany, fetchUserCompanies } = this.props;
		const companyMemberAdded = auditCompany.get(
			EVENT_TYPE_COMPANY_MEMBER_ADDED,
		);
		const companyMemberUpdate = auditCompany.get(
			EVENT_TYPE_COMPANY_MEMBER_UPDATE,
		);
		const companyMemberRemove = auditCompany.get(
			EVENT_TYPE_COMPANY_MEMBER_REMOVE,
		);
		const companyUpdate = auditCompany.get(EVENT_TYPE_COMPANY_UPDATE);

		// Company member was added, updated or deleted so we update the list
		if (
			(companyMemberAdded && companyMemberAdded.get("refresh") === true) ||
			(companyMemberUpdate && companyMemberUpdate.get("refresh") === true) ||
			(companyMemberRemove && companyMemberRemove.get("refresh") === true) ||
			(companyUpdate && companyUpdate.get("refresh") === true)
		) {
			fetchUserCompanies("me");
		}
	};

	onToggleIntroBlock = (action, mode) => {
		const { saveUser, updateUser } = this.props;
		let { user } = this.props;

		if (action === "close") {
			user = user.setIn(
				["siteSettings", "minimalizedBlocks", COMPANY_ACCOUNT_INTRO_BLOCK],
				true,
			);
		}

		if (action === "open") {
			user = user.setIn(
				["siteSettings", "minimalizedBlocks", COMPANY_ACCOUNT_INTRO_BLOCK],
				false,
			);
		}

		updateUser(user);

		// If more isn't 'local', update user in database
		if (mode !== "local") {
			// Delay 0.5s to prevent jerking animations (rerendering while animation is in progress)
			setTimeout(() => {
				saveUser("me", user);
			}, 500);
		}
	};

	onCompanyWizardOpen = () => {
		const { openModal } = this.props;
		openModal(COMPANY_ADD_NEW_COMPANY_MODAL);
	};

	renderCompanyList = () => {
		return <CompanyListContainer emptyMode="accounts" />;
	};

	render = () => {
		const { isMinimized, user } = this.props;
		const { companyWizardOpen } = this.state;

		return (
			<CompanyAccounts
				isOpen={user && !isMinimized}
				companyWizardOpen={companyWizardOpen}
				onToggleDemo={this.toggleUserDemo}
				onToggleIntroBlock={this.onToggleIntroBlock}
				onCompanyWizardOpen={this.onCompanyWizardOpen}
				renderCompanyList={this.renderCompanyList}
			/>
		);
	};
}

function mapStateToProps(state) {
	return {
		user: state.user.get("userObj"),
		isMinimized: state.user.getIn([
			"userObj",
			"siteSettings",
			"minimalizedBlocks",
			COMPANY_ACCOUNT_INTRO_BLOCK,
		]),
		auditCompany: state.audit.get("company"),
	};
}

const mapDispatchToProps = {
	openModal,
	updateUser,
	saveUser,
	fetchUserCompanies,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CompanyAccountsContainer);
