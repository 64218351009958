import React, { PureComponent } from "react";
import styled from "styled-components";
import { oneOf, func } from "prop-types";
import { AnimatedButtonToggle } from "../../shared/animated-button-toggle";

const NotesWrapper = styled.div`
	flex: 1;

	> div:first-of-type {
		padding-bottom: ${(props) => props.theme.spacing[2]};
	}
`;

export default class MinutesPersonalNotes extends PureComponent {
	static propTypes = {
		view: oneOf([0, 1]),
		onChangeView: func,
		minutesRenderer: func,
		personalNotesRenderer: func,
	};

	render = () => {
		const { onChangeView, minutesRenderer, personalNotesRenderer, view } =
			this.props;
		return (
			<NotesWrapper>
				<AnimatedButtonToggle
					leftTid="meetings.minutes.notes.left.label"
					rightTid="meetings.minutes.notes.right.label"
					onChange={onChangeView}
					themeName="notesSwitch"
					value={view}
				/>

				{view === 0 && minutesRenderer && minutesRenderer()}
				{view === 1 && personalNotesRenderer && personalNotesRenderer()}
			</NotesWrapper>
		);
	};
}
