import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import {
	array,
	bool,
	func,
	object,
	oneOf,
	oneOfType,
	string,
} from "prop-types";
import styled from "styled-components";
import FooterRightControls from "../../../dumb-components/shared/modal/footer-right-controls";
import Button from "../../../mui-components/button/button";
import Icon from "../../../dumb-components/shared/icon/icon";
import ColoredContentWrapper from "../../../dumb-components/shared/colored-content-wrapper/colored-content-wrapper";
import Text from "../../../dumb-components/shared/text/text";

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
`;

const StyledIconWrapper = styled.div`
	padding-bottom: ${(props) => props.theme.spacing[3]};
`;

const Confirm = ({
	title,
	message,
	onConfirm,
	onDecline,
	isOpen = false,
	mode = "confirm",
	confirmBtnTid = "generic.yes",
	declineBtnTid = "generic.no",
}) => {
	const { t } = useTranslation();

	return (
		<Dialog
			open={isOpen}
			disableEscapeKeyDown
			onClose={onDecline}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle>{t(title)}</DialogTitle>
			<DialogContent sx={{ height: "auto" }}>
				<StyledColoredContentWrapper type="secondary">
					{typeof message === "string" ? (
						<>
							<StyledIconWrapper>
								<Icon
									icon="faExclamationTriangle"
									size="normal"
									color="yellowMid"
									type="solid"
								/>
							</StyledIconWrapper>
							<Typography variant="body2">{t(message)}</Typography>
						</>
					) : null}
					{typeof message === "object" && !Array.isArray(message) ? (
						<>
							<StyledIconWrapper>
								<Icon
									icon="faExclamationTriangle"
									size="normal"
									color="yellowMid"
									type="solid"
								/>
							</StyledIconWrapper>
							<Text
								tag="p"
								tid={message.tid}
								values={message.values}
								asHtml={message.asHml}
							/>
						</>
					) : null}
					{Array.isArray(message) ? (
						<>
							<StyledIconWrapper>
								<Icon
									icon="faExclamationTriangle"
									size="normal"
									color="yellowMid"
									type="solid"
								/>
							</StyledIconWrapper>
							{message.map((m, index) => (
								<Text
									key={index}
									tag="p"
									tid={m.tid}
									values={m.values}
									asHtml={m.asHml}
								/>
							))}
						</>
					) : null}
				</StyledColoredContentWrapper>
			</DialogContent>
			<DialogActions>
				<FooterRightControls>
					{mode === "alert" ? (
						<Button color="positive" onClick={onConfirm}>
							{t(confirmBtnTid)}
						</Button>
					) : (
						<>
							<Button color="positive" onClick={onConfirm}>
								{t(confirmBtnTid)}
							</Button>
							<Divider orientation="vertical" flexItem light />
							<Button onClick={onDecline}>{t(declineBtnTid)}</Button>
						</>
					)}
				</FooterRightControls>
			</DialogActions>
		</Dialog>
	);
};

Confirm.propTypes = {
	title: string,
	message: oneOfType([string, object, array]),
	onConfirm: func,
	onDecline: func,
	isOpen: bool,
	mode: oneOf(["confirm", "alert"]),
	confirmBtnTid: string,
	declineBtnTid: string,
};

export { Confirm };
