import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import ProtocolEsigningContainer from "./protocol-esigning.container";
import SplitView from "../../../dumb-components/shared/layout/split-view/split-view";
import ScrollView from "../../../dumb-components/shared/layout/scroll-view/scroll-view";
import Box from "../../../dumb-components/shared/layout/box/box";
import ProtocolTabsContainer from "../protocol/protocol-tabs.container";
import AlertContainer from "../protocol/alert.container";
import AlertManualPublishContainer from "../attendees/alert-manual-publish.container";
import ScrollViewHeader from "../../../dumb-components/shared/scroll-view-header/scroll-view-header";
import ProtocolToolbarContainer from "../meeting/smart-meeting/protocol-toolbar.container";

import Text from "../../../dumb-components/shared/text/text";
import Alert from "../../../dumb-components/shared/alert/alert";

import {
	calculateMaxWidthOfSplitView,
	toggleVisibilityOfTabs,
} from "../../../components/helpers/tabs.helper";
import withResolvedProps from "@/hocs/withResolvedProps";
import useSubscriptionHelper from "@/hooks/useSubscriptionHelper";

const UpgradePlanToUseEsignAlert = () => (
	<Alert mode="success" icon>
		<Text tid="meetings.esign.subscription.upgrade" color="white" />
	</Alert>
);

class SmartMeetingsProtocolEsigningTabContainer extends Component {
	// References to the scrollviews
	scrollViewRefs = {};

	// Reference to the tabs
	tabsRef;

	state = {
		hiddenTabs: [],
	};

	componentDidMount = () => {
		window.addEventListener("resize", this.onWindowResize, false);
		this.onWindowResize();
	};

	componentWillUnmount = () => {
		window.removeEventListener("resize", this.onWindowResize, false);
	};

	onWindowResize = () => {
		let { hiddenTabs } = this.state;
		const maxWidth = calculateMaxWidthOfSplitView(this.scrollViewRefs);
		hiddenTabs = toggleVisibilityOfTabs(this.tabsRef, hiddenTabs, { maxWidth });

		this.setState({ hiddenTabs });
	};

	getComponentBasePath = () => {
		const {
			location: { pathname },
		} = this.props;
		const path = pathname;
		const pathArray = path.split("sign-off");
		return pathArray[0] + "sign-off";
	};

	renderLeftHeader = () => {
		const { location, meeting } = this.props;
		const { hiddenTabs } = this.state;

		return (
			<Box direction="column">
				<Box direction="row">
					<ProtocolTabsContainer
						location={location}
						match="sign-off"
						onSetRef={(ref) => (this.tabsRef = ref)}
						hiddenTabs={hiddenTabs}
					/>
				</Box>

				<AlertManualPublishContainer
					meetingIsLoaded={meeting && meeting.get("id") ? true : false}
					atLeastOneSigned={
						meeting &&
						meeting
							.get("signees", Map())
							.find(
								(s) => s.get("status") === "SIGNED" && s.get("active") === true,
							)
							? true
							: false
					}
					everyoneHasSigned={
						meeting &&
						meeting
							.get("signees", Map())
							.find(
								(s) =>
									s.get("status") === "NOT-SIGNED" && s.get("active") === true,
							)
							? false
							: true
					}
					protocolPublished={
						meeting && meeting.getIn(["computedValues", "protocolPublished"])
					}
				/>

				<AlertContainer
					mode="signoff"
					meetingIsLoaded={meeting && meeting.get("id") ? true : false}
					numOfMembers={
						meeting
							? meeting.get("signees", Map()).filter((obj) => obj.get("active"))
									.size
							: 0
					}
					membersNeedNotification={
						meeting &&
						meeting.getIn(["computedValues", "signeesWarning"]) === true
					}
				/>
			</Box>
		);
	};

	renderLeftSplitView = () => {
		const { meeting, eSigningEnabled } = this.props;
		const docId = meeting && meeting.getIn(["protocolData", "documentId"]);

		return (
			<ScrollView
				header={this.renderLeftHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				onSetRef={(ref) => (this.scrollViewRefs["left"] = ref)}
			>
				{!eSigningEnabled && <UpgradePlanToUseEsignAlert />}
				{docId && <ProtocolEsigningContainer documentId={docId} />}
			</ScrollView>
		);
	};

	renderRightHeader = () => {
		const {
			match: { params },
			location,
		} = this.props;
		const basePath = this.getComponentBasePath();

		return (
			<ScrollViewHeader>
				<ProtocolToolbarContainer
					location={location}
					params={params}
					basePath={basePath.split("/sign-off")[0]}
				/>
			</ScrollViewHeader>
		);
	};

	renderRightSplitView = () => {
		return (
			<ScrollView
				header={this.renderRightHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				onSetRef={(ref) => (this.scrollViewRefs["right"] = ref)}
			></ScrollView>
		);
	};

	render = () => {
		return (
			<SplitView
				leftComponent={this.renderLeftSplitView}
				rightComponent={this.renderRightSplitView}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get("meeting"),
		company: store.company.company,
	};
};

const mapActionsToProps = {};

const SmartMeetingsProtocolEsigningTabContainerConnected = connect(
	mapStoreToProps,
	mapActionsToProps,
)(SmartMeetingsProtocolEsigningTabContainer);

export default withResolvedProps(() => {
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	return { eSigningEnabled: subscriptionHelper?.eSigningEnabled };
})(SmartMeetingsProtocolEsigningTabContainerConnected);
