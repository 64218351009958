import React from "react";
import { Confirm } from "./Confirm";
import { ConfirmContext } from "./confirm.context";

function ConfirmProvider({ children }) {
	const [showConfirmationModal, setShowConfirmationModal] =
		React.useState(false);
	const [content, setContent] = React.useState({
		title: "",
		message: "",
		confirmBtnTid: "",
		declineBtnTid: "",
	});
	const resolver = React.useRef();

	const handleShow = (
		title,
		message,
		confirmBtnTid = "generic.yes",
		declineBtnTid = "generic.no",
	) => {
		setShowConfirmationModal(true);
		setContent({ title, message, confirmBtnTid, declineBtnTid });

		return new Promise((resolve) => {
			resolver.current = resolve;
		});
	};

	const onConfirm = () => {
		resolver.current && resolver.current(true);
		setShowConfirmationModal(false);
	};

	const onDecline = () => {
		resolver.current && resolver.current(false);
		setShowConfirmationModal(false);
	};

	const value = {
		showConfirmation: handleShow,
	};

	return (
		<ConfirmContext.Provider value={value}>
			{children}
			<Confirm
				isOpen={showConfirmationModal}
				title={content.title}
				message={content.message}
				confirmBtnTid={content.confirmBtnTid}
				declineBtnTid={content.declineBtnTid}
				onConfirm={onConfirm}
				onDecline={onDecline}
			/>
		</ConfirmContext.Provider>
	);
}

export { ConfirmProvider };
