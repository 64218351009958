import React, { PureComponent } from "react";
import { string, oneOf, object } from "prop-types";

import Badge from "./badge";
import Text from "../text/text";

export default class SubscriptionBadge extends PureComponent {
	static propTypes = {
		bgColor: oneOf(["java"]),
		tid: string,
		tidValues: object,
		textTransform: oneOf(["uppercase"]),
	};

	static defaultProps = {
		bgColor: "java",
		textTransform: "uppercase",
	};

	render = () => {
		const { bgColor, tid, tidValues, textTransform } = this.props;

		return (
			<Badge
				bgColor={bgColor}
				height={18}
				maxWidth={500}
				tidValues={tidValues}
				noTransition
				isStatic
			>
				<Text
					tid={tid}
					values={tidValues}
					color="white"
					fontSize="11px"
					textTransform={textTransform}
					lineHeight={2}
					nowrap
					importantColor
				/>
			</Badge>
		);
	};
}
