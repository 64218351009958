import React from "react";
import { DynamicPlaceholderEngine } from "../../shared/content-placeholders";

const recipe = [["i-sl"], ["i-sl"], ["i-sl", "", ""]];

const FieldsLoader = () => {
	return <DynamicPlaceholderEngine recipe={recipe} marginBottom />;
};

export default FieldsLoader;
