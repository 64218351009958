import React, { PureComponent } from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import DocumentsDetailedList from "../../../documents/documents-detailed-list/documents-detailed-list";
import Block from "../block/block";

const StyledDocumentsRoot = styled.div`
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

export default class DocumentsList extends PureComponent {
	static propTypes = {
		documents: immutablePropTypes.list,
		onOpenFile: func,
		onDownloadFile: func,
		hideProposalColumn: bool,
	};

	render = () => {
		const { documents, onOpenFile, onDownloadFile, hideProposalColumn } =
			this.props;

		return (
			<StyledDocumentsRoot>
				<Block titleTid="meetings.protocol.documents_list.title">
					<DocumentsDetailedList
						documents={documents}
						onOpenFile={onOpenFile}
						onDownloadFile={onDownloadFile}
						hideProposalColumn={hideProposalColumn}
					/>
				</Block>
			</StyledDocumentsRoot>
		);
	};
}
