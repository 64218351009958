const helper = {
	removeEmptyExternalPerson: (shareWith) => {
		shareWith = shareWith.filter((person) =>
			person.has("isGuest")
				? person.get("isGuest") === true &&
				  person.get("name")?.length > 0 &&
				  person.get("email")?.length > 0
				: true,
		);

		return shareWith;
	},
};

export default helper;
