import React, { Component } from "react";
import { connect } from "react-redux";
import ImmutableProps from "react-immutable-proptypes";
import { List, Map, Set } from "immutable";
import moment from "../../../modules/moment.module";
import {
	fetchAgendaItem,
	saveMeeting,
	fetchMeeting,
	updateMeetingLocal,
	saveAgendaItem,
	meetingPushLiveUpdate,
} from "../../../actions/meetings.actions";
import { fetchSimpleUsers } from "../../../actions/usersCache.actions";
import { fetchAndCacheInvestors } from "../../../actions/investors.actions";
import {
	MEETINGS_ITEM_TYPES,
	MEETING_GROUPS_ID,
} from "../../../constants/meetings";
import Box from "../../../dumb-components/shared/layout/box/box";
import AgendaDetails from "../../../dumb-components/meetings/agenda-details/agenda-details";
import AttachmentsByObjIdContainer from "../../shared/attachments-by-objid.container";
import { renderMeetingAttachmentDropdown } from "../../documents/attachments/predefined-dropdown-render-functions";
import TaskPluginContainer from "../../tasks/task-plugin.container";
import { Margin } from "styled-components-spacing";
import NotesContainer from "../../shared/notes.container";
import PreviousMeetingPanelContainer from "../previous-meeting/previous-meeting-panel.container";
import NextMeetingDetailsContainer from "../next-meeting/next-meeting-details.container";
import CommentsListContainer from "../../comments/comments-list.container";
import FeedbackPanel from "../../../dumb-components/meetings/protocol-review/feedback-panel";
import MinutesPersonalNotesContainer from "./minutes-personal-notes.container";
import {
	hasMeetingStarted,
	canViewTopicNotes,
} from "../../../components/helpers/meeting.helper";
import Panel from "../../../dumb-components/shared/panel/panel";
import TagsPanelContainer from "../../shared/tags-panel.container";
import StatusPanelContainer from "./status-panel.container";
import EditorContainer from "../../shared/editor.container";
import SimpleDarkInfoPanel from "../../../dumb-components/shared/dark-info-panel/simple-dark-info-panel";
import Text from "../../../dumb-components/shared/text/text";
import {
	convertToHtml,
	convertToContentState,
} from "../../../components/helpers/draftjs.helper";
import documentsHelper from "../../../components/helpers/documents.helper";
import { openModal } from "../../../actions/modals.actions";
import { MEETING_START_MEETING_MODAL } from "../../../constants/modals";
import {
	LIVE_MEETINGS_AGENDA_UPDATE,
	LIVE_MEETINGS_AGENDA_DELETE,
} from "../../../constants/live-update";
import {
	OBJ_TYPE_AGENDA_ITEM,
	OBJ_TYPE_MEETING,
	OBJ_TYPE_AGENDA_ITEM_FEEDBACK,
	OBJ_TYPE_MEETING_PROTOCOL,
	ATTACHMENTS_DISPLAY_STATUS_SHOWN,
	ATTACHMENTS_DISPLAY_STATUS_DURING_AFTER,
	ATTACHMENTS_DISPLAY_STATUS_HIDDEN,
	AGENDA_TOPIC_NOTES_VISIBILITY_SHOW,
	AGENDA_TOPIC_NOTES_VISIBILITY_HIDE,
	EVENT_TYPE_MEETINGS_UPDATE,
} from "/shared/constants";
import history from "../../../interfaces/history";

class AgendaDetailsContainer extends Component {
	static propTypes = {
		agendaItem: ImmutableProps.map,
	};

	state = {
		presenterOptions: List(),
	};

	componentDidMount() {
		const {
			fetchAgendaItem,
			meeting,
			usersCache,
			match: { params },
		} = this.props;

		fetchAgendaItem(params.agendaItemId);

		if (meeting) {
			fetchSimpleUsers(Set.fromKeys(meeting.get("attendees", Map())).toList());
			this.loadInvestors();
		}

		this.parsePresenterOptions(usersCache);
	}

	componentDidUpdate = (prevProps) => {
		const {
			fetchAgendaItem,
			fetchSimpleUsers,
			usersCache,
			meeting,
			agendaItem,
			match: { params },
			investors,
		} = this.props;

		if (
			meeting &&
			(!agendaItem.get("id") ||
				prevProps.match.params.agendaItemId !== params.agendaItemId)
		) {
			fetchAgendaItem(params.agendaItemId);
		}

		if (
			(!prevProps.meeting && meeting) ||
			(meeting &&
				prevProps.meeting &&
				prevProps.meeting.get("attendees") !== meeting.get("attendees"))
		) {
			fetchSimpleUsers(Set.fromKeys(meeting.get("attendees", Map())).toList());
			this.loadInvestors();
		}

		if (
			usersCache !== prevProps.usersCache ||
			(!prevProps.meeting && meeting && usersCache) ||
			investors !== prevProps.investors
		) {
			this.parsePresenterOptions(usersCache);
		}

		this.checkLiveUpdateEvents();
	};

	checkLiveUpdateEvents = () => {
		const {
			audit,
			fetchMeeting,
			fetchAgendaItem,
			match: {
				params: { id, agendaItemId },
			},
			location: { search },
			history,
		} = this.props;
		const agendaUpdate = audit.get(LIVE_MEETINGS_AGENDA_UPDATE, Map());
		const agendaDelete = audit.get(LIVE_MEETINGS_AGENDA_DELETE, Map());
		const meetingUpdate = audit.get(EVENT_TYPE_MEETINGS_UPDATE, Map());

		if (
			(agendaUpdate.get("refresh") === true &&
				agendaItemId === agendaUpdate.getIn(["metadata", "agendaItemId"])) ||
			(meetingUpdate.get("refresh") === true &&
				agendaItemId === meetingUpdate.getIn(["metadata", "agendaItemId"]))
		) {
			fetchMeeting(id, null, () => {
				fetchAgendaItem(agendaItemId);
			});
		}

		if (
			agendaDelete.get("refresh") === true &&
			id === agendaDelete.get("objId")
		) {
			history.push({
				pathname: this.getComponentBasePath(),
				search,
			});
		}
	};

	loadInvestors = () => {
		const { fetchAndCacheInvestors, meeting } = this.props;

		const investmentIds = meeting
			.get("attendees")
			?.filter((attendee) => !attendee.get("isGuest"))
			?.map((attendee) => attendee.get("userId"));

		if (investmentIds) {
			fetchAndCacheInvestors(investmentIds);
		}
	};

	getComponentBasePath = () => {
		const {
			location: { pathname },
		} = this.props;
		const path = pathname;
		const pathArray = path.split("agenda");
		return pathArray[0] + "agenda";
	};

	parsePresenterOptions = (usersCache) => {
		const { meeting, investors } = this.props;
		let presenterOptions = List();

		meeting &&
			meeting.get("attendees", Map()).forEach((attendee) => {
				const user = usersCache && usersCache.get(attendee.get("userId"));

				if (user) {
					presenterOptions = presenterOptions.push(
						Map({
							value: user.get("id"),
							label: user.get("name"),
						}),
					);
				} else if (attendee.get("isInvestor")) {
					const investor = investors?.get(attendee.get("investmentId"));

					if (investor) {
						presenterOptions = presenterOptions.push(
							Map({
								value: attendee.get("userId"),
								label: investor.getIn(["investorInformation", "name"]),
							}),
						);
					}
				} else if (attendee.get("isGuest")) {
					presenterOptions = presenterOptions.push(
						Map({
							value: attendee.get("userId"),
							label: attendee.get("name"),
						}),
					);
				}
			});

		// Sort alphabetically
		presenterOptions = presenterOptions.sortBy((p) => p.get("label"));

		this.setState({ presenterOptions });
	};

	updateAgendaItemLocal = (agendaItem) => {
		const { saveAgendaItem } = this.props;

		saveAgendaItem(agendaItem);
	};

	onChange = (field, value) => {
		const { meeting, openModal } = this.props;
		let { agendaItem } = this.props;

		// Convert contentState to stringified JSON
		if (field === "minutes") {
			value = JSON.stringify(value.toJS ? value.toJS() : value);
		}

		// Convert contentState to stringified JSON due to some fuck-up when saving data to db or converting vanilla js to immutable js
		if (field === "topicNotes") {
			value = JSON.stringify(value.toJS ? value.toJS() : value);
		}

		if (field === "notes") {
			// TODO: determine if user is secretary or member and set correct field
			field = "meetingNotes";
		}

		// Use Meeting as a template for next meeting
		if (field === "internalData.useExistingMeeting") {
			agendaItem = agendaItem.setIn(
				["internalData", "useExistingMeeting"],
				value,
			);
			field = "internalData.meeting";
			value = value ? meeting : null;

			if (value) {
				// Delete date and time
				value = value.delete("startDate");
				value = value.delete("endDate");

				// Attendees manipulation
				value = value.get("attendees")
					? value.update("attendees", (attendees) => {
							return attendees.map((attendee) => {
								attendee = attendee.set("notified", false);
								attendee = attendee.set("notifiedAboutChanges", false);
								attendee = attendee.set("status", false);
								attendee = attendee.set("haveResponded", false);
								return attendee;
							});
					  })
					: value;
			}
		}

		// When in protocol mode and the topic is marked as 'done' 'hasUnreadFeedback' must be set to false
		if (field === "progress" && value === "done") {
			const hadUnreadFeedbackBefore = agendaItem.get("hasUnreadFeedback");

			if (hadUnreadFeedbackBefore) {
				agendaItem = agendaItem.set("hasUnreadFeedback", false);
			}
		}

		field = field.split(".");
		agendaItem = agendaItem.setIn(field, value);

		const openMeeting =
			field[0] === "outcome" &&
			!hasMeetingStarted(meeting) &&
			agendaItem.get("internalType") === "open";
		const closeMeeting = field[0] === "outcome" && value === "closed";

		// If meeting is not yet open and outcome value for item
		// open (internal type) was changed, open the meeting
		if (openMeeting) {
			openModal(MEETING_START_MEETING_MODAL, { agendaItemOutcomeValue: value });
			return;
		}

		if (closeMeeting) {
			let internalData = agendaItem.get("internalData", Map());
			internalData = internalData.set("closedAt", moment().toISOString());
			agendaItem = agendaItem.set("internalData", internalData);
		}

		this.updateAgendaItemLocal(agendaItem);
	};

	validateAgendaItem = () => {
		return true;
	};

	getObjId = () => {
		const { meeting, agendaItem } = this.props;
		const objId = `${meeting.get("id")}$${agendaItem.get("id")}`;

		return objId;
	};

	switchTopicNotesVisibility = () => {
		const { agendaItem } = this.props;
		const topicNotesVisibility = agendaItem.get("topicNotesVisibility");
		const newValue =
			topicNotesVisibility === AGENDA_TOPIC_NOTES_VISIBILITY_SHOW
				? AGENDA_TOPIC_NOTES_VISIBILITY_HIDE
				: AGENDA_TOPIC_NOTES_VISIBILITY_SHOW;
		this.onChange("topicNotesVisibility", newValue);
	};

	renderTopicNotes = () => {
		const { agendaItem } = this.props;
		const id = agendaItem.get("id");
		const topicNotes = agendaItem.get("topicNotes");

		return (
			<EditorContainer
				fieldName="topicNotes"
				objId={id}
				contentState={topicNotes}
				onChange={this.onChange}
				editorHeight="150px"
			/>
		);
	};

	renderStatusPanelContainer = () => {
		const basePath = this.getComponentBasePath();

		return <StatusPanelContainer basePath={basePath} />;
	};

	renderDetails = () => {
		const { meeting, agendaItem, location, isSecretary } = this.props;
		const { presenterOptions } = this.state;
		const itemHasSubItems =
			agendaItem && agendaItem.get("agendaItems", List()).size > 0;
		const showProgressField =
			!itemHasSubItems && location.pathname.indexOf("/review") >= 0;
		const objId =
			meeting && agendaItem && `${meeting.get("id")}$${agendaItem.get("id")}`;
		const showStatusPanel = isSecretary;
		const duration = agendaItem?.get("duration");

		return (
			<AgendaDetails
				key={objId}
				onChange={this.onChange}
				id={agendaItem && agendaItem.get("id")}
				proposal={agendaItem && agendaItem.get("proposal")}
				description={agendaItem && agendaItem.get("description")}
				presenter={agendaItem && agendaItem.get("presenter")}
				itemType={agendaItem && agendaItem.get("itemType")}
				duration={duration}
				tags={agendaItem && agendaItem.get("tags")}
				progress={agendaItem && agendaItem.get("progress")}
				minutes={agendaItem && agendaItem.get("minutes")}
				presenterOptions={presenterOptions}
				itemTypeOptions={MEETINGS_ITEM_TYPES}
				renderNotes={this.renderNotes}
				itemHasSubItems={itemHasSubItems}
				showProgressField={showProgressField}
				renderStatusPanelContainer={
					showStatusPanel ? this.renderStatusPanelContainer : undefined
				}
				renderTopicNotes={this.renderTopicNotes}
				topicNotesVisibility={agendaItem.get("topicNotesVisibility")}
				switchTopicNotesVisibility={this.switchTopicNotesVisibility}
			/>
		);
	};

	onUploadAndDelete = (action, uploadData) => {
		const { meeting, updateMeetingLocal } = this.props;

		updateMeetingLocal(
			meeting.updateIn(
				["computedValues", "numOfDocuments"],
				(numOfDocuments) => {
					if (action === "UPLOAD") {
						return numOfDocuments + uploadData.filesToUpload.length;
					} else if (action === "DELETE") {
						return numOfDocuments - 1;
					}

					return numOfDocuments;
				},
			),
		);
	};

	onLinkDocument = () => {
		const { updateMeetingLocal, meetingPushLiveUpdate } = this.props;
		let { meeting } = this.props;

		meeting = meeting.updateIn(
			["computedValues", "numOfDocuments"],
			(numOfDocuments) => numOfDocuments + 1,
		);
		updateMeetingLocal(meeting);
		meetingPushLiveUpdate(meeting.get("id"));
	};

	onUnlinkDocument = () => {
		const { updateMeetingLocal, meetingPushLiveUpdate } = this.props;
		let { meeting } = this.props;

		meeting = meeting.updateIn(
			["computedValues", "numOfDocuments"],
			(numOfDocuments) => numOfDocuments - 1,
		);
		updateMeetingLocal(meeting);
		meetingPushLiveUpdate(meeting.get("id"));
	};

	onCreateTask = () => {
		const { meeting, updateMeetingLocal } = this.props;

		updateMeetingLocal(
			meeting.updateIn(
				["computedValues", "numOfTasks"],
				(numOfTasks) => ++numOfTasks,
			),
		);
	};

	onDeleteTask = () => {
		const { meeting, updateMeetingLocal } = this.props;

		updateMeetingLocal(
			meeting.updateIn(
				["computedValues", "numOfTasks"],
				(numOfTasks) => --numOfTasks,
			),
		);
	};

	getFileIconDotData = (document, objId) => {
		const links = document.get("links");
		const isProtocol = links.some(
			(link) =>
				link.get("objType") === OBJ_TYPE_MEETING_PROTOCOL &&
				link.get("objId") === objId,
		);

		if (isProtocol) {
			return;
		}

		const displayStatus = documentsHelper.findDisplayStatus(links, objId);
		const fileIconDotColor =
			displayStatus === ATTACHMENTS_DISPLAY_STATUS_SHOWN ||
			displayStatus === ATTACHMENTS_DISPLAY_STATUS_DURING_AFTER
				? "green"
				: "orange";
		let fileIconTooltipTid;

		if (displayStatus === ATTACHMENTS_DISPLAY_STATUS_SHOWN) {
			fileIconTooltipTid =
				"meetings.standard.attachments.visibility.tooltip.shown";
		} else if (displayStatus === ATTACHMENTS_DISPLAY_STATUS_HIDDEN) {
			fileIconTooltipTid =
				"meetings.standard.attachments.visibility.tooltip.hidden";
		} else if (displayStatus === ATTACHMENTS_DISPLAY_STATUS_DURING_AFTER) {
			fileIconTooltipTid =
				"meetings.standard.attachments.visibility.tooltip.duringAfter";
		}

		return {
			fileIconDot: true,
			fileIconDotColor,
			fileIconTooltipTid,
		};
	};

	renderAttachmentsContainer = () => {
		const { agendaItem, meeting, isSimpleMode, userId } = this.props;

		if (
			!meeting ||
			!agendaItem ||
			agendaItem.get("internalType") === "nextMeeting"
		) {
			return null;
		}

		if (agendaItem?.get("id")) {
			const objId = `${meeting.get("id")}$${agendaItem.get("id")}`;

			const readOnly =
				(isSimpleMode && agendaItem.get("presenter") !== userId) ||
				(meeting.getIn(["computedValues", "meetingIsFinished"]) &&
					meeting.get("secretary") !== userId);

			return (
				<Margin bottom={isSimpleMode ? 4 : undefined}>
					<AttachmentsByObjIdContainer
						objType={OBJ_TYPE_AGENDA_ITEM}
						objId={objId}
						onUpload={this.onUploadAndDelete.bind(null, "UPLOAD")}
						onLinkDocument={this.onLinkDocument}
						onUnlinkDocument={this.onUnlinkDocument}
						nakedStyle={isSimpleMode}
						readOnly={readOnly}
						getFileIconDotData={this.getFileIconDotData}
						renderAttachmentDropdown={(defaultProps) => {
							return renderMeetingAttachmentDropdown(defaultProps, {
								onDeleteCallback: () => {
									this.onUploadAndDelete("DELETE");
								},
							});
						}}
					/>
				</Margin>
			);
		}
	};

	renderExternalTasksList = () => {
		const { meeting, agendaItem } = this.props;

		if (!meeting || !agendaItem) {
			return null;
		}

		const objId = `${meeting.get("id")}$${agendaItem.get("id")}`;
		const readOnly = agendaItem.get("agendaItems", List()).size > 0;

		return (
			<TaskPluginContainer
				objType={OBJ_TYPE_MEETING}
				projectId={
					meeting.get("groupId") ? meeting.get("groupId") : MEETING_GROUPS_ID
				}
				objId={objId}
				readOnly={readOnly}
				onCreateTask={this.onCreateTask}
				onDeleteTask={this.onDeleteTask}
				marginBottom={true}
			/>
		);
	};

	renderPersonalNotes = () => {
		const { meeting, agendaItem, isSimpleMode } = this.props;

		if (!meeting || !agendaItem) {
			return null;
		}

		const height =
			isSimpleMode || agendaItem.get("archived") ? "590px" : undefined;
		const objId = `${meeting.get("id")}$${agendaItem.get("id")}`;

		return (
			<NotesContainer
				objId={objId}
				objType={OBJ_TYPE_AGENDA_ITEM}
				height={height}
				inline
			/>
		);
	};

	renderNotes = () => {
		const { agendaItem, meeting } = this.props;

		if (!meeting || !agendaItem) {
			return null;
		}

		const meetingId = meeting.get("id");
		const objId = `${meeting.get("id")}$${agendaItem.get("id")}`;
		const agendaItemMinutes = agendaItem.get("minutes");

		return (
			<MinutesPersonalNotesContainer
				meetingId={meetingId}
				objId={objId}
				agendaItemMinutes={agendaItemMinutes}
				onChange={this.onChange}
			/>
		);
	};

	renderPreviousMeeting = () => {
		const { agendaItem, meeting, isSimpleMode } = this.props;

		if (!agendaItem || agendaItem.get("internalType") !== "previousMeeting") {
			return null;
		}

		return (
			<PreviousMeetingPanelContainer
				fieldName="internalData.previousMeetingId"
				selectedPreviousMeeting={agendaItem.getIn([
					"internalData",
					"previousMeetingId",
				])}
				currentMeetingId={meeting && meeting.get("id")}
				currentMeetingGroupId={meeting && meeting.get("groupId")}
				onChange={this.onChange}
				readOnly={isSimpleMode}
				nakedStyle={isSimpleMode}
			/>
		);
	};

	renderNextMeetingContainer = () => {
		const { meeting, agendaItem, isSimpleMode } = this.props;

		if (!agendaItem || agendaItem.get("internalType") !== "nextMeeting") {
			return null;
		}

		const newNextMeeting = Map({
			groupId: meeting ? meeting.get("groupId") : null,
			permissions: meeting && meeting.get("permissions"),
		});
		const nextMeeting =
			agendaItem.getIn(["internalData", "meeting"]) || newNextMeeting;
		const useExistingMeeting = agendaItem.getIn([
			"internalData",
			"useExistingMeeting",
		]);

		return (
			<NextMeetingDetailsContainer
				onChange={this.onChange}
				nextMeeting={nextMeeting}
				readOnly={isSimpleMode}
				useExistingMeeting={useExistingMeeting}
			/>
		);
	};

	renderFeedbackPanel = () => {
		const {
			agendaItem,
			meeting,
			location: { pathname },
		} = this.props;

		if (!meeting) {
			return null;
		}

		const isProtocolMode = pathname.indexOf("/review") >= 0;
		const hasFeedback = agendaItem.get("hasFeedback") === true;

		if (!isProtocolMode || !hasFeedback) {
			return null;
		}

		const objId = `${meeting.get("id")}$${agendaItem.get("id")}`;

		return (
			<FeedbackPanel>
				<CommentsListContainer
					objType={OBJ_TYPE_AGENDA_ITEM_FEEDBACK}
					objId={objId}
					objTitle={agendaItem && agendaItem.get("proposal")}
					objUrl={window.location.href}
					withoutPanel
				/>
			</FeedbackPanel>
		);
	};

	renderTagsPanel = () => {
		const { agendaItem } = this.props;
		const itemHasSubItems =
			agendaItem && agendaItem.get("agendaItems", List()).size > 0;

		if (agendaItem && agendaItem.has("internalType")) {
			return null;
		}

		return (
			<TagsPanelContainer
				onChange={this.onChange}
				value={agendaItem && agendaItem.get("tags")}
				readOnly={itemHasSubItems}
				menuPlacement="top"
			/>
		);
	};

	renderTopicNotesBlock = () => {
		const { agendaItem } = this.props;

		if (!canViewTopicNotes(agendaItem)) {
			return null;
		}

		const topicNotes = agendaItem.get("topicNotes");

		try {
			const contentState = convertToContentState(topicNotes);
			if (!contentState.hasText()) {
				return null;
			}
		} catch (e) {
			console.error(e);
		}

		return (
			<Margin top={0} bottom={4}>
				<SimpleDarkInfoPanel flexDirection="column">
					<Text
						color="white"
						bold={600}
						tid="meetings.agenda.topic_notes.block.title"
					/>
					<br />
					<Text color="white" asHtml>
						{convertToHtml(topicNotes)}
					</Text>
				</SimpleDarkInfoPanel>
			</Margin>
		);
	};

	render() {
		const { isSimpleMode, agendaItem } = this.props;
		if (agendaItem.get("archived")) {
			return this.renderPersonalNotes();
		}

		/*
			For future reference:
			If the attendee is in simple mode and the agenda topic is of Previous Meeting type we show the link to the previous meeting above
			the personal notes due to task IPS-4659 (https://invono.atlassian.net/browse/IPS-4659)
		*/

		if (!isSimpleMode) {
			return (
				<>
					{this.renderDetails()}
					{this.renderPreviousMeeting()}
					{this.renderNextMeetingContainer()}
					{this.renderFeedbackPanel()}
					{this.renderAttachmentsContainer()}
					{this.renderExternalTasksList()}
					{this.renderTagsPanel()}
				</>
			);
		} else {
			return (
				<Box direction="column">
					<Panel mode="transparent">
						{this.renderTopicNotesBlock()}
						{this.renderAttachmentsContainer()}
						{this.renderPreviousMeeting()}
						{this.renderPersonalNotes()}
					</Panel>
				</Box>
			);
		}
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meeting: store.meetings.get("meeting"),
		agendaItem: store.meetings.get("agendaItem", Map()) || Map(),
		usersCache: store.usersCache.get("usersCache"),
		company: store.company.company,
		userObj: store.user.get("userObj"),
		userId: store.user.getIn(["userObj", "id"]),
		i18n: store.i18n,
		hasExtendedRights: store.meetings.getIn([
			"meeting",
			"computedValues",
			"hasExtendedRights",
		]),
		isSimpleMode: store.meetings.getIn([
			"meeting",
			"computedValues",
			"isSimpleMode",
		]),
		audit: store.audit.get("meetings"),
		investors: store.investors.get("investorsCache"),
		isSecretary:
			store.meetings.getIn(["meeting", "secretary"]) ===
			store.user.getIn(["userObj", "id"]),
		hasMeetingStarted: store.meetings.getIn([
			"meeting",
			"computedValues",
			"meetingIsStarted",
		]),
	};
};

const mapActionsToProps = {
	fetchAgendaItem,
	saveMeeting,
	fetchSimpleUsers,
	openModal,
	fetchMeeting,
	updateMeetingLocal,
	saveAgendaItem,
	fetchAndCacheInvestors,
	meetingPushLiveUpdate,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(AgendaDetailsContainer);
