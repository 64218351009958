import React, { PureComponent } from "react";
import { func, bool, string } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import styled, { css } from "styled-components";
import { getUserName } from "../../../../components/helpers/users";
import ScrollView from "../../../shared/layout/scroll-view/scroll-view";
import List from "../../../shared/list/list";
import { StyledListHeaderTemplate } from "../../../shared/list/list-header.template";
import ListItem from "../../../shared/list-item/list-item";
import Text from "../../../shared/text/text";
import Tooltip from "../../../shared/tooltip/tooltip";
import { ATTENDEE_STATUS_PRESENT } from "/shared/constants";
import { findProxyByAttendeeId } from "../../../../components/helpers/meeting.helper";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import TopicAndQuestionPanel from "../topic-and-question-panel";

import {
	NUMBER_FORMAT_PERCENTAGE,
	NUMBER_FORMAT_INTEGER,
} from "/shared/constants";

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const StyledResultTableWrapper = styled.div`
	flex: 1;
`;

const StyledList = styled(List)`
	display: flex;
	padding: 0 ${(props) => props.theme.spacing["6-scrollOffset"]}
		${(props) => props.theme.spacing[5]};
	border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const StyledListItem = styled(ListItem)`
	display: flex;
	align-items: center;
	border-top: 1px solid ${(props) => props.theme.colors.border};
	margin-bottom: 0;

	:first-child {
		border-top: none;
	}

	:last-child {
		border-bottom: 1px solid ${(props) => props.theme.colors.border};
	}
`;

/* CSS templates */
const EllipsisTemplate = css`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const MarginTemplate = css`
	@media only screen and (max-width: 800px) {
		margin: 0 ${(props) => props.theme.spacing[2]};
	}

	margin: 0 ${(props) => props.theme.spacing[3]};
`;

/* Table Layout */
const StyledHeader = styled.div`
	${StyledListHeaderTemplate}
	${EllipsisTemplate}

	> div {
		cursor: default;
	}
`;

const StyledName = styled.div`
	${EllipsisTemplate}
	${MarginTemplate}
	min-width: 50px;
	flex: 1;
`;

const StyledInfo = styled.div`
	${EllipsisTemplate}
	${MarginTemplate}
	min-width: 50px;
	flex: 1;
`;

const StyledNumberOfShares = styled.div`
	${EllipsisTemplate}
	${MarginTemplate}
	min-width: 100px;
	text-align: right;
`;

const StyledVotingRightsPercantage = styled.div`
	${EllipsisTemplate}
	${MarginTemplate}
	min-width: 100px;
	text-align: right;
`;

const StyledVotingResult = styled.div`
	${EllipsisTemplate}
	${MarginTemplate}
	min-width: 100px;
	text-align: right;
`;

const StyledSummaryWrapper = styled.div`
	${EllipsisTemplate}
	padding: 0 ${(props) => props.theme.spacing["6-scrollOffset"]};
	display: flex;
`;

const StyledSummary = styled.div`
	min-width: 300px;
	display: flex;
	flex-direction: column;
`;

const StyledSummaryRow = styled.div`
	display: flex;
	padding: ${(props) => props.theme.spacing[1]} 0;
	border-bottom: 1px solid ${(props) => props.theme.colors.border};

	:last-child {
		border-bottom: none;
	}
`;

const StyledTopicAndQuestionWrapper = styled.div`
	padding: ${(props) => props.theme.spacing[5]}
		${(props) => props.theme.spacing["6-scrollOffset"]} 0;
`;

const HeaderText = ({ tid, tooltipTid }) => (
	<Tooltip tid={tooltipTid} delayShow="short">
		<Text tid={tid} bold={400} color="lightGrey" singleLine />
	</Tooltip>
);

const TextVotingResult = ({ result }) => {
	let textColor;

	if (result === "NOT_VOTED") {
		return <Text>-</Text>;
	}

	switch (result) {
		case "YES":
			textColor = "green";
			break;
		case "NO":
			textColor = "red";
			break;
		case "ABSTAIN":
			textColor = "yellowMid";
			break;
	}

	return <Text color={textColor} tid={`meetings.voting.result.${result}`} />;
};

export default class ResultView extends PureComponent {
	static propTypes = {
		votes: immutablePropTypes.map,
		investors: immutablePropTypes.list,
		usersCache: immutablePropTypes.map,
		sharesSnapshot: immutablePropTypes.map,
		results: immutablePropTypes.map,
		renderChangeResultSelect: func,
		votingIsSealed: bool,
		proxies: immutablePropTypes.map,
		topicRef: string,
		question: string,
	};

	getUserName = (attendee) => {
		const { usersCache, investors } = this.props;
		return getUserName(attendee, investors, usersCache);
	};

	getOtherInfo = (attendee) => {
		const { attendees, proxies } = this.props;
		const proxy = findProxyByAttendeeId(proxies, attendee.get("userId"));
		const isAssistant = attendee.get("isAssistant");
		const isPresent = attendee.get("status", ATTENDEE_STATUS_PRESENT);

		if (proxy && isPresent) {
			return {
				infoTid: "meetings.agm.attendee.rollcall.info.proxy",
				infoValues: { name: proxy.get("name") },
			};
		}

		if (isAssistant) {
			// todo fetch assistants in a better way that do not mean that we need to bind all attendees
			const assistantObject = attendees.get(
				attendee.get("assistantForAttendee"),
			);
			const assistantName = this.getUserName(assistantObject);

			return {
				infoTid: "meetings.attendee.assistant_of_attendee",
				infoValues: { name: assistantName },
			};
		}

		return {};
	};

	renderVoterItem = (voter, voterId) => {
		const { sharesSnapshot, renderChangeResultSelect, votingIsSealed } =
			this.props;
		const attendee = voter.get("attendee");

		const name = this.getUserName(attendee);
		const { infoTid, infoValues } = this.getOtherInfo(attendee);
		const shares = sharesSnapshot && sharesSnapshot.get(voterId);

		const ResultComponent = votingIsSealed ? (
			<TextVotingResult result={voter.get("result")} />
		) : (
			renderChangeResultSelect(voterId)
		);

		return (
			<StyledListItem key={voterId} hoverColor="lightestGrey" striped>
				<StyledName>
					<Text singleLine>{name ? name : "-"}</Text>
				</StyledName>

				<StyledInfo>
					{infoTid && <Text tid={infoTid} values={infoValues} singleLine />}
				</StyledInfo>

				<StyledNumberOfShares>
					{shares && (
						<Text>
							{localeFormatNumber(
								shares.get("numOfShares"),
								NUMBER_FORMAT_INTEGER,
							)}
						</Text>
					)}
				</StyledNumberOfShares>

				<StyledVotingRightsPercantage>
					{shares && (
						<Text>
							{localeFormatNumber(
								shares.get("votingRightPercentage"),
								NUMBER_FORMAT_PERCENTAGE,
							)}
						</Text>
					)}
				</StyledVotingRightsPercantage>

				<StyledVotingResult>{ResultComponent}</StyledVotingResult>
			</StyledListItem>
		);
	};

	renderHeader = () => {
		return (
			<StyledHeader>
				<StyledName>
					<HeaderText
						tid="meetings.votings.result.heading.name"
						tooltipTid="meetings.votings.result.tooltip.name"
					/>
				</StyledName>

				<StyledInfo>
					<HeaderText
						tid="meetings.votings.result.heading.info"
						tooltipTid="meetings.votings.result.tooltip.info"
					/>
				</StyledInfo>

				<StyledNumberOfShares>
					<HeaderText
						tid="meetings.votings.result.heading.number_of_shares"
						tooltipTid="meetings.votings.result.tooltip.number_of_shares"
					/>
				</StyledNumberOfShares>

				<StyledVotingRightsPercantage>
					<HeaderText
						tid="meetings.votings.result.heading.voting_rights_percantage"
						tooltipTid="meetings.votings.result.tooltip.voting_rights_percantage"
					/>
				</StyledVotingRightsPercantage>

				<StyledVotingResult>
					<HeaderText
						tid="meetings.votings.result.heading.result"
						tooltipTid="meetings.votings.result.tooltip.result"
					/>
				</StyledVotingResult>
			</StyledHeader>
		);
	};

	renderSummaryTable = () => {
		const { results, votes, sharesSnapshot } = this.props;

		return (
			<StyledSummaryWrapper>
				<StyledName /> {/* Spacer */}
				<StyledSummary>
					{results &&
						results
							.map((result, index) => {
								const voterIds =
									votes &&
									votes.filter((vote) => vote.get("result") === index).keySeq();
								let sumOfShares = 0;
								let sumOfRightsPercentage = 0;

								if (sharesSnapshot) {
									voterIds &&
										voterIds.forEach((id) => {
											sumOfShares +=
												sharesSnapshot.getIn([id, "numOfShares"], 0) || 0;
											sumOfRightsPercentage +=
												sharesSnapshot.getIn(
													[id, "votingRightPercentage"],
													0,
												) || 0;
										});
								}

								return (
									<StyledSummaryRow key={index}>
										<StyledVotingResult>
											<Text
												bold={600}
												tid={`meetings.voting.result.${index}`}
											/>
											:
										</StyledVotingResult>
										<StyledNumberOfShares>
											<Text singleLine>
												{localeFormatNumber(sumOfShares, NUMBER_FORMAT_INTEGER)}
											</Text>
										</StyledNumberOfShares>
										<StyledVotingRightsPercantage>
											<Text singleLine>
												{localeFormatNumber(
													sumOfRightsPercentage,
													NUMBER_FORMAT_INTEGER,
													{
														round: 3,
													},
												)}
											</Text>
										</StyledVotingRightsPercantage>
										<StyledVotingResult>
											<Text singleLine>
												{localeFormatNumber(
													result.get("numOfVotes"),
													NUMBER_FORMAT_INTEGER,
												)}
											</Text>
										</StyledVotingResult>
									</StyledSummaryRow>
								);
							})
							.toList()}
				</StyledSummary>
			</StyledSummaryWrapper>
		);
	};

	render = () => {
		const { votes, topicRef, question } = this.props;

		return (
			<StyledWrapper>
				<StyledResultTableWrapper>
					<ScrollView noRightMargin noLeftMargin fillContent alwaysShow>
						<StyledTopicAndQuestionWrapper>
							<TopicAndQuestionPanel topicRef={topicRef} question={question} />
						</StyledTopicAndQuestionWrapper>
						<StyledList header={this.renderHeader}>
							{votes && votes.map(this.renderVoterItem).toList()}
						</StyledList>
					</ScrollView>
				</StyledResultTableWrapper>
				{this.renderSummaryTable()}
			</StyledWrapper>
		);
	};
}
