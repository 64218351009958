import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { fetchSimpleUsers } from "../../actions/usersCache.actions";
import { List } from "immutable";
import { getEmail } from "/shared/helpers/users.helpers";
import { useParams } from "react-router";

const SubscriptionSlipErrorState = ({ header, description }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { companyId } = useParams();
	const company = useSelector(
		(state) => state.subscriptionSlip?.displayData?.company,
	);
	const emissionContactUserId = useSelector(
		(state) => state.subscriptionSlip?.displayData?.emission?.emissionContact,
	);
	const emissonContactPerson = useSelector((state) =>
		state.usersCache.getIn(["usersCache", emissionContactUserId]),
	);

	useEffect(() => {
		dispatch(fetchSimpleUsers(List([emissionContactUserId])));
	}, [emissionContactUserId]);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				width: "100%",
				mt: "150px",
			}}
		>
			<Box sx={{ display: "flex", flexDirection: "column", width: "520px" }}>
				<Typography variant="h2">{t(header)}</Typography>
				<Typography variant="body2" sx={{ fontSize: "13px", my: 3 }}>
					{t(description)}
				</Typography>
				<Typography
					variant="body1"
					sx={{ fontSize: "13px", fontWeight: 600, mt: 8 }}
				>
					{t("emission.company_contact", { company: company.name })}
				</Typography>
				{emissonContactPerson && (
					<Typography variant="body2" sx={{ fontSize: "13px" }}>
						{emissonContactPerson.get("name")}
						<br />
						{t("emission.company_contact.email", {
							email: getEmail(emissonContactPerson?.toJS(), companyId),
						})}
						<br />
						{t("emission.company_contact.phone", {
							phone: emissonContactPerson.get("phone"),
						})}
					</Typography>
				)}
				<Typography variant="body2" sx={{ fontSize: "13px", mt: 3 }}>
					{t("emission.company_contact.text")}
				</Typography>
			</Box>
		</Box>
	);
};

export default SubscriptionSlipErrorState;
