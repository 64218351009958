import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";

import { ButtonWithIcon } from "../../../dumb-components/shared/button";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";

import { notifyFeedbackees } from "../../../actions/meetings.actions";

class RequestFeedbackButtonContainer extends Component {
	state = {
		isLoading: false,
	};

	getNoOneNotified = () => {
		const { feedbackees } = this.props;
		return !feedbackees.every((f) => f.get("notified"));
	};

	getSomeNotified = () => {
		const { feedbackees } = this.props;
		return feedbackees.some((f) => f.get("notified"));
	};

	getTooltipData = () => {
		let activeState;
		let disabled;

		// switchDisabled={signingInProgress || alreadySigned || alreadyLeftFeedback || protocolPublished}

		return { activeState, disabled };
	};

	requestFeedback = () => {
		const { notifyFeedbackees, meetingId } = this.props;

		this.setState({ isLoading: true });

		notifyFeedbackees(meetingId, () => {
			this.setState({ isLoading: false });
		});
	};

	render = () => {
		const { isLoading } = this.state;

		return (
			<Tooltip>
				<ButtonWithIcon
					mode="primary"
					icon="faPaperPlane"
					tid="meeting.smart.feedback.panel.button.request_feedback"
					color="white"
					iconColor="white"
					isLoading={isLoading}
					onClick={this.requestFeedback}
				/>
			</Tooltip>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meetingId: store.meetings.getIn(["meeting", "id"]),
		feedbackees: store.meetings.getIn(["meeting", "feedbackees"], Map()),
	};
};

const mapActionsToProps = {
	notifyFeedbackees,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(RequestFeedbackButtonContainer);
