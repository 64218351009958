import React, { Component } from "react";
import { string, bool } from "prop-types";
import { withTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";

class Input extends Component {
	state = {
		value: null,
	};

	static propTypes = {
		className: string,
		disabled: bool,
		name: string,
		placeholder: string,
		value: string,
		type: string,
	};

	static defaultProps = {
		className: "form-control",
		type: "text",
	};

	onChange = (event) => {
		const { type, onChange, name, sharedState } = this.props;

		if (type === "radio") {
			onChange(event.target.value);
			return;
		}

		if (type === "checkbox") {
			onChange(event.target.checked);
			return;
		}

		if (sharedState) {
			onChange({ [name]: event.target.value });
			return;
		}

		onChange(event.target.value);
	};

	render = () => {
		const {
			name,
			disabled,
			className,
			type,
			value,
			checked,
			placeholderTid,
			variant,
			t,
		} = this.props;
		let { placeholder } = this.props;

		if (!placeholder && placeholderTid) {
			placeholder = t(placeholderTid);
		}

		if (type === "radio") {
			return (
				<Radio
					variant={variant}
					disabled={disabled}
					onChange={this.onChange}
					value={value}
					checked={checked}
				/>
			);
		}

		return (
			<input
				style={{ minHeight: "30px" }}
				className={className}
				value={value}
				type={type}
				name={name}
				placeholder={placeholder}
				disabled={disabled}
				onChange={this.onChange}
				checked={checked}
			/>
		);
	};
}

export default withTranslation()(Input);
