// OBS: When using, always bind 'this'
export const setRef = (refsObject, key, reference) => {
	if (!refsObject[key]) {
		refsObject[key] = {};
	}

	refsObject[key].ref = reference;
};

export const getRef = (refsObject, key) => {
	if (!refsObject[key]) {
		refsObject[key] = {};
	}

	return refsObject[key];
};

const hasOwnProperty = Object.prototype.hasOwnProperty;

export const toggleMenu = (refObject) => {
	if (!refObject) {
		return;
	}

	if (hasOwnProperty.call(refObject, "ref")) {
		refObject.ref.onToggleMenu();
	} else {
		refObject.onToggleMenu();
	}
};
