import React from "react";
import styled, { css } from "styled-components";
import { Margin } from "styled-components-spacing";
import Text from "../../shared/text/text";
import Button from "../../shared/button/button";
import { object, func, bool, string } from "prop-types";
import { lighten, rgba } from "polished";
import Icon from "../../shared/icon/icon";
import Tooltip from "../tooltip/tooltip";
import DocumentsHelper from "../../../components/helpers/documents.helper";
import NotificationBadge from "../notification-badge/notification-badge";

import DndDragHandlers, {
	hoverStyling,
} from "../dnd-drag-handlers/dnd-drag-handlers";
import ModernFilePreview from "../modern-file-preview/modern-file-preview";

const StyledLinkDocumentBody = styled.div`
	display: flex;
	justify-content: space-between;
	background: ${(props) =>
		props.transparent === true || props.nakedStyle
			? "transparent"
			: props.theme.colors.solitudeLight};
	align-items: center;
	width: 100%;
	height: 50px;
	border: ${(props) =>
		props.nakedStyle
			? "none"
			: `1px solid ${(props) => lighten(0.2, props.theme.colors.lightGrey)}`};

	${(props) =>
		props.nakedStyle &&
		css`
			border-bottom: 1px solid ${rgba("#d0d0d0", 0.2)};
		`}
`;

const StyledDndDragHandlers = styled(DndDragHandlers)`
	background-color: ${(props) =>
		props.nakedStyle ? "transparent" : props.theme.colors.solitudeLight};

	${(props) =>
		!props.nakedStyle &&
		css`
			border-right: 1px solid
				${(props) => lighten(0.2, props.theme.colors.lightGrey)};
		`}

	${StyledLinkDocumentBody}:hover & {
		${hoverStyling}
	}
`;

const StyledIconDiv = styled.div`
	display: flex;
	align-items: center;

	width: fit-content;
	background: ${(props) =>
		props.transparent === true || props.nakedStyle
			? "transparent"
			: props.theme.colors.solitudeLight};
	border-right: ${(props) =>
		props.nakedStyle
			? "none"
			: `1px solid ${(props) => lighten(0.2, props.theme.colors.lightGrey)}`};
	height: 100%;

	${(props) =>
		props.addLeftPadding &&
		css`
			padding-left: ${(props) => props.theme.spacing[3]};
		`}
`;

const StyledFlexBox = styled.div`
	display: flex;
	button[class^="button"],
	[class^="dropdown_menu"] {
		margin-left: ${(props) => props.theme.spacing[2]};
	}
`;

const FilePreview = ({
	document,
	downloadButtonMode,
	downloadButtonTid,
	downloadTextColor,
	onDownloadFile,
	onOpenFile,
	transparent,
	dragHandleProps,
	isDragDisabled,
	readOnly,
	dropdownRenderer,
	dropdownDeleteOnlyRenderer,
	fileIcon,
	nakedStyle,
	preControlsRenderer,
	fileIconTooltipTid,
	notifyBadge,
	notifyBadgeColor,
}) => {
	if (!document) {
		return null;
	}

	const documentIsViewableInBrowser = DocumentsHelper.isViewableInBrowser(
		document.get("file"),
	);
	const { webViewerSupportedFormat, webViewerSupportedSize } =
		DocumentsHelper.getWebViewerIsSupported(document.get("file"));
	const onOpenFileDelayShow = documentIsViewableInBrowser ? "short" : "instant";

	let openFileTid;
	if (documentIsViewableInBrowser) {
		openFileTid = "generic.open";
	} else if (!webViewerSupportedFormat) {
		openFileTid = "tooltip.documents.action.unable_open_document_format";
	} else if (!webViewerSupportedSize) {
		openFileTid = "tooltip.documents.action.unable_open_document_size";
	}

	return (
		<StyledLinkDocumentBody transparent={transparent} nakedStyle={nakedStyle}>
			{dragHandleProps && (
				<StyledDndDragHandlers
					isDragDisabled={isDragDisabled}
					dragHandleProps={dragHandleProps}
					nakedStyle={nakedStyle}
				/>
			)}

			<StyledIconDiv
				transparent={transparent}
				nakedStyle={nakedStyle}
				addLeftPadding={fileIcon !== "faFile"}
			>
				<Tooltip tid={fileIconTooltipTid} delayShow="short">
					<NotificationBadge
						active={notifyBadge}
						color={notifyBadgeColor}
						x={13}
					>
						<ModernFilePreview
							document={document}
							readOnly={true}
							nakedStyle={true}
							color="midGrey"
						/>
					</NotificationBadge>
				</Tooltip>
			</StyledIconDiv>
			<Margin horizontal={3}>
				<StyledFlexBox>
					{preControlsRenderer && preControlsRenderer(document)}

					{onOpenFile && !readOnly && !dropdownDeleteOnlyRenderer ? (
						<>
							<Tooltip tid={openFileTid} delayShow={onOpenFileDelayShow}>
								<Button
									onClick={() => {
										onOpenFile(document);
									}}
									disabled={!documentIsViewableInBrowser}
								>
									<Icon icon="faExpandArrowsAlt" size="sm" />
								</Button>
							</Tooltip>

							{dropdownRenderer && dropdownRenderer(document)}
						</>
					) : (
						<>
							{onOpenFile && (
								<Tooltip tid={openFileTid} delayShow={onOpenFileDelayShow}>
									<Button
										onClick={() => {
											onOpenFile(document);
										}}
										disabled={!documentIsViewableInBrowser}
										mode={nakedStyle ? "transparent-icon" : undefined}
										icon={nakedStyle ? "faExpandArrowsAlt" : undefined}
										transparentIconButtonSize={nakedStyle ? "sml" : undefined}
									>
										{!nakedStyle && <Icon icon="faExpandArrowsAlt" size="sm" />}
									</Button>
								</Tooltip>
							)}
							<Tooltip tid="documents.download" delayShow="short">
								<Button
									onClick={() => {
										onDownloadFile && onDownloadFile(document);
									}}
									mode={nakedStyle ? "transparent-icon" : downloadButtonMode}
									icon={nakedStyle ? "faArrowAltToBottom" : undefined}
									transparentIconButtonSize={nakedStyle ? "sml" : undefined}
								>
									{!nakedStyle && (
										<>
											{downloadButtonTid ? (
												<>
													<Margin right={3}>
														<Icon
															icon="faArrowAltToBottom"
															size="xs"
															color={downloadTextColor}
														/>
													</Margin>
													<Text
														tid={downloadButtonTid}
														color={downloadTextColor}
													/>
												</>
											) : (
												<Icon
													icon="faArrowAltToBottom"
													size="sm"
													color={downloadTextColor}
												/>
											)}
										</>
									)}
								</Button>
							</Tooltip>

							{!readOnly &&
								dropdownDeleteOnlyRenderer &&
								dropdownDeleteOnlyRenderer(document)}
						</>
					)}
				</StyledFlexBox>
			</Margin>
		</StyledLinkDocumentBody>
	);
};

FilePreview.defaultProps = {
	isOpen: false,
	downloadButtonMode: "default",
	fileIcon: "faFile",
};

FilePreview.propTypes = {
	document: object,
	onOpenFile: func,
	transparent: bool,
	dragHandleProps: object,
	downloadButtonMode: string,
	downloadButtonTid: string,
	preControlsRenderer: func,
	fileIcon: string,
	readOnly: bool,
	dropdownRenderer: func,
	dropdownDeleteOnlyRenderer: func,
	nakedStyle: bool,
	fileIconTooltipTid: string,
	documentToolTipTid: string,
	notifyBadge: bool,
	notifyBadgeColor: string,
};

export default FilePreview;
