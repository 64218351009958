import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";

import { OutlinedButton } from "../../../../dumb-components/shared/button-v2";
import DropdownMenuContainer from "../../../shared/dropdown-menu.container";
import CancelEsignOrPublishProcess from "../../../documents/esign/cancel-esign-or-publish-process.container";
import DropdownIconItem from "../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import PublishWithoutEsignPanel from "../../../../dumb-components/meetings/publish-without-esign-panel/publish-without-esign-panel";
import { ButtonWithIcon } from "../../../../dumb-components/shared/button";

import PublishProtocolComponentContainer from "../../protocol/publish-protocol-component.container";
import SharedProtocolFilePreviewContainer from "./shared-protocol-file-preview.container";

import { unpublishProtocol } from "../../../../actions/meetings.actions";
import { MEETING_TYPE_SMART } from "/shared/constants";

class PublishWithoutSignPanelContainer extends Component {
	dropdownRef = null;

	toggleDropdown = () => {
		this.dropdownRef && this.dropdownRef.onToggleMenu();
	};

	unpublishProtocol = () => {
		const { unpublishProtocol, meeting } = this.props;
		unpublishProtocol(meeting);
	};

	renderPublishButton = () => {
		const { meeting } = this.props;
		const isSmartMeeting = meeting.get("meetingType") === MEETING_TYPE_SMART;

		if (isSmartMeeting) {
			return (
				<PublishProtocolComponentContainer
					renderComponent={({ openModal }) => (
						<ButtonWithIcon
							mode="primary"
							tid="meetings.protocol.publish.btn.publish"
							icon="faShareAll"
							color="white"
							iconColor="white"
							onClick={openModal}
						/>
					)}
				/>
			);
		}

		return (
			<PublishProtocolComponentContainer
				renderComponent={({ openModal }) => (
					<ButtonWithIcon
						mode="primary"
						tid="meetings.protocol.publish.btn.publish"
						icon="faShareAll"
						color="white"
						iconColor="white"
						onClick={openModal}
					/>
				)}
			/>
		);
	};

	renderPublishMainControls = () => {
		return (
			<DropdownMenuContainer
				halignMenu="right"
				ref={(ref) => (this.dropdownRef = ref)}
				onClick={this.toggleDropdown}
				renderRaw={<OutlinedButton icon="faEllipsisV" leftMargin />}
			>
				<CancelEsignOrPublishProcess onClick={this.toggleDropdown} />
			</DropdownMenuContainer>
		);
	};

	renderUnpublishControls = () => {
		return (
			<DropdownMenuContainer
				halignMenu="right"
				renderRaw={<OutlinedButton icon="faEllipsisV" leftMargin />}
			>
				<DropdownIconItem
					icon="faTimes"
					tid="meetings.protocol.publish.published.dropdown.unpublish"
					onClick={this.unpublishProtocol}
				/>
			</DropdownMenuContainer>
		);
	};

	render = () => {
		const { protocolSharedAt } = this.props;

		if (protocolSharedAt) {
			return <SharedProtocolFilePreviewContainer />;
		}

		return (
			<PublishWithoutEsignPanel
				mainControlsComponent={this.renderPublishMainControls()}
				protocolSharedAt={protocolSharedAt}
				footerComponent={this.renderPublishButton()}
				unpublishControlsComponent={this.renderUnpublishControls()}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get("meeting", Map()),
		protocolSharedAt: store.meetings.getIn([
			"meeting",
			"metadata",
			"protocolSharedAt",
		]),
	};
};

const mapActionsToProps = {
	unpublishProtocol,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(PublishWithoutSignPanelContainer);
