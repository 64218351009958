import styled, { css } from "styled-components";

const StyledStatusRectangle = styled.div`
	display: flex;
	height: 20px;
	width: 20px;
	margin-right: ${(props) => props.theme.spacing[3]};

	${(props) =>
		props.color &&
		css`
			background: ${(props) => props.theme.colors[props.color]};
		`}
`;

export default StyledStatusRectangle;
