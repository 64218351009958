import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GRID_MODAL } from "../../../constants/modals";
import { GRID_MODE_PANEL, GRID_MODE_MODAL } from "/shared/constants";
import { openModal } from "../../../actions/modals.actions";
import { withTranslation } from "react-i18next";
import AgGrid from "../../../dumb-components/shared/ag-grid/ag-grid";
import ExportExcelButton from "../../../dumb-components/investment/shareholders/export-excel-button";
import Moment from "../../../modules/moment.module";
import localeFormatNumber from "../../../modules/format-number";
import {
	NUMBER_FORMAT_CURRENCY,
	NUMBER_FORMAT_INTEGER,
} from "/shared/constants";
import { getShareTypeLabel } from "../../../components/helpers/shares";
import { List, Map } from "immutable";
import { columnTypes } from "../../../dumb-components/shared/ag-grid/column-defs/column-defs";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import GridPanelRightButtons from "../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons";
import Text from "../../../dumb-components/shared/text/text";

const SharebookCompanyLedgerGridContainer = ({ t }) => {
	const investment = useSelector((state) =>
		state.capTableDetails.get("combinedSingleInvestmentAndAllTransactions"),
	);

	const gridRef = useRef();

	const [colDefs, setColDefs] = useState(null);
	const colTypes = columnTypes();
	const [rowData, setRowData] = useState(null);
	const companyName = useSelector(
		(state) =>
			state.capTableDetails.get("aliasOfTheInvestedCompany") ||
			state.capTableDetails.getIn([
				"combinedSingleInvestmentAndAllTransactions",
				"investedInCompanyInformation",
				"name",
			]),
	);

	const gridOption = "sharebookCompanyLedger";

	const isGridModalOpen = useSelector((state) => {
		return state.modals.getIn(["activeModal", "options"]) === gridOption;
	});

	useEffect(() => {
		if (!investment) {
			return null;
		}
		setRowData(getRowData());
		setColDefs(getColumnDefs());
	}, [investment]);

	const dispatch = useDispatch();

	const getRowData = useCallback(() => {
		let transactions = formattedTransactions();
		const shareTypes = investment.getIn(["details", "types"], List());

		let newRowData = List();
		let numOfTotalCompanyShares = Map({
			title: t(
				"investments.sharebook.company_ledger.grid.row_title.total_number_of_shares",
			),
		});
		let shareCapital = Map({
			title: t(
				"investments.sharebook.company_ledger.grid.row_title.share_capital",
			),
		});
		let quotaValue = Map({
			title: t(
				"investments.sharebook.company_ledger.grid.row_title.quota_value",
			),
		});
		let numOfTotalShares = Map({
			title: t(
				"investments.sharebook.company_ledger.grid.row_title.new_shares",
			),
		});
		let raised = Map({
			title: t(
				"investments.sharebook.company_ledger.grid.row_title.investments_invested_capital",
			),
		});
		let sharePremium = Map({
			title: t(
				"investments.sharebook.company_ledger.grid.row_title.share_premium",
			),
		});
		let postMoney = Map({
			title: t(
				"investments.sharebook.company_ledger.grid.row_title.company_valuation",
			),
		});
		let shareTypeData = shareTypes.map((shareType) => {
			return Map({
				title: t("value_of_share_N").replace(
					"{shareType}",
					getShareTypeLabel(shareType.get("type")),
				),
				type: shareType.get("type"),
			});
		});

		transactions.forEach((transaction, index) => {
			const transactionDate = Moment(transaction.get("date")).format("L");
			const dateIndexKey = transactionDate + "#" + index;

			numOfTotalCompanyShares = numOfTotalCompanyShares.set(
				dateIndexKey,
				localeFormatNumber(
					transaction.getIn(["shareData", "numOfTotalShares"]),
					NUMBER_FORMAT_INTEGER,
				),
			);

			shareCapital = shareCapital.set(
				dateIndexKey,
				localeFormatNumber(
					transaction.getIn(["shareData", "shareCapital"]),
					NUMBER_FORMAT_CURRENCY,
				),
			);

			quotaValue = quotaValue.set(
				dateIndexKey,
				localeFormatNumber(
					transaction.getIn(["shareData", "quotaValue"]),
					NUMBER_FORMAT_CURRENCY,
					{ round: 4 },
				),
			);

			numOfTotalShares = numOfTotalShares.set(
				dateIndexKey,
				localeFormatNumber(
					transaction.getIn(["shareData", "numOfTotalSharesDiff"]),
					NUMBER_FORMAT_INTEGER,
				),
			);

			raised = raised.set(
				dateIndexKey,
				localeFormatNumber(
					transaction.getIn(["shareData", "raisedDiff"]),
					NUMBER_FORMAT_CURRENCY,
				),
			);

			sharePremium = sharePremium.set(
				dateIndexKey,
				localeFormatNumber(
					transaction.getIn(["shareData", "sharePremiumDiff"]),
					NUMBER_FORMAT_CURRENCY,
				),
			);

			postMoney = postMoney.set(
				dateIndexKey,
				localeFormatNumber(
					transaction.getIn(["shareData", "postMoney"]),
					NUMBER_FORMAT_CURRENCY,
				),
			);

			const transactionShareTypes = transaction.getIn(
				["shareData", "types"],
				List(),
			);

			shareTypes.forEach((shareType) => {
				const shareTypeInTransaction = transactionShareTypes.find(
					(transactionShareType) =>
						shareType.get("type") === transactionShareType.get("type"),
				);
				let latestPrice = shareTypeInTransaction
					? shareTypeInTransaction.get("latestPrice")
					: null;

				shareTypeData = shareTypeData.map((shareTypeDataItem) => {
					if (shareType.get("type") === shareTypeDataItem.get("type")) {
						latestPrice = latestPrice
							? localeFormatNumber(latestPrice, NUMBER_FORMAT_CURRENCY)
							: "-";
						shareTypeDataItem = shareTypeDataItem.set(
							dateIndexKey,
							latestPrice,
						);
					}
					return shareTypeDataItem;
				});
			});
		});

		newRowData = newRowData.push(
			numOfTotalCompanyShares,
			shareCapital,
			quotaValue,
			numOfTotalShares,
			raised,
			sharePremium,
			postMoney,
		);
		newRowData = newRowData.concat(shareTypeData);

		return newRowData.toJS();
	});

	const formattedTransactions = () => {
		let transactions = investment.get("transactions").reverse();

		if (!transactions) {
			return null;
		}

		const fieldsToCompare = [
			["shareData", "numOfTotalCompanyShares"],
			["shareData", "shareCapital"],
			["shareData", "quotaValue"],
			["shareData", "numOfTotalShares"],
			["shareData", "raised"],
			["shareData", "sharePremium"],
			["shareData", "postMoney"],
		];

		let prevTransaction = null;

		transactions = transactions.filter((transaction) => {
			let isEqualToPrev = true;

			fieldsToCompare.forEach((fieldPath) => {
				if (
					(prevTransaction && prevTransaction.getIn(fieldPath)) !==
					transaction.getIn(fieldPath)
				) {
					isEqualToPrev = false;
				}
			});
			prevTransaction = transaction;
			return !isEqualToPrev;
		});

		return transactions;
	};

	const getColumnDefs = useCallback(() => {
		let transactions = formattedTransactions();
		let colDefs = [
			{
				field: "title",
				headerName: "",
				minWidth: 200,
				type: ["lockedFirstColumn", "widthDefault"],
			},
		];

		transactions.forEach((transaction, index) => {
			const transactionDate = Moment(transaction.get("date")).format("L");
			colDefs.push({
				field: transactionDate + "#" + index,
				headerName: transactionDate,
				minWidth: 200,
				type: "rightAligned",
			});
		});

		return colDefs;
	});

	const exportAsExcel = useCallback(() => {
		gridRef.current.api.exportDataAsExcel();
	});

	const openGridModal = useCallback(() => {
		dispatch(openModal(GRID_MODAL, gridOption));
	});

	const modalLeftHeader = {
		tid: "investments.sharebook.grid_panel.company_ledger.title",
		values: { companyName: companyName },
	};

	const onGridReady = (event) => {
		const allColumns = event.columnApi.getAllGridColumns();
		const lastColumn = allColumns[allColumns.length - 1];

		event.api.ensureColumnVisible(lastColumn.colId);
		event.columnApi.resetColumnState();
	};

	return (
		<Card>
			<CardHeader
				title={
					<Text
						tid={"investments.sharebook.grid_panel.company_ledger.title"}
						values={{ companyName: companyName }}
					/>
				}
				action={
					<GridPanelRightButtons
						exportAsExcel={exportAsExcel}
						openGridModal={openGridModal}
						downloadExcelTid={
							"investments.sharebook.dropdown_item.download_excel_list.company_ledger"
						}
						noFilter={true}
					/>
				}
			/>
			<CardContent variant="panel">
				<AgGrid
					mode={isGridModalOpen ? GRID_MODE_MODAL : GRID_MODE_PANEL}
					isGridModalOpen={isGridModalOpen}
					rowData={rowData}
					columnDefs={colDefs}
					rightHeaderComponent={() => {
						return (
							<ExportExcelButton
								exportAsExcel={exportAsExcel}
								noFilter={true}
							/>
						);
					}}
					modalLeftHeader={modalLeftHeader}
					gridRef={gridRef}
					columnTypes={colTypes}
					onGridReady={onGridReady}
				/>
			</CardContent>
		</Card>
	);
};

export default withTranslation()(SharebookCompanyLedgerGridContainer);
