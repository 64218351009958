import React, { PureComponent } from "react";
import { bool, func, oneOf } from "prop-types";
import styled from "styled-components";

import Modal from "../../shared/modal/modal";
import Text from "../../shared/text/text";
import ColoredContentWrapper from "../../shared/colored-content-wrapper/colored-content-wrapper";
import { TransparentButton } from "../../shared/button-v2";
import Icon from "../../shared/icon/icon";
import DropdownMenuContainer from "../../../containers/shared/dropdown-menu.container";
import DropdownItem from "../../shared/dropdown-item/dropdown-item";

const StyledContentWrapper = styled.div`
	padding: ${(props) => props.theme.spacing[5]} 0;
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
`;

const StyledIconWrapper = styled.div`
	padding-bottom: ${(props) => props.theme.spacing[3]};
`;

export default class DocumentDownloadSignedModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		onOpenOriginal: func,
		onOpenSigned: func,
		onDownloadOriginal: func,
		onDownloadSigned: func,
		onCancel: func,
		mode: oneOf(["DOWNLOAD", "VIEWER"]),
	};

	static defaultProps = {
		isOpen: false,
		mode: "DOWNLOAD",
	};

	dropdownRef = null;

	setDropdownRef = (ref) => {
		this.dropdownRef = ref;
	};

	modalMode = {
		DOWNLOAD: {
			txtInfo: "documents.esign.signed.download.info",
			btnOriginalAction: this.props.onDownloadOriginal,
			btnSignedAction: this.props.onDownloadSigned,
			btnOriginalTid: "documents.esign.signed.download.original",
			btnSignedTid: "documents.esign.signed.download.signed",
		},
		VIEWER: {
			txtInfo: "documents.esign.signed.open.info",
			btnOriginalAction: this.props.onOpenOriginal,
			btnSignedAction: this.props.onOpenSigned,
			btnOriginalTid: "documents.esign.signed.open.original",
			btnSignedTid: "documents.esign.signed.open.signed",
		},
	};

	renderContent = () => {
		const { mode } = this.props;

		return (
			<StyledContentWrapper>
				<StyledColoredContentWrapper type="secondary">
					<StyledIconWrapper>
						<Icon
							icon="faExclamationTriangle"
							size="normal"
							color="yellowMid"
							type="solid"
						/>
					</StyledIconWrapper>
					<Text tid={this.modalMode[mode].txtInfo} />
				</StyledColoredContentWrapper>
			</StyledContentWrapper>
		);
	};

	renderFooter = () => {
		const { onCancel, mode } = this.props;

		return (
			<>
				<DropdownMenuContainer
					renderRaw={
						<TransparentButton tid="collections.add_document.modal.options" />
					}
					ref={this.setDropdownRef}
					withPortal
				>
					<DropdownItem onClick={this.modalMode[mode].btnOriginalAction}>
						<Text tid={this.modalMode[mode].btnOriginalTid} />
					</DropdownItem>
					<DropdownItem onClick={this.modalMode[mode].btnSignedAction}>
						<Text tid={this.modalMode[mode].btnSignedTid} />
					</DropdownItem>
				</DropdownMenuContainer>
				<TransparentButton
					tid="generic.form.cancel"
					onClick={onCancel}
					textColor="midGrey"
				/>
			</>
		);
	};

	render = () => {
		const { isOpen } = this.props;

		return (
			<Modal
				isOpen={isOpen}
				vSize={50}
				hSize="md"
				footerComponent={this.renderFooter()}
				scrollableContent={false}
				title="documents.esign.signed.download.modal.title"
			>
				{this.renderContent()}
			</Modal>
		);
	};
}
