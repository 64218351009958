import { fromJS } from "immutable";
import { LIVE_REQUEST, LIVE_REQUEST_RESET } from "./types";

export function setLiveRequest(section, state) {
	return { type: LIVE_REQUEST, payload: fromJS({ section, state }) };
}

export function resetLiveRequest(section) {
	return { type: LIVE_REQUEST_RESET, payload: fromJS({ section }) };
}
