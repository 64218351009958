import { saveAs } from "file-saver";
import { fromJS } from "immutable";
import req from "../modules/request.module";
import {
	DELETE_PERSONAL_ATTACHMENT,
	LIST_PERSONAL_ATTACHMENTS,
	RESET_PERSONAL_ATTACHMENTS,
	LIST_MANY_PERSONAL_ATTACHMENTS,
} from "./types";

/**
 * Upload a new attachment
 * @param {string} objType
 * @param {uuid} objId
 * @param {Object} attachment
 * @param {Function} callback
 * @returns {void}
 */
export function uploadPersonalAttachment(
	objType,
	objId,
	attachments,
	callback,
) {
	return async function (dispatch) {
		const form = new FormData();

		for (const attachment of attachments) {
			form.append("files", attachment);
		}

		await req.post(`/gateway/personal-attachments/${objType}/${objId}`, form);
		dispatch(listPersonalAttachments(objType, objId));
		callback?.();
	};
}

/**
 * List all personal attachments
 * @param {string} objType
 * @param {uuid} objId
 * @returns {void}
 */
export function listPersonalAttachments(objType, objId) {
	return async function (dispatch) {
		const response = await req.get(`/documents/personal/${objType}/${objId}`);
		dispatch({
			type: LIST_PERSONAL_ATTACHMENTS,
			payload: { objType, objId, attachments: fromJS(response.data) },
		});
	};
}

/**
 * List all documents attached to objType
 * @param {string} objType
 * @returns {void}
 */
export function listManyPersonalAttachments(objType) {
	return async function (dispatch) {
		const response = await req.get(`/documents/personal/${objType}/all`);
		const attachments = fromJS(response.data);

		if (!attachments) {
			return;
		}

		const groupedAttachments = attachments.groupBy((attachment) =>
			attachment.getIn(["links", 0, "objId"]),
		);

		dispatch({
			type: LIST_MANY_PERSONAL_ATTACHMENTS,
			payload: { objType, groupedAttachments },
		});
	};
}

/**
 * Delete a personal attachment
 * @param {string} objType
 * @param {uuid} objId
 * @param {uuid} id
 * @returns {void}
 */
export function deletePersonalAttachment(objType, objId, id) {
	return async function (dispatch) {
		dispatch({
			type: DELETE_PERSONAL_ATTACHMENT,
			payload: { objType, objId, id },
		});
		await req.delete(`/gateway/personal-attachments/${id}`);
	};
}

/**
 * Download a personal attachment
 * @param {uuid} id
 * @returns {void}
 */
export function downloadPersonalAttachment(id) {
	return async function () {
		const response = await req.get(
			`/gateway/personal-attachments/${id}/download`,
			{
				responseType: "blob",
			},
		);

		saveAs(
			new Blob([response.data], { type: response.headers["content-type"] }),
			response.headers["content-disposition"].match(/filename=(.+)/)[1],
		);
	};
}

/**
 * Open attachment in a new window
 * @param {uuid} id
 * @returns
 */
export function openPersonalAttachment(id) {
	return async function () {
		const response = await req.get(
			`/gateway/personal-attachments/${id}/signed-url`,
		);
		window.open(response.data, "_blank");
	};
}

/**
 * Delete all entries in the attachments reducer that are attached to objId of objType.
 * objId can be omitted, in that case all documents attached to objType is deleted
 * @param {string} objType
 * @param {uuid} objId
 * @returns
 */
export function resetPersonalAttachments(objType, objId) {
	return { type: RESET_PERSONAL_ATTACHMENTS, payload: { objType, objId } };
}
