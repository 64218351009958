import { getStore } from "../../store";

const obj = {
	replaceIdWithName: (tagsFromobject) => {
		const storedTags = getStore().getState().tags.get("tags");
		return tagsFromobject.map((tag) => {
			const storedTag = storedTags.find(
				(storedTag) => storedTag.get("id") === tag,
			);
			return storedTag ? storedTag.get("name") : "";
		});
	},
};

export default obj;
