import React, { Component } from "react";
import { connect } from "react-redux";
import DropdownMenuContainer from "../../../shared/dropdown-menu.container";
import { TransparentButton } from "../../../../dumb-components/shared/button-v2";
import DropdownIconItem from "../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import { generateExcelFromTemplate } from "../../../../actions/shares.actions";
import { investorsWithNoSsnOrOrgnrExists } from "../../../../components/helpers/shares";
import { importEmissionExcel } from "../../../../actions/transaction.actions";
import { func } from "prop-types";
import InvestorsWarningModalContainer from "../../modals/investors-warning-modal.container";
import Dropzone from "../../../../dumb-components/shared/dropzone/dropzone";
import ErrorMessageModal from "../../../../dumb-components/shares/error-message-modal/error-message-modal";
import { fromJS, List } from "immutable";
import { notifyIsUploadingExcel } from "../../../../actions/notify.actions";

class EmissionExcelDropdownContainer extends Component {
	state = {
		warningModalOpen: false,
		errorModalOpen: false,
		isUploading: false,
		errorData: List(),
	};

	static propTypes = {
		onUploadCallback: func,
	};

	componentDidMount = () => {};

	generateExcelFromTemplate = () => {
		const { generateExcelFromTemplate } = this.props;
		const hasInvestorsWithoutID = investorsWithNoSsnOrOrgnrExists();
		if (hasInvestorsWithoutID) {
			this.setState({ warningModalOpen: true });
			return;
		}

		generateExcelFromTemplate("emission");
	};

	onCloseWarningModal = () => {
		this.setState({ warningModalOpen: false });
	};

	dropzoneRef = null;

	setDropzoneRef = (ref) => {
		this.dropzoneRef = ref;
	};

	uploadExcel = () => {
		this.dropzoneRef.open();
	};

	onHandleUploadExcel = (files) => {
		const { importEmissionExcel, onUploadCallback, notifyIsUploadingExcel } =
			this.props;

		this.setState({ isUploading: true });
		notifyIsUploadingExcel(true);
		importEmissionExcel(
			files[0],
			() => {
				onUploadCallback();
				setTimeout(() => {
					notifyIsUploadingExcel(false);
				}, 3000);
				this.setState({ isUploading: false });
			},
			this.handleErrorData,
		);
	};

	handleErrorData = (errorData) => {
		this.setState({ isUploading: false });

		if (!errorData) {
			return;
		}
		this.setState({ errorData: fromJS(errorData), errorModalOpen: true });
	};

	onDeclineErrorModal = () => {
		this.setState({ errorModalOpen: false });
	};
	onAcceptErrorModal = () => {
		this.setState({ errorModalOpen: false });
		this.dropzoneRef.open();
	};

	render = () => {
		const { warningModalOpen, errorModalOpen, errorData } = this.state;
		return (
			<>
				<DropdownMenuContainer
					renderRaw={
						<TransparentButton
							tid="transactions.new_share_issue.ag_grid.header.dropdown.excel.title"
							textColor="white"
							hasUnderline
						/>
					}
				>
					<DropdownIconItem
						tid="transactions.new_share_issue.ag_grid.header.dropdown_item.excel.download"
						icon="faArrowAltToBottom"
						onClick={this.generateExcelFromTemplate}
					/>{" "}
					<DropdownIconItem
						tid="transactions.new_share_issue.ag_grid.header.dropdown_item.excel.upload"
						icon="faArrowAltFromBottom"
						onClick={this.uploadExcel}
					/>{" "}
				</DropdownMenuContainer>
				<InvestorsWarningModalContainer
					isOpen={warningModalOpen}
					onCloseModal={this.onCloseWarningModal}
				/>
				<Dropzone
					hidden={true}
					onDrop={this.onHandleUploadExcel}
					dropzoneRef={this.setDropzoneRef}
					size="full-screen"
				/>
				<ErrorMessageModal
					isOpen={errorModalOpen}
					onDecline={this.onDeclineErrorModal}
					onAccept={this.onAcceptErrorModal}
					errorData={errorData}
				/>
			</>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};
const mapActionsToProps = {
	generateExcelFromTemplate,
	importEmissionExcel,
	notifyIsUploadingExcel,
};
export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(EmissionExcelDropdownContainer);
