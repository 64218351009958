import React, { PureComponent } from "react";
import { bool, func, string, object, oneOfType, oneOf } from "prop-types";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
import styled from "styled-components";
import { StyledInputTemplate } from "../input/input.template";
import Moment from "../../../modules/moment.module";
import "react-datepicker/dist/react-datepicker.min.css";

registerLocale("sv", sv);

const StyledDatePicker = styled(ReactDatePicker)`
	${StyledInputTemplate}
	color: ${(props) =>
		props.muted ? props.theme.colors.muted : props.theme.colors.midGrey};
`;

const StyledDatePickerWraper = styled.div`
	.react-datepicker__navigation {
		line-height: 1.7rem;
		height: 10px;
		width: 10px;
		text-indent: -999em;
		overflow: hidden;
		border: 0.45rem solid transparent;
	}

	.react-datepicker-wrapper,
	.react-datepicker__input-container {
		display: block;
	}

	.react-datepicker__navigation--previous {
		border-right-color: #ccc;
	}

	.react-datepicker__navigation--next {
		border-left-color: #ccc;
	}

	.react-datepicker__close-icon::after {
		background-color: transparent;
		border-radius: 0;
		color: rgb(154, 154, 154);
		font-size: 18px;
		top: 42%;
	}

	.react-datepicker {
		font-size: 1em;
	}

	.react-datepicker__header {
		padding-top: 0.8em;
		background-color: hsl(var(--accent));
	}

	.react-datepicker__month {
		margin: 0.4em 1em;
	}

	.react-datepicker__day-name,
	.react-datepicker__day {
		width: 1.9em;
		line-height: 1.9em;
		margin: 0.166em;
	}

	.react-datepicker__current-month,
	.react-datepicker-time__header {
		font-size: 1em;
	}

	.react-datepicker-popper {
		z-index: 999;
	}
`;

class DatePicker extends PureComponent {
	static propTypes = {
		disabled: bool,
		fieldName: string,
		isLoading: bool,
		error: bool,
		icon: string,
		muted: bool,
		onClose: func,
		onChange: func,
		onOpen: func,
		placeholder: string,
		showWeekNumbers: bool,
		minDate: string,
		maxDate: string,
		timepickerOnly: bool,
		showTimeSelect: bool,
		value: oneOfType([string, object]),
		calendarPlacement: oneOf([
			"bottom-start",
			"bottom-end",
			"top-start",
			"top-end",
		]),
		isClearable: bool,
		hasError: bool,
		showTimeSelectOnly: bool,
		language: string,
		onBlur: func,
	};

	static defaultProps = {
		isLoading: false,
		showWeekNumbers: true,
		disabled: false,
		timepickerOnly: false,
		showTimeSelect: false,
		calendarPlacement: "bottom-start",
		showTimeSelectOnly: false,
	};

	onChange = (val) => {
		const { onChange, fieldName } = this.props;
		const dateObj = Moment(val).toISOString();

		if (fieldName) {
			onChange && onChange(fieldName, dateObj);
			return;
		}

		onChange && onChange(dateObj);
	};

	filterDate = (date) => {
		const { minDate, maxDate } = this.props;

		if (minDate && !maxDate) {
			return new Date(minDate) < new Date(date);
		} else if (!minDate && maxDate) {
			return new Date(maxDate) > new Date(date);
		} else if (minDate && maxDate) {
			return (
				new Date(minDate) < new Date(date) && new Date(maxDate) > new Date(date)
			);
		}

		return true;
	};

	getLocaleString = () => {
		const { language } = this.props;
		return language ? language.split("-")[0] : Moment.locale();
	};

	getDateFormat = () => {
		const { showTimeSelectOnly, showTimeSelect } = this.props;
		const localeString = this.getLocaleString();
		const localizedTimeFormat = Moment(new Date())
			.localeData(localeString)
			.longDateFormat("LT")
			.toString();

		if (showTimeSelectOnly) {
			return localizedTimeFormat;
		}

		if (showTimeSelect) {
			return `yyyy-MM-dd ${localizedTimeFormat}`;
		}

		if (localeString === "sv") {
			return "yyyy-MM-dd";
		}

		return "MM/dd/yyyy";
	};

	getPlaceholder = () => {
		const { showTimeSelectOnly, showTimeSelect } = this.props;
		let { placeholder } = this.props;
		const localeString = this.getLocaleString();

		// Time Only Placeholder
		if (showTimeSelectOnly) {
			return Moment(new Date())
				.localeData(localeString)
				.longDateFormat("LT")
				.toLowerCase();
		}

		// Calendar
		if (!placeholder) {
			// With Time
			if (showTimeSelect) {
				placeholder =
					Moment(new Date())
						.localeData(localeString)
						.longDateFormat("L")
						.toLowerCase() +
					" " +
					Moment(new Date()).localeData().longDateFormat("LT").toLowerCase();
			} else {
				// Without Time
				placeholder = Moment(new Date())
					.localeData(localeString)
					.longDateFormat("L")
					.toLowerCase();
			}

			// The placeholder is yyyy-mm-dd for Sweden which is incorrect, it should be åååå-mm-dd.
			if (localeString === "sv") {
				placeholder = placeholder.replace(/y/g, "å");
			}
		}

		return placeholder;
	};

	getTimeString = () => {
		const localeString = this.getLocaleString();

		if (localeString === "sv") {
			return "Tid";
		}

		return "Time";
	};

	// Temporary fix. Should be removed upon 2.7.0 release as there will be
	// way more robust solution
	onChangeRaw = (e) => {
		const m = Moment(e.target.value);
		if (m.isValid()) {
			this.onChange(m);
		}
	};

	render = () => {
		const {
			value,
			calendarPlacement,
			disabled,
			isClearable,
			showWeekNumbers,
			showTimeSelect,
			hasError,
			showTimeSelectOnly,
			minTime,
			onBlur,
		} = this.props;
		const localeString = this.getLocaleString();

		return (
			<StyledDatePickerWraper>
				<StyledDatePicker
					hasError={hasError}
					selected={value ? new Date(value) : null}
					onChange={this.onChange}
					placeholderText={this.getPlaceholder()}
					locale={localeString}
					dateFormat={this.getDateFormat()}
					filterDate={this.filterDate}
					popperPlacement={calendarPlacement}
					disabled={disabled}
					isClearable={isClearable}
					timeFormat="HH:mm"
					timeIntervals={15}
					onBlur={onBlur}
					timeCaption={this.getTimeString()}
					minTime={minTime && Moment(minTime).toDate()}
					maxTime={minTime && Moment().endOf("day").toDate()}
					showTimeSelect={showTimeSelectOnly ? true : showTimeSelect}
					showTimeSelectOnly={showTimeSelectOnly}
					showWeekNumbers={showWeekNumbers}
					onChangeRaw={this.onChangeRaw}
					showMonthDropdown
					showYearDropdown
				/>
			</StyledDatePickerWraper>
		);
	};
}

export default DatePicker;
