/**
 * Request
 * Adds some global settings to axios that will be used for all requests.
 */

import axios from "axios";
import Cookies from "universal-cookie";
import { getStore } from "../store";
import { v1 } from "uuid";
import {
	NOTIFY_LOADING_START,
	NOTIFY_LOADING_END,
	NOTIFY_LOADING_SUCCESS,
	CREDENTIALS_SET_AUTENTICATED,
	NOTIFY_RETURN_URL,
	CREDENTIALS_SIGNOUT,
} from "../actions/types";
import { addErrorNotification } from "../actions/notify.actions";
import history from "../interfaces/history";

const store = getStore();

const axiosState = {
	latestRequest: {},
	currentRequest: {},
};

// Create an axios instance with a default ROOT-url and an authorization header.
const axiosInstance = axios.create({
	baseURL: "/api",
	timeout: 300000,
});
axiosInstance.CancelToken = axios.CancelToken;

// Intercept before request is made
axiosInstance.interceptors.request.use(
	(config) => {
		const currentUrl = `${config.baseURL}${config.url}`;
		if (config.onlyLatest) {
			config.random = v1();
			axiosState.latestRequest[`${config.baseURL}${config.url}`] =
				config.random;
		}

		if (config.preventRepeatedRequests) {
			if (axiosState.currentRequest[currentUrl]) {
				const source = axiosState.currentRequest[currentUrl];
				delete axiosState.currentRequest[currentUrl];
				source.cancel();
			}

			const CancelToken = axios.CancelToken;
			const source = CancelToken.source();
			config.cancelToken = source.token;
			axiosState.currentRequest[currentUrl] = source;
		}

		const state = store.getState();
		const accessToken = new Cookies().get("accessToken");

		if (accessToken) {
			config.headers["authorization"] = "Bearer " + accessToken;
		}

		const publicAccessToken = localStorage.getItem("publicAccessToken");
		if (location.pathname.includes("/public") && publicAccessToken) {
			config.headers["authorization"] =
				"Bearer " + localStorage.getItem("publicAccessToken");
		}

		if (state.company.company) {
			config.headers["x-company-id"] = state.company.company.id;
		}
		if (state.notify.socketId) {
			config.headers["x-socket-id"] = state.notify.socketId;
		}

		if (
			state.user &&
			state.user.getIn(["userObj", "siteSettings", "language"])
		) {
			config.headers["accept-language"] = state.user.getIn([
				"userObj",
				"siteSettings",
				"language",
			]);
		}

		const isSaving = config.method === "put";

		store.dispatch({ type: NOTIFY_LOADING_START, payload: { isSaving } });
		return config;
	},
	function (error) {
		return Promise.reject(error);
	},
);

// Intercept when request is finished
axiosInstance.interceptors.response.use(
	(response) => {
		if (response.config.onlyLatest) {
			if (
				response.config.random !== axiosState.latestRequest[response.config.url]
			) {
				store.dispatch({ type: NOTIFY_LOADING_END });
				return Promise.reject(
					new Error("onlyLatest:true, this request is dismissed"),
				);
			}
		}

		if (response.config.preventRepeatedRequests) {
			if (axiosState.currentRequest[response.config.url]) {
				delete axiosState.currentRequest[response.config.url];
			}
		}

		if (
			(response.config.method === "put" || response.config.method === "post") &&
			response.config.supressLoadingSuccess !== true
		) {
			store.dispatch({ type: NOTIFY_LOADING_SUCCESS });
		} else {
			store.dispatch({ type: NOTIFY_LOADING_END });
		}
		return response;
	},
	(error) => {
		store.dispatch({ type: NOTIFY_LOADING_END });
		if (!axios.isCancel(error)) {
			if (error.response.config.preventRepeatedRequests) {
				if (axiosState.currentRequest[error.response.config.url]) {
					delete axiosState.currentRequest[error.response.config.url];
				}
			}
			// Handle 403 responses
			if (error.response.status === 403) {
				const state = store.getState();
				if (state.notify.returnUrl === null) {
					store.dispatch({
						type: NOTIFY_RETURN_URL,
						payload: `${location.pathname}${location.search}`,
					});
				}
				new Cookies().remove("accessToken", { path: "/" });
				// Public area
				localStorage.removeItem("publicAccessToken");
				store.dispatch({ type: CREDENTIALS_SET_AUTENTICATED, payload: false });
				store.dispatch({ type: CREDENTIALS_SIGNOUT, payload: null });

				const dmz = ["/signup", "/signin", "/forgot-password"];

				const pathname = window.location.pathname;
				if (!dmz.includes(pathname)) {
					history.push("/signin");
				}
			} else if (error.response.status === 503) {
				store.dispatch(addErrorNotification({ tid: "generic.error.fatal" }));
			} else if (
				error.response.status === 404 &&
				error.response.config.show404PageIfNotFound === true
			) {
				history.push("/public/404");
			} else {
				const payload = {};
				if (
					error.response &&
					error.response.data &&
					error.response.data.translationId
				) {
					payload.tid = error.response.data.translationId;
				} else if (error?.response?.data?.message) {
					payload.tid = Array.isArray(error.response.data.message)
						? error.response.data.message[0]
						: error.response.data.message;
				} else if (error.response && error.response.data) {
					payload.text = error.response.data;
				} else {
					payload.text = "Error";
				}

				store.dispatch(addErrorNotification(payload));
			}
		}

		return Promise.reject(error);
	},
);

/** Export */
export default axiosInstance;
