import React, { Component } from "react";
import { connect } from "react-redux";

import { closeModal } from "../../actions/modals.actions";
import { SUBSCRIPTION_AFTER_SUB_POT_INFO } from "../../constants/modals";
import InfoAboutPotAfterSubModal from "../../dumb-components/subscriptions/info-about-pot-after-sub-modal/info-about-pot-after-sub-modal";

class InfoAboutPotAfterSubModalContainer extends Component {
	closeModal = () => {
		this.props.closeModal();
	};

	render = () => {
		const { isOpen } = this.props;

		return (
			<InfoAboutPotAfterSubModal isOpen={isOpen} onClose={this.closeModal} />
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		isOpen:
			store.modals.getIn(["activeModal", "name"]) ===
			SUBSCRIPTION_AFTER_SUB_POT_INFO,
	};
};

const mapActionsToProps = {
	closeModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(InfoAboutPotAfterSubModalContainer);
