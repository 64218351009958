import { fromJS } from "immutable";
import req from "../modules/request.module";
import { INVOICES_LIST_INVOICES } from "./types";

/**
 * Action for retrieving a list of invoices
 */
export function fetchInvoices() {
	return function (dispatch) {
		return req.get("/subscriptions/invoices").then((response) => {
			dispatch({
				type: INVOICES_LIST_INVOICES,
				payload: fromJS(response.data),
			});
		});
	};
}

export function createInvoicePdf(invoiceId, callback) {
	return function () {
		return req.get(`/subscriptions/invoices/${invoiceId}`).then(() => {
			callback && callback();
		});
	};
}
