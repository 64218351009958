import { useLocation, useHistory } from "react-router-dom";

export default function useQueryParams() {
	const location = useLocation();
	const history = useHistory();
	const searchParams = new URLSearchParams(location.search);

	const get = (key) => {
		return searchParams.get(key);
	};

	const set = (key, value) => {
		if (value === null) {
			searchParams.delete(key);
		} else {
			searchParams.set(key, value);
		}

		history.push({
			search: searchParams.toString(),
		});
	};

	return [get, set];
}
