import { ALIAS_UPDATE } from "./types";
import req from "../modules/request.module";

export function updateAlias(alias, id, callback) {
	return function (dispatch) {
		return req
			.put(`shares/personal/investment/alias/${id}`, { alias })
			.then(() => {
				dispatch({ type: ALIAS_UPDATE, payload: alias });
				callback && callback();
			});
	};
}

export function deleteAlias(id, alias, callback) {
	return function (dispatch) {
		return req
			.delete(`/shares/personal/investment/alias/${id}`)
			.then(() => {
				dispatch({ type: ALIAS_UPDATE, payload: alias });
				callback && callback();
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
