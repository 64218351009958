import { fromJS, List } from "immutable";
import {
	DOCUMENT_ESIGN_VERIFY_FETCH,
	DOCUMENT_ESIGN_LOADING,
	DOCUMENT_ESIGN_SIGN_FETCH,
	DOCUMENT_FETCH,
	DOCUMENTS_FETCH_ATTACHMENTS,
	DOCUMENTS_UPDATE_ATTACHMENT,
	DOCUMENTS_APPEND_ATTACHMENT,
	DOCUMENTS_REMOVE_ATTACHMENT,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	eSign: {
		isLoading: false,
		status: null,
		translationTid: null,
		document: null,
		tangleUrl: null,
	},
	document: null,
	attachments: {},
});

const appendAttachment = (state, { objId, data }) => {
	if (!objId) {
		return state;
	}

	state = state.update("attachments", (attachments) => {
		if (!attachments.get(objId)) {
			attachments = attachments.set(objId, List());
		}
		attachments = attachments.update(objId, (attachmentList) => {
			return attachmentList.push(data);
		});
		return attachments;
	});

	return state;
};

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case DOCUMENT_ESIGN_VERIFY_FETCH: {
			state = state.setIn(["eSign", "status"], payload.get("status", null));
			state = state.setIn(
				["eSign", "translationTid"],
				payload.get("translationTid", null),
			);
			state = state.setIn(["eSign", "tangleUrl"], payload.get("tangleUrl"));
			return state;
		}

		case DOCUMENT_ESIGN_LOADING: {
			return state.setIn(["eSign", "isLoading"], payload);
		}

		case DOCUMENT_ESIGN_SIGN_FETCH: {
			return state.setIn(["eSign", "document"], payload);
		}

		case DOCUMENT_FETCH:
			return state.set("document", payload);

		case DOCUMENTS_FETCH_ATTACHMENTS: {
			const { mapObjIdToDocId, attachments } = payload;

			mapObjIdToDocId.forEach((attachmentIds, objId) => {
				if (!attachmentIds) {
					return;
				}

				const atts = attachmentIds.map((id) =>
					attachments.find((doc) => doc.get("id") === id),
				);

				state = state.setIn(["attachments", objId], atts);
			});

			return state;
		}

		case DOCUMENTS_UPDATE_ATTACHMENT: {
			const { objId, data } = payload;

			const index = state
				.getIn(["attachments", objId])
				?.findIndex((d) => d.get("id") === data.get("id"));

			if (index >= 0) {
				return state.setIn(["attachments", objId, index], data);
			} else {
				return appendAttachment(state, { objId, data });
			}
		}

		case DOCUMENTS_APPEND_ATTACHMENT: {
			if (!payload) {
				return state;
			}
			return appendAttachment(state, payload);
		}

		case DOCUMENTS_REMOVE_ATTACHMENT: {
			if (!payload) {
				return state;
			}
			const { objId, attachmentId } = payload;
			if (!objId) {
				return state;
			}

			state = state.update("attachments", (attachments) => {
				if (!attachments.get(objId)) {
					return attachments;
				}
				attachments = attachments.update(objId, (attachmentList) => {
					return attachmentList.filter((attachment) => {
						return attachment.get("id") !== attachmentId;
					});
				});
				return attachments;
			});

			return state;
		}

		default:
			return state;
	}
}
