import React, { Component } from "react";
import { connect } from "react-redux";
import DropzoneFullscreenDetector from "../../dumb-components/shared/dropzone/dropzone-fullscreen-detector";
import { changeFileEnteredGlobal } from "../../actions/notify.actions";

class DropzoneFullscreenDetectorContainer extends Component {
	onDrop = () => {
		const { isAnyModalOpen } = this.props;

		if (isAnyModalOpen) {
			return;
		}

		this.props.changeFileEnteredGlobal(false);
	};

	onDragEnter = () => {
		const { fileEnteredWebsiteGlobal, isAnyModalOpen } = this.props;

		if (isAnyModalOpen) {
			return;
		}

		if (fileEnteredWebsiteGlobal) {
			return;
		}

		this.props.changeFileEnteredGlobal(true);
	};

	onDragLeave = () => {
		const { isAnyModalOpen } = this.props;

		if (isAnyModalOpen) {
			return;
		}

		this.props.changeFileEnteredGlobal(false);
	};

	render = () => {
		const { children } = this.props;

		return (
			<DropzoneFullscreenDetector
				onDrop={this.onDrop}
				onDragEnter={this.onDragEnter}
				onDragLeave={this.onDragLeave}
			>
				{children}
			</DropzoneFullscreenDetector>
		);
	};
}

function mapStoreToProps(store) {
	return {
		fileEnteredWebsiteGlobal: store.notify.fileEnteredWebsiteGlobal,
		isAnyModalOpen: store.modals.hasIn(["activeModal", "name"]) ? true : false,
	};
}

const mapActionsToProps = {
	changeFileEnteredGlobal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(DropzoneFullscreenDetectorContainer);
