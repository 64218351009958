import React, { Component } from "react";
import { connect } from "react-redux";
import { bool } from "prop-types";
import { Margin } from "styled-components-spacing";
import Alert from "../../../dumb-components/shared/alert/alert";
import Text from "../../../dumb-components/shared/text/text";

class AlertContainer extends Component {
	static propTypes = {
		everyoneHasSigned: bool,
		protocolPublished: bool,
		meetingIsLoaded: bool,
		atLeastOneSigned: bool,
	};

	render() {
		const {
			everyoneHasSigned,
			protocolPublished,
			meetingIsLoaded,
			atLeastOneSigned,
		} = this.props;

		if (
			!everyoneHasSigned ||
			protocolPublished ||
			!meetingIsLoaded ||
			!atLeastOneSigned
		) {
			return null;
		}

		return (
			<Margin top={3}>
				<Alert mode="warning" icon="faExclamationTriangle" iconType="solid">
					<Text
						color="white"
						tid="meetings.attendees.alert.needs_manual_publishing"
					/>
				</Alert>
			</Margin>
		);
	}
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {};

export default connect(mapStoreToProps, mapActionsToProps)(AlertContainer);
