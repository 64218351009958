import {
	COMPANY_CONTACTS_LIST,
	COMPANY_CONTACTS_CREATE,
	COMPANY_CONTACTS_EDIT,
	COMPANY_CONTACTS_DELETE,
	MEMBERS_AND_INVESTORS_LIST,
	MEMBERS_AND_INVESTORS_FILTER,
} from "./types";
import req from "../modules/request.module";
import { fromJS } from "immutable";

export function listCompanyContacts(callback) {
	return function (dispatch) {
		return req.get(`/companies/external-people/`).then((response) => {
			dispatch({ type: COMPANY_CONTACTS_LIST, payload: fromJS(response.data) });
			callback && callback(response.data);
		});
	};
}

export function createCompanyContact(person, callback) {
	return function (dispatch) {
		return req
			.post("/companies/external-people/", { person })
			.then((response) => {
				dispatch({
					type: COMPANY_CONTACTS_CREATE,
					payload: fromJS(response.data),
				});
				typeof callback === "function" && callback(response.data);
			});
	};
}

export function editCompanyContact(id, person) {
	return function (dispatch) {
		return req
			.put(`/companies/external-people/${id}`, { person })
			.then((response) => {
				dispatch({
					type: COMPANY_CONTACTS_EDIT,
					payload: fromJS(response.data),
				});
			});
	};
}

export function deleteCompanyContact(id) {
	return function (dispatch) {
		dispatch({ type: COMPANY_CONTACTS_DELETE, payload: id });
		return req.delete(`/companies/external-people/${id}`);
	};
}

export function fetchMembersAndInvestors(options, callback) {
	return function (dispatch) {
		const query = new URLSearchParams(options).toString();
		return req.get(`/companies/people?${query}`).then((response) => {
			dispatch({
				type: MEMBERS_AND_INVESTORS_LIST,
				payload: fromJS(response.data),
			});
			callback?.(response.data);
		});
	};
}

export function filterMembersAndInvestors(filters) {
	return function (dispatch) {
		dispatch({ type: MEMBERS_AND_INVESTORS_FILTER, payload: { filters } });
	};
}
