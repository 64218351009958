import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import AgGrid from "../../../dumb-components/shared/ag-grid/ag-grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { GRID_MODAL } from "../../../constants/modals";
import { GRID_MODE_PANEL, GRID_MODE_MODAL } from "/shared/constants";
import ExportExcelButton from "../../../dumb-components/investment/shareholders/export-excel-button";
import { InvestmentsContext } from "../investment.container";
import { useTranslation } from "react-i18next";
import {
	columnTypes,
	transactionHistoryColumnDefs,
} from "../../../dumb-components/shared/ag-grid/column-defs/column-defs";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../actions/modals.actions";
import GridPanelRightButtons from "../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons";
import Text from "../../../dumb-components/shared/text/text";
import Moment from "../../../modules/moment.module";
import { Map, List } from "immutable";
import { getUniqueTransactionShareTypes } from "../../../components/helpers/sharebook.helpers";
import { transactionHistorySelector } from "../../../reducers/selectors/transaction-history.selector";

const TransactionHistoryGridContainer = () => {
	const { investmentId } = useContext(InvestmentsContext);
	const transactions = useSelector((state) =>
		transactionHistorySelector(state, investmentId),
	);

	if (!transactions) {
		return null;
	}

	const gridRef = useRef();
	const { t } = useTranslation();
	const [colDefs, setColDefs] = useState(null);
	const [rowData, setRowData] = useState();
	const [colTypes] = useState(columnTypes());

	const gridOption = "transactionHistory";

	const dispatch = useDispatch();

	const isGridModalOpen = useSelector((state) => {
		return state.modals.getIn(["activeModal", "options"]) === gridOption;
	});

	useEffect(() => {
		if (transactions) {
			const uniqueShareTypes = getUniqueTransactionShareTypes(transactions);
			const reversedTransaction = transactions.reverse();

			let previousTransactionPostMoney = 0;

			const newTransactions = reversedTransaction.map((transaction) => {
				let newTransaction = Map();

				const date = Moment(transaction.get("date")).format("L");
				newTransaction = newTransaction.set("date", date);

				uniqueShareTypes.forEach((uniqueShareType) => {
					const transactionTypes = transaction.getIn(["shareData", "types"]);
					const isTransactionShareType =
						transactionTypes &&
						transactionTypes.find(
							(type) => type.get("type") === uniqueShareType,
						);
					let newShares = "-";
					let latestPrice = "-";

					if (isTransactionShareType) {
						newShares = isTransactionShareType.get("numOfSharesDiff") || "-";
						latestPrice = isTransactionShareType.get("latestPrice") || "-";
					}

					newTransaction = newTransaction.set(
						`${uniqueShareType}&newShares`,
						newShares,
					);
					newTransaction = newTransaction.set(
						`${uniqueShareType}&latestPrice`,
						latestPrice,
					);
				});

				let shareTypesList = List();
				transaction.getIn(["shareData", "types"]).forEach((shareType) => {
					const type = shareType.get("type");
					const newShares = shareType.get("numOfSharesDiff");

					if (!newShares) {
						return;
					}

					const latestPrice = shareType.get("latestPrice") || "-";
					shareTypesList = shareTypesList.push(
						Map({ type, newShares, latestPrice }),
					);
				});
				newTransaction = newTransaction.set("types", shareTypesList);

				newTransaction = newTransaction.set(
					"numOfTotalSharesDiff",
					transaction.getIn(["shareData", "numOfTotalSharesDiff"]),
				);

				const numOfTotalShares = transaction.getIn([
					"shareData",
					"numOfTotalShares",
				]);
				newTransaction = newTransaction.set(
					"numOfTotalShares",
					numOfTotalShares,
				);

				const raisedDiff = transaction.getIn(["shareData", "raisedDiff"]);
				newTransaction = newTransaction.set("raisedDiff", raisedDiff);

				const postMoney = transaction.getIn(["shareData", "postMoney"]);
				newTransaction = newTransaction.set("postMoney", postMoney);

				const dilutionPercentage =
					(transaction.getIn(["shareData", "numOfTotalSharesDiff"]) * 100) /
					transaction.getIn(["shareData", "numOfTotalShares"]);
				newTransaction = newTransaction.set(
					"dilutionPercentage",
					dilutionPercentage,
				);

				const getPreviousTransactionPostMoney =
					previousTransactionPostMoney && previousTransactionPostMoney;
				const valueStep =
					transaction.getIn(["shareData", "postMoney"]) -
					transaction.getIn(["shareData", "raisedDiff"]) -
					getPreviousTransactionPostMoney;
				newTransaction = newTransaction.set("valueStep", valueStep);
				previousTransactionPostMoney = postMoney;

				const description =
					t(transaction.getIn(["descriptionData", "title"])) +
					": " +
					transaction.getIn(["descriptionData", "summary"]);
				newTransaction = newTransaction.set("description", description);

				return newTransaction;
			});

			setColDefs(
				transactionHistoryColumnDefs(t, transactions, isGridModalOpen),
			);
			setRowData(newTransactions.toJS());
		}
	}, [transactions, isGridModalOpen]);

	const exportAsExcel = useCallback(() => {
		gridRef.current.api.exportDataAsExcel();
	});

	const openGridModal = useCallback(() => {
		dispatch(openModal(GRID_MODAL, gridOption));
	});

	const modalLeftHeader = {
		tid: "investments.sharebook.transaction_history_grid.title",
	};

	if (!rowData) {
		return null;
	}

	return (
		<>
			<Card>
				<CardHeader
					title={
						<Text
							tid={"investments.sharebook.transaction_history_grid.title"}
						/>
					}
					action={
						<GridPanelRightButtons
							exportAsExcel={exportAsExcel}
							openGridModal={openGridModal}
							downloadExcelTid={
								"investments.sharebook.transaction_history_grid.dropdown_item.download_excel_list"
							}
							noFilter={true}
						/>
					}
				/>
				<CardContent variant={rowData.length > 15 ? "noBottomPadding" : ""}>
					<AgGrid
						mode={isGridModalOpen ? GRID_MODE_MODAL : GRID_MODE_PANEL}
						isGridModalOpen={isGridModalOpen}
						rowData={rowData}
						columnDefs={colDefs}
						rightHeaderComponent={() => {
							return (
								<ExportExcelButton
									exportAsExcel={exportAsExcel}
									noFilter={true}
								/>
							);
						}}
						modalLeftHeader={modalLeftHeader}
						gridRef={gridRef}
						columnTypes={colTypes}
						rowsPerPage={15}
					/>
				</CardContent>
			</Card>
		</>
	);
};

TransactionHistoryGridContainer.defaultProps = {
	staticRows: 13,
};

export default TransactionHistoryGridContainer;
