import React, { PureComponent } from "react";
import styled from "styled-components";
import { func } from "prop-types";
import ScrollView from "../../shared/layout/scroll-view/scroll-view";
import Box from "../../shared/layout/box/box";
import Text from "../../shared/text/text";

const StyledProtocolView = styled.div`
	display: flex;
	width: 90%;
	max-width: 1200px;
	height: 100%;
	margin: 0 auto;
	flex-direction: column;
`;

const StyledBackButton = styled.div`
	display: flex;
	height: 40px;
`;

const StyledProtocolViewHeader = styled.div`
	display: flex;
	height: 74px;
`;

const StyledProtocolViewBody = styled.div`
	display: flex;
	flex: 1;
`;

const StyledProtocolViewContent = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	padding: ${(props) => props.theme.spacing[5]};
`;

export default class ProtocolView extends PureComponent {
	static propTypes = {
		header: func,
		tabs: func,
		toolbar: func,
		backButton: func,
		allowedToViewProtocol: func,
	};

	renderScrollViewHeader = () => {
		const { tabs, toolbar, allowedToViewProtocol } = this.props;

		if (!allowedToViewProtocol) {
			return;
		}

		return (
			<Box>
				{tabs && tabs()}
				{toolbar && toolbar()}
			</Box>
		);
	};

	render = () => {
		const { header, children, backButton, allowedToViewProtocol } = this.props;

		return (
			<StyledProtocolView>
				{backButton && <StyledBackButton>{backButton()}</StyledBackButton>}

				{header && (
					<StyledProtocolViewHeader>{header()}</StyledProtocolViewHeader>
				)}

				<StyledProtocolViewBody>
					<ScrollView
						header={this.renderScrollViewHeader}
						headerBottomMargin={3}
						showOnHover
					>
						<StyledProtocolViewContent>
							{!allowedToViewProtocol && (
								<Text tid="meetings.protocol.not_allowed_to_view_protocol" />
							)}
							{allowedToViewProtocol && children}
						</StyledProtocolViewContent>
					</ScrollView>
				</StyledProtocolViewBody>
			</StyledProtocolView>
		);
	};
}
