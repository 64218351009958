import React, { PureComponent } from "react";
import { func, bool } from "prop-types";
import styled, { css } from "styled-components";

import Button from "../../shared/button/button";

const StyledWrapper = styled.div`
	${(props) =>
		props.marginTop &&
		css`
			margin-top: ${(props) => props.theme.spacing[4]};
		`}
`;

export default class SkipAuthorise extends PureComponent {
	static propTypes = {
		onSkipAuthorise: func,
		onVerifyToCustomer: func,
		marginTop: bool,
	};

	static defaultProps = {
		marginTop: true,
	};

	render = () => {
		const { onSkipAuthorise, onVerifyToCustomer, marginTop } = this.props;

		return (
			<StyledWrapper marginTop={marginTop}>
				<Button
					onClick={onSkipAuthorise}
					tid="account_wizard.step_2.btn.verify_later"
				/>
				<Button
					onClick={onVerifyToCustomer}
					tid="account_wizard.step_2.btn.verify_to_customer"
				/>
			</StyledWrapper>
		);
	};
}
