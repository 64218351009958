import React, { Component } from "react";
import { connect } from "react-redux";

import AlertBetaAgmInfo from "../../notifications/onboarding/alerts/dashboard/beta-agm-info";
import AlertNewEsigningInfo from "../../notifications/onboarding/alerts/dashboard/new-esigning-info";

class CompanyDashboardAlerts extends Component {
	render = () => {
		return (
			<>
				<AlertBetaAgmInfo />
				<AlertNewEsigningInfo />
				{/*<AlertPremiumSmartMeetingsInfo />*/}
			</>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(CompanyDashboardAlerts);
