import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { releaseSubmission } from "../../../../actions/emissions.actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const SubmissionContextMenu = (props) => {
	const dispatch = useDispatch();
	const emission = useSelector((state) => state.emissions.current);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = !!anchorEl;
	const { t } = useTranslation();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const menuItem = props.editable ? (
		<MenuItem
			onClick={() => {
				props.setAddSubmissionModalOpen({
					inviteeId: props.inviteeId,
					readOnly: false,
				});
				handleClose();
			}}
		>
			<ListItemIcon>
				<EditOutlinedIcon fontSize="small" />
			</ListItemIcon>
			{t("emissions.subscription-list.edit-subscription")}
		</MenuItem>
	) : (
		<MenuItem
			onClick={() => {
				props.setAddSubmissionModalOpen({
					inviteeId: props.inviteeId,
					readOnly: true,
				});
				handleClose();
			}}
		>
			<ListItemIcon>
				<VisibilityOutlinedIcon fontSize="small" />
			</ListItemIcon>
			{t("emissions.subscription-list.open-subscription")}
		</MenuItem>
	);

	return (
		<>
			<Tooltip title={t("emissions.invitations.more-options")}>
				<IconButton onClick={handleClick}>
					<MoreVertIcon sx={{ color: "grey.700" }} />
				</IconButton>
			</Tooltip>
			<Menu
				anchorEl={anchorEl}
				id="submission-menu"
				open={open}
				onClose={handleClose}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				{menuItem}
				<MenuItem
					onClick={async () => {
						handleClose();
						await dispatch(
							releaseSubmission(
								emission.companyId,
								emission.id,
								props.inviteeId,
							),
						);
					}}
				>
					<ListItemIcon>
						<DeleteOutlineIcon fontSize="small" />
					</ListItemIcon>
					{t("emissions.subscription-list.delete-subscription")}
				</MenuItem>
			</Menu>
		</>
	);
};

export default SubmissionContextMenu;
