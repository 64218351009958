import React from "react";
import Alert from "@/components/alert";
import useBuildVersion from "@/hooks/useBuildVersion";
import { RefreshCw } from "lucide-react";
import { Trans, useTranslation } from "react-i18next";

const BuildVersionAlert = () => {
	const { t } = useTranslation();
	const {
		data: versionQuery,
		isLoading: isLoadingBuildVersion,
		error,
	} = useBuildVersion();

	const version = versionQuery?.data;
	const currentVersion = window.COMMIT_SHA;

	if (
		version === "N/A" ||
		isLoadingBuildVersion ||
		version === currentVersion ||
		!!error
	)
		return null;

	return (
		<Alert
			className="bg-orange-100 border-l-none border-t-none border-r-none border-orange-800/10 text-orange-800 rounded-none border-b-2 pb-2"
			icon={<RefreshCw className="!text-orange-800" />}
			title={t("buildVersionAlert.title")}
			message={
				<Trans
					i18nKey="buildVersionAlert.message"
					components={[
						<a
							key="a"
							className="underline font-medium text-primary"
							href="javascript:window.location.reload();"
						></a>,
					]}
				/>
			}
		/>
	);
};

export default BuildVersionAlert;
