import React from "react";
import { func, bool } from "prop-types";
import styled, { css } from "styled-components";
import Panel from "../../shared/panel/panel";
import ImmutableProps from "react-immutable-proptypes";
import { List, Map } from "immutable";
import DocumentsList from "../document-list/document-list";

const StyledContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	flex: 1;
	min-height: ${(props) => (props.noMinHeight ? 0 : 300)}px;

	${(props) =>
		props.captableMode &&
		css`
			padding: 24px;
		`}
`;

const StyledLeftColumn = styled.div`
	border-right: 1px solid ${(props) => props.theme.colors.solitudeDark};
	width: 350px;
`;

const StyledRightColumn = styled.div`
	flex: 1;
	padding-top: ${(props) => props.theme.spacing[4]};
	padding-right: ${(props) => props.theme.spacing[3]};
	padding-bottom: ${(props) => props.theme.spacing[4]};
	padding-left: ${(props) => props.theme.spacing[4]};
`;

const DocumentList = (props) => {
	const renderContent = () => {
		return (
			<StyledContentWrapper
				noMinHeight={props.noMinHeight}
				captableMode={props.captableMode}
			>
				<StyledLeftColumn>
					{props.folderTreeContainer && props.folderTreeContainer()}
				</StyledLeftColumn>
				<StyledRightColumn>
					<DocumentsList
						documents={props.documents}
						documentsInSubFolders={props.documentsInSubFolders}
						folders={props.folders}
						folderId={props.selectedFolder.get("id")}
						onBadgeClick={props.onBadgeClick}
						isInvestorMode={true}
						userLang={props.userLang}
						region={props.region}
						renderBadge={props.renderBadge}
						renderDropdown={props.renderDropdown}
						showToggleSwitch={props.showToggleSwitch}
						isToggleSwitchChecked={props.isToggleSwitchChecked}
						onToggleSwitchChange={props.onToggleSwitchChange}
						isToggleSwitchDisabled={props.isToggleSwitchDisabled}
						onLoadDocuments={props.onLoadDocuments}
					/>
				</StyledRightColumn>
			</StyledContentWrapper>
		);
	};

	if (props.withoutPanel) {
		return renderContent();
	}

	return (
		<Panel
			title="service.documents"
			mode="light"
			noBottomPadding
			bodyHeight="100%"
			captableMode={props.captableMode}
		>
			{renderContent()}
		</Panel>
	);
};

DocumentList.defaultProps = {
	documents: List(),
	folders: Map(),
	selectedFolder: Map(),
	marginBottom: true,
};

DocumentList.propTypes = {
	documents: ImmutableProps.list,
	documentsInSubFolders: ImmutableProps.list,
	folders: ImmutableProps.map,
	onOpenDocument: func,
	onBadgeClick: func,
	selectedFolder: ImmutableProps.map,
	folderTreeContainer: func,
	withoutPanel: bool,
	renderDropdown: func,
	showToggleSwitch: bool,
	isToggleSwitchChecked: func,
	onToggleSwitchChange: func,
	isToggleSwitchDisabled: func,
	noMinHeight: bool,
	onLoadDocuments: func,
};

export default DocumentList;
