import React, { Component } from "react";
import { connect } from "react-redux";
import { List, Map } from "immutable";
import { list } from "react-immutable-proptypes";
import { func, bool, string } from "prop-types";
import Box from "../../../dumb-components/shared/layout/box/box";
import AgendaList from "../../../dumb-components/meetings/agenda-list/agenda-list";
import AgendaItemContainer from "../agenda/agenda-item.container";
import NewListItemButton from "../../../dumb-components/shared/new-list-item-button/new-list-item-button";
import MeetingTemplateListItem from "../../../dumb-components/meetings/meeting-templates/meeting-templates-details/meeting-template-list-item";
import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import DropdownMenuItem from "../../../dumb-components/shared/dropdown-item/dropdown-item";
import Text from "../../../dumb-components/shared/text/text";
import uuid from "uuid/v1";

class TemplateListContainer extends Component {
	static propTypes = {
		items: list,
		onItemSelect: func,
		onChange: func,
		onChangeItem: func,
		readOnly: bool,
		selectedItemId: string,
	};

	static defaultProps = {
		readOnly: false,
		items: List(),
	};

	state = {
		agendaItemInEditMode: null,
		ordinaryItems: List(),
		postItems: List(),
	};

	dropdownMenuRefs = {};

	componentDidMount = () => {
		const { items } = this.props;
		this.parseItems();

		// Select first item on the list
		const firstItemId = items.first().get("id");
		this.setTemplateItem(firstItemId);
	};

	componentDidUpdate = (prevProps) => {
		const { items } = this.props;
		if (prevProps.items !== items) {
			this.parseItems();
		}

		if (this.state.agendaItemInEditMode && prevProps.items.size < items.size) {
			this.setTemplateItem(this.state.agendaItemInEditMode);
		}
	};

	parseItems = () => {
		const { items } = this.props;

		if (!items) {
			return;
		}

		const lists = items.groupBy((obj) =>
			obj.get("postItem") ? "postItems" : "ordinaryItems",
		);
		this.setState({
			ordinaryItems: lists.get("ordinaryItems"),
			postItems: lists.get("postItems"),
		});
	};

	setTemplateItem = (templateItemId) => {
		const { items, onItemSelect } = this.props;
		let templateItem;

		items.forEach((item) => {
			if (item.get("id") === templateItemId) {
				templateItem = item;
			} else if (item.has("items")) {
				item.get("items", List()).forEach((subitem) => {
					if (subitem.get("id") === templateItemId) {
						templateItem = subitem;
					}
				});
			}
		});

		onItemSelect(templateItem);
	};

	shouldDisplayNewItemButton = (listId, level) => {
		const { items } = this.props;

		if (!items || items.size === 0) {
			return true;
		}

		return level === 1 && listId === "ordinaryItems";
	};

	onAgendaItemClick = (id) => {
		this.setState({ agendaItemInEditMode: id });
		this.setTemplateItem(id);
	};

	onDragEnd = (result) => {
		const { ordinaryItems, postItems, suggestedItems } = this.state;
		const { draggableId, source, destination, type } = result;
		const parseItem = (item, destinationListName) => {
			if (destinationListName === "ordinaryItems") {
				item = item.remove("postItem");
			} else if (destinationListName === "postItems") {
				item = item.set("postItem", true);
			}

			return item;
		};
		const mergeLists = (
			sourceListName,
			sourceList,
			destinationListName,
			destinationList,
		) => {
			const lists = Map({
				ordinaryItems,
				postItems,
				suggestedItems,
			});

			const result = lists
				.map((list, name) => {
					if (name === sourceListName) {
						return sourceList;
					} else if (name === destinationListName) {
						return destinationList;
					}

					return list;
				})
				.toList()
				.filter((list) => (list ? true : false))
				.flatten(true);

			return result;
		};
		const canItemMoveToDestination = (
			destinationIndex,
			destinationListName,
		) => {
			if (destinationListName === "ordinaryItems" && destinationIndex === 0) {
				return false;
			} else if (destinationListName === "postItems") {
				return !(destinationIndex + 1 >= postItems.size);
			}

			return true;
		};
		let agendaItems = List();

		if (!destination) {
			return;
		}

		if (!canItemMoveToDestination(destination.index, destination.droppableId)) {
			return;
		}

		// First level items
		if (type === "1$root") {
			// Move items within same list
			if (source.droppableId === destination.droppableId) {
				let list = this.state[source.droppableId];
				const item = list.find((obj) => obj.get("id") === draggableId);
				list = list.remove(source.index);
				list = list.splice(destination.index, 0, item);
				this.setState({
					[source.droppableId]: list,
				});
				agendaItems = mergeLists(source.droppableId, list);
				// Move items between lists
			} else {
				let sourceList = this.state[source.droppableId];
				let destinationList = this.state[destination.droppableId];
				let item = sourceList.find((obj) => obj.get("id") === draggableId);
				item = parseItem(item, destination.droppableId);
				sourceList = sourceList.remove(source.index);
				destinationList = destinationList.splice(destination.index, 0, item);
				this.setState({
					[source.droppableId]: sourceList,
					[destination.droppableId]: destinationList,
				});
				agendaItems = mergeLists(
					source.droppableId,
					sourceList,
					destination.droppableId,
					destinationList,
				);
			}
			// Second level items
		} else {
			const [_level, _id, parentIndex] = type.split("$");
			let list = this.state[source.droppableId];
			list = list.updateIn([parentIndex, "agendaItems"], (list) => {
				const item = list.find((obj) => obj.get("id") === draggableId);
				list = list.remove(source.index);
				list = list.splice(destination.index, 0, item);
				return list;
			});
			this.setState({
				[source.droppableId]: list,
			});
			agendaItems = mergeLists(source.droppableId, list);
		}

		this.onTemplateChange(["items"], agendaItems);
	};

	onTemplateChange = (field, value) => {
		const { onChange } = this.props;
		onChange && onChange(field, value);
	};

	onItemsChange = (itemId, fieldName, value) => {
		const { onChangeItem, selectedItemId } = this.props;
		let { items } = this.props;

		items = items.map((item) => {
			if (item.get("id") === itemId) {
				item = item.set(fieldName, value);
			} else if (item.get("items", List()).size > 0) {
				const subitems = item.get("items", List()).map((subitem) => {
					if (subitem.get("id") === itemId) {
						subitem = subitem.set(fieldName, value);
					}

					return subitem;
				});

				item = item.set("items", subitems);
			}

			return item;
		});

		if (itemId === selectedItemId) {
			onChangeItem(fieldName, value, false);
		}

		this.onTemplateChange(["items"], items);
	};

	createNewAgendaItem = (listId, parentIndex, itemIndex) => {
		if (listId === "adjournmentList") {
			return;
		}

		const { items } = this.props;
		const insertAtIndex = itemIndex + 1;
		const itemId = uuid();
		const agendaItem = Map({ id: itemId, active: true, progress: "todo" });

		this.timeout && clearTimeout(this.timeout);

		let agendaItems = items;
		let parentItem;

		if (parentIndex >= 0) {
			parentItem = agendaItems.get(parentIndex);
			let itemsInParentItem = parentItem.get("items", List());
			itemsInParentItem = itemsInParentItem.insert(insertAtIndex, agendaItem);
			parentItem = parentItem.set("items", itemsInParentItem);
			agendaItems = agendaItems.set(parentIndex, parentItem);
		} else {
			agendaItems = agendaItems.insert(insertAtIndex, agendaItem);
		}

		this.onTemplateChange(["items"], agendaItems);
		this.setState({ agendaItemInEditMode: itemId });
	};

	resetAgendaItemInEditMode = () => {
		this.timeout = setTimeout(() => {
			this.setState({ agendaItemInEditMode: null });
		}, 500);
	};

	onDeleteMeetingTemplateItem = (itemId) => {
		const { items } = this.props;
		let agendaItems = items;
		let parentIndex = -1,
			itemIndex;

		agendaItems.forEach((item, index) => {
			if (item.get("id") === itemId) {
				itemIndex = index;
			} else if (item.get("items")) {
				item.get("items", List()).forEach((subitem, subindex) => {
					if (subitem.get("id") === itemId) {
						itemIndex = subindex;
						parentIndex = index;
					}
				});
			}
		});

		if (parentIndex >= 0) {
			let parentItem = agendaItems.get(parentIndex);
			parentItem = parentItem.deleteIn(["items", itemIndex]);

			if (parentItem.get("items").size === 0) {
				parentItem = parentItem.set(
					"itemType",
					parentItem.get("oldItemType", null),
				);
				parentItem = parentItem.delete("items");
			}

			agendaItems = agendaItems.set(parentIndex, parentItem);
		} else {
			agendaItems = agendaItems.delete(itemIndex);
		}

		this.onTemplateChange(["items"], agendaItems);
		this.dropdownMenuRefs[itemId].onToggleMenu();
	};

	renderDropdownMenu = (id) => {
		return (
			<DropdownMenuContainer
				btnIcon="faEllipsisV"
				halignMenu="right"
				btnMode="transparent-icon"
				transparentIconButtonSize="sml"
				tooltipActiveState="btnMoreActions"
				ref={(ref) => (this.dropdownMenuRefs[id] = ref)}
				inline
			>
				<DropdownMenuItem onClick={() => this.onDeleteMeetingTemplateItem(id)}>
					<Text tid="generic.delete" />
				</DropdownMenuItem>
			</DropdownMenuContainer>
		);
	};

	renderAgendaItem = (listId, templateItemProps) => {
		const { readOnly } = this.props;
		const { agendaItemInEditMode } = this.state;

		templateItemProps = { ...templateItemProps };

		return (
			<AgendaItemContainer
				{...templateItemProps}
				onClick={this.onAgendaItemClick}
				onChange={this.onItemsChange}
				onEnter={this.createNewAgendaItem.bind(
					this,
					listId,
					templateItemProps.parentIndex,
					templateItemProps.itemIndex,
				)}
				onFocus={() => this.timeout && clearTimeout(this.timeout)}
				onBlur={this.resetAgendaItemInEditMode}
				inEditMode={agendaItemInEditMode === templateItemProps.id}
				itemComponent={MeetingTemplateListItem}
				renderDropdownMenu={this.renderDropdownMenu.bind(
					this,
					templateItemProps.id,
				)}
				noDurationMenu={true}
				readOnly={readOnly}
			/>
		);
	};

	renderNewAgendaItemButton = (listId, listSize, indexOfParentItem) => {
		const tid =
			listSize > 0
				? "meetings.list.add_another_item"
				: "meetings.list.add_new_item";

		return (
			<NewListItemButton
				onClick={this.createNewAgendaItem.bind(
					this,
					listId,
					indexOfParentItem,
					listSize - 1,
				)}
				type="template"
				tid={tid}
			/>
		);
	};

	render = () => {
		const { readOnly, selectedItemId } = this.props;
		const { agendaItemInEditMode, ordinaryItems, postItems } = this.state;

		return (
			<Box direction="column">
				<AgendaList
					listName="ordinaryItems"
					ordinaryItems={ordinaryItems}
					postItems={postItems}
					renderItemComponent={this.renderAgendaItem}
					renderNewItemButton={this.renderNewAgendaItemButton}
					shouldDisplayNewItemButton={this.shouldDisplayNewItemButton}
					isDragDisabled={readOnly}
					onDragEnd={this.onDragEnd}
					agendaItemInEditMode={agendaItemInEditMode}
					childProps="items"
					seperatorBgColor="white"
					selectedItemId={selectedItemId}
				/>
			</Box>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(TemplateListContainer);
