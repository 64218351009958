import { List, Set, Map } from "immutable";
import { getStore } from "../../store";
import { DEFAULT_TEAM_SUPER_ADMIN } from "/shared/constants";
const store = getStore();

const obj = {
	parseRoleOptions: (permissions, rolesCache) => {
		let options = List();
		const rolesWithAccess = permissions.getIn(["roles", "rolesWithAccess"]);

		if (!rolesWithAccess) {
			return options;
		}

		rolesWithAccess.forEach((permissions, roleId) => {
			if (rolesCache.getIn([roleId, "name"])) {
				options = options.push(
					Map({ label: rolesCache.getIn([roleId, "name"]), value: roleId }),
				);
			}
		});
		options = options.filter((item) => {
			return !(item.get("value") === DEFAULT_TEAM_SUPER_ADMIN);
		});

		options = obj._sortOptionsInAlphabeticalOrder(options);

		return options;
	},

	parseUsersOptions: (permissions, usersInRoleCache, usersCache, isSimple) => {
		let options = List();
		let usersSet = Set();
		let invertedUsersSet = Set();

		const selectedRoles = obj.parseRoleValues(permissions);
		usersInRoleCache.forEach((users, role) => {
			users.forEach((user) => {
				if (selectedRoles.includes(role)) {
					usersSet = usersSet.add(user);
				} else {
					invertedUsersSet = invertedUsersSet.add(user);
				}
			});
		});

		invertedUsersSet = invertedUsersSet.subtract(usersSet);

		usersSet.forEach((userId) => {
			if (usersCache.getIn([userId, "name"])) {
				options = options.push(
					Map({
						label: usersCache.getIn([userId, "name"]),
						value: userId,
						inRole: true,
					}),
				);
			}
		});

		invertedUsersSet.forEach((userId) => {
			if (usersCache.getIn([userId, "name"])) {
				options = options.push(
					Map({
						label: usersCache.getIn([userId, "name"]),
						value: userId,
						inRole: false,
						multiValueLabelColor: "white",
					}),
				);
			}
		});

		options = obj._sortOptionsInAlphabeticalOrder(options);

		if (isSimple) {
			options = options.map((option) => {
				return option.get("value");
			});
		}

		return options;
	},

	parseRoleValues: (permissions) => {
		let parsedValues = List();
		const isRestricted = permissions.get("isRestricted");
		const whitelist = permissions.getIn(["roles", "whitelist"], Map()) || Map();
		if (isRestricted) {
			whitelist.forEach((permissions, roleId) => {
				parsedValues = parsedValues.push(roleId);
			});
		} else {
			const blacklist = Set.fromKeys(permissions.getIn(["roles", "blacklist"]));
			const inheritedWhitelist = Set.fromKeys(
				permissions.getIn(["roles", "inheritedWhitelist"]),
			);
			const inheritedBlacklist = Set.fromKeys(
				permissions.getIn(["roles", "inheritedBlacklist"]),
			);
			const rolesWithAccess = Set.fromKeys(
				permissions.getIn(["roles", "rolesWithAccess"]),
			);

			let sortedSet = rolesWithAccess.intersect(inheritedWhitelist);
			sortedSet = sortedSet.subtract(inheritedBlacklist);

			sortedSet = sortedSet.subtract(blacklist);
			sortedSet = sortedSet.union(Set.fromKeys(whitelist));
			parsedValues = List(sortedSet);
		}
		parsedValues = parsedValues.filter((item) => {
			return !(item === DEFAULT_TEAM_SUPER_ADMIN);
		});
		return parsedValues;
	},

	parseUsersValues: (permissions, usersInRoleCache, usersCache) => {
		let parsedValues = List();
		const isRestricted = permissions.get("isRestricted");
		let whitelist = permissions.getIn(["users", "whitelist"], Map()) || Map();

		let usersOptions = obj.parseUsersOptions(
			permissions,
			usersInRoleCache,
			usersCache,
		);

		usersOptions = usersOptions.filter((option) => {
			return option.get("inRole");
		});

		usersOptions = usersOptions.map((option) => {
			return option.get("value");
		});

		if (isRestricted) {
			whitelist.forEach((permissions, roleId) => {
				parsedValues = parsedValues.push(roleId);
			});
		} else {
			const inheritedWhitelist = Set.fromKeys(
				permissions.getIn(["users", "inheritedWhitelist"]),
			);
			const inheritedBlacklist = Set.fromKeys(
				permissions.getIn(["users", "inheritedBlacklist"]),
			);
			const blacklist = Set.fromKeys(permissions.getIn(["users", "blacklist"]));
			whitelist = Set.fromKeys(whitelist);

			let sortedSet = inheritedWhitelist.union(Set(usersOptions));
			sortedSet = sortedSet.subtract(inheritedBlacklist);
			sortedSet = sortedSet.subtract(blacklist);
			sortedSet = sortedSet.union(whitelist);

			parsedValues = List(sortedSet);
		}
		return parsedValues;
	},

	isUserAllowed: (userId, permissions, usersInRoleCache, usersCache) => {
		const users = obj.parseUsersValues(
			permissions,
			usersInRoleCache,
			usersCache,
		);
		return users && users.includes(userId);
	},

	isRoleAllowed: (roleId, permissions) => {
		const roles = obj.parseRoleValues(permissions);
		return roles && roles.includes(roleId);
	},

	getIsAdminForSelectedCompany: (companiesAccessRights, companyId) => {
		if (!companiesAccessRights || companiesAccessRights.size === 0) {
			return;
		}

		if (companyId) {
			const currentCompanyAccessRights = companiesAccessRights.get(
				companyId,
				Map(),
			);

			return currentCompanyAccessRights.find(
				(role) => role.get("id") === DEFAULT_TEAM_SUPER_ADMIN,
			)
				? true
				: false;
		}

		const storeState = store.getState();
		const storeStateCompanyId = storeState.company.company.id;
		const currentCompanyAccessRights = companiesAccessRights.get(
			storeStateCompanyId,
			Map(),
		);

		return currentCompanyAccessRights.find(
			(role) => role.get("id") === DEFAULT_TEAM_SUPER_ADMIN,
		)
			? true
			: false;
	},

	_sortOptionsInAlphabeticalOrder: (options, key = "label") => {
		return options.sort((a, b) => a.get(key).localeCompare(b.get(key)));
	},
};

export default obj;
