import React, { PureComponent } from "react";
import { func } from "prop-types";
import styled from "styled-components";
import DarkInfoPanel from "../../shared/dark-info-panel/dark-info-panel";

const StyledDarkInfoPanel = styled(DarkInfoPanel)`
	margin: ${(props) => props.theme.spacing[3]}
		${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[4]}
		${(props) => props.theme.spacing[4]};
`;

export default class ViaGuiPanel extends PureComponent {
	static propTypes = {
		headerLeftComponent: func,
		footerComponent: func,
	};

	render = () => {
		const { headerLeftComponent, footerComponent } = this.props;

		return (
			<>
				<StyledDarkInfoPanel
					headerLeftComponent={headerLeftComponent()}
					footerComponent={footerComponent()}
				/>
			</>
		);
	};
}
