import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MUIDialog from "../../mui-components/dialog/mui-dialog";
import { useTranslation } from "react-i18next";

export default function AddFolderModal({ open, onCancel, onAccept }) {
	const [value, setValue] = useState("");
	const { t } = useTranslation();
	const label = t("personal-documents.folder-name");
	return (
		<MUIDialog
			isOpen={open}
			title={t("personal-documents.add-folder")}
			confirmButtonTid="generic.form.save"
			closeButtonTid="dialog.generic.cancel"
			disabledConfirm={!value}
			onConfirm={() => onAccept(value.trim())}
			onClose={onCancel}
		>
			<TextField
				sx={{ mt: 4 }}
				required
				autoFocus
				id="folder-name-input"
				label={label}
				value={value}
				onChange={({ target }) => setValue(target.value)}
			/>
		</MUIDialog>
	);
}
