import React from "react";
import InvestmentContainer from "../investments/investment.container";

const ShareholderPreview = ({ ...props }) => {
	return (
		<div className="flex flex-col h-full p-md">
			<InvestmentContainer {...props} isShareholderPreview />
		</div>
	);
};

export default ShareholderPreview;
