import React, { Component } from "react";
import { connect } from "react-redux";
import immutablePropTypes from "react-immutable-proptypes";
import { string, object, bool, func, number } from "prop-types";
import i18nhelper from "../../../components/helpers/i18n.helper";
import MeetingItem from "../../../dumb-components/meetings/meeting-list/meeting-item";

class MeetingItemContainer extends Component {
	static propTypes = {
		id: string,
		name: string,
		status: string,
		listIndex: number,
		userLang: string,
		region: object,
		active: bool,
		groupId: string,
		groupPath: immutablePropTypes.list,
		groupName: immutablePropTypes.map,
		onBadgeClick: func,
		onMeetingClick: func,
		onArrowUp: func,
		onArrowDown: func,
		onFocus: func,
		onBlur: func,
		startDate: string,
		endDate: string,
		readOnly: bool,
		agendaPublished: bool,
		protocolPublished: bool,
		goToSection: string,
		meetingIsFinished: bool,
		archived: bool,
		meetingType: string,
		protocolUploaded: bool,
	};

	renderGroupPath = () => {
		const { groupPath, userLang, region } = this.props;

		if (!groupPath) {
			return;
		}

		return groupPath
			.map((obj) => {
				return i18nhelper.getTranslatedValue(obj, userLang, region);
			})
			.join(" / ");
	};

	render = () => {
		const {
			id,
			name,
			startDate,
			endDate,
			showWarning,
			userLang,
			region,
			active,
			onBadgeClick,
			onMeetingClick,
			groupId,
			agendaPublished,
			protocolPublished,
			goToSection,
			isSecretary,
			meetingIsFinished,
			archived,
			meetingType,
			protocolUploaded,
		} = this.props;
		const groupPath = this.renderGroupPath();
		const groupName = i18nhelper.getTranslatedValue(
			this.props.groupName,
			userLang,
			region,
		);

		return (
			<MeetingItem
				id={id}
				name={name}
				startDate={startDate}
				endDate={endDate}
				showWarning={showWarning}
				active={active}
				groupId={groupId}
				groupPath={groupPath}
				groupName={groupName}
				onBadgeClick={onBadgeClick}
				onMeetingClick={onMeetingClick}
				agendaPublished={agendaPublished}
				protocolPublished={protocolPublished}
				goToSection={goToSection}
				isSecretary={isSecretary}
				meetingIsFinished={meetingIsFinished}
				archived={archived}
				meetingType={meetingType}
				protocolUploaded={protocolUploaded}
			/>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingItemContainer);
