import React, { useState, useCallback } from "react";
import MUITreeView from "@mui/lab/TreeView";
import TreeDocumentItem from "./tree-document-item";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "@mui/material/Button";
import { FormattedMessage } from "react-intl";
import TreeFolderItem from "./tree-folder-item";
import {
	movePersonalDocument,
	movePersonalFolder,
	uploadPersonalDocuments,
} from "../../actions/personal-documents.actions";
import { useDispatch } from "react-redux";

const TreeView = ({ documents, folderIds }) => {
	const rootNodeId = "personal-documents.my-documents-";
	const [expanded, setExpanded] = useState([rootNodeId]);
	const rootIsExpanded = expanded.some((e) => e === rootNodeId);
	const dispatch = useDispatch();

	const handleToggle = (event, nodeIds) => {
		setExpanded(nodeIds);
	};

	const handleExpandClick = () => {
		setExpanded((oldExpanded) =>
			oldExpanded.length && rootIsExpanded ? [] : folderIds.toArray(),
		);
	};

	const handleDrop = useCallback((prefix, documents) => {
		dispatch(uploadPersonalDocuments(prefix, documents));
	}, []);

	const renderNode = (root) => {
		const children = root.get("children");
		const absolutePath = root.get("absolutePath");
		const name = root.get("name");
		const isFolder = root.get("isFolder");
		const nodeId = `${name}-${absolutePath}`;

		return isFolder ? (
			<TreeFolderItem
				key={nodeId}
				name={name}
				absolutePath={absolutePath}
				nodeId={nodeId}
				isRoot={root.get("isRoot")}
				onDragStart={(e) => {
					e.stopPropagation();
					e.dataTransfer.setData(
						"drop-data",
						JSON.stringify({ absolutePath, type: "folder" }),
					);
				}}
				handleDocumentDrop={(e) => {
					const dropData = JSON.parse(e.dataTransfer.getData("drop-data"));
					return dispatch(
						movePersonalDocument(dropData.absolutePath, absolutePath),
					);
				}}
				handleFolderDrop={(e) => {
					const dropData = JSON.parse(e.dataTransfer.getData("drop-data"));
					return dispatch(
						movePersonalFolder(dropData.absolutePath, absolutePath),
					);
				}}
				handleBinaryFileDrop={(documents) =>
					handleDrop(absolutePath, documents)
				}
			>
				{!!children.size && children.map((c) => renderNode(c))}
			</TreeFolderItem>
		) : (
			<TreeDocumentItem
				key={nodeId}
				name={name}
				onDragStart={(e) => {
					e.stopPropagation();
					e.dataTransfer.setData(
						"drop-data",
						JSON.stringify({ absolutePath, type: "file" }),
					);
				}}
				absolutePath={absolutePath}
				nodeId={nodeId}
				handleDrop={(documents) => handleDrop(absolutePath, documents)}
			/>
		);
	};

	return (
		<>
			<Button onClick={handleExpandClick} size={"small"} sx={{ my: 1 }}>
				<FormattedMessage
					id={
						expanded.length && rootIsExpanded
							? "personal-documents.collapse-all-folders"
							: "personal-documents.expand-all-folders"
					}
				/>
			</Button>
			<MUITreeView
				aria-label="file system navigator"
				defaultCollapseIcon={<ExpandMoreIcon />}
				defaultExpandIcon={<ChevronRightIcon />}
				defaultExpanded={[rootNodeId]}
				expanded={expanded}
				onNodeToggle={handleToggle}
				sx={{
					overflowY: "auto",
					minHeight: 300,
				}}
			>
				{renderNode(documents)}
			</MUITreeView>
		</>
	);
};
export default TreeView;
