import React from "react";
import Cookies from "universal-cookie";
import i18nHelper from "@/components/helpers/i18n.helper";

import Select from "@/components/select";

import flagSE from "@/assets/images/flags/se.svg";
import flagUS from "@/assets/images/flags/us.svg";

import { useTranslation } from "react-i18next";
import useMe from "@/hooks/users/useMe";
import { useDispatch } from "react-redux";
import { setLanguage } from "@/actions/i18n.actions";
import useUpdateUser from "@/hooks/users/useUpdateUser";

const flagByLocale = {
	"en-GB": flagUS,
	"sv-SE": flagSE,
};

const cookies = new Cookies();

const LanguagePickerDropdown = ({ className }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { mutateAsync: updateUser, isPending: isUpdateUserPending } =
		useUpdateUser("me");

	const { data: meQuery, isLoading: isLoadingMe } = useMe({
		retry: (_failureCount, error) => (error?.response?.status === 403 ? 0 : 3), // Stop retrying user is not logged in
	});

	const me = meQuery?.data;
	const languageFromUserApi = me?.siteSettings?.language;
	const languageFromCookie = cookies.get("userLanguage");
	const browserLanguage = i18nHelper.getBrowserLanguage();
	const value = languageFromUserApi ?? languageFromCookie ?? browserLanguage;
	const isLoading = isLoadingMe || isUpdateUserPending;

	const options = [
		{ value: "en-GB", label: t("language_switcher.language.english") },
		{ value: "sv-SE", label: t("language_switcher.language.swedish") },
	];

	const onChange = async (value) => {
		if (me) {
			await updateUser({ siteSettings: { language: value } });
		}
		dispatch(setLanguage(value));
	};

	return (
		<Select
			className={className}
			value={value}
			onChange={onChange}
			optionRenderer={(option) => (
				<div className="flex items-center gap-2">
					<img
						className="rounded"
						src={flagByLocale[option.value]}
						width={24}
					/>
					{option.label}
				</div>
			)}
			options={options}
			isLoading={isLoading}
		/>
	);
};

export default LanguagePickerDropdown;
