import React, { Component } from "react";
import { connect } from "react-redux";
import ScreenMessage from "../../dumb-components/shared/screen-message/screen-message";

import StartMeetingMessageContainer from "../meetings/start-meeting-message.container";

const mapComponentIdToComponentClass = {
	START_MEETING: StartMeetingMessageContainer,
};

class ScreenMessageContainer extends Component {
	renderScreenMessage = (Component, props) => {
		return (
			<ScreenMessage>
				<Component {...props} />
			</ScreenMessage>
		);
	};
	render = () => {
		const { activeMessage } = this.props;

		if (!activeMessage) {
			return null;
		}
		const props = activeMessage.componentProps || {};
		const componentId = activeMessage.componentId;

		if (!componentId) {
			return null;
		}

		const Component = mapComponentIdToComponentClass[componentId];

		return this.renderScreenMessage(Component, props);
	};
}

const mapStoreToProps = (store) => {
	return {
		activeMessage: store.screenMessage.activeMessage,
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ScreenMessageContainer);
