import React from "react";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";

const PdfDownloadWarningModal = (props) => {
	const { t } = useTranslation();
	return (
		<Dialog open={props.open} onClose={props.close}>
			<DialogContent sx={{ px: 6, py: 4, minWidth: "60rem" }}>
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Typography sx={{ textAlign: "center", pb: 2 }} variant="h4">
						{t(
							"emissions.preparations.pdf-download-warning-form-not-complete.header",
						)}
					</Typography>
					<Box sx={{ textAlign: "center" }}>
						{t("emissions.preparations.pdf-download-warning-desc")}
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
							mt: 7,
							columnGap: 2,
						}}
					>
						<Button
							onClick={props.close}
							variant={"contained"}
							sx={{ fontWeight: "bold" }}
						>
							<Typography variant={"h5"}>
								{t("emissions.preparations.close")}
							</Typography>
						</Button>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default PdfDownloadWarningModal;
