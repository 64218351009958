import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "../../modules/moment.module";

import {
	allowSupportAccess,
	disallowSupportAccess,
} from "../../actions/user.actions";

import DropdownMenuContainer from "../shared/dropdown-menu.container";
import DropdownIconItem from "../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import { OutlinedButton } from "../../dumb-components/shared/button-v2";
import { USERS_SUPPORT_ACCESS_CODE_VALID_IN_MINUTES } from "/shared/constants";

class RequestSupportDropdownContainer extends Component {
	dropdownRef = null;

	setDropdownRef = (ref) => {
		this.dropdownRef = ref;
	};

	toggleMenu = () => {
		this.dropdownRef && this.dropdownRef.onToggleMenu();
	};

	handleGrantAccess = () => {
		const { allowSupportAccess } = this.props;
		allowSupportAccess();
		this.toggleMenu();
	};

	handleEndAccess = () => {
		const { disallowSupportAccess } = this.props;
		disallowSupportAccess();
		this.toggleMenu();
	};

	renderDropdownItem = () => {
		const { supportCode, supportCreatedAt } = this.props;
		const expiresAt =
			supportCreatedAt &&
			Moment(supportCreatedAt).add(
				USERS_SUPPORT_ACCESS_CODE_VALID_IN_MINUTES,
				"minutes",
			);
		const hasExpired = Moment().isAfter(expiresAt);

		if (!supportCode || hasExpired) {
			return (
				<DropdownIconItem
					icon="faFingerprint"
					tid="user_profile.support.dropdown.item.grant_support_access"
					onClick={this.handleGrantAccess}
				/>
			);
		}

		return (
			<DropdownIconItem
				icon="faTimes"
				tid="user_profile.support.dropdown.item.end_support_access"
				onClick={this.handleEndAccess}
			/>
		);
	};

	render = () => {
		return (
			<DropdownMenuContainer
				halignMenu="right"
				ref={this.setDropdownRef}
				renderRaw={
					<OutlinedButton
						icon="faEllipsisV"
						borderColor="midGrey"
						color="midGrey"
					/>
				}
				noMaxWidth={true}
			>
				{this.renderDropdownItem()}
			</DropdownMenuContainer>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		supportCode: store.user.getIn(["userObj", "supportAccessCode", "code"]),
		supportCreatedAt: store.user.getIn([
			"userObj",
			"supportAccessCode",
			"createdAt",
		]),
	};
};

const mapActionsToProps = {
	allowSupportAccess,
	disallowSupportAccess,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(RequestSupportDropdownContainer);
