import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { List, Map } from "immutable";
import { closeModal, openModal } from "../../actions/modals.actions";
import { deleteTasksPerminantly } from "../../actions/tasks.actions";
import { downloadProjectMatrix } from "../../actions/projects.actions";

import IconDrawer from "../../dumb-components/shared/icon-drawer/icon-drawer";
import ConfirmContainer from "../shared/confirm.container";

const DELETE_TASKS_TOOLTIP_STATES = {
	default: {
		tid: "tasks.perminantly_delete_tasks.tooltip.default",
	},
	disabled: {
		tid: "tasks.perminantly_delete_tasks.tooltip.disabled",
		delayShow: "instant",
	},
};

const DOWNLOAD_PROJECT_MATRIX_TOOLTIP_STATES = {
	default: {
		tid: "tasks.download_project_structure.tooltip.default",
	},
};

const CONFIRM_PERMINANTLY_DELETE_TASKS_MODAL =
	"CONFIRM_PERMINANTLY_DELETE_TASKS_MODAL";

class TasksSettingsBarContainer extends Component {
	getDrawerObject = () => {
		const { downloadProjectMatrix } = this.props;

		return List([
			Map({
				icon: "faCog",
			}),
			Map({
				icon: "faFileSpreadsheet",
				tooltipStates: DOWNLOAD_PROJECT_MATRIX_TOOLTIP_STATES,
				onClick: () => downloadProjectMatrix(true),
			}),
			Map({
				icon: "faTrash",
				disabled: false,
				tooltipStates: DELETE_TASKS_TOOLTIP_STATES,
				tooltipActiveState: "default",
				onClick: this.onModalOpen.bind(
					this,
					CONFIRM_PERMINANTLY_DELETE_TASKS_MODAL,
				),
			}),
		]);
	};

	onModalOpen = (modalName) => {
		this.props.openModal(modalName);
	};

	onModalClose = () => {
		this.props.closeModal();
	};

	doDeleteDocuments = () => {
		const { taskId, deleteTasksPerminantly } = this.props;

		this.onModalClose();
		deleteTasksPerminantly(taskId);
	};

	renderConfirmDeleteDocumentsModal = () => {
		const { confirmDeleteTasksModalOpen } = this.props;

		return (
			<ConfirmContainer
				title="tasks.perminantly_delete_tasks.confirm.title"
				question="tasks.perminantly_delete_tasks.confirm.question"
				onConfirm={this.doDeleteDocuments}
				onDecline={this.onModalClose}
				isOpen={confirmDeleteTasksModalOpen}
			/>
		);
	};

	render = () => {
		const drawer = this.getDrawerObject();

		return (
			<Fragment>
				<IconDrawer drawer={drawer} />

				{this.renderConfirmDeleteDocumentsModal()}
			</Fragment>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		confirmDeleteTasksModalOpen:
			store.modals.getIn(["activeModal", "name"]) ===
			CONFIRM_PERMINANTLY_DELETE_TASKS_MODAL,
		taskId: store.tasks.getIn(["task", "id"]),
		companiesAccessRights: store.company.companiesAccessRights,
	};
};

const mapActionsToProps = {
	closeModal,
	openModal,
	deleteTasksPerminantly,
	downloadProjectMatrix,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(TasksSettingsBarContainer);
