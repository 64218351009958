import React, { useState, useRef, useEffect } from "react";
import { func } from "prop-types";
import { Map, List } from "immutable";
import CompanyWizard from "../../../dumb-components/company/company-wizard/company-wizard";
import SynaSearch from "../../syna/search.container";
import CountrySelect from "../../../dumb-components/shared/country-select/country-select";
import styled from "styled-components";
import Input from "../../../dumb-components/shared/input/input";
import { connect } from "react-redux";
import Button from "../../../dumb-components/shared/button/button";
import Text from "../../../dumb-components/shared/text/text";

const SearchWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const SearchInnerWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 3fr;
	column-gap: 8px;
`;

const NameOrgNumberWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 8px;
`;

const INITIAL_STATE = {
	step: 1,
	company: Map(),
	toBeAuthorizedBy: null,
	allowedRepresentativesOptions: List(),
	ToS: false,
	companyCountry: "SE",
	companyName: "",
	companyOrgNumber: "",
};

const CompanyAccountWizardContainer = ({
	isInvonoAdmin,
	onAuthorize,
	onSkipAuthorise,
	onVerifyToCustomer,
}) => {
	const [state, setState] = useState(INITIAL_STATE);
	const stepRefs = useRef({});
	const scrollbarRef = useRef(null);
	const synaSearchRef = useRef(null);

	const adminOverrideData =
		(isInvonoAdmin &&
			state.companyCountry !== "SE" &&
			state.companyName.length > 0 &&
			state.companyOrgNumber.length > 0 && {
				companyName: state.companyName,
				companyOrgNumber: state.companyOrgNumber,
				companyCountry: state.companyCountry,
			}) ||
		undefined;

	useEffect(() => {
		const { step, company } = state;

		if (company.size > 0 && step !== INITIAL_STATE.step) {
			stepRefs.current[step]?.scrollIntoViewIfNeeded?.();
		}
	}, [state]);

	const onChangeCompany = (company) => {
		if (!company) {
			resetState();
			return;
		}

		setState((prevState) => ({
			...prevState,
			company,
			step: 2,
			toBeAuthorizedBy: null,
			ToS: false,
		}));
	};

	const onChange = (fieldName, value) => {
		if (fieldName === "toBeAuthorizedBy") {
			return setState((prevState) => ({
				...prevState,
				[fieldName]: value,
				step: prevState.step >= 3 ? prevState.step : 3,
			}));
		}

		if (fieldName === "ToS") {
			return setState((prevState) => ({
				...prevState,
				[fieldName]: value,
				step: value ? 4 : 3,
			}));
		}

		setState((prevState) => ({ ...prevState, [fieldName]: value }));
	};

	const onSetStepRef = (index, ref) => {
		stepRefs.current[index] = ref;
	};

	const onSetScrollbarRef = (ref) => {
		scrollbarRef.current = ref;
	};

	const onSetSearchRef = (ref) => {
		synaSearchRef.current = ref;
	};

	const onAuthorizeHandler = () => {
		const { company, toBeAuthorizedBy } = state;

		onAuthorize?.(
			Map({
				companyOrgNumber: company.getIn(["company", "orgNumber"]),
				companyName: company.getIn(["company", "name"]),
				toBeAuthorizedBy,
			}),
		);
	};

	const onSkipAuthoriseHandler = () => {
		const { company } = state;

		onSkipAuthorise?.(
			Map({
				companyOrgNumber: company.getIn(["company", "orgNumber"]),
				companyName: company.getIn(["company", "name"]),
			}),
		);
	};

	const onVerifyToCustomerHandler = () => {
		const { company } = state;

		const details = adminOverrideData
			? {
					companyName: adminOverrideData.companyName,
					companyOrgNumber: adminOverrideData.companyOrgNumber,
					companyCountry: adminOverrideData.companyCountry,
			  }
			: {
					companyName: company.getIn(["company", "name"]),
					companyOrgNumber: company.getIn(["company", "orgNumber"]),
					companyCountry: "SE",
			  };

		onVerifyToCustomer?.(Map(details));
	};

	const searchComponentRenderer = () => {
		const synaSearchElement = (
			<SynaSearch onChange={onChangeCompany} onSetRef={onSetSearchRef} />
		);

		const foreignContryAdminElement = (
			<>
				<SearchInnerWrapper>
					<CountrySelect
						fieldName="companyCountry"
						value={state.companyCountry}
						onChange={onChange}
					/>
					{state.companyCountry !== "SE" ? (
						<NameOrgNumberWrapper>
							<Input
								fieldName="companyName"
								value={state.companyName}
								onChange={onChange}
								placeholderTid="generic.company_placeholder"
							/>
							<Input
								fieldName="companyOrgNumber"
								value={state.companyOrgNumber}
								onChange={onChange}
								placeholderTid="generic.company_org_number_placeholder"
							/>
						</NameOrgNumberWrapper>
					) : (
						synaSearchElement
					)}
				</SearchInnerWrapper>
				{adminOverrideData && (
					<Button
						mode="primary"
						tid="generic.create_company_account"
						onClick={onVerifyToCustomerHandler}
					/>
				)}
			</>
		);

		return (
			<SearchWrapper>
				<Text tid="account_wizard.select_company_info" />
				{isInvonoAdmin ? foreignContryAdminElement : synaSearchElement}
			</SearchWrapper>
		);
	};

	const resetState = () => {
		setState(INITIAL_STATE);
	};

	const { step, ToS, company } = state;

	return (
		<CompanyWizard
			isInvonoAdmin={isInvonoAdmin}
			step={step}
			ToS={ToS}
			onSetScrollbarRef={onSetScrollbarRef}
			onSetStepRef={onSetStepRef}
			onChange={onChange}
			onAuthorize={onAuthorizeHandler}
			renderSearch={searchComponentRenderer}
			allowedRepresentativesToSign={company
				?.get("allowedRepresentativesToSign")
				?.toJS()}
			onSkipAuthorise={onSkipAuthoriseHandler}
			onVerifyToCustomer={onVerifyToCustomerHandler}
		/>
	);
};

CompanyAccountWizardContainer.propTypes = {
	onAuthorize: func,
	onSkipAuthorise: func,
	onVerifyToCustomer: func,
};

const mapStateToProps = (state) => ({
	isInvonoAdmin: state.user.getIn(["userObj", "invonoAdmin"]) === true,
});

export default connect(mapStateToProps)(CompanyAccountWizardContainer);
