import React, { PureComponent } from "react";
import immutablePropTypes from "react-immutable-proptypes";
import { func, string } from "prop-types";
import styled, { css } from "styled-components";
import moment from "../../../../modules/moment.module";
import ScrollView from "../../../shared/layout/scroll-view/scroll-view";
import List from "../../../shared/list/list";
import ListItem from "../../../shared/list-item/list-item";
import Text from "../../../shared/text/text";
import Icon from "../../../shared/icon/icon";
import { StyledListHeaderTemplate } from "../../../shared/list/list-header.template";

const StyledHeader = styled.div`
	${StyledListHeaderTemplate}
`;

const StyledList = styled(List)`
	margin: ${(props) => props.theme.spacing[5]}
		${(props) => props.theme.spacing[6]};
`;

const StyledListItem = styled(ListItem)`
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeDark};
	padding: 0;
	margin-bottom: 0px;
	align-items: center;
	cursor: pointer;
`;

const CommonTemplate = css`
	display: flex;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledDate = styled.div`
	${CommonTemplate}
	flex-basis: 15%;
	max-width: 15%;
`;

const StyledMeetingName = styled.div`
	${CommonTemplate}
	flex-basis: 25%;
	max-width: 25%;
`;

const StyledMeetingTemplate = styled.div`
	${CommonTemplate}
	flex-basis: 20%;
	max-width: 20%;
`;

const StyledStatus = styled.div`
	${CommonTemplate}
	flex-basis: 20%;
	max-width: 20%;
`;

const StyledSecretary = styled.div`
	${CommonTemplate}
	flex-basis: 15%;
	max-width: 15%;
`;

const StyledCheckWrapper = styled.div`
	${CommonTemplate}
	flex-basis: 5%;
	max-width: 5%;
	justify-content: flex-end;
`;

export default class PreviousMeetingList extends PureComponent {
	static propTypes = {
		meetings: immutablePropTypes.list,
		metadata: immutablePropTypes.map,
		onSelectMeeting: func,
		selectedMeetingId: string,
	};

	renderHeader = () => {
		return (
			<StyledHeader>
				<StyledDate>
					<Text
						tid="meetings.previous_meeting.date"
						bold={400}
						color="lightGrey"
					/>
				</StyledDate>
				<StyledMeetingName>
					<Text
						tid="meetings.previous_meeting.meeting_name"
						bold={400}
						color="lightGrey"
					/>
				</StyledMeetingName>
				<StyledMeetingTemplate>
					<Text
						tid="meetings.previous_meeting.meeting_type"
						bold={400}
						color="lightGrey"
					/>
				</StyledMeetingTemplate>
				<StyledStatus>
					<Text
						tid="meetings.previous_meeting.status"
						bold={400}
						color="lightGrey"
					/>
				</StyledStatus>
				<StyledSecretary>
					<Text
						tid="meetings.previous_meeting.secretary"
						bold={400}
						color="lightGrey"
					/>
				</StyledSecretary>
				<StyledCheckWrapper></StyledCheckWrapper>
			</StyledHeader>
		);
	};

	renderMeeting = (meeting, index) => {
		const { selectedMeetingId, onSelectMeeting, metadata } = this.props;
		const id = meeting.get("id");
		const isSelected = selectedMeetingId === id;
		const statusTid = meeting.getIn(["computedValues", "protocolPublished"])
			? "meetings.previous_meeting.status.published"
			: "meetings.previous_meeting.status.unpublished";

		return (
			<StyledListItem
				hoverColor="lightestGrey"
				key={index}
				onClick={() => onSelectMeeting(id)}
			>
				<StyledDate>
					<Text>
						{meeting.get("startDate") &&
							moment(meeting.get("startDate")).format("L")}
					</Text>
				</StyledDate>

				<StyledMeetingName>
					<Text singleLine>{meeting.get("name")}</Text>
				</StyledMeetingName>

				<StyledMeetingTemplate>
					<Text singleLine>{metadata.getIn([id, "template"])}</Text>
				</StyledMeetingTemplate>

				<StyledStatus>
					<Text tid={statusTid} singleLine />
				</StyledStatus>

				<StyledSecretary>
					<Text singleLine>{metadata.getIn([id, "secretary"])}</Text>
				</StyledSecretary>

				<StyledCheckWrapper>
					{isSelected && (
						<Icon icon="faCheckCircle" type="solid" size="lg" color="green" />
					)}
				</StyledCheckWrapper>
			</StyledListItem>
		);
	};

	render = () => {
		const { meetings } = this.props;

		return (
			<ScrollView noLeftMargin noRightMargin alwaysShow>
				<StyledList header={this.renderHeader}>
					{meetings && meetings.map(this.renderMeeting)}
				</StyledList>
			</ScrollView>
		);
	};
}
