import { Component } from "react";
import { connect } from "react-redux";
import { string } from "prop-types";
import { Map } from "immutable";
import { list } from "react-immutable-proptypes";

import i18nHelper from "../../../components/helpers/i18n.helper";
import {
	SYNA_COMPANY_ROLES,
	MEETING_ROLES_ADMINISTRATIVE,
} from "../../../constants/syna-company-roles";

const CONSTANT_ROLES = {
	...SYNA_COMPANY_ROLES,
	...MEETING_ROLES_ADMINISTRATIVE,
};

class MeetingRoleDisplayContainer extends Component {
	static propTypes = {
		roles: list,
		attendeeId: string,
		secretary: string,
		chairamn: string,
	};

	getRoleText = () => {
		const {
			customFunctions,
			i18n,
			roles,
			region,
			secretary,
			chairman,
			attendeeId,
		} = this.props;
		const language = i18n.language;
		let role;

		const isChairman = chairman && chairman === attendeeId;
		const isSecretary = secretary && secretary === attendeeId;

		// Check roles that are not actually defined in 'roles' list
		if (isChairman) {
			return i18n.messages["meetings.general.label.chairman"];
		}

		if (isSecretary) {
			return i18n.messages["meetings.general.label.secretary"];
		}

		if (!roles || !roles.size) {
			return "";
		}

		// Non-translatable/constant roles
		role = CONSTANT_ROLES[roles.get(0)];

		if (role) {
			return role;
		}

		// Translate custom roles
		const roleName = customFunctions.getIn([roles.get(0), "name"]);

		if (!roleName) {
			return "";
		}

		return i18nHelper.getTranslatedValue(roleName, language, region);
	};

	render = () => {
		return this.getRoleText();
	};
}

const mapStoreToProps = (store) => {
	return {
		customFunctions: store.meetings.get("customFunctions", Map()) || Map(),
		i18n: store.i18n,
		region: store.company.company?.region,
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingRoleDisplayContainer);
