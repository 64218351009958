import React, { PureComponent } from "react";
import { string, bool } from "prop-types";
import styled, { css } from "styled-components";
import Text from "../../shared/text/text";

const StyledWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 40px;
	transition: all 0.4s ease;
	padding-left: ${(props) => props.theme.spacing[4]};
	padding-right: ${(props) => props.theme.spacing[4]};
	max-width: 200px;
	cursor: default;

	:hover {
		background-color: #f2f2f2;
	}

	${(props) =>
		props.disabled &&
		css`
			* {
				color: ${props.theme.colors.disabled};
			}

			:hover {
				background-color: unset;
			}
		`}
`;

export default class LangaugeSwitcherMenu extends PureComponent {
	static propTypes = {
		language: string,
		disabled: bool,
	};

	getLanguage = () => {
		const { language } = this.props;
		return language.split(/[_-]+/)[0];
	};

	render = () => {
		const { disabled } = this.props;

		return (
			<StyledWrapper disabled={disabled}>
				<Text bold={600} textTransform="uppercase">
					{this.getLanguage()}
				</Text>
			</StyledWrapper>
		);
	};
}
