/**
 * Store
 * @module store
 */

import { applyMiddleware, createStore as reduxCreateStore } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers/reducers";
import socketMiddleware from "./socket.middleware";

let store;

export function createStore() {
	const createStoreWithMiddleware = applyMiddleware(
		reduxThunk,
		socketMiddleware(`${location.protocol}//${location.hostname}`),
	)(reduxCreateStore);

	return createStoreWithMiddleware(
		reducers,
		window.__REDUX_DEVTOOLS_EXTENSION__ &&
			window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }),
	);
}

export function getStore() {
	if (!store) {
		store = createStore();
	}

	return store;
}
