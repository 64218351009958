import React from "react";
import { bool, func, string, oneOf } from "prop-types";
import styled, { css } from "styled-components";
import { tint } from "polished";
import v1 from "uuid";

const StyledCheckbox = styled.input`
	display: none;
`;

const StyledLabel = styled.label`
	display: inline-block;
	position: relative;
	height: 16px;
	cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};
	-webkit-touch-calloudt: none;
	margin-right: 0;
	margin-bottom: 0;
	width: 30px;
	line-height: 16px;
	font-weight: normal;
	max-width: 100%;

	:before {
		position: absolute;
		display: inline-block;
		left: 0;
		content: "";
		border-style: solid;
		border-width: 0;
		border-color: transparent;
		width: 30px;
		height: 16px;
		box-shadow: 0;
		border-radius: 8px;
		transition: position 0.3s;

		${(props) =>
			!props.solidColor &&
			css`
				background-color: ${(props) =>
					props.checked
						? props.isDisabled
							? tint(0.35, props.theme.colors.green)
							: props.theme.colors.green
						: props.isDisabled
						? tint(0.35, "#afafaf")
						: "#afafaf"};
			`}

		${(props) =>
			props.solidColor &&
			css`
				background-color: ${(props) =>
					props.theme.colors[props.solidColor]} !important;
			`}
	}

	:after {
		position: absolute;
		content: "";
		width: 14px;
		height: 14px;
		top: 1px;
		left: ${(props) => (props.checked ? "15px" : "1px")};
		background-color: ${(props) => props.theme.colors.white};
		border-radius: 16px;
		transition:
			left 0.3s,
			right 0.3s;
	}
`;

const StyledLabelNormal = styled(StyledLabel)`
	height: 23px;
	width: 45px;

	:before {
		width: 45px;
		height: 23px;
		border-radius: 11.5px;
		content: attr(data-switch-label);
		text-align: center;
	}

	:after {
		width: 21px;
		height: 21px;
		border-radius: 23px;
		left: ${(props) => (props.checked ? "23px" : "1px")};
	}
`;

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: ${(props) => (props.size === "sm" ? "30px" : "45px")};
`;

const ToggleSwitch = ({
	className,
	id,
	checked,
	onChange,
	size,
	disabled,
	fieldName,
	solidColor,
}) => {
	id = id || v1();

	const _onChange = (e) => {
		if (fieldName) {
			onChange && onChange(fieldName, e.target.checked);
			return;
		}

		onChange && onChange(e.target.checked);
	};

	if (size === "sm") {
		return (
			<StyledWrapper className={className} size={size}>
				<StyledCheckbox
					id={id}
					type="checkbox"
					checked={checked}
					onChange={_onChange}
					disabled={disabled === true}
				/>
				<StyledLabel
					checked={checked}
					htmlFor={id}
					isDisabled={disabled}
					solidColor={solidColor}
				/>
			</StyledWrapper>
		);
	}

	return (
		<StyledWrapper className={className} size={size}>
			<StyledCheckbox
				id={id}
				type="checkbox"
				checked={checked}
				onChange={_onChange}
				disabled={disabled === true}
			/>
			<StyledLabelNormal
				checked={checked}
				htmlFor={id}
				isDisabled={disabled}
				solidColor={solidColor}
			/>
		</StyledWrapper>
	);
};

ToggleSwitch.defaultProps = {
	size: "normal",
	disabled: false,
};

ToggleSwitch.propTypes = {
	disabled: bool,
	checked: bool,
	id: string,
	onChange: func,
	fieldName: string,
	solidColor: string,
	size: oneOf(["sm", "normal"]),
};

export default ToggleSwitch;
