import React, { useState } from "react";
import { Padding } from "styled-components-spacing";
import { userIsAccountHolder } from "../../../helpers/users";
import ConfirmContainer from "../../../../containers/shared/confirm.container";
import Panel from "../../../../dumb-components/panel";
import Text from "../../../../dumb-components/shared/text/text";
import Button from "../../../../dumb-components/shared/button/button";

function TerminateSubscriptionField({
	user,
	companies,
	cancelAccount,
	history,
}) {
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const isAccountHolder = userIsAccountHolder(user.get("id"), companies);

	const _cancelAccount = () => {
		setIsConfirmOpen(true);
	};

	const closeConfirm = () => {
		setIsConfirmOpen(false);
	};

	const doCancelAccount = () => {
		cancelAccount(user.get("id"), () => {
			history.push("/signin");
		});
		closeConfirm();
	};

	return (
		<Panel tid="users.delete_account">
			<Padding all={4}>
				{!isAccountHolder && (
					<Text tid="users.delete_account.information" tag="p" />
				)}
				{isAccountHolder && (
					<Text
						tid="users.delete_account.is_account_holder_information"
						tag="p"
					/>
				)}
				<Button
					tid="users.delete_account.button"
					onClick={_cancelAccount}
					disabled={isAccountHolder}
				/>
				<ConfirmContainer
					title="users.delete_account"
					question="users.are_you_sure_you_want_to_delete_your_account"
					message="users.delete_account.information"
					onConfirm={doCancelAccount}
					onDecline={closeConfirm}
					isOpen={isConfirmOpen}
					oneWrapper
				/>
			</Padding>
		</Panel>
	);
}

export default TerminateSubscriptionField;
