import { SET_LOCALE } from "./types";
import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import sv from "react-intl/locale-data/sv";
import translations from "../i18n/translations.json";
import { Settings } from "luxon";
import { fromJS } from "immutable";
import i18n from "../i18n";
import i18nHelper from "../components/helpers/i18n.helper";
import Moment from "../modules/moment.module";
import {
	HELPSCOUT_BEACON_SWEDISH,
	HELPSCOUT_BEACON_ENGLISH,
} from "/shared/constants";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const setLanguage = (language) => {
	addLocaleData([...en, ...sv]);

	let locale = language || i18nHelper.getBrowserLanguage();
	locale = (locale ? locale : "sv").toLowerCase();
	locale = i18nHelper.normalizeLanguage(locale);

	const data = {
		en: {},
		sv: {},
	};

	const msg = fromJS(translations);
	msg.forEach((langs, id) => {
		langs.forEach((m, lang) => {
			data[lang][id] = m;
		});
	});

	const languageWithoutRegionCode = locale.toLowerCase().split(/[_-]+/)[0];

	// Needed for react-i18next
	i18n.changeLanguage(locale);

	// Change Moment locale
	Moment.locale(locale);
	Settings.defaultLocale = locale;

	cookies.set("userLanguage", locale, {
		path: "/",
		expires: Moment().add(1, "year").toDate(),
	});

	// Change Helpscout Beacon
	if (typeof Beacon !== "undefined") {
		if (typeof Beacon("info") !== "undefined") {
			Beacon("destroy");
		}
		Beacon(
			"init",
			languageWithoutRegionCode === "sv"
				? HELPSCOUT_BEACON_SWEDISH
				: HELPSCOUT_BEACON_ENGLISH,
		);
	}

	return {
		type: SET_LOCALE,
		payload: {
			messages: data[languageWithoutRegionCode] || data[language] || data.en,
			language: locale,
		},
	};
};
