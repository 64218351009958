import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import AgGrid from "../../../dumb-components/shared/ag-grid/ag-grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { GRID_MODAL } from "../../../constants/modals";
import { GRID_MODE_PANEL, GRID_MODE_MODAL } from "/shared/constants";
import ExportExcelButton from "../../../dumb-components/investment/shareholders/export-excel-button";
import { InvestmentsContext } from "../investment.container";
import { useTranslation } from "react-i18next";
import {
	shareholderColumnDefs,
	columnTypes,
} from "../../../dumb-components/shared/ag-grid/column-defs/column-defs";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../actions/modals.actions";
import GridPanelRightButtons from "../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons";
import Text from "../../../dumb-components/shared/text/text";

const ShareholderGridContainer = () => {
	const { selectedInvestment } = useContext(InvestmentsContext);
	const investments = selectedInvestment?.get("investments");

	if (!investments) {
		return null;
	}

	const gridRef = useRef();
	const { t } = useTranslation();
	const colDefs = shareholderColumnDefs(t);
	const [rowData, setRowData] = useState();
	const colTypes = columnTypes();

	const gridOption = "shareholder";
	const companyName = useSelector(
		(state) =>
			state.capTableDetails.get("aliasOfTheInvestedCompany") ||
			state.capTableDetails.getIn([
				"combinedSingleInvestmentAndAllTransactions",
				"investedInCompanyInformation",
				"name",
			]),
	);

	const dispatch = useDispatch();

	const isGridModalOpen = useSelector((state) => {
		return state.modals.getIn(["activeModal", "options"]) === gridOption;
	});

	useEffect(() => {
		const rowData = investments.toJS();
		let numOfTotalShares = 0;
		let value = 0;
		for (const shareholder of rowData) {
			numOfTotalShares +=
				(shareholder.balance && shareholder.balance.numOfTotalShares) || 0;
			value += (shareholder.balance && shareholder.balance.value) || 0;
		}
		rowData.push({
			name: t("cap-table.shareholders.shareholder-grid.summary"),
			id: "",
			isSummary: true,
			balance: {
				numOfTotalShares,
				ownershipPercentage: 100,
				value,
				votingRightPercentage: 100,
			},
		});
		setRowData(rowData);
	}, [investments]);

	const exportAsExcel = useCallback(() => {
		gridRef.current.api.exportDataAsExcel();
	});

	const openGridModal = useCallback(() => {
		dispatch(openModal(GRID_MODAL, gridOption));
	});

	const mode = () => {
		return isGridModalOpen ? GRID_MODE_MODAL : GRID_MODE_PANEL;
	};

	const modalLeftHeader = {
		tid: "investments.shareholders.grid.modal.title",
		values: { company: companyName },
	};

	const rowClassRules = {
		bold: (params) => {
			return params.data && params.data.isSummary;
		},
	};
	return (
		<Card>
			<CardHeader
				title={
					<Text
						tid={"investments.shareholders.grid_panel.title"}
						values={{ companyName: companyName }}
					/>
				}
				action={
					<GridPanelRightButtons
						exportAsExcel={exportAsExcel}
						openGridModal={openGridModal}
						downloadExcelTid={
							"investments.shareholders.grid.dropdown_item.download_excel_list"
						}
						noFilter={true}
					/>
				}
			/>
			<CardContent variant="panel">
				<AgGrid
					mode={mode()}
					isGridModalOpen={isGridModalOpen}
					rowData={rowData}
					columnDefs={colDefs}
					rowClassRules={rowClassRules}
					rightHeaderComponent={() => {
						return (
							<ExportExcelButton
								exportAsExcel={exportAsExcel}
								noFilter={true}
							/>
						);
					}}
					modalLeftHeader={modalLeftHeader}
					gridRef={gridRef}
					columnTypes={colTypes}
				/>
			</CardContent>
		</Card>
	);
};

export default ShareholderGridContainer;
