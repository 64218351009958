import React, { Component } from "react";
import { connect } from "react-redux";
import { string, bool } from "prop-types";

import { closeModal } from "../../../../actions/modals.actions";
import { createProxy } from "../../../../actions/meetings.actions";

import { MEETINGS_ADD_PROXY_MODAL } from "../../../../constants/modals";
import { TeamsAndInvestorsListContainer } from "../../../shared/people-modal/teams-and-investors-list/teams-and-investors-list.container";
import { CompanyContactsGrid } from "../../../shared/people-modal/company-contacts/CompanyContactsGrid";
import { PersonalContactsGrid } from "../../../shared/people-modal/personal-contacts/PersonalConatctsGrid";
import { PeopleModalProvider } from "../../../shared/people-modal/people-modal.provider";
import {
	getDefaultTabs,
	PeopleModalHeader,
} from "../../../shared/people-modal/people-modal-header";
import { PeopleModalFooter } from "../../../shared/people-modal/people-modal-footer";
import { PeopleModalContainer } from "../../../shared/people-modal/people-modal.container";
import {
	INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE,
	INVESTOR_TYPE_OF_OWNER_COMPANY,
} from "/shared/constants";

class SelectProxyModalContainer extends Component {
	static propTypes = {
		renderOnlyBody: bool,
		attendeeId: string,
	};

	onSave = (selectedUsers) => {
		const {
			createProxy,
			meetingId,
			proxyInviterId,
			renderOnlyBody,
			closeModal,
		} = this.props;

		createProxy(meetingId, proxyInviterId, selectedUsers);

		if (!renderOnlyBody) {
			closeModal();
		}
	};

	disableMemberAndShareholderFunction = (member) => {
		const { proxyInviterId } = this.props;
		return (
			proxyInviterId === member.get("id") ||
			proxyInviterId === member.get("investorId") ||
			member.get("investorTypeOfOwner") === INVESTOR_TYPE_OF_OWNER_COMPANY ||
			member.get("investorTypeOfOwner") ===
				INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE
		);
	};

	render = () => {
		const { isOpen, closeModal, renderOnlyBody } = this.props;

		if (!isOpen) {
			return null;
		}

		if (renderOnlyBody) {
			return (
				<>
					<TeamsAndInvestorsListContainer
						disableInvestorFunction={this.disableMemberAndShareholderFunction}
						disableMemberFunction={this.disableMemberAndShareholderFunction}
					/>
					<CompanyContactsGrid />
					<PersonalContactsGrid />
				</>
			);
		}

		return (
			<PeopleModalProvider isOpen={isOpen} singleMode={true}>
				<PeopleModalContainer
					headerComponent={() => (
						<PeopleModalHeader
							title="meetings.agm.add_proxy.modal.title"
							tabs={getDefaultTabs()}
						/>
					)}
					footerComponent={() => (
						<PeopleModalFooter onSave={this.onSave} onCancel={closeModal} />
					)}
				>
					<TeamsAndInvestorsListContainer
						disableInvestorFunction={this.disableMemberAndShareholderFunction}
						disableMemberFunction={this.disableMemberAndShareholderFunction}
					/>
					<CompanyContactsGrid disableFunction={this.disableExternalFunction} />
					<PersonalContactsGrid />
				</PeopleModalContainer>
			</PeopleModalProvider>
		);
	};
}

const mapStoreToProps = (store, ownProps) => {
	return {
		isOpen:
			store.modals.getIn(["activeModal", "name"]) ===
				MEETINGS_ADD_PROXY_MODAL || ownProps.renderOnlyBody,
		meetingId: store.meetings.getIn(["meeting", "id"]),
		proxyInviterId:
			store.modals.getIn(["activeModal", "options", "attendeeId"]) ||
			ownProps.attendeeId,
	};
};

const mapActionsToProps = {
	closeModal,
	createProxy,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(SelectProxyModalContainer);
