import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { object } from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import uuid from "uuid/v4";
import IconButton from "../../mui-components/button/icon-button";
import { DropDown } from "../../mui-components/dropdown/DropDown";
import MenuItemIcon from "../../mui-components/dropdown/menu/menu-item-icon";
import { getQuery } from "../../interfaces/history";

const DocumentsSearch = ({ gridRef }) => {
	const query = getQuery();
	const isWithinSharedFolder = query.isSharedFromCompanyId ? true : false;
	const id = uuid();
	const inputRef = useRef(null);
	const [searchType, setSearchType] = useState("document"); // ['document', 'folder', 'tag'
	const [options, setOptions] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const allDocuments = useSelector((state) =>
		state.documents.get("allDocuments"),
	);
	const sharedDocuments = useSelector((state) =>
		state.documents.get("sharedDocuments"),
	);
	const documents = isWithinSharedFolder ? sharedDocuments : allDocuments;
	const { t } = useTranslation();

	let label = t("documents.search.label.document");
	if (searchType === "folder") {
		label = t("documents.search.label.folder");
	} else if (searchType === "tag") {
		label = t("documents.search.label.tag");
	}

	const applyFilter = (value) => {
		if (gridRef.current) {
			let instance;

			switch (searchType) {
				case "folder":
					instance = gridRef.current.api.getFilterInstance(
						"metadata.folderName.sv",
					);
					break;
				case "tag":
					instance = gridRef.current.api.getFilterInstance("tags");
					break;
				default:
					instance = gridRef.current.api.getFilterInstance("title");
			}

			instance.setModel({
				values: Array.isArray(value) && value.length > 0 ? value : null,
			});
			gridRef.current.api.onFilterChanged();
		}
	};

	const changeSearchType = (type) => {
		setSearchType(type);
		inputRef.current.focus();
	};

	useEffect(() => {
		if (documents && documents.size > 0) {
			let opts = [];
			switch (searchType) {
				case "folder":
					{
						const folderNames = documents
							.map((document) =>
								document.getIn(["metadata", "folderName", "sv"]),
							)
							.filter((folderName) => typeof folderName !== "undefined")
							.toJS()
							.flat();
						opts = [...new Set(folderNames)];
					}
					break;
				case "tag":
					{
						const tags = documents
							.filter((document) => document.has("tags"))
							.map((document) => document.get("tags"))
							.toJS()
							.flat();
						opts = [...new Set(tags)];
					}
					break;
				default: {
					const titles = documents
						.map((document) => document.get("title"))
						.toJS();
					opts = [...new Set(titles)];
				}
			}
			setOptions(opts);
		}
	}, [documents, searchType]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				flex: 1,
				alignItems: "start",
				"& > button": { paddingInline: "0 4px", width: "auto" },
			}}
		>
			<DropDown
				button={({ params }) => (
					<IconButton
						{...params}
						icon="faCaretDown"
						type="solid"
						size="sm"
						noBorder
					/>
				)}
			>
				{({ popupState }) => [
					<MenuItemIcon
						key={1}
						listItemTid="documents.search.label.document"
						onClick={() => {
							changeSearchType("document");
							popupState.close();
						}}
					/>,
					<MenuItemIcon
						key={2}
						listItemTid="documents.search.label.tag"
						onClick={() => {
							changeSearchType("tag");
							popupState.close();
						}}
					/>,
					<MenuItemIcon
						key={3}
						listItemTid="documents.search.label.folder"
						onClick={() => {
							changeSearchType("folder");
							popupState.close();
						}}
					/>,
				]}
			</DropDown>
			<Box sx={{ flex: 1 }}>
				<Autocomplete
					sx={{
						flex: 1,
						"& .MuiOutlinedInput-root": {
							paddingBlock: "2px",
							"& .MuiAutocomplete-input": {
								paddingBlock: "6px",
							},
						},
						"& .MuiInputLabel-root": {
							transform: "translate(14px, 9px) scale(1)",
							"&.Mui-focused": {
								transform: "translate(14px, -9px) scale(0.75)",
							},
						},
						"&.MuiAutocomplete-hasClearIcon .MuiInputLabel-root": {
							transform: "translate(14px, -9px) scale(0.75)",
						},
					}}
					id={id}
					options={options}
					noOptionsText={""}
					autoComplete
					includeInputInList
					filterSelectedOptions
					multiple
					open={isOpen}
					onChange={(_, newValue) => {
						applyFilter(newValue);
						setIsOpen(false);
					}}
					onInputChange={(_, value) => {
						if (value.length < 3) {
							isOpen && setIsOpen(false);
						} else if (!isOpen) {
							setIsOpen(true);
						}
					}}
					renderInput={(params) => {
						return (
							<TextField
								{...params}
								label={label}
								fullWidth
								inputRef={inputRef}
								InputProps={{
									...params.InputProps,
									endAdornment: "",
								}}
							/>
						);
					}}
					renderOption={(props, option) => {
						return (
							<li {...props} key={option}>
								{option}
							</li>
						);
					}}
				/>
			</Box>
		</Box>
	);
};

DocumentsSearch.propTypes = {
	gridRef: object.isRequired,
};

export default DocumentsSearch;
