import React, { PureComponent } from "react";
import styled from "styled-components";
import { string, oneOf } from "prop-types";
import Text from "../shared/text/text";
import Alert from "../shared/alert/alert";

const StyledAlert = styled(Alert)`
	margin-top: ${(props) => props.theme.spacing[5]} !important;
`;

export default class ErrorMessage extends PureComponent {
	static propTypes = {
		tid: string,
		mode: oneOf(["error", "info"]),
	};

	static defaultProps = {
		mode: "error",
	};

	render = () => {
		const { tid, mode } = this.props;

		if (!tid) {
			return null;
		}

		return (
			<StyledAlert mode={mode}>
				<Text tid={tid} color="white" />
			</StyledAlert>
		);
	};
}
