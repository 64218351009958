import React, { Component } from "react";
import { connect } from "react-redux";
import { fromJS, Map } from "immutable";
import debounce from "lodash/debounce";

import CollectionsModal from "../../dumb-components/collections/collections-modal/collections-modal";
import FooterRightControls from "../../dumb-components/shared/modal/footer-right-controls";
import { TransparentButton } from "../../dumb-components/shared/button-v2";
import Tooltip from "../../dumb-components/shared/tooltip/tooltip";

import DropdownMenuContainer from "../shared/dropdown-menu.container";
import CreatePdfItem from "./dropdown-items/create-pdf.container";
import ShareCollectionItem from "./dropdown-items/share-collection.container";
import RemoveCollectionItem from "./dropdown-items/remove-collection.container";
import RemoveDocumentItem from "./dropdown-items/remove-document.container";
import AddExistingDocumentToCollectionWarningModal from "../../dumb-components/collections/add-existing-document-warning-modal";

import { closeModal } from "../../actions/modals.actions";
import {
	selectCollection,
	createCollection,
	editCollection,
	updateCollectionLocal,
	fetchCollectionDocuments,
	reorderDocument,
	moveDocument,
} from "../../actions/collections.actions";
import OpenOrDownloadFileComponentContainer from "../documents/generic/open-or-download-file-component.container";
import DropdownIconItem from "../../dumb-components/shared/dropdown-item/dropdown-icon-item";

class CollectionsModalContainer extends Component {
	state = {
		collectionDocumentsLoading: true,
		moveDuplicateDocumentData: null,
		isDragging: false,
	};

	componentDidMount = () => {
		const { selectedCollectionId, collections } = this.props;

		this.handleCollectionSelected(collections.first());

		setTimeout(() => {
			const itemRefToScrollTo = this.itemRefs[selectedCollectionId];

			if (this.scrollbarRef && itemRefToScrollTo) {
				itemRefToScrollTo.scrollIntoViewIfNeeded();
			}
		}, 1);
	};

	componentDidUpdate = (prevProps) => {
		const { selectedCollectionId, fetchCollectionDocuments } = this.props;

		if (
			selectedCollectionId &&
			prevProps.selectedCollectionId !== selectedCollectionId
		) {
			this.setState({ collectionDocumentsLoading: true });

			fetchCollectionDocuments(selectedCollectionId, () => {
				this.setState({ collectionDocumentsLoading: false });
			});
		}
	};

	componentWillUnmount = () => {
		this.props.selectCollection();
	};

	collectionDropdownRef = {};
	documentDropdownRef = {};
	footerDropdownRef = null;
	scrollbarRef = null;
	itemRefs = {};

	doDebounce = debounce((collection) => {
		this.props.editCollection(collection);
	}, 1000);

	updateCollectionLocal = (collection) => {
		this.props.updateCollectionLocal(collection);
		this.doDebounce(collection);
	};

	handleGetTranslatedMessage = (tid) => {
		return this.props.messages[tid];
	};

	handleSetScrollbarRef = (ref) => {
		this.scrollbarRef = ref;
	};

	handleSetItemRef = (ref, collectionId) => {
		this.itemRefs[collectionId] = ref;
	};

	setFooterDropdownRef = (ref) => {
		this.footerDropdownRef = ref;
	};

	setDocumentsDropdownRef = (documentId, ref) => {
		this.documentDropdownRef[documentId] = ref;
	};

	setCollectionDropdownRef = (collectionId, ref) => {
		this.collectionDropdownRef[collectionId] = ref;
	};

	handleChange = (fieldName, value) => {
		let { selectedCollection } = this.props;
		selectedCollection = selectedCollection.set(fieldName, value);
		this.updateCollectionLocal(selectedCollection);
	};

	handleAddCollection = () => {
		this.scrollbarRef && this.scrollbarRef.scrollTop();
		this.props.createCollection();
		setTimeout(() => this.collectionNameInputRef?.focus(), 200);
	};

	handleCollectionSelected = (collection) => {
		const { selectCollection } = this.props;
		selectCollection(collection);
	};

	handleModalClose = () => {
		this.props.closeModal();
	};

	toggleCollectionDropdown = (collection) => {
		const collectionId = collection.get("id");
		this.collectionDropdownRef[collectionId] &&
			this.collectionDropdownRef[collectionId].onToggleMenu();
	};

	toggleDocumentDropdown = (document) => {
		const documentId = document.get("id");
		this.documentDropdownRef[documentId] &&
			this.documentDropdownRef[documentId].onToggleMenu();
	};

	toggleFooterDropdown = () => {
		this.footerDropdownRef && this.footerDropdownRef.onToggleMenu();
	};

	onSortDocuments = (items) => {
		const { reorderDocument, selectedCollection } = this.props;
		items = fromJS(items);
		const documentIds = items.map((i) => i.get("id"));
		reorderDocument(selectedCollection.get("id"), documentIds);
		this.setState({ isDragging: false });
	};

	onMoveDocument = ({ id: documentId }, toCollectionId) => {
		const { selectedCollection, collections } = this.props;

		if (selectedCollection.get("id") === toCollectionId) {
			return;
		}

		const destinationCollection = collections.find(
			(c) => c.get("id") === toCollectionId,
		);
		const draggedSharedVersion = selectedCollection
			.get("documents")
			.find((d) => d.get("id") === documentId)
			.get("sharedVersion");
		const destinationDuplicateDocument = destinationCollection
			.get("documents")
			.find((d) => d.get("id") === documentId);
		const destinationSharedVersion =
			destinationDuplicateDocument?.get("sharedVersion");

		if (
			destinationDuplicateDocument &&
			draggedSharedVersion !== destinationSharedVersion
		) {
			this.setState({
				moveDuplicateDocumentData: Map({
					documentId,
					toCollectionId,
					existingSharedVersion:
						destinationDuplicateDocument.get("sharedVersion"),
				}),
			});
		} else {
			this.doMoveDocument(documentId, toCollectionId);
		}
	};

	doMoveDocument = (documentId, toCollectionId) => {
		const { moveDocument, selectedCollectionId } = this.props;
		const { moveDuplicateDocumentData } = this.state;

		if (moveDuplicateDocumentData) {
			const documentId = moveDuplicateDocumentData.get("documentId");
			const toCollectionId = moveDuplicateDocumentData.get("toCollectionId");

			this.setState({ moveDuplicateDocumentData: null });

			moveDocument(documentId, toCollectionId, selectedCollectionId);
		} else {
			moveDocument(documentId, toCollectionId, selectedCollectionId);
		}

		this.setState({ isDragging: false });
	};

	onInitDragging = () => {
		this.setState({ isDragging: true });
	};

	onStopDragging = () => {
		this.setState({ isDragging: false });
	};

	setCollectionNameInputRef = (ref) => {
		this.collectionNameInputRef = ref;
	};

	unfocusCollectionNameInputRef = () => {
		this.collectionNameInputRef?.blur();
	};

	renderAddDuplicateDocumentToCollectionModal = () => {
		const { moveDuplicateDocumentData } = this.state;
		const isOpen = Boolean(moveDuplicateDocumentData);

		if (!isOpen) {
			return null;
		}

		const existingSharedVersion = moveDuplicateDocumentData.get(
			"existingSharedVersion",
		);

		return (
			<AddExistingDocumentToCollectionWarningModal
				existingSharedVersion={existingSharedVersion}
				onReplace={() => {
					this.doMoveDocument();
				}}
				onSkip={() => {
					this.setState({ moveDuplicateDocumentData: null });
				}}
			/>
		);
	};

	renderCollectionDropdown = (collection) => {
		const isRoot = collection.get("isRoot");

		if (isRoot) {
			return null;
		}

		return (
			<DropdownMenuContainer
				btnIcon="faEllipsisV"
				halignMenu="right"
				btnMode="transparent-icon"
				transparentIconButtonSize="sml"
				ref={this.setCollectionDropdownRef.bind(null, collection.get("id"))}
				noMaxWidth={true}
				withPortal={true}
				buttonNoHorizontalPadding={true}
			>
				<CreatePdfItem
					collection={collection}
					onClick={this.toggleCollectionDropdown}
				/>
				<ShareCollectionItem
					collection={collection}
					onClick={this.toggleCollectionDropdown}
				/>
				<RemoveCollectionItem
					collection={collection}
					onClick={this.toggleCollectionDropdown}
				/>
			</DropdownMenuContainer>
		);
	};

	renderDocumentDropdown = (document) => {
		return (
			<DropdownMenuContainer
				btnIcon="faEllipsisV"
				halignMenu="right"
				btnMode="transparent-icon"
				transparentIconButtonSize="sml"
				ref={this.setDocumentsDropdownRef.bind(null, document.get("id"))}
				noMaxWidth={true}
				withPortal={true}
			>
				<OpenOrDownloadFileComponentContainer document={document}>
					{({ TOOLTIP_STATES, activeState, isDisabled, openOrDownload }) => (
						<Tooltip
							states={TOOLTIP_STATES}
							activeState={activeState}
							active={isDisabled}
						>
							<DropdownIconItem
								icon="faExpandArrowsAlt"
								tid="open_file"
								disabled={isDisabled}
								onClick={() => {
									this.toggleDocumentDropdown(document);
									openOrDownload();
								}}
							/>
						</Tooltip>
					)}
				</OpenOrDownloadFileComponentContainer>
				<RemoveDocumentItem
					document={document}
					onClick={this.toggleDocumentDropdown}
				/>
			</DropdownMenuContainer>
		);
	};

	renderFooter = () => {
		const { selectedCollection } = this.props;
		const optionsDisabled = !selectedCollection;
		const isRoot = selectedCollection && selectedCollection.get("isRoot");

		return (
			<FooterRightControls>
				{!isRoot && (
					<DropdownMenuContainer
						halignMenu="right"
						openDirection="up"
						disabled={optionsDisabled}
						noMaxWidth={true}
						ref={this.setFooterDropdownRef}
						renderRaw={
							<Tooltip
								tid="collections.modal.manage.footer.options.disabled.not_selected"
								delayShow="instant"
								active={optionsDisabled}
							>
								<TransparentButton
									color="dodgerBlue"
									tid="collections.modal.manage.footer.options"
									disabled={optionsDisabled}
								/>
							</Tooltip>
						}
					>
						{selectedCollection && (
							<>
								<CreatePdfItem
									collection={selectedCollection}
									onClick={this.toggleCollectionDropdown}
								/>
								<ShareCollectionItem
									collection={selectedCollection}
									onClick={this.toggleCollectionDropdown}
								/>
								<RemoveCollectionItem
									collection={selectedCollection}
									onClick={this.toggleCollectionDropdown}
								/>
							</>
						)}
					</DropdownMenuContainer>
				)}

				<TransparentButton
					tid="generic.form.close"
					textColor="midGrey"
					onClick={this.handleModalClose}
				/>
			</FooterRightControls>
		);
	};

	render = () => {
		const { collectionDocumentsLoading, isDragging } = this.state;
		const { collections, selectedCollection, collectionDocuments } = this.props;

		return (
			<>
				<CollectionsModal
					collections={collections}
					documents={collectionDocuments}
					selectedCollection={selectedCollection}
					isLoadingFiles={collectionDocumentsLoading}
					onAddCollection={this.handleAddCollection}
					renderCollectionDropdown={this.renderCollectionDropdown}
					renderDocumentDropdown={this.renderDocumentDropdown}
					footerComponent={this.renderFooter()}
					setScrollbarRef={this.handleSetScrollbarRef}
					setItemRef={this.handleSetItemRef}
					onSelect={this.handleCollectionSelected}
					onChange={this.handleChange}
					getTranslatedMessage={this.handleGetTranslatedMessage}
					onSortDocuments={this.onSortDocuments}
					onMoveDocument={this.onMoveDocument}
					onInitDragging={this.onInitDragging}
					onStopDragging={this.onStopDragging}
					isDragging={isDragging}
					collectionNameInputRef={this.setCollectionNameInputRef}
					onEnter={this.unfocusCollectionNameInputRef}
				/>

				{this.renderAddDuplicateDocumentToCollectionModal()}
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		collections: store.collections.get("collections"),
		selectedCollection: store.collections.get("selectedCollection"),
		selectedCollectionId: store.collections.getIn(["selectedCollection", "id"]),
		collectionDocuments: store.collections.get("selectedCollectionDocuments"),
		messages: store.i18n.messages,
	};
};

const mapActionsToProps = {
	closeModal,
	selectCollection,
	createCollection,
	editCollection,
	updateCollectionLocal,
	fetchCollectionDocuments,
	reorderDocument,
	moveDocument,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(CollectionsModalContainer);
