import { fromJS } from "immutable";
import {
	ATTENDEE_STATUS_PRESENT,
	ATTENDEE_STATUS_ABSENT,
	ATTENDEE_STATUS_UNSURE,
	MEETING_REDIRECT_PROTOCOL_FEEDBACK,
	MEETING_REDIRECT_PROTOCOL_SIGN,
	MEETING_REDIRECT_PROTOCOL_OPEN,
	MEETING_TYPE_STANDARD,
	MEETING_TYPE_SMART,
	MEETING_SUBTYPE_DEFAULT,
	MEETING_SUBTYPE_AGM,
	ATTENDEE_ATTENDANCE_PHYSICAL,
	ATTENDEE_ATTENDANCE_VIDEO,
	ATTENDEE_ATTENDANCE_PHONE,
	MEETING_VOTING_DRAFT,
	MEETING_VOTING_STARTED,
	MEETING_VOTING_COMPLETED,
	PROXY_DOCUMENT_STATUS_ACCEPTED,
	PROXY_DOCUMENT_STATUS_REVIEW,
	PROXY_DOCUMENT_STATUS_DECLINED,
} from "/shared/constants";

export const MEETING_GROUPS_ID = "29a8836e-c8c8-4aef-a909-b70f589b2453";

export const MEETINGS_ITEM_TYPES = fromJS([
	{ value: "information", label: "meetings.item_types.information" },
	{ value: "discusson", label: "meetings.item_types.discussion" },
	{ value: "decision", label: "meetings.item_types.decision" },
]);

export const MEETING_INTERNAL_TYPES = fromJS({
	open: "meetings.constants.internal_types.open",
	previousMeeting: "meetings.constants.internal_types.previous",
	nextMeeting: "meetings.constants.internal_types.next",
	close: "meetings.constants.internal_types.close",
});

export const MEETINGS_OUTCOMES = fromJS([
	{
		value: "todo",
		label: "meetings.outcomes.todo",
		statusColor: "solitudeDark",
	},
	{ value: "passed", label: "meetings.outcomes.passed", statusColor: "green" },
	{
		value: "tabled",
		label: "meetings.outcomes.tabled",
		statusColor: "yellowMid",
	},
	{
		value: "not_accepted",
		label: "meetings.outcomes.not_accepted",
		statusColor: "red",
	},
	{
		value: "recalled",
		label: "meetings.outcomes.recalled",
		statusColor: "catalinaBlue",
	},
	{
		value: "corrected",
		label: "meetings.outcomes.corrected",
		statusColor: "lightGrey",
	},
]);

export const MEETINGS_CLOSED_ITEM_STATUS = fromJS([
	{ value: "closed", label: "meetings.outcomes.closed", statusColor: "green" },
]);

export const MEETINGS_DURATION_OPTIONS = fromJS([
	{ value: 5, label: 5 },
	{ value: 10, label: 10 },
	{ value: 15, label: 15 },
	{ value: 20, label: 20 },
	{ value: 25, label: 25 },
	{ value: 30, label: 30 },
	{ value: 45, label: 45 },
	{ value: 60, label: 60 },
	{ value: 90, label: 90 },
	{ value: 120, label: 120 },
]);

export const ATTENDEES_STATUS_OPTIONS = fromJS([
	{
		value: ATTENDEE_STATUS_PRESENT,
		label: "meetings.attendees.status_option.present",
	},
	{
		value: ATTENDEE_STATUS_ABSENT,
		label: "meetings.attendees.status_option.absent",
	},
]);

export const ATTENDEES_ATTENDANCE_TYPE = fromJS([
	{
		value: ATTENDEE_ATTENDANCE_PHYSICAL,
		label: "meetings.standard.agm.attendee_status.physical",
	},
	{
		value: ATTENDEE_ATTENDANCE_VIDEO,
		label: "meetings.standard.agm.attendee_status.video",
	},
	{
		value: ATTENDEE_ATTENDANCE_PHONE,
		label: "meetings.standard.agm.attendee_status.phone",
	},
]);

export const FILTER_FIELDS = fromJS([
	[
		{
			size: 1 / 2,
			fieldName: "dateProp",
			labelTid: "meetings.filter.label.dateprop",
			options: [
				{ value: "createdAt", label: "meetings.dateprop.createdat" },
				{ value: "startDate", label: "meetings.dateprop.start_date" },
			],
			isClearable: false,
			pinable: true,
			renderer: "select",
		},
		{
			size: 1 / 2,
			fieldName: "date",
			minDateLabelTid: "object_filter.label.from_date",
			maxDateLabelTid: "object_filter.label.to_date",
			yearLabelTid: "object_filter.label.by_year",
			showYearSelect: false,
			isClearable: true,
			renderer: "dateRange",
		},
	],
	[
		{
			size: 1 / 2,
			fieldName: "meetingStatus",
			labelTid: "generic.status",
			options: [
				{ value: "show_all", label: "meetings.filter.status.show_all" },
				{ value: "meetings", label: "meetings.filter.status.meetings" },
				{ value: "protocols", label: "meetings.filter.status.protocols" },
			],
			pinable: true,
			renderer: "select",
		},
		{
			size: 1 / 2,
			fieldName: "order",
			labelTid: "object_filter.label.order",
			options: [
				{ value: "datePropAsc", label: "tasks.filter.order.date_asc" },
				{ value: "datePropDesc", label: "tasks.filter.order.date_desc" },
			],
			isClearable: false,
			pinable: true,
			renderer: "select",
		},
	],
	[
		{
			size: 1 / 2,
			fieldName: "attendeeNotified",
			labelTid: "meetings.filter.label.attendees_notified",
			options: [
				{ value: "yes", label: "meeting.filter.attendees_notified.yes" },
				{ value: "no", label: "meeting.filter.attendees_notified.no" },
			],
			labelIsTid: true,
			isClearable: true,
			placeholder: "select_placeholder",
			renderer: "select",
		},
		{
			size: 1 / 2,
			fieldName: "templateId",
			labelTid: "meetings.filter.label.template",
			isClearable: true,
			placeholder: "select_placeholder",
			renderer: "select",
		},
	],
	[
		{
			size: 1,
			fieldName: "meetingType",
			labelTid: "meetings.filter.label.meeting_type",
			options: [
				{
					value: MEETING_TYPE_SMART,
					label: "meetings.filter.meeting_type.options.smart",
				},
				{
					value: MEETING_TYPE_STANDARD,
					label: "meetings.filter.meeting_type.options.standard",
				},
			],
			labelIsTid: true,
			isClearable: true,
			placeholder: "meetings.filter.meeting_type.placeholder",
			renderer: "select",
		},
	],
	[
		{
			size: 1,
			fieldName: "createdBy",
			labelTid: "meetings.filter.label.createdBy",
			isClearable: true,
			placeholder: "select_placeholder",
		},
	],
	[
		{
			size: 1,
			fieldName: "attendees",
			labelTid: "meetings.filter.label.attendees",
			pinable: true,
			placeholder: "select_placeholder",
			renderer: "select",
			labelIsTid: false,
		},
	],
	[
		{
			size: 1,
			fieldName: "includeArchived",
			tid: "meetings.filter.label.include_archived",
			renderer: "checkbox",
		},
	],
	[
		{
			size: 1,
			fieldName: "showOnlyTrashed",
			tid: "filter.label.show_only_trashed",
			renderer: "checkbox",
		},
	],
]);

export const FACTORY_DEFAULT_FILTER_VALUES = fromJS({
	dateProp: ["startDate"],
	order: ["datePropDesc"],
	meetingStatus: ["show_all"],
	includeArchived: [false],
	attendees: ["show_all"],
	showOnlyTrashed: [false],
});

// Meeting Statuses
export const MEETINGS_STATUS_DRAFT = "MEETING-DRAFT";
export const MEETINGS_STATUS_NOTIFIED_NO_PUB = "MEETING-NOTIFIED-NOT-PUB";
export const MEETINGS_STATUS_PUBLISHED = "MEETING-PUBLISHED";
export const MEETINGS_STATUS_CHANGED = "MEETING-CHANGED";
export const MEETINGS_STATUS_STARTED = "MEETING-STARTED";
export const MEETINGS_STATUS_FINISHED = "MEETING-FINISHED";
export const MEETINGS_STATUS_PROTOCOL_NEEDS_FEEDBACK =
	"PROTOCOL-NEEDS-FEEDBACK";
export const MEETINGS_STATUS_PROTOCOL_NEEDS_SIGNING = "PROTOCOL-NEEDS-SIGNING";
export const MEETINGS_STATUS_PROTOCOL_SIGNED = "PROTOCOL-SIGNED";
export const MEETINGS_STATUS_PROTOCOL_PUBLISHED = "PROTOCOL-PUBLISHED";

// Valid modal redirect actions
export const PROTOCOL_REDIRECT_VALID_ACTIONS = [
	MEETING_REDIRECT_PROTOCOL_FEEDBACK,
	MEETING_REDIRECT_PROTOCOL_SIGN,
	MEETING_REDIRECT_PROTOCOL_OPEN,
];

export const MEETING_SUBTYPE_OPTIONS = fromJS([
	{ value: MEETING_SUBTYPE_DEFAULT, label: MEETING_SUBTYPE_DEFAULT },
	{ value: MEETING_SUBTYPE_AGM, label: MEETING_SUBTYPE_AGM },
]);

export const ATTENDEE_STATUS_TO_COLOR_MAP = {
	[ATTENDEE_STATUS_PRESENT]: "green",
	[ATTENDEE_STATUS_ABSENT]: "red",
	[ATTENDEE_STATUS_UNSURE]: "yellowMid",
	default: "lightGrey",
};

export const ATTENDEE_STATUS_TO_TOOLTIP_MAP = {
	[ATTENDEE_STATUS_PRESENT]:
		"meetings.attendees.response_status.tooltip.accepted_invite",
	[ATTENDEE_STATUS_ABSENT]:
		"meetings.attendees.response_status.tooltip.declined_invite",
	[ATTENDEE_STATUS_UNSURE]:
		"meetings.attendees.response_status.tooltip.undecided_invite",
	default: "meetings.attendees.response_status.tooltip.no_response_invite",
};

export const ATTENDEE_STATUS_TO_TEXT_MAP = {
	[ATTENDEE_STATUS_PRESENT]: "meeting.generic.attendee.response.accepted",
	[ATTENDEE_STATUS_ABSENT]: "meeting.generic.attendee.response.declined",
	[ATTENDEE_STATUS_UNSURE]: "meeting.generic.attendee.response.undecided",
	default: "meeting.generic.attendee.response.no_response",
};

export const VOTING_STATUS_TO_TOOLTIP_MAP = {
	[MEETING_VOTING_DRAFT]: "meetings.voting_status.tooltip.voting_not_started",
	[MEETING_VOTING_STARTED]: "meetings.voting_status.tooltip.ongoing_voting",
	[MEETING_VOTING_COMPLETED]: "meetings.voting_status.tooltip.voting_finished",
};

export const PROXY_DOCUMENT_STATUS_OPTIONS = fromJS([
	{
		value: PROXY_DOCUMENT_STATUS_ACCEPTED,
		label: "meetings.agm.proxy.document_status.ACCEPTED",
	},
	{
		value: PROXY_DOCUMENT_STATUS_REVIEW,
		label: "meetings.agm.proxy.document_status.REVIEW",
	},
	{
		value: PROXY_DOCUMENT_STATUS_DECLINED,
		label: "meetings.agm.proxy.document_status.DECLINED",
	},
]);
