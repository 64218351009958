/**
 * Role management
 * @module components/company/user-management--roles
 */

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
	fetchRoles,
	fetchRoleDetails,
	deleteRole,
} from "../../../actions/user-management.actions";
import UserManagementContainer from "./user-management-container";

import { ButtonTransparentIcon } from "../../../dumb-components/shared/button";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";

import DropdownMenuContainer from "../../../containers/shared/dropdown-menu.container";
import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import TeamCreateModalContainer from "../../../containers/user-management/team-create-modal.container";
import TeamEditModalContainer from "../../../containers/user-management/team-edit-modal.container";
import ConfirmContainer from "../../../containers/shared/confirm.container";

class UserManagementRoles extends Component {
	state = {
		isOpenCreateTeamModal: false,
		isOpenEditTeamModal: false,
		isOpenDeleteConfirmModal: false,
		isLoading: false,
		managedRoleId: null, // Used for delete
	};

	roleMoreActionRef = [];

	componentDidMount() {
		this.props.fetchRoles();
	}

	addRole = () => {
		this.setState({ isOpenCreateTeamModal: true });
	};

	onCancelCreateTeamModal = () => {
		this.setState({ isOpenCreateTeamModal: false });
	};

	closeMoreActionDropdown = (roleId) => {
		this.roleMoreActionRef[roleId] &&
			this.roleMoreActionRef[roleId].onToggleMenu();
	};

	onEditRole = (roleId) => {
		this.closeMoreActionDropdown(roleId);
		this.props.fetchRoleDetails(roleId);
		this.setState({ isOpenEditTeamModal: true });
	};

	onCancelEditTeamModal = () => {
		this.setState({ isOpenEditTeamModal: false });
	};

	doCancelDeleteRole = () => {
		this.setState({ isOpenDeleteConfirmModal: false, managedUserId: null });
	};

	onDeleteRole = (roleId) => {
		this.closeMoreActionDropdown(roleId);
		this.setState({ isOpenDeleteConfirmModal: true, managedRoleId: roleId });
	};

	doDeleteRole = () => {
		const { deleteRole } = this.props;
		const { managedRoleId } = this.state;

		this.setState({ isLoading: true });

		deleteRole(managedRoleId, (e) => {
			if (e) {
				this.setState({ isLoading: false });
				return;
			}

			this.setState({
				isOpenDeleteConfirmModal: false,
				managedRoleId: null,
				isLoading: false,
			});
		});
	};

	renderRoles = (role) => {
		// Don't out render Super Administrator
		if (role.id === "aed45ce5-291f-438f-a47a-f547fdecc5ee") {
			return;
		}

		return (
			<tr key={role.name}>
				<td>{role.name}</td>
				<td>{role.description}</td>
				<td className="text-right">
					<DropdownMenuContainer
						btnIcon="faEllipsisV"
						halignMenu="right"
						btnMode="transparent-icon"
						transparentIconButtonSize="sml"
						ref={(r) => (this.roleMoreActionRef[role.id] = r)}
						inline
					>
						<DropdownIconItem
							icon="faUserEdit"
							tid="generic.form.edit"
							onClick={this.onEditRole.bind(null, role.id)}
						/>

						{!role.notDeletable && (
							<DropdownIconItem
								icon="faTrashAlt"
								tid="generic.delete"
								onClick={this.onDeleteRole.bind(null, role.id)}
							/>
						)}
					</DropdownMenuContainer>
				</td>
			</tr>
		);
	};

	renderAddRoleIcon = () => {
		return (
			<Tooltip tid="tooltip.company.management.actions.add_role">
				<ButtonTransparentIcon
					icon="faUsersMedical"
					size="sml"
					onClick={this.addRole}
				/>
			</Tooltip>
		);
	};

	render = () => {
		const { location } = this.props;
		const {
			isOpenCreateTeamModal,
			isOpenEditTeamModal,
			isOpenDeleteConfirmModal,
			isLoading,
		} = this.state;
		const roles = this.props.roles || [];

		return (
			<UserManagementContainer
				location={location}
				roleSelectorComponent={this.renderAddRoleIcon}
			>
				<div className="panel">
					<div className="p-6 bg-white border border-edge border-t-0 shadow-primary">
						<div className="table-responsive" style={{ overflowX: "unset" }}>
							<table className="table table-striped table-hover">
								<thead>
									<tr>
										<th>
											<FormattedMessage id="generic.name" />
										</th>
										<th colSpan="2">
											<FormattedMessage id="generic.description" />
										</th>
									</tr>
								</thead>
								<tbody>{roles.map(this.renderRoles)}</tbody>
							</table>
						</div>
					</div>
				</div>

				<TeamCreateModalContainer
					isOpen={isOpenCreateTeamModal}
					onCancel={this.onCancelCreateTeamModal}
				/>

				<TeamEditModalContainer
					isOpen={isOpenEditTeamModal}
					onCancel={this.onCancelEditTeamModal}
				/>

				<ConfirmContainer
					isOpen={isOpenDeleteConfirmModal}
					title="generic.delete"
					question="company.management.delete_role_confirm"
					isLoading={isLoading}
					onConfirm={this.doDeleteRole}
					onDecline={this.doCancelDeleteRole}
				/>
			</UserManagementContainer>
		);
	};
}

function mapStateToProps(state) {
	return {
		roles: state.company.userManagement.roles,
		company: state.company.company,
	};
}

const mapActionsToProps = {
	fetchRoles,
	fetchRoleDetails,
	deleteRole,
};

/** Export */
export default connect(mapStateToProps, mapActionsToProps)(UserManagementRoles);
