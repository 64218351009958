import { createBrowserHistory } from "history";
import queryString from "query-string";

if (!window.invonoHistory) {
	const history = createBrowserHistory();
	window.invonoHistory = history;
}
export default window.invonoHistory;

export const getQuery = (search) => {
	if (search) {
		return queryString.parse(search) || {};
	}
	return queryString.parse(window.invonoHistory.location.search) || {};
};

export const getLocation = () => window.invonoHistory.location;
