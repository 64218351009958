import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import { styled } from "@mui/system";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import Typography from "@mui/material/Typography";
import { useInvitedAsFilter } from "../../common/invited-as-filter-hook";
import { DateTime } from "luxon";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import SubmissionContextMenu from "./submission-context-menu";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import { NUMBER_FORMAT_INTEGER } from "/shared/constants.json";
import GridModal from "../../../../dumb-components/shares/ag-grid/grid-modal";
import ExportExcelButton from "../../../../dumb-components/investment/shareholders/export-excel-button";
import { closeModal, openModal } from "../../../../actions/modals.actions";
import { GRID_MODAL } from "../../../../constants/modals";
import GridPanelRightButtons from "../../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons";
import { GRID_MODE_PANEL, GRID_MODE_MODAL } from "/shared/constants";
import getTranslations from "../../../../dumb-components/shared/ag-grid/locale";

const AgGridStyle = styled("div")(({ theme }) => ({
	height: "70rem",
	"& .ag-header": {
		borderRadius: 5,
		backgroundColor: theme.palette.blue["100"],
	},
	"& .ag-cell": {
		display: "flex",
		alignItems: "center",
	},
}));

const SubscriptionsGrid = ({
	submissions,
	setAddSubmissionModalOpen,
	preventChanges,
}) => {
	const [floatingFilter, setFloatingFilter] = useState(false);

	const { t } = useTranslation();
	const invitedAsFilter = useInvitedAsFilter();
	const dispatch = useDispatch();

	const gridOption = "subscriptionList";
	const isGridModalOpen = useSelector((state) => {
		return state.modals.getIn(["activeModal", "options"]) === gridOption;
	});

	const openGridModal = useCallback(() => {
		dispatch(openModal(GRID_MODAL, gridOption));
	});

	const onCloseGridModal = () => {
		dispatch(closeModal());
	};

	const displayFloatingFilter = useCallback(() => {
		if (floatingFilter) {
			setFloatingFilter(false);
		} else {
			setFloatingFilter(true);
		}
	});

	const renderAgGrid = () => {
		return (
			<Box>
				<Box sx={{ display: "flex" }}>
					{invitedAsFilter.renderFilterButtons()}
					{!isGridModalOpen && (
						<div
							style={{ justifyContent: "flex-end", flex: 1, display: "flex" }}
						>
							<GridPanelRightButtons
								openGridModal={openGridModal}
								displayFloatingFilter={displayFloatingFilter}
								floatingFilter={floatingFilter}
							/>
						</div>
					)}
				</Box>
				<AgGridStyle className={"ag-theme-material"}>
					<AgGridReact
						gridOptions={{
							onGridReady(event) {
								invitedAsFilter.setGridApi(event.api);
								event.api.sizeColumnsToFit();
								this.gridApi = event.api;
							},
							onGridSizeChanged(event) {
								event.api.sizeColumnsToFit();
							},
						}}
						rowHeight={56}
						localeText={getTranslations(t)}
						defaultColDef={{
							suppressMovable: true,
							cellStyle: {
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								overflow: "hidden",
							},
							filter: "agMultiColumnFilter",
							floatingFilter: floatingFilter,
						}}
						suppressCellFocus
						components={{
							totalAmountRenderer: TotalAmountCell,
							submittedDateRenderer: SubmittedDateCell,
							subscriberRenderer: SubscriberCell,
							actionsRenderer: ActionsCell,
						}}
						mode={isGridModalOpen ? GRID_MODE_MODAL : GRID_MODE_PANEL}
						isGridModalOpen={isGridModalOpen}
						getContextMenuItems={true}
						rowData={submissions}
						columnDefs={
							isGridModalOpen
								? [
										{
											headerName: t(
												"emissions.subscriptions.grid.expanded.group_header_name.original_invite",
											),
											children: [
												{
													field: "invitedAs",
													filter: "agSetColumnFilter",
													hide: true,
												},
												{
													field: "invitee.reference",
													headerName: t(
														"emissions.subscriptions.grid.column_header.ssn",
													),
													suppressMenu: true,
												},
												{
													field: "invitee.company",
													headerName: t(
														"emissions.subscriptions.grid.column_header.company",
													),
													suppressMenu: true,
													// valueFormatter: (params) => {
													// 	const { value } = params
													// 	if (
													// 		value.type === 'company' ||
													// 		value.type === 'capitalInsurance'
													// 	) {
													// 		return value.name
													// 	} else {
													// 		return ''
													// 	}
													// }
												},
												{
													field: "invitee.name",
													headerName: t(
														"emissions.subscriptions.grid.column_header.person",
													),
													suppressMenu: true,
													// valueFormatter: (params) => {
													// 	const { value } = params
													// 	if (value.type === 'private' || value.type === '-') {
													// 		return value.name
													// 	} else {
													// 		return ''
													// 	}
													// }
												},
											],
										},
										{
											headerName: t(
												"emissions.subscriptions.grid.expanded.group_header_name.outcome_invite",
											),
											children: [
												{
													field: "invitedAs",
													filter: "agSetColumnFilter",
													hide: true,
												},
												{
													field: "submission.submittedDate",
													headerName: t(
														"emissions.subscriptions.grid.column_header.date",
													),
													suppressMenu: true,
													cellRenderer: "submittedDateRenderer",
												},
												{
													field: "submission",
													headerName: t(
														"emissions.subscriptions.grid.column_header.ssn",
													),
													suppressMenu: true,
													valueFormatter: (params) => {
														const { value } = params;
														const identification =
															value[value.type][
																getIdentificationCode[value.type]
															];

														return identification;
													},
												},
												{
													field: "subscriber",
													headerName: t(
														"emissions.subscription-list.subscriber",
													),
													suppressMenu: true,
													cellRenderer: "subscriberRenderer",
												},
												{
													field: "proRata",
													headerName: t("Pro-rata"),
													suppressMenu: true,
													valueFormatter: formatNumbers,
												},
												{
													field: "submission.shareCount",
													headerName: t(
														"emissions.subscriptions.grid.column_header.nr_subscribed",
													),
													suppressMenu: true,
													valueFormatter: formatNumbers,
												},
												{
													field: "totalAmount",
													headerName: t(
														"emissions.subscriptions.grid.column_header.sek_subscribed",
													),
													suppressMenu: true,
													valueFormatter: formatNumbers,
												},
											],
										},
										!preventChanges
											? {
													suppressMenu: true,
													cellRenderer: "actionsRenderer",
													pinned: "right",
													width: 50,
													cellStyle: { padding: 0 },
													cellRendererParams: {
														setAddSubmissionModalOpen,
													},
											  }
											: {},
								  ]
								: [
										{
											field: "invitedAs",
											filter: "agSetColumnFilter",
											hide: true,
										},
										{
											field: "submission.submittedDate",
											headerName: t(
												"emissions.subscriptions.grid.column_header.date",
											),
											suppressMenu: true,
											cellRenderer: "submittedDateRenderer",
										},
										{
											field: "submission",
											headerName: t(
												"emissions.subscriptions.grid.column_header.ssn",
											),
											suppressMenu: true,
											valueFormatter: (params) => {
												const { value } = params;
												const identification =
													value[value.type][getIdentificationCode[value.type]];

												return identification;
											},
										},
										{
											field: "subscriber",
											headerName: t("emissions.subscription-list.subscriber"),
											suppressMenu: true,
											cellRenderer: "subscriberRenderer",
										},
										{
											field: "proRata",
											headerName: t("Pro-rata"),
											suppressMenu: true,
											valueFormatter: formatNumbers,
										},
										{
											field: "submission.shareCount",
											headerName: t(
												"emissions.subscriptions.grid.column_header.nr_subscribed",
											),
											suppressMenu: true,
											valueFormatter: formatNumbers,
										},
										{
											field: "totalAmount",
											headerName: t(
												"emissions.subscriptions.grid.column_header.sek_subscribed",
											),
											suppressMenu: true,
											valueFormatter: formatNumbers,
										},
										!preventChanges
											? {
													suppressMenu: true,
													cellRenderer: "actionsRenderer",
													pinned: "right",
													width: 50,
													cellStyle: { padding: 0 },
													cellRendererParams: {
														setAddSubmissionModalOpen,
													},
											  }
											: {},
								  ]
						}
					/>
				</AgGridStyle>
			</Box>
		);
	};

	return (
		<>
			{isGridModalOpen ? (
				<GridModal
					onCancel={onCloseGridModal}
					isOpen={isGridModalOpen}
					rightHeaderComponent={() => {
						return (
							<ExportExcelButton
								floatingFilter={floatingFilter}
								displayFloatingFilter={displayFloatingFilter}
							/>
						);
					}}
					leftHeader={{
						tid: "emissions.tabs.subscriptions",
					}}
				>
					<div style={{ height: "100%", width: "100%" }}>{renderAgGrid()}</div>
				</GridModal>
			) : (
				<>{renderAgGrid()}</>
			)}
		</>
	);
};

const TotalAmountCell = (props) => {
	return props.data.submission.shareCount * props.pricePerShare;
};

const SubmittedDateCell = (props) => {
	return DateTime.fromISO(props.value).toFormat("yyyy-MM-dd HH:mm");
};

const SubscriberCell = (props) => {
	return (
		<Tooltip title={props.value}>
			<Typography
				variant={"body2"}
				sx={{
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					overflow: "hidden",
				}}
			>
				{props.value}
			</Typography>
		</Tooltip>
	);
};

const ActionsCell = (props) => {
	const emission = useSelector((state) => state.emissions.current);
	const inviteeId = props.data.inviteeId;

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					flex: "1 1 auto",
					width: "100%",
				}}
			>
				<SubmissionContextMenu
					setAddSubmissionModalOpen={props.setAddSubmissionModalOpen}
					companyId={emission.companyId}
					emissionId={emission.id}
					inviteeId={inviteeId}
					editable={props.data.submission.creator !== "invitee"}
				/>
			</Box>
		</>
	);
};

function formatNumbers(number) {
	return number.value
		? localeFormatNumber(number.value, NUMBER_FORMAT_INTEGER)
		: "";
}

const getIdentificationCode = {
	private: "ssn",
	company: "organizationNumber",
	insurance: "insuranceNumber",
};

export default SubscriptionsGrid;
