import React, { Component } from "react";
import { connect } from "react-redux";
import immutablePropTypes from "react-immutable-proptypes";
import { string, bool, func, oneOf } from "prop-types";
import { Map } from "immutable";
import { fetchSimpleUsers } from "../../../actions/usersCache.actions";
import AttendeeItem from "../../../dumb-components/meetings/attendees-list/attendee";
import DropdownStatusButtonContainer from "./dropdown-status-button.container";
import moment from "../../../modules/moment.module";
import { MEETING_TYPE_STANDARD } from "/shared/constants";
import MeetingRoleDisplay from "./meeting-role-display.container";
import { getUserName } from "../../../components/helpers/users";
import { openModal } from "../../../actions/modals.actions";
import { MEETINGS_ROLLCALL_MODAL } from "../../../constants/modals";
import investmentHelper from "../../../components/helpers/investment.helper";
class AttendeeItemContainer extends Component {
	static propTypes = {
		userId: string,
		readOnly: bool,
		onClick: func,
		onChange: func,
		attendee: immutablePropTypes.map,
		chairman: string,
		secretary: string,
		renderMoreActionButton: func,
		showWarning: bool,
		showStatusIndicator: bool,
		hasExtendedRights: bool,
		canSetStatus: bool,
		forceReadOnly: bool,
		context: oneOf(["attendees", "feedback", "signing"]),
		hasProxy: bool,
		proxy: immutablePropTypes.map,
		proxyDocument: immutablePropTypes.map,
		assistantForAttendee: immutablePropTypes.map,
		investors: immutablePropTypes.list,
		isGuest: bool,
		isInvestor: bool,
		isStatic: bool,
		moderators: immutablePropTypes.list,
	};

	static defaultProps = {
		showStatusIndicator: true,
		assistantForAttendee: Map(),
	};

	proxyDocumentBtnRefs = {};

	getAssistantForAttendeeName = () => {
		const { assistantForAttendee, investorsCache, usersCache, attendees } =
			this.props;
		const assistantForUserId = assistantForAttendee.get("userId");

		if (assistantForAttendee.isEmpty()) {
			return null;
		}
		const attendeeObject = attendees.get(assistantForUserId);
		return getUserName(attendeeObject, investorsCache, usersCache);
	};

	onChange = (field, val) => {
		const { onChange, userId } = this.props;
		let { attendee } = this.props;

		if (field === "status") {
			attendee = attendee.set("haveResponded", true);
			attendee = attendee.set("statusSetFromApp", true);
			attendee = attendee.setIn(["metadata", "respondedAt", "status"], val);
			attendee = attendee.setIn(
				["metadata", "respondedAt", "respondedAt"],
				moment().toISOString(),
			);
		}

		attendee = attendee.set(field, val);

		onChange(userId, attendee, field, val);
	};

	onAttendeeClick = () => {
		const { onClick, userId } = this.props;
		onClick && onClick(userId);
	};

	renderStatus = () => {
		const {
			userId,
			haveResponded,
			status,
			showStatusIndicator,
			isStatic,
			canSetStatus,
			forceReadOnly,
			context,
			attendee,
			openModal,
		} = this.props;

		if (!showStatusIndicator) {
			return null;
		}

		return (
			<DropdownStatusButtonContainer
				fieldName="status"
				itemId={userId}
				haveResponded={haveResponded}
				status={status}
				verifiedAttendance={attendee.get("verifiedAttendance")}
				onChange={this.onChange}
				isStatic={isStatic}
				readOnly={forceReadOnly || !canSetStatus}
				context={context}
				statusSetFromApp={attendee.get("statusSetFromApp")}
				openModal={() => openModal(MEETINGS_ROLLCALL_MODAL)}
			/>
		);
	};

	render() {
		const {
			usersCache,
			investorsCache,
			userId,
			switchDisabled,
			switchTooltipTid,
			companyName,
			roles,
			attendee,
			isInvestor,
			meetingHasModerator,
			isStandardMeeting,
			...attendeeItemProps
		} = this.props;

		let name, email, profileImage;
		if (usersCache.has(userId)) {
			const user = usersCache.get(userId);
			name = user.get("name");
			email = user.get("email");
			profileImage = user.getIn(["image", "filename"]);
		} else if (isInvestor && investorsCache.has(attendee.get("investmentId"))) {
			const investor = investorsCache.get(attendee.get("investmentId"));
			name = investmentHelper.getInvestorName(investor);
			email = investmentHelper.getPrimaryInvestmentEmail(investor);
			profileImage = investor.getIn(["image", "filename"]);
		} else {
			name = attendee.get("name");
			email = attendee.get("email");
		}

		return (
			<AttendeeItem
				{...attendeeItemProps}
				userId={userId}
				name={name}
				email={email}
				roles={roles}
				companyName={companyName}
				profileImage={profileImage}
				onClick={this.onAttendeeClick}
				onChange={this.onChange}
				renderStatus={this.renderStatus}
				switchDisabled={switchDisabled}
				switchTooltipTid={switchTooltipTid}
				assistantForAttendeeName={this.getAssistantForAttendeeName()}
				roleTextComponent={<MeetingRoleDisplay roles={roles} />}
				isStandardMeeting={isStandardMeeting}
				meetingHasModerator={meetingHasModerator}
			/>
		);
	}
}

const mapStoreToProps = (store, ownProps) => {
	return {
		usersCache: store.usersCache.get("usersCache"),
		investorsCache: store.investors.get("investorsCache"),
		companyName: store.meetings.getIn([
			"meeting",
			"metadata",
			"companyNames",
			ownProps.isProxyForCompany,
		]),
		userObj: store.user.get("userObj"),
		attendees: store.meetings.getIn(["meeting", "attendees"], Map()) || Map(),
		companyId: store.company.company.id,
		isStandardMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) ===
			MEETING_TYPE_STANDARD,
		meetingHasModerator: store.meetings.getIn([
			"meeting",
			"computedValues",
			"hasModerator",
		]),
	};
};

const mapActionsToProps = {
	fetchSimpleUsers,
	openModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(AttendeeItemContainer);
