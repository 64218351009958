import { func } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePeopleModalContext } from "../people-modal.context";
import MuiDialog from "../../../../mui-components/dialog/mui-dialog";
import { fetchRoles } from "../../../../actions/user-management.actions";
import { TextField, Box, MenuItem, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PEOPLE_MODAL_FILTERS_TEAMS } from "../constants/filters";
import IconButton from "../../../../mui-components/button/icon-button";

function RolesFilterModal({ onClose }) {
	const { filters, setFilters } = usePeopleModalContext();
	const [filtersInternally, setFiltersInternally] = useState({});
	const filterValues =
		filtersInternally[PEOPLE_MODAL_FILTERS_TEAMS] ||
		filters[PEOPLE_MODAL_FILTERS_TEAMS] ||
		"__placeholder__";
	const roles = useSelector((state) => state.company.userManagement.roles);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(fetchRoles());

		return () => {};
	}, []);

	const onChange = (event) => {
		const value = event.target.value;
		setFiltersInternally({
			...filtersInternally,
			[PEOPLE_MODAL_FILTERS_TEAMS]: value,
		});
	};

	const onClear = () => {
		setFiltersInternally({
			...filtersInternally,
			[PEOPLE_MODAL_FILTERS_TEAMS]: "__placeholder__",
		});
	};

	const onApply = () => {
		if (filtersInternally[PEOPLE_MODAL_FILTERS_TEAMS] === "__placeholder__") {
			filtersInternally[PEOPLE_MODAL_FILTERS_TEAMS] = null;
		}

		setFilters({ ...filtersInternally });
		onClose();
	};

	return (
		<MuiDialog
			title={t("people_modal.teams_filter.title")}
			isOpen={true}
			onClose={onClose}
			onConfirm={onApply}
			confirmButtonTid="people_modal.teams_filter.btn_apply"
			maxWidth="sm"
			fullWidth
			autoHeight
		>
			<Box sx={{ pb: "34px" }}>
				<TextField
					onChange={onChange}
					label={t("people_modal.teams_filter.select_teams")}
					variant="standard"
					fullWidth
					select
					value={filterValues}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end" sx={{ mr: "24px" }}>
								<IconButton
									icon="faTimes"
									size="sm"
									noBorder
									noInlinePadding
									onClick={onClear}
								/>
							</InputAdornment>
						),
					}}
				>
					<MenuItem value={"__placeholder__"} disabled={true}>
						{t("select_placeholder")}
					</MenuItem>
					{roles &&
						roles.map((role) => (
							<MenuItem key={role.id} value={role.name}>
								{role.name}
							</MenuItem>
						))}
				</TextField>
			</Box>
		</MuiDialog>
	);
}

RolesFilterModal.propTypes = {
	onClose: func.isRequired,
};

export { RolesFilterModal };
