import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";

import SimpleDarkInfoPanel from "../../../../dumb-components/shared/dark-info-panel/simple-dark-info-panel";
import ModernFilePreview from "../../../../dumb-components/shared/modern-file-preview/modern-file-preview";
import DropdownMenuContainer from "../../../shared/dropdown-menu.container";
import DropdownIconItem from "../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import { ButtonTransparentIcon } from "../../../../dumb-components/shared/button";
import ConfirmContainer from "../../../shared/confirm.container";
import PublishedDocumentSharedWith from "../../../../dumb-components/meetings/published-document/published-document-shared-with";
import SharedWithListContainer from "../../../documents/documents-shared-with-list/documents-shared-with-list.container";

import docHelper from "../../../../components/helpers/documents.helper";
import { downloadDocument } from "../../../../actions/documents.actions";
import { unpublishProtocol } from "../../../../actions/meetings.actions";

const IconButton = (props) => (
	<ButtonTransparentIcon
		color="white"
		hoverColor="muted"
		borderColor="white"
		size="sm"
		autoUnsetWidthHeight={false}
		noHorizontalMargin
		{...props}
	/>
);

class SharedProtocolFilePreviewContainer extends Component {
	state = {
		confirmIsOpen: false,
		isSharedWithModalOpen: false,
	};

	dropdownRef = null;

	toggleDropdown = () => {
		this.dropdownRef && this.dropdownRef.onToggleMenu();
	};

	getHasMergedDocument = () => {
		const { document } = this.props;
		return docHelper.getHasMergedDocument(document);
	};

	getFileCanBeOpenedInBrowser = (fileReference = Map()) => {
		const ext = fileReference.get("ext");
		const size = fileReference.get("size");
		return docHelper.isViewableInBrowser(fileReference, ext, size);
	};

	open = ({ fileReference, documentId }) => {
		const { downloadDocument } = this.props;
		const openInViewer = this.getFileCanBeOpenedInBrowser(fileReference);
		const getMergedVersion = this.getHasMergedDocument();

		downloadDocument({ documentId, openInViewer, getMergedVersion });
	};

	openSharedWithModal = () => {
		this.setState({ isSharedWithModalOpen: true });
	};

	download = ({ documentId }) => {
		const { downloadDocument } = this.props;
		const getMergedVersion = this.getHasMergedDocument();

		downloadDocument({ documentId, getMergedVersion });
	};

	delete = () => {
		this.toggleDropdown();

		this.setState({ confirmIsOpen: true });
	};

	closeConfirmModal = () => {
		this.setState({ confirmIsOpen: false });
	};

	doDeleteProtocol = () => {
		const { meetingId, unpublishProtocol } = this.props;

		this.setState({ confirmIsOpen: false });

		unpublishProtocol(meetingId);
	};

	closeSharedWithModal = () => {
		this.setState({ isSharedWithModalOpen: false });
	};

	renderDropdown = () => {
		return (
			<DropdownMenuContainer
				halignMenu="right"
				ref={(ref) => (this.dropdownRef = ref)}
				renderRaw={
					<IconButton
						icon="faEllipsisV"
						onClick={this.toggleDropdown}
						noHorizontalMargin
					/>
				}
			>
				<DropdownIconItem
					icon="faTrashAlt"
					tid="meetings.protocol.publish.panel.dropdown_item.delete_published"
					onClick={this.delete}
				/>
			</DropdownMenuContainer>
		);
	};

	render = () => {
		const { confirmIsOpen, isSharedWithModalOpen } = this.state;
		const { document } = this.props;
		if (!document) {
			return null;
		}
		const fileReference = docHelper.getCorrectMergedFileReference(document);

		return (
			<SimpleDarkInfoPanel flexDirection="column">
				<PublishedDocumentSharedWith
					onOpenSharedWithModal={this.openSharedWithModal}
					tid="meetings.protocol.publish.panel.info.published"
				/>
				<ModernFilePreview
					document={document}
					fileReference={fileReference}
					onOpenFile={this.open}
					onDownloadFile={this.download}
					dropdownRenderer={this.renderDropdown}
				/>

				<ConfirmContainer
					title="meetings.standard.delete_protocol.confirm.modal.title"
					message="meetings.standard.delete_protocol.confirm_modal.message"
					onConfirm={this.doDeleteProtocol}
					onDecline={this.closeConfirmModal}
					isOpen={confirmIsOpen}
				/>

				{isSharedWithModalOpen && (
					<SharedWithListContainer
						isOpen={true}
						onCancel={this.closeSharedWithModal}
					/>
				)}
			</SimpleDarkInfoPanel>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		document: store.documents.get("document"),
		meetingId: store.meetings.getIn(["meeting", "id"]),
		attendees: store.meetings.getIn(["meeting", "attendees"]),
	};
};

const mapActionsToProps = {
	downloadDocument,
	unpublishProtocol,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(SharedProtocolFilePreviewContainer);
