import React, { PureComponent } from "react";
import { string, object } from "prop-types";
import styled from "styled-components";

import DarkInfoPanel from "../../shared/dark-info-panel/dark-info-panel";
import SimpleDarkInfoPanel from "../../shared/dark-info-panel/simple-dark-info-panel";
import Text from "../../shared/text/text";

const ProtocolPublishedSimpleDarkPanel = styled(SimpleDarkInfoPanel)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	> :first-child {
		margin-right: ${(props) => props.theme.spacing[4]};
	}
`;

const StyledFooterWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledChildrenWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing[4]};
`;

const WhiteText = (textProps) => <Text color="white" {...textProps} />;
const OptionalText = (textProps) => <Text color="lightGrey" {...textProps} />;

const INSTRUCTIONS = [
	{ tid: "meetings.publish.without_esign.information.title" },
	{ tid: "meetings.publish.without_esign.information.info_1", optional: true },
	{ tid: "meetings.publish.without_esign.information.info_2", optional: true },
	{ tid: "meetings.publish.without_esign.information.info_3", optional: true },
	{ tid: "meetings.publish.without_esign.information.info_4" },
];

export default class PublishWithoutEsignPanel extends PureComponent {
	static propTypes = {
		mainControlsComponent: object,
		footerComponent: object,
		protocolSharedAt: string,
		unpublishControlsComponent: object,
	};

	getStepValuesTextComponent = (number, optional) => {
		const values = {
			step: (
				<WhiteText
					tid="esign.request_esign.information.step"
					bold={600}
					values={{ number }}
				/>
			),
		};

		if (optional) {
			values.optional = (
				<OptionalText tid="esign.request_esign.information.optional" />
			);
		}

		return values;
	};

	renderInformation = () => {
		return INSTRUCTIONS.map((data, index) => {
			if (index === 0) {
				return (
					<WhiteText tid={data.tid} bold={600} marginBottom={4} key={index} />
				);
			}

			return (
				<WhiteText
					tid={data.tid}
					values={this.getStepValuesTextComponent(index, data.optional)}
					key={index}
				/>
			);
		});
	};

	renderFooterComponent = () => {
		const { footerComponent } = this.props;

		return (
			<StyledFooterWrapper>
				{this.renderInformation()}

				<StyledChildrenWrapper>{footerComponent}</StyledChildrenWrapper>
			</StyledFooterWrapper>
		);
	};

	render = () => {
		const {
			mainControlsComponent,
			protocolSharedAt,
			unpublishControlsComponent,
		} = this.props;
		const textComponent = (
			<WhiteText tid="meetings.protocol.publish_panel.info.pre_start" />
		);

		return (
			<>
				{protocolSharedAt && (
					<ProtocolPublishedSimpleDarkPanel>
						{unpublishControlsComponent}
					</ProtocolPublishedSimpleDarkPanel>
				)}

				{!protocolSharedAt && (
					<DarkInfoPanel
						headerLeftComponent={textComponent}
						headerRightComponent={mainControlsComponent}
						footerComponent={this.renderFooterComponent()}
					/>
				)}
			</>
		);
	};
}
