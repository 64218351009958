import React, { Component } from "react";
import { connect } from "react-redux";
import { List } from "immutable";
import { listConversations } from "../../../actions/comments.actions";
import { fetchSimpleUsers } from "../../../actions/usersCache.actions";
import ProtocolFeedbackDetails from "../../../dumb-components/meetings/protocol-review/protocol-feedback-details/protocol-feedback-details";

class ProtocolFeedbackDetailsContainer extends Component {
	state = {
		agendaItems: List(),
		conversations: null,
	};

	componentDidMount = () => {
		const {
			agendaItems,
			fetchSimpleUsers,
			match: {
				params: { feedbackeeId },
			},
		} = this.props;

		if (agendaItems) {
			this.flattenAgendaItems();
		}

		fetchSimpleUsers(List([feedbackeeId]));
	};

	componentDidUpdate = (prevProps) => {
		const {
			agendaItems,
			conversations,
			match: {
				params: { feedbackeeId },
			},
		} = this.props;

		if (prevProps.agendaItems !== agendaItems) {
			this.flattenAgendaItems();
		}

		if (
			prevProps.conversations !== conversations ||
			prevProps.match.params.feedbackeeId !== feedbackeeId
		) {
			this.parseConversations();
		}
	};

	parseConversations = () => {
		const {
			conversations,
			match: {
				params: { feedbackeeId },
			},
			fetchSimpleUsers,
		} = this.props;
		let userIds = List();
		const parsedConversations =
			conversations &&
			conversations.filter((conversation) => {
				let found = false;

				conversation.get("comments", List()).forEach((comment) => {
					userIds = userIds.push(comment.get("createdBy"));

					if (comment.get("createdBy") === feedbackeeId) {
						found = true;
					}
				});

				return found;
			});

		fetchSimpleUsers(userIds);
		this.setState({ conversations: parsedConversations });
	};

	flattenAgendaItems = () => {
		const {
			listConversations,
			agendaItems,
			match: {
				params: { id },
			},
		} = this.props;
		let objIds = List();

		if (!agendaItems) {
			return;
		}

		let parsedAgendaItems = List();
		agendaItems.forEach((item, index) => {
			objIds = objIds.push(`${id}$${item.get("id")}`);
			const num = index + 1;
			item = item.set("num", num);
			parsedAgendaItems = parsedAgendaItems.push(item);

			if (item.has("agendaItems")) {
				item.get("agendaItems", List()).forEach((subitem, subindex) => {
					objIds = objIds.push(`${id}$${subitem.get("id")}`);
					const num = index + 1 + String.fromCharCode(96 + subindex + 1);
					subitem = subitem.set("num", num);
					parsedAgendaItems = parsedAgendaItems.push(subitem);
				});
			}
		});

		this.setState({ agendaItems: parsedAgendaItems });
		listConversations(objIds);
	};

	render = () => {
		const {
			usersCache,
			match: {
				params: { id, feedbackeeId },
			},
		} = this.props;
		const { agendaItems, conversations } = this.state;
		const feedbackeeName = usersCache.getIn([feedbackeeId, "name"]);

		return (
			<ProtocolFeedbackDetails
				meetingId={id}
				agendaItems={agendaItems}
				conversations={conversations}
				feedbackeeName={feedbackeeName}
				usersCache={usersCache}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		conversations: store.comments.get("list"),
		agendaItems: store.meetings.getIn(["meeting", "agendaItems"]),
		usersCache: store.usersCache.get("usersCache"),
	};
};

const mapActionsToProps = {
	listConversations,
	fetchSimpleUsers,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ProtocolFeedbackDetailsContainer);
