import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { List, Map } from "immutable";
import { closeModal, openModal } from "../../actions/modals.actions";
import { deleteDocumentsPerminantly } from "../../actions/documents.actions";
import { downloadFolderMatrix } from "../../actions/folders.actions";

import IconDrawer from "../../dumb-components/shared/icon-drawer/icon-drawer";
import ConfirmContainer from "../shared/confirm.container";

const DELETE_DOCUMENTS_TOOLTIP_STATES = {
	default: {
		tid: "documents.perminantly_delete_documents.tooltip.default",
	},
	disabled: {
		tid: "documents.perminantly_delete_documents.tooltip.disabled",
		delayShow: "instant",
	},
};

const DOWNLOAD_FOLDER_STRUCTURE_TOOLTIP_STATES = {
	default: {
		tid: "documents.download_folder_structure.tooltip.default",
	},
};

const CONFIRM_PERMINANTLY_DELETE_DOCUMENTS_MODAL =
	"CONFIRM_PERMINANTLY_DELETE_DOCUMENTS_MODAL";

class DocumentSettingsBarContainer extends Component {
	getDrawerObject = () => {
		const { downloadFolderMatrix } = this.props;

		return List([
			Map({
				icon: "faCog",
			}),
			Map({
				icon: "faFileSpreadsheet",
				tooltipStates: DOWNLOAD_FOLDER_STRUCTURE_TOOLTIP_STATES,
				onClick: () => downloadFolderMatrix(true),
			}),
			Map({
				icon: "faTrash",
				disabled: false,
				tooltipStates: DELETE_DOCUMENTS_TOOLTIP_STATES,
				tooltipActiveState: "default",
				onClick: this.onModalOpen.bind(
					this,
					CONFIRM_PERMINANTLY_DELETE_DOCUMENTS_MODAL,
				),
			}),
		]);
	};

	onModalOpen = (modalName) => {
		this.props.openModal(modalName);
	};

	onModalClose = () => {
		this.props.closeModal();
	};

	doDeleteDocuments = () => {
		const { documentId, deleteDocumentsPerminantly } = this.props;

		this.onModalClose();
		deleteDocumentsPerminantly(documentId);
	};

	renderConfirmDeleteDocumentsModal = () => {
		const { confirmDeleteDocumentsModalOpen } = this.props;

		return (
			<ConfirmContainer
				title="documents.perminantly_delete_documents.confirm.title"
				question="documents.perminantly_delete_documents.confirm.question"
				onConfirm={this.doDeleteDocuments}
				onDecline={this.onModalClose}
				isOpen={confirmDeleteDocumentsModalOpen}
			/>
		);
	};

	render = () => {
		const drawer = this.getDrawerObject();

		return (
			<Fragment>
				<IconDrawer drawer={drawer} />

				{this.renderConfirmDeleteDocumentsModal()}
			</Fragment>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		confirmDeleteDocumentsModalOpen:
			store.modals.getIn(["activeModal", "name"]) ===
			CONFIRM_PERMINANTLY_DELETE_DOCUMENTS_MODAL,
		documentId: store.documents.getIn(["document", "id"]),
		companiesAccessRights: store.company.companiesAccessRights,
	};
};

const mapActionsToProps = {
	closeModal,
	openModal,
	deleteDocumentsPerminantly,
	downloadFolderMatrix,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(DocumentSettingsBarContainer);
