import React, { PureComponent } from "react";
import { bool, func, oneOf, string } from "prop-types";
import styled from "styled-components";
import { Padding } from "styled-components-spacing";
import Panel from "../../shared/panel/panel";
import Grid from "styled-components-grid";
import Label from "../../shared/label/label";
import { Select } from "../../shared/select";
import { List, Map } from "immutable";
import Text from "../../shared/text/text";
import { Lock } from "lucide-react";

const StyledTextWrapper = styled.div`
	padding-top: ${(props) => props.theme.spacing[4]};
`;

const options = List([
	Map({
		value: "true",
		label: "documents.manage_folder.shareholder.permissions.yes",
	}),
	Map({
		value: "false",
		label: "documents.manage_folder.shareholder.permissions.no",
	}),
]);

const optionsIfSignedDocument = List([
	Map({
		value: "original",
		label: "documents.manage_folder.shareholder.permissions.yes_original",
	}),
	Map({
		value: "signed",
		label: "documents.manage_folder.shareholder.permissions.yes_signed",
	}),
	Map({
		value: "false",
		label: "documents.manage_folder.shareholder.permissions.no",
	}),
]);

class ShareholderPermissionsDocument extends PureComponent {
	static propTypes = {
		onChange: func,
		isMirrored: bool,
		mode: oneOf(["branded", "light", "transparent"]),
		panelType: oneOf(["inline", "transparent"]),
		selected: bool,
		noTitle: bool,
		infoTid: string,
		labelTid: string,
		hasSignedDocument: bool,
		onChangeWhenSignedDoc: func,
		signedIsMirrored: bool,
	};

	static defaultProps = {
		mode: null,
	};

	onChangedIsMirrored = (fieldName, value) => {
		const { onChange, hasSignedDocument, onChangeWhenSignedDoc } = this.props;
		if (hasSignedDocument) {
			if (value === "false") {
				onChangeWhenSignedDoc(
					Map({ isMirrored: false, signedIsMirrored: false }),
				);
			} else if (value === "original") {
				onChangeWhenSignedDoc(
					Map({ isMirrored: true, signedIsMirrored: false }),
				);
			} else {
				onChangeWhenSignedDoc(
					Map({ isMirrored: false, signedIsMirrored: true }),
				);
			}
		} else {
			if (value === "false") {
				onChange(fieldName, false);
			} else {
				onChange(fieldName, true);
			}
		}
	};

	getOptions = () => {
		const { hasSignedDocument } = this.props;

		if (hasSignedDocument) {
			return optionsIfSignedDocument;
		} else {
			return options;
		}
	};

	render = () => {
		const {
			isMirrored,
			mode,
			panelType,
			selected,
			noTitle,
			infoTid,
			labelTid,
			hasSignedDocument,
			signedIsMirrored,
		} = this.props;
		const isTransparentPanel = panelType === "transparent";
		const isModeTransparent = mode === "transparent";

		const value = () => {
			if (hasSignedDocument) {
				if (signedIsMirrored) {
					return "signed";
				} else if (isMirrored) {
					return "original";
				} else {
					return "false";
				}
			} else {
				return isMirrored ? isMirrored.toString() : "false";
			}
		};

		return (
			<Panel
				icon={<Lock />}
				title={
					noTitle
						? ""
						: "documents.permissions.manage_permissions.Shareholder_permissions"
				}
				mode={mode}
				type={panelType}
				active={selected}
				marginBottom={isTransparentPanel ? false : true}
			>
				<Padding all={isModeTransparent ? 0 : 4}>
					<Grid>
						<Grid.Unit size={1}>
							<Label tid={labelTid}>
								<Select
									fieldName={
										hasSignedDocument ? "signedIsMirrored" : "isMirrored"
									}
									onChange={this.onChangedIsMirrored}
									options={this.getOptions()}
									labelIsTid={true}
									value={value()}
								/>
							</Label>
							{infoTid && (
								<StyledTextWrapper>
									<Text tid={infoTid} />
								</StyledTextWrapper>
							)}
						</Grid.Unit>
					</Grid>
				</Padding>
			</Panel>
		);
	};
}

export default ShareholderPermissionsDocument;
