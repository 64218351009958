import { fromJS } from "immutable";
import {
	DELETE_PERSONAL_ATTACHMENT,
	LIST_PERSONAL_ATTACHMENTS,
	RESET_PERSONAL_ATTACHMENTS,
	LIST_MANY_PERSONAL_ATTACHMENTS,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	attachments: {},
});

export default function (state = INITIAL_STATE, { type, payload }) {
	switch (type) {
		case LIST_PERSONAL_ATTACHMENTS: {
			const { objType, objId, attachments } = payload;
			return state.setIn(["attachments", objType, objId], attachments);
		}

		case LIST_MANY_PERSONAL_ATTACHMENTS: {
			const { objType, groupedAttachments } = payload;
			return state.setIn(["attachments", objType], groupedAttachments);
		}

		case DELETE_PERSONAL_ATTACHMENT: {
			const { objType, objId, id } = payload;
			return state.updateIn(["attachments", objType, objId], (attachments) =>
				attachments.filter((attachment) => attachment.get("id") !== id),
			);
		}

		case RESET_PERSONAL_ATTACHMENTS: {
			const { objType, objId } = payload;

			if (!objId) {
				return state.deleteIn(["attachments", objType]);
			}

			return state.deleteIn(["attachments", objType, objId]);
		}

		default:
			return state;
	}
}
