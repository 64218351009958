import React, { PureComponent } from "react";
import { Button } from "@/components/ui/button";
import { bool, func, string, number } from "prop-types";
import { Input } from "@/components/ui/input";

import {
	EMAIL_FIELD,
	CODE_FIELD,
	PASSWORD_FIELD,
	PASSWORD_VERIFY_FIELD,
} from "../../../constants/credentials";

import i18n from "@/i18n";
import VerificationCodeInput from "./verificationCodeInput";
const { t } = i18n;

export default class ForgotPassword extends PureComponent {
	static propTypes = {
		onClickActionBtn: func,
		onCancel: func,
		onChange: func,
		actionBtnTid: string,
		email: string,
		code: string,
		password: string,
		passwordVerify: string,
		step: number,
		actionBtnLoading: bool,
	};

	static defaultProps = {
		actionBtnTid: "credentials.top.section.forgotpw",
		email: "",
		code: "",
		password: "",
		passwordVerify: "",
	};

	onInputEnterKey = () => {
		const { onClickActionBtn, actionBtnLoading } = this.props;
		!actionBtnLoading && onClickActionBtn && onClickActionBtn();
	};

	renderEmailInput = () => {
		const { hasError, onChange, email, step, actionBtnLoading } = this.props;

		return (
			<div>
				<label htmlFor="email">{t("credentials.forgotpw.label.email")}</label>
				<Input
					name="email"
					type="email"
					value={email}
					onChange={(evt) => onChange(EMAIL_FIELD, evt.target.value)}
					onKeyDown={(evt) => evt.key === "Enter" && this.onInputEnterKey()}
					disabled={step >= 1 || actionBtnLoading}
					error={
						hasError.get(EMAIL_FIELD)?.size > 0 &&
						hasError.get(EMAIL_FIELD).map(t).toJS()
					}
				/>
			</div>
		);
	};

	renderVerifyCodeInput = () => {
		const { onChange, code, step, actionBtnLoading, email, onClickActionBtn } =
			this.props;
		const stepPassed = step >= 2;

		return (
			<VerificationCodeInput
				value={code}
				onChange={(code) => {
					onChange(CODE_FIELD, code);
				}}
				email={email}
				ref={(ref) => {
					this.verificationCodeInput = ref;
				}}
				disabled={stepPassed || actionBtnLoading}
				onComplete={onClickActionBtn}
			/>
		);
	};

	renderPasswordInput = () => {
		const { hasError, onChange, password, actionBtnLoading } = this.props;

		return (
			<div>
				<label htmlFor="password">{t("credentials.forgotpw.label.pass")}</label>
				<Input
					id="password"
					type="password"
					onChange={(evt) => onChange(PASSWORD_FIELD, evt.target.value)}
					onKeyDown={(evt) => evt.key === "Enter" && this.onInputEnterKey()}
					error={
						hasError.get(PASSWORD_FIELD)?.size > 0 &&
						hasError.get(PASSWORD_FIELD).map(t).toJS()
					}
					disabled={actionBtnLoading}
					value={password}
					autoFocus
				/>
			</div>
		);
	};

	renderPasswordVerifyInput = () => {
		const { hasError, onChange, passwordVerify, actionBtnLoading } = this.props;

		return (
			<div>
				<label htmlFor="passwordVerify">
					{t("credentials.forgotpw.label.pass_verify")}
				</label>
				<Input
					id="passwordVerify"
					type="password"
					onChange={(evt) => onChange(PASSWORD_VERIFY_FIELD, evt.target.value)}
					onKeyDown={(evt) => evt.key === "Enter" && this.onInputEnterKey()}
					error={
						hasError.get(PASSWORD_VERIFY_FIELD)?.size > 0 &&
						hasError.get(PASSWORD_VERIFY_FIELD).map(t).toJS()
					}
					disabled={actionBtnLoading}
					value={passwordVerify}
				/>
			</div>
		);
	};

	render = () => {
		const { onClickActionBtn, onCancel, actionBtnTid, step, actionBtnLoading } =
			this.props;

		return (
			<div className="flex flex-col gap-6 mb-6">
				<h1 className="text-2xl mb-10">
					{t("credentials.top.section.forgotpw")}
				</h1>
				{step >= 0 && this.renderEmailInput()}
				{step >= 1 && this.renderVerifyCodeInput()}
				{step >= 2 && this.renderPasswordInput()}
				{step >= 2 && this.renderPasswordVerifyInput()}

				<div className="flex w-full gap-4">
					<Button
						className="flex-1"
						isLoading={actionBtnLoading}
						onClick={onClickActionBtn}
						size="lg"
					>
						{t(actionBtnTid)}
					</Button>
					<Button
						className="flex-1"
						variant="outline"
						onClick={onCancel}
						size="lg"
					>
						{t("credentials_cancel")}
					</Button>
				</div>
			</div>
		);
	};
}
