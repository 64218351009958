import React, { Component } from "react";
import { connect } from "react-redux";
import DashboardAlert from "../../components/user/dashboard--alert";
import ScrollView from "../../dumb-components/shared/layout/scroll-view/scroll-view";
import MeetingListByCompaniesContainer from "../../containers/meetings/meeting-list-by-companies.container";
import TaskListByCompaniesContainer from "../../containers/tasks/task-list-by-companies.container";
import CompanyAccountsContainer from "../../containers/dashboard/company-accounts.container";
import { resetCompany } from "../../actions/company.actions";
import DocumentsListByCompaniesContainer from "../documents/documents-list-by-companies.container";
import { routeHoc } from "../../interfaces/router";
import { LOCATION_DASHBOARD } from "/shared/constants";
import MyInvestmentsChart from "../../dumb-components/dashboard/my-investments-chart";

class DashboardContainer extends Component {
	componentDidMount = () => {
		const { company, userCompanies, resetCompany } = this.props;

		if (userCompanies && company) {
			const filteredCompanies =
				userCompanies &&
				userCompanies.filter((company) => {
					const notCanceled = !company.get("isCanceled");
					return notCanceled;
				});

			if (filteredCompanies && filteredCompanies.size > 1) {
				resetCompany();
			}
		}
	};

	getUserHasCompanies = () => {
		const { userCompanies } = this.props;

		const filteredCompanies =
			userCompanies &&
			userCompanies.filter((company) => {
				const notCanceled = !company.get("isCanceled");
				return notCanceled;
			});

		return filteredCompanies && filteredCompanies.size > 0 ? true : false;
	};

	render = () => {
		const userHasCompanies = this.getUserHasCompanies();

		return (
			<div className="flex flex-col p-md h-full">
				<div className="i-content__body i-content__body--column">
					<ScrollView autoHide={true} showOnHover={true} noLeftMargin={true}>
						<div className="row">
							<DashboardAlert />

							<MeetingListByCompaniesContainer />

							{userHasCompanies && <DocumentsListByCompaniesContainer />}

							{userHasCompanies && <TaskListByCompaniesContainer />}

							<div className="col-md-12" style={{ marginBottom: "16px" }}>
								<MyInvestmentsChart />
							</div>

							<div className="col-md-12">
								<CompanyAccountsContainer />
							</div>
						</div>
					</ScrollView>
				</div>
			</div>
		);
	};
}

function mapStoreToProps(store) {
	return {
		userCompanies: store.company.companies,
		company: store.company.company,
	};
}

const mapActionsToProps = {
	resetCompany,
};
export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(routeHoc(DashboardContainer, { routeName: LOCATION_DASHBOARD }));
