import React from "react";
import immutablePropTypes from "react-immutable-proptypes";
import { func, object } from "prop-types";
import ListItem from "../list-item/list-item";
import Text from "../text/text";
import { FixedSizeList as List } from "react-window";
import ListPanelItem from "../list-panel/list-panel-item";

import { calculateListheight } from "../../../components/helpers/react-window.helper";

//import { FixedSizeList as List } from 'react-window'

const PublishedDocumentSharedList = ({
	setListRef,
	scrollbarRef,
	sharedWithPeopleList,
}) => {
	const renderEmpty = () => {
		return (
			<ListItem>
				<Text tid="meetings.protocol.shared_with_list.modal.no_receivers" />
			</ListItem>
		);
	};

	const RenderSharedWithListItemName = ({ index, style }) => {
		const sharedWithName = sharedWithPeopleList.get(index);

		return (
			<div style={style}>
				<ListPanelItem
					name={sharedWithName}
					status={true}
					key={index}
					sharedWithList
				/>
			</div>
		);
	};

	const itemHeight = 50;
	const numOfItems = sharedWithPeopleList && sharedWithPeopleList.size;
	const listHeight = calculateListheight({
		numOfItems,
		itemHeight,
		scrollbarRef,
	});

	return (
		<>
			{!sharedWithPeopleList || sharedWithPeopleList.size === 0 ? (
				renderEmpty()
			) : (
				<List
					height={listHeight}
					itemCount={numOfItems}
					itemSize={itemHeight}
					ref={setListRef}
					style={{ overflow: "visible" }}
				>
					{RenderSharedWithListItemName}
				</List>
			)}
		</>
	);
};

PublishedDocumentSharedList.propTypes = {
	setListRef: func,
	scrollbarRef: object,
	sharedWithPeopleList: immutablePropTypes.list,
};

export default PublishedDocumentSharedList;
