import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AddPaymentMethodModal, {
	AddPaymentMethodButton,
} from "./addPaymentMethodModal";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent } from "@/components/ui/card";
import { Trash2 } from "lucide-react";

import usePaymentMethods from "@/hooks/usePaymentMethods";
import useSubscription from "@/hooks/useSubscription";
import useUpdateStripeCustomer from "@/hooks/useUpdateStripeCustomer";
import useDeletePaymentMethod from "@/hooks/useDeletePaymentMethod";
import useQueryParams from "../../hooks/useQueryParams";
import useDialogModal from "@/hooks/useDialogModal";

import iconVisa from "@/assets/images/payment-providers/visa.svg";
import iconMastercard from "@/assets/images/payment-providers/mastercard.svg";
import iconAmex from "@/assets/images/payment-providers/amex.svg";
import iconDiners from "@/assets/images/payment-providers/diners.svg";
import iconJcb from "@/assets/images/payment-providers/jcb.svg";
import iconDiscover from "@/assets/images/payment-providers/discover.svg";
import iconUnknown from "@/assets/images/payment-providers/unknown.svg";
import useCompanyIdFromUrl from "@/hooks/useCompanyIdFromUrl";
import LoadingPanel from "../loadingPanel";

const PaymentMethods = ({ autoOpenAddPaymentMethod }) => {
	const companyId = useCompanyIdFromUrl();
	const { t } = useTranslation();
	const [isAddPaymentDetailsOpen, setIsAddPaymentDetailsOpen] = useState(false);
	const { confirm, dialogElement } = useDialogModal();

	const { data: paymentMethodsRequest, isLoading } = usePaymentMethods();

	const paymentMethods = paymentMethodsRequest?.data;

	const {
		mutate: updateStripeCustomer,
		isPending: isUpdatingCustomerPending,
		variables: updatingCustomerVariables,
	} = useUpdateStripeCustomer(companyId);

	const {
		mutate: deletePaymentMethod,
		isPending: isDeletingPaymentMethod,
		variables: deletePaymentMethodId,
	} = useDeletePaymentMethod(companyId);

	const { data: subscriptionRequest } = useSubscription();

	const [getQueryParam, setQueryParam] = useQueryParams();

	const setupIntentQueryParam = getQueryParam("setup_intent");
	const setupIntentClientSecretQueryParam = getQueryParam(
		"setup_intent_client_secret",
	);
	const redirectStatusQueryParam = getQueryParam("redirect_status");

	const paymentMethodWasAdded =
		setupIntentQueryParam &&
		setupIntentClientSecretQueryParam &&
		redirectStatusQueryParam === "succeeded";

	// Auto open the add payment method modal
	useEffect(() => {
		if (autoOpenAddPaymentMethod && paymentMethods?.length === 0) {
			setIsAddPaymentDetailsOpen(true);
		}
	}, [autoOpenAddPaymentMethod, paymentMethods?.length]);

	// Auto select the latest payment method as default
	useEffect(() => {
		if (paymentMethods && paymentMethodWasAdded) {
			setQueryParam("setup_intent", null);
			setQueryParam("setup_intent_client_secret", null);
			setQueryParam("redirect_status", null);

			const latestPaymentMethod = paymentMethods[0];

			updateStripeCustomer({
				invoice_settings: {
					default_payment_method: latestPaymentMethod.id,
				},
			});
		}
	}, [paymentMethodWasAdded, paymentMethods]);

	const onDeletePaymentMethod = async (paymentMethodId) => {
		if (
			await confirm(
				t("subscriptions.payment_methods.delete_payment_method"),
				t("subscriptions.payment_methods.confirm_delete.body"),
				{
					submitText: t("subscriptions.payment_methods.delete_payment_method"),
					variant: "destructive",
				},
			)
		) {
			deletePaymentMethod(paymentMethodId);
		}
	};

	if (isLoading) return <LoadingPanel />;

	return (
		<>
			<div className="flex gap-4 flex-col">
				{paymentMethods?.length > 0 && (
					<div className="flex gap-4 flex-col">
						{paymentMethods?.map((paymentMethod) => {
							const { card } = paymentMethod;

							const isDefault =
								paymentMethod.id ===
								subscriptionRequest.data?.stripeData?.invoice_settings
									?.default_payment_method;

							const iconUrl =
								{
									visa: iconVisa,
									mastercard: iconMastercard,
									amex: iconAmex,
									diners: iconDiners,
									jcb: iconJcb,
									discover: iconDiscover,
								}[card?.brand?.toLowerCase()] || iconUnknown;

							return (
								<div key={paymentMethod.id}>
									<Card isHighlighted={isDefault}>
										<CardContent className="p-4">
											<div className="flex gap-4">
												<div className="flex items-center">
													<img
														className="rounded-md"
														src={iconUrl}
														width={40}
													/>
												</div>
												<div className="flex-1">
													<div>
														<strong>**** **** **** {card.last4}</strong>
													</div>
													<div>
														{t(
															"subscriptions.payment_methods.card_expiration",
															{ month: card.exp_month, year: card.exp_year },
														)}
													</div>
												</div>
												<div className="flex items-center justify-center">
													{isDefault ? (
														<Badge>
															{t("subscriptions.payment_methods.default")}
														</Badge>
													) : (
														<Button
															variant="link"
															onClick={() =>
																updateStripeCustomer({
																	invoice_settings: {
																		default_payment_method: paymentMethod.id,
																	},
																})
															}
															isLoading={
																isUpdatingCustomerPending &&
																updatingCustomerVariables?.invoice_settings
																	?.default_payment_method === paymentMethod.id
															}
														>
															{t("subscriptions.payment_methods.set_default")}
														</Button>
													)}
												</div>
												<div className="flex items-center justify-end">
													<Button
														variant="ghost"
														onClick={() =>
															onDeletePaymentMethod(paymentMethod.id)
														}
														isLoading={
															isDeletingPaymentMethod &&
															deletePaymentMethodId === paymentMethod.id
														}
													>
														<Trash2 size={20} />
													</Button>
												</div>
											</div>
										</CardContent>
									</Card>
								</div>
							);
						})}
					</div>
				)}
				<AddPaymentMethodButton
					onClick={() => setIsAddPaymentDetailsOpen(true)}
				/>
				{isAddPaymentDetailsOpen && (
					<AddPaymentMethodModal
						onCancel={() => setIsAddPaymentDetailsOpen(false)}
					/>
				)}
			</div>
			{dialogElement}
		</>
	);
};

export default PaymentMethods;
