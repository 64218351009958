import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import history from "../../../../interfaces/history";
import { makeInternalLink } from "../../../../components/helpers/link.helper";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../actions/modals.actions";

const OpenInvestmentButtonRenderer = (props) => {
	const dispatch = useDispatch();

	const onOpen = useCallback(() => {
		history.push(makeInternalLink(props.data.link));
		dispatch(closeModal());
	}, [history]);

	return (
		<button type="button" onClick={onOpen}>
			<FormattedMessage id="investments.portfolio.grid.row_button.open_investment_button.label" />
		</button>
	);
};

export default OpenInvestmentButtonRenderer;
