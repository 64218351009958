import React from "react";
import {
	string,
	number,
	object,
	node,
	oneOf,
	oneOfType,
	bool,
	func,
} from "prop-types";
import styled, { css } from "styled-components";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { darken } from "polished";

const StyledElement = styled.span`
	font-size: ${(props) =>
		props.fontSize ||
		props.theme.fontSizes[props.tag] ||
		props.theme.fontSizes.default};
	line-height: ${(props) =>
		props.lineHeight ||
		props.theme.lineHeights[props.tag] ||
		props.theme.lineHeights.default};
	color: ${(props) => props.theme.colors[props.color]};
	font-weight: ${(props) =>
		props.bold || props.theme.bold[props.tag] || props.theme.bold.default};
	text-transform: ${(props) => props.textTransform};
	margin-top: ${(props) =>
		props.theme.margins[props.tag]
			? props.theme.margins[props.tag].top
			: props.theme.margins.default.top};
	margin-bottom: ${(props) =>
		props.marginBottom
			? props.theme.spacing[props.marginBottom]
			: props.theme.margins[props.tag]
			? props.theme.margins[props.tag].bottom
			: props.theme.margins.default.bottom};
	text-decoration: "none";
	font-style: ${(props) => (props.hasItalic ? "italic" : "normal")};
	text-decoration-color: ${(props) => props.theme.colors[props.color]};
	text-align: ${(props) => props.align};
	white-space: ${(props) => (props.nowrap ? "nowrap" : "initial")};

	${(props) =>
		props.onClick &&
		css`
			cursor: pointer;

			:hover {
				text-decoration: underline;
				color: ${(props) => darken(0.05, props.theme.colors[props.color])};
			}
		`}

	${(props) =>
		props.hasUnderline &&
		css`
			text-decoration: underline;
		`}

	${(props) =>
		props.hasLinethrough &&
		css`
			text-decoration: line-through;
		`}


	${(props) =>
		props.importantColor &&
		css`
			* {
				color: ${(props) => props.theme.colors[props.color]} !important;
			}
		`}

	${(props) =>
		props.maxRows &&
		css`
			display: -webkit-box;
			-webkit-line-clamp: ${props.maxRows};
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		`}

	${(props) =>
		props.singleLine &&
		css`
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		`}

	${(props) =>
		props.as === "em" &&
		css`
			* {
				font-style: italic;
			}
		`}
`;

const Text = ({
	className,
	onClick,
	hasItalic,
	title,
	children,
	color,
	bold,
	fontSize,
	lineHeight,
	textTransform,
	tag,
	tid,
	values,
	hasUnderline,
	align,
	maxRows,
	singleLine,
	nowrap,
	setRef,
	marginBottom,
	asHtml,
	importantColor,
	hasLinethrough,
}) => {
	return (
		<StyledElement
			className={className}
			onClick={onClick}
			as={tag}
			title={title}
			color={color}
			bold={bold}
			hasUnderline={hasUnderline}
			hasItalic={hasItalic}
			fontSize={fontSize}
			lineHeight={lineHeight}
			tag={tag}
			textTransform={textTransform}
			align={align}
			maxRows={maxRows}
			singleLine={singleLine}
			nowrap={nowrap}
			ref={setRef}
			hasLinethrough={hasLinethrough}
			marginBottom={marginBottom}
			importantColor={importantColor}
		>
			{tid && !asHtml && <FormattedMessage id={tid} values={values} />}
			{tid && asHtml && <FormattedHTMLMessage id={tid} values={values} />}
			{!tid && children && asHtml && (
				<div dangerouslySetInnerHTML={{ __html: children }} />
			)}
			{!tid && !asHtml && children}
		</StyledElement>
	);
};

Text.defaultProps = {
	tag: "span",
	color: "midGrey",
	textTransform: "none",
	hasUnderline: false,
	align: "left",
	singleLine: false,
	nowrap: false,
	asHtml: false,
};

Text.propTypes = {
	values: object,
	bold: oneOf([300, 400, 600, 700]),
	color: oneOf([
		"lightestGrey",
		"green",
		"muted",
		"persianRed",
		"disabled",
		"midGrey",
		"catalinaBlue",
		"white",
		"lightGrey",
		"black",
		"darkGrey",
		"java",
		"placeholder",
		"red",
		"yellowMid",
		"dodgerBlue",
		"headerGrey",
		"darkHeaderGrey",
	]),
	tid: string,
	children: node,
	tag: oneOf(["span", "div", "p", "h1", "h2", "h3", "h4", "em"]),
	fontSize: string,
	lineHeight: oneOfType([string, number]),
	textTransform: oneOf(["uppercase", "lowercase", "none", "capitalize"]),
	hasUnderline: bool,
	align: oneOf(["left", "center", "right"]),
	maxRows: number,
	singleLine: bool,
	nowrap: bool,
	setRef: func,
	marginBottom: number,
	asHtml: bool,
	importantColor: bool,
};

export default Text;
