export const numberFormats = {
	SEK: {
		style: "currency",
		currency: "SEK",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	},
	integer: {
		style: "decimal",
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	},
	quota: {
		style: "decimal",
		minimumFractionDigits: 0,
		maximumFractionDigits: 4,
	},
	percentage: {
		style: "percent",
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	},
	filesize: {
		style: "decimal",
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	},
};
