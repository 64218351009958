import React, { PureComponent } from "react";
import { bool, func, node } from "prop-types";
import styled from "styled-components";
import { map, list } from "react-immutable-proptypes";

import Modal from "../../shared/modal/modal";
import Text from "../../shared/text/text";
import Label from "../../shared/label/label";
import Input from "../../shared/input/input";
import Tooltip from "../../shared/tooltip/tooltip";
import { MultiSelect } from "../../shared/select";
import ColoredContentWrapper from "../../shared/colored-content-wrapper/colored-content-wrapper";

const StyledUserEditWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const StyledNameInputWrapper = styled.div`
	margin-bottom: 16px;
`;

const StyledEmailInputWrapper = styled.div``;

const StyledLinkCodeWrapper = styled.div``;

const StyledMultiSelectWrapper = styled.div`
	margin-bottom: 16px;
`;

const StyledTextWrapper = styled.div`
	margin-top: 16px;
`;

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

export default class UserManageModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		onChange: func,
		user: map,
		hasError: map,
		rolesOptions: list,
		isLoading: bool,
		renderFooter: node,
	};

	renderContent = () => {
		const { onChange, user, rolesOptions, hasError, isLoading } = this.props;
		const userIsActive = user.get("id") ? true : false;

		return (
			<StyledUserEditWrapper>
				<StyledColoredContentWrapper
					type={userIsActive ? "disabledLightGrey" : "secondary"}
				>
					<Label tid="generic.name">
						<Tooltip
							delayShow="instant"
							tid={
								userIsActive &&
								"user_management.user_edit_modal.profile.muted_tooltip"
							}
						>
							<StyledNameInputWrapper>
								<Input
									fieldName="name"
									placeholderTid="generic.name_placeholder"
									onChange={onChange}
									hasError={hasError.get("name")}
									value={user.get("name", "")}
									disabled={isLoading || userIsActive}
								/>
							</StyledNameInputWrapper>
						</Tooltip>
					</Label>
					<Label tid="generic.email">
						<Tooltip
							delayShow="instant"
							tid={
								userIsActive &&
								"user_management.user_edit_modal.profile.muted_tooltip"
							}
						>
							<StyledEmailInputWrapper>
								<Input
									fieldName="email"
									placeholderTid="generic.email"
									onChange={onChange}
									hasError={hasError.get("email")}
									value={user.get("email", "")}
									disabled={isLoading || userIsActive}
								/>
							</StyledEmailInputWrapper>
						</Tooltip>
					</Label>
					{userIsActive && (
						<StyledTextWrapper>
							<Text tid="user_management.user_edit_modal.profile.muted_info_text" />
						</StyledTextWrapper>
					)}
				</StyledColoredContentWrapper>

				<StyledColoredContentWrapper
					type={userIsActive ? "disabledLightGrey" : "secondary"}
				>
					<Label tid="generic.invono_id">
						<Tooltip
							delayShow="instant"
							tid={
								userIsActive &&
								"user_management.user_edit_modal.profile.invono_id.muted_tooltip"
							}
						>
							<StyledLinkCodeWrapper>
								<Input
									fieldName="linkCode"
									placeholderTid="generic.invono_id"
									onChange={onChange}
									hasError={hasError.get("linkCode")}
									value={user.get("linkCode", "")}
									disabled={isLoading || userIsActive}
								/>
							</StyledLinkCodeWrapper>
						</Tooltip>
					</Label>
					{!userIsActive ? (
						<StyledTextWrapper>
							<Text tid="user_management.user_edit_modal.profile.invono_id.different_sign_up_email.info_text" />
						</StyledTextWrapper>
					) : (
						<StyledTextWrapper>
							<Text tid="user_management.user_edit_modal.profile.invono_id.muted.info_text" />
						</StyledTextWrapper>
					)}
				</StyledColoredContentWrapper>

				<StyledColoredContentWrapper type="secondary">
					<Label tid="user_management.teams">
						<StyledMultiSelectWrapper>
							<MultiSelect
								fieldName="userRoles"
								placeholderTid="user_management.teams"
								onChange={onChange}
								options={rolesOptions}
								value={user.get("userRoles")}
								isDisabled={isLoading}
								menuPortalTarget={document.body}
							/>
						</StyledMultiSelectWrapper>
					</Label>
					<Text tid="user_management.user_create_modal.teams" />
				</StyledColoredContentWrapper>
			</StyledUserEditWrapper>
		);
	};

	render = () => {
		const { isOpen, renderFooter } = this.props;
		return (
			<Modal
				isOpen={isOpen}
				title="company.management.edit_user"
				footerComponent={renderFooter}
				hSize="md"
				vSize={75}
			>
				{this.renderContent()}
			</Modal>
		);
	};
}
