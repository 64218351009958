import { printCanvas } from "../../components/helpers/html-helper";

export function byWhatNumberShouldNavigatorShow(data, amount) {
	return data.length >= amount;
}

export function showHowManyHundreds(data, hundreds) {
	return 1 - (100 / data.length) * hundreds;
}

export function exportAsPNG(ref, filename) {
	printCanvas({ ref, filename });
}

export function removeIndexFromString(string) {
	const completeString = string.length;
	const charsToRemove = string.split(" ")[0].length;
	return string.substring(charsToRemove, completeString);
}

export function limitNameLength(name, characters) {
	if (name.length > characters) {
		return `${name.substr(0, characters)} ...`;
	} else {
		return name;
	}
}
