import React from "react";
import {
	Router,
	Route,
	Redirect,
	Switch,
	Link,
	withRouter,
	NavLink,
} from "react-router-dom";
import history from "./history";

export { Router, Route, Redirect, Switch, Link, NavLink, withRouter };

// HoCs
import ValidateAuth from "../containers/router-hoc/validate-auth.hoc";
import TrackRoute from "../containers/router-hoc/location-tracker.hoc";
export { default as fetchCompanyHoc } from "../public/containers/router-hoc/fetch-company.hoc";

export const routeHoc = (ComposedComponent, options = {}) => {
	return ValidateAuth(TrackRoute(ComposedComponent, options.routeName));
};

export const renderComponent = (ComposedComponent, props) => {
	return <ComposedComponent {...props} />;
};

export const routeIsActive = (route) => {
	return route === history.location.pathname;
};
