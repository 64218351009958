import React, { useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import styled from "@mui/styled-engine";

import { useTranslation } from "react-i18next";
import { object, string } from "prop-types";
import {
	PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
	PERSONAL_TRANSACTION_TYPE_LEVELING,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
	PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
	PERSONAL_TRANSACTION_TYPE_SPLIT,
	PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
	PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES,
	PERSONAL_TRANSACTION_TYPE_SNAPSHOT,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
	PERSONAL_TRANSACTION_TYPE_GENESIS,
	NUMBER_FORMAT_INTEGER,
} from "/shared/constants";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import Button from "@mui/material/Button";
import { InvestmentsContext } from "../../../containers/investments/investment.container";
import { linkedTransactionPopulateInitialValues } from "../../../components/helpers/my-records-helpers";
import { useForm } from "react-final-form";
import Tooltip from "../../../mui-components/tooltip/tooltip";
import { DateTime } from "luxon";
import { FormattedHTMLMessage } from "react-intl";
import { useFieldTranslation } from "./hooks/useFieldTranslation";

const StyledGreyBox = styled(Box)(({ theme }) => ({
	display: "flex",
	backgroundColor: theme.palette.solitude.midLight,
	padding: theme.spacing(4),
	gap: theme.spacing(2),
	borderRadius: "8px",
}));

export default function ReadOnlyPreview({
	transactionType,
	fieldData,
	transactionProps,
}) {
	const { selectedInvestment } = useContext(InvestmentsContext);
	const form = useForm();

	const { t } = useTranslation();
	const { translateFieldLabel } = useFieldTranslation();

	const onClickLinkTransaction = () => {
		const selectedInvestmentTransactions =
			selectedInvestment.get("transactions");
		const userId = selectedInvestment.get("id");
		const { data } = transactionProps;
		const { linkButton } = data;
		const { link, id } = linkButton;
		const { isDirty } = link;

		const newLinkButton = {
			id: id,
			link: {
				transactionId: id,
				isDirty: isDirty,
			},
		};

		linkedTransactionPopulateInitialValues(
			newLinkButton,
			form,
			selectedInvestmentTransactions,
			userId,
		);

		// This check seems to be necessary to make form dirty. Which is used to define if save button should be muted or not
		if (!form.getFieldState("link")) {
			form.registerField("link", (fieldState) => {
				const { change } = fieldState;

				change({
					transactionId: form.getState().values.link.transactionId,
					isDirty: form.getState().values.link.isDirty,
				});
			});
		}

		form.mutators.resetModified();
	};

	return (
		<Box sx={{ display: "flex", gap: 2, flexDirection: "column", flex: 1 }}>
			<StyledGreyBox tabIndex={-1} sx={{ flexDirection: "column" }}>
				<Tooltip
					title={
						"investments.my_records.modal.add_transaction.company_transactions.dropdown.menu_item.link.disabled"
					}
					hideTooltip={
						!form.getState().values?.link?.transactionId ||
						form.getState().values?.link?.transactionId !==
							transactionProps.data?.linkButton?.id ||
						form.getState().values.link.isDirty
					}
				>
					<Button
						variant="primary"
						onClick={
							form.getState().values?.link?.transactionId ===
								transactionProps.data?.linkButton?.id &&
							!form.getState().values.link.isDirty
								? undefined
								: onClickLinkTransaction
						}
						sx={{ flexGrow: 1 }}
						fullWidth
						disabled={
							form.getState().values?.link?.transactionId ===
								transactionProps.data?.linkButton?.id &&
							!form.getState().values.link.isDirty
						}
						component={
							form.getState().values?.link?.transactionId ===
								transactionProps.data?.linkButton?.id &&
							!form.getState().values.link.isDirty
								? "div"
								: undefined
						}
					>
						{t(
							"investments.my_records.add_transaction.company_transactions.button.link",
						)}
					</Button>
				</Tooltip>

				<TextField
					name="date"
					label={translateFieldLabel("date")}
					fullWidth
					sx={{ width: "50%", minWidth: "143px", mt: "15.69px" }}
					disabled
					value={DateTime.fromISO(fieldData.get("date")).toLocaleString()}
				/>
			</StyledGreyBox>

			<StyledGreyBox tabIndex={-1} sx={{ flexDirection: "column" }}>
				<Box>
					<Typography variant="h4">
						{t(
							"own_ownership_registration.yellow_box.title.values_this_transaction",
						)}
					</Typography>
					<Typography variant="body2">
						{t(
							"own_ownership_registration.yellow_box.body.values_this_transaction",
						)}
					</Typography>
				</Box>

				{[
					PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
					PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
					PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
				].includes(transactionType) && (
					<Box display="flex" gap={2}>
						<TextField
							fullWidth
							name={"transferFrom"}
							label={translateFieldLabel("transferFrom", transactionType)}
							disabled
							value={fieldData.get("transferFrom")}
						/>
						<TextField
							fullWidth
							name={"transferTo"}
							label={translateFieldLabel("transferTo", transactionType)}
							disabled
							value={fieldData.get("transferTo")}
						/>
					</Box>
				)}

				{[PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES].includes(
					transactionType,
				) && (
					<>
						<Box display="flex" gap={2}>
							<TextField
								fullWidth
								sx={{ flexGrow: 1 }}
								disabled
								value={fieldData.get("conversionOfSharesNameFrom")}
								name={"conversionOfSharesNameFrom"}
								label={translateFieldLabel(
									"conversionOfSharesNameFrom",
									transactionType,
								)}
							/>

							<TextField
								fullWidth
								sx={{ width: "40%" }}
								disabled
								value={fieldData.get("conversionOfSharesClassFrom")}
								name={"conversionOfSharesClassFrom"}
								label={translateFieldLabel(
									"conversionOfSharesClassFrom",
									transactionType,
								)}
							/>
						</Box>
						<Box display="flex" gap={2}>
							<TextField
								fullWidth
								sx={{ flexGrow: 1 }}
								disabled
								value={fieldData.get("conversionOfSharesNameTo")}
								name={"conversionOfSharesNameTo"}
								label={translateFieldLabel(
									"conversionOfSharesNameTo",
									transactionType,
								)}
							/>
							<TextField
								fullWidth
								sx={{ width: "40%" }}
								disabled
								value={fieldData.get("conversionOfSharesClassTo")}
								name={"conversionOfSharesClassTo"}
								label={translateFieldLabel(
									"conversionOfSharesClassTo",
									transactionType,
								)}
							/>
						</Box>
					</>
				)}

				{[PERSONAL_TRANSACTION_TYPE_SPLIT].includes(transactionType) && (
					<Box display="flex" gap={2}>
						<TextField
							fullWidth
							disabled
							value={fieldData.get("splitQuotaFrom")}
							name={"splitQuotaFrom"}
							label={translateFieldLabel("splitQuotaFrom", transactionType)}
						/>
						<TextField
							fullWidth
							disabled
							value={fieldData.get("splitQuotaTo")}
							name={"splitQuotaTo"}
							label={translateFieldLabel("splitQuotaTo", transactionType)}
						/>
					</Box>
				)}

				{[PERSONAL_TRANSACTION_TYPE_SNAPSHOT].includes(transactionType) && (
					<>
						<TextField
							fullWidth
							disabled
							value={localeFormatNumber(
								fieldData.get("capitalInvested"),
								NUMBER_FORMAT_INTEGER,
							)}
							name={"capitalInvested"}
							label={translateFieldLabel("capitalInvested", transactionType)}
						/>
						<TextField
							fullWidth
							sx={{ mb: -2 }}
							disabled
							value={localeFormatNumber(
								fieldData.get("numOfTotalShares"),
								NUMBER_FORMAT_INTEGER,
							)}
							name={"numOfTotalShares"}
							label={translateFieldLabel("numOfTotalShares", transactionType)}
						/>
					</>
				)}

				<Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
					{fieldData.get("shareTypes") &&
						fieldData.get("shareTypes").map((shareType, index) => {
							return (
								<div
									key={`shareTypes[${index}]`}
									style={{ marginTop: index === 1 ? 52 : 0 }}
								>
									<Box display="flex" gap={2}>
										{[
											PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
											PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
											PERSONAL_TRANSACTION_TYPE_LEVELING,
											PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
											PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
											PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
											PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
											PERSONAL_TRANSACTION_TYPE_SPLIT,
											PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
											PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
											PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
											PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
											PERSONAL_TRANSACTION_TYPE_GENESIS,
										].includes(transactionType) && (
											<>
												<TextField
													fullWidth
													disabled
													value={shareType.get("shareTypeName")}
													name={`shareTypes[${index}].shareTypeName`}
													label={translateFieldLabel(
														"shareTypes.shareTypeName",
														transactionType,
													)}
												/>
												<TextField
													fullWidth
													disabled
													value={shareType.get("shareTypeClass")}
													name={`shareTypes[${index}].shareTypeClass`}
													label={translateFieldLabel(
														"shareTypes.shareTypeClass",
														transactionType,
													)}
												/>
											</>
										)}
									</Box>

									<Box
										display="flex"
										gap={2}
										sx={{ mt: transactionType === "snapshot" ? 0 : 2 }}
									>
										{[
											PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
											PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
											PERSONAL_TRANSACTION_TYPE_LEVELING,
											PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
											PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
											PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
											PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
											PERSONAL_TRANSACTION_TYPE_SPLIT,
											PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
											PERSONAL_TRANSACTION_TYPE_GENESIS,
										].includes(transactionType) && (
											<TextField
												fullWidth
												disabled
												value={localeFormatNumber(
													shareType.get("latestPrice"),
													NUMBER_FORMAT_INTEGER,
												)}
												name={`shareTypes[${index}].latestPrice`}
												label={translateFieldLabel(
													"shareTypes.latestPrice",
													transactionType,
												)}
											/>
										)}

										{[
											PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
											PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
											PERSONAL_TRANSACTION_TYPE_LEVELING,
											PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
											PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
											PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
											PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
											PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
											PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
											PERSONAL_TRANSACTION_TYPE_GENESIS,
										].includes(transactionType) && (
											<TextField
												fullWidth
												disabled
												value={localeFormatNumber(
													shareType.get("votesPerShare"),
													NUMBER_FORMAT_INTEGER,
												)}
												name={`shareTypes[${index}].votesPerShare`}
												label={translateFieldLabel(
													"shareTypes.votesPerShare",
													transactionType,
												)}
											/>
										)}
									</Box>

									{[
										PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
										PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
										PERSONAL_TRANSACTION_TYPE_LEVELING,
										PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
										PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
										PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
										PERSONAL_TRANSACTION_TYPE_GENESIS,
									].includes(transactionType) && (
										<Box display="flex" gap={2} sx={{ mt: 2 }}>
											<TextField
												fullWidth
												disabled
												value={localeFormatNumber(
													shareType.get("totalSharesEmitted"),
													NUMBER_FORMAT_INTEGER,
												)}
												name={`shareTypes[${index}].totalSharesEmitted`}
												label={translateFieldLabel(
													"shareTypes.totalSharesEmitted",
													transactionType,
												)}
											/>
										</Box>
									)}

									{[
										PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
										PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
										PERSONAL_TRANSACTION_TYPE_LEVELING,
										PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
										PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
										PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
										PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
										PERSONAL_TRANSACTION_TYPE_SPLIT,
										PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
										PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
										PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES,
										PERSONAL_TRANSACTION_TYPE_GENESIS,
									].includes(transactionType) && (
										<TextField
											fullWidth
											disabled
											value={localeFormatNumber(
												shareType.get("myShares"),
												NUMBER_FORMAT_INTEGER,
											)}
											sx={{
												mt:
													transactionType ===
													PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES
														? -2
														: transactionType ===
														  PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL
														? 0
														: 2,
											}}
											name={`shareTypes[${index}].myShares`}
											label={translateFieldLabel(
												"shareTypes.myShares",
												transactionType,
											)}
										/>
									)}

									{[
										PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
										PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
										PERSONAL_TRANSACTION_TYPE_SPLIT,
									].includes(transactionType) && (
										<TextField
											fullWidth
											disabled
											value={localeFormatNumber(
												shareType.get("purchasePrice"),
												NUMBER_FORMAT_INTEGER,
											)}
											name={`shareTypes[${index}].purchasePrice`}
											label={translateFieldLabel(
												"shareTypes.purchasePrice",
												transactionType,
											)}
											sx={{
												mt: 2,
											}}
										/>
									)}
								</div>
							);
						})}
				</Box>
			</StyledGreyBox>

			{[
				PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
				PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
				PERSONAL_TRANSACTION_TYPE_LEVELING,
				PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
				PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
				PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
				PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
				PERSONAL_TRANSACTION_TYPE_SPLIT,
				PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
				PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
				PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
				PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES,
				PERSONAL_TRANSACTION_TYPE_SNAPSHOT,
				PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
				PERSONAL_TRANSACTION_TYPE_GENESIS,
			].includes(transactionType) && (
				<StyledGreyBox tabIndex={-1} sx={{ mt: "auto" }}>
					<Grid container flex columns={2} spacing={2}>
						<Grid item xs={2}>
							<Typography variant="h4">
								{t(
									"own_ownership_registration.yellow_box.title.values_after_this_transaction",
								)}
							</Typography>
							<Typography variant="body2">
								{t(
									"own_ownership_registration.yellow_box.body.values_after_this_transaction",
								)}
							</Typography>
						</Grid>

						<Grid item xs={2}>
							<Typography variant="body2">
								<FormattedHTMLMessage id="own_ownership_registration.form.current_value_title" />
							</Typography>
						</Grid>

						<Grid item xs={2}>
							<TextField
								fullWidth
								disabled
								value={localeFormatNumber(
									fieldData.get("numOfTotalCompanyShares"),
									NUMBER_FORMAT_INTEGER,
								)}
								name="numOfTotalCompanyShares"
								label={translateFieldLabel(
									"numOfTotalCompanyShares",
									transactionType,
								)}
							/>
						</Grid>

						<Grid item xs={2}>
							<TextField
								fullWidth
								disabled
								value={localeFormatNumber(
									fieldData.get("totalVotes"),
									NUMBER_FORMAT_INTEGER,
								)}
								name="totalVotes"
								label={translateFieldLabel("totalVotes", transactionType)}
							/>
						</Grid>

						<Grid item xs={2}>
							<TextField
								fullWidth
								disabled
								value={localeFormatNumber(
									fieldData.get("myTotalVotes"),
									NUMBER_FORMAT_INTEGER,
								)}
								name="myTotalVotes"
								label={translateFieldLabel("myTotalVotes", transactionType)}
							/>
						</Grid>

						<Grid item xs={2}>
							<TextField
								fullWidth
								disabled
								value={localeFormatNumber(
									fieldData.get("companyTotalValue"),
									NUMBER_FORMAT_INTEGER,
								)}
								name="companyTotalValue"
								label={translateFieldLabel(
									"companyTotalValue",
									transactionType,
								)}
							/>
						</Grid>

						<Grid item xs={2}>
							<TextField
								fullWidth
								disabled
								value={localeFormatNumber(
									fieldData.get("myTotalValue"),
									NUMBER_FORMAT_INTEGER,
								)}
								name="myTotalValue"
								label={translateFieldLabel("myTotalValue", transactionType)}
							/>
						</Grid>
					</Grid>
				</StyledGreyBox>
			)}
		</Box>
	);
}

ReadOnlyPreview.propTypes = {
	transactionType: string,
	fieldData: object,
};

ReadOnlyPreview.defaultProps = {};
