import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import MuiSelect from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import MuiTextField from "@mui/material/TextField";
import { DatePicker, TextField, Select } from "mui-rff";
import { Field, Form } from "react-final-form";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import * as parsers from "../../../../modules/field-parsers";
import { summarize } from "../../utils/calculations";
import { useTranslation } from "react-i18next";
import {
	deleteDocument,
	generateBoardSuggestionPdf,
	generatePdfFromEmission,
	saveEmission,
	setPreparationError,
	updateEmissionStatus,
	uploadDocument,
} from "../../../../actions/emissions.actions";
import MuiAutocomplete, {
	createFilterOptions,
} from "@mui/material/Autocomplete";
import { useShareType } from "./share-type-hook";

import IconButton from "@mui/material/IconButton";
import Spinner from "../../common/full-page-spinner";
import { emissionConsts } from "../../../../constants/emissions";
import FormAccordionEntry from "../../common/form-accordion-entry";
import { DateTime } from "luxon";
import AutoSave from "../../forms/AutoSave";
import CustomSelect from "../../forms/CustomSelect";
import WhiteSection from "../../common/white-section";
import PdfDownloadWarningModal from "./pdf-download-warning-modal";
import LocaleNumberFormat from "../../common/locale-number-format";
import { downloadDocument } from "../../../../actions/documents.actions";
import { Link, useHistory, useParams } from "react-router-dom";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import {
	NUMBER_FORMAT_CURRENCY,
	NUMBER_FORMAT_INTEGER,
} from "/shared/constants.json";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { emissionIsReadOnly } from "../../utils/emission-status-helper";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ThemeProvider } from "@mui/material/styles";
import { MUItheme } from "../../../../../MUItheme";
import Tooltip from "../../../../mui-components/tooltip/tooltip";
import { fetchCompanyUsers } from "../../../../actions/company.actions";
import { getEmail } from "/shared/helpers/users.helpers";
import { buttonStyles } from "../custom-theme";

import { getOnlyUppercaseAndLetters } from "../../../../components/helpers/shares";

const {
	documentTypes,
	decisionTypes,
	shareDisclaimers,
	newShareCountTypes,
	exceedingAmountTypes,
	allocationTypes,
	emissionTypes,
	emissionStatus,
} = emissionConsts.common;
const { preparations } = emissionConsts.tabs;

const filter = createFilterOptions();

const CalculatedField = (props) => {
	const theme = useTheme();

	return (
		<MuiTextField
			variant="standard"
			margin="dense"
			fullWidth
			value={props.value}
			sx={{
				"& .MuiInput-underline:before": {
					borderBottomColor: theme.palette.divider + " !important",
				},
				"& .MuiInput-underline": {
					pb: 1,
				},
			}}
			inputProps={{
				style: {
					textAlign: "right",
				},
				sx: { cursor: "default" },
			}}
			InputProps={{
				readOnly: true,

				sx: { cursor: "default" },
				startAdornment: (
					<InputAdornment position="start">{props.label}</InputAdornment>
				),
				endAdornment: (
					<InputAdornment position="end">{props.unit}</InputAdornment>
				),
			}}
		/>
	);
};

const shareTypeConsts = emissionConsts.common.shareTypes;
const getShareTypeLangKey = (name) => {
	return (
		{
			[shareTypeConsts.ordinaryShare]: "share_class.ordinary%20share",
			[shareTypeConsts.preferenceShare]: "share_class.preference%20share",
			[shareTypeConsts.nonDividendShare]: "share_class.non-dividend%20share",
		}[name] || name
	);
};

const Preparations = (props) => {
	const [_, setRerenderDate] = useState(new Date());
	const { t } = useTranslation();
	const history = useHistory();
	const { company } = useParams();
	const companyMembers = useSelector((state) => state.company.members);
	const userId = useSelector((state) => state.user.getIn(["userObj", "id"]));
	const emission = useSelector((state) => state.emissions.current);
	const [calculations, setCalculations] = useState(summarize(emission));
	const st = useShareType(emission);
	const documentMap = useSelector((state) => state.emissions.documentMap);
	const basicShareData = useSelector((state) => state.emissions.basicShareData);
	const [pdfDownloadWarningOpen, setPdfDownloadWarningOpen] = useState(false);

	const parsedValues = {
		...emission,
		subscriptionStartDate: emission.subscriptionStartDate
			? DateTime.fromISO(emission.subscriptionStartDate)
			: null,
		subscriptionEndDate: emission.subscriptionEndDate
			? DateTime.fromISO(emission.subscriptionEndDate)
			: null,
		paymentDueDate: emission.paymentDueDate
			? DateTime.fromISO(emission.paymentDueDate)
			: null,
		proRataDueDate: emission.proRataDueDate
			? DateTime.fromISO(emission.proRataDueDate)
			: null,
		createdDate: DateTime.fromISO(emission.createdDate),
		emissionContact: emission.emissionContact ?? userId,
	};
	const overSubscriptionType = emissionConsts.common.overSubscriptionType;
	const dispatch = useDispatch();
	const PdfDownloadChoice = {
		None: "none",
		Table: "table",
		BoardSuggestion: "board-suggestion",
	};
	const [pdfChoice, setPdfChoice] = useState(PdfDownloadChoice.None);

	const emissionContactUser = companyMembers?.find(
		(member) => member.get("id") === emission.emissionContact ?? userId,
	);

	const endDateRef = useRef();
	const startDateRef = useRef();
	const proRataDateRef = useRef();
	const paymentDueDateRef = useRef();

	const preventChanges = emissionIsReadOnly(emission, preparations);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handlePdfMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handlePdfMenuClose = () => {
		setAnchorEl(null);
	};

	const hasError = () => {
		const error_newShareCountMin = (values) => {
			if (values.newShareCountType === newShareCountTypes.highest) {
				return false;
			}
			return (
				!emission.newShareCountMin ||
				emission.newShareCountMin <= 0 ||
				(values.newShareCountType === newShareCountTypes.range &&
					emission.newShareCountMin > emission.newShareCountMax)
			);
		};

		const error_newShareCountMax = (values) => {
			return (
				!emission.newShareCountMax ||
				emission.newShareCountMax <= 0 ||
				(values.newShareCountType === newShareCountTypes.range &&
					emission.newShareCountMin > emission.newShareCountMax)
			);
		};

		const error_pricePerShare = (values) => {
			return !values.pricePerShare || values.pricePerShare < values.quoteValue;
		};

		return [
			Object.keys(validate(parsedValues)).length > 0,
			error_newShareCountMin(parsedValues),
			error_newShareCountMax(parsedValues),
			error_pricePerShare(parsedValues),
		].some(Boolean);
	};

	const errored = hasError();

	function onCustomShareType(form) {
		form.change("isCustomShareType", true);
		form.change("shareTypeName", null);
		form.change("shareTypeSeries", null);
		form.change("shareTypeVotes", null);
		form.change("shareTypeDisclaimers", []);
		st.setShareType("");
		setRerenderDate(new Date());
	}

	function onExistingShareType(form) {
		form.change("isCustomShareType", false);
		form.change("shareTypeName", null);
		form.change("shareTypeSeries", null);
		form.change("shareTypeVotes", null);
		form.change("shareTypeDisclaimers", []);
		st.setShareType("");
		setRerenderDate(new Date());
	}

	function change(form, key, value) {
		form.change(key, value);
		setRerenderDate(new Date());
	}

	function changeShareType(form, shareType) {
		form.change("isCustomShareType", false);
		form.change("shareTypeName", shareType.name);
		form.change("shareTypeSeries", shareType.series ? shareType.series : "");
		form.change("shareTypeVotes", shareType.votes);
		form.change("shareTypeDisclaimers", shareType.disclaimers);
		setRerenderDate(new Date());
	}

	const [inputValueShareType, setInputValueShareType] = React.useState("");

	const createShareTypeContainer = (form) => {
		const shareTypeOptions = [
			shareTypeConsts.ordinaryShare,
			shareTypeConsts.preferenceShare,
			shareTypeConsts.nonDividendShare,
		];

		const customShareTypes = st.shareTypes.filter((shareType) => {
			const ordinaryShareTypes = Object.values(shareTypeConsts);
			if (
				!ordinaryShareTypes.find(
					(ordinaryShareType) => ordinaryShareType === shareType.name,
				)
			) {
				return shareType;
			}
		});

		if (customShareTypes.length > 0) {
			customShareTypes.forEach((customShareType) => {
				shareTypeOptions.push(customShareType.name);
			});
		}

		const shareTypeSeriesOptions = ["A", "B", "C", "D"];

		const customShareTypeSeries = st.shareTypes.filter((shareType) => {
			if (
				!shareTypeSeriesOptions.find(
					(ordinaryShareTypeSerie) =>
						ordinaryShareTypeSerie === shareType.series,
				)
			) {
				return shareType;
			}
		});

		if (customShareTypeSeries.length > 0) {
			customShareTypeSeries.forEach((customShareTypeSerie) => {
				shareTypeSeriesOptions.push(customShareTypeSerie.series);
			});
		}

		return (
			<Grid container rowSpacing={3} columnSpacing={3}>
				<Grid item xs={12}>
					<Typography>
						{t("emissions.proposal-new-emission.create-share-type")}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<MuiAutocomplete
						label={t("emissions.form-field.share-series")}
						value={
							emission.isCustomShareType ? emission.shareTypeName || "" : ""
						}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								event.preventDefault();
							}
						}}
						onChange={(event, newValue) => {
							event.stopPropagation();
							event.preventDefault();

							if (!emission.isCustomShareType) {
								onCustomShareType(form);
							}

							if (typeof newValue === "string") {
								change(form, "shareTypeName", newValue);

								return;
							}

							if (newValue && newValue.inputValue) {
								change(form, "shareTypeName", newValue.inputValue);
							}
						}}
						filterOptions={(options, params) => {
							const filtered = filter(options, params);

							const { inputValue } = params;
							const isExisting = options.some(
								(option) => inputValue === option,
							);
							if (inputValue !== "" && !isExisting) {
								filtered.push({
									inputValue,
									name: `${t("emissions.add")} "${inputValue}"`,
								});
							}

							return filtered;
						}}
						fullWidth
						selectOnFocus
						handleHomeEndKeys
						id="share-type-name"
						disabled={preventChanges}
						options={shareTypeOptions}
						getOptionLabel={(option) => {
							if (typeof option === "string") {
								return t(getShareTypeLangKey(option));
							}

							if (option.inputValue) {
								return option.inputValue;
							}
						}}
						renderOption={(props, option) => (
							<li {...props}>
								{option.inputValue
									? option.name
									: t(getShareTypeLangKey(option))}
							</li>
						)}
						freeSolo
						renderInput={(params) => (
							<TextField
								{...params}
								label={t("emissions.form-field.share-name")}
								name={"shareTypeName"}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={3}>
					<MuiAutocomplete
						label={t("emissions.form-field.share-name")}
						value={
							emission.isCustomShareType ? emission.shareTypeSeries || "" : ""
						}
						inputValue={inputValueShareType}
						onInputChange={(event, inputValue) => {
							const value = getOnlyUppercaseAndLetters(inputValue) || "";
							setInputValueShareType(value);
						}}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								event.preventDefault();
							}
						}}
						onChange={(event, newValue) => {
							event.stopPropagation();
							event.preventDefault();

							if (!emission.isCustomShareType) {
								onCustomShareType(form);
							}
							if (typeof newValue === "string") {
								change(form, "shareTypeSeries", newValue);

								return;
							}
							if (!newValue) {
								change(form, "shareTypeSeries", "");
							}

							if (newValue && newValue.inputValue) {
								change(form, "shareTypeSeries", newValue.inputValue);
							}
						}}
						filterOptions={(options, params) => {
							const filtered = filter(options, params);

							const { inputValue } = params;
							const isExisting = options.some(
								(option) => inputValue === option,
							);
							if (inputValue !== "" && !isExisting) {
								filtered.push({
									inputValue,
									name: `${t("emissions.add")} "${inputValue}"`,
								});
							}

							return filtered;
						}}
						fullWidth
						selectOnFocus
						handleHomeEndKeys
						id="share-type-series"
						disabled={preventChanges}
						options={shareTypeSeriesOptions}
						getOptionLabel={(option) => {
							if (typeof option === "string") {
								return option;
							}

							if (option.inputValue) {
								return option.inputValue;
							}
						}}
						renderOption={(props, option) => (
							<li {...props}>{option.inputValue ? option.name : option}</li>
						)}
						freeSolo
						renderInput={(params) => (
							<TextField
								{...params}
								label={t("emissions.form-field.share-series")}
								name={"shareTypeSeries"}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						type={"number"}
						label={t("emissions.form-field.votes-per-share")}
						value={
							emission.isCustomShareType ? emission.shareTypeVotes || "" : ""
						}
						fullWidth
						disabled={preventChanges}
						name={"shareTypeVotes"}
						onChange={(e) => {
							if (!emission.isCustomShareType) {
								onCustomShareType(form);
							}

							change(form, "shareTypeVotes", parsers.toFloat(e.target.value));
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<MuiAutocomplete
						multiple
						options={[
							shareDisclaimers.preEmption,
							shareDisclaimers.redemptionDisclaimers,
							shareDisclaimers.redemptionReservation,
							shareDisclaimers.consentDisclaimers,
							shareDisclaimers.conversionDisclaimer,
						]}
						value={
							emission.isCustomShareType ? emission.shareTypeDisclaimers : []
						}
						getOptionLabel={(option) => t(`transfer-limitation.${option}`)}
						disabled={preventChanges}
						onChange={(_, value) => {
							if (!emission.isCustomShareType) {
								onCustomShareType(form);
							}

							change(form, "shareTypeDisclaimers", value);
						}}
						renderInput={(params) => (
							<MuiTextField
								{...params}
								label={t("document.pdf.template.paragraph-heading-reservation")}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant={"body2"}>
						{t("emissions.custom-share-type-explanation")}
					</Typography>
				</Grid>
			</Grid>
		);
	};

	const noShareTypesAvailable = st.shareTypes.length === 0;

	const createShareTypeFinal = (form) =>
		noShareTypesAvailable ? (
			createShareTypeContainer(form)
		) : (
			<>
				<FormControl disabled sx={{ mb: 1 }} fullWidth>
					<InputLabel id="share-type">
						{t("emissions.form-field.share-type")}
					</InputLabel>
					<MuiSelect
						labelId="share-type"
						id="share-type-select"
						label={t("emissions.form-field.share-type")}
						value={st.shareType}
						onChange={() => {}}
					>
						<MenuItem value={null} id={""}>
							{t("emissions.header.create-new-share-type")}
						</MenuItem>
					</MuiSelect>
				</FormControl>
				<Box
					sx={{
						bgcolor: "primary.50",
						p: 2,
						borderRadius: 1,
						position: "relative",
					}}
				>
					<IconButton
						onClick={() => {
							onExistingShareType(form);
						}}
						size={"small"}
						disabled={preventChanges}
						sx={{ position: "absolute", right: 6, top: 5 }}
					>
						<CloseIcon />
					</IconButton>
					{createShareTypeContainer(form)}
				</Box>
			</>
		);

	const shareTypesContent = (form) =>
		emission.isCustomShareType || noShareTypesAvailable ? (
			createShareTypeFinal(form)
		) : (
			<Grid container rowSpacing={3} columnSpacing={3}>
				<Grid item xs={6}>
					<FormControl fullWidth>
						<Select
							disabled={preventChanges}
							labelId="share-type"
							id="share-type-select"
							label={t("emissions.form-field.share-type")}
							value={st.shareType}
							name={"shareTypeName"}
							onChange={(e) => {
								const value = e.target.value;

								if (value === "create-new-share-type") {
									onCustomShareType(form);
									return;
								}

								st.setShareType(value);

								const t = st.shareTypes.find((s) => {
									const stName = s.name ? s.name : "";
									const stSeries = s.series ? s.series : "";
									const combinedName = `${stName} ${stSeries}`.trim();

									if (combinedName === value) {
										return s;
									}
								});

								changeShareType(form, t);
							}}
						>
							{st.shareTypes.map((s) => {
								const stName = s.name ? s.name : "";
								const stSeries = s.series ? s.series : "";
								const combinedName = `${stName} ${stSeries}`.trim();

								const stNameDisplay = s.name
									? t(getShareTypeLangKey(s.name))
									: "";
								const combinedNameDisplay =
									`${stNameDisplay} ${stSeries}`.trim();

								return (
									<MenuItem
										key={combinedName}
										value={combinedName}
										id={combinedName}
									>
										{combinedNameDisplay}
									</MenuItem>
								);
							})}
							<MenuItem value={"create-new-share-type"} id={null}>
								<Box
									sx={{
										color: "primary.500",
										display: "flex",
										alignItems: "center",
									}}
								>
									<AddIcon sx={{ mr: 1 }} />
									{t("emissions.header.create-new-share-type")}
								</Box>
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={6}>
					<MuiTextField
						type={"number"}
						label={t("emissions.form-field.votes-per-share")}
						fullWidth
						disabled
						InputProps={{
							readOnly: true,
						}}
						value={
							emission.isCustomShareType ? "" : emission.shareTypeVotes || ""
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<MuiAutocomplete
						multiple
						readOnly
						disabled
						options={[]}
						value={
							emission.isCustomShareType ? [] : emission.shareTypeDisclaimers
						}
						getOptionLabel={(option) => t(`transfer-limitation.${option}`)}
						renderInput={(params) => (
							<MuiTextField
								{...params}
								label={t("document.pdf.template.paragraph-heading-reservation")}
							/>
						)}
					/>
				</Grid>
			</Grid>
		);

	const getShareCountOptions = (form) => {
		const em = form.getState().values;
		return em.newShareCountType === newShareCountTypes.range
			? {
					colSize: 6,
					maxFieldLabel: "emissions.form-field.new-share-count-max",
					showMinField: true,
			  }
			: {
					colSize: 12,
					maxFieldLabel:
						em.newShareCountType === newShareCountTypes.specific
							? "emissions.form-field.new-share-count-type.specific"
							: "emissions.form-field.new-share-count-type.highest",
					showMinField: false,
			  };
	};

	const save = async (values) => {
		// ! IPS-14468: You should be able to change contact person at any time but this if-statement prevents you from saving the change
		// ! Commented out so that the contact person can be changed. The other form fields are still disabled.
		// if (!preventChanges) {
		let newValues = values;
		if (values.shareTypeSeries === "") {
			newValues = {
				...newValues,
				shareTypeSeries: null,
			};
		}

		await dispatch(saveEmission(newValues));
		// }
	};

	useEffect(() => {
		dispatch(setPreparationError(errored));
		triggerValidationErrorLabels();
		dispatch(fetchCompanyUsers(emission.companyId));
	}, [errored]);

	const triggerValidationErrorLabels = () => {
		[endDateRef, startDateRef, proRataDateRef, paymentDueDateRef].forEach(
			(ref) => {
				if (ref && ref.current?.value) {
					ref.current.focus({
						preventScroll: true,
					});
					ref.current.blur();
				}
			},
		);
	};

	function validate(values) {
		const errors = {};

		if (!values) return errors;

		if (!values.newShareCountType) {
			errors.newShareCountType = t(
				"public.final-submission.field-error-message",
			);
		}

		if (!values.exceedingAmountType) {
			errors.exceedingAmountType = t(
				"public.final-submission.field-error-message",
			);
		}

		if (!values.paymentType) {
			errors.paymentType = t("public.final-submission.field-error-message");
		}

		if (!values.pricePerShare || values.pricePerShare < values.quoteValue) {
			errors.pricePerShare = t(
				"emissions.proposal-new-emission.must-be-same-or-higher-than-current-quota",
			);
		}

		if (!values.allocationType) {
			errors.allocationType = t("public.final-submission.field-error-message");
		}

		if (!values.overSubscriptionType) {
			errors.overSubscriptionType = t(
				"public.final-submission.field-error-message",
			);
		}

		if (
			values.emissionType === emissionTypes.preferential &&
			!values.proRataDueDate
		) {
			errors.proRataDueDate = t("public.final-submission.field-error-message");
		}
		if (emission.isCustomShareType) {
			const { shareTypeName, shareTypeSeries } = values;
			const { shareTypes } = st;
			const preExistingShareType = shareTypes.some((type) => {
				return type.name === shareTypeName && type.series === shareTypeSeries;
			});
			if (preExistingShareType) {
				errors.shareTypeSeries = t(
					"public.final-submission.field-error-message-share-type-exists",
				);
			}
		}

		if (
			values.newShareCountType === newShareCountTypes.specific ||
			values.newShareCountType === newShareCountTypes.highest
		) {
			switch (values.newShareCountMax) {
				case undefined:
				case null:
				case "": {
					errors.newShareCountMax = t(
						"public.final-submission.field-error-message",
					);
					break;
				}
				case 0: {
					errors.newShareCountMax = t("validation.value_gt_zero");
					break;
				}
			}
		}

		if (values.newShareCountType === newShareCountTypes.range) {
			if (emission.newShareCountMin > emission.newShareCountMax) {
				errors.newShareCountMin = t(
					"emissions.proposal-new-emission.must-be-lower-than-max",
				);
			} else if (values.newShareCountMin === 0) {
				errors.newShareCountMin = t("validation.value_gt_zero");
			} else if (!values.newShareCountMin) {
				errors.newShareCountMin = t(
					"public.final-submission.field-error-message",
				);
			}

			if (emission.newShareCountMin > emission.newShareCountMax) {
				errors.newShareCountMax = t(
					"emissions.proposal-new-emission.must-be-higher-than-min",
				);
			} else if (values.newShareCountMax === 0) {
				errors.newShareCountMax = t("validation.value_gt_zero");
			} else if (!values.newShareCountMax) {
				errors.newShareCountMax = t(
					"public.final-submission.field-error-message",
				);
			}
		}

		if (!values.shareTypeName) {
			errors.shareTypeName = t("public.final-submission.field-error-message");
		}

		if (
			values.exceedingAmountType ===
				exceedingAmountTypes.bothSharePremiumReserve &&
			parseInt(
				values.exceedingAmountFreePercentage +
					values.exceedingAmountTiedPercentage,
			) !== 100
		) {
			const text = t("emissions.proposal-new-emission.must-be-100");
			errors.exceedingAmountFreePercentage = text;
			errors.exceedingAmountTiedPercentage = text;
		}

		if (values.subscriptionEndDate < values.subscriptionStartDate) {
			errors.subscriptionEndDate = t(
				"emissions.proposal-new-emission.must-be-after-start-date",
			);
		}

		if (values.paymentDueDate < values.subscriptionEndDate) {
			errors.paymentDueDate = t(
				"emissions.proposal-new-emission.must-be-after-end-date",
			);
		}

		if (
			values.emissionType === emissionTypes.preferential &&
			((values.subscriptionStartDate &&
				values.proRataDueDate < values.subscriptionStartDate) ||
				(values.subscriptionEndDate &&
					values.proRataDueDate > values.subscriptionEndDate))
		) {
			errors.proRataDueDate = t(
				"emissions.proposal-new-emission.proRataDueDate-must-be-between-start-and-end-date",
			);
			errors.subscriptionStartDate = t(
				"emissions.proposal-new-emission.proRataDueDate-must-be-between-start-and-end-date",
			);
			errors.subscriptionEndDate = t(
				"emissions.proposal-new-emission.proRataDueDate-must-be-between-start-and-end-date",
			);
		}

		if (!values.subscriptionStartDate) {
			errors.subscriptionStartDate = t(
				"public.final-submission.field-error-message",
			);
		}

		if (!values.subscriptionEndDate) {
			errors.subscriptionEndDate = t(
				"public.final-submission.field-error-message",
			);
		}

		if (!values.paymentDueDate) {
			errors.paymentDueDate = t("public.final-submission.field-error-message");
		}

		return errors;
	}

	return (
		<>
			<PdfDownloadWarningModal
				open={pdfDownloadWarningOpen}
				close={() => setPdfDownloadWarningOpen(false)}
				onPdfDownloadPicked={() => {
					if (pdfChoice === PdfDownloadChoice.Table) {
						dispatch(generatePdfFromEmission(parsedValues, calculations));
					} else if (pdfChoice === PdfDownloadChoice.BoardSuggestion) {
						dispatch(generateBoardSuggestionPdf(parsedValues, calculations));
					}
					setPdfChoice(PdfDownloadChoice.None);
					setPdfDownloadWarningOpen(false);
				}}
			/>
			<Form
				keepDirtyOnReinitialize={true}
				subscription={{
					valid: true,
				}}
				onSubmit={save}
				initialValues={parsedValues}
				validate={validate}
				validateOnBlur
				render={({ form, handleSubmit }) => {
					const shareCountOptions = getShareCountOptions(form);

					return (
						<>
							<form onSubmit={handleSubmit}>
								<AutoSave
									save={(values) => {
										if (emission.currentShareCount === null) {
											change(
												form,
												"currentShareCount",
												basicShareData?.numOfTotalShares ||
													emission.currentShareCount,
											);
										}
										if (basicShareData?.quotaValue) {
											change(form, "quoteValue", basicShareData?.quotaValue);
										}

										save(values);
									}}
								/>
								<Grid container>
									<Grid item xs={7}>
										<WhiteSection>
											<Typography variant="h5">
												{t("emissions.proposal-new-emission")}
											</Typography>
											<Typography variant="body2" sx={{ pt: 2 }}>
												{t("emissions.proposal-new-emission.desc")}
											</Typography>
											<Typography variant="body2" sx={{ pt: 2 }}>
												{t("emissions.proposal-new-emission.note")}
											</Typography>
											<FormAccordionEntry
												header={t("emissions.header.emission-admin-reference")}
											>
												<Box>
													<Box sx={{ mb: 2 }}>
														<Typography
															sx={{
																display: "inline-block",
																minInlineSize: "150px",
																fontWeight: "bold",
															}}
														>
															{t("emisions.admin-reference.name")}
														</Typography>
														<Typography
															sx={{
																display: "inline-block",
																inlineSize: "auto",
															}}
														>
															{emissionContactUser?.get("name")}
														</Typography>
													</Box>
													<Box sx={{ mb: 2 }}>
														<Typography
															sx={{
																display: "inline-block",
																minInlineSize: "150px",
																fontWeight: "bold",
															}}
														>
															{t("emissions.admin-reference.email")}
														</Typography>
														<Typography
															sx={{
																display: "inline-block",
																inlineSize: "auto",
															}}
														>
															{emissionContactUser
																? getEmail(
																		emissionContactUser.toJS(),
																		emission.companyId,
																  )
																: ""}
														</Typography>
													</Box>
													<Box sx={{ mb: 2 }}>
														<Typography
															sx={{
																display: "inline-block",
																minInlineSize: "150px",
																fontWeight: "bold",
															}}
														>
															{t("emissions.admin-reference.phone")}
														</Typography>
														<Typography
															sx={{
																display: "inline-block",
																inlineSize: "auto",
															}}
														>
															{emissionContactUser?.get("phone")}
														</Typography>
													</Box>
													<Typography variant="body1" sx={{ mb: 2 }}>
														{t("emissions.admin-reference.desc")}
													</Typography>
													<TextField
														name="emissionContact"
														label={t("emissions.admin-reference.label")}
														sx={
															preventChanges
																? {
																		"& .MuiInputBase-input.Mui-disabled": {
																			WebkitTextFillColor: "#BDBDBD",
																		},
																  }
																: {}
														}
														select
														fullWidth
													>
														{companyMembers.map((member) => (
															<MenuItem
																key={member.get("id")}
																value={member.get("id")}
															>
																{member.get("name")}
															</MenuItem>
														))}
													</TextField>
												</Box>
											</FormAccordionEntry>
											<FormAccordionEntry
												header={t("emissions.header.basic-information")}
											>
												<Field name={"isCustomShareType"}>
													{({ input }) => {
														return <input type={"hidden"} {...input} />;
													}}
												</Field>
												<Field name={"shareTypeName"}>
													{({ input }) => {
														return <input type={"hidden"} {...input} />;
													}}
												</Field>
												<Field name={"shareTypeSeries"}>
													{({ input }) => {
														return <input type={"hidden"} {...input} />;
													}}
												</Field>
												<Field name={"shareTypeVotes"}>
													{({ input }) => {
														return <input type={"hidden"} {...input} />;
													}}
												</Field>
												<Field name={"shareTypeDisclaimers"}>
													{({ input }) => {
														return <input type={"hidden"} {...input} />;
													}}
												</Field>
												<Grid container rowSpacing={3} columnSpacing={3}>
													<Grid item xs={12}>
														<LocaleNumberFormat
															{...props}
															value={
																emission.currentShareCount
																	? emission.currentShareCount
																	: basicShareData?.numOfTotalShares
															}
															inputtype={"integer"}
															name="currentShareCount"
															label={
																emission.currentShareCount ===
																	basicShareData?.numOfTotalShares ||
																emission.currentShareCount === null
																	? t(
																			"emissions.form-field.current-share-count.fetched-from-register",
																	  )
																	: basicShareData?.numOfTotalShares
																	? t(
																			"emissions.form-field.current-share-count.share-register",
																			{
																				value: localeFormatNumber(
																					basicShareData?.numOfTotalShares,
																					NUMBER_FORMAT_INTEGER,
																				),
																			},
																	  )
																	: t(
																			"emissions.form-field.current-share-count",
																	  )
															}
															fullWidth
															onValueChange={({ value: v }) => {
																emission.currentShareCount = parseFloat(v);
																change(
																	form,
																	"currentShareCount",
																	emission.currentShareCount,
																);
																setCalculations(
																	summarize(form.getState().values),
																);
															}}
															disabled={preventChanges}
														/>
													</Grid>
													<Grid item xs={12}>
														<CustomSelect
															id="emissionType"
															label={t("emissions.form-field.emission-type")}
															readOnly={preventChanges}
															options={[
																{
																	id: emissionTypes.preferential,
																	text: t(
																		"emissions.form-field.emission-type.options.preferential",
																	),
																},
																{
																	id: emissionTypes.directed,
																	text: t(
																		"emissions.form-field.emission-type.options.directed",
																	),
																},
															]}
														/>
													</Grid>
													{form.getState().values.emissionType ===
														emissionTypes.preferential && (
														<>
															<Grid item xs={12}>
																<DatePicker
																	inputRef={proRataDateRef}
																	disabled={preventChanges}
																	label={t(
																		"emissions.form-field.pro-rata-due-date",
																	)}
																	name="proRataDueDate"
																/>
															</Grid>
															<Box sx={{ width: "100%" }} />
														</>
													)}
													<Grid item xs={12}>
														{shareTypesContent(form)}
													</Grid>
													<Grid item xs={12}>
														<CustomSelect
															readOnly={preventChanges}
															id="newShareCountType"
															label={t(
																"emissions.form-field.new-share-count-type",
															)}
															onItemClick={(item) => {
																const min =
																	item.id === newShareCountTypes.highest
																		? 0
																		: null;

																change(form, "newShareCountMin", min);
																change(form, "newShareCountMax", null);

																setRerenderDate(new Date());
															}}
															options={[
																{
																	id: newShareCountTypes.specific,
																	text: t(
																		"emissions.form-field.new-share-count-type.options.specific",
																	),
																},
																{
																	id: newShareCountTypes.highest,
																	text: t(
																		"emissions.form-field.new-share-count-type.options.highest",
																	),
																},
																{
																	id: newShareCountTypes.range,
																	text: t(
																		"emissions.form-field.new-share-count-type.options.range",
																	),
																},
															]}
														/>
													</Grid>
													{form.getState().values.newShareCountType && (
														<>
															{shareCountOptions.showMinField && (
																<Grid item xs={6}>
																	<LocaleNumberFormat
																		disabled={preventChanges}
																		{...props}
																		value={emission.newShareCountMin}
																		inputtype={"integer"}
																		name="newShareCountMin"
																		label={t(
																			"emissions.form-field.new-share-count-min",
																		)}
																		fullWidth
																		//error={error_newShareCountMin(
																		//	form.getState().values
																		//)}
																		//helperText={helperText_newShareCountMin(
																		//	form.getState().values
																		//)}
																		onValueChange={({ value: v }) => {
																			emission.newShareCountMin = parseFloat(v);
																			change(
																				form,
																				"newShareCountMin",
																				emission.newShareCountMin,
																			);
																			setCalculations(
																				summarize(form.getState().values),
																			);
																		}}
																	/>
																</Grid>
															)}
															<Grid item xs={shareCountOptions.colSize}>
																<LocaleNumberFormat
																	disabled={preventChanges}
																	{...props}
																	value={emission.newShareCountMax}
																	inputtype={"integer"}
																	name="newShareCountMax"
																	label={
																		form.getState().values.newShareCountType ===
																		newShareCountTypes.specific
																			? t(
																					"emissions.form-field.new-share-count",
																			  )
																			: t(
																					"emissions.form-field.new-share-count-max",
																			  )
																	}
																	fullWidth
																	//error={error_newShareCountMax(form.getState().values)}
																	//helperText={helperText_newShareCountMax(
																	//	form.getState().values
																	//)}
																	onValueChange={({ value: v }) => {
																		emission.newShareCountMax = parseFloat(v);
																		change(
																			form,
																			"newShareCountMax",
																			emission.newShareCountMax,
																		);
																		setCalculations(
																			summarize(form.getState().values),
																		);
																	}}
																/>
															</Grid>
														</>
													)}
													<Grid item xs={6}>
														<LocaleNumberFormat
															disabled={preventChanges}
															{...props}
															value={emission.pricePerShare}
															inputtype={"currency"}
															name="pricePerShare"
															//error={error_pricePerShare(form.getState().values)}
															//helperText={helperText_pricePerShare(form.getState().values)}
															label={
																basicShareData?.latestPrice
																	? t(
																			"emissions.form-field.price-per-share.current",
																			{
																				value: localeFormatNumber(
																					basicShareData?.latestPrice,
																					NUMBER_FORMAT_CURRENCY,
																				),
																			},
																	  )
																	: t("emissions.form-field.price-per-share")
															}
															fullWidth
															onValueChange={({ value: v }) => {
																emission.pricePerShare = parseFloat(v);
																change(
																	form,
																	"pricePerShare",
																	emission.pricePerShare,
																);
																setCalculations(
																	summarize(form.getState().values),
																);
															}}
														/>
													</Grid>
													<Grid item xs={6}>
														<LocaleNumberFormat
															disabled={
																basicShareData?.quotaValue || preventChanges
																	? true
																	: false
															}
															{...props}
															value={basicShareData?.quotaValue}
															inputtype={"currency"}
															name="quoteValue"
															label={
																basicShareData?.quotaValue
																	? t(
																			"emissions.form-field.quote-value.current",
																	  )
																	: t("emissions.form-field.quote-value")
															}
															fullWidth
															onValueChange={({ value: v }) => {
																emission.quoteValue = parseFloat(v);
																change(form, "quoteValue", emission.quoteValue);
																setCalculations(
																	summarize(form.getState().values),
																);
															}}
														/>
													</Grid>
													<Grid item xs={12}>
														<CalculatedField
															value={calculations.emissionAmount}
															label={t("emissions.form-field.emission-amount")}
															unit={"SEK"}
														/>
													</Grid>
													<Grid item xs={12}>
														<CalculatedField
															value={calculations.shareCapitalRaise}
															label={t(
																"emissions.form-field.share-capital-raise",
															)}
															unit={"SEK"}
														/>
													</Grid>
													<Grid item xs={12}>
														<CalculatedField
															value={calculations.dilutionEffect}
															label={t("emissions.form-field.dilution-effect")}
															unit={"%"}
														/>
													</Grid>
												</Grid>
											</FormAccordionEntry>
											<FormAccordionEntry
												header={t("emissions.header.exceeding-amount")}
											>
												<Grid container rowSpacing={3} columnSpacing={3}>
													<Grid item xs={12}>
														<CustomSelect
															readOnly={preventChanges}
															id="exceedingAmountType"
															label={t(
																"emissions.form-field.exceeding-amount-type",
															)}
															onItemClick={(value) => {
																if (
																	value ===
																	exceedingAmountTypes.bothSharePremiumReserve
																) {
																	change(
																		form,
																		"exceedingAmountTiedPercentage",
																		50,
																	);
																	change(
																		form,
																		"exceedingAmountFreePercentage",
																		50,
																	);
																} else {
																	change(
																		form,
																		"exceedingAmountTiedPercentage",
																		null,
																	);
																	change(
																		form,
																		"exceedingAmountFreePercentage",
																		null,
																	);
																}

																setRerenderDate(new Date());
															}}
															options={[
																{
																	id: exceedingAmountTypes.freeSharePremiumReserve,
																	text: t(
																		"emissions.form-field.exceeding-amount-type.options.freeSharePremiumReserve",
																	),
																},
																{
																	id: exceedingAmountTypes.tiedSharePremiumReserve,
																	text: t(
																		"emissions.form-field.exceeding-amount-type.options.tiedSharePremiumReserve",
																	),
																},
																{
																	id: exceedingAmountTypes.bothSharePremiumReserve,
																	text: t(
																		"emissions.form-field.exceeding-amount-type.options.bothSharePremiumReserve",
																	),
																},
															]}
														/>
													</Grid>
													{form.getState().values.exceedingAmountType ===
														exceedingAmountTypes.bothSharePremiumReserve && (
														<>
															<Grid item xs={6}>
																<TextField
																	disabled={preventChanges}
																	name="exceedingAmountFreePercentage"
																	type={"number"}
																	label={t(
																		"emissions.form-field.exceeding-amount-free-percentage",
																	)}
																	fullWidth
																	fieldProps={{
																		parse: parsers.toInt,
																	}}
																/>
															</Grid>
															<Grid item xs={6}>
																<TextField
																	disabled={preventChanges}
																	name="exceedingAmountTiedPercentage"
																	type={"number"}
																	label={t(
																		"emissions.form-field.exceeding-amount-tied-percentage",
																	)}
																	fullWidth
																	fieldProps={{
																		parse: parsers.toInt,
																	}}
																/>
															</Grid>
														</>
													)}
													<Grid item xs={12}>
														<CalculatedField
															value={calculations.premiumReserveAmount}
															label={t(
																"emissions.form-field.premium-reserve-amount",
															)}
															unit={"SEK"}
														/>
													</Grid>
												</Grid>
											</FormAccordionEntry>
											<FormAccordionEntry
												header={t("emissions.header.allocation")}
											>
												<Grid container rowSpacing={3} columnSpacing={3}>
													<Grid item xs={12}>
														<CustomSelect
															readOnly={preventChanges}
															id="allocationType"
															label={t("emissions.form-field.allocation-type")}
															options={[
																{
																	id: allocationTypes.decision,
																	text: t(
																		"emissions.form-field.allocation-type.options.decision",
																	),
																},
																{
																	id: allocationTypes.payment,
																	text: t(
																		"emissions.form-field.allocation-type.options.payment",
																	),
																},
															]}
														/>
													</Grid>
													<Grid item xs={12}>
														<CustomSelect
															readOnly={preventChanges}
															id="overSubscriptionType"
															label={t(
																"emissions.form-field.over-subscription-type",
															)}
															options={Object.keys(overSubscriptionType).map(
																(type) => {
																	return {
																		id: type,
																		text: t(
																			"emissions.form-field.over-subscription-type.options." +
																				type,
																		),
																	};
																},
															)}
														/>
													</Grid>
													<Grid item xs={6}>
														<DatePicker
															inputRef={startDateRef}
															disabled={preventChanges}
															label={t(
																"emissions.form-field.subscription-start-date",
															)}
															name="subscriptionStartDate"
														/>
													</Grid>
													<Grid item xs={6}>
														<DatePicker
															inputRef={endDateRef}
															disabled={preventChanges}
															label={t(
																"emissions.form-field.subscription-end-date",
															)}
															name="subscriptionEndDate"
														/>
													</Grid>
												</Grid>
											</FormAccordionEntry>
											<FormAccordionEntry
												header={t("emissions.header.payment")}
											>
												<Grid container rowSpacing={3} columnSpacing={3}>
													<Grid item xs={6}>
														<CustomSelect
															readOnly={preventChanges}
															id="paymentType"
															label={t("emissions.form-field.payment-type")}
															options={[
																{
																	id: "cash",
																	text: t(
																		"emissions.form-field.payment-type.options.cash",
																	),
																},
																{
																	id: "nonMonetary",
																	text: t(
																		"emissions.form-field.payment-type.options.nonMonetary",
																	),
																},
																{
																	id: "setOff",
																	text: t(
																		"emissions.form-field.payment-type.options.setOff",
																	),
																},
															]}
														/>
													</Grid>
													<Grid item xs={6}>
														<DatePicker
															inputRef={paymentDueDateRef}
															disabled={preventChanges}
															label={t("emissions.form-field.payment-due-date")}
															name="paymentDueDate"
														/>
													</Grid>
												</Grid>
											</FormAccordionEntry>
											<Box
												sx={{
													display: "flex",
													flex: "1",
													justifyContent: "flex-end",
													mt: 6,
												}}
											>
												<Button
													variant="contained"
													size="large"
													onClick={handlePdfMenuClick}
													sx={{
														...(emission.status === emissionStatus.active
															? buttonStyles.greyContained
															: buttonStyles.blackContained),
														fontWeight: "bold",
														alignSelf: "center",
														py: "1.5rem !important",
													}}
													endIcon={<KeyboardArrowDownIcon />}
												>
													<Typography variant="h5">
														{t("emissions.button.download-table-as-pdf")}
													</Typography>
												</Button>
												<Menu
													anchorEl={anchorEl}
													open={open}
													onClose={handlePdfMenuClose}
												>
													<MenuItem
														onClick={async () => {
															if (hasError()) {
																form.submit();
																setPdfChoice(PdfDownloadChoice.Table);
																setPdfDownloadWarningOpen(true);
															} else {
																await dispatch(
																	generatePdfFromEmission(
																		form.getState().values,
																		calculations,
																	),
																);
															}
															handlePdfMenuClose();
														}}
													>
														<DownloadIcon sx={{ mr: "10px" }} />
														{t("emissions.pdf.header.proposed")}
													</MenuItem>
													<MenuItem
														onClick={async () => {
															if (hasError()) {
																form.submit();
																setPdfChoice(PdfDownloadChoice.BoardSuggestion);
																setPdfDownloadWarningOpen(true);
															} else {
																await dispatch(
																	generateBoardSuggestionPdf(
																		form.getState().values,
																		calculations,
																	),
																);
															}
															handlePdfMenuClose();
														}}
													>
														<DownloadIcon sx={{ mr: "10px" }} />
														{t(
															"emissions.button.download-board-suggestion-as-pdf",
														)}
													</MenuItem>
												</Menu>
											</Box>
										</WhiteSection>
									</Grid>
									<Grid item xs={5}>
										<WhiteSection sx={{ ml: 2 }}>
											<Typography variant="h5">
												{t("emissions.header.document")}
											</Typography>
											<Typography variant="body2" sx={{ pt: 2 }}>
												{t("emissions.header.document.desc")}
											</Typography>
											<Typography variant="h6" sx={{ pt: 2 }}>
												{t("emissions.header.document.desc2")}
											</Typography>
											<Typography variant="body2" sx={{ pt: 2 }}>
												{t("emissions.header.document.desc3")}
											</Typography>
											<Box sx={{ display: "flex", mt: 3 }}>
												<CustomSelect
													readOnly={preventChanges}
													id="decisionType"
													onItemClick={() => setRerenderDate(new Date())}
													label={t("emissions.form-field.decision-type")}
													options={[
														{
															id: decisionTypes.annual,
															text: t(
																"emissions.form-field.decision-type.options.annual",
															),
														},
														{
															id: decisionTypes.extraOrdinary,
															text: t(
																"emissions.form-field.decision-type.options.extraordinary",
															),
														},
														{
															id: decisionTypes.authorizing,
															text: t(
																"emissions.form-field.decision-type.options.authorizing",
															),
														},
														{
															id: decisionTypes.retrospective,
															text: t(
																"emissions.form-field.decision-type.options.retrospective",
															),
														},
													]}
												/>
											</Box>
											<FormAccordionEntry
												header={t("emissions.documents-to-upload")}
											>
												<Box sx={{ width: "100%" }}>
													{documentTypeEntries.map((t) => {
														const info = documentMap[t.fileKey];

														return t.visible(emission.decisionType) ? (
															<FileToUpload
																preventChanges={preventChanges}
																key={t.fileKey}
																title={t.title}
																filekey={t.fileKey}
																fileName={info ? info.name : ""}
																fileId={info ? info.id : ""}
																onFileDeleted={() =>
																	dispatch(
																		deleteDocument(
																			emission.companyId,
																			emission.id,
																			t.fileKey,
																		),
																	)
																}
																onFilePicked={(file) =>
																	dispatch(
																		uploadDocument(
																			emission.companyId,
																			emission.id,
																			t.fileKey,
																			file,
																		),
																	)
																}
															/>
														) : null;
													})}
												</Box>
											</FormAccordionEntry>
										</WhiteSection>
									</Grid>
								</Grid>
							</form>
							{emission.status === emissionStatus.active && (
								<ThemeProvider theme={MUItheme}>
									<Grid container sx={{ pt: 2 }}>
										<Grid item xs={7}>
											<Card variant="dark">
												<CardContent
													variant="flex"
													sx={{ alignItems: "center" }}
												>
													<Box>
														<Typography variant="body1">
															{t(
																"emissions_module.preparations.dark_panel.title.next_step",
															)}
														</Typography>
														<Typography variant="body2">
															{t(
																"emissions_module.preparations.dark_panel.body.next_step",
															)}
														</Typography>
													</Box>
													<Tooltip
														title={
															hasError()
																? t(
																		"emissions_module.preparations.dark_panel.next_btn.disabled.tooltip",
																  )
																: ""
														}
													>
														<span>
															<Button
																variant={"hbgStyle"}
																onClick={() => {
																	form.submit();

																	if (form.getState().hasValidationErrors) {
																		return;
																	}

																	dispatch(
																		updateEmissionStatus(
																			emission.companyId,
																			emission.id,
																			emissionStatus.preparationsApproved,
																			() => {
																				history.push(
																					`/${company}/shares/cap-table/emission/${emission.id}/subscription-form`,
																				);
																			},
																		),
																	);
																}}
															>
																<Typography
																	variant="hbgStyle"
																	sx={{ whiteSpace: "nowrap" }}
																>
																	{t(
																		"emissions_module.preparations.dark_panel.btn.next_step.subscription_form",
																	)}
																</Typography>
															</Button>
														</span>
													</Tooltip>
												</CardContent>
											</Card>
										</Grid>
									</Grid>
								</ThemeProvider>
							)}
						</>
					);
				}}
			/>
		</>
	);
};

export const documentTypeEntries = [
	{
		title: "emissions.document-types.proposal-from-board",
		fileKey: documentTypes.proposalFromBoard,
		visible: () => true,
	},
	{
		title: "emissions.document-types.board-decision",
		fileKey: documentTypes.boardDecision,
		visible: (decisionType) =>
			new Set([
				decisionTypes.annual,
				decisionTypes.extraOrdinary,
				decisionTypes.retrospective,
			]).has(decisionType),
	},
	{
		title: "emissions.document-types.board-decision.authorizing",
		fileKey: documentTypes.boardDecision,
		visible: (decisionType) =>
			new Set([decisionTypes.authorizing]).has(decisionType),
	},
	{
		title: "emissions.document-types.notice-general-meeting",
		fileKey: documentTypes.noticeGeneralMeeting,
		visible: (decisionType) =>
			new Set([decisionTypes.annual, decisionTypes.extraOrdinary]).has(
				decisionType,
			),
	},
	{
		title: "emissions.document-types.notice-general-meeting.authorizing",
		fileKey: documentTypes.noticeGeneralMeeting,
		visible: (decisionType) =>
			new Set([decisionTypes.authorizing]).has(decisionType),
	},
	{
		title: "emissions.document-types.first-appendix",
		fileKey: documentTypes.firstAppendix,
		visible: (decisionType) =>
			new Set([
				decisionTypes.annual,
				decisionTypes.authorizing,
				decisionTypes.extraOrdinary,
				decisionTypes.retrospective,
			]).has(decisionType),
	},
	{
		title: "emissions.document-types.second-appendix",
		fileKey: documentTypes.secondAppendix,
		visible: (decisionType) =>
			new Set([
				decisionTypes.annual,
				decisionTypes.authorizing,
				decisionTypes.extraOrdinary,
				decisionTypes.retrospective,
			]).has(decisionType),
	},
	{
		title: "emissions.document-types.third-appendix",
		fileKey: documentTypes.thirdAppendix,
		visible: (decisionType) =>
			new Set([
				decisionTypes.authorizing,
				decisionTypes.extraOrdinary,
				decisionTypes.retrospective,
			]).has(decisionType),
	},
	{
		title: "emissions.document-types.fourth-appendix",
		fileKey: documentTypes.fourthAppendix,
		visible: (decisionType) =>
			new Set([
				decisionTypes.authorizing,
				decisionTypes.extraOrdinary,
				decisionTypes.retrospective,
			]).has(decisionType),
	},
];

export const FileToUpload = (props) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const [loadingText, setLoadingText] = useState("");
	const uploaded = !!props.fileName;
	const styles = uploaded
		? {
				fontSize: 10,
				fontStyle: "normal",
				typographyVariant: "overline",
		  }
		: {
				fontSize: theme.typography.body2.fontSize,
				fontStyle: "italic",
				typographyVariant: "body2",
		  };

	return (
		<Box
			sx={{
				width: "100%",
				borderBottom: `1px solid ${theme.palette.divider}`,
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					height: 65,
					py: theme.spacing(2),
				}}
			>
				{props.mailIcon && (
					<Box
						sx={{
							display: "flex",
							background: "#D6EBEB",
							alignItems: "center",
							justifyContent: "center",
							borderRadius: "50%",
							height: "40px",
							width: "40px",
						}}
					>
						<MailOutlineIcon sx={{ color: "#1F5C5C" }} />
					</Box>
				)}
				{loadingText ? (
					<Spinner text={loadingText} />
				) : (
					<>
						<Box sx={{ mt: -2.2, pr: 1, width: 28 }}>
							{uploaded && (
								<CheckIcon sx={{ color: theme.palette.success.main }} />
							)}
						</Box>

						<Box sx={{ flex: "auto", width: 0 }}>
							{uploaded && (
								<Link
									to={"#"}
									onClick={() => {
										viewDocument(props.fileId);
									}}
								>
									<Typography
										title={props.fileName}
										variant="body2"
										sx={{
											mb: 1,
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
											color: "#369ff4",
										}}
									>
										{props.fileName}
									</Typography>
								</Link>
							)}

							<Typography
								title={t(props.title)}
								sx={{
									fontStyle: styles.fontStyle,
									fontSize: styles.fontSize,
									color: theme.palette.grey["600"],
									overflow: "hidden",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
								}}
							>
								{t(props.title)}
							</Typography>
						</Box>
						{!props.preventChanges && (
							<Box>
								{uploaded ? (
									<IconButton
										onClick={async () => {
											try {
												setLoadingText("Tar bort...");
												await props.onFileDeleted();
											} finally {
												setLoadingText("");
											}
										}}
									>
										<CloseIcon />
									</IconButton>
								) : (
									<IconButton
										component={"label"}
										onClick={() => {
											const input = document.createElement("input");
											input.type = "file";
											input.accept =
												"application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .doc, .docx";

											input.onchange = async (e) => {
												try {
													const file = e.target.files[0];
													setLoadingText("Laddar upp...");
													await props.onFilePicked(file);
												} finally {
													setLoadingText("");
												}
											};

											input.click();
										}}
									>
										<UploadOutlinedIcon color={"primary"} />
									</IconButton>
								)}
							</Box>
						)}
					</>
				)}
			</Box>
		</Box>
	);
};

const viewDocument = (docId) => {
	downloadDocument({
		documentId: docId,
		openInViewer: true,
		getMergedVersion: false,
		getSignedVersion: false,
	})();
};

export default Preparations;
