import React, { Component } from "react";
import { connect } from "react-redux";
import { List, Map } from "immutable";
import { string, object } from "prop-types";
import Moment from "../../../modules/moment.module";
import {
	saveMeeting,
	updateAgendaItemLocal,
} from "../../../actions/meetings.actions";
import { MEETINGS_STATUS_STARTED } from "../../../constants/meetings";
import { closeModal } from "../../../actions/modals.actions";
import { MEETING_START_MEETING_MODAL } from "../../../constants/modals";
import AttendeesModalListContainer from "../attendees/attendees-modal-list.container";
import history from "../../../interfaces/history";

class OpenMeetingModalContainer extends Component {
	static propTypes = {
		location: object,
		basePath: string,
	};

	componentWillUnmount() {
		this.props.closeModal(MEETING_START_MEETING_MODAL);
	}

	// First Agenda item = Open
	goToFirstAgendaItem = () => {
		const { location, basePath, closeModal, history } = this.props;
		let { meeting } = this.props;
		const agendaItem = meeting
			.get("agendaItems", List())
			.find((obj) => obj.get("internalType") === "open");

		closeModal(MEETING_START_MEETING_MODAL);

		history.push({
			pathname: `${basePath}/${meeting.get(
				"id",
			)}/meeting/agenda/${agendaItem.get("id")}`,
			search: location.search,
		});
	};

	openMeeting = () => {
		const { saveMeeting, updateAgendaItemLocal, closeModal, activeModal } =
			this.props;
		let { meeting } = this.props;
		let agendaItem = meeting
			.get("agendaItems", List())
			.find((obj) => obj.get("internalType") === "open");
		const redirectToOpenMeetingItem = activeModal.getIn(
			["options", "redirectToOpenMeetingItem"],
			false,
		);
		const agendaItemOutcomeValue = activeModal.getIn([
			"options",
			"agendaItemOutcomeValue",
		]);

		let internalData = agendaItem.get("internalData", Map());
		const now = Moment();
		internalData = internalData.set("openedAt", now.toISOString());
		agendaItem = agendaItem.set("internalData", internalData);

		if (agendaItemOutcomeValue) {
			agendaItem = agendaItem.set("outcome", agendaItemOutcomeValue);
		}

		updateAgendaItemLocal(agendaItem, (payload) => {
			if (!meeting.get("startDate")) {
				meeting = meeting.set("startDate", now.toISOString());
			}
			if (!meeting.get("endDate")) {
				meeting = meeting.set("endDate", now.add(1, "hours").toISOString());
			}
			meeting = meeting.set("agendaItems", payload.get("agendaItems"));
			meeting = meeting.set("status", MEETINGS_STATUS_STARTED);
			saveMeeting(meeting);
		});

		if (redirectToOpenMeetingItem) {
			this.goToFirstAgendaItem();
		} else {
			closeModal(MEETING_START_MEETING_MODAL);
		}
	};

	render() {
		const { activeModal } = this.props;
		const isModalOpen = activeModal
			? activeModal.get("name") === MEETING_START_MEETING_MODAL
			: false;

		return (
			<AttendeesModalListContainer
				isOpen={isModalOpen}
				onClose={this.openMeeting}
			/>
		);
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meeting: store.meetings.get("meeting"),
		activeModal: store.modals.get("activeModal"),
	};
};

const mapActionsToProps = {
	saveMeeting,
	updateAgendaItemLocal,
	closeModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(OpenMeetingModalContainer);
