import React, { PureComponent } from "react";
import { func, bool, string } from "prop-types";
import { map } from "react-immutable-proptypes";
import { List } from "immutable";
import styled from "styled-components";
import moment from "../../../modules/moment.module";

import Text from "../../shared/text/text";
import ListItem from "../../shared/list-item/list-item";
import Badge from "../../shared/badge/badge-new";

const Wrapper = styled(ListItem)`
	display: flex;
	border-bottom: 1px solid ${({ theme }) => theme.colors.border};
	padding-right: ${({ theme }) => theme.spacing["4-scrollOffset"]};
	align-items: center;
	margin-bottom: 0;
	cursor: ${({ active }) => (active ? "default" : "pointer")};
	flex-direction: column;
	height: 50px;
	background-color: transparent;
`;

const StyledWrapperInner = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

const ContentWrapper = styled.div`
	display: flex;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-left: ${({ theme }) => theme.spacing[4]};
	align-items: center;
	width: 100%;
	height: 100%;
`;

const StyledName = styled(Text)`
	flex: 1;
`;

const StyledDate = styled(Text)`
	padding-right: ${({ theme }) => theme.spacing[3]};
	margin-right: ${({ theme }) => theme.spacing[3]};
	border-right: 1px solid ${({ theme }) => theme.colors.border};
`;

const StyledBadge = styled(Badge)`
	margin: 0 ${(props) => props.theme.spacing[3]};
`;

const StyledDropdownWrapper = styled.div`
	min-width: 35px;
`;

export default class CollectionListItem extends PureComponent {
	static propTypes = {
		collection: map,
		renderDropdown: func,
		onSelect: func,
		selectedCollectionId: string,
		setRef: func,
		getCollectionNameData: func,
		isDragging: bool,
	};

	setRef = (ref) => {
		const { collection, setRef } = this.props;
		const collectionId = collection.get("id");
		setRef(ref, collectionId);
	};

	handleSelect = () => {
		const { collection, onSelect } = this.props;
		onSelect(collection);
	};

	render = () => {
		const {
			collection,
			renderDropdown,
			selectedCollectionId,
			getCollectionNameData,
			onSelect,
			setRef,
			isDragging,
		} = this.props;
		const id = collection.get("id");
		const date = collection.get("createdAt");

		const { name, isItalic, isRoot } = getCollectionNameData(collection, true);
		const formattedDate = date ? moment(date).format("L") : "-";
		const documentsAmount = collection.get("documents", List()).size;
		const isActive = selectedCollectionId === id;
		const badgeColor = documentsAmount
			? isRoot
				? "yellowMid"
				: "java"
			: "lightGrey";
		const onClick = !isActive && onSelect ? this.handleSelect : undefined;
		const activeColor = isDragging ? "white" : "lightestGrey";
		const hoverColor = isActive || isDragging ? "white" : "lightestGrey";

		return (
			<Wrapper
				hoverColor={hoverColor}
				activeColor={activeColor}
				active={isActive}
			>
				<StyledWrapperInner>
					<ContentWrapper onClick={onClick} ref={setRef && this.setRef}>
						{!isRoot && <StyledDate nowrap>{formattedDate}</StyledDate>}

						<StyledName singleLine hasItalic={isItalic}>
							{name}
						</StyledName>

						<StyledBadge
							marginRight={false}
							height={20}
							minWidth={30}
							bgColor={badgeColor}
							textColor="white"
						>
							{documentsAmount.toString()}
						</StyledBadge>
					</ContentWrapper>

					<StyledDropdownWrapper>
						{renderDropdown(collection)}
					</StyledDropdownWrapper>
				</StyledWrapperInner>
			</Wrapper>
		);
	};
}
