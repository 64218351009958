import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchMeeting, saveMeeting } from "../../../actions/meetings.actions";
import SplitView from "../../../dumb-components/shared/layout/split-view/split-view";
import ScrollView from "../../../dumb-components/shared/layout/scroll-view/scroll-view";
import ScrollViewHeader from "../../../dumb-components/shared/scroll-view-header/scroll-view-header";
import AgendaListContainer from "../agenda/agenda-list.container";
import ProtocolTabsContainer from "../protocol/protocol-tabs.container";
import ProtocolToolbarContainer from "../meeting/smart-meeting/protocol-toolbar.container";

import AgendaDetailsContainer from "../agenda/agenda-details.container";

import {
	calculateMaxWidthOfSplitView,
	toggleVisibilityOfTabs,
} from "../../../components/helpers/tabs.helper";

class ProtocolReviewContainer extends Component {
	// References to the scrollviews
	scrollViewRefs = {};

	// Reference to the tabs
	tabsRef;

	// Timer
	resizeEnd;

	state = {
		hiddenTabs: [],
	};

	componentDidMount = () => {
		window.addEventListener("resize", this.onWindowResize, false);
		this.onWindowResize();
	};

	componentWillUnmount = () => {
		window.removeEventListener("resize", this.onWindowResize, false);
	};

	onWindowResize = () => {
		let { hiddenTabs } = this.state;
		const maxWidth = calculateMaxWidthOfSplitView(this.scrollViewRefs);
		hiddenTabs = toggleVisibilityOfTabs(this.tabsRef, hiddenTabs, { maxWidth });

		this.setState({ hiddenTabs });
	};

	getComponentBasePath = () => {
		const {
			location: { pathname },
		} = this.props;
		const path = pathname;
		const pathArray = path.split("review");
		return pathArray[0] + "review";
	};

	onChange = (prop, val) => {
		const { saveMeeting } = this.props;
		let { meeting } = this.props;

		meeting = meeting.setIn(prop, val);
		saveMeeting(meeting);
	};

	renderLeftHeader = () => {
		const { location } = this.props;
		const { hiddenTabs } = this.state;

		return (
			<ProtocolTabsContainer
				match="review"
				location={location}
				onSetRef={(ref) => (this.tabsRef = ref)}
				hiddenTabs={hiddenTabs}
			/>
		);
	};

	renderLeftSplitView = () => {
		const {
			meeting,
			location,
			match: { params },
		} = this.props;
		const basePath = this.getComponentBasePath();
		const canUpdate = meeting
			? meeting.getIn(["computedValues", "hasExtendedRights"])
			: false;
		const showProgressIcon = location.pathname.indexOf("/review") >= 0;

		return (
			<ScrollView
				header={this.renderLeftHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				onSetRef={(ref) => (this.scrollViewRefs["left"] = ref)}
			>
				<AgendaListContainer
					basePath={basePath}
					querystr={location.search}
					agendaItemIdInUrl={params.agendaItemId}
					items={meeting && meeting.get("agendaItems")}
					onMeetingChange={this.onChange}
					readOnly={!canUpdate}
					showProgressIcon={showProgressIcon}
				/>
			</ScrollView>
		);
	};

	renderRightHeader = () => {
		const {
			match: { params },
			location,
		} = this.props;
		const basePath = this.getComponentBasePath();

		return (
			<ScrollViewHeader>
				<ProtocolToolbarContainer
					location={location}
					params={params}
					basePath={basePath.split("/review")[0]}
				/>
			</ScrollViewHeader>
		);
	};

	renderRightSplitView = () => {
		const { match } = this.props;

		return (
			<ScrollView
				header={this.renderRightHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				onSetRef={(ref) => (this.scrollViewRefs["right"] = ref)}
			>
				{match.params.agendaItemId && (
					<AgendaDetailsContainer
						match={match}
						location={this.props.location}
						history={this.props.history}
					/>
				)}
			</ScrollView>
		);
	};

	render = () => {
		return (
			<SplitView
				leftComponent={this.renderLeftSplitView}
				rightComponent={this.renderRightSplitView}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get("meeting"),
	};
};

const mapActionsToProps = {
	fetchMeeting,
	saveMeeting,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ProtocolReviewContainer);
