import React, { useCallback, useEffect, useState } from "react";
import { Map } from "immutable";
import { useDispatch, useSelector } from "react-redux";
import {
	filterAttendees,
	resetAttendeesFilter,
} from "../../../../actions/attendees.actions";
import RollcallFilter from "../../../../dumb-components/meetings/rollcall/rollcall-filter";

const RollcallFilterContainer = ({
	hideCheckin,
	hideMoreActions,
	hideVotingInfo,
	onFilter,
}) => {
	const meetingId = useSelector((state) => {
		return state.meetings.getIn(["meeting", "id"]);
	});

	const [filterData, setFilterData] = useState(Map());
	const [hasBeenFiltered, setHasBeenFiltered] = useState();

	const dispatch = useDispatch();

	useEffect(() => {
		if (meetingId && filterData.size > 0) {
			const attendeesFilteredInClient = onFilter?.(filterData);
			dispatch(
				filterAttendees(meetingId, filterData, attendeesFilteredInClient),
			);
		} else if (
			meetingId &&
			filterData.size === 0 &&
			hasBeenFiltered === false
		) {
			dispatch(resetAttendeesFilter(meetingId));
		}
	}, [meetingId, filterData, hasBeenFiltered, dispatch, onFilter]);

	const onChange = useCallback(
		(fieldName, value) => {
			let newFilterdata;
			let hasBeenFiltered;

			if (value) {
				newFilterdata = filterData.set(fieldName, value);
				hasBeenFiltered = true;
			} else {
				newFilterdata = filterData.remove(fieldName);
				hasBeenFiltered = false;
			}

			setFilterData(newFilterdata);
			setHasBeenFiltered(hasBeenFiltered);
		},
		[filterData, hasBeenFiltered, setFilterData, setHasBeenFiltered],
	);

	return (
		<RollcallFilter
			onChange={onChange}
			filterData={filterData}
			hideCheckin={hideCheckin}
			hideMoreActions={hideMoreActions}
			hideVotingInfo={hideVotingInfo}
		/>
	);
};

export default RollcallFilterContainer;
