import React, { Component } from "react";
import { number } from "prop-types";
import { Map } from "immutable";
import GoogleMap from "../../dumb-components/shared/map/map";

class GoogleMapsContainer extends Component {
	static propTypes = {
		lat: number,
		lng: number,
	};

	static defaultProps = {
		lat: 56.0487417,
		lng: 12.6880687,
	};

	state = {
		marker: null,
	};

	options = {
		mapTypeControl: false,
		streetViewControl: true,
		scrollwheel: false,
	};

	render = () => {
		const { lat, lng } = this.props;
		const location = Map({ lat, lng });

		return <GoogleMap location={location} options={this.options} />;
	};
}

export default GoogleMapsContainer;
