import React, { useContext, useEffect, useRef, useState } from "react";
import { InvestmentsContext } from "../investment.container";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import ChartModal from "../shared/chartmodal/chartmodal";
import { closeModal, openModal } from "../../../actions/modals.actions";
import { CHART_MODAL } from "../../../constants/modals";
import {
	byWhatNumberShouldNavigatorShow,
	exportAsPNG,
} from "../../../components/helpers/ag-chart.helper";
import localeFormatNumber from "../../../modules/format-number";
import { NUMBER_FORMAT_CURRENCY } from "/shared/constants";
import { MUItheme } from "../../../../MUItheme";
import AgGridChart from "../../../dumb-components/shared/ag-grid/chart/ag-grid-chart";
import Text from "../../../dumb-components/shared/text/text";
import GridPanelRightButtons from "../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

const HISTORY_CHART_DATE_FORMAT = { year: "numeric", month: "short" };

export default function TransactionHistoryChart({ title, downloadExcelTid }) {
	const chartRefPanel = useRef(null);
	const chartRefModal = useRef(null);
	const { selectedInvestment } = useContext(InvestmentsContext);
	const [chartData, setChartData] = useState();

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const transactions = selectedInvestment?.get("transactions");
	const chartOption = "TransactionHistoryChart";

	const isChartModalOpen = useSelector((state) => {
		return state.modals.getIn(["activeModal", "options"]) === chartOption;
	});

	useEffect(() => {
		if (transactions) {
			setChartData(generateDataForHistoryChart(transactions.reverse()));
		}
	}, [transactions]);

	const generateDataForHistoryChart = (transactions) => {
		{
			/* POST MONEY VALUATION */
		}
		//Create a bucket for each month since the first transaction
		const dateBucket = {};
		const firstTransactionDate = transactions.getIn([0, "date"]);
		const firstMonth = DateTime.fromISO(firstTransactionDate).startOf("month");
		const thisMonth = DateTime.now().startOf("month");

		let monthItterator = firstMonth;
		while (monthItterator <= thisMonth) {
			dateBucket[monthItterator] = {
				date: monthItterator.toLocaleString(HISTORY_CHART_DATE_FORMAT),
				raisedDiff: 0,
				postMoney: 0,
			};
			monthItterator = monthItterator.plus({ month: 1 });
		}

		//Sort the data of every transaction into the buckets.
		transactions.forEach((transaction) => {
			const transactionMonth = DateTime.fromISO(
				transaction.get("date"),
			).startOf("month");

			dateBucket[transactionMonth].postMoney = transaction.getIn([
				"balance",
				"postMoney",
			]);
			dateBucket[transactionMonth].raisedDiff =
				dateBucket[transactionMonth].raisedDiff +
				transaction.getIn(["balance", "raisedDiff"]);
		});

		//add the previous existing postmoney to months without transactions
		const bucketKeys = Object.keys(dateBucket);
		bucketKeys.forEach((bucketKey, i) => {
			const bucketItem = dateBucket[bucketKey];
			if (!bucketItem.postMoney) {
				const prevbucketItem = dateBucket[bucketKeys[i - 1]];
				bucketItem.postMoney = prevbucketItem.postMoney;
			}
		});

		//create formated object to fit history graph
		const formattedData = Object.values(dateBucket);
		return formattedData;
	};

	const series = [
		{
			type: "column",
			xKey: "date",
			yKey: "raisedDiff",
			yName: t(
				"investments.sharebook.transaction_history_grid_chart_serie_one.name",
			),
			strokeWidth: 0,
			tooltip: { renderer: formatValue },
			/* 	marker: {
					size: 13
				}, */
			highlightStyle: {
				item: {
					fill: MUItheme.palette.chart.complementary,
					strokeWidth: 0,
				},
				series: {
					...(isChartModalOpen ? { dimOpacity: 1 } : { dimOpacity: 0.2 }),
				},
			},
		},
		{
			type: "line",
			xKey: "date",
			yKey: "postMoney",
			yName: t(
				"investments.sharebook.transaction_history_grid_chart_serie_two.name",
			),
			// strokeWidth: 6,
			tooltip: { renderer: formatValue },
			marker: {
				size: 0,
			},
			highlightStyle: {
				item: {
					fill: MUItheme.palette.chart.complementary,
					strokeWidth: 0,
				},
				series: {
					...(isChartModalOpen ? { dimOpacity: 1 } : { dimOpacity: 0.2 }),
				},
			},
		},
	];

	const axes = [
		{
			type: "category",
			position: "bottom",
		},
		{
			min: 0,
			type: "number",
			position: "right",
			keys: ["postMoney"],
		},
		{
			min: 0,
			type: "number",
			position: "left",
			keys: ["raisedDiff"],
		},
	];

	const legend = {
		position: "bottom",
		item: {
			// paddingX: 50, //for position bottom (space between the items)
			marker: {
				shape: "square",
				strokeWidth: 0,
				size: 20,
			},
			label: {
				color: "#425060",
			},
		},
	};

	function formatValue(params) {
		if (params.xValue && params.yValue) {
			return {
				title: params.xValue,
				content: localeFormatNumber(params.yValue, NUMBER_FORMAT_CURRENCY),
			};
		}
		if (params.value === 0) {
			return "";
		} else {
			return params.value.toLocaleString().replace(/,(?=[^\s])/g, " ");
		}
	}

	const openChartModal = () => {
		dispatch(openModal(CHART_MODAL, chartOption));
	};

	const onCloseChartModal = () => {
		dispatch(closeModal());
	};

	const renderAgChart = () => {
		return (
			<AgGridChart
				chartRef={isChartModalOpen ? chartRefModal : chartRefPanel}
				series={series}
				axes={axes}
				data={chartData}
				showNavigator={byWhatNumberShouldNavigatorShow(chartData, 100)}
				howManyToShow={0}
				labelFormatter={formatValue}
				legend={legend}
			/>
		);
	};

	if (!chartData) {
		return null;
	}

	return (
		<>
			{isChartModalOpen && (
				<ChartModal
					leftHeader={title}
					onCancel={onCloseChartModal}
					onExport={() => {
						exportAsPNG(chartRefModal, t(title));
					}}
					isOpen={isChartModalOpen}
				>
					{renderAgChart()}
				</ChartModal>
			)}

			<Card>
				<CardHeader
					title={<Text tid={title} />}
					action={
						<GridPanelRightButtons
							chart
							noFilter={true}
							exportAsExcel={() => {
								exportAsPNG(chartRefPanel, t(title));
							}}
							downloadExcelTid={downloadExcelTid}
							openGridModal={openChartModal}
						/>
					}
				/>
				<CardContent variant="panel">{renderAgChart()}</CardContent>
			</Card>
		</>
	);
}
