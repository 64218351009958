import {
	HELPSCOUT_MY_PORTFOLIO_READ_MORE,
	HELPSCOUT_OWN_RECORDS_READ_MORE,
	HELPSCOUT_OWN_RECORDS_MORE_HELP,
	HELPSCOUT_ADD_TRANSACTION_MODAL_INFO,
	HELPSCOUT_DOCUMENTS_ESIGNING,
	HELPSCOUT_DOCUMENTS_SECTION,
} from "/shared/constants";

export const HELPSCOUT_ARTICLES = {
	[HELPSCOUT_MY_PORTFOLIO_READ_MORE]: {
		"en-gb": "643e8502bbab942d2df21efa",
		"sv-SE": "5e837e992c7d3a7e9aea2189",
	},
	[HELPSCOUT_OWN_RECORDS_READ_MORE]: {
		"en-gb": "643e8502bbab942d2df21efa",
		"sv-SE": "5e837e992c7d3a7e9aea2189",
	},
	[HELPSCOUT_OWN_RECORDS_MORE_HELP]: {
		"en-gb": "643e8502bbab942d2df21efa",
		"sv-SE": "5e837e992c7d3a7e9aea2189",
	},
	[HELPSCOUT_ADD_TRANSACTION_MODAL_INFO]: {
		"en-gb": "643e8502bbab942d2df21efa",
		"sv-SE": "5e837e992c7d3a7e9aea2189",
	},
	[HELPSCOUT_DOCUMENTS_ESIGNING]: {
		"en-gb": "211",
		"sv-SE": "211",
	},
	[HELPSCOUT_DOCUMENTS_SECTION]: {
		"en-gb": "133",
		"sv-SE": "133",
	},
};
