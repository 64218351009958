const helper = {
	getPath: (folderId, folders) => {
		if (!folderId) {
			return;
		}

		const currentFolderName = folders[folderId].name;
		let path = [];
		let parentId = helper.getParent(folderId, folders);

		path.push(currentFolderName);

		if (parentId) {
			const parent = folders[parentId].name;
			path.push(parent);
		}

		while (parentId) {
			parentId = helper.getParent(parentId, folders);

			if (parentId) {
				const parent = folders[parentId].name;
				path.push(parent);
			}
		}

		return path.reverse();
	},

	getParent: (childId, folders) => {
		return folders[childId].parent;
	},

	getShortPath: (folderId, folders) => {
		if (!folderId) {
			return;
		}

		const currentFolderName = folders[folderId].name;
		let path = [];
		let parentId = helper.getParent(folderId, folders);

		path.push(currentFolderName);

		if (parentId) {
			const parent = folders[parentId].name;
			path.push(parent);
		}

		while (parentId) {
			parentId = helper.getParent(parentId, folders);

			if (parentId) {
				const parent = folders[parentId].name;
				path.push(parent);
			}
		}

		const reversedPath = path.reverse();
		const slicedPath = reversedPath.slice(-2);

		if (slicedPath.length === 2) {
			return ['...', slicedPath[1]];
		} else {
			return slicedPath;
		}
	}
};

module.exports = helper;
