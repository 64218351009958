import React, { Component } from "react";
import { connect } from "react-redux";
import immutablePropTypes from "react-immutable-proptypes";
import { func } from "prop-types";
import { Map, List } from "immutable";
import { saveMeetingTemplate } from "../../../actions/meeting-templates.actions";
import MeetingTemplatesList from "../../../dumb-components/meetings/meeting-templates/meeting-templates-list/meeting-templates-list";
import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import DropdownMenuItem from "../../../dumb-components/shared/dropdown-item/dropdown-item";
import Text from "../../../dumb-components/shared/text/text";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";
import { MEETING_DEFAULT_TEMPLATE_ID } from "/shared/constants";

class MeetingTemplatesListContainer extends Component {
	static propTypes = {
		meetingTemplates: immutablePropTypes.list,
		usersCache: immutablePropTypes.map,
		onSelectMeetingTemplate: func,
		onDeleteMeetingTemplate: func,
		onChange: func,
	};

	state = {
		templatesMetadata: Map(),
		meetingTemplates: List(),
	};

	dropdownMenuRefs = {};

	componentDidMount = () => {
		this.parseMeetingTemplates();
	};

	componentDidUpdate = (prevProps) => {
		const { usersCache, meetingTemplates } = this.props;

		if (
			prevProps.usersCache !== usersCache ||
			prevProps.meetingTemplates !== meetingTemplates
		) {
			this.parseMeetingTemplates();
		}

		if (prevProps.meetingTemplates !== meetingTemplates) {
			this.parseMeetingTemplates();
		}
	};

	parseMeetingTemplates = () => {
		const { meetingTemplates, usersCache, selectedGroupId } = this.props;
		let metadata = Map();
		const filteredMeetingTemplates =
			meetingTemplates &&
			meetingTemplates.filter(
				(obj) => !obj.get("groupId") || obj.get("groupId") === selectedGroupId,
			);

		filteredMeetingTemplates &&
			filteredMeetingTemplates.forEach((template) => {
				let templateMetadata = Map();
				const user = usersCache.get(template.get("createdBy"));
				const groupId = template.get("groupId");

				if (!groupId || groupId === "" || groupId === "root") {
					templateMetadata = templateMetadata.set("isGlobal", true);
				}

				if (user) {
					templateMetadata = templateMetadata.set(
						"createdBy",
						user.get("name"),
					);
				}

				metadata = metadata.set(template.get("id"), templateMetadata);
			});

		this.setState({
			templatesMetadata: metadata,
			meetingTemplates: filteredMeetingTemplates,
		});
	};

	onChange = (id, field, val) => {
		const { meetingTemplates, saveMeetingTemplate, onChange } = this.props;
		let meetingTemplate = meetingTemplates.find((obj) => obj.get("id") === id);

		meetingTemplate = meetingTemplate.set(field, val);
		onChange(meetingTemplate);
		saveMeetingTemplate(meetingTemplate);
	};

	onDeleteMeetingTemplate = (id) => {
		const { onDeleteMeetingTemplate } = this.props;

		onDeleteMeetingTemplate(id);
		this.dropdownMenuRefs[id].onToggleMenu();
	};

	renderMoreActionButton = (id) => {
		const { onSelectMeetingTemplate } = this.props;
		const templateIsDefault = id === MEETING_DEFAULT_TEMPLATE_ID;

		return (
			<DropdownMenuContainer
				btnIcon="faEllipsisV"
				halignMenu="right"
				btnMode="transparent-icon"
				transparentIconButtonSize="sml"
				tooltipActiveState="btnMoreActions"
				ref={(ref) => (this.dropdownMenuRefs[id] = ref)}
				withPortal
				inline
			>
				<DropdownMenuItem onClick={() => onSelectMeetingTemplate(id)}>
					<Text tid="generic.edit" />
				</DropdownMenuItem>
				<Tooltip
					tid={
						templateIsDefault &&
						"meetings.templates.modal.list.more_actions.template_is_default.tooltip"
					}
				>
					<DropdownMenuItem
						onClick={() => this.onDeleteMeetingTemplate(id)}
						disabled={templateIsDefault}
					>
						<Text tid="generic.delete" />
					</DropdownMenuItem>
				</Tooltip>
			</DropdownMenuContainer>
		);
	};

	render = () => {
		const { meetingTemplate, onSelectMeetingTemplate } = this.props;
		const { meetingTemplates, templatesMetadata } = this.state;

		return (
			<MeetingTemplatesList
				meetingTemplates={meetingTemplates}
				metadata={templatesMetadata}
				onSelectTemplate={onSelectMeetingTemplate}
				onToggleTemplateStatus={this.onChange}
				selectedMeetingTemplateId={meetingTemplate && meetingTemplate.get("id")}
				renderMoreActionButton={this.renderMoreActionButton}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		selectedGroupId: store.groups.get("selectedGroupId"),
	};
};

const mapActionsToProps = {
	saveMeetingTemplate,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingTemplatesListContainer);
