import React, { PureComponent } from "react";
import { string, func, number, oneOfType } from "prop-types";
import styled from "styled-components";
import { fromJS } from "immutable";

import Input from "../../../shared/input/input";
import ScrollView from "../../../shared/layout/scroll-view/scroll-view";
import ColoredContentWrapper from "../../../shared/colored-content-wrapper/colored-content-wrapper";
import Label from "../../../shared/label/label";
import TextArea from "../../../shared/input/textarea";
import DurationSelect from "../../../shared/duration-select/duration-select";

const TIMER_OPTIONS = fromJS([
	{ label: "meetings.voting.manage_modal.label.timer.none", value: "none" },
	{ label: 1, value: 60 },
	{ label: 2, value: 120 },
	{ label: 5, value: 300 },
	{ label: 10, value: 600 },
	{ label: 15, value: 900 },
	{ label: 30, value: 1800 },
]);

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const WrapperInner = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]}
		${(props) => props.theme.spacing[6]};
`;

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	display: flex;
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

const StyledTopicAndTimerWrapper = styled.div`
	flex-direction: row;
	display: flex;
	flex: 1;
`;

const StyledTopicWrapper = styled.div`
	width: 100%;
`;

const StyledTimerWrapper = styled.div`
	width: 60%;
	margin-left: ${(props) => props.theme.spacing[4]};
`;

export default class VotingManageView extends PureComponent {
	static propTypes = {
		onChange: func,
		question: string,
		topicRef: string,
		timer: oneOfType([string, number]),
	};

	renderDraftView = () => {
		const { onChange, question, topicRef, timer } = this.props;

		return (
			<>
				<StyledColoredContentWrapper type="secondary">
					<StyledTopicAndTimerWrapper>
						<StyledTopicWrapper>
							<Label tid="meetings.voting.manage_modal.step.topicRef">
								<Input
									fieldName="topicRef"
									placeholderTid="meetings.voting.manage_modal.placeholder.topicRef"
									onChange={onChange}
									value={topicRef}
								/>
							</Label>
						</StyledTopicWrapper>
						<StyledTimerWrapper>
							<Label tid="meetings.voting.manage_modal.step.timer">
								<DurationSelect
									fieldName="timer"
									durationOptions={TIMER_OPTIONS}
									onChange={onChange}
									value={timer || "none"}
									menuPortalTarget={document.body}
									isClearable={!timer ? false : true}
								/>
							</Label>
						</StyledTimerWrapper>
					</StyledTopicAndTimerWrapper>
				</StyledColoredContentWrapper>

				<StyledColoredContentWrapper type="secondary">
					<Label tid="meetings.voting.manage_modal.step.question">
						<TextArea
							fieldName="question"
							value={question}
							onChange={onChange}
							placeholderTid="meetings.voting.manage_modal.placeholder.question"
							minRows={9}
						/>
					</Label>
				</StyledColoredContentWrapper>
			</>
		);
	};

	render = () => {
		return (
			<Wrapper>
				<ScrollView noRightMargin noLeftMargin fillContent alwaysShow>
					<WrapperInner>{this.renderDraftView()}</WrapperInner>
				</ScrollView>
			</Wrapper>
		);
	};
}
