import React from "react";
import { DateTime } from "luxon";
import documentsHelper from "../../../components/helpers/documents.helper";
import theme from "../../../../theme";
import MoreActionRenderer from "../../../dumb-components/documents/grid/more-action-renderer";
import { MUItheme } from "../../../../MUItheme";
import Loader from "../../../dumb-components/shared/loader/loader";
import FolderNameRenderer from "./folder-name-renderer";

export function documentListColumnDefs(t) {
	const columnDefs = [
		{
			field: "group",
			rowGroup: true,
			hide: true,
			valueFormatter: (params) => {
				return t(params.value);
			},
		},
		{
			field: "metadata.status",
			maxWidth: 16,
			minWidth: 16,
			cellStyle: (params) => {
				const statusColor = documentsHelper.getStatusColor(params.value);
				const style = {
					paddingRight: "0px",
					backgroundColor: theme.colors[statusColor],
					border: "unset",
				};

				return style;
			},
		},
		{
			field: "date",
			maxWidth: 105,
			minWidth: 105,
			valueFormatter: (params) => {
				if (!params.value) {
					return "";
				}

				const dt = DateTime.fromISO(params.value);
				return dt.toLocaleString();
			},
			cellStyle: () => {
				const style = {
					height: "20px",
					display: "flex",
					alignItems: "center",
					marginTop: "15px",
					borderRight: `1px solid ${MUItheme.palette.solitude.darker}`,
				};

				return style;
			},
			cellRenderer: (params) => {
				if (!params.data) {
					return null;
				}

				if (params.data.isUploading) {
					return <Loader width="35px" height="25px" barWidth="5px" />;
				}

				if (!params.value) {
					return "";
				}

				const dt = DateTime.fromISO(params.value);
				return dt.toLocaleString();
			},
		},
		{
			field: "title",
			filter: "agSetColumnFilter",
			cellRenderer: (params) => {
				if (!params.data) {
					return null;
				}

				const { folderId, title } = params.data;

				if (folderId) {
					return <FolderNameRenderer props={params} />;
				} else if (title) {
					return title;
				} else {
					return null;
				}
			},
		},
		{
			field: "moreAction",
			maxWidth: 44,
			minWidth: 44,
			cellRenderer: (params) => <MoreActionRenderer props={params} />,
			cellClass: "more-action-btn-icon",
		},

		//! These columns are only to make the search field above the list to work when searching for folder or tag
		{
			field: "metadata.folderName.sv",
			hide: true,
			filter: "agSetColumnFilter",
		},
		{
			field: "tags",
			hide: true,
			filter: "agSetColumnFilter",
		},
	];
	return columnDefs;
}
