import { fromJS, List } from "immutable";

import {
	ATTACHMENTS_FETCH,
	ATTACHMENTS_CREATE,
	ATTACHMENTS_UPDATE,
	ATTACHMENTS_DELETE,
	ATTACHMENTS_SELECT,
	ATTACHMENTS_UNSELECT,
	ATTACHMENTS_GET,
	ATTACHMENTS_FETCH_MULTIPLE,
	COMPANY_RESET_REDUCER,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	attachments: {},
	attachment: null,
});

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case ATTACHMENTS_FETCH: {
			const { objId, attachments } = payload;

			return (state = state.setIn(["attachments", objId], attachments));
		}

		case ATTACHMENTS_FETCH_MULTIPLE: {
			const { mapObjIdToDocId, attachments } = payload;

			mapObjIdToDocId.forEach((attachmentIds, objId) => {
				if (!attachmentIds) {
					return;
				}

				const atts = attachmentIds.map((id) =>
					attachments.find((doc) => doc.get("id") === id),
				);

				state = state.setIn(["attachments", objId], atts);
			});

			return state;
		}

		case ATTACHMENTS_GET: {
			const { objId, attachment } = payload;

			if (!state.hasIn(["attachments", objId])) {
				state = state.setIn(["attachments", objId], List([attachment]));
			} else {
				const index = state
					.getIn(["attachments", objId])
					.findIndex((a) => a.get("id") === attachment.get("id"));

				if (index >= 0) {
					state = state.setIn(["attachments", objId, index], attachment);
				}
			}

			return state;
		}

		case ATTACHMENTS_CREATE: {
			const { objId, attachment } = payload;

			if (!state.hasIn(["attachments", objId])) {
				state = state.setIn(["attachments", objId], List());
			}

			return state.updateIn(["attachments", objId], (attachments) =>
				attachments.push(attachment),
			);
		}

		case ATTACHMENTS_UPDATE: {
			const { objId, attachment, updateAttachmentsList = true } = payload;
			const index = state
				.getIn(["attachments", objId])
				?.findIndex((a) => a.get("id") === attachment.get("id"));

			if (updateAttachmentsList && index >= 0) {
				state = state.setIn(["attachments", objId, index], attachment);
			}

			state = state.set("attachment", attachment);

			return state;
		}

		case ATTACHMENTS_DELETE: {
			const { objId, attachmentId } = payload;

			const index = state
				.getIn(["attachments", objId])
				.findIndex((a) => a.get("id") === attachmentId);

			if (index >= 0) {
				const attachment = state.getIn(["attachments", objId, index]);

				if (state.getIn(["attachment", "id"]) === attachment?.get("id")) {
					state = state.set("attachment", null);
				}

				state = state.removeIn(["attachments", objId, index]);
			}

			return state;
		}

		case ATTACHMENTS_SELECT: {
			const { objId, attachmentId } = payload;
			const index = state
				.getIn(["attachments", objId])
				.findIndex((a) => a.get("id") === attachmentId);

			if (index >= 0) {
				state = state.set(
					"attachment",
					state.getIn(["attachments", objId, index]),
				);
			}

			return state;
		}

		case ATTACHMENTS_UNSELECT:
			return state.set("attachment", null);

		case COMPANY_RESET_REDUCER:
			return INITIAL_STATE;

		default:
			return state;
	}
}
