import React, { useState } from "react";
import { connect } from "react-redux";
import OnboardingModal from "../../../../../dumb-components/onboarding/modal";
import FakeFooterSpacer from "../../../../../dumb-components/shared/modal/fake-footer-spacer";
import { ButtonTransparentIcon } from "../../../../../dumb-components/shared/button/";
import HeaderControls from "../../header-controls.container";
import ButtonGoToBilling from "../shared/btn-go-to-billing.container";
import { getGlobalRef } from "../../../../../components/helpers/global-refs.helper";
import { REF_DOCUMENTS_UPLOAD_DROPZONE } from "../../../../../constants/global-refs";
import { markOnboardAsRead } from "../../../../../actions/notifications.actions";
import { closeModal } from "../../../../../actions/modals.actions";

import {
	FirstStepBody,
	SecondStepBody,
} from "../../../../../dumb-components/onboarding/modals/documents/intro";
import useSubscriptionHelper from "../../../../../hooks/useSubscriptionHelper";

const DocumentsIntroContainer = ({
	markOnboardAsRead,
	closeModal,
	notificationId,
}) => {
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	const eSigningEnabled = subscriptionHelper?.eSigningEnabled;

	const [step, setStep] = useState(0);

	const openUploadView = () => {
		getGlobalRef(REF_DOCUMENTS_UPLOAD_DROPZONE).open();
		markModalAsRead();
	};

	const goToPageTwo = () => {
		setStep(1);
	};

	const onGoToBeginning = () => {
		setStep(0);
	};

	const markModalAsRead = () => {
		markOnboardAsRead(notificationId);
		closeModal();
	};

	const getSteps = () => {
		return [
			{
				body: (
					<FirstStepBody
						openUpload={openUploadView}
						showEsignInfo={goToPageTwo}
					/>
				),
				footer: {
					component: <FakeFooterSpacer height={36} />,
				},
			},
			{
				body: (
					<SecondStepBody
						billingButton={
							!eSigningEnabled ? (
								<ButtonGoToBilling
									onDone={markModalAsRead}
									tid="onboarding.modals.documents.intro.esign.more.btn.upgrade"
								/>
							) : null
						}
					/>
				),
				footer: {
					leftComponent: (
						<ButtonTransparentIcon
							onClick={onGoToBeginning}
							icon="faLongArrowLeft"
							size="xl"
						/>
					),
				},
			},
		];
	};

	return (
		<OnboardingModal
			title="TODO: Title"
			mode="steps"
			step={step}
			steps={getSteps()}
			dropdownComponent={<HeaderControls type="DROPDOWN" />}
		/>
	);
};

const mapStoreToProps = (store) => {
	return {
		notificationId: store.modals.getIn([
			"activeModal",
			"options",
			"notificationId",
		]),
	};
};

const mapActionsToProps = {
	markOnboardAsRead,
	closeModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(DocumentsIntroContainer);
