import React, { PureComponent } from "react";
import styled from "styled-components";
import { map } from "react-immutable-proptypes";
import { string } from "prop-types";

import FileIcon from "../../shared/file-icon/file-icon";
import Text from "../../shared/text/text";

const StyledItem = styled.div`
	display: flex;
	height: 50px;
	align-items: center;

	/* File icon */
	> :first-child {
		width: 36px;
		min-width: 36px;
		margin-right: ${({ theme }) => theme.spacing[4]};
	}
`;

export default class SimpleDocListItem extends PureComponent {
	static propTypes = {
		file: map.isRequired,
		title: string,
	};

	render = () => {
		const { file, title } = this.props;
		const ext = file.get("ext");

		return (
			<StyledItem>
				<FileIcon ext={ext} />
				<Text>{title}</Text>
			</StyledItem>
		);
	};
}
