import { Component } from "react";
import { connect } from "react-redux";
import {
	GRID_MODAL,
	CHART_MODAL,
	SHARE_ISSUE_GRID_MODAL,
} from "../../constants/modals";

const ELEMENT_ID = "beacon-container";

class ChatVisibilityContainer extends Component {
	elementCheckTimer = null;

	componentDidMount = () => {
		/**
		 * This is used to handle the few cases where an modal might appear
		 * before the actual chat element is mounted eg. onboarding modals
		 * upon page refresh.
		 */
		this.elementCheckTimer = setInterval(() => {
			const chatElem = document.getElementById(ELEMENT_ID);
			// Return if element wasn't yet mounted
			if (!chatElem) {
				return;
			}

			// If element was found, clear this timer
			clearInterval(this.elementCheckTimer);

			this.setChatElementVisibility();
		}, 150);
	};

	componentDidUpdate = (prevProps) => {
		if (this.props.modalIsOpen !== prevProps.modalIsOpen) {
			this.setChatElementVisibility();
		}
	};

	componentWillUnmount = () => {
		clearInterval(this.elementCheckTimer);
	};

	setChatElementVisibility = () => {
		const { modalIsOpen, forceHidden } = this.props;
		const chatElem = document.getElementById(ELEMENT_ID);

		if (!chatElem) {
			return;
		}

		if (forceHidden) {
			chatElem.style.visibility = "hidden";
			return;
		}

		// Modal was Open, hide icon
		if (modalIsOpen) {
			chatElem.style.visibility = "hidden";
			return;
		}

		// Modal was Closed, show icon
		if (!modalIsOpen) {
			chatElem.style.visibility = "visible";
			return;
		}
	};

	render = () => {
		return null;
	};
}
//includes
const mapStoreToProps = (store) => {
	const modalsThatDisablesSupport = [
		GRID_MODAL,
		CHART_MODAL,
		SHARE_ISSUE_GRID_MODAL,
	];

	return {
		modalIsOpen: modalsThatDisablesSupport.includes(
			store.modals.getIn(["activeModal", "name"]),
		),
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ChatVisibilityContainer);
