import { SET_PERSONAL_DOCUMENTS } from "../actions/types";

const INITIAL_STATE = {
	documents: {
		name: "personal-documents.my-documents",
		absolutePath: "",
		isFolder: true,
		isRoot: true,
		children: [],
	},
	folderIds: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_PERSONAL_DOCUMENTS:
			return {
				...state,
				documents: action.payload,
				folderIds: extractFolderIds(action.payload),
			};
		default:
			return state;
	}
};

function extractFolderIds(root) {
	const result = [];

	result.push(`${root.name}-${root.absolutePath}`);
	collectFolderIds(root.children, result);

	return result;
}

function collectFolderIds(children, result) {
	for (const child of children) {
		if (child.isFolder) {
			result.push(`${child.name}-${child.absolutePath}`);
		}

		if (child.children.length) {
			collectFolderIds(child.children, result);
		}
	}
}
