import React from "react";
import NumberFormat from "react-number-format";
import { TextField } from "mui-rff";
import { useSelector } from "react-redux";
import { enConfig, svConfig } from "/shared/helpers/number-formatter.helper";

const LocaleNumberFormat = (props) => {
	const language = useSelector((state) => state.i18n.language);
	let langConfig;
	if (language?.substring(0, 2) === "sv") {
		langConfig = svConfig;
	} else if (language?.substring(0, 2) === "en") {
		langConfig = enConfig;
	} else {
		langConfig = svConfig.default;
	}

	const { integerSeparator, decimal, round } =
		langConfig[props.inputtype] || svConfig.default;

	return (
		<NumberFormat
			{...props}
			type={"text"}
			customInput={TextField}
			isNumericString
			thousandSeparator={integerSeparator}
			decimalSeparator={decimal}
			fixedDecimalScale={true}
			allowNegative={false}
			decimalScale={round}
		/>
	);
};

export default LocaleNumberFormat;
