import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Bowser from "bowser";
import CredentialsComponent from "../../dumb-components/credentials/credentials-component";
import TopComponent from "../../dumb-components/credentials/top-component";
import BottomComponent from "../../dumb-components/credentials/bottom-component";
import BankIdModal from "../../dumb-components/shared/bankid-modal/bankid-modal";
import SignInContainer from "./signin.container";
import SignUpContainer from "./signup.container";
import ForgotPasswordContainer from "./forgot-password.container";
import history, { getQuery } from "../../interfaces/history";

import {
	resetTids,
	resetForgotPassword,
	resetCredentials,
	setBankIDText,
	cancelBankId,
} from "../../actions/credentials.actions";

const browser = Bowser.getParser(window.navigator.userAgent);

const IS_VALID_BROWSER = browser.satisfies({
	chrome: ">=71",
	firefox: ">=64",
	edge: ">=16",
	safari: ">=12",
});

const CredentialsContainer = ({
	location,
	isAuthenticated,
	resetCredentials,
	history,
	translationId,
	bankIdInfo,
	resetTids,
	resetForgotPassword,
	setBankIDText,
	cancelBankId,
}) => {
	const [authMode, setAuthMode] = useState(1); // 0 = Username / 1 = BankID
	const [section, setSection] = useState(null);
	const [ignoreBrowserVersion, setIgnoreBrowserVersion] = useState(false);

	const query = getQuery();

	useEffect(() => {
		setSectionName();
		setCorrectAuthmode();
		checkAuthenticated(isAuthenticated);

		if (query.type === "basic") {
			setAuthMode(0);
		}
	}, [location.pathname]);

	useEffect(() => {
		if (isAuthenticated) {
			history.push("/");
		}
	}, [isAuthenticated]);

	useEffect(() => {
		const fromForgotPassword = location.pathname.startsWith("/forgot-password");
		const refferedBySignin = query["ref"] && query["ref"] === "signin";

		setSectionName();
		setCorrectAuthmode();
		resetCredentialTidsIfNeeded(fromForgotPassword);

		if (!fromForgotPassword && !refferedBySignin) {
			resetCredentials();
		}
	}, [location.pathname]);

	const resetCredentialTidsIfNeeded = (fromForgotPassword) => {
		if ((translationId || bankIdInfo.bankIdMessage) && !fromForgotPassword) {
			resetTids();
		}

		// Reset forgot password credentials in store
		if (fromForgotPassword) {
			resetForgotPassword();
		}
	};

	const checkAuthenticated = (isAuthenticated) => {
		if (isAuthenticated) {
			history.push("/");
		}
	};

	const setSectionName = () => {
		let section;

		if (location.pathname.startsWith("/signin")) {
			section = "SIGNIN";
		} else if (location.pathname.startsWith("/signup")) {
			section = "SIGNUP";
		} else if (location.pathname.startsWith("/forgot-password")) {
			section = "FORGOTPW";
		}

		setSection(section);
	};

	const setCorrectAuthmode = () => {
		// If pathname was changed from Login to Register, always select BankID
		if (location.pathname.startsWith("/signup") && authMode !== 1) {
			onChangeAuthMode(1);
		}

		// If user is in forgot password, always select 'Username' as authmode
		if (location.pathname.startsWith("/forgot-password") && authMode !== 0) {
			onChangeAuthMode(0);
		}
	};

	const onChangeAuthMode = (val) => {
		setAuthMode(val);
		resetCredentialTidsIfNeeded();
	};

	const onChange = () => {
		resetCredentialTidsIfNeeded();
	};

	const onBottomBtnSwitchSection = () => {
		if (section === "SIGNIN") {
			history.push("/signup");
		} else if (section === "SIGNUP") {
			history.push("/signin");
		} else if (section === "FORGOTPW") {
			history.push("/signup");
		}
	};

	const onIgnoreBrowserVersion = (location) => {
		setIgnoreBrowserVersion(true);
		history.push(location);
	};

	const goToForgotPassword = () => {
		history.push("/forgot-password");
		onChangeAuthMode(0);
	};

	const onCancelForgotPassword = () => {
		history.push("/signin");
		onChangeAuthMode(1);
	};

	const onCancelBankId = () => {
		if (!bankIdInfo.orderRef) {
			setBankIDText(null);
			resetCredentials();
		} else {
			cancelBankId(bankIdInfo.orderRef);
		}
	};

	const renderTopComponent = (section) => {
		return (
			<TopComponent
				onChange={onChangeAuthMode}
				authMode={authMode}
				section={section}
			/>
		);
	};

	const renderMiddleComponent = (section) => {
		const query = getQuery();

		if (section === "SIGNIN") {
			return (
				<SignInContainer
					authMode={authMode}
					section={section}
					onChangeAuthMode={onChangeAuthMode}
					onChange={onChange}
					onForgotPassword={goToForgotPassword}
				/>
			);
		} else if (section === "SIGNUP") {
			return (
				<SignUpContainer
					authMode={authMode}
					onChange={onChange}
					onChangeAuthMode={onChangeAuthMode}
					section={section}
					referredBySignin={query["ref"] && query["ref"] === "signin"}
				/>
			);
		} else if (section === "FORGOTPW") {
			return (
				<ForgotPasswordContainer
					onCancel={onCancelForgotPassword}
					onChange={onChange}
				/>
			);
		}
	};

	const renderBottomComponent = (section) => {
		return (
			<BottomComponent section={section} onClick={onBottomBtnSwitchSection} />
		);
	};

	return (
		<>
			<CredentialsComponent
				isValidBrowser={IS_VALID_BROWSER}
				ignoreBrowserVersion={ignoreBrowserVersion}
				onIgnoreBrowserVersion={onIgnoreBrowserVersion}
				authMode={authMode}
				section={section}
				renderTopComponent={renderTopComponent}
				renderMiddleComponent={renderMiddleComponent}
				renderBottomComponent={renderBottomComponent}
			/>
			<BankIdModal
				isOpen={!!bankIdInfo.bankIdMessage}
				bankIdInfo={bankIdInfo}
				onCancel={onCancelBankId}
			/>
		</>
	);
};

const mapStoreToProps = (store) => {
	return {
		history: history,
		isAuthenticated: store.credentials.get("authenticated"),
		translationId: store.credentials.get("translationId"),
		bankIdInfo: {
			orderRef: store.credentials.get("orderRef"),
			desktopAppLink: store.credentials.get("desktopAppLink"),
			qrLink: store.credentials.get("qrLink"),
			bankIdMessage: store.credentials.get("bankIdMessage"),
			status: store.credentials.get("status"),
		},
	};
};

const mapActionsToProps = {
	resetTids,
	resetForgotPassword,
	resetCredentials,
	setBankIDText,
	cancelBankId,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(CredentialsContainer);
