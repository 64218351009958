import React from "react";
import { LoaderCircle } from "lucide-react";

const LoadingPanel = () => {
	return (
		<div className="flex w-full h-full items-center justify-center">
			<LoaderCircle size={24} className="animate-spin text-black/30" />
		</div>
	);
};

export default LoadingPanel;
