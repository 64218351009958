import React, { PureComponent } from "react";
import { bool, func, string } from "prop-types";
import { List, Map } from "immutable";
import styled, { css } from "styled-components";

import Modal from "../../shared/modal/modal";
import Text from "../../shared/text/text";
import Label from "../../shared/label/label";
import Select from "../../shared/select/src/select";
import Tooltip from "../../shared/tooltip/tooltip";
import ColoredContentWrapper from "../../shared/colored-content-wrapper/colored-content-wrapper";
import FooterRightControls from "../../shared/modal/footer-right-controls";
import { TransparentButton } from "../../shared/button-v2";
import Button from "../../shared/button/button";
import Alert from "../../shared/alert/alert";
import { Margin } from "styled-components-spacing";
import PinableSelectOption from "../../shared/object-filter/helper-components/pinable-select-option";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	${({ paddinglessContainer }) =>
		!paddinglessContainer &&
		css`
			padding: ${(props) => props.theme.spacing[1]}
				${(props) => props.theme.spacing[2]};
		`}
`;

const StyledManageDocumentWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const StyledSelectWrapper = styled.div`
	margin-bottom: 16px;
`;

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

export default class ManageAttachmentModal extends PureComponent {
	static propTypes = {
		onSave: func,
		onClose: func,
		displayStatus: string,
		onChange: func,
		isSmartMeeting: bool,
		isPdf: bool,
		toBeMerged: string,
		isLoading: bool,
		defaultDisplayStatus: string,
		setDefaultDisplayStatus: func,
		resetDefaultDisplayStatus: func,
		showWarningForDisplayStatus: bool,
		defaultDisplayStatusChanged: bool,
	};

	renderFooterComponent = () => {
		const { onClose, onSave, isLoading } = this.props;

		return (
			<FooterRightControls>
				<TransparentButton
					tid="generic.form.save"
					onClick={onSave}
					isLoading={isLoading}
				/>
				<TransparentButton
					tid="generic.form.close"
					textColor="midGrey"
					onClick={onClose}
					disabled={isLoading}
				/>
			</FooterRightControls>
		);
	};

	renderSetAsDefaultDisplayStatus = () => {
		const {
			displayStatus,
			defaultDisplayStatus,
			setDefaultDisplayStatus,
			resetDefaultDisplayStatus,
			defaultDisplayStatusChanged,
		} = this.props;

		if (
			displayStatus === defaultDisplayStatus &&
			!defaultDisplayStatusChanged
		) {
			return null;
		}

		if (displayStatus === defaultDisplayStatus && defaultDisplayStatusChanged) {
			return (
				<Button
					mode="link"
					tid="attachments.manage.display_status.reset_default"
					onClick={resetDefaultDisplayStatus}
				/>
			);
		}

		return (
			<>
				<Button
					mode="link"
					tid="attachments.manage.display_status.set_as_default"
					onClick={setDefaultDisplayStatus}
				/>
				{defaultDisplayStatusChanged && (
					<>
						<Text>&nbsp;|&nbsp;</Text>
						<Button
							mode="link"
							tid="attachments.manage.display_status.reset_default"
							onClick={resetDefaultDisplayStatus}
						/>
					</>
				)}
			</>
		);
	};

	formatOptionLabel = (option) => {
		const { defaultDisplayStatus } = this.props;
		return (
			<PinableSelectOption
				defaultValue={defaultDisplayStatus}
				labelIsTid={true}
				option={option}
			/>
		);
	};

	renderManageDocument = () => {
		const {
			displayStatus,
			onChange,
			isPdf,
			toBeMerged,
			showWarningForDisplayStatus,
		} = this.props;
		const options = List([
			Map({
				value: "hidden",
				label:
					"meetings.manage_attachment.options.document_visibility.hidden_yes",
			}),
			Map({
				value: "duringAfter",
				label:
					"meetings.manage_attachment.options.document_visibility.during_after",
			}),
			Map({
				value: "shown",
				label:
					"meetings.manage_attachment.options.document_visibility.full_visibility",
			}),
		]);
		const toBeMergedOptions = List([
			Map({
				value: "true",
				label: "meetings.manage_attachment.attach_to_protocol_pdf.yes",
			}),
			Map({
				value: "false",
				label: "meetings.manage_attachment.attach_to_protocol_pdf.no",
			}),
		]);

		return (
			<StyledManageDocumentWrapper>
				<StyledColoredContentWrapper type="secondary">
					<Label
						tid="meetings.manage_attachment.label.visible_to_attendees"
						rightComponent={this.renderSetAsDefaultDisplayStatus}
					>
						<StyledSelectWrapper>
							<Select
								fieldName="displayStatus"
								isClearable={false}
								onChange={onChange}
								options={options}
								value={displayStatus}
								isSearchable={false}
								formatOptionLabel={this.formatOptionLabel}
							/>
						</StyledSelectWrapper>
					</Label>
					{showWarningForDisplayStatus && (
						<Margin bottom={4}>
							<Alert
								mode="warning"
								icon
								tid="attachments.manage.display_status_warning"
							/>
						</Margin>
					)}
					<Text
						marginBottom={4}
						tid="meetings.manage_attachment.text.visible_to_attendees.1"
					/>
					{/*<Text marginBottom={4} tid='meetings.manage_attachment.text.visible_to_attendees.2' />*/}
					<Text tid="meetings.manage_attachment.text.visible_to_attendees.3" />
				</StyledColoredContentWrapper>

				<StyledColoredContentWrapper
					type={!isPdf ? "disabledLightGrey" : "secondary"}
				>
					<Label tid="meetings.manage_attachment.label.include_in_protocol_pdf">
						<Tooltip
							delayShow="instant"
							tid={
								!isPdf &&
								"meetings.manage_attachment.tooltip.attach_to_protocol_pdf.not_pdf"
							}
						>
							<StyledSelectWrapper>
								<Select
									fieldName="metadata.toBeMerged"
									labelIsTid={true}
									isClearable={false}
									onChange={onChange}
									options={toBeMergedOptions}
									value={toBeMerged === true ? "true" : "false"}
									isSearchable={false}
									isDisabled={!isPdf}
								/>
							</StyledSelectWrapper>
						</Tooltip>
					</Label>
					<Text
						tid={
							!isPdf
								? "meetings.manage_attachment.text.mute.only_pdf_in_protocol_pdf"
								: "meetings.manage_attachment.text.include_in_protocol_pdf"
						}
					/>
				</StyledColoredContentWrapper>
			</StyledManageDocumentWrapper>
		);
	};

	render = () => {
		return (
			<Modal
				title="meetings.manage_attachment.modal.title"
				isOpen={true}
				footerComponent={this.renderFooterComponent()}
				hSize="lm"
				vSize={75}
			>
				<Wrapper>{this.renderManageDocument()}</Wrapper>
			</Modal>
		);
	};
}
