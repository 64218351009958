/* 
  This HOC is used to resolve props before passing them to the wrapped component.
  Especially useful in the process of migrating from Redux based class-components
  to react-query-hook based functional components.

  Usage:

    class MyComponent extends React.Component {
      ...
    }
    
    export default withResolvedProps((props) => {
      const myValue = useHook()

      return { myProp: myValue };
    })(MyComponent)

*/

import React from "react";

const withResolvedProps = (resolver = () => ({})) => {
	return (Component) => {
		const ComponentWithQuery = (props) => {
			const resolverProps = resolver(props);

			return <Component {...props} {...resolverProps} />;
		};
		ComponentWithQuery.displayName = `withResolvedProps(${Component.displayName})`;

		return ComponentWithQuery;
	};
};

export default withResolvedProps;
