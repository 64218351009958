import React, { PureComponent } from "react";
import { bool, func } from "prop-types";
import { Map } from "immutable";
import { map } from "react-immutable-proptypes";
import styled, { css } from "styled-components";

import ScrollView from "./layout/scroll-view/scroll-view";
import Tooltip from "./tooltip/tooltip";
import Input from "./input/input";
import Button from "./button/button";
// import List from '../../shared/list/list'
import List from "./list/list";
import ListItem from "./list-item/list-item";
import Text from "./text/text";
import ColoredContentWrapper from "./colored-content-wrapper/colored-content-wrapper";
import { OutlinedButton } from "./button-v2";

const EllipsisTemplate = css`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	display: flex;
	flex-direction: row;
	padding: ${(props) => props.theme.spacing[4]}
		${(props) => props.theme.spacing[5]};
	margin: ${(props) => props.theme.spacing[4]}
		${(props) => props.theme.spacing[6]};

	> span {
		flex: 1;
		margin-right: ${(props) => props.theme.spacing[3]};
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: ${(props) => props.theme.spacing[3]};
	height: calc(100% - ${(props) => props.theme.spacing[5]});
`;

const StyledListItem = styled(ListItem)`
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeDark};
	margin-bottom: 0px;
	padding: 0;

	:first-child {
		border-top: 1px solid ${(props) => props.theme.colors.solitudeDark};
	}
`;

const StyledListItemContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	height: 100%;
`;

const StyledName = styled.div`
	${EllipsisTemplate}
	display: flex;
	flex: 1;
	min-width: 100px;
	width: 100px;
	padding: 0 ${(props) => props.theme.spacing[3]};
`;

const StyledDropdown = styled.div``;

const InnerWrapper = styled.div`
	padding: 0 ${(props) => props.theme.spacing[6]};
	flex: 1;
`;

const StyledOutlinedButton = styled.div``;

export default class ManageObjectModal extends PureComponent {
	static propTypes = {
		hasError: map.isRequired,
		isLoading: bool,
		onChange: func,
		onAdd: func,
		onSetTooltipRefs: func.isRequired,
		onHideTooltip: func,
		depoData: map.isRequired,
		allFunctions: map,
		renderDropdownComponent: func,
		isEditMode: bool,
		onCancelEdit: func,
		onEdit: func,
		onSetInputRef: func,
	};

	static defaultProps = {
		allFunctions: Map(),
		hasError: Map(),
	};

	renderControls = () => {
		const { isEditMode, onAdd, onCancelEdit, onEdit, isLoading } = this.props;

		if (isEditMode) {
			return (
				<>
					<Button
						onClick={onCancelEdit}
						isLoading={isLoading}
						tid="generic.form.cancel"
					/>
					<Button
						onClick={onEdit}
						isLoading={isLoading}
						tid="generic.edit"
						mode="primary"
					/>
				</>
			);
		}

		return (
			<StyledOutlinedButton>
				<OutlinedButton
					icon="faPlus"
					onClick={onAdd}
					isLoading={isLoading}
					color="midGrey"
					borderColor="buttonBorderGrey"
					background="lightestGrey"
				/>
			</StyledOutlinedButton>
		);
	};

	renderNewCustomFunctionForm = () => {
		const {
			hasError,
			onSetTooltipRefs,
			onHideTooltip,
			onChange,
			placeholderTid,
			isLoading,
			onAdd,
			depoData,
			onSetInputRef,
			onEdit,
			isEditMode,
		} = this.props;

		return (
			<StyledColoredContentWrapper type="solitudeMid">
				<Tooltip
					activeState="formValidator"
					place="bottom"
					tid={hasError.getIn(["name", 0])}
					ref={onSetTooltipRefs.bind(null, "name")}
				>
					<Input
						fieldName="name"
						placeholderTid={placeholderTid}
						onChange={onChange}
						onBlur={onHideTooltip}
						disabled={isLoading}
						onEnter={isEditMode ? onEdit : onAdd}
						value={depoData.getIn(["name"], "")}
						hasError={hasError.get("name")}
						onSetRef={onSetInputRef}
					/>
				</Tooltip>

				{this.renderControls()}
			</StyledColoredContentWrapper>
		);
	};

	renderListItem = (data, index) => {
		const { renderDropdownComponent } = this.props;
		const id = data.get("id");
		return (
			<StyledListItem key={index} striped>
				<StyledListItemContent>
					<StyledName>
						<Text singleLine>{data.get("name")}</Text>
					</StyledName>
					<StyledDropdown>{renderDropdownComponent(id)}</StyledDropdown>
				</StyledListItemContent>
			</StyledListItem>
		);
	};

	renderCustomFunctionsList = () => {
		let { tid, data } = this.props;

		if (!data) {
			return (
				<InnerWrapper>
					<Text align="center" tid={tid} hasItalic />
				</InnerWrapper>
			);
		}

		return (
			<List>
				<ScrollView noLeftMargin noRightMargin alwaysShow>
					<InnerWrapper>{data.map(this.renderListItem).toList()}</InnerWrapper>
				</ScrollView>
			</List>
		);
	};

	render = () => {
		return (
			<Wrapper>
				{this.renderNewCustomFunctionForm()}
				{this.renderCustomFunctionsList()}
			</Wrapper>
		);
	};
}
