import {
	validatePersonNumber,
	validateOrgNumber,
	validateInvonoCompanyId,
	validateInvonoUserId,
} from "./validation.module";

export const validateSsn = (value) => {
	return validatePersonNumber(value) ? undefined : "validation.ssn";
};

export const validateOrg = (value) => {
	return validateOrgNumber(value) ? undefined : "validation.org";
};

export const validateCompanyId = (value) => {
	return validateInvonoCompanyId(value)
		? undefined
		: "validation.invono_comapny_id";
};

export const validateUserId = (value) => {
	return validateInvonoUserId(value) ? undefined : "validation.invono_user_id";
};

export const required = (value) => {
	return value ? undefined : "validation.is_required";
};
