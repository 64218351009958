import React, { Component } from "react";
import { connect } from "react-redux";
import { string, func } from "prop-types";

import DropdownIconItem from "../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import Tooltip from "../../../../../dumb-components/shared/tooltip/tooltip";

import { deleteAttendee } from "../../../../../actions/meetings.actions";
import { getAttendeeCanBeRemovedData } from "../../../../../components/helpers/voting.helper";
import { MEETING_TYPE_STANDARD } from "/shared/constants";

class DeleteAttendeeContainer extends Component {
	static propTypes = {
		userId: string,
		onCloseMoreActionDropdown: func,
	};

	deleteAttendee = () => {
		const { deleteAttendee, userId, onCloseMoreActionDropdown } = this.props;

		onCloseMoreActionDropdown(userId);
		deleteAttendee(userId);
	};

	getAttendeeCanBeRemovedData = () => {
		const {
			votingList,
			userId,
			meetingId,
			proxies,
			attendee,
			isStandardMeeting,
		} = this.props;
		return getAttendeeCanBeRemovedData({
			votingList,
			userId,
			meetingId,
			proxies,
			attendee,
			isStandardMeeting,
		});
	};

	render = () => {
		const { TOOLTIP_STATES, activeState, isDisabled } =
			this.getAttendeeCanBeRemovedData();

		return (
			<Tooltip
				states={TOOLTIP_STATES}
				activeState={activeState}
				active={isDisabled}
			>
				<DropdownIconItem
					icon="faTrashAlt"
					tid="meetings.attendees.list.remove_attendee"
					onClick={this.deleteAttendee}
					disabled={isDisabled}
				/>
			</Tooltip>
		);
	};
}

const mapStoreToProps = (store, ownProps) => {
	return {
		votingList: store.votings.get("list"),
		meetingId: store.meetings.getIn(["meeting", "id"]),
		proxies: store.meetings.getIn(["meeting", "proxies"]),
		attendee: store.meetings.getIn(["meeting", "attendees", ownProps.userId]),
		isStandardMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) ===
			MEETING_TYPE_STANDARD,
	};
};

const mapActionsToProps = {
	deleteAttendee,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(DeleteAttendeeContainer);
