import React from "react";
import { bool, func } from "prop-types";
import styled, { css } from "styled-components";
import Modal from "../../shared/modal/modal";
import TransparentButton from "../../shared/button-v2/src/buttons/transparent-button";
import Icon from "../../shared/icon/icon";
import Text from "../../shared/text/text";
import { SHARE_ISSUE_GRID_MODAL } from "../../../constants/modals";
import { useSelector } from "react-redux";

const StyledModalHeaderBar = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 14px ${(props) => props.theme.spacing[6]};
	border-bottom: 1px solid ${(props) => props.theme.colors.border};
	min-height: 50px;
	gap: ${(props) => props.theme.spacing[5]};

	${(props) =>
		props.headerBackground &&
		css`
			background: ${(props) => props.theme.colors[props.headerBackground]};
		`}

	.gridModal__icon-wrapper {
		flex: 1;
		display: flex;
		align-items: center;
	}

	.gridModal__text-wrapper {
		margin-left: 10px;

		&:after {
			content: " ...";
			overflow: hidden;
			display: inline-block;
			vertical-align: bottom;
			width: 0;
			color: white;
			animation: animateEllipsis steps(5, end) 2100ms infinite;
		}
	}

	@keyframes animateEllipsis {
		100% {
			width: 20px;
		}
	}
`;

const GridModal = ({
	isOpen,
	onCancel,
	children,
	cellValueUpdated,
	rightHeaderComponent,
	isUploadingExcel,
	leftHeader,
}) => {
	const isShareIssueModal = useSelector((state) => {
		return (
			state.modals.getIn(["activeModal", "name"]) === SHARE_ISSUE_GRID_MODAL
		);
	});
	const isLoading = cellValueUpdated || isUploadingExcel;
	let loadingTid;

	if (cellValueUpdated) {
		loadingTid = "new_share_issue.grid.header.is_saving_data";
	} else if (isUploadingExcel) {
		loadingTid = "new_share_issue.grid.header.is_uploading_excel";
	}

	const getCloseButtonTid = () => {
		if (isShareIssueModal) {
			return "transactions.share_issue.grid.modal.header.button.done";
		}

		return "investments.shareholders.grid.modal.header.button.close";
	};

	const RenderLeftHeader = () => {
		if (leftHeader) {
			return (
				<div className="gridModal__left-header-wrapper">
					<div
						className="gridModal__header-text-wrapper"
						style={{ display: "flex" }}
					>
						<Text
							tid={leftHeader.tid}
							values={leftHeader.values}
							tag="h4"
							color="headerGrey"
						/>
						{leftHeader.secondTid && (
							<>
								&nbsp;
								<Text
									tid={leftHeader.secondTid}
									tag="h4"
									color="darkHeaderGrey"
								/>
							</>
						)}
					</div>
				</div>
			);
		}
		return null;
	};

	return (
		<Modal
			fullSize
			isOpen={isOpen}
			scrollableContent={false}
			headerComponent={
				<StyledModalHeaderBar headerBackground="java">
					<RenderLeftHeader />
					<div className="gridModal__icon-wrapper">
						{isLoading && (
							<>
								<Icon
									icon="faSpinner"
									size="normal"
									spin
									color="white"
									type="solid"
								/>
								{loadingTid && (
									<div className="gridModal__text-wrapper">
										<Text tid={loadingTid} color="white" />
									</div>
								)}
							</>
						)}
					</div>
					{rightHeaderComponent && rightHeaderComponent()}
					<div className="gridModal__transparent-button-wrapper">
						<TransparentButton
							tid={getCloseButtonTid()}
							textColor="white"
							onClick={onCancel}
							hasUnderline
						/>
					</div>
				</StyledModalHeaderBar>
			}
		>
			{children}
		</Modal>
	);
};

GridModal.propTypes = {
	isOpen: bool,
	onCancel: func,
	cellValueUpdated: bool,
};

export default GridModal;
