import React, { PureComponent } from "react";
import { func, bool } from "prop-types";
import { Map } from "immutable";
import styled, { css } from "styled-components";
import { list, map } from "react-immutable-proptypes";
import { FormattedNumber } from "react-intl";

import SimpleDarkInfoPanel from "../../shared/dark-info-panel/simple-dark-info-panel";
import ToggleSwitch from "../../shared/toggle-switch/toggle-switch";
import Text from "../../shared/text/text";
import ListTableColumn from "../../shared/list-table/list-table-column";
import { StyledListHeaderTemplate } from "../../shared/list/list-header.template";
import List from "../../shared/list/list";
import ListItem from "../../shared/list-item/list-item";
import { Select } from "../../shared/select";
import { ButtonTransparentIcon } from "../../shared/button";
import Dot from "../../shared/dot/dot";
import Icon from "../../shared/icon/icon";
import DocumentsHelper from "../../../components/helpers/documents.helper";
import { getUserName } from "../../../components/helpers/users";
import { PROXY_DOCUMENT_STATUS_OPTIONS } from "../../../constants/meetings";

const StyledOverrideInfoPanel = styled(SimpleDarkInfoPanel)`
	display: flex;
	justify-content: flex-end;
	margin-bottom: ${(props) => props.theme.spacing[4]};

	> :last-child {
		margin-left: ${(props) => props.theme.spacing[3]};
	}
`;

// Compensate InfoPanel height
const StyledList = styled(List)`
	height: calc(100% - 55px);
`;

const OverrideDocumentAcceptance = ({ onChange, value }) => (
	<StyledOverrideInfoPanel>
		<Text
			tid="meetings.agm.proxy_documents.info.no_acceptance_required"
			color="white"
		/>
		<ToggleSwitch onChange={onChange} checked={value} />
	</StyledOverrideInfoPanel>
);

const ListHeaderWrapper = styled.div`
	${StyledListHeaderTemplate};
	height: 50px;
`;

const StyledListItem = styled(ListItem)`
	display: flex;
	align-items: center;

	${(props) =>
		props.centered &&
		css`
			justify-content: center;
		`}
`;

const Name = ({ children }) => (
	<ListTableColumn minWidth={80} flex={1}>
		{children}
	</ListTableColumn>
);

const UploadedBy = ({ children }) => (
	<ListTableColumn flex={1} hideBelow={700}>
		{children}
	</ListTableColumn>
);

const FileSize = ({ children }) => (
	<ListTableColumn minWidth={100} hideBelow={800}>
		{children}
	</ListTableColumn>
);

const Status = styled(ListTableColumn).attrs({
	minWidth: 160,
})`
	display: flex;
	align-items: center;

	> :nth-child(2) {
		margin-left: ${(props) => props.theme.spacing[3]};
	}
`;

const Controls = styled(ListTableColumn).attrs({
	minWidth: 50,
})`
	display: flex;
	justify-content: flex-end;
	margin-right: 0;
`;

const LoadingComponent = () => (
	<StyledListItem centered>
		<Icon icon="faSpinner" size="lg" spin />
	</StyledListItem>
);

export default class ManageProxyDocuments extends PureComponent {
	static propTypes = {
		initialLoading: bool,
		isUploading: bool,
		onUpload: func,
		renderDropdown: func,
		documents: list,
		attendee: map,
		attendeesProxy: map,
		onChangeOverrideDocumentAcceptance: func,
		onChangeDocumentAcceptance: func,
		investorsCache: map,
		usersCache: map,
	};

	getDocumentStatusColor = (documentStatus) => {
		if (documentStatus === "DECLINED") {
			return "persianRed";
		}

		if (documentStatus === "ACCEPTED") {
			return "green";
		}

		if (documentStatus === "REVIEW") {
			return "yellowMid";
		}

		return "lightGrey";
	};

	renderListHeader = () => {
		const { isUploading, onUpload } = this.props;

		return (
			<ListHeaderWrapper>
				<Name>
					<Text
						tid="meetings.agm.proxy_documents.table.header.name"
						singleLine
					/>
				</Name>
				<UploadedBy>
					<Text
						tid="meetings.agm.proxy_documents.table.header.uploaded_by"
						singleLine
					/>
				</UploadedBy>
				<FileSize>
					<Text
						tid="meetings.agm.proxy_documents.table.header.filesize"
						singleLine
					/>
				</FileSize>
				<Status>
					<Text
						tid="meetings.agm.proxy_documents.table.header.status"
						singleLine
					/>
				</Status>
				<Controls>
					<ButtonTransparentIcon
						icon="faUpload"
						color="dodgerBlue"
						onClick={onUpload}
						isLoading={isUploading}
						noHorizontalMargin={true}
					/>
				</Controls>
			</ListHeaderWrapper>
		);
	};

	renderListItem = (doc, index) => {
		const {
			onChangeDocumentAcceptance,
			attendee,
			investorsCache,
			usersCache,
			renderDropdown,
		} = this.props;
		const file = doc.get("file");
		const filename = file.get("originalname");
		const { value: fileSize, unit: fileSizeUnit } =
			DocumentsHelper.getFileSize(file);
		const documentId = doc.get("id");
		const documentAcceptance = attendee.getIn([
			"proxyDocuments",
			documentId,
			"status",
		]);
		const createdBy = doc.get("createdBy");
		const createdByName = getUserName(
			Map({ userId: createdBy }),
			investorsCache,
			usersCache,
		);
		const documentStatusColor = this.getDocumentStatusColor(documentAcceptance);

		return (
			<StyledListItem key={index} striped>
				<Name>{filename}</Name>

				<UploadedBy>{createdByName}</UploadedBy>

				<FileSize>
					<FormattedNumber value={fileSize} format="filesize" /> {fileSizeUnit}
				</FileSize>

				<Status>
					<Dot bgColor={documentStatusColor} />
					<Select
						options={PROXY_DOCUMENT_STATUS_OPTIONS}
						menuPortalTarget={document.body}
						isSearchable={false}
						placeholderTid="generic.select"
						onChange={onChangeDocumentAcceptance.bind(null, documentId)}
						value={documentAcceptance}
						labelIsTid
					/>
				</Status>

				<Controls>{renderDropdown(doc)}</Controls>
			</StyledListItem>
		);
	};

	renderList = () => {
		const { initialLoading, isUploading, documents } = this.props;

		if (initialLoading) {
			return <LoadingComponent />;
		}

		if (!documents.size && !isUploading) {
			return (
				<StyledListItem centered>
					<Text tid="meetings.agm.proxy.documents_modal.no_documents_uploaded" />
				</StyledListItem>
			);
		}

		return documents.map(this.renderListItem);
	};

	render = () => {
		const { attendee, onChangeOverrideDocumentAcceptance, isUploading } =
			this.props;
		const ignoreProxyDocuments = attendee.get("ignoreProxyDocuments", false);

		return (
			<>
				<OverrideDocumentAcceptance
					onChange={onChangeOverrideDocumentAcceptance}
					value={ignoreProxyDocuments}
				/>
				<StyledList header={this.renderListHeader}>
					{this.renderList()}
					{isUploading && <LoadingComponent />}
				</StyledList>
			</>
		);
	};
}
