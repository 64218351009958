// All actions must include the query of companyId to specify what company we're trying to access.

import { fromJS } from "immutable";
import req from "../modules/request.module";
import { SHARING_FOLDERS_LIST, FOLDERS_LIST } from "./types";

export function listSharedFolders(companyId, silentError) {
	return function (dispatch) {
		return req
			.get(
				`/documents/sharing/folders?companyId=${companyId}${
					silentError ? "&silent=true" : ""
				}`,
			)
			.then((response) => {
				const { data } = response;

				if (data.silentError) {
					return;
				}

				const folderData = {
					folders: data.folders,
					mapParentToChildren: data.sharedMapToChildren,
				};
				dispatch({ type: SHARING_FOLDERS_LIST, payload: fromJS(folderData) });
				dispatch({
					type: FOLDERS_LIST,
					payload: fromJS({
						folders: response.data.sharedWithCompanyFolders,
						mapParentToChildren:
							response.data.sharedWithCompanyMapParentToChildren,
					}),
				});
			});
	};
}
