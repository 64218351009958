import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import ColoredContentWrapper from "../../../dumb-components/shared/colored-content-wrapper/colored-content-wrapper";
import Text from "../../../dumb-components/shared/text/text";
import MuiButton from "../../../mui-components/button/button";
import Button from "../../../dumb-components/shared/button/button";
import { useTranslation } from "react-i18next";
import { closeModal } from "../../../actions/modals.actions";
import { DOCUMENTS_ESIGNING_PASSWORD_PROTECTED_PDF_MODAL } from "../../../constants/modals";

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
`;

const PasswordProtectedPdfModal = ({ onRetry }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isOpen = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) ===
			DOCUMENTS_ESIGNING_PASSWORD_PROTECTED_PDF_MODAL,
	);

	const onClose = React.useCallback(() => {
		dispatch(closeModal());
	}, [dispatch, closeModal]);

	const _onRetry = React.useCallback(() => {
		onRetry();
		onClose();
	}, [onClose]);

	if (!isOpen) {
		return null;
	}

	return (
		<Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>
				{t("documents.esigning.password_protected_pdf_modal.title")}
			</DialogTitle>
			<DialogContent sx={{ height: "auto" }}>
				<StyledColoredContentWrapper type="secondary">
					<Text
						asHtml
						tid="documents.esigning.password_protected_pdf_modal.info"
					/>
					<div>
						<Button mode="primary" onClick={_onRetry}>
							{t(
								"documents.esigning.password_protected_pdf_modal.buttons.retry",
							)}
						</Button>
					</div>
				</StyledColoredContentWrapper>
			</DialogContent>
			<DialogActions>
				<MuiButton onClick={onClose}>
					{t("documents.esigning.password_protected_pdf_modal.buttons.close")}
				</MuiButton>
			</DialogActions>
		</Dialog>
	);
};

export default PasswordProtectedPdfModal;
