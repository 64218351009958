import React, { PureComponent } from "react";
import { string, number, object } from "prop-types";
import styled from "styled-components";
import SimpleDarkInfoPanel from "../dark-info-panel/simple-dark-info-panel";
import Text from "../text/text";

const StyledDarkInfoPanel = styled(SimpleDarkInfoPanel)`
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: 68px;

	@media (max-width: 1200px) {
		flex-direction: column;
	}
`;

const StyledTextWrapper = styled.div`
	flex: 1;
`;

const StyledComponentWrapper = styled.div`
	margin-left: ${(props) => props.theme.spacing[2]};
	min-width: 200px;

	@media (max-width: 1200px) {
		margin-top: ${(props) => props.theme.spacing[3]};
	}
`;

export default class StatusPanel extends PureComponent {
	static propTypes = {
		infoTid: string,
		passedSubTopics: number,
		allSubTopics: number,
		dropdownComponent: object,
	};

	render = () => {
		const { infoTid, passedSubTopics, allSubTopics, dropdownComponent } =
			this.props;

		return (
			<StyledDarkInfoPanel>
				<StyledTextWrapper>
					<Text
						color="white"
						tid={infoTid}
						values={{ passedSubTopics, allSubTopics }}
					/>
				</StyledTextWrapper>

				{dropdownComponent && (
					<StyledComponentWrapper>{dropdownComponent}</StyledComponentWrapper>
				)}
			</StyledDarkInfoPanel>
		);
	};
}
