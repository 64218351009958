import React, { PureComponent } from "react";
import styled from "styled-components";
import { number, func } from "prop-types";

import ScrollView from "../../shared/layout/scroll-view/scroll-view";

const WIDTH = "300px";

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: ${WIDTH};
	margin: -${(props) => props.theme.spacing[3]} 0;
`;

const StyledInner = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	> div {
		width: ${WIDTH};
	}
`;

const AddItemContainer = styled.div`
	display: flex;
	height: 60px;
	justify-content: center;
	background-color: #ecf0f5;
	border-block-start: 1px solid ${(props) => props.theme.colors.border};
`;

export default class CompanyMenuList extends PureComponent {
	static propTypes = {
		maxHeight: number,
		onSetRef: func,
		onSetScrollbarRef: func,
		renderAddCompanyItem: func,
	};

	render = () => {
		const {
			companiesSize,
			children,
			maxHeight,
			onSetRef,
			onSetScrollbarRef,
			renderAddCompanyItem,
		} = this.props;

		let minHeight = companiesSize * 60;
		minHeight = minHeight === 0 ? 60 : minHeight;

		if (minHeight > maxHeight) {
			minHeight = maxHeight;
		}

		return (
			<StyledWrapper ref={onSetRef}>
				<ScrollView
					autoHeightMin={minHeight}
					autoHeightMax={maxHeight}
					scrollbarRef={onSetScrollbarRef}
					alwaysShow
					noLeftMargin
					noRightMargin
				>
					<StyledInner>{children}</StyledInner>
				</ScrollView>

				{renderAddCompanyItem && (
					<AddItemContainer>{renderAddCompanyItem()}</AddItemContainer>
				)}
			</StyledWrapper>
		);
	};
}
