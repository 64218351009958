import React from "react";
import Text from "../../text/text";

const LinkRenderer = (props) => {
	const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

	return (
		<a href={cellValue} target="_blank" rel="noopener noreferrer">
			<Text tid="ag_grid.link_renderer.view" hasUnderline />
		</a>
	);
};

export default LinkRenderer;
