import React, { Component } from "react";
import { connect } from "react-redux";
import { Margin } from "styled-components-spacing";
import { clearTask } from "../../actions/tasks.actions";
import Content from "../../dumb-components/shared/layout/content/content";
import SplitView from "../../dumb-components/shared/layout/split-view/split-view";
import ScrollView from "../../dumb-components/shared/layout/scroll-view/scroll-view";
import ScrollViewHeader from "../../dumb-components/shared/scroll-view-header/scroll-view-header";
import TaskListContainer from "./task-list.container";
import ProjectsContainer from "./projects.container";
import TasksCreateModalBtnCtrl from "./task-create-modal-btn-controller.container";
import RightViewContainer from "./right-view.continer";
import TasksFilterByContainer from "./tasks-filter-by.container";
import Box from "../../dumb-components/shared/layout/box/box";
import TaskAdvancedFilterButtonContainer from "./task-advanced-filter-button.container";
import TasksFilterAndSortContainer from "./tasks-filter-and-sort.container";
import TasksSettingsBarContainer from "./tasks-settings-bar.container";
import TaskDetailsContainer from "./task-details.container";
import { getQuery } from "../../interfaces/history";

class TasksContainer extends Component {
	state = {
		totalTasksFiltered: 0,
		totalTasksFilteredShown: 0,
		filterIsOpen: false,
		prevTask: null,
	};

	getComponentBasePath = () => {
		const {
			location: { pathname },
		} = this.props;
		const path = pathname;
		const pathArray = path.split("tasks");
		return pathArray[0] + "tasks";
	};

	toggleAdvancedFilter = () => {
		const { clearTask, task, location, history } = this.props;
		const { filterIsOpen, prevTask } = this.state;
		const querystr = location.search;
		const basePath = this.getComponentBasePath();

		// if there are no selected task in the list and there was before filter was open, we select that task again
		if (!task && prevTask) {
			history.push({
				pathname: `${basePath}/${prevTask.get("id")}`,
				search: querystr,
			});
			this.setState({ prevTask: null });
		} else {
			history.push({
				pathname: basePath,
				search: querystr,
			});
			this.setState({ prevTask: task });
		}

		clearTask();
		this.setState({ filterIsOpen: !filterIsOpen });
	};

	closeAdvancedFilter = () => {
		this.setState({ filterIsOpen: false, prevTask: null });
	};

	renderLeftSplitViewHeader = () => {
		const { totalTasksFiltered, totalTasksFilteredShown } = this.state;
		const basePath = this.getComponentBasePath();

		return (
			<ScrollViewHeader>
				<TasksFilterByContainer
					totalTasks={totalTasksFiltered}
					totalTasksShown={totalTasksFilteredShown}
				/>
				<Margin left={3}>
					<Box>
						<TasksCreateModalBtnCtrl basePath={basePath} />
						<TaskAdvancedFilterButtonContainer
							onClick={this.toggleAdvancedFilter}
						/>
					</Box>
				</Margin>
			</ScrollViewHeader>
		);
	};

	renderLeftSplitView = () => {
		const {
			location,
			match: { params },
		} = this.props;
		const basePath = this.getComponentBasePath();
		const query = getQuery();

		return (
			<ScrollView
				header={this.renderLeftSplitViewHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
			>
				<TasksFilterAndSortContainer
					filterTasksChanged={(totalTasksFilteredShown, totalTasksFiltered) => {
						this.setState({ totalTasksFilteredShown, totalTasksFiltered });
					}}
				>
					<TaskListContainer
						scrollbarRef={this.scrollbarRef}
						basePath={basePath}
						params={params}
						location={location}
						projectId={query.project}
						onTaskClick={this.closeAdvancedFilter}
					/>
				</TasksFilterAndSortContainer>
			</ScrollView>
		);
	};

	renderRightSplitView = () => {
		const { location, match } = this.props;
		const { filterIsOpen } = this.state;
		const basePath = this.getComponentBasePath();

		return (
			<RightViewContainer
				location={location}
				params={match.params}
				basePath={basePath}
				filterIsOpen={filterIsOpen}
				onFilterClose={this.toggleAdvancedFilter}
			>
				{match.params.id && (
					<TaskDetailsContainer
						match={match}
						location={this.props.location}
						history={this.props.history}
					/>
				)}
			</RightViewContainer>
		);
	};

	renderHeader = () => {
		const {
			match: { params },
		} = this.props;

		return (
			<Box>
				<ProjectsContainer
					basePath={this.getComponentBasePath()}
					params={params}
				/>

				<TasksSettingsBarContainer />
			</Box>
		);
	};

	render = () => {
		return (
			<Content header={this.renderHeader} tightenHeader>
				<SplitView
					leftComponent={this.renderLeftSplitView}
					rightComponent={this.renderRightSplitView}
				/>
			</Content>
		);
	};
}

function mapStoreToProps(store) {
	return {
		task: store.tasks.get("task"),
	};
}

const mapActionsToProps = {
	clearTask,
};

export default connect(mapStoreToProps, mapActionsToProps)(TasksContainer);
