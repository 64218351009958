import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../actions/modals.actions";
import {
	createPersonalTransaction,
	deletePersonalTransaction,
	updatePersonalTransaction,
} from "../../../actions/my-records.actions";
import { InvestmentsContext } from "../investment.container";
import { MY_RECORDS_EDIT_TRANSACTION_MODAL } from "../../../constants/modals";
import {
	getMergedShareType,
	getShareTypeClass,
	getShareTypeName,
} from "../../../components/helpers/shares";
import { checkTransactionMissingValues } from "../../../components/helpers/my-records-helpers";
import { useConfirmContext } from "../../shared/confirm/confirm.context";

import AddTransactionModal from "../../../dumb-components/investment/my-records/add-transaction-modal";
import { List, fromJS } from "immutable";

import {
	PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
	PERSONAL_TRANSACTION_TYPE_LEVELING,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
	PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
	PERSONAL_TRANSACTION_TYPE_SPLIT,
	PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
	PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES,
	PERSONAL_TRANSACTION_TYPE_GENESIS,
} from "/shared/constants";

export default function AddTransactionModalContainer({ onChangeCallback }) {
	const { selectedPersonalInvestment, selectedInvestment } =
		useContext(InvestmentsContext);
	const confirm = useConfirmContext();

	const [initialValues, setInitialValues] = useState({
		shareTypes: [{}],
	});

	const isEditTransactionModalOpen = useSelector((state) => {
		return (
			state.modals.getIn(["activeModal", "name"]) ===
			MY_RECORDS_EDIT_TRANSACTION_MODAL
		);
	});

	const transactionId = useSelector((state) => {
		return state.modals.getIn(["activeModal", "options", "id"]);
	});

	const personalAttachments = useSelector((state) => {
		return state.personalAttachments.getIn([
			"attachments",
			"personalAttachments",
		]);
	});

	const [shareTypeNameOptions, setShareTypeNameOptions] = useState([]);
	const [previousTransactionValues, setPreviousTransactionValues] = useState();

	const dispatch = useDispatch();
	//const label = t('personal-documents.folder-name')

	useEffect(() => {
		if (isEditTransactionModalOpen) {
			const transactionToUpdate = selectedPersonalInvestment
				.get("transactions")
				.find((transaction) => {
					return transaction.get("id") === transactionId;
				});
			if (transactionToUpdate) {
				const transactionType = transactionToUpdate.get("transactionType");
				const date = transactionToUpdate.get("date");
				const numOfTotalCompanyShares = transactionToUpdate.getIn([
					"shareData",
					"numOfTotalCompanyShares",
				]);
				const totalVotes = transactionToUpdate.getIn([
					"shareData",
					"totalVotes",
				]);
				const companyTotalValue = transactionToUpdate.getIn([
					"shareData",
					"postMoney",
				]);
				const myTotalValue = transactionToUpdate.getIn([
					"shareData",
					"myPostMoney",
				]);
				const capitalInvested = transactionToUpdate.getIn([
					"shareData",
					"capitalInvested",
				]);
				const numOfTotalShares = transactionToUpdate.getIn([
					"shareData",
					"numOfTotalShares",
				]);
				const myTotalVotes = transactionToUpdate.getIn([
					"shareData",
					"myTotalVotes",
				]);
				const transferFrom = transactionToUpdate.getIn([
					"handlerData",
					"transferFrom",
				]);
				const transferTo = transactionToUpdate.getIn([
					"handlerData",
					"transferTo",
				]);
				const splitQuotaFrom = transactionToUpdate.getIn([
					"handlerData",
					"splitQuotaFrom",
				]);
				const splitQuotaTo = transactionToUpdate.getIn([
					"handlerData",
					"splitQuotaTo",
				]);
				const link = transactionToUpdate.get("link");

				const transactionValues = {
					transactionType: transactionType,
					date: date,
					numOfTotalCompanyShares: numOfTotalCompanyShares,
					totalVotes: totalVotes,
					companyTotalValue: companyTotalValue,
					myTotalValue: myTotalValue,
					capitalInvested: capitalInvested,
					numOfTotalShares: numOfTotalShares,
					myTotalVotes: myTotalVotes,
					transferFrom: transferFrom,
					transferTo: transferTo,
					splitQuotaFrom: splitQuotaFrom,
					splitQuotaTo: splitQuotaTo,
					...(link ? { link: link.toJS() } : { link: { isDirty: false } }),
				};

				Object.keys(transactionValues).forEach((key) => {
					if (transactionValues[key] === undefined) {
						delete transactionValues[key];
					}
				});

				if (
					transactionToUpdate.get("transactionType") === "conversionOfShares"
				) {
					transactionValues.conversionOfSharesNameFrom = getShareTypeName(
						transactionToUpdate.getIn([
							"handlerData",
							"conversionOfSharesFrom",
						]),
					).trim();
					transactionValues.conversionOfSharesClassFrom = getShareTypeClass(
						transactionToUpdate.getIn([
							"handlerData",
							"conversionOfSharesFrom",
						]),
					);

					transactionValues.conversionOfSharesNameTo = getShareTypeName(
						transactionToUpdate.getIn(["handlerData", "conversionOfSharesTo"]),
					).trim();
					transactionValues.conversionOfSharesClassTo = getShareTypeClass(
						transactionToUpdate.getIn(["handlerData", "conversionOfSharesTo"]),
					);
				}

				const types = transactionToUpdate.getIn(["handlerData", "types"]);

				const shareTypes =
					types &&
					types.map((type) => {
						const modifiedType = {};

						if (
							type.get("shareType") &&
							transactionToUpdate.get("transactionType") !==
								"conversionOfShares"
						) {
							modifiedType.shareTypeName = getShareTypeName(
								type.get("shareType"),
							).trim();
							modifiedType.shareTypeClass = getShareTypeClass(
								type.get("shareType"),
							);
						}
						const totalSharesEmitted = type.get("totalSharesEmitted");
						if (totalSharesEmitted || totalSharesEmitted === 0) {
							modifiedType.totalSharesEmitted = totalSharesEmitted;
						}

						const latestPrice = type.get("latestPrice");
						if (latestPrice || latestPrice === 0) {
							modifiedType.latestPrice = latestPrice;
						}

						const purchasePrice = type.get("purchasePrice");
						if (purchasePrice || purchasePrice === 0) {
							modifiedType.purchasePrice = purchasePrice;
						}

						const votesPerShare = type.get("votesPerShare");
						if (votesPerShare || votesPerShare === 0) {
							modifiedType.votesPerShare = votesPerShare;
						}

						const myShares = type.get("myShares");
						if (myShares || myShares === 0) {
							modifiedType.myShares = myShares;
						}

						return modifiedType;
					});

				transactionValues.shareTypes = shareTypes
					? shareTypes.toJS()
					: [undefined];

				setInitialValues(transactionValues);
			}
		}
	}, [isEditTransactionModalOpen, transactionId]);

	useEffect(() => {
		const transactions = selectedPersonalInvestment.get("transactions");
		if (transactions) {
			const shareTypes = transactions.map((transaction) => {
				return transaction.getIn(["handlerData", "types"]);
			});

			let shareTypeNames = List();

			shareTypes.forEach((shareType) => {
				shareType &&
					shareType.forEach((shareTypeName) => {
						shareTypeNames =
							shareTypeName.get("shareType") &&
							shareTypeNames.push(
								getShareTypeName(shareTypeName.get("shareType")).trim(),
							);
					});
			});

			let uniqueShareTypeNames = shareTypeNames.toSet().map((shareType) => {
				return { label: shareType };
			});

			setShareTypeNameOptions(uniqueShareTypeNames.toJS());
		}
	}, [selectedPersonalInvestment]);

	useEffect(() => {
		const transactions = selectedPersonalInvestment.get("transactions");

		if (transactions) {
			let previousTransaction;

			let openedTransactionIndex = transactions.findIndex((transaction) => {
				return transaction.get("id") === transactionId;
			});

			if (openedTransactionIndex === -1) {
				openedTransactionIndex = transactions.size - 1;
			}

			let i = openedTransactionIndex;

			if (isEditTransactionModalOpen) {
				while (i-- && i >= 0) {
					if (transactions.getIn([i, "isComplete"]) === true) {
						previousTransaction = transactions.get(i);
						break;
					}
				}
			} else {
				while (i >= 0) {
					if (transactions.getIn([i, "isComplete"]) === true) {
						previousTransaction = transactions.get(i);
						break;
					}

					i--;
				}
			}

			if (!previousTransaction) {
				return;
			}

			const numOfTotalCompanyShares = previousTransaction.getIn([
				"shareData",
				"numOfTotalCompanyShares",
			]);
			const totalVotes = previousTransaction.getIn(["shareData", "totalVotes"]);
			const myTotalVotes = previousTransaction.getIn([
				"shareData",
				"myTotalVotes",
			]);
			const companyTotalValue = previousTransaction.getIn([
				"shareData",
				"postMoney",
			]);
			const myTotalValue = previousTransaction.getIn([
				"shareData",
				"myPostMoney",
			]);

			const transactionValues = {
				...(numOfTotalCompanyShares && {
					numOfTotalCompanyShares: numOfTotalCompanyShares,
				}),
				...(totalVotes && { totalVotes: totalVotes }),
				...(myTotalVotes && { myTotalVotes: myTotalVotes }),
				...(companyTotalValue && { companyTotalValue: companyTotalValue }),
				...(myTotalValue && { myTotalValue: myTotalValue }),
			};

			transactionValues.shareTypes = [undefined];

			setPreviousTransactionValues(transactionValues);
		}
	}, [selectedPersonalInvestment, isEditTransactionModalOpen, transactionId]);

	const getTooltipActiveState = (formProps) => {
		if (!formProps.values.transactionType && !formProps.values.date) {
			return "noTransactionTypeOrDate";
		}

		if (formProps.values.date?.invalid && formProps.errors.transactionType) {
			return "wrongDateAndTransactionTypeInput";
		}

		if (formProps.values.date?.invalid) {
			return "wrongDateInput";
		}

		if (!formProps.values.transactionType) {
			return "noTransactionType";
		}

		if (!formProps.values.date) {
			return "noDate";
		}

		if (isEditTransactionModalOpen && !formProps.dirty) {
			return "editModeAndFormNotDirty";
		}

		const shareTypeErrors = formProps.errors.shareTypes;
		const shareTypeWithError =
			shareTypeErrors &&
			shareTypeErrors.find((shareType) => shareType?.errorType);
		if (shareTypeWithError) {
			return shareTypeWithError.errorType;
		}
	};

	const onClose = () => {
		dispatch(closeModal());
	};

	const doDelete = () => {
		const selectedInvestmentTransactions =
			selectedInvestment && selectedInvestment.get("transactions");

		dispatch(
			deletePersonalTransaction(
				selectedPersonalInvestment.get("id"),
				transactionId,
				selectedInvestmentTransactions,
				"personalAttachments",
			),
		);
		selectedPersonalInvestment.get("autoSynchCompanyData") &&
			onChangeCallback();
	};

	const onDelete = async (callbackFn) => {
		const hasAttachments =
			personalAttachments.get(transactionId) &&
			personalAttachments.get(transactionId).size > 0;

		if (hasAttachments) {
			const result = await confirm.showConfirmation(
				"investment.confirm_delete_transaction.title",
				[
					{ tid: "investment.confirm_delete_transaction.question" },
					{ tid: "investment.confirm_delete_transaction.message" },
				],
			);

			if (result) {
				doDelete();
				callbackFn?.();
			}
		} else {
			doDelete();
			callbackFn?.();
		}
	};

	const onSaveData = (formValues, formErrors) => {
		const isValid = formErrors.getState().valid;

		if (!isValid) {
			return;
		}

		const selectedInvestmentTransactions =
			selectedInvestment && selectedInvestment.get("transactions");
		dispatch(
			createPersonalTransaction(
				selectedPersonalInvestment.get("id"),
				getTransaction(formValues),
				selectedInvestmentTransactions,
			),
		);
		dispatch(closeModal());

		const firstTransaction =
			selectedPersonalInvestment.get("transactions") &&
			selectedPersonalInvestment.get("transactions").size === 0;

		if (
			firstTransaction ||
			selectedPersonalInvestment.get("autoSynchCompanyData")
		) {
			onChangeCallback();
		}
	};

	const onUpdate = (formValues) => {
		const selectedInvestmentTransactions =
			selectedInvestment && selectedInvestment.get("transactions");

		dispatch(
			updatePersonalTransaction(
				selectedPersonalInvestment.get("id"),
				transactionId,
				getTransaction(formValues),
				selectedInvestmentTransactions,
			),
		);
		selectedPersonalInvestment.get("autoSynchCompanyData") &&
			onChangeCallback();
	};

	const getTransaction = useCallback((formValues) => {
		const {
			myTotalValue,
			companyTotalValue,
			numOfTotalCompanyShares,
			totalVotes,
			myTotalVotes,
			capitalInvested,
			numOfTotalShares,
			shareTypes,
			transferFrom,
			transferTo,
			splitQuotaFrom,
			splitQuotaTo,
			conversionOfSharesNameFrom,
			conversionOfSharesClassFrom,
			conversionOfSharesNameTo,
			conversionOfSharesClassTo,
			date,
			transactionType,
			link,
		} = formValues;
		const transactionId = link?.transactionId;
		const shareData = {
			myPostMoney: myTotalValue,
			postMoney: companyTotalValue,
			numOfTotalCompanyShares: numOfTotalCompanyShares,
			totalVotes: totalVotes,
			myTotalVotes: myTotalVotes,
		};

		if (capitalInvested === 0 || capitalInvested) {
			shareData.capitalInvested = capitalInvested;
		}
		if (numOfTotalShares === 0 || numOfTotalShares) {
			shareData.numOfTotalShares = numOfTotalShares;
		}

		let modifiedShareTypes;
		let handlerData;
		if (transactionType !== "snapshot") {
			modifiedShareTypes =
				shareTypes &&
				shareTypes[0] &&
				shareTypes.map((shareType) => {
					const {
						shareTypeName,
						shareTypeClass,
						totalSharesEmitted,
						latestPrice,
						votesPerShare,
						myShares,
						purchasePrice,
					} = shareType;

					let _shareTypeName;
					const _shareTypeClass = shareTypeClass && shareTypeClass.trim();

					if (typeof shareTypeName === "object") {
						_shareTypeName = shareTypeName.label && shareTypeName.label.trim();
					} else {
						_shareTypeName = shareTypeName && shareTypeName.trim();
					}

					const _shareType = getMergedShareType(
						_shareTypeName,
						_shareTypeClass,
					);

					let _totalSharesEmitted;
					let _latestPrice;
					let _votesPerShare;
					let _myShares;
					let _purchasePrice;

					switch (transactionType) {
						case PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES: {
							_votesPerShare = votesPerShare ? votesPerShare : 0;
							break;
						}

						case PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES: {
							_myShares = myShares ? myShares : 0;
							break;
						}
						case PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE:
						case PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES:
						case PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE:
						case PERSONAL_TRANSACTION_TYPE_LEVELING:
						case PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS:
						case PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE:
						case PERSONAL_TRANSACTION_TYPE_GENESIS: {
							_totalSharesEmitted = totalSharesEmitted ? totalSharesEmitted : 0;
							_latestPrice = latestPrice ? latestPrice : 0;
							_votesPerShare = votesPerShare ? votesPerShare : 0;
							_myShares = myShares ? myShares : 0;
							break;
						}
						case PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL: {
							_purchasePrice = purchasePrice ? purchasePrice : 0;
							_myShares = myShares ? myShares : 0;
							break;
						}
						case PERSONAL_TRANSACTION_TYPE_SPLIT: {
							_latestPrice = latestPrice ? latestPrice : 0;
							_purchasePrice = purchasePrice ? purchasePrice : 0;
							_myShares = myShares ? myShares : 0;
							break;
						}
						case PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY:
						case PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL: {
							_latestPrice = latestPrice ? latestPrice : 0;
							_votesPerShare = votesPerShare ? votesPerShare : 0;
							_purchasePrice = purchasePrice ? purchasePrice : 0;
							_myShares = myShares ? myShares : 0;
							break;
						}

						default:
							break;
					}

					return {
						shareType: _shareType,
						totalSharesEmitted: _totalSharesEmitted,
						latestPrice: _latestPrice,
						votesPerShare: _votesPerShare,
						myShares: _myShares,
						purchasePrice: _purchasePrice,
					};
				});

			handlerData = {
				...(modifiedShareTypes && { types: modifiedShareTypes }),
			};
		}

		if (transferFrom) {
			handlerData.transferFrom = transferFrom;
		}
		if (transferTo) {
			handlerData.transferTo = transferTo;
		}

		if (splitQuotaFrom) {
			handlerData.splitQuotaFrom = splitQuotaFrom;
		}
		if (splitQuotaTo) {
			handlerData.splitQuotaTo = splitQuotaTo;
		}

		if (conversionOfSharesNameFrom) {
			let _conversionOfSharesNameFrom;
			const _conversionOfSharesClassFrom =
				conversionOfSharesClassFrom && conversionOfSharesClassFrom.trim();

			if (typeof conversionOfSharesNameFrom === "object") {
				_conversionOfSharesNameFrom =
					conversionOfSharesNameFrom.label &&
					conversionOfSharesNameFrom.label.trim();
			} else {
				_conversionOfSharesNameFrom =
					conversionOfSharesNameFrom && conversionOfSharesNameFrom.trim();
			}

			const _conversionOfSharesFrom = getMergedShareType(
				_conversionOfSharesNameFrom,
				_conversionOfSharesClassFrom,
			);

			handlerData.conversionOfSharesFrom = _conversionOfSharesFrom;
		}
		if (conversionOfSharesNameTo) {
			let _conversionOfSharesNameTo;
			const _conversionOfSharesClassTo =
				conversionOfSharesClassTo && conversionOfSharesClassTo.trim();

			if (typeof conversionOfSharesNameTo === "object") {
				_conversionOfSharesNameTo =
					conversionOfSharesNameTo.label &&
					conversionOfSharesNameTo.label.trim();
			} else {
				_conversionOfSharesNameTo =
					conversionOfSharesNameTo && conversionOfSharesNameTo.trim();
			}

			const _conversionOfSharesTo = getMergedShareType(
				_conversionOfSharesNameTo,
				_conversionOfSharesClassTo,
			);

			handlerData.conversionOfSharesTo = _conversionOfSharesTo;
			if (handlerData.types && handlerData.types[0]) {
				handlerData.types[0].shareType = _conversionOfSharesTo;
			} else {
				handlerData.types = [{ shareType: _conversionOfSharesTo }];
			}
		}

		const transaction = {
			date: date,
			transactionType: transactionType,
			...(transactionId && {
				link: {
					transactionId: transactionId,
					isDirty: link?.isDirty && link.isDirty,
				},
			}),
			handlerData,
			shareData,
		};
		const hasMissingValues = checkTransactionMissingValues(fromJS(transaction));
		transaction.isComplete = !hasMissingValues;

		return transaction;
	});

	return (
		<AddTransactionModal
			onSaveData={onSaveData}
			initialValues={initialValues}
			onClose={onClose}
			isEditTransactionModalOpen={isEditTransactionModalOpen}
			onDelete={onDelete}
			onUpdate={onUpdate}
			getTooltipActiveState={getTooltipActiveState}
			shareTypeNameOptions={shareTypeNameOptions}
			previousTransactionValues={previousTransactionValues}
		/>
	);
}
