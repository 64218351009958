/**
 * Store
 * @module store
 */

import { createStore as reduxCreateStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers/reducers";
import socketMiddleware from "./socket.middleware";

let store;

// For usage when creating a new store (i.e. inside unit tests etc.)
// Ideally, this is the only function we'd call, and only once (where we inject the store in the provider),
// but it's currently not possible (see next comment)
export function createStore() {
	const createStoreWithMiddleware = applyMiddleware(
		reduxThunk,
		socketMiddleware(`${location.protocol}//${location.hostname}`),
	)(reduxCreateStore);

	return createStoreWithMiddleware(
		reducers,
		window.__REDUX_DEVTOOLS_EXTENSION__ &&
			window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }),
	);
}

export function getStore() {
	if (!store) {
		store = createStore();
	}

	return store;
}
