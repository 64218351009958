import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, bool, oneOfType } from "prop-types";
import { Map } from "immutable";

import { addMultipleFeedbackees } from "../../../../../actions/meetings.actions";
import { getFeedbackeeTooltips } from "../../../../../components/helpers/meeting.helper";
import history from "../../../../../interfaces/history";
import { PeopleModalProvider } from "../../../../shared/people-modal/people-modal.provider";
import { PeopleModalContainer } from "../../../../shared/people-modal/people-modal.container";
import { TeamsAndInvestorsListContainer } from "../../../../shared/people-modal/teams-and-investors-list/teams-and-investors-list.container";
import {
	PeopleModalHeader,
	getOnlyTabs,
} from "../../../../shared/people-modal/people-modal-header";
import { PeopleModalFooter } from "../../../../shared/people-modal/people-modal-footer";
import { TAB_TEAMS_AND_SHAREHOLDES } from "../../../../shared/select-user-modal/select-user-modal.constants";
class RequestFeedbackContainer extends Component {
	state = {
		isOpen: false,
	};

	static propTypes = {
		renderComponent: func,
		redirectTo: oneOfType([string, bool]),
	};

	openUsersModal = () => {
		this.setState({ isOpen: true });
	};

	/**
	 * A callback function that is called when the people select modal is closed either by
	 * canceling the action or by saving the added people.
	 * @param {boolean} isCanceled - indicating that this function was called by onCancel
	 */
	closeModal = (isCanceled = false) => {
		const { redirectTo, history } = this.props;

		this.setState({ isOpen: false });

		if (!isCanceled && redirectTo) {
			history.push(redirectTo);
		}
	};

	addFeedbackees = (feedbackees) => {
		const { addMultipleFeedbackees } = this.props;

		this.closeModal();

		addMultipleFeedbackees(feedbackees);
	};

	render = () => {
		const { renderComponent, feedbackees, meetingStatus, protocolPublished } =
			this.props;
		const { isOpen } = this.state;
		const tooltip = getFeedbackeeTooltips({ meetingStatus, protocolPublished });

		return (
			<>
				{renderComponent({
					openFeedbackeesModal: this.openUsersModal,
					tooltip,
				})}

				{isOpen && (
					<PeopleModalProvider values={feedbackees} isOpen={true}>
						<PeopleModalContainer
							headerComponent={() => (
								<PeopleModalHeader
									title="meetings.smart.feedback.feedbackees.modal.title"
									tabs={getOnlyTabs([TAB_TEAMS_AND_SHAREHOLDES])}
								/>
							)}
							footerComponent={() => (
								<PeopleModalFooter
									onSave={this.addFeedbackees}
									onCancel={this.closeModal}
								/>
							)}
						>
							<TeamsAndInvestorsListContainer showOnlyMembers={true} />
						</PeopleModalContainer>
					</PeopleModalProvider>
				)}
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		feedbackees: store.meetings.getIn(["meeting", "feedbackees"], Map()),
		meetingStatus: store.meetings.getIn(["meeting", "status"]),
		protocolPublished: store.meetings.getIn([
			"meeting",
			"computedValues",
			"protocolPublished",
		]),
	};
};

const mapActionsToProps = {
	addMultipleFeedbackees,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(RequestFeedbackContainer);
