export function calculateListheight({ numOfItems, itemHeight, scrollbarRef }) {
	const totalListHeight = numOfItems && numOfItems * itemHeight;
	const scrollHeight = scrollbarRef?.container?.scrollHeight;
	const listHeight =
		scrollHeight && totalListHeight > scrollHeight
			? scrollHeight
			: totalListHeight;

	return listHeight;
}
