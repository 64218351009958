import usePaymentMethods from "./usePaymentMethods";
import useSubscription from "./useSubscription";

export default () => {
	const { data: paymentMethodsRequest, isLoading: isLoadingPaymentMethods } =
		usePaymentMethods();

	const { data: subscriptionRequest, isLoading: isLoadingSubscription } =
		useSubscription();

	const defaultPaymentMethod = paymentMethodsRequest?.data?.find(
		(paymentMethod) =>
			paymentMethod.id ===
			subscriptionRequest?.data?.stripeData?.invoice_settings
				?.default_payment_method,
	);

	return {
		isLoading: isLoadingPaymentMethods || isLoadingSubscription,
		data: defaultPaymentMethod,
	};
};
