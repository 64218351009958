import React, { PureComponent } from "react";
import { func, string, bool } from "prop-types";
import Grid from "styled-components-grid";
import { Padding } from "styled-components-spacing";
import Panel from "../../shared/panel/panel";
import Input from "../../shared/input/input";
import Label from "../../shared/label/label";
import Datepicker from "../../shared/datepicker/datepicker";
import ImmutableProps from "react-immutable-proptypes";
import { List } from "immutable";
import Text from "../../shared/text/text";
import Moment from "../../../modules/moment.module";
import v1 from "uuid";
import TextArea from "../../shared/input/textarea";
import ColorProgressSelect from "../../shared/color-progress-select/color-progress-select";

class TaskInformationDetails extends PureComponent {
	static propTypes = {
		task: ImmutableProps.map,
		avaibleTaskStatus: ImmutableProps.list,
		folder: ImmutableProps.map,
		userLang: string,
		onChange: func,
		onProjectChange: func,
		renderTagsSelectContainer: func,
		language: string,
		selected: bool,
		readOnly: bool,
		renderAssigneeContainer: func,
		renderEditorContainer: func,
		isDeleted: bool,
	};

	static defaultProps = {
		avaibleTaskStatus: List(),
		readOnly: false,
	};

	render = () => {
		const {
			task,
			avaibleTaskStatus,
			userLang,
			onChange,
			renderTagsSelectContainer,
			renderAssigneeContainer,
			language,
			selected,
			readOnly,
			renderEditorContainer,
			isDeleted,
		} = this.props;
		const panelRightComponent = isDeleted && (
			<Text tid="generic.is_deleted" color="red" tag="em" />
		);

		return (
			<Panel
				title="tasks.general.panel.title"
				marginBottom={true}
				id={v1()}
				ref={(node) => (this._panel = node)}
				active={selected}
				rightComponent={() => panelRightComponent}
			>
				<Padding all={3}>
					<Grid>
						<Grid.Unit size={{ xs: 1, lg: 1 / 3 }}>
							<Padding all={3}>
								<Label tid="tasks.details.label.task_id">
									<Input
										value={task ? task.get("taskId", "").toString() : ""}
										disabled
									/>
								</Label>
							</Padding>
						</Grid.Unit>
						{renderAssigneeContainer && (
							<Grid.Unit size={{ xs: 1, lg: 2 / 3 }}>
								<Padding all={3}>
									<Label tid="tasks.details.label.assigned_to">
										{renderAssigneeContainer(readOnly)}
									</Label>
								</Padding>
							</Grid.Unit>
						)}
					</Grid>

					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid="generic.title">
									<TextArea
										fieldName="title"
										onChange={onChange}
										value={task && task.get("title", "")}
										placeholderTid="tasks.details.title.placeholder"
										disabled={readOnly}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>

					<Grid>
						<Grid.Unit size={{ xs: 1, lg: 1 / 3 }}>
							<Padding all={3}>
								<Label tid="tasks.details.label.due_date">
									<Datepicker
										fieldName="dueAt"
										onChange={onChange}
										disabled={readOnly}
										value={
											task
												? task.get("dueAt") && Moment(task.get("dueAt"))
												: null
										}
										language={language || userLang}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
						<Grid.Unit size={{ xs: 1, lg: 2 / 3 }}>
							<Padding all={3}>
								<Label tid="tasks.details.label.task_status">
									<ColorProgressSelect
										fieldName="status"
										options={avaibleTaskStatus}
										onChange={onChange}
										isDisabled={readOnly}
										value={task ? task.get("status") : null}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>

					{renderTagsSelectContainer && (
						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid="generic.tags">
										{renderTagsSelectContainer &&
											renderTagsSelectContainer(readOnly)}
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>
					)}

					{renderEditorContainer && (
						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid="generic.description">
										{renderEditorContainer()}
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>
					)}
				</Padding>
			</Panel>
		);
	};
}

export default TaskInformationDetails;
