import React, { PureComponent } from "react";
import { bool, func, string, oneOf, object, oneOfType } from "prop-types";
import styled from "styled-components";
import Icon from "../icon/icon";
import Text from "../text/text";
import Button from "../button/button";
import { Margin } from "styled-components-spacing";
import theme from "../../../../theme";
import { ButtonTransparentIcon } from "../button";
import Tooltip from "../tooltip/tooltip";
import DropdownMenuMenu from "./dropdown-menu-menu";

const StyledWrapper = styled.div`
	position: relative;
	display: ${(props) => (props.inline ? "inline-block" : "block")};
`;

class DropdownMenu extends PureComponent {
	static propTypes = {
		scrollbars: bool,
		isOpen: bool,
		btnTid: string,
		btnText: string,
		btnIcon: string,
		btnIconColor: oneOf(Object.keys(theme.colors)),
		onToggleMenu: func.isRequired,
		disabled: bool,
		inline: bool,
		halignMenu: oneOf(["left", "right"]),
		direction: oneOf(["down", "up"]),
		btnMode: oneOf(["primary", "transparent-icon", "link", "dark"]),
		transparentIconButtonSize: oneOf(["sm", "sml", "normal"]),
		onSetRef: func,
		minWidth: string,
		buttonMinWidth: string,
		buttonNoHorizontalPadding: bool,
		scrollbarAlwaysVisible: bool,
		tooltipTid: string,
		activeState: oneOfType([string, bool]),
		tooltipStates: object,
		tooltipDelayShow: string,
		menuIcon: string,
		noMaxWidth: bool,
		onSetMenuRef: func,
		clientRects: object,
		renderRaw: object,
		onMouseEnter: func,
		onMouseLeave: func,
		btnIconType: oneOf(["solid", "light"]),
		btnIconSize: oneOf(["xs", "sm", "sml"]),
		btnTextColor: oneOf(Object.keys(theme.colors)),
		position: oneOf(["absolute", "fixed"]),
		withPortal: bool,
		notificationBadge: bool,
	};

	static defaultProps = {
		isOpen: false,
		disabled: false,
		inline: false,
		halignMenu: "left",
		scrollbars: false,
		direction: "down",
		btnIconColor: "midGrey",
		menuNoTop: false,
		menuIcon: "faCaretDown",
		noMaxWidth: false,
		btnIconType: "solid",
		btnIconSize: "xs",
	};

	render = () => {
		const {
			children,
			tooltipTid,
			tooltipActiveState,
			tooltipStates,
			isOpen,
			btnTid,
			btnText,
			btnIcon,
			onToggleMenu,
			disabled,
			inline,
			halignMenu,
			btnMode,
			scrollbars,
			transparentIconButtonSize,
			btnIconColor,
			direction,
			onSetRef,
			minWidth,
			buttonMinWidth,
			buttonNoHorizontalPadding,
			menuNoTop,
			scrollbarAlwaysVisible,
			menuIcon,
			noMaxWidth,
			onSetMenuRef,
			clientRects,
			parentRects,
			renderRaw,
			onMouseEnter,
			onMouseLeave,
			btnIconType,
			btnIconSize,
			tooltipDelayShow,
			noDropdownPaddingTop,
			btnTextColor,
			position,
			alwaysRenderDropdownItems,
			withPortal,
			notificationBadge,
		} = this.props;

		const btnProps = {
			onClick: onToggleMenu,
			mode: btnMode,
			size: transparentIconButtonSize,
			disabled,
			minWidth: buttonMinWidth,
			noHorizontalPadding: buttonNoHorizontalPadding,
			noHorizontalMargin: buttonNoHorizontalPadding,
			color: btnIconColor,
			notificationBadge,
		};

		if (btnMode === "transparent-icon") {
			btnProps.icon = btnIcon || menuIcon;
		}

		return (
			<StyledWrapper
				inline={inline}
				ref={(ref) => {
					onSetRef && onSetRef(ref);
				}}
				onMouseEnter={onMouseEnter && onMouseEnter}
				onMouseLeave={onMouseLeave && onMouseLeave}
			>
				{renderRaw && renderRaw}

				{!renderRaw && btnMode === "transparent-icon" && (
					<Tooltip
						tid={tooltipTid}
						activeState={tooltipActiveState}
						states={tooltipStates}
						delayShow={tooltipDelayShow}
					>
						<ButtonTransparentIcon {...btnProps}>
							{btnTid && <Text tid={btnTid} />}
							{btnText && <Text>{btnText}</Text>}
							{!btnIcon && (btnTid || btnText) && (
								<Margin left={2}>
									<Icon
										icon={menuIcon}
										size={btnIconSize}
										type={btnIconType}
										color={btnIconColor}
									/>
								</Margin>
							)}
							{!btnIcon && (
								<Icon
									icon={menuIcon}
									size={btnIconSize}
									type={btnIconType}
									color={btnIconColor}
								/>
							)}
						</ButtonTransparentIcon>
					</Tooltip>
				)}

				{!renderRaw && btnMode !== "transparent-icon" && (
					<Tooltip
						tid={tooltipTid}
						activeState={tooltipActiveState}
						states={tooltipStates}
						delayShow={tooltipDelayShow}
					>
						<Button {...btnProps}>
							{btnTid && <Text tid={btnTid} color={btnTextColor} />}
							{btnText && <Text>{btnText}</Text>}
							{btnIcon && !btnTid && !btnText && (
								<Icon
									icon={btnIcon}
									size={btnIconSize}
									type={btnIconType}
									color={btnIconColor}
								/>
							)}
							{!btnIcon && (btnTid || btnText) && (
								<Margin left={4}>
									<Icon
										icon={menuIcon}
										size={btnIconSize}
										type={btnIconType}
										color={btnIconColor}
									/>
								</Margin>
							)}
							{!btnIcon && !btnTid && (
								<Icon
									icon={menuIcon}
									size={btnIconSize}
									type={btnIconType}
									color={btnIconColor}
								/>
							)}
						</Button>
					</Tooltip>
				)}

				<DropdownMenuMenu
					scrollbars={scrollbars}
					isOpen={isOpen}
					halignMenu={halignMenu}
					direction={direction}
					minWidth={minWidth}
					menuNoTop={menuNoTop}
					noMaxWidth={noMaxWidth}
					scrollbarAlwaysVisible={scrollbarAlwaysVisible}
					noDropdownPaddingTop={noDropdownPaddingTop}
					onSetRef={onSetMenuRef}
					clientRects={clientRects}
					parentRects={parentRects}
					position={position}
					alwaysRenderDropdownItems={alwaysRenderDropdownItems}
					withPortal={withPortal}
				>
					{children}
				</DropdownMenuMenu>
			</StyledWrapper>
		);
	};
}

export default DropdownMenu;
