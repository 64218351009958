import CRITERIA from "/shared/criteria";

export const selectAll = (args, state, tools) => {
	if (args[0].includes("select_all")) {
		const section = args[0].split("!")[1];
		const lastFieldState = state.fields[args[0]].lastFieldState.value;

		CRITERIA[section].forEach((criterion) => {
			tools.changeValue(state, criterion, () =>
				lastFieldState === true ? false : true,
			);
		});
	}
};

export const resetSubmitFailed = (args, state) => {
	state.formState.submitFailed = false;
};
