import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, number } from "prop-types";
import { Map, List } from "immutable";
import { flattenAgendaItems } from "../../../components/helpers/meeting.helper";

import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";
import DropdownItem from "../../../dumb-components/shared/dropdown-item/dropdown-item";
import Text from "../../../dumb-components/shared/text/text";
import history from "../../../interfaces/history";

import {
	createAgendaItem,
	updateAgendaItemLocal,
	deleteAgendaItemLocal,
	deleteAgendaItem,
	convertAgendaItemToSubitem,
	convertAgendaItemToSuperItem,
	convertAgendaItemToPreviousMeetingItem,
	convertAgendaItemToScheduleNextMeetingItem,
} from "../../../actions/meetings.actions";

const ADD_SUBITEM_STATES = {
	itemInternalType: {
		tid: "meetings.agenda.item.dropdown.tooltip.special_internal_type",
		delayShow: "instant",
	},
};

const CONVERT_ITEM_STATES = {
	itemInternalType: {
		tid: "meetings.agenda.item.dropdown.tooltip.special_internal_type",
		delayShow: "instant",
	},
	itemLocked: {
		tid: "meetings.agenda.item.dropdown.tooltip.item_locked",
		delayShow: "instant",
	},
	previousItemIsInternal: {
		tid: "meeting.agenda.item.dropdown.tooltip.previous_item_is_special_item",
		delayShow: "instant",
	},
};

const CONVERT_ITEM_TO_PREVIOUS_MEETING_STATES = {
	itemInternalType: {
		tid: "meetings.agenda.item.dropdown.tooltip.special_internal_type",
		delayShow: "instant",
	},
	itemNotSuper: {
		tid: "meetings.agenda.item.dropdown.tooltip.not_super_item",
		delayShow: "instant",
	},
	itemLocked: {
		tid: "meetings.agenda.item.dropdown.tooltip.item_locked",
		delayShow: "instant",
	},
	itemPreviousMeetingExists: {
		tid: "meeting.agenda.item.dropdown.tooltip.previous_meeting_item_exists",
		delayShow: "instant",
	},
	hasChildren: {
		tid: "meeting.agenda.item.dropdown.tooltip.item_has_children",
		delayShow: "instant",
	},
};

const CONVERT_ITEM_TO_NEXT_MEETING_STATES = {
	itemInternalType: {
		tid: "meetings.agenda.item.dropdown.tooltip.special_internal_type",
		delayShow: "instant",
	},
	itemNotSuper: {
		tid: "meetings.agenda.item.dropdown.tooltip.not_super_item",
		delayShow: "instant",
	},
	itemLocked: {
		tid: "meetings.agenda.item.dropdown.tooltip.item_locked",
		delayShow: "instant",
	},
	itemNextMeetingExists: {
		tid: "meeting.agenda.item.dropdown.tooltip.next_meeting_item_exists",
		delayShow: "instant",
	},
	hasChildren: {
		tid: "meeting.agenda.item.dropdown.tooltip.item_has_children",
		delayShow: "instant",
	},
};

const DELETE_ITEM_STATES = {
	itemMandatory: {
		tid: "meeting.agenda.item.dropdown.tooltip.mandatory_item",
		delayShow: "instant",
	},
};

class MeetingsTaskItemDropdownContainer extends Component {
	static propTypes = {
		onMeetingChange: func,
		querystr: string,
		ordinaryItemsSize: number,
	};

	dropdownRef = null;

	toggleDropdown = () => {
		this.dropdownRef && this.dropdownRef.onToggleMenu();
	};

	addSubitem = () => {
		const {
			createAgendaItem,
			updateAgendaItemLocal,
			querystr,
			basePath,
			agendaItems,
		} = this.props;
		let { agendaItem } = this.props;
		let insertAtIndex = agendaItem.get("agendaItems", List()).size;
		let indexOfParentItem = agendaItems.findIndex(
			(obj) => obj.get("id") === agendaItem.get("id"),
		);
		const itemIsSuperItem = this.getIsSuperItem();

		this.toggleDropdown();

		if (!itemIsSuperItem) {
			indexOfParentItem = this.getItemParentIndex();
			insertAtIndex = agendaItems.getIn(
				[indexOfParentItem, "agendaItems"],
				List(),
			).size;
		} else if (agendaItem.get("itemType") !== "category") {
			agendaItem = agendaItem.set("itemType", "category");
			updateAgendaItemLocal(agendaItem);
		}

		createAgendaItem(
			indexOfParentItem,
			insertAtIndex,
			Map(),
			(newAgendaItem) => {
				this.props.history.push({
					pathname: `${basePath}/${newAgendaItem.get("id")}`,
					search: querystr,
				});
			},
		);
	};

	deleteItem = () => {
		const {
			deleteAgendaItemLocal,
			meetingId,
			agendaItem,
			basePath,
			deleteAgendaItem,
			history,
		} = this.props;
		const agendaItemId = agendaItem.get("id");

		this.toggleDropdown();

		deleteAgendaItemLocal(agendaItemId);
		deleteAgendaItem(meetingId, agendaItemId);
		history.push({
			pathname: `${basePath}`,
			search: location.search,
		});
	};

	convertItem = () => {
		const {
			agendaItem,
			agendaItems,
			convertAgendaItemToSubitem,
			convertAgendaItemToSuperItem,
		} = this.props;
		const isFirstItemInList =
			agendaItems.first().get("id") === agendaItem.get("id");
		const isSuperItem = this.getIsSuperItem();

		this.toggleDropdown();

		if (isSuperItem && !isFirstItemInList) {
			convertAgendaItemToSubitem(agendaItem.get("id"));
		} else if (!isSuperItem) {
			const parentIndex = this.getItemParentIndex();
			convertAgendaItemToSuperItem(parentIndex, agendaItem.get("id"));
		}
	};

	convertItemToPreviousMeetingItem = () => {
		const {
			agendaItem,
			convertAgendaItemToPreviousMeetingItem,
			updateAgendaItemLocal,
		} = this.props;
		const agendaItemId = agendaItem.get("id");

		this.toggleDropdown();

		convertAgendaItemToPreviousMeetingItem(
			agendaItemId,
			(updatedAgendaItem) => {
				updateAgendaItemLocal(updatedAgendaItem);
			},
		);
	};

	convertItemToScheduleNextMeetingItem = () => {
		const {
			agendaItem,
			convertAgendaItemToScheduleNextMeetingItem,
			updateAgendaItemLocal,
		} = this.props;
		const agendaItemId = agendaItem.get("id");

		this.toggleDropdown();

		convertAgendaItemToScheduleNextMeetingItem(
			agendaItemId,
			(updatedAgendaItem) => {
				updateAgendaItemLocal(updatedAgendaItem);
			},
		);
	};

	convertSuggestedToMainAgenda = () => {
		const { ordinaryItemsSize, onMeetingChange } = this.props;
		let { agendaItem, agendaItems } = this.props;
		const agendaItemName = agendaItem.get("name");

		const agendaItemId = agendaItem.get("id");
		const itemIndex = agendaItems.findIndex(
			(item) => item.get("id") === agendaItemId,
		);

		agendaItem = agendaItem.set("proposal", agendaItemName);
		agendaItem = agendaItem.set("progress", "todo");
		agendaItem = agendaItem.set("outcome", "todo");
		agendaItem = agendaItem.set("num", ordinaryItemsSize + 1);
		agendaItem = agendaItem.set("isMainTopic", true);

		agendaItem = agendaItem.remove("isSuggestion");
		agendaItem = agendaItem.remove("name");
		agendaItem = agendaItem.remove("parentIndex");
		agendaItem = agendaItem.remove("level");
		agendaItem = agendaItem.remove("dragHandleProps");

		agendaItems = agendaItems.remove(itemIndex);
		agendaItems = agendaItems.insert(ordinaryItemsSize, agendaItem);

		onMeetingChange(["agendaItems"], agendaItems);
	};

	getIsSuggested = () => {
		const { agendaItem } = this.props;
		return agendaItem.get("isSuggestion");
	};

	getItemParentIndex = () => {
		const { agendaItem, agendaItems } = this.props;

		if (!agendaItem) {
			return false;
		}

		const itemId = agendaItem.get("id");
		let parentIndex;

		agendaItems.forEach((item, index) => {
			if (item && item.get("agendaItems")) {
				item.get("agendaItems", List()).forEach((subitem) => {
					if (subitem.get("id") === itemId) {
						parentIndex = index;
					}
				});
			}
		});

		return parentIndex;
	};

	getIsSuperItem = () => {
		const { agendaItem, agendaItems } = this.props;

		if (!agendaItem) {
			return false;
		}

		const itemId = agendaItem.get("id");
		let isSuperItem = false;

		agendaItems.forEach((item) => {
			if (item && item.get("id") === itemId) {
				isSuperItem = true;
			}
		});

		return isSuperItem;
	};

	getIsInternalItem = () => {
		const { agendaItem } = this.props;
		return Boolean(agendaItem.get("internalType"));
	};

	getIsLockedItem = () => {
		const { agendaItem } = this.props;
		return Boolean(agendaItem.get("locked"));
	};

	getItemHasChildren = () => {
		const { agendaItem } = this.props;
		return agendaItem.get("itemType") === "category";
	};

	getPreviousItemIsInternal = () => {
		const { agendaItem, agendaItems } = this.props;
		const indexOfCurrentAgendaItem = agendaItems.findIndex(
			(item) => item.get("id") === agendaItem.get("id"),
		);
		return indexOfCurrentAgendaItem > 0
			? agendaItems.getIn([indexOfCurrentAgendaItem - 1, "internalType"])
				? true
				: false
			: true;
	};

	getPreviousMeetingItemExists = () => {
		const { agendaItems } = this.props;
		return agendaItems.some(
			(obj) => obj.get("internalType") === "previousMeeting",
		);
	};

	getScheduleNextMeetingExists = () => {
		const { agendaItems } = this.props;
		return agendaItems.some((obj) => obj.get("internalType") === "nextMeeting");
	};

	getAddSubitemActiveState = () => {
		const isInternalItem = this.getIsInternalItem();

		if (isInternalItem) {
			return "itemInternalType";
		}
	};

	getConvertItemActiveState = () => {
		const isSuperItem = this.getIsSuperItem();
		const isInternalItem = this.getIsInternalItem();
		const isLockedItem = this.getIsLockedItem();
		const previousItemIsInternal = this.getPreviousItemIsInternal();

		if (!isSuperItem) {
			return;
		}

		if (isInternalItem) {
			return "itemInternalType";
		}

		if (isLockedItem) {
			return "itemLocked";
		}

		if (previousItemIsInternal) {
			return "previousItemIsInternal";
		}
	};

	getConvertItemToPreviousMeetingActiveState = () => {
		const { agendaItem } = this.props;
		const isInternalItem = this.getIsInternalItem();
		const isSuperItem = this.getIsSuperItem();
		const isLockedItem = this.getIsLockedItem();
		const previousMeetingItemExists = this.getPreviousMeetingItemExists();
		const isNotNextMeetingItem =
			agendaItem.get("internalType") !== "nextMeeting";
		const hasChildren = this.getItemHasChildren();

		if (previousMeetingItemExists && !isLockedItem) {
			return "itemPreviousMeetingExists";
		}

		if (isInternalItem && isNotNextMeetingItem) {
			return "itemInternalType";
		}

		if (hasChildren) {
			return "hasChildren";
		}

		if (!isSuperItem) {
			return "itemNotSuper";
		}

		if (isLockedItem) {
			return "itemLocked";
		}
	};

	getConvertItemToNextMeetingActiveState = () => {
		const { agendaItem } = this.props;
		const isInternalItem = this.getIsInternalItem();
		const isSuperItem = this.getIsSuperItem();
		const isLockedItem = this.getIsLockedItem();
		const scheduleNextMeetingItemExists = this.getScheduleNextMeetingExists();
		const isNotPreviousMeetingItem =
			agendaItem.get("internalType") !== "previousMeeting";
		const hasChildren = this.getItemHasChildren();

		if (scheduleNextMeetingItemExists && !isLockedItem) {
			return "itemNextMeetingExists";
		}

		if (isInternalItem && isNotPreviousMeetingItem) {
			return "itemInternalType";
		}

		if (hasChildren) {
			return "hasChildren";
		}

		if (!isSuperItem) {
			return "itemNotSuper";
		}

		if (isLockedItem) {
			return "itemLocked";
		}
	};

	getDeleteItemActiveState = () => {
		const isLockedItem = this.getIsLockedItem();

		if (isLockedItem) {
			return "itemMandatory";
		}
	};

	getDropdownActiveState = () => {
		const { hasExtendedRights } = this.props;

		if (!hasExtendedRights) {
			return "hasNoPermissions";
		}
	};

	getIsMeetingOpenItem = () => {
		const { agendaItem } = this.props;
		return agendaItem.get("internalType") === "open";
	};

	getIsMeetingCloseItem = () => {
		const { agendaItem } = this.props;
		return agendaItem.get("internalType") === "close";
	};

	renderDeleteItemOption = (isLockedItem) => {
		const deleteItemActiveState = this.getDeleteItemActiveState();

		return (
			<Tooltip states={DELETE_ITEM_STATES} activeState={deleteItemActiveState}>
				<DropdownIconItem
					icon="faTrashAlt"
					tid="meetings.agenda.toolbar.option.delete_item"
					onClick={this.deleteItem}
					disabled={isLockedItem}
				/>
			</Tooltip>
		);
	};

	render() {
		const { agendaItem, isSimpleMode, hasExtendedRights } = this.props;
		if (!agendaItem) {
			return null;
		}
		const isMeetingOpenItem = this.getIsMeetingOpenItem();
		const isMeetingCloseItem = this.getIsMeetingCloseItem();

		if (isMeetingOpenItem || isMeetingCloseItem) {
			return null;
		}

		const isSuperItem = this.getIsSuperItem();
		const isInternalItem = this.getIsInternalItem();
		const isLockedItem = this.getIsLockedItem();
		const isSuggested = this.getIsSuggested();
		const previousItemIsInternal = this.getPreviousItemIsInternal();
		const previousMeetingItemExists = this.getPreviousMeetingItemExists();
		const scheduleNextMeetingItemExists = this.getScheduleNextMeetingExists();
		const hasChildren = this.getItemHasChildren();

		const addSubitemActiveState = this.getAddSubitemActiveState();
		const convertItemActiveState = this.getConvertItemActiveState();
		const convertItemToPreviousMeetingActiveState =
			this.getConvertItemToPreviousMeetingActiveState();
		const convertItemToNextMeetingActiveState =
			this.getConvertItemToNextMeetingActiveState();
		const dropdownActiveState = this.getDropdownActiveState();

		/*if (isSimpleMode) {
			return null
		}*/

		return (
			<DropdownMenuContainer
				btnIcon="faEllipsisV"
				halignMenu="right"
				transparentIconButtonSize="sml"
				btnMode="transparent-icon"
				ref={(r) => (this.dropdownRef = r)}
				disabled={!hasExtendedRights}
				tooltipActiveState={dropdownActiveState}
				noMaxWidth
				withPortal
			>
				{!isSuggested && (
					<Tooltip
						states={ADD_SUBITEM_STATES}
						activeState={addSubitemActiveState}
					>
						<DropdownIconItem
							icon="faCodeMerge"
							onClick={this.addSubitem}
							disabled={isSimpleMode || isInternalItem}
							tid="meetings.agenda.toolbar.option.add_subitem"
						/>
					</Tooltip>
				)}

				{isSuperItem && !isSuggested && (
					<Tooltip
						states={CONVERT_ITEM_STATES}
						activeState={convertItemActiveState}
					>
						<DropdownIconItem
							icon="faCaretRight"
							onClick={this.convertItem}
							disabled={
								isSimpleMode ||
								isLockedItem ||
								isInternalItem ||
								previousItemIsInternal
							}
							tid="meetings.agenda.toolbar.convert_to_sub_item"
						/>
					</Tooltip>
				)}

				{!isSuperItem && !isSuggested && (
					<Tooltip
						states={CONVERT_ITEM_STATES}
						activeState={convertItemActiveState}
					>
						<DropdownIconItem
							icon="faCaretLeft"
							onClick={this.convertItem}
							disabled={isSimpleMode}
							tid="meetings.agenda.toolbar.convert_to_super_item"
						/>
					</Tooltip>
				)}

				{!isSuggested && (
					<Tooltip
						states={CONVERT_ITEM_TO_PREVIOUS_MEETING_STATES}
						activeState={convertItemToPreviousMeetingActiveState}
					>
						<DropdownIconItem
							icon="faList"
							onClick={this.convertItemToPreviousMeetingItem}
							disabled={
								!isSuperItem ||
								isLockedItem ||
								previousMeetingItemExists ||
								hasChildren
							}
						>
							<Text>
								<Text tid="meetings.agenda.toolbar.option.convert_item_to_previous_meeting_item_1" />
								&nbsp;
								<Text
									tid="meetings.agenda.toolbar.option.convert_item_to_previous_meeting_item_2"
									bold={600}
								/>
							</Text>
						</DropdownIconItem>
					</Tooltip>
				)}

				{!isSuggested && (
					<Tooltip
						states={CONVERT_ITEM_TO_NEXT_MEETING_STATES}
						activeState={convertItemToNextMeetingActiveState}
					>
						<DropdownIconItem
							icon="faCalendarPlus"
							onClick={this.convertItemToScheduleNextMeetingItem}
							disabled={
								!isSuperItem ||
								isLockedItem ||
								scheduleNextMeetingItemExists ||
								hasChildren
							}
						>
							<Text>
								<Text tid="meetings.agenda.toolbar.option.convert_item_to_schedule_next_meeting_item_1" />
								&nbsp;
								<Text
									tid="meetings.agenda.toolbar.option.convert_item_to_schedule_next_meeting_item_2"
									bold={600}
								/>
							</Text>
						</DropdownIconItem>
					</Tooltip>
				)}

				{isSuggested && (
					<DropdownIconItem
						icon="faPlus"
						onClick={this.convertSuggestedToMainAgenda}
						//disabled={!isSuperItem || isLockedItem || scheduleNextMeetingItemExists || hasChildren}
						tid="meeting.agenda.item.dropdown.add_suggested"
					/>
				)}

				<DropdownItem divider />

				{this.renderDeleteItemOption(isLockedItem)}
			</DropdownMenuContainer>
		);
	}
}

const mapStoreToProps = (store, ownProps) => {
	const meeting = store.meetings.get("meeting", Map()) || Map();
	const flattenedAgendaItems = flattenAgendaItems(meeting);

	return {
		history: history,
		agendaItems: meeting.get("agendaItems", List()) || List(),
		isSimpleMode: meeting.getIn(["computedValues", "isSimpleMode"]),
		meetingId: meeting.get("id"),
		hasExtendedRights: meeting.getIn(["computedValues", "hasExtendedRights"]),
		agendaItem: flattenedAgendaItems.find(
			(item) => item.get("id") === ownProps.agendaItemId,
		),
	};
};

const mapActionsToProps = {
	createAgendaItem,
	updateAgendaItemLocal,
	deleteAgendaItemLocal,
	deleteAgendaItem,
	convertAgendaItemToSubitem,
	convertAgendaItemToSuperItem,
	convertAgendaItemToPreviousMeetingItem,
	convertAgendaItemToScheduleNextMeetingItem,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingsTaskItemDropdownContainer);
