import { fromJS, Map } from "immutable";
import { v4 } from "uuid";
import req from "../modules/request.module";
import shareWithHelper from "../components/helpers/share-with.helper";

import { addInfoNotification } from "./notify.actions";
import { selectSharedCollection } from "./shared-collections.actions";

import {
	COLLECTIONS_FETCH,
	COLLECTION_SELECT,
	COLLECTION_CREATE,
	COLLECTION_REMOVE,
	COLLECTION_ADD_DOCUMENT,
	COLLECTION_REMOVE_DOCUMENT,
	COLLECTION_UPDATE_LOCAL,
	COLLECTION_UPDATE_DOCUMENTS_LOCAL,
	COLLECTION_MOVE_DOCUMENT,
	COLLECTION_UPDATE_MERGED_DOCUMENT,
	COLLECTION_DOCUMENT_SET_INCLUDE_IN_ACTION,
	DOCUMENTS_UPDATE_DOCUMENT_IN_LIST,
} from "./types";
import documentsHelper from "../components/helpers/documents.helper";

export function fetchCollections(callback) {
	return function (dispatch) {
		req
			.get(`/documents/collections`)
			.then((response) => {
				dispatch({ type: COLLECTIONS_FETCH, payload: fromJS(response.data) });
				callback && callback();
			})
			.catch((e) => console.log(e));
	};
}

export function createCollection(callback) {
	const collection = {
		id: v4(),
	};

	return function (dispatch) {
		req
			.post(`/documents/collections`, { collection })
			.then((response) => {
				const savedCollection = fromJS(response.data);
				dispatch({ type: COLLECTION_CREATE, payload: savedCollection });
				callback && callback(savedCollection);
			})
			.catch((e) => console.log(e));
	};
}

export function selectCollection(collection = Map()) {
	return function (dispatch) {
		dispatch({ type: COLLECTION_SELECT, payload: collection });
	};
}

export function editCollection(collection) {
	const collectionId = collection.get("id");

	return function () {
		req.put(`/documents/collections/${collectionId}`, {
			collection: collection.toJS(),
		});
	};
}

export function deleteCollection(collectionId) {
	return function (dispatch) {
		req
			.delete(`/documents/collections/${collectionId}`)
			.then((response) => {
				dispatch({ type: COLLECTION_REMOVE, payload: fromJS(response.data) });
				dispatch(
					addInfoNotification({
						tid: "collections.notification.collection_deleted",
					}),
				);
			})
			.catch((e) => console.log(e));
	};
}

export function addDocumentToCollection(documentId, sharedVersion, callback) {
	return function (dispatch) {
		req
			.post(`/documents/collections/document/${documentId}`, { sharedVersion })
			.then((response) => {
				const data = fromJS(response.data);
				const documentSummary = documentsHelper.makeSummary(
					data.get("document"),
				);
				dispatch({ type: COLLECTION_ADD_DOCUMENT, payload: data });
				dispatch({
					type: DOCUMENTS_UPDATE_DOCUMENT_IN_LIST,
					payload: { documentSummary },
				});
				dispatch(
					addInfoNotification({
						tid: "collections.add_document.notification.success",
					}),
				);
				callback && callback();
			})
			.catch((e) => console.log(e));
	};
}

export function fetchCollectionDocuments(collectionId, callback) {
	return function (dispatch) {
		req
			.get(`/documents/collections/${collectionId}/documents`)
			.then((response) => {
				const documents = fromJS(response.data.documents);
				dispatch({
					type: COLLECTION_UPDATE_DOCUMENTS_LOCAL,
					payload: documents,
				});
				callback && callback(documents);
			})
			.catch((e) => console.log(e));
	};
}

export function removeDocumentFromCollection(collectionId, documentId) {
	return function (dispatch) {
		req
			.delete(`/documents/collections/document/${documentId}/${collectionId}`)
			.then((response) => {
				dispatch({
					type: COLLECTION_REMOVE_DOCUMENT,
					payload: fromJS(response.data),
				});
			})
			.catch((e) => console.log(e));
	};
}

export function updateCollectionLocal(collection) {
	return function (dispatch) {
		dispatch({ type: COLLECTION_UPDATE_LOCAL, payload: collection });
	};
}

export function reorderDocument(collectionId, documentIds) {
	return function (dispatch, getState) {
		let documents = getState().collections.get("selectedCollectionDocuments");
		documents = documentIds.map((id) => {
			return documents.find((d) => d.get("id") === id);
		});
		dispatch({ type: COLLECTION_UPDATE_DOCUMENTS_LOCAL, payload: documents });
		req.put(`/documents/collections/${collectionId}/documents/reorder`, {
			documentIds,
		});
	};
}

export function moveDocument(documentId, toCollectionId, fromCollectionId) {
	return function (dispatch) {
		dispatch({
			type: COLLECTION_MOVE_DOCUMENT,
			payload: { documentId, toCollectionId, fromCollectionId },
		});
		req.put(
			`/documents/collections/document/${documentId}/${fromCollectionId}/${toCollectionId}`,
		);
	};
}

export function setToBeMergedLocal(documentId, toBeMerged) {
	return function (dispatch) {
		dispatch({
			type: COLLECTION_DOCUMENT_SET_INCLUDE_IN_ACTION,
			payload: { documentId, toBeMerged },
		});
	};
}

export function shareCollection(
	collectionId,
	{ documentIds, shareWith, expiresAt },
	callback,
) {
	return function (dispatch) {
		shareWith = shareWithHelper.removeEmptyExternalPerson(shareWith);
		req
			.post(`/documents/collections/${collectionId}/share`, {
				documentIds,
				shareWith,
				expiresAt,
			})
			.then((response) => {
				dispatch(
					addInfoNotification({
						tid: "collections.notifications.collection_shared",
					}),
				);
				callback && callback(fromJS(response.data));
			})
			.catch((err) => {
				callback && callback(undefined, err);
			});
	};
}

export function mergePdfsInCollection(collectionId, documentIds, callback) {
	return function (dispatch) {
		req
			.post(`/documents/collections/${collectionId}/merge`, { documentIds })
			.then((response) => {
				const document = fromJS(response.data.document);
				const sharedCollection = fromJS(response.data.sharedCollection);
				dispatch({
					type: COLLECTION_UPDATE_MERGED_DOCUMENT,
					payload: document,
				});
				dispatch(selectSharedCollection(sharedCollection));
				callback && callback();
			})
			.catch((err) => {
				callback && callback(undefined, err);
			});
	};
}

export function saveMergedDocument(documentId, documentData, callback) {
	return function (dispatch) {
		req
			.patch(`/documents/documents/${documentId}`, { documentData })
			.then((response) => {
				const document = fromJS(response.data);
				dispatch({
					type: COLLECTION_UPDATE_MERGED_DOCUMENT,
					payload: document,
				});
				dispatch(
					addInfoNotification({
						tid: "collections.notifications.merged_document_saved",
					}),
				);
				callback && callback(document);
			})
			.catch((err) => {
				callback && callback(undefined, err);
			});
	};
}

export function updateMergedDocumentLocal(mergedDocument) {
	return function (dispatch) {
		dispatch({
			type: COLLECTION_UPDATE_MERGED_DOCUMENT,
			payload: mergedDocument,
		});
	};
}

export function deleteMergedDocument(documentId) {
	return function (dispatch) {
		req.delete(`/documents/documents/${documentId}`).then(() => {
			dispatch({ type: COLLECTION_UPDATE_MERGED_DOCUMENT, payload: null });
		});
	};
}
