import req from "../modules/request.module";
import { fromJS } from "immutable";
import {
	DEPOS_LIST,
	DEPO_CREATE,
	DEPO_UPDATE,
	DEPO_DELETE,
	SHARES_ERROR,
} from "./types";

export function listDepos(callback) {
	return function (dispatch) {
		return req
			.get(`/shares/depos?includeDeleted=true`, {
				preventRepeatedRequests: true,
			})
			.then((response) => {
				response.data.sort((a, b) =>
					a.name.localeCompare(b.name, "sv", { ignorePunctuation: true }),
				);

				dispatch({
					type: DEPOS_LIST,
					payload: fromJS(response.data),
				});

				callback && callback();
			})
			.catch(() => {
				dispatch(sharesError("Could't list depos."));
			});
	};
}

export function createDepo(depo, callback) {
	return function (dispatch) {
		return req
			.post(`/shares/depos`, { depo })
			.then((response) => {
				dispatch({
					type: DEPO_CREATE,
					payload: fromJS(response.data),
				});

				callback && callback();
			})
			.catch(() => {
				dispatch(sharesError("Could't save new depo."));
			});
	};
}

export function editDepo(id, depoData, callback) {
	return function (dispatch) {
		return req
			.put(`/shares/depos/${id}`, { depoData: depoData.toJS() })
			.then((response) => {
				dispatch({
					type: DEPO_UPDATE,
					payload: fromJS(response.data),
				});
				callback && callback();
			})
			.catch(() => {
				dispatch(sharesError("Could't save depo changes."));
			});
	};
}

export function deleteDepo(depoId, callback) {
	return function (dispatch) {
		req
			.delete(`/shares/depos/${depoId}`)
			.then((response) => {
				dispatch({
					type: DEPO_DELETE,
					payload: fromJS(response.data),
				});

				callback && callback();
			})
			.catch(() => {
				dispatch(sharesError("Couldn't delete depo."));
			});
	};
}

/**
 * Error handling
 */
function sharesError(error) {
	return {
		type: SHARES_ERROR,
		payload: error,
	};
}
