import React, { useEffect } from "react";
import emissions from "../../../../services/emissions";
import { useSelector } from "react-redux";
import BaseInvitationModal from "./base-invitation-modal";
import { CustomTheme } from "../custom-theme";
import { ThemeProvider } from "@mui/material/styles";

const ComposeInvitationModal = (props) => {
	const emission = useSelector((state) => state.emissions.current);
	const language = useSelector((state) => state.i18n.language);

	useEffect(() => {
		const f = async () =>
			props.onEmailtemplateChanged(
				await emissions.getInviteTemplate(
					emission.companyId,
					emission.id,
					props.typeOfInvitees,
					language,
				),
			);
		f();
	}, []);

	return (
		<ThemeProvider theme={CustomTheme}>
			<BaseInvitationModal
				invite={props.invite}
				typeOfInvitees={props.typeOfInvitees}
				numOfPeopleToInvite={props.numOfPeopleToInvite}
				onEmailTemplateChanged={(value) =>
					props.onEmailtemplateChanged({
						template: value,
						files: props.invite.files,
					})
				}
			/>
		</ThemeProvider>
	);
};

export default ComposeInvitationModal;
