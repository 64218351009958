import { fromJS, List } from "immutable";
import {
	PROTOCOL_PDF_OPTIONS_LIST,
	PROTOCOL_PDF_OPTIONS_FETCH,
	PROTOCOL_PDF_OPTIONS_SAVE,
	PROTOCOL_PDF_OPTIONS_UPDATE_LOCAL,
	PROTOCOL_PDF_OPTIONS_CLEAR,
	COMPANY_RESET_REDUCER,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	list: null,
	protocolPdfOption: null,
});

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case PROTOCOL_PDF_OPTIONS_LIST:
			return state.set("list", payload);

		case PROTOCOL_PDF_OPTIONS_FETCH:
			return state.set("protocolPdfOption", payload);

		case PROTOCOL_PDF_OPTIONS_SAVE: {
			const index = state
				.get("list", List())
				.findIndex(
					(protocolPdfOption) =>
						protocolPdfOption.get("id") === payload.get("id"),
				);

			if (index >= 0) {
				state = state.setIn(["list", index], payload);
			}

			return state.set("protocolPdfOption", payload);
		}

		case PROTOCOL_PDF_OPTIONS_UPDATE_LOCAL: {
			if (payload) {
				return state.set("protocolPdfOption", payload);
			}

			return state;
		}

		case PROTOCOL_PDF_OPTIONS_CLEAR: {
			const protocolPdfOption = state.get("protocolPdfOption");
			const index = state
				.get("list", List())
				.findIndex((obj) => obj.get("id") === protocolPdfOption.get("id"));

			if (index >= 0) {
				state = state.setIn(["list", index], protocolPdfOption);
			}

			return state.set("protocolPdfOption", null);
		}

		case COMPANY_RESET_REDUCER:
			return INITIAL_STATE;

		default:
			return state;
	}
}
