import React, { PureComponent } from "react";
import styled from "styled-components";

const StyledScreenMessage = styled.div`
	display: flex;
	min-height: 85px;
	background-color: ${(props) => props.theme.colors.java};
	justify-content: center;
	align-items: center;
`;

export default class ScreenMessage extends PureComponent {
	render = () => {
		const { children } = this.props;

		return <StyledScreenMessage>{children}</StyledScreenMessage>;
	};
}
