import React from "react";
import { bool, node, oneOf, func, string } from "prop-types";
import styled, { css } from "styled-components";
import Text from "../text/text";
import Icon from "../icon/icon";
import { lighten } from "polished";
import { StyledButtonTemplate } from "./src/button.template";

const StyledDefaultButton = styled(StyledButtonTemplate)`
	background-color: ${(props) => props.theme.colors.lightestGrey};
	border-color: #d1d9de;
	border-radius: 2px;
	font-weight: 600;

	:active,
	:focus,
	:focus:hover,
	:hover {
		background-color: #e8e8e8;
		border-color: #d1d9de;
	}

	:active:focus,
	:active:hover,
	:active:focus:hover {
		background-color: #d4d4d4;
		border-color: #8c8c8c;
	}

	:focus {
		background-color: ${(props) => props.theme.colors.lightestGrey};
	}

	:disabled:hover {
		background-color: ${(props) => props.theme.colors.lightestGrey};
		border-color: #d1d9de;
	}
`;

const StyledDefaultButtonStatic = styled(StyledDefaultButton)`
	background-color: transparent;
	border-color: ${(props) => (props.borderless ? "transparent" : "#d1d9de")};
	cursor: default;

	:active,
	:focus,
	:focus:hover,
	:hover,
	:active:focus,
	:active:hover,
	:active:focus:hover,
	:disabled:hover {
		background-color: transparent;
		box-shadow: unset;
		border-color: ${(props) => (props.borderless ? "transparent" : "#d1d9de")};
	}
`;

const StyledPrimaryButton = styled(StyledButtonTemplate)`
	border-radius: 2px;
	font-weight: 600;
	background-color: ${(props) => props.theme.colors.dodgerBlue};
	border-color: #42a5f5;

	:active,
	:focus,
	:focus:hover,
	:hover {
		background-color: #1690f3;
		border-color: #2095f3;
	}

	:active:focus,
	:active:hover,
	:active:focus:hover {
		background-color: #204d74;
		border-color: #122b40;
	}

	:focus {
		background-color: ${(props) => props.theme.colors.dodgerBlue};
	}

	:disabled:hover {
		background-color: ${(props) => props.theme.colors.dodgerBlue};
		border-color: #42a5f5;
	}
`;

const StyledDarkButton = styled(StyledButtonTemplate)`
	background-color: ${(props) => props.theme.colors.black};
	border-color: ${(props) => lighten(0.15, props.theme.colors.black)};

	:active,
	:focus,
	:focus:hover,
	:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.black)};
		border-color: ${(props) => lighten(0.2, props.theme.colors.black)};
	}

	:active:focus,
	:active:hover,
	:active:focus:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.black)};
		border-color: ${(props) => lighten(0.2, props.theme.colors.black)};
	}

	:focus {
		background-color: ${(props) => lighten(0.2, props.theme.colors.black)};
	}

	:disabled:hover {
		background-color: ${(props) => lighten(0.3, props.theme.colors.black)};
		border-color: ${(props) => lighten(0.35, props.theme.colors.black)};
	}
`;

const StyledGreenButton = styled(StyledButtonTemplate)`
	background-color: ${(props) => props.theme.colors.green};
	border-color: ${(props) => lighten(0.15, props.theme.colors.green)};

	:active,
	:focus,
	:focus:hover,
	:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.green)};
		border-color: ${(props) => lighten(0.2, props.theme.colors.green)};
	}

	:active:focus,
	:active:hover,
	:active:focus:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.green)};
		border-color: ${(props) => lighten(0.2, props.theme.colors.green)};
	}

	:focus {
		background-color: ${(props) => lighten(0.2, props.theme.colors.green)};
	}

	:disabled:hover {
		background-color: ${(props) => lighten(0.3, props.theme.colors.green)};
		border-color: ${(props) => lighten(0.35, props.theme.colors.green)};
	}
`;

const StyledRedButton = styled(StyledButtonTemplate)`
	background-color: ${(props) => props.theme.colors.red};
	border-color: ${(props) => lighten(0.15, props.theme.colors.red)};

	:active,
	:focus,
	:focus:hover,
	:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.red)};
		border-color: ${(props) => lighten(0.2, props.theme.colors.red)};
	}

	:active:focus,
	:active:hover,
	:active:focus:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.red)};
		border-color: ${(props) => lighten(0.2, props.theme.colors.red)};
	}

	:focus {
		background-color: ${(props) => lighten(0.2, props.theme.colors.red)};
	}

	:disabled:hover {
		background-color: ${(props) => lighten(0.3, props.theme.colors.red)};
		border-color: ${(props) => lighten(0.35, props.theme.colors.red)};
	}
`;

const StyledOrangeButton = styled(StyledButtonTemplate)`
	background-color: ${(props) => props.theme.colors.orange};
	border-color: ${(props) => lighten(0.15, props.theme.colors.orange)};

	:active,
	:focus,
	:focus:hover,
	:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.orange)};
		border-color: ${(props) => lighten(0.2, props.theme.colors.orange)};
	}

	:active:focus,
	:active:hover,
	:active:focus:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.orange)};
		border-color: ${(props) => lighten(0.2, props.theme.colors.orange)};
	}

	:focus {
		background-color: ${(props) => lighten(0.2, props.theme.colors.orange)};
	}

	:disabled:hover {
		background-color: ${(props) => lighten(0.3, props.theme.colors.orange)};
		border-color: ${(props) => lighten(0.35, props.theme.colors.orange)};
	}
`;

const StyledTransparentOutlinedButton = styled(StyledButtonTemplate)`
	background-color: transparent;
	border-color: ${(props) => props.theme.colors.white};

	* {
		color: ${(props) => props.theme.colors.white};
	}

	${(props) =>
		!props.disabled &&
		css`
			:hover {
				border-color: ${(props) => props.theme.colors.muted};

				* {
					transition: all 0.25s;
					color: ${(props) => props.theme.colors.muted};
				}
			}
		`}/** {
		transition: all 0.25s;
		color: ${(props) => props.theme.colors.muted};
	}*/
`;

const StyledRoundedButton = styled(StyledButtonTemplate)`
	background-color: ${(props) =>
		props.transparent ? "transparet" : props.theme.colors.java};
	border-radius: 1000px;
	height: ${(props) => props.height};

	:active,
	:focus,
	:focus:hover,
	:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.java)};
		border-color: ${(props) => lighten(0.2, props.theme.colors.java)};
	}

	:active:focus,
	:active:hover,
	:active:focus:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.java)};
		border-color: ${(props) => lighten(0.2, props.theme.colors.java)};
	}

	:focus {
		background-color: ${(props) => lighten(0.2, props.theme.colors.java)};
	}

	:disabled:hover {
		background-color: ${(props) => lighten(0.3, props.theme.colors.java)};
		border-color: ${(props) => lighten(0.35, props.theme.colors.java)};
	}
`;

const StyledRoundedButtonInverted = styled(StyledButtonTemplate)`
	background-color: ${(props) =>
		props.transparent ? "transparent" : props.theme.colors.white};
	border-color: ${(props) => lighten(0.15, props.theme.colors.white)};
	border-radius: 1000px;
	height: ${(props) => props.height};

	:active,
	:focus,
	:focus:hover,
	:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.white)};
		border-color: ${(props) => lighten(0.2, props.theme.colors.white)};
	}

	:active:focus,
	:active:hover,
	:active:focus:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.white)};
		border-color: ${(props) => lighten(0.2, props.theme.colors.white)};
	}

	:focus {
		background-color: ${(props) => lighten(0.2, props.theme.colors.white)};
	}

	:hover {
		${(props) =>
			props.transparent &&
			css`
				> span,
				> span > span {
					color: ${props.theme.colors.darkGrey};
				}
			`}
	}

	:disabled:hover {
		background-color: ${(props) => lighten(0.3, props.theme.colors.white)};
		border-color: ${(props) => lighten(0.35, props.theme.colors.white)};
	}
`;

const StyledLinkButton = styled(StyledButtonTemplate)`
	background-color: transparent;

	:active {
		box-shadow: none;
	}
`;

const StyledTransparentButton = styled(StyledButtonTemplate)`
	border: none;
	background: transparent;
	padding: 0;

	/* Left/Right margin for every icon button. Removable by noHorizontalMargin prop */
	/*margin-left: ${(props) =>
		props.noHorizontalMargin ? 0 : props.theme.spacing[3]};*/
	margin-right: ${(props) =>
		props.noHorizontalMargin ? 0 : props.theme.spacing[3]};

	${(props) =>
		props.transparentIconButtonSize === "sm" &&
		css`
			min-width: unset;
			height: unset;
		`};

	:active {
		box-shadow: unset;
	}

	:disabled {
		> span > span[class*="fa-"]:hover {
			color: inherit;
		}

		> span:hover {
			> span[class*="fa-"] {
				color: inherit;
			}
		}
	}

	> span {
		> span[class*="fa-"] {
			transition: all 0.3s;
		}

		/* Fill out span inside button so onhover triggers before coursor appears on the icon
		 * in case it doesn't fill out all the space inside a button
		 */
		height: 100%;
		width: 100%;
	}

	> span:hover {
		> span[class*="fa-"] {
			color: ${(props) => props.theme.colors[props.iconHoverColor]};
		}
	}
`;

const StyledChildrenWrapper = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: ${(props) => (props.isLoading ? "hidden" : "visible")};
	color: ${(props) =>
		props.mode === "primary"
			? props.theme.colors.white
			: props.theme.colors.midGrey};
`;

const StyledSpinnerWrapper = styled.div`
	position: absolute;
	top: 0px;
	left: 0;
	/* height: 36px; */
	height: 100%;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const StyledLink = styled.span`
	cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const StyledBrandedButton = styled(StyledButtonTemplate)`
	background-color: ${(props) => props.theme.colors.java};
	border-color: ${(props) => lighten(0.15, props.theme.colors.java)};

	:active,
	:focus,
	:focus:hover,
	:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.java)};
		border-color: ${(props) => lighten(0.2, props.theme.colors.java)};
	}

	:active:focus,
	:active:hover,
	:active:focus:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.java)};
		border-color: ${(props) => lighten(0.2, props.theme.colors.java)};
	}

	:focus {
		background-color: ${(props) => lighten(0.2, props.theme.colors.java)};
	}

	:disabled:hover {
		background-color: ${(props) => lighten(0.3, props.theme.colors.java)};
		border-color: ${(props) => lighten(0.35, props.theme.colors.java)};
	}
`;

const StyledJadedButton = styled(StyledButtonTemplate)`
	background-color: ${(props) => props.theme.colors.java};

	:active,
	:focus,
	:focus:hover,
	:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.java)};
	}

	:active:focus,
	:active:hover,
	:active:focus:hover {
		background-color: ${(props) => lighten(0.15, props.theme.colors.java)};
	}

	:focus {
		background-color: ${(props) => lighten(0.2, props.theme.colors.java)};
	}

	:disabled:hover {
		background-color: ${(props) => lighten(0.3, props.theme.colors.java)};
	}
`;

const Button = ({
	children,
	color,
	isStatic,
	mode,
	disabled,
	isLoading,
	onClick,
	tid,
	block,
	iconColor,
	icon,
	iconHoverColor,
	transparentIconButtonSize,
	inverted,
	onMouseDown,
	height,
	minWidth,
	noHorizontalMargin,
	noHorizontalPadding,
	maxWidth,
	btnRef,
	borderless,
	transparent,
}) => {
	const props = {
		disabled: disabled || isLoading,
		block,
		onClick,
		onMouseDown,
		minWidth,
		height,
		noHorizontalPadding,
		maxWidth,
		ref: btnRef,
		borderless,
		transparent,
		inverted,
	};

	// Link Button
	if (mode === "button-link") {
		return (
			<StyledLinkButton {...props}>
				<StyledChildrenWrapper
					isLoading={isLoading}
					disabled={disabled}
					mode="linkButton"
				>
					{tid && tid.length > 0 && !children ? (
						<Text tid={tid} color="muted" hasUnderline bold={600} />
					) : (
						children
					)}
				</StyledChildrenWrapper>
				{isLoading && (
					<StyledSpinnerWrapper>
						<Icon icon="faSpinner" type="solid" color="muted" spin />
					</StyledSpinnerWrapper>
				)}
			</StyledLinkButton>
		);
	}

	// Dark Button
	if (mode === "dark") {
		return (
			<StyledDarkButton {...props}>
				<StyledChildrenWrapper
					isLoading={isLoading}
					disabled={disabled}
					mode={mode}
				>
					{tid?.length > 0 && !children ? (
						<Text tid={tid} color="white" bold={600} />
					) : (
						children
					)}
				</StyledChildrenWrapper>
				{isLoading && (
					<StyledSpinnerWrapper>
						<Icon icon="faSpinner" type="solid" color="white" spin />
					</StyledSpinnerWrapper>
				)}
			</StyledDarkButton>
		);
	}

	//Rounded Button
	if (mode === "rounded" && !inverted) {
		return (
			<StyledRoundedButton {...props}>
				<StyledChildrenWrapper
					isLoading={isLoading}
					disabled={disabled}
					mode={mode}
				>
					{tid && tid.length > 0 && !children ? (
						<Text tid={tid} color="white" bold={600} />
					) : (
						children
					)}
				</StyledChildrenWrapper>
				{isLoading && (
					<StyledSpinnerWrapper>
						<Icon icon="faSpinner" type="solid" color="white" spin />
					</StyledSpinnerWrapper>
				)}
			</StyledRoundedButton>
		);
	}

	//Rounded Button Inverted
	if (mode === "rounded" && inverted) {
		return (
			<StyledRoundedButtonInverted {...props}>
				<StyledChildrenWrapper
					isLoading={isLoading}
					disabled={disabled}
					mode={mode}
				>
					{tid && tid.length > 0 && !children ? (
						<Text tid={tid} color="black" bold={600} />
					) : (
						children
					)}
				</StyledChildrenWrapper>
				{isLoading && (
					<StyledSpinnerWrapper>
						<Icon icon="faSpinner" type="solid" color="white" spin />
					</StyledSpinnerWrapper>
				)}
			</StyledRoundedButtonInverted>
		);
	}

	// Primary Button
	if (mode === "primary") {
		return (
			<StyledPrimaryButton {...props}>
				<StyledChildrenWrapper
					isLoading={isLoading}
					disabled={disabled}
					mode={mode}
				>
					{tid && tid.length > 0 && !children ? (
						<Text tid={tid} color="white" bold={600} />
					) : (
						children
					)}
				</StyledChildrenWrapper>
				{isLoading && (
					<StyledSpinnerWrapper>
						<Icon icon="faSpinner" type="solid" color="white" spin />
					</StyledSpinnerWrapper>
				)}
			</StyledPrimaryButton>
		);
	}

	// Transparent modern "icon" mode
	if (mode === "transparent-icon") {
		return (
			<StyledTransparentButton
				{...props}
				noHorizontalMargin={noHorizontalMargin}
				iconHoverColor={iconHoverColor}
				transparentIconButtonSize={transparentIconButtonSize}
			>
				<StyledChildrenWrapper
					isLoading={isLoading}
					disabled={disabled}
					mode={mode}
				>
					<Icon
						icon={icon}
						color={iconColor}
						size={transparentIconButtonSize}
					/>
				</StyledChildrenWrapper>
				{isLoading && (
					<StyledSpinnerWrapper>
						<Icon icon="faSpinner" type="solid" spin />
					</StyledSpinnerWrapper>
				)}
			</StyledTransparentButton>
		);
	}

	if (mode === "link") {
		return (
			<StyledLink
				onClick={!disabled ? props.onClick : undefined}
				disabled={disabled}
			>
				{children ? (
					children
				) : (
					<Text
						color={color ? color : "muted"}
						hasUnderline={true}
						tid={tid}
						bold={600}
					/>
				)}
			</StyledLink>
		);
	}

	if (mode === "default" && isStatic) {
		return (
			<StyledDefaultButtonStatic {...props}>
				<StyledChildrenWrapper
					isLoading={isLoading}
					disabled={disabled}
					mode={mode}
				>
					{tid && tid.length > 0 && !children ? <Text tid={tid} /> : children}
				</StyledChildrenWrapper>
			</StyledDefaultButtonStatic>
		);
	}

	// Branded Button
	if (mode === "branded") {
		return (
			<StyledBrandedButton {...props}>
				<StyledChildrenWrapper
					isLoading={isLoading}
					disabled={disabled}
					mode={mode}
				>
					{tid && tid.length > 0 && !children ? (
						<Text tid={tid} color="white" bold={600} />
					) : (
						children
					)}
				</StyledChildrenWrapper>
				{isLoading && (
					<StyledSpinnerWrapper>
						<Icon icon="faSpinner" type="solid" color="white" spin />
					</StyledSpinnerWrapper>
				)}
			</StyledBrandedButton>
		);
	}

	// Jaded Button
	if (mode === "jaded") {
		return (
			<StyledJadedButton {...props}>
				<StyledChildrenWrapper
					isLoading={isLoading}
					disabled={disabled}
					mode={mode}
				>
					{tid && tid.length > 0 && !children ? (
						<Text tid={tid} color="white" bold={600} />
					) : (
						children
					)}
				</StyledChildrenWrapper>
				{isLoading && (
					<StyledSpinnerWrapper>
						<Icon icon="faSpinner" type="solid" color="white" spin />
					</StyledSpinnerWrapper>
				)}
			</StyledJadedButton>
		);
	}

	if (mode === "green") {
		// Green Button
		return (
			<StyledGreenButton {...props}>
				<StyledChildrenWrapper
					isLoading={isLoading}
					disabled={disabled}
					mode={mode}
				>
					{tid && tid.length > 0 && !children ? (
						<Text tid={tid} color="white" bold={600} />
					) : (
						children
					)}
				</StyledChildrenWrapper>
				{isLoading && (
					<StyledSpinnerWrapper>
						<Icon icon="faSpinner" type="solid" spin />
					</StyledSpinnerWrapper>
				)}
			</StyledGreenButton>
		);
	}

	// Red Button
	if (mode === "red") {
		return (
			<StyledRedButton {...props}>
				<StyledChildrenWrapper
					isLoading={isLoading}
					disabled={disabled}
					mode={mode}
				>
					{tid && tid.length > 0 && !children ? (
						<Text tid={tid} color="white" bold={600} />
					) : (
						children
					)}
				</StyledChildrenWrapper>
				{isLoading && (
					<StyledSpinnerWrapper>
						<Icon icon="faSpinner" type="solid" spin />
					</StyledSpinnerWrapper>
				)}
			</StyledRedButton>
		);
	}

	// Orange Button
	if (mode === "orange") {
		return (
			<StyledOrangeButton {...props}>
				<StyledChildrenWrapper
					isLoading={isLoading}
					disabled={disabled}
					mode={mode}
				>
					{tid && tid.length > 0 && !children ? (
						<Text tid={tid} color="white" bold={600} />
					) : (
						children
					)}
				</StyledChildrenWrapper>
				{isLoading && (
					<StyledSpinnerWrapper>
						<Icon icon="faSpinner" type="solid" spin />
					</StyledSpinnerWrapper>
				)}
			</StyledOrangeButton>
		);
	}

	if (mode === "transparent-outlined") {
		return (
			<StyledTransparentOutlinedButton {...props}>
				<StyledChildrenWrapper
					isLoading={isLoading}
					disabled={disabled}
					mode={mode}
				>
					{tid && tid.length > 0 && !children ? (
						<Text tid={tid} color="white" bold={600} />
					) : (
						children
					)}
				</StyledChildrenWrapper>
				{isLoading && (
					<StyledSpinnerWrapper>
						<Icon icon="faSpinner" type="solid" spin />
					</StyledSpinnerWrapper>
				)}
			</StyledTransparentOutlinedButton>
		);
	}

	// Default Button
	return (
		<StyledDefaultButton {...props}>
			<StyledChildrenWrapper
				isLoading={isLoading}
				disabled={disabled}
				mode={mode}
			>
				{tid && tid.length > 0 && !children ? (
					<Text tid={tid} bold={600} />
				) : (
					children
				)}
			</StyledChildrenWrapper>
			{isLoading && (
				<StyledSpinnerWrapper>
					<Icon icon="faSpinner" type="solid" spin />
				</StyledSpinnerWrapper>
			)}
		</StyledDefaultButton>
	);
};

Button.defaultProps = {
	icon: "faCalendarAlt",
	iconHoverColor: "java",
	disabled: false,
	isLoading: false,
	block: false,
	transparentIconButtonSize: "normal",
	minWidth: "36px",
	noHorizontalMargin: false,
	noHorizontalPadding: false,
	mode: "default",
	isStatic: false,
	inverted: false,
	borderless: false,
};

Button.propTypes = {
	children: node,
	disabled: bool,
	icon: string,
	iconColor: string,
	iconHoverColor: string,
	isLoading: bool,
	mode: oneOf([
		"primary",
		"transparent-icon",
		"link",
		"button-link",
		"dark",
		"default",
		"rounded",
		"branded",
		"green",
		"red",
		"orange",
		"jaded",
		"button-link-primary",
	]),
	onClick: func,
	onMouseDown: func,
	tid: string,
	inverted: bool,
	block: bool,
	height: string,
	minWidth: string,
	maxWidth: string,
	transparentIconButtonSize: oneOf(["sm", "sml", "normal", "lg"]),
	noHorizontalMargin: bool,
	noHorizontalPadding: bool,
	btnRef: func,
	isStatic: bool,
	borderless: bool,
	transparent: bool,
};

export default Button;
