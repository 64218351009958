import React, { PureComponent } from "react";
import { string, bool, func, object } from "prop-types";
import styled from "styled-components";
import { Editor, EditorState, getDefaultKeyBinding } from "draft-js";
import Text from "../text/text";

const StyledRoot = styled.div`
	flex: 1;
`;

const StyledEditor = styled.div`
	cursor: text;

	/* Fix for z-index issue for placeholder */
	.public-DraftEditorPlaceholder-root {
		z-index: 0;
	}
`;

class RTE extends PureComponent {
	state = {};

	static defaultProps = {
		editorState: EditorState.createEmpty(),
		simpleMode: false, // Simple Mode disables ENTER key
	};

	static propTypes = {
		onChange: func,
		editorState: object,
		placeholderTid: string,
		simpleMode: bool,
		onClick: func,
		onFocus: func,
		onBlur: func,
		readOnly: bool,
	};

	componentDidMount = () => {
		const { onRef } = this.props;
		onRef && onRef(this);
	};

	focusEditor = () => {
		this.editorRef.focus();
	};

	keyBindingFn = (e) => {
		const { simpleMode } = this.props;

		if (simpleMode && e.key === "Enter") {
			return;
		}

		return getDefaultKeyBinding(e);
	};

	render = () => {
		const {
			className,
			editorState,
			placeholderTid,
			onClick,
			onFocus,
			onBlur,
			onChange,
			readOnly,
		} = this.props;

		return (
			<StyledRoot className={className} onClick={onClick}>
				<StyledEditor>
					<Text>
						<Editor
							editorState={editorState}
							placeholder={
								placeholderTid && (
									<Text tid={placeholderTid} color="placeholder" />
								)
							}
							onChange={onChange}
							keyBindingFn={this.keyBindingFn}
							ref={(r) => (this.editorRef = r)}
							onFocus={onFocus}
							onBlur={onBlur}
							readOnly={readOnly}
							spellCheck={true}
						/>
					</Text>
				</StyledEditor>
			</StyledRoot>
		);
	};
}

export default RTE;
