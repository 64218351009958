/*
  The following example demonstrates how to use all the different dialog types (prompt, confirm, alert) available in the `useDialogModal` hook:

  const { prompt, confirm, alert, dialogElement } = useDialogModal()

  const handlePurchase = () => {
		// Collect item to purchase via a `prompt` dialog
		const selectedItem = await prompt('Select item', (setSubmitValue) => <div>Please select: <select onChange={setSubmitValue} /></div>)

		// Confirm purchase via a `confirm` dialog
    const isConfirmed = await confirm('Are you sure you want to purchase ${selectedItem.name}?')

		// Notify user of successful purchase via an `alert` dialog
    if (isConfirmed) {
			alert('Purchase accepted', 'Your purchase will be completed')
    } else {
			alert('Purchase cancelled', 'Your purchase has been cancelled')
    }
  }

  return (
    <div>
      <button onClick={handlePurchase}>Purchase</button>
      {dialogElement}
    </div>
  )
*/

import React, { useState } from "react";
import DialogModal from "@/components/dialogModal";
import { useTranslation } from "react-i18next";

export default () => {
	const { t } = useTranslation();

	const [showDialog, setShowDialog] = useState(false);
	const [dialogProps, setDialogProps] = useState({});

	const dialogElement = (
		<DialogModal size="sm" {...dialogProps} open={showDialog} />
	);

	const prompt = (title, bodyRenderer, dialogProps) =>
		new Promise((resolve) => {
			setShowDialog(true);

			setDialogProps({
				...dialogProps,
				title,
				bodyRenderer,
				onSubmit: (submitValue) => {
					const value =
						typeof dialogProps.onSubmit === "function"
							? dialogProps.onSubmit(submitValue)
							: submitValue;

					setShowDialog(false);
					resolve(value);
				},
				onCancel: () => {
					setShowDialog(false);
					resolve(false);
				},
			});
		});

	const confirm = (title, body, dialogProps) =>
		prompt(title, () => body, {
			onSubmit: () => true,
			...dialogProps,
		});

	const alert = (title, body, dialogProps) =>
		confirm(title, body, {
			cancelText: t("generic.close"),
			...(dialogProps || {}),
			submitText: null,
		});

	return {
		confirm,
		alert,
		prompt,
		dialogElement,
	};
};
