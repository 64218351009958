import { func, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	createFeature,
	editFeature,
	listFeaturesByPosition,
	unsetFeaturePosition,
	deleteFeature as actionDeleteFeature,
	listFeatures,
} from "../../../actions/feature.actions";
import { FeatureGroupContext } from "./feature-group.context";

function FeatureGroupContextProvider({ position, children }) {
	let [features, setFeatures] = useState([]);
	const [featureOptions, setFeatureOptions] = useState([]);
	const [isFetchingFeatures, setIsFetchingFeatures] = useState(false);
	const isInvonoAdmin = useSelector(
		(state) => state.user.getIn(["userObj", "invonoAdmin"]) || false,
	);
	const [shouldRenderChildren, setShouldRenderChildren] =
		useState(isInvonoAdmin);
	const language = useSelector((state) => state.i18n.language.substr(0, 2));
	const dispatch = useDispatch();

	const saveFeature = (changedFeature, callback) => {
		const isNewfeature = Boolean(!changedFeature.id);
		const action = isNewfeature ? createFeature : editFeature;

		if (!changedFeature.positions) {
			changedFeature.positions = { [position]: features.length };
		}

		dispatch(
			action(changedFeature, (error, response) => {
				if (error) {
					return;
				}

				const index = features.findIndex(({ id }) => id === response.id);
				if (index >= 0) {
					features[index] = response;
				} else {
					features.push(response);
				}

				setFeatures([...features]);
				callback(response);
			}),
		);
	};

	const getFeature = (id) => {
		if (!id) {
			return [features?.length || 0, undefined];
		}

		const index = features
			? features.findIndex((feature) => feature.id === id)
			: 0;
		const feature = features[index];

		return [index, feature];
	};

	const setFeature = (feature) => {
		const index = features && features.findIndex(({ id }) => id === feature.id);

		if (index !== -1) {
			features.splice(index, 0, feature);
		} else {
			features.push(feature);
		}

		setFeatures([...features.sort(sortFeatures)]);
		setFeatureOptions([
			...featureOptions.filter(({ value }) => value !== feature.id),
		]);
	};

	const removeFeature = (id) => {
		dispatch(
			unsetFeaturePosition(id, position, (error, feature) => {
				if (error) {
					return;
				}

				const index = features && features.findIndex((f) => f.id === id);
				if (index >= 0) {
					features.splice(index, 1);
					setFeatures([...features.sort(sortFeatures)]);
					featureOptions.push({ value: id, label: feature.title[language] });
					setFeatureOptions([...featureOptions]);
				}
			}),
		);
	};

	const deleteFeature = (id) => {
		dispatch(
			actionDeleteFeature(id, (error) => {
				if (error) {
					return;
				}

				const index = features && features.findIndex((f) => f.id === id);
				if (index >= 0) {
					features.splice(index, 1);
					setFeatures([...features.sort(sortFeatures)]);
				}
			}),
		);
	};

	const sortFeatures = (a, b) => b.size - a.size;

	useEffect(() => {
		setIsFetchingFeatures(true);
		dispatch(
			listFeaturesByPosition(position, (error, featureResponse) => {
				setIsFetchingFeatures(false);

				if (error) {
					return;
				}

				setFeatures(featureResponse || []);

				if (featureResponse && featureResponse.length > 0) {
					setShouldRenderChildren(true);
				}

				if (isInvonoAdmin) {
					dispatch(
						listFeatures((error, response) => {
							if (error) {
								return;
							}

							const featureIds = featureResponse.map(({ id }) => id);
							const filteredFeatureOptions = response.filter(({ value }) => {
								return !featureIds.includes(value);
							});

							setFeatureOptions(filteredFeatureOptions);
						}),
					);
				}
			}),
		);

		return () => {};
	}, []);

	if (!shouldRenderChildren) {
		return false;
	}

	const value = {
		position,
		getFeature,
		isInvonoAdmin,
		isFetchingFeatures,
		saveFeature,
		setFeature,
		removeFeature,
		deleteFeature,
		featureOptions,
	};

	return (
		<FeatureGroupContext.Provider value={value}>
			{children(features?.map(({ id, size }) => ({ id, size })))}
		</FeatureGroupContext.Provider>
	);
}

FeatureGroupContextProvider.propTypes = {
	position: string.isRequired,
	children: func.isRequired,
};

export { FeatureGroupContextProvider };
