import React, { PureComponent } from "react";
import immutablePropTypes from "react-immutable-proptypes";
import { func } from "prop-types";
import styled, { css } from "styled-components";
import ScrollView from "../../shared/layout/scroll-view/scroll-view";
import List from "../../shared/list/list";
import ListItem from "../../shared/list-item/list-item";
import Text from "../../shared/text/text";
import { StyledListHeaderTemplate } from "../../shared/list/list-header.template";
import Icon from "../../shared/icon/icon";

const StyledHeader = styled.div`
	${StyledListHeaderTemplate};

	> div {
		cursor: default;
	}
`;

const StyledList = styled(List)`
	margin: ${(props) => props.theme.spacing[5]}
		${(props) => props.theme.spacing[6]};
`;

const StyledListItem = styled(ListItem)`
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeDark};
	padding: 0;
	margin-bottom: 0px;
	align-items: center;
`;

const StyledNameTemplate = css`
	display: flex;
	flex: 1;
	height: 100%;
	align-items: center;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
	cursor: pointer;
`;

const StyledBadgeTemplate = css`
	display: flex;
	flex-basis: 200px;
	max-width: 200px;
	align-items: center;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
	justify-content: flex-end;

	${(props) =>
		props.onClick &&
		css`
			cursor: pointer;
		`}
`;

const StyledName = styled.div`
	${StyledNameTemplate}
`;

const StyledBadge = styled.div`
	${StyledBadgeTemplate}
	/* Used for clickable area in the list item */
	height: 100%;
	width: 100%;
`;

export default class ProtocolPdfOptionsList extends PureComponent {
	static propTypes = {
		protocolPdfOptions: immutablePropTypes.list,
		onOptionClick: func,
		onSelectOption: func,
		renderMoreActionButton: func,
	};

	renderHeader = () => {
		return (
			<StyledHeader>
				<StyledName>
					<Text
						tid="meetings.protocol_pdf_options.template"
						bold={400}
						color="lightGrey"
					/>
				</StyledName>
				<StyledBadge />
			</StyledHeader>
		);
	};

	renderProtocolPdfOption = (protocolPdfOption, index) => {
		const { onOptionClick, renderMoreActionButton } = this.props;
		const id = protocolPdfOption.get("id");
		const name = protocolPdfOption.get("name");
		const isDefault = protocolPdfOption.get("isDefault");

		return (
			<StyledListItem hoverColor="lightestGrey" key={index}>
				<StyledName onClick={() => onOptionClick(id)}>
					<Text>{name}</Text>
				</StyledName>
				<StyledBadge onClick={() => onOptionClick(id)}>
					{isDefault && (
						<Icon icon="faCheck" size="sml" type="solid" color="green" />
					)}
				</StyledBadge>
				{renderMoreActionButton && renderMoreActionButton(id)}
			</StyledListItem>
		);
	};

	render = () => {
		const { protocolPdfOptions } = this.props;

		return (
			<ScrollView alwaysShow noLeftMargin noRightMargin>
				<StyledList header={this.renderHeader}>
					{protocolPdfOptions &&
						protocolPdfOptions.map(this.renderProtocolPdfOption)}
				</StyledList>
			</ScrollView>
		);
	};
}
