import React, { PureComponent } from "react";
import immutablePropTypes from "react-immutable-proptypes";
import { string, bool, func, oneOf } from "prop-types";
import { List } from "immutable";
import styled, { css } from "styled-components";
import Text from "../../shared/text/text";
import ProfileImage from "../../shared/profile-image/profile-image";
import Icon from "../../shared/icon/icon";
import ToggleSwitch from "../../shared/toggle-switch/toggle-switch";
import Tooltip from "../../shared/tooltip/tooltip";
import Square from "../../shared/square/square";
import {
	ATTENDEE_STATUS_TO_COLOR_MAP,
	ATTENDEE_STATUS_TO_TOOLTIP_MAP,
} from "../../../constants/meetings";
import { isProxyConfirmedForAttendee } from "../../../components/helpers/meeting.helper";

const StyledAttendee = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	height: 50px;
	cursor: ${(props) => (props.clickable ? "pointer" : "default")};
	width: 100%;
	border: ${(props) =>
		props.active
			? "2px solid hsl(var(--primary))"
			: "1px solid hsl(var(--border-edge))"};
	background-color: ${(props) =>
		props.active ? "hsl(var(--background))" : "transparent"};
	border-radius: 4px;
`;

const StyledUserImageWrapper = styled.div`
	position: relative;
	min-width: 50px;
	height: 100%;
`;

const StyledContent = styled.div`
	flex: 0 1 auto;
	padding: ${(props) => props.theme.spacing[3]}
		${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[3]}
		${(props) => props.theme.spacing[4]};
	height: 100%;
	display: flex;
	flex-direction: column;
	width: 1000px;
	overflow: hidden;
`;

const StyledTitle = styled.div`
	display: flex;
`;

const StyledSubtitle = styled.div`
	display: flex;
`;

const StyledWarning = styled.div`
	min-width: 50px;
	max-width: 50px;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const StyledButtonContainer = styled.div`
	margin-right: ${(props) => props.theme.spacing[3]};
`;

const StyledStatusIndicatorContainer = styled.div`
	min-width: 26px;
	max-width: 26px;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	:last-of-type {
		margin-right: ${(props) => props.theme.spacing[4]};
	}
`;

const StyledToggleSwitchContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0;

	${(props) =>
		props.rightPadding &&
		css`
			padding-right: ${(props) => props.theme.spacing[4]};
		`}
`;

const StyledAdminImageText = styled(Text)`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background: ${({ theme }) => theme.colors.catalinaBlue};
`;

const UserImageComponent = ({
	profileImage,
	userId,
	isStandardAndAttendeeIsModerator,
}) => (
	<StyledUserImageWrapper>
		<ProfileImage
			image={
				profileImage
					? `/api/users/public/images/${profileImage}-80x80?userId=${userId}`
					: null
			}
		/>

		{isStandardAndAttendeeIsModerator && (
			<StyledAdminImageText
				tid="meetings.generic.admin"
				color="white"
				align="center"
				fontSize="10px"
				textTransform="uppercase"
			/>
		)}
	</StyledUserImageWrapper>
);

export default class AttendeeItem extends PureComponent {
	static propTypes = {
		userId: string,
		name: string,
		email: string,
		profileImage: string,
		roles: immutablePropTypes.list,
		chairman: string,
		secretary: string,
		notified: bool,
		notifiedAboutChanges: bool,
		active: bool,
		onClick: func,
		onChange: func,
		renderStatus: func,
		// renderMoreActionButton: func,
		showToggleSwitch: bool,
		attendeesWarning: bool,
		feedbackeesWarning: bool,
		signeesWarning: bool,
		readOnly: bool,
		hasExtendedRights: bool,
		switchDisabled: bool,
		switchTooltipTid: string,
		hasProxy: bool,
		proxy: immutablePropTypes.map,
		assistantForAttendeeName: string,
		meetingHasModerator: bool,
		isStandardMeeting: bool,
		moderators: immutablePropTypes.list,
		hasResponded: bool,
		context: oneOf(["attendees", "feedback", "signing"]),
	};

	static defaultProps = {
		switchDisabled: false,
	};

	renderNotNotifiedWarning = () => {
		const {
			isStandardMeeting,
			meetingHasModerator,
			hasExtendedRights,
			attendeesWarning,
			notified,
			notifiedAboutChanges,
			notifyProxyInvite,
			hasResponded,
		} = this.props;

		if (isStandardMeeting && !meetingHasModerator) {
			return;
		}

		return (
			<>
				{hasExtendedRights &&
					attendeesWarning &&
					(!notified || !notifiedAboutChanges || notifyProxyInvite) &&
					!hasResponded && (
						<StyledWarning>
							<Tooltip
								tid={
									notifyProxyInvite
										? "meetings.attendees.list.tooltip.proxy_needs_invite"
										: "meetings.attendees.list.tooltip.attendee_not_notified"
								}
								delayShow="instant"
							>
								<Icon
									icon="faExclamationTriangle"
									size="sml"
									type="solid"
									color="orange"
								/>
							</Tooltip>
						</StyledWarning>
					)}
			</>
		);
	};

	renderResponseStatus = () => {
		const { responseStatus } = this.props;
		const statusColor =
			ATTENDEE_STATUS_TO_COLOR_MAP[responseStatus || "default"];
		const tooltipTid =
			ATTENDEE_STATUS_TO_TOOLTIP_MAP[responseStatus || "default"];

		return (
			<StyledStatusIndicatorContainer>
				<Tooltip tid={tooltipTid} delayShow="instant">
					<Square bgColor={statusColor} />
				</Tooltip>
			</StyledStatusIndicatorContainer>
		);
	};

	renderProxy = (txtColor, hasNoSeparator) => {
		const { proxy } = this.props;

		return (
			<>
				{!hasNoSeparator && <>{this.renderSeparator()}</>}
				<Text
					color={txtColor ? txtColor : "muted"}
					singleLine
					tid="meetings.attendee.proxyType.PROXY"
					values={{ name: proxy.get("name") }}
				/>
			</>
		);
	};

	renderAssistantOfAttendee = (hasNoSeparator) => {
		const { assistantForAttendeeName } = this.props;

		return (
			<>
				{!hasNoSeparator && <>{this.renderSeparator()}</>}
				<Text
					color="muted"
					singleLine
					tid="meetings.attendee.assistant_of_attendee"
					values={{ name: assistantForAttendeeName }}
				/>
			</>
		);
	};

	renderProxyReplyPending = (hasNoSeparator) => {
		const { proxy } = this.props;

		return (
			<>
				{!hasNoSeparator && <>{this.renderSeparator()}</>}
				<Text
					color="muted"
					singleLine
					tid="meetings.agm.attendees_list_item.proxyType.reply_pending.PROXY"
					values={{ name: proxy.get("name") }}
				/>
			</>
		);
	};

	renderProfileImage = (isStandardAndAttendeeIsModerator) => {
		const { profileImage, userId } = this.props;

		return (
			<UserImageComponent
				profileImage={profileImage}
				userId={userId}
				isStandardAndAttendeeIsModerator={isStandardAndAttendeeIsModerator}
			/>
		);
	};

	renderNameTitle = () => {
		const { name } = this.props;
		let email = this.props.email;
		let numOfEmails = 1;

		if (List.isList(this.props.email)) {
			email = this.props.email.get(0);
			numOfEmails = this.props.email.size;
		}

		return (
			<StyledTitle>
				{name && numOfEmails > 1 && (
					<Text singleLine>{`${name}${email ? ` - ${email}` : ""}`}</Text>
				)}
				{name && numOfEmails === 1 && <Text singleLine>{name}</Text>}
			</StyledTitle>
		);
	};

	renderCompanyNameTitle = ({ attendeeIsChairman, attendeeIsSecretary }) => {
		const { companyName, hasProxy, assistantForAttendeeName } = this.props;

		return (
			<>
				{companyName && !(attendeeIsChairman || attendeeIsSecretary) && (
					<StyledSubtitle>
						<Text color="muted"> {companyName}</Text>
						{hasProxy && <>{this.renderProxy()}</>}
						{assistantForAttendeeName && (
							<>{this.renderAssistantOfAttendee()}</>
						)}
					</StyledSubtitle>
				)}
			</>
		);
	};

	renderSeparator = () => {
		return <Text color="muted">&nbsp; | &nbsp;</Text>;
	};

	renderFunctions = ({
		attendeeisChairmanOrSecretary,
		attendeeIsChairman,
		attendeeIsSecretary,
		confirmedProxy,
	}) => {
		const { proxy, hasProxy, assistantForAttendeeName } = this.props;

		return (
			<>
				{attendeeisChairmanOrSecretary && (
					<StyledSubtitle>
						{attendeeIsChairman && (
							<Text color="muted" tid="meetings.general.label.chairman" />
						)}
						{attendeeIsChairman && attendeeIsSecretary && (
							<>{this.renderSeparator()}</>
						)}
						{attendeeIsSecretary && (
							<Text color="muted" tid="meetings.general.label.secretary" />
						)}
						{!confirmedProxy && proxy && <>{this.renderProxyReplyPending()}</>}
						{hasProxy && <>{this.renderProxy()}</>}
						{assistantForAttendeeName && (
							<>{this.renderAssistantOfAttendee()}</>
						)}
					</StyledSubtitle>
				)}
			</>
		);
	};

	renderRoles = ({
		attendeeIsChairman,
		attendeeIsSecretary,
		confirmedProxy,
	}) => {
		const {
			companyName,
			roles,
			roleTextComponent,
			proxy,
			hasProxy,
			assistantForAttendeeName,
		} = this.props;

		return (
			<>
				{!companyName &&
					roles &&
					!attendeeIsChairman &&
					!attendeeIsSecretary && (
						<>
							<StyledSubtitle>
								<Text color="muted" singleLine>
									{roleTextComponent}
								</Text>

								{!confirmedProxy && proxy && (
									<>{this.renderProxyReplyPending()}</>
								)}
								{hasProxy && <>{this.renderProxy("red")}</>}
								{assistantForAttendeeName && (
									<>{this.renderAssistantOfAttendee()}</>
								)}
							</StyledSubtitle>
						</>
					)}
			</>
		);
	};

	renderProxyPending = ({
		isStandardAndAttendeeIsModerator,
		confirmedProxy,
		attendeeisChairmanOrSecretary,
	}) => {
		const { proxy, roles } = this.props;

		return (
			<>
				{!isStandardAndAttendeeIsModerator &&
					!confirmedProxy &&
					proxy &&
					!attendeeisChairmanOrSecretary &&
					!roles && (
						<StyledSubtitle>
							{this.renderProxyReplyPending("hasNoSeparator")}
						</StyledSubtitle>
					)}
			</>
		);
	};

	renderHasProxy = ({
		isStandardAndAttendeeIsModerator,
		attendeeIsChairman,
		attendeeIsSecretary,
	}) => {
		const { hasProxy, companyName, roles } = this.props;
		return (
			<>
				{!isStandardAndAttendeeIsModerator &&
					hasProxy &&
					!companyName &&
					!roles &&
					!attendeeIsChairman &&
					!attendeeIsSecretary && (
						<StyledSubtitle>
							{this.renderProxy(null, "hasNoSeparator")}
						</StyledSubtitle>
					)}
			</>
		);
	};

	renderAssistant = () => {
		const { assistantForAttendeeName } = this.props;
		return (
			<>
				{assistantForAttendeeName && (
					<StyledSubtitle>
						{this.renderAssistantOfAttendee("hasNoSeparator")}
					</StyledSubtitle>
				)}
			</>
		);
	};

	renderFeedbackeesOrSigneesWarning = () => {
		const {
			hasExtendedRights,
			feedbackeesWarning,
			signeesWarning,
			active,
			notified,
		} = this.props;

		return (
			<>
				{hasExtendedRights &&
					(feedbackeesWarning || signeesWarning) &&
					active &&
					!notified && (
						<StyledWarning>
							<Tooltip
								tid={
									feedbackeesWarning
										? "meetings.attendees.list.tooltip.feedbackee_not_notified"
										: "meetings.attendees.list.tooltip.signee_not_notified"
								}
								delayShow="instant"
							>
								<Icon
									icon="faExclamationTriangle"
									size="sml"
									type="solid"
									color="orange"
								/>
							</Tooltip>
						</StyledWarning>
					)}
			</>
		);
	};

	renderToggleSwitch = () => {
		const {
			readOnly,
			showToggleSwitch,
			renderMoreActionButton,
			switchDisabled,
			switchTooltipTid,
			active,
			onChange,
		} = this.props;

		return (
			<>
				{!readOnly && showToggleSwitch && (
					<StyledToggleSwitchContainer
						rightPadding={renderMoreActionButton ? false : true}
					>
						<Tooltip
							active={switchDisabled && switchTooltipTid}
							tid={switchTooltipTid}
							delayShow="instant"
						>
							<ToggleSwitch
								fieldName="active"
								checked={active}
								onChange={onChange}
								disabled={switchDisabled}
							/>
						</Tooltip>
					</StyledToggleSwitchContainer>
				)}
			</>
		);
	};

	renderMoreAction = () => {
		const { readOnly, renderMoreActionButton, userId } = this.props;

		return (
			<>
				{!readOnly && renderMoreActionButton && (
					<StyledButtonContainer>
						{renderMoreActionButton(userId)}
					</StyledButtonContainer>
				)}
			</>
		);
	};

	render = () => {
		const {
			userId,
			chairman,
			secretary,
			onClick,
			renderStatus,
			proxy,
			clickable,
			isStandardMeeting,
			moderators,
			context,
			active,
		} = this.props;
		const attendeeIsChairman = chairman && chairman === userId;
		const attendeeIsSecretary = secretary && secretary === userId;
		const attendeeIsModerator = moderators?.includes(userId);
		const isStandardAndAttendeeIsModerator =
			isStandardMeeting && attendeeIsModerator;
		const attendeeisChairmanOrSecretary =
			attendeeIsChairman || attendeeIsSecretary;
		const confirmedProxy = isProxyConfirmedForAttendee(proxy, userId);
		const contextIsFeedback = context && context === "feedback";

		return (
			<StyledAttendee clickable={clickable} active={active}>
				{this.renderProfileImage(isStandardAndAttendeeIsModerator)}

				{renderStatus && renderStatus()}

				<StyledContent onClick={() => onClick && onClick()}>
					{this.renderNameTitle()}
					{this.renderCompanyNameTitle({
						attendeeIsChairman,
						attendeeIsSecretary,
					})}
					{this.renderFunctions({
						attendeeisChairmanOrSecretary,
						attendeeIsChairman,
						attendeeIsSecretary,
						confirmedProxy,
					})}
					{this.renderRoles({
						attendeeIsChairman,
						attendeeIsSecretary,
						confirmedProxy,
					})}
					{this.renderProxyPending({
						isStandardAndAttendeeIsModerator,
						confirmedProxy,
						attendeeisChairmanOrSecretary,
					})}
					{this.renderHasProxy({
						isStandardAndAttendeeIsModerator,
						attendeeIsChairman,
						attendeeIsSecretary,
					})}
					{this.renderAssistant()}
				</StyledContent>

				{this.renderNotNotifiedWarning()}

				{!contextIsFeedback && this.renderResponseStatus()}

				{this.renderFeedbackeesOrSigneesWarning()}

				{this.renderToggleSwitch()}

				{this.renderMoreAction()}
			</StyledAttendee>
		);
	};
}
