import React from "react";
import { func, object, oneOf } from "prop-types";
import styled from "styled-components";
import List from "../../shared/list/list";
import ListItem from "../../shared/list-item/list-item";
import Text from "../../shared/text/text";
import Button from "../../shared/button/button";
import Moment from "../../../modules/moment.module";
import ImmutableProps from "react-immutable-proptypes";
import { Margin, Padding } from "styled-components-spacing";
import { StyledListHeaderTemplate } from "../../shared/list/list-header.template";
import Panel from "../../shared/panel/panel";
import history from "../../../interfaces/history";
import { formatOrgPretty } from "/shared/helpers/helpers";

const StyledHeader = styled.div`
	${StyledListHeaderTemplate}
	border-left: ${(props) => props.theme.spacing[3]} solid transparent;
	padding-left: ${(props) => props.theme.spacing[3]};
`;

const StyledListItem = styled(ListItem)`
	align-items: center;
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeLight};
	margin-bottom: 0px;
	border-left: ${(props) =>
		props.isCanceled
			? `${props.theme.spacing[3]} solid ${props.theme.colors.persianRed}`
			: `${props.theme.spacing[3]} solid transparent`};
	padding-left: ${(props) => props.theme.spacing[3]};
`;

/* Headers */
const StyledHeaderTitle = styled.div`
	flex: 1;
	padding: 0 ${(props) => props.theme.spacing[3]};
`;

const StyledHeaderOrgNr = styled.div`
	width: 110px;
`;

const StyledHeaderRoles = styled.div`
	width: 27%;
`;

const StyledHeaderStatus = styled.div`
	width: 28%;
`;

const StyledHeaderButtonPlaceholder = styled.div`
	width: 7%;
	min-width: 80px;
`;

/* List */
const StyledListTitle = styled.div`
	flex: 1;
	padding: 0 ${(props) => props.theme.spacing[3]};
`;

const StyledListOrgNr = styled.div`
	width: 110px;
`;

const StyledListRoles = styled.div`
	width: 27%;
`;

const StyledListStatus = styled.div`
	width: 28%;
`;

const StyledListButtonPlaceholder = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-right: ${(props) => props.theme.spacing[3]};
	width: 7%;
	min-width: 80px;
`;

const StyledEmptyTableWrapper = styled.div``;

const StyledEmptyWrapper = styled.div`
	height: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
`;

const StyledEmptyTextWrapper = styled.div``;

const StyledButtonWrapper = styled.div`
	align-self: center;
	margin-top: ${(props) => props.theme.spacing[3]};
`;

const CompanyList = ({
	companies,
	companiesAccessRights,
	onReactivateCompany,
	rowIsLoading,
	emptyMode,
	user,
	pagerController,
	onOpenCompany,
}) => {
	const demoIndex =
		companies &&
		user &&
		companies.findIndex(
			(company) =>
				company.get("isUserDemo") &&
				company.get("superUser") === user.get("id"),
		);

	/**
	 * Place demo company on the top if one was found in the companies list.
	 * Overwrites companies.
	 */
	if (companies && demoIndex >= 0) {
		companies = companies.delete(demoIndex).insert(0, companies.get(demoIndex));
	}

	const renderHeader = () => {
		return (
			<StyledHeader>
				<StyledHeaderOrgNr>
					<Text tid="account.orgnumber" bold={400} color="lightGrey" />
				</StyledHeaderOrgNr>
				<StyledHeaderTitle>
					<Text tid="generic.company" bold={400} color="lightGrey" />
				</StyledHeaderTitle>
				<StyledHeaderStatus>
					<Text tid="generic.status" bold={400} color="lightGrey" />
				</StyledHeaderStatus>
				<StyledHeaderRoles>
					<Text tid="generic.my_roles" bold={400} color="lightGrey" />
				</StyledHeaderRoles>
				<StyledHeaderButtonPlaceholder />
			</StyledHeader>
		);
	};

	const calcDaysUntilDeletion = (company) => {
		const isCanceledAt = Moment(company.get("isCanceledAt"));
		const numberOfDaysToDelete = company.get("numberOfDaysToDelete");
		return numberOfDaysToDelete - Moment().diff(isCanceledAt, "days");
	};

	const onGetStartedClicked = () => {
		history.push("/services");
	};

	const renderStatus = (company) => {
		const userMetaData =
			user &&
			company
				.get("usersMetaData")
				.find((obj) => obj.get("userId") === user.get("id"));
		const isSuperUser = user && company.get("superUser") === user.get("id");

		if (company.get("isCanceled")) {
			const daysUntilDeletion = calcDaysUntilDeletion(company);

			if (daysUntilDeletion === 1) {
				return (
					<Text
						tid="services.company_will_be_canceled_text_single"
						values={{ days: daysUntilDeletion }}
					/>
				);
			}

			return (
				<Text
					tid="services.company_will_be_canceled_text"
					values={{ days: daysUntilDeletion }}
				/>
			);
		}

		if (!userMetaData) {
			return null;
		}

		return (
			<Text
				tid={isSuperUser ? "account_holder_since" : "member_since"}
				values={{ date: Moment(userMetaData.get("memberAt")).format("ll") }}
			/>
		);
	};

	const renderCompany = (company, index) => {
		if (!company) {
			return null;
		}
		let myTeams =
			companiesAccessRights && companiesAccessRights.get(company.get("id"));

		if (myTeams && company.get("superUser") === user.get("id")) {
			const index = myTeams.findIndex((team) => {
				return team === "Super Administratör";
			});
			myTeams = myTeams.update(index, () => {
				return "Kontoägare";
			});
		}

		const _onOpenCompany = () => {
			onOpenCompany && onOpenCompany(company);
		};

		return (
			<StyledListItem
				key={company.get("id")}
				striped={true}
				isCanceled={company.get("isCanceled")}
				emptyMode={emptyMode}
				hoverColor="lightestGrey"
			>
				<StyledListOrgNr>
					<Text>
						{company.get("orgNumber").indexOf("demo=") === 0 ||
						company.get("orgNumber").indexOf("userdemo=") === 0
							? "556987-2335"
							: formatOrgPretty(company.get("orgNumber"))}
					</Text>
				</StyledListOrgNr>
				<StyledListTitle isCanceled={company.get("isCanceled")}>
					<Text>{company.get("name")}</Text>
				</StyledListTitle>
				<StyledListStatus>{renderStatus(company)}</StyledListStatus>
				<StyledListRoles>
					<Text>{myTeams ? myTeams.join(", ") : null}</Text>
				</StyledListRoles>
				<StyledListButtonPlaceholder>
					{company.get("isCanceled") ? (
						<Button
							onClick={onReactivateCompany.bind(null, company.get("id"), index)}
							isLoading={rowIsLoading[index]}
							tid="generic.activate"
							mode="primary"
							minWidth="80px"
						/>
					) : (
						<Button
							tid="generic.open"
							onClick={_onOpenCompany}
							minWidth="80px"
						/>
					)}
				</StyledListButtonPlaceholder>
			</StyledListItem>
		);
	};

	if (companies && companies.size === 0 && emptyMode === "overview") {
		return (
			<Panel
				title="service.user_dashboard.my_accounts"
				mode="light"
				footerComponent={pagerController}
				bodyHeight="308px"
				marginBottom
			>
				<Padding all={4} bottom={3}>
					<StyledEmptyWrapper>
						<StyledEmptyTextWrapper>
							<Text
								tid="service.user_dashboard.no_companies_listed"
								bold={700}
								tag="p"
							/>
							<Text tid="service.user_dashboard.no_premium_companies_listed_desc" />
						</StyledEmptyTextWrapper>
						<StyledButtonWrapper>
							<Button
								tid="generic.get_started"
								mode="primary"
								onClick={onGetStartedClicked}
							/>
						</StyledButtonWrapper>
					</StyledEmptyWrapper>
				</Padding>
			</Panel>
		);
	}

	if (emptyMode === "overview") {
		return (
			<Panel
				title="service.user_dashboard.my_accounts"
				mode="light"
				footerComponent={pagerController}
				bodyHeight="308px"
				marginBottom
			>
				<Padding all={4} bottom={3}>
					<List header={renderHeader}>
						{companies && companies.map(renderCompany.bind(null))}
						{companies && companies.size === 0 && emptyMode === "accounts" && (
							<StyledEmptyTableWrapper>
								<StyledListItem striped={true} />
								<Margin top={5}>
									<Text
										tag="p"
										bold={700}
										tid="my_accounts.you_have_no_companies_listed_header"
									/>
									<Text tid="my_accounts.you_have_no_companies_listed_text" />
								</Margin>
							</StyledEmptyTableWrapper>
						)}
					</List>
				</Padding>
			</Panel>
		);
	}

	return (
		<List header={renderHeader}>
			{companies && companies.map(renderCompany.bind(null))}
			{companies && companies.size === 0 && emptyMode === "accounts" && (
				<StyledEmptyTableWrapper>
					<StyledListItem striped={true} />
					<Margin top={5}>
						<Text
							tag="p"
							bold={700}
							tid="my_accounts.you_have_no_companies_listed_header"
						/>
						<Text tid="my_accounts.you_have_no_companies_listed_text" />
					</Margin>
				</StyledEmptyTableWrapper>
			)}
		</List>
	);
};

CompanyList.defaultProps = {
	emptyMode: "overview",
};

CompanyList.propTypes = {
	companies: ImmutableProps.list,
	companiesAccessRights: ImmutableProps.map,
	rowIsLoading: object,
	user: object,
	onReactivateCompany: func,
	emptyMode: oneOf(["overview", "accounts"]),
	pagerController: func,
	onOpenCompany: func,
};

export default CompanyList;
