import React, { PureComponent } from "react";
import { bool, func } from "prop-types";
import styled from "styled-components";

import Button from "../button/button";
import BankId from "../bankid/bankid";
import Modal from "../modal/modal";

const StyledWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

export default class BankIdModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		onCancel: func,
		bankIdInfo: BankId.propTypes.bankIdInfo,
	};

	static defaultProps = {
		bankIdInfo: BankId.defaultProps.bankIdInfo,
	};

	renderFooterComponent = () => {
		const { onCancel } = this.props;

		if (!onCancel) return null;

		return <Button onClick={onCancel} tid="generic.form.cancel" />;
	};

	render = () => {
		const { isOpen, bankIdInfo } = this.props;

		return (
			<Modal
				isOpen={isOpen}
				hSize="md"
				vSize={75}
				scrollableContent={false}
				footerComponent={this.renderFooterComponent()}
			>
				<StyledWrapper>
					<BankId
						mode="dark"
						bankIdInfo={bankIdInfo}
						fill={false}
						coloredLogo={true}
						size="75px"
					/>
				</StyledWrapper>
			</Modal>
		);
	};
}
