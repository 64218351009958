import React from "react";
import IconButton from "../../mui-components/button/icon-button";
import { useSelector } from "react-redux";
import { string } from "prop-types";
import { HELPSCOUT_ARTICLES } from "../../constants/helpscout-articles";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

const StyledLink = styled("a")(({ theme }) => ({
	color: theme.palette.positive.main,
	...theme.typography.link,
	"&:hover": {
		textDecoration: "underline",
		color: theme.palette.positive.hover,
	},
}));

export default function InfoSidebar({ article, link, linkTid }) {
	const { t } = useTranslation();
	const locale = useSelector((s) => s.i18n.language);

	return (
		<>
			{link ? (
				<StyledLink
					href={"#"}
					data-beacon-article-sidebar={HELPSCOUT_ARTICLES[article][locale]}
				>
					{t(linkTid)}
				</StyledLink>
			) : (
				<IconButton
					icon={"faInfoCirle"}
					onClick={() => {
						Beacon("article", HELPSCOUT_ARTICLES[article][locale], {
							type: "sidebar",
						});
					}}
					noBorder
					color={"dodgerBlue"}
				/>
			)}
		</>
	);
}

InfoSidebar.defaultProps = {};

InfoSidebar.propTypes = {
	article: string,
};
