import { fromJS } from "immutable";
import req from "../modules/request.module";
import {
	GROUPS_CREATE_REMOTE,
	GROUPS_FETCH_REMOTE,
	GROUPS_DELETE_REMOTE,
	GROUPS_SAVE_REMOTE,
	GROUPS_LIST,
	GROUPS_UPDATE_LOCAL,
	GROUPS_UPDATE_SELECTED_GROUP_ID,
	GROUPS_GET_DEFAULT_PERMISSIONS,
} from "./types";
import {
	LIVE_GROUP_CREATE,
	LIVE_GROUP_UPDATE,
	LIVE_GROUP_DELETE,
} from "../constants/live-update";
import { setLiveRequest, resetLiveRequest } from "./live-update.actions";
import documentsHelper from "../components/helpers/documents.helper";

export function updateSelectedGroupId(id) {
	return {
		type: GROUPS_UPDATE_SELECTED_GROUP_ID,
		payload: id,
	};
}
export function fetchDefaultPermissions(parentId, createGroupObject = true) {
	return function (dispatch) {
		return req
			.get(
				`/meetings/groups/default-permissions/${parentId ? parentId : "root"}`,
			)
			.then((response) => {
				dispatch({
					type: GROUPS_GET_DEFAULT_PERMISSIONS,
					payload: fromJS({ permissions: response.data, createGroupObject }),
				});
			});
	};
}

export function fetchGroup(id) {
	return function (dispatch) {
		return req.get(`/meetings/groups/${id}`).then((response) => {
			dispatch({ type: GROUPS_FETCH_REMOTE, payload: fromJS(response.data) });
		});
	};
}

export function createGroup(group, callback, errCallback) {
	return function (dispatch) {
		return req
			.post(`/meetings/groups`, group)
			.then((response) => {
				const newGroup = fromJS(response.data);
				dispatch({ type: GROUPS_CREATE_REMOTE, payload: newGroup });
				callback && callback(newGroup);
			})
			.catch(() => {
				errCallback && errCallback();
			});
	};
}

export function editGroup(group, callback, errCallback) {
	return function (dispatch) {
		return req
			.put(`/meetings/groups/${group.get("id")}`, group.toJS())
			.then((response) => {
				dispatch({ type: GROUPS_SAVE_REMOTE, payload: fromJS(response.data) });
				callback && callback();
			})
			.catch(() => {
				errCallback && errCallback();
			});
	};
}

export function deleteGroup(id, callback, errCallback) {
	return function (dispatch) {
		return req
			.delete(`/meetings/groups/${id}`)
			.then((response) => {
				dispatch({
					type: GROUPS_DELETE_REMOTE,
					payload: fromJS(response.data),
				});
				callback && callback();
			})
			.catch(() => {
				errCallback && errCallback();
			});
	};
}

export function listGroups() {
	return function (dispatch) {
		return req.get(`/meetings/groups`).then((response) => {
			dispatch({ type: GROUPS_LIST, payload: fromJS(response.data) });
		});
	};
}

export function updateGroupLocal(group) {
	return function (dispatch) {
		dispatch({ type: GROUPS_UPDATE_LOCAL, payload: group });
	};
}

export function downloadGroupMatrix(openInViewer) {
	let w = null;
	if (openInViewer) {
		w = window.open(
			`https://${window.location.hostname}/assets/build/misc/redirecting.html`,
			"_blank",
		);
	}

	return function () {
		return req.get(`/meetings/groups/matrix`).then((response) => {
			let { downloadUrl, fileSize } = response.data;
			if (openInViewer) {
				const { webViewerSupportedFormat, webViewerSupportedSize } =
					documentsHelper.getWebViewerIsSupported(undefined, "xlsx", fileSize);
				if (webViewerSupportedFormat && webViewerSupportedSize) {
					downloadUrl = encodeURIComponent(downloadUrl);
					const webViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${downloadUrl}`;
					w.location = webViewerUrl;
				} else {
					w.location = downloadUrl;
				}
			} else {
				window.open(downloadUrl, "_self");
			}
		});
	};
}

// ================ AUDIT ================

export function socketEventGroups(eventObj) {
	const { eventName, objId, metadata } = eventObj;

	return function (dispatch) {
		switch (eventName) {
			case LIVE_GROUP_CREATE:
			case LIVE_GROUP_UPDATE:
			case LIVE_GROUP_DELETE:
				dispatch(
					setLiveRequest(["groups", eventName], {
						refresh: true,
						objId,
						metadata,
					}),
				);
				dispatch(resetLiveRequest(["groups", eventName]));
				break;
		}
	};
}
