import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SearchHoc from "../../../hocs/search-hoc";
import { v4 } from "uuid";
import { bool, func, string, object, oneOfType } from "prop-types";
import { useDispatch } from "react-redux";
import { searchCompany } from "../../../actions/syna.actions";

const SynaSearchContainer = ({
	id,
	label,
	onChange,
	onClear,
	disabled,
	noOptionsText,
	initialValue,
	meta = {},
	onBlur,
	inputRef,
}) => {
	const { submitError, dirtySinceLastSubmit, error, touched } = meta;
	const dispatch = useDispatch();
	const searchFunc = (query, callback) => {
		dispatch(searchCompany(query, callback));
	};

	const myShowErrorFunction = () =>
		!!(((submitError && !dirtySinceLastSubmit) || error) && touched);
	const hasError = myShowErrorFunction();

	return (
		<SearchHoc
			onChange={onChange}
			onClear={onClear}
			initialValue={initialValue}
			searchFunc={searchFunc}
		>
			{({ value, options, loading, setValue, setInputValue, setOptions }) => {
				return (
					<Autocomplete
						id={id || v4()}
						filterOptions={(x) => x}
						options={options}
						noOptionsText={noOptionsText}
						autoComplete
						includeInputInList
						filterSelectedOptions
						loading={loading}
						disabled={disabled}
						value={value}
						onChange={(event, newValue) => {
							setOptions(newValue ? [newValue, ...options] : options);
							setValue(newValue);
						}}
						onInputChange={(event, newInputValue) => {
							setInputValue(newInputValue);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label={label}
								fullWidth
								error={hasError}
								helperText={hasError ? error : undefined}
								onBlur={onBlur}
								inputRef={inputRef}
							/>
						)}
						renderOption={(props, option) => {
							return (
								<li {...props} key={`${option.value + option.label}`}>
									{option.label}
								</li>
							);
						}}
					/>
				);
			}}
		</SearchHoc>
	);
};

SynaSearchContainer.propTypes = {
	id: string,
	label: string,
	onChange: func.isRequired,
	onClear: func,
	disabled: bool,
	initialValue: oneOfType([object, string]),
	meta: object,
	onBlur: func,
};

export default SynaSearchContainer;
