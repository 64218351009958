/*
See https://mui.com/x/api/date-pickers/date-picker/ for definition of props.
*/
import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";

const DatePickerInput = styled.div`
	cursor: pointer;
`;

function DatePickerRenderer(params) {
	const {
		value,
		minDate,
		maxDate,
		onChange,
		disableOpenPicker,
		open,
		onClose,
		onClick,
	} = params;

	return (
		<DatePicker
			name="date"
			onChange={onChange}
			open={open}
			onClose={onClose}
			value={value}
			disableOpenPicker={disableOpenPicker}
			minDate={minDate}
			maxDate={maxDate}
			renderInput={(params) => {
				return (
					<DatePickerInput onClick={onClick} ref={params.inputRef}>
						{params.inputProps.value}
					</DatePickerInput>
				);
			}}
		/>
	);
}

export { DatePickerRenderer };
