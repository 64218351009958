import React, { useState } from "react";
import { connect } from "react-redux";
import { COMPANY_ADD_NEW_COMPANY_MODAL } from "../../constants/modals";
import StepsModal from "../../dumb-components/shared/modal/steps-modal";
import CompanyAccountWizard from "./account-wizard/account-wizard.container";
import InitAuthorizeCompany from "./account-wizard/init-authorize-company.container";
import FooterRightControls from "../../dumb-components/shared/modal/footer-right-controls";
import { TransparentButton } from "../../dumb-components/shared/button-v2";
import history from "../../interfaces/history";
import { closeModal } from "../../actions/modals.actions";
import { createUnverifiedCompany } from "../../actions/company.actions";

const INITIAL_STATE = {
	step: 0,
	authData: null,
};

const AddCompanyModalContainer = ({
	isOpen,
	closeModal,
	createUnverifiedCompany,
	userObj,
	history,
}) => {
	const [state, setState] = useState(INITIAL_STATE);

	const onCloseModal = () => {
		resetState();
		closeModal();
	};

	const goToFirstStep = () => {
		resetState();
	};

	const goToAutorize = (authData) => {
		setState({ step: 1, authData });
	};

	const onSkipAuthorise = (authData) => {
		createUnverifiedCompany(
			authData.get("companyOrgNumber"),
			userObj.get("email"),
			userObj.get("name"),
			authData.get("companyName"),
			(company, err) => {
				if (!err) {
					closeModal();
					history.push(`/${company.urlAlias}`);
				}
			},
		);
	};

	const onVerifyToCustomer = (authData) => {
		createUnverifiedCompany(
			authData.get("companyOrgNumber"),
			userObj.get("email"),
			userObj.get("name"),
			authData.get("companyName"),
			(company, err) => {
				if (!err) {
					onCloseModal();
					history.push(`/${company.urlAlias}`);
				}
			},
			true,
			authData.get("companyCountry") !== "SE",
			authData.get("companyCountry"),
		);
	};

	const onCompanyAuthorised = (responseData) => {
		const urlAlias = responseData.getIn(["company", "urlAlias"]);
		onCloseModal();
		history.push(`/${urlAlias}`);
	};

	const resetState = () => {
		setState(INITIAL_STATE);
	};

	const getSteps = () => {
		const { authData } = state;

		return [
			{
				body: (
					<CompanyAccountWizard
						onAuthorize={goToAutorize}
						onSkipAuthorise={onSkipAuthorise}
						onVerifyToCustomer={onVerifyToCustomer}
					/>
				),
				footer: {
					component: (
						<FooterRightControls>
							<TransparentButton
								tid="generic.close"
								textColor="midGrey"
								onClick={onCloseModal}
							/>
						</FooterRightControls>
					),
				},
			},
			{
				body: (
					<InitAuthorizeCompany
						authData={authData}
						onCompanyAuthorised={onCompanyAuthorised}
						isShowing={state.step === 1}
						onCancel={goToFirstStep}
					/>
				),
				footer: {
					component: (
						<FooterRightControls>
							<TransparentButton
								tid="generic.form.cancel"
								textColor="midGrey"
								onClick={goToFirstStep}
							/>
						</FooterRightControls>
					),
				},
			},
		];
	};

	return (
		<>
			<StepsModal
				isOpen={isOpen}
				steps={getSteps()}
				step={state.step}
				hSize="lg"
				vSize={100}
				title="company.add_company_wizard.modal.title"
			/>
		</>
	);
};

const mapStoreToProps = (store) => {
	return {
		history: history,
		isOpen:
			store.modals.getIn(["activeModal", "name"]) ===
			COMPANY_ADD_NEW_COMPANY_MODAL,
		userObj: store.user.get("userObj"),
	};
};

const mapActionsToProps = {
	closeModal,
	createUnverifiedCompany,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(AddCompanyModalContainer);
