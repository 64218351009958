import React, { PureComponent } from "react";
import { string } from "prop-types";
import styled from "styled-components";
import Text from "../text/text";

const StyledLabelWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const StyledText = styled(Text)`
	margin: 0 ${(props) => props.theme.spacing[1]};
`;

export default class InlineLabel extends PureComponent {
	static propTypes = {
		tid: string,
	};

	render = () => {
		const { children, tid } = this.props;

		return (
			<StyledLabelWrapper>
				{children}
				<StyledText tid={tid} />
			</StyledLabelWrapper>
		);
	};
}
