import React, { Component } from "react";
import { connect } from "react-redux";

import Alert from "../../../../../dumb-components/onboarding/alert";
import Badge from "../../../../../dumb-components/shared/badge/badge-new";
import DismissControls from "../dismiss-controls.container";
import Text from "../../../../../dumb-components/shared/text/text";

import { getIsDismissed } from "../helpers";
const ALERT_ID = "0d894a01-4c4c-4c6b-8863-9b11a8ee3e40";

class NewEsigningInfoAlert extends Component {
	render = () => {
		const { isDismissed } = this.props;

		if (isDismissed) {
			return null;
		}

		return (
			<Alert
				badgeComponent={
					<Badge
						isStatic
						bgColor="dodgerBlue"
						textColor="white"
						tid="generic.new"
					/>
				}
				controlsComponent={<DismissControls alertId={ALERT_ID} />}
			>
				<Text
					color="white"
					tid="company.dashboard.panel.new_esigning.information"
				/>
			</Alert>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		isDismissed: getIsDismissed(store.user.get("userObj"), ALERT_ID),
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(NewEsigningInfoAlert);
