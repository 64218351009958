import React, { useCallback, useRef } from "react";
import DropdownMenuContainer from "../../../../containers/shared/dropdown-menu.container";
import DropdownIconItem from "../../../shared/dropdown-item/dropdown-icon-item";
import { array, bool, func } from "prop-types";
import history from "../../../../interfaces/history";
import { makeInternalLink } from "../../../../components/helpers/link.helper";
import DropdownItem from "../../dropdown-item/dropdown-item";

const GridPanelRightButtons = ({
	exportAsExcel,
	openGridModal,
	downloadExcelTid,
	displayFloatingFilter,
	floatingFilter,
	showTeamsFilter,
	displayTeamsFilterModal,
	hasTeamFilterValue,
	noFilter,
	chart,
	hamburgerMenuItems,
}) => {
	const downloadDropdownMenu = useRef(null);
	const expandDropdownMenu = useRef(null);
	const displayFloatingFilterDropdownMenu = useRef(null);

	const _exportAsExcel = () => {
		downloadDropdownMenu.current.onToggleMenu();
		exportAsExcel();
	};

	const _openGridModal = () => {
		expandDropdownMenu.current.onToggleMenu();
		openGridModal();
	};

	const _displayFloatingFilter = () => {
		displayFloatingFilterDropdownMenu.current.onToggleMenu();
		displayFloatingFilter();
	};

	const _displayTeamsFilterModal = () => {
		displayFloatingFilterDropdownMenu.current.onToggleMenu();
		displayTeamsFilterModal();
	};

	const floatingFilterDropdownMenuItemTid = useCallback(() => {
		if (floatingFilter) {
			return "ag_grid.panel.dropdown_menu.filter.item.hide_filter";
		} else {
			return "ag_grid.panel.dropdown_menu.filter.item.show_filter";
		}
	}, [floatingFilter]);

	return (
		<div style={{ position: "relative", /* left: 16, */ display: "flex" }}>
			{hamburgerMenuItems && (
				<DropdownMenuContainer
					btnIcon="faBars"
					halignMenu="right"
					btnMode="transparent-icon"
					transparentIconButtonSize="sml"
					noMaxWidth={true}
					scrollbars={true}
				>
					{hamburgerMenuItems
						.sort((a, b) => {
							a = Object.keys(a)[0].toLowerCase();
							b = Object.keys(b)[0].toLowerCase();
							if (a === b) {
								return 0;
							}
							return a < b ? -1 : 1;
						})
						.map((menuItem, i) => (
							<DropdownItem
								key={i}
								leftComponent={Object.keys(menuItem)}
								onClick={() => {
									history.push(makeInternalLink(Object.values(menuItem)));
								}}
							/>
						))}
				</DropdownMenuContainer>
			)}
			{!noFilter && (
				<DropdownMenuContainer
					btnIcon="faFilter"
					halignMenu="right"
					btnMode="transparent-icon"
					transparentIconButtonSize="sml"
					noMaxWidth={true}
					notificationBadge={hasTeamFilterValue}
					ref={displayFloatingFilterDropdownMenu}
				>
					<DropdownIconItem
						tid={floatingFilterDropdownMenuItemTid()}
						icon="faFilter"
						onClick={_displayFloatingFilter}
					/>
					{showTeamsFilter && (
						<DropdownIconItem
							tid="ag_grid.panel.dropdown_menu.filter.item.filter_by_team"
							icon="faUserFriends"
							onClick={_displayTeamsFilterModal}
							notificationBadgeProps={{
								active: hasTeamFilterValue,
							}}
						/>
					)}
				</DropdownMenuContainer>
			)}

			{exportAsExcel && (
				<DropdownMenuContainer
					btnIcon="faArrowAltToBottom"
					halignMenu="right"
					btnMode="transparent-icon"
					transparentIconButtonSize="sml"
					noMaxWidth={true}
					ref={downloadDropdownMenu}
				>
					<DropdownIconItem
						tid={downloadExcelTid}
						icon={chart ? "faFileChartLine" : "faFileSpreadsheet"}
						onClick={_exportAsExcel}
					/>
				</DropdownMenuContainer>
			)}

			{openGridModal && (
				<DropdownMenuContainer
					btnIcon="faExpandArrowsAlt"
					halignMenu="right"
					btnMode="transparent-icon"
					transparentIconButtonSize="sml"
					ref={expandDropdownMenu}
					noMaxWidth={true}
				>
					<DropdownIconItem
						tid="investments.grid.panel.dropdown.right_button.item.expand"
						icon="faExpandArrowsAlt"
						onClick={_openGridModal}
					/>
				</DropdownMenuContainer>
			)}
		</div>
	);
};

GridPanelRightButtons.propTypes = {
	exportAsExcel: func,
	openGridModal: func,
	displayFloatingFilter: func,
	floatingFilter: bool,
	showTeamsFilter: bool,
	displayTeamsFilterModal: func,
	hasTeamFilterValue: bool,
	hamburgerMenuItems: array,
};

export default GridPanelRightButtons;
