import React, { PureComponent } from "react";

import Text from "../../shared/text/text";

export default class EmptyListMessage extends PureComponent {
	render = () => {
		return (
			<div className="flex w-full flex-col gap-6 justify-start items-center p-6">
				<Text color="muted" tid="documents.list.no_documents_in_root_folder" />
			</div>
		);
	};
}
