import React from "react";
import { array, func } from "prop-types";
import { FormattedMessage } from "react-intl";
import { SYNA_COMPANY_ROLES } from "../../../constants/syna-company-roles";
import { maskSsn } from "../../helpers/users";
import styled from "styled-components";
import { Padding } from "styled-components-spacing/dist/cjs/Padding";

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const RepresentativesTable = ({
	companyRepresentatives = [],
	onClickAuthorise,
}) => {
	const renderTableHeader = () => {
		return (
			<thead>
				<tr>
					<th className="account-company">
						<FormattedMessage id="account.name" />
					</th>
					<th className="account-type">
						<FormattedMessage id="account.ssn" />
					</th>
					<th className="account-access">
						<FormattedMessage id="account.position" />
					</th>
				</tr>
			</thead>
		);
	};

	const renderTableRow = (rep, index) => {
		const { ssn, roles, name } = rep;

		const rolesNamed = roles
			.filter((role) => !!SYNA_COMPANY_ROLES[role])
			.map((role) => SYNA_COMPANY_ROLES[role])
			.join(", ");

		return (
			<tr key={index}>
				<td>{name}</td>
				<td>{maskSsn(ssn)}</td>
				<td>{rolesNamed}</td>
			</tr>
		);
	};

	const renderTableBody = () => {
		return <tbody>{companyRepresentatives.map(renderTableRow)}</tbody>;
	};

	const renderAuthoriseButton = () =>
		typeof onClickAuthorise === "function" ? (
			<ButtonContainer>
				<Padding vertical={4}>
					<button
						className="btn btn-primary"
						onClick={() => onClickAuthorise()}
					>
						<FormattedMessage id="sign_width_bankid" />
					</button>
				</Padding>
			</ButtonContainer>
		) : null;

	return (
		<>
			<table className="table table-striped">
				{renderTableHeader()}
				{renderTableBody()}
			</table>
			{renderAuthoriseButton()}
		</>
	);
};

RepresentativesTable.propTypes = {
	companyRepresentatives: array,
	onClickAuthorise: func.isRequired,
};

export default RepresentativesTable;
