import React, { useContext } from "react";
import { object } from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import history from "../../../interfaces/history";
import theme from "../../../../theme";
import { InvestmentsContext } from "../investment.container";
import Box from "@mui/material/Box";
import { Badge } from "../../../dumb-components/investment/peek/Badge";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";
import Alert from "../../../dumb-components/shared/alert/alert";
import Text from "../../../dumb-components/shared/text/text";
import AppealToCompanyModal from "../appeal-to-company-modal";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../actions/modals.actions";
import { APPEAL_TO_COMPANY_MODAL } from "../../../constants/modals";

const StyledTab = styled(Tab)((props) => ({
	"&:before": {
		background: theme.colors[props.highlightcolor],
	},
	"&.Mui-disabled": {
		opacity: ".5",
	},
}));

function LinkTab({ tooltip, ...props }) {
	const { setEmissionTabIndex } = useContext(InvestmentsContext);
	const navigate = (e) => {
		e.preventDefault();

		history.push(`${e.target.pathname}${e.target.search}`);
	};

	return (
		<>
			{typeof tooltip !== "undefined" ? (
				<Tooltip text={tooltip}>
					<StyledTab
						component="a"
						disableRipple={true}
						disabled={props.disabled}
						highlightcolor={props.highlightcolor}
						onClick={(e) => {
							// display emission list on tab click
							if (props.path === "emission") {
								setEmissionTabIndex(0);
							}
							navigate(e);
						}}
						{...props}
					/>
				</Tooltip>
			) : (
				<StyledTab
					component="a"
					disableRipple={true}
					disabled={props.disabled}
					highlightcolor={props.highlightcolor}
					onClick={(e) => {
						// display emission list on tab click
						if (props.path === "emission") {
							setEmissionTabIndex(0);
						}
						navigate(e);
					}}
					{...props}
				/>
			)}
		</>
	);
}

export default function InvestmentTabsContainer({ location }) {
	const {
		permissions,
		isShareholderPreview,
		isCompany,
		investedInCompanyInformation,
		investmentId,
	} = useContext(InvestmentsContext);
	const isGuest = permissions && permissions.get("isGuest");
	const isDealflow = permissions?.get?.("isDealflow");
	const isOverInvestmentSizeLimit = permissions?.get?.(
		"isOverInvestmentSizeLimit",
	);
	const isOverSubscribedInvestmentsLimit = permissions?.get?.(
		"isOverSubscribedInvestmentsLimit",
	);
	const isAppealModalOpen = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) === APPEAL_TO_COMPANY_MODAL,
	);
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const getParentBasePath = () => {
		const { pathname } = location;

		const pathArray = pathname.split("/");
		const lastItem = pathArray[pathArray.length - 1];
		const basePath = pathname.replace(`/${lastItem}`, "");

		return basePath;
	};

	const getTabs = () => {
		return [
			{
				path: "general",
				highlightColor: "solitudeDark",
				tid: "investments.tabs.general",
			},
			{
				path: "sharebook",
				highlightColor: "solitudeDark",
				tid: "investments.tabs.share_book",
				disabled: isGuest || isDealflow || isOverSubscribedInvestmentsLimit,
				notVisible: isOverInvestmentSizeLimit || isGuest || isDealflow,
				tooltip: isOverSubscribedInvestmentsLimit
					? t("investments.tabs.tooltip.no_access_due_to_shareholder_limit", {
							company: investedInCompanyInformation.get("name"),
					  })
					: undefined,
			},
			{
				path: "shareholders",
				highlightColor: "solitudeDark",
				tid: "investments.tabs.shareholders",
				disabled: isGuest || isDealflow || isOverSubscribedInvestmentsLimit,
				notVisible: isGuest || isDealflow,
				tooltip: isOverSubscribedInvestmentsLimit
					? t("investments.tabs.tooltip.no_access_due_to_shareholder_limit", {
							company: investedInCompanyInformation.get("name"),
					  })
					: undefined,
			},
			{
				path: "my-records",
				highlightColor: "solitudeDark",
				tid: "investments.tabs.my_records",
				disabled: isShareholderPreview || isCompany || isDealflow,
				notVisible:
					isOverInvestmentSizeLimit ||
					isShareholderPreview ||
					isCompany ||
					isDealflow,
			},
			{
				path: "documents",
				highlightColor: "solitudeDark",
				tid: "investments.tabs.documents",
				disabled: isGuest || isDealflow || isOverSubscribedInvestmentsLimit,
				notVisible: isGuest || isDealflow,
				tooltip: isOverSubscribedInvestmentsLimit
					? t("investments.tabs.tooltip.no_access_due_to_shareholder_limit", {
							company: investedInCompanyInformation.get("name"),
					  })
					: undefined,
			},
			{
				path: "emission",
				highlightColor: "solitudeDark",
				tid: "investments.tabs.emission",
				disabled: isGuest || isDealflow,
				notVisible: isGuest || isDealflow,
			},
			//{
			//	path: 'capital',
			//	highlightColor: 'solitudeDark',
			//	tid: 'investments.tabs.capital',
			//	disabled: isGuest || isDealflow,
			//	notVisible: isGuest || isDealflow,
			//},
			//{
			//	path: 'buy-sell',
			//	highlightColor: 'solitudeDark',
			//	tid: 'investments.tabs.buy_sell',
			//},
			//{
			//	path: 'reports',
			//	highlightColor: 'solitudeDark',
			//	tid: 'investments.tabs.reports',
			//	disabled: isGuest || isDealflow,
			//	notVisible: isGuest || isDealflow,
			//},
			//{
			//{
			//	path: 'simulation',
			//	highlightColor: 'solitudeDark',
			//	tid: 'investments.tabs.simulation',
			//	disabled: isGuest || isDealflow,
			//	notVisible: isGuest || isDealflow,
			//}
		];
	};

	const basePath = getParentBasePath();
	const tabs = getTabs();

	const getActivePath = () => {
		const { pathname } = location;

		const pathArray = pathname.split("/");
		const lastItem = pathArray[pathArray.length - 1];
		const visibleTabs = tabs.filter((tab) => !tab.disabled);
		const index = visibleTabs.findIndex((tab) => tab.path === lastItem);

		if (index === -1) {
			return 0;
		}

		return index;
	};

	const openAppealToCompanyModal = () => {
		dispatch(openModal(APPEAL_TO_COMPANY_MODAL));
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: 2, flex: 1 }}>
			{isOverSubscribedInvestmentsLimit && (
				<Box sx={{ flex: 1, marginBottom: 1 }}>
					<Alert mode="warning" icon>
						<Text
							tid="captable.alerts.features_disabled_due_to_shareholder_limit"
							values={{ companyName: investedInCompanyInformation.get("name") }}
							color="white"
						/>
						<br />
						<span
							style={{
								color: "white",
								cursor: "pointer",
								textDecoration: "underline",
								fontWeight: "bold",
							}}
							onClick={openAppealToCompanyModal}
						>
							{t("captable.alerts.click_here")}
						</span>{" "}
						<Text
							tid="captable.alerts.contact_company_owner"
							values={{ companyName: investedInCompanyInformation.get("name") }}
							color="white"
						/>
					</Alert>
					{isAppealModalOpen && (
						<AppealToCompanyModal
							investmentId={investmentId}
							companyId={investedInCompanyInformation.get("id")}
							companyName={investedInCompanyInformation.get("name")}
						/>
					)}
				</Box>
			)}
			<Box sx={{ flex: 1 }}>
				<Tabs
					scrollButtons="auto"
					variant="scrollable"
					value={getActivePath()}
					selectionFollowsFocus={false}
					TabIndicatorProps={{ style: { display: "none" } }}
				>
					{tabs &&
						tabs.map((tab, i) => {
							const {
								tid,
								path,
								disabled,
								notVisible,
								highlightColor,
								tooltip,
							} = tab;

							if (notVisible) {
								return null;
							}

							return (
								<LinkTab
									key={i}
									label={t(tid)}
									disabled={disabled}
									highlightcolor={highlightColor}
									href={`${basePath}/${path}${
										location.search ? location.search : ""
									}`}
									path={path}
									tooltip={tooltip}
								/>
							);
						})}
				</Tabs>
			</Box>
			{isShareholderPreview && (
				<Box>
					<Badge />
				</Box>
			)}
		</Box>
	);
}

InvestmentTabsContainer.propTypes = {
	location: object,
};
