import { fromJS, Map } from "immutable";

export const AVAIBLE_TASK_STATUS = fromJS([
	{
		value: "to_do",
		label: "tasks.details.status.to_do",
		statusColor: "solitudeDark",
	},
	{
		value: "in_progress",
		label: "tasks.details.status.in_progress",
		statusColor: "java",
	},
	{
		value: "on_hold",
		label: "tasks.details.status.on_hold",
		statusColor: "yellowMid",
	},
	{
		value: "not_required",
		label: "tasks.details.status.not_required",
		statusColor: "catalinaBlue",
	},
	{ value: "done", label: "tasks.details.status.done", statusColor: "green" },
]);

const tasksStatuses = AVAIBLE_TASK_STATUS.unshift(
	Map({
		value: "show_all",
		label: "tasks.details.status.show_all",
	}),
);

export const FILTER_FIELDS = fromJS([
	[
		{
			size: 1 / 2,
			fieldName: "dateProp",
			labelTid: "tasks.filter.label.dateprop",
			options: [
				{ value: "orderIndex", label: "tasks.dateprop.orderIndex" },
				{ value: "createdAt", label: "tasks.dateprop.createdat" },
				{ value: "dueAt", label: "tasks.dateprop.dueat" },
			],
			pinable: true,
			renderer: "select",
			placeholder: "select_placeholder",
		},
		{
			size: 1 / 2,
			fieldName: "date",
			minDateLabelTid: "object_filter.label.from_date",
			maxDateLabelTid: "object_filter.label.to_date",
			yearLabelTid: "object_filter.label.by_year",
			showYearSelect: false,
			isClearable: true,
			renderer: "dateRange",
		},
	],
	[
		{
			size: 1 / 2,
			fieldName: "status",
			labelTid: "generic.status",
			options: tasksStatuses,
			pinable: true,
			renderer: "select",
			placeholder: "select_placeholder",
		},
		{
			size: 1 / 2,
			fieldName: "order",
			labelTid: "object_filter.label.order",
			options: [
				{ value: "title", label: "tasks.filter.order.alphabetical" },
				{ value: "datePropAsc", label: "tasks.filter.order.date_asc" },
				{ value: "datePropDesc", label: "tasks.filter.order.date_desc" },
				{ value: "orderIndex", label: "tasks.filter.order.index" },
			],
			isClearable: false,
			pinable: true,
			renderer: "select",
			placeholder: "select_placeholder",
		},
	],
	[
		{
			size: 1,
			fieldName: "createdBy",
			labelTid: "tasks.filter.label.createdBy",
			isClearable: true,
			placeholder: "select_placeholder",
		},
	],
	[
		{
			size: 1,
			fieldName: "assigne",
			labelTid: "tasks.filter.label.assignee",
			includeNoneOption: true,
			noneOptionValue: "none",
			noneOptionLabelTid: "tasks.filter.unassigned",
			placeholder: "select_placeholder",
			pinable: true,
		},
	],
	[
		{
			size: 1,
			fieldName: "showOnlyTrashed",
			tid: "filter.label.show_only_trashed",
			renderer: "checkbox",
		},
	],
]);

export const FACTORY_DEFAULT_FILTER_VALUES = fromJS({
	dateProp: ["orderIndex"],
	order: ["orderIndex"],
	status: ["show_all"],
	assigne: ["show_all"],
	showOnlyTrashed: [false],
});
