import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Text from "../../text/text";
import { useTranslation } from "react-i18next";

const TransposedTitleColumnTooltipRenderer = (props) => {
	const { t } = useTranslation();
	const companyName = props.data.companyName;
	const tooltipTid = props.data.transposedTitleRowTooltipTid;

	return (
		<Box
			className="custom-tooltip"
			sx={{ display: "flex", flexDirection: "column", maxWidth: "300px" }}
		>
			{tooltipTid && (
				<Typography
					ml={2}
					mt={2}
					mr={2}
					mb={2}
					variant="body2"
					color="text.primary"
				>
					{companyName ? (
						<Text tid={tooltipTid} values={{ companyName: companyName }} />
					) : (
						t(tooltipTid)
					)}
				</Typography>
			)}
		</Box>
	);
};

export default TransposedTitleColumnTooltipRenderer;
