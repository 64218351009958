import React from "react";
import MenuItemIcon from "../../../../../mui-components/dropdown/menu/menu-item-icon";
import { usePeopleModalContext } from "../../people-modal.context";
import { TAB_MY_PERSONAL_CONTACTS } from "../../../select-user-modal/select-user-modal.constants";

export const ColumnViewMenuItem = () => {
	const { setShowGridInExtendedView, showGridInExtendedView, activeTab } =
		usePeopleModalContext();
	const isViewingMyPersonalContacts = activeTab === TAB_MY_PERSONAL_CONTACTS;

	return (
		<>
			<MenuItemIcon
				icon="faTh"
				listItemTid="people_modal.view_menu.view_all_columns"
				onClick={() => setShowGridInExtendedView(true)}
				disabled={isViewingMyPersonalContacts || showGridInExtendedView}
			/>
			<MenuItemIcon
				icon="faThLarge"
				listItemTid="people_modal.view_menu.column_default_view"
				onClick={() => setShowGridInExtendedView(false)}
				disabled={isViewingMyPersonalContacts || !showGridInExtendedView}
			/>
		</>
	);
};
