import React, { PureComponent } from "react";
import { func, bool } from "prop-types";

import TabsPanel from "../../shared/tabs-panel/tabs-panel";

import {
	OBJ_TYPE_MEETING_AGENDA,
	OBJ_TYPE_MEETING_ATTACHMENT,
	OBJ_TYPE_MEETING_PROTOCOL,
} from "/shared/constants";

export default class MeetingStandardAttachments extends PureComponent {
	static propTypes = {
		isChecked: func,
		renderControls: func,
		renderBody: func,
		preventChangeOfTab: bool,
		protocolTabIsActiveFromStore: bool,
		hasAgenda: bool,
		hasAttachments: bool,
		hasProtocol: bool,
	};

	render = () => {
		const {
			renderBody,
			renderControls,
			preventChangeOfTab,
			protocolTabIsActiveFromStore,
			hasAgenda,
			hasAttachments,
			hasProtocol,
		} = this.props;

		const tabs = [
			{
				tab: {
					tid: "meetings.standard.attachments.tabs.agenda",
					checked: hasAgenda,
				},
				body: renderBody(
					OBJ_TYPE_MEETING_AGENDA,
					renderControls,
					"faClipboardList",
				),
			},
			{
				tab: {
					tid: "meetings.standard.attachments.tabs.attachments",
					checked: hasAttachments,
				},
				body: renderBody(
					OBJ_TYPE_MEETING_ATTACHMENT,
					renderControls,
					"faPaperclip",
				),
			},
			{
				tab: {
					tid: "meetings.standard.attachments.tabs.protocol",
					checked: hasProtocol,
					active: protocolTabIsActiveFromStore,
					initialSelect: !preventChangeOfTab && hasProtocol,
				},
				body: renderBody(
					OBJ_TYPE_MEETING_PROTOCOL,
					renderControls,
					"faFileAlt",
				),
			},
		];

		return <TabsPanel tabs={tabs} />;
	};
}
