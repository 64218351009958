import React, { Component } from "react";
import { connect } from "react-redux";
import immutableProps from "react-immutable-proptypes";
import { string, bool, func } from "prop-types";
import {
	ObjectFilterForm,
	ObjectFilterPanel,
} from "../../dumb-components/shared/object-filter";

class ObjectFilterContainer extends Component {
	static propTypes = {
		id: string.isRequired, // ie: documents, tasks etc. Need this to create a unique ID together with the fieldname for the Pin component
		fields: immutableProps.list,
		values: immutableProps.list,
		defaultValues: immutableProps.map,
		title: string,
		onChange: func,
		onClear: func,
		onClearAll: func,
		onClose: func,
		onSetDefaultValue: func,
		onResetDefaultValues: func,
		isOpen: bool,
	};

	renderObjectFilterForm = () => {
		const {
			fields,
			values,
			defaultValues,
			i18n,
			onChange,
			onClear,
			onSetDefaultValue,
		} = this.props;

		return (
			<ObjectFilterForm
				fields={fields}
				values={values}
				defaultValues={defaultValues}
				onChange={onChange}
				onClear={onClear}
				onSetDefaultValue={onSetDefaultValue}
				language={i18n.language}
			/>
		);
	};

	render = () => {
		const { title, isOpen, onClose, onClearAll, onResetDefaultValues } =
			this.props;

		return (
			<ObjectFilterPanel
				title={title}
				renderObjectFilterFormComponent={this.renderObjectFilterForm}
				isOpen={isOpen}
				onClose={onClose}
				onClearAll={onClearAll}
				onResetDefaultValues={onResetDefaultValues}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		i18n: store.i18n,
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ObjectFilterContainer);
