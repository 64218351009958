import React, { PureComponent } from "react";
import { bool, func, node } from "prop-types";
import { map } from "react-immutable-proptypes";
import styled from "styled-components";

import Modal from "../../shared/modal/modal";
import Label from "../../shared/label/label";
import Input from "../../shared/input/input";
import TextArea from "../../shared/input/textarea";
import ColoredContentWrapper from "../../shared/colored-content-wrapper/colored-content-wrapper";

const StyledTeamEditWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const StyledInputWrapper = styled.div`
	margin-bottom: 16px;
`;

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

export default class TeamEditModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		onChange: func,
		team: map,
		hasError: map,
		isLoading: bool,
		renderFooter: node,
	};

	renderContent = () => {
		const { onChange, team, hasError, isLoading } = this.props;

		return (
			<StyledTeamEditWrapper>
				<StyledColoredContentWrapper type="secondary">
					<Label tid="generic.name">
						<StyledInputWrapper>
							<Input
								fieldName="name"
								placeholderTid="user_management.team.add_team.name.placeholder"
								onChange={onChange}
								hasError={hasError.get("name")}
								value={team.get("name", "")}
								disabled={isLoading}
							/>
						</StyledInputWrapper>
					</Label>
					<Label tid="generic.description">
						<TextArea
							fieldName="description"
							placeholderTid="generic.description"
							onChange={onChange}
							value={team.get("description", "")}
							minRows={7}
							disabled={isLoading}
						/>
					</Label>
				</StyledColoredContentWrapper>
			</StyledTeamEditWrapper>
		);
	};

	render = () => {
		const { isOpen, renderFooter } = this.props;

		return (
			<Modal
				isOpen={isOpen}
				title="company.management.edit_role"
				footerComponent={renderFooter}
				hSize="md"
				vSize={75}
			>
				{this.renderContent()}
			</Modal>
		);
	};
}
