import React, { Component } from "react";
import { connect } from "react-redux";
import { string, func } from "prop-types";

import DropdownIconItem from "../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import Tooltip from "../../../../../dumb-components/shared/tooltip/tooltip";

import { openModal } from "../../../../../actions/modals.actions";
import { MEETINGS_PROXY_MANAGE_DOCUMENT } from "../../../../../constants/modals";
import { MEETING_SUBTYPE_AGM } from "/shared/constants";
import {
	findProxyByAttendeeId,
	getProxyDocumentsDisplayData,
} from "../../../../../components/helpers/meeting.helper";

class DocumentsContainer extends Component {
	static propTypes = {
		userId: string,
		onClickOverride: func,
	};

	onClick = () => {
		const { openModal, userId } = this.props;
		openModal(MEETINGS_PROXY_MANAGE_DOCUMENT, { attendeeId: userId });
	};

	getActiveState = (badgeColor) => {
		if (badgeColor === "lightGrey") {
			return "default";
		} else if (badgeColor === "green") {
			return "accepted";
		} else if (badgeColor === "persianRed") {
			return "declined";
		} else {
			return "underReview";
		}
	};

	render = () => {
		const { userId, isAGM, proxies, onClickOverride } = this.props;
		const proxy = findProxyByAttendeeId(proxies, userId);
		const {
			badgeColor,
			showDropdownItem,
			dropdownItemDisabled,
			TOOLTIP_STATES,
			activeState,
		} = getProxyDocumentsDisplayData({
			proxy,
			userId,
			isAGM,
		});

		if (!showDropdownItem) {
			return null;
		}

		return (
			<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
				<DropdownIconItem
					icon="faPaperclip"
					tid="meetings.agm.attendees_list_item.dropdown.documents"
					onClick={onClickOverride ? onClickOverride : this.onClick}
					notificationBadgeColor={badgeColor}
					disabled={dropdownItemDisabled}
				/>
			</Tooltip>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		proxies: store.meetings.getIn(["meeting", "proxies"]),
		isAGM:
			store.meetings.getIn(["meeting", "meetingSubType"]) ===
			MEETING_SUBTYPE_AGM,
		attendees: store.meetings.getIn(["meeting", "attendees"]),
	};
};

const mapActionsToProps = {
	openModal,
};

export default connect(mapStoreToProps, mapActionsToProps)(DocumentsContainer);
