import React from "react";
import { Button } from "@/components/ui/button";
import { oneOf } from "prop-types";
import { Trans } from "react-i18next";

const BottomComponent = ({ section, onClick }) => {
	let buttonTid = "credentials_register.account";

	if (section === "SIGNUP") {
		buttonTid = "credentials_sign_in.account";
	}

	return (
		<div className="flex font-semibold items-center justify-center py-10">
			<Trans
				i18nKey={buttonTid}
				components={[
					<Button
						className="!px-2"
						key="button"
						variant="link"
						onClick={onClick}
					/>,
				]}
			/>
		</div>
	);
};

BottomComponent.propTypes = {
	section: oneOf(["SIGNIN", "SIGNUP", "FORGOTPW"]),
};

BottomComponent.defaultProps = {
	section: "SIGNIN",
};

export default BottomComponent;
