import React from "react";
import Panel from "../../../../dumb-components/panel";
import Field from "../../../../dumb-components/fields/field";
import Input from "../../../../dumb-components/fields/input";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import clsx from "clsx";
import BankIdModal from "../../../../dumb-components/shared/bankid-modal/bankid-modal";
import useBankId from "../../../../hooks/useBankId";
import api from "../../../../modules/request.module.v2";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../../../actions/notify.actions";
import { updateUser } from "../../../../actions/user.actions";
import i18n from "../../../../i18n";
import { fromJS } from "immutable";

function InformationField({
	user,
	onChange,
	addErrorNotification,
	addInfoNotification,
	updateUser,
}) {
	const { bankIdInfo, initBankIdAuth, cancelBankId } = useBankId({
		onBankIdAuthComplete: (data) => {
			api
				.post("/users/bankid/change-ssn/complete", { orderRef: data.orderRef })
				.then((response) => {
					updateUser(fromJS(response.data.user));
					addInfoNotification({
						tid: "user.profile.information.ssn.updated_succesfully",
					});
				})
				.catch((err) => {
					addErrorNotification({ tid: err.response?.data?.translationId });
				});
		},
	});

	if (!user) {
		return null;
	}

	const verifySsn = () => {
		initBankIdAuth(api.post(`/users/bankid/auth`));
	};

	return (
		<>
			<Panel tid="user.profile.information">
				<Field tid="generic.name">
					<Input
						name="name"
						value={user.get("name")}
						onChange={(val) => {
							onChange(["name"], val);
						}}
					/>
				</Field>
				<Field tid="generic.ssn">
					<div className={clsx({ "input-group": !user.get("ssn") })}>
						<Input
							name="ssn"
							value={
								user.get("ssn") ?? i18n.t("user.profile.ssn_verify.description")
							}
							disabled
						/>
						{user.get("ssn") ? (
							<span
								className="form-control-feedback text--success"
								style={{ top: "24px", width: "120px" }}
							>
								<FormattedMessage id="user.profile.information.ssn.verified" />{" "}
								<i className="fa fa-check"></i>
							</span>
						) : null}
						{!user.get("ssn") && (
							<div className="input-group-btn">
								<button
									className="btn btn-primary"
									type="button"
									onClick={verifySsn}
								>
									<FormattedMessage id="user.profile.information.ssn.btn.verify" />
								</button>
							</div>
						)}
					</div>
				</Field>
				<Field tid="generic.telephone">
					<Input
						name="phone"
						value={user.get("phone")}
						onChange={(val) => {
							onChange(["phone"], val);
						}}
					/>
				</Field>
			</Panel>
			<BankIdModal
				isOpen={!!bankIdInfo.status && bankIdInfo.status !== "complete"}
				bankIdInfo={bankIdInfo}
				onCancel={cancelBankId}
			/>
		</>
	);
}

const mapDispatchToProps = {
	addErrorNotification,
	addInfoNotification,
	updateUser,
};

export default connect(undefined, mapDispatchToProps)(InformationField);
