import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { oneOfType, bool, func } from "prop-types";
import { map } from "react-immutable-proptypes";
import {
	companyChanged,
	fetchUserCompanies,
} from "../../../actions/company.actions";
import StepsBankID from "../../../dumb-components/shared/bankid/steps-bankid";
import useBankId from "../../../hooks/useBankId";
import api from "../../../modules/request.module.v2";
import { fetchMenu } from "../../../actions/menu.actions";
import { fromJS } from "immutable";
import { addErrorNotification } from "../../../actions/notify.actions";
import Icon from "../../../dumb-components/shared/icon/icon";
import styled from "styled-components";
import Text from "../../../dumb-components/shared/text/text";

const Wrapper = styled.div`
	position: relative;
	height: 100%;
`;

const CreatingCompanyLoader = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 100;
	gap: 20px;
`;

const InitAuthorizeCompanyContainer = ({
	authData,
	onCompanyAuthorised,
	companyChanged,
	fetchUserCompanies,
	addErrorNotification,
	userEmail: email,
	userName,
	userPhone: phone,
	fetchMenu,
	isShowing,
	onCancel,
}) => {
	const [isCreatingCompany, setIsCreatingCompany] = useState(false);

	const orgNumber = authData?.get("companyOrgNumber");
	const name = authData?.get("companyName");
	const ssn = authData?.get("toBeAuthorizedBy");

	const { initBankIdAuth, bankIdInfo, resetBankId } = useBankId({
		onBankIdAuthComplete: async ({ orderRef }) => {
			setIsCreatingCompany(true);
			try {
				const completionResponse = await api.post(
					"/companies/active/complete",
					{
						orderRef,
						orgNumber,
						email,
						userName,
						phone,
					},
				);

				companyChanged(completionResponse.data.company);
				fetchUserCompanies("me");
				fetchMenu("main");
				onCompanyAuthorised?.(fromJS(completionResponse.data));
			} catch (error) {
				console.error(error.response.data);
				error.response?.data?.translationId &&
					addErrorNotification({ tid: error.response.data.translationId });
				onCancel?.();
			} finally {
				resetBankId();
				setIsCreatingCompany(false);
			}
		},
	});

	useEffect(() => {
		if (!isShowing) resetBankId();
	}, [isShowing]);

	useEffect(() => {
		authData &&
			initBankIdAuth(
				api.post("/companies/active/init", {
					orgNumber,
					ssn,
					email,
					userName,
					name,
				}),
			);
	}, [authData]);

	return (
		<Wrapper>
			{isCreatingCompany && (
				<CreatingCompanyLoader>
					<Icon icon="faSpinner" size="xl" spin />
					<Text tid="generic.creating_company_please_wait" />
				</CreatingCompanyLoader>
			)}
			<StepsBankID bankIdInfo={bankIdInfo} />
		</Wrapper>
	);
};

InitAuthorizeCompanyContainer.propTypes = {
	authData: oneOfType([map, bool]),
	onCompanyAuthorised: func,
	onCancel: func,
};

InitAuthorizeCompanyContainer.defaultProps = {
	authData: false,
};

const mapStateToProps = (store) => {
	return {
		userEmail: store.user.getIn(["userObj", "email"]),
		userName: store.user.getIn(["userObj", "name"]),
		userPhone: store.user.getIn(["userObj", "phone"]),
	};
};

const mapDispatchToProps = {
	companyChanged,
	fetchUserCompanies,
	fetchMenu,
	addErrorNotification,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(InitAuthorizeCompanyContainer);
