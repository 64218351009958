import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

class ShareTypeOptionRenderer extends Component {
	render = () => {
		const { onSelect, option, className } = this.props;
		const arr = this.props.option.label.split("[split-here]");
		const name = arr[0];
		const left = arr[1];

		return (
			<span onClick={onSelect.bind(this, option)} className={className}>
				{name}
				<span className="text--muted">
					{" "}
					<FormattedMessage values={{ num: left }} id="N_left" />
				</span>
			</span>
		);
	};
}
export default ShareTypeOptionRenderer;
