import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-final-form";
import MUIDialog from "../../mui-components/dialog/mui-dialog";
import { useTranslation } from "react-i18next";
import {
	FormGroup,
	FormGroupContainer,
} from "../../mui-components/form-block/FromGroup";
import arrayMutators from "final-form-arrays";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { closeModal } from "../../actions/modals.actions";
import { MODELS_RECOMMEND_INVONO } from "../../constants/modals";
import { validateEmail } from "../../modules/validation.module";
import {
	recommendInvonoToCompany,
	recommendInvonoToCompanyByDealflow,
} from "../../actions/user.actions";
import { FormattedMessage } from "react-intl";
import { EmailFields } from "./EmailFields";

const RECOMMEND_INVONO_FORM_TOOLTIP_STATES = {
	noEmails: {
		title: "recommend_invono.modal.tooltip.no_emails",
		enterDelay: "instant",
	},
	invalid: {
		title: "recommend_invono.modal.tooltip.form_invalid",
		enterDelay: "instant",
	},
	formNotDirty: {
		title: "recommend_invono.modal.tooltip.form_not_updated",
		enterDelay: "instant",
	},
};

function RecommendModal() {
	const open = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) === MODELS_RECOMMEND_INVONO,
	);
	const recommendations = useSelector((state) =>
		state.user.getIn(["userObj", "recommends"]),
	);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const initialValues = {
		emails: [""],
	};

	const onClose = () => {
		dispatch(closeModal());
	};

	const validate = (values) => {
		let errors = {};

		values.emails.forEach((email, index) => {
			if (email && email.length > 0 && !validateEmail(email)) {
				if (!errors.emails) {
					errors.emails = [];
				}

				errors.emails[index] = "E-postadressen är inte giltig.";
			}
		});

		return errors;
	};

	const hasMinOneEmailAddress = (emails) => {
		emails = emails?.filter(
			(value) => typeof value === "string" && validateEmail(value),
		);

		return emails.length >= 1;
	};

	const onSubmit = ({ emails }) => {
		emails = emails?.filter(
			(value) => typeof value === "string" && validateEmail(value),
		);

		if (emails.length === 0) {
			return;
		}

		if (window.location.href.includes("/dealflow/")) {
			dispatch(recommendInvonoToCompanyByDealflow(emails));
		} else {
			dispatch(recommendInvonoToCompany(emails));
		}

		dispatch(closeModal());
	};

	const getTooltipState = ({ emails }, invalid, dirty) => {
		const noEmails = !hasMinOneEmailAddress(emails);

		if (!dirty) {
			return "formNotDirty";
		}

		if (noEmails) {
			return "noEmails";
		}

		if (invalid) {
			return "invalid";
		}
	};

	if (!open) {
		return null;
	}

	return (
		<Form
			onSubmit={onSubmit}
			mutators={{ ...arrayMutators }}
			initialValues={initialValues}
			validate={validate}
			keepDirtyOnReinitialize
		>
			{(formProps) => {
				const {
					handleSubmit,
					form: {
						mutators: { push, remove },
					},
					values,
					invalid,
					dirty,
				} = formProps;
				const noEmails = !hasMinOneEmailAddress(values.emails);
				return (
					<MUIDialog
						isOpen={true}
						title={t("recommend_invono.modal.title")}
						onClose={onClose}
						onConfirm={handleSubmit}
						disabledConfirm={noEmails || invalid}
						confirmButton
						confirmButtonTid="generic.send"
						maxWidth="sm"
						tooltipStates={RECOMMEND_INVONO_FORM_TOOLTIP_STATES}
						tooltipActiveState={getTooltipState(values, invalid, dirty)}
						fullWidth
					>
						<FormGroupContainer>
							<FormGroup>
								<EmailFields
									push={push}
									remove={remove}
									name="emails"
									label="recommend_invono.modal.field.label"
								/>
								<Typography variant="body">
									<FormattedMessage
										id={"recommend_invono.modal.instructions"}
										values={{
											send: (
												<Typography
													variant={"body1"}
													sx={{ display: "contents" }}
												>
													{t("recommend_invono.modal.instructions.values.send")}
												</Typography>
											),
										}}
									/>
								</Typography>
							</FormGroup>
							{recommendations && (
								<FormGroup>
									<Box sx={{ display: "flex", flexDirection: "column" }}>
										<Typography component="p" variant="body1" gutterBottom>
											{t("recommend_invono.modal.prev_recommendations.title")}
										</Typography>
										{recommendations.map((recommendation, index) => (
											<Typography key={index} variant="body2" component="span">
												{recommendation}
											</Typography>
										))}
									</Box>
								</FormGroup>
							)}
						</FormGroupContainer>
					</MUIDialog>
				);
			}}
		</Form>
	);
}

export { RecommendModal };
