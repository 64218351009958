import { css } from "styled-components";
import theme from "../../../../theme";

const commonInputCss = css`
	color: ${(props) => props.theme.colors.midGrey};
	font-size: ${(props) => props.theme.fontSizes.default};
	font-family: ${(props) => props.theme.fontFamilies.openSans};
	padding: 8px 12px;
	width: 100%;
	border-radius: 1px;

	${(props) =>
		props.maxWidth &&
		css`
			max-width: ${(props) => props.maxWidth};
		`}
`;

export const StyledInputTemplate = css`
	${commonInputCss};
	background-color: ${(props) => props.theme.colors.white};
	border: 1px solid
		${(props) =>
			props.hasError || props.haserror ? props.theme.colors.red : "#c3c3c3"};
	box-shadow: none;
	transition:
		border-color ease-in-out 0.5s,
		box-shadow ease-in-out 0.5s;
	transition-duration: 0.5s;
	outline: none;

	:disabled {
		background-color: ${(props) =>
			props.legacyMode ? "#EEEEEE" : props.theme.colors.disabled};
	}

	::placeholder {
		color: ${theme.colors.placeholder};
	}

	:focus {
		outline-color: hsl(var(--ring));
		outline-style: solid;
		outline-width: 2px;
		outline-offset: 2px;
	}

	::selection {
		background-color: #42a5f5;
		color: ${(props) => props.theme.colors.white};
	}

	${(props) =>
		props.inputStyle === "v2" &&
		css`
			border-radius: ${(props) => props.theme.borderRadius.default};
			border-color: ${(props) =>
				props.hasError ? props.theme.colors.red : "transparent"};

			:disabled {
				background-color: transparent;
				border-color: ${(props) => props.theme.colors.white};
			}

			::placeholder {
				color: ${(props) => props.theme.colors.white};
			}
		`}
`;

export const StyledTransparentInputTemplate = css`
	${commonInputCss};
	background-color: transparent;
	border: none;
	outline: none;
	padding: 0;

	::placeholder {
		color: ${theme.colors.placeholder};
	}
`;
