import React, { Component } from "react";
import { connect } from "react-redux";
import MeetingsTasksListContainer from "../tasks/meetings-tasks-list.container";

class ProtocolTasksViewContainer extends Component {
	getComponentBasePath = () => {
		const {
			location: { pathname },
		} = this.props;
		const path = pathname;
		const pathArray = path.split("tasks");
		return pathArray[0] + "tasks";
	};

	render = () => {
		const { location } = this.props;
		const basePath = this.getComponentBasePath();

		return (
			<MeetingsTasksListContainer
				basePath={basePath}
				location={location}
				viewMode="protocol"
			/>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ProtocolTasksViewContainer);
