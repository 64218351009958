import React, { useState } from "react";
import { AddPersonModal } from "../../add-person-modal/add-person-modal";
import { useDispatch } from "react-redux";
import Button from "../../../../mui-components/button/button";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { addContactToAddressBook } from "../../../../actions/user.actions";
import { v4 } from "uuid";

function AddContactButton() {
	const { t } = useTranslation();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const dispatch = useDispatch();

	const onSave = (person) => {
		person.id = v4();
		dispatch(
			addContactToAddressBook(person, () => {
				setModalIsOpen(false);
			}),
		);
	};

	return (
		<Box>
			<Button variant="primary" onClick={() => setModalIsOpen(true)}>
				{t("people_modal.company_contacts.button.new_contact")}
			</Button>
			<AddPersonModal
				isOpen={modalIsOpen}
				onSave={onSave}
				onCancel={() => setModalIsOpen(false)}
			/>
		</Box>
	);
}

export { AddContactButton };
