import React, { PureComponent } from "react";
import { string, bool, func, oneOf, number } from "prop-types";
import styled, { css } from "styled-components";
import ReactTextArea from "react-autosize-textarea";
import Icon from "../icon/icon";
import { StyledInputTemplate } from "../input/input.template";
import { withTranslation } from "react-i18next";

const StyledReactTextArea = styled(ReactTextArea)`
	resize: none;

	${(props) =>
		props.istransparent === "false" &&
		css`
			${StyledInputTemplate};
		`}

	${(props) =>
		props.istransparent === "true" &&
		css`
			border: ${(props) =>
				props.error === "true"
					? `1px solid ${props.theme.colors.red}`
					: "none"};
			background-color: transparent;
			color: ${(props) => props.theme.colors.midGrey};
			font-size: ${(props) =>
				props.fontSize === "large" ? "16px" : props.theme.fontSizes.default};
			width: 100%;
			padding: ${(props) => props.theme.spacing[2]};

			:focus {
				outline: none;
			}

			::placeholder {
				color: ${(props) => props.theme.colors.placeholder};
			}
		`}
`;

const StyledChevronWrapper = styled.div`
	display: flex;

	> span[class*="fa-"] {
		margin-top: ${(props) => (props.fontSize === "large" ? "7px" : "6px")};
		margin-right: ${(props) => props.theme.spacing[2]};
	}
`;

class TextArea extends PureComponent {
	static defaultProps = {
		ignoreEnter: true,
		error: false,
		fontSize: "normal",
		isTransparent: false,
		hasChevronRight: false,
		disabled: false,
		autofocus: false,
	};

	static propTypes = {
		ignoreEnter: bool,
		placeholderTid: string,
		onChange: func,
		fieldName: string,
		error: bool,
		value: string,
		isTransparent: bool,
		fontSize: oneOf(["normal", "large"]),
		onArrowUp: func,
		onArrowDown: func,
		onEsc: func,
		onEnter: func,
		onFocus: func,
		onBlur: func,
		editorref: func,
		hasChevronRight: bool,
		maxRows: number,
		minRows: number,
		disabled: bool,
		autofocus: bool,
	};

	onKeyDown = (e) => {
		const { value, ignoreEnter, onEnter, onArrowUp, onArrowDown, onEsc } =
			this.props;

		if (ignoreEnter && e.keyCode === 13) {
			e.preventDefault();
			onEnter && onEnter(value);
			return;
		}

		if (onArrowUp && e.keyCode === 38) {
			e.preventDefault();
			onArrowUp(-1);
		}

		if (onArrowDown && e.keyCode === 40) {
			e.preventDefault();
			onArrowDown(1);
		}

		if (onEsc && e.keyCode === 27) {
			e.preventDefault();
			onEsc();
		}
	};

	onChange = (e) => {
		const { fieldName, onChange } = this.props;

		if (fieldName) {
			onChange(fieldName, e.target.value);
			return;
		}

		onChange(e.target.value);
	};

	renderTextArea = () => {
		const {
			disabled,
			placeholderTid,
			value,
			fontSize,
			editorref,
			minRows,
			maxRows,
			onFocus,
			onBlur,
			autofocus,
			t,
		} = this.props;
		let { error, isTransparent } = this.props;
		const placeholder = placeholderTid ? t(placeholderTid) : undefined;

		// Fix console warnings HTML elements not accepting boolean attributes
		isTransparent = isTransparent ? "true" : "false";
		error = error ? "true" : "false";

		return (
			<StyledReactTextArea
				istransparent={isTransparent}
				error={error}
				placeholder={placeholder}
				onKeyDown={this.onKeyDown}
				onChange={this.onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				value={value ? value : ""}
				fontSize={fontSize}
				ref={editorref}
				maxRows={maxRows}
				rows={minRows}
				disabled={disabled}
				autoFocus={autofocus}
			/>
		);
	};

	render = () => {
		const { hasChevronRight, fontSize } = this.props;

		if (hasChevronRight) {
			return (
				<StyledChevronWrapper fontSize={fontSize}>
					<Icon
						color="placeholder"
						type="solid"
						icon="faChevronDoubleRight"
						size="xs"
					/>
					{this.renderTextArea()}
				</StyledChevronWrapper>
			);
		}

		return this.renderTextArea();
	};
}

export default withTranslation()(TextArea);
