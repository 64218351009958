import React, { PureComponent } from "react";
import { list } from "react-immutable-proptypes";
import { List } from "immutable";
import { string, oneOfType, func } from "prop-types";
import styled from "styled-components";
import Text from "../text/text";

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledText = styled(Text)`
	margin-top: ${(props) => props.theme.spacing[2]};
`;

export default class FieldError extends PureComponent {
	static propTypes = {
		hasError: oneOfType([list, string]),
		onSetRef: func,
	};

	static defaultProps = {};

	getErrorTid = () => {
		const { hasError } = this.props;
		if (List.isList(hasError) && hasError.size > 0) {
			return hasError.first();
		}
		if (hasError) {
			return hasError;
		}
	};

	render = () => {
		const { children, onSetRef } = this.props;
		const errorTid = this.getErrorTid();

		return (
			<StyledWrapper ref={onSetRef}>
				{children}
				{errorTid && <StyledText tid={errorTid} color="persianRed" />}
			</StyledWrapper>
		);
	};
}
