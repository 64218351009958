import React, { useContext } from "react";
import { DropDownContext } from "../../../../../mui-components/dropdown/DropDown";
import MenuItemIcon from "../../../../../mui-components/dropdown/menu/menu-item-icon";
import { TAB_TEAMS_AND_SHAREHOLDES } from "../../../select-user-modal/select-user-modal.constants";
import { usePeopleModalContext } from "../../people-modal.context";
import { PEOPLE_MODAL_FILTERS_TEAMS } from "../../constants/filters";

export const TeamsFilterMenuItem = () => {
	const { activeTab, filters, setIsFilterByRoleModalOpen } =
		usePeopleModalContext();
	const { popupState } = useContext(DropDownContext);
	const teamsAndShareholderTabIsActive =
		activeTab === TAB_TEAMS_AND_SHAREHOLDES;

	const openModal = () => {
		setIsFilterByRoleModalOpen(true);
		popupState.toggle();
	};

	if (!teamsAndShareholderTabIsActive) {
		return null;
	}

	const filterActive =
		typeof filters[PEOPLE_MODAL_FILTERS_TEAMS] !== "undefined" &&
		filters[PEOPLE_MODAL_FILTERS_TEAMS] !== null;

	return (
		<>
			<MenuItemIcon
				icon="faUserFriends"
				listItemTid="ag_grid.panel.dropdown_menu.filter.item.filter_by_team"
				onClick={openModal}
				notificationBadge={filterActive}
				notificationBadgeAnimate={false}
				notificationBadgeColor={"green"}
			/>
		</>
	);
};
