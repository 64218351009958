import React, { useState } from "react";
import { Form } from "react-final-form";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TextField } from "mui-rff";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material";

const EmissionCreationModal = (props) => {
	const { t } = useTranslation();
	const [emissionName, setEmissionName] = useState("");
	const [emissionType, setEmissionType] = useState("");
	const [nameError, setNameError] = useState(false);
	const [typeError, setTypeError] = useState(false);
	const [submitButtonDisabled, setSubmitActionDisabled] = useState(false);

	const validateForm = () => {
		if (emissionName.trim() === "") {
			setNameError(true);
		} else {
			setNameError(false);
		}
		if (emissionType.trim() === "") {
			setTypeError(true);
		} else {
			setTypeError(false);
		}
		return emissionName.trim() !== "" && emissionType.trim() !== "";
	};

	const localHandleSubmit = () => {
		if (validateForm()) {
			setSubmitActionDisabled(true);
			props.onSubmit(
				{ name: emissionName, emissionType: emissionType },
				(error) => {
					if (error) {
						setSubmitActionDisabled(false);
					}
				},
			);
		}
	};

	const handleChange = (event) => {
		if (event.target.name === "emissionType") {
			setEmissionType(event.target.value);
			if (event.target.value.trim() === "") {
				setTypeError(true);
			} else {
				setTypeError(false);
			}
		}
		if (event.target.name === "emissionName") {
			setEmissionName(event.target.value);
			if (event.target.value.trim() === "") {
				setNameError(true);
			} else {
				setNameError(false);
			}
		}
	};

	return (
		<Dialog open={props.open} onClose={props.close}>
			<Form onSubmit={localHandleSubmit} keepDirtyOnReinitialize={true}>
				{(formProps) => {
					return (
						<form onSubmit={formProps.handleSubmit}>
							<DialogContent sx={{ px: 6, py: 4 }}>
								<IconButton
									aria-label="close"
									onClick={props.close}
									sx={{
										position: "absolute",
										right: 8,
										top: 8,
										color: (theme) => theme.palette.grey[600],
									}}
								>
									<CloseIcon />
								</IconButton>
								<Box sx={{ display: "flex", flexDirection: "column" }}>
									<Typography sx={{ textAlign: "center", pb: 2 }} variant="h4">
										{props.title}
									</Typography>
									<Typography sx={{ textAlign: "left", pb: 3 }} variant="body2">
										{t("emissions.create-emission.desc")}
									</Typography>
									<Box sx={{ mt: 0.5, mb: 2 }}>
										<FormControl fullWidth error={typeError}>
											<InputLabel id="emissionType">
												{t("emissions.form-field.emission-type")}
											</InputLabel>
											<Select
												name={"emissionType"}
												labelId={"emissionType"}
												label={t("emissions.form-field.emission-type")}
												value={emissionType}
												onChange={handleChange}
											>
												<MenuItem value={"preferential"}>
													<Typography variant={"body2"}>
														{t(
															"emissions.form-field.emission-type.options.preferential",
														)}
													</Typography>
												</MenuItem>
												<MenuItem value={"directed"}>
													<Typography variant={"body2"}>
														{t(
															"emissions.form-field.emission-type.options.directed",
														)}
													</Typography>
												</MenuItem>
											</Select>
											<FormHelperText>
												{typeError
													? t(
															"emission.create-emission.emission-type.error-text",
													  )
													: " "}
											</FormHelperText>
										</FormControl>
									</Box>
									<Box mb={5}>
										<TextField
											sx={(theme) => ({
												minWidth: theme.spacing(48),
											})}
											name="emissionName"
											margin="dense"
											label={t("emissions.column.name")}
											error={nameError}
											value={emissionName}
											helperText={
												nameError
													? t(
															"emission.create-emission.emission-name.error-text",
													  )
													: " "
											}
											onChange={handleChange}
											inputProps={{ maxLength: 42 }}
										/>
									</Box>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
										}}
									>
										<Button
											disabled={submitButtonDisabled}
											variant="contained"
											size="large"
											type="submit"
										>
											<Typography variant={"h5"}>{props.buttonText}</Typography>
										</Button>
									</Box>
								</Box>
							</DialogContent>
						</form>
					);
				}}
			</Form>
		</Dialog>
	);
};
export default EmissionCreationModal;
