import { fromJS } from "immutable";

export const DOCUMENT_TASKS_CATEGORY_ID =
	"783838f5-2b0e-47d5-9075-b4eb0fa50433";

export const FACTORY_DEFAULT_FILTER_VALUES = fromJS({
	dateProp: ["createdAt"],
	order: ["datePropDesc"],
	showOnlyTrashed: [false],
});
