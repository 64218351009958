import React from "react";
import UpgradePanel from "../../../dumb-components/company/comapny-dashboard/upgrade-panel";
import GoToBillingButton from "../../notifications/onboarding/modals/shared/btn-go-to-billing.container";
import Text from "../../../dumb-components/shared/text/text";
import { useTranslation } from "react-i18next";
import LoadingPanel from "../../../components/loadingPanel";
import useSubscriptionHelper from "../../../hooks/useSubscriptionHelper";

const DashboardUpgradePanelContainer = () => {
	const { i18n } = useTranslation();
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;

	if (!subscriptionHelper) return <LoadingPanel />;

	const { lite, starter, premium, enterprise, enterprisePlus } =
		subscriptionHelper;

	const getPanelData = () => {
		if (lite) {
			return {
				panelTitle: "company.dashboard.upgrade_panel.title.lite",
				text: "company.dashboard.upgrade_panel.lite.description",
			};
		}

		if (starter) {
			return {
				panelTitle: "company.dashboard.upgrade_panel.title.standard",
				text: "company.dashboard.upgrade_panel.standard.description",
				tidLink: (
					<Text
						tid="company.dashboard.upgrade_panel.standard.description.link.text"
						onClick={onClick}
						hasUnderline
					/>
				),
			};
		}

		if (premium) {
			return {
				panelTitle: "company.dashboard.upgrade_panel.title.premium",
				text: "company.dashboard.upgrade_panel.premium.description",
			};
		}

		if (enterprise) {
			return {
				panelTitle: "company.dashboard.upgrade_panel.title.enterprise",
				text: "company.dashboard.upgrade_panel.enterprise.description",
			};
		}

		if (enterprisePlus) {
			return {
				panelTitle: "company.dashboard.upgrade_panel.title.enterprise_plus",
				text: "company.dashboard.upgrade_panel.enterprise_plus.description",
			};
		}

		// Free
		return {
			panelTitle: "company.dashboard.upgrade_panel.title.free",
			text: "company.dashboard.upgrade_panel.free.description",
		};
	};

	const onClick = () => {
		const url = i18n.language.startsWith("sv")
			? "https://invono.se/moten/"
			: "https://invono.se/moten/";
		window.open(url, "_blank");
	};

	return (
		<UpgradePanel
			{...getPanelData()}
			upgradeButton={
				!premium && (
					<GoToBillingButton tid="company.dashboard.upgrade_panel.btn.upgrade_now" />
				)
			}
		/>
	);
};

export default DashboardUpgradePanelContainer;
