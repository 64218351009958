import React from "react";
import { AddressElement, useElements } from "@stripe/react-stripe-js";

const BillingDetailForm = ({ valuesRef, defaultValues }) => {
	const elements = useElements();

	valuesRef.current = elements;

	return (
		<div>
			<AddressElement
				options={{
					mode: "billing",
					defaultValues,
					display: {
						name: "organization",
					},
				}}
			/>
		</div>
	);
};

export default BillingDetailForm;
