import {
	SET_STRIPE_SETUP_INTENT_CLIENT_SECRET,
	SET_STRIPE_PRODUCTS,
	SET_STRIPE_SUBSCRIPTION_DATA,
} from "../actions/types";

const INITIAL_STATE = {
	products: [],
	setupIntentClientSecret: "",
	subscription: null,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_STRIPE_SETUP_INTENT_CLIENT_SECRET:
			return {
				...state,
				setupIntentClientSecret: action.setupIntentClientSecret,
			};
		case SET_STRIPE_PRODUCTS:
			return { ...state, products: action.products };
		case SET_STRIPE_SUBSCRIPTION_DATA:
			return { ...state, subscription: action.subscription };
		default:
			return state;
	}
}
