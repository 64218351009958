/* eslint-disable react/no-unknown-property */

import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { getShareTypeLabel } from "../../../../components/helpers/shares";
import localeFormatNumber from "../../../../modules/format-number";
import {
	NUMBER_FORMAT_CURRENCY,
	NUMBER_FORMAT_INTEGER,
} from "/shared/constants";
import {
	INVESTED_AMOUNT_GRID_TOOLTIP,
	MY_SHARES_TOOLTIP,
	MY_TOTAL_SHARES_GRID_TOOLTIP,
	NUMBER_OF_TOTAL_COMPANY_SHARES_GRID_TOOLTIP,
	POST_MONEY_GRID_TOOLTIP,
	TOTAL_VOTES_TOOLTIP,
} from "../../../../constants/grid-tooltip";
import { Paper } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import { useTranslation } from "react-i18next";
import {
	bodyTableCellSx,
	footerTableCellSx,
	headerTableCellSx,
	helpTextSx,
} from "./tooltip-renderer.styles";

const TooltipRenderer = (props) => {
	const { t } = useTranslation();
	const colId = props.column.colId;
	const details = props.data.details && props.data.details[colId];
	const rowId = props.data.rowId && props.data.rowId;
	const numOfTotalCompanyShares =
		rowId === NUMBER_OF_TOTAL_COMPANY_SHARES_GRID_TOOLTIP;
	const postMoney = rowId === POST_MONEY_GRID_TOOLTIP;
	const totalVotes = rowId === TOTAL_VOTES_TOOLTIP;
	const myTotalShares = rowId === MY_TOTAL_SHARES_GRID_TOOLTIP;
	const myShares = rowId === MY_SHARES_TOOLTIP;
	const investedAmount = rowId === INVESTED_AMOUNT_GRID_TOOLTIP;
	const bodyTid = props.data.bodyTid;

	return (
		<div className="custom-tooltip" component={Paper}>
			{details && details.length > 0 && (
				<TableContainer>
					<Table
						sx={{
							width: "-webkit-fill-available",
							marginBottom: bodyTid ? "0!important" : "16px",
						}}
					>
						<TableHead>
							{investedAmount ? (
								<TableRow>
									<TableCell sx={headerTableCellSx}>
										<Typography variant="body1">
											{t("investments.grid.tooltip.table.column_header.name")}
										</Typography>
									</TableCell>
									<TableCell sx={headerTableCellSx} align="right">
										<Typography variant="body1">
											{t(
												"investments.grid.tooltip.table.column_header.number_of_shares",
											)}
										</Typography>
									</TableCell>
									<TableCell sx={headerTableCellSx} align="right">
										<Typography variant="body1">
											{t(
												"investments.grid.tooltip.table.column_header.value_of_share",
											)}
										</Typography>
									</TableCell>
								</TableRow>
							) : (
								<TableRow>
									<TableCell sx={headerTableCellSx}>
										<Typography variant="body1">
											{t("investments.grid.tooltip.table.column_header.name")}
										</Typography>
									</TableCell>
									<TableCell sx={headerTableCellSx} align="right">
										<Typography variant="body1">
											{t("investments.grid.tooltip.table.column_header.total")}
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableHead>
						<TableBody>
							{details.map((shareType, index) => {
								return (
									<TableRow
										key={
											`${shareType.type}$${index}` ||
											`${shareType.shareTypeName}$${index}`
										}
									>
										{/* Left values */}
										{!myTotalShares && !myShares && !investedAmount && (
											<TableCell sx={bodyTableCellSx}>
												<Typography variant="body2">
													{getShareTypeLabel(shareType.type)}
												</Typography>
											</TableCell>
										)}
										{myTotalShares && shareType.shares > 0 && (
											<TableCell sx={bodyTableCellSx}>
												<Typography variant="body2">
													{getShareTypeLabel(shareType.type)}
												</Typography>
											</TableCell>
										)}
										{myShares && (
											<TableCell sx={bodyTableCellSx}>
												<Typography variant="body2">
													{shareType.shareType
														? getShareTypeLabel(shareType.shareType)
														: "-"}
												</Typography>
											</TableCell>
										)}
										{investedAmount && (
											<TableCell sx={bodyTableCellSx}>
												<Typography variant="body2">
													{shareType.shareType
														? getShareTypeLabel(shareType.shareType)
														: "-"}
												</Typography>
											</TableCell>
										)}

										{/* Right values */}
										{numOfTotalCompanyShares && (
											<TableCell align="right" sx={bodyTableCellSx}>
												<Typography variant="body2">
													{shareType.totalShares
														? localeFormatNumber(
																shareType.totalShares,
																NUMBER_FORMAT_INTEGER,
														  )
														: "-"}
												</Typography>
											</TableCell>
										)}

										{postMoney && (
											<TableCell align="right" sx={bodyTableCellSx}>
												<Typography variant="body2">
													{localeFormatNumber(
														shareType.latestPrice,
														NUMBER_FORMAT_CURRENCY,
													) +
														" x " +
														localeFormatNumber(
															shareType.totalShares,
															NUMBER_FORMAT_INTEGER,
														)}
												</Typography>
											</TableCell>
										)}

										{totalVotes && (
											<TableCell align="right" sx={bodyTableCellSx}>
												<Typography variant="body2">
													{localeFormatNumber(
														shareType.votesPerShare,
														NUMBER_FORMAT_INTEGER,
													)}
												</Typography>
											</TableCell>
										)}

										{myTotalShares && shareType.shares > 0 && (
											<TableCell align="right" sx={bodyTableCellSx}>
												<Typography variant="body2">
													{localeFormatNumber(
														shareType.shares,
														NUMBER_FORMAT_INTEGER,
													)}
												</Typography>
											</TableCell>
										)}

										{myShares && (
											<TableCell align="right" sx={bodyTableCellSx}>
												<Typography variant="body2">
													{shareType.myShares
														? localeFormatNumber(
																shareType.myShares,
																NUMBER_FORMAT_INTEGER,
														  )
														: "-"}
												</Typography>
											</TableCell>
										)}

										{investedAmount && (
											<>
												<TableCell align="right" sx={bodyTableCellSx}>
													<Typography variant="body2">
														{shareType.myShares
															? localeFormatNumber(
																	shareType.myShares,
																	NUMBER_FORMAT_INTEGER,
															  )
															: "-"}
													</Typography>
												</TableCell>
												<TableCell align="right" sx={bodyTableCellSx}>
													<Typography variant="body2">
														{shareType.latestPrice
															? localeFormatNumber(
																	shareType.latestPrice,
																	NUMBER_FORMAT_CURRENCY,
															  )
															: "-"}
													</Typography>
												</TableCell>
											</>
										)}
									</TableRow>
								);
							})}
							<TableRow>
								<TableCell sx={footerTableCellSx}></TableCell>
								{investedAmount ? (
									<>
										<TableCell sx={footerTableCellSx}></TableCell>
										<TableCell sx={footerTableCellSx} align="right">
											{props.value}
										</TableCell>
									</>
								) : (
									<TableCell sx={footerTableCellSx} align="right">
										{props.value}
									</TableCell>
								)}
							</TableRow>
						</TableBody>
					</Table>
					{bodyTid && (
						<Typography variant="body2" sx={helpTextSx} color="text.primary">
							{t(bodyTid)}
						</Typography>
					)}
				</TableContainer>
			)}
		</div>
	);
};

export default TooltipRenderer;
