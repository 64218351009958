import React from "react";

import Box from "@mui/material/Box";
import { DropDown } from "../../../mui-components/dropdown/DropDown";
import IconButton from "../../../mui-components/button/icon-button";
import Tooltip from "../../../mui-components/tooltip/tooltip";
import MenuItemIcon from "../../../mui-components/dropdown/menu/menu-item-icon";
import OpenOrDownloadComponent from "../../../containers/documents/generic/open-or-download-file-component.container";
import AddToCollectionComponent from "../../../containers/documents/generic/add-document-to-collection-component.container";
import { CopyFileToPersonalDocuments } from "../../../containers/documents/generic/dropdown-items/copy-file-to-personal-documents";
import MoveFileItemContainer from "../../../containers/documents/generic/dropdown-items/move-file-item.container";
import DeleteFileItem from "../../../containers/documents/generic/dropdown-items/delete-file-item.container";
import RecoverFileDropDownItem from "../../../containers/documents/generic/dropdown-items/recover-file.container";
import { fromJS } from "immutable";
import { getQuery } from "../../../interfaces/history";
import { makeUrlRelativeToCompany } from "../../../components/helpers/link.helper";

const MoreActionRenderer = ({ props }) => {
	const basePath = makeUrlRelativeToCompany("documents");
	const document = fromJS(props.data);
	const querystr = new URLSearchParams(getQuery()).toString();

	return (
		<Box sx={{ display: "flex", justifyContent: "center" }}>
			<DropDown
				alignMenu="right"
				button={({ params }) => (
					<IconButton noBorder icon="faEllipsisV" {...params} />
				)}
			>
				<OpenOrDownloadComponent document={document}>
					{({ TOOLTIP_STATES, activeState, isDisabled, openOrDownload }) => (
						<Tooltip
							states={TOOLTIP_STATES}
							activeState={activeState}
							active={isDisabled.toString()}
						>
							<MenuItemIcon
								icon="faExpandArrowsAlt"
								listItemTid="open_file"
								disabled={isDisabled}
								onClick={() => {
									openOrDownload();
								}}
							/>
						</Tooltip>
					)}
				</OpenOrDownloadComponent>

				<OpenOrDownloadComponent document={document} mode="DOWNLOAD">
					{({ openOrDownload }) => (
						<MenuItemIcon
							icon="faArrowAltToBottom"
							listItemTid="download_file"
							onClick={() => {
								openOrDownload();
							}}
						/>
					)}
				</OpenOrDownloadComponent>

				<AddToCollectionComponent document={document}>
					{({ TOOLTIP_STATES, activeState, isDisabled, addToCollection }) => (
						<Tooltip
							states={TOOLTIP_STATES}
							activeState={activeState}
							active={isDisabled.toString()}
						>
							<MenuItemIcon
								listItemTid="documents.list.dropdown.item.add_to_collection"
								icon="faReceipt"
								disabled={isDisabled}
								onClick={() => {
									addToCollection();
								}}
							/>
						</Tooltip>
					)}
				</AddToCollectionComponent>

				<CopyFileToPersonalDocuments document={document} />

				<MoveFileItemContainer document={document} onClick={() => {}} />

				<DeleteFileItem
					document={document}
					basePath={basePath}
					querystr={querystr}
				/>

				<RecoverFileDropDownItem
					document={document}
					basePath={basePath}
					querystr={querystr}
				/>
			</DropDown>
		</Box>
	);
};

export default MoreActionRenderer;
