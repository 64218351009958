import React, { PureComponent } from "react";
import { bool } from "prop-types";
import { Margin } from "styled-components-spacing";
import OnboardingAlert from "../../onboarding/alert";
import Text from "../../shared/text/text";

export default class SharedDocumentInfoAlert extends PureComponent {
	static propTypes = {
		readOnly: bool,
	};

	render = () => {
		const { readOnly } = this.props;
		const tid = readOnly
			? "documents.document.shared_document_info.alert"
			: "documents.document.shared_document_info.alert.can_manage_doc";
		// TODO: change text depending on read only state of the document
		return (
			<Margin bottom={4}>
				<OnboardingAlert>
					<Text color="white" tid={tid} />
				</OnboardingAlert>
			</Margin>
		);
	};
}
