import React from "react";
import { oneOf, bool, number } from "prop-types";
import styled, { css } from "styled-components";

const StyledBox = styled.div`
	display: flex;
	flex: ${(props) => (props.flexGrow ? "1" : "0 1 auto")};
	flex-direction: ${(props) => props.direction};
	justify-content: ${(props) => {
		if (props.align === "left") {
			return "flex-start";
		} else if (props.align === "center") {
			return "center";
		} else if (props.align === "space-around") {
			return "space-around";
		}

		return "flex-end";
	}};

	${(props) =>
		props.valign &&
		css`
			align-items: ${props.valign};
		`}

	${(props) =>
		props.customMarginRight &&
		css`
			margin-right: ${props.customMarginRight}px;
		`}

	${(props) =>
		props.customMarginLeft &&
		css`
			margin-left: ${props.customMarginLeft}px;
		`}

	${(props) =>
		typeof props.marginBottom === "number" &&
		css`
			margin-bottom: ${(props) => props.theme.spacing[props.marginBottom]};
		`}

	${(props) =>
		props.fullHeight &&
		css`
			height: 100%;
		`}
`;

const Box = ({
	alignItems,
	direction,
	children,
	valign,
	flexGrow,
	customMarginRight,
	customMarginLeft,
	marginBottom,
	fullHeight,
}) => {
	const props = {
		customMarginRight,
		customMarginLeft,
		marginBottom,
		fullHeight,
	};

	return (
		<StyledBox
			align={alignItems}
			direction={direction}
			valign={valign}
			flexGrow={flexGrow}
			{...props}
		>
			{children}
		</StyledBox>
	);
};

Box.defaultProps = {
	direction: "row",
	alignItems: "left",
	flexGrow: true,
};

Box.propTypes = {
	direction: oneOf(["column", "row"]),
	alignItems: oneOf(["left", "center", "right", "space-around"]),
	flexGrow: bool,
	customMarginRight: number,
	customMarginLeft: number,
	marginBottom: number,
	fullHeight: bool,
};

export default Box;
