import React, { useState } from "react";
import { func } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import AddAttendeesModalContainer from "../../attendees/add-attendees-modal.container";

const AddAttendees = ({ children, onAddAttendees, attendees }) => {
	const [isOpen, setIsOpen] = useState(false);

	const openAttendeesModal = () => {
		setIsOpen(true);
	};

	const closeAttendeesModal = () => {
		setIsOpen(false);
	};

	return (
		<>
			{children({ openAttendeesModal })}

			{isOpen && (
				<AddAttendeesModalContainer
					isOpen={true}
					onClose={closeAttendeesModal}
					onSelectMultipleAttendees={onAddAttendees}
					users={attendees}
				/>
			)}
		</>
	);
};

AddAttendees.propTypes = {
	children: func.isRequired,
	onAddAttendees: func,
	attendees: immutablePropTypes.map,
};

export default AddAttendees;
