import React, { PureComponent } from "react";
import { func } from "prop-types";
import styled from "styled-components";

import Tooltip from "../../shared/tooltip/tooltip";
import Text from "../../shared/text/text";
import Icon from "../../shared/icon/icon";

const StyledWrapper = styled.div`
	display: flex;
	height: 100%;
	gap: 8px;
	padding: 0 16px;
	align-items: center;
	cursor: pointer;
`;

export default class AddCompanyItem extends PureComponent {
	static propTypes = {
		onClick: func,
	};

	render = () => {
		const { onClick } = this.props;

		return (
			<Tooltip
				tid="navbar.company_dropdown.add_company.tooltip"
				delayShow="instant"
			>
				<StyledWrapper onClick={onClick}>
					<Icon icon="faPlusCircle" size="sml" />
					<Text tid="navbar.company_dropdown.add_company.title" bold={700} />
				</StyledWrapper>
			</Tooltip>
		);
	};
}
