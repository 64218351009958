import React, { Component } from "react";
import { connect } from "react-redux";
import { string, object } from "prop-types";

import Toolbar from "../../../dumb-components/shared/toolbar/toolbar";
import { ButtonTransparentIcon } from "../../../dumb-components/shared/button";
import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";
import DropdownItem from "../../../dumb-components/shared/dropdown-item/dropdown-item";

// Handle attendee components
import RollcallStatusMessage from "../../../dumb-components/meetings/toolbar/rollcall-status-message";
import AddAttendees from "./general/dropdown-items/add-attendee.container";
import InviteAttendees from "./general/dropdown-items/invite-attendees.container";
import Rollcall from "./general/dropdown-items/rollcall-button.container";
import ManageModerators from "./general/dropdown-items/manage-moderators.container";

// Handle meeting (rocket) components
import StartMeeting from "./general/dropdown-items/start-meeting.container";

// Handle publish (cloud) components
import PublishWithEsign from "./general/dropdown-items/publish-with-esign.container";
import PublishWithoutEsign from "../protocol/publish-protocol-component.container";

// More actions components
import AssignSecretary from "./general/dropdown-items/assign-secretary.container";
import MoveMeeting from "./general/dropdown-items/move-meeting.container";
import UnpublishProtocol from "./general/dropdown-items/unpublish-protocol.container";
import Archive from "./general/dropdown-items/archive.container";
import Delete from "./general/dropdown-items/delete-meeting.container";
import Recover from "./general/dropdown-items/recover.container";

// Other
import OpenSmartMeeting from "./general/dropdown-items/open-smart-meeting.container";

import { MEETING_TYPE_STANDARD, MEETING_TYPE_SMART } from "/shared/constants";

class MeetingsToolbarContainer extends Component {
	static propTypes = {
		paramsId: string,
		location: object,
		basePath: string,
	};

	toggleAttendeeDropdown = () => {
		this.attendeeDropdownRef && this.attendeeDropdownRef.onToggleMenu();
	};

	toggleActionDropdown = () => {
		this.actionDropdownRef && this.actionDropdownRef.onToggleMenu();
	};

	togglePublishDropdown = () => {
		this.publishDropdownRef && this.publishDropdownRef.onToggleMenu();
	};

	toggleMoreActionDropdown = () => {
		this.moreActionDropdownRef && this.moreActionDropdownRef.onToggleMenu();
	};

	renderAttendeeDropdown = () => {
		const { rollcallDone } = this.props;

		return (
			<DropdownMenuContainer
				ref={(ref) => (this.attendeeDropdownRef = ref)}
				halignMenu="right"
				noMaxWidth={true}
				withPortal={true}
				renderRaw={
					<ButtonTransparentIcon
						icon="faUserCircle"
						notificationBadge={true}
						notificationBadgeAnimate={rollcallDone ? false : true}
						notificationBadgeColor={rollcallDone ? "green" : "persianRed"}
						onClick={this.toggleAttendeeDropdown}
					/>
				}
			>
				<AddAttendees
					renderComponent={({ openAttendeesModal, disabled }) => (
						<DropdownIconItem
							icon="faUserPlus"
							tid="meetings.general.toolbar.dropdown.add_attendee"
							disabled={disabled}
							onClick={() => {
								this.toggleAttendeeDropdown();
								openAttendeesModal();
							}}
						/>
					)}
				/>

				<ManageModerators toggleDropdown={this.toggleAttendeeDropdown} />

				<InviteAttendees
					type="dropdown"
					dropdownRef={this.attendeeDropdownRef}
				/>
				<Rollcall dropdownRef={this.attendeeDropdownRef} />
			</DropdownMenuContainer>
		);
	};

	renderRollcallStatusMessage = () => {
		const { rollcallDone } = this.props;
		return <RollcallStatusMessage rollcallDone={rollcallDone} />;
	};

	renderActionsDropdown = () => {
		return (
			<DropdownMenuContainer
				btnMode="transparent-icon"
				btnIcon="faRocket"
				halignMenu="right"
				ref={(ref) => (this.actionDropdownRef = ref)}
			>
				<StartMeeting dropdownRef={this.actionDropdownRef} />
			</DropdownMenuContainer>
		);
	};

	renderPublishDropdown = () => {
		return (
			<DropdownMenuContainer
				btnMode="transparent-icon"
				btnIcon="faCloudUpload"
				halignMenu="right"
				noMaxWidth={true}
				withPortal
				ref={(ref) => (this.publishDropdownRef = ref)}
			>
				<PublishWithEsign
					renderComponent={({
						openSigneesModal,
						tooltip: { TOOLTIP_STATES, activeState, isDisabled },
					}) => (
						<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
							<DropdownIconItem
								icon="faArrowFromBottom"
								tid="meetings.general.toolbar.dropdown.publish_with_esign"
								disabled={isDisabled}
								onClick={() => {
									this.togglePublishDropdown();
									openSigneesModal();
								}}
							/>
						</Tooltip>
					)}
				/>

				<PublishWithoutEsign
					renderComponent={({
						TOOLTIP_STATES,
						isDisabled,
						activeState,
						openModal,
					}) => (
						<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
							<DropdownIconItem
								icon="faSignature"
								tid="meetings.general.toolbar.dropdown.publish_without_esign"
								disabled={isDisabled}
								onClick={() => {
									this.togglePublishDropdown();
									openModal();
								}}
							/>
						</Tooltip>
					)}
				/>
			</DropdownMenuContainer>
		);
	};

	renderMoreActionsDropdown = () => {
		const { meetingIsDeleted, basePath, location } = this.props;
		const commonProps = {
			dropdownRef: this.moreActionDropdownRef,
		};

		return (
			<DropdownMenuContainer
				btnIcon="faEllipsisH"
				halignMenu="right"
				btnMode="transparent-icon"
				ref={(ref) => (this.moreActionDropdownRef = ref)}
				tooltipActiveState="btnMoreActions"
				noMaxWidth
			>
				<AssignSecretary {...commonProps} />
				<MoveMeeting {...commonProps} />
				<UnpublishProtocol {...commonProps} />
				<Archive {...commonProps} />
				<DropdownItem divider />

				{meetingIsDeleted ? (
					<Recover {...commonProps} basePath={basePath} location={location} />
				) : (
					<Delete {...commonProps} basePath={basePath} location={location} />
				)}
			</DropdownMenuContainer>
		);
	};

	renderOpenSmartMeeting = () => {
		const { basePath, location } = this.props;

		return <OpenSmartMeeting basePath={basePath} location={location} />;
	};

	render = () => {
		const { paramsId, isStandardMeeting, isSmartMeeting } = this.props;

		if (!paramsId) {
			return <Toolbar />;
		}

		return (
			<Toolbar>
				{isSmartMeeting && this.renderOpenSmartMeeting()}
				{isStandardMeeting && this.renderRollcallStatusMessage()}
				{isStandardMeeting && this.renderAttendeeDropdown()}
				{isStandardMeeting && this.renderActionsDropdown()}
				{isStandardMeeting && this.renderPublishDropdown()}
				{this.renderMoreActionsDropdown()}
			</Toolbar>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		rollcallDone: store.meetings.getIn(
			["meeting", "metadata", "rollcallDone"],
			false,
		),
		isStandardMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) ===
			MEETING_TYPE_STANDARD,
		isSmartMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) === MEETING_TYPE_SMART,
		meetingIsDeleted: store.meetings.getIn(["meeting", "isDeleted"]),
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingsToolbarContainer);
