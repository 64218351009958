import React, { PureComponent } from "react";
import { bool, func } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import styled from "styled-components";
import GlobalList from "../../shared/list/list";
import { StyledListHeaderTemplate } from "../../shared/list/list-header.template";
import ScrollView from "../../shared/layout/scroll-view/scroll-view";
import Button from "../../shared/button/button";
import AttendeesModalListItem from "./attendees-modal-list-item";
import investmentApi from "/shared/helpers/investment.helper";
import { INVESTOR_TYPE_OF_OWNER_COMPANY } from "/shared/constants";

const StyledList = styled(GlobalList)`
	margin: ${(props) => props.theme.spacing[6]};
	margin-top: ${(props) => props.theme.spacing[5]};
`;

const StyledHeader = styled.div`
	${StyledListHeaderTemplate}
`;

const StyledActions = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
`;

const StyledButtonPlaceholder = styled.div`
	min-width: 44px;
	max-width: 44px;
`;

export default class AttendeesModalList extends PureComponent {
	static propTypes = {
		attendees: immutablePropTypes.map,
		usersCache: immutablePropTypes.map,
		investors: immutablePropTypes.map,
		onChange: func,
		renderStatus: func,
		renderMoreAction: func,
		onSelectAll: func,
		allSelected: bool,
	};

	renderHeader = () => {
		const { allSelected, onSelectAll } = this.props;

		return (
			<StyledHeader>
				<StyledActions>
					<Button
						mode="link"
						tid={
							allSelected
								? "meetings.agenda.attendees_modal.deselect_all"
								: "meetings.agenda.attendees_modal.select_all"
						}
						onClick={() => onSelectAll && onSelectAll(!allSelected)}
					/>
				</StyledActions>
				<StyledButtonPlaceholder />
			</StyledHeader>
		);
	};

	renderAttendee = (attendee, userId) => {
		const { onChange, renderStatus, renderMoreAction, usersCache, investors } =
			this.props;
		const user = usersCache.get(userId);
		let name = user ? user.get("name") : attendee.get("name");

		if (attendee.get("isInvestor") === true && investors) {
			const investor = investors.find(
				(obj) => obj.get("id") === attendee.get("investmentId"),
			);

			if (investor) {
				const email = investmentApi.getInvestorEmail(investor.toJS());
				name = investmentApi.getInvestorName(investor.toJS());
				if (
					investor.get("investorTypeOfOwner") === INVESTOR_TYPE_OF_OWNER_COMPANY
				) {
					name = `${name} - ${email}`;
				}
			}
		}

		return (
			<AttendeesModalListItem
				key={userId}
				id={userId}
				name={name}
				profileImage={user && user.getIn(["image", "filename"])}
				response={attendee.getIn(["metadata", "respondedAt"])}
				status={attendee.get("status")}
				onChange={onChange}
				renderStatus={renderStatus}
				renderMoreAction={renderMoreAction}
			/>
		);
	};

	render = () => {
		const { attendees } = this.props;

		return (
			<ScrollView showOnHover autoHide noLeftMargin noRightMargin>
				<StyledList header={this.renderHeader}>
					{attendees && attendees.map(this.renderAttendee).toList()}
				</StyledList>
			</ScrollView>
		);
	};
}
