import { getLocation } from "../../interfaces/history";
import { getStore } from "../../store";

/**
 * Create link to page in the application
 * @param {string} uri - path to route
 * @param {string|object} [query] - additional params to add to the querystring of the url.
 * @return {string}
 */
export const makeInternalLink = (uri, query, relative = true) => {
	let { pathname } = getLocation();

	if (typeof query === "object") {
		query = new URLSearchParams(query).toString();
	}

	if (relative) {
		if (pathname.endsWith("/")) {
			pathname = pathname.slice(0, -1);
		}

		uri = `${pathname}/${uri}`;
	}

	return `${uri}${query ? `?${query}` : ""}`;
};

/**
 * Create a link relative to the company's base url
 * @param {string} uri - path to route
 * @param {string|object} [query] - additional params to add to the querystring of the url.
 * @return {string}
 */
export const makeUrlRelativeToCompany = (uri, query) => {
	const store = getStore();
	const urlAlias = store.getState().company?.company?.urlAlias;

	if (!urlAlias) {
		throw `Failed to retrieve url alias`;
	}

	if (typeof query === "object") {
		query = new URLSearchParams(query).toString();
	}

	if (uri.startsWith("/")) {
		uri = uri.substring(1);
	}

	return `/${urlAlias}/${uri}${query ? `?${query}` : ""}`;
};
