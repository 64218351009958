import React, { PureComponent } from "react";
import { string, func } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import styled from "styled-components";
import moment from "../../../modules/moment.module";
import ListItem from "../../shared/list-item/list-item";
import ToggleSwitch from "../../shared/toggle-switch/toggle-switch";
import ProfileImage from "../../shared/profile-image/profile-image";
import Text from "../../shared/text/text";
import {
	ATTENDEE_STATUS_PRESENT,
	ATTENDEE_STATUS_ABSENT,
	ATTENDEE_STATUS_UNSURE,
} from "/shared/constants";

const StyledListItem = styled(ListItem)`
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeDark};
	margin-bottom: 0px;
	padding: 0;
`;

const StyledListItemContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	height: 100%;
`;

const StyledName = styled.div`
	display: flex;
	flex: 1;
	padding: 0 ${(props) => props.theme.spacing[5]};
`;

const StyledResponse = styled.div`
	display: flex;
	flex: 1;
	padding: 0 ${(props) => props.theme.spacing[5]};
`;

const StyledToggleSwitchContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0;
`;

const StyledUserImageContainer = styled.div`
	width: 50px;
	height: 100%;
`;

const StyledButtonContainer = styled.div`
	min-width: 44px;
	max-width: 44px;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0;

	button {
		margin-right: 0;
	}
`;

export default class AttendeesModalListItem extends PureComponent {
	static propTypes = {
		id: string,
		name: string,
		profileImage: string,
		response: immutablePropTypes.map,
		status: string,
		onChange: func,
		renderStatus: func,
		renderMoreAction: func,
	};

	onChange = (fieldName, val) => {
		const { onChange, id } = this.props;

		onChange(id, fieldName, val);
	};

	render = () => {
		const {
			id,
			name,
			profileImage,
			response,
			status,
			renderStatus,
			renderMoreAction,
		} = this.props;
		let responseTid;
		const haveResponded = response && response.get("status") ? true : false;
		const respondedAt =
			haveResponded &&
			response &&
			response.get("respondedAt") &&
			moment(response.get("respondedAt")).format("ll LT");

		if (response && response.get("status") === ATTENDEE_STATUS_PRESENT) {
			responseTid = "meeting.attendees.response.accepted_invite";
		} else if (response && response.get("status") === ATTENDEE_STATUS_UNSURE) {
			responseTid = "meeting.attendees.response.unsure";
		} else if (response && response.get("status") === ATTENDEE_STATUS_ABSENT) {
			responseTid = "meeting.attendees.response.declined_invite";
		} else {
			responseTid = "meeting.attendees.response.did_not_respond_to_invite";
		}

		return (
			<StyledListItem>
				<StyledListItemContent>
					<StyledUserImageContainer>
						<ProfileImage
							image={
								profileImage
									? `/api/users/public/images/${profileImage}-80x80?userId=${id}`
									: null
							}
						/>
					</StyledUserImageContainer>

					{renderStatus(id)}

					<StyledName>
						<Text>{name}</Text>
					</StyledName>

					<StyledResponse>
						{haveResponded && respondedAt && (
							<span>
								<Text tid={responseTid} />
								&nbsp;-&nbsp;<Text>{respondedAt}</Text>
							</span>
						)}
						{responseTid && !respondedAt && <Text tid={responseTid} />}
					</StyledResponse>

					<StyledToggleSwitchContainer>
						<ToggleSwitch
							fieldName="statusSwitch"
							checked={status === ATTENDEE_STATUS_PRESENT}
							onChange={this.onChange}
						/>
					</StyledToggleSwitchContainer>

					<StyledButtonContainer>{renderMoreAction(id)}</StyledButtonContainer>
				</StyledListItemContent>
			</StyledListItem>
		);
	};
}
