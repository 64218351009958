// Folders
export const LIVE_FOLDER_UPDATE = "FOLDER_UPDATE";
export const LIVE_FOLDER_DELETE = "FOLDER_DELETE";

// Tasks
export const LIVE_TASK_CREATE = "TASK_CREATE";
export const LIVE_TASK_UPDATE = "TASK_UPDATE";
export const LIVE_TASK_DELETE = "TASK_DELETE";
export const LIVE_TASK_DELETE_MULTIPLE = "TASK_DELETE_MULTIPLE ";
export const LIVE_TASK_TRANSFER = "TASK_TRANSFER";
export const LIVE_TASK_EXTERNAL_CREATE = "TASK_EXTERNAL_CREATE";
export const LIVE_TASK_EXTERNAL_UPDATE = "TASK_EXTERNAL_UPDATE";
export const LIVE_TASK_EXTERNAL_DELETE = "TASK_EXTERNAL_DELETE";
export const LIVE_TASK_EXTERNAL_DELETE_MULTIPLE =
	"TASK_EXTERNAL_DELETE_MULTIPLE";

// Projects
export const LIVE_PROJECT_CREATE = "PROJECT_CREATE";
export const LIVE_PROJECT_UPDATE = "PROJECT_UPDATE";
export const LIVE_PROJECT_DELETE = "PROJECT_DELETE";

// Tags
export const LIVE_TAG_CREATE = "TAG_CREATE";

// Comments
export const LIVE_CONVERSATION_UPDATE = "CONVERSATION_UPDATE";

// Meetings
export const LIVE_MEETINGS_CREATE = "MEETINGS_CREATE";
export const LIVE_MEETINGS_UPDATE = "MEETINGS_UPDATE";
export const LIVE_MEETINGS_DELETE = "MEETINGS_DELETE";
export const LIVE_MEETINGS_AGENDA_CREATE = "MEETINGS_AGENDA_CREATE";
export const LIVE_MEETINGS_AGENDA_UPDATE = "MEETINGS_AGENDA_UPDATE";
export const LIVE_MEETINGS_AGENDA_DELETE = "MEETINGS_AGENDA_DELETE";
export const LIVE_MEETINGS_AGENDA_SOFT_DELETE = "MEETINGS_AGENDA_SOFT_DELETE";
export const LIVE_MEETINGS_ATTENDEE_ADD = "MEETINGS_ATTENDEE_ADD";
export const LIVE_MEETINGS_ATTENDEE_REMOVE = "MEETINGS_ATTENDEE_REMOVE";
export const LIVE_MEETINGS_OPEN = "MEETINGS_OPEN";
export const LIVE_MEETINGS_CLOSE = "MEETINGS_CLOSE";
export const LIVE_MEETINGS_PROTOCOL_PUBLISHED = "MEETINGS_PROTOCOL_PUBLISHED";
export const LIVE_MEETINGS_FEEDBACKEE_DONE = "MEETINGS_FEEDBACKEE_DONE";
export const LIVE_MEETINGS_SIGNEE_DONE = "MEETINGS_SIGNEE_DONE";

// Groups
export const LIVE_GROUP_CREATE = "GROUP_CREATE";
export const LIVE_GROUP_UPDATE = "GROUP_UPDATE";
export const LIVE_GROUP_DELETE = "GROUP_DELETE";

// Meeting Templates
export const LIVE_MEETING_TEMPLATES_CREATE = "MEETING_TEMPLATES_CREATE";
export const LIVE_MEETING_TEMPLATES_UPDATE = "MEETING_TEMPLATES_UPDATE";
export const LIVE_MEETING_TEMPLATES_DELETE = "MEETING_TEMPLATES_DELETE";
