import {
	SET_ALL_EMISSIONS,
	ADD_NEW_EMISSION,
	SET_SINGLE_EMISSION,
	SET_EMISSION_SHARE_TYPES,
	SET_EMISSION_DOCUMENT_MAP,
	SET_EMISSION_MARKETING_FILES,
	SET_EMISSION_MARKETING_FILE_PERMISSIONS,
	DELETE_EMISSION_MARKETING_FILE,
	SET_EMISSION_INVITEES,
	SET_EMISSION_INVITEE_NOTES,
	SET_EMISSION_SUBSCRIPTION_SLIP,
	SET_EMISSION_SUBMISSIONS,
	SET_EMISSION_SUBMISSION_FORM_OPTIONS,
	SET_EMISSION_STATISTICS,
	SET_EMISSION_MARKETING_DIAGRAM_PERMISSIONS,
	SET_EMISSION_MARKETING_DIAGRAM_WHEN_TO_SHARE,
	SET_EMISSION_CLOSURE_REPORT_FILE_INFO,
	SET_EMISSION_BASIC_SHARE_DATA,
	SET_EMISSION_NUM_OF_INVITED_DEALFLOWS,
	SET_PREPARATION_ERROR,
	COMPANY_RESET_REDUCER,
	SET_EMISSION_MARKETING_DEALFLOW_DISPLAY_STATUS,
	SET_ANY_ACTIVE_EMISSION,
} from "../actions/types";
import { DateTime } from "luxon";

const INITIAL_STATE = {
	all: [],
	current: null,
	hasPreparationError: false,
	shareTypes: [],
	documentMap: {},
	marketingFiles: [],
	subscriptionSlip: {},
	invitees: [],
	invitedDealflows: {
		numOfDirectDealflows: 0,
		numOfDealflowsViaCriteria: 0,
	},
	notes: null,
	submissions: [],
	submissionFormOptions: {
		submission: {},
		insuranceOptions: [],
		depositoryOptions: [],
	},
	statistics: {
		maxAmountToAchieve: 0,
		minAmountToAchieve: 0,
		achievedAmount: 0,
	},
	anyActiveEmissions: null,
};

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_PREPARATION_ERROR: {
			return {
				...state,
				hasPreparationError: payload.hasError,
			};
		}
		case SET_SINGLE_EMISSION: {
			return {
				...state,
				current: payload.emission,
			};
		}
		case SET_EMISSION_SHARE_TYPES: {
			return {
				...state,
				shareTypes: payload.shareTypes,
			};
		}
		case SET_EMISSION_BASIC_SHARE_DATA: {
			return {
				...state,
				basicShareData: payload.basicShareData,
			};
		}
		case SET_EMISSION_DOCUMENT_MAP: {
			return {
				...state,
				documentMap: payload.documentMap,
			};
		}
		case SET_ALL_EMISSIONS: {
			return {
				...state,
				all: payload.allEmissions,
			};
		}
		case ADD_NEW_EMISSION: {
			return {
				...state,
				all: [...state.all, payload.emission],
			};
		}
		case SET_EMISSION_MARKETING_FILES: {
			return {
				...state,
				marketingFiles: payload.files,
			};
		}
		case SET_EMISSION_MARKETING_FILE_PERMISSIONS: {
			return {
				...state,
				marketingFiles: state.marketingFiles.map((m) =>
					payload.fileId === m.id
						? { ...m, permissions: payload.permissions }
						: m,
				),
			};
		}
		case DELETE_EMISSION_MARKETING_FILE: {
			return {
				...state,
				marketingFiles: state.marketingFiles.filter(
					(m) => payload.fileId !== m.id,
				),
			};
		}
		case SET_EMISSION_INVITEES: {
			return {
				...state,
				invitees: payload.invitees,
			};
		}
		case SET_EMISSION_NUM_OF_INVITED_DEALFLOWS: {
			return {
				...state,
				invitedDealflows: {
					numOfDirectDealflows: payload[0],
					numOfDealflowsViaCriteria: payload[1],
				},
			};
		}
		case SET_EMISSION_SUBMISSIONS: {
			return {
				...state,
				submissions: payload.submissions,
			};
		}
		case SET_EMISSION_INVITEE_NOTES: {
			if (!payload.notes) {
				return {
					...state,
					notes: null,
				};
			}

			const divided = payload.notes.reduce(
				(acc, curr) => {
					const arr = curr.important ? "flagged" : "unflagged";

					acc[arr].push(curr);
					return acc;
				},
				{ flagged: [], unflagged: [] },
			);

			const sortByDate = (a, b) =>
				DateTime.fromISO(b.date).toMillis() -
				DateTime.fromISO(a.date).toMillis();

			return {
				...state,
				notes: {
					inviteeId: payload.inviteeId,
					raw: payload.notes,
					processed: {
						flagged: divided.flagged.sort(sortByDate),
						unflagged: divided.unflagged.sort(sortByDate),
					},
				},
			};
		}
		case SET_EMISSION_SUBSCRIPTION_SLIP: {
			return {
				...state,
				subscriptionSlip: payload.subscriptionSlip,
			};
		}
		case SET_EMISSION_SUBMISSION_FORM_OPTIONS: {
			return {
				...state,
				submissionFormOptions: {
					...state.submissionFormOptions,
					...payload.submissionFormOptions,
				},
			};
		}

		case SET_EMISSION_STATISTICS: {
			return {
				...state,
				statistics: {
					...payload.statistics,
				},
			};
		}

		case SET_EMISSION_MARKETING_DIAGRAM_PERMISSIONS: {
			return {
				...state,
				current: {
					...state.current,
					marketingDiagramSettings: {
						...state.current.marketingDiagramSettings,
						permissions: payload.permissions,
					},
				},
			};
		}

		case SET_EMISSION_MARKETING_DEALFLOW_DISPLAY_STATUS: {
			return {
				...state,
				current: {
					...state.current,
					displayInUserDealflow: payload.displayInUserDealflow,
				},
			};
		}

		case SET_ANY_ACTIVE_EMISSION: {
			return {
				...state,
				anyActiveEmissions: payload.anyActiveEmissions,
			};
		}

		case SET_EMISSION_MARKETING_DIAGRAM_WHEN_TO_SHARE: {
			return {
				...state,
				current: {
					...state.current,
					marketingDiagramSettings: {
						...state.current.marketingDiagramSettings,
						whenToShare: payload.settings.whenToShare,
						value: payload.settings.value,
					},
				},
			};
		}

		case SET_EMISSION_CLOSURE_REPORT_FILE_INFO: {
			if (!state.current.closures || state.current.closures === 0) {
				return state;
			}

			const closures = state.current.closures;

			const lastClosure = closures[closures.length - 1];

			lastClosure.docIdList = lastClosure.docIdList.map((doc) => {
				if (doc.docId === payload.info.id) {
					return {
						...doc,
						mimetype: payload.info.mimetype,
						size: payload.info.size,
					};
				} else {
					return { ...doc };
				}
			});

			closures.pop();
			closures.push(lastClosure);

			return {
				...state,
				current: {
					...state.current,
					closures: [...closures],
				},
			};
		}

		case COMPANY_RESET_REDUCER:
			return INITIAL_STATE;

		default:
			return state;
	}
}
