import React, { PureComponent } from "react";
import { bool, func, object, node, oneOfType } from "prop-types";
import styled from "styled-components";
import Modal from "../modal/modal";

export const StyledModalContentWrapper = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
	padding-top: ${(props) => props.theme.spacing[4]};
`;

export default class SelectUserModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		modalFooterComponent: node,
		headerComponent: oneOfType([object, func]),
	};

	render = () => {
		const { children, isOpen, modalFooterComponent, headerComponent } =
			this.props;

		return (
			<Modal
				hSize="xl"
				isOpen={isOpen}
				footerComponent={modalFooterComponent}
				scrollableContent={false}
				headerComponent={headerComponent}
				marginTopInFooter
			>
				<StyledModalContentWrapper>{children}</StyledModalContentWrapper>
			</Modal>
		);
	};
}
