import {
	SUBSCRIPTION_SLIP_SET_DEPOSITORY_OPTIONS,
	SUBSCRIPTION_SLIP_SET_DISPLAY_DATA,
	SUBSCRIPTION_SLIP_SET_INSURANCE_OPTIONS,
	SUBSCRIPTION_SLIP_SET_SUBMISSION,
	SUBSCRIPTION_SLIP_LEAVE_LANDINGPAGE,
	SUBSCRIPTION_SLIP_ERROR_LANDINGPAGE,
} from "../actions/types";

const initialState = {
	displayData: { emission: {} },
	insuranceOptions: [],
	depositoryOptions: [],
	submission: {},
	isLandingPage: false,
	hasError: false,
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case SUBSCRIPTION_SLIP_SET_DISPLAY_DATA:
			return {
				...state,
				displayData: payload.displayData,
			};
		case SUBSCRIPTION_SLIP_SET_INSURANCE_OPTIONS:
			return {
				...state,
				insuranceOptions: payload.insuranceOptions,
			};
		case SUBSCRIPTION_SLIP_SET_DEPOSITORY_OPTIONS:
			return {
				...state,
				depositoryOptions: payload.depositoryOptions,
			};
		case SUBSCRIPTION_SLIP_SET_SUBMISSION:
			return {
				...state,
				submission: payload.submission,
			};
		case SUBSCRIPTION_SLIP_LEAVE_LANDINGPAGE:
			return {
				...state,
				isLandingPage: payload.isLandingPage,
			};
		case SUBSCRIPTION_SLIP_ERROR_LANDINGPAGE:
			return {
				...state,
				hasError: payload.hasError,
			};
		default:
			return state;
	}
}
