import React, { Component } from "react";
import { connect } from "react-redux";
import BreadcrumbsContainer from "./breadcrumbs.container";
import ProjectsFormContainer from "./projects-form.container";
import { withRouter } from "../../interfaces/router";

class ProjectsContainer extends Component {
	state = {
		showProjectForm: false,
		projectFormMode: "create",
		projectId: null,
		parentProjectId: null,
	};

	onCreateProject = (projectId) => {
		this.setState({
			showProjectForm: true,
			projectFormMode: "create",
			parentProjectId: projectId,
		});
	};

	onManageProject = (projectId) => {
		this.setState({
			showProjectForm: true,
			projectFormMode: "manage",
			projectId: projectId,
		});
	};

	onModalClose = () => {
		this.setState({
			showProjectForm: false,
			projectId: null,
		});
	};

	render = () => {
		const {
			basePath,
			match: { params },
		} = this.props;
		const { showProjectForm, projectFormMode, projectId, parentProjectId } =
			this.state;

		return (
			<div>
				<BreadcrumbsContainer
					basePath={basePath}
					params={params}
					onCreateProject={this.onCreateProject}
					onManageProject={this.onManageProject}
				/>
				<ProjectsFormContainer
					basePath={basePath}
					projectId={projectId}
					parentProjectId={parentProjectId}
					isOpen={showProjectForm}
					mode={projectFormMode}
					onClose={this.onModalClose}
				/>
			</div>
		);
	};
}

function mapStoreToProps() {
	return {};
}

const mapActionsToProps = {};

export default withRouter(
	connect(mapStoreToProps, mapActionsToProps)(ProjectsContainer),
);
