import React from "react";
import SubscriptionSlipErrorState from "./subscription-slip.container.error-state";
import { errorPage } from "../../actions/subscription-slip.actions";
import { connect } from "react-redux";
import { emissionConsts } from "../../../constants/emissions";

const {
	public: { emissionError },
} = emissionConsts;

class PublicSubscriptionSlipErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, reason: emissionError.unknown };
	}

	static getDerivedStateFromError(error) {
		return {
			hasError: true,
			reason: Object.values(emissionError).includes(error.message)
				? error.message
				: emissionError.unknown,
		};
	}

	render() {
		if (this.state.hasError) {
			return (
				<SubscriptionSlipErrorState
					header={`public.subscription-slip.container.error-state-header.${this.state.reason}`}
					description={`public.subscription-slip.container.error-state-desc.${this.state.reason}`}
				/>
			);
		}
		return this.props.children;
	}

	componentDidCatch() {
		this.props.errorPage();
	}
}

const mapDispatchToProps = {
	errorPage,
};

export default connect(
	null,
	mapDispatchToProps,
)(PublicSubscriptionSlipErrorBoundary);
