import React, { useEffect, useRef, useState } from "react";
import { Route, Switch, Redirect } from "../../interfaces/router";

import InvestmentTabsContainer from "./investment/investment-tabs.container";
import ScrollView from "../../dumb-components/shared/layout/scroll-view/scroll-view";

import InvestmentGeneralContainer from "./general/investment-general.container"; //General Info Tab
import InvestmentSharebookContainer from "./sharebook/investment-sharebook.container"; //Share Register Tab
import InvestmentShareholdersContainer from "./shareholders/investment-shareholders.container"; //Shareholders Tab
import InvestmentMyRecordsContainer from "./my-records/investment-my-records.container"; //Own Records Tab
import InvestmentDocumentsContainer from "./documents/investment-documents.container"; //Documents Tab
import InvestmentCapitalContainer from "./capital/investment-capital.container"; //Capital Module Tab
import InvestmentBuySellContainer from "./buy-sell/investment-buy-sell.container"; //Buy/Sell Module Tab
import EmissionContainer from "./emissions/emissions.container"; //Emission Module Tab
import InvestmentSimulationContainer from "./simulation/investment-simulation.container"; //Portfolio Simulation Tab
import InvestmentReportsContainer from "./reports/investment-reports.container"; //Not used?

import { useDispatch, useSelector } from "react-redux";
import {
	fetchMyPortfolioInvestment,
	fetchPersonalInvestment,
	resetInvestments,
	fetchShareholderPreviewPortfolioInvestment,
} from "../../actions/cap-table-details.actions";
import { getQuery } from "../../interfaces/history";
import { myInvestmentSelector } from "../../reducers/selectors/my-investment.selector";
import {
	resetCompanyReducer,
	companyChanged,
} from "../../actions/company.actions";
import { bool, object } from "prop-types";

export const InvestmentsContext = React.createContext();

const InvestmentContainer = ({
	location,
	match,
	isShareholderPreview,
	isCompany,
	inDealflow,
}) => {
	const query = getQuery(location.search);
	const { investmentId, personalInvestmentId, viaInvestmentId } = query;
	const selectedInvestment = useSelector(myInvestmentSelector);
	const selectedPersonalInvestment = useSelector((state) => {
		return state.capTableDetails.get("selectedPersonalInvestment");
	});
	const [emissionTabIndex, setEmissionTabIndex] = useState(0);
	const [currentEmissionIds, setCurrentEmissionIds] = useState({
		inviteeId: "",
		emissionId: "",
	});

	const dispatch = useDispatch();

	useEffect(() => {
		if (investmentId) {
			if (isShareholderPreview) {
				dispatch(fetchShareholderPreviewPortfolioInvestment({ investmentId }));
			} else {
				dispatch(
					fetchMyPortfolioInvestment({
						investmentId,
						viaInvestmentId,
						isCompany,
					}),
				);
			}
		}
	}, [investmentId]);

	useEffect(() => {
		if (personalInvestmentId) {
			const selectedInvestmentTransactions =
				selectedInvestment && selectedInvestment.get("transactions");
			dispatch(
				fetchPersonalInvestment(
					personalInvestmentId,
					selectedInvestmentTransactions,
				),
			);
		}
	}, [personalInvestmentId, selectedInvestment]);

	useEffect(() => {
		if (!isShareholderPreview && !isCompany) {
			dispatch(resetCompanyReducer());
			dispatch(companyChanged());
		}

		return () => {
			dispatch(resetInvestments());
		};
	}, [isShareholderPreview, isCompany]);

	// References to the scrollviews
	let scrollViewRef = useRef();

	let investedInCompanyInformation, permissions;
	if (selectedInvestment) {
		permissions = selectedInvestment.get("permissions");
		investedInCompanyInformation = selectedInvestment.get(
			"investedInCompanyInformation",
		);
	} else if (selectedPersonalInvestment) {
		permissions = selectedPersonalInvestment.get("permissions");
		investedInCompanyInformation = selectedPersonalInvestment.get(
			"investedInCompanyInformation",
		);
	}

	const Header = ({ location }) => {
		return <InvestmentTabsContainer location={location} />;
	};

	const contextValues = {
		investedInCompanyInformation,
		selectedInvestment,
		selectedPersonalInvestment,
		permissions,
		investmentId,
		isShareholderPreview,
		isCompany,
		inDealflow,
		emissionTabIndex,
		setEmissionTabIndex,
		currentEmissionIds,
		setCurrentEmissionIds,
	};

	if (personalInvestmentId && !selectedPersonalInvestment) {
		return null;
	}
	if (investmentId && !selectedInvestment) {
		return null;
	}

	return (
		<InvestmentsContext.Provider value={contextValues}>
			<ScrollView
				header={() => {
					return <Header location={location} />;
				}}
				headerBottomMargin={3}
				showOnHover={true}
				onSetRef={scrollViewRef}
				noLeftMargin={true}
				fillContent
			>
				<Switch>
					<Redirect
						exact
						from={`${match.path}/`}
						to={`${match.path}/general${location.search}`}
					/>
					<Route
						exact
						path={`${match.path}/general`}
						render={(props) => <InvestmentGeneralContainer {...props} />}
					/>
					<Route
						exact
						path={`${match.path}/my-records`}
						render={(props) => <InvestmentMyRecordsContainer {...props} />}
					/>
					<Route
						exact
						path={`${match.path}/shareholders`}
						render={(props) => <InvestmentShareholdersContainer {...props} />}
					/>
					<Route
						exact
						path={`${match.path}/documents`}
						render={(props) => <InvestmentDocumentsContainer {...props} />}
					/>
					<Route
						exact
						path={`${match.path}/sharebook`}
						render={(props) => <InvestmentSharebookContainer {...props} />}
					/>
					<Route
						exact
						path={`${match.path}/capital`}
						render={(props) => <InvestmentCapitalContainer {...props} />}
					/>
					<Route
						exact
						path={`${match.path}/reports`}
						render={(props) => <InvestmentReportsContainer {...props} />}
					/>
					<Route
						exact
						path={`${match.path}/buy-sell`}
						render={(props) => <InvestmentBuySellContainer {...props} />}
					/>
					<Route
						exact
						path={`${match.path}/simulation`}
						render={(props) => <InvestmentSimulationContainer {...props} />}
					/>
					<Route
						exact
						path={`${match.path}/emission`}
						render={() => <EmissionContainer />}
					/>
				</Switch>
			</ScrollView>
		</InvestmentsContext.Provider>
	);
};

InvestmentContainer.propTypes = {
	location: object,
	match: object,
	isShareholderPreview: bool,
	inDealflow: bool,
};

InvestmentContainer.defaultProps = {};

export default InvestmentContainer;
