import req from "../modules/request.module";
import {
	INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS,
	INVESTORS_FETCH_MY_INVESTMENTS,
	INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS_MAP,
	INVESTORS_CAPITAL_INSURANCE_UPDATE_MY_INVESTMENTS_MAP,
	INVESTORS_COMPANY_UPDATE_MY_INVESTMENTS_MAP,
	INVESTORS_COUNT_ACTIVE,
} from "../actions/types";
import { fromJS } from "immutable";
import { addErrorNotification } from "./notify.actions";
import uuid from "uuid";

export function addPersonalInvestment(orgNumber) {
	return function (dispatch) {
		return req
			.post(`/shares/personal/investment`, { orgNumber })
			.then((response) => {
				dispatch({
					type: INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS,
					payload: fromJS(response.data),
				});
				dispatch({
					type: INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS_MAP,
					payload: fromJS(response.data),
				});
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function fetchAllMyInvestments(callback) {
	return function (dispatch) {
		return req
			.get(`/shares/personal/investments/all?companyInvestments=true`)
			.then((response) => {
				dispatch({
					type: INVESTORS_FETCH_MY_INVESTMENTS,
					payload: fromJS(response.data),
				});
				callback && callback();
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function addPersonalInvestmentCompany(
	primaryOrgNumber,
	secondaryOrgNumber,
) {
	return function (dispatch) {
		return req
			.post(`/shares/personal/investment/company`, {
				primaryOrgNumber,
				secondaryOrgNumber,
			})
			.then((response) => {
				dispatch({
					type: INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS,
					payload: fromJS(response.data),
				});
				dispatch({
					type: INVESTORS_COMPANY_UPDATE_MY_INVESTMENTS_MAP,
					payload: fromJS(response.data),
				});
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function addPersonalInvestmentCapitalInsurance(
	capitalInsuranceCompanyOrgNumber,
	insuranceNr,
) {
	return function (dispatch) {
		return req
			.post(`/shares/personal/investment/via-capital-insurance`, {
				capitalInsuranceCompanyOrgNumber,
				insuranceNr,
			})
			.then((response) => {
				dispatch({
					type: INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS,
					payload: fromJS(response.data),
				});
				dispatch({
					type: INVESTORS_CAPITAL_INSURANCE_UPDATE_MY_INVESTMENTS_MAP,
					payload: fromJS(response.data),
				});
			})
			.catch(() => {});
	};
}

export function convertDealflowToPrivatePersonalInvestment(id, orgNumber) {
	return async function (dispatch) {
		try {
			const response = await req.put(
				`/shares/personal/investment/swap/${id}/private`,
				{ orgNumber },
			);
			dispatch({
				type: INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS,
				payload: fromJS(response.data),
			});
			dispatch({
				type: INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS_MAP,
				payload: fromJS(response.data),
			});
		} catch (error) {
			console.log(error);
			dispatch(
				addErrorNotification({
					tid: "investment.errors.swap_dealflow_to_personal_investment",
				}),
			);
		}
	};
}

export function convertDealflowToCapitalInsurancePersonalInvestment(
	id,
	capitalInsuranceCompanyOrgNumber,
	insuranceNr,
) {
	return async function (dispatch) {
		try {
			const response = await req.put(
				`/shares/personal/investment/swap/${id}/capital-insurance`,
				{
					capitalInsuranceCompanyOrgNumber,
					insuranceNr,
				},
			);
			dispatch({
				type: INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS,
				payload: fromJS(response.data),
			});
			dispatch({
				type: INVESTORS_CAPITAL_INSURANCE_UPDATE_MY_INVESTMENTS_MAP,
				payload: fromJS(response.data),
			});
		} catch (error) {
			console.log(error);
			dispatch(
				addErrorNotification({
					tid: "investment.errors.swap_dealflow_to_personal_investment",
				}),
			);
		}
	};
}

/**
 *
 * @param {object} values
 * @param {string} values.telephone
 * @param {uuid} id
 */
export function patchContactInformation(values, id) {
	return async function (dispatch) {
		try {
			await req.patch(`/shares/company/investments/${id}`, values);
		} catch (error) {
			dispatch(
				addErrorNotification({
					tid: "investment.error.update_investment_information",
				}),
			);
		}
	};
}

export function addCompanyInvestmentContact(contact, id, callback) {
	if (typeof contact.id === "undefined") {
		contact.id = uuid.v4();
	}

	return async function (dispatch) {
		try {
			await req.post(`/shares/company/investments/${id}/contact`, contact);
			typeof callback === "function" && callback(contact);
		} catch (error) {
			dispatch(
				addErrorNotification({
					tid:
						error?.response?.data?.tid ??
						"investment.error.add_company_investment_contact",
				}),
			);
		}
	};
}

export function editCompanyInvestmentContact(contact, contactId, investmentId) {
	return async function (dispatch) {
		try {
			await req.patch(
				`/shares/company/investments/${investmentId}/contact/${contactId}`,
				contact,
			);
		} catch (error) {
			dispatch(
				addErrorNotification({
					tid: "investment.error.edit_company_investment_contact",
				}),
			);
		}
	};
}

export function countActiveInvestments() {
	return async function (dispatch) {
		try {
			const response = await req.get(
				"/shares/company/investments/count/active",
			);
			dispatch({
				type: INVESTORS_COUNT_ACTIVE,
				payload: response.data.numOfActiveShareholders,
			});
		} catch (error) {
			dispatch(
				addErrorNotification({
					tid: "investment.error.count_active_investments",
				}),
			);
		}
	};
}
