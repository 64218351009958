import React, { PureComponent } from "react";
import immutablePropTypes from "react-immutable-proptypes";
import { func, string, object } from "prop-types";
import styled, { css } from "styled-components";
import { Padding } from "styled-components-spacing";
import {
	isMeetingToday,
	isAttendeeModerator,
} from "../../../components/helpers/meeting.helper";
import List from "../../shared/list/list";
import ListItem from "../../shared/list-item/list-item";
import Text from "../../shared/text/text";
import Button from "../../shared/button/button";
import moment from "../../../modules/moment.module";
import { StyledListHeaderTemplate } from "../../shared/list/list-header.template";
import Panel from "../../shared/panel/panel";
import ShowMoreBox from "../../../components/framework/show-more-box";
import { Select } from "../../shared/select";
import { fromJS } from "immutable";
import { MEETING_TYPE_STANDARD, MEETING_TYPE_SMART } from "/shared/constants";
import permissionsHelper from "../../../components/helpers/permissions.helper";
import history from "../../../interfaces/history";
import { Presentation } from "lucide-react";

const EllipsisTemplate = css`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const TemplateDateWidthCss = css`
	width: 140px;
`;

const TemplateMeetingTypeWidthCss = css`
	${EllipsisTemplate};
	width: 160px;

	@media (max-width: 1200px) {
		display: none;
	}
`;

const TemplateMeetingNameWidthCss = css`
	${EllipsisTemplate};
	flex: 1;
`;

const TemplateSecretaryWidthCss = css`
	${EllipsisTemplate};
	width: 170px;
`;

const TemplateCompanyNameWidthCss = css`
	${EllipsisTemplate};
	width: 170px;
`;

const TemplateOpenButtonWidthCss = css`
	width: 80px;
	display: flex;
	justify-content: flex-end;
`;

const StyledFilterWrapper = styled.div`
	width: 200px;
`;

const StyledHeader = styled.div`
	${StyledListHeaderTemplate};
	border-left: ${(props) =>
		props.isCanceled
			? `${props.theme.spacing[3]} solid ${props.theme.colors.persianRed}`
			: `${props.theme.spacing[3]} solid transparent`};
`;

const StyledListItem = styled(ListItem)`
	align-items: center;
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeLight};
	margin-bottom: 0px;
	border-left-width: ${(props) => props.theme.spacing[3]};
	border-left-style: solid;
	border-left-color: ${(props) => {
		if (props.meetingIsToday) {
			return props.theme.colors.java;
		} else {
			return "transparent";
		}
	}};
`;

// List Items
const StyledDate = styled.div`
	${TemplateDateWidthCss};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledInfo = styled.div`
	width: 100%;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledMeetingType = styled.div`
	${TemplateMeetingTypeWidthCss};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledMeetingName = styled.div`
	${TemplateMeetingNameWidthCss};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledSecretaryName = styled.div`
	${TemplateSecretaryWidthCss};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledCompanyName = styled.div`
	${TemplateCompanyNameWidthCss};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledButtonWrapper = styled.div`
	${TemplateOpenButtonWidthCss};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

class MeetingListByCompanies extends PureComponent {
	static propTypes = {
		meetings: immutablePropTypes.list,
		companiesFilterComponent: func,
		pagerController: func,
		userId: string,
		companiesAccessRights: object,
	};

	openMeeting = (meeting) => {
		const { userId, companiesAccessRights } = this.props;
		const groupId = meeting.get("groupId");
		const companyId = meeting.getIn(["companyInformation", "id"]);
		const moderators = meeting.get("moderators");
		const attendeeIsModerator = isAttendeeModerator(userId, moderators);
		const isStandardMeeting =
			meeting.get("meetingType") === MEETING_TYPE_STANDARD;
		const isSmartMeeting = meeting.get("meetingType") === MEETING_TYPE_SMART;
		const meetingCreator = meeting.get("createdBy") === userId;
		const isAdmin = permissionsHelper.getIsAdminForSelectedCompany(
			companiesAccessRights,
			companyId,
		);
		const attendeeIsMember = meeting.get("attendeeIsMember");
		let url;

		if (
			isStandardMeeting &&
			!attendeeIsModerator &&
			!meetingCreator &&
			!isAdmin
		) {
			url = meeting.get("publicUrl");
			window.open(url, "_blank");
			return;
		}

		if (isSmartMeeting && !attendeeIsMember && !meetingCreator && !isAdmin) {
			url = meeting.get("publicUrl");
			window.open(url, "_blank");
			return;
		}

		if (isStandardMeeting) {
			url = `/${meeting.getIn([
				"companyInformation",
				"urlAlias",
			])}/meetings/${meeting.get("id")}/${groupId ? `?group=${groupId}` : ""}`;
		} else {
			url = `/${meeting.getIn([
				"companyInformation",
				"urlAlias",
			])}/meetings/${meeting.get("id")}/meeting/agenda/${
				groupId ? `?group=${groupId}` : ""
			}`;
		}

		history.push(url);
	};

	renderHeader = () => {
		return (
			<StyledHeader>
				<StyledDate>
					<Text tid="generic.date_time" bold={400} color="lightGrey" />
				</StyledDate>
				<StyledMeetingName>
					<Text
						tid="dashboard.meetings.header.meeting_name"
						bold={400}
						color="lightGrey"
					/>
				</StyledMeetingName>
				<StyledMeetingType>
					<Text tid="generic.meeting_type" bold={400} color="lightGrey" />
				</StyledMeetingType>
				<StyledSecretaryName>
					<Text
						tid="dashboard.meetings.header.secretary"
						bold={400}
						color="lightGrey"
					/>
				</StyledSecretaryName>
				<StyledCompanyName>
					<Text tid="generic.company" bold={400} color="lightGrey" />
				</StyledCompanyName>
				<StyledButtonWrapper />
			</StyledHeader>
		);
	};

	renderMeeting = (meeting, index) => {
		const { usersCache } = this.props;
		let startDate = "";
		const meetingNameText = meeting.get("name");
		const meetingCompanyNameText = meeting.getIn([
			"companyInformation",
			"name",
		]);
		const meetingSecretaryId = meeting.get("secretary");
		const meetingSecretaryText =
			meetingSecretaryId &&
			usersCache &&
			usersCache.getIn([meetingSecretaryId, "name"]);
		const meetingIsToday = isMeetingToday(meeting);
		const isStandardMeeting =
			meeting.get("meetingType") === MEETING_TYPE_STANDARD;

		if (meeting.get("startDate")) {
			const date = moment(meeting.get("startDate"));
			startDate = date.format("ll, LT");
		}

		return (
			<StyledListItem
				key={index}
				meetingIsToday={meetingIsToday}
				hoverColor="lightestGrey"
				striped
			>
				<StyledDate>
					<Text singleLine={true}>{startDate}</Text>
				</StyledDate>
				<StyledMeetingName>
					<Text singleLine={true}>{meetingNameText}</Text>
				</StyledMeetingName>
				{!isStandardMeeting && (
					<StyledMeetingType>
						<Text singleLine={true}>{meeting.get("templateName")}</Text>
					</StyledMeetingType>
				)}
				{isStandardMeeting && (
					<StyledMeetingType>
						<Text
							tid="meetings.standard.general.type.standard"
							singleLine={true}
						/>
					</StyledMeetingType>
				)}
				<StyledSecretaryName>
					<Text singleLine={true}>{meetingSecretaryText}</Text>
				</StyledSecretaryName>
				<StyledCompanyName>
					<Text singleLine={true}>{meetingCompanyNameText}</Text>
				</StyledCompanyName>
				<StyledButtonWrapper>
					<Button
						tid="generic.open"
						onClick={this.openMeeting.bind(this, meeting)}
						minWidth="80px"
					/>
				</StyledButtonWrapper>
			</StyledListItem>
		);
	};

	renderEmptyMeeting = () => {
		return (
			<StyledListItem striped>
				<StyledInfo>
					{" "}
					<Text
						color="muted"
						tid="meeting.dashboard.upcoming_available"
					></Text>{" "}
				</StyledInfo>
			</StyledListItem>
		);
	};

	renderDateFilter = () => {
		const { onFilter, selectedFilter } = this.props;
		const filterValues = [
			{ label: "dashboard.block.filter.label.today", value: "today" },
			{ label: "dashboard.block.filter.label.7d", value: "7d" },
			{ label: "dashboard.block.filter.label.30d", value: "30d" },
			{ label: "dashboard.block.filter.label.90d", value: "90d" },
			{ label: "dashboard.block.filter.label.show_all", value: "show_all" },
		];

		return (
			<StyledFilterWrapper>
				<Select
					options={fromJS(filterValues)}
					onChange={onFilter && onFilter}
					labelIsTid={true}
					value={selectedFilter}
					isSearchable={false}
				/>
			</StyledFilterWrapper>
		);
	};

	render = () => {
		const { meetings, pagerController } = this.props;

		return (
			<Panel
				icon={<Presentation />}
				title="service.user_dashboard.meetings"
				mode="light"
				footerComponent={pagerController}
				rightComponent={this.renderDateFilter}
				marginBottom
			>
				<Padding all={4}>
					<List header={this.renderHeader}>
						{meetings && meetings.size > 0 && (
							<ShowMoreBox closedHeight={191} btnMarginTop={32}>
								{meetings.map(this.renderMeeting)}
							</ShowMoreBox>
						)}
						{(!meetings || meetings.size === 0) && this.renderEmptyMeeting()}
					</List>
				</Padding>
			</Panel>
		);
	};
}

export default MeetingListByCompanies;
