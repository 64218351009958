import React, { Component } from "react";
import { connect } from "react-redux";
import { object } from "prop-types";
import { deleteMeeting } from "../../../../../actions/meetings.actions";
import { hideScreenMessage } from "../../../../../actions/screen-message.actions";

import Tooltip from "../../../../../dumb-components/shared/tooltip/tooltip";
import DropdownIconItem from "../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import history from "../../../../../interfaces/history";

import { MEETING_TYPE_STANDARD } from "/shared/constants";
import { makeUrlRelativeToCompany } from "../../../../../components/helpers/link.helper";

const TRASH_MEETING_TOOLTIP_STATES = {
	default: {
		tid: "meetings.toolbar.tooltip.trash_meeting",
	},
};

class DeleteMeetingDropdownItemContainer extends Component {
	static propTypes = {
		dropdownRef: object,
	};

	deleteMeeting = () => {
		const {
			deleteMeeting,
			meetingId,
			meetingType,
			dropdownRef,
			hideScreenMessage,
		} = this.props;

		dropdownRef && dropdownRef.onToggleMenu();

		deleteMeeting(meetingId, meetingType, this.unselectMeetingInList);
		hideScreenMessage();
	};

	unselectMeetingInList = () => {
		const url = makeUrlRelativeToCompany("meetings");
		history.push(url);
	};

	render = () => {
		const { canBeDeleted, isMeeting, isStandardMeeting } = this.props;
		const activeState = canBeDeleted ? "default" : "hasNoPermissions";
		const deleteTid =
			isMeeting || isStandardMeeting
				? "meeting.general.toolbar.option.delete_meeting"
				: "meeting.general.toolbar.option.delete_protocol";

		return (
			<Tooltip activeState={activeState} states={TRASH_MEETING_TOOLTIP_STATES}>
				<DropdownIconItem
					onClick={this.deleteMeeting}
					disabled={!canBeDeleted}
					icon="faTrashAlt"
					tid={deleteTid}
				/>
			</Tooltip>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meetingId: store.meetings.getIn(["meeting", "id"]),
		meetingType: store.meetings.getIn(["meeting", "meetingType"]),
		isMeeting: store.meetings
			.getIn(["meeting", "computedValues", "goToSection"], "")
			.startsWith("meeting"),
		isStandardMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) ===
			MEETING_TYPE_STANDARD,
		canBeDeleted: store.meetings.getIn(
			["meeting", "computedValues", "canBeDeleted"],
			false,
		),
	};
};

const mapActionsToProps = {
	deleteMeeting,
	hideScreenMessage,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(DeleteMeetingDropdownItemContainer);
