export const headerTableCellSx = {
	borderBottom: "none",
	pb: 1,
};

export const bodyTableCellSx = {
	borderBottom: "none",
	pt: 1,
	pb: 1,
};

export const footerTableCellSx = {
	borderBottom: "none",
	borderTop: "1px solid rgba(224, 224, 224, 1)",
	pt: 1,
};

export const helpTextSx = {
	maxWidth: "fit-content",
	padding: "0 32px 32px 32px",
};
