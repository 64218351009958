import React, { PureComponent } from "react";
import { List } from "immutable";
import { bool, func, oneOf, string } from "prop-types";
import styled from "styled-components";
import { Margin } from "styled-components-spacing";
import Text from "../../shared/text/text";
import Button from "../../shared/button/button";
import Panel from "../../shared/panel/panel";
import Label from "../../shared/label/label";
import { MultiSelect } from "../select";
import ToggleSwitch from "../../shared/toggle-switch/toggle-switch";
import ImmutableProps from "react-immutable-proptypes";
import uuid from "uuid";
import { Lock } from "lucide-react";

const StyledLabelInput = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;

	> :not(:first-child):not(:last-child) {
		margin-left: ${(props) => props.theme.spacing[4]};
	}

	> :last-child {
		margin-left: ${(props) => props.theme.spacing[2]};
	}
`;

const StyledLeftInput = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;

	> :not(:first-child) {
		margin-left: ${(props) => props.theme.spacing[2]};
	}
`;

const StyledBoxPadding = styled.div`
	padding: ${(props) =>
		props.panelType === "inline" || props.panelType === "transparent"
			? "0px"
			: props.theme.spacing[4]};
`;

class ObjectPermissions extends PureComponent {
	static propTypes = {
		roleOptions: ImmutableProps.list,
		usersOptions: ImmutableProps.list,
		roleValues: ImmutableProps.list,
		usersValues: ImmutableProps.list,
		onRolesChange: func,
		onUsersChange: func,
		onResetDefaultsRoles: func,
		onClear: func,
		onToggleRestricted: func,
		isRestricted: bool,
		panelType: oneOf(["inline", "transparent"]),
		selected: bool,
		infoTextTid: string,
		noTitle: bool,
		marginBottom: bool,
	};

	static defaultProps = {
		roleOptions: List(),
		usersOptions: List(),
		roleValues: List(),
		usersValues: List(),
		isRestricted: false,
	};

	noOptionsUsersMessage = () => {
		return <Text tid="documents.userPermissions.no_users" />;
	};

	noOptionsRolesMessage = () => {
		return <Text tid="documents.userPermissions.no_roles" />;
	};

	render = () => {
		const {
			roleOptions,
			usersOptions,
			roleValues,
			selected,
			usersValues,
			onRolesChange,
			onUsersChange,
			onResetDefaultsRoles,
			onClear,
			onToggleRestricted,
			isRestricted,
			panelType,
			infoTextTid,
			noTitle,
			marginBottom,
		} = this.props;

		return (
			<Panel
				icon={<Lock />}
				title={
					noTitle
						? ""
						: "documents.permissions.manage_permissions.user_permissions"
				}
				mode={
					panelType === "inline"
						? "light"
						: panelType === "transparent"
						? "transparent"
						: null
				}
				type={panelType}
				id={uuid()}
				ref={(node) => (this._panel = node)}
				active={selected}
				marginBottom={marginBottom}
			>
				<StyledBoxPadding>
					<Label
						rightComponent={() => {
							return (
								<StyledLabelInput>
									<Button
										onClick={onResetDefaultsRoles}
										mode="link"
										tid="documents.permissions.manage_permissions.reset_to_defaults"
										disabled={isRestricted}
									/>
									<Text
										color="muted"
										tid="documents.permissions.manage_permissions.lock"
									/>
									<ToggleSwitch
										size="sm"
										onChange={onToggleRestricted}
										checked={isRestricted}
										removeWrapperHeight={true}
									/>
								</StyledLabelInput>
							);
						}}
						leftComponent={() => {
							return (
								<StyledLeftInput>
									<Text tid="documents.permissions.manage_permissions.by_team" />
								</StyledLeftInput>
							);
						}}
					>
						<Margin bottom={4}>
							<MultiSelect
								noOptionsMessage={this.noOptionsRolesMessage}
								options={roleOptions}
								value={roleValues}
								placeholderTid="documents.permissions.manage_permissions.by_team.placeholder"
								onChange={onRolesChange}
								isDisabled={isRestricted}
							/>
						</Margin>
						<Margin bottom={4}>
							<Text tid={infoTextTid} />
						</Margin>
					</Label>
					<Label
						rightComponent={() => {
							return (
								<StyledLabelInput>
									<Button
										onClick={onClear}
										mode="link"
										tid="documents.permissions.manage_permissions.reset_to_defaults"
										disabled={isRestricted}
									/>
								</StyledLabelInput>
							);
						}}
						tid="documents.permissions.manage_permissions.by_user"
					>
						<>
							<Margin bottom={4}>
								<MultiSelect
									noOptionsMessage={this.noOptionsUsersMessage}
									options={usersOptions}
									value={usersValues}
									onChange={onUsersChange}
									placeholderTid="documents.permissions.manage_permissions.by_user.placeholder"
									isDisabled={isRestricted}
								/>
							</Margin>
							<Text tid="documents.create_folder.permissions.member-access.info" />
						</>
					</Label>
				</StyledBoxPadding>
			</Panel>
		);
	};
}

export default ObjectPermissions;
