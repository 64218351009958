import React, { useCallback, useContext } from "react";
import { useForm, useFormState } from "react-final-form";
import { InvestmentsContext } from "../../../../containers/investments/investment.container";
import { linkedTransactionPopulateInitialValues } from "../../../../components/helpers/my-records-helpers";

import Box from "@mui/material/Box";
import { DropDown } from "../../../../../app/mui-components/dropdown/DropDown";
import IconButton from "../../../../../app/mui-components/button/icon-button";
import Tooltip from "../../../../mui-components/tooltip/tooltip";
import MenuItemIcon from "../../../../mui-components/dropdown/menu/menu-item-icon";

const MoreActionRenderer = ({ props, previewTransactionCallback }) => {
	const { selectedInvestment } = useContext(InvestmentsContext);

	const form = useForm();
	const formState = useFormState();

	const selectedInvestmentTransactions = selectedInvestment.get("transactions");
	const userId = selectedInvestment.get("id");
	const { data, node } = props;
	const { linkButton } = data;
	const { link } = linkButton;
	const { transactionId, isDirty } = link;

	const _previewTransactionCallback = useCallback(() => {
		previewTransactionCallback(
			props.data.linkButton.id,
			props,
			formState.values.transactionType,
		);
	});

	return (
		<Box>
			<DropDown
				alignMenu="right"
				button={({ params }) => (
					<IconButton noBorder icon="faEllipsisV" {...params} />
				)}
			>
				<Tooltip
					title={
						"investments.my_records.modal.add_transaction.company_transactions.dropdown.menu_item.link.disabled"
					}
					hideTooltip={!transactionId}
				>
					<MenuItemIcon
						disabled={transactionId && !isDirty}
						onClick={() => {
							const allItemsSelector = document.querySelectorAll(
								".link-transaction-row",
							);
							const itemSelector = allItemsSelector[node.id];
							const viewportEl = itemSelector.closest(".ag-body-viewport");
							const rowEl = itemSelector.closest(".ag-row");
							const offsetTop = parseInt(rowEl.style.top); //+ rowEl.offsetHeight
							setTimeout(() => viewportEl.scrollTo(0, offsetTop));

							linkedTransactionPopulateInitialValues(
								linkButton,
								form,
								selectedInvestmentTransactions,
								userId,
							);

							// This was commented out, in order to remove a warning. Don't know what for this was used.
							//node.setDataValue('linkButton', {
							//	id: id,
							//	link: {
							//		transactionId: transactionId && transactionId,
							//		isDirty: isDirty
							//	}
							//})

							// This check seems to be necessary to make form dirty. Which is used to define if save button should be muted or not
							if (!form.getFieldState("link")) {
								form.registerField("link", (fieldState) => {
									const { change } = fieldState;

									change({
										transactionId: form.getState().values.link.transactionId,
										isDirty: form.getState().values.link.isDirty,
									});
								});
							}
						}}
						icon={isDirty ? "faRetweet" : "faLink"}
						iconType={isDirty ? "light" : transactionId ? "solid" : "light"}
						listItemTid={
							isDirty
								? "investments.my_records.add_transaction.company_transactions.dropdown.item.relink"
								: "investments.my_records.add_transaction.company_transactions.dropdown.item.link"
						}
					/>
				</Tooltip>

				<MenuItemIcon
					onClick={() => {
						_previewTransactionCallback();
					}}
					icon={"faEye"}
					iconType={"light"}
					listItemTid={
						"investments.my_records.add_transaction.company_transactions.dropdown.item.preview"
					}
				/>
			</DropDown>
		</Box>
	);
};

export default MoreActionRenderer;
