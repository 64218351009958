import React, { Component } from "react";
import { string, bool, func, object, oneOf, oneOfType } from "prop-types";
import { isImmutable, fromJS } from "immutable";
import {
	EditorState,
	convertFromRaw,
	convertToRaw,
	getVisibleSelectionRect,
} from "draft-js";
import Editor from "../../dumb-components/shared/editor/editor";

// Docs: https://jpuri.github.io/react-draft-wysiwyg/#/docs

const TOOLBAR_HEIGHT = 40;

export default class EditorContainer extends Component {
	state = {
		isFullscreen: false,
		editorState: EditorState.createEmpty(),
		isFocused: false,
		toolbarHidden: false,
		position: {},
	};

	static propTypes = {
		fieldName: string,
		objId: string,
		contentState: oneOfType([object, string]),
		onChange: func,
		mode: oneOf(["notes"]),
		editorHeight: string,
		readOnly: bool,
		placeholderTid: string,
		borderless: bool,
		inline: bool,
		includePadding: bool,
	};

	editorRef = null;
	wrapperRef = null;

	parseContentState = (contentState) => {
		try {
			contentState = isImmutable(contentState)
				? contentState.toJS()
				: contentState;
			if (typeof contentState === "string" && contentState.length > 1) {
				contentState = JSON.parse(contentState);
			}

			if (contentState) {
				contentState = convertFromRaw(contentState);
				this.setState({
					editorState: EditorState.createWithContent(contentState),
				});
			} else {
				this.setState({ editorState: EditorState.createEmpty() }); // Reset state when switching between views where editor appears
			}
		} catch (e) {
			console.log(e);
			this.setState({ editorState: EditorState.createEmpty() }); // Reset state when switching between views where editor appears
		}
	};

	componentDidMount = () => {
		const { contentState, inline } = this.props;
		if (contentState) {
			this.parseContentState(contentState);
		}

		if (inline) {
			this.setState({ toolbarHidden: true });
		}
	};

	componentDidUpdate = (prevProps) => {
		const { contentState, objId } = this.props;
		if (objId !== prevProps.objId) {
			this.parseContentState(contentState);
			this.setState({ isFocused: false });
		}

		if (contentState !== prevProps.contentState && !this.state.isFocused) {
			if (contentState) {
				this.parseContentState(contentState);
			}
		}
	};

	componentWillUnmount = () => {
		// const {onChange, fieldName} = this.props;
		// const {editorState} = this.state;
		//
		// if (fieldName) {
		// 	onChange(fieldName, convertToRaw(editorState.getCurrentContent()));
		// 	return;
		// } else {
		// 	onChange(convertToRaw(editorState.getCurrentContent()));
		// }
	};

	onSelectionChanged = () => {
		setTimeout(() => {
			let editorNode =
				this.editorRef && this.editorRef.editor && this.editorRef.editor.editor;

			if (!editorNode) {
				return;
			}

			while (!editorNode.className.includes("DraftEditor-root")) {
				editorNode = editorNode.parentNode;
			}

			const editorNodeRect = editorNode.getBoundingClientRect();
			const selectionRect = getVisibleSelectionRect(window);

			if (!selectionRect) {
				return;
			}

			// The toolbar shouldn't be positioned directly on top of the selected text,
			// but rather with a small offset so the caret doesn't overlap with the text.
			const extraTopOffset = -5;

			const toolbar =
				this.wrapperRef && this.wrapperRef.querySelector(".rdw-editor-toolbar");
			let top =
				editorNode.offsetTop -
				TOOLBAR_HEIGHT +
				(selectionRect.top - editorNodeRect.top) +
				extraTopOffset;
			let left =
				editorNode.offsetLeft +
				(selectionRect.left - editorNodeRect.left) +
				selectionRect.width / 2 -
				toolbar.offsetWidth / 2;
			let right;

			// Check so that the toolbar fits inside editor area vertically, if not it will not be fully viewed because of overflow-hidden
			if (top < 0) {
				top =
					TOOLBAR_HEIGHT / 2 +
					(selectionRect.top - editorNodeRect.top) -
					extraTopOffset;
			}

			// Check so that the toolbar fits inside editor area horizontally, if not it will not be fully viewed because of overflow-hidden
			if (left < 0) {
				left = 0;
			} else if (left > editorNode.offsetWidth - toolbar.offsetWidth) {
				left = undefined;
				right = 0;
			}

			const position = { top, left, right };

			this.setState({ position, toolbarHidden: false });
		});
	};

	onChange = (editorState) => {
		const { inline } = this.props;

		this.setState((prevState) => {
			const state = { editorState };
			const isCollapsed = editorState.getSelection().isCollapsed();

			if (
				inline &&
				prevState.isFocused &&
				!prevState.toolbarHidden &&
				isCollapsed
			) {
				state.toolbarHidden = true;
			} else if (
				inline &&
				prevState.isFocused &&
				prevState.toolbarHidden &&
				!isCollapsed
			) {
				this.onSelectionChanged();
			}

			return state;
		});
	};

	onBlur = (event, editorState) => {
		const { onChange, fieldName, contentState, inline } = this.props;
		const convertedValue = convertToRaw(editorState.getCurrentContent());
		const valueIsImmutable = isImmutable(contentState);

		if (fieldName) {
			onChange(
				fieldName,
				valueIsImmutable ? fromJS(convertedValue) : convertedValue,
			);
			this.setState((prevState) => {
				const state = {};

				if (inline && prevState.toolbarHidden === false) {
					state.toolbarHidden = true;
				}

				return state;
			});
			return;
		} else {
			onChange(valueIsImmutable ? fromJS(convertedValue) : convertedValue);
		}
		this.setState((prevState) => {
			const state = { isFocused: false };

			if (inline && prevState.toolbarHidden === false) {
				state.toolbarHidden = true;
			}

			return state;
		});
	};

	toggleFullscreen = () => {
		this.setState((prevState) => ({
			isFullscreen: !prevState.isFullscreen,
		}));
	};

	onFocus = () => {
		this.setState(() => {
			return { isFocused: true };
		});
	};

	setEditorRef = (ref) => {
		this.editorRef = ref;
	};

	setWrapperRef = (ref) => {
		this.wrapperRef = ref;
	};

	showToolbar = (e) => {
		e.preventDefault();
		this.onSelectionChanged();
	};

	render = () => {
		const { isFullscreen, editorState, toolbarHidden, position } = this.state;
		const {
			mode,
			editorHeight,
			readOnly,
			placeholderTid,
			borderless,
			inline,
			includePadding,
		} = this.props;

		return (
			<Editor
				mode={mode}
				editorState={editorState}
				onChange={this.onChange}
				onBlur={this.onBlur}
				isFullscreen={isFullscreen}
				toggleFullscreen={this.toggleFullscreen}
				onFocus={this.onFocus}
				editorHeight={editorHeight}
				readOnly={readOnly}
				placeholderTid={placeholderTid}
				borderless={borderless}
				toolbarHidden={!isFullscreen && toolbarHidden}
				inline={!isFullscreen && inline}
				setEditorRef={this.setEditorRef}
				setWrapperRef={this.setWrapperRef}
				editorRef={this.editorRef}
				wrapperRef={this.wrapperRef}
				onShowToolbar={this.showToolbar}
				toolbarPosition={position}
				toolbarHeight={TOOLBAR_HEIGHT}
				includePadding={includePadding}
			/>
		);
	};
}
