import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { getFullShareTypeLabel } from "../../../helpers/shares";
import Moment from "../../../../modules/moment.module";
import Panel from "../../../../dumb-components/panel";

class ChangeOfReservationView extends Component {
	componentDidMount = () => {};

	renderShareType = (shareType, index) => {
		const shareTypeName = getFullShareTypeLabel(shareType.get("type"));
		const updatedTransactionLimitations = shareType.get(
			"updatedTransactionLimitations",
		);

		return (
			<div className="list__item" key={index}>
				<div className="list__item__body">
					<span className="list__item__text">{shareTypeName}</span>
				</div>
				<div className="list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right">
					{updatedTransactionLimitations &&
						Moment(updatedTransactionLimitations).format("L")}
				</div>
			</div>
		);
	};

	render = () => {
		const { transaction } = this.props;

		if (!transaction) {
			return null;
		}

		return (
			<Panel padHor className="i-panel--white">
				<div>
					<div className="list__list-header">
						<div className="list__list-header__body list__list-header__text-area--pad-lft list__list-header__text-area--pad-right">
							<FormattedMessage id="shares.transactions.change_of_reservation.class_of_shares" />
						</div>
						<div className="list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right">
							<FormattedMessage id="shares.transactions.change_of_reservation.last_changed" />
						</div>
					</div>
					<div className="list list--table list--striped">
						{transaction
							.getIn(["shareData", "types"], [])
							.map(this.renderShareType)}
					</div>
				</div>
				<FormattedHTMLMessage id="shares.transactions.change_of_reservation.information" />
			</Panel>
		);
	};
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get("transaction"),
		i18n: state.i18n,
	};
}
const mapActionsToProps = {};

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(ChangeOfReservationView);
