import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import { Margin } from "styled-components-spacing";
import moment from "../../../modules/moment.module";
import {
	fetchMeeting,
	attendeesChanged,
} from "../../../actions/meetings.actions";
import { updateSelectedGroupId } from "../../../actions/groups.actions";
import {
	showScreenMessage,
	hideScreenMessage,
} from "../../../actions/screen-message.actions";
import { listCustomMeetingFunctions } from "../../../actions/custom-meeting-functions.actions";
import {
	isMeetingToday,
	hasMeetingStarted,
} from "../../../components/helpers/meeting.helper";
import Content from "../../../dumb-components/shared/layout/content/content";
import Breadcrumbs from "../../../dumb-components/shared/breadcrumbs/breadcrumbs";
import BreadcrumbsItem from "../../../dumb-components/shared/breadcrumbs/breadcrumbs-item";
import Text from "../../../dumb-components/shared/text/text";
import OpenMeetingModalContainer from "../agenda/open-meeting-modal.container";
import {
	MEETING_TYPE_STANDARD,
	MEETING_STATUS_PROTOCOL_NEEDS_SIGNING,
	EVENT_TYPE_MEETINGS_UPDATE,
	MEETING_STATUS_MEETING_STARTED,
} from "/shared/constants";
import {
	LIVE_MEETINGS_DELETE,
	LIVE_MEETINGS_OPEN,
	LIVE_MEETINGS_ATTENDEE_REMOVE,
	LIVE_MEETINGS_PROTOCOL_PUBLISHED,
	LIVE_MEETINGS_AGENDA_UPDATE,
} from "../../../constants/live-update";
import history, { getQuery } from "../../../interfaces/history";

// Meeting
import MeetingInformationContainer from "../meeting-information/meeting-information.container";
import MeetingAgendaContainer from "../agenda/meeting-agenda.container";
import MeetingAttendeesContainer from "../attendees/meeting-attendees.container";
import MeetingDocumentsContainer from "../documents/meeting-documents.container";
import MeetingTasksContainer from "../tasks/meetings-tasks.container";

// Protocol
import ProtocolAttendeesContainer from "../attendees/protocol-attendees.container";
import ProtocolReviewContainer from "../protocol-review/protocol-review.container";
import ProtocolInformationContainer from "../protocol-information/protocol-information.container";
import ProtocolFeedbackContainer from "../protocol-feedback/protocol-feedback.container";
import SmartMeetingsProtocolEsigningTabContainer from "../protocol-esigning/smart-meeting-protocol-tab.container";
import ProtocolDocumentsContainer from "../documents/protocol-documents.container";
import ProtocolTasksContainer from "../tasks/protocol-tasks.container";
import { Switch, Route, Redirect } from "../../../interfaces/router";

class MeetingContainer extends Component {
	componentDidMount = () => {
		const {
			fetchMeeting,
			listCustomMeetingFunctions,
			match: {
				params: { id },
			},
		} = this.props;
		fetchMeeting(id);
		this.storeSelectedGroup();
		this.showStartMeetingMessageIfNeeded();
		listCustomMeetingFunctions();
	};

	componentDidUpdate = (prevProps) => {
		const {
			location,
			meeting,
			match: { params },
			fetchMeeting,
			meetingIsFinished,
			hideScreenMessage,
			history,
			attendeesChanged,
		} = this.props;
		const startDate = meeting && meeting.get("startDate");

		if (prevProps.match.params.id !== params.id) {
			fetchMeeting(params.id);
			hideScreenMessage();
		}

		if (prevProps.location !== location) {
			this.storeSelectedGroup();
		}

		if (prevProps.meeting !== meeting) {
			this.showStartMeetingMessageIfNeeded();

			if (meeting) {
				const isStandardMeeting =
					meeting.get("meetingType") === MEETING_TYPE_STANDARD;
				if (isStandardMeeting) {
					history.push(
						location.pathname.split("/meetings")[0] + `/meetings/${params.id}`,
					);
				}
			}
		}

		if (
			meeting &&
			!prevProps.meeting &&
			meeting.get("status") === MEETING_STATUS_PROTOCOL_NEEDS_SIGNING &&
			!location.pathname.includes("/sign-off")
		) {
			history.push(
				location.pathname.split("/protocol")[0] + `/protocol/sign-off`,
			);
		}

		if (
			meeting &&
			!prevProps.meeting &&
			startDate &&
			moment(startDate).isBefore(moment(), "minutes") &&
			!location.pathname.includes("/agenda") &&
			!meetingIsFinished
		) {
			const basePath = this.getComponentBasePath();
			history.push(`${basePath}/${params.id}/meeting/agenda`);
		}

		if (
			meeting &&
			prevProps.meeting &&
			prevProps.meeting !== meeting &&
			meeting.get("id") === prevProps.meeting.get("id")
		) {
			attendeesChanged(
				meeting.get("attendees"),
				prevProps.meeting.get("attendees"),
				meeting.get("id"),
			);
		}

		this.checkLiveUpdateEvents();
	};

	componentWillUnmount = () => {
		const { hideScreenMessage } = this.props;
		hideScreenMessage();
	};

	checkLiveUpdateEvents = () => {
		const {
			audit,
			fetchMeeting,
			userId,
			match: {
				params: { id },
			},
			location: { pathname, search },
			history,
		} = this.props;
		const meetingUpdate = audit.get(EVENT_TYPE_MEETINGS_UPDATE, Map());
		const meetingDelete = audit.get(LIVE_MEETINGS_DELETE, Map());
		const attendeeDelete = audit.get(LIVE_MEETINGS_ATTENDEE_REMOVE, Map());
		const protocolPublished = audit.get(
			LIVE_MEETINGS_PROTOCOL_PUBLISHED,
			Map(),
		);
		const meetingOpen = audit.get(LIVE_MEETINGS_OPEN, Map());
		const agendaUpdate = audit.get(LIVE_MEETINGS_AGENDA_UPDATE, Map());

		if (
			attendeeDelete.get("refresh") === true &&
			id === attendeeDelete.get("objId") &&
			attendeeDelete.getIn(["metadata", "removedAttendees"]).includes(userId)
		) {
			history.push({
				pathname: this.getComponentBasePath(),
				search,
			});
		}

		if (
			meetingUpdate.get("refresh") === true &&
			meetingUpdate.get("objId") === id
		) {
			fetchMeeting(id);
		}

		if (
			meetingDelete.get("refresh") === true &&
			id === meetingDelete.get("objId")
		) {
			history.push({
				pathname: this.getComponentBasePath(),
				search,
			});
		}

		// Protocol Published and the meeting is selected
		if (
			protocolPublished.get("refresh") === true &&
			id === protocolPublished.get("objId")
		) {
			if (pathname.includes("/protocol") || pathname.includes("/meeting")) {
				history.push({
					pathname: this.getComponentBasePath(),
					search,
				});
			}
		}

		// Meeting started when user was inside the meetig/agenda. Hide the Meeting starts message
		if (
			meetingOpen.get("refresh") === true &&
			id === meetingOpen.get("objId") &&
			pathname.includes("/meeting")
		) {
			fetchMeeting(id);
		}

		if (
			agendaUpdate.get("refresh") === true &&
			id === agendaUpdate.get("objId")
		) {
			fetchMeeting(id, null);
		}
	};

	showStartMeetingMessageIfNeeded = () => {
		const { meeting, showScreenMessage, hideScreenMessage } = this.props;

		if (!meeting) {
			return;
		}

		const meetingStatusStarted =
			meeting.get("status") === MEETING_STATUS_MEETING_STARTED;
		if (meetingStatusStarted) {
			hideScreenMessage();
			return;
		}

		const meetingIsToday = isMeetingToday(meeting);
		const meetingHasStarted = hasMeetingStarted(meeting);

		if (meetingIsToday && !meetingHasStarted) {
			showScreenMessage("START_MEETING");
		} else {
			hideScreenMessage();
		}
	};

	storeSelectedGroup = () => {
		const { updateSelectedGroupId } = this.props;
		const query = getQuery();
		const { group } = query;

		updateSelectedGroupId(group || null);
	};

	getComponentBasePath = () => {
		const {
			location: { pathname },
		} = this.props;
		const path = pathname;
		const pathArray = path.split("meetings");
		return pathArray[0] + "meetings";
	};

	onBreadcrumbClick = () => {
		const {
			match: { params },
			location,
			history,
		} = this.props;
		const basePath = this.getComponentBasePath();

		history.push({
			pathname: `${basePath}/${params.id}`,
			search: location.search,
		});
	};

	renderHeader = () => {
		const { meeting } = this.props;
		const meetingName = meeting && meeting.get("name");
		const meetingStartTime = meeting && moment(meeting.get("startDate"));
		const meetingEndTime = meeting && moment(meeting.get("endDate"));
		let startDate, startTime, endTime;

		if (meetingStartTime && meetingStartTime.isValid()) {
			startTime = meetingStartTime.format("LT");
			startDate = meetingStartTime.format("L");
		}

		if (meetingEndTime && meetingEndTime.isValid()) {
			endTime = meetingEndTime.format("LT");
		}

		return (
			<Margin all={3}>
				<Breadcrumbs>
					<BreadcrumbsItem
						itemName={<Text tid="meetings.breadcrumbs.title" />}
						onBtnClick={this.onBreadcrumbClick}
					/>
					{meetingName && (
						<BreadcrumbsItem
							itemName={`${meetingName} ${startDate} @ ${startTime} - ${endTime}`}
							isStatic={true}
							breadcrumbsMaxWidth="unset"
							borderless
						/>
					)}
				</Breadcrumbs>
			</Margin>
		);
	};

	renderOpenMeetingModal = () => {
		const { location } = this.props;
		const basePath = this.getComponentBasePath();

		return (
			<OpenMeetingModalContainer location={location} basePath={basePath} />
		);
	};

	render = () => {
		const { match } = this.props;

		return (
			<Content header={this.renderHeader} tightenHeader>
				{this.renderOpenMeetingModal()}
				{match.params.type === "meeting" && (
					<Switch>
						<Redirect exact from={`${match.path}/`} to={`${match.path}/info`} />
						<Route
							exact
							path={`${match.path}/info`}
							component={MeetingInformationContainer}
						/>
						<Route
							exact
							path={`${match.path}/agenda/:agendaItemId?`}
							component={MeetingAgendaContainer}
						/>
						<Route
							exact
							path={`${match.path}/attendees/:attendeeId?`}
							component={MeetingAttendeesContainer}
						/>
						<Route
							exact
							path={`${match.path}/documents/:documentId?`}
							component={MeetingDocumentsContainer}
						/>
						<Route
							exact
							path={`${match.path}/tasks/:taskId?`}
							component={MeetingTasksContainer}
						/>
					</Switch>
				)}
				{match.params.type === "protocol" && (
					<Switch>
						<Redirect
							exact
							from={`${match.path}/`}
							to={`${match.path}/review`}
						/>
						<Route
							exact
							path={`${match.path}/info`}
							component={ProtocolInformationContainer}
						/>
						<Route
							exact
							path={`${match.path}/attendees/:attendeeId?`}
							component={ProtocolAttendeesContainer}
						/>
						<Route
							exact
							path={`${match.path}/review/:agendaItemId?`}
							component={ProtocolReviewContainer}
						/>
						<Route
							exact
							path={`${match.path}/feedback/:feedbackeeId?`}
							component={ProtocolFeedbackContainer}
						/>
						<Route
							exact
							path={`${match.path}/sign-off`}
							component={SmartMeetingsProtocolEsigningTabContainer}
						/>
						<Route
							exact
							path={`${match.path}/documents/:documentId?`}
							component={ProtocolDocumentsContainer}
						/>
						<Route
							exact
							path={`${match.path}/tasks/:taskId?`}
							component={ProtocolTasksContainer}
						/>
					</Switch>
				)}
			</Content>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meeting: store.meetings.get("meeting"),
		audit: store.audit.get("meetings"),
		userId: store.user.getIn(["userObj", "id"]),
		meetingIsFinished: store.meetings.getIn([
			"meeting",
			"computedValues",
			"meetingIsFinished",
		]),
	};
};

const mapActionsToProps = {
	fetchMeeting,
	updateSelectedGroupId,
	showScreenMessage,
	hideScreenMessage,
	listCustomMeetingFunctions,
	attendeesChanged,
};

export default connect(mapStoreToProps, mapActionsToProps)(MeetingContainer);
