import React from "react";
import {
	Card,
	CardHeader,
	CardContent,
	Box,
	Divider,
	TextField,
	MenuItem,
} from "@mui/material";
import Button from "../../../mui-components/button/button";
import { FeatureModal } from "./feature-modal";
import { useDispatch } from "react-redux";
import { setFeaturePosition } from "../../../actions/feature.actions";
import { useFeatureGroupContext } from "./feature-group.context";
import { useFeatureContext } from "./feature.context";

function FeatureAdmin() {
	const { position, setFeature, featureOptions } = useFeatureGroupContext();
	const { startEditingFeature } = useFeatureContext();
	const dispatch = useDispatch();

	const selectFeature = (event) => {
		const featureId = event.target.value;
		dispatch(
			setFeaturePosition(featureId, position, (error, response) => {
				if (error) {
					return;
				}

				setFeature(response);
			}),
		);
	};

	return (
		<>
			<Card>
				<CardHeader variant="noBorder" title="Tomt feature-kort" />
				<CardContent>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						<Box sx={{ textAlign: "center" }}>
							<Button onClick={startEditingFeature} variant="primary">
								Skapa ny feature
							</Button>
						</Box>
						<Divider sx={{ my: 2 }}>Eller välj befintlig</Divider>
						<TextField
							label="Befintliga features"
							onChange={selectFeature}
							value=""
							select
						>
							{featureOptions.map((option, index) => (
								<MenuItem key={index} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</Box>
				</CardContent>
			</Card>
			<FeatureModal />
		</>
	);
}

export { FeatureAdmin };
