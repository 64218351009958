import req from "../modules/request.module";
import {
	INVESTORS_FETCH_MY_INVESTMENT_PERSONAL,
	RESET_PERSONAL_ATTACHMENTS,
} from "../actions/types";
import { fromJS } from "immutable";
import { modifyTransactionIfDirty } from "../components/helpers/my-records-actions-helpers";
import { addInfoNotification } from "./notify.actions";

export function createPersonalTransaction(
	personalInvestmentId,
	transaction,
	selectedInvestmentTransactions,
) {
	return function (dispatch) {
		return req
			.post(
				`/shares/personal/investment/transaction/${personalInvestmentId}`,
				transaction,
			)
			.then((response) => {
				let responseDataTransactions = response.data.transactions;
				if (selectedInvestmentTransactions && responseDataTransactions) {
					responseDataTransactions = modifyTransactionIfDirty(
						selectedInvestmentTransactions,
						responseDataTransactions,
					);
				}

				response.data.transactions = responseDataTransactions;
				dispatch({
					type: INVESTORS_FETCH_MY_INVESTMENT_PERSONAL,
					payload: fromJS(response.data),
				});
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function updatePersonalTransaction(
	personalInvestmentId,
	personalTransactionId,
	transaction,
	selectedInvestmentTransactions,
) {
	return function (dispatch) {
		return req
			.put(
				`/shares/personal/investment/transaction/${personalInvestmentId}/${personalTransactionId}`,
				transaction,
			)
			.then((response) => {
				let responseDataTransactions = response.data.transactions;
				if (selectedInvestmentTransactions && responseDataTransactions) {
					responseDataTransactions = modifyTransactionIfDirty(
						selectedInvestmentTransactions,
						responseDataTransactions,
					);
				}

				response.data.transactions = responseDataTransactions;
				dispatch({
					type: INVESTORS_FETCH_MY_INVESTMENT_PERSONAL,
					payload: fromJS(response.data),
				});
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function deletePersonalTransaction(
	personalInvestmentId,
	personalTransactionId,
	selectedInvestmentTransactions,
	objType,
) {
	return function (dispatch) {
		return req
			.delete(
				`/shares/personal/investment/transaction/${personalInvestmentId}/${personalTransactionId}?objType=${objType}`,
			)
			.then((response) => {
				let responseDataTransactions = response.data.transactions;
				if (selectedInvestmentTransactions && responseDataTransactions) {
					responseDataTransactions = modifyTransactionIfDirty(
						selectedInvestmentTransactions,
						responseDataTransactions,
					);
				}

				response.data.transactions = responseDataTransactions;
				dispatch({
					type: INVESTORS_FETCH_MY_INVESTMENT_PERSONAL,
					payload: fromJS(response.data),
				});
				dispatch({
					type: RESET_PERSONAL_ATTACHMENTS,
					payload: { objType: objType, objId: personalTransactionId },
				});
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function updateInvestmentConfiguration(
	personalInvestmentId,
	configuration,
	callback,
) {
	return async function (dispatch) {
		try {
			const response = await req.put(
				`/shares/personal/investment/configure/${personalInvestmentId}`,
				configuration,
			);
			dispatch({
				type: INVESTORS_FETCH_MY_INVESTMENT_PERSONAL,
				payload: fromJS(response.data),
			});
			dispatch(
				addInfoNotification({
					tid: "investments.my_records.configure_my_portfolio_table_data.modal.save_successfull",
				}),
			);
			callback(undefined);
		} catch (error) {
			callback(error);
		}
	};
}

export function getIndirectPersonalInvestmentsWithOrgNrsLimited(
	orgNumber,
	viaCompanyOrgNumber,
	id,
	callback,
) {
	return async function () {
		try {
			const response = await req.get(
				`/shares/personal/investment/list/limited/indirect/${orgNumber}/${viaCompanyOrgNumber}/${id}`,
			);
			callback(response.data);
		} catch (error) {
			callback(error);
		}
	};
}

export function copyAllCompayTransactions(
	personalInvestmentId,
	investmentId,
	callback,
) {
	return function (dispatch) {
		return req
			.put(
				`shares/personal/investment/copy-company-transactions/${personalInvestmentId}/${investmentId}`,
			)
			.then((response) => {
				dispatch({
					type: INVESTORS_FETCH_MY_INVESTMENT_PERSONAL,
					payload: fromJS(response.data),
				});
				callback && callback();
			});
	};
}

export function updateTransactionsOrder(
	personalInvestmentId,
	newOrderTransactionIds,
	toIndex,
	movedColumnId,
	callback,
) {
	return function (dispatch) {
		return req
			.put(
				`/shares/personal/investment/reorder-transactions/${personalInvestmentId}`,
				{
					newOrderTransactionIds: newOrderTransactionIds,
					toIndex: toIndex,
					movedColumnId: movedColumnId,
				},
			)
			.then((response) => {
				dispatch({
					type: INVESTORS_FETCH_MY_INVESTMENT_PERSONAL,
					payload: fromJS(response.data),
				});
				callback && callback();
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function updateTransactionDate(
	personalInvestmentId,
	personalTransactionId,
	date,
) {
	return function (dispatch) {
		return req
			.put(
				`/shares/personal/investment/date/local/${personalInvestmentId}/${personalTransactionId}`,
				{
					date,
				},
			)
			.then((response) => {
				dispatch({
					type: INVESTORS_FETCH_MY_INVESTMENT_PERSONAL,
					payload: fromJS(response.data),
				});
			})
			.catch((e) => {
				console.log(e);
			});
	};
}
