import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { fromJS, isImmutable } from "immutable";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS } from "../../constants/modals";
import TargetFolderTreeView from "../../dumb-components/personal-documents/choose-target-folder-tree-view";
import personalDocumentsService from "../../services/personal-documents";
import { closeModal } from "../../actions/modals.actions";
import { copyDocumentToPersonalDocuments } from "../../actions/personal-documents.actions";
import MUIDialog from "../../mui-components/dialog/mui-dialog";
import Button from "../../mui-components/button/button";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import {
	usePopupState,
	bindHover,
	bindMenu,
} from "material-ui-popup-state/hooks";
import MenuItemIcon from "../../mui-components/dropdown/menu/menu-item-icon";
import useSubscriptionHelper from "../../hooks/useSubscriptionHelper";

export default function CopyDocumentToPersonalDocumentsModal() {
	const [folders, setFolders] = useState(
		fromJS({ name: "personal-documents.my-documents", children: [] }),
	);

	const [targetFolder, setTargetFolder] = useState(null);

	const modals = useSelector((state) => state.modals);

	const isOpen =
		modals.getIn(["activeModal", "name"]) ===
		COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS;

	const modalOptions = modals.getIn(["activeModal", "options"]);

	const document =
		isOpen &&
		typeof modalOptions !== "undefined" &&
		isImmutable(modalOptions) &&
		modalOptions?.get("document");

	const context =
		isOpen &&
		typeof modalOptions !== "undefined" &&
		isImmutable(modalOptions) &&
		modalOptions?.get("context");

	const esigningCompleted =
		document &&
		document.getIn(["metadata", "documentIsSigned"]) &&
		document.getIn(["metadata", "ongoingEsigning"]) === false;

	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	const esigningEnabled = subscriptionHelper?.eSigningEnabled;

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const popupState = usePopupState({
		variant: "popover",
		popupId: "demoMenu",
	});

	const handleCopyFromInvestor = () => {
		let fileName = document.get("title");
		let file = document.get("file");
		const documentId = document.get("id");
		let sourceBucket = file.get("bucket");
		let sourceFilePath = file.get("path");

		if (document.get("signedIsMirrored")) {
			fileName = document.getIn([
				"eSigningData",
				"fileReference",
				"originalname",
			]);
			file = document.getIn(["eSigningData", "fileReference"]);
			sourceBucket = file.get("bucket");
			sourceFilePath = file.get("path");
		}

		handleCopy(
			documentId,
			sourceBucket,
			fileName,
			sourceFilePath,
			targetFolder,
		);
		dispatch(closeModal(COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS));
	};

	const handleCopyFromDocuments = (typeOfDocument) => {
		let fileName = document.get("title");
		let file = document.get("file");
		const documentId = document.get("id");
		let sourceBucket = file.get("bucket");
		let sourceFilePath = file.get("path");

		if (typeOfDocument === "signed") {
			fileName = document.getIn([
				"eSigningData",
				"fileReference",
				"originalname",
			]);
			file = document.getIn(["eSigningData", "fileReference"]);
			sourceBucket = file.get("bucket");
			sourceFilePath = file.get("path");
		}

		handleCopy(
			documentId,
			sourceBucket,
			fileName,
			sourceFilePath,
			targetFolder,
		);
		dispatch(closeModal(COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS));
	};

	const handleCopy = (
		documentId,
		sourceBucket,
		fileName,
		sourceFilePath,
		targetFolder,
	) => {
		dispatch(
			copyDocumentToPersonalDocuments(
				documentId,
				sourceBucket,
				fileName,
				sourceFilePath,
				targetFolder === "root" ? "" : targetFolder,
			),
		);
	};

	useEffect(() => {
		const fetchFolders = async () => {
			const folders = await personalDocumentsService.getPersonalFolders();

			setFolders(fromJS(folders));
		};

		isOpen && fetchFolders();
	}, [isOpen]);

	let onConfirmCb =
		context === "my_portfolio" ? handleCopyFromInvestor : undefined;
	if (context === "documents" && (!esigningEnabled || !esigningCompleted)) {
		onConfirmCb = () => handleCopyFromDocuments("original");
	}

	return (
		<>
			<MUIDialog
				maxWidth={"md"}
				fullWidth={true}
				isOpen={isOpen}
				title={t("personal-documents.copy-file-to-personal-documents")}
				onConfirm={onConfirmCb}
				confirmButtonTid="personal-documents.copy-document"
				disabledConfirm={!targetFolder}
				renderConfirmButton={
					esigningEnabled && esigningCompleted
						? () => (
								<div>
									<Button color="positive" {...bindHover(popupState)}>
										{t("personal-documents.options")}
									</Button>
									<HoverMenu
										{...bindMenu(popupState)}
										anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
										transformOrigin={{ vertical: "top", horizontal: "left" }}
										transitionDuration={0}
									>
										<MenuItemIcon
											onClick={() => {
												handleCopyFromDocuments("original");
												popupState.close();
											}}
											listItemTid="personal-documents.copy-original-document"
											key="original"
										/>
										<MenuItemIcon
											disabled={!esigningCompleted}
											onClick={() => {
												handleCopyFromDocuments("signed");
												popupState.close();
											}}
											listItemTid="personal-documents.copy-signed-document"
										/>
									</HoverMenu>
								</div>
						  )
						: null
				}
				onClose={() => dispatch(closeModal(null, "DISMISSED"))}
				closeButtonTid="dialog.generic.cancel"
			>
				<Typography variant={"body2"} sx={{ flex: "auto", my: 4 }}>
					{t("personal-documents.choose-target-folder-description")}
				</Typography>
				<TargetFolderTreeView
					folders={folders}
					onSelectFolder={(nodeId) => setTargetFolder(nodeId)}
				/>
			</MUIDialog>
		</>
	);
}
