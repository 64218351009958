import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { string, bool } from "prop-types";

export default class TextBox extends Component {
	static propTypes = {
		showMoreText: string,
		showLessText: string,
		white: bool,
	};

	static defaultProps = {
		showMoreText: "generic.show_more",
		showLessText: "generic.show_less",
		white: false,
	};

	constructor() {
		super();
		this.textBoxDivRef = React.createRef();
	}

	state = {
		open: false,
		showButton: false,
	};

	componentDidMount = () => {
		const numOfParagraphs =
			this.textBoxDivRef?.current?.getElementsByTagName?.("p")?.length || 0;
		this.setState({ showButton: numOfParagraphs >= 2 });
	};

	toggleText = () => {
		this.setState((prevState) => {
			return { open: !prevState.open };
		});
	};

	render = () => {
		const { showMoreText, showLessText, white, children } = this.props;
		const { open, showButton } = this.state;

		return (
			<div className={`textbox ${white ? "textbox--white" : ""}`}>
				<div
					className={`textbox__text ${open ? "textbox__text--open" : ""}`}
					ref={this.textBoxDivRef}
				>
					{children}
				</div>
				{showButton && (
					<div className="textbox__button">
						<div className="textbox__button-text" onClick={this.toggleText}>
							<FormattedMessage id={open ? showLessText : showMoreText} />
						</div>
					</div>
				)}
			</div>
		);
	};
}
