import req from "../modules/request.module";

export function fetchCompanyData(orgNr, cb) {
	return function () {
		req
			.get(`/syna-integration/companies/${orgNr}`)
			.then((response) => {
				cb && cb(response.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function searchCompany(query, callback) {
	return function () {
		const data = [];

		req
			.get(`/syna-integration/companies?q=${query}`)
			.then((response) => {
				if (response.data && response.data.length > 0) {
					response.data.map((company) => {
						data.push({ value: company.orgNumber, label: company.name });
					});
				}

				callback(undefined, data);
			})
			.catch((err) => {
				callback(err);
			});
	};
}

export function fetchFinancialStatements(orgNumber, callback) {
	return async function () {
		try {
			const response = await req.get(
				`/syna-integration/portfolio/investment/company/${orgNumber}/financial-statement`,
			);
			callback(undefined, response.data);
		} catch (err) {
			callback(err);
		}
	};
}

export function fetchRepresentatives(orgNumber, callback) {
	return async function () {
		try {
			const response = await req.get(
				`/syna-integration/portfolio/investment/company/representatives/${orgNumber}`,
			);
			callback(undefined, response.data);
		} catch (err) {
			callback(err);
		}
	};
}

export function fetchCases(orgNumber, callback) {
	return async function () {
		try {
			const response = await req.get(
				`/syna-integration/portfolio/investment/company/cases/${orgNumber}`,
			);
			callback(undefined, response.data);
		} catch (err) {
			callback(err);
		}
	};
}
