const formatNumber = require('@invono/format-number');

const svConfig = {
	currency: {
		integerSeparator: ' ',
		decimalsSeparator: '',
		decimal: ',',
		round: 2,
		suffix: ' SEK'
	},
	percentage: {
		integerSeparator: ' ',
		decimalsSeparator: '',
		decimal: ',',
		round: 2,
		suffix: ' %'
	},
	default: {
		integerSeparator: ' ',
		decimalsSeparator: '',
		decimal: ',',
		round: 2
	},
	defaultInput: {
		integerSeparator: ' ',
		decimalsSeparator: '',
		decimal: ',',
		round: 15
	},
	piece: {
		integerSeparator: ' ',
		decimalsSeparator: '',
		decimal: ',',
		round: 0,
		suffix: ' st'
	},
	integer: {
		integerSeparator: ' ',
		decimalsSeparator: '',
		decimal: ',',
		round: 0
	},
	times: {
		integerSeparator: ' ',
		decimalsSeparator: '',
		decimal: ',',
		round: 2
	}
};

const enConfig = {
	// Kept for backwards compatibility
	round: 2,
	currency: {
		integerSeparator: ' ',
		decimalsSeparator: '',
		decimal: '.',
		round: 2,
		suffix: ''
	},
	percentage: {
		integerSeparator: ' ',
		decimalsSeparator: '',
		decimal: '.',
		round: 2,
		suffix: ' %'
	},
	default: {
		integerSeparator: ' ',
		decimalsSeparator: '',
		decimal: '.',
		round: 2
	},
	defaultInput: {
		integerSeparator: ' ',
		decimalsSeparator: '',
		decimal: '.',
		round: 15
	},
	piece: {
		integerSeparator: ' ',
		decimalsSeparator: '',
		decimal: '.',
		round: 0,
		suffix: ' st'
	},
	integer: {
		integerSeparator: ' ',
		decimalsSeparator: '',
		decimal: '.',
		round: 0
	},
	times: {
		integerSeparator: ' ',
		decimalsSeparator: '',
		decimal: '.',
		round: 2
	}
};

const formatDecimals = (number, config) => {
	const { prefix, suffix, decimal, round } = config;

	// Prefix and later suffix are removed so we can work with the pure number,
	// this allows us to add the extra decimals even if they are 0.
	if (prefix) {
		number = number.substr(prefix.length, number.length);
	}

	const spaceRemovedFromNumber = number.replace(/ /g, '');
	let decimalNumber = parseFloat(spaceRemovedFromNumber.replace(decimal, '.')).toFixed(round);

	// Regex magic to add a space every thousand number
	const parts = decimalNumber.toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	decimalNumber = parts.join('.');

	let formatedDecimalNumber = decimalNumber.replace('.', decimal);

	if (suffix) {
		formatedDecimalNumber = formatedDecimalNumber + suffix;
	}

	if (prefix) {
		formatedDecimalNumber = prefix + formatedDecimalNumber;
	}

	return formatedDecimalNumber;
};

//Default config Naming perserved for backwards compability reasons.
const localeConfig = svConfig.default;

const localeFormatNumber = (number, type, customConfig) => {
	if (!type || !svConfig[type]) {
		type = 'default';
	}

	let config = svConfig[type];
	if (customConfig) {
		config = { ...config, ...customConfig };
	}
	const result = formatNumber(config)(number);
	return formatDecimals(result, config);
};

//exports
module.exports = localeFormatNumber;
module.exports.svConfig = svConfig;
module.exports.enConfig = enConfig;
module.exports.localeConfig = localeConfig;
