import { fromJS } from "immutable";
import req from "../modules/request.module";
import {
	INVESTORS_LIST,
	INVESTORS_FETCH,
	INVESTORS_EDIT,
	INVESTORS_CREATE,
	INVESTORS_FETCH_MY_INVESTMENTS,
	INVESTORS_DIRTY,
	INVESTORS_SAVE_START,
	INVESTORS_EDIT_LOCAL,
	INVESTORS_SET_FILTERS,
	INVESTORS_UNSET_FILTERS,
	INVESTORS_SET_VISIBLE,
	INVESTORS_SET_SELECTEDINVESTOR,
	INVESTORS_BEING_FETCHED,
	INVESTORS_FETCHED_AT,
	INVESTORS_UPDATE_INVESTORS_CACHE,
	INVESTORS_UPDATE_INVESTORS_REQUEST_CACHE,
	INVESTOR_LIST_CONCAT,
	INVESTORS_FETCH_MY_INVESTMENTS_DASHBOARD,
	INVESTORS_CLEAR_MY_INVESTMENTS_DASHBOARD,
} from "./types";
import {
	EVENT_TYPE_SHARES_INVESTMENT_CREATE,
	EVENT_TYPE_SHARES_INVESTMENT_UPDATE,
	EVENT_TYPE_SHARES_INVESTMENT_DELETE,
	EVENT_TYPE_SHARES_INVESTMENT_LINKED,
} from "/shared/constants";
import { setLiveRequest, resetLiveRequest } from "./live-update.actions";
import { addErrorNotification, addInfoNotification } from "./notify.actions";
import documentsHelper from "../components/helpers/documents.helper";

export function inviteInvestor(
	investorId,
	index,
	mailTemplate,
	callback,
	errorCallback,
) {
	return function (dispatch) {
		dispatch({ type: INVESTORS_SAVE_START });
		return req
			.post(`/shares/invite-shareholder/${investorId}`, { index, mailTemplate })
			.then((response) => {
				dispatch({ type: INVESTORS_EDIT, payload: fromJS(response.data) });
				callback?.();
			})
			.catch(() => {
				errorCallback?.();
			});
	};
}

export function remindInvestors(
	investmentIds,
	mailTemplate,
	callback,
	errorCallback,
) {
	return function () {
		req
			.post(`/shares/remind-shareholders`, { investmentIds, mailTemplate })
			.then(() => {
				callback?.();
			})
			.catch(() => {
				errorCallback?.();
			});
	};
}

export function setFilteredInvestors(investors, hasAppliedFilters) {
	return {
		type: INVESTORS_SET_VISIBLE,
		payload: { investors, hasAppliedFilters },
	};
}

export function listInvestors(props, callback) {
	return function (dispatch, getState) {
		const isFetchingInvestors = getState().investors.get("isFetchingInvestors");
		if (!isFetchingInvestors) {
			dispatch({ type: INVESTORS_BEING_FETCHED, payload: true });
			return req
				.get(`/shares/company/investments?excludeOwnCompany=${props}`, {
					preventRepeatedRequests: true,
				})
				.then((response) => {
					const investors = fromJS(response.data);
					dispatch({ type: INVESTORS_LIST, payload: investors });
					dispatch({
						type: INVESTORS_UPDATE_INVESTORS_CACHE,
						payload: investors,
					});
					callback && callback(investors);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
}

export function listInvestorsUnsafe(successCallback) {
	return function (dispatch, getState) {
		const investorsFetchedAt = getState().investors.get("investorsFetchedAt");
		const isFetchingInvestors = getState().investors.get("isFetchingInvestors");

		if (!investorsFetchedAt && !isFetchingInvestors) {
			dispatch({ type: INVESTORS_BEING_FETCHED, payload: true });
			return req
				.get(`/shares/company/investments/unsafe`, {
					preventRepeatedRequests: true,
				})
				.then((response) => {
					const investors = fromJS(response.data);
					dispatch({ type: INVESTORS_LIST, payload: investors });
					dispatch({
						type: INVESTORS_UPDATE_INVESTORS_CACHE,
						payload: investors,
					});
					dispatch({
						type: INVESTORS_FETCHED_AT,
						payload: new Date().getTime(),
					});
					successCallback && successCallback();
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
}

export function fetchAndCacheInvestors(investmentIds) {
	return function (dispatch, getState) {
		const cache = getState().investors.get("investorsCache");
		const requestCache = getState().investors.get("investorsRequestCache");

		investmentIds =
			investmentIds &&
			investmentIds
				.filter((id) => {
					return id && !cache.has(id) && !requestCache.has(id);
				})
				?.toList();

		if (investmentIds?.size > 0) {
			dispatch({
				type: INVESTORS_UPDATE_INVESTORS_REQUEST_CACHE,
				payload: investmentIds,
			});
			return req
				.post("/shares/company/investments/array", {
					investments: investmentIds,
				})
				.then((response) => {
					dispatch({
						type: INVESTORS_UPDATE_INVESTORS_CACHE,
						payload: fromJS(response.data),
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
}

export function relinkInvestor(id, linkCode) {
	return function (dispatch) {
		return req
			.put(`/shares/company/investments/${id}/link/${linkCode}`)
			.then((response) => {
				dispatch({
					type: INVESTORS_EDIT_LOCAL,
					payload: fromJS(response.data),
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function unlinkInvestor(id) {
	return function (dispatch) {
		return req
			.delete(`/shares/company/investments/${id}/link`)
			.then((response) => {
				dispatch({
					type: INVESTORS_EDIT_LOCAL,
					payload: fromJS(response.data),
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function removeInvestorInfoId(id, isCapitalIncurance, callback) {
	return function (dispatch) {
		return req
			.delete(
				`/shares/company/investments/${id}/removeId?capitalInsurance=${
					isCapitalIncurance ? true : false
				}`,
			)
			.then((response) => {
				dispatch({
					type: INVESTORS_EDIT_LOCAL,
					payload: fromJS(response.data),
				});
				callback && callback();
			})
			.catch((err) => {
				console.log(err);
				callback && callback(err);
			});
	};
}

export function fetchInvestor(investmentId) {
	return function (dispatch) {
		return req
			.get(`/shares/company/investments/${investmentId}`)
			.then((response) => {
				dispatch({ type: INVESTORS_FETCH, payload: fromJS(response.data) });
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function deleteInvestor(investmentId) {
	return function (dispatch) {
		return req
			.delete(`/shares/company/investments/${investmentId}`)
			.then((response) => {
				const investors = fromJS(response.data);
				dispatch(addInfoNotification({ tid: "shares.deleting_investor" }));
				dispatch({ type: INVESTORS_LIST, payload: investors });
				dispatch({
					type: INVESTORS_UPDATE_INVESTORS_CACHE,
					payload: investors,
				});
			});
	};
}

export function archiveInvestor(investmentId, investment) {
	return function (dispatch) {
		investment.isArchived = true;
		investment.isDeletable = false;
		return req
			.put(`/shares/company/investments/${investmentId}`, investment)
			.then((response) => {
				dispatch({ type: INVESTORS_EDIT, payload: fromJS(response.data) });
				dispatch(listInvestors());
			});
	};
}

export function editInvestor(investmentId, investment) {
	return function (dispatch) {
		dispatch({ type: INVESTORS_SAVE_START });
		return req
			.put(`/shares/company/investments/${investmentId}`, investment, {
				onlyLatest: true,
			})
			.then((response) => {
				dispatch({ type: INVESTORS_EDIT, payload: fromJS(response.data) });
				dispatch({
					type: INVESTORS_UPDATE_INVESTORS_CACHE,
					payload: fromJS([response.data]),
				});
			});
	};
}

export function editInvestorLocal(investment) {
	return function (dispatch) {
		dispatch({ type: INVESTORS_EDIT_LOCAL, payload: investment });
	};
}

export function createInvestor(investment = {}, callback) {
	return function (dispatch) {
		return req
			.post(`/shares/company/investments`, investment)
			.then((response) => {
				const investor = fromJS(response.data);
				dispatch(listInvestors());
				callback && callback(null, investor);
				dispatch({ type: INVESTORS_CREATE, payload: investor });
			})
			.catch((e) => {
				callback && callback(e);
			});
	};
}

export function fetchMyInvestments(callback) {
	return function (dispatch) {
		return req
			.get("/shares/investments?companyInvestments=true")
			.then((response) => {
				dispatch({
					type: INVESTORS_FETCH_MY_INVESTMENTS_DASHBOARD,
					payload: fromJS(response.data),
				});
				callback && callback();
			});
	};
}

export function fetchMyInvestmentsSimple(callback) {
	return function () {
		return req.get("/shares/investments/simple").then((response) => {
			callback(fromJS(response.data));
		});
	};
}

export function fetchCompanyInvestments(callback) {
	return function (dispatch) {
		return req
			.get("/shares/investments?isCompany=true&companyInvestments=true")
			.then((response) => {
				dispatch({
					type: INVESTORS_FETCH_MY_INVESTMENTS,
					payload: fromJS(response.data),
				});
				callback && callback();
			});
	};
}

export function fetchInvestorsCount(callback) {
	return function () {
		return req
			.get(`/shares/company/investors/count`)
			.then((response) => {
				callback && callback(fromJS(response.data));
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function importShareholdersFromExcel(file, callback) {
	return function (dispatch) {
		const valid = documentsHelper.validateFileExtension(file, ["xls", "xlsx"]);

		if (!valid) {
			dispatch(
				addErrorNotification({
					tid: "shares.investors.import.error.invalid_file_extension",
				}),
			);
			return;
		}

		const fileForm = new FormData();
		fileForm.append("file", file);

		req
			.post(`/shares/excel/parse/add-shareholders`, fileForm)
			.then((response) => {
				const investors = fromJS(response.data);
				dispatch({ type: INVESTOR_LIST_CONCAT, payload: investors });
			})
			.catch((e) => {
				const errorData = e.response.data.data;
				callback(errorData);
			});
	};
}

export function updateInvestorsList(investment) {
	return function (dispatch, getState) {
		const investorsList = getState().investors.get("list").push(investment);
		dispatch({ type: INVESTORS_LIST, payload: investorsList });
		dispatch({
			type: INVESTORS_UPDATE_INVESTORS_CACHE,
			payload: investorsList,
		});
	};
}

export function cleanDashboard() {
	return function (dispatch) {
		dispatch({ type: INVESTORS_CLEAR_MY_INVESTMENTS_DASHBOARD });
	};
}

export function setFilterBy(val) {
	return {
		type: INVESTORS_SET_FILTERS,
		payload: val,
	};
}
export function setSelectedInvestor(val) {
	return {
		type: INVESTORS_SET_SELECTEDINVESTOR,
		payload: val,
	};
}

export function unsetFilterBy(sources) {
	return {
		type: INVESTORS_UNSET_FILTERS,
		payload: sources,
	};
}

export function investorIsDirty() {
	return {
		type: INVESTORS_DIRTY,
		payload: null,
	};
}

export function socketEventInvestors(eventObj) {
	const { eventName, objId, metadata } = eventObj;

	return function (dispatch) {
		switch (eventName) {
			case EVENT_TYPE_SHARES_INVESTMENT_CREATE:
			case EVENT_TYPE_SHARES_INVESTMENT_UPDATE:
			case EVENT_TYPE_SHARES_INVESTMENT_LINKED:
			case EVENT_TYPE_SHARES_INVESTMENT_DELETE: {
				dispatch(
					setLiveRequest(["investors", eventName], {
						refresh: true,
						objId,
						metadata,
					}),
				);
				dispatch(resetLiveRequest(["investors", eventName]));
				break;
			}
			default:
				dispatch({ type: "INVESTORS_NULL", payload: null });
		}
	};
}
