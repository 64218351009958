import { func } from "prop-types";
import React, { useState } from "react";
import { usePeopleModalContext } from "../people-modal.context";
import MuiDialog from "../../../../mui-components/dialog/mui-dialog";
import { TextField, Box, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PEOPLE_MODAL_FILTERS_SHAREHOLDERS_STATUS } from "../constants/filters";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "../../../../mui-components/button/icon-button";

const FILTER_OPTIONS = [
	{
		value: "SHOW_ALL",
		label: "people_modal.shareholders_status_filter.options.show_all",
	},
	{
		value: "CURRENT",
		label: "people_modal.shareholders_status_filter.options.show_current",
	},
	{
		value: "NON_SHAREHOLDER",
		label:
			"people_modal.shareholders_status_filter.options.show_non_shareholders",
	},
];

function ShareholdersFilterModal({ onClose }) {
	const { filters, setFilters } = usePeopleModalContext();
	const [filtersInternally, setFiltersInternally] = useState({});
	const filterValues =
		filtersInternally[PEOPLE_MODAL_FILTERS_SHAREHOLDERS_STATUS] ||
		filters[PEOPLE_MODAL_FILTERS_SHAREHOLDERS_STATUS] ||
		"__placeholder__";
	const { t } = useTranslation();

	const onChange = (event) => {
		const value = event.target.value;
		setFiltersInternally({
			...filtersInternally,
			[PEOPLE_MODAL_FILTERS_SHAREHOLDERS_STATUS]: value,
		});
	};

	const onClear = () => {
		setFiltersInternally({
			...filtersInternally,
			[PEOPLE_MODAL_FILTERS_SHAREHOLDERS_STATUS]: "__placeholder__",
		});
	};

	const onApply = () => {
		if (
			filtersInternally[PEOPLE_MODAL_FILTERS_SHAREHOLDERS_STATUS] ===
			"__placeholder__"
		) {
			filtersInternally[PEOPLE_MODAL_FILTERS_SHAREHOLDERS_STATUS] = null;
		}

		setFilters({ ...filtersInternally });
		onClose();
	};

	return (
		<MuiDialog
			title={t("people_modal.shareholders_status_filter.title")}
			isOpen={true}
			onClose={onClose}
			onConfirm={onApply}
			confirmButtonTid="people_modal.shareholders_status_filter.btn_apply"
			maxWidth="sm"
			fullWidth
			autoHeight
		>
			<Box sx={{ pb: "34px" }}>
				<TextField
					onChange={onChange}
					label={t("people_modal.shareholders_status_filter.select_status")}
					variant="standard"
					fullWidth
					select
					value={filterValues}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end" sx={{ mr: "24px" }}>
								<IconButton
									icon="faTimes"
									size="sm"
									noBorder
									noInlinePadding
									onClick={onClear}
								/>
							</InputAdornment>
						),
					}}
				>
					<MenuItem value={"__placeholder__"} disabled={true}>
						{t("select_placeholder")}
					</MenuItem>
					{FILTER_OPTIONS.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{t(option.label)}
						</MenuItem>
					))}
				</TextField>
			</Box>
		</MuiDialog>
	);
}

ShareholdersFilterModal.propTypes = {
	onClose: func.isRequired,
};

export { ShareholdersFilterModal };
