import React, { useEffect, useState } from "react";

import Text from "../../../dumb-components/shared/text/text";
import apiClient from "../../../modules/request.module.v2";
import { connect } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { Button } from "@/components/ui/button.jsx";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../framework/notify";
import { useTranslation } from "react-i18next";
import Panel from "@/dumb-components/shared/panel/panel";
import { Bell } from "lucide-react";

const Label = styled.label`
	display: flex;
	gap: 8px;
`;

const Indented = styled.div`
	padding-inline-start: 22px;
`;

const NEWSLETTER_AUDIENCES = [
	"newsInvestors",
	"newsCompanyAdministrators",
	"newsBoardMembers",
	"newsCompanyMembers",
];

const NotificationSettings = ({ userId }) => {
	const { t } = useTranslation();

	const [userPreferences, setUserPreferences] = useState(null);

	const { isLoading, data: fetchedUserPreferences } = useQuery({
		queryKey: ["userPreferences", userId],
		queryFn: () => {
			return apiClient.get(`users/${userId}/preferences`);
		},
	});

	const {
		mutate: updateUserPreferences,
		isPending: isUpdatingUserPreferences,
	} = useMutation({
		mutationKey: ["updateUserPreferences"],
		mutationFn: async () => {
			await apiClient.put(`users/${userId}/preferences/emailUnsubscribedAll`, {
				value: userPreferences.emailUnsubscribedAll,
			});

			await apiClient.put(`users/${userId}/preferences/emailUnsubscribedList`, {
				value: userPreferences.emailUnsubscribedList,
			});
		},
		onSuccess: () => {
			addInfoNotification({
				tid: "user.notification_preferences.notify.update.success",
			});
		},
		onError: () => {
			addErrorNotification({
				tid: "user.notification_preferences.notify.update.error",
			});
		},
	});

	useEffect(() => {
		if (!userPreferences && fetchedUserPreferences?.data) {
			setUserPreferences(fetchedUserPreferences.data);
		}
	}, [fetchedUserPreferences?.data, userPreferences]);

	if (isLoading || !userPreferences) return null;

	return (
		<div className="flex flex-col h-full p-md ">
			<Panel
				icon={<Bell />}
				title="user.notification_preferences.breadcrumbs.title"
			>
				<div className="flex flex-col p-md gap-6">
					<div>
						<Text tid="user.notification_preferences.hero_text" />
					</div>
					<div className="flex flex-col border bg-accent p-md rounded-lg gap-12">
						{NEWSLETTER_AUDIENCES.map((audience) => (
							<div key={audience} className="">
								<Label>
									<input
										type="checkbox"
										checked={
											!userPreferences?.emailUnsubscribedList.includes(audience)
										}
										disabled={userPreferences?.emailUnsubscribedAll}
										onChange={(evt) => {
											const emailUnsubscribedList = evt.target.checked
												? userPreferences.emailUnsubscribedList.filter(
														(a) => a !== audience,
												  )
												: [...userPreferences.emailUnsubscribedList, audience];

											setUserPreferences({
												...userPreferences,
												emailUnsubscribedList: emailUnsubscribedList,
											});
										}}
									/>
									<Text
										bold={700}
										tid={`user.notification_preferences.newsletter.audience.${audience}.title`}
									/>
								</Label>
								<Indented>
									<Text
										bold={600}
										tid={`user.notification_preferences.newsletter.audience.${audience}.subtitle`}
									/>
								</Indented>
								<Indented>
									<Text
										tid={`user.notification_preferences.newsletter.audience.${audience}.description`}
									/>
								</Indented>
							</div>
						))}
						<div>
							<Label>
								<input
									type="checkbox"
									checked={userPreferences?.emailUnsubscribedAll}
									onChange={(evt) => {
										setUserPreferences({
											...userPreferences,
											emailUnsubscribedAll: evt.target.checked,
										});
									}}
								/>
								<Text
									bold={700}
									tid="user.notification_preferences.newsletter.unsubscribeToAll.label"
								/>
							</Label>
							<Indented>
								<Text tid="user.notification_preferences.newsletter.unsubscribeToAll.description" />
							</Indented>
						</div>
					</div>
					<div>
						<Button
							onClick={updateUserPreferences}
							isLoading={isUpdatingUserPreferences}
						>
							{t("user.notification_preferences.update_button.label")}
						</Button>
					</div>
				</div>
			</Panel>
		</div>
	);
};

const mapStateToProps = (state) => ({
	userId: state.user.getIn(["userObj", "id"]),
});

export default connect(mapStateToProps)(NotificationSettings);
