export const setIsDirty = (args, state) => {
	const linkField = state.fields.link;

	if (linkField) {
		const linkValue = linkField.lastFieldState.value;

		linkField.change({
			isDirty: true,
			transactionId: linkValue.transactionId,
		});
	}
};

export const retainDirtyOnInitialize = (args, state) => {
	const linkField = state.fields.link;

	if (linkField) {
		linkField.change({
			transactionId: state.lastFormState.values.link.transactionId,
			isDirty: state.lastFormState.values.link.isDirty,
		});
	}
};

export const resetModified = (args, state, tools) => {
	Object.keys(state.fields).forEach((key) => {
		tools.resetFieldState(key);
	});
};
