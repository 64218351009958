import { combineReducers } from "redux";

import I18nReducer from "../../reducers/i18n.reducer";
import AuthReducer from "./auth.reducer";
import CompanyReducer from "../../reducers/company.reducer";
import MeetingReducer from "./meetings.reducer";
import UsersCacheReducer from "./usersCache.reducer";
import InvestorsReducer from "./investors.reducer";
import NotifyReducer from "./notify.reducer";
import DocumentsReducer from "./documents.reducer";
import UsersReducer from "./users.reducer";
import VotingsReducer from "./votings.reducer";
import LiveUpdateReducer from "./live-update.reducer";
import AttachmentsReducer from "./attachments.reducer";
import SharedCollectionsReducer from "./shared-collections.reducer";
import SubscriptionSlipReducer from "./subscription-slip.reducer";

/** Combined reducers. */
const rootReducer = combineReducers({
	i18n: I18nReducer,
	auth: AuthReducer,
	meetings: MeetingReducer,
	votings: VotingsReducer,
	company: CompanyReducer,
	usersCache: UsersCacheReducer,
	investors: InvestorsReducer,
	notify: NotifyReducer,
	documents: DocumentsReducer,
	users: UsersReducer,
	audit: LiveUpdateReducer,
	attachments: AttachmentsReducer,
	sharedCollections: SharedCollectionsReducer,
	subscriptionSlip: SubscriptionSlipReducer,
});

/** Export rootReducer. */
export default rootReducer;
