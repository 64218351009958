import React, { Component } from "react";
import { connect } from "react-redux";
import UserManagement from "../../../dumb-components/company/user-management-access/user-management-access";
import { Map, fromJS } from "immutable";
import {
	fetchResources,
	fetchRolePermissions,
	allow,
	removeAllow,
	fetchRoles,
	changeSelectedRoleValue,
	fetchPermissionsBundles,
	updateCurrentRolePermissions,
} from "../../../actions/user-management.actions";
import UserManagementContainer from "../../../components/company/user-management/user-management-container";
import RoleSelector from "../../../dumb-components/company/role-selector/role-selector";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";
import { DEFAULT_TEAM_USER } from "/shared/constants";

class UserManagementAccessContainer extends Component {
	state = {
		selectedRole: null,
		rowStates: Map(),
	};

	componentDidMount = () => {
		const { roles } = this.props;

		this.props.fetchResources();
		this.props.fetchRoles();
		this.props.fetchPermissionsBundles();

		if (roles) {
			const selectedRole = roles.find((r) => r.id === DEFAULT_TEAM_USER);
			this.setState({ selectedRole: fromJS(selectedRole) });
		}
	};

	componentDidUpdate = (prevProps) => {
		const { roles } = this.props;

		// Set User as default selected role as per IPS-5671
		if (roles !== prevProps.roles) {
			const selectedRole = roles.find((r) => r.id === DEFAULT_TEAM_USER);
			this.props.fetchRolePermissions(DEFAULT_TEAM_USER);
			this.setState({ selectedRole: fromJS(selectedRole) });
		}
	};

	onChange = (type, value, checked) => {
		const { roles } = this.props;

		if (type === "roleChanged") {
			const selectedRole = roles.find((r) => r.id === value);

			// Don't fetch roles if value is empty (ex on delete or backspace in Role Selector)
			if (value.size === 0) {
				this.setState({ selectedRole: undefined });
				return;
			}

			this.props.fetchRolePermissions(value);
			this.setState({ selectedRole: fromJS(selectedRole) });
			this.tooltipRef.hide();
			return;
		}

		if (type === "updateRowStates") {
			this.updateRowStates(value);
			return;
		}

		if (type === "onItemToggle") {
			const { rowStates } = this.state;
			const resourceId = value.get("id");
			const oldRowState = rowStates.getIn([resourceId, "open"]);
			const newRowStates = rowStates.setIn([resourceId, "open"], !oldRowState);

			this.setState({ rowStates: newRowStates });
			return;
		}

		if (type === "accessChanged") {
			const { bundles } = this.props;
			const roleId = this.state.selectedRole.get("id");
			const resourceId = value.get("id");

			if (!checked) {
				this.props.updateCurrentRolePermissions(roleId, resourceId, null);
				return;
			}

			const firstBundle = bundles.getIn([resourceId, 0, "value"]);
			this.props.updateCurrentRolePermissions(roleId, resourceId, firstBundle);
		}
	};

	onChangePermission = (resourceId, value) => {
		const { selectedRole } = this.state;
		const roleId = selectedRole.get("id");

		// Return on empty value
		if (!value) {
			return;
		}

		this.props.updateCurrentRolePermissions(roleId, resourceId, value);
	};

	updateRowStates = (resource) => {
		const { rowStates } = this.state;
		const resourceId = resource.get("id");

		if (rowStates.get(resourceId) === undefined) {
			const newRowState = rowStates.set(
				resourceId,
				Map({
					open: true,
					parentId: resource.get("parent"),
				}),
			);

			this.setState({ rowStates: newRowState });
		}
	};

	renderRoleSelector = () => {
		const { roles } = this.props;

		let rolesWithoutSuperAdmin =
			roles &&
			roles.filter((role) => {
				return role.id !== "aed45ce5-291f-438f-a47a-f547fdecc5ee";
			});

		if (!Array.isArray(rolesWithoutSuperAdmin)) {
			rolesWithoutSuperAdmin = [];
		}

		rolesWithoutSuperAdmin = fromJS(rolesWithoutSuperAdmin);

		return (
			<Tooltip
				tid="tooltip.company.management.access.select_role"
				ref={(ref) => (this.tooltipRef = ref)}
			>
				<RoleSelector
					selectedRole={this.state.selectedRole}
					onChange={this.onChange}
					roles={rolesWithoutSuperAdmin}
				/>
			</Tooltip>
		);
	};

	render = () => {
		const {
			roles,
			resources,
			bundles,
			permissions,
			permissionRequestPending,
			location,
		} = this.props;
		const { rowStates, selectedRole } = this.state;

		const rolesWithoutSuperAdmin = roles.filter((role) => {
			return role.id !== "aed45ce5-291f-438f-a47a-f547fdecc5ee";
		});

		console.log("RESOURCES", resources.toJS());

		return (
			<UserManagementContainer
				roleSelectorComponent={this.renderRoleSelector}
				location={location}
			>
				<UserManagement
					bundles={bundles}
					onChange={this.onChange}
					onChangePermission={this.onChangePermission}
					roles={rolesWithoutSuperAdmin}
					resources={resources}
					selectedRole={selectedRole}
					rowStates={rowStates}
					permissions={permissions}
					disableSwitches={permissionRequestPending}
				/>
			</UserManagementContainer>
		);
	};
}

function mapStoreToProps(state) {
	return {
		roles: state.company.userManagement.roles,
		resources: state.company.userManagement.resources,
		bundles: state.company.userManagement.bundles,
		permissions: state.company.userManagement.selectedRolePermissions,
		permissionRequestPending:
			state.company.userManagement.permissionRequestPending,
	};
}

const mapActionsToProps = {
	fetchResources,
	fetchRolePermissions,
	fetchRoles,
	updateCurrentRolePermissions,
	allow,
	removeAllow,
	changeSelectedRoleValue,
	fetchPermissionsBundles,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(UserManagementAccessContainer);
