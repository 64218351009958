import React, { Component } from "react";
import { connect } from "react-redux";
import { string } from "prop-types";

import { ButtonTransparentIcon } from "../../../../dumb-components/shared/button";
import { dismissOnboardingAlert } from "../../../../actions/user.actions";

class OnboardingAlertDismissControls extends Component {
	static propTypes = {
		alertId: string.isRequired,
	};

	dismissOnboardingAlert = () => {
		const { alertId, dismissOnboardingAlert } = this.props;

		if (alertId) {
			dismissOnboardingAlert(alertId);
		}
	};

	render = () => {
		return (
			<ButtonTransparentIcon
				onClick={this.dismissOnboardingAlert}
				icon="faTimes"
				size="sml"
				color="white"
				noHorizontalMargin
			/>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {
	dismissOnboardingAlert,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(OnboardingAlertDismissControls);
