import { fromJS } from "immutable";
import {
	COMPANY_CONTACTS_LIST,
	COMPANY_CONTACTS_CREATE,
	COMPANY_CONTACTS_EDIT,
	COMPANY_CONTACTS_DELETE,
	COMPANY_CONTACTS_CREATE_MULTIPLE,
	MEMBERS_AND_INVESTORS_LIST,
	MEMBERS_AND_INVESTORS_FILTER,
} from "../actions/types";
import {
	PEOPLE_MODAL_FILTERS_SHAREHOLDERS_STATUS,
	PEOPLE_MODAL_FILTERS_TEAMS,
} from "../containers/shared/people-modal/constants/filters";

function filterMembersAndInvestorsByTeam(state, filter) {
	if (!filter) {
		state = state.set(
			"filteredMembersAndInvestors",
			state.get("allMembersAndInvestors"),
		);
		return state;
	}

	const filteredMembersAndInvestors = state
		.get("allMembersAndInvestors")
		.filter((person) => {
			if (!person.get("roles")) {
				return false;
			}

			return person.get("roles").includes(filter);
		});

	return state.set("filteredMembersAndInvestors", filteredMembersAndInvestors);
}

function filterMembersAndInvestorsByShareholderStatus(state, filter) {
	if (!filter) {
		state = state.set(
			"filteredMembersAndInvestors",
			state.get("allMembersAndInvestors"),
		);
		return state;
	}

	const filteredMembersAndInvestors = state
		.get("allMembersAndInvestors")
		.filter((person) => {
			if (!person.get("investorStatus")) {
				return false;
			}

			if (filter === "SHOW_ALL") {
				return person.has("investorStatus");
			}

			return person.get("investorStatus") === filter;
		});

	return state.set("filteredMembersAndInvestors", filteredMembersAndInvestors);
}

const INITIAL_STATE = fromJS({
	companyContacts: [],
	allMembersAndInvestors: [],
	filteredMembersAndInvestors: [],
});

export default function (state = INITIAL_STATE, { type, payload }) {
	switch (type) {
		case COMPANY_CONTACTS_LIST:
			return state.set("companyContacts", payload);

		case COMPANY_CONTACTS_CREATE: {
			const index = state
				.get("companyContacts")
				.findIndex((person) => person.get("id") === payload.get("id"));

			if (index >= 0) {
				state = state.setIn(["companyContacts", index], payload);
			} else {
				state = state.update("companyContacts", (list) =>
					list.insert(0, payload),
				);
			}

			return state;
		}

		case COMPANY_CONTACTS_CREATE_MULTIPLE: {
			return state.update("companyContacts", (people) => {
				payload.forEach((person) => {
					const index = people.findIndex(
						(p) => p.get("id") === person.get("id"),
					);

					if (index >= 0) {
						people = people.set(index, person);
					} else {
						people = people.push(person);
					}
				});

				return people;
			});
		}

		case COMPANY_CONTACTS_EDIT: {
			const index = state
				.get("companyContacts")
				.findIndex((person) => person.get("id") === payload.get("id"));
			return state.setIn(["companyContacts", index], payload);
		}

		case COMPANY_CONTACTS_DELETE: {
			const index = state
				.get("companyContacts")
				.findIndex((person) => person.get("id") === payload);
			return state.setIn(["companyContacts", index, "isDeleted"], true);
		}

		case MEMBERS_AND_INVESTORS_LIST: {
			state = state.set("allMembersAndInvestors", payload);
			state = state.set("filteredMembersAndInvestors", payload);
			return state;
		}

		case MEMBERS_AND_INVESTORS_FILTER: {
			const { filters } = payload;

			for (const [key, filter] of Object.entries(filters)) {
				if (key === PEOPLE_MODAL_FILTERS_TEAMS) {
					state = filterMembersAndInvestorsByTeam(state, filter);
				} else if (key === PEOPLE_MODAL_FILTERS_SHAREHOLDERS_STATUS) {
					state = filterMembersAndInvestorsByShareholderStatus(state, filter);
				}
			}

			return state;
		}

		default:
			return state;
	}
}
