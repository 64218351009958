import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../../../mui-components/checkbox/checkbox";
import { getCompanyLogoUrl } from "../../../../../components/helpers/company.helper";
import Button from "../../../../../mui-components/button/button";
import { bool, func, string } from "prop-types";
import FooterRightControls from "../../../../../dumb-components/shared/modal/footer-right-controls";

const LogoModal = ({ open, value, onChange, onClose }) => {
	const [selectedLogotype, setSelectedLogotype] = useState(value);
	const { t } = useTranslation();
	const company = useSelector((state) => state.company.company);

	return (
		<Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
			<DialogTitle>{t("logo_selector.modal.select_logo")}</DialogTitle>
			<DialogContent>
				{Object.values(company.image).map((image) => {
					const logoUrl = getCompanyLogoUrl(company.id, image.filename, 100);
					return (
						<Box key={image.filename}>
							<Checkbox
								checked={selectedLogotype === image.filename}
								onChange={(event) =>
									event.target.checked && setSelectedLogotype(image.filename)
								}
							/>
							<img
								src={logoUrl}
								alt={company.name}
								style={{
									maxHeight: "75px",
									width: "auto",
									height: "unset",
									maxWidth: "unset",
								}}
							/>
						</Box>
					);
				})}
			</DialogContent>
			<DialogActions>
				<FooterRightControls>
					<Button color="positive" onClick={() => onChange(selectedLogotype)}>
						{t("logo_selector.modal.buttons.select")}
					</Button>
					<Divider orientation="vertical" />
					<Button onClick={onClose}>
						{t("logo_selector.modal.buttons.close")}
					</Button>
				</FooterRightControls>
			</DialogActions>
		</Dialog>
	);
};

LogoModal.propTypes = {
	open: bool,
	value: string,
	onChange: func.isRequired,
	onClose: func.isRequired,
};

export { LogoModal };
