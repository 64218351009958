import React, { Component } from "react";
import { connect } from "react-redux";
import { string, func, bool, number } from "prop-types";
import { Map } from "immutable";
import { MEETINGS_DURATION_OPTIONS } from "../../../constants/meetings";
import Duration from "../../../dumb-components/meetings/agenda-list/duration";

class DurationContainer extends Component {
	static propTypes = {
		fieldName: string,
		itemId: string,
		duration: number,
		onChange: func,
		readOnly: bool,
		usePortal: bool,
	};

	state = {
		isOpen: false,
		durationOptions: MEETINGS_DURATION_OPTIONS,
		inputValue: "",
	};

	componentDidMount() {
		const { duration } = this.props;
		let { durationOptions } = this.state;

		if (duration) {
			const val = parseInt(duration);
			let found = false;

			durationOptions.forEach((option) => {
				if (option.get("value") === val) {
					found = true;
				}
			});

			if (!found) {
				durationOptions = durationOptions.push(
					Map({
						value: val,
						label: val,
					}),
				);
			}
		}

		this.setState({
			durationOptions: durationOptions.sortBy((obj) => obj.get("value")),
		});

		document.addEventListener("mousedown", this.handleMouseDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleMouseDown, false);
	}

	handleMouseDown = (e) => {
		const { usePortal } = this.props;

		if (
			usePortal &&
			!this.node.contains(e.target) &&
			!this.dropdownMenuRef.contains(e.target)
		) {
			this.setState({ isOpen: false });
		} else if (!usePortal && !this.node.contains(e.target)) {
			this.setState({ isOpen: false });
		}
	};

	setRef = (ref) => {
		this.node = ref;
	};

	onChange = (val) => {
		const { onChange, fieldName } = this.props;

		onChange && onChange(fieldName, parseInt(val));
		this.setState({ isOpen: false });
	};

	onInputChange = (val) => {
		this.setState({ inputValue: val });
	};

	onCreate = () => {
		const { inputValue } = this.state;
		let { durationOptions } = this.state;

		if (inputValue.length === 0) {
			return;
		}

		const val = parseInt(inputValue);
		let found = false;

		durationOptions.forEach((option) => {
			if (option.get("value") === val) {
				found = true;
			}
		});

		if (!found) {
			durationOptions = durationOptions.push(
				Map({
					value: val,
					label: val,
				}),
			);
		}

		this.setState({
			durationOptions: durationOptions.sortBy((obj) => obj.get("value")),
			inputValue: "",
		});
		this.onChange(val);
	};

	onToggleStatusMenu = () => {
		this.setState((prevState) => {
			return { isOpen: !prevState.isOpen };
		});
	};

	render() {
		const { duration, readOnly, usePortal } = this.props;
		const { isOpen, durationOptions, inputValue } = this.state;

		return (
			<Duration
				onToggleMenu={this.onToggleStatusMenu}
				options={durationOptions}
				value={duration}
				onSelect={this.onChange}
				onCreate={this.onCreate}
				onChange={this.onInputChange}
				inputValue={inputValue}
				isOpen={isOpen}
				onSetRef={this.setRef}
				readOnly={readOnly}
				onSetDropdowMenuRef={
					usePortal && ((ref) => (this.dropdownMenuRef = ref))
				}
				clientRects={
					usePortal &&
					this.dropdownMenuRef &&
					this.dropdownMenuRef.getClientRects()[0]
				}
				parentRects={usePortal && this.node && this.node.getClientRects()[0]}
			/>
		);
	}
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {};

export default connect(mapStoreToProps, mapActionsToProps)(DurationContainer);
