import React, { useState } from "react";
import MUIDialog from "../../../mui-components/dialog/mui-dialog";
import { Form, Field } from "react-final-form";
import { TextField } from "mui-rff";
import { Box, DialogTitle, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { LanguageSwitcher } from "./language-switcher";
import RichTextEditor from "../../emissions/common/rich-text-editor";
import { useFeatureGroupContext } from "./feature-group.context";
import { useFeatureContext } from "./feature.context";
import { useTranslation } from "react-i18next";

function FeatureModal() {
	const { saveFeature } = useFeatureGroupContext();
	const { feature, stopEditingFeature, modalOpen } = useFeatureContext();
	const [language, setLanguage] = useState(
		useSelector((state) => state.i18n.language.substr(0, 2)),
	);
	const { t } = useTranslation();

	if (!modalOpen) {
		return null;
	}

	const onSubmit = (values) => {
		saveFeature(values, stopEditingFeature);
	};

	const validate = (values) => {
		const errors = {};

		if (!values.title) {
			if (language === "en") {
				errors.title = {
					en: t(
						"investments.feature.modal.form.validation.field.title_required",
						{ lng: language },
					),
				};
			} else {
				errors.title = {
					sv: t(
						"investments.feature.modal.form.validation.field.title_required",
						{ lng: language },
					),
				};
			}
		}

		return errors;
	};

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={feature}
			validate={validate}
			render={(formProps) => {
				const { handleSubmit } = formProps;
				return (
					<MUIDialog
						isOpen={true}
						onClose={stopEditingFeature}
						onConfirm={handleSubmit}
						confirmButtonTid="save_and_close"
						maxWidth="sm"
						fullWidth
						header={
							<DialogTitle component="div" sx={{ py: "14.5px" }}>
								<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
									<Box sx={{ flex: 1 }}>
										{t("investments.feature.modal.title.manage_feature")}
									</Box>
									<Box>
										<LanguageSwitcher
											initialLanguage={language}
											onChange={(lang) => setLanguage(lang)}
										/>
									</Box>
								</Box>
							</DialogTitle>
						}
					>
						<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
							<TextField
								name={`title.${language}`}
								label={t("investments.feature.modal.field.title", {
									lng: language,
								})}
							/>
							{language === "sv" && (
								<Field name={"body.sv"}>
									{({ input }) => (
										<RichTextEditor
											onChange={input.onChange}
											label={t("investments.feature.modal.field.text", {
												lng: language,
											})}
											value={input.value}
											height="164px"
										/>
									)}
								</Field>
							)}
							{language === "en" && (
								<Field name={`body.en`}>
									{({ input }) => (
										<RichTextEditor
											onChange={input.onChange}
											label={t("investments.feature.modal.field.text", {
												lng: language,
											})}
											value={input.value}
											height="164px"
										/>
									)}
								</Field>
							)}
							<TextField
								name="link.url"
								label={t("investments.feature.modal.field.link", {
									lng: language,
								})}
							/>
							<TextField
								name="size"
								label={t("investments.feature.modal.field.size", {
									lng: language,
								})}
								select
							>
								<MenuItem value="25">1/4</MenuItem>
								<MenuItem value="50">1/2</MenuItem>
							</TextField>
						</Box>
					</MUIDialog>
				);
			}}
		/>
	);
}

export { FeatureModal };
