import React, { useEffect, useState } from "react";
import { alpha, useTheme } from "@mui/material";
import htmlToDraft from "html-to-draftjs";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const RichTextEditor = (props) => {
	const theme = useTheme();
	const draft = htmlToDraft(props.value || "");
	const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
	const [editorState, setEditorState] = useState(
		EditorState.createWithContent(contentState),
	);

	useEffect(() => {
		if (!props.updateValue) return;
		const draft = htmlToDraft(props.updateValue);
		const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
		setEditorState(EditorState.createWithContent(contentState));
	}, [props.updateValue, setEditorState]);

	return (
		<>
			<fieldset
				style={{
					border: `1px solid ${theme.palette.divider}`,
					borderRadius: theme.shape.borderRadius,
					padding: "0 8px",
					minHeight: props.height || "auto",
				}}
			>
				<legend
					style={{
						all: "unset",
						padding: "0 0.5rem",
						paddingBottom: "0.25rem",
						fontSize: "0.95rem",
						color: alpha(theme.palette.common.black, 0.6),
					}}
				>
					{props.label}
					{props.required ? "*" : ""}
				</legend>
				<Editor
					readOnly={props.readOnly}
					wrapperStyle={{
						border: "none",
						borderRadius: theme.shape.borderRadius,
						padding: theme.spacing(1),
					}}
					editorStyle={{
						padding: "0 1rem",
						...(props.editorStyle || {}),
					}}
					toolbarStyle={{
						display: props.readOnly ? "none" : "flex",
						borderRadius: 0,
						border: "none",
						backgroundColor: "transparent",
					}}
					toolbar={{
						options: ["inline", "list"],
						inline: {
							options: ["bold", "italic", "underline"],
						},
						list: {
							options: ["unordered", "ordered"],
						},
					}}
					editorState={editorState}
					onEditorStateChange={(newEditorState) => {
						setEditorState(newEditorState);
						props.onChange(
							draftToHtml(convertToRaw(newEditorState.getCurrentContent())),
						);
					}}
				/>
			</fieldset>
		</>
	);
};

export default RichTextEditor;
