import React from "react";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";
import FooterRightControls from "../../../dumb-components/shared/modal/footer-right-controls";
import { TransparentButton } from "../../../dumb-components/shared/button-v2";
import { usePeopleModalContext } from "../../shared/people-modal/people-modal.context";

function NotifyMultipleUsersModalFooter({ onSave, onCancel }) {
	let { selectedPeople, isLoading, genericOnSave } = usePeopleModalContext();
	const nextButtonIsDisabled = selectedPeople.size === 0;

	return (
		<FooterRightControls>
			<Tooltip
				tid="notify_multiple_users.footer.no_selected_users"
				delayShop="instant"
				active={nextButtonIsDisabled}
			>
				<TransparentButton
					tid="next"
					onClick={() => genericOnSave(onSave)}
					isLoading={isLoading}
					disabled={nextButtonIsDisabled}
				/>
			</Tooltip>
			<TransparentButton
				tid="generic.form.cancel"
				textColor="midGrey"
				onClick={() => onCancel(true)}
				disabled={isLoading}
			/>
		</FooterRightControls>
	);
}

export { NotifyMultipleUsersModalFooter };
