import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, bool, oneOfType } from "prop-types";
import { Map } from "immutable";

import { getPublishWithEsignTooltipData } from "../../../../../components/helpers/meeting.helper";

import DocumentEsignUsersModal from "../../../../documents/document-esign-users-modal.container";
import {
	updateDocumentLocal,
	saveDocument,
} from "../../../../../actions/documents.actions";
import { fetchAttachedDocument } from "../../../../../actions/attachments.actions";
import { changePublishingType } from "../../../../../actions/meetings.actions";
import { setActiveTab } from "../../../../../actions/notify.actions";
import history from "../../../../../interfaces/history";

import {
	PROTOCOL_PUBLISHING_TYPE_ESIGNING,
	MEETING_TYPE_SMART,
	OBJ_TYPE_MEETING,
	MEETING_TYPE_STANDARD,
} from "/shared/constants";

import { TAB_STANDARD_MEETING_PROTOCOL } from "../../../../../constants/tabs";
import withResolvedProps from "@/hocs/withResolvedProps";
import useSubscriptionHelper from "@/hooks/useSubscriptionHelper";

class PublishWithEsignContainer extends Component {
	state = {
		isOpen: false,
	};

	static propTypes = {
		renderComponent: func,
		redirectTo: oneOfType([string, bool]),
	};

	componentDidMount = () => {
		const { document, protocolMetadata, meetingId, fetchAttachedDocument } =
			this.props;

		if (
			meetingId &&
			(!document || document.get("id") !== protocolMetadata?.get("documentId"))
		) {
			fetchAttachedDocument(
				OBJ_TYPE_MEETING,
				meetingId,
				protocolMetadata?.get("documentId"),
				true,
			);
		}
	};

	componentDidUpdate = () => {
		const {
			document,
			protocolMetadata,
			meetingId,
			fetchAttachedDocument,
			isStandardMeeting,
		} = this.props;
		if (
			isStandardMeeting &&
			meetingId &&
			protocolMetadata &&
			(!document || document.get("id") !== protocolMetadata?.get("documentId"))
		) {
			fetchAttachedDocument(
				OBJ_TYPE_MEETING,
				meetingId,
				protocolMetadata?.get("documentId"),
				true,
			);
		}
	};

	openModal = () => {
		const { isStandardMeeting, setActiveTab } = this.props;

		if (isStandardMeeting) {
			setActiveTab(TAB_STANDARD_MEETING_PROTOCOL);
		}

		this.setState({ isOpen: true });
	};

	closeModal = (isCanceled = false) => {
		const { isSmartMeeting, redirectTo } = this.props;

		this.setState({ isOpen: false });

		if (!isCanceled && isSmartMeeting && redirectTo) {
			history.push(redirectTo);
		}
	};

	setPublishingTypeToEsigning = () => {
		const { changePublishingType, meetingId } = this.props;
		changePublishingType(meetingId, PROTOCOL_PUBLISHING_TYPE_ESIGNING);
	};

	setSignees = (field, val) => {
		const { updateDocumentLocal, saveDocument } = this.props;
		let { document } = this.props;

		document = document.set(field, val);

		updateDocumentLocal(document);
		saveDocument(document);
	};

	onSave = (field, val) => {
		this.setSignees(field, val);
		this.setPublishingTypeToEsigning();
	};

	getPublishWithEsignTooltipData = () => {
		const {
			eSigningEnabled,
			protocolMetadata,
			publishingType,
			protocolPublished,
			isStandardMeeting,
			isModerator,
		} = this.props;

		return getPublishWithEsignTooltipData({
			eSigningEnabled,
			protocolMetadata,
			publishingType,
			protocolPublished,
			isStandardMeeting,
			isModerator,
		});
	};

	render = () => {
		const { renderComponent } = this.props;
		const { isOpen } = this.state;
		const { TOOLTIP_STATES, activeState, isDisabled } =
			this.getPublishWithEsignTooltipData();

		return (
			<>
				{renderComponent({
					openSigneesModal: this.openModal,
					tooltip: {
						TOOLTIP_STATES,
						activeState,
						isDisabled,
					},
				})}

				{isOpen && (
					<DocumentEsignUsersModal
						onChange={this.onSave}
						overrideIsOpen={true}
						overrideCloseModal={this.closeModal}
					/>
				)}
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		document: store.documents.get("document", Map()),
		meetingId: store.meetings.getIn(["meeting", "id"]),
		protocolMetadata: store.meetings.getIn(["meeting", "protocolData"]),
		publishingType: store.meetings.getIn(["meeting", "publishingType"]),
		isSmartMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) === MEETING_TYPE_SMART,
		isStandardMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) ===
			MEETING_TYPE_STANDARD,
		protocolPublished: store.meetings.getIn([
			"meeting",
			"computedValues",
			"protocolPublished",
		]),
		isModerator: store.meetings.getIn([
			"meeting",
			"computedValues",
			"isModerator",
		]),
	};
};

const mapActionsToProps = {
	updateDocumentLocal,
	saveDocument,
	changePublishingType,
	fetchAttachedDocument,
	setActiveTab,
};

const PublishWithEsignContainerConnected = connect(
	mapStoreToProps,
	mapActionsToProps,
)(PublishWithEsignContainer);

export default withResolvedProps(() => {
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	return { eSigningEnabled: !!subscriptionHelper?.eSigningEnabled };
})(PublishWithEsignContainerConnected);
