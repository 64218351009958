import { fromJS, List } from "immutable";
import {
	CAPITAL_INSURANCE_LIST,
	CAPITAL_INSURANCE_CREATE,
	COMPANY_RESET_REDUCER,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	capitalInsurances: List(),
});

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case CAPITAL_INSURANCE_LIST:
			return state.set("capitalInsurances", action.payload);

		case CAPITAL_INSURANCE_CREATE:
			return state.update("capitalInsurances", (capitalInsurances) => {
				return capitalInsurances.push(action.payload);
			});

		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE;
		}
	}

	return state;
}
