import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, string, func, oneOf, object } from "prop-types";
import { List, Map } from "immutable";
import {
	fetchTask,
	updateTaskLocal,
	saveTask,
	createTask,
	deleteTask,
	deleteTaskLocal,
	fetchExternalTask,
	createExternalTask,
	saveExternalTask,
	deleteExternalTask,
} from "../../actions/tasks.actions";
import { deleteDocumentsExternal } from "../../actions/documents.actions";
import { deleteComment } from "../../actions/comments.actions";
import { fetchDefaultPermissions } from "../../actions/projects.actions";
import { fetchCompanyUsersAsSelectOptions } from "../../actions/company.actions";
import TaskActivityInformation from "../../components/framework/activities-log";
import TagsSelectContainer from "../shared/tags-select.container";
import CommentsListContainer from "../comments/comments-list.container";
import CommentsCreatorContainer from "../comments/comments-creator.container";
import AttachmentsByObjIdContainer from "../shared/attachments-by-objid.container";
import { renderCommonAttachmentDropdown } from "../documents/attachments/predefined-dropdown-render-functions";
import TaskCreateEditModal from "../../dumb-components/tasks/task-create-edit-modal/task-create-edit-modal";
import { DropDown } from "../../mui-components/dropdown/DropDown";
import Button from "../../mui-components/button/button";
import { FormattedMessage } from "react-intl";
import Divider from "@mui/material/Divider";
import AssigneeContainer from "../shared/assignee.container";
import EditorContainer from "../shared/editor.container";
import FooterRightControls from "../../dumb-components/shared/modal/footer-right-controls";
import { TransparentButton } from "../../dumb-components/shared/button-v2";

import {
	LIVE_TASK_UPDATE,
	LIVE_TASK_DELETE,
	LIVE_TASK_TRANSFER,
	LIVE_TASK_EXTERNAL_UPDATE,
	LIVE_TASK_EXTERNAL_DELETE,
} from "../../constants/live-update";

import { OBJ_TYPE_TASK } from "/shared/constants";
import MenuItemIcon from "../../mui-components/dropdown/menu/menu-item-icon";

class TaskCreateEditModalContainer extends Component {
	state = {
		backupTask: null,
		companyUsers: List(),
		newlyAddedAttachmentIds: List(),
		newlyAddedCommentsIds: List(),
		isLoading: false,
	};

	static propTypes = {
		isOpen: bool,
		taskId: string,
		onClose: func,
		objId: string,
		objType: string,
		mode: oneOf(["normal", "external"]),
		taskMode: oneOf(["new", "edit"]),
		company: object,
		onDeleteTask: func,
	};

	static defaultProps = {
		isOpen: false,
		mode: "normal",
		taskMode: "edit",
	};

	controlsDropdownRef = null;

	componentDidMount() {
		const { mode, company } = this.props;
		if (mode === "external") {
			this.fetchUsers(company.id);
		}
	}

	componentDidUpdate = (prevProps) => {
		const {
			taskId,
			fetchTask,
			fetchExternalTask,
			company,
			isOpen,
			fetchDefaultPermissions,
			task,
			defaultPermissions,
			mode,
			objId,
			selectedProjectId,
		} = this.props;

		if (!isOpen) {
			return;
		}

		this.checkLiveUpdateEvents(prevProps);

		if (
			mode === "normal" &&
			(!defaultPermissions ||
				defaultPermissions.size === 0 ||
				(prevProps.taskId !== taskId &&
					taskId !== null &&
					taskId !== undefined))
		) {
			fetchDefaultPermissions(
				task ? task.get("projectId") : selectedProjectId || null,
				false,
			);
		}

		// TaskID was changed
		if (
			prevProps.taskId !== taskId &&
			taskId !== null &&
			taskId !== undefined
		) {
			if (mode === "external") {
				fetchExternalTask(objId, taskId, company.id, () => {
					this.setState({ backupTask: task });
				});
			} else {
				fetchTask(taskId, (task) => {
					this.setState({ backupTask: task });
				});
			}
		}
	};

	checkLiveUpdateEvents = () => {
		const {
			audit,
			taskId,
			fetchTask,
			fetchExternalTask,
			mode,
			onClose,
			deleteTaskLocal,
			company,
		} = this.props;
		const componentObjId = this.props.objId;
		const selectedTaskId = taskId;
		const taskUpdate = audit.get(LIVE_TASK_UPDATE);
		const taskDelete = audit.get(LIVE_TASK_DELETE);
		const taskTransfer = audit.get(LIVE_TASK_TRANSFER);
		const taskExtUpdate = audit.get(LIVE_TASK_EXTERNAL_UPDATE);
		const taskExtDelete = audit.get(LIVE_TASK_EXTERNAL_DELETE);

		// Task was edited, update object
		if (taskUpdate && taskUpdate.get("refresh") === true && mode === "normal") {
			const objId = taskUpdate.get("objId");

			if (selectedTaskId === objId) {
				fetchTask(selectedTaskId);
			}
		} else if (
			taskExtUpdate &&
			taskExtUpdate.get("refresh") === true &&
			mode === "external"
		) {
			const objId = taskExtUpdate.get("objId");
			const objIdInMetadata = taskExtUpdate.getIn(["metadata", "objId"]);

			if (selectedTaskId === objId && componentObjId === objIdInMetadata) {
				fetchExternalTask(componentObjId, selectedTaskId, company.id);
			}
		}

		// Task was deleted, close modal
		if (taskDelete && taskDelete.get("refresh") === true && mode === "normal") {
			deleteTaskLocal(taskId, () => {
				this.destroyTask();
				onClose();
			});
		} else if (
			taskExtDelete &&
			taskExtDelete.get("refresh") === true &&
			mode === "external"
		) {
			const objId = taskExtDelete.get("objId");
			const objIdInMetadata = taskExtDelete.getIn(["metadata", "objId"]);

			if (selectedTaskId === objId && componentObjId === objIdInMetadata) {
				deleteTaskLocal(taskId, () => {
					this.destroyTask();
					onClose();
				});
			}
		}

		// Task was transfered, update object
		if (taskTransfer && taskTransfer.get("refresh") === true) {
			const objId = taskTransfer.get("objId");
			const objIdInMetadata = taskTransfer.getIn(["metadata", "objId"]);

			if (mode === "normal" && selectedTaskId === objId) {
				fetchTask(selectedTaskId);
			} else if (
				mode === "external" &&
				selectedTaskId === objId &&
				componentObjId === objIdInMetadata
			) {
				fetchExternalTask(componentObjId, selectedTaskId, company.id);
			}
		}
	};

	fetchUsers = (companyId) => {
		const { fetchCompanyUsersAsSelectOptions } = this.props;

		if (!companyId) {
			return;
		}

		fetchCompanyUsersAsSelectOptions(companyId, (companyUsers) => {
			this.setState({ companyUsers });
		});
	};

	updateTaskLocal = (task) => {
		const { updateTaskLocal } = this.props;
		updateTaskLocal(task);
	};

	onChange = (field, value) => {
		let { task } = this.props;

		task = task.set(field, value);
		this.updateTaskLocal(task);
	};

	onTagsChange = (tags) => {
		this.onChange("tags", tags);
	};

	onSave = () => {
		const {
			saveTask,
			saveExternalTask,
			onClose,
			taskId,
			createTask,
			createExternalTask,
			mode,
			objId,
			objType,
		} = this.props;
		let { task } = this.props;

		// Reset Newly uploaded attachments and added comments
		this.setState({
			newlyAddedAttachmentIds: List(),
			newlyAddedCommentsIds: List(),
			isLoading: true,
		});

		// New Task
		if (!taskId) {
			const callback = () => {
				this.setState({ isLoading: false });
				this.destroyTask();
				// The second argument redirects browser to the new task so that it is active in the Tasks section.
				// Do not implement the redirect functionality in the container used in other sections where tasks can be created in this modal.
				onClose(task.get("id"), true);
			};

			if (mode === "external") {
				createExternalTask(objType, objId, task, callback);
			} else {
				createTask(task, callback);
			}
		} else {
			const callback = () => {
				this.setState({ isLoading: false });
				this.destroyTask();
				onClose();
			};

			// Edit
			if (mode === "external") {
				saveExternalTask(objId, task, callback);
			} else {
				saveTask(task, callback);
			}
		}
	};

	deleteCallback = () => {
		const { onClose } = this.props;
		this.setState({ isLoading: false });
		this.destroyTask();
		onClose();
	};

	onDelete = () => {
		const { task, deleteTask, deleteExternalTask, mode, objId, onDeleteTask } =
			this.props;
		const taskId = task.get("id");

		this.controlsDropdownRef && this.controlsDropdownRef.onToggleMenu();
		this.setState({ isLoading: true });
		onDeleteTask && onDeleteTask(taskId);

		if (mode === "external") {
			deleteExternalTask(objId, taskId, this.deleteCallback);
		} else {
			deleteTask(taskId, this.deleteCallback);
		}
	};

	onCancel = () => {
		const {
			onClose,
			updateTaskLocal,
			taskId,
			deleteDocumentsExternal,
			deleteComment,
		} = this.props;
		const { backupTask, newlyAddedAttachmentIds, newlyAddedCommentsIds } =
			this.state;

		updateTaskLocal(backupTask);

		// New attachments were added after the modal was opened, delete these
		if (newlyAddedAttachmentIds.size > 0) {
			newlyAddedAttachmentIds.forEach((docId) => {
				deleteDocumentsExternal(docId, OBJ_TYPE_TASK, taskId);
			});
		}

		// New comments were added after the modal was opened, delete these
		if (newlyAddedCommentsIds.size > 0) {
			newlyAddedCommentsIds.forEach((commentId) => {
				deleteComment(taskId, commentId);
			});
		}

		// Reset newly uploaded attachments and comments
		this.setState({
			newlyAddedAttachmentIds: List(),
			newlyAddedCommentsIds: List(),
		});

		this.destroyTask();

		onClose();
	};

	destroyTask = () => {
		const { updateTaskLocal } = this.props;

		updateTaskLocal(null);
	};

	onUploadAttachment = ({ documentId }) => {
		let { newlyAddedAttachmentIds } = this.state;
		newlyAddedAttachmentIds = newlyAddedAttachmentIds.push(documentId);
		this.setState({ newlyAddedAttachmentIds });
	};

	onDeleteAttachment = (file) => {
		let { newlyAddedAttachmentIds } = this.state;
		const docId = file.get("id");
		const index = newlyAddedAttachmentIds.findIndex((id) => id === docId);
		newlyAddedAttachmentIds = newlyAddedAttachmentIds.delete(index);
		this.setState({ newlyAddedAttachmentIds });
	};

	onCommentCreated = (objType, objId, commentId) => {
		let { newlyAddedCommentsIds } = this.state;
		newlyAddedCommentsIds = newlyAddedCommentsIds.push(commentId);
		this.setState({ newlyAddedCommentsIds });
	};

	onCommentDeleted = (objType, objId, commentId) => {
		let { newlyAddedCommentsIds } = this.state;
		const index = newlyAddedCommentsIds.findIndex((id) => id === commentId);
		newlyAddedCommentsIds = newlyAddedCommentsIds.delete(index);
		this.setState({ newlyAddedCommentsIds });
	};

	renderTagsSelectContainer = () => {
		const { task } = this.props;

		return (
			<TagsSelectContainer
				onTagsChange={this.onTagsChange}
				value={task && task.get("tags")}
				menuPortalTarget={document.body}
			/>
		);
	};

	renderAttachments = () => {
		const { task } = this.props;

		return (
			<AttachmentsByObjIdContainer
				objType={OBJ_TYPE_TASK}
				objId={task && task.get("id")}
				panelType="inline"
				onUpload={this.onUploadAttachment}
				renderAttachmentDropdown={(defaultProps) => {
					return renderCommonAttachmentDropdown(defaultProps, {
						onDeleteCallback: this.onDeleteAttachment,
					});
				}}
			/>
		);
	};

	renderComments = () => {
		const { task } = this.props;

		return (
			<CommentsListContainer
				objType={OBJ_TYPE_TASK}
				objId={task && task.get("id")}
				panelType="inline"
				maxVisibleComments={5}
				onCommentDeleted={this.onCommentDeleted}
				onCommentCreated={this.onCommentCreated}
			/>
		);
	};

	renderTaskActivityInformation = () => {
		const { task } = this.props;

		return (
			<TaskActivityInformation
				activities={task && task.get("activities")}
				panelType="inline"
				marginBottom={true}
			/>
		);
	};

	renderControlButtons = () => {
		const { taskId } = this.props; // Edit mode if present
		const { isLoading } = this.state;

		return (
			<FooterRightControls>
				<TransparentButton
					tid="generic.form.save"
					onClick={this.onSave}
					isLoading={isLoading}
				/>
				{taskId ? (
					<DropDown
						button={({ params }) => (
							<Button {...params}>
								<FormattedMessage id="modal.footer.dropdown_menu.more_options" />
							</Button>
						)}
						alignMenu="right"
					>
						<MenuItemIcon
							onClick={this.onCancel}
							icon="faTimes"
							listItemTid="modal.footer.dropdown_menu.cancel_and_close"
						/>
						<Divider />
						<MenuItemIcon
							onClick={this.onDelete}
							icon="faTrashAlt"
							listItemTid="tasks.modal.btn.delete_task"
							disabled={isLoading}
						/>
					</DropDown>
				) : (
					<TransparentButton
						tid="generic.form.cancel"
						textColor="midGrey"
						onClick={this.onCancel}
					/>
				)}
			</FooterRightControls>
		);
	};

	renderCommentsCreator = () => {
		const { task } = this.props;

		return (
			<CommentsCreatorContainer
				objType={OBJ_TYPE_TASK}
				objId={task && task.get("id")}
				onCommentCreated={this.onCommentCreated}
			/>
		);
	};

	renderAssigneeContainer = (readOnly) => {
		const { task, projects, defaultPermissions } = this.props;
		const project =
			task && projects ? projects.get(task.get("projectId")) : null;

		return (
			<AssigneeContainer
				fieldName="assigne"
				onChange={this.onChange}
				value={task ? task.get("assigne") : null}
				readOnly={readOnly}
				permissions={
					project ? project.get("permissions", Map()) : defaultPermissions
				}
				isRoot={!project}
			/>
		);
	};

	renderEditorContainer = () => {
		const { task } = this.props;

		return (
			<EditorContainer
				fieldName="description"
				objId={task && task.get("id")}
				contentState={task && task.get("description")}
				onChange={this.onChange}
				editorHeight="36px"
			/>
		);
	};

	render = () => {
		const { isOpen, i18n, task, mode } = this.props;
		const { companyUsers } = this.state;

		// MoveTaskModalContainer not rendering as of IPS-5226
		// Removed due to not being used.
		return (
			<TaskCreateEditModal
				isOpen={isOpen}
				i18n={i18n}
				task={task || Map()}
				onChange={this.onChange}
				tagsSelectorRenderer={this.renderTagsSelectContainer}
				fileUploadRenderer={this.renderAttachments}
				commentsListRenderer={this.renderComments}
				controlButtonsRenderer={this.renderControlButtons}
				activityPanelRenderer={this.renderTaskActivityInformation}
				renderAssigneeContainer={
					mode === "external" ? null : this.renderAssigneeContainer
				}
				users={companyUsers}
				renderEditorContainer={this.renderEditorContainer}
				onSave={this.onSave}
				onCancel={this.onCancel}
				onDelete={this.onDelete}
			/>
		);
	};
}

function mapStoreToProps(store) {
	return {
		task: store.tasks.get("task"),
		i18n: store.i18n,
		usersCache: store.usersCache.get("usersCache"),
		selectedProjectId: store.projects.get("selectedProjectId"),
		projects: store.projects.get("projects"),
		defaultPermissions: store.projects.get("defaultPermissions"),
		audit: store.audit.get("tasks"),
	};
}

const mapActionsToProps = {
	saveTask,
	fetchTask,
	updateTaskLocal,
	deleteTask,
	deleteDocumentsExternal,
	deleteTaskLocal,
	createTask,
	deleteComment,
	fetchDefaultPermissions,
	fetchExternalTask,
	createExternalTask,
	saveExternalTask,
	fetchCompanyUsersAsSelectOptions,
	deleteExternalTask,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(TaskCreateEditModalContainer);
