import React, { PureComponent } from "react";
import { string, func, array } from "prop-types";

import { Padding } from "styled-components-spacing";
import Grid from "styled-components-grid";

import Panel from "../shared/panel/panel";
import Label from "../shared/label/label";
import Input from "../shared/input/input";
import CountrySelect from "../shared/country-select/country-select";
import DatePicker from "../../../app/dumb-components/shared/datepicker/datepicker";

export default class CompanyDetailsLeft extends PureComponent {
	static propTypes = {
		language: string,
		onChange: func,
		companyName: string,
		orgNumber: string,
		regDate: string,
		// mainContactName: string,
		// mainContactPhone: string,
		// mainContactEmail: string,
		addresses: array,
		companyCountry: string,
	};

	static defaultProps = {
		companyName: "",
		orgNumber: "",
		regDate: "",
		// mainContactName: '',
		// mainContactPhone: '',
		// mainContactEmail: ''
	};

	render = () => {
		const {
			onChange,
			language,
			companyName,
			orgNumber,
			regDate,
			// mainContactName,
			// mainContactPhone,
			// mainContactEmail,
			addresses,
			companyCountry,
		} = this.props;

		let padrIndex = addresses.findIndex((address) => address.type === "padr");
		padrIndex = padrIndex !== -1 ? padrIndex : 0;

		return (
			<>
				{/* Company Details Panel */}
				<Panel title="company.profile.details" marginBottom>
					<Padding all={3}>
						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid="company.profile.details.name">
										<Input
											fieldName="name"
											placeholderTid="company.profile.details.name"
											onChange={onChange}
											value={companyName}
										/>
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>

						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid="company.profile.details.org_number">
										<Input
											placeholderTid="company.profile.details.org_number"
											value={orgNumber}
											disabled
										/>
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>

						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid="company.profile.details.registration_date">
										<DatePicker
											calendarPlacement="bottom-end"
											value={regDate}
											onChange={onChange}
											language={language}
											fieldName={"dateOfReg"}
											disabled={companyCountry === "SE"}
										/>
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>
					</Padding>
				</Panel>

				{/* Contact Information Panel */}
				{/*<Panel title='company.profile.panel.contact.title' marginBottom>
					<Padding all={3}>

						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid='company.profile.details.main_contact'>
										<Input
											fieldName='mainContact.name'
											placeholderTid='generic.name'
											onChange={onChange}
											value={mainContactName} />
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>

						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid='generic.telephone'>
										<Input
											fieldName='mainContact.phone'
											placeholderTid='generic.telephone'
											onChange={onChange}
											value={mainContactPhone} />
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>

						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid='generic.email'>
										<Input
											fieldName='mainContact.email'
											placeholderTid='generic.email'
											onChange={onChange}
											value={mainContactEmail} />
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>
					</Padding>
				</Panel>*/}

				{/* Address Information Panel - Main */}
				<Panel title="company.profile.details.registered_address" marginBottom>
					<Padding all={3}>
						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid="generic.address">
										<Input
											fieldName={`addresses.${padrIndex}.street`}
											placeholderTid="generic.address"
											onChange={onChange}
											value={addresses[padrIndex].street}
										/>
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>

						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid="generic.post_code">
										<Input
											fieldName={`addresses.${padrIndex}.postalCode`}
											placeholderTid="generic.post_code"
											onChange={onChange}
											value={addresses[padrIndex].postalCode}
										/>
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>

						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid="generic.city">
										<Input
											fieldName={`addresses.${padrIndex}.city`}
											placeholderTid="generic.city"
											onChange={onChange}
											value={addresses[padrIndex].city}
										/>
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>

						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid="country">
										<CountrySelect
											fieldName={`addresses.${padrIndex}.country`}
											placeholderTid="country"
											language={language}
											onChange={onChange}
											value={addresses[padrIndex].country}
										/>
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>
					</Padding>
				</Panel>
			</>
		);
	};
}
