import React, { Component } from "react";
import { connect } from "react-redux";
import { List, Map } from "immutable";
import immutablePropTypes from "react-immutable-proptypes";
import { string, func, bool } from "prop-types";
import moment from "../../../modules/moment.module";
import {
	createAgendaItem,
	reorderAgendaItem,
	saveAgendaItem,
} from "../../../actions/meetings.actions";
import Box from "../../../dumb-components/shared/layout/box/box";
import AgendaList from "../../../dumb-components/meetings/agenda-list/agenda-list";
import AgendaItemContainer from "./agenda-item.container";
import NewListItemButton from "../../../dumb-components/shared/new-list-item-button/new-list-item-button";
import AgendaItem from "../../../dumb-components/meetings/agenda-list/agenda-item";
import { hasMeetingStarted } from "../../../components/helpers/meeting.helper";
import AgendaDropdownItemContainer from "./meetings-agenda-item-dropdown.container";
import history from "../../../interfaces/history";

class AgendaListContainer extends Component {
	static propTypes = {
		basePath: string,
		querystr: string,
		items: immutablePropTypes.list,
		onMeetingChange: func,
		readOnly: bool,
		isSimpleMode: bool,
		showProgressIcon: bool,
		agendaItemIdInUrl: string,
	};

	static defaultProps = {
		readOnly: false,
		items: List(),
	};

	state = {
		agendaItemInEditMode: null,
		ordinaryItems: List(),
		postItems: List(),
		suggestedItems: List(),
		archivedItems: List(),
	};

	componentDidMount() {
		const { items, agendaItemIdInUrl } = this.props;
		this.parseItems();

		if (items && !agendaItemIdInUrl) {
			this.goToFirstItem();
		}
	}

	componentDidUpdate(prevProps) {
		const { items, agendaItemIdInUrl, userId } = this.props;

		if (prevProps.items !== items) {
			this.parseItems();
		}
		//because you can't be 100% sure that userId exist on did mount
		if (prevProps.userId !== userId) {
			this.parseItems();
		}

		if (
			(!prevProps.items || prevProps.items.size === 0) &&
			items &&
			!agendaItemIdInUrl
		) {
			this.goToFirstItem();
		}
	}

	goToFirstItem = () => {
		const { items } = this.props;
		if (items && items.size > 0) {
			this.onAgendaItemClick(items.first().get("id"));
		}
	};

	parseItems = () => {
		let { items, userId } = this.props;

		if (!items || !userId) {
			return;
		}

		const lists = items.groupBy((item) => {
			if (item.get("archived")) {
				if (
					item.get("usersWithNotes") &&
					item.get("usersWithNotes").size &&
					item.get("usersWithNotes").includes(userId)
				) {
					return "archivedItems";
				}
				return;
			} else if (item.get("postItem")) {
				return "postItems";
			} else if (item.get("isSuggested")) {
				return "suggestedItems";
			} else {
				return "ordinaryItems";
			}
		});

		this.setState({
			ordinaryItems: lists.get("ordinaryItems"),
			postItems: lists.get("postItems"),
			suggestedItems: lists.get("suggestedItems"),
			archivedItems: lists.get("archivedItems"),
		});
	};

	getItemParentIndex = (itemId) => {
		const { items } = this.props;
		let parentIndex;

		items.forEach((item, index) => {
			if (item.get("agendaItems")) {
				item.get("agendaItems", List()).forEach((subitem) => {
					if (subitem.get("id") === itemId) {
						parentIndex = index;
					}
				});
			}
		});

		return parentIndex;
	};

	shouldDisplayNewItemButton = (listId, level) => {
		const { items, readOnly } = this.props;

		if (readOnly) {
			return false;
		}

		if (!items || items.size === 0) {
			return true;
		}

		return level === 1 && listId === "ordinaryItems";
	};

	onAgendaItemClick = (id) => {
		const { basePath, querystr, history } = this.props;

		this.setState({ agendaItemInEditMode: id });
		history.push({
			pathname: `${basePath}/${id}`,
			search: querystr,
		});
	};

	onDragEnd = (result) => {
		const { onMeetingChange } = this.props;
		const { ordinaryItems, postItems, suggestedItems, archivedItems } =
			this.state;
		const { draggableId, source, destination, type } = result;
		const parseItem = (item, destinationListName) => {
			if (destinationListName === "ordinaryItems") {
				item = item.remove("postItem");
			} else if (destinationListName === "postItems") {
				item = item.set("postItem", true);
			}

			if (destinationListName !== "suggestedItems") {
				item = item.remove("isSuggested");
			}

			return item;
		};
		const mergeLists = (
			sourceListName,
			sourceList,
			destinationListName,
			destinationList,
		) => {
			const lists = Map({
				ordinaryItems,
				postItems,
				suggestedItems,
				archivedItems,
			});

			const result = lists
				.map((list, name) => {
					if (name === sourceListName) {
						return sourceList;
					} else if (name === destinationListName) {
						return destinationList;
					}

					return list;
				})
				.toList()
				.filter((list) => (list ? true : false))
				.flatten(true);

			return result;
		};
		const canItemMoveToDestination = (
			destinationIndex,
			destinationListName,
		) => {
			if (destinationListName === "ordinaryItems" && destinationIndex === 0) {
				return false;
			} else if (destinationListName === "postItems") {
				return !(destinationIndex + 1 >= postItems.size);
			}

			return true;
		};
		let agendaItems = List();

		if (!destination) {
			return;
		}

		if (!canItemMoveToDestination(destination.index, destination.droppableId)) {
			return;
		}

		// First level items
		if (type === "1$root") {
			// Move items within same list
			if (source.droppableId === destination.droppableId) {
				let list = this.state[source.droppableId];
				const item = list.find((obj) => obj.get("id") === draggableId);
				list = list.remove(source.index);
				list = list.splice(destination.index, 0, item);
				this.setState({
					[source.droppableId]: list,
				});
				agendaItems = mergeLists(source.droppableId, list);
				// Move items between lists
			} else {
				let sourceList = this.state[source.droppableId];
				let destinationList = this.state[destination.droppableId];
				let item = sourceList.find((obj) => obj.get("id") === draggableId);
				item = parseItem(item, destination.droppableId);
				sourceList = sourceList.remove(source.index);
				destinationList = destinationList.splice(destination.index, 0, item);
				this.setState({
					[source.droppableId]: sourceList,
					[destination.droppableId]: destinationList,
				});
				agendaItems = mergeLists(
					source.droppableId,
					sourceList,
					destination.droppableId,
					destinationList,
				);
			}
			// Second level items
		} else {
			const [_level, _id, parentIndex] = type.split("$");
			let list = this.state[source.droppableId];
			list = list.updateIn([parentIndex, "agendaItems"], (list) => {
				const item = list.find((obj) => obj.get("id") === draggableId);
				list = list.remove(source.index);
				list = list.splice(destination.index, 0, item);
				return list;
			});
			this.setState({
				[source.droppableId]: list,
			});
			agendaItems = mergeLists(source.droppableId, list);
		}

		onMeetingChange(["agendaItems"], agendaItems);
	};

	goToSubitems = (level, index) => {
		const { items } = this.props;

		if (level !== 1) {
			return;
		}

		const item = items.get(index);

		if (item.get("agendaItems")) {
			const subitem = item.get("agendaItems", List()).first();

			if (subitem) {
				this.onAgendaItemClick(subitem.get("id"));
			}
		}
	};

	goToParentItem = (level, parentIndex) => {
		const { items } = this.props;
		const item = items.get(parentIndex);

		if (item) {
			this.onAgendaItemClick(item.get("id"));
		}
	};

	onChange = (itemId, field, val) => {
		const { items, saveAgendaItem } = this.props;
		const closeMeeting = field === "outcome" && val === "closed";
		let agendaItem;

		items.forEach((item) => {
			if (item.get("id") === itemId) {
				agendaItem = item;
			} else if (item.get("agendaItems", List()).size > 0) {
				item.get("agendaItems").forEach((subitem) => {
					if (subitem.get("id") === itemId) {
						agendaItem = subitem;
					}
				});
			}
		});

		agendaItem = agendaItem.set(field, val);

		if (
			field === "progress" &&
			val === "done" &&
			agendaItem.get("hasUnreadFeedback")
		) {
			agendaItem = agendaItem.set("hasUnreadFeedback", false);
		}

		if (closeMeeting) {
			let internalData = agendaItem.get("internalData", Map());
			internalData = internalData.set("closedAt", moment().toISOString());
			agendaItem = agendaItem.set("internalData", internalData);
		}

		saveAgendaItem(agendaItem);
	};

	createNewAgendaItem = (listId, indexOfParentItem, itemIndex) => {
		if (listId === "adjournmentList") {
			return;
		}

		const { createAgendaItem, querystr, basePath } = this.props;
		const insertAtIndex = itemIndex + 1;
		const agendaItem = Map();
		this.timeout && clearTimeout(this.timeout);

		createAgendaItem(
			indexOfParentItem,
			insertAtIndex,
			agendaItem,
			(newAgendaItem) => {
				this.setState({ agendaItemInEditMode: newAgendaItem.get("id") });

				this.props.history.push({
					pathname: `${basePath}/${newAgendaItem.get("id")}`,
					search: querystr,
				});
			},
		);
	};

	itemIsSuperItem = (itemId) => {
		const { items } = this.props;

		if (!items) {
			return false;
		}

		let isSuperItem = false;

		items.forEach((item) => {
			if (item && item.get("id") === itemId) {
				isSuperItem = true;
			}
		});

		return isSuperItem;
	};

	resetAgendaItemInEditMode = () => {
		this.timeout = setTimeout(() => {
			this.setState({ agendaItemInEditMode: null });
		}, 500);
	};

	renderItemDropdown = ({ agendaItemId }) => {
		const { onMeetingChange, querystr, basePath } = this.props;
		const { ordinaryItems } = this.state;

		return (
			<AgendaDropdownItemContainer
				agendaItemId={agendaItemId}
				onMeetingChange={onMeetingChange}
				querystr={querystr}
				basePath={basePath}
				ordinaryItemsSize={ordinaryItems.size}
			/>
		);
	};

	renderAgendaItem = (listId, agendaItemProps) => {
		const { readOnly, showProgressIcon, meeting, isSecretary } = this.props;
		const { agendaItemInEditMode } = this.state;
		const meetingIsStarted = hasMeetingStarted(meeting);
		const statusDropDownReadOnly =
			(agendaItemProps.internalType === "close" && !meetingIsStarted) ||
			((agendaItemProps.internalType === "open" ||
				agendaItemProps.internalType === "close") &&
				!isSecretary);
		const hideProfile = listId === "archivedItems";
		const hideDuration = listId === "archivedItems";
		const hideOutcome = listId === "archivedItems";

		return (
			<AgendaItemContainer
				{...agendaItemProps}
				onClick={this.onAgendaItemClick}
				onChange={this.onChange}
				hideProfile={hideProfile}
				hideDuration={hideDuration}
				hideOutcome={hideOutcome}
				onEnter={this.createNewAgendaItem.bind(
					this,
					listId,
					agendaItemProps.parentIndex,
					agendaItemProps.itemIndex,
				)}
				onFocus={() => this.timeout && clearTimeout(this.timeout)}
				onBlur={this.resetAgendaItemInEditMode}
				inEditMode={agendaItemInEditMode === agendaItemProps.id}
				itemComponent={AgendaItem}
				readOnly={readOnly}
				showProgressIcon={showProgressIcon}
				onProgressIconClick={this.onChange}
				statusDropDownReadOnly={statusDropDownReadOnly}
				isSecretary={isSecretary}
				renderDropdown={this.renderItemDropdown.bind(null, {
					agendaItemId: agendaItemProps.id,
				})}
			/>
		);
	};

	renderNewAgendaItemButton = (listId, listSize, indexOfParentItem) => {
		const tid =
			listSize > 0
				? "meetings.list.add_another_item"
				: "meetings.list.add_new_item";

		return (
			<NewListItemButton
				onClick={this.createNewAgendaItem.bind(
					this,
					listId,
					indexOfParentItem,
					listSize - 1,
				)}
				tid={tid}
			/>
		);
	};

	render() {
		const {
			basePath,
			agendaItemIdInUrl,
			readOnly,
			hasExtendedRights,
			isSecretary,
			userId,
		} = this.props;
		const {
			agendaItemInEditMode,
			ordinaryItems,
			postItems,
			suggestedItems,
			archivedItems,
		} = this.state;

		return (
			<Box direction="column">
				<AgendaList
					listName="ordinaryItems"
					ordinaryItems={ordinaryItems}
					postItems={postItems}
					suggestedItems={hasExtendedRights ? suggestedItems : undefined}
					archivedItems={archivedItems}
					userId={userId}
					renderItemComponent={this.renderAgendaItem}
					renderNewItemButton={this.renderNewAgendaItemButton}
					shouldDisplayNewItemButton={this.shouldDisplayNewItemButton}
					isDragDisabled={readOnly}
					onDragEnd={this.onDragEnd}
					agendaItemInEditMode={agendaItemInEditMode}
					basePath={basePath}
					currentUrl={`${basePath}/${agendaItemIdInUrl}`}
					isSecretary={isSecretary}
				/>
			</Box>
		);
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meeting: store.meetings.get("meeting"),
		hasExtendedRights: store.meetings.getIn([
			"meeting",
			"computedValues",
			"hasExtendedRights",
		]),
		isSecretary: store.meetings.getIn([
			"meeting",
			"computedValues",
			"isSecretary",
		]),
		userId: store.user.getIn(["userObj", "id"]),
	};
};

const mapActionsToProps = {
	createAgendaItem,
	reorderAgendaItem,
	saveAgendaItem,
};

export default connect(mapStoreToProps, mapActionsToProps)(AgendaListContainer);
