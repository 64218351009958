import React, { createContext } from "react";
import {
	usePopupState,
	bindHover,
	bindMenu,
} from "material-ui-popup-state/hooks";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import { uniqueId } from "lodash";
import { bool, func, node, oneOf, oneOfType, shape } from "prop-types";

export const DropDownContext = createContext();
function DropDown({
	button,
	children,
	alignMenu,
	disabled,
	anchorOrigin,
	transformOrigin,
}) {
	const popupState = usePopupState({
		variant: "popover",
		popupId: uniqueId(),
	});

	if (!anchorOrigin) {
		anchorOrigin = { vertical: "bottom", horizontal: alignMenu };
	}

	if (!transformOrigin) {
		transformOrigin = { vertical: "top", horizontal: alignMenu };
	}

	return (
		<>
			{button({ params: bindHover(popupState) })}
			{!disabled && (
				<HoverMenu
					transitionDuration={0}
					{...bindMenu(popupState)}
					anchorOrigin={anchorOrigin}
					transformOrigin={transformOrigin}
				>
					<DropDownContext.Provider value={{ popupState }}>
						{typeof children === "function"
							? children({ popupState })
							: children}
					</DropDownContext.Provider>
				</HoverMenu>
			)}
		</>
	);
}

DropDown.propTypes = {
	button: func.isRequired,
	children: oneOfType([node, func]),
	alignMenu: oneOf(["left", "right"]),
	disabled: bool,
	anchorOrigin: shape({
		vertical: oneOf(["top", "bottom", "center"]),
		horizontal: oneOf(["left", "right", "center"]),
	}),
	transformOrigin: shape({
		vertical: oneOf(["top", "bottom", "center"]),
		horizontal: oneOf(["left", "right", "center"]),
	}),
};

DropDown.defaultProps = {
	alignMenu: "left",
};

export { DropDown };
