import React, { PureComponent } from "react";
import styled, { css } from "styled-components";
import { bool, func } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import { Margin } from "styled-components-spacing";
import { FormattedNumber } from "react-intl";
import StyledList from "../../shared/list/list";
import ListItem from "../../shared/list-item/list-item";
import { StyledListHeaderTemplate } from "../../shared/list/list-header.template";
import Text from "../../shared/text/text";
import Button from "../../shared/button/button";
import DocumentsHelper from "../../../components/helpers/documents.helper";
import Tooltip from "../../shared/tooltip/tooltip";
import Icon from "../../shared/icon/icon";

const StyledHeader = styled.div`
	${StyledListHeaderTemplate}
`;

const StyledListItem = styled(ListItem)`
	background: ${(props) => props.theme.colors.silver};
	padding: 0;
	align-items: center;
`;

const CommonTemplate = css`
	display: flex;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledIconHeader = styled.div`
	${CommonTemplate}
	min-width: 40px;
	max-width: 40px;
`;

const StyledIconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 40px;
	max-width: 40px;
	height: 100%;
`;

const StyledListName = styled.div`
	${CommonTemplate}
	flex: 1;
	min-width: 0;
`;

const StyledListExtension = styled.div`
	${CommonTemplate}
	justify-content: flex-end;
	min-width: 90px;
	max-width: 90px;
`;

const StyledProposalName = styled.div`
	${CommonTemplate}
	min-width: 250px;
	max-width: 250px;
`;

const StyledListFileSize = styled.div`
	${CommonTemplate}
	justify-content: flex-end;
	min-width: 90px;
	max-width: 90px;
`;

const StyledListButtonContainer = styled.div`
	${CommonTemplate}
	justify-content: flex-end;
	min-width: 220px;
	max-width: 220px;
`;

export default class DocumentsDetailedList extends PureComponent {
	static propTypes = {
		documents: immutablePropTypes.list,
		onOpenFile: func,
		onDownloadFile: func,
		hideProposalColumn: bool,
	};

	renderHeader = () => {
		const { hideProposalColumn } = this.props;

		return (
			<StyledHeader>
				<StyledIconHeader />

				<StyledListName>
					<Text tid="documents.file_name" bold={400} color="lightGrey" />
				</StyledListName>

				{!hideProposalColumn && (
					<StyledProposalName>
						<Text
							tid="meeting.protocol.documents.attached_to_topic"
							bold={400}
							color="lightGrey"
						/>
					</StyledProposalName>
				)}

				<StyledListExtension>
					<Text tid="documents.file_type" bold={400} color="lightGrey" />
				</StyledListExtension>

				<StyledListFileSize>
					<Text tid="documents.file_size" bold={400} color="lightGrey" />
				</StyledListFileSize>

				<StyledListButtonContainer />
			</StyledHeader>
		);
	};

	renderDocument = (file, index) => {
		const { onOpenFile, onDownloadFile, hideProposalColumn } = this.props;
		const documentIsViewableInBrowser =
			DocumentsHelper.isViewableInBrowser(file);
		const fileSize = DocumentsHelper.getFileSize(file);

		return (
			<StyledListItem hoverColor="lightestGrey" key={index}>
				<StyledIconContainer>
					<Icon icon="faFile" size="lg" />
				</StyledIconContainer>

				<StyledListName>
					<Text singleLine title={file.get("originalname")}>
						{file.get("originalname")}
					</Text>
				</StyledListName>

				{!hideProposalColumn && (
					<StyledProposalName>
						<Text singleLine>{file.get("proposal", "")}</Text>
					</StyledProposalName>
				)}

				<StyledListExtension>
					<Text>{file.get("ext")}</Text>
				</StyledListExtension>

				<StyledListFileSize>
					<Text>
						<FormattedNumber value={fileSize.value} format={"filesize"} />{" "}
						{fileSize.unit}
					</Text>
				</StyledListFileSize>

				<StyledListButtonContainer>
					<Tooltip
						tid="tooltip.documents.action.unable_open_document_size"
						delayShow="instant"
						active={!documentIsViewableInBrowser}
					>
						<Button
							onClick={() => onOpenFile(file)}
							disabled={!documentIsViewableInBrowser}
						>
							<Margin right={3}>
								<Icon icon="faExpandArrowsAlt" size="xs" />
							</Margin>
							<Text tid="generic.open" />
						</Button>
					</Tooltip>

					<Button onClick={() => onDownloadFile && onDownloadFile(file)}>
						<Margin right={3}>
							<Icon icon="faArrowAltToBottom" size="xs" />
						</Margin>
						<Text tid="documents.download" />
					</Button>
				</StyledListButtonContainer>
			</StyledListItem>
		);
	};

	render = () => {
		const { documents } = this.props;

		if (!documents || documents.size === 0) {
			return null;
		}

		return (
			<StyledList header={this.renderHeader}>
				{documents.map(this.renderDocument)}
			</StyledList>
		);
	};
}
