import React, { Component } from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { fetchUser } from "../../actions/user.actions";
import { fetchMenu } from "../../actions/menu.actions";

import CompanyDropdownContainer from "../../containers/company/nav-dropdown/menu.container";
import CollectionsContainer from "../../containers/navbar/collections.container";
import UserProfileMenuContainer from "../../containers/navbar/user-profile-menu.container";
import LanguagePickerDropdown from "../languagePickerDropdown";

class AppNavBar extends Component {
	componentDidMount() {
		this.fetchMenus();
	}

	componentDidUpdate = (prevProps) => {
		const { companyId } = this.props;

		if (companyId !== prevProps.companyId) {
			this.fetchMenus();
		}
	};

	fetchMenus = () => {
		const accessToken = new Cookies().get("accessToken");
		if (accessToken) {
			this.props.fetchUser("me");
		}
	};

	render = () => {
		return (
			<header id="navbar">
				<div id="navbar-container" className="boxed">
					<div className="navbar-content clearfix">
						<div className="media-block">
							<div className="nav navbar-top-links media-left">
								<CompanyDropdownContainer />
							</div>

							<div className="media-body media-block overflow-visible">
								<ul className="nav navbar-top-links media-right">
									<li>
										<CollectionsContainer />
									</li>
									<li>
										<UserProfileMenuContainer />
									</li>
									<li>
										<LanguagePickerDropdown className="border-none py-[20px]" />
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</header>
		);
	};
}

function mapStateToProps(state) {
	return {
		companyId: state.company?.company?.id,
		i18n: state.i18n,
		userObj: state.user.get("userObj"),
	};
}

const mapActionsToProps = {
	fetchUser,
	fetchMenu,
};

export default connect(mapStateToProps, mapActionsToProps)(AppNavBar);
