import React, { PureComponent } from "react";
import { func, object } from "prop-types";
import styled from "styled-components";
import ColoredContentWrapper from "../../shared/colored-content-wrapper/colored-content-wrapper";
import Text from "../../shared/text/text";

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
`;

const StyledTextWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding-top: ${(props) => props.theme.spacing[4]};
`;

const StyledZipCityWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;

const StyledCityText = styled.div`
	margin-left: ${(props) => props.theme.spacing[2]};
`;

export default class AddCapitalInsurancePanel extends PureComponent {
	static propTypes = {
		synaComponent: func,
		selectedCompany: object,
	};

	renderCompany = () => {
		const { selectedCompany } = this.props;

		if (!selectedCompany || selectedCompany.size < 1) {
			return;
		}

		const name = selectedCompany.getIn(["company", "name"]);
		const organisationNumber = selectedCompany.getIn(["company", "orgNumber"]);
		const address = selectedCompany.getIn([
			"company",
			"addresses",
			0,
			"street",
		]);
		const zip = selectedCompany.getIn([
			"company",
			"addresses",
			0,
			"postalCode",
		]);
		const city = selectedCompany.getIn(["company", "addresses", 0, "city"]);

		return (
			<>
				<StyledTextWrapper>
					<Text bold={600} marginBottom={2}>
						{name}
					</Text>
					<Text bold={600} marginBottom={2}>
						{organisationNumber}
					</Text>
					<Text marginBottom={2}>{address}</Text>
					<StyledZipCityWrapper>
						<Text>{zip}</Text>
						<StyledCityText>
							<Text>{city}</Text>
						</StyledCityText>
					</StyledZipCityWrapper>
				</StyledTextWrapper>
			</>
		);
	};

	render = () => {
		const { synaComponent } = this.props;

		return (
			<StyledColoredContentWrapper type="secondary">
				{synaComponent()}
				{this.renderCompany()}
			</StyledColoredContentWrapper>
		);
	};
}
