import React, { PureComponent } from "react";
import { func, bool } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import styled from "styled-components";

// import { FixedSizeList as List } from 'react-window'
import Modal from "../../shared/modal/modal";
import FooterRightControls from "../../shared/modal/footer-right-controls";
import { TransparentButton } from "../../shared/button-v2";
import Text from "../../shared/text/text";
import List from "../../shared/list/list";
import ListPanelItem from "../../shared/list-panel/list-panel-item";
import YellowWarningWrapper from "../../shared/styled-content-wrapper/yellow-warning-wrapper";

const StyledErrorMessageWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const StyledListWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing[3]};
`;

export default class InvestorsWarningModal extends PureComponent {
	static propTypes = {
		investorsWithWarning: immutablePropTypes.list,
		onCloseAndGoToShareholders: func,
		onClose: func,
		isOpen: bool,
	};

	renderFooterButtons = () => {
		const { onCloseAndGoToShareholders, onClose } = this.props;

		return (
			<FooterRightControls>
				<TransparentButton
					tid="shareholders.warning_modal.btn.close_and_go_to_shareholders"
					onClick={onCloseAndGoToShareholders}
				/>
				<TransparentButton
					tid="shareholders.warning_modal.btn.close"
					onClick={onClose}
					textColor="midGrey"
				/>
			</FooterRightControls>
		);
	};

	renderInvestor = (investor, index) => {
		const name = investor.getIn(["investorInformation", "name"]);
		const nameIsTid = name ? false : true;
		return (
			<ListPanelItem
				key={index}
				name={name || "shareholders.warning_modal.no_name"}
				nameIsTid={nameIsTid}
				nameInItalic={true}
				status={false}
				sharedWithList={true}
			/>
		);
	};

	renderInvestorsList = () => {
		const { investorsWithWarning } = this.props;

		return <List>{investorsWithWarning.map(this.renderInvestor)}</List>;
	};

	render = () => {
		const { isOpen } = this.props;

		return (
			<Modal
				title="shareholders.warning_modal.title"
				isOpen={isOpen}
				footerComponent={this.renderFooterButtons()}
				hSize="md"
			>
				<StyledErrorMessageWrapper>
					<YellowWarningWrapper>
						<Text tid="shareholders.warning_modal.info" />
					</YellowWarningWrapper>
					<StyledListWrapper>{this.renderInvestorsList()}</StyledListWrapper>
				</StyledErrorMessageWrapper>
			</Modal>
		);
	};
}
