import React, { Component } from "react";
import { connect } from "react-redux";

class Accordion extends Component {
	state = {
		selectedSection: null,
	};

	componentDidMount() {
		this.setState({
			selectedSection:
				this.props.selectedSection !== undefined
					? this.props.selectedSection
					: null,
		});
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.selectedSection !== prevProps.selectedSection) {
			this.setState({ selectedSection: this.props.selectedSection });
		}
	};

	selectSection(index) {
		this.setState({ selectedSection: index });
	}

	renderChild(child, index) {
		const { selectedSection } = this.state;

		const selected = selectedSection === index;
		let bodyClassName = "expandable";
		bodyClassName += selectedSection === index ? " expandable--open" : "";

		return React.cloneElement(child, {
			bodyClassName,
			selected,
			onSelect: this.selectSection.bind(this, index),
		});
	}

	render() {
		const children = React.Children.map(
			this.props.children,
			this.renderChild.bind(this),
		);

		return <div className="i-accordion">{children}</div>;
	}
}

function mapStateToProps(state) {
	return {
		company: state.company.company,
		userObj: state.user.toJS().userObj,
	};
}

export default connect(mapStateToProps)(Accordion);
