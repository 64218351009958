import React, { useState, useEffect } from "react";
import { ACCOUNT_OWNER, EXTERNAL_INVOICE_RECEIVER } from "/shared/constants";

import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import Panel from "@/dumb-components/shared/panel/panel";
import LoadingPanel from "@/components/loadingPanel";
import useSubscription from "@/hooks/useSubscription";
import useCompanyUsers from "@/hooks/useCompanyUsers";
import useUpdateSubscriptionInvoiceReiceiver from "@/hooks/useUpdateSubscriptionInvoiceReceiver";
import useCompanyFromUrl from "@/hooks/useCompanyFromUrl";
import { Trans, useTranslation } from "react-i18next";
import { Input } from "@/components/ui/input";

const NotificationDetailsPanel = () => {
	const { t } = useTranslation();
	const [selectedReceiver, setSelectedReceiver] = useState(ACCOUNT_OWNER);
	const [selectedExternalInvoiceEmail, setSelectedExternalInvoiceEmail] =
		useState();

	const { data: companyQuery, isLoading: isLoadingCompany } =
		useCompanyFromUrl();
	const company = companyQuery?.data;
	const companyId = company?.id;

	const {
		mutate: updateSubscriptionInvoiceReiceiver,
		isPending: isUpdatingSubscriptionInvoiceReceiver,
	} = useUpdateSubscriptionInvoiceReiceiver(companyId);

	const { data: companyUsersQuery, isLoading: isLoadingCompanyUsers } =
		useCompanyUsers();
	const companyUsers = companyUsersQuery?.data;

	const { data: subscriptionQuery, isLoading: isLoadingSubscription } =
		useSubscription();
	const subscription = subscriptionQuery?.data;
	const invoiceReceiver = subscription?.invoiceReciever; // Note: correct for typo in backend `reciever` -> `receiver`

	useEffect(() => {
		if (!invoiceReceiver) return;
		setSelectedReceiver(invoiceReceiver);
	}, [invoiceReceiver]);

	useEffect(() => {
		if (!subscription.externalInvoiceEmail) return;
		setSelectedExternalInvoiceEmail(subscription.externalInvoiceEmail);
	}, [subscription.externalInvoiceEmail]);

	if (isLoadingSubscription || isLoadingCompanyUsers || isLoadingCompany)
		return <LoadingPanel />;

	const companyUsersOptions = companyUsers
		?.filter((u) => u.name)
		?.map((user) => {
			const email = user.emails.find((email) => user.defaultEmail === email.id);

			return {
				value: user.id,
				label: (
					<div className="inline-flex gap-4">
						<span>{user.name}</span>
						<span className="text-muted-foreground">({email?.email})</span>
					</div>
				),
			};
		});

	const options = [
		{
			label: t(
				"subscriptions.notification_panel.options.external_invoice_receiver",
			),
			value: EXTERNAL_INVOICE_RECEIVER,
		},
		{
			label: t("subscriptions.notification_panel.options.company_owner"),
			value: ACCOUNT_OWNER,
		},
		...companyUsersOptions,
	];

	const currentReceiverUser = companyUsers?.find((user) =>
		selectedReceiver === ACCOUNT_OWNER
			? user.id === company.superUser
			: user.id === invoiceReceiver,
	);

	const currentReceiverEmail = currentReceiverUser?.emails.find(
		(email) => email.id === currentReceiverUser.defaultEmail,
	)?.email;

	const currentReceiverName = currentReceiverUser?.name;

	return (
		<Panel title="subscriptions.notification_panel.title">
			<div className="flex flex-col gap-6 p-6">
				<div>
					<label>
						{t(
							"subscriptions.notification_panel.label.member_to_recieve_emails",
						)}
					</label>
					<Select
						value={selectedReceiver}
						onValueChange={(value) => {
							if (
								value === EXTERNAL_INVOICE_RECEIVER &&
								!selectedExternalInvoiceEmail
							) {
								return setSelectedReceiver(value);
							}

							updateSubscriptionInvoiceReiceiver({
								invoiceReceiver: value,
							});
						}}
					>
						<SelectTrigger isLoading={isUpdatingSubscriptionInvoiceReceiver}>
							<SelectValue />
						</SelectTrigger>
						<SelectContent>
							{options.map(({ label, value }) => (
								<SelectItem key={value} value={value}>
									{label}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
				{selectedReceiver === EXTERNAL_INVOICE_RECEIVER && (
					<div>
						<label htmlFor="externalInvoiceEmailInput">
							{t(
								"subscriptions.notification_panel.label.external_invoice_email",
							)}
						</label>
						<Input
							id="externalInvoiceEmailInput"
							isLoading={isUpdatingSubscriptionInvoiceReceiver}
							value={selectedExternalInvoiceEmail}
							onChange={(evt) =>
								setSelectedExternalInvoiceEmail(evt.target.value)
							}
							onBlur={() => {
								updateSubscriptionInvoiceReiceiver({
									invoiceReceiver: selectedReceiver,
									externalInvoiceEmail: selectedExternalInvoiceEmail,
								});
							}}
						/>
					</div>
				)}
				{selectedReceiver !== EXTERNAL_INVOICE_RECEIVER && (
					<Trans
						i18nKey="subscriptions.notification_panel.info_text"
						values={{ name: currentReceiverName, email: currentReceiverEmail }}
					/>
				)}
			</div>
		</Panel>
	);
};

export default NotificationDetailsPanel;
