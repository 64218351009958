import req from "../modules/request.module";

export const openAdvertPreviewPdf = async (advertPreviewData) => {
	const w = window.open(
		`https://${window.location.hostname}/assets/build/misc/redirecting.html`,
		"_blank",
	);
	const response = await req.post(
		"/gateway/adverts/preview",
		advertPreviewData,
	);
	w.location = response.data;
};

export const createDuplicateAdvert = async (advertId) => {
	const response = await req.post(`/gateway/adverts/${advertId}/duplicate`);
	return response.data;
};
