import React, { Component } from "react";
import { connect } from "react-redux";
import { string, func, object, oneOfType } from "prop-types";

import MinutesPersonalNotes from "../../../dumb-components/meetings/minutes-personal-notes/minutes-personal-notes";
import PersonalNotesContainer from "../../shared/notes.container";
import EditorContainer from "../../shared/editor.container";

import { OBJ_TYPE_AGENDA_ITEM } from "/shared/constants";

class MinutesPersonalNotesContainer extends Component {
	state = {
		view: 0,
	};

	static propTypes = {
		objId: string,
		onChange: func,
		agendaItemMinutes: oneOfType([object, string]),
	};

	static defaultProps = {};

	onChangeView = (view) => {
		this.setState({ view });
	};

	renderMinutes = () => {
		const { onChange, agendaItemMinutes, objId } = this.props;

		return (
			<EditorContainer
				fieldName="minutes"
				objId={objId}
				onChange={onChange}
				contentState={agendaItemMinutes}
				editorHeight="300px"
			/>
		);
	};

	renderPersonalNotes = () => {
		const { objId, meetingId } = this.props;

		if (!objId || !meetingId) {
			return null;
		}

		return (
			<PersonalNotesContainer
				meetingId={meetingId}
				objId={objId}
				objType={OBJ_TYPE_AGENDA_ITEM}
			/>
		);
	};

	render() {
		const { view } = this.state;

		return (
			<MinutesPersonalNotes
				minutesRenderer={this.renderMinutes}
				personalNotesRenderer={this.renderPersonalNotes}
				onChangeView={this.onChangeView}
				view={view}
			/>
		);
	}
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MinutesPersonalNotesContainer);
