import { INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE } from "/shared/constants";

const obj = {
	getInvestorInformationPath: (investment) => {
		if (!investment.get("investorTypeOfOwner")) {
			return;
		}
		if (
			investment.get("investorTypeOfOwner") ===
			INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE
		) {
			return "captialIncuranceOwnerInformation";
		} else {
			return "investorInformation";
		}
	},
	getInvestorInformation: (investment) => {
		if (!investment.get("investorTypeOfOwner")) {
			return;
		}
		return investment.get(obj.getInvestorInformationPath(investment));
	},

	getPrimaryInvestmentEmail: (investment) => {
		if (!investment) {
			return;
		}
		const investorInformation = obj.getInvestorInformation(investment);
		if (
			!investorInformation ||
			!investorInformation.has("email") ||
			investorInformation.get("email").size === 0
		) {
			return;
		}
		return investorInformation.getIn(["email", 0]);
	},

	getInvestorName: (investment) => {
		if (!investment) {
			return;
		}
		const investorInformation = obj.getInvestorInformation(investment);
		if (!investorInformation || !investorInformation.get("name")) {
			return;
		}
		return investorInformation.get("name");
	},

	getInvestorSsn: (investment) => {
		if (!investment) {
			return;
		}
		const investorInformation = obj.getInvestorInformation(investment);
		if (!investorInformation || !investorInformation.get("ssn")) {
			return;
		}
		return investorInformation.get("ssn");
	},

	getInvestedInCompanyNameOrAlias: (
		personalInvestment,
		actualCompanyName = null,
	) => {
		if (!personalInvestment) {
			return actualCompanyName;
		}

		const alias = personalInvestment.get("alias");
		const companyName =
			personalInvestment.getIn(["investedInCompanyInformation", "name"]) ||
			actualCompanyName;

		return alias || companyName;
	},
};

export default obj;
