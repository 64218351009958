import React from "react";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import { bindPopover } from "material-ui-popup-state/hooks";
import { Box, Stack } from "@mui/material";
import { object } from "prop-types";
import { ButtonTransparentIcon } from "../../../dumb-components/shared/button";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";
import { FeatureModal } from "./feature-modal";
import { useFeatureGroupContext } from "./feature-group.context";
import { useFeatureContext } from "./feature.context";

function FeatureToolbar({ popupState }) {
	const { removeFeature, deleteFeature } = useFeatureGroupContext();
	const { feature, startEditingFeature } = useFeatureContext();

	return (
		<>
			<HoverPopover
				{...bindPopover(popupState)}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
			>
				<Box sx={{ padding: 1 }}>
					<Stack direction="row">
						<Tooltip tid="feature.toolbar.edit" delayShow="instant">
							<ButtonTransparentIcon
								icon="faEdit"
								size="sml"
								onClick={startEditingFeature}
							/>
						</Tooltip>
						<Tooltip tid="feature.toolbar.remove" delayShow="instant">
							<ButtonTransparentIcon
								icon="faUnlink"
								size="sml"
								onClick={() => removeFeature(feature.id)}
							/>
						</Tooltip>
						<Tooltip tid="feature.toolbar.delete" delayShow="instant">
							<ButtonTransparentIcon
								icon="faTrashAlt"
								size="sml"
								onClick={() => deleteFeature(feature.id)}
							/>
						</Tooltip>
					</Stack>
				</Box>
			</HoverPopover>
			<FeatureModal />
		</>
	);
}

FeatureToolbar.propTypes = {
	popupState: object,
};

export { FeatureToolbar };
