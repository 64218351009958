import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { useSelector } from "react-redux";
import { displayDate } from "../../utils/display-helpers";
import { Divider } from "@mui/material";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import { NUMBER_FORMAT_INTEGER } from "/shared/constants.json";
import CustomFileIcon from "../../../../dumb-components/shared/file-icon/file-icon";
import { downloadDocument } from "../../../../actions/documents.actions";

const ShowPartialClosuresList = (props) => {
	const { t } = useTranslation();
	const closures = useSelector(
		(state) => state.emissions.current.closures ?? [],
	);
	const langCode = useSelector((s) => s.i18n.language).substring(0, 2);

	return (
		<Dialog open={props.open} onClose={props.close}>
			<DialogContent sx={{ px: 6, py: 4, minWidth: "60rem" }}>
				<Box>
					<Typography sx={{ textAlign: "center", pb: 2 }} variant="h4">
						{t("emissions.allocation.partialClosures")}
					</Typography>
					<Box sx={{ textAlign: "center", mb: "20px" }}>
						{t("emissions.allocation.partial-closure-desc")}
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						{closures.map((closure) => {
							return (
								<Box
									key={closure.id}
									sx={{
										alignItems: "center",
										display: "flex",
										flexDirection: "row",
										minWidth: "540px",
										height: "56px",
										background: "#F0F7FC",
										borderRadius: "4px",
										mb: 1,
									}}
								>
									<Box sx={{ width: "34px", height: "42px", ml: "16px" }}>
										<CustomFileIcon ext={"pdf"} />
									</Box>
									<Typography
										onClick={() => {
											OpenPartialClosurePdf(
												closure.docIdList.find(
													(doc) => doc.language === langCode,
												).docId,
											);
										}}
										variant={"subtitle1"}
										sx={{
											display: "flex",
											ml: "16px",
											lineHeight: "22px",
											cursor: "pointer",
										}}
									>
										{`${t("emissions.allocation." + closure.type + ".title")} ${
											closure.index?.toString() !== "0" ? closure.index : ""
										}`}
									</Typography>
									<Typography
										variant={"body2"}
										sx={{ display: "flex", ml: "16px", lineHeight: "20px" }}
									>
										{displayDate(closure.date)}
									</Typography>
									<Divider
										sx={{ ml: "16px", height: "24px", alignSelf: "center" }}
										flexItem
										orientation={"vertical"}
										variant={"middle"}
									/>
									<Typography
										variant={"body2"}
										sx={{ display: "flex", ml: "16px", lineHeight: "20px" }}
									>
										{t("emissions.allocation.allocated-shares")}
									</Typography>
									<Typography
										variant={"body2"}
										sx={{ display: "flex", ml: "16px", lineHeight: "20px" }}
									>
										{localeFormatNumber(
											closure.allocations.reduce(
												(previousValue, currentValue) => {
													return previousValue + currentValue.allocatedShares;
												},
												0,
											),
											NUMBER_FORMAT_INTEGER,
										)}
									</Typography>
									<Typography
										variant={"body2"}
										sx={{ display: "flex", ml: "2px", lineHeight: "20px" }}
									>
										{t("emissions.allocation.quantity")}
									</Typography>
								</Box>
							);
						})}
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

const OpenPartialClosurePdf = (docId) => {
	downloadDocument({
		documentId: docId,
		openInViewer: true,
		getMergedVersion: false,
		getSignedVersion: false,
	})();
};

export default ShowPartialClosuresList;
