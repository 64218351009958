import React from "react";
import { Link, Stack, Chip } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../actions/modals.actions";
import { PERSONAL_ATTACHMENTS_MODAL } from "../../../../constants/modals";
import { OBJ_TYPE_PERSONAL_ATTACHMENT } from "/shared/constants";

function OpenAttachmentsModalButton({
	value: {
		data: { transactionId },
	},
}) {
	const dispatch = useDispatch();
	const attachments = useSelector((state) =>
		state.personalAttachments.getIn([
			"attachments",
			"personalAttachments",
			transactionId,
		]),
	);
	const btnTid =
		attachments && attachments.size > 0
			? "my_records.personal_attachments.grid.button.manage"
			: "my_records.personal_attachments.grid.button.add";

	const openAttachmentModal = () =>
		dispatch(
			openModal(PERSONAL_ATTACHMENTS_MODAL, {
				objType: OBJ_TYPE_PERSONAL_ATTACHMENT,
				objId: transactionId,
				multiple: true,
			}),
		);

	return (
		<Stack
			spacing={2}
			direction="row"
			sx={{ justifyContent: "end", height: "100%", alignItems: "center" }}
		>
			<Chip
				label={attachments ? attachments.size : "0"}
				color={attachments?.size > 0 ? "success" : undefined}
			/>
			<Link
				component="button"
				variant="body2"
				color="positive.main"
				onClick={openAttachmentModal}
			>
				<FormattedMessage id={btnTid} />
			</Link>
		</Stack>
	);
}

export { OpenAttachmentsModalButton };
