import React, { PureComponent } from "react";
import immutablePropTypes from "react-immutable-proptypes";
import { func } from "prop-types";
import styled, { css } from "styled-components";
import moment from "../../../../modules/moment.module";
import List from "../../../shared/list/list";
import ListItem from "../../../shared/list-item/list-item";
import Text from "../../../shared/text/text";
import ToggleSwitch from "../../../shared/toggle-switch/toggle-switch";
import { StyledListHeaderTemplate } from "../../../shared/list/list-header.template";
import Tooltip from "../../../shared/tooltip/tooltip";
import Badge from "../../../shared/badge/badge";
import { MEETING_DEFAULT_TEMPLATE_ID } from "/shared/constants";

const StyledHeader = styled.div`
	${StyledListHeaderTemplate}
`;

const StyledList = styled(List)``;

const NameTemplate = css`
	display: flex;
	flex-basis: 30%;
	max-width: 30%;
	height: 100%;
	align-items: center;
	cursor: pointer;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const BadgeTemplate = css`
	display: flex;
	flex-basis: 15%;
	max-width: 15%;
	height: 100%;
	align-items: center;
	cursor: pointer;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const CreatedByTemplate = css`
	display: flex;
	flex-basis: 25%;
	max-width: 25%;
	height: 100%;
	align-items: center;
	cursor: pointer;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const CreatedAtTemplate = css`
	display: flex;
	flex-basis: 20%;
	max-width: 20%;
	height: 100%;
	align-items: center;
	cursor: pointer;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledToggleSwitchContainerTemplate = css`
	display: flex;
	flex-basis: 10%;
	max-width: 10%;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledListItem = styled(ListItem)`
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeDark};
	padding: 0;
	margin-bottom: 0px;
	align-items: center;
`;

const StyledName = styled.div`
	${NameTemplate}
`;

const StyledBadgeContainer = styled.div`
	${BadgeTemplate}
`;

const StyledCreatedBy = styled.div`
	${CreatedByTemplate}
`;

const StyledCreatedAt = styled.div`
	${CreatedAtTemplate}
`;

const StyledToggleSwitchContainer = styled.div`
	${StyledToggleSwitchContainerTemplate}

	> span {
		display: flex;
	}
`;

export default class MeetingTemplatesList extends PureComponent {
	static propTypes = {
		meetingTemplates: immutablePropTypes.list,
		metadata: immutablePropTypes.map,
		onSelectTemplate: func,
		onToggleTemplateStatus: func,
		renderMoreActionButton: func,
	};

	onChange = (id, val) => {
		const { onToggleTemplateStatus } = this.props;

		onToggleTemplateStatus(id, "active", val);
	};

	renderHeader = () => {
		return (
			<StyledHeader>
				<StyledName>
					<Text
						tid="meetings.templates.meeting_type_template_name"
						bold={400}
						color="lightGrey"
					/>
				</StyledName>

				<StyledCreatedBy>
					<Text
						tid="meetings.templates.created_by"
						bold={400}
						color="lightGrey"
					/>
				</StyledCreatedBy>

				<StyledCreatedAt>
					<Text
						tid="meetings.templated.created_at"
						bold={400}
						color="lightGrey"
					/>
				</StyledCreatedAt>

				<StyledBadgeContainer />

				<StyledToggleSwitchContainer />
			</StyledHeader>
		);
	};

	renderMeetingTemplate = (meetingTemplate, index) => {
		const { onSelectTemplate, metadata, renderMoreActionButton } = this.props;
		const id = meetingTemplate.get("id");
		const name = meetingTemplate.get("name");
		const isGlobal = metadata.getIn([id, "isGlobal"]);
		const templateIsDefault = id === MEETING_DEFAULT_TEMPLATE_ID;

		return (
			<StyledListItem hoverColor="lightestGrey" key={index}>
				<StyledName onClick={() => onSelectTemplate(meetingTemplate.get("id"))}>
					{name ? (
						<Text singleLine>{name}</Text>
					) : (
						<Text
							color="placeholder"
							tid="meetings.templates.modal.list.no_name_placeholder"
						/>
					)}
				</StyledName>

				<StyledCreatedBy
					onClick={() => onSelectTemplate(meetingTemplate.get("id"))}
				>
					<Text singleLine>{metadata.getIn([id, "createdBy"])}</Text>
				</StyledCreatedBy>

				<StyledCreatedAt
					onClick={() => onSelectTemplate(meetingTemplate.get("id"))}
				>
					<Text>{moment(meetingTemplate.get("createdAt")).format("L")}</Text>
				</StyledCreatedAt>

				<StyledBadgeContainer
					onClick={() => onSelectTemplate(meetingTemplate.get("id"))}
				>
					{isGlobal && <Badge tid="meetings.templates.global" isStatic />}
				</StyledBadgeContainer>

				<StyledToggleSwitchContainer>
					<Tooltip
						tid={
							templateIsDefault
								? "meetings.templates.modal.list.toggle.tooltip.template_is_default"
								: "meetings.templates.modal.list.toggle.tooltip"
						}
					>
						<ToggleSwitch
							checked={meetingTemplate.get("active")}
							onChange={this.onChange.bind(this, id)}
							disabled={templateIsDefault}
						/>
					</Tooltip>

					{renderMoreActionButton(id)}
				</StyledToggleSwitchContainer>
			</StyledListItem>
		);
	};

	render = () => {
		const { meetingTemplates } = this.props;

		return (
			<StyledList header={this.renderHeader}>
				{meetingTemplates && meetingTemplates.map(this.renderMeetingTemplate)}
			</StyledList>
		);
	};
}
