import React, { PureComponent } from "react";
import { string, bool, func, object } from "prop-types";
import styled from "styled-components";
import { StyledTransparentInputTemplate } from "./input.template";

const StyledTransparentInput = styled.input`
	${StyledTransparentInputTemplate};
`;

class TransparentInput extends PureComponent {
	static propTypes = {
		className: string,
		disabled: bool,
		name: string,
		onChange: func,
		onFocus: func,
		onBlur: func,
		placeholder: string,
		value: string,
		i18n: object,
		onArrowUp: func,
		onArrowDown: func,
		onArrowRight: func,
		onArrowLeft: func,
		onEsc: func,
		onTab: func,
		onKeyUp: func,
		onKeyDown: func,
		autofocus: bool,
		inputRef: func,
		onEnter: func,
		readOnly: bool,
	};

	static defaultProps = {
		disabled: false,
		placeholder: "",
		value: "",
		autofocus: false,
	};

	componentDidMount = () => {
		const { autofocus } = this.props;
		if (autofocus) {
			this.inputRef.focus();
		}
	};

	componentDidUpdate = (prevProps) => {
		if (this.props.autofocus && prevProps.key !== this.props.key) {
			this.inputRef.focus();
		}
	};

	onChange = (event) => {
		const { onChange, fieldName } = this.props;

		if (fieldName) {
			onChange(fieldName, event.target.value);
		} else {
			onChange(event.target.value);
		}
	};

	onKeyDown = (e) => {
		const {
			onKeyDown,
			onArrowUp,
			onArrowDown,
			onArrowRight,
			onArrowLeft,
			onEsc,
			onTab,
		} = this.props;

		if (onKeyDown) {
			return onKeyDown(e);
		}

		if (onArrowUp && e.keyCode === 38) {
			e.preventDefault();
			onArrowUp(-1);
		}

		if (onArrowDown && e.keyCode === 40) {
			e.preventDefault();
			onArrowDown(1);
		}

		if (onArrowRight && e.keyCode === 39) {
			e.preventDefault();
			onArrowRight();
		}

		if (onArrowLeft && e.keyCode === 37) {
			e.preventDefault();
			onArrowLeft();
		}

		if (onEsc && e.keyCode === 27) {
			e.preventDefault();
			onEsc();
		}

		if (onTab && e.keyCode === 9) {
			e.preventDefault();
			onTab();
		}
	};

	onKeyUp = (e) => {
		const { onKeyUp, onEnter, value } = this.props;

		if (onKeyUp) {
			return onKeyUp(e);
		}

		if (onEnter && e.keyCode === 13) {
			e.preventDefault();
			onEnter(value);
		}
	};

	setInputRef = (ref) => {
		const { inputRef } = this.props;

		if (ref) {
			this.inputRef = ref;
			inputRef && inputRef(ref);
		}
	};

	render() {
		const {
			disabled,
			value,
			i18n,
			onBlur,
			onFocus,
			className,
			readOnly,
			autofocus,
		} = this.props;
		let { placeholder } = this.props;

		placeholder =
			i18n && placeholder ? i18n.messages[placeholder] : placeholder;

		return (
			<StyledTransparentInput
				className={className}
				type="text"
				onChange={this.onChange}
				disabled={disabled}
				value={value}
				placeholder={placeholder}
				ref={this.setInputRef}
				onBlur={onBlur}
				onFocus={onFocus}
				onKeyDown={this.onKeyDown}
				onKeyUp={this.onKeyUp}
				readOnly={readOnly}
				autoFocus={autofocus}
			/>
		);
	}
}

export default TransparentInput;
