import { fromJS, List, Map } from "immutable";

import {
	GROUPS_CREATE_REMOTE,
	GROUPS_FETCH_REMOTE,
	GROUPS_UPDATE_LOCAL,
	GROUPS_DELETE_REMOTE,
	GROUPS_SAVE_REMOTE,
	GROUPS_LIST,
	GROUPS_UPDATE_SELECTED_GROUP_ID,
	GROUPS_GET_DEFAULT_PERMISSIONS,
	COMPANY_RESET_REDUCER,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	mapParentToChildren: {},
	groups: Map(),
	currentGroup: null,
	selectedGroupId: "",
	defaultPermissions: {},
});

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;
	let parentId, parentList, group, parent, index;

	switch (type) {
		case GROUPS_LIST:
			state = state.remove("mapParentToChildren");
			state = state.remove("groups");
			state = state.set(
				"mapParentToChildren",
				payload.get("mapParentToChildren"),
			);
			state = state.set("groups", payload.get("groups"));
			return state;

		case GROUPS_UPDATE_LOCAL:
			return state.set("currentGroup", payload);

		case GROUPS_CREATE_REMOTE:
			parentId = payload.get("parent") || "root";
			parentList = state.getIn(["mapParentToChildren", parentId]) || List();
			parentList = parentList.push(payload.get("id"));
			state = state.setIn(["mapParentToChildren", parentId], parentList);
			state = state.setIn(["groups", payload.get("id")], payload);
			return state.set("currentGroup", null);

		case GROUPS_FETCH_REMOTE:
			return state.set("currentGroup", payload);

		case GROUPS_DELETE_REMOTE:
			group = state.get("currentGroup");
			parent = group.get("parent") || "root";
			index = state
				.getIn(["mapParentToChildren", parent], List())
				.indexOf(group.get("id"));

			state = state.removeIn(["groups", group.get("id")]);
			state = state.removeIn(["mapParentToChildren", group.get("id")]);
			state = state.removeIn(["mapParentToChildren", parent, index]);
			return state.set("currentGroup", null);

		case GROUPS_SAVE_REMOTE:
			state = state.setIn(["groups", payload.get("id")], payload);
			return state.set("currentGroup", null);

		case GROUPS_UPDATE_SELECTED_GROUP_ID:
			return state.set("selectedGroupId", payload);

		case GROUPS_GET_DEFAULT_PERMISSIONS: {
			const permissions = payload.get("permissions");
			const createGroupObject = payload.get("createGroupObject");
			state = state.set("defaultPermissions", permissions);

			if (!createGroupObject) {
				return state;
			}

			let currentGroup = state.get("currentGroup");
			if (!currentGroup) {
				currentGroup = Map({ permissions });
				return state.set("currentGroup", currentGroup);
			}

			return state.setIn(["currentGroup", "permissions"], permissions);
		}

		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE;
		}

		default:
			return state;
	}
}
