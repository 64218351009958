import React from "react";
import { Stepper as MuiStepper } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Text from "../../dumb-components/shared/text/text";
import { arrayOf, shape, number, string } from "prop-types";

const Stepper = ({ steps, activeStep }) => {
	return (
		<MuiStepper activeStep={activeStep}>
			{steps.map((step, index) => (
				<Step key={index}>
					<StepLabel>
						<Text tid={step.label}></Text>
					</StepLabel>
				</Step>
			))}
		</MuiStepper>
	);
};

Stepper.propTypes = {
	steps: arrayOf(
		shape({
			label: string,
		}),
	).isRequired,
	activeStep: number.isRequired,
};

export default Stepper;
