import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Box, useTheme } from "@mui/material";
import Icon from "../../icon/icon";
import TickHeaderTooltip from "./TickHeaderTooltip";

const TickHeader = ({
	displayName,
	tooltipTid,
	showColumnMenu,
	enableMenu,
	isNotMovable,
	isLinked,
}) => {
	const refButton = useRef(null);
	const theme = useTheme();

	let menu = null;
	if (enableMenu) {
		menu = (
			<div
				ref={refButton}
				className="customHeaderMenuButton"
				onClick={() => {
					showColumnMenu(refButton.current);
				}}
			>
				<i className="ag-icon ag-icon-menu"></i>
			</div>
		);
	}

	const linkedIcon = (
		<Icon icon="faLink" size="sml" color={theme.palette.success.main} />
	);
	const unlinkedIcon = (
		<Icon icon="faChainBroken" size="sml" color={theme.palette.grey[600]} />
	);

	return (
		<>
			<Box sx={{ cursor: "pointer" }}>{menu}</Box>
			<TickHeaderTooltip
				title={tooltipTid}
				hideTooltip={false}
				placement="bottom"
				showTic={isLinked}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						overflow: "hidden",
						height: "100%",
						width: "100%",
						justifyContent: "end",
						...(!isNotMovable && { cursor: "grab" }),
						alignItems: "center",
					}}
				>
					<Box sx={{ pr: 1 }}>{isLinked ? linkedIcon : unlinkedIcon}</Box>
					<Box
						sx={{
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
							color: theme.palette.text.primary,
						}}
						className="customHeaderLabel"
					>
						{displayName}
					</Box>
				</Box>
			</TickHeaderTooltip>
		</>
	);
};

TickHeader.propTypes = {
	isLinked: PropTypes.bool.isRequired,
	tooltipTid: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
		.isRequired,
	displayName: PropTypes.string.isRequired,
	showColumnMenu: PropTypes.func,
	isNotMovable: PropTypes.bool,
};

TickHeader.defaultProps = {
	displayName: "",
	showColumnMenu: () => {},
	isNotMovable: false,
};

export default TickHeader;
