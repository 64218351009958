import { Component } from "react";
import { connect } from "react-redux";
import filterAndSort from "../../components/helpers/filter-and-sort.helper";

import { setFilteredTasks } from "../../actions/tasks.actions";

class TasksFilterAndSortContainer extends Component {
	applyFiltersAndSort = () => {
		const {
			allTasks,
			setFilteredTasks,
			filters,
			filterTasksChanged,
			tasksExcludedFromFiltering,
		} = this.props;
		let visibleTasks = allTasks;
		const hasAppliedFilters = this.hasAppliedFilters(filters);
		if (visibleTasks) {
			const filtering = new filterAndSort(
				visibleTasks,
				tasksExcludedFromFiltering,
				filters,
			);
			visibleTasks = filtering.filter();
			visibleTasks = filtering.sort();
			setFilteredTasks(visibleTasks, hasAppliedFilters);
			filterTasksChanged &&
				filterTasksChanged(visibleTasks.size, allTasks.size);
		}
	};

	hasAppliedFilters = (filters) => {
		const { defaultFilters } = this.props;
		let hasAppliedFilters = false;

		filters.forEach((filter) => {
			const source = filter.get("source");
			const values = filter.get("values");
			const val = values && values.get(0);

			// Covers basic filter
			if (
				(source === "task" || source === "tag" || source === "project") &&
				values.size !== 0
			) {
				hasAppliedFilters = true;
				return;
			}

			// Check default filters
			if (
				source !== "task" &&
				source !== "tag" &&
				source !== "project" &&
				((defaultFilters.has(source) &&
					defaultFilters.getIn([source, 0]) !== val) ||
					!defaultFilters.has(source))
			) {
				hasAppliedFilters = true;
				return;
			}
		});

		return hasAppliedFilters;
	};

	componentDidMount = () => {
		this.applyFiltersAndSort();
	};

	componentDidUpdate = (prevProps) => {
		const { allTasks, filters, defaultFilters } = this.props;
		if (prevProps.allTasks !== allTasks) {
			this.applyFiltersAndSort();
		}
		if (prevProps.filters !== filters) {
			this.applyFiltersAndSort();
		}
		if (prevProps.defaultFilters !== defaultFilters) {
			this.applyFiltersAndSort();
		}
	};

	render = () => {
		return this.props.children;
	};
}

const mapStoreToProps = (store) => {
	return {
		allTasks: store.tasks.get("allTasks"),
		filters: store.tasks.get("filterBy"),
		defaultFilters: store.tasks.get("defaultFilters"),
		tasksExcludedFromFiltering: store.tasks.get("tasksExcludedFromFiltering"),
	};
};

const mapActionsToProps = {
	setFilteredTasks,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(TasksFilterAndSortContainer);
