import { saveAs } from "file-saver";

//prints the content of a html element containing a canvas.
//expected to only have one canvas element as child property.
export const printCanvas = ({ ref, filename }) => {
	const element = ref.current;
	const canvas = element.querySelector("canvas");
	const image = canvas.toDataURL("image/png");
	saveAs(image, filename);
};
