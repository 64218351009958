/**
 * Notify reducer
 * @module reducers/notify.reducer
 */

import {
	NOTIFY_LOADING_START,
	NOTIFY_LOADING_END,
	NOTIFY_LOADING_SUCCESS,
	NOTIFY_LOADING_SUCCESS_END,
	NOTIFY_MENU_COLLAPSED,
	NOTIFY_SOCKET_ID,
	NOTIFY_ADD_MESSAGE,
	NOTIFY_REMOVE_MESSAGE,
	NOTIFY_BANKID,
	NOTIFY_CLEAR_MESSAGES,
	NOTIFY_ASIDE_EXPANDED,
	NOTIFY_RETURN_URL,
	NOTIFY_FAKE_LOADING_START,
	NOTIFY_FAKE_LOADING_END,
	NOTIFY_FILE_ENTERED_GLOBAL,
	NOTIFY_HIDE_APP_MENUS,
	NOTIFY_SHOW_APP_MENUS,
	NOTIFY_DEMO_LOADING,
	NOTIFY_ATTACHMENT_UPLOADING,
	NOTIFY_FETCHING_ATTACHMENTS,
	NOTIFY_SET_ACTIVE_TAB,
	NOTIFY_COMPONENT_LOADING,
	NOTIFY_IS_UPLOADING_EXCEL,
} from "../actions/types";

const windowWidth =
	window.innerWidth ||
	document.documentElement.clientWidth ||
	document.body.clientWidth;
let menuCollapsed = windowWidth < 992;

/** The initial state object for notify. */
const INITIAL_STATE = {
	loadInProgress: 0,
	loading: false,
	loadingSuccess: null,
	isSaving: false,
	menuCollapsed: menuCollapsed,
	asideExpanded: false,
	socketId: null,
	notifications: [],
	bankId: null,
	returnUrl: null,
	fakeLoading: false,
	fakeLoadingMessage: "null",
	fileEnteredWebsiteGlobal: false,
	appMenusShow: true,
	demoIsLoading: false,
	attachmentIsUploading: false,
	attachmentsAreBeingFetched: false,
	activeTab: null,
	loadingComponents: {},
	isUploadingExcel: false,
};

/** Export the reducer */
export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case NOTIFY_LOADING_START:
			return {
				...state,
				loadInProgress: state.loadInProgress + 1,
				loading: state.loadInProgress + 1 > 0,
				isSaving: action.payload.isSaving,
			};
		case NOTIFY_LOADING_END:
			return {
				...state,
				loadInProgress:
					state.loadInProgress - 1 > 0 ? state.loadInProgress - 1 : 0,
				loading: state.loadInProgress - 1 > 0,
				isSaving: false,
			};
		case NOTIFY_LOADING_SUCCESS:
			return {
				...state,
				loadInProgress:
					state.loadInProgress - 1 > 0 ? state.loadInProgress - 1 : 0,
				loading: state.loadInProgress - 1 > 0,
				loadingSuccess: true,
				isSaving: false,
			};
		case NOTIFY_LOADING_SUCCESS_END:
			return { ...state, loadingSuccess: null };
		case NOTIFY_MENU_COLLAPSED:
			return { ...state, menuCollapsed: action.payload };
		case NOTIFY_ASIDE_EXPANDED:
			return { ...state, asideExpanded: action.payload };
		case NOTIFY_SOCKET_ID:
			return { ...state, socketId: action.payload };
		case NOTIFY_ADD_MESSAGE:
			return {
				...state,
				notifications: [...state.notifications, action.payload],
			};
		case NOTIFY_REMOVE_MESSAGE:
			return {
				...state,
				notifications: [
					...state.notifications.slice(0, action.payload),
					...state.notifications.slice(action.payload + 1),
				],
			};
		case NOTIFY_CLEAR_MESSAGES:
			return { ...state, notifications: [] };
		case NOTIFY_BANKID:
			return { ...state, bankId: action.payload };
		case NOTIFY_RETURN_URL:
			return { ...state, returnUrl: action.payload };
		case NOTIFY_FAKE_LOADING_START:
			return {
				...state,
				fakeLoading: true,
				fakeLoadingMessage: action.payload,
			};
		case NOTIFY_FAKE_LOADING_END:
			return { ...state, fakeLoading: false, fakeLoadingMessage: null };
		case NOTIFY_FILE_ENTERED_GLOBAL:
			return { ...state, fileEnteredWebsiteGlobal: action.payload };
		case NOTIFY_SHOW_APP_MENUS:
			return { ...state, appMenusShow: true };
		case NOTIFY_HIDE_APP_MENUS:
			return { ...state, appMenusShow: false };
		case NOTIFY_DEMO_LOADING:
			return { ...state, demoIsLoading: action.payload };
		case NOTIFY_ATTACHMENT_UPLOADING:
			return { ...state, attachmentIsUploading: action.payload };
		case NOTIFY_FETCHING_ATTACHMENTS:
			return { ...state, attachmentsAreBeingFetched: action.payload };
		// This set socket ID to null which messes up live updating. Is it really needed to reset this reducer?
		// case COMPANY_RESET_REDUCER: {
		// 	return INITIAL_STATE;
		// }
		case NOTIFY_SET_ACTIVE_TAB:
			return { ...state, activeTab: action.payload };
		case NOTIFY_COMPONENT_LOADING:
			return {
				...state,
				loadingComponents: {
					...state.loadingComponents,
					[action.payload.componentName]: action.payload.isLoading,
				},
			};
		case NOTIFY_IS_UPLOADING_EXCEL: {
			return { ...state, isUploadingExcel: action.payload };
		}
	}

	return state;
}
