import React from "react";
import { bool, oneOf, string } from "prop-types";
import styled, { css } from "styled-components";
import Icon, { mapIconToClassName } from "../icon/icon";
import theme from "../../../../theme";

const StyledWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	position: relative;

	${(props) =>
		(props.isLoading || props.icon) &&
		!props.isTransparent &&
		css`
			> input {
				padding-right: 28px;
				background-color: ${(props) => props.theme.colors.white} !important;
			}
		`}

	${(props) =>
		props.isLoading &&
		props.isTransparent &&
		css`
			> input {
				padding-right: 28px;
				background-color: transparent !important;
			}
		`}

	>span[class*="fa-"] {
		color: ${(props) =>
			props.muted
				? props.theme.colors.muted
				: props.theme.colors[props.iconColor]};
		height: 15px;
		right: ${(props) => props.theme.spacing[3]};
		position: absolute;
		width: 15px;
	}
`;

const StyledAddons = ({
	children,
	icon,
	iconType,
	iconColor,
	isLoading,
	muted,
	isTransparent,
}) => {
	function renderIcon() {
		if (!isLoading) {
			return <Icon icon={icon} size="sm" type={iconType} color={iconColor} />;
		}

		return <Icon icon="faSpinner" size="sm" type="solid" spin />;
	}

	const childWithProp = React.Children.map(children, (child) => {
		return React.cloneElement(child, { ...child.props, disabled: isLoading });
	});

	return (
		<StyledWrapper
			icon={icon}
			iconColor={iconColor}
			isLoading={isLoading}
			muted={muted}
			isTransparent={isTransparent}
		>
			{childWithProp}
			{renderIcon()}
		</StyledWrapper>
	);
};

StyledAddons.defaultProps = {
	isLoading: false,
	muted: false,
	iconColor: "midGrey",
};

StyledAddons.propTypes = {
	muted: bool,
	isTransparent: bool,
	iconType: string,
	icon: oneOf(Object.keys(mapIconToClassName)),
	iconColor: oneOf(Object.keys(theme.colors)),
	isLoading: bool,
};

export default StyledAddons;
