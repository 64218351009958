import { fromJS, Map, List } from "immutable";
import {
	MEETING_TEMPLATES_LIST,
	MEETING_TEMPLATES_UPDATE_LOCAL,
	MEETING_TEMPLATES_CREATE,
	MEETING_TEMPLATES_CREATE_LOCAL,
	MEETING_TEMPLATES_FETCH,
	MEETING_TEMPLATES_SAVE,
	MEETING_TEMPLATES_SAVE_START,
	MEETING_TEMPLATES_DELETE,
	MEETING_TEMPLATES_DIRTY,
	MEETING_TEMPLATES_SELECT,
	MEETING_TEMPLATES_LIST_BY_COMPANIES,
	COMPANY_RESET_REDUCER,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	list: [],
	meetingTemplate: null,
	isDirty: Map(),
	listByCompanies: [],
});

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case MEETING_TEMPLATES_LIST:
			return state.set("list", payload);

		case MEETING_TEMPLATES_UPDATE_LOCAL:
			if (payload) {
				const index = state
					.get("list", List())
					.findIndex(
						(meetingTemplate) =>
							meetingTemplate.get("id") === payload.get("id"),
					);

				if (index >= 0) {
					state = state.setIn(["list", index], payload);
				}

				return state.set("meetingTemplate", payload);
			}

			return state;

		case MEETING_TEMPLATES_SELECT: {
			const meetingTemplate = state
				.get("list", List())
				.find((meetingTemplate) => meetingTemplate.get("id") === payload);
			return state.set("meetingTemplate", meetingTemplate);
		}

		case MEETING_TEMPLATES_CREATE: {
			const meetingTemplate = payload;
			let list = state.get("list");

			list = list.map((existingMeetingTemplate) => {
				if (existingMeetingTemplate.get("id") === meetingTemplate.get("id")) {
					existingMeetingTemplate = meetingTemplate;
				}
				return existingMeetingTemplate;
			});

			const updatedState = state.set("list", list);
			return updatedState.set("meetingTemplate", meetingTemplate);
		}

		case MEETING_TEMPLATES_CREATE_LOCAL: {
			const meetingTemplate = payload.get("meetingTemplate");
			let list = state.get("list");
			list = list.insert(0, meetingTemplate);
			const updatedState = state.set("list", list);
			return updatedState.set("meetingTemplate", meetingTemplate);
		}

		case MEETING_TEMPLATES_FETCH: {
			if (payload && payload.has("id")) {
				state = state.set("meetingTemplate", payload);
			} else if (payload === null) {
				state = state.set("meetingTemplate", null);
			}
			return state;
		}

		case MEETING_TEMPLATES_SAVE_START:
			return state.setIn(["isDirty", payload], false);

		case MEETING_TEMPLATES_SAVE: {
			if (state.getIn(["isDirty", payload.get("id")])) {
				return state;
			}

			const index = (state.get("list") || List()).findIndex(
				(meeting) => meeting.get("id") === payload.get("id"),
			);
			if (index >= 0) {
				state = state.setIn(["list", index], payload);
			}
			return state;
		}

		case MEETING_TEMPLATES_DELETE: {
			const id = payload;
			const list = state.get("list").filter((obj) => {
				return obj.get("id") !== id;
			});

			state = state.set("list", list);
			return state.set("meetingTemplate", null);
		}

		case MEETING_TEMPLATES_LIST_BY_COMPANIES:
			return state.set("listByCompanies", payload);

		case MEETING_TEMPLATES_DIRTY:
			return state.setIn(["isDirty", payload], true);

		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE;
		}

		default:
			return state;
	}
}
