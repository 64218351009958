import React, { Component } from "react";
import { connect } from "react-redux";
import { oneOf, string } from "prop-types";
import {
	fetchMeeting,
	saveMeeting,
	clearMeeting,
	unpublishProtocol,
} from "../../../actions/meetings.actions";
import { listMeetingTemplates } from "../../../actions/meeting-templates.actions";
import { fetchSimpleUsers } from "../../../actions/usersCache.actions";
import MeetingsGeneralInformation from "../../../dumb-components/meetings/meetings-general-information/meetings-general-information";
import Box from "../../../dumb-components/shared/layout/box/box";
import { List, Map, fromJS } from "immutable";
import {
	shouldOpenAgendaTab,
	getIsSigningRequested,
	hasMeetingStarted,
	isMeetingToday,
} from "../../../components/helpers/meeting.helper";
import { createAttendeeObject } from "../../../components/helpers/meeting.helper.pure";
import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import TagsSelectContainer from "../../shared/tags-select.container";
import moment from "../../../modules/moment.module";
import EditorContainer from "../../shared/editor.container";
import {
	MEETING_TEMPLATES_MODAL,
	MEETING_TEMPLATES_IMPORTANT_INFORMATION_MODAL,
	MEETINGS_UNPUBLISH_PROTOCOL_MODAL,
} from "../../../constants/modals";
import { openModal, closeModal } from "../../../actions/modals.actions";
import {
	clearDocument,
	fetchDocument,
} from "../../../actions/documents.actions";
import {
	MEETING_TYPE_STANDARD,
	MEETING_DEFAULT_TEMPLATE_ID,
	EVENT_TYPE_MEETINGS_UPDATE,
	MEETING_STATUS_MEETING_STARTED,
	OBJ_TYPE_MEETING_PROTOCOL,
} from "/shared/constants";
import {
	showScreenMessage,
	hideScreenMessage,
} from "../../../actions/screen-message.actions";
import FieldsLoader from "../../../dumb-components/shared/fields-loader/fields-loader";

import SigneesPanelContainer from "./general/signees-panel.container";
import FeedbackeesPanelContainer from "./general/feedbackees-panel.container";
import AttendeesPanelContainer from "./general/attendees-panel.container";

import ArchiveDropdownItem from "./general/dropdown-items/archive.container";
import AssignSecretaryDropdownItem from "./general/dropdown-items/assign-secretary.container";
import UnpublishProtocolDropdownItem from "./general/dropdown-items/unpublish-protocol.container";

import LocationsContainer from "../location-panels/locations.container";
import MeetingStandardContainer from "./standard/meeting-standard.container";
import history from "../../../interfaces/history";
import { withRouter } from "../../../interfaces/router";
import templateHelper from "../../../components/helpers/templates-helper";
import ConfirmModal from "../../../containers/shared/confirm.container";

import {
	LIVE_MEETINGS_DELETE,
	LIVE_MEETINGS_ATTENDEE_REMOVE,
	LIVE_MEETINGS_PROTOCOL_PUBLISHED,
} from "../../../constants/live-update";
import TemplateInformationModal from "../../../dumb-components/meetings/modals/template-information-modal";
import withResolvedProps from "@/hocs/withResolvedProps";
import useSubscriptionHelper from "@/hooks/useSubscriptionHelper";

const TAB_MEETING = 0;
// const TAB_LOCATION = 1

class MeetingsGeneralContainer extends Component {
	static propTypes = {
		renderMode: oneOf(["summary", "full"]),
		scrollableTarget: string,
	};

	static defaultProps = {
		renderMode: "summary",
	};

	state = {
		meetingNameSuggestions: [],
		meetingTemplates: List(),
		activeTab: TAB_MEETING,
		meetingRoles: List(),
	};

	componentDidMount = () => {
		const {
			fetchMeeting,
			listMeetingTemplates,
			match: { params },
			meeting,
		} = this.props;

		if (meeting?.get("id") !== params.id) {
			fetchMeeting(params.id, null, (meeting) => {
				this.loadUsers(meeting.get("attendees"));
			});
		}

		listMeetingTemplates();
		this.showStartMeetingMessageIfNeeded();
	};

	componentDidUpdate = (prevProps) => {
		const {
			fetchMeeting,
			listMeetingTemplates,
			match: { params },
			selectedGroupId,
			meetingTemplates,
			meeting,
			hideScreenMessage,
		} = this.props;
		const { activeTab } = this.state;

		if (prevProps.match.params.id !== params.id) {
			if (activeTab !== TAB_MEETING) {
				this.setState({ activeTab: TAB_MEETING });
			}

			fetchMeeting(params.id, null, (meeting) => {
				this.loadUsers(meeting.get("attendees"));
			});

			hideScreenMessage();
		}

		if (prevProps.selectedGroupId !== selectedGroupId) {
			listMeetingTemplates();
		}

		if (
			prevProps.meetingTemplates !== meetingTemplates ||
			(meeting &&
				prevProps.meeting &&
				meeting.get("meetingType") !== prevProps.meeting.get("meetingType"))
		) {
			this.parseMeetingTemplates();
		}

		const prevMeeting = prevProps.meeting || Map();
		if (
			meeting &&
			(prevMeeting.get("groupId") !== meeting.get("groupId") ||
				prevMeeting.get("meetingType") !== meeting.get("meetingType"))
		) {
			this.parseMeetingTemplates();
		}

		meeting && this.loadUsers(meeting.get("attendees"));

		if (
			prevProps.meeting?.get("status") !== meeting?.get("status") ||
			prevProps.meeting?.get("startDate") !== meeting?.get("startDate") ||
			prevProps.meeting?.get("moderators") !== meeting?.get("moderators") ||
			prevProps.meeting?.getIn(["computedValues", "isModerator"]) !==
				meeting?.getIn(["computedValues", "isModerator"])
		) {
			this.showStartMeetingMessageIfNeeded();
		}

		this.checkLiveUpdateEvents();
	};

	checkLiveUpdateEvents = () => {
		const {
			audit,
			fetchMeeting,
			meeting,
			clearMeeting,
			userId,
			location: { search },
			history,
		} = this.props;
		const meetingUpdate = audit.get(EVENT_TYPE_MEETINGS_UPDATE, Map());
		const meetingDelete = audit.get(LIVE_MEETINGS_DELETE, Map());
		const attendeeDelete = audit.get(LIVE_MEETINGS_ATTENDEE_REMOVE, Map());
		const protocolPublished = audit.get(
			LIVE_MEETINGS_PROTOCOL_PUBLISHED,
			Map(),
		);
		const meetingId = meeting && meeting.get("id");

		// Attendee was removed
		if (
			attendeeDelete.get("refresh") === true &&
			meetingId === attendeeDelete.get("objId") &&
			attendeeDelete.getIn(["metadata", "removedAttendees"]).includes(userId)
		) {
			history.push({
				pathname: this.getComponentBasePath(),
				search,
			});
		}

		// Meeting was edited
		if (
			meetingUpdate.get("refresh") &&
			meetingId === meetingUpdate.get("objId")
		) {
			fetchMeeting(meetingId);
		}

		// Meeting was deleted
		if (
			meetingDelete.get("refresh") === true &&
			meetingId === meetingDelete.get("objId")
		) {
			clearMeeting();

			history.push({
				pathname: this.getComponentBasePath(),
				search: search,
			});
		}

		//Protocol published
		if (
			protocolPublished.get("refresh") === true &&
			meetingId === protocolPublished.get("objId")
		) {
			fetchMeeting(meetingId);
		}
	};

	showStartMeetingMessageIfNeeded = () => {
		const { meeting, showScreenMessage, hideScreenMessage, isStandardMeeting } =
			this.props;

		if (!meeting) {
			return;
		}

		if (!isStandardMeeting) {
			return;
		}

		if (!meeting.getIn(["computedValues", "isModerator"])) {
			return;
		}

		const meetingStatusStarted =
			meeting.get("status") === MEETING_STATUS_MEETING_STARTED;
		if (meetingStatusStarted) {
			hideScreenMessage();
			return;
		}

		const meetingIsToday = isMeetingToday(meeting);
		const meetingHasStarted = hasMeetingStarted(meeting);

		if (meetingIsToday && !meetingHasStarted) {
			showScreenMessage("START_MEETING", { isStandardMeeting });
		} else {
			hideScreenMessage();
		}
	};

	getComponentBasePath = () => {
		const {
			location: { pathname },
		} = this.props;
		const path = pathname;
		const pathArray = path.split("meetings");
		return pathArray[0] + "meetings";
	};

	parseMeetingTemplates = () => {
		const { meetingTemplates, meeting, isStandardMeeting } = this.props;
		let parsedMeetingTemplates = List();

		if (!meetingTemplates) {
			return;
		}

		parsedMeetingTemplates = meetingTemplates.filter((obj) =>
			obj.get("active"),
		);

		if (meeting) {
			if (isStandardMeeting) {
				parsedMeetingTemplates = parsedMeetingTemplates.filter(
					(obj) => obj.get("id") === MEETING_DEFAULT_TEMPLATE_ID,
				);
			} else {
				parsedMeetingTemplates = parsedMeetingTemplates.filter(
					(obj) =>
						!obj.get("groupId") ||
						obj.get("groupId") === meeting.get("groupId") ||
						obj.get("id") === MEETING_DEFAULT_TEMPLATE_ID,
				);
			}
		}

		parsedMeetingTemplates = parsedMeetingTemplates.map((obj) =>
			Map({ value: obj.get("id"), label: obj.get("name") }),
		);
		this.setState({ meetingTemplates: parsedMeetingTemplates });
	};

	loadUsers = () => {
		const { fetchSimpleUsers, meeting } = this.props;

		if (!meeting) {
			return;
		}

		const attendees = meeting.get("attendees", Map()).keySeq().toList();
		const feedbackees = meeting.get("feedbackees", Map()).keySeq().toList();
		const signatories = meeting.get("signees", Map()).keySeq().toList();
		const userIds = attendees.concat(feedbackees.concat(signatories));

		if (!userIds) {
			return;
		}

		fetchSimpleUsers(userIds);
	};

	updateMeetingLocal = (meeting) => {
		const { saveMeeting } = this.props;

		saveMeeting(meeting);
	};

	onChange = (field, value) => {
		const { openModal, meetingTemplates } = this.props;
		let { meeting } = this.props;

		// New template creation request
		if (field === "templateId" && value === "CREATE_NEW_TEMPLATE") {
			openModal(MEETING_TEMPLATES_MODAL, {
				openCreateView: true,
				closeModalWhenCreateViewIsCanceled: true,
			});
			return;
		}

		if (
			field === "templateId" &&
			value !== "CREATE_NEW_TEMPLATE" &&
			!meeting.get("templateLocked")
		) {
			if (meeting.get("templateId")) {
				const meetingTemplate = meetingTemplates.find(
					(template) => template.get("id") === value,
				);

				if (!meetingTemplate) {
					throw new Error(`no meeting template found for ID ${value}`);
				}

				const agendaItems = templateHelper.itemsToApply(
					meetingTemplate.get("items"),
				);
				meeting = meeting.set("agendaItems", agendaItems);
			} else {
				openModal(MEETING_TEMPLATES_IMPORTANT_INFORMATION_MODAL);
			}
		}

		// Add chairman and secretary as attendees
		if (value && (field === "chairman" || field === "secretary")) {
			let attendees = meeting.get("attendees", Map());

			if (!attendees.has(value)) {
				attendees = attendees.set(value, createAttendeeObject(value));

				meeting = meeting.set("attendees", attendees);
			}
		}

		// Start Date was changed, check so that the endDate is not earlier or same than startDate
		if (field === "startDate" && value) {
			const startDate = moment(value);
			let endDate = meeting.get("endDate") && moment(meeting.get("endDate"));

			if (endDate) {
				endDate = endDate.set({
					year: startDate.year(),
					month: startDate.month(),
					date: startDate.date(),
				});

				meeting = meeting.set("endDate", endDate.toISOString());
			}

			// endDate comes before or at startDate. Set it to meetingStartDate + 1h
			if (startDate >= endDate || !endDate) {
				endDate = startDate.add(1, "hours");
				meeting = meeting.set("endDate", endDate.toISOString());
			}
		}

		// End Date was changed, replace only the time values
		if (field === "endDate" && value) {
			const valueDate = moment(value);
			let endDate = moment(meeting.get("endDate") || meeting.get("startDate"));

			endDate = endDate.set({
				hour: valueDate.hour(),
				minute: valueDate.minute(),
			});

			value = endDate.toISOString();
		}

		meeting = meeting.set(field, value);
		this.updateMeetingLocal(meeting);
	};

	onOpenMeeting = () => {
		const {
			meeting,
			userId,
			location: { pathname, search },
			history,
		} = this.props;
		const openAgendaTab = shouldOpenAgendaTab(meeting, userId);

		// Redirect to agenda-tab
		if (openAgendaTab) {
			history.push(`${pathname}/meeting/agenda`);
			return;
		}

		// Proceed to info tab
		history.push(`${pathname}/meeting/info${search && search}`);
	};

	onOpenProtocolReview = () => {
		const {
			location: { pathname, search },
			meeting,
			history,
		} = this.props;
		const isSigningRequested = getIsSigningRequested(meeting);

		if (isSigningRequested) {
			history.push(`${pathname}/protocol/sign-off${search && search}`);
		} else {
			history.push(`${pathname}/protocol/review${search && search}`);
		}
	};

	onOpenProtocol = () => {
		const {
			meeting,
			company: { urlAlias },
		} = this.props;
		window.open(`/${urlAlias}/protocols/${meeting.get("id")}`, "_blank");
	};

	onTabChange = (path) => {
		this.setState(() => {
			return { activeTab: path };
		});
	};

	onDeleteAttachment = (document, objType) => {
		const { clearDocument } = this.props;
		let { meeting } = this.props;

		if (objType !== OBJ_TYPE_MEETING_PROTOCOL) {
			return;
		}

		meeting = meeting.remove("protocolData");
		this.updateMeetingLocal(meeting);
		clearDocument();
	};

	renderMeetingPreviewDropdown = () => {
		const { meeting, meetingIsDeleted } = this.props;

		if (!meeting) {
			return null;
		}

		const hasExtendedRights = meeting.getIn([
			"computedValues",
			"hasExtendedRights",
		]);

		if (!hasExtendedRights || meetingIsDeleted) {
			return null;
		}

		return (
			<DropdownMenuContainer
				ref={(r) => (this.moreActionBtnRef = r)}
				halignMenu="right"
				noMaxWidth
			>
				<UnpublishProtocolDropdownItem dropdownRef={this.moreActionBtnRef} />
				<AssignSecretaryDropdownItem dropdownRef={this.moreActionBtnRef} />
				<ArchiveDropdownItem dropdownRef={this.moreActionBtnRef} />
			</DropdownMenuContainer>
		);
	};

	renderTagsContainer = () => {
		const { meeting, isSimpleMode, meetingIsDeleted } = this.props;
		const readOnly = isSimpleMode || meetingIsDeleted;

		return (
			<TagsSelectContainer
				onTagsChange={(tags) => this.onChange("tags", tags)}
				readOnly={readOnly}
				value={meeting && meeting.get("tags")}
			/>
		);
	};

	renderEditorContainer = (fieldName) => {
		const { meeting, isSimpleMode, meetingIsDeleted } = this.props;
		const readOnly = isSimpleMode || meetingIsDeleted;

		return (
			<EditorContainer
				fieldName={fieldName}
				objId={meeting.get("id")}
				contentState={meeting.get(fieldName)}
				onChange={this.onChange}
				readOnly={readOnly}
				placeholderTid="meetings.general_information.notes.placeholder"
			/>
		);
	};

	renderLocationContainer = (readOnly) => {
		return <LocationsContainer readOnly={readOnly} withoutPanel={true} />;
	};

	renderGeneralInformation = () => {
		const {
			meeting,
			userId,
			i18n,
			groups,
			renderMode,
			subHasSmartMeetings,
			isSimpleMode,
			meetingIsDeleted,
		} = this.props;
		let { meetingTemplates } = this.state;
		const templateLocked = meeting && meeting.get("templateLocked");

		// Sort alphabetically
		meetingTemplates = meetingTemplates.sortBy((t) => t.get("label"));

		if (subHasSmartMeetings) {
			const createTemplateOption = fromJS({
				value: "CREATE_NEW_TEMPLATE",
				label: i18n.messages["meeting.general.template.create_new_template"],
			});
			// Put new template in the beginning of the list
			meetingTemplates = meetingTemplates.unshift(createTemplateOption);
		}

		const group =
			meeting && groups && groups.has(meeting.get("groupId"))
				? groups.get(meeting.get("groupId"))
				: null;
		const atLeastOneNotified =
			meeting &&
			meeting.get("signees", Map()).find((s) => s.get("notified") === true)
				? true
				: false;

		return (
			<MeetingsGeneralInformation
				meeting={meeting}
				userId={userId}
				onChange={this.onChange}
				readOnly={isSimpleMode || atLeastOneNotified || meetingIsDeleted}
				templateOptions={meetingTemplates}
				onOpenMeeting={this.onOpenMeeting}
				onOpenProtocolReview={this.onOpenProtocolReview}
				onOpenProtocol={this.onOpenProtocol}
				renderFilePreview={this.renderFilePreview}
				renderMeetingPreviewDropdown={this.renderMeetingPreviewDropdown}
				renderTagsContainer={this.renderTagsContainer}
				renderEditorContainer={this.renderEditorContainer}
				templateLocked={templateLocked}
				group={group}
				userLang={i18n.language}
				renderMode={renderMode}
				meetingIsDeleted={meetingIsDeleted}
				renderLocation={this.renderLocationContainer}
				isDeleted={meetingIsDeleted}
			/>
		);
	};

	onDoUnpublish = () => {
		const {
			meeting,
			unpublishProtocol,
			fetchDocument,
			protocolDocumentId,
			closeModal,
		} = this.props;

		unpublishProtocol(meeting, () => {
			fetchDocument(protocolDocumentId);
			closeModal();
		});
	};

	render = () => {
		const {
			meeting,
			renderMode,
			isStandardMeeting,
			meetingIsFinished,
			scrollableTarget,
			isUnpublishConfirmModalOpen,
			protocolSigned,
			closeModal,
			isTemplateModalOpen,
		} = this.props;

		if (!meeting) {
			return <FieldsLoader />;
		}

		if (isStandardMeeting) {
			return (
				<MeetingStandardContainer
					updateMeetingLocal={this.updateMeetingLocal}
					scrollableTarget={scrollableTarget}
				/>
			);
		}

		return (
			<>
				{isUnpublishConfirmModalOpen && protocolSigned && (
					<ConfirmModal
						isOpen={true}
						title="meetings.protocol.unpublish.signed.confirm_modal.title"
						question="meetings.protocol.unpublish.signed.confirm_modal.text"
						onConfirm={this.onDoUnpublish}
						onDecline={() => {
							closeModal();
						}}
					/>
				)}
				<Box direction="column">
					{this.renderGeneralInformation()}

					{renderMode === "summary" && (
						<>
							<SigneesPanelContainer />
							<FeedbackeesPanelContainer />

							{meetingIsFinished && <AttendeesPanelContainer showSummary />}
						</>
					)}
				</Box>
				{isTemplateModalOpen && (
					<TemplateInformationModal
						primaryTid={"meetings.smart.template_information_modal.body"}
					/>
				)}
			</>
		);
	};
}

function mapStoreToProps(store) {
	return {
		history: history,
		meeting: store.meetings.get("meeting"),
		isStandardMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) ===
			MEETING_TYPE_STANDARD,
		userId: store.user.getIn(["userObj", "id"]),
		selectedGroupId: store.groups.get("selectedGroupId"),
		groups: store.groups.get("groups"),
		investors: store.investors.get("list"),
		meetingTemplates: store.meetingTemplates.get("list"),
		i18n: store.i18n,
		audit: store.audit.get("meetings"),
		company: store.company.company,
		attachedProtocolMetadata: store.meetings.getIn(["meeting", "protocolData"]),
		isSimpleMode: store.meetings.getIn([
			"meeting",
			"computedValues",
			"isSimpleMode",
		]),
		protocolIsInESigningMode:
			store.documents.getIn(["document", "eSigning"]) === true,
		meetingIsDeleted: store.meetings.getIn(["meeting", "isDeleted"]),
		meetingIsFinished: store.meetings.getIn(
			["meeting", "computedValues", "meetingIsFinished"],
			false,
		),
		isTemplateModalOpen:
			store.modals.getIn(["activeModal", "name"]) ===
			MEETING_TEMPLATES_IMPORTANT_INFORMATION_MODAL,
		isUnpublishConfirmModalOpen:
			store.modals.getIn(["activeModal", "name"]) ===
			MEETINGS_UNPUBLISH_PROTOCOL_MODAL,
		protocolSigned: store.meetings.getIn(["meeting", "protocolSigned"], false),
		protocolDocumentId: store.meetings.getIn([
			"meeting",
			"protocolData",
			"documentId",
		]),
	};
}

const mapActionsToProps = {
	fetchMeeting,
	saveMeeting,
	fetchSimpleUsers,
	listMeetingTemplates,
	clearMeeting,
	openModal,
	clearDocument,
	showScreenMessage,
	hideScreenMessage,
	closeModal,
	fetchDocument,
	unpublishProtocol,
};

const MeetingsGeneralContainerConnected = connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingsGeneralContainer);

const MeetingsGeneralContainerWithRouter = withRouter(
	MeetingsGeneralContainerConnected,
);

export default withResolvedProps(() => {
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	return { subHasSmartMeetings: subscriptionHelper?.smartMeetingsEnabled };
})(MeetingsGeneralContainerWithRouter);
