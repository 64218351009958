import React, { PureComponent } from "react";
import { Button } from "@/components/ui/button";
import { bool, func } from "prop-types";
import bankIdLogo from "../../../assets/images/bankid.svg";
import i18n from "@/i18n";

const { t } = i18n;

export default class SignInEmail extends PureComponent {
	static propTypes = {
		onClickSignIn: func,
		onChange: func,
		actionBtnLoading: bool,
	};

	render = () => {
		const { onClickSignIn, actionBtnLoading } = this.props;

		return (
			<Button
				isLoading={actionBtnLoading}
				onClick={onClickSignIn}
				size="lg"
				className="w-full"
				variant="outline"
			>
				<img src={bankIdLogo} width={36} />
				{t("credentials_signin_bankid")}
			</Button>
		);
	};
}
