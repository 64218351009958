import React from "react";

import { Info, Lightbulb } from "lucide-react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";

export default function CredentialsCarouselPricingUpdateJanuary() {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col w-full h-full gap-12 select-none [&_h2]:text-xl [&>p]:text-background/80 [&>p>strong]:text-background [&_s]:opacity-40">
			<h2 className="mb-8">
				<Trans i18nKey="credentials_carousel.pricing_update_january_2025.heading" />
			</h2>
			<div className="grid grid-cols-[min-content_1fr] gap-8">
				<div>
					<Info strokeWidth={2} size={40} className="text-brand" />
				</div>
				<div className="flex flex-col gap-12">
					<Trans i18nKey="credentials_carousel.pricing_update_january_2025.body" />
					<Button
						variant="secondary"
						className="bg-transparent text-background hover:bg-background/20"
						size="lg"
						onClick={() => {
							window.open(
								t("credentials_carousel.pricing_update_january_2025.link"),
								"_blank",
							);
						}}
					>
						<Lightbulb /> {t("generic.learn_more")}
					</Button>
				</div>
			</div>
		</div>
	);
}
