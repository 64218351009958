import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import {
	SUBSCRIPTION_ESIGN_BANKID_COST,
	NUMBER_FORMAT_CURRENCY,
} from "/shared/constants";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";

export const PrepaidInfo = () => {
	const { t } = useTranslation();

	return (
		<Box sx={{ flex: 1 }}>
			<Typography variant="body1">
				{t("subscriptions.prepaid_modal.info.title")}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t("subscriptions.prepaid_modal.info.price_per_signing", {
					cost: localeFormatNumber(
						SUBSCRIPTION_ESIGN_BANKID_COST,
						NUMBER_FORMAT_CURRENCY,
					),
				})}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t("subscriptions.prepaid.description")}
			</Typography>
			<Typography variant="body2" gutterBottom>
				{t("subscriptions.prepaid.description_pt2")}
			</Typography>
		</Box>
	);
};
