import React, { Component } from "react";
import { connect } from "react-redux";

import OnboardingModal from "../../../../../dumb-components/onboarding/modal";
import HeaderControls from "../../header-controls.container";
import WelcomeShareholderUser from "../../../../../dumb-components/onboarding/modals/dashboard/welcome-shareholder-user";

import { closeModal } from "../../../../../actions/modals.actions";
import { markOnboardAsDismiss } from "../../../../../actions/notifications.actions";
import history from "../../../../../interfaces/history";

class WelcomeShareholderUserOnboardModal extends Component {
	goToInvestments = () => {
		this.props.history.push("/portfolio");

		this.dismissPermanently();
	};

	dismissPermanently = () => {
		const { notificationId, markOnboardAsDismiss, closeModal } = this.props;
		markOnboardAsDismiss(notificationId);
		closeModal();
	};

	renderControls = () => {
		return <HeaderControls />;
	};

	render = () => {
		const { addedToCompanyName } = this.props;

		return (
			<OnboardingModal dropdownComponent={this.renderControls()}>
				<WelcomeShareholderUser
					companyName={addedToCompanyName}
					onGoToInvestments={this.goToInvestments}
				/>
			</OnboardingModal>
		);
	};
}

const mapStoreToProps = (store) => {
	const modalOptions = store.modals.getIn(["activeModal", "options"]);

	return {
		history: history,
		notificationId: modalOptions.get("notificationId"),
		addedToCompanyName: modalOptions.getIn([
			"notificationData",
			"addedToCompanyName",
		]),
	};
};

const mapActionsToProps = {
	closeModal,
	markOnboardAsDismiss,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(WelcomeShareholderUserOnboardModal);
