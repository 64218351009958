import React, { Component } from "react";
import { connect } from "react-redux";
import { bool } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import { downloadDocument } from "../../../actions/documents.actions";
import DocumentsList from "../../../dumb-components/meetings/protocol-view/documents-list/documents-list";

class DocumentsListContainer extends Component {
	static propTypes = {
		documentIds: immutablePropTypes.list,
		files: immutablePropTypes.list,
		hideProposalColumn: bool,
	};

	state = {
		files: null,
	};

	componentDidMount = () => {
		const { files } = this.props;

		if (files) {
			this.parseFiles();
		}
	};

	componentDidUpdate = (prevProps) => {
		const { files } = this.props;

		if (files !== prevProps.files) {
			this.parseFiles();
		}
	};

	parseFiles = () => {
		let { files } = this.props;

		if (!files || files.size === 0) {
			return;
		}

		this.setState({ files });
	};

	openFile = (file) => {
		const { downloadDocument } = this.props;
		downloadDocument({ documentId: file.get("id"), openInViewer: true });
	};

	downloadFile = (file) => {
		const { downloadDocument } = this.props;
		downloadDocument({ documentId: file.get("id") });
	};

	render = () => {
		const { hideProposalColumn } = this.props;
		const { files } = this.state;

		return (
			<DocumentsList
				documents={files}
				onOpenFile={this.openFile}
				onDownloadFile={this.downloadFile}
				hideProposalColumn={hideProposalColumn}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		company: store.company.company,
	};
};

const mapActionsToProps = {
	downloadDocument,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(DocumentsListContainer);
