import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import {
	NUMBER_FORMAT_CURRENCY,
	NUMBER_FORMAT_INTEGER,
} from "/shared/constants";
import { DateTime } from "luxon";
import { emissionConsts } from "../../../constants/emissions";

const {
	common: { newShareCountTypes, emissionTypes },
} = emissionConsts;

export function displayCurrency(num) {
	if (num !== 0 && !num) {
		return "-";
	}

	return localeFormatNumber(num, NUMBER_FORMAT_CURRENCY, { suffix: "" });
}

export function displayInteger(num) {
	if (num !== 0 && !num) {
		return "-";
	}

	return localeFormatNumber(num, NUMBER_FORMAT_INTEGER, { suffix: "" });
}

export const displayShareCount = (emission) => {
	switch (emission.newShareCountType) {
		case newShareCountTypes.range:
			return `${displayInteger(emission.newShareCountMin)} - ${displayInteger(
				emission.newShareCountMax,
			)}`;
		case newShareCountTypes.highest:
			return `0 - ${displayInteger(emission.newShareCountMax)}`;
		case newShareCountTypes.specific:
			return displayInteger(emission.newShareCountMax);
		default:
			return "-";
	}
};

export const displayEmissionType = (emission) => {
	switch (emission.emissionType) {
		case emissionTypes.preferential:
			return "emissions.form-field.emission-type.options.preferential";
		case emissionTypes.directed:
			return "emissions.form-field.emission-type.options.directed";
		default:
			return "-";
	}
};

export const displayDate = (dateString) => {
	const dateTime = DateTime.fromISO(dateString);
	if (dateString && DateTime.fromISO(dateString).isValid) {
		return dateTime.toFormat("yyyy-MM-dd");
	}

	return "-";
};

export const displayShareTypeDisclaimers = (untranslatedReservattions, t) => {
	const reservations = untranslatedReservattions.map((str) =>
		t(`transfer-limitation.${str}`),
	);
	return reservations.join(", ");
};
