import React, { PureComponent } from "react";
import styled from "styled-components";

import folderCreate from "../../../../assets/images/onboarding/folder-create.png";
import folderQuickstart from "../../../../assets/images/onboarding/folder-directory-quickstart.png";
import userImageConfig from "../../../../assets/images/onboarding/config.png";

import LeftRightLayout, {
	Header,
	Entry,
} from "../sub-modules/left-right-layout";
import CenterLeftRightLayout, {
	Column,
} from "../sub-modules/centered-left-right-layout";
import Button from "../../../shared/button/button";
import Text from "../../../shared/text/text";

const StyledRedirectWrapper = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	padding: ${(props) => props.theme.spacing[5]}
		${(props) => props.theme.spacing[6]};
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
`;

const StyledRedirectImage = styled.img`
	width: 160px;
	height: auto;
`;

/**
 * Props:
 * - onReadMoreScratch
 * - onReadMoreTemplate
 */
export class AdminFirstStepBody extends PureComponent {
	render = () => {
		const { onReadMoreScratch, onReadMoreTemplate } = this.props;

		return (
			<CenterLeftRightLayout>
				<Column
					image={folderCreate}
					title="onboarding.modals.documents.doc_setup.scratch.title"
					subtitle="onboarding.modals.documents.doc_setup.scratch.subtitle"
					bottomComponent={
						<Button
							minWidth="100px"
							onClick={onReadMoreScratch}
							color="white"
							mode="primary"
							tid="onboarding.modals.documents.doc_setup.scratch.read_more"
						/>
					}
				/>
				<Column
					image={folderQuickstart}
					title="onboarding.modals.documents.doc_setup.template.title"
					subtitle="onboarding.modals.documents.doc_setup.template.subtitle"
					bottomComponent={
						<Button
							minWidth="100px"
							onClick={onReadMoreTemplate}
							mode="primary"
							color="white"
							tid="onboarding.modals.documents.doc_setup.scratch.read_more"
						/>
					}
				/>
			</CenterLeftRightLayout>
		);
	};
}

/**
 * Props:
 * - onStartFromScratch
 * - onCancel
 * - isLoading
 */
export class AdminReadMoreScratchBody extends PureComponent {
	render = () => {
		const { onStartFromScratch, onCancel, isLoading } = this.props;

		return (
			<LeftRightLayout image={folderCreate}>
				<Header tid="onboarding.modals.documents.doc_setup.scratch_details.header" />

				<Entry
					titleTid="onboarding.modals.documents.doc_setup.scratch_details.subtitle.1"
					subTextTid="onboarding.modals.documents.doc_setup.scratch_details.text.1"
				/>

				<Entry
					titleTid="onboarding.modals.documents.doc_setup.scratch_details.subtitle.2"
					subTextTid="onboarding.modals.documents.doc_setup.scratch_details.text.2"
				/>

				<Entry
					titleTid="onboarding.modals.documents.doc_setup.scratch_details.subtitle.3"
					subTextTid="onboarding.modals.documents.doc_setup.scratch_details.text.3"
					bottomComponent={
						<>
							<Button
								tid="onboarding.modals.documents.doc_setup.scratch_details.btn.start_from_scratch"
								onClick={onStartFromScratch}
								isLoading={isLoading}
								mode="primary"
							/>
							<Button
								tid="onboarding.modals.documents.doc_setup.scratch_details.btn.cancel"
								onClick={onCancel}
								disabled={isLoading}
							/>
						</>
					}
				/>
			</LeftRightLayout>
		);
	};
}

/**
 * Props:
 * - onStartFromTemplate
 * - onCancel
 * - isLoading
 */
export class AdminReadMoreTemplateBody extends PureComponent {
	render = () => {
		const { onStartFromTemplate, onCancel, isLoading } = this.props;

		return (
			<LeftRightLayout image={folderQuickstart}>
				<Header tid="onboarding.modals.documents.doc_setup.template_details.header" />

				<Entry
					titleTid="onboarding.modals.documents.doc_setup.template_details.subtitle.1"
					subTextTid="onboarding.modals.documents.doc_setup.template_details.text.1"
					subTextValues={{
						link: (
							<Text
								hasUnderline
								bold={600}
								tid="onboarding.modals.documents.doc_setup.template_details.text.1.link"
								onClick={() => {
									window.open(
										"https://invono.se/dokument/dokumentstruktur/",
										"_blank",
									);
								}}
							/>
						),
					}}
				/>

				<Entry
					titleTid="onboarding.modals.documents.doc_setup.template_details.subtitle.2"
					subTextTid="onboarding.modals.documents.doc_setup.template_details.text.2"
					subTextValues={{
						link: (
							<Text
								hasUnderline
								bold={600}
								tid="onboarding.modals.documents.doc_setup.template_details.text.2.link"
								onClick={() => {
									window.open(
										"https://invono.se/wp-content/uploads/2020/03/Mappstruktur-dokument.pdf",
										"_blank",
									);
								}}
							/>
						),
					}}
				/>

				<Entry
					titleTid="onboarding.modals.documents.doc_setup.template_details.subtitle.3"
					subTextTid="onboarding.modals.documents.doc_setup.template_details.text.3"
					bottomComponent={
						<>
							<Button
								tid="onboarding.modals.documents.doc_setup.template_details.btn.start_from_scratch"
								onClick={onStartFromTemplate}
								isLoading={isLoading}
								mode="primary"
							/>
							<Button
								tid="onboarding.modals.documents.doc_setup.template_details.btn.cancel"
								onClick={onCancel}
								disabled={isLoading}
							/>
						</>
					}
				/>
			</LeftRightLayout>
		);
	};
}

export class UserTemplateMoreInfo extends PureComponent {
	render = () => {
		return (
			<StyledRedirectWrapper>
				<StyledRedirectImage src={userImageConfig} />
				<Text
					tag="h1"
					tid="onboarding.modals.documents.doc_setup.user.info.header"
					align="center"
				/>
			</StyledRedirectWrapper>
		);
	};
}
