import React from "react";
import styled from "@mui/styled-engine";

const StyledNumber = styled("span")(({ negative, theme }) => {
	return {
		color: negative ? theme.palette.error.main : theme.palette.text.primary,
	};
});

function FinancialRenderer({ value, valueFormatted }) {
	if (typeof value === "undefined") {
		return null;
	}

	if (typeof value !== "number") {
		return "-";
	}

	return <StyledNumber negative={value < 0}>{valueFormatted}</StyledNumber>;
}

export { FinancialRenderer };
