/**
 * Routes
 * @module routes
 */

import React from "react";
import { Route, Redirect, Switch } from "./interfaces/router";
import Signout from "./containers/credentials/signout.container";
import Dashboard from "./containers/dashboard/dashboard.container";
import PersonalDocuments from "./containers/personal-documents/personal-documents.container";
import InvestmentPortfolio from "./components/user/investment-portfolio";
import Profile from "./components/user/services/profile/profile";
import NotificationPreferences from "./components/user/notification-preferences";
import CredentialsContainer from "./containers/credentials/credentials.container";

// Company
import CompanyRoutesContainer from "./containers/company/company-routes.container";
import DealflowRoute from "./containers/dealflow/DealflowRoute";

export const ROUTES = {
	SIGNIN: "/signin",
	SIGNUP: "/signup",
	FORGOT_PASSWORD: "/forgot-password",
	SIGNOUT: "/signout",
	DASHBOARD: "/dashboard",
	PERSONAL_DOCUMENTS: "/personal-documents",
	PROFILE: "/profile",
	NOTIFICATION_PREFERENCES: "/notification-preferences",
	PORTFOLIO: "/portfolio",
	DEALFLOW: "/dealflow",
};

const Routes = () => {
	return (
		<>
			<Switch>
				<Route exact path={ROUTES.SIGNIN} component={CredentialsContainer} />
				<Route exact path={ROUTES.SIGNUP} component={CredentialsContainer} />
				<Route
					exact
					path={ROUTES.FORGOT_PASSWORD}
					component={CredentialsContainer}
				/>
				<Route exact path={ROUTES.SIGNOUT} component={Signout} />

				<Redirect exact from="/" to="/dashboard" />
				<Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
				<Route
					exact
					path={ROUTES.PERSONAL_DOCUMENTS}
					component={PersonalDocuments}
				/>

				<Route exact path={ROUTES.PROFILE} component={Profile} />
				<Route
					exact
					path={ROUTES.NOTIFICATION_PREFERENCES}
					component={NotificationPreferences}
				/>
				<Route path={ROUTES.PORTFOLIO} component={InvestmentPortfolio} />
				<Route path={ROUTES.DEALFLOW} component={DealflowRoute} />

				<Route path="/:company" component={CompanyRoutesContainer} />
			</Switch>
		</>
	);
};

export default Routes;
