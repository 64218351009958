import { Component } from "react";
import { connect } from "react-redux";
import { string } from "prop-types";
import { map } from "react-immutable-proptypes";

import history from "../../../interfaces/history";

import { deleteDocuments } from "../../../actions/documents.actions";
import { deleteSharedDocument } from "../../../actions/documents-sharing.actions";
import { openModal, closeModal } from "../../../actions/modals.actions";
import {
	CONFIRM_DELETE_DOCUMENT,
	CONFIRM_DELETE_SIGNED_DOCUMENT,
	CONFIRM_DELETE_DOCUMENT_SIGNING_PROCESS_ONGOING,
} from "../../../constants/modals";

import withResolvedProps from "@/hocs/withResolvedProps";
import useSubscriptionHelper from "@/hooks/useSubscriptionHelper";

const DELETE_TOOLTIP_STATES = {
	default: {
		tid: "documents.toolbar.tooltip.trash_document",
	},
};

class DeleteFileComponentContainer extends Component {
	static propTypes = {
		document: map.isRequired,
		basePath: string.isRequired,
		querystr: string.isRequired,
	};

	reqCallback = () => {
		const { basePath, querystr } = this.props;

		history.push({
			pathname: basePath,
			search: querystr,
		});
	};

	deleteDocument = () => {
		const { document, openModal, eSigningEnabled } = this.props;
		const func = this.doDeleteDocument;
		const eSigningFinalized = document.getIn(["metadata", "documentIsSigned"]);
		const ongoingEsigning = document.getIn(["metadata", "ongoingEsigning"]);

		if (document.has("links") && document.get("links").size > 0) {
			openModal(CONFIRM_DELETE_DOCUMENT, func);
			return;
		} else if (eSigningEnabled && eSigningFinalized) {
			openModal(CONFIRM_DELETE_SIGNED_DOCUMENT, func);
			return;
		} else if (eSigningEnabled && ongoingEsigning) {
			openModal(CONFIRM_DELETE_DOCUMENT_SIGNING_PROCESS_ONGOING, func);
			return;
		}

		this.doDeleteDocument();
	};

	doDeleteDocument = () => {
		const {
			deleteDocuments,
			document,
			closeModal,
			deleteSharedDocument,
			isSharedFromCompany,
			isSharedFromCompanyId,
		} = this.props;
		const docId = document.get("id");
		if (isSharedFromCompany) {
			deleteSharedDocument(docId, isSharedFromCompanyId, this.reqCallback);
		} else {
			deleteDocuments(docId, this.reqCallback);
		}
		closeModal();
	};

	getDeleteTooltipData = () => {
		const { document, isSharedFromCompany, isSharedFromCompanyName } =
			this.props;
		const canDeleteDocument = document.get("ALLOW_DELETE");

		let activeState = "default";
		let isDisabled = false;
		const tooltipValues = {};

		if (!canDeleteDocument && isSharedFromCompany) {
			activeState = "hasNoPermissionsSharedCompany";
			tooltipValues.companyName = isSharedFromCompanyName;
			isDisabled = true;
		} else if (!canDeleteDocument) {
			activeState = "hasNoPermissions";
			isDisabled = true;
		}

		return {
			TOOLTIP_STATES: DELETE_TOOLTIP_STATES,
			activeState,
			tooltipValues,
			isDisabled,
		};
	};

	getDocumentIsDeleted = () => {
		const { document } = this.props;
		return document.get("isDeleted");
	};

	render = () => {
		const { document, children } = this.props;
		const isDeleted = document.get("isDeleted");
		const { TOOLTIP_STATES, activeState, tooltipValues, isDisabled } =
			this.getDeleteTooltipData();

		if (isDeleted) {
			return null;
		}

		return children({
			softDelete: this.deleteDocument,
			TOOLTIP_STATES,
			activeState,
			tooltipValues,
			isDisabled,
			isDeleted: false,
		});
	};
}

const mapStoreToProps = (store, ownProps) => {
	const isSharedFromCompanyId = ownProps.document.get("isSharedFromCompanyId");

	return {
		isSharedFromCompanyId,
		isSharedFromCompany: Boolean(isSharedFromCompanyId),
		isSharedFromCompanyName: store.folders.getIn([
			"folders",
			isSharedFromCompanyId,
			"translatedName",
		]),
	};
};

const mapActionsToProps = {
	deleteDocuments,
	deleteSharedDocument,
	openModal,
	closeModal,
};

const DeleteFileComponentContainerConnected = connect(
	mapStoreToProps,
	mapActionsToProps,
)(DeleteFileComponentContainer);

export default withResolvedProps(() => {
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	return { eSigningEnabled: subscriptionHelper?.eSigningEnabled };
})(DeleteFileComponentContainerConnected);
