import req from "../modules/request.module";
import { fromJS } from "immutable";
import {
	NOTIFICATIONS_ONBOARD_FETCH_REMOTE,
	NOTIFICATIONS_ONBOARD_SINGLE_FETCH_REMOTE,
	NOTIFICATIONS_ONBOARD_MARK_READ,
	NOTIFICATIONS_ONBOARD_MARK_DISMISS,
	NOTIFICATIONS_ONBOARD_SESSION_RESET,
	NOTIFICATIONS_LOCATION_UPDATE_LOCAL,
} from "./types";
import { NOTIFICATION_TYPE_ONBOARDING_MODAL } from "/shared/constants";

export function fetchOnboardNotifications(callback) {
	return function (dispatch) {
		req
			.get(`/notifications/type/${NOTIFICATION_TYPE_ONBOARDING_MODAL}`)
			.then((response) => {
				dispatch({
					type: NOTIFICATIONS_ONBOARD_FETCH_REMOTE,
					payload: fromJS(response.data.notifications),
				});
				callback && callback(null);
			})
			.catch((e) => {
				callback && callback(e);
			});
	};
}

export function markOnboardAsRead(id) {
	return function (dispatch) {
		dispatch({ type: NOTIFICATIONS_ONBOARD_MARK_READ, payload: id });
		req.post(`/notifications/onboard/mark/${id}/read`);
	};
}

export function markOnboardAsDismiss(id) {
	return function (dispatch) {
		dispatch({ type: NOTIFICATIONS_ONBOARD_MARK_DISMISS, payload: id });
		req.post(`/notifications/onboard/mark/${id}/dismiss`);
	};
}

export function updateUserLocation(locationConstant) {
	return function (dispatch) {
		dispatch({
			type: NOTIFICATIONS_LOCATION_UPDATE_LOCAL,
			payload: locationConstant,
		});
	};
}

// Resets user onboarding modals (isRead isDismissed is set to false)
export function resetOnboardingModals(callback) {
	return function (dispatch) {
		req
			.post(`/notifications/onboard/reset`)
			.then((response) => {
				dispatch({ type: NOTIFICATIONS_ONBOARD_SESSION_RESET });
				dispatch({
					type: NOTIFICATIONS_ONBOARD_FETCH_REMOTE,
					payload: fromJS(response.data),
				});
				callback && callback(null);
			})
			.catch((e) => {
				callback && callback(e);
			});
	};
}

export function socketHandleNotifications(eventObj) {
	return function (dispatch) {
		dispatch({
			type: NOTIFICATIONS_ONBOARD_SINGLE_FETCH_REMOTE,
			payload: fromJS(eventObj),
		});
	};
}
