import { fromJS } from "immutable";
import req from "../../modules/request.module";
import {
	DOCUMENT_ESIGN_VERIFY_FETCH,
	DOCUMENT_ESIGN_LOADING,
	DOCUMENT_ESIGN_SIGN_FETCH,
	DOCUMENT_FETCH,
	DOCUMENTS_FETCH_ATTACHMENTS,
	DOCUMENTS_UPDATE_ATTACHMENT,
	DOCUMENTS_APPEND_ATTACHMENT,
	DOCUMENTS_REMOVE_ATTACHMENT,
} from "./types";
import {
	EVENT_TYPE_ATTACHED_DOCUMENT_UPDATE,
	EVENT_TYPE_ATTACHED_DOCUMENT_CREATE,
	EVENT_TYPE_ATTACHED_DOCUMENT_DELETE,
	EVENT_TYPE_ATTACHED_DOCUMENT_LINKED,
} from "/shared/constants";

import { saveAs } from "file-saver";

const isMobileDevice = () => {
	let check = false;
	(function (a) {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
				a,
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
				a.substr(0, 4),
			)
		) {
			check = true;
		}
	})(navigator.userAgent || navigator.vendor || window.opera);
	return check;
};

export function eSignGetDocument(documentId, companyId, userId, cb, ecb) {
	return function (dispatch) {
		dispatch({ type: DOCUMENT_ESIGN_LOADING, payload: true });

		return req
			.get(
				`/documents/public/esign/${documentId}?companyId=${companyId}&sub=${userId}`,
			)
			.then((response) => {
				cb && cb(response);
				dispatch({
					type: DOCUMENT_ESIGN_SIGN_FETCH,
					payload: fromJS(response.data),
				});
			})
			.catch((err) => {
				ecb && ecb(err);
				console.log(err);
			})
			.finally(() => {
				dispatch({ type: DOCUMENT_ESIGN_LOADING, payload: false });
			});
	};
}

export function eSignVerifyDocument(file, cb) {
	return function (dispatch) {
		dispatch({ type: DOCUMENT_ESIGN_LOADING, payload: true });

		return req
			.post(`/documents/public/esign/verify`, file)
			.then((response) => {
				dispatch({
					type: DOCUMENT_ESIGN_VERIFY_FETCH,
					payload: fromJS(response.data),
				});
				cb && cb();
			})
			.catch(() => {
				cb && cb();
			})
			.finally(() => {
				dispatch({ type: DOCUMENT_ESIGN_LOADING, payload: false });
			});
	};
}

const verify = (
	orderRef,
	documentId,
	companyId,
	userId,
	bankidCallback,
	callback,
) => {
	return function (dispatch) {
		req
			.post(
				`/documents/public/esign/${documentId}/sign/complete?companyId=${companyId}&sub=${userId}`,
				{
					orderRef,
				},
			)
			.then((response) => {
				dispatch({
					type: DOCUMENT_ESIGN_SIGN_FETCH,
					payload: fromJS(response.data),
				});
				callback && callback();
			})
			.catch((e) => {
				callback && callback(e);
				console.log(e);
			});
	};
};

const collect = (
	orderRef,
	documentId,
	companyId,
	userId,
	bankidCallback,
	callback,
) => {
	return function (dispatch) {
		req
			.post("/users/public/bankid/collect", { orderRef })
			.then((response) => {
				const { status } = response.data;

				if (status === "not_found") {
					bankidCallback({});
					return callback();
				} else if (status === "pending") {
					setTimeout(() => {
						bankidCallback({ ...response.data, orderRef });

						dispatch(
							collect(
								orderRef,
								documentId,
								companyId,
								userId,
								bankidCallback,
								callback,
							),
						);
					}, 1000);
				} else if (status === "complete") {
					dispatch(
						verify(
							orderRef,
							documentId,
							companyId,
							userId,
							bankidCallback,
							callback,
						),
					);
				} else {
					callback && callback(new Error("Failed to collect bankid"));
				}
			})
			.catch((e) => {
				callback && callback(e);
				console.log(e);
			});
	};
};

export function eSignSignCollectDocument(
	orderRef,
	documentId,
	companyId,
	userId,
	bankidCallback,
	callback,
	getShouldContinue,
) {
	return function (dispatch) {
		console.log(orderRef);
		dispatch(
			collect(
				orderRef,
				documentId,
				companyId,
				userId,
				bankidCallback,
				callback,
				getShouldContinue,
			),
		);
	};
}

export function downloadMultipleDocumentsPublic({
	documentsData,
	companyId,
	userId,
}) {
	return function () {
		req
			.post(
				`/documents/public/multi-download/?companyId=${companyId}&sub=${userId}`,
				{ documentsData },
			)
			.then((response) => {
				const { downloadData } = response.data;
				downloadData &&
					downloadData.forEach((data) => {
						const { downloadUrl, originalname } = data;
						fetch(downloadUrl)
							.then((res) => res.blob())
							.then((blob) => saveAs(blob, originalname))
							.catch((err) => console.error(err));
					});
			});
	};
}

export function eSignSignDocument(
	documentId,
	companyId,
	userId,
	bankidCallback,
	callback,
) {
	return function (dispatch) {
		return req
			.post(
				`/documents/public/esign/${documentId}/sign/init?companyId=${companyId}&sub=${userId}`,
				{},
			)
			.then((response) => {
				const { orderRef, qrLink, desktopAppLink } = response.data;

				if (isMobileDevice()) {
					let redirectUrl = encodeURIComponent(
						`${window.location.origin}${window.location.pathname}?orderref=${orderRef}&origin=bankid`,
					);
					if (
						/android/i.test(
							navigator.userAgent || navigator.vendor || window.opera,
						)
					) {
						redirectUrl = "null";
					}

					window.location = desktopAppLink.replace("null", redirectUrl);
				}

				// Set the initial data right away
				bankidCallback({
					orderRef,
					desktopAppLink,
					qrLink,
					bankIdMessage: "auth.bankid.status.OUTSTANDING_TRANSACTION",
					status: "pending",
				});

				if (orderRef !== "bypass") {
					dispatch(
						collect(
							orderRef,
							documentId,
							companyId,
							userId,
							bankidCallback,
							callback,
						),
					);
				} else {
					dispatch(verify(orderRef));
				}
			})
			.catch((e) => {
				callback && callback(e);
				throw e;
			});
	};
}

export function checkIfDocumentIsShared(
	documentId,
	userId,
	companyId,
	type,
	callback,
) {
	return function () {
		req
			.get(
				`/documents/public/documents/${documentId}/check-shared?userId=${userId}&companyId=${companyId}&type=${type}`,
			)
			.then((response) => {
				callback(response.data.shareType);
			})
			.catch((e) => {
				console.log(e);
				callback("failed");
			});
	};
}

export function getSimpleDocument(documentId, userId, companyId, callback) {
	return function (dispatch) {
		return req
			.get(
				`/documents/public/documents/${documentId}/simple?userId=${userId}&companyId=${companyId}`,
			)
			.then((response) => {
				callback && callback();
				dispatch({
					type: DOCUMENT_FETCH,
					payload: fromJS(response.data),
				});
			});
	};
}

export function initSignWithEmail(documentId, userId, companyId, callback) {
	return function () {
		return req
			.post(
				`/documents/public/esign/${documentId}/sign/email/init?sub=${userId}&companyId=${companyId}`,
			)
			.then(() => {
				callback && callback();
			})
			.catch((e) => {
				callback && callback(e);
			});
	};
}

export function completeSignWithEmail(
	code,
	documentId,
	sub,
	companyId,
	callback,
) {
	return function (dispatch) {
		return req
			.post(
				`/documents/public/esign/${documentId}/sign/email/complete?code=${code}&sub=${sub}&companyId=${companyId}`,
			)
			.then((response) => {
				dispatch({
					type: DOCUMENT_ESIGN_SIGN_FETCH,
					payload: fromJS(response.data),
				});
				dispatch({ type: DOCUMENT_FETCH, payload: fromJS(response.data) });
				callback && callback();
			})
			.catch((e) => {
				callback && callback(e);
			});
	};
}

export function listMultipleAttachedDocumentsPublic(
	objIds,
	companyId,
	userId,
	callback,
) {
	return function (dispatch) {
		return req
			.post(
				`/documents/public/documents/attachment/multiple?companyId=${companyId}&sub=${userId}`,
				objIds,
			)
			.then((response) => {
				const attachments = fromJS(response.data.documents);
				const mapObjIdToDocId = fromJS(response.data.mapObjIdToDocId);
				dispatch({
					type: DOCUMENTS_FETCH_ATTACHMENTS,
					payload: { mapObjIdToDocId, attachments },
				});
				callback?.({ mapObjIdToDocId, attachments });
			});
	};
}

export function socketEventDocuments(eventObject) {
	const { eventName, data, metadata, objId: attachmentId } = eventObject;
	const objId = metadata && metadata.objId;
	const objType = metadata && metadata.objType;
	const ignoreInPublic = metadata && metadata.ignoreInPublic;
	if (ignoreInPublic) {
		return () => {};
	}

	return function (dispatch) {
		switch (eventName) {
			case EVENT_TYPE_ATTACHED_DOCUMENT_UPDATE:
				dispatch({
					type: DOCUMENTS_UPDATE_ATTACHMENT,
					payload: { data: fromJS(data), objId },
				});
				break;

			case EVENT_TYPE_ATTACHED_DOCUMENT_CREATE:
			case EVENT_TYPE_ATTACHED_DOCUMENT_LINKED:
				dispatch({
					type: DOCUMENTS_APPEND_ATTACHMENT,
					payload: { data: fromJS(data), objId, objType },
				});
				break;

			case EVENT_TYPE_ATTACHED_DOCUMENT_DELETE:
				dispatch({
					type: DOCUMENTS_REMOVE_ATTACHMENT,
					payload: { attachmentId, objId },
				});
		}
	};
}
