import { fromJS } from "immutable";
import {
	VOTING_LIST,
	VOTING_UPDATE_LOCAL,
	VOTING_CREATE,
	VOTING_CREATE_LOCAL,
	VOTING_FETCH,
	VOTING_SAVE,
	VOTING_DELETE,
	VOTING_SELECT,
	VOTING_REMOTLY_CREATED,
	VOTING_REMONTLY_UPDATED,
	VOTING_REMOTLY_DELETED,
	VOTING_VOTE_USER_UPDATE_LOCAL,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	list: [],
	voting: null,
});

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case VOTING_LIST:
			return state.set("list", payload);

		case VOTING_UPDATE_LOCAL:
			return state.set("voting", payload);

		case VOTING_CREATE_LOCAL: {
			state = state.update("list", (list) => list.push(payload));
			return state.set("voting", payload);
		}

		case VOTING_CREATE: {
			const votingId = payload.get("id");

			state = state.update("list", (list) => {
				return list.map((voting) => {
					if (voting.get("id") === votingId) {
						voting = payload;
					}

					return voting;
				});
			});

			return state.set("voting", payload);
		}

		case VOTING_FETCH:
			return state.set("voting", payload);

		case VOTING_SAVE: {
			const votingId = payload.get("id");

			state = state.update("list", (list) => {
				return list.map((voting) => {
					if (voting.get("id") === votingId) {
						voting = payload;
					}

					return voting;
				});
			});

			return state.set("voting", payload);
		}

		case VOTING_DELETE: {
			const votingId = payload;

			state = state.update("list", (list) =>
				list.filter((v) => v.get("id") !== votingId),
			);
			return state.set("voting", null);
		}

		case VOTING_SELECT: {
			if (!payload) {
				return state.set("voting", null);
			}

			const voting = state.get("list").find((v) => v.get("id") === payload);
			return state.set("voting", voting);
		}

		case VOTING_REMOTLY_CREATED: {
			const inList = state
				.get("list")
				.some((voting) => voting.get("id") === payload.get("id"));

			if (!inList) {
				return state.update("list", (list) => list.push(payload));
			}

			return state;
		}

		case VOTING_REMONTLY_UPDATED: {
			const voting = state.get("voting");

			state = state.update("list", (list) =>
				list.map((v) => (v.get("id") === payload.get("id") ? payload : v)),
			);

			if (voting && voting.get("id") === payload.get("id")) {
				state = state.set("voting", payload);
			}

			return state;
		}

		case VOTING_REMOTLY_DELETED: {
			const voting = state.get("voting");

			state = state.update("list", (list) =>
				list.filter((v) => v.get("id") !== payload),
			);

			if (voting && voting.get("id") === payload) {
				state = state.set("voting", null);
			}

			return state;
		}

		case VOTING_VOTE_USER_UPDATE_LOCAL: {
			const voterId = payload.get("voterId");
			const voteResult = payload.get("result");
			let voting = state.get("voting");
			voting = voting.setIn(["votes", voterId, "result"], voteResult);
			return state.set("voting", voting);
		}

		default:
			return state;
	}
}
