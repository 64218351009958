import { fromJS } from "immutable";
import {
	VOTING_VOTE,
	VOTING_LIST,
	VOTING_UPDATE_LOCAL,
	VOTING_VOTE_USER_UPDATE_LOCAL,
	VOTING_FETCH,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	list: {},
	voting: null,
	// voting: {
	// 	status : 'MEETING_VOTING_STARTED',
	// 	meetingId : 'e75b04d0-87be-11eb-8022-cd0f3b5a8340',
	// 	id : 'ee3857b1-5d47-45b0-9c87-43004deb0b7a',
	// 	createdBy : 'e3939290-aee3-11ea-8b68-47c6f296cfa6',
	// 	index : 0,
	// 	createdAt : '2021-03-18T07:53:51.238+0000',
	// 	updatedAt : '2021-03-18T07:53:51.238+0000',
	// 	topicRef : 'Timer',
	// 	question : 'Hur fungerar timern?',
	// 	timer : 60,
	// 	startedAt: '2021-03-18T08:56:41.305Z'
	// }
});

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case VOTING_VOTE:
			return state.set("voting", payload);

		case VOTING_LIST:
			return state.set("list", payload);

		case VOTING_UPDATE_LOCAL:
			return state.set("voting", payload);

		case VOTING_FETCH:
			return state.set("voting", payload);

		case VOTING_VOTE_USER_UPDATE_LOCAL: {
			const voterId = payload.get("voterId");
			const voteResult = payload.get("result");
			let voting = state.get("voting");
			voting = voting.setIn(["votes", voterId, "result"], voteResult);
			return state.set("voting", voting);
		}

		default:
			return state;
	}
}
