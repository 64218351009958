/**
 * Notify
 * @module components/framework/notify
 */

import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
	removeNotification,
	clearNotifications,
	addErrorNotification as _addErrorNotification,
	addInfoNotification as _addInfoNotification,
} from "../../actions/notify.actions";
import { getStore } from "../../store";

const NOTIFICATION_TYPE_ERROR = "ERROR";

/** Class representing the notify component. */
class Notify extends Component {
	state = {
		mountEl: null,
	};

	/**
	 * Remova an notification based on its index
	 */
	removeNotification(index) {
		this.props.removeNotification(index);
	}

	/**
	 * Clears all notifications
	 */
	clearNotifications() {
		this.props.clearNotifications();
	}

	/**
	 * TODO
	 * Returns an error message based on the errorCode
	 */
	getErrorMessage(message) {
		/*switch (errorCode) {
			case '404'
		}*/
		return message;
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		this.clearNotifications();

		let mountEl = document.createElement("div");
		mountEl.setAttribute("id", "NotifyPortal");
		document.body.appendChild(mountEl);

		this.setState({ mountEl });
	}

	componentDidUpdate() {
		this.props.notifications.map((notification, index) => {
			const notificationType = notification.type;

			if (notificationType === NOTIFICATION_TYPE_ERROR) {
				this.setTimeoutTimer(index, 30000);
				return;
			}
			this.setTimeoutTimer(index, 5000);
		});
	}

	setTimeoutTimer(index, milliSeconds) {
		setTimeout(
			() => {
				this.removeNotification(index);
			},
			milliSeconds + index * 100,
		);
	}

	/**
	 * Renders a single notification
	 */
	renderNotification(item, index) {
		const wrapperClassName =
			item.type === "ERROR"
				? "alert alert-danger animated jellyIn"
				: "alert alert-dark animated jellyIn";
		const title = `notifications.title.${item.type}`;

		return (
			<div className={wrapperClassName} role="alert" key={index}>
				<button
					className="close"
					type="button"
					onClick={this.removeNotification.bind(this, index)}
				>
					<i className="pci-cross pci-circle"></i>
				</button>
				<div className="media">
					<div className="media-body">
						<h4 className="alert-title">
							<FormattedMessage id={title} />
						</h4>
						{item.tid !== undefined ? (
							<p className="alert-message">
								<FormattedMessage id={item.tid} />
							</p>
						) : (
							<p className="alert-message">
								{this.getErrorMessage(item.text, item.title)}
							</p>
						)}
					</div>
				</div>
			</div>
		);
	}

	/**
	 * The render function
	 */
	render() {
		const { notifications } = this.props;
		const { mountEl } = this.state;

		if (!mountEl) {
			return null;
		}

		return ReactDOM.createPortal(
			<div id="floating-top-right" className="floating-container">
				<div className="alert-wrap animated">
					{notifications.map(this.renderNotification.bind(this))}
				</div>
			</div>,
			mountEl,
		);
	}
}

/** Map state to props. */
function mapStateToProps(state) {
	return { notifications: state.notify.notifications };
}

export const addInfoNotification = (...args) => {
	const store = getStore();
	store.dispatch(_addInfoNotification(...args));
};

export const addErrorNotification = (...args) => {
	const store = getStore();
	store.dispatch(_addErrorNotification(...args));
};

/** Export */
export default connect(mapStateToProps, {
	removeNotification,
	clearNotifications,
})(Notify);
