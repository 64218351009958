import React from "react";
import { useTranslation } from "react-i18next";
import AgGrid from "../../dumb-components/shared/ag-grid/ag-grid";
import { GRID_MODE_PANEL, GRID_MODE_MODAL } from "/shared/constants";
import { useDispatch, useSelector } from "react-redux";
import {
	changeEmissionOptin,
	removeCompanyFromDealflow,
} from "../../actions/dealflow.actions";
import history from "../../interfaces/history";
import { makeInternalLink } from "../../components/helpers/link.helper";
import slug from "slug";
import { DropDown } from "../../mui-components/dropdown/DropDown";
import IconButton from "../../mui-components/button/icon-button";
import MenuItemIcon from "../../mui-components/dropdown/menu/menu-item-icon";
import { TickCellRenderer } from "../../dumb-components/shared/ag-grid/renderers/tick.cell-renderer";
import { TextOnlyTooltip } from "../../dumb-components/shared/ag-grid/tooltips/text-only-tooltip";
import { formatOrgPretty } from "/shared/helpers/helpers";

export default function CompaniesFollowedGrid({
	isGridModalOpen,
	localCloseModal,
	modalLeftHeader,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const rowData = useSelector((state) => state.dealflow.companies);

	const shared = {
		all: {
			sortable: false,
			suppressMenu: true,
		},
	};

	const goToDeatilsView = (investmentId, name) => {
		const sluggedName = name ? slug(name) : "";
		history.push(
			makeInternalLink(`${sluggedName}/general`, {
				personalInvestmentId: investmentId,
			}),
		);
	};

	const unfollowCompany = (dealflowId) => {
		dispatch(removeCompanyFromDealflow(dealflowId));
	};

	return (
		<AgGrid
			rowData={rowData}
			isGridModalOpen={isGridModalOpen}
			localCloseModal={localCloseModal}
			modalLeftHeader={modalLeftHeader}
			mode={isGridModalOpen ? GRID_MODE_MODAL : GRID_MODE_PANEL}
			suppressContextMenu={true}
			stopEditingWhenCellsLoseFocus={true}
			columnDefs={[
				{
					field: "isRegisteredInvonoCompany",
					headerName: "",
					cellRenderer: TickCellRenderer,
					tooltipComponent: TextOnlyTooltip,
					tooltipComponentParams: {
						tooltipTid:
							"dealflow.companies_followed_grid.registered_company_tooltip",
						shouldShow: (props) => props.value === true,
					},
					tooltipField: "isRegisteredInvonoCompany",
					maxWidth: 47,
					...shared.all,
				},
				{
					field: "investedInCompanyInformation.name",
					headerName: t("dealflow.companies_followed_grid.company_name"),
					...shared.all,
				},
				{
					field: "orgNumber",
					headerName: t("dealflow.companies_followed_grid.org_number"),
					valueFormatter: (params) => formatOrgPretty(params.value),
					...shared.all,
				},
				{
					field: "emission.subscriptionStartDate",
					headerName: t("dealflow.companies_followed_grid.planned_emission"),
					...shared.all,
				},
				{
					field: "emission.newShareCountMax",
					headerName: t("dealflow.companies_followed_grid.emission_value"),
					...shared.all,
				},
				{
					field: "emissionsOptIn",
					headerName: t("dealflow.companies_followed_grid.take_part_of_offer"),
					editable: true,
					cellEditorPopup: true,
					singleClickEdit: true,
					cellEditor: "agRichSelectCellEditor",
					cellEditorParams: {
						values: [true, false],
						formatValue: (value) => {
							return value ? t("generic.yes") : t("generic.no");
						},
						cellHeight: 48,
					},
					valueFormatter: (params) =>
						params.value ? t("generic.yes") : t("generic.no"),
					onCellValueChanged: ({ data: { orgNumber }, newValue }) => {
						dispatch(changeEmissionOptin(orgNumber, newValue));
					},
					...shared.all,
				},
				{
					field: "button",
					headerName: "",
					cellRenderer: (params) => {
						return (
							<DropDown
								alignMenu="right"
								button={({ params }) => (
									<IconButton noBorder icon="faEllipsisV" {...params} />
								)}
							>
								<MenuItemIcon
									icon="faExpandArrowsAlt"
									onClick={() =>
										goToDeatilsView(
											params.data.id,
											params.data.investedInCompanyInformation.name,
										)
									}
									listItemTid="dealflow.companies_followed_grid.field.buttons.open"
								/>
								<MenuItemIcon
									icon="faTimes"
									onClick={() => unfollowCompany(params.data.id)}
									listItemTid="dealflow.companies_followed_grid.field.buttons.unfollow_company"
								/>
							</DropDown>
						);
					},
					type: "rightAligned",
					...shared.all,
					maxWidth: 84,
					cellStyle: { textOverflow: "unset" },
				},
			]}
		/>
	);
}
