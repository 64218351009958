import { Component } from "react";
import { connect } from "react-redux";
import filterAndSort from "../../../components/helpers/filter-and-sort.helper";
import { setFilteredMeetings } from "../../../actions/meetings.actions";

class MeetingsFilterAndSortContainer extends Component {
	applyFiltersAndSort = () => {
		const {
			allMeetings,
			setFilteredMeetings,
			meetingsExcludedFromFiltering,
			filters,
		} = this.props;
		let visibleMeetings = allMeetings;
		const hasAppliedFilters = this.hasAppliedFilters(filters);
		if (visibleMeetings) {
			const filtering = new filterAndSort(
				visibleMeetings,
				meetingsExcludedFromFiltering,
				filters,
			);
			visibleMeetings = filtering.filter();
			visibleMeetings = filtering.sort();
			setFilteredMeetings(visibleMeetings, hasAppliedFilters);
			this.props.filtersChanged(visibleMeetings.size, allMeetings.size);
		}
	};

	hasAppliedFilters = (filters) => {
		const { defaultFilters } = this.props;
		let hasAppliedFilters = false;

		filters.forEach((filter) => {
			const source = filter.get("source");
			const values = filter.get("values");
			const val = values && values.get(0);

			// Covers basic filter
			if (
				(source === "meeting" || source === "tag" || source === "group") &&
				values.size !== 0
			) {
				hasAppliedFilters = true;
				return;
			}

			// Check default filters
			if (
				source !== "meeting" &&
				source !== "tag" &&
				source !== "group" &&
				((defaultFilters.has(source) &&
					defaultFilters.getIn([source, 0]) !== val) ||
					!defaultFilters.has(source))
			) {
				hasAppliedFilters = true;
				return;
			}
		});

		return hasAppliedFilters;
	};

	componentDidUpdate = (prevProps) => {
		const { allMeetings, filters, defaultFilters } = this.props;
		if (prevProps.allMeetings !== allMeetings) {
			this.applyFiltersAndSort();
		}
		if (prevProps.filters !== filters) {
			this.applyFiltersAndSort();
		}
		if (prevProps.defaultFilters !== defaultFilters) {
			this.applyFiltersAndSort();
		}
	};

	render = () => {
		return this.props.children;
	};
}

const mapStoreToProps = (store) => {
	return {
		allMeetings: store.meetings.get("allMeetings"),
		filters: store.meetings.get("filterBy"),
		defaultFilters: store.meetings.get("defaultFilters"),
		meetingsExcludedFromFiltering: store.meetings.get(
			"meetingsExcludedFromFiltering",
		),
	};
};

const mapActionsToProps = {
	setFilteredMeetings,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingsFilterAndSortContainer);
