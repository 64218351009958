import React from "react";
import MUIDialog from "../../../mui-components/dialog/mui-dialog";
import { closeModal } from "../../../actions/modals.actions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import styled from "@mui/styled-engine";
import { func, string } from "prop-types";

const StyledYellowBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.highlight.yellow.main,
	padding: theme.spacing(4),
	borderRadius: "8px",
}));

const TemplateInformationModal = ({
	primaryTid,
	onConfirm,
	secondaryFooterBtn,
}) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const _onConfirm = () => {
		onConfirm && onConfirm();
		dispatch(closeModal());
	};

	const onClose = () => {
		dispatch(closeModal());
	};

	return (
		<MUIDialog
			isOpen={true}
			onConfirm={_onConfirm}
			title={t("meetings.smart.template_information_modal.title")}
			confirmButtonTid="meetings.smart.template_information_modal.footer_btn.confirm"
			closeButton={secondaryFooterBtn ? true : false}
			closeButtonTid={"generic.close"}
			onClose={onClose}
			height={"60%"}
		>
			<StyledYellowBox>{t(primaryTid)}</StyledYellowBox>
		</MUIDialog>
	);
};

TemplateInformationModal.propTypes = {
	primaryTid: string,
	onConfirm: func,
};

TemplateInformationModal.defaultProps = {};

export default TemplateInformationModal;
