import { List } from "immutable";
import { node } from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { COPY_CONTACT_TO_COMPANY_MODAL } from "../../../constants/modals";
import { CopyContactModalContext } from "./copy-contact-modal.context";

function CopyContactModalProvider({ children }) {
	const modalIsOpen = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) ===
			COPY_CONTACT_TO_COMPANY_MODAL,
	);

	const [selectedCompanies, setSelectedCompanies] = useState(List());

	if (!modalIsOpen) {
		return null;
	}

	const value = {
		selectedCompanies,
		setSelectedCompanies,
	};

	return (
		<CopyContactModalContext.Provider value={value}>
			{children}
		</CopyContactModalContext.Provider>
	);
}

CopyContactModalProvider.propTypes = {
	children: node,
};

export { CopyContactModalProvider };
