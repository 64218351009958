import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import { fetchMeeting } from "../../../actions/meetings.actions";
import { fetchMeetingTemplate } from "../../../actions/meeting-templates.actions";
import { fetchSimpleUsers } from "../../../actions/usersCache.actions";
import { showAppMenus, hideAppMenus } from "../../../actions/notify.actions";
import Text from "../../../dumb-components/shared/text/text";
import ProtocolView from "../../../dumb-components/meetings/protocol-view/protocol-view";
import ProtocolViewTabs from "./protocol-view-tabs.container";
import ProtocolViewToolbarContainer from "./protocol-view-toolbar.container";
import {
	MEETING_TYPE_STANDARD,
	EVENT_TYPE_MEETINGS_UPDATE,
} from "/shared/constants";
import { getQuery } from "../../../interfaces/history";

import ProtocolViewAgendaContainer from "./agenda-view.container";
import ProtocolViewDocumentsContainer from "./documents-view.container";
import ProtocolViewTasksContainer from "./tasks-view.container";
import ProtocolViewNotesContainer from "./notes-view.container";
import { Switch, Route, Redirect } from "../../../interfaces/router";

import { LIVE_MEETINGS_AGENDA_UPDATE } from "../../../constants/live-update";

class ProtocolViewContainer extends Component {
	componentDidMount = () => {
		const {
			hideAppMenus,
			fetchMeeting,
			match: { params },
		} = this.props;
		const query = getQuery();

		hideAppMenus();
		fetchMeeting(params.id, query.nextMeeting);

		// Hide zendesk support chat
		setTimeout(() => {
			const zendeskSupport = document.getElementById("launcher");
			if (zendeskSupport) {
				zendeskSupport.style.display = "none";
			}
		}, 5000);
	};

	componentDidUpdate = (prevProps) => {
		const {
			meeting,
			meetingTemplate,
			fetchMeetingTemplate,
			location,
			history,
		} = this.props;

		if (prevProps.meeting !== meeting) {
			if (!meetingTemplate) {
				fetchMeetingTemplate(meeting.get("templateId"));
			}

			if (meeting) {
				this.loadUsers();

				const isStandardMeeting =
					meeting.get("meetingType") === MEETING_TYPE_STANDARD;
				if (isStandardMeeting) {
					history.push(location.pathname.replace("/protocols", "/meetings"));
				}
			}
		}

		this.checkLiveUpdateEvents();
	};

	checkLiveUpdateEvents = () => {
		const {
			audit,
			match: { params },
			fetchMeeting,
		} = this.props;
		const meetingUpdate = audit.get(EVENT_TYPE_MEETINGS_UPDATE, Map());
		const agendaItemUpdate = audit.get(LIVE_MEETINGS_AGENDA_UPDATE, Map());
		const query = getQuery();

		if (
			meetingUpdate.get("refresh") === true ||
			agendaItemUpdate.get("refresh") === true
		) {
			fetchMeeting(params.id, query.nextMeeting);
		}
	};

	componentWillUnmount = () => {
		this.props.showAppMenus();
	};

	loadUsers = () => {
		const { meeting, fetchSimpleUsers } = this.props;

		if (!meeting) {
			return;
		}

		fetchSimpleUsers(meeting.get("attendees", Map()).keySeq().toList());
	};

	goToApp = () => {
		const { location, history } = this.props;
		const urlAlias = location.pathname.split("/")[1];

		history.push(`/${urlAlias}`);
	};

	renderHeader = () => {
		const { company } = this.props;

		return <Text tag="h1">{company.name}</Text>;
	};

	renderTabs = () => {
		const { location } = this.props;
		const match = location.pathname.split("/")[4];

		return <ProtocolViewTabs location={location} match={match} />;
	};

	renderToolbar = () => {
		const { location } = this.props;

		return <ProtocolViewToolbarContainer location={location} />;
	};

	render = () => {
		const {
			meeting,
			match,
			protocolPublished,
			isFeedbackee,
			isSignee,
			hasExtendedRights,
		} = this.props;
		const allowedToViewProtocol =
			meeting &&
			(protocolPublished || isFeedbackee || isSignee || hasExtendedRights);

		if (!meeting) {
			return null;
		}

		return (
			<ProtocolView
				header={this.renderHeader}
				tabs={this.renderTabs}
				toolbar={this.renderToolbar}
				allowedToViewProtocol={allowedToViewProtocol}
			>
				<Switch>
					<Redirect
						exact
						from={`${match.path}/`}
						to={`${match.path}/protocol`}
					/>
					<Route
						exact
						path={`${match.path}/protocol`}
						component={ProtocolViewAgendaContainer}
					/>
					<Route
						exact
						path={`${match.path}/documents`}
						component={ProtocolViewDocumentsContainer}
					/>
					<Route
						exact
						path={`${match.path}/tasks`}
						component={ProtocolViewTasksContainer}
					/>
					<Route
						exact
						path={`${match.path}/notes`}
						component={ProtocolViewNotesContainer}
					/>
				</Switch>
			</ProtocolView>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get("meeting"),
		meetingTemplate: store.meetingTemplates.get("meetingTemplate"),
		isFeedbackee: store.meetings.getIn([
			"meeting",
			"computedValues",
			"isFeedbackee",
		]),
		isSignee: store.meetings.getIn(["meeting", "computedValues", "isSignee"]),
		hasExtendedRights: store.meetings.getIn([
			"meeting",
			"computedValues",
			"hasExtendedRights",
		]),
		protocolPublished: store.meetings.getIn([
			"meeting",
			"computedValues",
			"protocolPublished",
		]),
		usersCache: store.usersCache.get("usersCache"),
		userObj: store.user.get("userObj"),
		company: store.company.company,
		audit: store.audit.get("meetings"),
	};
};

const mapActionsToProps = {
	hideAppMenus,
	fetchMeeting,
	fetchMeetingTemplate,
	fetchSimpleUsers,
	showAppMenus,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ProtocolViewContainer);
