import React from "react";
import Panel from "../../../../dumb-components/panel";
import Field from "../../../../dumb-components/fields/field";
import Input from "../../../../dumb-components/fields/input";
import CountrySelect from "../../../../dumb-components/shared/country-select/country-select";

export default function ContactDetailsField({ user, onChange, language }) {
	const index = 0;

	if (!user) {
		return null;
	}

	return (
		<Panel tid="generic.contact.details">
			<Field tid="generic.address.line.1">
				<Input
					name="addr_1"
					value={user.getIn(["addresses", index, "addr_1"], "")}
					onChange={(val) => {
						onChange(["addresses", index, "addr_1"], val);
					}}
				/>
			</Field>
			<Field tid="generic.city">
				<Input
					name="city"
					value={user.getIn(["addresses", index, "city"], "")}
					onChange={(val) => {
						onChange(["addresses", index, "city"], val);
					}}
				/>
			</Field>
			<Field tid="generic.post_code">
				<Input
					name="postCode"
					value={user.getIn(["addresses", index, "postCode"], "")}
					onChange={(val) => {
						onChange(["addresses", index, "postCode"], val);
					}}
				/>
			</Field>
			<Field tid="country">
				<CountrySelect
					language={language}
					isClearable={true}
					value={user.getIn(["addresses", index, "country"])}
					onChange={(val) => {
						onChange(["addresses", index, "country"], val);
					}}
				/>
			</Field>
		</Panel>
	);
}
