import React, { Component } from "react";
import { connect } from "react-redux";
import { string, object, func, array } from "prop-types";
import { List } from "immutable";
import Text from "../../../dumb-components/shared/text/text";
import Box from "../../../dumb-components/shared/layout/box/box";
import Tabs from "../../../dumb-components/shared/tabs/tabs";
import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import DropdownMenuItem from "../../../dumb-components/shared/dropdown-item/dropdown-item";
import { MEETING_STATUS_PROTOCOL_NEEDS_SIGNING } from "/shared/constants";
import history from "../../../interfaces/history";

class ProtocolTabsContainer extends Component {
	static propTypes = {
		location: object,
		match: string,
		onSetRef: func,
		hiddenTabs: array,
	};

	onDropdownItemClick = (id) => {
		const {
			location: { search },
			history,
		} = this.props;
		const basePath = this.getParentBasePath();

		history.push({
			pathname: `${basePath}/${id}`,
			search,
		});
	};

	getParentBasePath = () => {
		const {
			location: { pathname },
		} = this.props;
		const path = pathname;
		const pathArray = path.split("protocol/");
		return pathArray[0] + "protocol";
	};

	calculateAgendaItemsCount = () => {
		const { meeting } = this.props;

		if (!meeting) {
			return 0;
		}

		let items = 0;
		meeting.get("agendaItems").forEach((item) => {
			if (item.get("agendaItems") && item.get("agendaItems").size > 0) {
				items += item.get("agendaItems").size;
			}
			items++;
		});

		return items;
	};

	calculateFeedbackeeCount = () => {
		const { meeting } = this.props;
		return meeting
			? meeting
					.get("feedbackees", List())
					.filter((obj) => obj.get("active") === true).size
			: 0;
	};

	anyTopicsDone = () => {
		const { meeting } = this.props;

		if (!meeting) {
			return;
		}

		let anyTopicsDone = false;
		meeting &&
			meeting.get("agendaItems", List()).forEach((item) => {
				if (item.get("progress") === "done") {
					anyTopicsDone = true;
				}

				item.get("agendaItems", List()).forEach((subitem) => {
					if (subitem.get("progress") === "done") {
						anyTopicsDone = true;
					}
				});
			});

		return anyTopicsDone;
	};

	anyTopicsHasUnreadFeedback = () => {
		const { meeting } = this.props;

		if (!meeting) {
			return;
		}

		let anyTopicsHasUnreadFeedback = false;
		meeting &&
			meeting.get("agendaItems", List()).forEach((item) => {
				if (item.get("hasUnreadFeedback")) {
					anyTopicsHasUnreadFeedback = true;
				}

				item.get("agendaItems", List()).forEach((subitem) => {
					if (subitem.get("hasUnreadFeedback")) {
						anyTopicsHasUnreadFeedback = true;
					}
				});
			});

		return anyTopicsHasUnreadFeedback;
	};

	getTabs = () => {
		const { meeting } = this.props;

		const feedbackeeCount = this.calculateFeedbackeeCount();

		const topicsDone =
			meeting && meeting.getIn(["computedValues", "topicsTick"]) === true;
		const feedbackeesDone =
			meeting && meeting.getIn(["computedValues", "feedbackeesTick"]) === true;
		const signeesDone =
			meeting && meeting.getIn(["computedValues", "signeesTick"]) === true;
		const eSigningInitiated =
			meeting &&
			meeting.getIn(["computedValues", "eSigningInitiated"]) === true;
		const anyTopicsHasUnreadFeedback = this.anyTopicsHasUnreadFeedback();
		const protocolPublished =
			meeting && meeting.getIn(["computedValues", "protocolPublished"]);
		let disabledTooltip =
			eSigningInitiated &&
			"meeting.protocol_edit.tabs.tooltip.disabled_due_to_signing";

		if (protocolPublished) {
			disabledTooltip =
				"meeting.protocol_edit.tabs.tooltip.disabled_protocol_published";
		}

		let topicsHighlightColor = "solitudeDark";
		if (feedbackeeCount > 0 && anyTopicsHasUnreadFeedback && !topicsDone) {
			topicsHighlightColor = "orange";
		} else if (topicsDone) {
			topicsHighlightColor = "green";
		}

		let feedbackHighlightColor = "solitudeDark";
		if (feedbackeeCount > 0 && !feedbackeesDone) {
			feedbackHighlightColor = "orange";
		} else if (feedbackeeCount > 0 && feedbackeesDone) {
			feedbackHighlightColor = "green";
		}

		let signatoriesHighlightColor = "solitudeDark";
		if (
			meeting &&
			meeting.get("status") === MEETING_STATUS_PROTOCOL_NEEDS_SIGNING
		) {
			signatoriesHighlightColor = "orange";
		} else if (signeesDone) {
			signatoriesHighlightColor = "green";
		}

		return [
			{
				path: "info",
				highlightColor: "solitudeDark",
				tid: "meetings.agenda.tabs.info",
				disabled: eSigningInitiated,
				tooltip: disabledTooltip,
				tabContentRenderer() {
					return (
						<Box alignItems="center" valign="center">
							<Text tid="meetings.agenda.tabs.info" />
						</Box>
					);
				},
			},
			{
				path: "attendees",
				highlightColor: "solitudeDark",
				tid: "meetings.agenda.tab.attendees",
				disabled: eSigningInitiated,
				tooltip: disabledTooltip,
				tabContentRenderer() {
					return (
						<Box alignItems="center" valign="center">
							<Text tid="meetings.agenda.tab.attendees" singleLine />
						</Box>
					);
				},
			},
			{
				path: "review",
				highlightColor: topicsHighlightColor,
				tid: "meetings.protocol.tabs.review",
				disabled: eSigningInitiated,
				tooltip: disabledTooltip,
				tabContentRenderer() {
					return (
						<Box alignItems="center" valign="center">
							<Text tid="meetings.protocol.tabs.review" nowrap />
						</Box>
					);
				},
			},
			{
				path: "feedback",
				highlightColor: feedbackHighlightColor,
				tid: "meetings.protocol.tab.feedback",
				disabled: eSigningInitiated,
				tooltip: disabledTooltip,
				tabContentRenderer() {
					return (
						<Box alignItems="space-around" valign="center">
							<Text tid="meetings.protocol.tab.feedback" />
						</Box>
					);
				},
			},
			{
				path: "sign-off",
				highlightColor: signatoriesHighlightColor,
				tid: "meetings.agenda.tab.publish",
				tabContentRenderer() {
					return (
						<Box alignItems="space-around" valign="center">
							<Text tid="meetings.agenda.tab.publish" />
						</Box>
					);
				},
			},
			{
				path: "documents",
				highlightColor: "solitudeDark",
				disabled: eSigningInitiated,
				tid: "meetings.agenda.tab.files",
				tooltip: disabledTooltip,
				tabContentRenderer() {
					return (
						<Box alignItems="space-around" valign="center">
							<Text tid="meetings.agenda.tab.files" />
						</Box>
					);
				},
			},
			{
				path: "tasks",
				highlightColor: "solitudeDark",
				disabled: eSigningInitiated,
				tid: "meetings.agenda.tab.tasks",
				tooltip: disabledTooltip,
				tabContentRenderer() {
					return (
						<Box alignItems="space-around" valign="center">
							<Text tid="meetings.agenda.tab.tasks" />
						</Box>
					);
				},
			},
		];
	};

	renderDropdownMenuItem = (id, index) => {
		const tabs = this.getTabs();
		const tab = tabs.find((obj) => obj.path === id);

		return (
			<DropdownMenuItem
				onClick={() => this.onDropdownItemClick(id)}
				disabled={tab.disabled}
				key={index}
			>
				<Text
					color={
						tab.highlightColor !== "solitudeDark"
							? tab.highlightColor
							: undefined
					}
					tid={tab.tid}
				/>
			</DropdownMenuItem>
		);
	};

	renderDropdownMenu = () => {
		const { hiddenTabs } = this.props;

		if (!hiddenTabs || hiddenTabs.length === 0) {
			return null;
		}

		return (
			<DropdownMenuContainer
				btnIcon="faEllipsisV"
				halignMenu="right"
				btnMode="transparent-icon"
				transparentIconButtonSize="sml"
			>
				{hiddenTabs && hiddenTabs.map(this.renderDropdownMenuItem)}
			</DropdownMenuContainer>
		);
	};

	render = () => {
		const { match, onSetRef } = this.props;
		const basePath = this.getParentBasePath();
		const tabs = this.getTabs();

		return (
			<React.Fragment>
				<Tabs
					invertedColors={true}
					basePath={basePath}
					match={match}
					tabs={tabs}
					onSetRef={onSetRef}
					flex={false}
				/>
				{this.renderDropdownMenu()}
			</React.Fragment>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meeting: store.meetings.get("meeting"),
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ProtocolTabsContainer);
