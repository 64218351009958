import React, { PureComponent } from "react";
import { string, oneOf, number } from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	background-color: ${(props) =>
		props.theme.colors.contentBlocks[props.bgColor]};
	border-radius: ${(props) => props.theme.spacing[3]};
	padding-top: ${(props) => props.theme.spacing[props.paddingTop]};
	padding-right: ${(props) => props.theme.spacing[props.paddingRight]};
	padding-bottom: ${(props) => props.theme.spacing[props.paddingBottom]};
	padding-left: ${(props) => props.theme.spacing[props.paddingLeft]};
	margin-top: ${(props) => props.theme.spacing[props.marginTop]};
	margin-right: ${(props) => props.theme.spacing[props.marginRight]};
	margin-bottom: ${(props) => props.theme.spacing[props.marginBottom]};
	margin-left: ${(props) => props.theme.spacing[props.marginLeft]};
	flex-direction: ${(props) => props.flexDirection};
`;

export default class ColoredContentWrapper extends PureComponent {
	static propTypes = {
		type: oneOf(["primary", "secondary", "disabledLightGrey", "solitudeMid"]),
		className: string,
		paddingTop: number,
		paddingRight: number,
		paddingBottom: number,
		paddingLeft: number,
		marginTop: number,
		marginRight: number,
		marginBottom: number,
		marginLeft: number,
		flexDirection: oneOf(["row", "column"]),
	};

	static defaultProps = {
		type: "primary",
		paddingTop: 4,
		paddingRight: 4,
		paddingBottom: 4,
		paddingLeft: 4,
		marginTop: 0,
		marginRight: 0,
		marginBottom: 0,
		marginLeft: 0,
		flexDirection: "row",
	};

	getWrapperProps = () => {
		return {
			bgColor: this.props.type,
			paddingTop: this.props.paddingTop,
			paddingRight: this.props.paddingRight,
			paddingBottom: this.props.paddingBottom,
			paddingLeft: this.props.paddingLeft,
			marginTop: this.props.marginTop,
			marginRight: this.props.marginRight,
			marginBottom: this.props.marginBottom,
			marginLeft: this.props.marginLeft,
			className: this.props.className,
			flexDirection: this.props.flexDirection,
		};
	};

	render = () => {
		const { children } = this.props;

		return <Wrapper {...this.getWrapperProps()}>{children}</Wrapper>;
	};
}
