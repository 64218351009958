import React, { PureComponent } from "react";
import { func, string } from "prop-types";
import userImage from "../../../../assets/images/onboarding/member-notification.png";

import LeftRightLayout, {
	Header,
	Entry,
} from "../sub-modules/left-right-layout";
import Button from "../../../shared/button/button";

export default class WelcomeUser extends PureComponent {
	static propTypes = {
		companyName: string,
		onGoToCompany: func,
	};

	renderAddToCompanyButton = () => {
		const { onGoToCompany } = this.props;

		return (
			<Button
				mode="primary"
				onClick={onGoToCompany}
				tid="onboarding.modal.new_member.1.btn.go_to_company"
			/>
		);
	};

	render = () => {
		const { companyName } = this.props;

		return (
			<LeftRightLayout image={userImage}>
				<Header tid="onboarding.modal.shared.welcome_to_invono" />

				<Entry
					titleTid="onboarding.modal.new_member.subtitle.1"
					titleValues={{ companyName }}
					subTextTid="onboarding.modal.new_member.text.1"
					bottomComponent={this.renderAddToCompanyButton()}
				/>

				<Entry
					titleTid="onboarding.modal.new_member.subtitle.2"
					subTextTid="onboarding.modal.new_member.text.2"
				/>

				<Entry
					titleTid="onboarding.modal.shared.knowledge_base.subtitle"
					subTextTid="onboarding.modal.shared.knowledge_base.text"
				/>
			</LeftRightLayout>
		);
	};
}
