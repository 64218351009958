import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import { fetchMeeting } from "../../../actions/meetings.actions";
import SplitView from "../../../dumb-components/shared/layout/split-view/split-view";
import ScrollView from "../../../dumb-components/shared/layout/scroll-view/scroll-view";
import Box from "../../../dumb-components/shared/layout/box/box";
import MeetingTabsContainer from "../meeting/meeting-tabs.container";
import AttendeesAlert from "./alert.container";
import AlertSecretaryContainer from "./alert-secretary.container";
import MeetingToolbarContainer from "../meeting/smart-meeting/meeting-toolbar.container";
import AttendeesPanelContainer from "../meeting/general/attendees-panel.container";

import AttendeesDetailsContainer from "./attendees-details.container";

import {
	calculateMaxWidthOfSplitView,
	toggleVisibilityOfTabs,
} from "../../../components/helpers/tabs.helper";
import { generateScrollableTarget } from "../../../dumb-components/shared/infinite-scroll";

import {
	EVENT_TYPE_COMPANY_MEMBER_ADDED,
	EVENT_TYPE_COMPANY_MEMBER_UPDATE,
	EVENT_TYPE_COMPANY_MEMBER_REMOVE,
} from "/shared/constants";

class MeetingAttendeeContainer extends Component {
	// References to the scrollviews
	scrollViewRefs = {};

	// Reference to the tabs
	tabsRef;

	// Timer
	resizeEnd;

	state = {
		hiddenTabs: [],
	};

	constructor(props) {
		super(props);

		const { scrollableTarget, renderScrollableElement } =
			generateScrollableTarget();
		this.scrollableTarget = scrollableTarget;
		this.renderScrollableElement = renderScrollableElement;
	}

	componentDidMount = () => {
		window.addEventListener("resize", this.onWindowResize, false);
		this.onWindowResize();
	};

	componentWillUnmount = () => {
		window.removeEventListener("resize", this.onWindowResize, false);
	};

	componentDidUpdate = () => {
		this.checkLiveUpdateEvents();
	};

	checkLiveUpdateEvents = () => {
		const {
			companyAudit,
			fetchMeeting,
			match: {
				params: { id },
			},
		} = this.props;
		const companyMemberAdded = companyAudit.get(
			EVENT_TYPE_COMPANY_MEMBER_ADDED,
			Map(),
		);
		const companyMemberUpdate = companyAudit.get(
			EVENT_TYPE_COMPANY_MEMBER_UPDATE,
			Map(),
		);
		const companyMemberRemove = companyAudit.get(
			EVENT_TYPE_COMPANY_MEMBER_REMOVE,
			Map(),
		);

		if (
			companyMemberAdded.get("refresh") === true ||
			companyMemberUpdate.get("refresh") === true ||
			companyMemberRemove.get("refresh") === true
		) {
			fetchMeeting(id);
		}
	};

	onWindowResize = () => {
		let { hiddenTabs } = this.state;
		const maxWidth = calculateMaxWidthOfSplitView(this.scrollViewRefs);
		hiddenTabs = toggleVisibilityOfTabs(this.tabsRef, hiddenTabs, { maxWidth });

		this.setState({ hiddenTabs });
	};

	getComponentBasePath = () => {
		const {
			location: { pathname },
		} = this.props;
		const path = pathname;
		const pathArray = path.split("attendees");
		return pathArray[0] + "attendees";
	};

	renderLeftHeader = () => {
		const { location, meeting, rollcallDone } = this.props;
		const { hiddenTabs } = this.state;
		const isSimpleMode =
			meeting && meeting.getIn(["computedValues", "isSimpleMode"]);
		const meetingIsLoaded = meeting && meeting.get("id") ? true : false;
		const secretary = meeting && meeting.get("secretary");
		const numOfAttendees = meeting ? meeting.get("attendees", Map()).size : 0;

		return (
			<Box direction="column">
				<Box direction="row">
					<MeetingTabsContainer
						location={location}
						match="attendees"
						onSetRef={(ref) => (this.tabsRef = ref)}
						hiddenTabs={hiddenTabs}
					/>
				</Box>

				{!isSimpleMode && !rollcallDone && (
					<AlertSecretaryContainer
						meetingIsLoaded={meetingIsLoaded}
						secretary={secretary}
						numOfAttendees={numOfAttendees}
					/>
				)}

				{!isSimpleMode && !rollcallDone && <AttendeesAlert />}
			</Box>
		);
	};

	renderLeftSplitView = () => {
		const {
			meeting,
			location,
			match: { params },
		} = this.props;
		const basePath = this.getComponentBasePath();
		const readOnly = meeting
			? meeting.getIn(["computedValues", "isSimpleMode"])
			: false;

		return (
			<ScrollView
				header={this.renderLeftHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				onSetRef={(ref) => (this.scrollViewRefs["left"] = ref)}
				renderView={this.renderScrollableElement}
			>
				<AttendeesPanelContainer
					basePath={basePath}
					querystr={location.search}
					params={params}
					attendees={meeting && meeting.get("attendees")}
					readOnly={readOnly}
					permissions={meeting && meeting.get("permissions")}
					chairman={meeting && meeting.get("chairman")}
					secretary={meeting && meeting.get("secretary")}
					attendeesWarning={
						meeting && meeting.getIn(["computedValues", "attendeesWarning"])
					}
					innerMarginTop={0}
					innerMarginLeft={0}
					innerMarginRight={0}
					showMoreAction
					noPanel
					scrollableTarget={this.scrollableTarget}
				/>
			</ScrollView>
		);
	};

	renderRightHeader = () => {
		const {
			match: { params },
			location,
		} = this.props;
		const basePath = this.getComponentBasePath();

		return (
			<MeetingToolbarContainer
				location={location}
				params={params}
				basePath={basePath.split("/attendees")[0]}
			/>
		);
	};

	renderRightSplitView = () => {
		const { match } = this.props;

		return (
			<ScrollView
				header={this.renderRightHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				onSetRef={(ref) => (this.scrollViewRefs["right"] = ref)}
			>
				{match.params.attendeeId && (
					<AttendeesDetailsContainer
						match={match}
						location={this.props.location}
						history={this.props.history}
					/>
				)}
			</ScrollView>
		);
	};

	render = () => {
		return (
			<SplitView
				leftComponent={this.renderLeftSplitView}
				rightComponent={this.renderRightSplitView}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get("meeting"),
		groups: store.groups.get("groups"),
		companyAudit: store.audit.get("company"),
		rollcallDone: store.meetings.getIn(["meeting", "metadata", "rollcallDone"]),
	};
};

const mapActionsToProps = {
	fetchMeeting,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingAttendeeContainer);
