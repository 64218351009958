import React, { useState } from "react";
import { fromJS, Map } from "immutable";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import Button from "../../../../mui-components/button/button";
import { validateEmail } from "../../../../modules/validation.module";
import {
	addErrorNotification,
	clearNotifications,
} from "../../../../actions/notify.actions";
import {
	addEmail,
	verifyNewEmail,
	changeUserEmail,
	verifyEmailCode,
	deleteEmail,
} from "../../../../actions/user.actions";
import Field from "../../../../dumb-components/fields/field";
import Input from "../../../../dumb-components/fields/input";
import Text from "../../../../dumb-components/shared/text/text";
import { func, number, oneOf, string } from "prop-types";
import { map } from "react-immutable-proptypes";
import Tooltip from "../../../../dumb-components/shared/tooltip/tooltip";

const DELETE_BUTTON_TOOLTIPS = {
	emailIsDefault: {
		tid: "users.profile.email_field.delete_btn.tooltip.email_is_default",
		delayShow: "instant",
	},
};

const StyledInput = styled.div`
	position: relative;
	margin: ${(props) => props.margin};
	top: ${(props) => props.top};
`;

const StyledEmailFieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledFieldContainer = styled("div")`
	position: relative;
	display: flex;
	align-items: center;
`;

const EmailField = ({
	userId,
	emailEntry,
	defaultEmail,
	mode,
	onChangeDefaultEmail,
	index,
}) => {
	const dispatch = useDispatch();
	const [email, setEmail] = useState(emailEntry);
	const [errors, setErrors] = useState(Map());
	const [pendingEmailChange, setPendingEmailChange] = useState(
		mode === "edit" && emailEntry ? !emailEntry.get("verified", false) : false,
	);
	const [code, setCode] = useState("");

	const emailId = email.get("id");
	const emailChangedAndValid =
		email.get("email") !== emailEntry.get("email") &&
		validateEmail(email.get("email"));
	const isDefaultEmail = defaultEmail === emailId;

	const onChangeEmail = (val) => {
		setEmail(emailEntry.set("email", val));
	};

	const onSaveEmail = () => {
		if (!validateEmail(email.get("email"))) {
			dispatch(
				addErrorNotification({
					tid: "validation.email",
				}),
			);
			setErrors(fromJS({ email: ["validation.email"] }));
			return;
		}

		if (mode === "create") {
			dispatch(
				addEmail(
					userId,
					{
						email: email.get("email"),
						isDefault: isDefaultEmail,
					},
					(newEmail) => {
						setEmail(newEmail);
						dispatch(clearNotifications());
						setPendingEmailChange(true);
						setErrors(Map());
					},
				),
			);
			return;
		}

		dispatch(
			changeUserEmail(userId, email.get("email"), emailId, () => {
				dispatch(clearNotifications());
				setPendingEmailChange(true);
				setErrors(Map());
			}),
		);
	};

	const onVerify = () => {
		if (mode === "create") {
			dispatch(
				verifyNewEmail(code, emailId, () => {
					setEmail(emailEntry);
					setCode("");
					setPendingEmailChange(false);
				}),
			);
			return;
		}

		dispatch(
			verifyEmailCode(code, emailId, () => {
				setPendingEmailChange(false);
			}),
		);
	};

	const onCancelVerify = () => {
		setPendingEmailChange(false);
		setCode("");
		setEmail(emailEntry);
	};

	const removeEmail = () => {
		dispatch(deleteEmail(userId, emailId));
	};

	let emailLabelObj = {
		tid: "users.profile.email_field.email",
		values: { num: index + 1 },
	};

	if (mode === "create") {
		emailLabelObj = { tid: "users.profile.email_field.new_email" };
	}

	return (
		<StyledEmailFieldWrapper>
			<StyledFieldContainer>
				<Field
					{...emailLabelObj}
					name="email"
					errors={errors}
					flex
					className={`has-feedback ${
						mode === "create" ? "col-md-10" : "col-md-12"
					}`}
				>
					<Input
						name={emailId}
						value={email.get("email")}
						onChange={onChangeEmail}
					/>
					<span
						className="form-control-feedback text--success"
						style={{ top: "25px" }}
					>
						{isDefaultEmail && <FormattedMessage id="generic.default" />}
					</span>
				</Field>

				{mode === "edit" && (
					<>
						<StyledInput top="12px">
							<Field name="email" errors={errors} flex className="none">
								<Input
									onChange={onChangeDefaultEmail}
									type="radio"
									value={emailId}
									checked={isDefaultEmail}
									disabled={!emailEntry.get("verified", false)}
								/>
							</Field>
						</StyledInput>
						<StyledInput top="4px" margin="0 15px 0 0">
							<Tooltip
								states={DELETE_BUTTON_TOOLTIPS}
								activeState={isDefaultEmail && "emailIsDefault"}
							>
								<Button
									variant="underline"
									disabled={isDefaultEmail}
									onClick={removeEmail}
								>
									<Text tid="generic.delete" />
								</Button>
							</Tooltip>
						</StyledInput>
					</>
				)}
			</StyledFieldContainer>
			{pendingEmailChange && (
				<Field>
					<Text tid="user_profile.change_email_pending" />{" "}
					<a onClick={onSaveEmail} className="btn-link">
						<Text tid="user_profile.change_email_resend" />
					</a>
				</Field>
			)}
			{emailChangedAndValid && !pendingEmailChange && (
				<Field>
					<button
						type="submit"
						className="btn btn-primary"
						onClick={onSaveEmail}
					>
						<FormattedMessage id="user_profile.change_email" />
					</button>
				</Field>
			)}
			{pendingEmailChange && (
				<Field>
					<div style={{ display: "flex", width: "75%" }}>
						<div style={{ marginRight: "16px" }}>
							<Input
								name="code"
								placeholder="XXXX"
								value={code}
								onChange={(val) => {
									setCode(val);
								}}
							/>
						</div>

						<button
							type="submit"
							style={{ marginRight: "16px" }}
							className="btn btn-primary"
							onClick={onVerify}
						>
							<FormattedMessage id="user_profile.change_email_verify" />
						</button>
						<button
							type="submit"
							className="btn btn-default"
							onClick={onCancelVerify}
						>
							<FormattedMessage id="generic.form.cancel" />
						</button>
					</div>
				</Field>
			)}
		</StyledEmailFieldWrapper>
	);
};

EmailField.propTypes = {
	userId: string,
	emailEntry: map,
	defaultEmail: string,
	mode: oneOf(["create", "edit"]),
	onChangeDefaultEmail: func,
	index: number,
};

export default EmailField;
