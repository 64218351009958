import React from "react";
import { Tooltip as MuiTooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { number, object, oneOf, oneOfType, string } from "prop-types";
import styled from "styled-components";
import { FormattedHTMLMessage } from "react-intl";
import { useTheme } from "@mui/material";
import Icon from "../../../../dumb-components/shared/icon/icon";

const StyledTooltip = styled((props) => (
	<MuiTooltip classes={{ popper: props.className }} {...props} />
))`
	& .MuiTooltip-tooltip {
		background: white;
		color: #6c6c6c;
		font-size: 1.3rem;
		padding: 16px;
		border-radius: 0;
		box-shadow: 0 5px 12px 2px rgba(0, 0, 0, 0.25);
	}
`;

export default function TickHeaderTooltip({
	title,
	delay,
	placement,
	hideTooltip,
	showTic,
	...muiTooltipProps
}) {
	const theme = useTheme();

	if (hideTooltip) {
		const { children } = muiTooltipProps;
		return <>{children}</>;
	}

	if (typeof delay === "string") {
		switch (delay) {
			case "instant":
				delay = 0;
				break;
			case "short":
				delay = 300;
				break;
			default:
				delay = 600;
				break;
		}
	}

	if (typeof title === "string") {
		title = { id: title };
	}

	return (
		<StyledTooltip
			{...muiTooltipProps}
			title={
				<>
					{showTic && (
						<Box>
							<Icon
								icon="faLink"
								size="sml"
								color={theme.palette.success.main}
							/>
						</Box>
					)}

					<FormattedHTMLMessage {...title} />
				</>
			}
			enterDelay={delay}
			placement={placement}
			TransitionProps={{ timeout: 0 }}
		/>
	);
}

TickHeaderTooltip.propTypes = {
	...MuiTooltip.propTypes,
	delay: oneOfType([number, oneOf(["instant", "short", "default"])]),
	placement: oneOf(["top", "bottom", "left", "right"]), //+ -start/-end	ex: 'top-start'
	title: oneOfType([string, object]),
};

TickHeaderTooltip.defaultProps = {
	...MuiTooltip.defaultProps,
	placement: "top",
	delay: "default",
	hideTooltip: false,
};
