import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";

import ResultView from "../../../dumb-components/meetings/voting/manage-modal/result";
import ChangeResultSelect from "./change-result-select.container";

import { fetchAndCacheInvestors } from "../../../actions/investors.actions";

class ResultViewContainer extends Component {
	state = {
		votes: null,
		results: null,
	};

	componentDidMount = () => {
		const { votes, results } = this.props;

		if (votes) {
			this.parseVotes();
		}

		if (results) {
			this.parseResults();
		}

		this.fetchInvestors();
	};

	componentDidUpdate = (prevProps) => {
		const { votes, results, attendees } = this.props;

		if (votes !== prevProps.votes) {
			this.parseVotes();
		}

		if (results !== prevProps.results) {
			this.parseResults();
		}

		if (attendees !== prevProps.attendees) {
			this.fetchInvestors();
		}
	};

	fetchInvestors = () => {
		const { attendees, fetchAndCacheInvestors } = this.props;

		const investmentIds = attendees
			.filter((a) => !a.get("isGuest"))
			.map((a) => a.get("userId"));
		fetchAndCacheInvestors(investmentIds);
	};

	parseVotes = () => {
		const { attendees } = this.props;
		let { votes } = this.props;

		if (!votes) {
			return;
		}

		votes = votes.map((vote, attendeeId) => {
			return vote.set("attendee", attendees.get(attendeeId));
		});

		this.setState({ votes });
	};

	parseResults = () => {
		const { sharesInitialized } = this.props;
		let { results } = this.props;

		if (!results) {
			return;
		}

		results = results.map((result) => {
			result = result.set(
				"value",
				sharesInitialized
					? result.get("percentageOfVotes")
					: result.get("numOfVotes"),
			);
			result = result.set(
				"numberFormat",
				sharesInitialized ? "decimal" : "integer",
			);
			return result.set("prefix", sharesInitialized ? "%" : null);
		});

		this.setState({ results });
	};

	renderChangeResultSelect = (voterId) => {
		return <ChangeResultSelect voterId={voterId} />;
	};

	render = () => {
		const {
			investors,
			usersCache,
			sharesSnapshot,
			votingIsSealed,
			proxies,
			attendees,
			topicRef,
			question,
		} = this.props;
		const { votes, results } = this.state;

		return (
			<ResultView
				votes={votes}
				investors={investors}
				usersCache={usersCache}
				sharesSnapshot={sharesSnapshot}
				results={results}
				votingIsSealed={votingIsSealed}
				renderChangeResultSelect={this.renderChangeResultSelect}
				proxies={proxies}
				attendees={attendees}
				topicRef={topicRef}
				question={question}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		attendees: store.meetings.getIn(["meeting", "attendees"], Map()),
		votes: store.votings.getIn(["voting", "votes"]),
		votingIsSealed: store.votings.getIn(["voting", "sealed"]),
		investors: store.investors.get("investorsCache"),
		usersCache: store.usersCache.get("usersCache"),
		sharesSnapshot: store.votings.getIn(["voting", "sharesSnapshot"]),
		results: store.votings.getIn(["voting", "results"]),
		sharesInitialized: store.company.company?.metadata?.sharesInitialized,
		proxies: store.meetings.getIn(["meeting", "proxies"]),
		topicRef: store.votings.getIn(["voting", "topicRef"]),
		question: store.votings.getIn(["voting", "question"]),
	};
};

const mapActionsToProps = {
	fetchAndCacheInvestors,
};

export default connect(mapStoreToProps, mapActionsToProps)(ResultViewContainer);
