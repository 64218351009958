import { fromJS } from "immutable";

/**
 * User reducer
 * @module reducers/user.reducer
 */

import {
	USER_FETCH,
	USER_UPDATE,
	USER_SAVE,
	USER_REMOVE_COMPANY,
	USER_DIRTY,
	USER_EDIT_START,
	CREDENTIALS_SIGNOUT,
	USER_FETCH_COMPANIES_USER_MEMBER_OF,
} from "../actions/types";

/** The initial state object for user. */
const INITIAL_STATE = fromJS({
	userObj: null,
	isDirty: false,
	companies: null,
	companiesUserMemberOf: null,
});

/** Export the reducer */
export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case USER_FETCH:
			state = state.set("companies", action.payload.companies);
			return state.set("userObj", action.payload);

		case USER_UPDATE:
			return state.set("userObj", action.payload);

		case USER_SAVE:
			if (state.get("isDirty")) {
				return state;
			}
			return state.set("userObj", action.payload);

		case USER_DIRTY:
			return state.set("isDirty", true);

		case USER_EDIT_START:
			return state.set("isDirty", false);

		case USER_REMOVE_COMPANY:
			state = state.update("companies", (companies) => {
				companies = companies.filter((company) => {
					return company.get("id") !== action.payload;
				});
				return companies;
			});
			return state;

		case USER_FETCH_COMPANIES_USER_MEMBER_OF: {
			return state.set("companiesUserMemberOf", action.payload);
		}
		case CREDENTIALS_SIGNOUT:
			return INITIAL_STATE;

		default:
			return state;
	}
}
