import isEqual from "lodash/isEqual";

export const hasAppliedSessionFilters = (filterBy, defaultFilters) => {
	const sessionFilters = {};

	filterBy.forEach((filter) => {
		sessionFilters[filter.get("source")] = filter.get("values").toJS();
	});

	return !isEqual(sessionFilters, defaultFilters.toJS());
};
