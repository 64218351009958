import React, { Component } from "react";
import { connect } from "react-redux";
import { string, func } from "prop-types";
import { map } from "react-immutable-proptypes";

import DropdownIconItem from "../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import Tooltip from "../../../../dumb-components/shared/tooltip/tooltip";

import DeleteFileComponent from "../delete-file-component.container";

class DeleteFileComboItem extends Component {
	static propTypes = {
		document: map.isRequired,
		basePath: string.isRequired,
		querystr: string.isRequired,
		onClick: func,
	};

	render = () => {
		const { document, basePath, querystr, onClick } = this.props;

		return (
			<DeleteFileComponent
				document={document}
				basePath={basePath}
				querystr={querystr}
			>
				{({
					TOOLTIP_STATES,
					activeState,
					tooltipValues,
					isDisabled,
					softDelete,
				}) => {
					return (
						<Tooltip
							states={TOOLTIP_STATES}
							activeState={activeState}
							values={tooltipValues}
						>
							<DropdownIconItem
								tid="delete_file"
								icon="faTrashAlt"
								disabled={isDisabled}
								onClick={() => {
									onClick && onClick();
									softDelete();
								}}
							/>
						</Tooltip>
					);
				}}
			</DeleteFileComponent>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {};

export default connect(mapStoreToProps, mapActionsToProps)(DeleteFileComboItem);
