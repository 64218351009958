import React, { PureComponent } from "react";
import { Button } from "@/components/ui/button";
import { bool, func, string } from "prop-types";
import { map } from "react-immutable-proptypes";
import { Map } from "immutable";
import { Input } from "@/components/ui/input";
import Label from "../../shared/label/label";
import { Checkbox } from "@/components/ui/checkbox";

import i18n from "@/i18n";

import {
	EMAIL_FIELD,
	PASSWORD_FIELD,
	REMEMBER_DETAILS_FIELD,
	CODE_FIELD,
} from "../../../constants/credentials";
import { LockOpen } from "lucide-react";
import VerificationCodeInput from "./verificationCodeInput";

const { t } = i18n;

export default class SignInEmail extends PureComponent {
	static propTypes = {
		onClickForgotPassword: func,
		rememberDetails: bool,
		onClickSignIn: func,
		onClickVerify: func,
		onClickCancel: func,
		isVerifyMode: bool,
		onChange: func,
		hasError: map,
		code: string,
		email: string,
		password: string,
		actionBtnLoading: bool,
	};

	static defaultProps = {
		rememberDetails: false,
		isVerifyMode: false,
		hasError: Map(),
		code: "",
		email: "",
		password: "",
	};

	componentDidUpdate(prevProps) {
		if (!prevProps.isVerifyMode && this.props.isVerifyMode) {
			this.verificationCodeInput?.focus();
		}
	}

	renderEmailInput = () => {
		const {
			hasError,
			onClickSignIn,
			isVerifyMode,
			onChange,
			email,
			actionBtnLoading,
		} = this.props;

		return (
			<div>
				<label htmlFor="email">{t("credentials.signin.label.email")}</label>
				<Input
					autoFocus
					id="email"
					value={email}
					error={
						hasError.get(EMAIL_FIELD)?.size > 0 &&
						hasError.get(EMAIL_FIELD).map(t).toJS()
					}
					disabled={isVerifyMode || actionBtnLoading}
					onChange={(evt) => onChange(EMAIL_FIELD, evt.target.value)}
					onKeyDown={(evt) => evt.key === "Enter" && onClickSignIn()}
				/>
			</div>
		);
	};

	renderPasswordInput = () => {
		const {
			onClickSignIn,
			isVerifyMode,
			hasError,
			onChange,
			password,
			actionBtnLoading,
		} = this.props;

		return (
			<Label tid="credentials_password">
				<Input
					type="password"
					value={password}
					error={
						hasError.get(PASSWORD_FIELD)?.size > 0 &&
						hasError.get(PASSWORD_FIELD).map(t).toJS()
					}
					disabled={isVerifyMode || actionBtnLoading}
					onChange={(evt) => onChange(PASSWORD_FIELD, evt.target.value)}
					onKeyDown={(evt) => evt.key === "Enter" && onClickSignIn()}
				/>
			</Label>
		);
	};

	renderVerifyCodeInput = () => {
		const { onChange, code, email, isVerifyMode, onClickVerify } = this.props;

		if (!isVerifyMode) {
			return null;
		}

		return (
			<VerificationCodeInput
				ref={(ref) => {
					this.verificationCodeInput = ref;
				}}
				email={email}
				value={code}
				onChange={(code) => {
					onChange(CODE_FIELD, code);
				}}
				onComplete={onClickVerify}
			/>
		);
	};

	renderRememberCredentials = () => {
		const { onChange, rememberDetails, isVerifyMode } = this.props;

		if (isVerifyMode) {
			return null;
		}

		return (
			<div className="flex gap-2 items-center">
				<Checkbox
					id="remember-credentials"
					label={t("credentials_remember.credentials")}
					checked={rememberDetails}
					onCheckedChange={(checked) =>
						onChange(REMEMBER_DETAILS_FIELD, checked)
					}
				/>
			</div>
		);
	};

	renderForgotPassword = () => {
		const { onClickForgotPassword, isVerifyMode } = this.props;

		if (isVerifyMode) {
			return null;
		}

		return (
			<Button onClick={onClickForgotPassword} variant="link">
				{t("credentials_forgot.password")}
			</Button>
		);
	};

	render = () => {
		const {
			onClickSignIn,
			onClickVerify,
			onClickCancel,
			isVerifyMode,
			actionBtnLoading,
		} = this.props;

		return (
			<div className="flex flex-col gap-4">
				{this.renderEmailInput()}
				{this.renderPasswordInput()}
				{this.renderVerifyCodeInput()}
				<div className="flex justify-between">
					{this.renderRememberCredentials()}
					{this.renderForgotPassword()}
				</div>

				{isVerifyMode ? (
					<div className="flex justify-stretch gap-2">
						<Button
							className="w-full"
							onClick={onClickVerify}
							isLoading={actionBtnLoading}
							size="lg"
						>
							<LockOpen size={16} />
							{t("credentials_verify")}
						</Button>

						<Button
							className="w-full"
							onClick={onClickCancel}
							size="lg"
							variant="outline"
						>
							{t("credentials_cancel")}
						</Button>
					</div>
				) : (
					<Button
						onClick={onClickSignIn}
						isLoading={actionBtnLoading}
						size="lg"
						className="w-full"
					>
						{t("credentials_signin")}
					</Button>
				)}
			</div>
		);
	};
}
