import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import React from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

const EmissionDisplayValue = (props) => {
	const lang = props.lang
		? props.lang
		: useSelector((state) => state.i18n.language).substring(0, 2);
	const { t } = useTranslation();

	return (
		<Box sx={{ display: "flex", columnGap: 0.5 }}>
			<Typography variant={"body1"} sx={{ py: 1, flex: 1, fontWeight: "600" }}>
				{t(props.label, { lng: lang })}:
			</Typography>
			<Typography variant={"body2"} sx={{ py: 1, flex: 1, display: "flex" }}>
				{props.value}
			</Typography>
		</Box>
	);
};

export default EmissionDisplayValue;
