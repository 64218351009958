import React, { PureComponent } from "react";
import styled, { css } from "styled-components";
import { string, bool } from "prop-types";
import Text from "../../../shared/text/text";

const StyledBlock = styled.div`
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

const StyledBlockTitle = styled.div`
	min-height: 45px;
	padding: 0 ${(props) => props.theme.spacing[4]}
		${(props) => props.theme.spacing[4]} 0;
	display: flex;
	flex-direction: row;
	align-items: center;

	${(props) =>
		!props.noBorderBottom &&
		css`
			border-bottom: 1px solid ${(props) => props.theme.colors.border};
		`}
`;

export default class Block extends PureComponent {
	static propTypes = {
		titleTid: string,
		noBorderBottom: bool,
	};

	static defaultProps = {
		noBorderBottom: false,
	};

	render = () => {
		const { children, titleTid, noBorderBottom } = this.props;

		return (
			<StyledBlock>
				<StyledBlockTitle noBorderBottom={noBorderBottom}>
					<Text fontSize="18px" color="lightGrey" tid={titleTid} />
				</StyledBlockTitle>

				{children}
			</StyledBlock>
		);
	};
}
