/**
 * Notify reducer
 * @module reducers/notify.reducer
 */

import { SET_LOCALE } from "../actions/types";

/** The initial state object for i18n. */
const INITIAL_STATE = { messages: null, language: null };

/** Export the reducer */
export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_LOCALE:
			return { ...state, ...action.payload };
	}

	return state;
}
