import React, { useCallback, useEffect } from "react";
import { fromJS } from "immutable";
import { useDispatch, useSelector } from "react-redux";
import { fetchPersonalDocuments } from "../../actions/personal-documents.actions";
import TreeView from "../../components/personal-documents/tree-view";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import { resetCompany } from "../../actions/company.actions";

const PersonalDocuments = () => {
	const dispatch = useDispatch();
	const documents = useSelector((state) =>
		fromJS(state.personalDocuments.documents),
	);
	const folderIds = useSelector((state) =>
		fromJS(state.personalDocuments.folderIds),
	);

	const getPersonalDocuments = useCallback(async () => {
		await dispatch(fetchPersonalDocuments());
	}, []);

	useEffect(() => {
		getPersonalDocuments();
		dispatch(resetCompany());
	}, []);
	return (
		<Box sx={{ p: 2 }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card>
						<CardContent>
							<Typography variant="h4" component="div">
								<FormattedMessage id={"personal-documents.main-header"} />
							</Typography>
							<Box>
								<TreeView documents={documents} folderIds={folderIds} />
							</Box>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
};

export default PersonalDocuments;
