import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { fromJS } from "immutable";
import { Scrollbars } from "react-custom-scrollbars";
import isFunction from "lodash/isFunction";
import { string, object, bool, func } from "prop-types";

/**
 Props:
 - columns: []
 - data: []
 - defaultSortOrder; 'ASC'
 - defaultSortByProp: null
 - Rowcomponent: null
 - rowComponentProps: null
 - noStripe: false
*/

// const columns = [
// 	{
// 		prop: 'investedInCompanyInformation.name',
// 		header: { translationId: 'generic.company' },
// 		size: '*',
// 		sortable: true,
// 		Rendercomponent: LinkColumn
// 	},
// 	{
// 		prop: 'details.totalCompanyValue',
// 		header: { translationId: 'capital_raised' },
// 		size: 3,
// 		formatter: NumberFormat
// 	}
// ];
//
// const data = [];

class FlexTable extends Component {
	static propTypes = {
		columns: object, // Immutable List
		data: object, // Immutable List
		defaultSortOrder: string,
		defaultSortByProp: string,
		noStripe: bool,
		sortOwnerReportPdfByValue: func,
	};

	static defaultProps = {
		defaultSortOrder: "DESC",
		noStripe: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			data: fromJS([]),
			sortByProp: null,
			sortOrder: "ASC",
			defaultSortOrder: "ASC",
		};
	}

	componentDidMount() {
		const { data, defaultSortByProp, defaultSortOrder } = this.props;
		this.setState({
			sortByProp: defaultSortByProp || null,
			sortOrder: defaultSortOrder || "ASC",
			defaultSortOrder: defaultSortOrder || "ASC",
		});

		if (defaultSortByProp) {
			this.setData(data, defaultSortByProp, defaultSortOrder);
		} else {
			this.setState({ data });
		}

		if (this.props.scrollReverse) {
			this.scrollLastColumnIntoView();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.data !== prevProps.data) {
			this.setData(this.props.data);
		}
	}

	setData = (data, sortByProp, sortOrder) => {
		if (!data || data.size === 0) {
			this.setState({ data });
			return;
		}

		if (sortByProp) {
			data = data.sort((a, b) => {
				const propPath = sortByProp.split(".");
				const aValue = a.getIn(propPath);
				const bValue = b.getIn(propPath);

				if (sortOrder === "ASC") {
					if (typeof aValue === "string") {
						return aValue.localeCompare(bValue);
					} else {
						if (aValue < bValue) {
							return -1;
						} else if (aValue > bValue) {
							return 1;
						} else {
							return 0;
						}
					}
				} else {
					if (typeof bValue === "string") {
						return bValue.localeCompare(aValue);
					} else {
						if (aValue < bValue) {
							return 1;
						} else if (aValue > bValue) {
							return -1;
						} else {
							return 0;
						}
					}
				}
			});
		}

		this.setState({ data });
	};

	sortData = (sortByProp, sortOrder) => {
		const { sortOwnerReportPdfByValue } = this.props;
		let data = this.state.data;

		if (sortByProp) {
			data = data.sort((a, b) => {
				const propPath = sortByProp.split(".");
				const aValue = a.getIn(propPath);
				const bValue = b.getIn(propPath);

				if (sortOrder === "ASC") {
					if (typeof aValue === "string") {
						return aValue.localeCompare(bValue);
					} else {
						if (aValue < bValue) {
							return -1;
						} else if (aValue > bValue) {
							return 1;
						} else {
							return 0;
						}
					}
				} else {
					if (typeof bValue === "string") {
						return bValue.localeCompare(aValue);
					} else {
						if (aValue < bValue) {
							return 1;
						} else if (aValue > bValue) {
							return -1;
						} else {
							return 0;
						}
					}
				}
			});
		}

		if (sortOwnerReportPdfByValue && sortByProp) {
			sortOwnerReportPdfByValue({
				ownerReport: {
					sortByProp: sortByProp,
					sortOrder: sortOrder,
				},
			});
		}

		this.setState({ data, sortByProp, sortOrder });
	};

	scrollLastColumnIntoView = () => {
		setTimeout(() => {
			this.scrollbars && this.scrollbars.scrollToRight();
		}, 300);
	};

	renderHeaderColumn = (column, index) => {
		const { sortByProp, sortOrder } = this.state;
		const prop = column.get("prop");
		const className =
			"flex-table__header-col " +
			(column.get("size") === "*"
				? ""
				: `flex-table__header-col--${column.get("size")}`);
		const headerClasses = column.getIn(["header", "className"], "");
		// const title = column.getIn(['header', 'translationId']) && <FormattedMessage id={column.getIn(['header', 'translationId'])}/>;
		let title = column.hasIn(["header", "translationId"]) ? (
			<FormattedMessage id={column.getIn(["header", "translationId"])} />
		) : (
			column.getIn(["header", "text"], "")
		);

		let sort = "";

		if (column.get("sortable") === true) {
			title = (
				<span
					style={{ cursor: "pointer" }}
					onClick={this.sortData.bind(
						this,
						prop,
						sortOrder === "ASC" ? "DESC" : "ASC",
					)}
				>
					{title}
				</span>
			);

			let sortClasses = "";
			if (sortByProp === prop) {
				sortClasses += "fa fa-caret-" + (sortOrder === "ASC" ? "up" : "down");
			}
			sort = <span className={sortClasses}></span>;
		}

		return (
			<th key={index} className={`${className} ${headerClasses}`}>
				{title} {sort}
			</th>
		);
	};

	renderRow = (columns, row, index) => {
		const { Rowcomponent, rowComponentProps } = this.props;

		if (Rowcomponent) {
			return (
				<Rowcomponent
					{...rowComponentProps}
					className="flex-table__body-row"
					key={index}
					data={row}
				>
					{columns.map(this.renderColumn.bind(this, row, index))}
				</Rowcomponent>
			);
		}

		return (
			<tr className="flex-table__body-row" key={index}>
				{columns.map(this.renderColumn.bind(this, row, index))}
			</tr>
		);
	};

	renderColumn = (datarow, rowIndex, column, index) => {
		const propPath = column.get("prop").split(".");
		const value = datarow.getIn(propPath);

		if (column.has("Rendercomponent")) {
			const Rendercomponent = column.get("Rendercomponent");
			return (
				<Rendercomponent
					value={value}
					prop={propPath}
					index={index}
					column={column}
					rowIndex={rowIndex}
					dataRow={datarow}
				/>
			);
		} else {
			const content =
				column.has("formatter") && !column.has("type")
					? column.get("formatter")(value, column, index, rowIndex, datarow)
					: value;
			const newContent =
				column.has("formatter") && column.has("type")
					? column.get("formatter")(value, column.get("type"))
					: undefined;
			const className =
				"flex-table__body-col " +
				(column.get("size") === "*"
					? ""
					: `flex-table__body-col--${column.get("size")}`);
			const prefix = column.get("prefix", "");
			const suffix = column.get("suffix", "");

			let columnClasses = column.get("columnClasses", "");

			if (isFunction(columnClasses)) {
				columnClasses = columnClasses(value, column, index, rowIndex, datarow);
			}

			return (
				<td className={`${className} ${columnClasses}`} key={index}>
					{newContent ? (
						newContent
					) : (
						<>
							{prefix}
							{content}
							{suffix}
						</>
					)}
				</td>
			);
		}
	};

	renderEmptyResult = (colSpan) => {
		return (
			<tr className="flex-table__body-row">
				<td className="flex-table__body-col" colSpan={colSpan}>
					&nbsp;
				</td>
			</tr>
		);
	};

	setScrollbarRef = (ref) => {
		this.props.onScrollbarRef && this.props.onScrollbarRef(ref);
		this.scrollbars = ref;
	};

	renderTable = (columns, index) => {
		const locked = columns.getIn([0, "locked"], false);
		const { data } = this.state;
		const { noStripe } = this.props;

		if (locked) {
			return (
				<div className={`flex-table__overflow`} key={index}>
					<table
						className={`flex-table ${noStripe ? "" : "flex-table--striped"}`}
					>
						<thead>
							<tr className="flex-table__header-row">
								{columns.map(this.renderHeaderColumn)}
							</tr>
						</thead>
						<tbody className="flex-table__body">
							{data &&
								data.size > 0 &&
								data.map(this.renderRow.bind(this, columns))}
							{(!data || data.size === 0) &&
								this.renderEmptyResult(columns.size)}
						</tbody>
					</table>
				</div>
			);
		} else {
			return (
				<div className={`flex-table__overflow i-layout-column`} key={index}>
					<Scrollbars
						ref={this.setScrollbarRef}
						autoHeight
						autoHeightMin={100}
						autoHeightMax={2000}
					>
						<table
							className={`flex-table ${noStripe ? "" : "flex-table--striped"}`}
						>
							<thead>
								<tr className="flex-table__header-row">
									{columns.map(this.renderHeaderColumn)}
								</tr>
							</thead>
							<tbody className="flex-table__body">
								{data &&
									data.size > 0 &&
									data.map(this.renderRow.bind(this, columns))}
								{(!data || data.size === 0) &&
									this.renderEmptyResult(columns.size)}
							</tbody>
						</table>
					</Scrollbars>
				</div>
			);
		}
	};

	render() {
		const { columns } = this.props;
		const groupedColumns = columns.groupBy((column) => {
			return !column.get("locked", false);
		});

		return (
			<div className="i-layout-row">
				{groupedColumns.map(this.renderTable).toList()}
			</div>
		);
	}
}

export default FlexTable;
