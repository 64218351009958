import React from "react";
import IconButton from "../../../mui-components/button/icon-button";
import { openModal } from "../../../actions/modals.actions";
import { useDispatch, useSelector } from "react-redux";
import { DOCUMENTS_ADVANCED_FILTER_MODAL } from "../../../constants/modals";
// import { hasAppliedAdvancedFilters } from '../../../components/helpers/filter-and-sort.helper';
import Tooltip from "../../../mui-components/tooltip/tooltip";
import { hasAppliedSessionFilters } from "./advanced-filter.helper";

const DocumentsFilterDialogButton = () => {
	const filterBy = useSelector((state) => state.documents.get("filterBy"));
	const defaultFilters = useSelector((state) =>
		state.documents.get("defaultFilters"),
	);
	const dispatch = useDispatch();

	// const advancedFilterApplied = hasAppliedAdvancedFilters(filterBy, defaultFilters);
	const sessionFiltersIsApplied = hasAppliedSessionFilters(
		filterBy,
		defaultFilters,
	);

	return (
		<Tooltip
			states={BTN_STATES}
			activeState={sessionFiltersIsApplied ? "hasAppliedFilters" : "default"}
		>
			<IconButton
				icon={"faSlidersH"}
				noBorder
				size={"sml"}
				onClick={() => dispatch(openModal(DOCUMENTS_ADVANCED_FILTER_MODAL))}
				notificationBadge={sessionFiltersIsApplied}
			/>
		</Tooltip>
	);
};

export default DocumentsFilterDialogButton;

const BTN_STATES = {
	default: {
		title: "tooltip.documents.action.advanced_filter",
	},
	hasAppliedFilters: {
		title: "tooltip.documents.action.advanced_filter.active",
		enterDelay: "instant",
	},
};
