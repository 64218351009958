import React, { Component } from "react";
import { connect } from "react-redux";
import { object } from "prop-types";

import DropdownIconItem from "../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import Tooltip from "../../../../../dumb-components/shared/tooltip/tooltip";

import { openModal } from "../../../../../actions/modals.actions";
import { MEETINGS_START_STANDARD_MEETING_MODAL } from "../../../../../constants/modals";

class StartStandardMeetingsContainer extends Component {
	static propTypes = {
		dropdownRef: object,
	};

	TOOLTIP_STATES = {
		meetingisStarted: {
			tid: "meetings.standard.toolbar.dropdown.start_meeting.tooltip.started",
			delayShow: "instant",
		},
		notMeetingAdmin: {
			tid: "meetings.standard.toolbar.dropdown.start_meeting.tooltip.not_admin",
			delayShow: "instant",
		},
	};

	onStartMeeting = () => {
		const { dropdownRef } = this.props;

		dropdownRef && dropdownRef.onToggleMenu();

		this.props.openModal(MEETINGS_START_STANDARD_MEETING_MODAL);
	};

	getTooltipActiveState = () => {
		const { meetingIsStarted, isModerator } = this.props;

		if (meetingIsStarted) {
			return "meetingisStarted";
		}
		if (!isModerator) {
			return "notMeetingAdmin";
		}
	};

	render = () => {
		const activeState = this.getTooltipActiveState();

		return (
			<Tooltip states={this.TOOLTIP_STATES} activeState={activeState}>
				<DropdownIconItem
					icon="faPlayCircle"
					onClick={this.onStartMeeting}
					disabled={Boolean(activeState)}
					tid="meetings.standard.toolbar.dropwon.start_meeting"
				/>
			</Tooltip>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meetingIsStarted: store.meetings.getIn([
			"meeting",
			"computedValues",
			"meetingIsStarted",
		]),
		isModerator: store.meetings.getIn([
			"meeting",
			"computedValues",
			"isModerator",
		]),
	};
};

const mapActionsToProps = {
	openModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(StartStandardMeetingsContainer);
