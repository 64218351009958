import React, { PureComponent } from "react";
import { func, string } from "prop-types";
import { map } from "react-immutable-proptypes";

import Grid from "styled-components-grid";
import { Padding } from "styled-components-spacing";
import Label from "../../../shared/label/label";
import Input from "../../../shared/input/input";
import TextArea from "../../../shared/input/textarea";

export default class ModalItemForm extends PureComponent {
	static propTypes = {
		item: map,
		onChange: func,
		errors: map,
		nameTid: string,
	};

	render = () => {
		const { item, onChange, errors, nameTid } = this.props;

		return (
			<Grid>
				<Grid.Unit size={1}>
					<Padding bottom={4}>
						<Label tid={nameTid}>
							<Input
								fieldName="name"
								value={item && item.get("name")}
								onChange={onChange && onChange}
								hasError={errors && errors.get("name")}
								placeholderTid={nameTid}
							/>
						</Label>
					</Padding>
				</Grid.Unit>
				<Grid.Unit size={1}>
					<Label tid="generic.description">
						<TextArea
							fieldName="description"
							value={item && item.get("description")}
							onChange={onChange && onChange}
							placeholderTid="generic.description"
							maxRows={6}
							minRows={6}
						/>
					</Label>
				</Grid.Unit>
			</Grid>
		);
	};
}
