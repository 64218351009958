import React, { Component } from "react";
import { connect } from "react-redux";
import { string, func } from "prop-types";

import ManageAttendee from "../../../../dumb-components/meetings/manage-attendee/manage-attendee";

import AttendeeFunctionContainer from "../attendee-functions.container";
import ProxyPanelContainer from "../../proxy-panel/proxy-panel.container";
import ModeratorControlsContainer from "./moderator-controls.container";

class ManageAttendeeContainer extends Component {
	static propTypes = {
		attendeeId: string,
		onManageFunctions: func,
	};

	renderAttendeeFunction = (attendeeId) => {
		return (
			<AttendeeFunctionContainer attendeeId={attendeeId} withPortal={true} />
		);
	};

	renderProxyPanelContainer = (attendeeId) => {
		return <ProxyPanelContainer attendeeId={attendeeId} />;
	};

	renderModeratorControlsContainer = (attendeeId) => {
		return <ModeratorControlsContainer attendeeId={attendeeId} />;
	};

	render = () => {
		const { attendeeId, onManageFunctions, meetingSubType, attendees } =
			this.props;
		const attendee = attendees.get(attendeeId);
		const dontRenderModerator =
			!attendee || attendee.get("isGuest") || attendee.get("isInvestor");

		return (
			<ManageAttendee
				attendeeId={attendeeId}
				meetingSubType={meetingSubType}
				onManageFunctions={onManageFunctions}
				renderAttendeeFunction={this.renderAttendeeFunction}
				renderProxyPanelContainer={this.renderProxyPanelContainer}
				renderModeratorControlsContainer={
					dontRenderModerator
						? undefined
						: this.renderModeratorControlsContainer
				}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meetingSubType: store.meetings.getIn(["meeting", "meetingSubType"]),
		attendees: store.meetings.getIn(["meeting", "attendees"]),
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ManageAttendeeContainer);
