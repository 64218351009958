import React, { PureComponent } from "react";
import immutablePropTypes from "react-immutable-proptypes";
import { func, number, bool, string, object } from "prop-types";
import styled from "styled-components";
import Input from "../../shared/input/input";
import Text from "../../shared/text/text";
import DropdownMenuMenu from "../../shared/dropdown-menu/dropdown-menu-menu";
import DropdownItem from "../../shared/dropdown-item/dropdown-item";

const StyledWrapper = styled.div`
	display: inline-block;
	width: 50px;
	height: 100%;
	position: relative;
`;

const StyledDropdownItem = styled(DropdownItem)`
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledDuration = styled.div`
	min-width: 50px;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	cursor: ${(props) => (props.readOnly ? "default" : "pointer")};
	border-right: 1px solid ${(props) => props.theme.colors.border};
`;

const StyledText = styled(Text)`
	flex: 1;
`;

const StyledInput = styled(Input)`
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
	max-width: 40px;
`;

export default class Duration extends PureComponent {
	static propTypes = {
		onToggleMenu: func.isRequired,
		options: immutablePropTypes.list,
		value: number,
		onSelect: func.isRequired,
		onCreate: func.isRequired,
		onChange: func.isRequired,
		inputValue: string,
		isOpen: bool,
		onSetRef: func,
		readOnly: bool,
		onSetDropdowMenuRef: func,
		clientRects: object,
	};

	renderInput = () => {
		const { onChange, onCreate, inputValue } = this.props;

		return (
			<StyledDropdownItem>
				<StyledInput
					name="newDuration"
					value={inputValue}
					onChange={onChange}
					onBlur={onCreate}
					onEnter={onCreate}
				/>
			</StyledDropdownItem>
		);
	};

	renderOption = (option, index) => {
		const { onSelect } = this.props;

		return (
			<StyledDropdownItem
				key={index}
				onClick={() => onSelect(option.get("value"))}
			>
				<StyledText tag="div" align="center">
					{option.get("label")}
				</StyledText>
			</StyledDropdownItem>
		);
	};

	render = () => {
		const {
			onToggleMenu,
			options,
			value,
			isOpen,
			onSetRef,
			readOnly,
			parentRects,
			onSetDropdowMenuRef,
			clientRects,
		} = this.props;

		return (
			<StyledWrapper ref={(ref) => onSetRef && onSetRef(ref)}>
				<StyledDuration onClick={onToggleMenu} readOnly={readOnly}>
					<Text tag="div" align="center">
						<Text tag="div" align="center">
							{value ? value : "--"}
						</Text>
						<Text tag="div" align="center" tid="generic.mins" color="muted" />
					</Text>
				</StyledDuration>
				{!readOnly && (
					<DropdownMenuMenu
						isOpen={isOpen}
						minWidth="50px"
						onSetRef={onSetDropdowMenuRef && onSetDropdowMenuRef}
						parentRects={parentRects}
						clientRects={clientRects}
					>
						{this.renderInput()}
						{options && options.map(this.renderOption)}
					</DropdownMenuMenu>
				)}
			</StyledWrapper>
		);
	};
}
