import React, { PureComponent } from "react";
import { func, bool } from "prop-types";
import Grid from "styled-components-grid";
import { map } from "react-immutable-proptypes";
import { Padding } from "styled-components-spacing";
import Panel from "../../shared/panel/panel";
import Label from "../../shared/label/label";
import Input from "../../shared/input/input";
import FilePreview from "../../shared/file-preview/file-preview";

class DocumentDetailsInformation extends PureComponent {
	static propTypes = {
		document: map,
		onChange: func,
		onOpenFile: func,
		onDownloadFile: func,
		renderTagsSelectContainer: func,
		readOnly: bool,
	};

	static defaultProps = {
		readOnly: false,
	};

	render = () => {
		const {
			document,
			onChange,
			onOpenFile,
			onDownloadFile,
			renderTagsSelectContainer,
			readOnly,
		} = this.props;

		return (
			<Panel title="generic.information" marginBottom={true}>
				<Padding all={3}>
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid="generic.name">
									<Input
										fieldName="title"
										disabled={readOnly}
										onChange={onChange}
										placeholderTid="documents.details.title.placeholder"
										value={(document && document.get("title", "")) || ""}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>

					{renderTagsSelectContainer && (
						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid="generic.tags">
										{renderTagsSelectContainer && renderTagsSelectContainer()}
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>
					)}

					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid="file">
									<FilePreview
										document={document}
										onDownloadFile={onDownloadFile}
										onOpenFile={onOpenFile}
										readOnly={true}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>
				</Padding>
			</Panel>
		);
	};
}

export default DocumentDetailsInformation;
