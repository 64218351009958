import React from "react";
import { Tooltip as MuiTooltip } from "@mui/material";
import { number, object, oneOf, oneOfType, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { getShowDelay } from "../../dumb-components/shared/tooltip/tooltip.helpers";
import styled from "styled-components";
const DEFAULT_PLACEMENT = "top";

const StyledTooltip = styled((props) => (
	<MuiTooltip classes={{ popper: props.className }} {...props} />
))`
	& .MuiTooltip-tooltip {
		font-size: 1.3rem;
	}
`;

const Tooltip = ({ states, activeState, hideTooltip, ...muiTooltipProps }) => {
	const { t } = useTranslation();

	const currentState = activeState || "default";
	let tooltipSettings = states && states[currentState];

	if (!tooltipSettings) {
		const { title, enterDelay, placement } = muiTooltipProps;
		tooltipSettings = { title, enterDelay, placement };
	}

	const { title, enterDelay, placement } = tooltipSettings;
	const delay =
		typeof enterDelay === "string"
			? getShowDelay(enterDelay)
			: enterDelay || getShowDelay("short");

	if (hideTooltip) {
		const { children } = muiTooltipProps;
		return <>{children}</>;
	}

	return (
		<StyledTooltip
			{...muiTooltipProps}
			title={t(title)}
			enterDelay={delay}
			placement={placement || DEFAULT_PLACEMENT}
			arrow
			TransitionProps={{ timeout: 0 }}
		/>
	);
};

Tooltip.propTypes = {
	...MuiTooltip.propTypes,
	enterDelay: oneOfType([number, oneOf(["instant", "short", "default"])]),
	placement: oneOf(["top", "bottom", "left", "right"]), //+ -start/-end	ex: 'top-start'
	states: object,
	activeState: string,
	title: string,
};

Tooltip.defaultProps = {
	...MuiTooltip.defaultProps,
	placement: DEFAULT_PLACEMENT,
	enterDelay: "instant",
	hideTooltip: false,
};

export default Tooltip;
