import styled from "styled-components";
import ListItem from "../../shared/list-item/list-item";

export const StyledListItem = styled(ListItem)`
	display: flex;
	align-items: center;
	padding-right: ${(props) => props.theme.spacing[3]};
	padding-left: ${(props) => props.theme.spacing[3]};
	border-top: 1px solid ${(props) => props.theme.colors.border};
	margin-bottom: 0;

	:last-child {
		border-bottom: 1px solid ${(props) => props.theme.colors.border};
	}
`;

export const StyledName = styled.div`
	display: flex;
	flex: 1;
`;
