import React, { useState } from "react";
import Box from "@mui/material/Box";
import { emissionConsts } from "../../../constants/emissions";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
const { inviteeStatus } = emissionConsts.invitation;

export function useInvitedAsFilter() {
	const filterKey = "invitedAs";
	const [gridApi, setGridApi] = useState(null);
	const [_, setRenderDate] = useState(null);

	const toggle = (invitedAs) => {
		const instance = gridApi.getFilterInstance(filterKey);
		const model = instance.getModel();

		if (!model) {
			instance.setModel({ values: [invitedAs] });
		} else {
			const values = model.values.some((s) => s === invitedAs)
				? model.values.filter((s) => s !== invitedAs)
				: [...model.values, invitedAs];

			instance.setModel(values.length ? { values } : null);
		}

		gridApi.onFilterChanged();
		setRenderDate(new Date());
	};

	const selectAll = () => {
		const instance = gridApi.getFilterInstance(filterKey);
		instance.setModel(null);
		gridApi.onFilterChanged();
		setRenderDate(new Date());
	};

	const isSelected = (invitesAs) => {
		if (!gridApi) {
			return false;
		}
		const instance = gridApi.getFilterInstance(filterKey);

		const model = instance.getModel();

		if (!model) {
			return false;
		}

		return instance.getModel().values.some((p) => p === invitesAs);
	};

	const isAllSelected = () => {
		if (!gridApi) {
			return false;
		}
		const instance = gridApi.getFilterInstance("invitedAs");

		const model = instance.getModel();

		return !model;
	};

	return {
		setGridApi,
		renderFilterButtons: () => {
			const { t } = useTranslation();
			return (
				<Box sx={{ display: "flex", columnGap: 0.5, mb: 0.5 }}>
					<FilterButton
						label={t("emissions.filter.everything")}
						highlight={isAllSelected()}
						onFilterToggled={() => selectAll()}
					/>
					<FilterButton
						label={t("emissions.invitations.current-shareholders")}
						highlight={isSelected(inviteeStatus.currentShareholder)}
						onFilterToggled={() => toggle(inviteeStatus.currentShareholder)}
					/>
					<FilterButton
						label={t("emissions.invitations.own-investors")}
						highlight={isSelected(inviteeStatus.potentialShareholder)}
						onFilterToggled={() => toggle(inviteeStatus.potentialShareholder)}
					/>
					{/* <FilterButton
						label={t('emissions.invitations.dealflow-investors')}
						highlight={isSelected(inviteeStatus.dealFlow)}
						onFilterToggled={() => toggle(inviteeStatus.dealFlow)}
					/> */}
				</Box>
			);
		},
	};
}

const FilterButton = (props) => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				px: 3,
				py: 1,
				borderRadius: 1,
				cursor: "pointer",
				bgcolor: props.highlight ? "blue.100" : "white",
			}}
			onClick={() => props.onFilterToggled()}
		>
			<Typography
				variant={"subtitle2"}
				sx={{ fontWeight: props.highlight ? "400" : "normal" }}
			>
				{props.label}
			</Typography>
		</Box>
	);
};
