import { SET_PRIVATE_CUSTOMER } from "../actions/types";

const INITIAL_STATE = {
	customer: {
		userId: "",
		stripeCustomerId: "",
		stripeSubscriptions: [],
	},
};

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_PRIVATE_CUSTOMER:
			return {
				...state,
				customer: {
					...payload.customer,
				},
			};

		default:
			return state;
	}
}
