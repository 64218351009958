import React, { Component } from "react";
import { connect } from "react-redux";

import StepsModal from "../../../dumb-components/shared/modal/steps-modal";
import { TransparentButton } from "../../../dumb-components/shared/button-v2";
import PreviousLeftButton from "../../../dumb-components/shared/modal/previous-left-button";

import SharedCollectionsListContainer from "./shared-list.container";
import SharedCollectionOverviewContainer from "./shared-collection-overview.container";
import MergedCollectionOverviewContainer from "./merged-collection-overview.container";

import { closeModal } from "../../../actions/modals.actions";
import { selectSharedCollection } from "../../../actions/shared-collections.actions";
import {
	COLLECTION_TYPE_SHARED_DOCUMENTS,
	COLLECTION_TYPE_MERGED_DOCUMENTS,
} from "/shared/constants";

class ManagePostCollectionsModalContainer extends Component {
	state = {
		step: 0,
		extraMountedView: null,
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { selectSharedCollection } = this.props;
		const { step } = this.state;

		// Unmount the extra views when user goes back to first step
		if (step === 0 && step !== prevState.step) {
			setTimeout(() => {
				this.setState({ extraMountedView: null });
				selectSharedCollection();
			}, 600);
		}
	};

	closeModal = () => {
		const { closeModal } = this.props;
		closeModal();
	};

	handleOpenDetails = (collection) => {
		const type = collection.get("collectionType");

		this.setState({ extraMountedView: type });

		// Some miliseconds delay to let the HTML render/mount before sliding
		setTimeout(() => {
			this.setState({ step: 1 });
		}, 20);
	};

	goToPreviousStep = () => {
		this.setState({ step: this.state.step - 1 });
	};

	getModalTitle = () => {
		const { step } = this.state;

		switch (step) {
			default:
				return "collections.manage_shared.modal.collections.title";
		}
	};

	getSteps = () => {
		const { extraMountedView } = this.state;

		const steps = [
			{
				body: (
					<SharedCollectionsListContainer
						onOpenDetails={this.handleOpenDetails}
					/>
				),
				footer: {
					component: (
						<TransparentButton
							tid="generic.close"
							onClick={this.closeModal}
							textColor="midGrey"
						/>
					),
				},
			},
		];

		if (extraMountedView === COLLECTION_TYPE_SHARED_DOCUMENTS) {
			steps.push({
				body: <SharedCollectionOverviewContainer />,
				footer: {
					leftComponent: <PreviousLeftButton onClick={this.goToPreviousStep} />,
				},
			});
		} else if (extraMountedView === COLLECTION_TYPE_MERGED_DOCUMENTS) {
			steps.push({
				body: <MergedCollectionOverviewContainer />,
				footer: {
					leftComponent: <PreviousLeftButton onClick={this.goToPreviousStep} />,
				},
			});
		}

		return steps;
	};

	render = () => {
		const { step } = this.state;

		return (
			<StepsModal
				isOpen={true}
				steps={this.getSteps()}
				step={step}
				hSize="xl"
				title={this.getModalTitle()}
			/>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {
	closeModal,
	selectSharedCollection,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ManagePostCollectionsModalContainer);
