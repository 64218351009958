import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import NumberOfUsersToNotifyListItem from "../../../../../dumb-components/collections/shared/number-of-users-to-notify-list-item";

class NotifyAboutSignedProtocolContainer extends Component {
	render = () => {
		const { numberOfShareWithUsers } = this.props;
		return (
			<NumberOfUsersToNotifyListItem numberOfPeople={numberOfShareWithUsers} />
		);
	};
}

const mapStateToProps = (state) => {
	return {
		numberOfShareWithUsers: state.documents.getIn(
			["document", "shareWith"],
			Map(),
		).size,
	};
};

export default connect(mapStateToProps)(NotifyAboutSignedProtocolContainer);
