import React, { useState } from "react";
import { connect } from "react-redux";

import OnboardingModal from "../../../../../dumb-components/onboarding/modal";
import {
	FirstStepBody,
	SecondStepBody,
} from "../../../../../dumb-components/onboarding/modals/meetings/intro";
import FakeFooterSpacer from "../../../../../dumb-components/shared/modal/fake-footer-spacer";
import { ButtonTransparentIcon } from "../../../../../dumb-components/shared/button";
import HeaderControls from "../../header-controls.container";
import ButtonGoToBilling from "../shared/btn-go-to-billing.container";
import MeetingCreateButtonContainer from "../../../../meetings/meeting-create-button.container";
import Button from "../../../../../dumb-components/shared/button/button";
import { markOnboardAsRead } from "../../../../../actions/notifications.actions";
import { closeModal } from "../../../../../actions/modals.actions";
import useSubscriptionHelper from "@/hooks/useSubscriptionHelper";

const DocumentsIntroContainer = ({
	markOnboardAsRead,
	closeModal,
	notificationId,
	companyUrlAlias,
}) => {
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	const isPremiumAccount = subscriptionHelper?.premium;
	const [step, setStep] = useState(0);

	const markModalAsRead = () => {
		markOnboardAsRead(notificationId);
		closeModal();
	};

	const goToPageTwo = () => {
		setStep(1);
	};

	const onGoToBeginning = () => {
		setStep(0);
	};

	const renderCreateStandardMeetingButton = ({ createStandardMeeting }) => {
		return (
			<Button
				minWidth="100px"
				onClick={() => {
					createStandardMeeting();
					markModalAsRead();
				}}
				color="white"
				mode="primary"
				tid="onboarding.modals.meetings.intro.create.btn"
			/>
		);
	};

	const renderCreateSmartMeetingButton = ({ createSmartMeeting }) => {
		return (
			<Button
				minWidth="100px"
				onClick={() => {
					createSmartMeeting();
					markModalAsRead();
				}}
				color="white"
				mode="primary"
				tid="onboarding.modals.meetings.intro.create.btn"
			/>
		);
	};

	const getSteps = () => {
		return [
			{
				body: (
					<FirstStepBody
						createStandardMeetingButton={
							<MeetingCreateButtonContainer
								basePath={`/${companyUrlAlias}/meetings`}
								renderCustomComponent={renderCreateStandardMeetingButton}
							/>
						}
						createSmartMeetingButton={
							<MeetingCreateButtonContainer
								basePath={`/${companyUrlAlias}/meetings`}
								renderCustomComponent={renderCreateSmartMeetingButton}
							/>
						}
						showSmartMeetingInfo={goToPageTwo}
						isPremiumAccount={isPremiumAccount}
					/>
				),
				footer: {
					component: <FakeFooterSpacer height={36} />,
				},
			},
			{
				body: (
					<SecondStepBody
						billingButton={
							<ButtonGoToBilling
								onDone={markModalAsRead}
								tid="onboarding.modals.meetings.intro.smart.more.btn.upgrade"
							/>
						}
					/>
				),
				footer: {
					leftComponent: (
						<ButtonTransparentIcon
							onClick={onGoToBeginning}
							icon="faLongArrowLeft"
							size="xl"
						/>
					),
				},
			},
		];
	};

	return (
		<OnboardingModal
			title="TODO: Title"
			mode="steps"
			step={step}
			steps={getSteps()}
			dropdownComponent={<HeaderControls type="DROPDOWN" />}
		/>
	);
};

const mapStoreToProps = (store) => {
	return {
		notificationId: store.modals.getIn([
			"activeModal",
			"options",
			"notificationId",
		]),
		companyUrlAlias: store.company.company?.urlAlias,
	};
};

const mapActionsToProps = {
	markOnboardAsRead,
	closeModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(DocumentsIntroContainer);
