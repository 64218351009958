import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import { fetchMeeting, saveMeeting } from "../../../actions/meetings.actions";
import SplitView from "../../../dumb-components/shared/layout/split-view/split-view";
import ScrollView from "../../../dumb-components/shared/layout/scroll-view/scroll-view";
import ScrollViewHeader from "../../../dumb-components/shared/scroll-view-header/scroll-view-header";
import Box from "../../../dumb-components/shared/layout/box/box";
import ProtocolTabsContainer from "../protocol/protocol-tabs.container";
import ProtocolToolbarContainer from "../meeting/smart-meeting/protocol-toolbar.container";
import AlertContainer from "../protocol/alert.container";
import FeedbackPanelContainer from "./protocol-feedback-panel.container";

import ProtocolFeedbackDetailsContainer from "./protocol-feedback-details.container";

import { LIVE_MEETINGS_FEEDBACKEE_DONE } from "../../../constants/live-update";

import {
	calculateMaxWidthOfSplitView,
	toggleVisibilityOfTabs,
} from "../../../components/helpers/tabs.helper";

class ProtocolFeedbackContainer extends Component {
	// References to the scrollviews
	scrollViewRefs = {};

	// Reference to the tabs
	tabsRef;

	// Timer
	resizeEnd;

	state = {
		feedbackeesInitiated: false,
		hiddenTabs: [],
	};

	componentDidMount = () => {
		window.addEventListener("resize", this.onWindowResize, false);
		this.onWindowResize();
	};

	componentWillUnmount = () => {
		window.removeEventListener("resize", this.onWindowResize, false);
	};

	componentDidUpdate = () => {
		this.checkLiveUpdateEvents();
	};

	onWindowResize = () => {
		let { hiddenTabs } = this.state;
		const maxWidth = calculateMaxWidthOfSplitView(this.scrollViewRefs);
		hiddenTabs = toggleVisibilityOfTabs(this.tabsRef, hiddenTabs, { maxWidth });

		this.setState({ hiddenTabs });
	};

	checkLiveUpdateEvents = () => {
		const {
			audit,
			fetchMeeting,
			match: {
				params: { id },
			},
		} = this.props;
		const feedbackeeDone = audit.get(LIVE_MEETINGS_FEEDBACKEE_DONE, Map());

		if (feedbackeeDone.get("refresh") === true) {
			fetchMeeting(id);
		}
	};

	getComponentBasePath = () => {
		const {
			location: { pathname },
		} = this.props;
		const path = pathname;
		const pathArray = path.split("feedback");
		return pathArray[0] + "feedback";
	};

	onChange = (field, val, onChangeCallback) => {
		const { saveMeeting } = this.props;
		let { meeting } = this.props;

		meeting = meeting.setIn(field, val);
		saveMeeting(meeting, onChangeCallback);
	};

	renderLeftHeader = () => {
		const { location, meeting } = this.props;
		const { hiddenTabs } = this.state;

		return (
			<Box direction="column">
				<Box direction="row">
					<ProtocolTabsContainer
						location={location}
						match="feedback"
						onSetRef={(ref) => (this.tabsRef = ref)}
						hiddenTabs={hiddenTabs}
					/>
				</Box>

				<AlertContainer
					meetingIsLoaded={meeting && meeting.get("id") ? true : false}
					numOfMembers={
						meeting
							? meeting
									.get("feedbackees", Map())
									.filter((obj) => obj.get("active")).size
							: 0
					}
					membersNeedNotification={
						meeting &&
						meeting.getIn(["computedValues", "feedbackeesWarning"]) === true
					}
				/>
			</Box>
		);
	};

	renderLeftSplitView = () => {
		const {
			location,
			match: { params },
		} = this.props;
		const basePath = this.getComponentBasePath();

		return (
			<ScrollView
				header={this.renderLeftHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				onSetRef={(ref) => (this.scrollViewRefs["left"] = ref)}
			>
				<FeedbackPanelContainer
					basePath={basePath}
					params={params}
					querystr={location.search}
				/>
			</ScrollView>
		);
	};

	renderRightHeader = () => {
		const {
			match: { params },
			location,
		} = this.props;
		const basePath = this.getComponentBasePath();

		return (
			<ScrollViewHeader>
				<ProtocolToolbarContainer
					location={location}
					params={params}
					basePath={basePath.split("/feedback")[0]}
				/>
			</ScrollViewHeader>
		);
	};

	renderRightSplitView = () => {
		const { match } = this.props;

		return (
			<ScrollView
				header={this.renderRightHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				onSetRef={(ref) => (this.scrollViewRefs["right"] = ref)}
			>
				{match.params.feedbackeeId && (
					<ProtocolFeedbackDetailsContainer
						match={match}
						location={this.props.location}
						history={this.props.history}
					/>
				)}
			</ScrollView>
		);
	};

	render = () => {
		return (
			<SplitView
				leftComponent={this.renderLeftSplitView}
				rightComponent={this.renderRightSplitView}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get("meeting"),
		groups: store.groups.get("groups"),
		audit: store.audit.get("meetings"),
	};
};

const mapActionsToProps = {
	fetchMeeting,
	saveMeeting,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ProtocolFeedbackContainer);
