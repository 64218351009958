import React from "react";
import SplitView from "../../dumb-components/shared/layout/split-view/split-view";
import ScrollView from "../../dumb-components/shared/layout/scroll-view/scroll-view";
import PlansContainer from "./plans.container";
import CancelAccountContainer from "./cancel-account.container";
import PrepaidContainer from "./prepaid.container";
import NotificationDetailsPanel from "./notificationDetailsPanel";
import InfoAboutPotAfterSubModalContainer from "./info-about-pot-after-sub-modal.container";

import useSubscription from "@/hooks/useSubscription";
import useSubscriptionHelper from "@/hooks/useSubscriptionHelper";
import useSubscriptionPlans from "@/hooks/useSubscriptionPlans";
import useDocumentsHelper from "@/hooks/useDocumentsHelper";
import useCompanyIdFromUrl from "@/hooks/useCompanyIdFromUrl";
import LoadingPanel from "@/components/loadingPanel";
import PaymentDetailsPanel from "@/dumb-components/subscriptions/payment-details-panel/payment-details-panel";

function SubscriptionsContainer() {
	const companyId = useCompanyIdFromUrl();

	const { data: subscriptionRequest, isLoading: isFetchingSubscription } =
		useSubscription();

	const { data: subscriptionPlansRequest, isLoading: isFetchingPlans } =
		useSubscriptionPlans();

	const {
		data: subscriptionHelperRequest,
		isLoading: isFetchingSubscriptionHelper,
	} = useSubscriptionHelper();

	const { data: documentsHelperRequest, isLoading: isFetchingDocumentsHelper } =
		useDocumentsHelper();

	const subscription = subscriptionRequest?.data;
	const subscriptionPlans = subscriptionPlansRequest?.data;
	const subscriptionHelper = subscriptionHelperRequest?.data;
	const documentsHelper = documentsHelperRequest?.data;

	if (
		!companyId ||
		isFetchingDocumentsHelper ||
		isFetchingSubscription ||
		isFetchingPlans ||
		isFetchingSubscriptionHelper
	) {
		return <LoadingPanel />;
	}

	const renderLeftSplitView = () => {
		return (
			<div className="overflow-auto w-full">
				<PlansContainer
					subscription={subscription}
					plans={subscriptionPlans}
					prepaidAmount={subscriptionHelper.prepaidAmount ?? 0}
					documentsSpace={subscriptionHelper.documentsSpace}
					usedStorageSpace={documentsHelper.usedStorageSpace}
					isSharingFolders={documentsHelper.isSharingFolders}
				/>
				<PrepaidContainer subscription={subscription} />
				<CancelAccountContainer />
			</div>
		);
	};

	const renderRightSplitView = () => {
		const isFreeOfCharge = subscription?.isFreeOfCharge;

		if (isFreeOfCharge) return null;

		return (
			<ScrollView autoHide={true} showOnHover={true}>
				<PaymentDetailsPanel />
				<NotificationDetailsPanel />
			</ScrollView>
		);
	};

	return (
		<div className="flex flex-col h-full p-md">
			<SplitView
				leftComponent={renderLeftSplitView}
				rightComponent={renderRightSplitView}
			/>

			<InfoAboutPotAfterSubModalContainer />
		</div>
	);
}

export default SubscriptionsContainer;
