import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool } from "prop-types";
import { fromJS } from "immutable";
import req from "../../modules/request.module";
import { SelectAsync } from "../../dumb-components/shared/select";
import Text from "../../dumb-components/shared/text/text";
import Tooltip from "../../dumb-components/shared/tooltip/tooltip";

class SynaSearchContainer extends Component {
	autocompleteTimeout = null;
	onGoingRequest = false;
	reqCancelToken = req.CancelToken.source();

	static propTypes = {
		onChange: func,
		onSetRef: func,
		withPortal: bool,
		isLoading: bool,
		disabled: bool,
	};

	onChange = (orgNumber) => {
		const { onChange } = this.props;

		if (!orgNumber) {
			onChange(null);
			return null;
		}

		req
			.get(`/syna-integration/companies/${orgNumber}`)
			.then((response) => {
				if (
					response.data &&
					response.data.company &&
					response.data.representatives
				) {
					onChange(fromJS(response.data));
				} else {
					onChange(null);
				}
			})
			.catch(() => onChange(null));
	};

	search = (query, callback) => {
		const data = [];

		if (this.onGoingRequest) {
			this.reqCancelToken.cancel("Operation canceled by the user.");
			this.reqCancelToken = req.CancelToken.source();
		}

		this.onGoingRequest = true;

		req
			.get(`/syna-integration/companies?q=${query}`, {
				cancelToken: this.reqCancelToken.token,
			})
			.then((response) => {
				this.onGoingRequest = false;

				if (response.data && response.data.length > 0) {
					response.data.map((company) => {
						data.push({ value: company.orgNumber, label: company.name });
					});
				}

				callback(data);
			})
			.catch(() => {
				this.onGoingRequest = false;
				callback(data);
			});
	};

	loadOptions = (query, callback) => {
		if (this.autocompleteTimeout) {
			clearTimeout(this.autocompleteTimeout);
		}

		this.autocompleteTimeout = setTimeout(() => {
			if (query.trim().length < 3) {
				callback([]);
			} else {
				this.search(query, callback);
			}
		}, 300);
	};

	formatOrgNumber = (orgNumber) => {
		return orgNumber.substr(0, 6) + "-" + orgNumber.substr(6);
	};

	formatOptionLabel = (option) => {
		const formattedOrgNumber = this.formatOrgNumber(option.value);
		return (
			<Text singleLine>
				{option.label} |{" "}
				<Text singleLine color="muted">
					{formattedOrgNumber}
				</Text>
			</Text>
		);
	};

	render = () => {
		const { onSetRef, withPortal, isLoading, disabled } = this.props;

		return (
			<Tooltip activeState={disabled && "hasNoPermissions"}>
				<SelectAsync
					loadOptions={this.loadOptions}
					placeholderTid="services.syna_search.placeholder"
					isClearable={true}
					hideSelectedOptions={false}
					onChange={this.onChange}
					formatOptionLabel={this.formatOptionLabel}
					onSetRef={onSetRef}
					menuPortalTarget={withPortal ? document.body : undefined}
					isLoading={isLoading}
					isDisabled={disabled}
				/>
			</Tooltip>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {};

export default connect(mapStoreToProps, mapActionsToProps)(SynaSearchContainer);
