import React, { PureComponent } from "react";
import { string } from "prop-types";
import styled from "styled-components";
import immutablePropTypes from "react-immutable-proptypes";
import moment from "../../../../modules/moment.module";
import Text from "../../../shared/text/text";
import Panel from "../../../shared/panel/panel";

const StyledItemsContainer = styled.div`
	padding: ${(props) => props.theme.spacing[4]};
`;

const StyledItem = styled.div``;

const StyledItemTitle = styled.div`
	margin-bottom: ${(props) => props.theme.spacing[3]};
`;

const StyledItemBody = styled.div`
	margin-left: ${(props) => props.theme.spacing[4]};
`;

const StyledItemCommentContainer = styled.div`
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

const StyledItemCommentTitle = styled.div`
	display: flex;
	flex-direction: row;
`;

const StyledItemCommentName = styled.div`
	display: flex;
	flex: 1;
`;

const StyledItemCommentDate = styled.div``;

export default class ProtocolFeedbackDetails extends PureComponent {
	static propTypes = {
		meetingId: string,
		agendaItems: immutablePropTypes.list,
		conversations: immutablePropTypes.map,
		usersCache: immutablePropTypes.map,
		feedbackeeName: string,
	};

	renderComment = (comment, index) => {
		const { usersCache } = this.props;
		const commentator = usersCache.get(comment.get("createdBy"));
		const commentText = comment.get("text");

		return (
			<StyledItemCommentContainer key={index}>
				<StyledItemCommentTitle>
					<StyledItemCommentName>
						{commentator && (
							<Text color="lightGrey">{commentator.get("name")}</Text>
						)}
					</StyledItemCommentName>

					<StyledItemCommentDate>
						<Text color="lightGrey">
							{moment(comment.get("createdAt")).format("L LT")}
						</Text>
					</StyledItemCommentDate>
				</StyledItemCommentTitle>

				<Text tag="p">{commentText ? commentText.getPlainText() : ""}</Text>
			</StyledItemCommentContainer>
		);
	};

	renderAgendaItem = (item, index) => {
		const { conversations, meetingId } = this.props;
		const itemComments =
			conversations &&
			conversations.getIn([`${meetingId}$${item.get("id")}`, "comments"]);

		if (!itemComments || itemComments.size === 0) {
			return null;
		}

		return (
			<StyledItem key={index}>
				<StyledItemTitle>
					<Text>{`${item.get("num")}. ${item.get("proposal")}`}</Text>
				</StyledItemTitle>

				<StyledItemBody>
					{itemComments && itemComments.map(this.renderComment)}
				</StyledItemBody>
			</StyledItem>
		);
	};

	renderAgendaItems = () => {
		const { agendaItems } = this.props;

		return (
			<StyledItemsContainer>
				{agendaItems && agendaItems.map(this.renderAgendaItem)}
			</StyledItemsContainer>
		);
	};

	renderFeedbackeeName = () => {
		return (
			<Text tag="h4" color="catalinaBlue">
				{this.props.feedbackeeName}
			</Text>
		);
	};

	render = () => {
		return (
			<Panel leftComponent={this.renderFeedbackeeName}>
				{this.renderAgendaItems()}
			</Panel>
		);
	};
}
