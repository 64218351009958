import React, { Component } from "react";
import { connect } from "react-redux";
import { object } from "prop-types";

import Tooltip from "../../../../../dumb-components/shared/tooltip/tooltip";
import DropdownIconItem from "../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import MoveMeetingModalContainer from "../../move-meeting-modal.container";

import { openModal } from "../../../../../actions/modals.actions";
import { MEETING_MOVE_MEETING_MODAL } from "../../../../../constants/modals";
import { MEETING_TYPE_SMART } from "/shared/constants";

const TOOLTIP_STATES = {
	isDeleted: {
		tid: "meetings.general.toolbar.dropdown.move_to_group.tooltip.removed",
		delayShow: "instant",
	},
};

class MoveMeetingContainer extends Component {
	static propTypes = {
		dropdownRef: object,
	};

	getTooltipData = () => {
		const { hasExtendedRights, meetingIsDeleted } = this.props;
		let activeState;

		if (!hasExtendedRights) {
			activeState = "hasNoPermissions";
		}

		if (meetingIsDeleted) {
			activeState = "isDeleted";
		}

		return { activeState, isDisabled: Boolean(activeState) };
	};

	openModal = () => {
		const { dropdownRef, openModal } = this.props;

		dropdownRef && dropdownRef.onToggleMenu();

		openModal(MEETING_MOVE_MEETING_MODAL);
	};

	render = () => {
		const { isSmartMeeting } = this.props;
		const { activeState, isDisabled } = this.getTooltipData();

		return (
			<>
				<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
					<DropdownIconItem
						icon="faCodeBranch"
						tid="meetings.meeting.toolbar.item.move_meeting"
						onClick={this.openModal}
						disabled={isDisabled}
					/>
				</Tooltip>

				{isSmartMeeting && <MoveMeetingModalContainer />}
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		hasExtendedRights: store.meetings.getIn([
			"meeting",
			"computedValues",
			"hasExtendedRights",
		]),
		meetingIsDeleted: store.meetings.getIn(["meeting", "isDeleted"]),
		isSmartMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) === MEETING_TYPE_SMART,
	};
};

const mapActionsToProps = {
	openModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MoveMeetingContainer);
