import React, { Component } from "react";
import { connect } from "react-redux";
import { func } from "prop-types";

import SharedCollectionList from "../../../dumb-components/collections/shared-modal/shared-list";

import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";

import {
	listSharedCollections,
	selectSharedCollection,
} from "../../../actions/shared-collections.actions";

class SharedCollectionsListContainer extends Component {
	state = {
		isLoading: true,
	};

	static propTypes = {
		onOpenDetails: func.isRequired,
	};

	dropdownRefs = {};

	componentDidMount = () => {
		const { listSharedCollections } = this.props;

		listSharedCollections(() => {
			this.setState({ isLoading: false });
		});
	};

	setDropdownRef = (id, ref) => {
		this.dropdownRefs[id] = ref;
	};

	toggleDropdown = (id) => {
		this.dropdownRefs[id] && this.dropdownRefs[id].onToggleMenu();
	};

	openDetails = (collection) => {
		const { onOpenDetails, selectSharedCollection } = this.props;
		const id = collection.get("id");

		this.toggleDropdown(id);

		selectSharedCollection(collection);

		onOpenDetails(collection);
	};

	renderItemDropdown = (collection) => {
		return (
			<DropdownMenuContainer
				btnIcon="faEllipsisV"
				halignMenu="right"
				btnMode="transparent-icon"
				transparentIconButtonSize="sml"
				ref={this.setDropdownRef.bind(null, collection.get("id"))}
				withPortal
				inline
			>
				<DropdownIconItem
					tid="collections.manage_shared.modal.collections.dropdown.view_details"
					icon="faInfoCircle"
					onClick={this.openDetails.bind(null, collection)}
				/>
			</DropdownMenuContainer>
		);
	};

	render = () => {
		const { sharedCollections } = this.props;
		const { isLoading } = this.state;

		return (
			<SharedCollectionList
				sharedCollections={sharedCollections}
				renderItemDropdown={this.renderItemDropdown}
				isLoading={isLoading}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		sharedCollections: store.sharedCollections.get("sharedCollections"),
	};
};

const mapActionsToProps = {
	listSharedCollections,
	selectSharedCollection,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(SharedCollectionsListContainer);
