import React, { Component } from "react";
import { connect } from "react-redux";
import { List } from "immutable";
import { getUserName } from "../../../../components/helpers/users";

import ListPanelItem from "../../../../dumb-components/shared/list-panel/list-panel-item";
import MeetingRoleDisplay from "../../attendees/meeting-role-display.container";
import { ListPanelHeader } from "../../../../dumb-components/shared/list-panel/list-panel";

import { getHasMeetingRole } from "../../../../components/helpers/meeting.helper";
import {
	ATTENDEE_STATUS_PRESENT,
	ATTENDEE_STATUS_UNSURE,
	ATTENDEE_STATUS_ABSENT,
} from "/shared/constants";

import { fetchAndCacheInvestors } from "../../../../actions/investors.actions";

// Used in meeting general meeting view
class AttendeesSummaryListContainer extends Component {
	componentDidMount = () => {
		this.fetchInvestors();
	};

	fetchInvestors = () => {
		const { attendees, fetchAndCacheInvestors } = this.props;

		if (!attendees) {
			return;
		}

		const investmentIds = attendees
			.filter((a) => !a.isGuest)
			.map((a) => a.get("userId"));
		fetchAndCacheInvestors(investmentIds);
	};

	renderAttendeeItem = (attendee, index) => {
		const { usersCache, investors, secretary, chairman } = this.props;

		const haveResponded = attendee.get("haveResponded");
		const respondedAt = attendee.getIn([
			"metadata",
			"respondedAt",
			"respondedAt",
		]);
		const name = getUserName(attendee, investors, usersCache, true);
		const attendeeStatus = attendee.get("status");
		let status = null;

		if (attendeeStatus === ATTENDEE_STATUS_PRESENT) {
			status = true;
		} else if (attendeeStatus === ATTENDEE_STATUS_ABSENT) {
			status = false;
		} else if (attendeeStatus === ATTENDEE_STATUS_UNSURE) {
			status = ATTENDEE_STATUS_UNSURE;
		}

		const attendeeId = attendee.get("id");
		const roles = attendee.get("roles");
		const hasMeetingRole = getHasMeetingRole({
			attendeeId,
			roles,
			chairman,
			secretary,
		});

		return (
			<ListPanelItem
				name={name}
				status={status}
				date={haveResponded ? respondedAt : null}
				key={index}
				role={
					hasMeetingRole ? (
						<MeetingRoleDisplay
							roles={roles}
							attendeeId={attendeeId}
							secretary={secretary}
							chairman={chairman}
						/>
					) : undefined
				}
			/>
		);
	};

	render = () => {
		const { usersCache, attendees } = this.props;

		if (!usersCache || attendees.size === 0) {
			return null;
		}

		return (
			<>
				<ListPanelHeader columnTid="meetings.details.attendees.panel.responded" />
				{attendees.map(this.renderAttendeeItem).toList()}
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		attendees: store.meetings.getIn(["meeting", "attendees"], List()),
		usersCache: store.usersCache.get("usersCache"),
		investors: store.investors.get("investorsCache"),
		secretary: store.meetings.getIn(["meeting", "secretary"]),
		chairman: store.meetings.getIn(["meeting", "chairman"]),
		i18n: store.i18n,
	};
};

const mapActionsToProps = {
	fetchAndCacheInvestors,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(AttendeesSummaryListContainer);
