import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SendSettlementNotesModal from "./send-settlement-notes-modal";
import { PaymentFormComponent } from "./payment-components";
import { deleteSettlementNotesForClosure } from "../../../../actions/emissions.actions";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../../../actions/notify.actions";
import { useDispatch, useSelector } from "react-redux";

const EditPaymentInfoModal = (props) => {
	const emission = useSelector((state) => state.emissions.current);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [sendSettlementNotes, setSendSettlementNotes] = useState(false);
	const [validForm, setValidForm] = useState(false);

	const handleClick_ResetSettlementNotes = async () => {
		if (validForm) {
			dispatch(
				deleteSettlementNotesForClosure(
					emission.companyId,
					emission.id,
					props.closure.id,
				),
			);
			setSendSettlementNotes(true);
			await dispatch(
				addInfoNotification({
					text: t("emissions.payment.settlement-notes-created"),
				}),
			);
		} else {
			await dispatch(
				addErrorNotification({
					text: t(
						"emissions.payment.payment-create-settlement-notes-error.notification",
					),
				}),
			);
		}
	};

	return (
		<Dialog open={props.open} onClose={props.close}>
			<SendSettlementNotesModal
				closeAll={props.closeAll}
				open={sendSettlementNotes}
				close={() => setSendSettlementNotes(false)}
				onSubmit={() => {}}
			/>
			<DialogContent sx={{ px: 6, minWidth: "60rem", minHeight: "30rem" }}>
				<IconButton
					aria-label="close"
					onClick={props.close}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[600],
					}}
				>
					<CloseIcon />
				</IconButton>
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Typography
						component={"span"}
						variant={"body2"}
						sx={{ display: "flex", color: "grey.600", my: 2 }}
					>
						{t("emissions.payment.step2-1")}
					</Typography>
					<Typography sx={{ textAlign: "flex-start", pb: 2 }} variant="h4">
						{t("emissions.payment.edit-payment-info-header")}
					</Typography>
					<Typography sx={{ textAlign: "flex-start", pb: 4 }} variant="body2">
						{t("emissions.payment.edit-payment-info.desc")}
					</Typography>
					<Typography
						sx={{ textAlign: "flex-start", pb: 4 }}
						variant="subtitle1"
					>
						{t("emissions.subscription-form.payment-information")}
					</Typography>
					<PaymentFormComponent setValidForm={setValidForm} />
					<Box sx={{ display: "flex", mt: "48px", justifyContent: "flex-end" }}>
						<Button
							variant={"outlined"}
							sx={{
								fontWeight: "bold",
								alignSelf: "center",
								py: "1rem",
								mr: "8px",
							}}
						>
							<Typography variant={"h5"} onClick={props.close}>
								{t("dialog.generic.cancel")}
							</Typography>
						</Button>
						<Button
							variant={"contained"}
							sx={{ fontWeight: "bold", alignSelf: "center", ml: "8px" }}
							onClick={() => handleClick_ResetSettlementNotes()}
						>
							<Typography variant={"h5"}>
								{t("emissions.payment.save-and-create-new-button")}
							</Typography>
						</Button>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default EditPaymentInfoModal;
