import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { Map } from "immutable";
import { formatSsnPretty } from "../../../helpers/users";

class IssueShareCertificates extends Component {
	getShareTypeName = (type) => {
		const { i18n } = this.props;
		const [shareClass, shareType] = type.get("type").split("$");
		return (
			(i18n.messages[`share_class.${shareClass}`] ||
				decodeURIComponent(shareClass)) +
			" " +
			(i18n.messages[`share_type.${shareType}`] ||
				decodeURIComponent(shareType))
		);
	};

	renderShareType = (shareType, index) => {
		const name = this.getShareTypeName(shareType);
		const numOfShares = shareType.get("shares");

		return (
			<div className="list__item" key={index}>
				<div className="list__item__body">
					<span className="list__item__text">{name}</span>
				</div>
				<div className="list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right">
					<span className="list__item__text">{numOfShares}</span>
				</div>
			</div>
		);
	};

	render = () => {
		const { tmpTransaction, investors, transaction } = this.props;
		let investmentId;
		let investor = Map({});
		let shareTypes;

		if (tmpTransaction) {
			investmentId = tmpTransaction.getIn(["handlerData", "investorId"]);
		}

		if (investmentId) {
			if (investors) {
				investor = investors.find((inv) => {
					return inv.get("id") === investmentId;
				});
			}

			shareTypes = transaction.getIn(["balances", investmentId, "types"]);
		}

		if (!investor) {
			investor = Map({});
		}

		return (
			<div className="i-panel i-panel--white">
				<div className="i-panel__body i-panel__body i-panel__body--pad-hor">
					<div className="i-panel__heading--transaction-accordio">
						{investmentId && (
							<h2 className="i-panel__title">
								{investor.getIn(["investorInformation", "name"])}{" "}
								<span className="text--muted">
									{formatSsnPretty(
										investor.getIn(["investorInformation", "id"]),
									)}
								</span>
							</h2>
						)}
						{!investmentId && (
							<h2 className="i-panel__title">
								<FormattedMessage id="none_selected" />
							</h2>
						)}
					</div>
					<div className="i-panel__body i-panel__body i-panel__body--pad-btm-0">
						<div className="list__list-header">
							<div className="list__list-header__body list__list-header__text-area--pad-lft list__list-header__text-area--pad-right">
								<FormattedMessage id="shares.class_of_shares" />
							</div>
							<div className="list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right">
								<FormattedMessage id="shares.transactions.issue_share_certificates.view.shares" />
							</div>
						</div>
						<div className="list list--table list--striped">
							{shareTypes && shareTypes.map(this.renderShareType.bind(this))}
							{!shareTypes && (
								<div className="list__item">
									<div className="list__item__body">&nbsp;</div>
								</div>
							)}
						</div>
					</div>
					<FormattedHTMLMessage id="issue_share_certificates.view-information" />
				</div>
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get("transaction"),
		tmpTransaction: state.transaction.getIn([
			"tmpTransaction",
			"CHANGE-SHARE-LETTER",
		]),
		investors: state.investors.get("list"),
		i18n: state.i18n,
	};
}

const mapActionsToProps = {};

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(IssueShareCertificates);
