import React, { Component } from "react";
import { connect } from "react-redux";
import { object, bool, string } from "prop-types";
import { Map } from "immutable";

import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";
import history from "../../../interfaces/history";

import { deleteTask, deleteExternalTask } from "../../../actions/tasks.actions";
import { updateMeetingLocal } from "../../../actions/meetings.actions";

class MeetingsTaskItemDropdownContainer extends Component {
	static propTypes = {
		location: object,
		isExternal: bool,
		basePath: string,
		taskId: string.isRequired,
	};

	dropdownRef = null;

	deleteCallback = () => {
		const {
			basePath,
			location: { search },
			meeting,
			updateMeetingLocal,
			history,
		} = this.props;

		history.push({ pathname: basePath, search });
		updateMeetingLocal(
			meeting.updateIn(
				["computedValues", "numOfTasks"],
				(numOfTasks) => --numOfTasks,
			),
		);
	};

	doDeleteTask = () => {
		const { deleteTask, deleteExternalTask, taskId, creatorObjId, isExternal } =
			this.props;

		this.dropdownRef && this.dropdownRef.onToggleMenu();

		if (isExternal) {
			deleteExternalTask(creatorObjId, taskId, this.deleteCallback);
		} else {
			deleteTask(taskId, this.deleteCallback);
		}
	};

	render = () => {
		const { hasExtendedRights } = this.props;
		const isDisabled = !hasExtendedRights;
		let activeState;

		if (isDisabled) {
			activeState = "hasNoPermissions";
		}

		return (
			<DropdownMenuContainer
				btnIcon="faEllipsisV"
				halignMenu="right"
				transparentIconButtonSize="sml"
				btnMode="transparent-icon"
				ref={(r) => (this.dropdownRef = r)}
				withPortal
			>
				<Tooltip activeState={activeState} active={isDisabled}>
					<DropdownIconItem
						tid="tasks.delete_task"
						icon="faTrashAlt"
						disabled={isDisabled}
						onClick={this.doDeleteTask}
					/>
				</Tooltip>
			</DropdownMenuContainer>
		);
	};
}

const mapStoreToProps = (store, ownProps) => {
	return {
		history: history,
		hasExtendedRights: store.meetings.getIn([
			"meeting",
			"computedValues",
			"hasExtendedRights",
		]),
		creatorObjId: store.tasks.getIn([
			"listByObjects",
			"tasksMap",
			ownProps.taskId,
			"creatorObjId",
		]),
		meeting: store.meetings.get("meeting", Map()),
	};
};

const mapActionsToProps = {
	deleteTask,
	deleteExternalTask,
	updateMeetingLocal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingsTaskItemDropdownContainer);
