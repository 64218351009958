import React from "react";

import Badge from "../../shared/badge/badge-new";
import i18nhelper from "../../../../app/components/helpers/i18n.helper";
import { useSelector } from "react-redux";
import history, { getQuery } from "../../../../app/interfaces/history";

const FolderNameRenderer = ({ props }) => {
	const query = getQuery();
	const { isSharedFromCompanyId } = query;
	const isWithinSharedFolder = typeof isSharedFromCompanyId !== "undefined";
	const i18n = useSelector((state) => state.i18n);
	const company = useSelector((state) => state.company.company);
	const { id, folderId, metadata, basePath, title } = props.data;
	const { folderName, folderPath } = metadata || {};

	const renderFolderPath = () => {
		if (!folderPath) {
			return;
		}
		return folderPath
			.map((obj) =>
				i18nhelper.getTranslatedValue(obj, i18n.language, company.region),
			)
			.reverse()
			.join(" / ");
	};

	const onBadgeClick = (documentId, folderId) => {
		history.push({
			pathname: basePath + `/${documentId}`,
			search: `?folder=${folderId}${
				isWithinSharedFolder
					? `&isSharedFromCompanyId=${isSharedFromCompanyId}`
					: ""
			}`,
		});
	};

	return (
		<>
			{title}
			<Badge
				expandedText={renderFolderPath()}
				onClick={onBadgeClick.bind(this, id, folderId)}
				expandedTextInlinePadding={4}
			>
				{i18nhelper.getTranslatedValue(
					folderName,
					i18n.language,
					company.region,
				)}
			</Badge>
		</>
	);
};

export default FolderNameRenderer;
