import React from "react";
import { bool, func, number } from "prop-types";
import styled, { css } from "styled-components";
import { rgba } from "polished";

const StyledItem = styled.li`
	display: flex;
	flex-direction: column;
	background-color: ${(props) => props.theme.colors.white};
	list-style: none;
`;

const StyledContent = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	min-height: 45px;
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeDark};
	padding-left: ${(props) => props.level * 16 + "px"};

	:hover {
		background-color: ${(props) => rgba(props.theme.colors.solitudeMid, 0.25)};
	}

	${(props) =>
		props.selected &&
		css`
			background-color: ${(props) => props.theme.colors.solitudeMid};
			cursor: default;

			:hover {
				background-color: ${(props) => props.theme.colors.solitudeMid};
			}
		`}
`;

const StyledChildrenWrapper = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	display: ${(props) => (props.isOpen ? "flex" : "none")};
	flex-direction: column;
`;

const TreeMenuItem = ({
	children,
	contentComponent,
	isOpen,
	level,
	onClick,
	onRef,
}) => {
	return (
		<StyledItem ref={onRef && onRef}>
			{contentComponent && (
				<StyledContent level={level} onClick={onClick}>
					{contentComponent()}
				</StyledContent>
			)}
			{children && (
				<StyledChildrenWrapper level={level} isOpen={isOpen}>
					{children}
				</StyledChildrenWrapper>
			)}
		</StyledItem>
	);
};

TreeMenuItem.defaultProps = {
	isOpen: false,
	level: 0,
};

TreeMenuItem.propTypes = {
	level: number,
	isOpen: bool,
	contentComponent: func,
	onClick: func,
};

export default TreeMenuItem;
