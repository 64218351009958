import React from "react";
import { Form } from "react-final-form";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TextField } from "mui-rff";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";

const EmissionNameModal = (props) => {
	const { t } = useTranslation();
	const initialValues = props.initialValues || { name: "" };
	return (
		<Dialog open={props.open} onClose={props.close}>
			<Form
				onSubmit={props.onSubmit}
				initialValues={initialValues}
				keepDirtyOnReinitialize={true}
			>
				{(formProps) => {
					return (
						<form onSubmit={formProps.handleSubmit}>
							<DialogContent sx={{ px: 6, py: 4 }}>
								<IconButton
									aria-label="close"
									onClick={props.close}
									sx={{
										position: "absolute",
										right: 8,
										top: 8,
										color: (theme) => theme.palette.grey[600],
									}}
								>
									<CloseIcon />
								</IconButton>
								<Box sx={{ display: "flex", flexDirection: "column" }}>
									<Typography sx={{ textAlign: "center", pb: 2 }} variant="h4">
										{props.title}
									</Typography>
									<Box sx={{ textAlign: "center" }}>
										{t("emissions.rename-emission.desc")}
									</Box>
									<TextField
										sx={(theme) => ({
											my: 6,
											minWidth: theme.spacing(48),
										})}
										autoFocus
										name="name"
										margin="dense"
										label={t("emissions.column.name")}
										fullWidth
									/>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
										}}
									>
										<Button variant="contained" size="large" type="submit">
											<Typography variant={"h5"}>{props.buttonText}</Typography>
										</Button>
									</Box>
								</Box>
							</DialogContent>
						</form>
					);
				}}
			</Form>
		</Dialog>
	);
};

export default EmissionNameModal;
