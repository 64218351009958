import React, { PureComponent } from "react";
import { func, string, bool, object } from "prop-types";
import { map } from "react-immutable-proptypes";
import styled, { css } from "styled-components";
import { FormattedNumber } from "react-intl";
import { Map } from "immutable";
import DocumentsHelper from "../../../components/helpers/documents.helper";

import FileIcon from "../file-icon/file-icon";
import Text from "../text/text";
import Tooltip from "../tooltip/tooltip";
import NotificationDot from "../notification-badge/notification-badge";
import { OutlinedButton } from "../button-v2";
import Icon from "../icon/icon";

const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	flex: 1;

	${({ isDragDisabled }) =>
		!isDragDisabled &&
		css`
			cursor: move !important;
		`}
`;

const StyledDraghandlersWrapper = styled.div`
	position: absolute;
	height: 100%;
	z-index: 1;
	align-items: center;
	left: 0;
	justify-content: flex-end;
	display: flex;
	visibility: hidden;

	${Wrapper}:hover & {
		cursor: ${(props) =>
			!props.isDragDisabled ? "move !important" : "default"};
		visibility: visible;
	}
`;

const StyledDragHandlers = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	background: ${(props) => props.theme.colors.java};
	z-index: 2;
`;

const FileIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 36px;
	min-width: 36px;
	align-items: center;
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	margin: 0 ${(props) => props.theme.spacing[4]};
	min-width: 0;
`;

const ControlsWrapper = styled.div`
	display: flex;

	> * {
		margin-right: ${(props) => props.theme.spacing[2]};
	}

	> * :last-child {
		margin-right: 0;
	}
`;

export default class ModernFilePreview extends PureComponent {
	static propTypes = {
		document: map,
		fileReference: map,
		onOpenFile: func,
		onDownloadFile: func,
		dropdownRenderer: func,
		fileIconTooltipTid: string,
		fileIconDot: bool,
		fileIconDotColor: string,
		dragHandleProps: object,
		isDragDisabled: bool,
		showFileName: bool,
		color: string,
	};

	static defaultProps = {
		document: Map(),
		isDragDisabled: true,
		showFileName: true,
		color: "white",
	};

	openFile = () => {
		const { document, onOpenFile } = this.props;
		const documentId = this.getDocumentId();
		const fileReference = this.getFileReference();
		onOpenFile && onOpenFile({ document, documentId, fileReference });
	};

	downloadFile = () => {
		const { document, onDownloadFile } = this.props;
		const documentId = this.getDocumentId();
		const fileReference = this.getFileReference();
		onDownloadFile && onDownloadFile({ document, documentId, fileReference });
	};

	getDocumentId = () => {
		const { document } = this.props;
		return document.get("id");
	};

	getFileReference = () => {
		const { document, fileReference } = this.props;
		return fileReference ? fileReference : document.get("file");
	};

	getFileSizeInfo = () => {
		const fileReference = this.getFileReference();
		const { value: fileSize, unit: fileSizeUnit } =
			DocumentsHelper.getFileSize(fileReference);
		return { fileSize, fileSizeUnit };
	};

	renderOpenFileButton = () => {
		const { onOpenFile } = this.props;
		const fileReference = this.getFileReference();
		const ext = fileReference.get("ext");
		const size = fileReference.get("size");

		if (!onOpenFile) {
			return null;
		}

		const openableInBrowser = DocumentsHelper.isViewableInBrowser(
			fileReference,
			ext,
			size,
		);

		if (!openableInBrowser) {
			return null;
		}

		return (
			<Tooltip tid="generic.open">
				<OutlinedButton icon="faExpandArrowsAlt" onClick={this.openFile} />
			</Tooltip>
		);
	};

	renderFileIcon = () => {
		const { fileIconTooltipTid, fileIconDot, fileIconDotColor, onOpenFile } =
			this.props;
		const fileReference = this.getFileReference();
		const ext = fileReference.get("ext");

		if (fileIconTooltipTid && fileIconDot && fileIconDotColor) {
			return (
				<FileIconWrapper>
					<Tooltip tid={fileIconTooltipTid} delayShow="short">
						<NotificationDot
							active={fileIconDot}
							color={fileIconDotColor}
							offsetBgColor="white"
							x={24}
							y={-6}
						>
							<FileIcon ext={ext} />
						</NotificationDot>
					</Tooltip>
				</FileIconWrapper>
			);
		}

		return (
			<FileIconWrapper role="button" onClick={onOpenFile}>
				<FileIcon ext={ext} />
			</FileIconWrapper>
		);
	};

	render = () => {
		const {
			document,
			onDownloadFile,
			dropdownRenderer,
			className,
			isDragDisabled,
			dragHandleProps,
			showFileName,
			color,
		} = this.props;
		const fileReference = this.getFileReference();

		if (!fileReference) {
			return null;
		}

		const documentId = this.getDocumentId();
		const { fileSize, fileSizeUnit } = this.getFileSizeInfo();
		const fileName = fileReference.get("originalname");

		return (
			<Wrapper
				className={className}
				isDragDisabled={isDragDisabled}
				{...(dragHandleProps && dragHandleProps)}
			>
				{!isDragDisabled && (
					<StyledDraghandlersWrapper>
						<StyledDragHandlers>
							<Icon icon="faArrowsAlt" type="solid" size="10" color="white" />
						</StyledDragHandlers>
					</StyledDraghandlersWrapper>
				)}

				{this.renderFileIcon()}

				<InfoWrapper>
					{showFileName && (
						<Text color={color} singleLine>
							{fileName}
						</Text>
					)}

					<Text
						color={color}
						tid="documents.generic.filesize.with_units"
						singleLine
						values={{
							fileSize: <FormattedNumber value={fileSize} format="filesize" />,
							fileSizeUnit,
						}}
					/>
				</InfoWrapper>

				<ControlsWrapper>
					{this.renderOpenFileButton()}

					{onDownloadFile && (
						<Tooltip tid="documents.download">
							<OutlinedButton
								icon="faArrowAltToBottom"
								onClick={this.downloadFile}
							/>
						</Tooltip>
					)}

					{dropdownRenderer &&
						dropdownRenderer({ document, fileReference, documentId })}
				</ControlsWrapper>
			</Wrapper>
		);
	};
}
