import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentsUpload from "../../dumb-components/documents/documents-upload/documents-upload";
import { createDocument } from "../../actions/documents.actions";
import { createSharedDocument } from "../../actions/documents-sharing.actions";
import moment from "../../modules/moment.module";
import { setGlobalRef } from "../../components/helpers/global-refs.helper";
import { REF_DOCUMENTS_UPLOAD_DROPZONE } from "../../constants/global-refs";
import history from "../../interfaces/history";
import useDialogModal from "@/hooks/useDialogModal";
import DocumentsFolderPicker from "@/components/documents/documentsFolderPicker";
import withResolvedProps from "@/hocs/withResolvedProps";
import FolderDetailsContainer from "./folder-details.container";
import { fetchDefaultPermissions } from "@/actions/folders.actions";
import Alert from "@/components/alert";
import i18n from "@/i18n";
import { Button } from "@/components/ui/button";
import { Folder } from "lucide-react";

const { t } = i18n;

class Documents extends Component {
	state = {
		isOpenDocumensUpload: false,
		createFolderModalElement: null,
	};

	componentDidUpdate = (prevProps) => {
		const { fileEnteredWebsiteGlobal } = this.props;

		if (fileEnteredWebsiteGlobal !== prevProps.fileEnteredWebsiteGlobal) {
			this.setState({ isOpenDocumensUpload: fileEnteredWebsiteGlobal });
		}
	};

	onClickBtnOpen = () => {
		this.dropdownRef?.open?.();
	};

	setDropdownRef = (ref) => {
		this.dropdownRef = ref;
		setGlobalRef(ref, REF_DOCUMENTS_UPLOAD_DROPZONE);
	};

	uploadFiles = async (files) => {
		const {
			createDocument,
			folderId,
			folders,
			basePath,
			isSharedFromCompanyId,
			createSharedDocument,
			history,
			prompt,
			fetchDefaultPermissions,
		} = this.props;

		if (!files || files.length < 1) {
			return;
		}

		let pickedFolder, parentFolderId;
		let selectedFolderId = folderId;

		// If in root folder, force the user to create or select a folder before uploading.
		const existingFolderIds = Object.keys(folders.toJS());
		const hasOnlyRootFolder =
			existingFolderIds.length === 1 && existingFolderIds[0] === "root";

		const promptCreateFolder = async (parentFolderId) => {
			await fetchDefaultPermissions();

			return await new Promise((resolve) =>
				this.setState({
					createFolderModalElement: (
						<FolderDetailsContainer
							isOpen
							parentFolderId={parentFolderId}
							userMessage={
								<Alert
									title={t("documents.upload.folder_required.title")}
									message={t("documents.upload.folder_required_create.message")}
									className="mb-4"
								/>
							}
							mode="create"
							basePath={basePath}
							onClose={() => this.setState({ createFolderModalElement: null })}
							onSubmit={resolve}
						/>
					),
				}),
			);
		};

		if (!folderId) {
			if (hasOnlyRootFolder) {
				selectedFolderId = await promptCreateFolder();
			} else {
				pickedFolder = await prompt(
					t("documents.upload.folder_required.title"),
					(props, setSubmitValue) => (
						<>
							<p>{t("documents.upload.folder_required_select.message")}</p>
							<DocumentsFolderPicker onChange={setSubmitValue} />
						</>
					),
					{
						size: "md",
						submitText: t("upload_files"),
						submitDisabled: (submitValue) => !submitValue,
						footerRenderer: (props, submitValue) => (
							<div className="flex justify-between w-full">
								<Button
									variant="secondary"
									onClick={async () => {
										parentFolderId = submitValue;
										props.onSubmit("CREATE_FOLDER");
									}}
								>
									{<Folder size={16} />}
									{t("documents.create.title")}
								</Button>
								<div className="flex gap-2">
									{props.cancelButtonRenderer(props, submitValue)}
									{props.submitButtonRenderer(props, submitValue)}
								</div>
							</div>
						),
					},
				);

				if (pickedFolder === "CREATE_FOLDER") {
					selectedFolderId = await promptCreateFolder(parentFolderId);
				} else {
					if (!pickedFolder) return;
					selectedFolderId = pickedFolder;
				}
			}
		}

		const callbacks = {
			onComplete: (doc) => {
				const folderQuery = selectedFolderId
					? `?folder=${selectedFolderId}`
					: "";

				if (files.length === 1) {
					history.push(`${basePath}/${doc.get("id")}${folderQuery}`);
				} else {
					history.push(`${basePath}/${folderQuery}`);
				}
			},
		};

		files.forEach((file) => {
			const document = new FormData();
			const props = {
				file,
				documentType: "file",
				folderId: selectedFolderId,
				lastModifiedDate: moment(file.lastModified).toISOString(),
				title: file.name,
			};

			Object.keys(props).forEach((key) => {
				if (Array.isArray(props[key])) {
					for (let i = 0; i < props[key].length; i++) {
						document.append(key, props[key][i]);
					}
				} else {
					document.append(key, props[key]);
				}
			});

			if (isSharedFromCompanyId) {
				createSharedDocument(
					document,
					folderId,
					isSharedFromCompanyId,
					callbacks,
				);
			} else {
				createDocument(document, folderId, callbacks);
			}
		});

		// Close on document upload
		this.setState({ isOpenDocumensUpload: false });
	};

	render = () => {
		const {
			folders,
			isSharedFromCompanyId,
			sharedByCompanyName,
			dialogElement,
		} = this.props;
		let { folderId } = this.props;
		const { isOpenDocumensUpload, createFolderModalElement } = this.state;

		if (!folderId) {
			folderId = "root";
		}

		const hasPermissionsToUpload =
			folders && folders.getIn([folderId, "ALLOW_CREATE_DOCUMENT"]);
		const isRootSharedFolder = folderId === isSharedFromCompanyId;

		return (
			<>
				{createFolderModalElement}
				{dialogElement}
				<DocumentsUpload
					isOpen={isOpenDocumensUpload}
					hasPermissionsToUpload={hasPermissionsToUpload}
					isSharedFolder={Boolean(isSharedFromCompanyId)}
					sharedByCompanyName={sharedByCompanyName}
					isRootSharedFolder={isRootSharedFolder}
					onDrop={this.uploadFiles}
					onClickBtnOpen={this.onClickBtnOpen}
					dropzoneRef={this.setDropdownRef}
					multiple
				/>
			</>
		);
	};
}

function mapStoreToProps(store, ownProps) {
	return {
		history: history,
		fileEnteredWebsiteGlobal: store.notify.fileEnteredWebsiteGlobal,
		filterBy: store.documents.get("filterBy"),
		folderId: store.folders.get("selectedFolderId"),
		folders: ownProps.isSharedFromCompanyId
			? store.folders.getIn(["sharedFolders", "folders"])
			: store.folders.get("folders"),
		sharedByCompanyName: store.folders.getIn([
			"folders",
			ownProps.isSharedFromCompanyId,
			"translatedName",
		]),
	};
}

const mapActionsToProps = {
	createDocument,
	createSharedDocument,
	fetchDefaultPermissions,
};

const ConnectedDocumentsUploadContainer = connect(
	mapStoreToProps,
	mapActionsToProps,
)(Documents);

export default withResolvedProps(() => {
	const { prompt, dialogElement } = useDialogModal();

	return { prompt, dialogElement };
})(ConnectedDocumentsUploadContainer);
