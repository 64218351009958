import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { css } from "styled-components";

const hideInfiniteScrollbars = css`
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}

	overflow: visible !important;
`;

function generateRandomString(length = 6) {
	const ALPHABET = "abcdefghijklmnopqrstuvwxyz1234567890";

	let rtn = "";
	for (let i = 0; i < length; i++) {
		rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
	}
	return rtn;
}

const generateScrollableTarget = () => {
	const scrollableTarget = `infinite-scroll${generateRandomString(6)}`;
	const renderScrollableElement = (props) => (
		<div {...props} id={scrollableTarget} />
	);

	return { scrollableTarget, renderScrollableElement };
};

export { InfiniteScroll, generateScrollableTarget, hideInfiniteScrollbars };
