import { List } from "immutable";

const getShareTypesPath = (transaction) => {
	if (transaction.has("balance")) {
		return ["balance", "types"];
	}

	return ["shareData", "types"];
};
export const getUniqueTransactionShareTypes = (transactions) => {
	if (!transactions) {
		return;
	}

	const shareTypes = transactions.map((transaction) =>
		transaction
			.getIn(getShareTypesPath(transaction))
			.map((type) => type.get("type")),
	);
	const merged = List().concat(...shareTypes);
	const uniqueShareTypes = merged.toSet();

	return uniqueShareTypes;
};
