import React, { PureComponent } from "react";
import { string, bool, object, func } from "prop-types";
import styled from "styled-components";
import { darken } from "polished";

import ButtonBase from "./button-base";

const StyledOutlinedButton = styled(ButtonBase)`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 36px;
	border: 1px solid ${(props) => props.theme.colors[props.borderColor]};
	transition:
		300ms color,
		300ms border;

	* {
		transition:
			300ms color,
			300ms border;
		color: ${(props) => props.theme.colors[props.color]};
	}

	:focus {
		border-radius: 1px;
		outline-color: ${(props) => props.theme.colors[props.color]} !important;
		outline-style: solid !important;
		outline-width: 2px !important;
		outline-offset: 2px !important;
	}

	:disabled {
		border: 1px solid ${(props) => props.theme.colors.muted};

		* {
			color: ${(props) => props.theme.colors.muted};
		}
	}

	:hover:not(:disabled) {
		border: 1px solid
			${(props) => darken(0.15, props.theme.colors[props.borderColor])};

		* {
			color: ${(props) => darken(0.15, props.theme.colors[props.color])};
		}
	}

	:active:focus {
		transition:
			100ms color,
			100ms border;
		border: 1px solid
			${(props) => darken(0.25, props.theme.colors[props.borderColor])};

		* {
			color: ${(props) => darken(0.25, props.theme.colors[props.color])};
		}
	}
`;

export default class OutlinedBaseButton extends PureComponent {
	static propTypes = {
		disabled: bool,
		color: string,
		borderColor: string,
		children: object,
		onClick: func,
		fullWidth: bool,
	};

	static defaultProps = {
		color: "white",
	};

	render = () => {
		const { children, color, borderColor, ...rest } = this.props;
		const btnProps = {
			color,
			borderColor: borderColor || color,
		};

		return (
			<StyledOutlinedButton {...btnProps} {...rest}>
				{children}
			</StyledOutlinedButton>
		);
	};
}
