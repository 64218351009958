import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Map } from "immutable";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-final-form";
import isEqual from "lodash/isEqual";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { updateDefaultFilters } from "../../../actions/documents.actions";
import { updateUserFilters } from "../../../actions/user.actions";
import { invonoDefault } from "./advanced-filter-constants";

const StyledLink = styled("a")(({ theme }) => ({
	color: theme.palette.positive.main,
	...theme.typography.link,
	"&:hover": {
		textDecoration: "underline",
		color: theme.palette.positive.hover,
	},
}));

const TYPE_OF_FILTER_FACTORY_DEFAULT = "FACTORY_DEFAULT";
const TYPE_OF_FILTER_MY_CUSTOM_SETUP = "MY_CUSTOM_SETUP";

const getPinnedFiltersForContext = (pinnedFilters, companyId, context) => {
	if (pinnedFilters && pinnedFilters.size > 0) {
		let [...pinnedFilterKeys] = pinnedFilters.keys();

		pinnedFilterKeys = pinnedFilterKeys.filter((key) =>
			key.includes(`${companyId}-${context}`),
		);

		let _pinnedFilters = Map();

		if (pinnedFilterKeys.length > 0) {
			pinnedFilterKeys.forEach((key) => {
				const field = key.split("$")[1];
				_pinnedFilters = _pinnedFilters.set(field, pinnedFilters.get(key));
			});
		}

		return _pinnedFilters;
	}

	return Map();
};

export const AdvancedFilterStoreCustoms = () => {
	const { t } = useTranslation();
	const form = useForm();
	const dispatch = useDispatch();
	const defaultFilters = useSelector((state) =>
		state.documents.get("defaultFilters"),
	);
	const pinnedFilters = useSelector((store) =>
		store.user.getIn(["userObj", "siteSettings", "pinedFilters"]),
	);
	const companyId = useSelector((store) => store.company.company.id);
	const documentsPinnedFilters = getPinnedFiltersForContext(
		pinnedFilters,
		companyId,
		"documents",
	);

	const getTypeOfFiltersInUse = () => {
		if (documentsPinnedFilters.size > 0) {
			return TYPE_OF_FILTER_MY_CUSTOM_SETUP;
		}

		return TYPE_OF_FILTER_FACTORY_DEFAULT;
	};

	const areFormValuesEqualToOriginalSetup = () => {
		const formValues = form.getState().values;

		if (Object.keys(formValues).length === 0) {
			return true; // need to set this to true so that the renderingen is not flashed
		}

		const flattenDefaultFilters = defaultFilters.map((value) => value.get(0));
		return isEqual(formValues, flattenDefaultFilters.toJS());
	};

	const resetToDefaultFilters = (event) => {
		event.preventDefault();

		form.setConfig("keepDirtyOnReinitialize", false);
		form.restart({});
		form.setConfig("keepDirtyOnReinitialize", true);

		defaultFilters.forEach((value, key) => {
			form.change(key, value.get(0));
		});
	};

	const storeCustomDefault = (event) => {
		event.preventDefault();

		const values = form.getState().values;
		const newDefaultFilters = { ...values };

		dispatch(updateDefaultFilters(newDefaultFilters));
		dispatch(updateUserFilters(values, "documents"));
	};

	const restoreInvonoDefault = (event) => {
		event.preventDefault();

		form.setConfig("keepDirtyOnReinitialize", false);
		form.restart({});
		form.setConfig("keepDirtyOnReinitialize", true);

		Object.entries(invonoDefault).forEach(([key, value]) => {
			form.change(key, value);
		});

		dispatch(updateDefaultFilters(invonoDefault));
		dispatch(updateUserFilters({}, "documents"));
	};

	const renderInvonoDefaultsInfoPanel = () => {
		const formValuesChanged = !areFormValuesEqualToOriginalSetup();

		return (
			<Typography variant={"body2"} component="div">
				<p
					dangerouslySetInnerHTML={{
						__html: t(
							"documents.advanced_filter.modal.naked_card.body.info.part_1",
						),
					}}
				/>
				<p
					dangerouslySetInnerHTML={{
						__html: t(
							"documents.advanced_filter.modal.naked_card.body.info.part_2",
						),
					}}
				/>
				{formValuesChanged && (
					<Box
						sx={{ mt: "-10px", mb: "10px" }}
						component="div"
						onClick={resetToDefaultFilters}
					>
						<StyledLink href="#">
							{t("documents.advanced_filter.clear_all_field_back_to_default")}
						</StyledLink>
					</Box>
				)}

				<p
					dangerouslySetInnerHTML={{
						__html: t(
							"documents.advanced_filter.modal.naked_card.body.info.part_3",
						),
					}}
				/>
				<p
					dangerouslySetInnerHTML={{
						__html: t(
							"documents.advanced_filter.default_filter_type.info.invono_selected",
						),
					}}
				/>
				{formValuesChanged && (
					<Box sx={{ mt: "-10px", mb: "10px" }} component="div">
						<StyledLink href="#" onClick={storeCustomDefault}>
							{t("documents.advanced_filter.save_selection_as_custom_default")}
						</StyledLink>
					</Box>
				)}
			</Typography>
		);
	};

	const renderMyCustomSetupInfoPanel = () => {
		const formValuesChanged = !areFormValuesEqualToOriginalSetup();

		return (
			<Typography variant={"body2"} component="div">
				<p
					dangerouslySetInnerHTML={{
						__html: t(
							"documents.advanced_filter.modal.naked_card.body.info.part_1",
						),
					}}
				/>
				<p
					dangerouslySetInnerHTML={{
						__html: t(
							"documents.advanced_filter.modal.naked_card.body.info.part_2",
						),
					}}
				/>
				{formValuesChanged && (
					<Box
						sx={{ mt: "-10px", mb: "10px" }}
						component="div"
						onClick={resetToDefaultFilters}
					>
						<StyledLink href="#">
							{t("documents.advanced_filter.clear_all_field_back_to_default")}
						</StyledLink>
					</Box>
				)}

				<p
					dangerouslySetInnerHTML={{
						__html: t(
							"documents.advanced_filter.modal.naked_card.body.info.part_3",
						),
					}}
				/>
				<p
					dangerouslySetInnerHTML={{
						__html: t(
							"documents.advanced_filter.default_filter_type.info.custom_selected",
						),
					}}
				/>
				<Box sx={{ mt: "-10px", mb: "10px" }} component="div">
					{formValuesChanged && (
						<>
							<StyledLink href="#" onClick={storeCustomDefault}>
								{t("documents.advanced_filter.update_custom_default")}
							</StyledLink>{" "}
							{t("generic.or")}{" "}
						</>
					)}
					<StyledLink href="#" onClick={restoreInvonoDefault}>
						{t("documents.advanced_filter.restore_to_invono_default")}
					</StyledLink>
				</Box>
			</Typography>
		);
	};

	if (getTypeOfFiltersInUse() === TYPE_OF_FILTER_FACTORY_DEFAULT) {
		return renderInvonoDefaultsInfoPanel();
	} else if (getTypeOfFiltersInUse() === TYPE_OF_FILTER_MY_CUSTOM_SETUP) {
		return renderMyCustomSetupInfoPanel();
	} else {
		return null;
	}
};
