import React, { PureComponent } from "react";
import { bool, func } from "prop-types";
import styled from "styled-components";
import { map, list } from "react-immutable-proptypes";

import Text from "../../shared/text/text";
import Label from "../../shared/label/label";
import Input from "../../shared/input/input";
import { MultiSelect } from "../../shared/select";
import ColoredContentWrapper from "../../shared/colored-content-wrapper/colored-content-wrapper";

const StyledUserCreateWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const StyledNameInputWrapper = styled.div`
	margin-bottom: 16px;
`;

const StyledMultiSelectWrapper = styled.div`
	margin-bottom: 16px;
`;

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

export default class UserCreateForm extends PureComponent {
	static propTypes = {
		onChange: func,
		user: map,
		hasError: map,
		rolesOptions: list,
		userRolesLoading: bool,
	};

	render = () => {
		const { onChange, user, rolesOptions, hasError, userRolesLoading } =
			this.props;

		return (
			<StyledUserCreateWrapper>
				<StyledColoredContentWrapper type="secondary">
					<Label tid="generic.name">
						<StyledNameInputWrapper>
							<Input
								fieldName="name"
								placeholderTid="generic.name_placeholder"
								onChange={onChange}
								hasError={hasError.get("name")}
								value={user.get("name", "")}
							/>
						</StyledNameInputWrapper>
					</Label>
					<Label tid="generic.email">
						<Input
							fieldName="email"
							placeholderTid="generic.email"
							onChange={onChange}
							hasError={hasError.get("email")}
							value={user.get("email", "")}
						/>
					</Label>
				</StyledColoredContentWrapper>

				<StyledColoredContentWrapper type="secondary">
					<Label tid="user_management.teams">
						<StyledMultiSelectWrapper>
							<MultiSelect
								fieldName="userRoles"
								placeholderTid="user_management.teams"
								onChange={onChange}
								options={rolesOptions}
								value={user.get("userRoles")}
								isDisabled={userRolesLoading}
								menuPortalTarget={document.body}
							/>
						</StyledMultiSelectWrapper>
					</Label>

					<Text tid="user_management.user_create_modal.teams" />
				</StyledColoredContentWrapper>
			</StyledUserCreateWrapper>
		);
	};
}
