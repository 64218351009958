import React from "react";
import { bool, node } from "prop-types";
import { cn } from "./utils";

const Separator = ({ children, size, hideLeft, hideRight }) => (
	<div
		className={cn("flex items-center gap-2 w-full", {
			"my-6": size === "default",
			"my-4": size === "small",
		})}
	>
		<div
			className={cn("flex-1 border-t h-[1px]", { invisible: hideLeft })}
		></div>
		<div className="text-input flex gap-1 items-center">{children}</div>
		<div
			className={cn("flex-1 border-t h-[1px]", { invisible: hideRight })}
		></div>
	</div>
);

Separator.propTypes = {
	children: node,
	hideLeft: bool,
	hideRight: bool,
};

Separator.defaultProps = {
	size: "default",
	hideLeft: false,
	hideRight: false,
};

export default Separator;
