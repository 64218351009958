import React, { Component } from "react";
import { connect } from "react-redux";

import StepsModal from "../../../dumb-components/shared/modal/steps-modal";

import {
	MEETING_VOTING_DRAFT,
	MEETING_VOTING_STARTED,
	MEETING_VOTING_COMPLETED,
} from "/shared/constants";

import ManageViewContainer from "./manage-view.container";
import ProgressViewContainer from "./progress-view.container";
import ResultViewContainer from "./result-view.container";
import VotingManageModalFooterContainer from "./voting-manage-modal-footer.container";
import EndVotingTimerContainer from "./end-voting-timer.container";

const VOTING_MODAL_TITLE_STATES = {
	MEETING_VOTING_DRAFT: {
		tid: "meetings.voting.manage_modal.title",
	},
	MEETING_VOTING_STARTED: {
		tid: "meetings.voting.manage_modal.title.voting_in_progress",
	},
	MEETING_VOTING_COMPLETED: {
		tid: "meetings.voting.manage_modal.title.voting_results",
	},
};

class VotingManageModalContainer extends Component {
	/**
	 * Draft: 0 - Create || 1 - Progress || 2 - Result
	 * Progress: 0 - Progress || 1 - Result
	 * Completed: 0 - Result
	 */

	state = {
		step: 0,
		steps: [],
	};

	componentDidMount = () => {
		const { votingStatus } = this.props;

		if (votingStatus === MEETING_VOTING_DRAFT) {
			this.setState({
				steps: [
					this.getStepDraft(),
					this.getStepStarted(),
					this.getStepCompleted(),
				],
			});
			return;
		}

		if (votingStatus === MEETING_VOTING_STARTED) {
			this.setState({
				steps: [this.getStepStarted(), this.getStepCompleted()],
			});
			return;
		}

		if (votingStatus === MEETING_VOTING_COMPLETED) {
			this.setState({ steps: [this.getStepCompleted()] });
			return;
		}
	};

	componentDidUpdate = (prevProps) => {
		const { votingStatus } = this.props;

		if (votingStatus !== prevProps.votingStatus) {
			this.setCorrectStep();
		}
	};

	setCorrectStep = () => {
		const { steps } = this.state;
		const { votingStatus } = this.props;

		if (votingStatus === MEETING_VOTING_DRAFT) {
			this.setState({ step: 0 });
		} else if (votingStatus === MEETING_VOTING_STARTED) {
			this.setState({ step: 1 });
		} else if (votingStatus === MEETING_VOTING_COMPLETED) {
			this.setState({ step: steps.length - 1 });
		}
	};

	getStepDraft = () => {
		return {
			body: <ManageViewContainer />,
			footer: { component: <VotingManageModalFooterContainer /> },
		};
	};

	getStepStarted = () => {
		return {
			body: <ProgressViewContainer />,
			footer: { component: <VotingManageModalFooterContainer /> },
		};
	};

	getStepCompleted = () => {
		return {
			body: <ResultViewContainer />,
			footer: { component: <VotingManageModalFooterContainer /> },
		};
	};

	renderRightHeaderComponent = () => {
		return <EndVotingTimerContainer />;
	};

	render = () => {
		const { steps, step } = this.state;
		const { isOpen, votingStatus } = this.props;

		if (!isOpen) {
			return null;
		}

		const titleStateTid = VOTING_MODAL_TITLE_STATES[votingStatus].tid;

		return (
			<StepsModal
				isOpen={true}
				steps={steps}
				step={step}
				headerRightRenderer={this.renderRightHeaderComponent}
				hSize="xl"
				title={titleStateTid}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		votingStatus: store.votings.getIn(
			["voting", "status"],
			MEETING_VOTING_DRAFT,
		),
		isOpen: store.votings.get("voting") ? true : false,
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(VotingManageModalContainer);
