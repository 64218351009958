import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { TextField } from "mui-rff";
import { Field, Form } from "react-final-form";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { emissionConsts } from "../../../../constants/emissions";
import CustomSelect from "../../../../containers/emissions/forms/CustomSelect";
import { Divider } from "@mui/material";
import * as parsers from "../../../../modules/field-parsers";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { validateEmail } from "../../../../modules/validation.module";
import Icon from "../../../../dumb-components/shared/icon/icon";
import { useSubscriptionModalAdminContext } from "./subscription-modal-admin.context";

const {
	invitation: { investorType },
	submission: { creator, emptyPrivate, emptyCompany, emptyCapitalInsurance },
} = emissionConsts;

const SubscriptionSlipForAdminForm = (props) => {
	const { t } = useTranslation();
	const [initialValues, setInitialValues] = useState(null);
	const { submission, insuranceOptions, depositoryOptions } =
		props.subscriptionSlip;
	const emission = useSelector((state) => state.emissions.current);
	const invitee = useSelector((state) =>
		state.emissions.invitees.find(
			(invitee) => invitee.id === props.selectedInvitee,
		),
	);
	const { isSubmitting, setIsSubmitting } = useSubscriptionModalAdminContext();

	useEffect(() => {
		let typeValues = submission;

		if (invitee && submission.creator === "unknown") {
			switch (invitee.type) {
				case investorType.private: {
					typeValues.type = investorType.private;
					typeValues.private = {
						city: invitee.address.city,
						country: invitee.address.country,
						email: invitee.email,
						fullName: invitee.name,
						phone: invitee.phone ? invitee.phone : "",
						ssn: invitee.reference,
						street: invitee.address.street,
						zipCode: invitee.address.zipCode,
					};
					typeValues.company = emptyCompany;

					typeValues.insurance = emptyCapitalInsurance;

					break;
				}
				case investorType.company: {
					typeValues.type = investorType.company;
					typeValues.company = {
						city: invitee.address.city,
						contactPerson: {
							email: invitee.contacts?.[0]?.email ?? "",
							fullName: invitee.contacts?.[0]?.name ?? "",
							phone: invitee.contacts?.[0]?.phone ?? "",
						},
						country: invitee.address.country,
						name: invitee.company,
						organizationNumber: invitee.reference,
						street: invitee.address.street,
						zipCode: invitee.address.zipCode,
					};
					typeValues.private = emptyPrivate;

					typeValues.insurance = emptyCapitalInsurance;

					break;
				}
				case "capitalInsurance": {
					typeValues.type = investorType.insurance;
					typeValues.insurance = {
						beneficiary: {
							city: invitee.capitalInsuranceOwnerInformation.city,
							country: invitee.capitalInsuranceOwnerInformation.country,
							email: invitee.email,
							fullName: invitee.name,
							phone: invitee.phone,
							ssn: invitee.beneficiarySsn,
							street: invitee.capitalInsuranceOwnerInformation.street,
							zipCode: invitee.capitalInsuranceOwnerInformation.zipCode,
						},
						depositoryInstitution: invitee.depositoryInstitute,
						insuranceCompany: insuranceOptions.find(
							(option) => option.name === invitee.company,
						).id,
						insuranceNumber: invitee.reference,
					};
					typeValues.private = emptyPrivate;

					typeValues.company = emptyCompany;

					break;
				}
			}
			setInitialValues(typeValues);
		} else if (invitee) {
			setInitialValues(typeValues);
		}
	}, [invitee, submission]);

	if (!initialValues) {
		return null;
	}

	const onSubmit = (values) => {
		setIsSubmitting(true);
		props.onSubmit(
			{
				...values,
				finalSubmission: true,
				signSubmissionNote: true,
			},
			() => {
				props.onFinalizedSubmission();
			},
		);
	};

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={submission}
			validate={(values) => validateSubscriptionSlipForm(values, t)}
		>
			{({ form, handleSubmit }) => {
				const currentValues = form.getState().values;
				const readOnly =
					currentValues.finalSubmission &&
					currentValues.creator === creator.invitee;

				return (
					<form onSubmit={handleSubmit} noValidate>
						<fieldset disabled={isSubmitting}>
							<Box
								sx={{
									overflowY: "scroll",
									maxHeight: "500px",
									pr: 2,
									pb: 2,
								}}
							>
								<Typography variant="h5">
									{t("emissions.tabs.subscription-form")}
								</Typography>
								<Grid container rowSpacing={3} columnSpacing={3}>
									<InvestorTypeRadioButtons
										currentValues={currentValues}
										readOnly={readOnly}
									/>
									{currentValues.type === investorType.private && (
										<PrivateForm readOnly={readOnly} />
									)}
									{currentValues.type === investorType.company && (
										<CompanyForm readOnly={readOnly} />
									)}
									{currentValues.type === investorType.insurance && (
										<InsuranceForm
											readOnly={readOnly}
											insuranceOptions={insuranceOptions}
											depositoryOptions={depositoryOptions}
										/>
									)}
									<Grid item xs={12}>
										<Divider flexItem sx={{ my: 2 }} />
									</Grid>
									<SharesFields
										readOnly={readOnly}
										currentValues={currentValues}
										emission={emission}
									/>
								</Grid>
							</Box>
							{!readOnly && (
								<div style={{ display: "flex", marginTop: "16px" }}>
									{isSubmitting && (
										<Box sx={{ display: "flex", alignItems: "center" }}>
											<Icon icon="faSpinner" spin />{" "}
											<Typography
												component="span"
												variant="body2"
												sx={{ ml: 1 }}
											>
												{t(
													"emissions_module.subscriptions.admin.adding_subscription",
												)}
											</Typography>
										</Box>
									)}

									<Box
										sx={{
											display: "flex",
											justifyContent: "flex-end",
											width: "100%",
											flex: 1,
										}}
									>
										<Button
											variant={"contained"}
											type="submit"
											disabled={isSubmitting}
										>
											<Typography variant={"h5"}>
												{t("generic.form.save")}
											</Typography>
										</Button>
									</Box>
								</div>
							)}
						</fieldset>
					</form>
				);
			}}
		</Form>
	);
};

export const PrivateForm = (props) => {
	const { t } = useTranslation();

	return (
		<>
			<Grid item xs={12}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"private.fullName"}
					label={t("emissions.subscription-form.full-name")}
					disabled={props.readOnly || props.stickyFields?.includes("fullName")}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"private.ssn"}
					label={t("credentials_pnr")}
					disabled={props.readOnly || props.stickyFields?.includes("ssn")}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"private.email"}
					label={t("email_address")}
					disabled={props.readOnly || props.stickyFields?.includes("email")}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"private.phone"}
					label={t("emissions.subscription-form.telephone-number")}
					disabled={props.readOnly || props.stickyFields?.includes("phone")}
				/>
			</Grid>
			<Grid item xs={8}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"private.street"}
					label={t("generic.address")}
					disabled={props.readOnly || props.stickyFields?.includes("street")}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"private.zipCode"}
					label={t("postal_code")}
					disabled={props.readOnly || props.stickyFields?.includes("zipCode")}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"private.city"}
					label={t("generic.city")}
					disabled={props.readOnly || props.stickyFields?.includes("city")}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"private.country"}
					label={t("country")}
					disabled={props.readOnly || props.stickyFields?.includes("country")}
				/>
			</Grid>
		</>
	);
};

export const CompanyForm = (props) => {
	const { t } = useTranslation();

	return (
		<>
			<Grid item xs={6}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"company.name"}
					label={t("generic.company_name")}
					disabled={props.readOnly || props.stickyFields?.includes("name")}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"company.organizationNumber"}
					label={t("emissions.subscription-form.organization-number")}
					disabled={
						props.readOnly || props.stickyFields?.includes("organizationNumber")
					}
				/>
			</Grid>
			<Grid item xs={8}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"company.street"}
					label={t("generic.address")}
					disabled={
						props.readOnly ||
						(props.type === investorType.company &&
							props.stickyFields?.includes("street"))
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"company.zipCode"}
					label={t("postal_code")}
					disabled={
						props.readOnly ||
						(props.type === investorType.company &&
							props.stickyFields?.includes("zipCode"))
					}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"company.city"}
					label={t("generic.city")}
					disabled={
						props.readOnly ||
						(props.type === investorType.company &&
							props.stickyFields?.includes("city"))
					}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"company.country"}
					label={t("country")}
					disabled={
						props.readOnly ||
						(props.type === investorType.company &&
							props.stickyFields?.includes("country"))
					}
				/>
			</Grid>
			<Grid item xs={12}>
				<Typography variant={"subtitle1"}>{t("contact_person")}</Typography>
			</Grid>
			<Grid item xs={12}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"company.contactPerson.fullName"}
					label={t("emissions.subscription-form.full-name")}
					disabled={props.readOnly}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"company.contactPerson.socailSecurityNumber"}
					label={t(
						"emissions.subscription-list.social-security-number-for-esigning",
					)}
					disabled={props.readOnly}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"company.contactPerson.email"}
					label={t("email_address")}
					disabled={props.readOnly}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"company.contactPerson.phone"}
					label={t("emissions.subscription-form.telephone-number")}
					disabled={props.readOnly}
				/>
			</Grid>
		</>
	);
};

export const InsuranceForm = (props) => {
	const { t } = useTranslation();

	return (
		<>
			<Grid item xs={12}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"insurance.insuranceNumber"}
					label={t("emissions.subscription-form.insurance-number")}
					disabled={props.readOnly}
				/>
			</Grid>
			<Grid item xs={6}>
				<CustomSelect
					id="insurance.insuranceCompany"
					label={t("emissions.subscription-form.insurance-company")}
					readOnly={props.readOnly}
					options={props.insuranceOptions.map((o) => ({ ...o, text: o.name }))}
					disabled={props.readOnly}
				/>
			</Grid>
			<Grid item xs={6}>
				<CustomSelect
					id="insurance.depositoryInstitution"
					label={t("emissions.subscription-form.depository-institution")}
					readOnly={props.readOnly}
					options={props.depositoryOptions.map((o) => ({ ...o, text: o.name }))}
					disabled={props.readOnly}
				/>
			</Grid>
			{props.insuranceMissingText && (
				<Grid item xs={12}>
					<Typography variant={"body2"}>
						{props.insuranceMissingText}
					</Typography>
				</Grid>
			)}
			<Grid item xs={12}>
				<Typography variant={"subtitle1"}>
					{t("emissions.subscription-form.investor.beneficiary")}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"insurance.beneficiary.fullName"}
					label={t("emissions.subscription-form.full-name")}
					disabled={props.readOnly || props.stickyFields?.includes("fullName")}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"insurance.beneficiary.ssn"}
					label={t("credentials_pnr")}
					disabled={props.readOnly || props.stickyFields?.includes("ssn")}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"insurance.beneficiary.email"}
					label={t("email_address")}
					disabled={props.readOnly || props.stickyFields?.includes("email")}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"insurance.beneficiary.phone"}
					label={t("emissions.subscription-form.telephone-number")}
					disabled={props.readOnly || props.stickyFields?.includes("phone")}
				/>
			</Grid>
			<Grid item xs={8}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"insurance.beneficiary.street"}
					label={t("generic.address")}
					disabled={props.readOnly || props.stickyFields?.includes("street")}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"insurance.beneficiary.zipCode"}
					label={t("postal_code")}
					disabled={props.readOnly || props.stickyFields?.includes("zipCode")}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"insurance.beneficiary.city"}
					label={t("generic.city")}
					disabled={props.readOnly || props.stickyFields?.includes("city")}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					InputProps={{
						readOnly: props.readOnly,
						inputProps: { maxLength: 80 },
					}}
					name={"insurance.beneficiary.country"}
					label={t("country")}
					disabled={props.readOnly || props.stickyFields?.includes("country")}
				/>
			</Grid>
		</>
	);
};

export const InvestorTypeRadioButtons = ({ currentValues, readOnly }) => {
	const { t } = useTranslation();

	return (
		<Grid item xs={12}>
			<RadioGroup row value={currentValues.type}>
				<Field name={"type"} type={"radio"}>
					{(p) => (
						<FormControlLabel
							disabled={readOnly}
							value={investorType.private}
							control={<Radio />}
							label={t("emissions.subscription-form.investor.radio.private")}
							onChange={p.input.onChange}
						/>
					)}
				</Field>
				<Field name={"type"} type={"radio"}>
					{(p) => (
						<FormControlLabel
							disabled={readOnly}
							value={investorType.company}
							control={<Radio />}
							label={t("emissions.subscription-form.investor.radio.company")}
							onChange={p.input.onChange}
						/>
					)}
				</Field>
				<Field name={"type"} type={"radio"}>
					{(p) => (
						<FormControlLabel
							disabled={readOnly}
							value={investorType.insurance}
							control={<Radio />}
							label={t("emissions.subscription-form.investor.radio.insurance")}
							onChange={p.input.onChange}
						/>
					)}
				</Field>
			</RadioGroup>
		</Grid>
	);
};

export const SharesFields = ({ currentValues, readOnly, emission }) => {
	const { t } = useTranslation();

	return (
		<>
			<Grid item xs={6}>
				<TextField
					InputProps={{ readOnly, inputProps: { maxLength: 80 } }}
					disabled={readOnly}
					name={"shareCount"}
					type={"text"}
					label={t("number_of_shares")}
					autoComplete="off"
					fieldProps={{
						parse: parsers.toInt,
					}}
					onKeyDown={(event) => {
						const allowedKeys = [
							"Backspace",
							"Delete",
							"ArrowDown",
							"ArrowUp",
							"ArrowLeft",
							"ArrowRight",
							"Tab",
							"Shift",
						];
						if (
							!(
								(event.key >= 0 && event.key <= 9) ||
								allowedKeys.includes(event.key)
							)
						) {
							event.preventDefault();
						}
						if (event.target.value[0] === "0") {
							event.target.value = event.target.value.slice(1);
						}
					}}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					onClick={(e) => e.target.blur()}
					inputProps={{ readOnly: true, tabIndex: -1 }}
					disabled={true}
					name={"amount"}
					label={t("emissions.subscription-form.investor.amount")}
					value={
						isNaN(currentValues.shareCount * emission.pricePerShare)
							? "0"
							: currentValues.shareCount * emission.pricePerShare
					}
				/>
			</Grid>
		</>
	);
};
export const validateSubscriptionSlipForm = (
	values,
	t,
	maxSharesToSubscribeTo,
) => {
	const formErrors = {};
	if (values.type === investorType.private) {
		formErrors.private = {};
		if (!values.private.fullName) {
			formErrors.private.fullName = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.private.ssn) {
			formErrors.private.ssn = t("public.final-submission.field-error-message");
		}
		if (!validateEmail(values.private.email)) {
			formErrors.private.email = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.private.phone) {
			formErrors.private.phone = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.private.street) {
			formErrors.private.street = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.private.zipCode) {
			formErrors.private.zipCode = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.private.city) {
			formErrors.private.city = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.private.country) {
			formErrors.private.country = t(
				"public.final-submission.field-error-message",
			);
		}
	}
	if (values.type === investorType.company) {
		formErrors.company = {};
		if (!values.company.name) {
			formErrors.company.name = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.company.organizationNumber) {
			formErrors.company.organizationNumber = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.company.street) {
			formErrors.company.street = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.company.zipCode) {
			formErrors.company.zipCode = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.company.city) {
			formErrors.company.city = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.company.country) {
			formErrors.company.country = t(
				"public.final-submission.field-error-message",
			);
		}
		formErrors.company.contactPerson = {};
		if (!values.company.contactPerson.fullName) {
			formErrors.company.contactPerson.fullName = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.company.contactPerson.socailSecurityNumber) {
			formErrors.company.contactPerson.socailSecurityNumber = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!validateEmail(values.company.contactPerson.email)) {
			formErrors.company.contactPerson.email = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.company.contactPerson.phone) {
			formErrors.company.contactPerson.phone = t(
				"public.final-submission.field-error-message",
			);
		}
	}
	if (values.type === investorType.insurance) {
		formErrors.insurance = {};
		formErrors.insurance.beneficiary = {};

		if (!values.insurance.insuranceNumber) {
			formErrors.insurance.insuranceNumber = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.insurance.insuranceCompany) {
			formErrors.insurance.insuranceCompany = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.insurance.depositoryInstitution) {
			formErrors.insurance.depositoryInstitution = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.insurance.beneficiary.fullName) {
			formErrors.insurance.beneficiary.fullName = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.insurance.beneficiary.ssn) {
			formErrors.insurance.beneficiary.ssn = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!validateEmail(values.insurance.beneficiary.email)) {
			formErrors.insurance.beneficiary.email = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.insurance.beneficiary.phone) {
			formErrors.insurance.beneficiary.phone = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.insurance.beneficiary.street) {
			formErrors.insurance.beneficiary.street = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.insurance.beneficiary.zipCode) {
			formErrors.insurance.beneficiary.zipCode = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.insurance.beneficiary.city) {
			formErrors.insurance.beneficiary.city = t(
				"public.final-submission.field-error-message",
			);
		}
		if (!values.insurance.beneficiary.country) {
			formErrors.insurance.beneficiary.country = t(
				"public.final-submission.field-error-message",
			);
		}
	}
	if (!values.shareCount) {
		formErrors.shareCount = t("public.final-submission.field-error-message");
	}
	if (maxSharesToSubscribeTo && values.shareCount > maxSharesToSubscribeTo) {
		formErrors.shareCount = t(
			"public.final-submission.field-error-message-max-shares",
			{
				amount: maxSharesToSubscribeTo,
			},
		);
	}

	return formErrors;
};

export default SubscriptionSlipForAdminForm;
