import React, { PureComponent } from "react";
import { func, bool } from "prop-types";
import styled from "styled-components";

import Text from "../../shared/text/text";
import Button from "../../shared/button/button";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacing[3]};
`;

const TextWrapper = styled.div`
	display: flex;
	flex: 1;
`;

const ButtonWrapper = styled.div``;

export default class EsignRecreateDocument extends PureComponent {
	static propTypes = {
		onClick: func,
		isLoading: bool,
	};

	render = () => {
		const { onClick, isLoading } = this.props;

		return (
			<Wrapper>
				<TextWrapper>
					<Text
						asHtml
						tid="documents.esign.signees_panel.recreate.info"
						color="white"
					/>
				</TextWrapper>
				<ButtonWrapper>
					<Button
						tid="documents.esign.signees_panel.recreate.btn"
						onClick={onClick}
						isLoading={isLoading}
						mode="primary"
					/>
				</ButtonWrapper>
			</Wrapper>
		);
	};
}
