export const portfolioFormatDecimals = (isViaCompany, num, type) => {
	if (type === "percentage") {
		const moreThanTwoDecimals = -Math.floor(Math.log10(num) + 1) > 1;
		if (!moreThanTwoDecimals) {
			return undefined;
		}
	}

	if (isViaCompany && num % 1 > 0) {
		if (num < 1) {
			const myTotalVotesToPrecision = num.toPrecision(1);
			const numberOfDecimals = myTotalVotesToPrecision
				.toString()
				.split(".")[1]?.length;

			return numberOfDecimals;
		} else {
			const firstNumber = num.toString()[0];
			const asNumber = parseInt(firstNumber);
			const getDecimals = num - asNumber;
			const myTotalVotesToPrecision = getDecimals.toPrecision(1);
			const numberOfDecimals =
				myTotalVotesToPrecision.toString().split(".")[1]?.length || 0;

			return numberOfDecimals;
		}
	} else {
		return undefined;
	}
};
