import React from "react";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

function RolesTooltipComponent({ data }) {
	if (!data.roles || data.roles.length === 0) {
		return null;
	}

	return (
		<Box
			className="custom-tooltip"
			sx={{ maxWidth: "300px", minWidth: "200px", padding: 2 }}
		>
			<Typography variant="body2" color="text.primary">
				<strong>
					<FormattedMessage id="people_modal.teams_and_shareholders.tooltip.roles" />
				</strong>
				<br />
				{data.roles.join(", ")}
			</Typography>
		</Box>
	);
}

export { RolesTooltipComponent };
