import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import { styled } from "@mui/system";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import Typography from "@mui/material/Typography";
import { useInvitedAsFilter } from "../../common/invited-as-filter-hook";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import MuiTextField from "@mui/material/TextField";
import {
	createInvestmentForInvitee,
	updateAllocationForSubmission,
} from "../../../../actions/emissions.actions";
import { useTheme } from "@mui/material";
import AllocationContextMenu from "./allocation-context.menu";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import {
	NUMBER_FORMAT_CURRENCY,
	NUMBER_FORMAT_INTEGER,
} from "/shared/constants.json";
import { emissionConsts } from "../../../../constants/emissions";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IconButton from "@mui/material/IconButton";

const AgGridStyle = styled("div")(({ theme }) => ({
	height: "70rem",
	"& .ag-header": {
		borderRadius: 5,
		backgroundColor: theme.palette.blue["100"],
	},
	"& .ag-cell": {
		display: "flex",
		alignItems: "center",
	},
}));

const gridData = {};
const ALLOCATE_KEY = "Allocate";
const LEFT_TO_ALLOCATE_KEY = "LeftToAllocate";
const TOTAL_ALLOCATION_KEY = "TotalAllocation";
const INVITED_AS_COLUMN_ID = "invitedAs";
const LEFT_TO_ALLOCATE_COLUMN_ID = "left.to.allocate";
const ALLOCATE_COLUMN_ID = "allocate";
const TOTAL_ALLOCATION_COLUMN_ID = "total.allocation";

const setGridData = (rowIndex, columnKey, cellValue) => {
	if (gridData[rowIndex]) {
		gridData[rowIndex][columnKey] = cellValue;
	} else {
		gridData[rowIndex] = { [columnKey]: cellValue };
	}
};

const AllocationGrid = ({
	submissions,
	companyId,
	emissionId,
	preventChanges,
}) => {
	const { t } = useTranslation();
	const invitedAsFilter = useInvitedAsFilter();
	const gridRef = useRef();

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				{invitedAsFilter.renderFilterButtons()}
				<Box sx={{ display: "flex" }}>
					<AllocationContextMenu
						handleDownloadAsExcel={() => {
							gridRef.current?.();
						}}
					/>
				</Box>
			</Box>
			<AgGridStyle className={"ag-theme-material"}>
				<AgGridReact
					ref={gridRef}
					gridOptions={{
						onGridReady(event) {
							invitedAsFilter.setGridApi(event.api);
							event.api.sizeColumnsToFit();
							gridRef.current = () => {
								event.api.exportDataAsExcel({
									suppressTextAsCDATA: false,
									allColumns: true,
									columnWidth: () => 150,
									sheetName: t("emissions.header.allocations-list"),
									fileName: t("emissions.header.allocations-list") + ".xlsx",
									processCellCallback: ({ value, node, column }) => {
										if (column.getId() === INVITED_AS_COLUMN_ID) {
											if (
												node.data.invitedAs ===
												emissionConsts.invitation.inviteeStatus
													.currentShareholder
											) {
												return t("emissions.invitations.current-shareholder");
											} else if (
												node.data.invitedAs ===
												emissionConsts.invitation.inviteeStatus
													.potentialShareholder
											) {
												return t("emissions.invitations.potential-shareholder");
											} else if (
												node.data.invitedAs ===
												emissionConsts.invitation.inviteeStatus.dealFlow
											) {
												return t("emission.invitation.inviteeStatus.deal-flow");
											}
										} else if (column.getId() === LEFT_TO_ALLOCATE_COLUMN_ID) {
											return gridData[node.rowIndex]?.[LEFT_TO_ALLOCATE_KEY];
										} else if (column.getId() === ALLOCATE_COLUMN_ID) {
											return gridData[node.rowIndex]?.[ALLOCATE_KEY];
										} else if (column.getId() === TOTAL_ALLOCATION_COLUMN_ID) {
											return gridData[node.rowIndex]?.[TOTAL_ALLOCATION_KEY];
										} else {
											return value;
										}
									},
								});
							};
						},
						onGridSizeChanged(event) {
							event.api.sizeColumnsToFit();
						},
						suppressScrollOnNewData: true,
						getLocaleText: (params) => t(`ag-grid.locale.${params.key}`),
					}}
					rowHeight={56}
					defaultColDef={{
						suppressMovable: true,
						cellStyle: {
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
							overflow: "hidden",
							border: "none",
						},
					}}
					components={{
						subscriberRenderer: SubscriberCell,
						leftToAllocateRenderer: LeftToAllocateCell,
						totalAllocationRenderer: TotalAllocationCell,
						allocateRenderer: (params) => {
							const customParams = {
								...params,
								preventChanges: preventChanges,
							};
							return <AllocationNumberField {...customParams} />;
						},
					}}
					getContextMenuItems={true}
					rowData={submissions.map((s) => {
						return {
							...s,
							companyId,
							emissionId,
						};
					})}
					columnDefs={[
						{
							field: "invitedAs",
							filter: "agSetColumnFilter",
							hide: true,
						},
						{
							field: "subscriber",
							headerName: t("emissions.subscription-list.subscriber"),
							suppressMenu: true,
							cellRenderer: "subscriberRenderer",
							editable: false,
						},
						{
							field: "proRata",
							headerName: t("Pro-rata"),
							suppressMenu: true,
							editable: false,
							valueFormatter: formatNumbers,
						},
						{
							field: "submission.shareCount",
							headerName: t("generic.applied"),
							suppressMenu: true,
							editable: false,
							valueFormatter: formatNumbers,
						},
						{
							field: "allocation.previouslyAllocated",
							headerName: t("emissions.allocation.pre-assigned"),
							suppressMenu: true,
							editable: false,
							valueFormatter: formatNumbers,
						},
						{
							field: "left.to.allocate",
							headerName: t("emissions.allocation.left-to-allocate"),
							suppressMenu: true,
							cellRenderer: "leftToAllocateRenderer",
							editable: false,
						},
						{
							field: "allocate",
							headerName: t("emissions.allocation.allocate"),
							suppressMenu: true,
							cellRenderer: "allocateRenderer",
						},
						{
							field: "total.allocation",
							headerName: t("emissions.allocation.total-allocation"),
							suppressMenu: true,
							cellRenderer: "totalAllocationRenderer",
							editable: false,
						},
					]}
				/>
			</AgGridStyle>
		</Box>
	);
};

const TotalAllocationCell = (props) => {
	const price = useSelector((state) => state.emissions.current.pricePerShare);
	const allocate =
		(props.data.allocation.allocation ?? 0) +
		(props.data.allocation.previouslyAllocated ?? 0);
	const { t } = useTranslation();
	const theme = useTheme();
	const result = localeFormatNumber(allocate, NUMBER_FORMAT_INTEGER);
	setGridData(props.rowIndex, TOTAL_ALLOCATION_KEY, result);

	return (
		<>
			<Box
				sx={{ display: "flex", lineHeight: "2rem", flexDirection: "column" }}
			>
				<Box sx={{ px: 0.5 }}>
					{result}
					<Typography
						component={"span"}
						variant={"body2"}
						sx={{ color: theme.palette.grey["800"], ml: "0.5rem" }}
					>
						{t("emissions.allocation.quantity")}
					</Typography>
				</Box>
				<Box sx={{ borderRadius: "4px", bgcolor: "secondary.100", px: 0.5 }}>
					{localeFormatNumber(allocate * price, NUMBER_FORMAT_CURRENCY)}
				</Box>
			</Box>
		</>
	);
};

const SubscriberCell = (props) => {
	return (
		<Tooltip title={props.value}>
			<Typography
				variant={"body2"}
				sx={{
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					overflow: "hidden",
				}}
			>
				{props.value}
			</Typography>
		</Tooltip>
	);
};

const LeftToAllocateCell = (props) => {
	const result =
		props.data.submission.shareCount -
		(props.data.allocation.previouslyAllocated ?? 0) -
		(props.data.allocation.allocation ?? 0);
	setGridData(props.rowIndex, LEFT_TO_ALLOCATE_KEY, result);
	return localeFormatNumber(result, NUMBER_FORMAT_INTEGER);
};

const AllocationNumberField = (props) => {
	const [allocation, setAllocation] = useState(
		props.data.allocation.allocation,
	);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	setGridData(props.rowIndex, ALLOCATE_KEY, allocation);
	const signedDocument = props?.data?.submission?.signed;
	if (!signedDocument) {
		return (
			<Tooltip title={t("emissions.allocation.invitee-not-yet-signed-tooltip")}>
				<span>{t("emissions.allocation.invitee-not-yet-signed-text")}</span>
			</Tooltip>
		);
	}

	return props?.data?.submission.investmentId ? (
		<MuiTextField
			disabled={props.preventChanges}
			sx={{
				"& legend": { display: "none" },
				"& fieldset": { top: 0 },
				"& input": { padding: "12px  12px" },
			}}
			key={props.rowIndex}
			type={"tel"}
			value={allocation || 0}
			fullWidth
			onChange={(e) => {
				setAllocation(parseInt(e.target.value));
			}}
			onBlur={(e) => {
				dispatch(
					updateAllocationForSubmission(
						props.data.companyId,
						props.data.emissionId,
						{
							inviteeId: props.data.inviteeId,
							allocation: parseInt(e.target.value),
						},
					),
				);
			}}
		/>
	) : !props.preventChanges ? (
		<Tooltip title={t("emissions.allocation.invitee-not-yet-shareholder.desc")}>
			<IconButton
				onClick={() =>
					dispatch(
						createInvestmentForInvitee(
							props.data.companyId,
							props.data.emissionId,
							props.data.inviteeId,
							t("emissions.allocation.create-investor.response-infotext", {
								value: props.data.subscriber,
							}),
						),
					)
				}
			>
				<AddBoxIcon sx={{ color: "grey.700" }} />
				<Typography variant={"body1"}>
					{t("emissions.allocation.invitee-not-yet-shareholder.linktext")}
				</Typography>
			</IconButton>
		</Tooltip>
	) : (
		<></>
	);
};

function formatNumbers(number) {
	return number.value
		? localeFormatNumber(number.value, NUMBER_FORMAT_INTEGER)
		: "";
}

export default AllocationGrid;
