import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";

import {
	listVotings,
	createVoting,
	selectVoting,
	reorderVotings,
} from "../../../actions/votings.actions";
import {
	getAtLeastOneAttendeeCanVote,
	isUserModerator,
} from "../../../components/helpers/voting.helper";

import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";
import VotingPanel from "../../../dumb-components/meetings/voting/panel";
import VotingManageModalContainer from "./voting-manage-modal.container";
import ConfirmDeleteVotingContainer from "./confirm-delete-voting.container";

import { openModal } from "../../../actions/modals.actions";
import {
	MEETINGS_VOTING_DELETE_CONFIRM_MODAL,
	MEETINGS_ROLLCALL_MODAL,
} from "../../../constants/modals";
import {
	MEETING_VOTING_STARTED,
	MEETING_VOTING_COMPLETED,
} from "/shared/constants";

const DELETE_VOTING_TOOLTIP_STATES = {
	started: {
		tid: "meetings.votings.delete_voting.tooltip.started",
		delayShow: "instant",
	},
	userIsNotModerator: {
		tid: "meetings.votings.delete_voting.tooltip.user_not_moderator",
		delayShow: "instant",
	},
};

class VotingPanelContainer extends Component {
	dropdownRefs = {};

	componentDidMount = () => {
		const { meetingId, listVotings } = this.props;

		if (meetingId) {
			listVotings(meetingId);
		}
	};

	componentDidUpdate = (prevProps) => {
		const { meetingId, listVotings } = this.props;

		if (prevProps.meetingId !== meetingId) {
			listVotings(meetingId);
		}
	};

	createQuestion = () => {
		const { meetingId, createVoting } = this.props;
		const voting = Map({ meetingId });

		createVoting(voting, meetingId);
	};

	manageQuestion = (questionId) => {
		const { selectVoting } = this.props;

		selectVoting(questionId);
	};

	onDragEnd = ({ source, destination }) => {
		const { reorderVotings, meetingId } = this.props;

		if (!destination) {
			return;
		}

		reorderVotings(meetingId, source.index, destination.index);
	};

	openConfirmDelete = (voting) => {
		const { openModal } = this.props;

		this.dropdownRefs[voting.get("id")]?.onToggleMenu();

		openModal(
			MEETINGS_VOTING_DELETE_CONFIRM_MODAL,
			Map({ votingToBeDeleted: voting }),
		);
	};

	openRollcallModal = () => {
		const { openModal } = this.props;
		openModal(MEETINGS_ROLLCALL_MODAL);
	};

	renderMoreActionsMenu = (votingId) => {
		const { votings, userId, moderators } = this.props;
		const voting = votings.find((v) => v.get("id") === votingId);
		const votingIsOngoing = voting.get("status") === MEETING_VOTING_STARTED;
		const votingHasEnded = voting.get("status") === MEETING_VOTING_COMPLETED;
		const userIsModerator = isUserModerator({ userId, moderators });
		let tooltipActiveState;
		let disabled = false;

		if (!userIsModerator) {
			tooltipActiveState = "userIsNotModerator";
			disabled = true;
		} else if (votingIsOngoing) {
			tooltipActiveState = "started";
			disabled = true;
		}

		let openVoteTid = "meetings.votings.open_voting";
		if (votingHasEnded) {
			openVoteTid = "meetings.votings.open_result";
		}

		let deleteVoteTid = "meetings.votings.delete_voting";
		if (votingHasEnded) {
			deleteVoteTid = "meetings.votings.delete_vote_result";
		}

		return (
			<DropdownMenuContainer
				ref={(ref) => (this.dropdownRefs[voting.get("id")] = ref)}
				halignMenu="right"
				btnIcon="faEllipsisV"
				btnIconType="light"
				btnIconSize="sml"
				btnMode="transparent-icon"
				inline
				noMaxWidth
			>
				<DropdownIconItem
					icon="faExpandArrowsAlt"
					tid={openVoteTid}
					onClick={this.manageQuestion.bind(this, votingId)}
				/>
				<Tooltip
					states={DELETE_VOTING_TOOLTIP_STATES}
					activeState={tooltipActiveState}
				>
					<DropdownIconItem
						icon="faTrashAlt"
						tid={deleteVoteTid}
						onClick={this.openConfirmDelete.bind(this, voting)}
						disabled={disabled}
					/>
				</Tooltip>
			</DropdownMenuContainer>
		);
	};

	render = () => {
		const { votings, voting, attendees, rollcallDone, investorsCache } =
			this.props;
		const atLeastOneAttendeeCanVote = getAtLeastOneAttendeeCanVote(
			attendees,
			investorsCache,
		);

		return (
			<>
				<VotingPanel
					questions={votings}
					atLeastOneAttendeeCanVote={atLeastOneAttendeeCanVote}
					rollcallDone={rollcallDone}
					hasAttendees={attendees.size > 0}
					onCreateQuestion={this.createQuestion}
					onManageQuestion={this.manageQuestion}
					onDragEnd={this.onDragEnd}
					onOpenRollcallModal={this.openRollcallModal}
					renderMoreActionsMenu={this.renderMoreActionsMenu}
				/>

				{voting && <VotingManageModalContainer />}
				<ConfirmDeleteVotingContainer />
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meetingId: store.meetings.getIn(["meeting", "id"]),
		attendees: store.meetings.getIn(["meeting", "attendees"], Map()) || Map(),
		votings: store.votings.get("list"),
		voting: store.votings.get("voting"),
		rollcallDone: store.meetings.getIn(["meeting", "metadata", "rollcallDone"]),
		investorsCache: store.investors.get("investorsCache"),
		userId: store.user.getIn(["userObj", "id"]),
		moderators: store.meetings.getIn(["meeting", "moderators"]),
	};
};

const mapActionsToProps = {
	listVotings,
	createVoting,
	selectVoting,
	reorderVotings,
	openModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(VotingPanelContainer);
