import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import Immutable, { List, fromJS } from "immutable";
import { string, bool } from "prop-types";

import { Select } from "../../../dumb-components/shared/select";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";
import i18nHelper from "../../../components/helpers/i18n.helper";

import {
	getMeetingRolesExtendedAsOptions,
	getSynaCompanyRolesAsOptions,
} from "../../../constants/syna-company-roles";
import { patchMeeting } from "../../../actions/meetings.actions";

import { __DELETE__ } from "/shared/constants";

const SECRETARY = "secretary";
const CHAIRMAN = "chairman";

const TOOLTIP_STATES = {
	isSimpleModeAndCanNotChangeSecretary: {
		tid: "meeting.attendee.function.disabled_due_to_investor_guest",
		delayShow: "instant",
	},
	meetingHasFinished: {
		tid: "meeting.attendee.function.disabled.meeting_has_finished",
		delayShow: "instant",
	},
};

class MeetingAttendeeFunctionsContainer extends Component {
	state = {
		rolesOptions: List(),
	};

	static propTypes = {
		isDisabled: bool,
		attendeeId: string,
		withPortal: bool,
		isSimpleModeAndCanNotChangeSecretary: bool,
		isSecretaryPostMeeting: bool,
	};

	componentDidMount() {
		this.setRoles();
	}

	componentDidUpdate = (prevProps) => {
		const { meetingChairman, meetingSecretary, customFunctions, attendee } =
			this.props;
		const currentRoles = attendee.get("roles");
		const previousRoles = prevProps.attendee.get("roles");

		if (meetingChairman !== prevProps.meetingChairman) {
			this.setRoles();
		} else if (meetingSecretary !== prevProps.meetingSecretary) {
			this.setRoles();
		} else if (!Immutable.is(currentRoles, previousRoles)) {
			// Roles changed
			this.setRoles();
		}

		if (customFunctions !== prevProps.customFunctions) {
			this.setRoles();
		}
	};

	setRoles = () => {
		const { customFunctions, language, region } = this.props;
		const meetingFuncOptions = getMeetingRolesExtendedAsOptions(true);

		// Fetch company role options. Filter out Ordförande and Enskild Firma
		let rolesOptions = getSynaCompanyRolesAsOptions().filter((role) => {
			return role.value !== "OF" && role.value !== "ENSKILD_FIRMA";
		});

		const currentAttendeeRole = this.getAttendeeRoleValue();

		// Append custom functions/roles
		customFunctions.forEach((customFunction) => {
			const functionId = customFunction.get("id");
			const name = customFunction.get("name");
			const isDeleted = customFunction.get("isDeleted");

			// Remove deleted roles from options, unless one of the
			// deleted options is currently selected
			if (currentAttendeeRole !== functionId && isDeleted) {
				return;
			}

			rolesOptions.push({
				value: customFunction.get("id"),
				label: i18nHelper.getTranslatedValue(name, language, region),
			});
		});

		rolesOptions = rolesOptions.sort((a, b) => {
			return a.label.localeCompare(b.label);
		});

		meetingFuncOptions.push({
			label: "meeting.attendee.details.functions.group.company",
			options: rolesOptions,
		});

		this.setState({ rolesOptions: fromJS(meetingFuncOptions) });
	};

	saveAttendee = (patchData) => {
		const { patchMeeting, meetingId } = this.props;
		patchMeeting(meetingId, patchData);
	};

	getAttendeeRoleValue = () => {
		const { attendeeId, meetingChairman, meetingSecretary, attendee } =
			this.props;

		if (!attendeeId || !attendee) {
			return "";
		}

		if (meetingChairman === attendeeId) {
			return CHAIRMAN;
		}

		if (meetingSecretary === attendeeId) {
			return SECRETARY;
		}

		if (attendee.get("roles") && attendee.get("roles").size > 0) {
			return attendee.getIn(["roles", 0]);
		}

		return "";
	};

	onChangeRole = (value) => {
		const { attendeeId, agendaItems } = this.props;
		let { attendee } = this.props;
		let patchData = Map();
		const previousRole = this.getAttendeeRoleValue();

		if (!attendee) {
			return;
		}

		// Reset field if secretary or chairamn was just removed
		if (previousRole === SECRETARY) {
			patchData = patchData.set(SECRETARY, "");
		} else if (previousRole === CHAIRMAN) {
			patchData = patchData.set(CHAIRMAN, "");
		}

		// CHAIRMAN or secretary, set these fields instead and stop
		if (value === CHAIRMAN || value === SECRETARY) {
			if (value === CHAIRMAN) {
				patchData = patchData.set(CHAIRMAN, attendeeId);
			}

			if (value === SECRETARY) {
				patchData = patchData.set(SECRETARY, attendeeId);
			}

			// Set attendee as presenter of open and close meeting agenda items if that is not set yet
			if (value === CHAIRMAN) {
				agendaItems?.forEach((item, index) => {
					if (
						(item.get("internalType") === "open" ||
							item.get("internalType") === "close") &&
						!item.get("presenter")
					) {
						patchData = patchData.setIn(
							["agendaItems", index, "presenter"],
							attendeeId,
						);
					}
				});
			}

			// Reset old role
			patchData = patchData.setIn(
				["attendees", attendeeId, "roles"],
				__DELETE__,
			);
			this.saveAttendee(patchData);
			return;
		}

		// Remove roles from attendee if it was cleared
		if (!value) {
			patchData = patchData.setIn(
				["attendees", attendeeId, "roles"],
				__DELETE__,
			);
		} else {
			// Set role in roles field in attendees
			patchData = patchData.setIn(
				["attendees", attendeeId, "roles"],
				List([value]),
			);
		}

		this.saveAttendee(patchData);
	};

	render() {
		const {
			isDisabled,
			withPortal,
			isSimpleModeAndCanNotChangeSecretary,
			isSecretaryPostMeeting,
		} = this.props;
		const { rolesOptions } = this.state;
		const role = this.getAttendeeRoleValue();
		const activeTooltip = isDisabled ? true : false;
		const menuPortalTarget = withPortal ? document.body : undefined;
		const activeStateIsSimpleMode =
			isSimpleModeAndCanNotChangeSecretary &&
			"isSimpleModeAndCanNotChangeSecretary";
		const activeStateMeetingHasFinished =
			isSecretaryPostMeeting && "meetingHasFinished";

		return (
			<Tooltip
				states={TOOLTIP_STATES}
				activeState={activeStateIsSimpleMode || activeStateMeetingHasFinished}
				active={activeTooltip}
			>
				<Select
					options={rolesOptions}
					placeholderTid="meetings.attendees.details.placeholder.function"
					value={role}
					onChange={this.onChangeRole}
					isDisabled={isDisabled}
					menuPortalTarget={menuPortalTarget}
					isClearable
				/>
			</Tooltip>
		);
	}
}

const mapStoreToProps = (store, ownProps) => {
	return {
		meetingChairman: store.meetings.getIn(["meeting", "chairman"]),
		meetingSecretary: store.meetings.getIn(["meeting", "secretary"]),
		attendee:
			store.meetings.getIn(
				["meeting", "attendees", ownProps.attendeeId],
				Map(),
			) || Map(),
		meetingId: store.meetings.getIn(["meeting", "id"]),
		agendaItems: store.meetings.getIn(["meeting", "agendaItems"]),
		customFunctions: store.meetings.get("customFunctions"),
		language: store.i18n.language,
		region: store.company.company?.region,
	};
};

const mapActionsToProps = {
	patchMeeting,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingAttendeeFunctionsContainer);
