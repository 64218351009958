import React, { Component } from "react";
import { object, string, bool, func } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import { FormattedMessage } from "react-intl";
import Tooltip from "../../shared/tooltip/tooltip";
import { validateEmail } from "../../../modules/validation.module";
import clsx from "clsx";

export default class InvestorEmailsField extends Component {
	static propTypes = {
		fieldName: string,
		email: string,
		onChange: func,
		id: string,
		labelTid: string,
		notified: immutablePropTypes.map,
		icon: string,
		iconClass: string,
		readonly: bool,
		placeholder: string,
		className: string,
		wrapperClassName: string,
		meta: object,
		i18n: object,
		objId: string,
		isActiveUser: bool,
		isActiveCompany: bool,
		isUnregistered: bool,
		onNotify: func,
		investorTypeOfOwner: string,
		onDelete: func,
		hideInformButton: bool,
	};

	onChange = (e) => {
		const { onChange } = this.props;
		const val = e.target.value;

		onChange(val);
	};

	onNotify = () => {
		const { onNotify } = this.props;

		onNotify();
	};

	render = () => {
		const {
			fieldName,
			email,
			id,
			labelTid,
			notified,
			readonly,
			placeholder,
			className,
			wrapperClassName,
			i18n,
			objId,
			isActiveUser,
			isActiveCompany,
			isUnregistered,
			hasInvestorId,
			investorTypeOfOwner,
			hideInformButton,
			meta: { touched, error },
		} = this.props;
		const reallyIsNotified = notified;
		const wrapperClass = wrapperClassName || "col-md-12";
		const showInputTooltip =
			(reallyIsNotified && isActiveUser) || isUnregistered;
		const reallyIsActiveUser =
			investorTypeOfOwner !== "company" && isActiveUser;
		const reallyIsActiveCompany =
			investorTypeOfOwner === "company" && isActiveCompany;
		const reallyIsUnregistered = isUnregistered;
		const showNotifyText = !reallyIsNotified;
		const showRemindText =
			reallyIsNotified && (!reallyIsActiveUser || !reallyIsActiveCompany);

		let showNotifyBtn;
		if (investorTypeOfOwner !== "company") {
			showNotifyBtn =
				!hasInvestorId &&
				email &&
				validateEmail(email) &&
				!reallyIsUnregistered &&
				(!reallyIsNotified || (reallyIsNotified && !reallyIsActiveUser));
		} else if (investorTypeOfOwner === "company") {
			showNotifyBtn =
				!hasInvestorId &&
				email &&
				validateEmail(email) &&
				!reallyIsUnregistered &&
				(!reallyIsNotified || (reallyIsNotified && !reallyIsActiveCompany));
		}

		if (hideInformButton) {
			showNotifyBtn = false;
		}

		let inputTooltipTid;
		if (isActiveUser) {
			inputTooltipTid = "shares.shareholders.registered.tooltip";
		} else if (isUnregistered) {
			inputTooltipTid = "shares.shareholders.unregistered.tooltip";
		}

		// Special occasion (to show showRemindText) when company type of owner gets linked and then link is removed.
		const shareholderIsUnregistered =
			reallyIsUnregistered === true || !reallyIsUnregistered;
		const notifiedButNotRegistered =
			reallyIsNotified && shareholderIsUnregistered;
		const hasFeedback =
			(reallyIsNotified && reallyIsActiveUser) || reallyIsUnregistered;
		const hasError = touched && error;

		return (
			<div
				className={clsx("form-group", wrapperClass, {
					"has-feedback": hasFeedback,
					"has-error": hasError,
				})}
			>
				{labelTid && (
					<label className="control-label" htmlFor={id}>
						<FormattedMessage id={labelTid} />
					</label>
				)}

				<div className={clsx({ "input-group": showNotifyBtn })}>
					<Tooltip active={showInputTooltip} tid={inputTooltipTid}>
						<input
							key={objId}
							name={fieldName}
							onChange={this.onChange}
							value={email || ""}
							id={id}
							placeholder={i18n[placeholder]}
							disabled={readonly}
							className={className}
							type="email"
						/>
					</Tooltip>
					{hasInvestorId && reallyIsActiveUser ? (
						<span
							className="form-control-feedback text--success"
							style={{ top: "24px", width: "120px" }}
						>
							<FormattedMessage id="shares.shareholders.registered" />{" "}
							<i className="fa fa-check"></i>
						</span>
					) : null}
					{reallyIsUnregistered ? (
						<span className="form-control-feedback text--error">
							<FormattedMessage id="shares.shareholders.unregistered" />{" "}
							<i className="fa fa-exclamation"></i>
						</span>
					) : null}
					{showNotifyBtn && (
						<div className="input-group-btn">
							{showNotifyBtn && (
								<button
									className="btn btn-primary"
									type="button"
									onClick={this.onNotify}
								>
									{showNotifyText && (
										<Tooltip tid="shares.shareholders.notify_shareholder.tooltip">
											<FormattedMessage id="shares.shareholders.notify_shareholder" />
										</Tooltip>
									)}
									{(showRemindText || notifiedButNotRegistered) && (
										<Tooltip tid="shares.shareholders.remind_shareholder.tooltip">
											<FormattedMessage id="shares.shareholders.remind_shareholder" />
										</Tooltip>
									)}
								</button>
							)}
						</div>
					)}
				</div>

				{touched && error && (
					<small className="help-block">
						<FormattedMessage id={error} />
					</small>
				)}
			</div>
		);
	};
}
