import io from "socket.io-client";
import { notifyBankId, notifySocketId } from "./actions/notify.actions";
import { socketEventDocuments } from "./actions/documents.actions";
import { socketEventFolders } from "./actions/folders.actions";
import { socketEventTasks } from "./actions/tasks.actions";
import { socketEventTags } from "./actions/tags.actions";
import { socketEventComments } from "./actions/comments.actions";
import { socketEventProjects } from "./actions/projects.actions";
import { socketEventCompany } from "./actions/company.actions";
import { socketEventInvestors } from "./actions/investors.actions";
import { socketEventTransactions } from "./actions/transaction.actions";
import { socketEventMeetings } from "./actions/meetings.actions";
import { socketEventGroups } from "./actions/groups.actions";
import { socketEventMeetingTemplates } from "./actions/meeting-templates.actions";
import { socketHandleNotifications } from "./actions/notifications.actions";
import { socketEventVotings } from "./actions/votings.actions";
import { socketEventAttachments } from "./actions/attachments.actions";

export default (url) => {
	return (store) => {
		const socket = io(url);

		socket.on("socket", (msg) => {
			store.dispatch(notifySocketId(msg.id));
		});

		socket.on("bankid", (msg) => {
			store.dispatch(notifyBankId(msg));
		});

		socket.on("events", (msg) => {
			// Documents
			store.dispatch(socketEventDocuments(msg));
			store.dispatch(socketEventFolders(msg));

			// Tasks
			store.dispatch(socketEventTasks(msg));
			store.dispatch(socketEventProjects(msg));

			// Tags
			store.dispatch(socketEventTags(msg));

			// Comments
			store.dispatch(socketEventComments(msg));

			// Company
			store.dispatch(socketEventCompany(msg));

			// Investors
			store.dispatch(socketEventInvestors(msg));

			// Transactions
			store.dispatch(socketEventTransactions(msg));

			// Meetings
			store.dispatch(socketEventMeetings(msg));
			store.dispatch(socketEventGroups(msg));
			store.dispatch(socketEventMeetingTemplates(msg));

			// Votings
			store.dispatch(socketEventVotings(msg));

			// Attachments
			store.dispatch(socketEventAttachments(msg));
		});

		socket.on("notifications", (msg) => {
			store.dispatch(socketHandleNotifications(msg));
		});

		return (next) => (action) => {
			if (action.isSocketAction) {
				socket.emit(action.type, { ...action.payload, socketId: socket.id });
				return;
			}

			return next(action);
		};
	};
};
