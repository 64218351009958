import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import { Margin } from "styled-components-spacing";
import Moment from "../../../../modules/moment.module";
import {
	createAttendeeObject,
	normalizeAttendeeObject,
} from "../../../../components/helpers/meeting.helper.pure";

import Text from "../../../../dumb-components/shared/text/text";

import { saveMeeting } from "../../../../actions/meetings.actions";

class AddAttendeesFromPreviousMeetingContainer extends Component {
	state = {
		lastCreatedMeeting: null,
	};

	componentDidMount = () => {
		this.setLastCreatedMeeting();
	};

	componentDidUpdate = (prevProps) => {
		const { hasPreviousMeetings, meetingId } = this.props;

		if (
			hasPreviousMeetings &&
			prevProps.hasPreviousMeetings !== hasPreviousMeetings
		) {
			this.setLastCreatedMeeting();
		}

		if (meetingId && prevProps.meetingId !== meetingId) {
			this.setLastCreatedMeeting();
		}
	};

	setLastCreatedMeeting = () => {
		const { allMeetings, meeting, meetingId } = this.props;
		const meetingsInGroup = allMeetings.filter(
			(m) => !m.get("isDeleted") && m.get("groupId") === meeting.get("groupId"),
		);

		// Only one meeting in group
		if (meetingsInGroup.size === 1) {
			this.setState({ lastCreatedMeeting: null });
			return;
		}

		// Get last created meeting that is not deleted. Can be null
		const lastCreatedMeeting = meetingsInGroup
			.filter((m) => m.get("id") !== meetingId)
			.sort(
				(m1, m2) => Moment(m1.get("createdAt")) - Moment(m2.get("createdAt")),
			)
			.last();

		this.setState({ lastCreatedMeeting });
	};

	applyFromLastMeeting = () => {
		const { saveMeeting, addressbook } = this.props;
		const { lastCreatedMeeting } = this.state;
		let { meeting } = this.props;

		const lastChairman = lastCreatedMeeting.get("chairman");
		const lastSecretary = lastCreatedMeeting.get("secretary");

		if (lastChairman) {
			meeting = meeting.set("chairman", lastChairman);
		}

		if (lastSecretary) {
			meeting = meeting.set("secretary", lastSecretary);
		}

		// Get and normalize previous attendees
		const previousAttendees = (
			lastCreatedMeeting.get("attendees", Map()) || Map()
		).map((attendee, attendeeId) => {
			const importedFromAddressbook = attendee.get("importedFromAddressbook");
			const isGuest = attendee.get("isGuest");
			const name = attendee.get("name");
			const email = attendee.get("email");
			const isInvestor = attendee.get("isInvestor");
			const investmentId = attendee.get("investmentId");
			const index = attendee.get("index");
			let newAttendee = createAttendeeObject(attendeeId, isGuest);

			newAttendee = newAttendee.set("name", name);
			newAttendee = newAttendee.set("email", email);

			if (importedFromAddressbook) {
				newAttendee = newAttendee.set(
					"importedFromAddressbook",
					importedFromAddressbook,
				);
			}

			if (isInvestor) {
				newAttendee = newAttendee.set("isInvestor", isInvestor);
			}

			if (investmentId) {
				newAttendee = newAttendee.set("investmentId", investmentId);
			}

			if (index !== undefined) {
				newAttendee = newAttendee.set("index", index);
			}

			if (
				newAttendee.get("importedFromAddressbook") === true &&
				!addressbook.some(
					(entry) => entry.get("email") === newAttendee.get("email"),
				)
			) {
				let largestIndex = 0;
				let guestAttendees = (
					lastCreatedMeeting.get("attendees", Map()) || Map()
				).filter((a) => a.get("isGuest") && !a.has("importedFromAddressbook"));

				if (guestAttendees) {
					const lastGuestAttendee = guestAttendees
						.sort((a, b) => a.get("index") > b.get("index"))
						.last();

					if (lastGuestAttendee) {
						largestIndex = lastGuestAttendee.get("index");
					}
				}

				newAttendee = newAttendee.remove("importedFromAddressbook");
				newAttendee = newAttendee.set("index", largestIndex || 0);
			}

			return normalizeAttendeeObject(newAttendee.get("id"), newAttendee);
		});

		meeting = meeting.set("attendees", previousAttendees);

		saveMeeting(meeting);
	};

	render = () => {
		const { hasExtendedRights, meeting } = this.props;
		const { lastCreatedMeeting } = this.state;
		const hasAttendees = meeting.get("attendees", Map()).size > 0;

		if (!hasExtendedRights || !lastCreatedMeeting || hasAttendees) {
			return null;
		}

		return (
			<Margin top={3}>
				<Text>
					<Text tid="meetings.attendess.add_attendees_from_previous_meeting.info_1" />
					&nbsp;
					<Text
						tid="meetings.attendess.add_attendees_from_previous_meeting.info_2"
						onClick={this.applyFromLastMeeting}
						hasUnderline
					/>
				</Text>
			</Margin>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		hasPreviousMeetings: (
			store.meetings.get("allMeetings", Map()) || Map()
		).some(
			(m) =>
				!m.get("isDeleted") &&
				m.get("groupId") === store.meetings.getIn(["meeting", "groupId"]),
		),
		allMeetings: store.meetings.get("allMeetings", Map()) || Map(),
		meeting: store.meetings.get("meeting", Map()) || Map(),
		meetingId: store.meetings.getIn(["meeting", "id"]),
		hasExtendedRights: store.meetings.getIn([
			"meeting",
			"computedValues",
			"hasExtendedRights",
		]),
		addressbook: store.user.getIn(["userObj", "addressBook"]),
	};
};

const mapActionsToProps = {
	saveMeeting,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(AddAttendeesFromPreviousMeetingContainer);
