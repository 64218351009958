import React, { Component } from "react";
import { func } from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";

import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";

import { changePublishingType } from "../../../actions/meetings.actions";
import {
	saveDocument,
	updateDocumentLocal,
} from "../../../actions/documents.actions";
import { PROTOCOL_PUBLISHING_TYPE_ESIGNING } from "/shared/constants";

class CancelEsignOrPublishProcessItem extends Component {
	static propTypes = {
		onClick: func,
	};

	cancelProcess = () => {
		const { changePublishingType, meetingId, onClick } = this.props;
		onClick && onClick();
		changePublishingType(meetingId, null);

		this.removeEsigneesIfNeeded();
	};

	removeEsigneesIfNeeded = () => {
		const { saveDocument, updateDocumentLocal, publishingType, doc } =
			this.props;

		if (publishingType === PROTOCOL_PUBLISHING_TYPE_ESIGNING) {
			const eSignees = doc.get("eSignees", Map());

			if (eSignees.size) {
				const resetedDoc = doc.set("eSignees", Map());
				updateDocumentLocal(resetedDoc);
				saveDocument(resetedDoc);
			}
		}
	};

	render = () => {
		const { eSigningPending } = this.props;

		if (eSigningPending) {
			return null;
		}

		return (
			<DropdownIconItem
				icon="faTimes"
				tid="meetings.protocol.sign_or_publish.common.cancel_process"
				onClick={this.cancelProcess}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		doc: store.documents.get("document"),
		meetingId: store.meetings.getIn(["meeting", "id"]),
		publishingType: store.meetings.getIn(["meeting", "publishingType"]),
		eSigningPending: store.documents.getIn(["document", "eSigning"]),
	};
};

const mapActionsToProps = {
	changePublishingType,
	saveDocument,
	updateDocumentLocal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(CancelEsignOrPublishProcessItem);
