import React, { Component } from "react";
import { connect } from "react-redux";
import { string, func } from "prop-types";

import { closeModal } from "../../actions/modals.actions";
import { SHAREBOOK_FILTER_BY_TRANSACION } from "../../constants/modals";

import FilterByCertificateModal from "../../dumb-components/dashboard/filter-by-certificate-modal/filter-by-certificate-modal";

class FilterByCertificateModalContainer extends Component {
	state = {
		selectedId: null,
	};

	static propTypes = {
		onChangeTransaction: func,
		selectedTransactionId: string,
		lastTransactionId: string,
	};

	componentDidMount = () => {
		const { selectedTransactionId } = this.props;

		this.setState({ selectedId: selectedTransactionId });
	};

	componentDidUpdate = (prevProps) => {
		const { selectedTransactionId } = this.props;

		if (selectedTransactionId !== prevProps.selectedTransactionId) {
			this.setState({ selectedId: selectedTransactionId });
		}
	};

	onSelect = (item) => {
		this.setState({ selectedId: item.get("id") });
	};

	onApply = () => {
		const { onChangeTransaction, closeModal, lastTransactionId } = this.props;
		const { selectedId } = this.state;

		if (selectedId === lastTransactionId) {
			this.revertToDefault();
			return;
		}

		onChangeTransaction && onChangeTransaction(selectedId);

		closeModal();
	};

	revertToDefault = () => {
		const { onChangeTransaction, closeModal, lastTransactionId } = this.props;

		this.setState({ selectedId: lastTransactionId });

		onChangeTransaction && onChangeTransaction(null);

		closeModal();
	};

	render = () => {
		const { isOpen, transactions, lastTransactionId } = this.props;
		const { selectedId } = this.state;

		return (
			<FilterByCertificateModal
				isOpen={isOpen}
				onRevert={this.revertToDefault}
				onClose={this.onApply}
				onSelect={this.onSelect}
				selectedId={selectedId || lastTransactionId}
				transactions={transactions}
				hideReset={!selectedId || selectedId === lastTransactionId}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		isOpen:
			store.modals.getIn(["activeModal", "name"]) ===
			SHAREBOOK_FILTER_BY_TRANSACION,
		transactions: store.transaction.get("transactions"),
	};
};

const mapActionsToProps = {
	closeModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(FilterByCertificateModalContainer);
