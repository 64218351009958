import { SET_PERSONAL_DOCUMENTS } from "./types";
import personalDocs from "../services/personal-documents";
import { addErrorNotification } from "./notify.actions";

export const fetchPersonalDocuments = () => async (dispatch, getState) => {
	const docs = await personalDocs.getPersonalDocuments();
	const sortFunc = (a, b) =>
		a.name.localeCompare(b.name, getState().i18n.language);
	const sortDocuments = (documents) => {
		const folders = documents.children.filter(({ isFolder }) => isFolder);
		const files = documents.children.filter(({ isFolder }) => !isFolder);

		return {
			...documents,
			children: [
				...files.sort(sortFunc).map((c) => sortDocuments(c)),
				...folders.sort(sortFunc).map((c) => sortDocuments(c)),
			],
		};
	};

	dispatch({ type: SET_PERSONAL_DOCUMENTS, payload: sortDocuments(docs) });
};

export const uploadPersonalDocuments =
	(prefix, documents) => async (dispatch) => {
		await personalDocs.uploadPersonalDocuments(prefix, documents);
		await dispatch(fetchPersonalDocuments());
	};

export const uploadPersonalDocumentsFolder =
	(prefix, folderName) => async (dispatch) => {
		await personalDocs.uploadPersonalDocumentsFolder(prefix, folderName);
		await dispatch(fetchPersonalDocuments());
	};

export const deletePersonalDocument = (absolutePath) => async (dispatch) => {
	await personalDocs.deletePersonalDocument(encodeURIComponent(absolutePath));
	await dispatch(fetchPersonalDocuments());
};

export const downloadPersonalDocument = async (fileName) => {
	await personalDocs.downloadPersonalDocument(fileName);
};

export const getSignedUrl = (fileName, onSignedUrlDownloaded) => async () => {
	const signedUrl = await personalDocs.getSignedUrl(fileName);

	if (typeof onSignedUrlDownloaded === "function") {
		onSignedUrlDownloaded(signedUrl.url);
	}
};

export const deletePersonalDocumentsFolder =
	(absolutePath) => async (dispatch) => {
		await personalDocs.deletePersonalDocumentsFolder(absolutePath);
		await dispatch(fetchPersonalDocuments());
	};

export const movePersonalDocument = (from, to) => async (dispatch) => {
	await personalDocs.movePersonalDocument(from, to);
	await dispatch(fetchPersonalDocuments());
};

export const movePersonalFolder = (from, to) => async (dispatch) => {
	try {
		await personalDocs.movePersonalFolder(from, to);
		await dispatch(fetchPersonalDocuments());
	} catch (e) {
		dispatch(addErrorNotification({ text: e.message }));
	}
};

export const uploadPersonalDocumentToCompanyFolder =
	(companyId, targetFolder, documentPath) => async (dispatch) => {
		try {
			await personalDocs.uploadPersonalDocumentToCompanyFolder(
				companyId,
				targetFolder,
				documentPath,
			);
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const copyDocumentToPersonalDocuments =
	(documentId, sourceBucket, fileName, sourceFilePath, targetFolder) =>
	async (dispatch, getState) => {
		const companyId =
			getState().company.company?.id ||
			getState().capTableDetails.getIn([
				"combinedSingleInvestmentAndAllTransactions",
				"investedInCompanyId",
			]);

		await personalDocs.copyDocumentToPersonalDocuments({
			documentId,
			sourceBucket,
			fileName,
			sourceFilePath,
			targetFolder,
			companyId: companyId,
		});
	};
