import React, { PureComponent } from "react";
import { string, func } from "prop-types";
import userImage from "../../../../assets/images/onboarding/private-shareholder-notification.png";

import LeftRightLayout, {
	Header,
	Entry,
} from "../sub-modules/left-right-layout";
import Button from "../../../shared/button/button";

export default class WelcomeShareholderUser extends PureComponent {
	static propTypes = {
		companyName: string,
		onGoToInvestments: func,
	};

	renderGoToInvestmentsButton = () => {
		const { onGoToInvestments } = this.props;

		return (
			<Button
				mode="primary"
				onClick={onGoToInvestments}
				tid="onboarding.modal.new_shareholder.btn.1"
			/>
		);
	};

	render = () => {
		const { companyName } = this.props;

		return (
			<LeftRightLayout image={userImage}>
				<Header tid="onboarding.modal.shared.welcome_to_invono" />

				<Entry
					titleTid="onboarding.modal.new_shareholder.subtitle.1"
					titleValues={{ companyName }}
					subTextTid="onboarding.modal.new_shareholder.text.1"
					bottomComponent={this.renderGoToInvestmentsButton()}
				/>

				<Entry
					titleTid="onboarding.modal.new_shareholder.subtitle.2"
					subTextTid="onboarding.modal.new_shareholder.text.2"
				/>

				<Entry
					titleTid="onboarding.modal.shared.knowledge_base.subtitle"
					subTextTid="onboarding.modal.shared.knowledge_base.text"
				/>
			</LeftRightLayout>
		);
	};
}
