import React, { Component } from "react";
import { connect } from "react-redux";
import { fromJS, List } from "immutable";

import GenericInfoPanel from "../../../dumb-components/company/comapny-dashboard/generic-info-panel/generic-info-panel";
import { fetchDocumentsCount } from "../../../actions/documents.actions";
import { File } from "lucide-react";

class DocumentsPanelContainer extends Component {
	state = {
		isLoading: true,
		data: List(),
	};

	componentDidMount = () => {
		this.countDocuments();
	};

	componentDidUpdate = (prevProps) => {
		const { companyId } = this.props;

		if (prevProps.companyId !== companyId) {
			this.setState({ isLoading: true });
			this.countDocuments();
		}
	};

	countDocuments = () => {
		this.props.fetchDocumentsCount((response) => {
			const uploadedDocuments = response.get("numOfDocuments", "-").toString();
			const signedDocuments = response
				.get("numOfSignedDocuments", "-")
				.toString();

			this.setState({
				data: fromJS([
					{
						title: "company.dashboard.documents.panel.uploaded",
						value: uploadedDocuments,
					},
					{
						title: "company.dashboard.documents.panel.signed",
						value: signedDocuments,
					},
				]),
				isLoading: false,
			});
		});
	};

	render = () => {
		const { isLoading, data } = this.state;

		return (
			<GenericInfoPanel
				icon={<File />}
				title="company.dashboard.documents.panel.title"
				data={data}
				isLoading={isLoading}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		companyId: store.company.company.id,
	};
};

const mapActionsToProps = {
	fetchDocumentsCount,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(DocumentsPanelContainer);
