import React, { PureComponent } from "react";
import immutablePropTypes from "react-immutable-proptypes";
import { func, bool } from "prop-types";
import styled, { css } from "styled-components";
import { Padding, Margin } from "styled-components-spacing";
import { lighten, rgba } from "polished";
import moment from "../../../../modules/moment.module";
import Panel from "../../../shared/panel/panel";
import Button from "../../../shared/button/button";
import Box from "../../../shared/layout/box/box";
import Text from "../../../shared/text/text";
import Icon from "../../../shared/icon/icon";

const StyledProtocolSummary = styled.div`
	display: flex;
	background: ${(props) =>
		!props.nakedStyle ? props.theme.colors.solitudeLight : "transparent"};
	align-items: center;
	width: 100%;
	height: 50px;
	border: ${(props) =>
		props.nakedStyle
			? "none"
			: `1px solid ${(props) => lighten(0.2, props.theme.colors.lightGrey)}`};

	${(props) =>
		props.nakedStyle &&
		css`
			border-bottom: 1px solid ${() => rgba("#d0d0d0", 0.2)};
		`}
`;

const StyledDate = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-right: ${(props) =>
		props.nakedStyle
			? "none"
			: `1px solid ${(props) => lighten(0.2, props.theme.colors.lightGrey)}`};
	flex-basis: 20%;
	max-width: 20%;
	padding: ${(props) => props.theme.spacing[3]};
`;

const StyledContent = styled.div`
	display: flex;
	flex: 0 1 auto;
	flex-direction: column;
	width: 50%;
	height: 100%;
	padding: ${(props) => props.theme.spacing[3]};
`;

const StyledMeetingName = styled.div`
	display: flex;
	flex: 0 1 auto;
	min-width: 0;
`;

const StyledMeetingType = styled.div`
	display: flex;
	flex: 0 1 auto;
	min-width: 0;
`;

const StyledDropdownWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-basis: 30%;
	max-width: 30%;
	padding: ${(props) => props.theme.spacing[3]};
`;

export default class PreviousMeetingPanel extends PureComponent {
	static propTypes = {
		selectedMeeting: immutablePropTypes.map,
		meetingTemplate: immutablePropTypes.map,
		onOpenModal: func,
		onOpenProtocol: func,
		renderDropdownContainer: func,
		readOnly: bool,
		nakedStyle: bool,
	};

	renderOpenModalButton = () => {
		const { onOpenModal, readOnly } = this.props;

		if (readOnly) {
			return null;
		}

		return (
			<Box alignItems="center">
				<Padding all={5}>
					<Button
						mode="primary"
						onClick={onOpenModal}
						tid="meetings.previous_meeting.select_previous_meetings_protocol"
					/>
				</Padding>
			</Box>
		);
	};

	renderProtocolSummary = () => {
		const {
			selectedMeeting,
			meetingTemplate,
			onOpenProtocol,
			renderDropdownContainer,
			readOnly,
			nakedStyle,
		} = this.props;
		const meetingName = selectedMeeting ? selectedMeeting.get("name") : null;
		const startDate = selectedMeeting ? selectedMeeting.get("startDate") : null;
		const endDate = selectedMeeting ? selectedMeeting.get("endDate") : null;
		const meetingType = meetingTemplate ? meetingTemplate.get("name") : "";
		let scheduledAt;

		if (startDate || endDate) {
			scheduledAt = "@ ";
			if (startDate) {
				scheduledAt += moment(startDate).format("LT");
			}

			scheduledAt += " - ";

			if (endDate) {
				scheduledAt += moment(endDate).format("LT");
			}
		}

		return (
			<Padding
				vertical={nakedStyle ? undefined : 5}
				horizontal={nakedStyle ? undefined : 4}
			>
				<StyledProtocolSummary nakedStyle={nakedStyle}>
					<StyledDate nakedStyle={nakedStyle}>
						<Text>{startDate ? moment(startDate).format("ll") : "-"}</Text>
					</StyledDate>

					<StyledContent>
						<StyledMeetingName>
							<Text singleLine>{meetingName ? meetingName : "-"}</Text>
						</StyledMeetingName>
						<StyledMeetingType>
							<Text color="muted" singleLine>
								{meetingType} {scheduledAt}
							</Text>
						</StyledMeetingType>
					</StyledContent>

					<StyledDropdownWrapper>
						<Button
							onClick={onOpenProtocol}
							mode={nakedStyle ? "transparent-icon" : undefined}
							icon={nakedStyle ? "faExpandArrowsAlt" : undefined}
							transparentIconButtonSize={nakedStyle ? "sml" : undefined}
						>
							{!nakedStyle && (
								<>
									<Margin right={3}>
										<Icon icon="faExpandArrowsAlt" size="xs" />
									</Margin>
									<Text tid="meetings.protocol_inline_preview.open" />
								</>
							)}
						</Button>

						{!readOnly && renderDropdownContainer()}
					</StyledDropdownWrapper>
				</StyledProtocolSummary>
			</Padding>
		);
	};

	render = () => {
		const { selectedMeeting, nakedStyle } = this.props;

		return (
			<Panel
				title="meeting.previous_meeting.previous_meetings_protocol"
				nakedStyle={nakedStyle}
				marginBottom
			>
				{!selectedMeeting && this.renderOpenModalButton()}
				{selectedMeeting && this.renderProtocolSummary()}
			</Panel>
		);
	};
}
