import React, { PureComponent } from "react";
import { string, node, object } from "prop-types";
import styled, { css } from "styled-components";
import ScrollView from "../../../shared/layout/scroll-view/scroll-view";

import Text from "../../../shared/text/text";

const Wrapper = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
`;

const Column = styled.div`
	display: flex;
	flex: 1;
`;

const ColumnInner = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 0 ${(props) => props.theme.spacing[6]};
	padding-bottom: ${(props) => props.theme.spacing[5]};
`;

const InfoBlock = styled.div`
	h3:first-child {
		margin-top: ${(props) => props.theme.spacing[5]};
	}
`;

const SubText = styled.div`
	${(props) => css`
		margin-bottom: ${props.marginBottom
			? props.theme.spacing[props.marginBottom]
			: 0};
	`}
`;

/**
 * Exported sub components
 */

export const Entry = ({
	titleTid,
	titleValues,
	subTextTid,
	subTextValues,
	bottomComponent,
	children,
}) => {
	return (
		<InfoBlock>
			<Text tag="h3" tid={titleTid} values={titleValues} />

			<SubText marginBottom={bottomComponent ? 3 : 0}>
				{children ? children : <Text tid={subTextTid} values={subTextValues} />}
			</SubText>

			{bottomComponent && bottomComponent}
		</InfoBlock>
	);
};

export default class RightColumnOnlyLayout extends PureComponent {
	static propTypes = {
		children: node,
		titleTid: string,
		titleValues: object,
		subTextTid: string,
		subTextValues: object,
		bottomComponent: object,
	};

	render = () => {
		const { children } = this.props;

		return (
			<Wrapper>
				<Column>
					<ScrollView noRightMargin noLeftMargin fillContent alwaysShow>
						<ColumnInner>{children}</ColumnInner>
					</ScrollView>
				</Column>
			</Wrapper>
		);
	};
}
