import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
	deleteInvitee,
	sendInvitationReminder,
} from "../../../../actions/emissions.actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SendIcon from "@mui/icons-material/Send";

const InviteeContextMenu = (props) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = !!anchorEl;
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	function mailTo(email) {
		window.location.href = "mailto:" + email;
	}

	return (
		<>
			<Tooltip title={t("emissions.invitations.more-options")}>
				<IconButton onClick={handleClick}>
					<MoreVertIcon sx={{ color: "grey.700" }} />
				</IconButton>
			</Tooltip>

			<Menu
				anchorEl={anchorEl}
				id="invitees-menu"
				open={open}
				onClose={handleClose}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<MenuItem onClick={() => mailTo(props.email)}>
					<ListItemIcon>
						<MailOutlineIcon />
					</ListItemIcon>
					{t("emissions.invitations.email_person")}
				</MenuItem>

				<MenuItem
					onClick={() => {
						dispatch(
							sendInvitationReminder(
								props.companyId,
								props.emissionId,
								props.inviteeId,
							),
						);
					}}
				>
					<ListItemIcon>
						<SendIcon />
					</ListItemIcon>
					{t("emissions.invitations.email.reminder")}
				</MenuItem>

				<MenuItem
					onClick={() => {
						dispatch(
							deleteInvitee(props.companyId, props.emissionId, props.inviteeId),
						);
					}}
				>
					<ListItemIcon>
						<DeleteOutlineIcon fontSize="small" />
					</ListItemIcon>
					{t("emissions.invitations.remove-invitee")}
				</MenuItem>
			</Menu>
		</>
	);
};

export default InviteeContextMenu;
