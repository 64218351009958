import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { bool, oneOf, shape, string } from "prop-types";
import QRCode from "react-qr-code";
import bankIdImg from "../../../assets/images/bankid-logo.png";
import bankIdImgColor from "../../../assets/images/bankid.svg";
import Text from "../text/text";
import Loader from "../loader/loader";
import Button from "../button/button";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${(props) => props.theme.spacing[4]};

	${(props) =>
		props.bFill &&
		css`
			width: 100%;
			height: 100%;
		`}
`;

const StyledImage = styled.img`
	width: ${(props) => props.size};
`;

const QRCodeContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 180px;
	width: 180px;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	line-height: 0;
`;

const BankId = ({
	size,
	mode,
	fill,
	coloredLogo,
	forceShow,
	noMarginTop,
	bankIdInfo: { desktopAppLink, qrLink, bankIdMessage, status },
}) => {
	const [desktopSignInMode, setDesktopSignInMode] = useState(false);
	const image = coloredLogo ? bankIdImgColor : bankIdImg;

	const showQRCode = status !== "failed" && !desktopSignInMode;
	const showOpenDesktopButton =
		status !== "failed" && desktopAppLink && !desktopSignInMode;

	useEffect(() => {
		if (typeof status !== "string") setDesktopSignInMode(false);
	}, [status]);

	if (!bankIdMessage && !forceShow) {
		return null;
	}

	const onClickOpenDesktopApp = () => {
		setDesktopSignInMode(true);
		window.location = desktopAppLink;
	};

	return (
		<Container bFill={fill} noMarginTop={noMarginTop}>
			<StyledImage src={image} alt="bankid logo" size={size} />
			{bankIdMessage && (
				<Text
					tid={bankIdMessage}
					color={mode === "dark" ? "midGrey" : "white"}
					align="center"
				/>
			)}
			{showQRCode && (
				<QRCodeContainer>
					{qrLink ? (
						<QRCode
							value={qrLink}
							level="L"
							size={180}
							style={{ height: "auto", maxWidth: "100%", width: "100%" }}
							viewBox="0 0 180 180"
						/>
					) : (
						<Loader />
					)}
				</QRCodeContainer>
			)}
			{showOpenDesktopButton && (
				<Button
					onClick={onClickOpenDesktopApp}
					tid="credentials_signin_bankid.open_on_this_device"
				/>
			)}
		</Container>
	);
};

BankId.propTypes = {
	bankIdInfo: shape({
		orderRef: string,
		desktopAppLink: string,
		qrLink: string,
		bankIdMessage: string,
		status: string,
	}),
	size: string,
	fill: bool,
	coloredLogo: bool,
	forceShow: bool,
	mode: oneOf(["dark"]),
	noMarginTop: bool,
};

BankId.defaultProps = {
	bankIdInfo: {},
	size: "50px",
	fill: true,
	coloredLogo: false,
	forceShow: false,
	noMarginTop: false,
};

export default BankId;
