import { useState } from "react";
import { useSelector } from "react-redux";

export const useShareType = (emission) => {
	const isCustom = emission.isCustomShareType;

	const shareTypes = useSelector((state) => state.emissions.shareTypes);

	const [shareType, setShareType] = useState(
		isCustom ||
			emission.shareTypeName === null ||
			emission.shareTypeName === undefined
			? ""
			: `${emission.shareTypeName} ${
					emission.shareTypeSeries ? emission.shareTypeSeries : ""
			  }`.trim(),
	);

	return {
		shareType,
		setShareType,
		shareTypes,
	};
};
