import React, { Component } from "react";
import { connect } from "react-redux";
import { object } from "prop-types";
import { Map } from "immutable";

import { unpublishProtocol } from "../../../../../actions/meetings.actions";
import { fetchDocument } from "../../../../../actions/documents.actions";
import { MEETINGS_STATUS_PROTOCOL_PUBLISHED } from "../../../../../constants/meetings";
import { MEETING_TYPE_STANDARD } from "/shared/constants";

import DropdownIconItem from "../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import { closeModal, openModal } from "../../../../../actions/modals.actions";
import { MEETINGS_UNPUBLISH_PROTOCOL_MODAL } from "../../../../../constants/modals";

class UnpublishProtocolDropdownItemContainer extends Component {
	static propTypes = {
		dropdownRef: object,
	};

	onUnpublish = () => {
		const {
			dropdownRef,
			protocolSigned,
			openModal,
			meeting,
			unpublishProtocol,
			fetchDocument,
			protocolDocumentId,
		} = this.props;

		dropdownRef && dropdownRef.onToggleMenu();

		if (protocolSigned) {
			openModal(MEETINGS_UNPUBLISH_PROTOCOL_MODAL);
		} else {
			unpublishProtocol(meeting, () => {
				fetchDocument(protocolDocumentId);
			});
		}
	};

	render = () => {
		const { meeting, meetingIsDeleted } = this.props;
		const isStandardMeeting =
			meeting.get("meetingType") === MEETING_TYPE_STANDARD;
		const status = meeting.get("status");

		if (status !== MEETINGS_STATUS_PROTOCOL_PUBLISHED || isStandardMeeting) {
			return null;
		}

		return (
			<DropdownIconItem
				icon="faFileEdit"
				onClick={this.onUnpublish}
				tid="meetings.protocol_inline_preview.unpublish_and_edit"
				disabled={meetingIsDeleted}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get("meeting", Map()) || Map(),
		meetingIsDeleted: store.meetings.getIn(["meeting", "isDeleted"]),
		protocolSigned: store.meetings.getIn(["meeting", "protocolSigned"], false),
		protocolDocumentId: store.meetings.getIn([
			"meeting",
			"protocolData",
			"documentId",
		]),
	};
};

const mapActionsToProps = {
	unpublishProtocol,
	fetchDocument,
	openModal,
	closeModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(UnpublishProtocolDropdownItemContainer);
