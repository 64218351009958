import React, { useRef } from "react";
import DialogModal from "@/components/dialogModal";
import BillingDetailsForm from "@/components/subscriptions/billingDetailsForm";
import StripeElements from "./stripeElements";
import { useTranslation } from "react-i18next";
import useCompanyIdFromUrl from "@/hooks/useCompanyIdFromUrl";
import useUpdateStripeCustomer from "@/hooks/useUpdateStripeCustomer";
import LoadingPanel from "../loadingPanel";
import useSubscription from "@/hooks/useSubscription";

const BillingDetailsModal = ({ onClose }) => {
	const { t } = useTranslation();
	const companyId = useCompanyIdFromUrl();

	const {
		mutateAsync: updateStripeCustomer,
		isPending: isUpdatingStripeCustomer,
	} = useUpdateStripeCustomer(companyId);

	const { data: subscriptionRequest, isLoading: isLoadingSubscription } =
		useSubscription();
	const subscription = subscriptionRequest?.data;
	const customerAddress = subscription?.stripeData?.address;
	const customerName = subscription?.stripeData?.name;

	const valuesRef = useRef(null);

	return (
		<DialogModal
			title={t("subscriptions.billing_details.edit")}
			size="sm"
			onCancel={onClose}
			onSubmit={async () => {
				const stripeElements = valuesRef.current;
				const addressElement = stripeElements?.getElement("address");
				const addressValues = await addressElement?.getValue();
				const result = await stripeElements.submit();

				if (result?.error) return;

				await updateStripeCustomer({
					address: addressValues?.value?.address,
				});

				onClose();
			}}
			submitText={t("subscriptions.billing_details.save")}
			isSubmitting={isUpdatingStripeCustomer}
			bodyRenderer={() =>
				isLoadingSubscription ? (
					<LoadingPanel />
				) : (
					<StripeElements setupIntent={{}}>
						<BillingDetailsForm
							valuesRef={valuesRef}
							defaultValues={{ name: customerName, address: customerAddress }}
						/>
					</StripeElements>
				)
			}
		/>
	);
};

BillingDetailsModal.propTypes = {
	onClose: DialogModal.propTypes.onCancel,
};

export default BillingDetailsModal;
