import React, { PureComponent } from "react";
import { array } from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	height: 100%;
	padding: ${({ theme }) => theme.spacing[5]} 0;
`;

const Content = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
`;

const LeftView = styled.div`
	flex: 1;
	margin-right: ${({ theme }) => theme.spacing[3]};
	margin-left: ${({ theme }) => theme.spacing[6]};
`;

const RightView = styled.div`
	flex: 1;
	margin-right: ${({ theme }) => theme.spacing[6]};
	margin-left: ${({ theme }) => theme.spacing[3]};
`;

export default class GeneratePdfAndNotifyComboView extends PureComponent {
	static propTypes = {
		children: array,
	};

	render = () => {
		const { children } = this.props;

		return (
			<Wrapper>
				<LeftView>
					<Content>{children[0]}</Content>
				</LeftView>

				<RightView>
					<Content>{children[1]}</Content>
				</RightView>
			</Wrapper>
		);
	};
}
