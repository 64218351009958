import React, { Component } from "react";
import { connect } from "react-redux";
import { object } from "prop-types";
import { Map, List } from "immutable";
import DocumentsInvestorsView from "../../dumb-components/documents/documents-investors-view/documents-investors-view";
import ItemTreeContainer from "../shared/item-tree.container";
import {
	listMirroredFolders,
	listShareholderPreviewMirroredFolders,
} from "../../actions/folders.actions";
import {
	listMirroredDocuments,
	downloadDocumentPublic,
	listShareholderPreviewMirroredDocuments,
} from "../../actions/documents.actions";
import folderHelper from "../../components/helpers/folder.helper";
import i18nhelper from "../../components/helpers/i18n.helper";
import Badge from "../../dumb-components/shared/badge/badge";
import documentsHelper from "../../components/helpers/documents.helper";
import { getQuery } from "../../interfaces/history";
import {
	EVENT_TYPE_DOCUMENT_UPDATE,
	EVENT_TYPE_DOCUMENT_DELETE,
	EVENT_TYPE_DOCUMENT_TRANSFER,
	EVENT_TYPE_FOLDER_CREATE,
	EVENT_TYPE_FOLDER_UPDATE,
	EVENT_TYPE_FOLDER_DELETE,
} from "/shared/constants";
import CopyDocumentToPersonalDocumentsModal from "./copy-document-to-personal-documents.container";

import { DropDown } from "../../mui-components/dropdown/DropDown";
import Box from "@mui/material/Box";
import IconButton from "../../mui-components/button/icon-button";
import { withTranslation } from "react-i18next";
import MenuItemIcon from "../../mui-components/dropdown/menu/menu-item-icon";

class DocumentListContainer extends Component {
	state = {
		selectedFolder: Map(),
	};

	static propTypes = {
		params: object,
	};

	static defaultProps = {};

	treeItemRefs = Map();

	dropdownRefs = Map();

	componentDidMount = () => {
		const { selectedInvestment } = this.props;

		if (selectedInvestment) {
			this.fetchData();
		}
	};

	componentDidUpdate = (prevProps) => {
		const { selectedInvestment } = this.props;

		this.checkLiveUpdateEvents(prevProps);

		if (selectedInvestment !== prevProps.selectedInvestment) {
			this.fetchData();
		}
	};

	checkLiveUpdateEvents = () => {
		const {
			auditDocument,
			auditFolder,
			isCompany,
			mirroredDocuments,
			mirroredFolders,
			listMirroredFolders,
			listMirroredDocuments,
			isShareholderPreview,
			listShareholderPreviewMirroredDocuments,
			listShareholderPreviewMirroredFolders,
		} = this.props;
		const selectedFolderId = this.state.selectedFolder
			? this.state.selectedFolder.get("id")
			: null;
		const companyId = this.getCompanyId();
		const query = getQuery();
		const { investmentId } = query;
		const documentUpdate = auditDocument.get(EVENT_TYPE_DOCUMENT_UPDATE);
		const documentDelete = auditDocument.get(EVENT_TYPE_DOCUMENT_DELETE);
		const documentTransfer = auditDocument.get(EVENT_TYPE_DOCUMENT_TRANSFER);
		const folderCreate = auditFolder.get(EVENT_TYPE_FOLDER_CREATE);
		const folderUpdate = auditFolder.get(EVENT_TYPE_FOLDER_UPDATE);
		const folderDelete = auditFolder.get(EVENT_TYPE_FOLDER_DELETE);

		// A mirrored document in the current document list was edited so we update the list
		if (documentUpdate && documentUpdate.get("r") === true) {
			const objId = documentUpdate.get("objId");
			const isMirrored = documentUpdate.getIn(["metadata", "isMirrored"]);
			let found = false;

			mirroredDocuments.forEach((obj) => {
				if (obj.get("id") === objId) {
					found = true;
				}
			});

			if (isMirrored || found) {
				if (isShareholderPreview) {
					listShareholderPreviewMirroredDocuments(
						companyId,
						investmentId,
						selectedFolderId,
					);
				} else {
					listMirroredDocuments(
						companyId,
						investmentId,
						selectedFolderId,
						isCompany,
					);
				}
			}
		}

		// A mirrored document in the current document list was deleted so we update the list
		if (
			documentDelete &&
			documentDelete.get("r") === true &&
			documentDelete.getIn(["metadata", "isMirrored"])
		) {
			const objId = documentDelete.get("objId");
			let found = false;

			mirroredDocuments.forEach((obj) => {
				if (obj.get("id") === objId) {
					found = true;
				}
			});

			if (found) {
				if (isShareholderPreview) {
					listShareholderPreviewMirroredDocuments(
						companyId,
						investmentId,
						selectedFolderId,
					);
				} else {
					listMirroredDocuments(
						companyId,
						investmentId,
						selectedFolderId,
						isCompany,
					);
				}
			}
		}

		// A mirrored document in the current document list was moved to another folder so we update the list
		if (
			documentTransfer &&
			documentTransfer.get("r") === true &&
			documentTransfer.getIn(["metadata", "isMirrored"])
		) {
			const objId = documentTransfer.get("objId");
			let found = false;

			mirroredDocuments.forEach((obj) => {
				if (obj.get("id") === objId) {
					found = true;
				}
			});

			if (found) {
				if (isShareholderPreview) {
					listShareholderPreviewMirroredDocuments(
						companyId,
						investmentId,
						selectedFolderId,
					);
				} else {
					listMirroredDocuments(
						companyId,
						investmentId,
						selectedFolderId,
						isCompany,
					);
				}
			}
		}

		// A folder marked as mirrored was created so we update the list
		if (
			folderCreate &&
			folderCreate.get("r") === true &&
			folderCreate.getIn(["metadata", "isMirrored"])
		) {
			if (isShareholderPreview) {
				listShareholderPreviewMirroredFolders(companyId, investmentId);
			} else {
				listMirroredFolders(companyId, investmentId, isCompany);
			}
		}

		// A mirrored folder was edited so we update the list
		if (folderUpdate && folderUpdate.get("r") === true) {
			const objId = folderUpdate.get("objId");
			const isMirrored =
				folderUpdate.getIn(["metadata", "isMirrored"]) ||
				mirroredFolders.getIn([objId, "isMirrored"]);

			if (isMirrored) {
				if (isShareholderPreview) {
					listShareholderPreviewMirroredFolders(companyId, investmentId);
					listShareholderPreviewMirroredDocuments(
						companyId,
						investmentId,
						selectedFolderId,
					);
				} else {
					listMirroredFolders(companyId, investmentId, isCompany);
					listMirroredDocuments(
						companyId,
						investmentId,
						selectedFolderId,
						isCompany,
					);
				}
			}
		}

		//A mirrored folder was deleted so we update the list
		if (
			folderDelete &&
			folderDelete.get("r") === true &&
			folderDelete.getIn(["metadata", "isMirrored"])
		) {
			if (isShareholderPreview) {
				listShareholderPreviewMirroredFolders(companyId, investmentId);
			} else {
				listMirroredFolders(companyId, investmentId, isCompany);
			}
		}
	};

	getCompanyId = () => {
		const { selectedInvestment } = this.props;

		return selectedInvestment.getIn(["investedInCompanyInformation", "id"]);
	};

	fetchData = () => {
		const {
			listMirroredFolders,
			listMirroredDocuments,
			isCompany,
			selectedInvestment,
			isShareholderPreview,
			listShareholderPreviewMirroredDocuments,
			listShareholderPreviewMirroredFolders,
		} = this.props;
		const companyId = this.getCompanyId();
		if (isShareholderPreview) {
			listShareholderPreviewMirroredDocuments(
				companyId,
				selectedInvestment.get("id"),
				null,
				isCompany,
			);
			listShareholderPreviewMirroredFolders(
				companyId,
				selectedInvestment.get("id"),
				isCompany,
			);
		} else {
			listMirroredFolders(companyId, selectedInvestment.get("id"), isCompany);
			listMirroredDocuments(
				companyId,
				selectedInvestment.get("id"),
				null,
				isCompany,
			);
		}
	};

	onFolderSelected = (folder) => {
		const {
			listMirroredDocuments,
			isCompany,
			isShareholderPreview,
			listShareholderPreviewMirroredDocuments,
		} = this.props;
		const companyId = this.getCompanyId();
		const folderId = folder.get("id");
		const query = getQuery();
		const { investmentId } = query;
		if (isShareholderPreview) {
			listShareholderPreviewMirroredDocuments(
				companyId,
				investmentId,
				folderId,
				isCompany,
			);
		} else {
			listMirroredDocuments(companyId, investmentId, folderId, isCompany);
		}

		this.setState({ selectedFolder: folder });
	};

	onDownloadFile = (documentId, getSignedVersion) => {
		const { downloadDocumentPublic, userId } = this.props;
		const companyId = this.getCompanyId();
		downloadDocumentPublic({ documentId, companyId, userId, getSignedVersion });
		this.dropdownRefs.get(documentId)?.onToggleMenu();
	};

	onOpenFile = (documentId, getSignedVersion) => {
		const { downloadDocumentPublic, userId } = this.props;
		const companyId = this.getCompanyId();
		downloadDocumentPublic({
			documentId,
			companyId,
			openInViewer: true,
			userId,
			getSignedVersion,
		});
		this.dropdownRefs.get(documentId)?.onToggleMenu();
	};

	onBadgeClick = (documentId, folderId) => {
		const { mirroredFolders } = this.props;
		const folderToSelect = mirroredFolders.get(folderId);

		this.onFolderSelected(folderToSelect);
		this.itemTreeContainerRef &&
			this.itemTreeContainerRef.onBadgeClick(folderId, true);

		// Scroll the folder into view when selected
		const selectedFolderItemRef = this.treeItemRefs.get(folderId);
		if (selectedFolderItemRef && selectedFolderItemRef.scrollIntoViewIfNeeded) {
			setTimeout(() => {
				selectedFolderItemRef.scrollIntoViewIfNeeded();
			}, 0);
		}
	};

	onTreeItemRef = (folderId, ref) => {
		if (!folderId || !ref) {
			return;
		}

		this.treeItemRefs = this.treeItemRefs.set(folderId, ref);
	};

	setDropdownRef = (id, ref) => {
		this.dropdownRefs = this.dropdownRefs.set(id, ref);
	};

	renderMoreActionMUIMenu = (documentId, getSignedVersion) => {
		const { mirroredDocuments } = this.props;
		const document = mirroredDocuments.find(
			(doc) => doc.get("id") === documentId,
		);
		const isViewableInBrowser = documentsHelper.isViewableInBrowser(
			document.get("file"),
			document.getIn(["file", "ext"]),
		);

		return (
			<Box>
				<DropDown
					alignMenu="right"
					button={({ params }) => (
						<IconButton noBorder icon="faEllipsisV" {...params} />
					)}
				>
					{isViewableInBrowser && (
						<MenuItemIcon
							onClick={this.onOpenFile.bind(null, documentId, getSignedVersion)}
							icon={"faExpandArrowsAlt"}
							listItemTid={"open_file"}
						/>
					)}

					<MenuItemIcon
						onClick={this.onDownloadFile.bind(
							null,
							documentId,
							getSignedVersion,
						)}
						icon={"faArrowAltToBottom"}
						listItemTid={"download_file"}
					/>
				</DropDown>
			</Box>
		);
	};

	renderBadge = (docId, folderId, folderPath, folderName) => {
		const {
			i18n: { language },
			company: { region },
		} = this.props;

		if (!folderName) {
			return null;
		}

		return (
			<Badge
				linkText={folderPath}
				onClick={this.onBadgeClick.bind(this, docId, folderId)}
			>
				{i18nhelper.getTranslatedValue(folderName, language, region)}
			</Badge>
		);
	};

	render = () => {
		const { selectedFolder } = this.state;
		const {
			mirroredFolders,
			selectedInvestment,
			i18n,
			mirroredDocuments,
			captableMode,
		} = this.props;

		let documents = List();

		mirroredDocuments.forEach((doc) => {
			const docFolderId = doc.get("folderId");
			const folderPath = folderHelper.getPath(docFolderId, mirroredFolders);

			if (folderPath) {
				doc = doc.set("folderPath", folderPath);
				doc = doc.set(
					"folderName",
					mirroredFolders.getIn([docFolderId, "name"]),
				);
			}

			documents = documents.push(doc);
		});

		return (
			<>
				<CopyDocumentToPersonalDocumentsModal />
				<DocumentsInvestorsView
					captableMode={captableMode}
					folders={mirroredFolders}
					documents={documents}
					selectedFolder={selectedFolder}
					userLang={i18n.language}
					onBadgeClick={this.onBadgeClick}
					region={selectedInvestment
						.getIn(["investedInCompanyInformation", "region"], Map())
						.toJS()}
					renderDropdown={this.renderMoreActionMUIMenu}
					folderTreeContainer={() => {
						return (
							<ItemTreeContainer
								onRef={(ref) => (this.itemTreeContainerRef = ref)}
								items={mirroredFolders}
								isInvestorMode={true}
								onItemSelected={this.onFolderSelected}
								selectedItem={selectedFolder}
								investedInCompany={
									selectedInvestment &&
									selectedInvestment.get("investedInCompanyInformation")
								}
								onItemRef={this.onTreeItemRef}
							/>
						);
					}}
				/>
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		mirroredFolders: store.folders.get("mirroredFolders"),
		mirroredDocuments: store.documents.get("mirroredDocuments"),
		i18n: store.i18n,
		userId: store.user.getIn(["userObj", "id"]),
		auditDocument: store.audit.get("documents"),
		auditFolder: store.audit.get("folders"),
	};
};

const mapActionsToProps = {
	downloadDocumentPublic,
	listMirroredDocuments,
	listMirroredFolders,
	listShareholderPreviewMirroredDocuments,
	listShareholderPreviewMirroredFolders,
};

export default withTranslation()(
	connect(mapStoreToProps, mapActionsToProps)(DocumentListContainer),
);
