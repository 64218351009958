import React, { PureComponent } from "react";
import { func, string, bool } from "prop-types";
import { map, list } from "react-immutable-proptypes";
import styled, { css } from "styled-components";

import Text from "../../../../dumb-components/shared/text/text";
import Icon from "../../../../dumb-components/shared/icon/icon";
import ScrollView from "../../../../dumb-components/shared/layout/scroll-view/scroll-view";
import Button from "../../../../dumb-components/shared/button/button";
import Tooltip from "../../../../dumb-components/shared/tooltip/tooltip";
import List from "../../../../dumb-components/shared/list/list";
import ListItem from "../../../../dumb-components/shared/list-item/list-item";
import { StyledListHeaderTemplate } from "../../../../dumb-components/shared/list/list-header.template";
import TopicAndQuestionPanel from "../../../../dumb-components/meetings/voting/topic-and-question-panel";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	${({ paddinglessContainer }) =>
		!paddinglessContainer &&
		css`
			padding: ${(props) => props.theme.spacing[5]}
				${(props) => props.theme.spacing[6]};
		`}
`;

/* CSS templates */
const EllipsisTemplate = css`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const MarginTemplate = css`
	@media only screen and (max-width: 800px) {
		margin: 0 ${(props) => props.theme.spacing[2]};
	}
	margin: 0 ${(props) => props.theme.spacing[3]};
`;

/* Table Layout */
const StyledHeader = styled.div`
	${StyledListHeaderTemplate}
	${EllipsisTemplate}

	> div {
		cursor: default;
	}

	@media only screen and (max-width: 550px) {
		display: none;
	}
`;

const StyledName = styled.div`
	${EllipsisTemplate}
	${MarginTemplate}
	min-width: 40px;
	flex: 1;

	@media only screen and (max-width: 550px) {
		margin-bottom: ${(props) => props.theme.spacing[2]};
	}
`;

const StyledPendingOrCompleted = styled.div`
	${EllipsisTemplate}
	${MarginTemplate}
	display: flex;
	flex-direction: row;
	align-items: center;

	/* Icon left margin */
	> :nth-child(2) {
		margin-left: ${(props) => props.theme.spacing[2]};
	}

	@media only screen and (max-width: 550px) {
		margin-bottom: ${(props) => props.theme.spacing[2]};
	}
`;

const StyledVoteControls = styled.div`
	${MarginTemplate}

	> span, > button {
		margin-left: ${(props) => props.theme.spacing[2]};
	}

	> button:first-child {
		margin-left: 0;
	}
`;

// Voting
const StyledVotingWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const StyledList = styled(List)`
	display: flex;
`;

const StyledListItem = styled(ListItem)`
	display: flex;
	align-items: center;
	border-top: 1px solid ${(props) => props.theme.colors.border};
	margin-bottom: 0;

	:first-child {
		border-top: none;
	}

	:last-child {
		border-bottom: 1px solid ${(props) => props.theme.colors.border};
	}

	@media only screen and (max-width: 550px) {
		border: 1px solid ${(props) => props.theme.colors.border};
		flex-direction: column;
		height: auto;
		padding: ${(props) => props.theme.spacing[3]};
		align-items: flex-start;
		border-radius: ${(props) => props.theme.spacing[2]};

		:first-child {
			border-top: 1px solid ${(props) => props.theme.colors.border};
		}
	}
`;

export default class VotingModal extends PureComponent {
	static propTypes = {
		voting: map,
		onVote: func,
		result: string,
		paddinglessContainer: bool,
		votingUsers: list,
		votingPendingForUsers: map,
	};

	renderHeader = () => {
		return (
			<StyledHeader>
				<StyledName>
					<Text bold={600} tid="meetings.voting_modal.vote.table.header.name" />
				</StyledName>
				<StyledVoteControls>
					<Text bold={600} tid="meetings.voting_modal.vote.table.header.vote" />
				</StyledVoteControls>
			</StyledHeader>
		);
	};

	renderVoteItem = (userId, voteType, btnColor) => {
		const { voting, onVote, votingPendingForUsers } = this.props;
		const result = voting.getIn(["votes", userId, "result"]);
		const voteIsPending = votingPendingForUsers.getIn(
			[userId, "isPending"],
			false,
		);
		const userVotedThis = result === voteType;

		return (
			<Tooltip
				tid="meetings.agm.voting_modal.tooltip.waiting_for_confirmation"
				delayShow="instant"
				place="left"
				active={voteIsPending && !userVotedThis}
			>
				<Button
					tid={`meetings.public.voting_modal.vote.${voteType}`}
					onClick={() => onVote(userId, voteType)}
					mode={result === voteType ? btnColor : undefined}
					disabled={voteIsPending}
					minWidth="50px"
					noHorizontalMargin
				/>
			</Tooltip>
		);
	};

	renderVotingItem = (user) => {
		const { voting, votingPendingForUsers } = this.props;
		const userId = user.get("id");
		const userName = user.get("name");
		const result = voting.getIn(["votes", userId, "result"]);
		const voteIsPending = votingPendingForUsers.getIn(
			[userId, "isPending"],
			false,
		);

		return (
			<StyledListItem key={userId} hoverColor="lightestGrey" striped>
				<StyledName>
					<Text singleLine>{userName}</Text>
				</StyledName>

				{(result || voteIsPending) && (
					<StyledPendingOrCompleted>
						{voteIsPending ? (
							<>
								<Text tid="meetings.agm.voting.vote_being_registered" />
								<Icon icon="faSpinner" size="sm" type="solid" spin />
							</>
						) : (
							<>
								<Text singleLine tid="meetings.agm.voting.vote_registered" />
								<Icon icon="faCheck" size="sm" type="solid" color="green" />
							</>
						)}
					</StyledPendingOrCompleted>
				)}

				<StyledVoteControls>
					{this.renderVoteItem(userId, "YES", "green")}
					{this.renderVoteItem(userId, "NO", "red")}
					{this.renderVoteItem(userId, "ABSTAIN", "orange")}
				</StyledVoteControls>
			</StyledListItem>
		);
	};

	renderVoting = () => {
		const { votingUsers, voting } = this.props;
		const showVoting = votingUsers && votingUsers.size > 0;
		const question = voting.get("question");
		const topicRef = voting.get("topicRef");

		return (
			<StyledVotingWrapper>
				<ScrollView alwaysShow>
					<TopicAndQuestionPanel topicRef={topicRef} question={question} />
					{showVoting && (
						<StyledList header={this.renderHeader}>
							{votingUsers.map(this.renderVotingItem)}
						</StyledList>
					)}
				</ScrollView>
			</StyledVotingWrapper>
		);
	};

	render = () => {
		const { paddinglessContainer } = this.props;

		return (
			<Wrapper paddinglessContainer={paddinglessContainer}>
				{this.renderVoting()}
			</Wrapper>
		);
	};
}
