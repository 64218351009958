import React, { Component } from "react";
import { connect } from "react-redux";
import { bool } from "prop-types";
import { Map, mergeDeep } from "immutable";

import StepsModal from "../../../../dumb-components/shared/modal/steps-modal";
import ModalInnerWrapper from "../../../../dumb-components/shared/modal/modal-inner-wrapper";
import PreviousLeftButton from "../../../../dumb-components/shared/modal/previous-left-button";
import FooterRightControls from "../../../../dumb-components/shared/modal/footer-right-controls";
import { TransparentButton } from "../../../../dumb-components/shared/button-v2";

import RollcallContainer from "./rollcall.container";
import DocumentsContainer from "./manage-proxy-documents.container";
import SelectProxyModalContainer from "./select-proxy-modal.container";

import { openModal, closeModal } from "../../../../actions/modals.actions";
import {
	patchMeeting,
	createProxy,
} from "../../../../actions/meetings.actions";
import { resetAttendeesFilter } from "../../../../actions/attendees.actions";

import {
	MEETINGS_ROLLCALL_MODAL,
	MEETINGS_START_STANDARD_MEETING_ALTERNATIVE_MODAL,
	MEETINGS_END_MEETING_MODAL,
} from "../../../../constants/modals";
import { TAB_TEAMS } from "../../../shared/select-user-modal/select-user-modal.constants";
import {
	MEETING_TYPE_STANDARD,
	MEETING_SUBTYPE_AGM,
	__DELETE__,
} from "/shared/constants";
import { PeopleModalProvider } from "../../../shared/people-modal/people-modal.provider";
import { PeopleModalFooter } from "../../../shared/people-modal/people-modal-footer";
import {
	PeopleModalHeader,
	getDefaultTabs,
} from "../../../shared/people-modal/people-modal-header";

const INITIAL_STATE = {
	step: 0,
	managedAttendeeId: null,
	formData: Map(),
	selectProxyActiveTab: TAB_TEAMS,
	selectProxySelectedUsers: Map(),
	rollCallDoneAndValuesHaveChanged: false,
};

class RollcallModalContainer extends Component {
	state = INITIAL_STATE;

	static propTypes = {
		hideInfo: bool,
		hideMoreActions: bool,
	};

	componentDidMount = () => {
		this.initializeState();
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { isOpen, isAGM } = this.props;
		const { step } = this.state;

		// Modal closed
		if (!isOpen && prevProps.isOpen) {
			this.initializeState();
		}

		if (isAGM !== prevProps.isAGM) {
			this.initializeState();
		}

		// Reset Proxy Data
		if (isAGM && step !== 0 && prevState.step === 0) {
			this.setState({
				selectProxyActiveTab: TAB_TEAMS,
				selectProxySelectedUsers: Map(),
			});
		}
	};

	initializeState = () => {
		const { isAGM } = this.props;

		if (isAGM) {
			this.setState({ ...INITIAL_STATE, step: 1 });
		} else {
			this.setState(INITIAL_STATE);
		}
	};

	onChange = (data) => {
		const { rollcallDone } = this.props;
		this.setState((prevState) => {
			let { formData } = prevState;
			formData = mergeDeep(formData, data);

			return { formData };
		});

		if (rollcallDone) {
			this.setState({ rollCallDoneAndValuesHaveChanged: true });
		}
	};

	openEndMeetingModal = () => {
		if (!this.props.showEndMeetingModalAfterRollcall) {
			return;
		}

		this.props.openModal(MEETINGS_END_MEETING_MODAL);
	};

	rollcallDone = () => {
		const { patchMeeting, meeting } = this.props;
		let { formData } = this.state;
		let attendees = meeting.get("attendees");

		formData = formData.setIn(["metadata", "rollcallDone"], true);

		attendees.forEach((attendee) => {
			if (!attendee.get("notified")) {
				formData = formData.setIn(
					["attendees", attendee.get("userId"), "notified"],
					true,
				);
			}

			if (!attendee.get("notifiedAboutChanges")) {
				formData = formData.setIn(
					["attendees", attendee.get("userId"), "notifiedAboutChanges"],
					true,
				);
			}
		});

		patchMeeting(meeting.get("id"), formData);

		this.openStartStandardMeetingModalIfNeededOrCloseModal();
		this.openEndMeetingModal();
	};

	patchMeeting = () => {
		const { patchMeeting, closeModal, meeting, resetAttendeesFilter } =
			this.props;
		let { formData } = this.state;
		const { rollCallDoneAndValuesHaveChanged } = this.state;

		if (rollCallDoneAndValuesHaveChanged) {
			formData = formData.setIn(["metadata", "rollcallDone"], __DELETE__);
		}

		patchMeeting(meeting.get("id"), formData);
		resetAttendeesFilter(meeting.get("id"));
		closeModal();
	};

	openStartStandardMeetingModalIfNeededOrCloseModal = () => {
		const { openModal, closeModal, isStandardMeeting, isModerator } =
			this.props;

		if (isStandardMeeting && isModerator) {
			openModal(MEETINGS_START_STANDARD_MEETING_ALTERNATIVE_MODAL);
		} else {
			closeModal();
		}
	};

	createProxy = () => {
		const { meeting, createProxy } = this.props;
		const { selectProxySelectedUsers, managedAttendeeId } = this.state;
		const meetingId = meeting.get("id");

		createProxy(meetingId, managedAttendeeId, selectProxySelectedUsers);
		this.goToRollcallStep();
	};

	goToProxyDocuments = (managedAttendeeId) => {
		this.setState({ step: this.state.step + 1, managedAttendeeId });
	};

	goToAddProxy = (managedAttendeeId) => {
		this.setState({ step: 0, managedAttendeeId });
	};

	goToRollcallStep = () => {
		const { isAGM } = this.props;

		if (isAGM) {
			this.setState({ step: 1 });
		} else {
			this.setState({ step: 0 });
		}

		// Wait for modal transition to finish before unsetting managedAttendeeId
		// and therefore even unmounting DocumentsContainer component
		setTimeout(() => {
			this.setState({ managedAttendeeId: null });
		}, 600);
	};

	footerRightControls = () => {
		const { rollcallDone } = this.props;
		const { rollCallDoneAndValuesHaveChanged } = this.state;

		if (rollCallDoneAndValuesHaveChanged || !rollcallDone) {
			return {
				tid: "meetings.agm.rollcall.modal.btn.done",
				onClick: this.rollcallDone,
			};
		}
	};

	getRollcallFooterComponent = () => {
		const { rollcallDone } = this.props;
		const { rollCallDoneAndValuesHaveChanged } = this.state;
		const footerRightControls = this.footerRightControls();

		return (
			<FooterRightControls>
				{(rollCallDoneAndValuesHaveChanged || !rollcallDone) && (
					<TransparentButton
						tid={footerRightControls.tid}
						onClick={footerRightControls.onClick}
					/>
				)}
				<TransparentButton
					tid="generic.form.close"
					textColor="midGrey"
					onClick={this.patchMeeting}
				/>
			</FooterRightControls>
		);
	};

	getModalTitle = () => {
		const { isAGM, step } = this.props;

		if (isAGM && step === 2) {
			return "meetings.agm.proxy.documents_modal.title";
		}

		return "meetings.agm.rollcall.modal.title";
	};

	getSteps = () => {
		const { managedAttendeeId, formData, step } = this.state;
		const { isAGM, hideInfo, hideMoreActions } = this.props;
		let steps = [];

		if (isAGM) {
			steps.push({
				header: {
					component: (
						<PeopleModalHeader
							title="meetings.agm.add_proxy.modal.title"
							tabs={getDefaultTabs()}
						/>
					),
				},
				body: (
					<ModalInnerWrapper noScrollView fullHeight>
						{step === 0 && (
							<SelectProxyModalContainer
								attendeeId={managedAttendeeId}
								renderOnlyBody={true}
							/>
						)}
					</ModalInnerWrapper>
				),
				footer: {
					component: (
						<PeopleModalFooter
							onSave={this.createProxy}
							onCancel={this.goToRollcallStep}
						/>
					),
				},
			});
		}

		steps.push({
			body: (
				<RollcallContainer
					onGoToProxyDocuments={this.goToProxyDocuments}
					onGoToAddProxy={this.goToAddProxy}
					hideInfo={hideInfo}
					hideMoreActions={hideMoreActions}
					formData={formData}
					onChange={this.onChange}
				/>
			),
			footer: {
				component: this.getRollcallFooterComponent(),
			},
		});

		if (isAGM) {
			steps.push({
				body: (
					<ModalInnerWrapper>
						{managedAttendeeId && (
							<DocumentsContainer attendeeId={managedAttendeeId} />
						)}
					</ModalInnerWrapper>
				),
				footer: {
					leftComponent: <PreviousLeftButton onClick={this.goToRollcallStep} />,
				},
			});
		}

		return steps;
	};

	render = () => {
		const { step } = this.state;
		const { isOpen } = this.props;

		if (!isOpen) {
			return null;
		}

		return (
			<PeopleModalProvider
				onChange={(selectedUsers) => {
					this.setState({ selectProxySelectedUsers: selectedUsers });
				}}
				singleMode={true}
			>
				<StepsModal
					isOpen={true}
					steps={this.getSteps()}
					step={step}
					hSize="xl"
					title={this.getModalTitle()}
				/>
			</PeopleModalProvider>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		isOpen:
			store.modals.getIn(["activeModal", "name"]) === MEETINGS_ROLLCALL_MODAL,
		meeting: store.meetings.get("meeting"),
		isStandardMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) ===
			MEETING_TYPE_STANDARD,
		meetingIsStarted: store.meetings.getIn([
			"meeting",
			"computedValues",
			"meetingIsStarted",
		]),
		isAGM:
			store.meetings.getIn(["meeting", "meetingSubType"]) ===
			MEETING_SUBTYPE_AGM,
		rollcallDone: store.meetings.getIn(["meeting", "metadata", "rollcallDone"]),
		showEndMeetingModalAfterRollcall: store.modals.getIn([
			"activeModal",
			"options",
			"showEndMeetingModalAfterRollcall",
		]),
		isModerator: store.meetings.getIn([
			"meeting",
			"computedValues",
			"isModerator",
		]),
	};
};

const mapActionsToProps = {
	openModal,
	closeModal,
	patchMeeting,
	createProxy,
	resetAttendeesFilter,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(RollcallModalContainer);
