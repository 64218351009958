import React, { PureComponent } from "react";
import styled from "styled-components";
import { func, string, bool } from "prop-types";
import ProfileImage from "../../../shared/profile-image/profile-image";
import Button from "../../../shared/button/button";
import Text from "../../../shared/text/text";
import Icon from "../../../shared/icon/icon";
import Block from "../block/block";

const StyledRoot = styled.div`
	margin: ${(props) => props.theme.spacing[4]} 0;
`;

const StyledContainer = styled.div`
	background-color: ${(props) => props.theme.colors.silver};
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
`;

const StyledName = styled.div`
	display: flex;
	flex: 1;
	padding: ${(props) => props.theme.spacing[4]};
`;

const StyledButtonContainer = styled.div`
	padding: ${(props) => props.theme.spacing[4]};
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export default class FeedbackeePanel extends PureComponent {
	static propTypes = {
		profileImage: string,
		name: string,
		feedbackIsDone: bool,
		onBtnClick: func,
	};

	render = () => {
		const { profileImage, name, feedbackIsDone, onBtnClick, userId } =
			this.props;

		return (
			<StyledRoot>
				<Block titleTid="meetings.protocol_view.feedback_panel.title">
					<StyledContainer>
						<ProfileImage
							width="50px"
							image={
								profileImage
									? `/api/users/public/images/${profileImage}-80x80?userId=${userId}`
									: null
							}
						/>

						<StyledName>
							<Text>{name}</Text>
						</StyledName>

						<StyledButtonContainer>
							{!feedbackIsDone && (
								<div>
									<Button
										mode="primary"
										tid="meetings.protocol_view.feedback.done"
										onClick={onBtnClick}
									/>
									<Button
										tid="meetings.protocol_view.feedback.no_feedback"
										onClick={onBtnClick}
									/>
								</div>
							)}
							{feedbackIsDone && (
								<Icon icon="faCheck" size="lg" color="green" />
							)}
						</StyledButtonContainer>
					</StyledContainer>
				</Block>
			</StyledRoot>
		);
	};
}
