import { fromJS, List, Map } from "immutable";

import { MODALS_OPEN, MODALS_CLOSE } from "../actions/types";

const INITIAL_STATE = fromJS({
	activeModal: {},
	/*activeModal: {
		name: 'COLLECTIONS_SHARE_MODAL',
		options: {
			collection: {
				id: 'cd65cbb6-c3d3-4c2a-9b5a-88704789373f',
				creatorId: '1ec64bf0-aee4-11ea-8111-e322a3952637',
				documents: [
					{
						sharedVersion: 'NORMAL',
						id: '9d915cb1-271e-4631-8c40-47cbf7db1045'
					},
					{
						sharedVersion: 'SIGNED',
						id: '09c63ce7-8e36-4fa7-a724-6cae59d6e8ec'
					}
				],
				createdAt: '2021-10-08T06:50:54.947Z',
				updatedAt: '2021-10-11T11:11:56.526Z',
				name: 'Test 3'
			}
		}
	}*/
});

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case MODALS_OPEN: {
			if (payload.get("multipleModals")) {
				let modalsList = List();
				const modalsInModal = state.get("modalsInModal");

				if (modalsInModal) {
					modalsList = modalsList.push(modalsInModal);
				}

				modalsList = modalsList.push(state.get("activeModal"));

				state = state.set("activeModal", Map());
				state = state.setIn(["activeModal", "name"], payload.get("modalName"));
				state = state.setIn(["activeModal", "options"], payload.get("options"));

				modalsList = modalsList.push(state.get("activeModal"));
				state = state.set("modalsInModal", modalsList);

				return state;
			} else {
				state = state.set("activeModal", Map());
				state = state.setIn(["activeModal", "name"], payload.get("modalName"));
				state = state.setIn(["activeModal", "options"], payload.get("options"));

				return state;
			}
		}

		case MODALS_CLOSE: {
			const isMultipleModals = state.get("modalsInModal");
			if (isMultipleModals) {
				const activeModalIndex = state
					.get("modalsInModal")
					.indexOf(state.get("activeModal"));
				const newModalsList = state
					.get("modalsInModal")
					.delete(activeModalIndex);
				state = state.set("modalsInModal", newModalsList);

				const latestActiveModal = newModalsList.last();
				state = state.set("activeModal", Map());
				state = state.setIn(
					["activeModal", "name"],
					latestActiveModal.get("name"),
				);
				state = state.setIn(
					["activeModal", "options"],
					latestActiveModal.get("options"),
				);

				if (state.get("modalsInModal").size === 1) {
					state = state.set("modalsInModal", null);
				}

				return state;
			} else {
				state = state.set("activeModal", null);

				return state;
			}
		}

		default:
			return state;
	}
}
