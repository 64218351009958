import { fromJS } from "immutable";

import { MODALS_OPEN, MODALS_CLOSE } from "./types";

export function openModal(modalName, options, multiple) {
	const multipleModals = multiple ? true : false;

	return function (dispatch) {
		dispatch({
			type: MODALS_OPEN,
			payload: fromJS({ modalName, options, multipleModals }),
		});
	};
}

export function closeModal() {
	return function (dispatch) {
		dispatch({ type: MODALS_CLOSE });
	};
}
