import req from "../modules/request.module";
import { fromJS } from "immutable";

import { NOTES_FETCH_REMOTE, NOTES_SAVE_REMOTE } from "./types";

export function fetchNote(objType, objId) {
	return function (dispatch) {
		return req
			.get(`/notes/${objType}/${objId}`)
			.then((response) => {
				dispatch({
					type: NOTES_FETCH_REMOTE,
					payload: fromJS({ objId, note: response.data }),
				});
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function saveNote(objId, objType, note, metadata) {
	return function (dispatch) {
		return req
			.post(`/notes/${objType}/${objId}`, { note, metadata: metadata.toJS() })
			.then((response) => {
				dispatch({ type: NOTES_SAVE_REMOTE, payload: fromJS(response.data) });
			});
	};
}
