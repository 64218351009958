// -------------------------------------------------------------------------------------
// ----------------------------------- Select Simple -----------------------------------
// -------------------------------------------------------------------------------------
import React from "react";
import ReactAsyncSelect from "react-select-next/async";
import styled from "styled-components";
import {
	string,
	bool,
	func,
	oneOfType,
	number,
	oneOf,
	object,
} from "prop-types";
import ImmutableProps from "react-immutable-proptypes";
import StyledSelectTemplate from "./select.template";
import { setupCustomComponents } from "./select.helpers";
import { List } from "immutable";
import Text from "../../text/text";

const StyledSelect = styled(ReactAsyncSelect)`
	${StyledSelectTemplate}
`;

const Select = (props) => {
	const {
		labelIsTid,
		placeholderTid,
		optionsLabel,
		optionsValue,
		isDisabled,
		fieldName,
		noOptionsMessage,
		onChange,
		isLoading,
		isClearable,
		hideSelectedOptions,
		getOptionLabel,
		formatOptionLabel,
		menuPlacement,
		placeholderRenderer,
		legacyDisabledColor,
		optionRenderer,
		maxMenuHeight,
		menuPortalTarget,
		loadOptions,
		onSetRef,
	} = props;
	const customComponentsConfig = {
		labelIsTid,
		placeholderTid,
		isClearable,
		placeholderRenderer,
		optionRenderer,
	};
	let { options } = props;

	// NULL !== undefined that's why default props don't help
	if (!options) {
		options = List();
	}

	/**
	 * If selectedValue is not undefined (was removed), pick
	 * only the value property instead of whole object
	 */
	const _onChange = (selectedValue) => {
		if (selectedValue) {
			selectedValue = selectedValue[optionsValue];
		}

		if (fieldName) {
			onChange && onChange(fieldName, selectedValue);
		} else {
			onChange && onChange(selectedValue);
		}
	};

	const _getOptionLabel = (val) => {
		return val[optionsLabel];
	};

	let ref;
	if (onSetRef) {
		ref = typeof onSetRef === "function" ? (r) => onSetRef(r) : onSetRef;
	}

	return (
		<StyledSelect
			classNamePrefix="react-select"
			components={setupCustomComponents(customComponentsConfig)}
			getOptionLabel={getOptionLabel || _getOptionLabel}
			getOptionValue={(val) => {
				return val[optionsValue];
			}}
			loadOptions={loadOptions}
			ignoreAccents={false}
			isDisabled={isDisabled || isLoading}
			isLoading={isLoading}
			placeholder={placeholderTid}
			noOptionsMessage={noOptionsMessage}
			isClearable={isClearable}
			hideSelectedOptions={hideSelectedOptions}
			menuPlacement={menuPlacement}
			onChange={_onChange}
			placeholderRenderer={placeholderRenderer}
			formatOptionLabel={formatOptionLabel}
			legacyDisabledColor={legacyDisabledColor}
			ref={ref}
			maxMenuHeight={maxMenuHeight}
			menuPortalTarget={menuPortalTarget}
			closeMenuOnScroll={
				menuPortalTarget &&
				((event) => {
					if (event.srcElement.className.includes("react-select")) {
						return false;
					}

					return true;
				})
			}
		/>
	);
};

Select.defaultProps = {
	labelIsTid: false,
	isClearable: false,
	isDisabled: false,
	optionsLabel: "label",
	optionsValue: "value",
	placeholderTid: "",
	isLoading: false,
	hideSelectedOptions: true,
	maxMenuHeight: 170,
	noOptionsMessage: () => {
		return <Text tid="select.default_no_options" />;
	},
};

Select.propTypes = {
	fieldName: string,
	optionsLabel: string,
	optionsValue: string,
	placeholderTid: string,
	labelIsTid: bool,
	isClearable: bool,
	isDisabled: bool,
	onChange: func,
	noOptionsMessage: func,
	options: ImmutableProps.list,
	value: oneOfType([string, number]),
	isLoading: bool,
	hideSelectedOptions: bool,
	getOptionLabel: func,
	formatOptionLabel: func,
	menuPlacement: oneOf(["top", "bottom", "auto"]),
	legacyDisabledColor: bool,
	optionRenderer: func,
	maxMenuHeight: number,
	hasError: ImmutableProps.list,
	onSetRef: oneOfType([func, object]),
};

export default Select;
