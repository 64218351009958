import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchMeeting, saveMeeting } from "../../../actions/meetings.actions";
import SplitView from "../../../dumb-components/shared/layout/split-view/split-view";
import ScrollView from "../../../dumb-components/shared/layout/scroll-view/scroll-view";
import ProtocolTabsContainer from "../protocol/protocol-tabs.container";
import MeetingDocumentsListContainer from "./meeting-documents-list.container";
import ProtocolToolbarContainer from "../meeting/smart-meeting/protocol-toolbar.container";
import MeetingDocumentDetailsContainer from "./meeting-document-details.container";

import {
	calculateMaxWidthOfSplitView,
	toggleVisibilityOfTabs,
} from "../../../components/helpers/tabs.helper";

class MeetingDocumetsContainer extends Component {
	// References to the scrollviews
	scrollViewRefs = {};

	// Reference to the tabs
	tabsRef;

	// Timer
	resizeEnd;

	state = {
		hiddenTabs: [],
	};

	componentDidMount = () => {
		const {
			fetchMeeting,
			meeting,
			match: {
				params: { id },
			},
		} = this.props;

		if (!meeting) {
			fetchMeeting(id);
		}

		window.addEventListener("resize", this.onWindowResize, false);
		this.onWindowResize();
	};

	componentWillUnmount = () => {
		window.removeEventListener("resize", this.onWindowResize, false);
	};

	componentDidUpdate = (prevProps) => {
		const {
			fetchMeeting,
			match: {
				params: { id },
			},
		} = this.props;

		if (prevProps.match.params.id !== id) {
			fetchMeeting(id);
		}
	};

	onWindowResize = () => {
		let { hiddenTabs } = this.state;
		const maxWidth = calculateMaxWidthOfSplitView(this.scrollViewRefs);
		hiddenTabs = toggleVisibilityOfTabs(this.tabsRef, hiddenTabs, { maxWidth });

		this.setState({ hiddenTabs });
	};

	getComponentBasePath = () => {
		const {
			location: { pathname },
		} = this.props;
		const path = pathname;
		const pathArray = path.split("documents");
		return pathArray[0] + "documents";
	};

	renderLeftHeader = () => {
		const { location } = this.props;
		const { hiddenTabs } = this.state;

		return (
			<ProtocolTabsContainer
				location={location}
				match="documents"
				onSetRef={(ref) => (this.tabsRef = ref)}
				hiddenTabs={hiddenTabs}
			/>
		);
	};

	renderLeftSplitView = () => {
		const { location } = this.props;
		const basePath = this.getComponentBasePath();

		return (
			<ScrollView
				header={this.renderLeftHeader}
				headerBottomMargin={3}
				showOnHover={true}
				onSetRef={(ref) => (this.scrollViewRefs["left"] = ref)}
			>
				<MeetingDocumentsListContainer
					basePath={basePath}
					location={location}
				/>
			</ScrollView>
		);
	};

	renderRightHeader = () => {
		const {
			location,
			match: { params },
		} = this.props;
		const basePath = this.getComponentBasePath();

		return (
			<ProtocolToolbarContainer
				location={location}
				params={params}
				basePath={basePath.split("/documents")[0]}
			/>
		);
	};

	renderRightSplitView = () => {
		const { match } = this.props;

		return (
			<ScrollView
				header={this.renderRightHeader}
				headerBottomMargin={3}
				showOnHover={true}
				onSetRef={(ref) => (this.scrollViewRefs["right"] = ref)}
			>
				{match.params.documentId && (
					<MeetingDocumentDetailsContainer
						match={match}
						location={this.props.location}
						history={this.props.history}
					/>
				)}
			</ScrollView>
		);
	};

	render = () => {
		return (
			<SplitView
				leftComponent={this.renderLeftSplitView}
				rightComponent={this.renderRightSplitView}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		company: store.company.company,
		document: store.documents.get("document"),
		meeting: store.meetings.get("meeting"),
	};
};

const mapActionsToProps = {
	fetchMeeting,
	saveMeeting,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingDocumetsContainer);
