import React, { useEffect, useState } from "react";
import MUIDialog from "../../mui-components/dialog/mui-dialog";
import { useTranslation } from "react-i18next";
import TargetFolderTreeView from "./choose-target-folder-tree-view";
import { fromJS } from "immutable";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import personalDocuments from "../../services/personal-documents";
import Icon from "../../dumb-components/shared/icon/icon";

export default function ChooseTargetFolderModal({
	open,
	onCancel,
	onAccept,
	companyId,
}) {
	const [folders, setFolders] = useState(
		fromJS({
			id: "root",
			name: "personal-documents.all-documents",
			children: [],
		}),
	);
	const [targetFolder, setTargetFolder] = useState(null);
	const [isCopyingDocument, setIsCopyingDocument] = useState(false);

	const { t } = useTranslation();

	useEffect(() => {
		const fetchFolders = async () => {
			const folders = await personalDocuments.getCompanyFolders(companyId);
			setFolders(fromJS(folders));
		};

		fetchFolders();
	}, [companyId]);

	return (
		<MUIDialog
			maxWidth={"md"}
			fullWidth={true}
			isOpen={open}
			title={t("personal-documents.choose-target-folder")}
			confirmButtonTid="personal-documents.copy-document"
			closeButtonTid="dialog.generic.cancel"
			onConfirm={() => {
				setIsCopyingDocument(true);
				onAccept(companyId, targetFolder);
			}}
			onClose={onCancel}
			disabledConfirm={!targetFolder || isCopyingDocument}
			leftSideLinkValidation={
				isCopyingDocument && (
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Icon icon="faSpinner" spin />{" "}
						<Typography component="span" variant="body2" sx={{ ml: 1 }}>
							{t(
								"personal-documents.notifications.uploading-document-to-company",
							)}
						</Typography>
					</Box>
				)
			}
		>
			<Typography variant={"body2"} sx={{ flex: "auto", my: 4 }}>
				{t("personal-documents.choose-target-folder-description")}
			</Typography>
			<TargetFolderTreeView
				folders={folders}
				onSelectFolder={(nodeId) => setTargetFolder(nodeId)}
			/>
		</MUIDialog>
	);
}
