import React, { PureComponent } from "react";
import { func } from "prop-types";
import userImage from "../../../../assets/images/onboarding/user-notification.png";

import LeftRightLayout, {
	Header,
	Entry,
} from "../sub-modules/left-right-layout";
import Button from "../../../shared/button/button";

export default class WelcomeUser extends PureComponent {
	static propTypes = {
		onGoToAddCompany: func,
	};

	renderAddToCompanyButton = () => {
		const { onGoToAddCompany } = this.props;

		return (
			<Button
				mode="primary"
				onClick={onGoToAddCompany}
				tid="onboarding.modal.new_user.btn.add_company"
			/>
		);
	};

	render = () => {
		return (
			<LeftRightLayout image={userImage}>
				<Header tid="onboarding.modal.shared.welcome_to_invono" />

				<Entry
					titleTid="onboarding.modal.new_user.subtitle.1"
					subTextTid="onboarding.modal.new_user.text.1"
					bottomComponent={this.renderAddToCompanyButton()}
				/>

				<Entry
					titleTid="onboarding.modal.new_user.subtitle.3"
					subTextTid="onboarding.modal.new_user.text.3"
				/>
			</LeftRightLayout>
		);
	};
}
