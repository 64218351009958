import React, { PureComponent } from "react";
import { string, func, bool } from "prop-types";
import styled from "styled-components";

import ListItem from "../../../shared/list-item/list-item";
import Text from "../../../shared/text/text";

const StyledListItem = styled(ListItem)`
	display: flex;
	align-items: center;
	padding: 0 ${(props) => props.theme.spacing[3]};
	margin: 0;
`;

const StyledTitle = styled.div`
	flex: 1;
	margin-right: ${(props) => props.theme.spacing[3]};
`;

const StyledLink = styled(Text)`
	margin-right: ${(props) => props.theme.spacing[4]};
`;

export default class GenericInfoListItem extends PureComponent {
	static propTypes = {
		label: string,
		linkText: string,
		onLinkClick: func,
		value: string,
		showLink: bool,
	};

	render = () => {
		const { label, linkText, showLink, onLinkClick, value } = this.props;

		return (
			<StyledListItem striped>
				<StyledTitle>
					<Text tid={label} />
				</StyledTitle>
				{linkText && showLink && (
					<StyledLink
						onClick={onLinkClick}
						tid={linkText}
						color="dodgerBlue"
						hasUnderline
						singleLine
					/>
				)}
				<Text>{value}</Text>
			</StyledListItem>
		);
	};
}
