import React, { Component } from "react";
import { connect } from "react-redux";
import InvestorRelinkModalContainer from "./investor-relink-modal.container";
import Tooltip from "../../dumb-components/shared/tooltip/tooltip";
import DropdownIconItem from "../../dumb-components/shared/dropdown-item/dropdown-icon-item";

class InvestorRelinkModalbtnControllerContainer extends Component {
	state = {
		isOpen: false,
	};

	onClickBtnOpenModal = () => {
		const { moreActionBtnRef } = this.props;
		moreActionBtnRef && moreActionBtnRef.onToggleMenu();
		this.setState({ isOpen: true });
	};

	onCancelModal = () => {
		this.setState({ isOpen: false });
	};

	render = () => {
		const { selectedInvestor } = this.props;
		const { isOpen } = this.state;

		return (
			<div>
				<Tooltip
					active={false}
					tid="shares.shareholder.relink"
					delayShow="instant"
				>
					<DropdownIconItem
						icon="faLink"
						tid="investor.reinvite.dropdown.item.title"
						onClick={this.onClickBtnOpenModal}
						disabled={false}
					/>
				</Tooltip>

				<InvestorRelinkModalContainer
					isOpen={isOpen}
					selectedInvestor={selectedInvestor}
					onCancel={this.onCancelModal}
				/>
			</div>
		);
	};
}

function mapStoreToProps(store) {
	return {
		selectedInvestor: store.investors.get("selectedInvestor"),
	};
}

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(InvestorRelinkModalbtnControllerContainer);
