import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { closeModal } from "../../../actions/modals.actions";
import MUIDialog from "../../../mui-components/dialog/mui-dialog";
import { ReadMoreModalContent } from "../../shared/ReadMoreModalContent";
import history from "../../../interfaces/history";
import { makeUrlRelativeToCompany } from "../../../components/helpers/link.helper";
import { COMPANY_INVESTMENTS_LIMITATIONS_MODAL } from "../../../constants/modals";

function ShareholderLimitationInfoModal() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isOpen = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) ===
			COMPANY_INVESTMENTS_LIMITATIONS_MODAL,
	);

	const onClose = () => {
		dispatch(closeModal());
	};

	const manageSubscription = () => {
		dispatch(closeModal());
		history.push(makeUrlRelativeToCompany("subscriptions"));
	};

	if (!isOpen) {
		return null;
	}

	return (
		<MUIDialog
			isOpen={true}
			onClose={onClose}
			title={t("shareholder_limitation_info_modal.title")}
			confirmButton={true}
			confirmButtonTid="manage_my_subscription"
			onConfirm={manageSubscription}
			closeButtonTid="generic.form.close"
			maxWidth="lg"
			fullWidth
		>
			<ReadMoreModalContent
				contentFile={"learn-about-shareholder-limitation.txt"}
			/>
		</MUIDialog>
	);
}

export { ShareholderLimitationInfoModal };
