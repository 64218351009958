import React, { Component } from "react";
import { connect } from "react-redux";
import { List } from "immutable";

import ListPanel from "../../../../dumb-components/shared/list-panel/list-panel";
import ListPanelItem from "../../../../dumb-components/shared/list-panel/list-panel-item";

import { getUserName } from "../../../../components/helpers/users";

// Used in meeting general view
class FeedbackeesContainer extends Component {
	renderFeedbackItem = (feedbackee, index) => {
		const { usersCache } = this.props;

		const notified = feedbackee.get("notified");
		const publishedAt = feedbackee.getIn(["metadata", "publishedAt"]);
		const done = feedbackee.get("done");
		const name = getUserName(feedbackee, undefined, usersCache, true);
		let status = null;

		if (notified && done) {
			status = true;
		}

		return (
			<ListPanelItem
				name={name}
				status={status}
				date={publishedAt}
				key={index}
			/>
		);
	};

	render = () => {
		const { feedbackees } = this.props;
		const activeFeedbackees = feedbackees.filter((obj) => obj.get("active"));
		const atLeastOneNotified = activeFeedbackees.find(
			(feedbackee) => feedbackee.get("notified") === true,
		)
			? true
			: false;

		if (activeFeedbackees.size === 0) {
			return null;
		}

		if (!atLeastOneNotified) {
			return null;
		}

		return (
			<ListPanel
				title="meeting.details.feedbackees_panel.title"
				items={activeFeedbackees.toList()}
				columnTid="meeting.details.feedbackees_panel_panel.publishedAt"
				renderItemComponent={this.renderFeedbackItem}
				panelProps={{ marginBottom: true }}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		feedbackees: store.meetings.getIn(["meeting", "feedbackees"], List()),
		usersCache: store.usersCache.get("usersCache"),
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(FeedbackeesContainer);
