import React, { PureComponent } from "react";
import styled from "styled-components";

// Placeholder Thumbnails
import CompanyIconPlaceholder from "../../../assets/images/company_logo_placeholder.png";
import DemoCompanyThumbailPlaceholder from "../../../assets/images/demo_thumbnail_placeholder.png";

const StyledThumbnail = styled.img``;

const StyledLogoWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 40px;
	width: 40px;
	height: 40px;
`;

export default class CompanyLogo extends PureComponent {
	render = () => {
		const { isDemo } = this.props;
		let { imageUrl } = this.props;

		// Demo Company without thumbnail
		if (isDemo && !imageUrl) {
			imageUrl = DemoCompanyThumbailPlaceholder;
		} else if (!imageUrl) {
			// Company without thumbnail
			imageUrl = CompanyIconPlaceholder;
		}

		return (
			<StyledLogoWrapper>
				<StyledThumbnail src={imageUrl} />
			</StyledLogoWrapper>
		);
	};
}
