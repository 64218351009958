import React, { useContext } from "react";
import { InvestmentsContext } from "../investment.container";
import EmissionDetailsContainer from "./emission-details.container";
import EmissionsListContainer from "./emissions-list.container";
import SnackbarProvider, {
	SnackbarContext,
} from "../../../public/providers/snackbar-provider";
import GenericSnackBar from "../../../public/dumb-components/shared/layout/generic-snackbar";
import { useTranslation } from "react-i18next";

const EmissionContainer = () => {
	const { emissionTabIndex } = useContext(InvestmentsContext);

	const SnackBar = () => {
		const snackbar = useContext(SnackbarContext);
		const { t } = useTranslation();

		return (
			<GenericSnackBar
				open={snackbar.isOpen}
				toggleSnackbar={() => snackbar.clear()}
				message={t(snackbar.message)}
				severity={snackbar.severity}
			/>
		);
	};

	const renderTab = () => {
		if (emissionTabIndex === 0) {
			return <EmissionsListContainer />;
		} else if (emissionTabIndex === 1) {
			return <EmissionDetailsContainer />;
		}
	};

	return (
		<SnackbarProvider>
			<SnackBar />
			{renderTab()}
		</SnackbarProvider>
	);
};

export default EmissionContainer;
