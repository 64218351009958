import React, { Component } from "react";
import { connect } from "react-redux";
import { string } from "prop-types";
import { Map } from "immutable";
import moment from "/shared/helpers/moment.helper";
import {
	saveMeeting,
	updateAgendaItemLocal,
} from "../../../actions/meetings.actions";
import { closeModal } from "../../../actions/modals.actions";
import { flattenAgendaItems } from "../../../components/helpers/meeting.helper";
import EndMeetingModal from "../../../dumb-components/meetings/end-meeting-modal/end-meeting-modal";
import { MEETINGS_END_MEETING_MODAL } from "../../../constants/modals";
import { MEETING_STATUS_MEETING_FINISHED } from "/shared/constants";
import history from "../../../interfaces/history";

class EndMeetingModalContainer extends Component {
	static propTypes = {
		basePath: string.isRequired,
	};

	state = {
		goToProtocolBtnIsLoading: false,
		goToMeetingsListBtnIsLoading: false,
	};

	getCloseMeetingAgendaItem = () => {
		const { meeting } = this.props;

		return (
			meeting
				?.get("agendaItems")
				?.find((item) => item.get("internalType") === "close") || Map()
		);
	};

	getAgendaItems = () => {
		const { meeting } = this.props;
		const agendaItem = this.getCloseMeetingAgendaItem();
		const agendaItems = meeting && flattenAgendaItems(meeting);

		return (
			agendaItems &&
			agendaItems.filter(
				(item) =>
					item.get("outcome") === "todo" &&
					item.get("id") !== agendaItem.get("id"),
			)
		);
	};

	onEndMeetingGoToProtocol = () => {
		this.setState({ goToProtocolBtnIsLoading: true });
		this.endMeeting("protocol");
	};

	onEndMeetingGoToMeetingList = () => {
		this.setState({ goToMeetingsListBtnIsLoading: true });
		this.endMeeting("meetingList");
	};

	onCancel = () => {
		this.close();
	};

	endMeetingErrorCallback = () => {
		this.setState({
			goToProtocolBtnIsLoading: false,
			goToMeetingsListBtnIsLoading: false,
		});
	};

	endMeeting = (redirectTo) => {
		const { saveMeeting, updateAgendaItemLocal } = this.props;
		let { meeting } = this.props;
		let agendaItem = this.getCloseMeetingAgendaItem();

		let internalData = agendaItem.get("internalData", Map());
		internalData = internalData.set("closedAt", moment().toISOString());
		agendaItem = agendaItem.set("internalData", internalData);
		agendaItem = agendaItem.set("outcome", "closed");
		updateAgendaItemLocal(agendaItem, (payload) => {
			meeting = meeting.set("agendaItems", payload.get("agendaItems"));
			meeting = meeting.set("status", MEETING_STATUS_MEETING_FINISHED);
			saveMeeting(
				meeting,
				() => {
					if (redirectTo === "protocol") {
						this.redirectToProtocol();
					} else if (redirectTo === "meetingList") {
						this.redirectToMeetingList();
					} else {
						this.close();
					}
				},
				this.endMeetingErrorCallback,
			);
		});
	};

	close = () => {
		const { closeModal } = this.props;
		closeModal(MEETING_STATUS_MEETING_FINISHED);
	};

	redirectToProtocol = () => {
		const { basePath, meeting, history } = this.props;

		history.push(
			`${basePath.split("meetings")[0]}meetings/${meeting.get("id")}/protocol`,
		);
	};

	redirectToMeetingList = () => {
		const { basePath, history } = this.props;

		history.push(`${basePath.split("meetings")[0]}meetings/`);
	};

	render() {
		const { isOpen } = this.props;
		const { goToProtocolBtnIsLoading, goToMeetingsListBtnIsLoading } =
			this.state;

		if (!isOpen) {
			return null;
		}

		return (
			<EndMeetingModal
				onEndMeetingGoToProtocol={this.onEndMeetingGoToProtocol}
				onEndMeetingGoToMeetingList={this.onEndMeetingGoToMeetingList}
				goToProtocolBtnIsLoading={goToProtocolBtnIsLoading}
				goToMeetingsListBtnIsLoading={goToMeetingsListBtnIsLoading}
				onCancel={this.onCancel}
				agendaItems={this.getAgendaItems()}
			/>
		);
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meeting: store.meetings.get("meeting"),
		isOpen:
			store.modals.getIn(["activeModal", "name"]) ===
			MEETINGS_END_MEETING_MODAL,
	};
};

const mapActionsToProps = {
	saveMeeting,
	updateAgendaItemLocal,
	closeModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(EndMeetingModalContainer);
