import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "../../../../modules/moment.module";

import ConfirmContainer from "../../../shared/confirm.container";

import { saveMeeting } from "../../../../actions/meetings.actions";
import { closeModal } from "../../../../actions/modals.actions";
import { MEETING_STATUS_MEETING_STARTED } from "/shared/constants";
import {
	MEETINGS_START_STANDARD_MEETING_MODAL,
	MEETINGS_START_STANDARD_MEETING_ALTERNATIVE_MODAL,
} from "../../../../constants/modals";

class StartStandardConfirmModalContainer extends Component {
	getConfirmMessageObject = () => {
		const { startDate } = this.props;

		if (!startDate) {
			return {
				tid: "meetings.start_meeting.confirm.no_start_date.message",
			};
		}

		if (moment(startDate).isAfter(moment())) {
			return {
				tid: "meetings.start_meeting.confirm.message",
				values: { startDate: moment(startDate).format("LLL") },
			};
		}

		return {
			tid: "meetings.start_meeting_before_scheduled_time.confirm.message",
			values: { startDate: moment(startDate).format("LLL") },
		};
	};

	getTitleTid = () => {
		const { activeModalName } = this.props;

		if (activeModalName === MEETINGS_START_STANDARD_MEETING_ALTERNATIVE_MODAL) {
			return "meetings.start_meeting.confirm.alternative_title";
		}

		return "meetings.start_meeting.confirm.title";
	};

	startMeeting = () => {
		const { saveMeeting } = this.props;
		let { meeting } = this.props;

		this.closeModal();

		meeting = meeting.set("startDate", moment().toISOString());
		meeting = meeting.set("status", MEETING_STATUS_MEETING_STARTED);

		saveMeeting(meeting);
	};

	closeModal = () => {
		this.props.closeModal();
	};

	render = () => {
		const { isOpen } = this.props;

		if (!isOpen) {
			return null;
		}

		return (
			<ConfirmContainer
				title={this.getTitleTid()}
				message={this.getConfirmMessageObject()}
				onConfirm={this.startMeeting}
				onDecline={this.closeModal}
				isOpen={true}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	const activeModalName = store.modals.getIn(["activeModal", "name"]);
	return {
		activeModalName: store.modals.getIn(["activeModal", "name"]),
		isOpen:
			activeModalName === MEETINGS_START_STANDARD_MEETING_MODAL ||
			activeModalName === MEETINGS_START_STANDARD_MEETING_ALTERNATIVE_MODAL,
		startDate: store.meetings.getIn(["meeting", "startDate"]),
		meeting: store.meetings.get("meeting"),
	};
};

const mapActionsToProps = {
	closeModal,
	saveMeeting,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(StartStandardConfirmModalContainer);
