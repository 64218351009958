import { func, node } from "prop-types";
import React from "react";
import SelectUserModal from "../../../dumb-components/shared/select-user-modal/select-user-modal";
import { usePeopleModalContext } from "./people-modal.context";

function PeopleModalContainer({ headerComponent, footerComponent, children }) {
	const { isOpen } = usePeopleModalContext();

	return (
		<SelectUserModal
			isOpen={isOpen}
			modalFooterComponent={footerComponent && footerComponent()}
			headerComponent={headerComponent && headerComponent()}
		>
			{children}
		</SelectUserModal>
	);
}

PeopleModalContainer.propTypes = {
	headerComponent: func,
	footerComponent: func,
	children: node,
};

export { PeopleModalContainer };
