import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, bool } from "prop-types";
import { fromJS, Map } from "immutable";
import { createSignatoryObject } from "../../../components/helpers/meeting.helper";
import {
	fetchPreviousMeeting,
	clearPreviousMeeting,
	savePreviousMeeting,
} from "../../../actions/meetings.actions";
import PreviousMeetingPanel from "../../../dumb-components/meetings/previous-meeting/previous-meeting-panel/previous-meeting-panel";
import PreviousMeetingModalContainer from "./previous-meeting-modal.container";
import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import req from "../../../modules/request.module";

class PreviousMeetingPanelContainer extends Component {
	static propTypes = {
		fieldName: string,
		currentMeetingId: string,
		selectedPreviousMeeting: string,
		currentMeetingGroupId: string,
		onChange: func,
		readOnly: bool,
		nakedStyle: bool,
	};

	state = {
		isModalOpan: false,
		previousMeeting: null,
		meetingTemplate: null,
		signatoriesInitiated: false,
	};

	componentDidMount = () => {
		const { selectedPreviousMeeting } = this.props;

		if (selectedPreviousMeeting) {
			this.fetchPreviousMeeting(selectedPreviousMeeting);
		}
	};

	componentDidUpdate = (prevProps) => {
		const { selectedPreviousMeeting } = this.props;
		const { signatoriesInitiated, previousMeeting } = this.state;

		if (prevProps.selectedPreviousMeeting !== selectedPreviousMeeting) {
			if (selectedPreviousMeeting) {
				this.fetchPreviousMeeting();
			}
		}

		if (
			!signatoriesInitiated &&
			previousMeeting &&
			!previousMeeting.has("signees") &&
			previousMeeting.get("attendees", Map()).size > 0
		) {
			this.initiateSignatories();
		}
	};

	componentWillUnmount = () => {
		this.setState({
			isModalOpan: false,
			previousMeeting: null,
			meetingTemplate: null,
			signatoriesInitiated: false,
		});
	};

	fetchPreviousMeeting = () => {
		const {
			fetchPreviousMeeting,
			currentMeetingId,
			selectedPreviousMeeting,
			i18n,
		} = this.props;

		if (!selectedPreviousMeeting) {
			return;
		}

		fetchPreviousMeeting(
			currentMeetingId,
			selectedPreviousMeeting,
			(previousMeeting) => {
				req
					.get(`/meetings/templates/${previousMeeting?.get("templateId")}`)
					.then((response) => {
						if (response.data) {
							this.setState({ meetingTemplate: fromJS(response.data) });
						} else {
							this.setState({
								meetingTemplate: Map({
									name: i18n.messages["meeting.general.template_option.legacy"],
								}),
							});
						}
					});
				this.setState({ previousMeeting });
			},
		);
	};

	initiateSignatories = () => {
		const { company } = this.props;
		const { previousMeeting } = this.state;
		const signatories =
			previousMeeting &&
			previousMeeting
				.get("attendees", Map())
				.filter((obj) => !obj.get("isInvestor") && !obj.get("isGuest"))
				.map((attendee, id) => {
					let signatory = createSignatoryObject(
						id,
						attendee.get("isGuest"),
						company,
					);
					signatory = signatory.set("active", false);
					return signatory;
				});

		if (signatories) {
			this.onPreviousMeetingChange("signees", signatories);
			this.setState({ signatoriesInitiated: true });
		}
	};

	onChange = (previousMeetingId) => {
		const { onChange, fieldName } = this.props;

		onChange(fieldName, previousMeetingId);
		this.closeModal();
	};

	onPreviousMeetingChange = (fieldName, value) => {
		const { currentMeetingId, selectedPreviousMeeting, savePreviousMeeting } =
			this.props;
		let { previousMeeting } = this.state;

		previousMeeting = previousMeeting.set(fieldName, value);
		this.setState({ previousMeeting });
		savePreviousMeeting(
			currentMeetingId,
			selectedPreviousMeeting,
			previousMeeting,
		);
	};

	openModal = () => {
		this.setState({ isModalOpan: true });
	};

	closeModal = () => {
		this.setState({ isModalOpan: false });
	};

	openProtocol = () => {
		const {
			selectedPreviousMeeting,
			currentMeetingId,
			company: { urlAlias },
		} = this.props;
		const { previousMeeting } = this.state;
		let url = `/${urlAlias}/protocols/${selectedPreviousMeeting}?nextMeeting=${currentMeetingId}`;

		if (previousMeeting.get("templateId") === "LEGACY") {
			url = `/${urlAlias}/protocols/${selectedPreviousMeeting}/documents?nextMeeting=${currentMeetingId}`;
		}
		window.open(url, "_blank");
	};

	removeProtocol = () => {
		this.setState({
			previousMeeting: null,
			meetingTemplate: null,
		});
		this.onChange(null);
	};

	renderModal = () => {
		const { selectedPreviousMeeting, currentMeetingId, currentMeetingGroupId } =
			this.props;
		const { isModalOpan } = this.state;

		return (
			<PreviousMeetingModalContainer
				selectedPreviousMeeting={selectedPreviousMeeting}
				currentMeetingId={currentMeetingId}
				currentMeetingGroupId={currentMeetingGroupId}
				onSave={this.onChange}
				onCancel={this.closeModal}
				isOpen={isModalOpan}
			/>
		);
	};

	renderDropdownContainer = () => {
		return (
			<DropdownMenuContainer
				ref={(ref) => (this.moreActionBtnRef = ref)}
				halignMenu="right"
			>
				<DropdownIconItem
					onClick={this.removeProtocol}
					tid="meetings.previous_meeting.remove_selected"
					icon="faTrashAlt"
				/>
			</DropdownMenuContainer>
		);
	};

	render = () => {
		const { readOnly, nakedStyle } = this.props;
		const { previousMeeting, meetingTemplate } = this.state;

		return (
			<React.Fragment>
				<PreviousMeetingPanel
					selectedMeeting={previousMeeting}
					meetingTemplate={meetingTemplate}
					onOpenModal={this.openModal}
					onOpenProtocol={this.openProtocol}
					renderDropdownContainer={this.renderDropdownContainer}
					readOnly={readOnly}
					nakedStyle={nakedStyle}
				/>
				{this.renderModal()}
			</React.Fragment>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meetingTemplate: store.meetingTemplates.get("meetingTemplate"),
		company: store.company.company,
		i18n: store.i18n,
	};
};

const mapActionsToProps = {
	fetchPreviousMeeting,
	clearPreviousMeeting,
	savePreviousMeeting,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(PreviousMeetingPanelContainer);
