import React, { forwardRef } from "react";
import { Checkbox as MuiCheckbox } from "@mui/material";
import { Checkboxes as CheckboxesRFF } from "mui-rff";

const Checkbox = forwardRef((props, ref) => {
	const { disabled, onClick, variant, color, onChange, ...other } = props;
	const properties = {
		icon: (
			<svg
				width="18"
				height="18"
				viewBox="0 0 18 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					id="Rectangle 54"
					x="0.5"
					y="0.5"
					width="17"
					height="17"
					fill="white"
					stroke="#C4C4C4"
				/>
			</svg>
		),
		checkedIcon: (
			<svg
				width="18"
				height="18"
				viewBox="0 0 18 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					x="0.5"
					y="0.5"
					width="17"
					height="17"
					fill="white"
					stroke="#C4C4C4"
				/>
				<path
					d="M3.45608 9.14761C3.45608 9.14761 4.60763 8.42576 4.98922 9.40152C5.3708 10.3773 5.70903 11.2857 5.70903 11.2857C5.70903 11.2857 6.03114 12.4022 6.6552 11.6585C7.27926 10.9149 11.3024 6.06118 11.3024 6.06118C11.3024 6.06118 12.4327 4.80802 13.246 4.61853C14.0593 4.42904 15.3653 4.30796 15.3653 4.30796C15.3653 4.30796 15.7926 4.28953 15.4828 4.56663C15.173 4.84374 12.7038 7.14612 11.1236 8.9006C8.98983 11.2677 6.60305 15.3256 5.00107 14.653C4.0998 14.2812 4.02329 13.7107 3.53963 12.2965C3.24498 11.446 2.75888 10.136 2.75888 10.136C2.75888 10.136 2.52451 9.59026 3.45608 9.14761Z"
					fill="#339999"
				/>
			</svg>
		),
		ref: ref,
		...other,
		disabled: disabled,
		onClick: disabled ? undefined : onClick,
		variant: variant,
		color: color,
	};

	if (!disabled && onChange) {
		properties.onChange = onChange;
	}

	return (
		<>
			{props.data ? (
				<CheckboxesRFF {...properties} />
			) : (
				<MuiCheckbox {...properties} />
			)}
		</>
	);
});

Checkbox.displayName = "Checkbox";

export default Checkbox;
