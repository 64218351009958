import React, { Component } from "react";
import { connect } from "react-redux";
import { List, Map } from "immutable";
import {
	createProtocolPdf,
	setProtocolFilter,
} from "../../../actions/meetings.actions";
import { downloadDocument } from "../../../actions/documents.actions";
import { fetchAttachedDocument } from "../../../actions/attachments.actions";
import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import DropdownCheckboxItem from "../../../dumb-components/shared/dropdown-item/dropdown-checkbox-item";
import Toolbar from "../../../dumb-components/shared/toolbar/toolbar";
import documentsHelper from "../../../components/helpers/documents.helper";
import {
	EVENT_TYPE_DOCUMENT_SIGNING_FINALIZED,
	OBJ_TYPE_MEETING,
} from "/shared/constants";

//eslint-disable-next-line
class ProtocolViewToolbarContainer extends Component {
	componentDidMount = () => {
		const { meeting, protocolDocumentId, fetchAttachedDocument } = this.props;

		if (protocolDocumentId) {
			fetchAttachedDocument(
				OBJ_TYPE_MEETING,
				meeting.get("id"),
				protocolDocumentId,
				true,
			);
		}
	};

	componentDidUpdate = (prevProps) => {
		const { meeting, protocolDocumentId, fetchAttachedDocument } = this.props;

		if (
			protocolDocumentId &&
			prevProps.protocolDocumentId !== protocolDocumentId
		) {
			fetchAttachedDocument(
				OBJ_TYPE_MEETING,
				meeting.get("id"),
				protocolDocumentId,
				true,
			);
		}

		this.checkLiveUpdateEvents();
	};

	checkLiveUpdateEvents = () => {
		const { audit, fetchAttachedDocument, protocolDocumentId, meeting } =
			this.props;
		const meetingId = meeting && meeting.get("id");

		const DOCUMENT_FINALIZED = audit.get(
			EVENT_TYPE_DOCUMENT_SIGNING_FINALIZED,
			Map(),
		);

		// Document was sealed, fetch updated document
		if (
			DOCUMENT_FINALIZED.get("r") === true &&
			protocolDocumentId === DOCUMENT_FINALIZED.get("objId")
		) {
			fetchAttachedDocument(
				OBJ_TYPE_MEETING,
				meetingId,
				protocolDocumentId,
				true,
			);
		}
	};

	toggleDocuments = () => {
		const { setProtocolFilter, documentsDisplayed } = this.props;
		setProtocolFilter("documentsDisplayed", !documentsDisplayed);
	};

	toggleTasks = () => {
		const { setProtocolFilter, tasksDisplayed } = this.props;
		setProtocolFilter("tasksDisplayed", !tasksDisplayed);
	};

	toggleNotes = () => {
		const { setProtocolFilter, notesDisplayed } = this.props;
		setProtocolFilter("notesDisplayed", !notesDisplayed);
	};

	printProtocol = () => {
		const {
			createProtocolPdf,
			meeting,
			document,
			downloadDocument,
			protocolPublished,
		} = this.props;
		const protocolDocumentExists = meeting.getIn(["protocolData", "documentId"])
			? true
			: false;

		if (!protocolPublished || !protocolDocumentExists) {
			createProtocolPdf(meeting.get("id"));
			return;
		}

		const documentIsSigned =
			document && documentsHelper.getDocumentIsSigned(document);
		const documentIsMerged = document?.hasIn([
			"mergedDocumentData",
			"fileReference",
		]);

		if (documentIsSigned) {
			downloadDocument({
				documentId: document.get("id"),
				openInViewer: true,
				getSignedVersion: true,
			});
		} else if (documentIsMerged) {
			downloadDocument({
				documentId: document.get("id"),
				openInViewer: true,
				getMergedVersion: true,
			});
		} else {
			downloadDocument({ documentId: document.get("id"), openInViewer: true });
		}
	};

	getButtons = () => {
		return List([
			Map({
				icon: "faPrint",
				onClick: this.printProtocol,
				tooltip: "meetings.protocol.toolbar.tooltip.print_protocol",
			}),
		]);
	};

	renderFilterActions = () => {
		const {
			documentsDisplayed,
			tasksDisplayed,
			notesDisplayed,
			location: { pathname },
		} = this.props;
		const filterDisabled =
			pathname.includes("/documents") ||
			pathname.includes("/tasks") ||
			pathname.includes("/notes");

		return (
			<DropdownMenuContainer
				btnIcon="faSlidersH"
				halignMenu="right"
				btnMode="transparent-icon"
				ref={(ref) => (this.moreActionBtnRef = ref)}
				tooltipTid="meetings.protocol.toolbar.tooltip.advanced"
				disabled={filterDisabled}
			>
				<DropdownCheckboxItem
					tid="meetings.protocol_view.toolbar.show_documents"
					checked={documentsDisplayed}
					onChange={this.toggleDocuments}
				/>

				<DropdownCheckboxItem
					tid="meetings.protocol_view.toolbar.show_tasks"
					checked={tasksDisplayed}
					onChange={this.toggleTasks}
				/>

				<DropdownCheckboxItem
					tid="meetings.protocol_view.toolbar.show_notes"
					checked={notesDisplayed}
					onChange={this.toggleNotes}
				/>
			</DropdownMenuContainer>
		);
	};

	render = () => {
		const buttons = this.getButtons();

		return <Toolbar buttons={buttons}>{this.renderFilterActions()}</Toolbar>;
	};
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get("meeting"),
		userId: store.user.getIn(["userObj", "id"]),
		company: store.company.company,
		documentsDisplayed: store.meetings.getIn([
			"protocolFilters",
			"documentsDisplayed",
		]),
		tasksDisplayed: store.meetings.getIn(["protocolFilters", "tasksDisplayed"]),
		notesDisplayed: store.meetings.getIn(["protocolFilters", "notesDisplayed"]),
		protocolDocumentId: store.meetings.getIn([
			"meeting",
			"protocolData",
			"documentId",
		]),
		document: store.documents.get("document"),
		audit: store.audit.get("documents"),
		protocolPublished: store.meetings.getIn([
			"meeting",
			"computedValues",
			"protocolPublished",
		]),
	};
};

const mapActionsToProps = {
	createProtocolPdf,
	setProtocolFilter,
	fetchAttachedDocument,
	downloadDocument,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ProtocolViewToolbarContainer);
