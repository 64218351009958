import React, { Component } from "react";
import { connect } from "react-redux";
import { List, Map } from "immutable";
import { number } from "prop-types";
import FilterBy from "../../../dumb-components/shared/filter-by/filter-by";
import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import DropdownItem from "../../../dumb-components/shared/dropdown-item/dropdown-item";
import Text from "../../../dumb-components/shared/text/text";
import { listTags } from "../../../actions/tags.actions";
import { setFilterBy } from "../../../actions/meetings.actions";
import folderHelper from "../../../components/helpers/folder.helper";

const FILTER_BY_ITEMS = ["tag", "group", "meeting"];

class MeetingsFilterByContainer extends Component {
	static propTypes = {
		totalMeetings: number,
		totalMeetingsShown: number,
	};

	state = {
		selectedFilter: "tag",
		savedValues: Map(),
		selectedValues: List(),
		options: List(),
	};

	componentDidMount = () => {
		this.props.listTags();
	};

	componentWillUnmount = () => {
		const { selectedFilter } = this.state;
		this.props.setFilterBy(
			Map({
				source: selectedFilter,
				values: List(),
				isSimpleFilter: true,
			}),
		);
	};

	componentDidUpdate = (prevProps) => {
		const { selectedFilter } = this.state;
		const { tags, groups, groupId, meetings } = this.props;

		if (
			(selectedFilter === "tag" && prevProps.tags !== tags) ||
			(selectedFilter === "group" && prevProps.groups !== groups) ||
			prevProps.groupId !== groupId ||
			prevProps.meetings !== meetings
		) {
			this.setOptions(selectedFilter);
		}
	};

	parseTags = (tags) => {
		const { meetings } = this.props;
		const tagsMap = {};

		if (meetings) {
			meetings.forEach((meeting) => {
				const tags = meeting.get("tags", List());
				tags.forEach((tag) => {
					tagsMap[tag] = true;
				});
			});
		}

		return tags
			.map((tag) => {
				return Map({
					value: tag.get("id"),
					label: tag.get("name"),
				});
			})
			.filter((tag) => {
				return tagsMap[tag.get("value")];
			})
			.sortBy((f) => f.get("label").toLowerCase());
	};

	handleMultipleGroupNames = (options, groups) => {
		let haveMultipleMap = Map();
		options.forEach((option, index) => {
			const label = option.get("label");
			if (haveMultipleMap.get(label)) {
				haveMultipleMap = haveMultipleMap.update(label, (arr) => {
					return arr.push(index);
				});
			} else {
				haveMultipleMap = haveMultipleMap.set(label, List([index]));
			}
		});

		haveMultipleMap.forEach((arr) => {
			if (arr.size > 1) {
				arr.forEach((i) => {
					const option = options.get(i);
					const group = groups.get(option.get("value"));
					const parentId = group.get("parent");
					const parent = groups.get(parentId);
					if (parent) {
						options = options.updateIn([i, "label"], (label) => {
							return `${label} | ${parent.get("translatedName")}`;
						});
					}
				});
			}
		});
		return options;
	};

	parseGroups = (groups) => {
		const { mapParentToChildren, groupId } = this.props;
		// if selected folder
		const ids = [];

		folderHelper.getDescendentsIds(ids, groupId, mapParentToChildren);
		let options = groups
			.toList()
			.map((project) => {
				return Map({
					value: project.get("id"),
					label: project.getIn(["name", "sv"]),
				});
			})
			.filter((project) => {
				return project.get("label") && ids.includes(project.get("value"));
			})
			.sortBy((f) => f.get("label").toLowerCase());
		options = this.handleMultipleGroupNames(options, groups);
		return options;
	};

	parseMeetings = (meetings) => {
		let meetingsSet = Map();
		if (!meetings) {
			return List();
		}

		meetings.forEach((meeting) => {
			if (meeting.get("name")) {
				meetingsSet = meetingsSet.set(meeting.get("name").trim(), meeting);
			}
		});

		return meetingsSet
			.toList()
			.map((meeting) => {
				let label = meeting.get("name");
				if (label === "" || label === " ") {
					label = "n/a";
				}
				return Map({
					value: meeting.get("id"),
					label,
				});
			})
			.filter((meeting) => {
				return meeting.get("label");
			})
			.sortBy((f) => f.get("label").toLowerCase());
	};

	setOptions = (selectedFilter) => {
		const { tags, groups, meetings } = this.props;

		switch (selectedFilter) {
			case "tag":
				this.setState({ options: this.parseTags(tags) });
				break;
			case "group":
				this.setState({ options: this.parseGroups(groups) });
				break;
			case "meeting":
				this.setState({ options: this.parseMeetings(meetings) });
				break;
			default:
		}
	};

	// Triggers when filter type is changed
	onChangeSelectedFilter = (selectedFilter) => {
		// Previously selected filter
		const prevSelectedFilter = this.state.selectedFilter;
		let { savedValues, selectedValues } = this.state;

		// Save current selection to backup
		savedValues = savedValues.setIn([prevSelectedFilter], selectedValues);

		// Read values from backup if there are any
		selectedValues = savedValues.get(selectedFilter, List());

		// Close menu
		this.moreActionBtnRef && this.moreActionBtnRef.onToggleMenu();

		// Get the new options
		this.setOptions(selectedFilter);

		this.setFilterBy(selectedFilter, selectedValues);

		// Update states
		this.setState({ selectedFilter, savedValues, selectedValues });
	};

	setFilterBy = (selectedFilter, selectedValues) => {
		const { meetings } = this.props;

		if (selectedFilter === "meeting") {
			let meetingMap = Map();
			meetings.forEach((doc) => {
				meetingMap = meetingMap.set(doc.get("id"), doc);
			});

			selectedValues = selectedValues.map((selectedValue) => {
				return meetingMap.getIn([selectedValue, "name"]);
			});
		}

		this.props.setFilterBy(
			Map({
				source: selectedFilter,
				values: selectedValues,
				isSimpleFilter: true,
			}),
		);
	};

	// Triggers when a new value is added or removed
	onChange = (selectedValues) => {
		const { selectedFilter } = this.state;
		this.setState({ selectedValues });

		this.setFilterBy(selectedFilter, selectedValues);
	};

	renderDropdown = () => {
		const { selectedFilter } = this.state;

		return (
			<DropdownMenuContainer
				menuIcon="faChevronDown"
				menuNoTop={true}
				buttonMinWidth="24px"
				buttonNoHorizontalPadding={true}
				tooltipTid="tooltip.meetings.filter_by.dropdown"
				ref={(ref) => (this.moreActionBtnRef = ref)}
			>
				{FILTER_BY_ITEMS.map((item, index) => {
					// Don't render item if it is currently selected
					if (selectedFilter === item) {
						return null;
					}

					return (
						<DropdownItem
							onClick={this.onChangeSelectedFilter.bind(null, item)}
							key={index}
						>
							<Text tid="component.filterby.menu.filter_by" />
							&nbsp;
							<Text tid={`component.filterby.item.${item}`} />
						</DropdownItem>
					);
				})}
			</DropdownMenuContainer>
		);
	};

	render = () => {
		const { selectedFilter, selectedValues, options } = this.state;
		const { totalMeetings, totalMeetingsShown } = this.props;

		return (
			<FilterBy
				dropdownRenderer={this.renderDropdown}
				options={options}
				onChange={this.onChange}
				selectedFilterByItem={selectedFilter}
				value={selectedValues}
				closeMenuOnSelect={true}
				counterLeftValue={totalMeetingsShown}
				counterRightValue={totalMeetings}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		tags: store.tags.getIn(["tags"]),
		groups: store.groups.get("groups"),
		meetings: store.meetings.get("allMeetings"),
		mapParentToChildren: store.groups.get("mapParentToChildren"),
		groupId: store.groups.get("selectedGroupId"),
	};
};

const mapActionsToProps = {
	listTags,
	setFilterBy,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingsFilterByContainer);
