import React, { PureComponent } from "react";
import styled from "styled-components";
import { list } from "react-immutable-proptypes";
import Text from "../text/text";
import { List } from "immutable";

const StyledWrapper = styled.div`
	display: flex;
	flex: 1;
	position: absolute;
	z-index: 1000;
	background: black;
	opacity: 0.7;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export default class SimpleConfirmationModal extends PureComponent {
	static propTypes = {
		config: list,
	};

	static defaultProps = {
		config: List(),
	};

	renderText = (cfg, index) => {
		return (
			<Text
				key={index}
				tid={cfg.get("tid")}
				color={cfg.get("color", "white")}
				bold={cfg.get("bold")}
				tag={cfg.get("tag", "p")}
				fontSize={cfg.get("fontSize", "42px")}
			/>
		);
	};

	render = () => {
		const { config } = this.props;

		return (
			<StyledWrapper>
				{config.size > 0 && config.map(this.renderText)}
			</StyledWrapper>
		);
	};
}
