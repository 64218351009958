export const invonoDefault = {
	dateProp: "createdAt",
	order: "datePropDesc",
	showOnlyTrashed: false,
};

export const showOnlyTrashedOptions = [
	{
		value: true,
		label: "generic.yes",
	},
	{
		value: false,
		label: "generic.no",
	},
];

export const datePropOptions = [
	{
		value: "lastModified",
		label: "documents.dateprop.lastmodified",
	},
	{
		value: "createdAt",
		label: "documents.dateprop.createdat",
	},
	{
		value: "validFrom",
		label: "documents.dateprop.validfrom",
	},
	{
		value: "validTo",
		label: "documents.dateprop.validto",
	},
];

export const orderOptions = [
	{
		value: "title",
		label: "documents.filter.order.alphabetical",
	},
	{
		value: "datePropAsc",
		label: "documents.filter.order.date_asc",
	},
	{
		value: "datePropDesc",
		label: "documents.filter.order.date_desc",
	},
];

export const docStatusOptions = [
	//{
	//	value: 'show_all',
	//	label: 'documents.status.show_all'
	//},
	{
		value: "INVALID",
		label: "documents.status.overdue",
	},
	{
		value: "WARNING",
		label: "documents.status.warning",
	},
	{
		value: "none",
		label: "documents.status.none",
	},
];

export const signedDocOptions = [
	{
		value: "unsigned",
		label: "documents.filter.signed.not_signed",
	},
	{
		value: "pending",
		label: "documents.filter.signed.pending",
	},
	{
		value: "ongoing",
		label: "documents.filter.signed.ongoing",
	},
	{
		value: "completed",
		label: "documents.filter.signed.completed",
	},
];
