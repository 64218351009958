import React, { PureComponent } from "react";
import { string, array, func, bool } from "prop-types";
import styled from "styled-components";

import Text from "../text/text";
import ModalTab from "./modal-tab";

const StyledWrapper = styled.div`
	display: flex;
	flex: 1;
	min-height: 69px;
	border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const StyledTabsWrapper = styled.div`
	align-items: flex-end;
	margin-left: auto;
	flex-direction: row;
	display: flex;
	position: relative;
	bottom: -1px;
	padding-right: ${(props) =>
		props.showFilterButton ? props.theme.spacing[4] : props.theme.spacing[6]};
`;

const StyledTitle = styled.div`
	padding: 14px ${(props) => props.theme.spacing[6]};
`;

export default class ModalTabsHeader extends PureComponent {
	static propTypes = {
		tabs: array,
		activeTab: string,
		onChange: func,
		title: string,
		showFilterButton: bool,
		renderFilterButton: func,
	};

	static defaultProps = {
		showFilterButton: false,
	};

	renderTab = (tab, index) => {
		const { onChange, activeTab } = this.props;

		return (
			<ModalTab
				onChange={onChange}
				tabName={tab.tabName}
				isActive={activeTab === tab.tabName}
				key={index}
				textTid={tab.title}
				turnedOff={tab.disabled}
				tooltipTid={tab.tooltip}
			/>
		);
	};

	render = () => {
		const { title, tabs, showFilterButton, renderFilterButton } = this.props;

		return (
			<StyledWrapper>
				<StyledTitle>
					<Text tid={title} tag="h4" color="catalinaBlue" />
				</StyledTitle>
				<StyledTabsWrapper showFilterButton={showFilterButton}>
					{tabs && tabs.length > 0 ? tabs.map(this.renderTab) : null}
				</StyledTabsWrapper>
				{showFilterButton && typeof renderFilterButton === "function"
					? renderFilterButton()
					: null}
			</StyledWrapper>
		);
	};
}
