import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { Map } from "immutable";
import {
	fetchExternalDocument,
	saveExternalDocument,
	downloadDocument,
} from "../../../actions/documents.actions";
import { updateAttachedDocumentLocal } from "../../../actions/attachments.actions";
import TagsSelectContainer from "../../shared/tags-select.container";
import DocumentDetails from "../../../dumb-components/meetings/document-details/document-details";

import {
	EVENT_TYPE_DOCUMENT_UPDATE,
	EVENT_TYPE_DOCUMENT_DELETE,
} from "/shared/constants";
import { getQuery } from "../../../interfaces/history";
class MeetingDocumentDetailsContainer extends Component {
	componentDidMount = () => {
		const {
			match: {
				params: { documentId },
			},
			fetchExternalDocument,
		} = this.props;

		if (documentId) {
			fetchExternalDocument(documentId);
		}
	};

	componentDidUpdate = (prevProps) => {
		const {
			match: {
				params: { documentId },
			},
			fetchExternalDocument,
		} = this.props;

		if (documentId !== prevProps.match.params.documentId) {
			fetchExternalDocument(documentId);
		}

		this.checkLiveUpdateEvents();
	};

	shouldComponentUpdate = (nextProps) => {
		const {
			document,
			match: {
				params: { documentId },
			},
			audit,
		} = this.props;

		if (nextProps.match.params.documentId !== documentId) {
			return true;
		}

		if (nextProps.document !== document) {
			return true;
		}

		if (nextProps.audit !== audit) {
			return true;
		}

		return false;
	};

	checkLiveUpdateEvents = () => {
		const {
			audit,
			fetchExternalDocument,
			match: {
				params: { documentId },
			},
			location: { search },
			history,
		} = this.props;
		const documentUpdate = audit.get(EVENT_TYPE_DOCUMENT_UPDATE, Map());
		const documentDelete = audit.get(EVENT_TYPE_DOCUMENT_DELETE, Map());

		if (
			documentUpdate.get("r") === true &&
			documentUpdate.get("objId") === documentId
		) {
			fetchExternalDocument(documentId);
		}

		if (
			documentDelete.get("r") === true &&
			documentDelete.get("objId") === documentId
		) {
			history.push({
				pathname: this.getComponentBasePath(),
				search,
			});
		}
	};

	getComponentBasePath = () => {
		const {
			location: { pathname },
		} = this.props;
		const path = pathname;
		const pathArray = path.split("documents");
		return pathArray[0] + "documents";
	};

	doDebounce = debounce((objType, objId, doc) => {
		const { saveExternalDocument } = this.props;
		saveExternalDocument(objType, objId, doc);
	}, 1000);

	updateAttachedDocumentLocal = (doc) => {
		const { updateAttachedDocumentLocal } = this.props;
		const query = getQuery();
		const { objId } = query;
		updateAttachedDocumentLocal(objId, doc);
		const link = doc.get("links").find((l) => l.get("objId") === objId);
		this.doDebounce(link.get("objType"), link.get("objId"), doc);
	};

	onChange = (fieldName, value) => {
		let { document } = this.props;
		document = document.set(fieldName, value);
		this.updateAttachedDocumentLocal(document);
	};

	onOpenFile = () => {
		const { document, downloadDocument } = this.props;
		downloadDocument({ documentId: document.get("id"), openInViewer: true });
	};

	onDownloadFile = () => {
		const { document, downloadDocument } = this.props;
		downloadDocument({ documentId: document.get("id") });
	};

	renderTagsSelectContainer = () => {
		const { document, isSimpleMode } = this.props;

		return (
			<TagsSelectContainer
				fieldName="tags"
				onTagsChange={this.onChange}
				value={document && document.get("tags")}
				readOnly={isSimpleMode}
			/>
		);
	};

	render = () => {
		const { document, isSimpleMode } = this.props;

		return (
			<DocumentDetails
				document={document}
				onChange={this.onChange}
				onOpenFile={this.onOpenFile}
				onDownloadFile={this.onDownloadFile}
				renderTagsSelectContainer={this.renderTagsSelectContainer}
				readOnly={isSimpleMode}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		company: store.company.company,
		isSimpleMode: store.meetings.getIn([
			"meeting",
			"computedValues",
			"isSimpleMode",
		]),
		document: store.attachments.get("attachment"),
		audit: store.audit.get("documents"),
	};
};

const mapActionsToProps = {
	fetchExternalDocument,
	updateAttachedDocumentLocal,
	saveExternalDocument,
	downloadDocument,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MeetingDocumentDetailsContainer);
