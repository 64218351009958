import React, { Component } from "react";
import { connect } from "react-redux";
import { func } from "prop-types";
import moment from "../../../modules/moment.module";

import Text from "../../../dumb-components/shared/text/text";
import CountDown from "../../../dumb-components/shared/count-down/count-down";

import { MEETING_VOTING_STARTED } from "/shared/constants";

class EndVotingTimerContainer extends Component {
	static propTypes = {
		onFinish: func,
	};

	getTimer = () => {
		const { startedAt, timer, endingAt, onFinish } = this.props;

		let diff;
		if (timer) {
			diff = moment(endingAt).diff(moment(startedAt));
		}

		return (
			<CountDown
				mode="live"
				time={diff}
				dateFormat="mm:ss"
				renderRaw={true}
				onFinish={onFinish}
			/>
		);
	};

	render = () => {
		const { votingStatus, timer } = this.props;

		if (votingStatus !== MEETING_VOTING_STARTED) {
			return null;
		}

		if (!timer) {
			return null;
		}

		const timerValue = this.getTimer();
		const timerComponent = (
			<Text color="green" fontSize="28px">
				{timerValue}
			</Text>
		);

		return (
			<Text
				tag="h4"
				color="catalinaBlue"
				tid="meetings.agm.voting_modal.progress_view.header.timer"
				values={{ timer: timerComponent }}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		startedAt: store.votings.getIn(["voting", "startedAt"]),
		timer: store.votings.getIn(["voting", "timer"]),
		endingAt: store.votings.getIn(["voting", "endingAt"]),
		votingStatus: store.votings.getIn(["voting", "status"]),
	};
};

const mapActionsToProps = {};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(EndVotingTimerContainer);
