// -------------------------------------------------------------------------------------
// ---------------------------- Custom Select Filters ----------------------------------
// -------------------------------------------------------------------------------------
// Standard select filtering
export const filterPartialMatch = (option, input) => {
	if (typeof option.label !== "string") {
		return true;
	}

	const currentLabel = option.label && option.label.toLowerCase().trim();
	const currentInput = input.toLowerCase().trim();

	return currentLabel.includes(currentInput) ? true : false;
};

export const filterExactWordMatch = (option, input) => {
	if (typeof option.label !== "string") {
		return true;
	}

	const currentLabel = option.label && option.label.toLowerCase();
	const currentInput = input.toLowerCase();
	const words = currentLabel.split(" ");

	return (
		words.some((w) => w.startsWith(currentInput)) ||
		currentLabel.startsWith(currentInput)
	);
};
