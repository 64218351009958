import React from "react";
import { func, string } from "prop-types";
import styled, { css } from "styled-components";
import Modal from "../../../../../app/dumb-components/shared/modal/modal";
import TransparentButton from "../../../../../app/dumb-components/shared/button-v2/src/buttons/transparent-button";
import Text from "../../../../../app/dumb-components/shared/text/text";

const StyledModalHeaderBar = styled.div`
	display: flex;
	align-items: center;
	padding: 14px ${(props) => props.theme.spacing[6]};
	border-bottom: 1px solid black;
	min-height: 50px;
	gap: ${(props) => props.theme.spacing[5]};

	${(props) =>
		props.headerBackground &&
		css`
			background: ${(props) => props.theme.colors[props.headerBackground]};
		`}

	.transparent-button-wrapper {
		display: flex;
		margin-left: auto;
		gap: 32px;
	}
`;

const StyledChartContainer = styled.div`
	position: relative;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	height: 90vh;
	width: 90vw;
`;

export default function ChartModal({
	onCancel,
	onExport,
	children,
	leftHeader,
	isOpen,
}) {
	const getExportButtonTid = () => {
		return "investments.shareholders.grid.modal.header.button.export_chart";
	};

	const getCloseButtonTid = () => {
		return "investments.shareholders.grid.modal.header.button.close";
	};

	return (
		<Modal
			fullSize
			isOpen={isOpen}
			scrollableContent={false}
			headerComponent={
				<StyledModalHeaderBar headerBackground="white">
					{leftHeader && <Text tid={leftHeader} tag="h4" color="black" />}

					<div className="transparent-button-wrapper">
						<TransparentButton
							tid={getExportButtonTid()}
							textColor="black"
							onClick={onExport}
							hasUnderline
						/>
						<TransparentButton
							tid={getCloseButtonTid()}
							textColor="black"
							onClick={onCancel}
							hasUnderline
						/>
					</div>
				</StyledModalHeaderBar>
			}
		>
			<StyledChartContainer>{children}</StyledChartContainer>
		</Modal>
	);
}

ChartModal.propTypes = {
	leftHeader: string,
	onCancel: func,
};
