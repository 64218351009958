import React, { Component } from "react";

class ShareholderValueRenderer extends Component {
	render = () => {
		const arr = this.props.children.split("[split-here]");
		const name = arr[0];
		const id = arr[1];

		return (
			<div className="Select-value">
				<span className="Select-value-label">
					{name} <span className="text--muted">{id}</span>
				</span>
			</div>
		);
	};
}

export default ShareholderValueRenderer;
