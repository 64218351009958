import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import PartialClosureModal from "./allocation-partial-closure-modal";
import { allocationCloseInfoNotification } from "../../../../actions/allocation.actions";
import { useDispatch } from "react-redux";

const SplitButton = (props) => {
	const { t } = useTranslation();
	const options = [
		t("emissions.allocation.close-emission"),
		t("emissions.allocation.partial-closure"),
	];
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [partialClosureModal, setPartialClosureModal] = useState(false);

	const selection = ["definiteClose", "partialClose"][selectedIndex];

	const handleClick = () => {
		if (!props.sharesAreAllocated({ selection })) {
			return false;
		}

		if (props.isOverAllocated() || props.proRataNotFullfilled()) {
			return false;
		}

		if (props.isEmissionValidCallback()) {
			setPartialClosureModal(true);
		} else {
			if (options[selectedIndex] === t("emissions.allocation.close-emission")) {
				dispatch(
					allocationCloseInfoNotification(
						t("emissions.allocation.info.close.lowest-amount-of-shares"),
					),
				);
			} else if (
				options[selectedIndex] === t("emissions.allocation.partial-closure")
			) {
				dispatch(
					allocationCloseInfoNotification(
						t(
							"emissions.allocation.info.partial-close.lowest-amount-of-shares",
						),
					),
				);
			}
		}
	};

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	return (
		<>
			{options[selectedIndex] === t("emissions.allocation.partial-closure") && (
				<PartialClosureModal
					{...props}
					partialCloseDesc
					open={partialClosureModal}
					close={() => setPartialClosureModal(false)}
					spinnerText={t("emissions.allocation.partialClosure.spinner-text")}
				/>
			)}
			{options[selectedIndex] === t("emissions.allocation.close-emission") && (
				<PartialClosureModal
					{...props}
					closeDesc
					open={partialClosureModal}
					close={() => setPartialClosureModal(false)}
					spinnerText={t("emissions.allocation.definiteClosure.spinner-text")}
					canConvertPartialClosureToDefinite={
						props.canConvertPartialClosureToDefinite
					}
				/>
			)}
			<ButtonGroup
				disabled={props.preventChanges}
				variant="contained"
				ref={anchorRef}
				aria-label="split button"
				sx={{ my: "1rem" }}
			>
				<Button
					onClick={handleClick}
					sx={{ borderRight: "1px solid white !important" }}
				>
					<Typography variant={"h5"}>{options[selectedIndex]}</Typography>
				</Button>
				<Button
					size="small"
					aria-controls={open ? "split-button-menu" : undefined}
					aria-expanded={open ? "true" : undefined}
					aria-label="select merge strategy"
					aria-haspopup="menu"
					onClick={handleToggle}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 1,
					width: "20%",
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === "bottom" ? "center top" : "center bottom",
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu">
									{options.map((option, index) => (
										<MenuItem
											key={option}
											disabled={index === 2}
											selected={index === selectedIndex}
											onClick={(event) => handleMenuItemClick(event, index)}
										>
											{option}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};

SplitButton.propTypes = {
	canConvertPartialToDefinite: PropTypes.Boolean,
	sharesAreAllocated: PropTypes.func,
	isOverAllocated: PropTypes.func,
	proRataNotFullfilled: PropTypes.func,
	isEmissionValidCallback: PropTypes.func,
	preventChanges: PropTypes.Boolean,
};

SplitButton.defaultProps = {
	canConvertPartialClosureToDefinite: false,
};

export default SplitButton;
