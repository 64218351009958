import React, { Component } from "react";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import { fromJS } from "immutable";
import moment from "../../../../modules/moment.module";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../../../actions/notify.actions";
import sharesValidator from "../../../helpers/shares.validator";
import NumericInput from "../../../../dumb-components/fields/numeric-input";
import Datepicker from "../../../../dumb-components/shared/datepicker/datepicker";
import { Button } from "@/components/ui/button";

import i18n from "../../../../i18n";

const { t } = i18n;

class ShareCapital extends Component {
	static propTypes = {
		intl: intlShape.isRequired,
	};

	constructor(props) {
		super(props);
		this.step = 0;
	}

	componentDidMount() {
		if (this.props.company && !this.props.transaction.get("date")) {
			this.onDateChange(moment(this.props.company.dateOfReg), false);
		}
	}

	componentDidUpdate(prevProps) {
		const newInitialShareData = this.props.transaction.getIn(
			["handlerData", "initialShareData"],
			fromJS({}),
		);
		const newShareCapital = newInitialShareData.get("shareCapital");
		const newNumOfTotalShares = newInitialShareData.get("numOfTotalShares");

		const oldInitialShareData = prevProps.transaction.getIn(
			["handlerData", "initialShareData"],
			fromJS({}),
		);
		const oldNumOfTotalShares = oldInitialShareData.get("numOfTotalShares");
		const oldShareCaplital = oldInitialShareData.get("shareCapital");

		if (
			newShareCapital !== oldShareCaplital ||
			newNumOfTotalShares !== oldNumOfTotalShares
		) {
			this.calculateQuota(this.props.transaction);
		}

		if (
			this.props.company !== prevProps.company &&
			!this.props.transaction.get("date")
		) {
			this.onDateChange(this.props.company.dateOfReg);
		}
	}

	onSubmit() {
		const { setupType, transaction, onNext, jumpToStep } = this.props;

		try {
			sharesValidator.validateShareCapital(
				this.props.transaction.getIn(["handlerData", "initialShareData"]),
			);

			if (setupType === "b") {
				const date = transaction.get("date");

				if (!date) {
					this.onDateChange(moment(date));
				}
			}

			onNext(this.step + 1);
			jumpToStep(this.step + 1);
		} catch (e) {
			this.props.addErrorNotification({
				text: e.message,
			});
		}
	}

	formatNumerInput(num) {
		const { formatNumber } = this.props.intl;
		if (isNaN(num)) {
			return num;
		}

		return formatNumber(num);
	}

	calculateQuota(transaction) {
		const shareCapital = transaction.getIn(
			["handlerData", "initialShareData", "shareCapital"],
			0,
		);
		const numOfTotalShares = transaction.getIn(
			["handlerData", "initialShareData", "numOfTotalShares"],
			0,
		);
		const quotaValue = shareCapital / numOfTotalShares;

		if (typeof quotaValue !== "number" || quotaValue === Infinity) {
			this.onChange("quotaValue", 0, transaction);
			return;
		}

		this.onChange("quotaValue", quotaValue, transaction);
	}

	onChange(field, val, nextTransaction) {
		let { transaction, onChange } = this.props;
		if (nextTransaction) {
			transaction = nextTransaction;
		}
		transaction = transaction.setIn(
			["handlerData", "initialShareData", field],
			val,
		);
		onChange(transaction);
	}

	onDateChange(date, fromLater) {
		let transaction = this.props.transaction.set("date", date);
		transaction = transaction.setIn(
			["handlerData", "initialShareData", "isFromDateLater"],
			fromLater ? true : false,
		);
		this.props.onChange(transaction);
	}

	renderFormGroup() {
		const { setupType, i18n, transaction } = this.props;
		let date = transaction.get("date");

		return (
			<div className="list list--striped list--table">
				<div className="list__list-header">
					<div className="list__list-header__col">
						<FormattedMessage id="share_capital" />
					</div>
					<div className="list__list-header__col">
						<FormattedMessage id="total_number_of_shares" />
					</div>
					<div className="list__list-header__col">
						<FormattedMessage id="quota_value" />
					</div>
					{setupType === "b" && (
						<div className="list__list-header__col">
							<FormattedMessage id="choose_a_date_to_start_your_register_from" />
						</div>
					)}
				</div>
				<div className="list__body">
					<div className="list__item">
						<span className="list__item__col">
							<span className="list__item__input list__item__input--pad-right list__item__input--pad-lft">
								<NumericInput
									type="text"
									className="form-control text--align-right"
									placeholder={i18n.messages["shares.value_in_sek"]}
									allowDecimals
									value={this.props.transaction.getIn([
										"handlerData",
										"initialShareData",
										"shareCapital",
									])}
									onChange={(val) => {
										this.onChange("shareCapital", val);
									}}
								/>
							</span>
						</span>
						<span className="list__item__col">
							<span className="list__item__input list__item__input--pad-right">
								<NumericInput
									type="text"
									className="form-control text--align-right"
									placeholder="0"
									value={this.props.transaction.getIn([
										"handlerData",
										"initialShareData",
										"numOfTotalShares",
									])}
									onChange={(val) => {
										this.onChange("numOfTotalShares", val);
									}}
								/>
							</span>
						</span>
						<span className="list__item__col">
							<span className="list__item__input list__item__input--pad-right">
								<NumericInput
									type="text"
									className="form-control text--align-right"
									placeholder="0"
									disabled={true}
									allowDecimals
									value={this.props.transaction.getIn([
										"handlerData",
										"initialShareData",
										"quotaValue",
									])}
								/>
							</span>
						</span>
						{setupType === "b" && (
							<span className="list__item__col">
								<span className="list__item__input text--align-right">
									<Datepicker
										language={i18n.language}
										calendarPlacement="bottom-end"
										value={date ? date : moment()}
										onChange={(val) => {
											this.onDateChange(val, true);
										}}
									/>
								</span>
							</span>
						)}
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { setupType } = this.props;

		return (
			<div className="h-full flex gap-12 flex-col">
				<div className="text-center self-center max-w-screen-sm">
					{t(
						setupType === "a"
							? "share_capital_information"
							: "share_capital_information_b",
					)}
				</div>
				<div className="i-panel i-panel--white max-w-fit self-center">
					<div className="i-panel__body">{this.renderFormGroup()}</div>
				</div>
				<div className="flex justify-center p-4">
					<Button onClick={this.onSubmit.bind(this)}>{t("next")}</Button>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		i18n: state.i18n,
		company: state.company.company,
	};
}

const ShareCapitalConnected = connect(mapStateToProps, {
	addErrorNotification,
	addInfoNotification,
})(ShareCapital);
const ShareCapitalInjectIntl = injectIntl(ShareCapitalConnected);
export default ShareCapitalInjectIntl;
