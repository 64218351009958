import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, number, oneOf } from "prop-types";
import { Margin } from "styled-components-spacing";
import Alert from "../../../dumb-components/shared/alert/alert";
import Text from "../../../dumb-components/shared/text/text";

class AlertContainer extends Component {
	static propTypes = {
		meetingIsLoaded: bool,
		membersNeedNotification: bool,
		numOfMembers: number,
		mode: oneOf(["feedback", "signoff"]),
	};

	static defaultProps = {
		mode: "feedback",
	};

	getTids = () => {
		const { mode } = this.props;
		let allInvitedTid, needsInviteTid;

		if (mode === "feedback") {
			allInvitedTid = "meetings.protocol.feedback.alert.all_invited";
			needsInviteTid =
				"meetings.protocol.feedback.alert.feedbackees_not_notified";
		}

		if (mode === "signoff") {
			allInvitedTid = "meetings.protocol.signoff.alert.all_invited";
			needsInviteTid = "meetings.protocol.signoff.alert.signees_not_notified";
		}

		return { allInvitedTid, needsInviteTid };
	};

	render = () => {
		const { meetingIsLoaded, membersNeedNotification, numOfMembers } =
			this.props;

		if (!meetingIsLoaded || numOfMembers === 0) {
			return null;
		}

		const { allInvitedTid, needsInviteTid } = this.getTids();

		if (!membersNeedNotification) {
			return (
				<Margin top={3}>
					<Alert mode="info" icon="faCheck" iconType="solid">
						<Text color="white" tid={allInvitedTid} />
					</Alert>
				</Margin>
			);
		}

		return (
			<Margin top={3}>
				<Alert mode="warning" icon="faExclamationTriangle" iconType="solid">
					<Text color="white" tid={needsInviteTid} />
				</Alert>
			</Margin>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {};

export default connect(mapStoreToProps, mapActionsToProps)(AlertContainer);
