import React, { useContext } from "react";
import { bool } from "prop-types";
import { FormattedMessage } from "react-intl";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import { useDispatch } from "react-redux";
import { useConfirmContext } from "../../../containers/shared/confirm/confirm.context";
import { deletePersonalInvestment } from "../../../actions/cap-table-details.actions";
import history from "../../../interfaces/history";
import { removeCompanyFromDealflow } from "../../../actions/dealflow.actions";
import { InvestmentsContext } from "../../../containers/investments/investment.container";

export default function RegisteredPanel({
	investment,
	isCompanyInvestor,
	isRegistered,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const confirm = useConfirmContext();
	const { inDealflow } = useContext(InvestmentsContext);

	const deleteInvestment = async () => {
		const result = await confirm.showConfirmation(
			"investment.confirm_deletion.title",
			[
				{ tid: "investment.confirm_deletion.question" },
				{ tid: "investment.confirm_deletion.message" },
			],
		);

		if (!result) {
			return;
		}

		const id = investment.get("id");
		dispatch(
			deletePersonalInvestment(id, () => {
				history.push("/portfolio");
			}),
		);
	};

	const unfollowDealflowCompany = () => {
		const str = window.location.search;
		const dealflowId = str.substring(str.indexOf("=") + 1);

		dispatch(
			removeCompanyFromDealflow(dealflowId, () => {
				history.push("/dealflow");
			}),
		);
	};

	return (
		<>
			<Card borderradius={"true"}>
				<CardContent>
					<Typography variant="body2" gutterBottom>
						{isRegistered
							? t("portfolio.investments.general.company_registered")
							: t("portfolio.investments.general.company_not_registered")}
					</Typography>

					{isCompanyInvestor && isRegistered ? null : (
						<Typography variant="body1">
							<Link
								sx={{ pb: 0.3 }}
								onClick={
									inDealflow ? unfollowDealflowCompany : deleteInvestment
								}
								component={"button"}
								color={"positive.main"}
							>
								<FormattedMessage
									id={
										inDealflow
											? "portfolio.investments.general.dealflow_preview.unfollow_link"
											: "portfolio.investments.general.remove_investment_link"
									}
								/>
							</Link>
						</Typography>
					)}
				</CardContent>
			</Card>
		</>
	);
}

RegisteredPanel.propTypes = {
	isCompanyInvestor: bool,
};
