import { DateTime } from "luxon";
import Grid from "@mui/material/Grid";
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Divider, useTheme } from "@mui/material";
import EditPaymentInfoModal from "./payment-info-modal";
import WhiteSection from "../../common/white-section";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { styled } from "@mui/material/styles";
import LinearProgress, {
	linearProgressClasses,
} from "@mui/material/LinearProgress";
import AutoSave from "../../forms/AutoSave";
import { updateSubscriptionSlipPaymentInfo } from "../../../../actions/emissions.actions";
import { DatePicker } from "mui-rff";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import { NUMBER_FORMAT_DEFAULT } from "/shared/constants.json";
import { displayDate } from "../../utils/display-helpers";

export const PaymentFormComponent = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const emission = useSelector((state) => state.emissions.current);
	const paymentInfo = useSelector(
		(state) => state.emissions.subscriptionSlip.paymentInfo,
	);
	const [formPaymentInfo, setFormPaymentInfo] = useState({
		paymentDueDate: emission?.paymentDueDate
			? DateTime.fromISO(emission.paymentDueDate)
			: null,
		bank: paymentInfo?.bank,
		accountNumber: paymentInfo?.accountNumber,
		iban: paymentInfo?.iban,
		bic: paymentInfo?.bic,
	});

	const initiallyFilled = () => {
		let valid = true;
		for (const [_key, value] of Object.entries(formPaymentInfo)) {
			if (!value) {
				valid = false;
			}
		}
		props.setValidForm(valid);
	};

	const onSubmit = async (values) => {
		const payload = {
			paymentInfo: {
				bank: values.bank,
				accountNumber: values.accountNumber,
				iban: values.iban,
				bic: values.bic,
			},
			paymentDueDate: emission.paymentDueDate
				? displayDate(values.paymentDueDate)
				: null,
		};
		await dispatch(
			updateSubscriptionSlipPaymentInfo(
				emission.companyId,
				emission.id,
				payload,
			),
		);
	};

	const validate = (values) => {
		const errors = {};

		if (!values.bank) {
			errors.bank = t("emissions.payment.bank-error");
		}
		if (!values.accountNumber) {
			errors.accountNumber = t("emissions.payment.account-number-error");
		}
		if (!values.iban) {
			errors.iban = t("emissions.payment.iban-error");
		}
		if (!values.bic) {
			errors.bic = t("emissions.payment.bic-error");
		}

		return errors;
	};

	useEffect(() => {
		setFormPaymentInfo({
			paymentDueDate: emission?.paymentDueDate
				? DateTime.fromISO(emission.paymentDueDate)
				: null,
			bank: paymentInfo?.bank,
			accountNumber: paymentInfo?.accountNumber,
			iban: paymentInfo?.iban,
			bic: paymentInfo?.bic,
		});
		initiallyFilled();
	}, []);

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={formPaymentInfo}
			validate={validate}
			render={({ valid }) => {
				return (
					<Grid container sx={{ display: "flex" }}>
						<AutoSave
							save={(values) => {
								props.setValidForm(valid);
								if (valid) {
									onSubmit(values);
								}
							}}
						/>
						<Grid item xs={12} sx={{ display: "flex" }}>
							<Grid container rowSpacing={3} columnSpacing={3}>
								<Grid item xs={12}>
									<DatePicker
										required
										label={t("emissions.subscription-form.payment-due-date")}
										name="paymentDueDate"
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										required
										name={"bank"}
										label={t("emissions.subscription-form.bank")}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										required
										name={"accountNumber"}
										label={t("emissions.subscription-form.bank-account-number")}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										required
										name={"iban"}
										label={t("emissions.subscription-form.iban")}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										required
										name={"bic"}
										label={t("emissions.subscription-form.bic")}
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body2" sx={{ my: 1 }}>
										{t("emissions.subscription-form.payment-reference")}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				);
			}}
		/>
	);
};

export const PaymentInfoComponent = (props) => {
	const paymentInfo = useSelector(
		(state) => state.emissions.subscriptionSlip.paymentInfo,
	);
	const [editPaymentModal, setEditPaymentModal] = useState(false);
	const [, setReceiptModal] = useState(false);
	const { t } = useTranslation();

	const closeModal = () => {
		setEditPaymentModal(false);
		setReceiptModal(false);
	};

	return (
		<>
			<EditPaymentInfoModal
				closeAll={closeModal}
				open={editPaymentModal}
				close={() => setEditPaymentModal(false)}
				closure={props.closure}
			/>
			<WhiteSection
				sx={{
					display: "flex",
					justifyContent: "space-between",
					width: "-webkit-fill-available",
				}}
			>
				<Box sx={{ display: "flex", alignItems: "baseline" }}>
					<Typography
						sx={{ display: "flex", mr: "16px" }}
						variant={"subtitle1"}
					>
						{`${t("emissions.subscription-form.payment-information")}: `}
					</Typography>
					<Typography
						variant={"body1"}
						sx={{ mr: "8px", fontWeight: "600" }}
					>{`${t("emissions.subscription-form.bank")}: `}</Typography>
					<Typography variant={"body2"} sx={{ mr: "8px" }}>
						{paymentInfo?.bank}
					</Typography>
					<Divider
						flexItem
						orientation={"vertical"}
						variant={"middle"}
						sx={{ my: 0, mr: "8px", border: "1px solid #D9E1E7" }}
					/>
					<Typography variant={"body1"} sx={{ mr: "8px", fontWeight: "600" }}>
						{`${t("emissions.subscription-form.bank-account-number")}: `}
					</Typography>
					<Typography variant={"body2"} sx={{ mr: "8px" }}>
						{paymentInfo?.accountNumber}
					</Typography>
					<Divider
						flexItem
						orientation={"vertical"}
						variant={"middle"}
						sx={{ my: 0, mr: "8px", border: "1px solid #D9E1E7" }}
					/>
					<Typography variant={"body1"} sx={{ mr: "8px", fontWeight: "600" }}>
						{`${t("emissions.subscription-form.iban")}: `}
					</Typography>
					<Typography variant={"body2"} sx={{ mr: "8px" }}>
						{paymentInfo?.iban}
					</Typography>
					<Divider
						flexItem
						orientation={"vertical"}
						variant={"middle"}
						sx={{ my: 0, mr: "8px", border: "1px solid #D9E1E7" }}
					/>
					<Typography variant={"body1"} sx={{ mr: "8px", fontWeight: "600" }}>
						{`${t("emissions.subscription-form.bic")}: `}
					</Typography>
					<Typography variant={"body2"} sx={{ mr: "8px" }}>
						{paymentInfo?.bic}
					</Typography>
				</Box>
				{!props.preventChanges ? (
					<CreateOutlinedIcon
						onClick={() => setEditPaymentModal(true)}
						sx={{
							cursor: "pointer",
							display: "flex",
							alignSelf: "flex-end",
							color: "#369FF4",
						}}
					/>
				) : (
					<></>
				)}
			</WhiteSection>
		</>
	);
};

export const PaymentDiagram = (props) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: "8px",
		width: "100%",
		borderRadius: "100px",
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: theme.palette.grey[200],
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: "#339999",
		},
	}));

	return (
		<Grid item xs={12}>
			<WhiteSection
				sx={{
					px: 0,
					py: 0,
					height: "296px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						borderBottom: 1,
						borderBottomColor: "divider",
						flexDirection: "column",
					}}
				>
					<Box sx={{ my: "32px", mx: "40px" }}>
						<Typography
							sx={{
								textTransform: "uppercase",
								color: "secondary.500",
								pb: "8px",
							}}
						>
							{t("emissions.payment.amount-paid-header")}
						</Typography>
					</Box>
					<Box
						sx={{
							mr: "40px",
							mt: "16px",
						}}
					>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<Typography variant={"h3"}>{props.deposit}</Typography>
							<Typography
								component={"span"}
								variant={"body1"}
								sx={{
									color: theme.palette.grey["800"],
									alignSelf: "flex-end",
									ml: "8px",
									fontWeight: "600",
								}}
							>
								{"/"}
							</Typography>
							<Typography
								component={"span"}
								variant={"subtitle2"}
								sx={{ color: "grey.500", alignSelf: "flex-end", ml: "8px" }}
							>
								{props.totalDeposit}
							</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								mt: "16px",
								mb: "62px",
								ml: "40px",
							}}
						>
							<Typography
								component={"span"}
								variant={"body2"}
								sx={{ color: "grey.500", mr: "8px" }}
							>
								{localeFormatNumber(props.percentage, NUMBER_FORMAT_DEFAULT)}%
							</Typography>
							<BorderLinearProgress
								variant="determinate"
								value={props.percentage}
							/>
						</Box>
					</Box>
				</Box>
				<Box sx={{ display: "flex", justifyContent: "center", my: "20px" }}>
					<Typography
						variant="body2Italic"
						sx={{ mr: "8px", color: "grey.500" }}
					>
						{t("emissions.payment.paid-amount.desc")}
					</Typography>
					<Typography variant="body2Italic" sx={{ color: "grey.500" }}>
						{props.allPrevPartialClosuresDeposits}
					</Typography>
				</Box>
			</WhiteSection>
		</Grid>
	);
};
