import React, { PureComponent } from "react";
import { bool, func, string, object } from "prop-types";
import styled from "styled-components";
import { map } from "react-immutable-proptypes";

import { formatIdPretty } from "../../../components/helpers/users";
import {
	findProxyByAttendeeId,
	getHasMeetingRole,
} from "../../../components/helpers/meeting.helper";

import ScrollView from "../../shared/layout/scroll-view/scroll-view";
import List from "../../shared/list/list";
import { StyledListHeaderTemplate } from "../../shared/list/list-header.template";
import ListItem from "../../shared/list-item/list-item";
import Text from "../../shared/text/text";
import Icon from "../../shared/icon/icon";
import Tooltip from "../../shared/tooltip/tooltip";
import ListTableColumn from "../../shared/list-table/list-table-column";
import NotificationBadge from "../../shared/notification-badge/notification-badge";
import { Select } from "../../shared/select";
import { getProxyDocumentsDisplayData } from "../../../components/helpers/meeting.helper";
import {
	InfiniteScroll,
	hideInfiniteScrollbars,
	generateScrollableTarget,
} from "../../shared/infinite-scroll";

import {
	ATTENDEE_STATUS_PRESENT,
	ATTENDEE_STATUS_ABSENT,
} from "/shared/constants";
import {
	ATTENDEE_STATUS_TO_COLOR_MAP,
	ATTENDEE_STATUS_TO_TEXT_MAP,
	ATTENDEES_ATTENDANCE_TYPE,
	ATTENDEES_STATUS_OPTIONS,
} from "../../../constants/meetings";

const StyledInfiniteScroll = styled(InfiniteScroll)`
	${hideInfiniteScrollbars}
`;

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const StyledFilterWrapper = styled.div`
	padding: 0 ${(props) => props.theme.spacing[6]};
	padding-left: ${(props) => props.theme.spacing["6-scrollOffset"]};
	margin-top: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

const StyledList = styled(List)`
	display: flex;
	padding: 0 ${(props) => props.theme.spacing["6-scrollOffset"]};
	margin-bottom: ${(props) => props.theme.spacing[5]};
`;

const StyledListItem = styled(ListItem)`
	display: flex;
	align-items: center;
	border-top: 1px solid ${(props) => props.theme.colors.border};
	margin-bottom: 0;

	:first-child {
		border-top: none;
	}

	:last-child {
		border-bottom: 1px solid ${(props) => props.theme.colors.border};
	}
`;
const StyledNoAttendeesWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: center;
	height: 50px;
`;

const StyledHeader = styled.div`
	${StyledListHeaderTemplate};
	margin-right: ${(props) => props.theme.spacing["0-scrollOffset"]};

	* {
		cursor: default;
	}
`;

const StyledName = styled(ListTableColumn).attrs({
	minWidth: 40,
	flex: 1,
	tighten: true,
})`
	display: flex;
	flex-direction: column;
`;

const StyledProxyWrapper = styled.div`
	display: grid;
	grid-template-columns: min-content auto;
	grid-template-rows: auto auto;
	grid-auto-flow: column;
	column-gap: ${(props) => props.theme.spacing[3]};
	align-items: center;

	> :first-child {
		grid-row: 1 / 3;
	}
`;

const StyledGeneralInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 28px; /* Offset for attachments icon */
`;

const StyledTextWrapper = styled.div``;

const ShareholderIcon = ({ isInvestor }) => (
	<ListTableColumn minWidth={16} center tighten>
		{isInvestor && <Icon icon="faStar" size="sm" type="solid" />}
	</ListTableColumn>
);

const Name = ({ children }) => <StyledName>{children}</StyledName>;

const Info = ({ children }) => (
	<ListTableColumn minWidth={40} flex={2} tighten>
		{children}
	</ListTableColumn>
);

const InviteResponse = ({ children, responseStatus }) => (
	<ListTableColumn minWidth={140} hideBelow={950} tighten>
		{children ? (
			children
		) : (
			<Text
				color={ATTENDEE_STATUS_TO_COLOR_MAP[responseStatus || "default"]}
				tid={ATTENDEE_STATUS_TO_TEXT_MAP[responseStatus || "default"]}
			/>
		)}
	</ListTableColumn>
);

const AttendingMethod = ({ children }) => (
	<ListTableColumn minWidth={140} tighten>
		{children}
	</ListTableColumn>
);

const Attendance = ({ children }) => (
	<ListTableColumn minWidth={140} tighten>
		{children}
	</ListTableColumn>
);

const Checkin = ({ children, verifiedAttendance }) => (
	<ListTableColumn minWidth={105} tighten>
		{children ? (
			children
		) : verifiedAttendance ? (
			<Text tid="generic.yes" color="green" />
		) : (
			<Text tid="generic.no" color="red" />
		)}
	</ListTableColumn>
);

const Dropdown = ({ children }) => (
	<ListTableColumn minWidth={36} tighten>
		{children}
	</ListTableColumn>
);

const HeaderText = ({ tid, tooltipTid }) => (
	<>
		{tooltipTid ? (
			<Tooltip tid={tooltipTid} delayShow="short">
				<Text tid={tid} bold={400} color="lightGrey" singleLine />
			</Tooltip>
		) : (
			<Text tid={tid} bold={400} color="lightGrey" singleLine />
		)}
	</>
);

export default class Rollcall extends PureComponent {
	static propTypes = {
		attendees: map,
		isOpen: bool,
		renderAttendenceContainer: func,
		renderAttendanceTypeContainer: func,
		renderMoreActionMenu: func,
		hideInfo: bool,
		hideCheckin: bool,
		hideMoreActions: bool,
		hideVotingInfo: bool,
		secretary: string,
		chairman: string,
		renderRoleDisplay: func,
		isAGM: bool,
		filterComponent: object,
		formData: map,
		onChange: func,
		scrollableTarget: string,
		loadAttendees: func,
		hasMoreAttendees: bool,
	};

	constructor(props) {
		super(props);

		const { scrollableTarget, renderScrollableElement } =
			generateScrollableTarget();
		this.scrollableTarget = scrollableTarget;
		this.renderScrollableElement = renderScrollableElement;
	}

	onChange = (attendeeId, fieldName, value) => {
		const { onChange } = this.props;
		onChange && onChange(attendeeId, fieldName, value);
	};

	getAttendeeResponse = (attendee) => {
		return attendee.get("responseStatus", null);
	};

	getIsAttendanceClearable = (attendee) => {
		const responseStatus = this.getAttendeeResponse(attendee);
		const currentStatus = attendee.get("status", null);

		return responseStatus !== currentStatus;
	};

	getAttendanceValue = (attendee, proxy) => {
		const { formData } = this.props;
		const attendeeId = attendee.get("userId");
		// const value = formData?.hasIn(['attendees', attendeeId, 'status'])
		// 	? formData.getIn(['attendees', attendeeId, 'status'])
		// 	: attendee.get('status')
		let value;
		if (formData?.hasIn(["attendees", attendeeId, "status"])) {
			value = formData?.hasIn(["attendees", attendeeId, "status"]);
		} else if (proxy) {
			value = proxy.get("status");
		} else {
			value = attendee.get("status");
		}

		// const statusSetFromApp = formData?.hasIn(['attendees', attendeeId, 'statusSetFromApp'])
		// 	? formData.getIn(['attendees', attendeeId, 'statusSetFromApp'])
		// 	: attendee.get('statusSetFromApp')
		let statusSetFromApp;
		if (formData?.hasIn(["attendees", attendeeId, "statusSetFromApp"])) {
			statusSetFromApp = formData.getIn([
				"attendees",
				attendeeId,
				"statusSetFromApp",
			]);
		} else {
			statusSetFromApp = attendee.get("statusSetFromApp");
		}

		const verifiedAttendance = this.getVerifiedAttendanceValue(
			attendeeId,
			attendee,
			proxy,
		);

		// Absent
		if (value === ATTENDEE_STATUS_ABSENT) {
			return value;
		}

		// Present and VAD
		if (value === ATTENDEE_STATUS_PRESENT && verifiedAttendance) {
			return value;
		}

		// Any status, overriden from front-end
		if (statusSetFromApp) {
			return value;
		}

		// Don't return any value othervise
		return undefined;
	};

	getVerifiedAttendanceValue = (attendeeId, attendee) => {
		const { formData } = this.props;

		let value;
		if (formData?.hasIn(["attendees", attendeeId, "verifiedAttendance"])) {
			value = formData.getIn(["attendees", attendeeId, "verifiedAttendance"]);
			// } else if (proxy) {
			// 	value = proxy.get('verifiedAttendance')
		} else {
			value = attendee.get("verifiedAttendance");
		}

		return value;
	};

	getAttendanceTypeValue = (attendee) => {
		const { formData } = this.props;
		const attendeeId = attendee.get("userId");

		let value;
		if (formData?.hasIn(["attendees", attendeeId, "attendanceType"])) {
			value = formData?.getIn(["attendees", attendeeId, "attendanceType"]);
			// } else if (proxy) {
			// 	value = proxy.get('attendanceType')
		} else {
			value = attendee.get("attendanceType");
		}

		return value;
	};

	renderInformation = (attendee) => {
		const {
			attendees,
			proxies,
			secretary,
			chairman,
			renderRoleDisplay,
			isAGM,
		} = this.props;
		const attendeeId = attendee.get("userId");
		const isAssistant = attendee.get("isAssistant");
		const proxy = findProxyByAttendeeId(proxies, attendeeId);
		const isCompanyInvestor = attendee.get("isCompanyInvestor");

		if (isCompanyInvestor && isAGM && !proxy) {
			return (
				<StyledProxyWrapper>
					<Icon
						icon="faExclamationTriangle"
						size="sml"
						color="yellowMid"
						type="solid"
					/>
					<StyledTextWrapper>
						<Text
							tid="meetings.agm.attendee.rollcall.is_company_investor.info.no_proxy"
							singleLine
						/>
					</StyledTextWrapper>
				</StyledProxyWrapper>
			);
		}

		if (proxy) {
			const proxyName = proxy.get("name");
			const { badgeColor, badgeVisible, TOOLTIP_STATES, activeState } =
				getProxyDocumentsDisplayData({
					proxy,
					userId: attendeeId,
					isAGM,
				});

			return (
				<StyledProxyWrapper>
					<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
						<NotificationBadge active={badgeVisible} color={badgeColor}>
							<Icon icon="faPaperclip" size="sml" />
						</NotificationBadge>
					</Tooltip>
					<Text bold={600} tid="meetings.generic.agm.proxy" singleLine />
					<Text singleLine>{proxyName}</Text>
				</StyledProxyWrapper>
			);
		}

		if (isAssistant) {
			// todo solve in another way that means not to add all attendees as a prop to this comp.
			const assistantObject = attendees.get(
				attendee.get("assistantForAttendee"),
			);
			const assistantName = assistantObject.get("name");

			return (
				<StyledGeneralInfoWrapper>
					<Text
						tid="meetings.agm.attendee.rollcall.info.assistant"
						bold={600}
					/>
					<Text>{assistantName}</Text>
				</StyledGeneralInfoWrapper>
			);
		}

		const roles = attendee.get("roles");
		const hasMeetingRole = getHasMeetingRole({
			attendeeId,
			roles,
			secretary,
			chairman,
		});

		if (hasMeetingRole) {
			return (
				<StyledGeneralInfoWrapper>
					<Text tid="meetings.attendee.rollcall.function" bold={600} />
					<Text>{renderRoleDisplay({ attendeeId, roles })}</Text>
				</StyledGeneralInfoWrapper>
			);
		}
	};

	renderAttendeeItem = (attendee, index) => {
		const { hideCheckin, hideMoreActions, hideVotingInfo, proxies, isAGM } =
			this.props;
		const attendeeId = attendee.get("userId");
		const name = attendee.get("name");
		const ssn = attendee.get("ssn");
		const responseStatus = attendee.get("responseStatus");
		const isInvestor = attendee.get(
			"isInvestor",
			attendee.get("isAlsoInvestor"),
		);
		const proxy = findProxyByAttendeeId(proxies, attendeeId);
		const verifiedAttendance = this.getVerifiedAttendanceValue(
			attendeeId,
			attendee,
			proxy,
		);
		const isCompanyInvestor = attendee.get("isCompanyInvestor");

		if (attendee.get("isProxy")) {
			return null;
		}

		return (
			<StyledListItem key={index} hoverColor="lightestGrey" striped>
				{!hideVotingInfo && <ShareholderIcon isInvestor={isInvestor} />}

				<Name>
					<Text bold={600} singleLine>
						{name ? name : "-"}
					</Text>
					{ssn && (
						<Text singleLine fontSize="12px" hasItalic>
							{formatIdPretty(ssn)}
						</Text>
					)}
				</Name>

				<Info>{this.renderInformation(attendee)}</Info>

				<InviteResponse responseStatus={responseStatus} />

				<AttendingMethod>
					<Select
						fieldName="attendanceType"
						options={ATTENDEES_ATTENDANCE_TYPE}
						onChange={this.onChange.bind(this, attendeeId)}
						value={this.getAttendanceTypeValue(attendee, proxy)}
						placeholderTid="generic.select"
						isSearchable={false}
						labelIsTid={true}
						isClearable={true}
						menuPortalTarget={document.body}
						isDisabled={isCompanyInvestor && isAGM && !proxy}
					/>
				</AttendingMethod>

				<Attendance>
					<Select
						fieldName="status"
						placeholderTid="meetings.attendees.details.placeholder.status"
						options={ATTENDEES_STATUS_OPTIONS}
						onChange={this.onChange.bind(this, attendeeId)}
						value={this.getAttendanceValue(attendee, proxy)}
						isClearable={this.getIsAttendanceClearable(attendee)}
						isSearchable={false}
						labelIsTid={true}
						menuPortalTarget={document.body}
						isDisabled={isCompanyInvestor && isAGM && !proxy}
					/>
				</Attendance>

				{!hideCheckin && <Checkin verifiedAttendance={verifiedAttendance} />}
				{!hideMoreActions && <div style={{ width: "50px" }} />}
			</StyledListItem>
		);
	};

	renderHeader = () => {
		const { hideInfo, hideCheckin, hideMoreActions, hideVotingInfo } =
			this.props;

		return (
			<StyledHeader>
				{!hideVotingInfo && <ShareholderIcon />}

				<Name>
					<HeaderText
						tid="generic.name"
						tooltipTid="meetings.standard.agm.rollcall.tooltip.name"
					/>
				</Name>

				{!hideInfo && <Info />}

				<InviteResponse>
					<HeaderText tid="meetings.standard.agm.rollcall.modal.head.invite_response" />
				</InviteResponse>

				<AttendingMethod>
					<HeaderText
						tid="meetings.standard.agm.rollcall.modal.head.attending_method"
						tooltipTid="meetings.standard.agm.rollcall.tooltip.attendance_method"
					/>
				</AttendingMethod>

				<Attendance>
					<HeaderText
						tid="meetings.standard.agm.rollcall.modal.head.attendance"
						tooltipTid="meetings.standard.agm.rollcall.tooltip.attendance"
					/>
				</Attendance>

				{!hideCheckin && (
					<Checkin>
						<HeaderText
							tid="meetings.standard.agm.rollcall.modal.head.checkin"
							tooltipTid="meetings.standard.agm.rollcall.tooltip.checkin"
						/>
					</Checkin>
				)}

				{!hideMoreActions && <Dropdown />}
			</StyledHeader>
		);
	};

	render = () => {
		let { filterComponent, loadAttendees, hasMoreAttendees, attendees } =
			this.props;
		const noAttendees = !attendees.size;

		return (
			<StyledWrapper>
				<StyledFilterWrapper>{filterComponent}</StyledFilterWrapper>
				<StyledList header={this.renderHeader}>
					<ScrollView
						noLeftMargin
						alwaysShow
						renderView={this.renderScrollableElement}
					>
						<StyledInfiniteScroll
							dataLength={attendees.size}
							next={loadAttendees}
							hasMore={hasMoreAttendees}
							scrollableTarget={this.scrollableTarget}
						>
							{attendees.map(this.renderAttendeeItem)}
						</StyledInfiniteScroll>
						{noAttendees && (
							<StyledNoAttendeesWrapper>
								<Text tid="meetings.rollcall.modal.no_attendees_that_match_filter" />
							</StyledNoAttendeesWrapper>
						)}
					</ScrollView>
				</StyledList>
			</StyledWrapper>
		);
	};
}
