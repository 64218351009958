import React from "react";
import useSubscription from "@/hooks/useSubscription";
import LoadingPanel from "../loadingPanel";
import moment from "@/modules/moment.module";
import { useTranslation } from "react-i18next";
import useDocumentsHelper from "@/hooks/useDocumentsHelper";
import useSubscriptionPlans from "@/hooks/useSubscriptionPlans";
import { Button } from "../ui/button";
import useUndoScheduledSubscriptionPlan from "@/hooks/useUndoScheduledSubscriptionPlan";
import useCompanyIdFromUrl from "@/hooks/useCompanyIdFromUrl";
import { Info, RefreshCwOff } from "lucide-react";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";

import { cn } from "../utils";

const SubscriptionBillingSummary = () => {
	const { t } = useTranslation();

	const companyId = useCompanyIdFromUrl();

	const { data: subscriptionQuery } = useSubscription();
	const subscription = subscriptionQuery?.data;

	const { data: subscriptionPlansRequest } = useSubscriptionPlans();
	const subscriptionPlans = subscriptionPlansRequest?.data;

	const { data: documentsHelperRequest } = useDocumentsHelper();
	const documentsHelper = documentsHelperRequest?.data;

	const {
		mutate: undoScheduledSubscriptionPlan,
		isPending: isUndoingScheduledSubscriptionPlan,
	} = useUndoScheduledSubscriptionPlan(companyId);

	if (!subscription || !documentsHelperRequest || !subscriptionPlansRequest) {
		return <LoadingPanel />;
	}

	const scheduledAccountPlan = [
		...(subscriptionPlans.account?.month ?? []),
		...(subscriptionPlans.account?.year ?? []),
	].find(({ id }) => id === subscription.willChangeToAccountPlan);

	const currentPeriodEndTimestamp =
		subscription?.stripeData?.subscriptions?.data[0]?.current_period_end;

	const currentPeriodEnd =
		currentPeriodEndTimestamp &&
		moment.unix(currentPeriodEndTimestamp).format("LL");

	const coupon = subscription?.stripeData?.discount?.coupon;

	const basePrice = subscription.accountPlan.amount / 100;
	let discountedPrice = basePrice;

	if (typeof coupon?.amount_off === "number")
		discountedPrice -= coupon.amount_off / 100;
	else if (typeof coupon?.percent_off === "number")
		discountedPrice -= (coupon.percent_off / 100) * basePrice;

	const discountedDifference = basePrice - discountedPrice;

	const isMonthly = subscription.accountPlan.interval === "month";
	const isPlanChangeScheduled = !!subscription?.willChangeToAccountPlan;

	return (
		<div>
			<div
				className={cn(
					"flex flex-col gap-4 p-4 bg-contrast text-contrast-foreground rounded-lg",
					{ "rounded-b-none": !!isPlanChangeScheduled },
				)}
			>
				<div className="grid grid-cols-2 even:[&>div]:text-right">
					<div>
						{t(
							`subscriptions.plans.company-account.${subscription.accountPlan.metadata.plan}`,
						)}
						{" - "}
						{isMonthly
							? t("subscriptions.plans.monthly")
							: t("subscriptions.plans.yearly")}
					</div>
					<div>{localeFormatNumber(basePrice, "currency")}</div>
					{discountedPrice !== basePrice && (
						<>
							<div>
								{t("subscriptions.subscribed_product.total.discount")}{" "}
								{typeof coupon?.amount_off === "number"
									? `(-${localeFormatNumber(
											coupon.amount_off / 100,
											"currency",
									  )})`
									: `(-${coupon.percent_off}%)`}
							</div>
							<div>-{localeFormatNumber(discountedDifference, "currency")}</div>
						</>
					)}
					<div className="font-bold text-lg pt-2">
						{isMonthly
							? t("subscriptions.subscribed_product.total.month")
							: t("subscriptions.subscribed_product.total.year")}
					</div>
					<div className="font-bold text-lg pt-2">
						{localeFormatNumber(discountedPrice, "currency")}
					</div>
				</div>
				<div className="grid grid-cols-2 even:[&>div]:text-right">
					{currentPeriodEnd && (
						<>
							<div>{t("subscriptions.next_billing_cycle")}</div>
							<div>{currentPeriodEnd}</div>
						</>
					)}
					<div>{t("subscriptions.disk_space.title")}</div>
					<div>
						{documentsHelper.usedStorageSpace.toFixed(3)} GB
						{" / "}
						{subscription.accountPlan.metadata.storageString}
					</div>
				</div>
			</div>
			{isPlanChangeScheduled && (
				<div className="flex gap-4 justify-between border border-edge border-t-0 rounded-lg rounded-t-none py-6 px-4">
					<div>
						<Info size={30} />
					</div>
					<div className="flex-1">
						{t("subscriptions.plan_change_scheduled.title")}:
						<div className="font-bold text-lg">
							{t(
								`subscriptions.plans.company-account.${scheduledAccountPlan.metadata.plan}`,
							)}
							{scheduledAccountPlan.metadata.plan !== "free" &&
								(scheduledAccountPlan.interval === "month"
									? ` - ${t("subscriptions.plans.monthly")}`
									: ` - ${t("subscriptions.plans.yearly")}`)}
						</div>
					</div>
					<div>
						<Button
							isLoading={isUndoingScheduledSubscriptionPlan}
							onClick={undoScheduledSubscriptionPlan}
							variant="secondary"
						>
							<RefreshCwOff /> {t("subscription.reset_plan")}
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

export default SubscriptionBillingSummary;
