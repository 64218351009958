import React, { Component } from "react";
import { connect } from "react-redux";
import { fromJS, List } from "immutable";

import GenericInfoPanel from "../../../dumb-components/company/comapny-dashboard/generic-info-panel/generic-info-panel";
import GenericInfoListItem from "../../../dumb-components/company/comapny-dashboard/generic-info-panel/generic-info-list-item";
import RemindUsersModalContainer from "../../notify-by-templates/modals/users/remind-users-modal.container";
import { fetchCompanyUsersCount } from "../../../actions/company.actions";
import { openModal } from "../../../actions/modals.actions";
import { INVITE_USERS_BY_EMAIL_TEMPLATE_MODAL } from "../../../constants/modals";
import { getHasAccessToSection } from "../../../components/helpers/resources.helper";

import { RESOURCE_USER_MANAGEMENT } from "/shared/constants";
import { Users } from "lucide-react";

class TeamMembersPanelContainer extends Component {
	state = {
		isLoading: true,
		data: List(),
	};

	componentDidMount = () => {
		this.countCompanyMembers();
	};

	componentDidUpdate = (prevProps) => {
		const { companyId } = this.props;

		if (prevProps.companyId !== companyId) {
			this.setState({ isLoading: true });
			this.countCompanyMembers();
		}
	};

	countCompanyMembers = () => {
		this.props.fetchCompanyUsersCount((data) => {
			const totalNumOfUsers = data.get("totalNumOfUsers").toString();
			const unregisteredUsers = data.get("unregisteredUsers").toString();
			const registeredUsers = data.get("registeredUsers").toString();

			this.setState({
				data: fromJS({ totalNumOfUsers, unregisteredUsers, registeredUsers }),
				isLoading: false,
			});
		});
	};

	getHasAccessToUserManagement = () => {
		const { menuCompanyResources } = this.props;
		return getHasAccessToSection(
			menuCompanyResources,
			RESOURCE_USER_MANAGEMENT,
		);
	};

	remindUnregisteredUsers = () => {
		this.props.openModal(INVITE_USERS_BY_EMAIL_TEMPLATE_MODAL);
	};

	render = () => {
		const { isLoading, data } = this.state;
		const unregisteredUsers = data.get("unregisteredUsers");
		const hasUnregisteredUsers = parseInt(unregisteredUsers) > 0;
		const hasAccessToUserManagement = this.getHasAccessToUserManagement();
		const showRemindLink = hasAccessToUserManagement && hasUnregisteredUsers;

		return (
			<>
				<GenericInfoPanel
					icon={<Users />}
					title="company.dashboard.team_members_panel.title"
					isLoading={isLoading}
				>
					<GenericInfoListItem
						label="company.dashboard.team_members.panel.total_num_company_members"
						value={data.get("totalNumOfUsers")}
					/>
					<GenericInfoListItem
						label="company.dashboard.team_members.panel.num_of_reg_members"
						value={data.get("registeredUsers")}
					/>
					<GenericInfoListItem
						label="company.dashboard.team_members.panel.num_of_unreg_members"
						linkText="company.dashboard.team_members.panel.num_of_unreg_members.link_text"
						onLinkClick={this.remindUnregisteredUsers}
						showLink={showRemindLink}
						value={unregisteredUsers}
					/>
				</GenericInfoPanel>
				<RemindUsersModalContainer />
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		companyId: store.company.company.id,
		menuCompanyResources: store.menu.companyResources,
	};
};

const mapActionsToProps = {
	fetchCompanyUsersCount,
	openModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(TeamMembersPanelContainer);
