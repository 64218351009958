import React, { PureComponent } from "react";
import { func, array, bool } from "prop-types";
import styled from "styled-components";
import ListItem from "../../shared/list-item/list-item";
import Text from "../../shared/text/text";
import ImmutableProps from "react-immutable-proptypes";
import { Select } from "../../shared/select";
import Panel from "../../shared/panel/panel";
import ToggleSwitch from "../../shared/toggle-switch/toggle-switch";
import Icon from "../../shared/icon/icon";
import { Padding } from "styled-components-spacing";
import { List } from "immutable";
import Tooltip from "../../shared/tooltip/tooltip";
import {
	RESOURCE_DOCUMENTS,
	RESOURCE_TASKS,
	RESOURCE_MEETINGS,
	RESOURCE_SMART_MEETINGS,
	RESOURCE_STANDARD_MEETINGS,
	DEFAULT_TEAM_USER,
} from "/shared/constants";

const resourcesDisabledForTeamUser = [
	RESOURCE_DOCUMENTS,
	RESOURCE_TASKS,
	RESOURCE_MEETINGS,
	RESOURCE_SMART_MEETINGS,
	RESOURCE_STANDARD_MEETINGS,
];

const StyledSectionWrapper = styled.div`
	margin: ${(props) => props.theme.spacing[4]};

	:last-child {
		padding-bottom: 32px;
	}
`;

const StyledHeader = styled.div`
	border-bottom: 1px solid ${(props) => props.theme.colors.placeholder};
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	height: 48px;
`;

const StyledListName = styled.div`
	width: 200px;
	padding-left: ${(props) =>
		props.resourceLevel === 2
			? props.theme.spacing[5]
			: props.resourceLevel === 3
			? "48px"
			: props.theme.spacing[4]};
`;

const StyledListDescription = styled.div`
	margin-left: ${(props) => props.theme.spacing[4]};
	margin-right: ${(props) => props.theme.spacing[4]};
	flex: 1;
`;

const StyledListPermissions = styled.div`
	width: ${(props) => (props.noContent ? "0px" : "340px")};
`;

const StyledListAccess = styled.div`
	width: ${(props) => (props.noContent ? "0px" : "80px")};
	text-align: center;
	display: flex;
	justify-content: center;
`;

const StyledListItem = styled(ListItem)`
	border-bottom: 1px solid ${(props) => props.theme.colors.placeholder};
	background-color: ${(props) =>
		props.resourceLevel > 1 && props.theme.colors.solitudeLight};
	align-items: center;
	margin-bottom: 0px;
	height: 48px;

	:hover {
		cursor: ${(props) => (props.noContent ? "pointer" : "default")};
	}
`;

const StyledNameIconWrapper = styled.div`
	> svg {
		margin-right: ${(props) => props.theme.spacing[2]};
	}
`;

const StyledBoxOnClickFix = styled.div`
	align-items: center;
	display: flex;
	flex: 1;
	height: 100%;
`;

class UserManagementAccess extends PureComponent {
	static propTypes = {
		onChange: func,
		onChangePermission: func,
		roles: array,
		bundles: ImmutableProps.map,
		selectedRole: ImmutableProps.map,
		rowStates: ImmutableProps.map,
		resources: ImmutableProps.list,
		permissions: ImmutableProps.map,
		disableSwitches: bool,
	};

	static defaultProps = {
		disableSwitches: false,
	};

	onClickRow = (resource) => {
		const { onChange } = this.props;
		onChange && onChange("onItemToggle", resource);
	};

	onClickStopPropagation = (e) => {
		e.stopPropagation();
	};

	renderTableRow = (disableSwitches, resource, index) => {
		const { rowStates, onChange, bundles, permissions, selectedRole } =
			this.props;
		const children = resource.get("children");
		const nameTid = resource.get("translationId");
		const descriptionTid = resource.get("permissionDescTranslationId", "");
		const resourceLevel = resource.get("level");
		let parentId = resource.get("parent");
		const resourceId = resource.get("id");
		const resourceBundle = bundles.get(resourceId, List());
		const noContent = resource.get("noContent");
		const switchIsOn = permissions.get(resourceId);
		const data = [];
		let isOpen = true;
		let hasParent = true;

		const hideToggleSwitch =
			selectedRole?.get("id") === DEFAULT_TEAM_USER &&
			resourcesDisabledForTeamUser.includes(resourceId);

		if (children) {
			if (rowStates.get(resourceId) === undefined) {
				onChange("updateRowStates", resource);
			}
		}

		while (hasParent) {
			const rowState = rowStates.get(parentId);

			if (rowState !== undefined) {
				if (rowState.get("open") === false) {
					isOpen = false;
				}

				if (rowState.get("parentId") !== undefined) {
					parentId = rowState.get("parentId");
				} else {
					hasParent = false;
				}
			} else {
				break;
			}
		}

		if (isOpen) {
			const rowState = rowStates.get(resource.get("id"));

			data.push(
				<StyledListItem
					resourceLevel={resourceLevel}
					key={index}
					noContent={noContent}
				>
					<StyledBoxOnClickFix
						onClick={children && this.onClickRow.bind(null, resource)}
					>
						<StyledListName resourceLevel={resourceLevel}>
							{noContent || children ? (
								<StyledNameIconWrapper>
									{rowState && rowState.get("open") ? (
										<Icon
											color="java"
											icon="faChevronDown"
											type="solid"
											size="xs"
										/>
									) : (
										<Icon
											color="java"
											icon="faChevronRight"
											type="solid"
											size="xs"
										/>
									)}
									<Text
										tid={nameTid}
										color={children ? "java" : "midGrey"}
										bold={700}
									/>
								</StyledNameIconWrapper>
							) : resourceLevel === 1 ? (
								<Text tid={nameTid} color="java" bold={700} />
							) : (
								<Text tid={nameTid} />
							)}
						</StyledListName>
						<StyledListDescription>
							<Text tid={descriptionTid} />
						</StyledListDescription>
						{!noContent && switchIsOn && (
							<StyledListPermissions onClick={this.onClickStopPropagation}>
								{resourceBundle.size > 1 ? (
									<Select
										options={resourceBundle}
										onChange={this.props.onChangePermission.bind(
											this,
											resourceId,
										)}
										labelIsTid={true}
										isSearchable={false}
										value={switchIsOn}
										isDisabled={disableSwitches === true}
									/>
								) : (
									<Text tid={resourceBundle.first().get("label")} />
								)}
							</StyledListPermissions>
						)}
						<StyledListAccess noContent={noContent}>
							{!noContent && !hideToggleSwitch && (
								<ToggleSwitch
									onChange={onChange.bind(null, "accessChanged", resource)}
									checked={permissions.get(resourceId) ? true : false}
									disabled={disableSwitches === true}
								/>
							)}
							{hideToggleSwitch && (
								<Tooltip
									tid="user_management.toggle_switch_disabled_due_to_team_user"
									delayShow="instant"
								>
									<Icon
										icon="faExclamationTriangle"
										size="sml"
										type="solid"
										color="orange"
									/>
								</Tooltip>
							)}
						</StyledListAccess>
					</StyledBoxOnClickFix>
				</StyledListItem>,
			);
		}

		if (children) {
			data.push(
				resource
					.get("children")
					.map(this.renderTableRow.bind(null, disableSwitches)),
			);
		}

		return data;
	};

	renderSection = (disableSwitches, resource, index) => {
		if (resource.get("hideFromUserManagement")) {
			return null;
		}
		return (
			<StyledSectionWrapper key={index}>
				<Text tag="h4" color="darkGrey" tid={resource.get("translationId")} />
				<StyledHeader>
					<StyledListName>
						<Text tid="generic.name" bold={600} />
					</StyledListName>
					<StyledListDescription>
						<Text tid="generic.description" bold={600} />
					</StyledListDescription>
					<StyledListPermissions>
						<Text tid="permissions" bold={600} />
					</StyledListPermissions>
					<StyledListAccess>
						<Text tid="generic.access" bold={600} />
					</StyledListAccess>
				</StyledHeader>
				{resource
					.get("children")
					.map(this.renderTableRow.bind(null, disableSwitches))}
			</StyledSectionWrapper>
		);
	};

	render = () => {
		const { resources, selectedRole, disableSwitches } = this.props;

		return (
			<Panel mode="light" marginBottom>
				{selectedRole &&
					resources &&
					resources.map(this.renderSection.bind(null, disableSwitches))}
				{!selectedRole && (
					<Padding all={4}>
						<Text tid="company.management.select_role" />
					</Padding>
				)}
			</Panel>
		);
	};
}

export default UserManagementAccess;
