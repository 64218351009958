import React, { useState, useEffect } from "react";
import immutablePropTypes from "react-immutable-proptypes";
import { bool, func, number, string } from "prop-types";
import { List } from "immutable";
import AttendeeItemContainer from "../../attendees/attendee-item.container";
import AttendeesList from "../../../../dumb-components/meetings/attendees-list/attendees-list";
import DropdownMenuContainer from "../../../shared/dropdown-menu.container";
import { ButtonTransparentIcon } from "../../../../dumb-components/shared/button";
import DropdownIconItem from "../../../../dumb-components/shared/dropdown-item/dropdown-icon-item";

const AttendeesListContainer = ({
	attendees,
	onRemoveAttendee,
	permissions,
	scrollableTarget,
}) => {
	const [attendeesList, setAttendeesList] = useState(List());

	useEffect(() => {
		if (attendees) {
			setAttendeesList(attendees.toList());
		}
	}, [attendees, setAttendeesList]);

	const renderMoreActionButton = (attendeeId) => {
		return (
			<DropdownMenuContainer
				halignMenu="right"
				noMaxWidth={true}
				buttonNoHorizontalPadding={true}
				inline={true}
				renderRaw={
					<ButtonTransparentIcon icon="faEllipsisV" size="sml" x={1} />
				}
			>
				<DropdownIconItem
					icon="faTrashAlt"
					tid="meetings.attendees.list.remove_attendee"
					onClick={() => onRemoveAttendee(attendeeId)}
				/>
			</DropdownMenuContainer>
		);
	};

	const renderAttendeeItem = (attendeeProps) => {
		return (
			<AttendeeItemContainer
				{...attendeeProps}
				canSetStatus={false}
				attendee={attendeesList.get(attendeeProps.index)}
				renderMoreActionButton={renderMoreActionButton}
				context="attendees in next meeting"
				isStandardMeeting={false}
				isStatic={true}
			/>
		);
	};

	return (
		<AttendeesList
			attendees={attendeesList}
			permissions={permissions}
			renderItemComponent={renderAttendeeItem}
			// attendeesRenderLimit={attendeesRenderLimit}
			// 	onToggleShowAllAttendees={this.toggleShowAllAttendees}
			// 	useReactWindow={useReactWindow}
			// 	reactWindowHeight={reactWindowHeight}
			// onSetListRef={onSetListRef}
			scrollableTarget={scrollableTarget}
		/>
	);
};

AttendeesListContainer.propTypes = {
	attendees: immutablePropTypes.map,
	onRemoveAttendee: func,
	permissions: immutablePropTypes.map,
	onSetListRef: func,
	useReactWindow: bool,
	reactWindowHeight: number,
	scrollableTarget: string,
};

export default AttendeesListContainer;
