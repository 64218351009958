import queryString from "query-string";

/**
 * Query Object
 * {key1: value1, key2: value2}
 */
export const generateQuery = (queryObject) => {
	let query = queryString.stringify(queryObject, {
		skipNull: true,
		skipEmptyString: true,
	});
	query = query ? `?${query}` : "";
	return query;
};
