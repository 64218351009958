import i18n from "i18next";

const httpError = {
	create: (errors) => {
		const message = Array.isArray(errors)
			? errors.map((m) => i18n.t(m)).join(",\n")
			: errors;
		return new Error(message);
	},
};

export default httpError;
