import React from "react";
import { func, bool } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import MUIDialog from "../../../mui-components/dialog/mui-dialog";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const EndMeetingModal = ({
	onEndMeetingGoToProtocol,
	onEndMeetingGoToMeetingList,
	onCancel,
	agendaItems,
	goToProtocolBtnIsLoading,
	goToMeetingsListBtnIsLoading,
}) => {
	const { t } = useTranslation();

	const isButtonsDisabled = () => {
		return (
			goToProtocolBtnIsLoading === true || goToMeetingsListBtnIsLoading === true
		);
	};

	return (
		<MUIDialog
			isOpen={true}
			onClose={onCancel}
			title={t("meetings.end_meeting_dialog.title")}
			confirmButton={false}
			maxWidth={"sm"}
			closeButton
			disabledClose={isButtonsDisabled()}
			height={"60%"}
		>
			{!agendaItems || agendaItems.size === 0 ? null : (
				<Card variant={"naked"}>
					<CardContent variant={"flex"}>
						<Box>
							<Typography variant={"body2"}>
								{t("meetings.end_meeting_modal.modal.status_info", {
									num: agendaItems.size,
								})}
							</Typography>
						</Box>
					</CardContent>
				</Card>
			)}
			<Card variant={"yellow"}>
				<CardContent variant={"flex"}>
					<Box>
						<Typography variant={"body2"}>
							{t(
								"meetings.end_meeting_dialog.yellow_panel.body.open_protocol_edit",
							)}
						</Typography>
						<Box sx={{ pt: 2 }}>
							<Button
								variant={"primary"}
								onClick={onEndMeetingGoToProtocol}
								disabled={isButtonsDisabled()}
								isLoading={goToProtocolBtnIsLoading}
							>
								{t(
									"meetings.end_meeting_dialog.yellow_panel.btn.open_protocol_edit",
								)}
							</Button>
						</Box>
					</Box>
				</CardContent>
			</Card>
			<Box sx={{ pt: 2 }}>
				<Card variant={"yellow"}>
					<CardContent variant={"flex"}>
						<Box>
							<Typography variant={"body2"}>
								{t(
									"meetings.end_meeting_dialog.yellow_panel.body.close_meeting",
								)}
							</Typography>
							<Box sx={{ pt: 2 }}>
								<Button
									variant={"primary"}
									onClick={onEndMeetingGoToMeetingList}
									disabled={isButtonsDisabled()}
									isLoading={goToMeetingsListBtnIsLoading}
								>
									{t(
										"meetings.end_meeting_dialog.yellow_panel.btn.close_meeting",
									)}
								</Button>
							</Box>
						</Box>
					</CardContent>
				</Card>
			</Box>
		</MUIDialog>
	);
};

MUIDialog.propTypes = {
	onEndMeetingGoToProtocol: func,
	onEndMeetingGoToMeetingList: func,
	onCancel: func,
	agendaItems: immutablePropTypes.list,
	goToProtocolBtnIsLoading: bool,
	goToMeetingsListBtnIsLoading: bool,
};

export default EndMeetingModal;
