import { fromJS, List, Map } from "immutable";
import {
	MEETINGS_LIST,
	MEETINGS_SET_VISIBLE,
	MEETINGS_UPDATE_LOCAL,
	MEETINGS_CREATE,
	MEETINGS_CREATE_LOCAL,
	MEETINGS_FETCH,
	MEETINGS_SAVE,
	MEETINGS_DELETE,
	MEETINGS_UPDATE_PROJECT,
	MEETINGS_LIST_BY_COMPANIES,
	MEETINGS_SET_FILTERS,
	MEETINGS_UNSET_FILTERS,
	MEETINGS_SELECT,
	MEETINGS_FETCH_MEETING_TYPES,
	MEETINGS_AGENDA_ITEM_FETCH,
	MEETINGS_AGENDA_ITEM_UPDATE,
	MEETINGS_LIST_PREVIOUS_MEETINGS,
	MEETINGS_FETCH_PREVIOUS_MEETING,
	MEETINGS_FETCH_SIGNATORY,
	MEETINGS_SET_PROTOCOL_FILTERS,
	MEETINGS_RESET_PROTOCOL_FILTERS,
	MEETINGS_SET_DEFAULT_FILTERS,
	MEETINGS_UPDATE_DEFAULT_FILTER,
	MEETINGS_RESET_DEFAULT_VALUES,
	MEETINGS_HARD_DELETE,
	COMPANY_RESET_REDUCER,
	CUSTOM_MEETING_FUNCTIONS_LIST,
	CUSTOM_MEETING_FUNCTIONS_SAVE,
	MEETINGS_UPDATE_PUBLISHING_TYPE,
	MEETINGS_ATTENDEES_HAS_BEEN_CHANGED,
	ATTENDEES_FILTER,
	ATTENDEES_RESET_FILTER,
} from "../actions/types";

import { FACTORY_DEFAULT_FILTER_VALUES } from "../constants/meetings";
import meetingHelpers from "/shared/meetings/helpers";

const INITIAL_STATE = fromJS({
	allMeetings: null,
	listByCompanies: null,
	meeting: null,
	agendaItem: null,
	meetingsIdMapToIndex: {},
	visibleMeetings: null,
	meetingsExcludedFromFiltering: [],
	hasAppliedFilters: false,
	filterBy: [
		{
			source: "dateProp",
			values: FACTORY_DEFAULT_FILTER_VALUES.get("dateProp"),
		},
		{ source: "order", values: FACTORY_DEFAULT_FILTER_VALUES.get("order") },
		{
			source: "meetingStatus",
			values: FACTORY_DEFAULT_FILTER_VALUES.get("meetingStatus"),
		},
		{
			source: "includeArchived",
			values: FACTORY_DEFAULT_FILTER_VALUES.get("includeArchived"),
		},
		{ source: "tag", values: [], isSimpleFilter: true },
		{
			source: "attendees",
			values: FACTORY_DEFAULT_FILTER_VALUES.get("attendees"),
		},
		{
			source: "showOnlyTrashed",
			values: FACTORY_DEFAULT_FILTER_VALUES.get("showOnlyTrashed"),
		},
	],
	defaultFilters: FACTORY_DEFAULT_FILTER_VALUES,
	isDirty: Map(),
	meetingTypes: null,
	previousMeetings: [],
	previousMeeting: null,
	signatory: null,
	protocolFilters: Map(),
	customFunctions: Map(),
	allAttendees: Map(),
	attendeesMapIndexToId: Map(),
	attendeesHasBeenChanged: Map(),
});

const createMeetingsIdMapToIndex = (meetings) => {
	let map = Map();
	meetings &&
		meetings.forEach((d, i) => {
			map = map.set(d.get("id"), i);
		});
	return map;
};

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case MEETINGS_LIST: {
			state = state.set("allMeetings", payload);
			state = state.set(
				"meetingsIdMapToIndex",
				createMeetingsIdMapToIndex(payload),
			);
			return state;
		}

		case MEETINGS_SET_VISIBLE: {
			state = state.set("hasAppliedFilters", payload.hasAppliedFilters);
			return state.set("visibleMeetings", payload.meetings);
		}

		case MEETINGS_UPDATE_LOCAL: {
			if (payload) {
				let meeting = payload;
				const index = state.getIn(["meetingsIdMapToIndex", meeting.get("id")]);
				if (index >= 0) {
					state = state.setIn(["allMeetings", index], meeting);
				}

				let currentAgendaItem = state.get("agendaItem");
				if (currentAgendaItem) {
					meeting.get("agendaItems")?.forEach((item) => {
						if (item.get("id") === currentAgendaItem.get("id")) {
							currentAgendaItem = item;
						} else if (item.has("agendaItems")) {
							item.get("agendaItems")?.forEach((subitem) => {
								if (subitem.get("id") === currentAgendaItem.get("id")) {
									currentAgendaItem = subitem;
								}
							});
						}
					});

					state = state.set("agendaItem", currentAgendaItem);
				}

				const { attendeesMap, attendeesMapIndexToId } =
					sortAttendeesByRoleAndName(
						meeting.get("attendees"),
						meeting.get("chairman"),
						meeting.get("secretary"),
						meeting.get("moderators"),
					);
				state = state.setIn(
					["attendeesMapIndexToId", meeting.get("id")],
					attendeesMapIndexToId,
				);
				meeting = meeting.set("attendees", attendeesMap);

				return state.set("meeting", meeting);
			}
			return state;
		}

		case MEETINGS_SELECT: {
			const index = state.getIn(["meetingsIdMapToIndex", payload]);
			let meeting = state.getIn(["allMeetings", index]);
			const { attendeesMap, attendeesMapIndexToId } =
				sortAttendeesByRoleAndName(
					meeting.get("attendees"),
					meeting.get("chairman"),
					meeting.get("secretary"),
					meeting.get("moderators"),
				);
			state = state.setIn(
				["attendeesMapIndexToId", meeting.get("id")],
				attendeesMapIndexToId,
			);
			meeting = meeting.set("attendees", attendeesMap);
			return state.set("meeting", meeting);
		}

		case MEETINGS_CREATE: {
			const index = state.getIn(["meetingsIdMapToIndex", payload.get("id")]);
			state = state.setIn(["allMeetings", index], payload);
			return state.set("meeting", payload);
		}

		case MEETINGS_CREATE_LOCAL: {
			const meeting = payload.get("meeting");
			let allMeetings = state.get("allMeetings");
			allMeetings = allMeetings.insert(0, meeting);
			state = state.set(
				"meetingsIdMapToIndex",
				createMeetingsIdMapToIndex(allMeetings),
			);
			state = state.update("meetingsExcludedFromFiltering", (meetings) =>
				meetings.push(meeting.get("id")),
			);
			const updatedState = state.set("allMeetings", allMeetings);
			return updatedState.set("meeting", meeting);
		}

		case MEETINGS_FETCH: {
			let meeting = payload;
			if (meeting && meeting.has("id")) {
				if (meeting.has("attendees")) {
					const { attendeesMap, attendeesMapIndexToId } =
						sortAttendeesByRoleAndName(
							meeting.get("attendees"),
							meeting.get("chairman"),
							meeting.get("secretary"),
							meeting.get("moderators"),
						);
					state = state.setIn(
						["attendeesMapIndexToId", meeting.get("id")],
						attendeesMapIndexToId,
					);
					meeting = meeting.set("attendees", attendeesMap);

					const allStackedAttendees = state.getIn([
						"allAttendees",
						meeting.get("id"),
					]);
					if (allStackedAttendees) {
						state = state.setIn(
							["allAttendees", meeting.get("id")],
							meeting.get("attendees"),
						);
					}
				}

				state = state.set("meeting", meeting);
			} else if (payload === null) {
				state = state.set("meeting", null);
			}
			return state;
		}

		case MEETINGS_SAVE: {
			let meeting = payload;
			if (state.getIn(["meeting", "id"]) === meeting.get("id")) {
				const { attendeesMap, attendeesMapIndexToId } =
					sortAttendeesByRoleAndName(
						meeting.get("attendees"),
						meeting.get("chairman"),
						meeting.get("secretary"),
						meeting.get("moderators"),
					);
				state = state.setIn(
					["attendeesMapIndexToId", meeting.get("id")],
					attendeesMapIndexToId,
				);
				meeting = meeting.set("attendees", attendeesMap);
				state = state.set("meeting", meeting);
			}

			const index = state.getIn(["meetingsIdMapToIndex", payload.get("id")]);
			if (index >= 0) {
				state = state.setIn(["allMeetings", index], payload);
			}
			return state;
		}

		case MEETINGS_DELETE: {
			const index = state.getIn(["meetingsIdMapToIndex", payload.id]); // Payload = id
			let allMeetings = state.get("allMeetings");

			if (allMeetings) {
				allMeetings = allMeetings.setIn([index, "isDeleted"], true);
				state = state.set("allMeetings", allMeetings);
				state = state.set(
					"meetingsIdMapToIndex",
					createMeetingsIdMapToIndex(allMeetings),
				);
			}

			state = state.update(
				"meetingsExcludedFromFiltering",
				(meetingsExcludedFromFiltering) =>
					meetingsExcludedFromFiltering.filter(
						(meetingId) => meetingId !== payload.id,
					),
			);

			state = state.removeIn(["attendeesMapIndexToId", payload.id]);
			return state.set("meeting", null);
		}

		case MEETINGS_HARD_DELETE: {
			const meetingsToDelete = payload.get("meetingsToDelete");
			const selectedMeetingWasDeleted = payload.get(
				"selectedMeetingWasDeleted",
			);

			state = state.update("allMeetings", (allMeetings) => {
				return allMeetings.filter(
					(meeting) => !meetingsToDelete.includes(meeting.get("id")),
				);
			});

			state = state.set(
				"meetingsIdMapToIndex",
				createMeetingsIdMapToIndex(state.get("allMeetings")),
			);

			state = state.update("attendeesMapIndexToId", (attendeesMapIndexToId) => {
				return attendeesMapIndexToId.filter(
					(meetingId) => !meetingsToDelete.includes(meetingId),
				);
			});

			if (selectedMeetingWasDeleted) {
				state = state.set("meeting", null);
			}

			return state;
		}

		case MEETINGS_UPDATE_PROJECT: {
			payload.get("meetingIds").forEach((meetingId) => {
				const index = state.getIn(["meetingsIdMapToIndex", meetingId]);
				state = state.setIn(
					["allMeetings", index, "projectId"],
					payload.get("projectId"),
				);
			});

			state = state.setIn(["meeting", "projectId"], payload.get("projectId"));
			return state;
		}

		case MEETINGS_LIST_BY_COMPANIES:
			return state.set("listByCompanies", payload);

		case MEETINGS_SET_FILTERS: {
			const source = payload.get("source");
			const values = payload.get("values");
			const isSimpleFilter = payload.get("isSimpleFilter");
			let filterBy = state.get("filterBy");
			let sourceRemoved = false;

			// Remove previous filter criteria when filitering in simple filter
			if (isSimpleFilter) {
				filterBy = filterBy.filter((obj) => !obj.get("isSimpleFilter"));
				// Remove filter criteria if value is null (null == value have been cleared in advanced filter)
			} else if (!values) {
				filterBy = filterBy.filter((obj) => obj.get("source") !== source);
				sourceRemoved = true;
			}

			const index = filterBy.findIndex((obj) => obj.get("source") === source);

			if (index >= 0) {
				filterBy = filterBy.set(index, payload);
			} else if (!sourceRemoved) {
				filterBy = filterBy.push(payload);
			}

			state = state.set("meetingsExcludedFromFiltering", List());
			return state.set("filterBy", filterBy);
		}

		case MEETINGS_UNSET_FILTERS: {
			const sources = payload;
			const defaultFilters = state.get("defaultFilters");
			let filterBy = state.get("filterBy");

			filterBy = filterBy.filter((filter) => {
				let keep = false;

				sources.forEach((source) => {
					if (source === filter.get("source") || filter.get("isSimpleFilter")) {
						if (defaultFilters.has(source)) {
							keep = true;
						}
					}
				});

				return keep;
			});

			filterBy = filterBy.map((filter) => {
				sources.forEach((source) => {
					if (source === filter.get("source")) {
						const defaultFilter = defaultFilters.get(source);
						if (defaultFilter) {
							filter = filter.set("values", defaultFilter);
						}
					}
				});

				return filter;
			});

			return state.set("filterBy", filterBy);
		}

		case MEETINGS_SET_DEFAULT_FILTERS: {
			const filters = payload;
			let defaultFilters = state.get("defaultFilters");

			filters.forEach((values, key) => {
				defaultFilters = defaultFilters.set(key, values);
			});

			return state.set("defaultFilters", defaultFilters);
		}

		case MEETINGS_UPDATE_DEFAULT_FILTER: {
			const source = payload.get("source");
			const values = payload.get("values");
			return state.setIn(["defaultFilters", source], values);
		}

		case MEETINGS_RESET_DEFAULT_VALUES: {
			let filterBy = state.get("filterBy");

			filterBy = filterBy.map((filter) => {
				const source = filter.get("source");

				if (FACTORY_DEFAULT_FILTER_VALUES.has(source)) {
					filter = filter.set(
						"values",
						FACTORY_DEFAULT_FILTER_VALUES.get(source),
					);
				}

				return filter;
			});

			state = state.set("filterBy", filterBy);
			return state.set("defaultFilters", FACTORY_DEFAULT_FILTER_VALUES);
		}

		case MEETINGS_FETCH_MEETING_TYPES:
			return state.set("meetingTypes", payload);

		case MEETINGS_AGENDA_ITEM_FETCH: {
			const agendaItemId = payload;
			const agendaItems = state.getIn(["meeting", "agendaItems"]);

			if (!agendaItems) {
				return state;
			}

			let agendaItem;
			agendaItems.forEach((item) => {
				if (item.get("id") === agendaItemId) {
					agendaItem = item;
				} else if (item.has("agendaItems")) {
					item.get("agendaItems", List()).forEach((subitem) => {
						if (subitem.get("id") === agendaItemId) {
							agendaItem = subitem;
						}
					});
				}
			});

			return state.set("agendaItem", agendaItem);
		}

		case MEETINGS_AGENDA_ITEM_UPDATE: {
			state = state.setIn(
				["meeting", "agendaItems"],
				payload.get("agendaItems"),
			);

			const selectedAgendaItem = state.get("agendaItem");

			if (
				selectedAgendaItem &&
				selectedAgendaItem.get("id") === payload.getIn(["agendaItem", "id"])
			) {
				return state.set("agendaItem", payload.get("agendaItem"));
			}

			return state;
		}

		case MEETINGS_LIST_PREVIOUS_MEETINGS:
			return state.set("previousMeetings", payload);

		case MEETINGS_FETCH_PREVIOUS_MEETING: {
			if (payload && payload instanceof Map && payload.has("id")) {
				state = state.set("previousMeeting", payload);
			} else {
				state = state.set("previousMeeting", null);
			}
			return state;
		}

		case MEETINGS_FETCH_SIGNATORY:
			return state.set("signatory", payload);

		case MEETINGS_SET_PROTOCOL_FILTERS: {
			const active = payload.get("active");
			const filter = payload.get("filter");

			if (!active) {
				state = state.removeIn(["protocolFilters", filter]);
			} else {
				state = state.setIn(["protocolFilters", filter], true);
			}

			return state;
		}

		case MEETINGS_RESET_PROTOCOL_FILTERS:
			return state.set("protocolFilters", Map());

		case CUSTOM_MEETING_FUNCTIONS_LIST: {
			payload.forEach((customFunction) => {
				state = state.setIn(
					["customFunctions", customFunction.get("id")],
					customFunction,
				);
			});
			return state;
		}

		case CUSTOM_MEETING_FUNCTIONS_SAVE: {
			return state.setIn(["customFunctions", payload.get("id")], payload);
		}

		case MEETINGS_UPDATE_PUBLISHING_TYPE: {
			return state.setIn(["meeting", "publishingType"], payload);
		}

		case MEETINGS_ATTENDEES_HAS_BEEN_CHANGED: {
			const { meetingId, changed } = payload;
			return state.setIn(["attendeesHasBeenChanged", meetingId], changed);
		}

		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE;
		}

		case ATTENDEES_FILTER: {
			const { meetingId, attendeesFilteredInClient } = payload;
			let { filteredAttendees } = payload;
			const currentMeetingId = state.getIn(["meeting", "id"]);
			const chairman = state.getIn(["meeting", "chairman"]);
			const secretary = state.getIn(["meeting", "secretary"]);
			const moderators = state.getIn(["meeting", "moderators"]);
			const allAttendees = state.getIn(["meeting", "attendees"]);

			if (meetingId !== currentMeetingId) {
				return state;
			}

			if (attendeesFilteredInClient) {
				filteredAttendees = filteredAttendees.concat(
					attendeesFilteredInClient.map((_, attendeeId) =>
						allAttendees.get(attendeeId),
					),
				);
			}

			const { attendeesMap, attendeesMapIndexToId } =
				sortAttendeesByRoleAndName(
					filteredAttendees,
					chairman,
					secretary,
					moderators,
				);

			const allPreviousStoredAttendees = state.getIn([
				"allAttendees",
				meetingId,
			]);

			if (!allPreviousStoredAttendees) {
				state = state.setIn(["allAttendees", meetingId], allAttendees);
			}

			state = state.setIn(
				["attendeesMapIndexToId", meetingId],
				attendeesMapIndexToId,
			);
			state = state.setIn(["meeting", "attendees"], attendeesMap);
			return state;
		}

		case ATTENDEES_RESET_FILTER: {
			const { meetingId } = payload;
			const meeting = state.get("meeting");

			if (meetingId !== meeting.get("id")) {
				return state;
			}

			const allAttendees = state.getIn(["allAttendees", meetingId]);

			if (!allAttendees) {
				return state;
			}

			const { attendeesMap, attendeesMapIndexToId } =
				sortAttendeesByRoleAndName(
					allAttendees,
					meeting.get("chairman"),
					meeting.get("secretary"),
					meeting.get("moderators"),
				);

			state = state.removeIn(["allAttendees", meetingId]);
			state = state.setIn(
				["attendeesMapIndexToId", meetingId],
				attendeesMapIndexToId,
			);
			state = state.setIn(["meeting", "attendees"], attendeesMap);
			return state;
		}

		default:
			return state;
	}
}

function sortAttendeesByRoleAndName(
	attendees,
	chairman,
	secretary,
	moderators,
) {
	if (!attendees) {
		return { attendeesMap: Map(), attendeesMapIndexToId: List() };
	}

	let attendeesList = attendees.toList();
	attendeesList = attendeesList.sort((a, b) => {
		const aName = a.get("name") || "a";
		const bName = b.get("name") || "b";

		return aName.localeCompare(bName);
	});
	attendeesList = attendeesList.sortBy((item) => {
		const userId = item.get("userId");
		if (userId === chairman) {
			return -5;
		} else if (userId === secretary) {
			return -4;
		} else if (
			meetingHelpers.isAttendeeModerator(
				userId,
				moderators && moderators.toJS(),
			)
		) {
			return -3;
		} else if (item.get("roles")) {
			return -2;
		}
		return 0;
	});

	let attendeesMap = Map();
	let attendeesMapIndexToId = List();
	attendeesList.forEach((attendee) => {
		const userId = attendee.get("userId");
		attendeesMap = attendeesMap.set(userId, attendee);
		attendeesMapIndexToId = attendeesMapIndexToId.push(userId);
	});

	return { attendeesMap, attendeesMapIndexToId };
}
