import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { registerPartialClosure } from "../../../../actions/emissions.actions";
import { useDispatch } from "react-redux";

const RegistrationPopUpModal = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const registerPartialClosureClick = async () => {
		await dispatch(
			registerPartialClosure(
				props.emission.companyId,
				props.emission.id,
				props.closure.id,
			),
		);
		props.close();
	};

	return (
		<Dialog
			maxWidth={"md"}
			fullWidth={true}
			open={props.open}
			onClose={props.close}
		>
			<DialogContent
				sx={{ px: 6, py: 4, minWidth: "60rem", minHeight: "30rem" }}
			>
				<IconButton
					aria-label="close"
					onClick={props.close}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[600],
					}}
				>
					<CloseIcon />
				</IconButton>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Typography variant={"h4"}>{props.header}</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "left",
						}}
					>
						<Typography variant={"body2"} sx={{ mx: "20px", mt: "24px" }}>
							{props.desc}
						</Typography>
						{props.emission.closures.length === 1 && (
							<Typography variant={"body2"} sx={{ mx: "20px", mt: "24px" }}>
								{props.desc2}
							</Typography>
						)}
					</Box>
					<Box sx={{ display: "flex", mt: "56px" }}>
						<Button
							onClick={props.close}
							variant={"text"}
							sx={{ color: "#757575", mr: "40px" }}
						>
							<Typography variant={"h5"}>
								{t("dialog.generic.cancel")}
							</Typography>
						</Button>
						<Button
							variant={"contained"}
							onClick={() => registerPartialClosureClick()}
						>
							<Typography variant={"h5"}>{props.button}</Typography>
						</Button>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default RegistrationPopUpModal;
