import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ProgressCircle } from "../admin/marketing/marketing";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { emissionConsts } from "../../../constants/emissions";
import { calculateStatistics } from "../utils/statistics";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import { NUMBER_FORMAT_CURRENCY } from "/shared/constants.json";

const EmissionDiagram = (
	props = {
		prefix: "",
		suffix: "",
	},
) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const {
		common: { newShareCountTypes },
	} = emissionConsts;
	const { newShareCountType } = props.emission;
	const currentEmission = props.currentEmission || props.emission;

	const {
		achievedAmount,
		percentageOfLowerSpanAsNumber,
		percentageOfHigherSpanAsNumber,
		percentageOfLowerSpanAsPercentage,
		percentageOfHigherSpanAsPercentage,
	} = calculateStatistics(props.statistics, currentEmission);

	const price = props.emission.pricePerShare;

	const minValue = localeFormatNumber(
		props.emission.newShareCountMin * price,
		NUMBER_FORMAT_CURRENCY,
	);
	const secondaryValue = localeFormatNumber(
		props.emission.newShareCountMax * price,
		NUMBER_FORMAT_CURRENCY,
	);

	return (
		<Box sx={{ ml: 5, my: 4, display: "flex", width: "100%", ...props.sx }}>
			<Box
				sx={{
					display: "flex",
					position: "relative",
					alignSelf: "start",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						position: "relative",
						display: "inline-flex",
						alignItems: "center",
						mr: "24px",
					}}
				>
					<ProgressCircle
						size={110}
						backColor={"custom.progress.orange.light"}
						frontColor={"custom.progress.orange.dark"}
						value={percentageOfHigherSpanAsNumber}
					/>
					<Box
						sx={{
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							position: "absolute",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{newShareCountType === newShareCountTypes.range && (
							<ProgressCircle
								value={percentageOfLowerSpanAsNumber}
								backColor={"custom.progress.purple.light"}
								frontColor={"custom.progress.purple.dark"}
								size={80}
							/>
						)}
						{newShareCountType !== newShareCountTypes.range && (
							<Typography variant="subtitle1">
								{percentageOfHigherSpanAsPercentage}
							</Typography>
						)}
					</Box>
				</Box>
				<Box>
					<Box sx={{ display: "flex", flexDirection: "row" }}>
						<Typography variant={"h3"} sx={{ mb: "8px" }}>
							{achievedAmount}
						</Typography>
						<Typography
							component={"span"}
							variant={"body2"}
							sx={{
								color: theme.palette.grey["800"],
								alignSelf: "flex-end",
								ml: "0.5rem",
								mb: "10px",
							}}
						>
							{props.suffix}
						</Typography>
					</Box>
					<Box sx={{ display: "flex", flexDirection: "row" }}>
						<Typography sx={{ ml: "2.5px", color: "grey.500" }}>
							{props.prefix}
						</Typography>
						{newShareCountType === newShareCountTypes.range && (
							<Typography sx={{ ml: "2.5px", color: "grey.500" }}>
								{minValue} -
							</Typography>
						)}
						<Typography sx={{ ml: "2.5px", color: "grey.500" }}>
							{secondaryValue}
						</Typography>
					</Box>
					{newShareCountType === newShareCountTypes.range && (
						<Box sx={{ display: "flex", mt: "24px", mr: "24px" }}>
							<DotValue
								label={t("emissions.subscriptions.highest-amount")}
								value={`${percentageOfHigherSpanAsPercentage}`}
								color={"custom.progress.orange.dark"}
							/>
							<DotValue
								label={t("emissions.subscriptions.lowest-amount")}
								value={`${percentageOfLowerSpanAsPercentage}`}
								color={"custom.progress.purple.dark"}
							/>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};

const DotValue = (props) => {
	return (
		<Box sx={{ display: "flex", alignItems: "center", mb: 1, mr: "24px" }}>
			<Box
				sx={{
					width: "1.5rem",
					height: "1.5rem",
					borderRadius: 1,
					bgcolor: props.color,
					mr: 1,
				}}
			/>
			<Typography sx={{ mr: 1 }}>{props.label}</Typography>
			<Typography
				sx={{ color: "grey.600", fontStyle: "italic" }}
			>{`(${props.value})`}</Typography>
		</Box>
	);
};

export default EmissionDiagram;
