import React, { Component } from "react";
import { connect } from "react-redux";
import { bool } from "prop-types";
import { Map, List, fromJS } from "immutable";

import {
	editRole,
	resetRolesAndUsersCache,
} from "../../actions/user-management.actions";
import {
	addErrorNotification,
	addInfoNotification,
	clearNotifications,
} from "../../actions/notify.actions";

import TeamEditModal from "../../dumb-components/user-management/team-edit-modal/team-edit-modal";
import FooterRightControls from "../../dumb-components/shared/modal/footer-right-controls";
import { TransparentButton } from "../../dumb-components/shared/button-v2";

class TeamEditModalContainer extends Component {
	state = {
		team: Map(),
		hasError: Map(),
		isLoading: false,
	};

	static propTypes = {
		isOpen: bool,
	};

	componentDidUpdate = (prevProps) => {
		const { isOpen, selectedTeam } = this.props;

		if (isOpen && prevProps.isOpen !== isOpen) {
			this.setState({ isLoading: true });
		}

		if (
			isOpen &&
			prevProps.selectedTeam !== selectedTeam &&
			Object.keys(selectedTeam).length > 0
		) {
			this.setState({ isLoading: false, team: fromJS(selectedTeam) });
		}
	};

	onChange = (fieldName, value) => {
		let { team, hasError } = this.state;
		team = team.set(fieldName, value);

		this.setState({ team });

		if (hasError.get(fieldName)) {
			hasError = hasError.delete(fieldName);
			this.setState({ hasError });
		}
	};

	onSave = () => {
		const { onCancel, editRole } = this.props;
		const { team } = this.state;

		this.setState({ isLoading: true });

		if (this.validate()) {
			editRole(team.toJS(), (e) => {
				if (e) {
					this.setState({ isLoading: false });
					return;
				}

				this.resetState();
				onCancel();
			});

			// edit action
			//in this.onCancel()
		} else {
			// Reset loading if validation did not pass
			this.setState({ isLoading: false });
		}
	};

	onCancel = () => {
		const { onCancel, clearNotifications } = this.props;
		this.resetState();
		clearNotifications();
		onCancel();
	};

	validate = () => {
		const { team } = this.state;
		let { hasError } = this.state;
		let isValid = true;

		if (team.get("name", "").trim().length === 0) {
			hasError = hasError.set("name", List([true]));
			this.addNotification("name");
			isValid = false;
		}

		if (!isValid) {
			this.setState({ hasError });
		}

		return isValid;
	};

	addNotification = (fieldName) => {
		const { addErrorNotification } = this.props;

		addErrorNotification({
			tid: `user_management.team.add.validation.${fieldName}`,
		});
	};

	resetState = () => {
		this.props.resetRolesAndUsersCache();
		this.setState({
			team: Map(),
			hasError: Map(),
			isLoading: false,
		});
	};

	renderFooter = () => {
		const { isLoading } = this.state;

		return (
			<FooterRightControls>
				<TransparentButton
					tid="update"
					onClick={this.onSave}
					isLoading={isLoading}
					disabled={isLoading}
				/>
				<TransparentButton
					tid="generic.form.cancel"
					textColor="midGrey"
					onClick={this.onCancel}
					disabled={isLoading}
				/>
			</FooterRightControls>
		);
	};

	render = () => {
		const { team, hasError, isLoading } = this.state;
		const { isOpen } = this.props;

		return (
			<TeamEditModal
				isOpen={isOpen}
				team={team}
				hasError={hasError}
				isLoading={isLoading}
				onChange={this.onChange}
				renderFooter={this.renderFooter()}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		selectedTeam: store.company.userManagement.selectedRole,
	};
};

const mapActionsToProps = {
	addErrorNotification,
	addInfoNotification,
	clearNotifications,
	editRole,
	resetRolesAndUsersCache,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(TeamEditModalContainer);
