import React, { PureComponent } from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import TaskDetailedList from "../../../tasks/task-detailed-list/task-detailed-list";
import Block from "../block/block";

const StyledTasksRoot = styled.div`
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

export default class DocumentsList extends PureComponent {
	static propTypes = {
		tasks: immutablePropTypes.list,
		usersCache: immutablePropTypes.map,
		onChange: func,
		readOnly: bool,
	};

	render = () => {
		const { tasks, usersCache, onChange, readOnly } = this.props;

		return (
			<StyledTasksRoot>
				<Block titleTid="meetings.protocol.tasks_list.title">
					<TaskDetailedList
						tasks={tasks}
						usersCache={usersCache}
						onChange={onChange}
						readOnly={readOnly}
					/>
				</Block>
			</StyledTasksRoot>
		);
	};
}
