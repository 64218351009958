import React, { Component } from "react";
import { connect } from "react-redux";

import EsignRecreateDocument from "../../../dumb-components/documents/esign-recreate-document/esign-recreate-document";
import { recreateDocument } from "../../../actions/documents.actions";
import PasswordProtectedPdfModal from "./PasswordProtectedPdfModal";

class EsignReacreateDocumentContainer extends Component {
	state = {
		isLoading: false,
	};

	regenerateDocument = () => {
		const { documentId, recreateDocument } = this.props;

		this.setState({ isLoading: true });

		recreateDocument(documentId, () => {
			this.setState({ isLoading: false });
		});
	};

	onRetry = () => {
		this.regenerateDocument();
	};

	render = () => {
		const { isLoading } = this.state;

		return (
			<>
				<EsignRecreateDocument
					onClick={this.regenerateDocument}
					isLoading={isLoading}
				/>
				<PasswordProtectedPdfModal onRetry={this.onRetry} />
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		documentId: store.documents.getIn(["document", "id"]),
	};
};

const mapActionsToProps = {
	recreateDocument,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(EsignReacreateDocumentContainer);
