import { fromJS, List } from "immutable";
import {
	COLLECTIONS_FETCH,
	COLLECTION_SELECT,
	COLLECTION_CREATE,
	COLLECTION_REMOVE,
	COLLECTION_ADD_DOCUMENT,
	COLLECTION_REMOVE_DOCUMENT,
	COLLECTION_UPDATE_LOCAL,
	COLLECTION_UPDATE_DOCUMENTS_LOCAL,
	COLLECTION_MOVE_DOCUMENT,
	COLLECTION_UPDATE_MERGED_DOCUMENT,
	COLLECTION_DOCUMENT_SET_INCLUDE_IN_ACTION,
	COLLECTION_REMOVE_DELETED_DOCUMENT,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	collections: [],
	selectedCollection: {},
	selectedCollectionDocuments: [],
	mergedDocument: null,
});

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case COLLECTIONS_FETCH: {
			return state.set("collections", payload);
		}

		case COLLECTION_SELECT: {
			state = state.set("selectedCollection", payload);
			state = state.set("selectedCollectionDocuments", List());
			return state;
		}

		case COLLECTION_CREATE: {
			const newCollections = state.get("collections").insert(1, payload);

			state = state.set("collections", newCollections);
			state = state.set("selectedCollection", payload);
			state = state.set("selectedCollectionDocuments", List());

			return state;
		}

		case COLLECTION_REMOVE: {
			const selectedCollectionId = state.getIn(["selectedCollection", "id"]);
			const removedCollectionId = payload.get("id");

			if (selectedCollectionId === removedCollectionId) {
				const rootCollection = state.getIn(["collections", 0]);
				state = state.set("selectedCollection", rootCollection);
			}

			const newCollections = state
				.get("collections")
				.filter((c) => c.get("id") !== removedCollectionId);
			state = state.set("collections", newCollections);
			state = state.set("selectedCollectionDocuments", List());

			return state;
		}

		case COLLECTION_ADD_DOCUMENT: {
			const updatedDocuments = payload.getIn(["collection", "documents"]);
			// Update root collection (always first in list)
			state = state.setIn(["collections", 0, "documents"], updatedDocuments);

			return state;
		}

		case COLLECTION_REMOVE_DOCUMENT: {
			const collectionId = payload.getIn(["collection", "id"]);
			const documentId = payload.getIn(["document", "id"]);

			// Remove removed document from collections
			state = state.update("collections", (collections) => {
				const collectionIndex = collections.findIndex(
					(c) => c.get("id") === collectionId,
				);
				const documentIndex = collections
					.getIn([collectionIndex, "documents"])
					.findIndex((d) => d.get("id") === documentId);

				return collections.removeIn([
					collectionIndex,
					"documents",
					documentIndex,
				]);
			});

			// Remove removed document from selectedCollection.documents
			state = state.updateIn(
				["selectedCollection", "documents"],
				(documents) => {
					const documentIndex = documents.findIndex(
						(d) => d.get("id") === documentId,
					);
					return documents.remove(documentIndex);
				},
			);

			// Remove removed document from selectedCollectionDocuments
			state = state.update("selectedCollectionDocuments", (documents) => {
				const index = documents.findIndex((d) => d.get("id") === documentId);
				return documents.remove(index);
			});

			return state;
		}

		case COLLECTION_UPDATE_LOCAL: {
			const updatedCollectionId = payload.get("id");
			const indexOfUpdatedCollection = state
				.get("collections")
				.findIndex((c) => c.get("id") === updatedCollectionId);
			const selectedCollectionId = state.getIn(["selectedCollection", "id"]);

			if (updatedCollectionId === selectedCollectionId) {
				state = state.set("selectedCollection", payload);
			}

			state = state.setIn(["collections", indexOfUpdatedCollection], payload);

			return state;
		}

		case COLLECTION_UPDATE_DOCUMENTS_LOCAL: {
			state = state.set("selectedCollectionDocuments", payload);
			return state;
		}

		case COLLECTION_MOVE_DOCUMENT: {
			const { documentId, toCollectionId, fromCollectionId } = payload;
			let documentEntry;

			// Remove the document from its current collection
			state = state.update("collections", (collections) => {
				const collectionIndex = collections.findIndex(
					(c) => c.get("id") === fromCollectionId,
				);
				const documentIndex = collections
					.getIn([collectionIndex, "documents"])
					.findIndex((d) => d.get("id") === documentId);

				documentEntry = collections.getIn([
					collectionIndex,
					"documents",
					documentIndex,
				]);

				return collections.removeIn([
					collectionIndex,
					"documents",
					documentIndex,
				]);
			});

			// Remove the document from selectedCollection.documents
			state = state.updateIn(
				["selectedCollection", "documents"],
				(documents) => {
					const documentIndex = documents.findIndex(
						(d) => d.get("id") === documentId,
					);
					return documents.remove(documentIndex);
				},
			);

			// Remove the document from selectedCollectionDocuments
			state = state.update("selectedCollectionDocuments", (documents) => {
				const index = documents.findIndex((d) => d.get("id") === documentId);
				return documents.remove(index);
			});

			// Add the document to the new collection
			const toCollectionIndex = state
				.get("collections")
				.findIndex((collection) => collection.get("id") === toCollectionId);

			const newCollection = state.getIn(["collections", toCollectionIndex]);

			const hasDocument = newCollection
				.get("documents")
				.some((doc) => doc.get("id") === documentId);

			if (hasDocument) {
				const index = newCollection
					.get("documents")
					.findIndex((doc) => doc.get("id") === documentId);
				const sharedVersionInNewCollection = newCollection.getIn([
					"documents",
					index,
					"sharedVersion",
				]);

				if (
					sharedVersionInNewCollection !== documentEntry.get("sharedVersion")
				) {
					state = state.setIn(
						[
							"collections",
							toCollectionIndex,
							"documents",
							index,
							"sharedVersion",
						],
						documentEntry.get("sharedVersion"),
					);
				}
			}

			if (!hasDocument) {
				state = state.updateIn(
					["collections", toCollectionIndex, "documents"],
					(documents) => documents.push(documentEntry),
				);
			}
			return state;
		}

		case COLLECTION_DOCUMENT_SET_INCLUDE_IN_ACTION: {
			const { documentId, toBeMerged } = payload;

			state = state.update("selectedCollectionDocuments", (documents) => {
				const index = documents.findIndex((d) => d.get("id") === documentId);
				return documents.setIn([index, "includedInAction"], toBeMerged);
			});

			return state;
		}

		case COLLECTION_UPDATE_MERGED_DOCUMENT:
			return state.set("mergedDocument", payload);

		case COLLECTION_REMOVE_DELETED_DOCUMENT: {
			const { documentId, removeSignedVersion } = payload;

			state = state.update("collections", (collections) => {
				collections = collections.map((collection) => {
					if (collection.has("documents")) {
						collection = collection.update("documents", (documents) => {
							return documents.filter((entry) => {
								if (
									entry.get("id") === documentId &&
									((removeSignedVersion &&
										entry.get("sharedVersion") === "SIGNED") ||
										!removeSignedVersion)
								) {
									return false;
								}

								return true;
							});
						});
					}

					return collection;
				});

				return collections;
			});

			return state;
		}

		default:
			return state;
	}
}
