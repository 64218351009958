import React, { Component } from "react";
import { connect } from "react-redux";

import { inviteInvestor } from "../../../../actions/investors.actions";

import NotifySingleUserModalContainer from "../../generic-modals/notify-single-user-modal.container";

import { REMIND_SHAREHOLDER_EMAIL_TEMPLATE_MODAL } from "../../../../constants/modals";
import { EMAIL_TEMPLATES_INVITE_SHAREHOLDER_TO_JOIN_INVONO } from "/shared/constants";

class RemindShareholderModalContainer extends Component {
	sendReminder = (workingTemplate, closeModalCallback, errorCallback) => {
		const { investor, index, inviteInvestor } = this.props;
		inviteInvestor(
			investor.get("id"),
			index,
			workingTemplate,
			closeModalCallback,
			errorCallback,
		);
	};

	render = () => {
		return (
			<NotifySingleUserModalContainer
				templateName={EMAIL_TEMPLATES_INVITE_SHAREHOLDER_TO_JOIN_INVONO}
				modalName={REMIND_SHAREHOLDER_EMAIL_TEMPLATE_MODAL}
				onSubmit={this.sendReminder}
				successNotificatonTid="shareholders.unregistered_shareholder_reminded_to_register"
				errorNotificationTid="shareholders.error.remind_unregistered_shareholders"
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		investor: store.modals.getIn(["activeModal", "options", "investor"]),
		index: store.modals.getIn(["activeModal", "options", "index"]),
	};
};

const mapActionsToProps = {
	inviteInvestor,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(RemindShareholderModalContainer);
