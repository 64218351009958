import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";
import {
	addErrorNotification,
	addInfoNotification,
} from "@/components/framework/notify";
import { useDispatch } from "react-redux";
import { USER_SAVE } from "@/actions/types";
import { fromJS } from "immutable";

export default (userId) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ["updateUser", userId],
		mutationFn: (payload) => {
			return apiClient.put(`users/${userId}`, payload);
		},
		onSuccess: (mutationResponse) => {
			if (userId === "me") {
				queryClient.setQueryData(["me"], mutationResponse);
			} else {
				queryClient.setQueryData(["users", userId], mutationResponse);
			}

			dispatch({ type: USER_SAVE, payload: fromJS(mutationResponse.data) }); // Save to redux store (legacy for as long as needed)

			addInfoNotification({
				tid: "subscriptions.notifications.success.subscription_updated",
			});
		},
		onError: () => {
			addErrorNotification({ tid: "generic.unknown_error" });
		},
	});
};
