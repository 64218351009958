import req from "../modules/request.module";

export default {
	/**
	 *
	 * @param {object} feature
	 * @returns {object}
	 */
	async createFeature(feature) {
		const response = await req.post("/wiki/feature", { feature });
		return response.data;
	},

	/**
	 *
	 * @param {uuid} id
	 * @throws {Error}
	 * @returns {object}
	 */
	async getFeature(id) {
		if (!id) {
			throw new Error("feature.errors.missing_id");
		}

		const response = await req.get(`/wiki/feature/${id}`);
		return response.data;
	},

	/**
	 *
	 * @param {object} feature
	 * @throws {Error}
	 * @returns {object}
	 */
	async editFeature(feature) {
		if (!feature.id) {
			throw new Error("feature.errors.missing_id");
		}

		const response = await req.put(`/wiki/feature/${feature.id}`, { feature });
		return response.data;
	},

	/**
	 *
	 * @param {uuid} id
	 * @throws {Error}
	 */
	async deleteFeature(id) {
		if (!id) {
			throw new Error("feature.errors.missing_id");
		}

		await req.delete(`/wiki/feature/${id}`);
	},

	async listFeaturesByPosition(position) {
		if (!position) {
			throw new Error("feature.errors.missing_position");
		}

		const response = await req.get(`/wiki/feature/position/${position}`);
		return response.data;
	},

	async listFeatures() {
		const response = await req.get("/wiki/feature");
		return response.data;
	},

	async setFeaturePosition(id, position) {
		const response = await req.put(`/wiki/feature/${id}/position/${position}`);
		return response.data;
	},

	async unsetFeaturePosition(id, position) {
		const response = await req.delete(
			`/wiki/feature/${id}/position/${position}`,
		);
		return response.data;
	},
};
