import React, { Component } from "react";
import { connect } from "react-redux";
import { List } from "immutable";
import { flattenAgendaItems } from "../../../components/helpers/meeting.helper";
import NotesList from "../../../dumb-components/meetings/protocol-view/notes-list/notes-list";
import NotesContainer from "../../shared/notes.container";
import { OBJ_TYPE_AGENDA_ITEM } from "/shared/constants";

class NotesViewContainer extends Component {
	state = {
		flatAgendaItems: List(),
	};

	componentDidMount = () => {
		const { meeting } = this.props;

		if (meeting) {
			this.flattenAgendaItems();
		}
	};

	componentDidUpdate = (prevProps) => {
		const { meeting, userId } = this.props;

		if (
			(prevProps.meeting && prevProps.meeting.get("id")) !==
			(meeting && meeting.get("id"))
		) {
			this.flattenAgendaItems();
		}

		if (prevProps.userId !== userId) {
			this.flattenAgendaItems();
		}
	};

	flattenAgendaItems = () => {
		const { meeting } = this.props;

		if (!meeting) {
			return;
		}

		const flatAgendaItems = flattenAgendaItems(meeting, true);

		this.setState({ flatAgendaItems });
	};

	renderNotesContainer = (objId) => {
		return (
			<NotesContainer
				objType={OBJ_TYPE_AGENDA_ITEM}
				objId={objId}
				borderless
				inline
			/>
		);
	};

	render = () => {
		const { meeting } = this.props;
		const { flatAgendaItems } = this.state;

		return (
			<NotesList
				meetingId={meeting && meeting.get("id")}
				flatAgendaItems={flatAgendaItems}
				renderNotesContainer={this.renderNotesContainer}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get("meeting"),
		userId: store.user.getIn(["userObj", "id"]),
	};
};

const mapActionsToProps = {};

export default connect(mapStoreToProps, mapActionsToProps)(NotesViewContainer);
