import React, { PureComponent } from "react";
import { string, number } from "prop-types";
import styled from "styled-components";
import { map } from "react-immutable-proptypes";
import { Map } from "immutable";

import Text from "../../shared/text/text";

const StyledNumberOfUsersWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${({ theme, marginBottom }) => theme.spacing[marginBottom]};

	> span:last-child {
		margin: 0 ${({ theme }) => theme.spacing[3]};
	}
`;

const StyledNumberOfUsers = styled.div`
	margin-bottom: ${({ theme }) => theme.spacing[1]};
	padding: 0 ${({ theme }) => theme.spacing[3]};
	min-height: 50px;
	display: flex;
	align-items: center;
	border-top: 1px solid
		${({ theme, hasError }) =>
			hasError ? theme.colors.persianRed : theme.colors.border};
	border-bottom: 1px solid
		${({ theme, hasError }) =>
			hasError ? theme.colors.persianRed : theme.colors.border};

	> span:first-child {
		flex: 1;
	}

	> span:last-child {
		padding: 0 ${({ theme }) => theme.spacing[3]};
	}
`;

export default class NumberOfUsersToNotifyListItem extends PureComponent {
	static propTypes = {
		fieldName: string,
		hasError: map,
		numberOfPeople: number,
		marginBottom: number,
	};

	static defaultProps = {
		hasError: Map(),
		marginBottom: 4,
		tid: "collections.share_documents.modal.overview.right.number_of_people",
	};

	render = () => {
		const { hasError, fieldName, tid, numberOfPeople, marginBottom } =
			this.props;
		const error = hasError.getIn([fieldName, 0]);

		return (
			<StyledNumberOfUsersWrapper marginBottom={marginBottom}>
				<StyledNumberOfUsers hasError={error}>
					<Text tid={tid} />
					<Text>{numberOfPeople.toString()}</Text>
				</StyledNumberOfUsers>
				{error && <Text tid={error} color="persianRed" />}
			</StyledNumberOfUsersWrapper>
		);
	};
}
