import React, { PureComponent } from "react";
import { bool, node, func, string } from "prop-types";
import styled from "styled-components";
import Modal from "./modal";
import Text from "../text/text";

const StyledModalContentWrapper = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
`;

export default class MeetingTemplatesModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		title: string,
		titleTid: string,
		modalFooterComponent: node,
		modalHeaderComponent: func,
		renderContent: func,
		hSize: string,
		vSize: string,
		noBodyMargin: bool,
		scrollableContent: bool,
		scrollbarRef: func,
	};

	static defaultProps = {
		hSize: "xl",
		noBodyMargin: true,
		scrollableContent: false,
	};

	render = () => {
		const {
			isOpen,
			modalFooterComponent,
			renderContent,
			modalHeaderComponent,
			hSize,
			vSize,
			noBodyMargin,
			scrollableContent,
			scrollbarRef,
		} = this.props;
		const props = {
			hSize,
			vSize,
			scrollbarRef,
		};

		if (this.props.title) {
			props.rawTitle = (
				<Text tag="h4" color="catalinaBlue">
					{this.props.title}
				</Text>
			);
		}

		if (this.props.titleTid) {
			props.title = this.props.titleTid;
		}

		return (
			<Modal
				{...props}
				isOpen={isOpen}
				footerComponent={modalFooterComponent}
				headerRightRenderer={modalHeaderComponent}
				noBodyMargin={noBodyMargin}
				scrollableContent={scrollableContent}
			>
				<StyledModalContentWrapper>{renderContent()}</StyledModalContentWrapper>
			</Modal>
		);
	};
}
