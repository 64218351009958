const moment = require('./moment.helper');
const folderHelper = require('./folders.helper');

const helper = {
	makeSummary: (fullDocument, dateProp, folders) => {
		const {
			eSigning,
			permissions,
			shareWith,
			isMirrored,
			eSigningData,
			activities,
			updatedAt,
			state,
			eSignees,
			hideFromDocumentsSection,
			createdAt,
			createdBy,
			lastModified,
			validTo,
			mergedDocumentData,
			...document
		} = fullDocument;
		const { destination, encoding, fieldname, filename, linkUrl, selfLink, uri, ...file } = fullDocument.file;

		const date = helper.getDate(dateProp, fullDocument);

		const status = helper.getStatus(fullDocument);

		let folderPath;
		try {
			folderPath = folderHelper.getShortPath(document.folderId, folders);
		} catch (error) {
			//
		}

		let folderName = '';

		if (document.folderId && document.folderId !== '') {
			folderName = folders && folders[document.folderId] && folders[document.folderId].name;
		}

		const documentIsSigned = !!helper.getDocumentIsSigned(fullDocument);
		const ongoingEsigning = fullDocument.eSigning === true && !documentIsSigned;

		const metadata = {
			status,
			folderName,
			folderPath,
			documentIsSigned,
			ongoingEsigning
		};

		if (document.tags && document.tags.length === 0) {
			delete document.tags;
		}

		return {
			...document,
			...(eSigningData ? { eSigningData: { fileReference: eSigningData?.fileReference } } : {}),
			date,
			metadata,
			file
		};
	},

	getDate: (dateProp, document) => {
		let date;

		switch (dateProp) {
			case 'lastModified':
				date = document.lastModified;
				break;
			case 'createdAt':
				date = document.createdAt;
				break;
			case 'updatedAt':
				date = document.updatedAt;
				break;
			case 'validTo':
				date = document.validTo;
				break;
			default:
				date = document.createdAt;
		}

		return date;
	},

	getStatus: (document) => {
		let status;

		if (document.reminderSet && document.validTo && moment().isAfter(moment(document.validTo), 'day')) {
			status = 'INVALID';
		} else if (
			document.reminderSet &&
			document.reminderDate &&
			moment().isSameOrAfter(moment(document.reminderDate), 'day')
		) {
			status = 'WARNING';
		}

		return status;
	},

	getDocumentIsSigned: (document) => {
		if (!document) {
			return false;
		}

		if (document.eSigning !== true) {
			return false;
		}

		const fileReference = document.eSigningData?.fileReference;
		const allSigned = helper.getDocumentAllHaveSigned(document);
		return fileReference && allSigned;
	},

	getDocumentAllHaveSigned: (document) => {
		if (!document) {
			return false;
		}

		if (document.eSigning !== true) {
			return false;
		}

		const eSignees = document.eSignees ?? {};

		if (Object.keys(eSignees).length === 0) {
			return false;
		}

		const allSigned = Object.values(eSignees).every((eSignee) => eSignee.status === 'SIGNED');
		return allSigned;
	}
};

module.exports = helper;
