import React from "react";
import { bool, func, string } from "prop-types";
import styled from "styled-components";
import Modal from "../../shared/modal/modal";
import Text from "../../shared/text/text";
import Input from "../../shared/input/input";
import FooterRightControls from "../../shared/modal/footer-right-controls";
import { TransparentButton } from "../../shared/button-v2";
import ColoredContentWrapper from "../../shared/colored-content-wrapper/colored-content-wrapper";

const StyledRelinkWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
`;

const StyledTextWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing[4]};
`;

const InvestorRelinkModal = (props) => {
	const { isOpen, onChange, onLink, onCancel, value, isLoading } = props;

	const btnLinkDisabled = value ? value.trim().length === 0 : true;

	return (
		<Modal
			vSize={50}
			hSize="md"
			title="investor.reinvite.modal.title"
			isOpen={isOpen}
			footerComponent={
				<FooterRightControls>
					<TransparentButton
						tid="investor.reinvite.modal.footer.btn.link"
						disabled={btnLinkDisabled}
						onClick={onLink}
						isLoading={isLoading}
					/>
					<TransparentButton
						tid="investor.reinvite.modal.footer.btn.cancel"
						textColor="midGrey"
						onClick={onCancel}
						disabled={isLoading}
					/>
				</FooterRightControls>
			}
		>
			<StyledRelinkWrapper>
				<StyledColoredContentWrapper type="secondary">
					<Input
						fieldName="invonoId"
						placeholderTid="investor.reinvite.modal.input.placeholder"
						onChange={onChange}
						value={value}
						disabled={isLoading}
					/>
					<StyledTextWrapper>
						<Text tid="investor.reinvite.modal.description.text.1" tag="p" />
						<Text tid="investor.reinvite.modal.description.text.2" />
					</StyledTextWrapper>
				</StyledColoredContentWrapper>
			</StyledRelinkWrapper>
		</Modal>
	);
};

InvestorRelinkModal.defaultProps = {
	isOpen: false,
	isLoading: false,
	value: "",
};

InvestorRelinkModal.propTypes = {
	isOpen: bool,
	isLoading: bool,
	value: string,
	onChange: func,
	onLink: func,
	onCancel: func,
};

export default InvestorRelinkModal;
