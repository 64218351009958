import React from "react";
import styled, { css } from "styled-components";
import { bool } from "prop-types";

const StyledMenu = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	${(props) =>
		props.margin &&
		css`
			margin-left: ${(props) => props.theme.spacing[3]};
			margin-right: ${(props) => props.theme.spacing[3]};
		`}
`;

const StyledHeader = styled.div``;

const StyledBody = styled.ul`
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	padding: 0;
	height: 100%;
`;

const TreeMenu = ({ className, children, header, margin }) => {
	return (
		<StyledMenu className={className} margin={margin}>
			{header && <StyledHeader>{header()}</StyledHeader>}
			<StyledBody>{children}</StyledBody>
		</StyledMenu>
	);
};

TreeMenu.defaultProps = {
	margin: false,
};

TreeMenu.propTypes = {
	margin: bool,
};

export default TreeMenu;
