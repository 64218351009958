import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import WhiteSection from "../../common/white-section";
import { getSubmissions } from "../../../../actions/emissions.actions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { emissionConsts } from "../../../../constants/emissions";
import PaymentGrid from "./payment-grid";
import Pages from "./payment-pages";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import SendReceiptPopUpModal from "./send-receipt-pop-up-modal";
import { summarize } from "../../utils/calculations";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import { NUMBER_FORMAT_CURRENCY } from "/shared/constants.json";
import { PaymentDiagram, PaymentInfoComponent } from "./payment-components";
import Button from "@mui/material/Button";
import PaymentPopUpModal from "./payment-pop-up-modal";
import SelectPartialClosure from "../registration/select-partial-closure-component";

const Payment = ({ emission }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const theme = useTheme();
	const allocationType = emission.allocationType;
	const { allocationTypes, newShareCountTypes, emissionStatus } =
		emissionConsts.common;
	const [pageNumber, setPageNumber] = useState(2);
	const submissions = useSelector((state) => state.emissions.submissions);
	const shareRegister = useSelector(
		(state) => state.company.company.metadata.sharesInitialized,
	);
	const [receiptModal, setReceiptModal] = useState(false);
	const totals = summarize(useSelector((state) => state.emissions.submissions));
	const price = useSelector((state) => state.emissions.current.pricePerShare);
	const currClosureStatus = useSelector(
		(state) => state.emissions.current.closureStatus,
	);
	const { closureStatus } = emissionConsts.closure;
	const closures = useSelector((state) => state.emissions.current.closures);
	const shareRegisterUpdated = [...closures].pop()?.shareRegisterUpdated;
	const settlementNotesGenerated = [...closures].pop()
		?.settlementNotesGenerated;
	const settlementNotesSent = [...closures].pop()?.settlementNotesSent;
	const [closeEmissionModal, setCloseEmissionModal] = useState(false);
	const emissionStat = useSelector((state) => state.emissions.current.status);
	const preventChanges = false; //The payment page is never disabled, can manage these things even after the emission is closed.
	// const preventChanges = emissionIsReadOnly(emission, payment)
	const [closure, setClosure] = useState(
		emission.closures[emission.closures.length - 1],
	);
	const [closureIndex, setClosureIndex] = useState(closures.length - 1);

	const backwards = () => {
		setPageNumber((t) => t - 1);
	};
	const forwards = () => {
		setPageNumber((t) => t + 1);
	};
	const goToPaymentList = () => {
		setPageNumber(4);
	};

	function totalDeposit() {
		let total = 0;
		for (let i = 0; i < closure.allocations.length; i++) {
			total += closure.allocations[i].allocatedShares;
		}
		return total;
	}

	function deposit() {
		let total = 0;
		for (let i = 0; i < closure.allocations.length; i++) {
			if (closure.allocations[i].paymentCompleted) {
				total += closure.allocations[i].allocatedShares;
			}
		}
		return total;
	}

	function amountOfReceiptsNotSent() {
		return closure.allocations.filter(
			(a) => (a["receiptDocId_sv"] || a["receiptDocId_en"]) && !a.receiptSent,
		).length;
	}

	useEffect(() => {
		setClosure(closures[closureIndex]);
	}, [emission]);

	useEffect(() => {
		async function fetchSubmissions() {
			await dispatch(getSubmissions(emission.companyId, emission.id));
		}
		function view() {
			if (shareRegisterUpdated && allocationType === allocationTypes.decision) {
				setPageNumber(2);
			}
			if (
				settlementNotesGenerated &&
				allocationType === allocationTypes.decision
			) {
				setPageNumber(3);
			}
			if (settlementNotesSent && allocationType === allocationTypes.decision) {
				setPageNumber(4);
			}
			if (shareRegisterUpdated && allocationType === allocationTypes.payment) {
				setPageNumber(4);
			}
		}

		fetchSubmissions(), view();
	}, []);

	return (
		<>
			{!shareRegisterUpdated ||
			(currClosureStatus === closureStatus.closurePending &&
				closures.length === 0) ? (
				// View with closure pending
				<>
					{emission.newShareCountType === newShareCountTypes.specific ? (
						<Pages
							startPage
							noClosure
							label={t("emissions.allocation.close-emission")}
							desc={t("emissions.payment.close-emission.desc")}
							buttonText={t("emissions.payment.go-to-allocation-button")}
							preventChanges={preventChanges}
						/>
					) : (
						<Pages
							startPage
							noClosure
							label={t("emissions.payment.partialclose/close-emission-header")}
							desc={t("emissions.payment.partialclose/close-emission.desc")}
							desc2={t("emissions.payment.partialclose/close-emission.desc2")}
							buttonText={t("emissions.payment.go-to-allocation-button")}
							preventChanges={preventChanges}
						/>
					)}
				</>
			) : (
				<Box position={"relative"}>
					{!shareRegister &&
						pageNumber === 0 && ( // First view without a share register in Invono One
							<Pages
								startPage
								noShareReg
								page={goToPaymentList}
								label={t(
									"emissions.payment.update-share-register-&-send-notes-header",
								)}
								desc={t(
									"emissions.payment.update-share-register-&-send-notes.desc",
								)}
								desc2={t("emissions.payment.no-share-register.desc2")}
								buttonText={t("emissions.payment.button-i-understand")}
								preventChanges={preventChanges}
							/>
						)}
					{shareRegister &&
						allocationType === allocationTypes.decision &&
						pageNumber === 0 && ( // First view when allocating with decision
							<Pages
								startPage
								decision
								page={forwards}
								label={t(
									"emissions.payment.update-share-register-&-send-notes-header",
								)}
								desc={t(
									"emissions.payment.update-share-register-&-send-notes.desc",
								)}
								desc2={t(
									"emissions.payment.update-share-register-&-send-notes.desc2",
								)}
								buttonText={t("emissions.payment.button-get-started")}
								preventChanges={preventChanges}
							/>
						)}
					{shareRegister &&
						allocationType === allocationTypes.payment &&
						pageNumber === 0 && ( // First view when allocating with payment
							<Pages
								startPage
								payment
								page={goToPaymentList}
								label={t("emissions.payment.update-share-register-header")}
								desc={t("emissions.payment.update-share-register.desc2")}
								buttonText={t("emissions.payment.update-share-register-header")}
								preventChanges={preventChanges}
							/>
						)}
					{shareRegister &&
						allocationType === allocationTypes.decision &&
						pageNumber === 1 && ( // Update share register view
							<Pages
								firstDecisionPage
								back={backwards}
								page={forwards}
								step={t("emissions.payment.step-1")}
								label={t("emissions.payment.update-share-register-header")}
								desc={t("emissions.payment.update-share-register.desc")}
								preventChanges={preventChanges}
							/>
						)}
					{shareRegister &&
						allocationType === allocationTypes.decision &&
						pageNumber === 2 && ( // Create settlement note view
							<Pages
								secondDecisionPage
								scroll="scroll"
								page={forwards}
								step={t("emissions.payment.step2-1")}
								label={t("emissions.payment.create-settlement-note-header")}
								desc={t("emissions.payment.create-settlement-note.desc")}
								desc2={t("emissions.payment.create-settlement-note.desc2")}
								sub={t("emissions.subscription-form.payment-information")}
								preventChanges={preventChanges}
							/>
						)}
					{shareRegister &&
						allocationType === allocationTypes.decision &&
						pageNumber === 3 && ( // Send settlement note view
							<Pages
								thirdDecisionPage
								scroll="scroll"
								back={backwards}
								page={forwards}
								step={t("emissions.payment.step2-2")}
								label={t("emissions.payment.send-settlement-note-header")}
								desc={t("emissions.payment.send-settlement-note.desc")}
								sub={t("emissions.payment.message-to-subscriber")}
								preventChanges={preventChanges}
							/>
						)}
					{((allocationType === allocationTypes.payment &&
						shareRegisterUpdated) ||
						pageNumber === 4) && ( // PaymentList view
						<Box>
							<SendReceiptPopUpModal
								toAll
								open={receiptModal}
								close={() => setReceiptModal(false)}
								closureId={closure.id}
								closure={closure}
							/>

							{emissionStat === emissionStatus.partiallyClosed ? (
								<Grid item xs={12} sx={{ display: "flex", mb: "24px" }}>
									<PaymentPopUpModal
										open={closeEmissionModal}
										close={() => setCloseEmissionModal(false)}
										desc={t("emissions.payment.close-emission-desc")}
										closureId={closure.id}
									/>
									<SelectPartialClosure
										emission={emission}
										closures={closures}
										setClosure={setClosure}
										setClosureIndex={setClosureIndex}
										closureIndex={closureIndex}
									/>
									<Box sx={{ display: "flex", my: "4px", mx: "4px" }}>
										<Button
											onClick={() => setCloseEmissionModal(true)}
											sx={{ width: "max-content" }}
											variant={"contained"}
										>
											<Typography variant={"h5"}>
												{t("emissions.allocation.close-emission")}
											</Typography>
										</Button>
									</Box>
								</Grid>
							) : (
								<Box sx={{ display: "flex" }}>
									<PaymentPopUpModal
										open={closeEmissionModal}
										close={() => setCloseEmissionModal(false)}
										desc={t(
											"emissions.payment.close-emission-desc.no-partials",
										)}
										closureId={closure.id}
									/>
								</Box>
							)}
							{shareRegister && allocationType === allocationTypes.decision && (
								<Grid item xs={12} sx={{ display: "flex", mb: "24px" }}>
									<PaymentInfoComponent
										closure={closure}
										preventChanges={preventChanges}
									/>
								</Grid>
							)}
							<Grid container columnSpacing={2}>
								{shareRegister ? (
									<Grid item xs={9}>
										<Grid container rowSpacing={1}>
											<PaymentDiagram
												percentage={(deposit() / totalDeposit()) * 100}
												deposit={localeFormatNumber(deposit() * price)}
												totalDeposit={localeFormatNumber(
													totalDeposit() * price,
													NUMBER_FORMAT_CURRENCY,
												)}
												allPrevPartialClosuresDeposits={localeFormatNumber(
													totals.amountOfPartialClosures * price -
														totalDeposit() * price,
													NUMBER_FORMAT_CURRENCY,
												)}
											/>
										</Grid>
									</Grid>
								) : (
									<Grid item xs={12}>
										<PaymentDiagram
											percentage={(deposit() / totalDeposit()) * 100}
											deposit={localeFormatNumber(deposit() * price)}
											totalDeposit={localeFormatNumber(
												totalDeposit() * price,
												NUMBER_FORMAT_CURRENCY,
											)}
											allPrevPartialClosuresDeposits={localeFormatNumber(
												totals.amountOfPartialClosures * price -
													totalDeposit() * price,
												NUMBER_FORMAT_CURRENCY,
											)}
										/>
									</Grid>
								)}
								{shareRegister && (
									<Grid item xs={3} sx={{ display: "flex" }}>
										<Grid container rowSpacing={1}>
											<Grid item xs={12}>
												<WhiteSection
													sx={{
														px: 0,
														py: 0,
														height: "296px",
													}}
												>
													<Box sx={{ my: "32px", mx: "40px" }}>
														<Box sx={{ display: "flex" }}>
															<Typography
																sx={{
																	textTransform: "uppercase",
																	color: "secondary.500",
																	pb: "8px",
																}}
															>
																{t("emissions.payment.receipt")}
															</Typography>
														</Box>
														<Box
															sx={{
																display: "flex",
																flexDirection: "column",
																columnGap: 2,
																mt: "16px",
															}}
														>
															{amountOfReceiptsNotSent() > 0 ? (
																<>
																	<Box
																		sx={{
																			display: "flex",
																			justifyContent: "center",
																			mt: "24px",
																		}}
																	>
																		<Typography
																			variant={"h3"}
																			sx={{ mb: "-2px" }}
																		>
																			{amountOfReceiptsNotSent()}
																		</Typography>
																		<Typography
																			component={"span"}
																			variant={"body2"}
																			sx={{
																				color: theme.palette.grey["800"],
																				alignSelf: "flex-end",
																				ml: "8px",
																			}}
																		>
																			{t(
																				"shares.owner_report_pdf.document.quantity.abbreviation",
																			)}
																		</Typography>
																	</Box>
																	<Typography
																		component={"span"}
																		variant={"body2"}
																		sx={{
																			display: "flex",
																			mt: "16px",
																			justifyContent: "center",
																			color: "grey.500",
																		}}
																	>
																		{t(
																			"emissions.payment.have-not-received-receipt",
																		)}
																	</Typography>
																	<Typography
																		onClick={() => setReceiptModal(true)}
																		sx={{
																			justifyContent: "center",
																			display: "flex",
																			color: "primary.main",
																			fontWeight: "bold",
																			cursor: "pointer",
																			mt: "46px",
																			mb: "12px",
																		}}
																	>
																		<ReceiptLongOutlinedIcon
																			sx={{ display: "flex", mr: "2px" }}
																		/>
																		{t("emissions.payment.send-receipt-to-all")}
																	</Typography>
																</>
															) : (
																<Box
																	sx={{
																		display: "flex",
																		justifyContent: "flex-start",
																		mt: "44px",
																	}}
																>
																	<Typography
																		component={"span"}
																		variant={"h6"}
																		sx={{
																			color: theme.palette.grey["800"],
																			alignSelf: "flex-end",
																		}}
																	>
																		{t(
																			"emissions.payment.send-receipt.no-more-to-send",
																		)}
																	</Typography>
																</Box>
															)}
														</Box>
													</Box>
												</WhiteSection>
											</Grid>
										</Grid>
									</Grid>
								)}
							</Grid>
							<WhiteSection sx={{ mt: 3 }}>
								<Grid container>
									<Grid item xs={12}>
										<Box sx={{ display: "flex", flexDirection: "column" }}>
											<Box
												sx={{
													display: "flex",
													justifyContent: "space-between",
												}}
											>
												<Typography variant={"h4"} sx={{ mb: 6 }}>
													{t("emissions.payment.payment-list")}
												</Typography>
											</Box>
											<PaymentGrid
												submissions={submissions}
												closure={closure}
												preventChanges={preventChanges}
											/>
										</Box>
									</Grid>
								</Grid>
							</WhiteSection>
						</Box>
					)}
				</Box>
			)}
		</>
	);
};

export default Payment;
