import React, { useEffect, useState } from "react";
import Input from "../../../dumb-components/shared/input/input";
import Label from "../../../dumb-components/shared/label/label";
import styled from "styled-components";
import ColoredContentWrapper from "../../../dumb-components/shared/colored-content-wrapper/colored-content-wrapper";
import FooterRightControls from "../../../dumb-components/shared/modal/footer-right-controls";
import { bool, func, object } from "prop-types";
import {
	validateEmail as validateEmailFunc,
	isRequired,
} from "../../../modules/validation.module";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import Button from "../../../mui-components/button/button";

const StyledWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: ${(props) => props.theme.spacing[4]};
`;

const StyledNameInputWrapper = styled.div`
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[5]};
`;

function AddPersonModal({
	isOpen,
	onSave,
	onCancel,
	person: initialPerson,
	showCategoryField,
}) {
	const [person, setPerson] = useState({});
	const [errors, setErrors] = useState({});
	const isEditing = Boolean(initialPerson);
	const { t } = useTranslation();

	useEffect(() => {
		if (initialPerson) {
			setPerson(initialPerson);
		}

		return () => {};
	}, [initialPerson]);

	const onChange = (field, val) => {
		setPerson({ ...person, [field]: val });
	};

	const validateName = () => {
		const { name } = person;

		if (!isRequired(name)) {
			setErrors({
				...errors,
				name: "people_modal.add_person_modal.name.error.required",
			});
			return false;
		}

		return true;
	};

	const validateEmail = () => {
		const { email } = person;

		if (!isRequired(email)) {
			setErrors({
				...errors,
				email: "people_modal.add_person_modal.email.error.required",
			});
			return false;
		} else if (!validateEmailFunc(email)) {
			setErrors({
				...errors,
				email: "people_modal.add_person_modal.email.error.not_valid",
			});
			return false;
		}

		return true;
	};

	const validate = () => {
		setErrors({});
		return validateName() && validateEmail();
	};

	const _onSave = () => {
		if (validate()) {
			resetStates();
			onSave(person);
		}
	};

	const _onCancel = () => {
		resetStates();
		onCancel();
	};

	const resetStates = () => {
		setPerson({});
		setErrors({});
	};

	if (!isOpen) {
		return null;
	}

	return (
		<Dialog open={true} fullWidth maxWidth="sm">
			<DialogTitle>
				{t(
					isEditing
						? "people_modal.add_person_modal.title.edit"
						: "people_modal.add_person_modal.title.create",
				)}
			</DialogTitle>
			<DialogContent>
				<StyledWrapper>
					<StyledColoredContentWrapper type="secondary">
						<StyledNameInputWrapper>
							<Label tid="generic.name">
								<Input
									fieldName="name"
									placeholderTid="generic.name_placeholder"
									onChange={onChange}
									value={person.name}
									hasError={errors.name || ""}
								/>
							</Label>
						</StyledNameInputWrapper>
						<StyledNameInputWrapper>
							<Label tid="generic.email">
								<Input
									fieldName="email"
									placeholderTid="generic.email"
									onChange={onChange}
									value={person.email}
									hasError={errors.email || ""}
								/>
							</Label>
						</StyledNameInputWrapper>
						<StyledNameInputWrapper>
							<Label tid="people_modal.add_person.phone">
								<Input
									fieldName="phone"
									placeholderTid="people_modal.add_person.phone"
									onChange={onChange}
									value={person.phone}
									hasError={errors.phone || ""}
								/>
							</Label>
						</StyledNameInputWrapper>
						{showCategoryField && (
							<Label tid="people_modal.category">
								<Input
									fieldName="tags"
									placeholderTid="people_modal.category"
									onChange={onChange}
									value={person.tags}
								/>
							</Label>
						)}
					</StyledColoredContentWrapper>
				</StyledWrapper>
			</DialogContent>
			<DialogActions>
				<FooterRightControls>
					<Button onClick={_onSave} color="positive">
						{t("generic.modal.save_and_close")}
					</Button>
					<Button onClick={_onCancel}>{t("generic.form.cancel")}</Button>
				</FooterRightControls>
			</DialogActions>
		</Dialog>
	);
}

AddPersonModal.propTypes = {
	isOpen: bool,
	onSave: func.isRequired,
	onCancel: func.isRequired,
	person: object,
	showCategoryField: bool,
};

AddPersonModal.defaultProps = {
	showCategoryField: true,
};

export { AddPersonModal };
