import React, { PureComponent } from "react";
import { func, bool } from "prop-types";
import { map } from "react-immutable-proptypes";
import { fromJS } from "immutable";
import styled from "styled-components";

import ColoredContentWrapper from "../../shared/colored-content-wrapper/colored-content-wrapper";
import ListTableColumn from "../../shared/list-table/list-table-column";
import Icon from "../../shared/icon/icon";
import Text from "../../shared/text/text";
import { Select } from "../../shared/select";

import {
	ATTENDEES_ATTENDANCE_TYPE,
	ATTENDEES_STATUS_OPTIONS,
} from "../../../constants/meetings";
import {
	ATTENDEE_STATUS_PRESENT,
	ATTENDEE_STATUS_ABSENT,
	ATTENDEE_STATUS_UNSURE,
} from "/shared/constants";

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	padding-top: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) =>
		props.hideMoreActions ? 0 : props.theme.spacing[4]};
	padding-bottom: ${(props) => props.theme.spacing[3]};
	padding-left: ${(props) => props.theme.spacing[4]};
`;

const InfoTableColmn = styled(ListTableColumn).attrs({
	flex: 1,
	tighten: true,
})`
	display: flex;
	align-items: center;
`;

const ATTENDEE_INVITE_RESPONSE_OPTIONS = fromJS([
	{
		value: ATTENDEE_STATUS_PRESENT,
		label: "meeting.generic.attendee.response.accepted",
	},
	{
		value: ATTENDEE_STATUS_ABSENT,
		label: "meeting.generic.attendee.response.declined",
	},
	{
		value: ATTENDEE_STATUS_UNSURE,
		label: "meeting.generic.attendee.response.undecided",
	},
	{
		value: "noResponse",
		label: "meeting.generic.attendee.response.no_response",
	},
]);

const ATTENDEE_CHECKIN_OPTIONS = fromJS([
	{ value: "true", label: "generic.yes" },
	{ value: "false", label: "generic.no" },
]);

const FIELD_RESPONSE_STATUS = "responseStatus";
const FIELD_ATTENDANCE_TYPE = "attendanceType";
const FIELD_STATUS = "status";
const FIELD_ATTENDANCE_VERIFIED = "verifiedAttendance";

const COMMON_SELECT_PROPS = {
	placeholderTid: "meetings.rollcall.modal.filter_by.placeholder",
	isSearchable: false,
	labelIsTid: true,
	isClearable: true,
	menuPortalTarget: document.body,
};

export default class RollcallFilter extends PureComponent {
	static propTypes = {
		onChange: func,
		filterData: map,
		hideCheckin: bool,
		hideMoreActions: bool,
		hideVotingInfo: bool,
	};

	render = () => {
		const {
			onChange,
			filterData,
			hideCheckin,
			hideMoreActions,
			hideVotingInfo,
		} = this.props;

		return (
			<StyledColoredContentWrapper hideMoreActions={hideMoreActions}>
				<InfoTableColmn flex={1} tighten>
					{!hideVotingInfo && (
						<>
							<Icon icon="faStar" size="sm" type="solid" />
							&nbsp;&nbsp;-&nbsp;&nbsp;
							<Text tid="meetings.rollcall.modal.filter_by.info_text" />
						</>
					)}
				</InfoTableColmn>

				<ListTableColumn minWidth={140} hideBelow={950} tighten center>
					<Select
						fieldName={FIELD_RESPONSE_STATUS}
						onChange={onChange}
						options={ATTENDEE_INVITE_RESPONSE_OPTIONS}
						value={filterData.get(FIELD_RESPONSE_STATUS)}
						{...COMMON_SELECT_PROPS}
					/>
				</ListTableColumn>

				<ListTableColumn minWidth={140} tighten center>
					<Select
						fieldName={FIELD_ATTENDANCE_TYPE}
						onChange={onChange}
						options={ATTENDEES_ATTENDANCE_TYPE}
						value={filterData.get(FIELD_ATTENDANCE_TYPE)}
						{...COMMON_SELECT_PROPS}
					/>
				</ListTableColumn>

				<ListTableColumn minWidth={140} tighten center>
					<Select
						fieldName={FIELD_STATUS}
						onChange={onChange}
						options={ATTENDEES_STATUS_OPTIONS}
						value={filterData.get(FIELD_STATUS)}
						{...COMMON_SELECT_PROPS}
					/>
				</ListTableColumn>

				{!hideCheckin && (
					<ListTableColumn minWidth={140} tighten center>
						<Select
							fieldName={FIELD_ATTENDANCE_VERIFIED}
							onChange={onChange}
							options={ATTENDEE_CHECKIN_OPTIONS}
							value={filterData.get(FIELD_ATTENDANCE_VERIFIED)}
							{...COMMON_SELECT_PROPS}
						/>
					</ListTableColumn>
				)}

				{/* Spacer for last column and scroll*/}
				{/* {!hideMoreActions && <ListTableColumn minWidth={20} tighten />} */}
			</StyledColoredContentWrapper>
		);
	};
}
