import React, { PureComponent } from "react";
import styled from "styled-components";
import { string } from "prop-types";
import Text from "../text/text";
import Icon from "../icon/icon";
import DropdownMenuMenu from "../dropdown-menu/dropdown-menu-menu";

const StyledDropdownWrapper = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	border: 1px solid transparent;
	padding: ${(props) => `${props.theme.spacing[2]} 12px`};
	height: 36px;
	min-width: ${(props) => props.minWidth || "121px"};
	width: ${(props) => (props.block ? "100%" : "auto")};
	background-color: ${(props) => props.theme.colors[props.statusColor]};
	transition: background-color 300ms ease;
`;

const StyledLeftComponent = styled.div`
	display: flex;
	flex: 1;
`;

const StyledRightComponent = styled.div``;

export default class AgendaTopicStatusDropdown extends PureComponent {
	static propTypes = {
		statusLabelTid: string,
		statusColor: string,
		outcomeValue: string,
	};

	render = () => {
		const { statusLabelTid, statusColor, outcomeValue } = this.props;
		const isTodo = outcomeValue === "todo" ? true : false;

		return (
			<StyledDropdownWrapper statusColor={statusColor}>
				<StyledLeftComponent>
					<Text
						tid={statusLabelTid}
						color={isTodo ? "midGrey" : "white"}
						bold={600}
					></Text>
				</StyledLeftComponent>
				<StyledRightComponent>
					<Icon
						icon="faCaretDown"
						color={isTodo ? "midGrey" : "white"}
						size="sm"
						type="solid"
					/>
				</StyledRightComponent>
				<DropdownMenuMenu></DropdownMenuMenu>
			</StyledDropdownWrapper>
		);
	};
}
