import { fromJS, List } from "immutable";

import {
	COMMENTS_UPDATE_LOCAL,
	COMMENTS_CREATE_COMMENT,
	COMMENTS_UPDATE_REMOTE,
	COMMENTS_DELETE_LOCAL,
	COMMENTS_EDIT_LOCAL,
	COMPANY_RESET_REDUCER,
	COMMENTS_FETCH_MULTIPLE_REMOTE,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	comments: {},
	list: {},
});

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case COMMENTS_UPDATE_LOCAL: {
			const objId = payload.get("objId");
			return state.setIn(["comments", objId], payload);
		}
		case COMMENTS_CREATE_COMMENT: {
			const objId = payload.get("objId");
			const newComment = payload.get("comment");

			return state.updateIn(["comments", objId, "comments"], (comments) => {
				if (!comments) {
					comments = List();
				}

				comments = comments.push(newComment);
				return comments;
			});
		}
		case COMMENTS_UPDATE_REMOTE: {
			const objId = payload.get("objId");
			return state.setIn(["comments", objId], payload);
		}
		case COMMENTS_EDIT_LOCAL: {
			const objId = payload.get("objId");
			const comment = payload.get("comment");

			const commentId = comment.get("id");
			const commentText = comment.get("text");
			const comments = state.getIn(["comments", objId, "comments"]);

			const commentIndex = comments.findIndex((comment) => {
				return comment.get("id") === commentId;
			});

			return state.setIn(
				["comments", objId, "comments", commentIndex, "text"],
				commentText,
			);
		}
		case COMMENTS_DELETE_LOCAL: {
			const objId = payload.get("objId");
			const commentId = payload.get("commentId");
			const comments = state.getIn(["comments", objId, "comments"]);
			const commentIndex = comments.findIndex((comment) => {
				return comment.get("id") === commentId;
			});

			return state.deleteIn(["comments", objId, "comments", commentIndex]);
		}
		case COMMENTS_FETCH_MULTIPLE_REMOTE:
			return state.set("list", payload);
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE;
		}
		default:
			return state;
	}
}
