import React, { PureComponent, Fragment } from "react";
import { string, func } from "prop-types";
import styled from "styled-components";
import immutablePropTypes from "react-immutable-proptypes";
import List from "../../shared/list/list";
import LineSeparator from "../../shared/line-separator/line-separator";
import Badge from "../../shared/badge/badge";

import { DocumentListItem } from "../../documents/document-list/document-list-item";

const StyledList = styled(List)`
	margin-top: ${(props) => (props.renderDropzone ? props.theme.spacing[5] : 0)};
`;

const StyledDocumentItemWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const StyledDocumentListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
`;

export default class DocumentList extends PureComponent {
	static propTypes = {
		attachments: immutablePropTypes.map,
		basePath: string,
		currentUrl: string,
		onDocumentClick: func,
		renderDropzone: func,
		onBadgeClick: func,
		renderDropdown: func,
		agendaItems: immutablePropTypes.list,
		meetingId: string,
	};

	renderBadge = (objId, docId) => {
		const { onBadgeClick, agendaItems } = this.props;
		const agendaItemId = objId.split("$")[1];
		const agendaItem = agendaItems.find(
			(item) => item.get("id") === agendaItemId,
		);
		const proposal = agendaItem?.get("proposal");

		return (
			<Badge
				linkText={proposal}
				onClick={() => onBadgeClick(docId, agendaItemId)}
			>
				{proposal}
			</Badge>
		);
	};

	renderDocument = (objId, attachment, index) => {
		const { basePath, currentUrl, onDocumentClick, renderDropdown } =
			this.props;

		if (!attachment) {
			return null;
		}

		const title = attachment.get("title");
		const date = attachment.get("lastModified");
		const attachmentId = attachment.get("id");
		const to = `${basePath}/${attachmentId}`;
		const active = currentUrl === to;

		const links = attachment.get("links");
		const linkedAttachment = links.find((link) => {
			return link.get("objId") === objId;
		});

		const objType = linkedAttachment.get("objType");

		return (
			<DocumentListItem
				id={attachmentId}
				key={index}
				active={active}
				to={to}
				date={date}
				onDocumentClick={onDocumentClick.bind(null, objId)}
				title={title}
				renderBadge={this.renderBadge.bind(this, objId)}
				renderDropdown={renderDropdown.bind(
					null,
					attachmentId,
					attachment,
					objId,
					objType,
				)}
			/>
		);
	};

	renderGeneralSection = (attachedDocs) => {
		const { renderDropzone, meetingId } = this.props;

		return (
			<StyledDocumentItemWrapper>
				{renderDropzone && renderDropzone()}
				{attachedDocs &&
					attachedDocs.toList().map(this.renderDocument.bind(this, meetingId))}
			</StyledDocumentItemWrapper>
		);
	};

	renderTopicsSection = (attachedDocs) => {
		return (
			<StyledDocumentItemWrapper>
				{attachedDocs
					.map((docs, objId) => (
						<Fragment key={objId}>
							{docs.toList().map(this.renderDocument.bind(this, objId))}
						</Fragment>
					))
					.toList()}
			</StyledDocumentItemWrapper>
		);
	};

	render = () => {
		const { attachments, meetingId, renderDropzone } = this.props;
		const attachmentsInTopics =
			attachments && attachments.filter((docs, objId) => objId !== meetingId);

		return (
			<StyledDocumentListWrapper>
				<StyledList renderDropzone={renderDropzone}>
					{this.renderGeneralSection(attachments.get(meetingId))}
					<LineSeparator
						bgColor="solitudeLight"
						tid="meetings.documents.files_attached_to_topics"
						margin
					/>
					{attachmentsInTopics && this.renderTopicsSection(attachmentsInTopics)}
				</StyledList>
			</StyledDocumentListWrapper>
		);
	};
}
