import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../interfaces/router";
import StartMeetingContainer from "./agenda/start-meeting.container";

class StartMeetingMessageContainer extends Component {
	render() {
		const { meeting, userObj } = this.props;

		if (!meeting || !userObj) {
			return null;
		}

		return <StartMeetingContainer mode="screenMessage" />;
	}
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get("meeting"),
		userObj: store.user.get("userObj"),
	};
};

const mapActionsToProps = {};

export default withRouter(
	connect(mapStoreToProps, mapActionsToProps)(StartMeetingMessageContainer),
);
