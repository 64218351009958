import React, { PureComponent } from "react";
import { number, object } from "prop-types";
import styled from "styled-components";

import ScrollView from "../../layout/scroll-view/scroll-view";

const Wrapper = styled.div`
	display: flex;
	height: 100%;
	padding: ${({ theme }) => theme.spacing[5]} ${({ theme }) => theme.spacing[6]};
	flex-direction: column;
`;

const CenterdHeader = styled.div`
	display: flex;
	margin-bottom: ${({ theme }) => theme.spacing[5]};
`;

const LeftRightWrapper = styled.div`
	display: flex;
	height: 100%;
`;

const Left = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-right: ${({ theme }) => theme.spacing[3]};
`;

const Right = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: ${({ theme }) => theme.spacing[3]};
`;

const Header = styled.div`
	display: flex;
	min-height: ${({ minHeight }) => minHeight}px;
	margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export default class LeftRightWitHeadersLayout extends PureComponent {
	static propTypes = {
		middleHeader: object,
		leftHeader: object,
		rightHeader: object,
		leftContent: object,
		rightContent: object,
		headerMinHeight: number,
	};

	static defaultProps = {
		headerMinHeight: 0,
	};

	render = () => {
		const {
			middleHeader,
			leftHeader,
			rightHeader,
			leftContent,
			rightContent,
			headerMinHeight,
		} = this.props;

		return (
			<Wrapper>
				{middleHeader && <CenterdHeader>{middleHeader}</CenterdHeader>}

				<LeftRightWrapper>
					<Left>
						{leftHeader && (
							<Header minHeight={headerMinHeight}>{leftHeader}</Header>
						)}
						<ScrollView noLeftMargin noRightMargin alwaysShow fillContent>
							{leftContent}
						</ScrollView>
					</Left>

					<Right>
						{rightHeader && (
							<Header minHeight={headerMinHeight}>{rightHeader}</Header>
						)}
						<ScrollView noLeftMargin noRightMargin alwaysShow fillContent>
							{rightContent}
						</ScrollView>
					</Right>
				</LeftRightWrapper>
			</Wrapper>
		);
	};
}
