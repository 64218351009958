import React from "react";
import DropdownMenuContainer from "./generic-dropdown-menu.container";
import DeleteFileItem from "./delete-file.container";
import ManageFileItem from "./manage-file.container";

/**
 * Usage:
	   1. renderAttachmentDropdown={(defaultProps) => {
		  return renderCommonAttachmentDropdown(defaultProps, {
			 onDeleteCallback: (document) => {
				 // DO STUFF
			 }
		 })
	 }}

	 2. renderAttachmentDropdown={renderCommonAttachmentDropdown}
 */

export const renderCommonAttachmentDropdown = (
	{ document, objType, objId, readOnly, mode },
	{ onDeleteCallback } = {},
) => {
	return (
		<DropdownMenuContainer
			readOnly={readOnly}
			renderChildren={({ toggleDropdown }) => {
				return (
					<DeleteFileItem
						document={document}
						objType={objType}
						objId={objId}
						onClick={toggleDropdown}
						onDelete={onDeleteCallback}
						mode={mode}
					/>
				);
			}}
		/>
	);
};

export const renderMeetingAttachmentDropdown = (
	{ document, objType, objId, readOnly },
	{ onDeleteCallback } = {},
) => {
	return (
		<DropdownMenuContainer
			readOnly={readOnly}
			renderChildren={({ toggleDropdown }) => {
				return (
					<>
						<ManageFileItem
							document={document}
							objType={objType}
							objId={objId}
							onClick={toggleDropdown}
						/>
						<DeleteFileItem
							document={document}
							objType={objType}
							objId={objId}
							onClick={toggleDropdown}
							onDelete={onDeleteCallback}
						/>
					</>
				);
			}}
		/>
	);
};
