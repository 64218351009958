import React, { useState } from "react";

const SnackbarContext = React.createContext(null);

export { SnackbarContext };

const SnackbarProvider = (props) => {
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "success",
	});
	return (
		<SnackbarContext.Provider
			value={{
				display: (message, severity = "success") => {
					setSnackbar({ open: true, message, severity });
				},
				clear: () => setSnackbar({ ...snackbar, open: false }),
				isOpen: snackbar.open,
				message: snackbar.message,
				severity: snackbar.severity,
			}}
		>
			{props.children}
		</SnackbarContext.Provider>
	);
};

export default SnackbarProvider;
