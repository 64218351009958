import React, { PureComponent } from "react";
import styled, { css } from "styled-components";
import Grid from "styled-components-grid";
import { func, bool } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import { Padding } from "styled-components-spacing";
import { List, Map } from "immutable";
import ScrollView from "../../shared/layout/scroll-view/scroll-view";
import Label from "../../shared/label/label";
import Input from "../../shared/input/input";
import { Select } from "../../shared/select";
import DummyText from "./dummy-text";
import Tooltip from "../../shared/tooltip/tooltip";

import {
	PDF_LOGO_POSITION_LEFT,
	PDF_LOGO_POSITION_RIGHT,
	PDF_LOGO_POSITION_CENTER,
	PDF_LOGO_SIZE_100,
	PDF_LOGO_SIZE_75,
	PDF_LOGO_SIZE_50,
	PDF_LOGO_SIZE_25,
	PDF_LOGO_DISPLAY_OPTION_SHOW,
	PDF_LOGO_DISPLAY_OPTION_SHOW_ONLY_LOGO,
	PDF_LOGO_DISPLAY_OPTION_HIDE,
} from "/shared/constants";

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	opacity: 1;
	transition: opacity 300ms;

	&.fade-enter {
		opacity: 0;
	}

	&.fade-enter-active {
		opacity: 1;
	}

	&.fade-exit {
		opacity: 1;
	}

	&.fade-exit-active {
		opacity: 0;
	}
`;

const StyledGridContainer = styled.div`
	padding: ${(props) => props.theme.spacing[5]}
		${(props) => props.theme.spacing[6]};

	${(props) =>
		props.noPadLeft &&
		css`
			padding-left: 0;
		`}
`;

const StyledLeftSide = styled.div`
	flex: 2;
	margin-left: ${(props) => props.theme.spacing[6]};
`;

const StyledRightSide = styled.div`
	flex: 1;
	border-left: 1px solid ${(props) => props.theme.colors.solitudeDark};
`;

export default class ProtocolPdfOptionDetails extends PureComponent {
	static propTypes = {
		onChange: func,
		protocolPdfOption: immutablePropTypes.map,
		fontsOptions: immutablePropTypes.list,
		fontWeightVariants: immutablePropTypes.list,
		fontSizes: immutablePropTypes.list,
		litStyleTypeOptions: immutablePropTypes.list,
		hasLogo: bool,
	};

	renderTypographyField = (field, index) => {
		const {
			onChange,
			fontSizes,
			protocolPdfOption,
			fontWeightVariants,
			litStyleTypeOptions,
		} = this.props;
		const optionHasField = protocolPdfOption.hasIn(["settings", field]);
		const fontSizeValue = protocolPdfOption.getIn([
			"settings",
			field,
			"fontSize",
		]);
		const fontWeightValue = protocolPdfOption.getIn([
			"settings",
			field,
			"fontWeight",
		]);
		const listStyleTypeValue = protocolPdfOption.getIn([
			"settings",
			field,
			"listStyleType",
		]);

		if (!optionHasField) {
			return null;
		}

		return (
			<Grid key={index}>
				<Grid.Unit size={1 / 3}>
					<Padding all={3}>
						<Label
							tid={`meetings.protocol_pdf_options.details.${field}.text_size`}
						>
							<Select
								fieldName={`${field}.fontSize`}
								onChange={onChange}
								options={fontSizes}
								value={fontSizeValue}
								maxMenuHeight={index === 6 ? 136 : undefined} // Change maxHeight of last row
								menuPortalTarget={document.body}
							/>
						</Label>
					</Padding>
				</Grid.Unit>
				<Grid.Unit size={1 / 3}>
					<Padding all={3}>
						<Label
							tid={`meetings.protocol_pdf_options.details.${field}.text_weight`}
						>
							<Select
								fieldName={`${field}.fontWeight`}
								onChange={onChange}
								options={fontWeightVariants}
								value={fontWeightValue}
								maxMenuHeight={index === 6 ? 136 : undefined} // Change maxHeight of last row
								menuPortalTarget={document.body}
							/>
						</Label>
					</Padding>
				</Grid.Unit>
				<Grid.Unit size={1 / 3}>
					{listStyleTypeValue && (
						<Padding all={3}>
							<Label
								tid={`meetings.protocol_pdf_options.details.${field}.list_style_type`}
							>
								<Select
									fieldName={`${field}.listStyleType`}
									onChange={onChange}
									options={litStyleTypeOptions}
									value={listStyleTypeValue}
									menuPortalTarget={document.body}
									labelIsTid
								/>
							</Label>
						</Padding>
					)}
				</Grid.Unit>
			</Grid>
		);
	};

	render = () => {
		const { fontsOptions, protocolPdfOption, onChange, hasLogo } = this.props;
		const fontFamilyValue = protocolPdfOption.getIn(["settings", "fontFamily"]);
		const typographyFields = ["h1", "h2", "h3", "h4", "h5", "h6", "body"];
		const logoTooltipTid =
			!hasLogo &&
			"meetings.protocol_pdf_options.details.tooltips.no_logo_uploaded";
		const logoInProtocol =
			protocolPdfOption &&
			protocolPdfOption.getIn(["settings", "logoInProtocol"]);

		const logoPositionOptions = List([
			Map({
				value: PDF_LOGO_POSITION_LEFT,
				label: "meetings.protocol_pdf_options.details.logo_position.left",
			}),
			Map({
				value: PDF_LOGO_POSITION_CENTER,
				label: "meetings.protocol_pdf_options.details.logo_position.center",
			}),
			Map({
				value: PDF_LOGO_POSITION_RIGHT,
				label: "meetings.protocol_pdf_options.details.logo_position.right",
			}),
		]);

		// 100% = 1/2 of A4 paper width = 10.5cm
		const logoSizeOptions = List([
			Map({ value: PDF_LOGO_SIZE_100, label: "100%" }),
			Map({ value: PDF_LOGO_SIZE_75, label: "75%" }),
			Map({ value: PDF_LOGO_SIZE_50, label: "50%" }),
			Map({ value: PDF_LOGO_SIZE_25, label: "25%" }),
		]);

		const logoInProtocolOptions = List([
			Map({
				value: PDF_LOGO_DISPLAY_OPTION_SHOW,
				label:
					"meetings.protocol_pdf_options.details.logo_display.show_with_company_name",
			}),
			Map({
				value: PDF_LOGO_DISPLAY_OPTION_SHOW_ONLY_LOGO,
				label:
					"meetings.protocol_pdf_options.details.logo_display.show_without_company_name",
			}),
			Map({
				value: PDF_LOGO_DISPLAY_OPTION_HIDE,
				label: "meetings.protocol_pdf_options.details.logo_display.hide",
			}),
		]);

		return (
			<StyledWrapper>
				<StyledLeftSide>
					<ScrollView alwaysShow noLeftMargin noRightMargin autoHeightMin={500}>
						<StyledGridContainer noPadLeft>
							<Grid>
								<Grid.Unit size={1}>
									<Padding all={3}>
										<Label tid="meetings.protocol_pdf_options.details.name">
											<Input
												fieldName="name"
												onChange={onChange}
												value={
													protocolPdfOption && protocolPdfOption.get("name")
												}
												disabled
											/>
										</Label>
									</Padding>
								</Grid.Unit>
							</Grid>
							<Grid>
								<Grid.Unit size={2 / 4}>
									<Padding all={3}>
										<Tooltip tid={logoTooltipTid}>
											<Label tid="meetings.protocol_pdf_options.details.inc_logo">
												<Select
													placeholderTid="meetings.protocol_pdf_options.details.inc_logo.placeholder"
													value={logoInProtocol}
													onChange={onChange}
													options={logoInProtocolOptions}
													fieldName="logoInProtocol"
													isDisabled={!hasLogo}
													labelIsTid
												/>
											</Label>
										</Tooltip>
									</Padding>
								</Grid.Unit>
								<Grid.Unit size={1 / 4}>
									<Padding all={3}>
										<Tooltip tid={logoTooltipTid}>
											<Label tid="meetings.protocol_pdf_options.details.logo_position">
												<Select
													fieldName="logoPosition"
													onChange={onChange}
													options={logoPositionOptions}
													value={
														protocolPdfOption &&
														protocolPdfOption.getIn([
															"settings",
															"logoPosition",
														])
													}
													isDisabled={!hasLogo}
													menuPortalTarget={document.body}
													labelIsTid
												/>
											</Label>
										</Tooltip>
									</Padding>
								</Grid.Unit>
								<Grid.Unit size={1 / 4}>
									<Padding all={3}>
										<Tooltip tid={logoTooltipTid}>
											<Label tid="meetings.protocol_pdf_options.details.logo_size">
												<Select
													fieldName="logoSize"
													onChange={onChange}
													options={logoSizeOptions}
													value={
														protocolPdfOption &&
														protocolPdfOption.getIn(["settings", "logoSize"])
													}
													isDisabled={!hasLogo}
													menuPortalTarget={document.body}
												/>
											</Label>
										</Tooltip>
									</Padding>
								</Grid.Unit>
							</Grid>
							<Grid>
								<Grid.Unit size={1}>
									<Padding all={3}>
										<Label tid="meetings.protocol_pdf_options.details.font_family">
											<Select
												fieldName="fontFamily"
												onChange={onChange}
												options={fontsOptions}
												value={fontFamilyValue}
												menuPortalTarget={document.body}
											/>
										</Label>
									</Padding>
								</Grid.Unit>
							</Grid>
							{typographyFields.map(this.renderTypographyField)}
						</StyledGridContainer>
					</ScrollView>
				</StyledLeftSide>

				<StyledRightSide>
					<ScrollView
						alwaysShow={true}
						noLeftMargin
						noRightMargin
						autoHeightMin={500}
					>
						<StyledGridContainer>
							<DummyText
								typography={
									protocolPdfOption && protocolPdfOption.get("settings").toJS()
								}
							/>
						</StyledGridContainer>
					</ScrollView>
				</StyledRightSide>
			</StyledWrapper>
		);
	};
}
