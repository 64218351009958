import React, { PureComponent } from "react";
import {
	func,
	string,
	bool,
	number,
	node,
	object,
	oneOfType,
} from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import { Map } from "immutable";
import { Select } from "../select";
import Text from "../text/text";
import { MEETINGS_DURATION_OPTIONS } from "../../../constants/meetings";

export default class DurationSelect extends PureComponent {
	static propTypes = {
		fieldName: string,
		onChange: func,
		value: oneOfType([number, string]),
		isDisabled: bool,
		additionalOptions: immutablePropTypes.list,
		placeholderTid: string,
		menuPortalTarget: oneOfType([node, object]),
		durationOptions: immutablePropTypes.list,
		isClearable: bool,
	};

	static defaultProps = {
		durationOptions: MEETINGS_DURATION_OPTIONS,
		isClearable: true,
	};

	formatDurationOptionlabel = (option) => {
		if (typeof option.value !== "number") {
			return (
				<span>
					<Text tid={option.label} />
				</span>
			);
		}

		return (
			<span>
				<Text>{option.label}</Text> <Text tid="generic.mins" color="muted" />
			</span>
		);
	};

	onChange = (fieldName, val) => {
		const { onChange } = this.props;
		onChange(fieldName, val?.value || val);
	};

	render = () => {
		const {
			fieldName,
			value,
			isDisabled,
			additionalOptions,
			menuPortalTarget,
			isClearable,
		} = this.props;
		let { durationOptions } = this.props;

		const durations = durationOptions.map((d) => d.get("value"));

		if (value && !durations.includes(value)) {
			durationOptions = durationOptions.push(
				Map({
					value,
					label: value,
				}),
			);
		}

		if (additionalOptions) {
			durationOptions = durationOptions.concat(additionalOptions);
		}

		return (
			<Select
				fieldName={fieldName}
				placeholderTid="duration_select.placeholder"
				value={value}
				options={durationOptions}
				onChange={this.onChange}
				formatOptionLabel={this.formatDurationOptionlabel}
				isDisabled={isDisabled}
				isClearable={isClearable}
				menuPortalTarget={menuPortalTarget}
			/>
		);
	};
}
