import React, { Component } from "react";
import { connect } from "react-redux";
import { func } from "prop-types";
import { map } from "react-immutable-proptypes";

import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";

import { openModal } from "../../../actions/modals.actions";
import { COLLECTIONS_MERGE_PDF_MODAL } from "../../../constants/modals";

const TOOLTIP_STATES = {
	isRoot: {
		tid: "collections.modal.collection.item.ROOT.disabled",
		delayShow: "instant",
	},
	isEmpty: {
		tid: "collections.modal.collection.item.generic.disabled.no_documents",
		delayShow: "instant",
	},
};

class CreatePdfDropdownItemContainer extends Component {
	static propTypes = {
		collection: map.isRequired,
		onClick: func,
	};

	handleCreatePdf = () => {
		const { collection, onClick, openModal } = this.props;

		onClick && onClick(collection);

		openModal(COLLECTIONS_MERGE_PDF_MODAL, { collection });
	};

	getTooltipData = () => {
		const { collection } = this.props;
		const isRoot = collection.get("isRoot");
		const isEmpty =
			!collection.get("documents") || collection.get("documents").size === 0;
		let activeState;

		if (isRoot) {
			activeState = "isRoot";
		} else if (isEmpty) {
			activeState = "isEmpty";
		}

		return { activeState, disabled: Boolean(activeState) };
	};

	render = () => {
		const { activeState, disabled } = this.getTooltipData();

		return (
			<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
				<DropdownIconItem
					icon="faFilePdf"
					tid="collections.modal.collection.dropdown.pdf"
					onClick={this.handleCreatePdf}
					disabled={disabled}
				/>
			</Tooltip>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {
	openModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(CreatePdfDropdownItemContainer);
