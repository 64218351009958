import React, { PureComponent } from "react";
import { node, oneOf, string, number, func, bool } from "prop-types";
import styled, { css } from "styled-components";
import Text from "../text/text";

const StyledBadgeWrapperTemplate = css`
	background: ${(props) => props.theme.colors[props.bgColor]};
	border: 1px solid hsl(var(--border-edge));
	border-radius: ${(props) => props.height / 2}px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: ${(props) => props.height}px;
	min-width: ${(props) => props.minWidth}px;
	transition: all 0.5s ease;
`;

const StyledBadgeTemplate = css`
	color: ${(props) =>
		props.theme.colors[props.textColor]}; /* Needed because of ellipsis */
	padding-left: ${(props) =>
		props.theme.spacing[props.expandedTextInlinePadding]};
	padding-right: ${(props) =>
		props.theme.spacing[props.expandedTextInlinePadding]};
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;

	> span {
		width: 100%;
	}
`;

const StyledBadgeWrapper = styled.div`
	${StyledBadgeWrapperTemplate}
	max-width: ${(props) => props.maxWidth}px;

	${({ marginRight }) =>
		marginRight &&
		css`
			margin-right: ${(props) => props.theme.spacing[4]};
			max-width: ${(props) => props.maxWidth}px;
		`}
`;

const StyledBadge = styled.div`
	${StyledBadgeTemplate}
	text-align: ${(props) => props.textAlign};
	max-width: ${(props) => props.maxWidth}px;
`;

const StyledExpandedBadgeRoot = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	background-color: ${(props) => props.theme.colors.lightestGrey};
	display: none;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	padding-left: ${(props) => props.theme.spacing[4]};

	${({ marginRight }) =>
		marginRight &&
		css`
			padding-right: ${(props) => props.theme.spacing[4]};
		`}
`;

const StyledExpandedBadgeWrapper = styled.div`
	${StyledBadgeWrapperTemplate}
	max-width: none;
	width: 100%;
`;

const StyledExpandedBadge = styled.div`
	${StyledBadgeTemplate}
	font-size: ${(props) => props.theme.fontSizes.default};
	text-align: ${(props) => props.expandedTextAlign};
	direction: ${(props) => props.expandedTextDirection};
	font-weight: 600;
`;

const StyledBadgeRoot = styled.div`
	${(props) =>
		props.hasExpandedText &&
		css`
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			display: flex;
			align-items: center;

			:hover {
				width: 100%;

				${StyledBadge} {
					display: none;
				}

				${StyledExpandedBadgeRoot} {
					display: flex;
					align-items: center;
				}
			}
		`}
`;

const avaibleColors = [
	"java",
	"black",
	"white",
	"lightGrey",
	"midGrey",
	"darkGrey",
	"solitudeLight",
	"solitudeMid",
	"solitudeDark",
	"purpleLight",
	"purpleMid",
	"yellowLight",
	"yellowMid",
	"catalinaBlue",
	"persianRed",
	"limeade",
	"gamboge",
	"red",
	"green",
	"orange",
	"active",
	"dodgerBlue",
	"lightestGrey",
	"muted",
	"disabled",
];

export default class Badge extends PureComponent {
	static propTypes = {
		bgColor: oneOf(avaibleColors),
		children: node,
		height: number,
		maxWidth: number,
		minWidth: number,
		textColor: oneOf(avaibleColors),
		textAlign: oneOf(["right", "left", "center"]),
		expandedTextDirection: oneOf(["ltr", "rtl"]),
		expandedTextAlign: oneOf(["right", "left", "center"]),
		expandedTextInlinePadding: number,
		tid: string,
		expandedText: string,
		onClick: func,
		isStatic: bool,
		onSetRef: func,
		className: string,
		marginRight: bool,
	};

	static defaultProps = {
		bgColor: "solitudeLight",
		height: 32,
		textColor: "midGrey",
		textAlign: "center",
		maxWidth: 128,
		minWidth: 60,
		isStatic: false,
		expandedTextDirection: "rtl",
		expandedTextAlign: "right",
		expandedTextInlinePadding: 3,
		marginRight: true,
	};

	onClick = (e) => {
		const { onClick } = this.props;

		e.stopPropagation();
		onClick && onClick();
	};

	onSetRef = (ref) => {
		const { onSetRef } = this.props;
		onSetRef && onSetRef(ref);
	};

	render = () => {
		const {
			tid,
			textColor,
			textAlign,
			children,
			expandedText,
			bgColor,
			height,
			minWidth,
			maxWidth,
			expandedTextDirection,
			expandedTextAlign,
			expandedTextInlinePadding,
			className,
			marginRight,
		} = this.props;
		const wrapperProps = {
			bgColor,
			height,
			minWidth,
			maxWidth,
			marginRight,
		};
		const badgeProps = {
			textColor,
			textAlign,
			maxWidth,
			expandedTextInlinePadding,
		};
		const expandedBadgeProps = {
			textColor,
			maxWidth,
			expandedTextDirection,
			expandedTextAlign,
			expandedTextInlinePadding,
		};

		return (
			<StyledBadgeRoot
				className={className}
				hasExpandedText={expandedText && expandedText.length > 0}
			>
				<StyledBadgeWrapper {...wrapperProps} ref={this.onSetRef}>
					<StyledBadge {...badgeProps}>
						{tid ? (
							<Text tid={tid} color={textColor} nowrap align={textAlign} />
						) : (
							<Text color={textColor} nowrap align={textAlign}>
								{children}
							</Text>
						)}
					</StyledBadge>
				</StyledBadgeWrapper>
				{expandedText && (
					<StyledExpandedBadgeRoot marginRight={marginRight}>
						<StyledExpandedBadgeWrapper {...wrapperProps}>
							<StyledExpandedBadge
								{...expandedBadgeProps}
								onClick={this.onClick}
							>
								{expandedText}
							</StyledExpandedBadge>
						</StyledExpandedBadgeWrapper>
					</StyledExpandedBadgeRoot>
				)}
			</StyledBadgeRoot>
		);
	};
}
