import React, { useEffect, useState } from "react";
import MUIDialog from "../../../mui-components/dialog/mui-dialog";
import { closeModal } from "../../../actions/modals.actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import { DatePicker, Select } from "mui-rff";
import { fetchCompanyUsers } from "../../../actions/company.actions";
import { fetchRoles } from "../../../actions/user-management.actions";
import {
	setFilterBy,
	fetchDocumentsExtensions,
	fetchFilteredDocuments,
	unsetFilterBy,
} from "../../../actions/documents.actions";
import {
	fetchFilteredSharedDocuments,
	fetchSharingDocumentsExtensions,
} from "../../../actions/documents-sharing.actions";
import { List, Map } from "immutable";
import theme from "../../../../theme";
import { getQuery } from "../../../interfaces/history";
import ClearIcon from "@mui/icons-material/Clear";
import {
	DialogTitle,
	Grid,
	Box,
	Card,
	CardContent,
	Typography,
	MenuItem,
} from "@mui/material";
import MUIIconButton from "@mui/material/IconButton";
import { AdvancedFilterStoreCustoms } from "./advanced-filter-store-customs";
import {
	datePropOptions,
	showOnlyTrashedOptions,
	orderOptions,
	docStatusOptions,
	signedDocOptions,
} from "./advanced-filter-constants";
import ListSubheader from "@mui/material/ListSubheader";

const AdvancedFilterDialog = () => {
	const query = getQuery();
	const isWithinSharedFolder = query.isSharedFromCompanyId ? true : false;
	const isSharedFromCompanyId = query.isSharedFromCompanyId;
	const companyId = useSelector((store) => store.company.company.id);
	const companyMembers = useSelector((store) => store.company.members);
	const teams = useSelector((store) => store.company.userManagement.roles);
	const defaultFilters = useSelector((store) =>
		store.documents.get("defaultFilters"),
	);
	const filterBy = useSelector((store) => store.documents.get("filterBy"));
	const folderId = useSelector((store) =>
		store.folders.get("selectedFolderId"),
	);

	const [teamAndMembers, setTeamAndMembers] = useState([]);
	const [members, setMembers] = useState([]);
	const [documentExtensions, setDocumentExtensions] = useState([]);
	const [initialValues, setInitialValues] = useState([]);
	const [defaultValues, setDefaultValues] = useState({});

	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(fetchCompanyUsers(companyId));
		dispatch(fetchRoles());
	}, [companyId]);

	useEffect(() => {
		if (companyMembers.size > 0 && teams.length > 0) {
			let _teams = teams.filter(
				(team) => team.id !== "aed45ce5-291f-438f-a47a-f547fdecc5ee",
			);
			_teams = _teams.map((team) => {
				return {
					value: team.id,
					label: team.name,
				};
			});

			if (_teams && _teams.length > 0) {
				_teams.unshift({
					value: "category",
					label:
						"documents.filter.permissions.select.dropdown.item.category.teams",
				});
			}

			let _companyMembers = companyMembers.toJS().map((member) => {
				return {
					value: member.id,
					label: member.name,
				};
			});

			_companyMembers = _companyMembers.sort(compareOptions);

			if (_companyMembers && _companyMembers.length > 0) {
				_companyMembers.unshift({
					value: "category",
					label:
						"documents.filter.permissions.select.dropdown.item.category.members",
				});
			}

			const teamsAndMembers = _teams.concat(_companyMembers);

			setTeamAndMembers(teamsAndMembers);
			setMembers(_companyMembers);
		}
	}, [companyMembers, teams]);

	useEffect(() => {
		if (defaultFilters.size > 0) {
			const defaultFilterKeys = Object.keys(defaultFilters.toJS());
			const _defaultFilters = {};
			defaultFilterKeys.forEach((key) => {
				_defaultFilters[key] = defaultFilters.get(key).get(0);
			});

			setDefaultValues(_defaultFilters);
		}
	}, [defaultFilters]);

	useEffect(() => {
		if (filterBy.size > 0) {
			let _filterBy = filterBy.toJS();
			const newFilterBy = {};
			_filterBy = _filterBy.filter((filter) => !filter.isSimpleFilter);

			_filterBy.forEach((filterByValue) => {
				if (
					(filterByValue.source === "docStatus" ||
						filterByValue.source === "permissions") &&
					filterByValue.values[0] === "show_all"
				) {
					return;
				} else {
					newFilterBy[filterByValue.source] = filterByValue.values[0];
				}
			});

			setInitialValues(newFilterBy);
		}
	}, [filterBy]);

	useEffect(() => {
		const _setDocumentExtensions = (documentExtensions) => {
			setDocumentExtensions(
				documentExtensions.map((extension) => ({
					value: extension,
					label: extension,
				})),
			);
		};

		if (isWithinSharedFolder) {
			dispatch(
				fetchSharingDocumentsExtensions(isSharedFromCompanyId, folderId),
			).then(_setDocumentExtensions);
		} else {
			dispatch(fetchDocumentsExtensions(folderId)).then(_setDocumentExtensions);
		}
	}, [folderId]);

	const compareOptions = (a, b) => {
		const aLabel = t(a.label);
		const bLabel = t(b.label);
		if (aLabel < bLabel) {
			return -1;
		}
		if (aLabel > bLabel) {
			return 1;
		}
		return 0;
	};

	const onSubmit = (values) => {
		let valueKeys = Object.keys(values);
		valueKeys = valueKeys.filter((key) => !key.includes("default$"));
		let combinedFilterBy = filterBy.map((filter) => {
			return Map({
				source: filter.get("source"),
				values: List([values[filter.get("source")]]),
				...(filter.get("isSimpleFilter") && {
					isSimpleFilter: filter.get("isSimpleFilter"),
				}),
			});
		});

		valueKeys.forEach((key) => {
			if (key !== "order" && key !== "dateProp") {
				const existingFilter = combinedFilterBy.find(
					(filter) => filter.get("source") === key,
				);
				if (!existingFilter && values[key] !== undefined) {
					combinedFilterBy = combinedFilterBy.push(
						Map({
							source: key,
							values: List([values[key]]),
						}),
					);
				}
			}
		});

		const undefinedFilterValues = [];

		combinedFilterBy.forEach((filter) => {
			if (filter.get("values").get(0) === undefined) {
				undefinedFilterValues.push(filter.get("source"));
			}
		});

		if (undefinedFilterValues.length > 0) {
			dispatch(unsetFilterBy(undefinedFilterValues));
		}

		combinedFilterBy = combinedFilterBy.filter(
			(filter) =>
				filter.get("values").size > 0 &&
				filter.get("values").get(0) !== undefined,
		);

		if (isWithinSharedFolder) {
			dispatch(
				fetchFilteredSharedDocuments(
					isSharedFromCompanyId,
					values.order,
					values.dateProp,
					combinedFilterBy,
					true,
				),
			);
		} else {
			dispatch(
				fetchFilteredDocuments(
					values.order,
					values.dateProp,
					combinedFilterBy,
					folderId,
				),
			);
		}

		dispatch(setFilterBy(combinedFilterBy));
		dispatch(closeModal());
	};

	const renderSelectField = (field, label, options, form) => {
		const sortedOptions =
			field !== "createdBy" && field !== "permissions"
				? options.sort(compareOptions)
				: options;
		const notOrderOrDateProp =
			field !== "order" && field !== "dateProp" && field !== "showOnlyTrashed";
		const fieldHasChangedValue = form.getState().values[field];

		let _options = [];

		sortedOptions.forEach((option) => {
			if (option.value === "category" && field !== "createdBy") {
				_options.push(
					<ListSubheader key={option.label} sx={{ pt: 0.75, pb: 0.75 }}>
						<Typography sx={{ color: "grey.mid" }}>
							{t(option.label)}
						</Typography>
					</ListSubheader>,
				);
			} else if (option.value !== "category") {
				_options.push(
					<MenuItem key={option.value} value={option.value}>
						<Typography
							sx={{
								color:
									defaultValues[field] === option.value ? "success.main" : "",
							}}
						>
							{t(option.label)}
						</Typography>
					</MenuItem>,
				);
			}
		});

		return (
			<Select
				name={field}
				label={label}
				disabled={!sortedOptions.length > 0}
				endAdornment={
					notOrderOrDateProp &&
					fieldHasChangedValue && (
						<MUIIconButton
							onClick={() => {
								form.change(field, undefined);
								form.resetFieldState(field);
							}}
						>
							<ClearIcon />
						</MUIIconButton>
					)
				}
				sx={{
					"& .MuiSelect-iconOutlined": {
						display: fieldHasChangedValue && notOrderOrDateProp ? "none" : "",
					},
				}}
			>
				{_options}
			</Select>
		);
	};

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={initialValues}
			keepDirtyOnReinitialize={true}
			render={({ handleSubmit, form, values }) => {
				return (
					<form onSubmit={handleSubmit}>
						<MUIDialog
							isOpen
							onClose={handleSubmit}
							confirmButton={false}
							closeButtonTid={
								"documents.advanced_filter.modal.footer.btn.cancel"
							}
							tooltipTid={t(
								"documents.advanced_filter.modal.footer.apply_btn.tooltip",
							)}
							maxWidth={"xl"}
							header={
								<DialogTitle
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									{t("documents.advanced_filter.modal.title")}
								</DialogTitle>
							}
						>
							<Grid container>
								<Grid
									item
									xs={8}
									sx={{
										display: "flex",
										flexDirection: "column",
										height: "100%",
										gap: 2,
									}}
								>
									<Card variant="blue">
										<CardContent sx={{ display: "flex", gap: 2 }}>
											<DatePicker
												label={t("object_filter.label.from_date")}
												name={"dateFrom"}
												selected={values.dateFrom}
												selectsStart
												startDate={values.dateFrom}
												endDate={values.dateTo}
												maxDate={values.dateTo}
												inputFormat={t("DATE_FORMAT_LONG_DATE")}
											/>
											<DatePicker
												label={t("object_filter.label.to_date")}
												name={"dateTo"}
												selected={values.dateTo}
												selectsEnd
												startDate={values.dateFrom}
												endDate={values.dateTo}
												minDate={values.dateFrom}
												inputFormat={t("DATE_FORMAT_LONG_DATE")}
											/>
										</CardContent>
									</Card>
									<Card variant="yellow">
										<CardContent>
											<Box
												sx={{
													display: "flex",
													gap: 2,
												}}
											>
												{renderSelectField(
													"dateProp",
													t("documents.filter.label.dateprop"),
													datePropOptions,
													form,
												)}
												{renderSelectField(
													"order",
													t("object_filter.label.order"),
													orderOptions,
													form,
												)}
											</Box>

											<Box sx={{ paddingTop: theme.spacing[4] }}>
												<Box sx={{ display: "flex", gap: 2 }}>
													{renderSelectField(
														"createdBy",
														t("documents.filter.label.created_by"),
														members,
														form,
													)}

													{renderSelectField(
														"permissions",
														t("documents.filter.label.permissions"),
														teamAndMembers,
														form,
													)}
												</Box>
											</Box>

											<Box sx={{ paddingTop: theme.spacing[4] }}>
												<Box
													sx={{
														display: "flex",
														gap: 2,
														flexDirection: "column",
													}}
												>
													<Box sx={{ display: "flex", gap: 2 }}>
														{renderSelectField(
															"docStatus",
															t("generic.status"),
															docStatusOptions,
															form,
														)}

														{renderSelectField(
															"signedDoc",
															t("documents.filter.label.signed"),
															signedDocOptions,
															form,
														)}
													</Box>
													<Box sx={{ display: "flex", gap: 2 }}>
														{renderSelectField(
															"extension",
															t("documents.filter.label.extension"),
															documentExtensions,
															form,
														)}

														{renderSelectField(
															"showOnlyTrashed",
															t("filter.label.show_only_trashed"),
															showOnlyTrashedOptions,
															form,
														)}
													</Box>
												</Box>
											</Box>
										</CardContent>
									</Card>
								</Grid>

								<Grid item xs={4} sx={{ paddingLeft: "32px!important" }}>
									<AdvancedFilterStoreCustoms />
								</Grid>
							</Grid>
						</MUIDialog>
					</form>
				);
			}}
		/>
	);
};

export default AdvancedFilterDialog;
