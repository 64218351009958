import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import { FormattedMessage } from "react-intl";

export default function TreeItemContextMenu({ options }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = !!anchorEl;

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose = (e) => {
		e.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<div>
			<Box sx={{ display: "flex", alignItems: "center", fontSize: "48px" }}>
				<IconButton
					onClick={(e) => {
						e.stopPropagation();
						handleClick(e);
					}}
				>
					<MoreVertIcon />
				</IconButton>
			</Box>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{options.map((o, i) => (
					<MenuItem
						key={i}
						onClick={(e) => {
							e.stopPropagation();
							o.onClick();
							handleClose(e);
						}}
					>
						<ListItemIcon>{o.icon}</ListItemIcon>
						<FormattedMessage id={o.label} />
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
