import { useDispatch } from "react-redux";
import { recoverDocument } from "../../../actions/documents.actions";
import { recoverSharedDocument } from "../../../actions/documents-sharing.actions";
import history from "../../../interfaces/history";
import { map } from "react-immutable-proptypes";
import { func, string } from "prop-types";

const RecoverFileComponentContainer = ({
	document,
	children,
	basePath,
	querystr,
}) => {
	const TOOLTIP_STATES = {};
	const docId = document.get("id");
	const isSharedFromCompanyId = document.get("isSharedFromCompanyId");
	const isSharedFromCompany = Boolean(isSharedFromCompanyId);
	const canDeleteDocument = document.get("ALLOW_DELETE");
	const isDeleted = document.get("isDeleted");
	let activeState;
	let isDisabled = false;

	if (!canDeleteDocument) {
		activeState = "hasNoPermissions";
		isDisabled = true;
	}

	const dispatch = useDispatch();
	const onRecoverDocument = () => {
		if (isSharedFromCompany) {
			dispatch(
				recoverSharedDocument(docId, isSharedFromCompanyId, () => {
					history.push({
						pathname: basePath,
						search: querystr,
					});
				}),
			);
		} else {
			dispatch(
				recoverDocument(docId, () => {
					history.push({
						pathname: basePath,
						search: querystr,
					});
				}),
			);
		}
	};

	if (!isDeleted) {
		return null;
	}

	return children({
		recover: onRecoverDocument,
		TOOLTIP_STATES,
		activeState,
		isDisabled,
	});
};

RecoverFileComponentContainer.propTypes = {
	document: map.isRequired,
	children: func.isRequired,
	basePath: string.isRequired,
	querystr: string,
};

export default RecoverFileComponentContainer;
