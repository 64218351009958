import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../interfaces/router";
import { object, string, bool, func, oneOf, node } from "prop-types";
import { Map, fromJS } from "immutable";

// Actions
import {
	fetchFolder,
	createFolder,
	editFolder,
	deleteFolder,
	updateFolderLocal,
} from "../../actions/folders.actions";
import {
	clearDocument,
	clearDocumentsList,
} from "../../actions/documents.actions";

// Validations
import { isRequired } from "../../modules/validation.module";

// HOC:s
import immutableForm from "../../components/framework/immutable-form";

// Components
import Text from "../../dumb-components/shared/text/text";
import DropdownMenuContainer from "../shared/dropdown-menu.container";
import DropdownItem from "../../dumb-components/shared/dropdown-item/dropdown-item";
import DropdownIconItem from "../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import ShareholderPermissions from "../../dumb-components/documents/shareholder-permissions/shareholder-permissions-documents";
import ObjectPermissionsContainer from "../shared/object-permissions.contrainer";
import ShareFolderControlsContainer from "./share-folder-controls.container";
import Tooltip from "../../dumb-components/shared/tooltip/tooltip";
import Box from "../../dumb-components/shared/layout/box/box";

import ManageFolderModal, {
	StyledColoredContentWrapper,
} from "../../dumb-components/documents/manage-folder/manage-folder-modal";
import ModalItemForm from "../../dumb-components/shared/modal/modal-form/modal-item-form";
import FooterRightControls from "../../dumb-components/shared/modal/footer-right-controls";
import { TransparentButton } from "../../dumb-components/shared/button-v2";

// Helpers
import folderHelper from "../../components/helpers/folder.helper";

// Audit
import {
	LIVE_FOLDER_UPDATE,
	LIVE_FOLDER_DELETE,
} from "../../constants/live-update";

import history from "../../interfaces/history";

const TAB_INFO = "info";
const TAB_PERMISSIONS = "permissions";
const TAB_COMPANY_SHARING = "sharing";

const TABS = [
	{
		tabName: TAB_INFO,
		title: "documents.folder.create_manage.tab.info.title",
	},
	{
		tabName: TAB_PERMISSIONS,
		title: "documents.folder.create_manage.tab.info.permissions",
	},
	{
		tabName: TAB_COMPANY_SHARING,
		title: "documents.folder.create_manage.tab.info.sharing",
	},
];

class FolderDetails extends Component {
	state = {
		activeTab: TAB_INFO,
	};

	static propTypes = {
		folderId: string,
		parentFolderId: string,
		mode: oneOf(["create", "manage"]),
		isOpen: bool,
		onClose: func,
		basePath: string,
		selectedFolderId: string,
		folder: object,
		userMessage: node,
		onSubmit: func,
	};

	static defaultProps = {
		mode: "create",
		isOpen: false,
		folder: Map(),
	};

	componentDidMount = () => {
		const { fetchFolder, folderId } = this.props;

		if (folderId) {
			fetchFolder(folderId);
		}
	};

	componentDidUpdate = (prevProps) => {
		const { fetchFolder, folderId } = this.props;

		if (this.props.folderId && prevProps.folderId !== this.props.folderId) {
			fetchFolder(folderId);
		}

		this.checkLiveUpdateEvents();
	};

	checkLiveUpdateEvents = () => {
		const { audit, currentFolder, fetchFolder } = this.props;
		const currentFolderId = currentFolder?.get("id");
		const FOLDER_UPDATE = audit.get(LIVE_FOLDER_UPDATE);
		const FOLDER_DELETE = audit.get(LIVE_FOLDER_DELETE);

		if (FOLDER_UPDATE && FOLDER_UPDATE.get("r") === true) {
			const objId = FOLDER_UPDATE.get("objId");

			if (currentFolderId === objId) {
				fetchFolder(currentFolderId);
			}
		}

		if (FOLDER_DELETE && FOLDER_DELETE.get("r") === true) {
			const objId = FOLDER_DELETE.get("objId");

			// Edit Folder modal open with deleted task. Close modal
			if (currentFolderId === objId) {
				this.closeModal(true);
			}
		}
	};

	onChange = (field, value) => {
		const { updateFolderLocal, resetErrors, company } = this.props;
		let { folder } = this.props;

		folder = folder || Map();

		if (field === "name" || field === "description") {
			let entry = folder.get(field, Map());
			entry = entry.set(company.region.language.main, value);
			folder = folder.set(field, entry);
		} else {
			folder = folder.set(field, value);
		}

		resetErrors(field);
		updateFolderLocal(folder);
	};

	onSubmit = () => {
		const { mode } = this.props;

		if (mode === "create") {
			return this.createFolder();
		}

		return this.updateFolder();
	};

	createFolder = () => {
		const {
			createFolder,
			parentFolderId,
			basePath,
			clearDocument,
			clearDocumentsList,
			onSubmit,
		} = this.props;
		let folder = this.props.folder || Map();

		folder = folder.set("parent", parentFolderId);

		if (this.folderNameIsValid(folder)) {
			createFolder(
				folder,
				(newFolder) => {
					this.closeModal();
					clearDocument();
					clearDocumentsList();

					if (typeof onSubmit === "function") {
						onSubmit(newFolder.get("id"));
					} else {
						history.push({
							pathname: basePath,
							search: `?folder=${newFolder.get("id")}`,
						});
					}
				},
				this.closeModal,
			);
		}
	};

	updateFolder = () => {
		const { editFolder, folder } = this.props;

		if (this.folderNameIsValid(folder)) {
			editFolder(
				folder,
				() => {
					this.closeModal();
				},
				this.closeModal,
			);
		}
	};

	folderNameIsValid = (folder) => {
		const { validate, validateField, company } = this.props;

		if (
			validate(folder) &&
			validateField(
				folder.getIn(["name", company.region.language.main]),
				"name",
			)
		) {
			return true;
		}

		return false;
	};

	deleteFolder = () => {
		const { folder, deleteFolder, selectedFolderId, basePath } = this.props;

		const cb = () => {
			if (folder.get("id") === selectedFolderId) {
				const parentFolderId = folder.get("parent");
				const goto = { pathname: basePath };

				if (parentFolderId) {
					goto.search = `?folder=${parentFolderId}`;
				}

				history.push(goto);

				clearDocument();
				clearDocumentsList();
				this.closeModal();
			}
		};

		deleteFolder(folder.get("id"), cb, this.closeModal);
	};

	closeModal = (clearFolder = true) => {
		const { onClose, updateFolderLocal, resetErrors } = this.props;

		// Reset any errors so they don't appear upon next modal opening
		resetErrors("name");

		if (clearFolder) {
			updateFolderLocal(null);
		}

		this.setState({ activeTab: TAB_INFO });

		onClose && onClose();
	};

	onTabChange = (tab) => {
		this.setState({ activeTab: tab });
	};

	renderFooterComponent = () => {
		const { mode, folder, mapParentToChildren, documents } = this.props;
		const folderisEmpty =
			folder &&
			folderHelper.folderisEmpty(
				folder.get("id"),
				documents,
				mapParentToChildren,
			);
		const canDeleteFolder = folder && folder.get("ALLOW_DELETE");
		const deleteBtnDisabled = !folderisEmpty || !canDeleteFolder;
		const deletebtnTooltip = !folderisEmpty
			? "documents.folders.delete_folder.tooltip"
			: !canDeleteFolder
			? "tooltip.default.has_no_permissions"
			: undefined;

		if (mode === "create") {
			return (
				<FooterRightControls>
					<TransparentButton
						tid="generic.form.create"
						onClick={this.onSubmit}
					/>
					<TransparentButton
						tid="generic.form.cancel"
						textColor="midGrey"
						onClick={this.closeModal}
					/>
				</FooterRightControls>
			);
		}

		return (
			<FooterRightControls>
				<TransparentButton tid="generic.form.save" onClick={this.onSubmit} />
				<DropdownMenuContainer
					inline
					halignMenu="right"
					renderRaw={
						<TransparentButton
							tid="modal.footer.dropdown_menu.more_options"
							textColor="midGrey"
						/>
					}
					openDirection="up"
				>
					<DropdownIconItem
						icon="faTimes"
						tid="modal.footer.dropdown_menu.cancel_and_close"
						onClick={this.closeModal}
					/>
					<DropdownItem divider />
					<Tooltip
						tid={deletebtnTooltip}
						delayShow="instant"
						active={deleteBtnDisabled}
					>
						<DropdownIconItem
							icon="faTrashAlt"
							tid="documents.folders.delete_folder"
							disabled={deleteBtnDisabled}
							onClick={this.deleteFolder}
						/>
					</Tooltip>
				</DropdownMenuContainer>
			</FooterRightControls>
		);
	};

	renderTabTitle = (titleTid) => {
		return (
			<Box alignItems="center" valign="center">
				<Text tid={titleTid} />
			</Box>
		);
	};

	renderInfoTab = () => {
		const { errors, company } = this.props;
		let { folder } = this.props;

		if (folder) {
			folder = folder.set(
				"name",
				folder.getIn(["name", company.region.language.main]),
			);
			folder = folder.set(
				"description",
				folder.getIn(["description", company.region.language.main]),
			);
		}

		return (
			<StyledColoredContentWrapper type="secondary">
				<ModalItemForm
					item={folder}
					errors={errors}
					onChange={this.onChange}
					nameTid="documents.folder.form.name"
				/>
			</StyledColoredContentWrapper>
		);
	};

	renderPermissionsTab = () => {
		const { folder } = this.props;

		return (
			<StyledColoredContentWrapper type="secondary">
				<ObjectPermissionsContainer
					permissions={folder && folder.get("permissions")}
					onChange={this.onChange}
					type="transparent"
					noTitle={true}
					infoTextTid="documents.create_folder.permissions.team-access.info"
				/>
			</StyledColoredContentWrapper>
		);
	};

	renderSharingTab = () => {
		const { folderId, parentFolderId, folder } = this.props;

		return (
			<>
				<StyledColoredContentWrapper type="secondary">
					<ShareholderPermissions
						onChange={this.onChange}
						isMirrored={folder && folder.get("isMirrored", false)}
						mode="transparent"
						noTitle={true}
						infoTid="documents.manage_folder.share_folder_with_shareholder.info"
						labelTid="documents.manage_folder.shareholder.permissions.label"
						panelType="transparent"
					/>
				</StyledColoredContentWrapper>
				<ShareFolderControlsContainer
					folderId={folderId}
					parentFolderId={parentFolderId}
					onChange={this.onChange}
				/>
				<ShareFolderControlsContainer
					folderId={folderId}
					parentFolderId={parentFolderId}
					onChange={this.onChange}
					listOnly={true}
				/>
			</>
		);
	};

	render = () => {
		const { mode, isOpen, userMessage } = this.props;
		const { activeTab } = this.state;
		const titleTid =
			mode === "create" ? "documents.create.title" : "documents.manage.title";

		return (
			<ManageFolderModal
				isOpen={isOpen}
				titleTid={titleTid}
				footerComponent={this.renderFooterComponent()}
				tabs={TABS}
				activeTab={activeTab}
				onTabChange={this.onTabChange}
			>
				{userMessage}
				{activeTab === TAB_INFO && this.renderInfoTab()}
				{activeTab === TAB_PERMISSIONS && this.renderPermissionsTab()}
				{activeTab === TAB_COMPANY_SHARING && this.renderSharingTab()}
			</ManageFolderModal>
		);
	};
}

function mapStoreToProps(store) {
	return {
		folder: store.folders.get("currentFolder", Map()),
		selectedFolderId: store.folders.get("selectedFolderId"),
		currentFolder: store.folders.get("currentFolder"),
		folders: store.folders.get("folders"),
		mapParentToChildren: store.folders.get("mapParentToChildren", Map()),
		documents: store.documents.get("allDocuments"),
		company: store.company.company,
		i18n: store.i18n,
		audit: store.audit.get("folders"),
	};
}

const mapActionsToProps = {
	fetchFolder,
	createFolder,
	editFolder,
	deleteFolder,
	updateFolderLocal,
	clearDocument,
	clearDocumentsList,
};

const validators = fromJS({
	name: {
		tid: "documents.folder.form.name",
		rules: [{ func: isRequired, message: "validation.is_required" }],
	},
});

const FolderDetailsImmutableForm = immutableForm(
	FolderDetails,
	"folderDetails",
	validators,
);

export default withRouter(
	connect(mapStoreToProps, mapActionsToProps)(FolderDetailsImmutableForm),
);
