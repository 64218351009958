import React from "react";
import { arrayOf, func, shape, string } from "prop-types";

import {
	Select as BaseSelect,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

const Select = ({
	value,
	className,
	onChange,
	options,
	optionRenderer,
	isLoading,
}) => {
	return (
		<BaseSelect disabled={isLoading} value={value} onValueChange={onChange}>
			<SelectTrigger isLoading={isLoading} className={className}>
				<SelectValue />
			</SelectTrigger>
			<SelectContent>
				{options.map((option) => (
					<SelectItem
						key={`${option.label}__${option.value}`}
						value={option.value}
					>
						{optionRenderer(option)}
					</SelectItem>
				))}
			</SelectContent>
		</BaseSelect>
	);
};

Select.propTypes = {
	value: string,
	onChange: func,
	options: arrayOf(shape({ value: string, label: string })),
	optionRenderer: func,
};

Select.defaultProps = {
	options: [],
	optionRenderer: ({ label }) => label,
};

export default Select;
