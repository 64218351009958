import React, { PureComponent } from "react";
import immutablePropTypes from "react-immutable-proptypes";
import { func } from "prop-types";
import { fromJS } from "immutable";
import styled, { css } from "styled-components";
import { Padding } from "styled-components-spacing";
import List from "../../shared/list/list";
import ListItem from "../../shared/list-item/list-item";
import Text from "../../shared/text/text";
import Button from "../../shared/button/button";
import moment from "../../../modules/moment.module";
import { StyledListHeaderTemplate } from "../../shared/list/list-header.template";
import Panel from "../../shared/panel/panel";
import ShowMoreBox from "../../../components/framework/show-more-box";
import { Select } from "../../shared/select";
import Tooltip from "../../shared/tooltip/tooltip";
import { isEllipsisActive } from "../../shared/tooltip/tooltip.helpers";
import { AVAIBLE_TASK_STATUS } from "../../../constants/tasks";
import history from "../../../interfaces/history";
import { SquareCheckBig } from "lucide-react";

const StyledHeader = styled.div`
	${StyledListHeaderTemplate}
	border-left: ${(props) =>
		props.isCanceled
			? `${props.theme.spacing[3]} solid ${props.theme.colors.persianRed}`
			: `${props.theme.spacing[3]} solid transparent`};
`;

const StyledListItem = styled(ListItem)`
	align-items: center;
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeLight};
	margin-bottom: 0px;
	border-left-width: ${(props) => props.theme.spacing[3]};
	border-left-style: solid;
	border-left-color: ${(props) => {
		if (props.overdue) {
			return props.theme.colors.red;
		} else if (props.statusColor) {
			return props.theme.colors[props.statusColor];
		} else {
			return "transparent";
		}
	}};
`;

const EllipsisTemplate = css`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const TemplateIdWidth = css`
	width: 110px;

	@media (max-width: 1000px) {
		display: none;
	}
`;

const TemplateTitleWidth = css`
	${EllipsisTemplate};
	min-width: 100px;
	flex: 1;
`;

const TemplateCreatedDateWidth = css`
	width: 130px;

	@media (max-width: 1200px) {
		display: none;
	}
`;

const TemplateDueDateWidth = css`
	width: 130px;
`;

const TemplateCompanyNameWidth = css`
	${EllipsisTemplate};
	width: 180px;
`;

const TemplateOpenButtonWidthCss = css`
	width: 80px;
	display: flex;
	justify-content: flex-end;
`;

const StyledFilterWrapper = styled.div`
	width: 200px;
`;

// List Items
const StyledId = styled.div`
	${TemplateIdWidth};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledTitle = styled.div`
	${TemplateTitleWidth};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledInfo = styled.div`
	width: 100%;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledTitleWrapper = styled.div`
	${TemplateTitleWidth};
`;

const StyledCreatedAt = styled.div`
	${TemplateCreatedDateWidth};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledDueDate = styled.div`
	${TemplateDueDateWidth};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledCompanyName = styled.div`
	${TemplateCompanyNameWidth};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

const StyledButtonWrapper = styled.div`
	${TemplateOpenButtonWidthCss};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`;

class TaskListByCompanies extends PureComponent {
	constructor() {
		super();
		this.taskElemRefs = {};
	}

	static propTypes = {
		tasks: immutablePropTypes.list,
		pagerController: func,
		onOpenTask: func,
	};

	componentDidMount() {
		window.addEventListener("resize", this.forceRerender);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.forceRerender);
	}

	forceRerender = () => {
		this.forceUpdate();
	};

	isTaskOverdue = (task) => {
		const completed = task ? task.get("status") === "done" : false;
		const delayed =
			task && task.get("dueAt") && !completed
				? moment(task.get("dueAt")).diff(moment(), "days") < 0
				: false;
		return delayed;
	};

	openTask = (task) => {
		const company = task.get("companyInformation");
		const projectId = task.get("projectId");
		const url =
			`/${company.get("urlAlias")}/tasks/${task.get("id")}` +
			(projectId && projectId !== "" ? `?project=${projectId}` : "");
		history.push(url);
	};

	renderHeader = () => {
		return (
			<StyledHeader>
				<StyledId>
					<Text
						tid="dashboard.tasks.header.taskId"
						bold={400}
						color="lightGrey"
					/>
				</StyledId>
				<StyledTitle>
					<Text tid="task" bold={400} color="lightGrey" />
				</StyledTitle>
				<StyledCreatedAt>
					<Text tid="generic.created_on" bold={400} color="lightGrey" />
				</StyledCreatedAt>
				<StyledDueDate>
					<Text tid="generic.due" bold={400} color="lightGrey" />
				</StyledDueDate>
				<StyledCompanyName>
					<Text tid="generic.company" bold={400} color="lightGrey" />
				</StyledCompanyName>
				<StyledButtonWrapper />
			</StyledHeader>
		);
	};

	renderTask = (task, index) => {
		const overdue = this.isTaskOverdue(task);
		const dueDate = task.get("dueAt")
			? moment(task.get("dueAt")).format("ll")
			: "";
		const createdAt = moment(task.get("createdAt")).format("ll");

		const companyName = task.getIn(["companyInformation", "name"]);
		const id = task.get("taskId");
		const title = task.get("title");
		const uid = task.get("id");
		const status = task.get("status");
		const tooltipIsActive = this.taskElemRefs[uid]
			? isEllipsisActive(this.taskElemRefs[uid])
			: false;

		const taskStatusObject = AVAIBLE_TASK_STATUS.find(
			(s) => s.get("value") === status,
		);
		let statusColor = null;

		if (taskStatusObject) {
			statusColor = taskStatusObject.get("statusColor");
		}

		return (
			<StyledListItem
				key={index}
				overdue={overdue}
				statusColor={statusColor}
				hoverColor="lightestGrey"
				striped
			>
				<StyledId>
					<Text>{id}</Text>
				</StyledId>

				<StyledTitle>
					<Tooltip raw={title} active={tooltipIsActive} delayShow="short">
						<StyledTitleWrapper
							ref={(r) => (this.taskElemRefs[uid] = r)}
							key={index}
						>
							<Text singleLine={true}>{title}</Text>
						</StyledTitleWrapper>
					</Tooltip>
				</StyledTitle>

				<StyledCreatedAt>
					<Text singleLine={true}>{createdAt}</Text>
				</StyledCreatedAt>

				<StyledDueDate>
					<Text singleLine={true}>{dueDate}</Text>
				</StyledDueDate>

				<StyledCompanyName>
					<Text singleLine={true}>{companyName} </Text>
				</StyledCompanyName>

				<StyledButtonWrapper>
					<Button
						tid="generic.open"
						onClick={this.openTask.bind(this, task)}
						minWidth="80px"
					/>
				</StyledButtonWrapper>
			</StyledListItem>
		);
	};

	renderEmptyTask = () => {
		return (
			<StyledListItem striped>
				<StyledInfo>
					<Text color="muted" tid="tasks.dashboard.no_available" />
				</StyledInfo>
			</StyledListItem>
		);
	};

	renderDateFilter = () => {
		const { onFilter, selectedFilter } = this.props;
		const filterValues = [
			{ label: "dashboard.block.filter.label.today", value: "today" },
			{ label: "dashboard.block.filter.label.7d", value: "7d" },
			{ label: "dashboard.block.filter.label.30d", value: "30d" },
			{ label: "dashboard.block.filter.label.90d", value: "90d" },
			{ label: "dashboard.block.tasks.label.show_all", value: "show_all" },
			{ label: "dashboard.blocks.tasks.label.overdue", value: "overdue" },
		];

		return (
			<StyledFilterWrapper>
				<Select
					options={fromJS(filterValues)}
					onChange={onFilter && onFilter}
					labelIsTid={true}
					value={selectedFilter}
					isSearchable={false}
				/>
			</StyledFilterWrapper>
		);
	};

	render = () => {
		const { tasks, pagerController } = this.props;

		return (
			<Panel
				icon={<SquareCheckBig />}
				title="service.user_dashboard.tasks"
				mode="light"
				footerComponent={pagerController}
				rightComponent={this.renderDateFilter}
				marginBottom
			>
				<Padding all={4}>
					<List header={this.renderHeader}>
						{tasks && tasks.size > 0 && (
							<ShowMoreBox closedHeight={191} btnMarginTop={32}>
								{tasks.map(this.renderTask)}
							</ShowMoreBox>
						)}
						{(!tasks || tasks.size === 0) && this.renderEmptyTask()}
					</List>
				</Padding>
			</Panel>
		);
	};
}

export default TaskListByCompanies;
