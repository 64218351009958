import { number, oneOfType, string } from "prop-types";
import React, { useRef, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { fetchFinancialStatements } from "../../../../actions/syna.actions";
import {
	columnTypes,
	useFinancialStatementsColumnDefs,
} from "../../../../dumb-components/shared/ag-grid/column-defs/column-defs";
import { Card, CardHeader, CardContent } from "@mui/material";
import Text from "../../../../dumb-components/shared/text/text";
import AgGrid from "../../../../dumb-components/shared/ag-grid/ag-grid";
import { GRID_MODE_PANEL, GRID_MODE_MODAL } from "/shared/constants";
import { useKeyFiguresRowData } from "../../../../dumb-components/shared/ag-grid/transposed-grid/transposed-grid-row-data";
import GridPanelRightButtons from "../../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons";
import ExportExcelButton from "../../../../dumb-components/investment/shareholders/export-excel-button";
import { openModal } from "../../../../actions/modals.actions";
import { GRID_MODAL } from "../../../../constants/modals";

function FinancialStatementGrid({ orgNumber }) {
	const dispatch = useDispatch();
	const gridRef = useRef();
	const colTypes = columnTypes();
	const [colDefs, setColDefs] = useFinancialStatementsColumnDefs();
	const [keyFiguresRowData, setKeyFiguresRowData] = useKeyFiguresRowData();

	const gridOption = "keyFigures";

	const isGridModalOpen = useSelector((state) => {
		return state.modals.getIn(["activeModal", "options"]) === gridOption;
	});

	useEffect(() => {
		dispatch(
			fetchFinancialStatements(orgNumber, (error, financialStatements) => {
				if (error) {
					return;
				}
				setColDefs(financialStatements?.nyckeltalslista);
				setKeyFiguresRowData(financialStatements?.nyckeltalslista);
			}),
		);

		return () => {};
	}, []);

	const exportAsExcel = useCallback(() => {
		gridRef.current.api.exportDataAsExcel({ skipGroups: false });
	});

	const openGridModal = useCallback(() => {
		dispatch(openModal(GRID_MODAL, gridOption));
	});

	const modalLeftHeader = {
		tid: "investments.sharebook.transaction_history_grid.title",
	};

	const onGridReady = (event) => {
		const allColumns = event.columnApi.getAllGridColumns();
		const lastColumn = allColumns[allColumns.length - 1];

		event.api.ensureColumnVisible(lastColumn.colId);
		event.columnApi.resetColumnState();
	};

	if (!keyFiguresRowData || keyFiguresRowData.length === 0) {
		return null;
	}

	return (
		<Grid item xs={12} name="FinancialStatementGrid">
			<Card>
				<CardHeader
					title={<Text tid="investments.general.key_figures.panel_title" />}
					action={
						<GridPanelRightButtons
							exportAsExcel={exportAsExcel}
							openGridModal={openGridModal}
							downloadExcelTid="investments.general.key_figures.download"
							noFilter={true}
						/>
					}
				/>
				<CardContent variant="panel">
					<AgGrid
						mode={isGridModalOpen ? GRID_MODE_MODAL : GRID_MODE_PANEL}
						isGridModalOpen={isGridModalOpen}
						rowData={keyFiguresRowData}
						columnDefs={colDefs}
						rightHeaderComponent={() => {
							return (
								<ExportExcelButton
									exportAsExcel={exportAsExcel}
									noFilter={true}
								/>
							);
						}}
						modalLeftHeader={modalLeftHeader}
						gridRef={gridRef}
						columnTypes={colTypes}
						rowsPerPage={keyFiguresRowData?.length}
						groupDisplayType="groupRows"
						isGroupOpenByDefault={() => true}
						groupRowRenderer="agGroupCellRenderer"
						groupRowRendererParams={{ suppressCount: true }}
						groupedGrid
						onGridReady={onGridReady}
					/>
				</CardContent>
			</Card>
		</Grid>
	);
}

FinancialStatementGrid.propTypes = {
	orgNumber: oneOfType([string, number]),
};

export { FinancialStatementGrid };
