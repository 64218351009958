import { fromJS } from "immutable";

import {
	INVOICES_LIST_INVOICES,
	INVOICES_FETCH_INVOICE,
	COMPANY_RESET_REDUCER,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	invoicesList: null,
	invoice: null,
});

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case INVOICES_LIST_INVOICES:
			return state.set("invoicesList", payload);
		case INVOICES_FETCH_INVOICE:
			return state.set("invoice", payload);
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE;
		}
		default:
			return state;
	}
}
