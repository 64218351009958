import req from "../modules/request.module";
import documentHelper from "../components/helpers/documents.helper";

export function generateExcelFromTemplate(templateId, openInViewer) {
	let w = null;
	if (openInViewer) {
		w = window.open(
			`https://${window.location.hostname}/assets/build/misc/redirecting.html`,
			"_blank",
		);
	}

	return function () {
		return req.post(`/shares/excel/template/${templateId}`).then((response) => {
			let { downloadUrl, fileSize } = response.data;
			if (openInViewer) {
				const { webViewerSupportedFormat, webViewerSupportedSize } =
					documentHelper.getWebViewerIsSupported(undefined, "xlsx", fileSize);
				if (webViewerSupportedFormat && webViewerSupportedSize) {
					downloadUrl = encodeURIComponent(downloadUrl);
					const webViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${downloadUrl}`;
					w.location = webViewerUrl;
				} else {
					w.location = downloadUrl;
				}
			} else {
				window.open(downloadUrl, "_self");
			}
		});
	};
}
