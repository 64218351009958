// NOTE: The point of this file is to break circular dependencies
// when setting up the store. In short, nothing in this file should
// ever under any circumstances import the store.

import meetingHelpers from "/shared/meetings/helpers";
import { fromJS } from "immutable";

export const createAttendeeObject = (attendeeId, isGuest) => {
	const attendee = meetingHelpers.createAttendeeObject(attendeeId, isGuest);

	return fromJS(attendee);
};

export const normalizeAttendeeObject = (attendeeId, attendee, company) => {
	const normalizedAttendee = meetingHelpers.normalizeAttendeeObject(
		attendeeId,
		attendee.toJS(),
		company,
	);

	return fromJS(normalizedAttendee);
};

export const normalizeFeedbackeeObject = (
	feedbackeeId,
	feedbackee,
	active = true,
) => {
	if (!feedbackee.has("active")) {
		feedbackee = feedbackee.set("active", active);
	}

	if (!feedbackee.has("done")) {
		feedbackee = feedbackee.set("done", false);
	}

	return feedbackee;
};
