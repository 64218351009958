import React, { PureComponent } from "react";
import { func, string, bool } from "prop-types";
import styled from "styled-components";
import { map, list } from "react-immutable-proptypes";

import LeftRightWithHeadersLayout from "../../shared/modal/standard-layouts/left-right-with-headers";
import ModernFilePreview from "../../shared/modern-file-preview/modern-file-preview";
import SimpleDocListItem from "../../documents/shared/simple-doc-list-item";
import SimpleDarkInfoPanelWithControls from "../../shared/dark-info-panel/simple-dark-info-panel-with-controls";
import { OutlinedButton } from "../../shared/button-v2";
import DropdownMenuContainer from "../../../containers/shared/dropdown-menu.container";
import DropdownIconItem from "../../shared/dropdown-item/dropdown-icon-item";
import CenteredLoading from "../../shared/modal/centered-loading";
import Text from "../../shared/text/text";
import ColoredContentWrapper from "../../shared/colored-content-wrapper/colored-content-wrapper";
import Label from "../../shared/label/label";
import Input from "../../shared/input/input";
import Button from "../../shared/button/button";

const StyledCenteredLoading = styled(CenteredLoading)`
	min-height: 68px;
	width: 100%;
`;

const StyledMergedDocumentsText = styled(Text)`
	display: flex;
	margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const RightWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const LeftWrapper = styled.div`
	display: flex;
	min-height: 68px;
	width: 100%;
`;

const StyledFolderItem = styled.div`
	display: flex;
	border-top: 1px solid ${({ theme }) => theme.colors.border};
	border-bottom: 1px solid ${({ theme }) => theme.colors.border};
	min-height: 50px;
	margin-bottom: ${({ theme }) => theme.spacing[4]};
	padding: 0 ${({ theme }) => theme.spacing[3]};
	align-items: center;

	> span:first-child {
		padding-right: ${({ theme }) => theme.spacing[4]};
	}
`;

const StyledDocumentNameWrapper = styled(ColoredContentWrapper)`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const StyledNameInfoText = styled(Text)`
	margin-top: ${({ theme }) => theme.spacing[4]};
`;

const StyledModernFilePreview = styled(ModernFilePreview)`
	background: ${({ theme }) => theme.colors.java};
	padding: ${({ theme }) => theme.spacing[4]};
	border-radius: ${({ theme }) => theme.spacing[3]};
`;

const StyledSimpleDarkInfoPanelWithControls = styled(
	SimpleDarkInfoPanelWithControls,
)`
	width: 100%;
`;

const RightHeader = ({ onGoToMoveFile }) => (
	<StyledSimpleDarkInfoPanelWithControls
		tid="collections.merge_pdf.modal.overview.right.move_to_folder.info"
		rightControlsComponent={
			<>
				<OutlinedButton icon="faPlus" onClick={onGoToMoveFile} />
				<DropdownMenuContainer
					halignMenu="right"
					renderRaw={<OutlinedButton icon="faEllipsisV" leftMargin />}
				>
					<DropdownIconItem
						tid="generic.edit"
						icon="faCog"
						onClick={onGoToMoveFile}
					/>
				</DropdownMenuContainer>
			</>
		}
	/>
);

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
`;

export default class MergeOverviewAndSave extends PureComponent {
	static propTypes = {
		mergedDocument: map,
		mergedDocuments: list.isRequired,
		onGoToMoveFile: func.isRequired,
		onOpenMerged: func.isRequired,
		onDownloadMerged: func.isRequired,
		onChangeDocumentName: func.isRequired,
		hasError: map.isRequired,
		folderPath: string,
		mergeFailed: bool,
		hasPasswordProtectedError: bool,
		onRetryMerge: func.isRequired,
		isMergingDocuments: bool,
	};

	renderDocumentItem = (document, index) => {
		return (
			<SimpleDocListItem
				file={document.get("file")}
				title={document.get("title")}
				key={index}
			/>
		);
	};

	renderLeftHeader = () => {
		const { mergedDocument, onOpenMerged, onDownloadMerged, mergeFailed } =
			this.props;

		if (!mergedDocument && !mergeFailed) {
			return <StyledCenteredLoading />;
		}

		if (mergeFailed) {
			return <LeftWrapper />;
		}

		return (
			<StyledModernFilePreview
				document={mergedDocument}
				onOpenFile={onOpenMerged}
				onDownloadFile={onDownloadMerged}
				showFileName={false}
			/>
		);
	};

	renderRightHeader = () => {
		const { mergedDocument, onGoToMoveFile } = this.props;

		if (!mergedDocument) {
			return null;
		}

		return <RightHeader onGoToMoveFile={onGoToMoveFile} />;
	};

	renderLeftContent = () => {
		const { mergedDocuments } = this.props;

		return (
			<>
				<StyledMergedDocumentsText tid="collections.merge_pdf.modal.overview.left.included_documents" />
				{mergedDocuments.map(this.renderDocumentItem)}
			</>
		);
	};

	renderRightContent = () => {
		const {
			mergedDocument,
			onChangeDocumentName,
			hasError,
			folderPath,
			mergeFailed,
			hasPasswordProtectedError,
			onRetryMerge,
			isMergingDocuments,
		} = this.props;
		const title = mergedDocument ? mergedDocument.get("title", "") : "";

		if (!mergedDocument && !mergeFailed) {
			return (
				<RightWrapper>
					<CenteredLoading />
				</RightWrapper>
			);
		}

		if (mergeFailed && !hasPasswordProtectedError) {
			return <RightWrapper />;
		}

		if (mergeFailed && hasPasswordProtectedError) {
			return (
				<RightWrapper>
					<StyledColoredContentWrapper type="secondary">
						<Text asHtml tid="collections.merge.password_protected_pdf.info" />
						<div>
							<Button
								mode="primary"
								onClick={onRetryMerge}
								tid="collections.merge.buttons.retry"
								isLoading={isMergingDocuments}
							/>
						</div>
					</StyledColoredContentWrapper>
				</RightWrapper>
			);
		}

		return (
			<RightWrapper>
				<StyledFolderItem>
					<Text tid="folder" />
					{folderPath ? (
						<Text>{folderPath}</Text>
					) : (
						<Text tid="collections.merge_pdf.modal.overview.folders.root" />
					)}
				</StyledFolderItem>

				<StyledDocumentNameWrapper type="secondary">
					<Label tid="generic.name">
						<Input
							onChange={onChangeDocumentName}
							value={title}
							hasError={hasError.get("title")}
							placeholderTid="collections.merge_pdf.modal.overview.filename.input.placeholder"
						/>
					</Label>
					<StyledNameInfoText tid="collections.merge_pdf.modal.overview.filename.info_1" />
				</StyledDocumentNameWrapper>
			</RightWrapper>
		);
	};

	render = () => {
		return (
			<LeftRightWithHeadersLayout
				leftHeader={this.renderLeftHeader()}
				rightHeader={this.renderRightHeader()}
				leftContent={this.renderLeftContent()}
				rightContent={this.renderRightContent()}
			/>
		);
	};
}
