import React from "react";
import styled, { css } from "styled-components";
import { bool, number, func, string, oneOfType, object } from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { SPACING } from "../../../../../theme";
import { ScrollViewContext } from "./scroll-view.context";

const StyledOuterContainer = styled.div`
	flex: 1;
	display: flex;
	height: 100%;
	flex-direction: column;
`;

const StyledInnerContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	position: relative;
	margin-top: ${(props) =>
		props.contentTopMargin ? props.theme.spacing[props.contentTopMargin] : 0};
	margin-left: ${(props) =>
		props.noLeftMargin ? "0px" : `${props.theme.spacing[3]}`};
	margin-right: ${(props) =>
		props.noRightMargin ? "0px" : `${SPACING.SCROLL_WIDTH}px`};
	${(props) =>
		props.fillContent &&
		css`
			height: 100%;
		`}
`;

const StyledHeaderContainer = styled.div`
	display: flex;
	margin-left: ${(props) =>
		props.noLeftMargin ? "0px" : `${props.theme.spacing[3]}`};
	margin-right: ${(props) =>
		props.noRightMargin ? "0px" : `${SPACING.SCROLL_WIDTH}px`};
	margin-bottom: ${(props) =>
		props.headerBottomMargin
			? props.theme.spacing[props.headerBottomMargin]
			: 0};
`;

const StyledFooterContainer = styled.div`
	display: flex;
	margin-left: ${(props) =>
		props.noLeftMargin ? "0px" : `${props.theme.spacing[3]}`};
	margin-right: ${(props) =>
		props.noRightMargin ? "0px" : `${SPACING.SCROLL_WIDTH}px`};
	height: 40px;
	z-index: 999;
`;

const StyledScrollbarContainer = styled.div`
	flex: 1;
`;

const StyledScrollbars = styled(Scrollbars)`
	${(props) =>
		props.alwaysshowonhover === "true" &&
		css`
			> div:last-child {
				opacity: 1 !important;
			}
		`}

	${(props) =>
		props.scrollbarcolor &&
		css`
			> div:last-child > div {
				background-color: ${(props) =>
					props.theme.colors[props.scrollbarcolor]} !important;
			}
		`};

	/* Display scrollbars on hover */
	${(props) =>
		props.showonhover === "true" &&
		css`
			> div:last-child {
				opacity: 0;
				transition: opacity 200ms ease;
			}

			${StyledScrollbarContainer}:hover & {
				> div:last-child {
					opacity: 1 !important;
				}
			}
		`}

	/* Always display scrollbars when alwaysShow is set to true */
	${(props) =>
		props.alwaysshow === "true" &&
		css`
			> div:last-child {
				opacity: 1 !important;
			}
		`}
`;

const ScrollView = ({
	children,
	header,
	footer,
	noRightMargin,
	noLeftMargin,
	autoHeightMax,
	headerBottomMargin,
	maxHeight,
	autoHeightMin,
	scrollbarRef,
	autoHide,
	fillContent,
	showOnHover,
	scrollbarColor,
	contentTopMargin,
	alwaysShowOnOverflow,
	alwaysShow,
	onSetRef,
	onScroll,
	renderView,
	disabled,
}) => {
	if (disabled) {
		return <>{children}</>;
	}
	const props = {
		autoHide,
		onScroll,
	};

	if (maxHeight || autoHeightMin) {
		props.autoHeight = true;
		// props.autoHeightMax = maxHeight;
	}

	if (maxHeight) {
		props.autoHeightMax = maxHeight;
	}

	if (!maxHeight && autoHeightMax) {
		props.autoHeightMax = autoHeightMax;
	}

	if (autoHeightMin) {
		props.autoHeightMin = autoHeightMin;
	}

	// Console errors fix
	showOnHover = showOnHover ? "true" : "false";
	alwaysShowOnOverflow = alwaysShowOnOverflow ? "true" : "false";
	alwaysShow = alwaysShow ? "true" : "false";

	let ref;
	if (onSetRef) {
		ref = typeof onSetRef === "function" ? (r) => onSetRef(r) : onSetRef;
	}

	const values = { outerContainerRef: ref, scrollbarRef };

	return (
		<ScrollViewContext.Provider value={values}>
			<StyledOuterContainer ref={ref}>
				{header && (
					<StyledHeaderContainer
						noRightMargin={noRightMargin}
						noLeftMargin={noLeftMargin}
						headerBottomMargin={headerBottomMargin}
					>
						{header()}
					</StyledHeaderContainer>
				)}
				<StyledScrollbarContainer>
					<StyledScrollbars
						{...props}
						ref={scrollbarRef}
						showonhover={showOnHover}
						scrollbarcolor={scrollbarColor}
						alwaysshowonhover={alwaysShowOnOverflow}
						alwaysshow={alwaysShow}
						autoHide
						renderView={renderView}
					>
						<StyledInnerContainer
							noRightMargin={noRightMargin}
							noLeftMargin={noLeftMargin}
							fillContent={fillContent}
							contentTopMargin={contentTopMargin}
						>
							{children}
						</StyledInnerContainer>
					</StyledScrollbars>
				</StyledScrollbarContainer>
				{footer && (
					<StyledFooterContainer
						noRightMargin={noRightMargin}
						noLeftMargin={noLeftMargin}
					>
						{footer()}
					</StyledFooterContainer>
				)}
			</StyledOuterContainer>
		</ScrollViewContext.Provider>
	);
};

ScrollView.defaultProps = {
	noRightMargin: false,
	noLeftMargin: false,
	autoHide: false,
	fillContent: false,
	showOnHover: false,
	alwaysShowOnOverflow: false,
	alwaysShow: false,
};

ScrollView.propTypes = {
	noRightMargin: bool,
	noLeftMargin: bool,
	headerBottomMargin: number,
	autoHeightMax: number,
	autoHeightMin: number,
	scrollbarRef: oneOfType([func, object]),
	autoHide: bool,
	fillContent: bool,
	showOnHover: bool,
	scrollbarColor: string,
	contentTopMargin: number,
	alwaysShowOnOverflow: bool,
	alwaysShow: bool,
	onSetRef: oneOfType([func, object]),
	onScroll: func,
	renderView: func,
	disabled: bool,
};

export default ScrollView;
