import React, { Component } from "react";
import { connect } from "react-redux";
import { func } from "prop-types";
import { Map, List } from "immutable";
import { fetchRoles } from "../../actions/user-management.actions";
import { validateEmail } from "../../modules/validation.module";

import UserCreateForm from "../../dumb-components/user-management/user-create-form/user-create-form";

class UserCreateFormContainer extends Component {
	state = {
		user: Map({ userRoles: List(["dede9e47-dd3c-4510-97d6-973a6ad7ddac"]) }),
		hasError: Map(),
		rolesOptions: List(),
		userRolesLoading: false,
	};

	static propTypes = {
		onSetValidateFunction: func,
		onUserChanged: func,
	};

	componentDidMount = () => {
		const { onSetValidateFunction } = this.props;

		this.fetchUserRoles();

		// Bind validation function from here to parent
		onSetValidateFunction && onSetValidateFunction(this.validate);
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { roles, onUserChanged } = this.props;
		const { user } = this.state;

		// Parse user roles
		if (roles !== prevProps.roles) {
			let rolesOptions = List();

			roles.forEach((role) => {
				rolesOptions = rolesOptions.push(
					Map({
						label: role.name,
						value: role.id,
					}),
				);
			});

			this.setState({ rolesOptions });
		}

		// Let parent know if user was updated
		if (onUserChanged && user !== prevState.user) {
			onUserChanged(user);
		}
	};

	fetchUserRoles = () => {
		const { fetchRoles } = this.props;

		this.setState({ userRolesLoading: true });

		fetchRoles(() => {
			this.setState({ userRolesLoading: false });
		});
	};

	onChange = (fieldName, value) => {
		let { user, hasError } = this.state;
		user = user.set(fieldName, value);

		this.setState({ user });

		if (hasError.get(fieldName)) {
			hasError = hasError.delete(fieldName);
			this.setState({ hasError });
		}
	};

	validate = () => {
		const { user } = this.state;
		let { hasError } = this.state;
		let isValid = true;

		if (user.get("name", "").trim().length === 0) {
			hasError = hasError.set(
				"name",
				List(["user_management.member.add.validation.name"]),
			);
			isValid = false;
		}

		if (!validateEmail(user.get("email"))) {
			hasError = hasError.set(
				"email",
				List(["user_management.member.add.validation.email"]),
			);
			isValid = false;
		}

		if (!isValid) {
			this.setState({ hasError });
		}

		return isValid;
	};

	render = () => {
		const { user, rolesOptions, hasError, userRolesLoading } = this.state;

		return (
			<UserCreateForm
				user={user}
				rolesOptions={rolesOptions}
				hasError={hasError}
				userRolesLoading={userRolesLoading}
				onChange={this.onChange}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		roles: store.company.userManagement.roles,
	};
};

const mapActionsToProps = {
	fetchRoles,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(UserCreateFormContainer);
