import React, { PureComponent } from "react";
import { func, number, string } from "prop-types";
import styled from "styled-components";
import { list, map } from "react-immutable-proptypes";
import Moment from "../../../modules/moment.module";

import LeftRightWitHeadersLayout from "../../shared/modal/standard-layouts/left-right-with-headers";
import SimpleDocListItem from "../../documents/shared/simple-doc-list-item";
import { OutlinedButton } from "../../shared/button-v2";

import Text from "../../shared/text/text";
import SimpleDarkInfoPanelWithControls from "../../shared/dark-info-panel/simple-dark-info-panel-with-controls";
import DropdownIconItem from "../../shared/dropdown-item/dropdown-icon-item";
import DropdownMenuContainer from "../../../containers/shared/dropdown-menu.container";
import ColoredContentWrapper from "../../shared/colored-content-wrapper/colored-content-wrapper";
import DatePicker from "../../shared/datepicker/datepicker";
import Label from "../../shared/label/label";
import NumberOfUsersToNotify from "../shared/number-of-users-to-notify-list-item";

const StyledRightWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledDateWrapper = styled(ColoredContentWrapper)`
	display: flex;
	flex-direction: column;

	> span:first-child {
		margin-top: ${({ theme }) => theme.spacing[1]};
	}
`;

const StyledDateInfoText = styled(Text)`
	margin-top: ${({ theme }) => theme.spacing[4]};
`;

const LeftHeader = () => (
	<Text tid="collections.share_documents.modal.overview.left.info" />
);

const RightHeader = ({ onGoToSelectPeople }) => (
	<SimpleDarkInfoPanelWithControls
		tid="collections.share_documents.modal.overview.right.info"
		rightControlsComponent={
			<>
				<OutlinedButton icon="faPlus" onClick={onGoToSelectPeople} />
				<DropdownMenuContainer
					halignMenu="right"
					renderRaw={<OutlinedButton icon="faEllipsisV" leftMargin />}
				>
					<DropdownIconItem
						tid="generic.edit"
						icon="faCog"
						onClick={onGoToSelectPeople}
					/>
				</DropdownMenuContainer>
			</>
		}
	/>
);

export default class ShareOverviewAndSaveView extends PureComponent {
	static propTypes = {
		documentsToBeShared: list.isRequired,
		onGoToSelectPeople: func.isRequired,
		numberOfPeopleSharedWith: number.isRequired,
		hasError: map.isRequired,
		userlang: string.isRequired,
		expiresAt: string,
		onChange: func.isRequired,
	};

	handleCalendarBlur = () => {
		this.forceUpdate();
	};

	renderDocumentItem = (document, index) => {
		return (
			<SimpleDocListItem
				file={document.get("file")}
				title={document.get("title")}
				key={index}
			/>
		);
	};

	renderLeftContent = () => {
		const { documentsToBeShared } = this.props;
		return documentsToBeShared.map(this.renderDocumentItem);
	};

	renderRightContent = () => {
		const {
			numberOfPeopleSharedWith,
			hasError,
			userlang,
			expiresAt,
			onChange,
		} = this.props;
		const expiresAtError = hasError.getIn(["expiresAt", 0]);
		const now = Moment();
		const expiresAtIsToday = Moment(expiresAt).isSame(now, "day");

		return (
			<StyledRightWrapper>
				<NumberOfUsersToNotify
					fieldName="usersToShareWith"
					hasError={hasError}
					numberOfPeople={numberOfPeopleSharedWith}
				/>

				<StyledDateWrapper type="secondary">
					<Label tid="collections.share_documents.modal.overview.right.label.expiry_date">
						<DatePicker
							fieldName="expiresAt"
							onChange={onChange}
							showTimeSelect={true}
							value={expiresAt ? Moment(expiresAt) : undefined}
							language={userlang}
							hasError={Boolean(expiresAtError)}
							minDate={now.subtract(1, "days").toISOString()}
							minTime={expiresAtIsToday ? now : undefined}
							calendarPlacement="bottom-start"
							onBlur={this.handleCalendarBlur}
						/>
					</Label>
					{expiresAtError && <Text tid={expiresAtError} color="persianRed" />}
					<StyledDateInfoText tid="collections.share_documents.modal.overview.right.date.info" />
				</StyledDateWrapper>
			</StyledRightWrapper>
		);
	};

	render = () => {
		const { onGoToSelectPeople } = this.props;

		return (
			<LeftRightWitHeadersLayout
				leftHeader={<LeftHeader />}
				rightHeader={<RightHeader onGoToSelectPeople={onGoToSelectPeople} />}
				leftContent={this.renderLeftContent()}
				rightContent={this.renderRightContent()}
			/>
		);
	};
}
