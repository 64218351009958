import React, { PureComponent } from "react";
import styled from "styled-components";

const unit = "px";
const lineHeightRatio = 1.5;

const StyledDummyTextContainer = styled.div`
	font-family: ${(props) =>
		props.fontFamily.includes(" ")
			? `'${props.fontFamily}'`
			: props.fontFamily};
`;

const StyledH1 = styled.h1`
	font-size: ${(props) => `${props.h1.fontSize}${unit}`};
	line-height: ${(props) => `${props.h1.fontSize * lineHeightRatio}${unit}`};
	margin: 0 0 16px;
	font-weight: ${(props) =>
		props.h1.fontWeight === "regular" ? "normal" : props.h1.fontWeight};
`;

const StyledH2 = styled.h2`
	font-size: ${(props) => `${props.h2.fontSize}${unit}`};
	line-height: ${(props) => `${props.h2.fontSize * lineHeightRatio}${unit}`};
	margin: 0 0 8px;
	font-weight: ${(props) =>
		props.h2.fontWeight === "regular" ? "normal" : props.h2.fontWeight};
`;

const StyledH3 = styled.h3`
	font-size: ${(props) => `${props.h3.fontSize}${unit}`};
	line-height: ${(props) => `${props.h3.fontSize * lineHeightRatio}${unit}`};
	margin: 0 0 8px;
	font-weight: ${(props) =>
		props.h3.fontWeight === "regular" ? "normal" : props.h3.fontWeight};
`;

const StyledP = styled.p`
	font-size: ${(props) => `${props.body.fontSize}${unit}`};
	line-height: ${(props) => `${props.body.fontSize * lineHeightRatio}${unit}`};
	font-weight: ${(props) =>
		props.body.fontWeight === "regular" ? "normal" : props.body.fontWeight};
	margin: 0 0 8px;
`;

const StyledUl = styled.ul`
	margin: 0;
	padding: 0;
`;

const StyledMainTopic = styled.li`
	list-style-type: ${(props) => props.h2.listStyleType};
	font-size: ${(props) => `${props.h2.fontSize}${unit}`};
	line-height: ${(props) => `${props.h2.fontSize * lineHeightRatio}${unit}`};
	font-weight: ${(props) =>
		props.h2.fontWeight === "regular" ? "normal" : props.h2.fontWeight};
`;

const StyledSubTopic = styled.li`
	list-style-type: ${(props) => props.h3.listStyleType};
	font-size: ${(props) => `${props.h3.fontSize}${unit}`};
	line-height: ${(props) => `${props.h3.fontSize * lineHeightRatio}${unit}`};
	font-weight: ${(props) =>
		props.h3.fontWeight === "regular" ? "normal" : props.h3.fontWeight};
`;

export default class DummyText extends PureComponent {
	render = () => {
		const typography = this.props.typography || {};

		return (
			<StyledDummyTextContainer fontFamily={typography.fontFamily}>
				<StyledH1 h1={typography.h1}>
					H1. The quick brown fox jumps over the lazy dog
				</StyledH1>

				<StyledUl>
					<StyledMainTopic h2={typography.h2}>
						<StyledH2 h2={typography.h2}>
							H2. The quick brown fox jumps over the lazy dog.
						</StyledH2>
						<StyledP body={typography.body}>
							PARAGRAPH. The quick brown fox jumps over the lazy dog. The quick
							brown fox jumps over the lazy dog. The quick brown fox jumps over
							the lazy dog. The quick brown fox jumps over the lazy dog. The
							quick brown fox jumps over the lazy dog. The quick brown fox jumps
							over the lazy dog. The quick brown fox jumps over the lazy dog.
						</StyledP>
					</StyledMainTopic>
				</StyledUl>

				<StyledUl>
					<StyledSubTopic h3={typography.h3}>
						<StyledH3 h3={typography.h3}>
							H3. The quick brown fox jumps over the lazy dog.
						</StyledH3>
						<StyledP body={typography.body}>
							PARAGRAPH. The quick brown fox jumps over the lazy dog. The quick
							brown fox jumps over the lazy dog. The quick brown fox jumps over
							the lazy dog. The quick brown fox jumps over the lazy dog. The
							quick brown fox jumps over the lazy dog. The quick brown fox jumps
							over the lazy dog. The quick brown fox jumps over the lazy dog.
						</StyledP>
					</StyledSubTopic>
				</StyledUl>
			</StyledDummyTextContainer>
		);
	};
}
