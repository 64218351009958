import React, { PureComponent } from "react";
import { bool, oneOf, object, array } from "prop-types";
import styled from "styled-components";

import Modal from "../shared/modal/modal";
import StepsModal from "../shared/modal/steps-modal";

const StyledHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding-right: 5px;
`;

const StyledBody = styled.div`
	height: 100%;
`;

export default class NotificationModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		steps: array,
		align: oneOf(["left", "center", "right"]),
		dropdownComponent: object,
		mode: oneOf(["normal", "steps"]),
	};

	static defaultProps = {
		isOpen: true,
		align: "center",
		mode: "normal",
	};

	renderDropdownComponent = () => {
		const { dropdownComponent } = this.props;

		return (
			<>
				{dropdownComponent && <StyledHeader>{dropdownComponent}</StyledHeader>}
			</>
		);
	};

	renderStepsModal = () => {
		const { isOpen, steps, step } = this.props;

		return (
			<StepsModal
				isOpen={isOpen}
				steps={steps}
				step={step}
				hSize="xl"
				noHeaderRightPadding={true}
				headerRightRenderer={this.renderDropdownComponent}
			/>
		);
	};

	renderNormalModal = () => {
		const { isOpen, align, children } = this.props;
		const hSize = align === "right" || align === "left" ? "md" : "xl";

		const modalProps = {
			scrollableContent: false,
			noHeaderRightPadding: true,
			noBodyMargin: true,
			vSize: 100,
			isOpen,
			hSize,
			align,
		};

		return (
			<Modal {...modalProps} headerRightRenderer={this.renderDropdownComponent}>
				<StyledBody>{children}</StyledBody>
			</Modal>
		);
	};

	render = () => {
		const { mode } = this.props;

		if (mode === "steps") {
			return this.renderStepsModal();
		}

		if (mode === "normal") {
			return this.renderNormalModal();
		}

		return null;
	};
}
