import React, { Component } from "react";
import { connect } from "react-redux";
import { oneOf, number, string } from "prop-types";
import {
	saveCompanyLogo,
	deleteCompanyLogo,
} from "../../../actions/company.actions";
import { Padding } from "styled-components-spacing";

import Panel from "../../../dumb-components/shared/panel/panel";
import CompanyImageCrop from "../../../components/user/services/image-crop";

class CompanyLogoContainer extends Component {
	static propTypes = {
		type: oneOf(["main", "thumbnail"]).isRequired,
		aspectRatio: number,
		title: string,
		uploadDesc: string,
	};

	static defaultProps = {
		aspectRatio: 0,
		title: "company.profile.logo",
		uploadDesc: "upload.company.logo.description",
	};

	submitCompanyLogo = (obj) => {
		const { saveCompanyLogo, companyId, type } = this.props;
		saveCompanyLogo(companyId, obj, type);
	};

	onDeleteCompanyLogo = () => {
		const { deleteCompanyLogo, companyId, type } = this.props;
		deleteCompanyLogo(companyId, type);
	};

	render = () => {
		const { image, type, aspectRatio, title, uploadDesc, companyId } =
			this.props;
		const companyImagePreview =
			image && image[type]
				? `/api/companies/public/images/${image[type].filename}`
				: null;
		const crop = image && image[type] ? image[type].crop : null;

		return (
			<Panel title={title} marginBottom>
				<Padding all={4}>
					<CompanyImageCrop
						currentImageUrl={companyImagePreview}
						crop={crop}
						noSelection={aspectRatio !== 1}
						isWide={true}
						companyId={companyId}
						imageSuffix="-250"
						aspectRatio={aspectRatio}
						uploadDesc={uploadDesc}
						onChange={this.submitCompanyLogo}
						onDelete={this.onDeleteCompanyLogo}
					/>
				</Padding>
			</Panel>
		);
	};
}

const mapStoreToProps = (store) => {
	const { company } = store.company;

	return {
		companyId: company && company.id,
		image: company && company.image,
	};
};

const mapActionsToProps = {
	saveCompanyLogo,
	deleteCompanyLogo,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(CompanyLogoContainer);
