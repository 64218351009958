import React, { PureComponent } from "react";
import { bool, object, func } from "prop-types";
import styled from "styled-components";
import Modal from "../../shared/modal/modal";
import FooterRightControls from "../../shared/modal/footer-right-controls";
import { TransparentButton } from "../../shared/button-v2";
import ColoredContentWrapper from "../../shared/colored-content-wrapper/colored-content-wrapper";
import SimpleDarkInfoPanel from "../../shared/dark-info-panel/simple-dark-info-panel";
import Text from "../../shared/text/text";

const StyledErrorMessageWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const StyledInfoPanel = styled(SimpleDarkInfoPanel)`
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

const StyledRowTextWrapper = styled.div`
	flex-direction: row;
	padding-top: ${(props) => props.theme.spacing[3]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

const StyledErrorList = styled.ul`
	margin-bottom: 0;
`;

export default class ErrorMessageModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		uploadTemplate: func,
		onDecline: func,
		onAccept: func,
		errorData: object,
	};

	renderErrorMessage = (error) => {
		return (
			<li>
				<Text tid={error} asHtml />
			</li>
		);
	};

	renderRow = (row) => {
		const rowNumber = row.get("row");
		const errors = row.get("errors");

		return (
			<StyledRowTextWrapper>
				<Text
					tid="shareholders.template_upload.modal.error_message.on_row"
					values={{ rowNumber }}
					bold={600}
				/>
				<StyledErrorList>{errors.map(this.renderErrorMessage)}</StyledErrorList>
			</StyledRowTextWrapper>
		);
	};

	renderErrorData = (errorData, index) => {
		const rows = errorData.get("rows");
		const sheetName = errorData.get("sheet");
		const error = errorData.get("e");

		return (
			<StyledColoredContentWrapper type="secondary">
				{sheetName && (
					<Text
						key={index}
						tid="shareholders.template_upload.modal.error_message.on_sheet"
						values={{ sheetName }}
						bold={600}
					/>
				)}
				{rows ? rows.map(this.renderRow) : this.renderErrorMessage(error)}
			</StyledColoredContentWrapper>
		);
	};

	renderFooterButtons = () => {
		const { onDecline, onAccept } = this.props;

		return (
			<FooterRightControls>
				<TransparentButton
					tid="shareholders.template_upload.modal.error_message.footer.btn.upload_again"
					onClick={onAccept}
				/>
				<TransparentButton
					tid="shareholders.template_upload.modal.error_message.footer.btn.close"
					textColor="midGrey"
					onClick={onDecline}
				/>
			</FooterRightControls>
		);
	};

	render = () => {
		const { isOpen, errorData } = this.props;

		return (
			<Modal
				title="shareholders.template_upload.modal.error_message.title"
				isOpen={isOpen}
				footerComponent={this.renderFooterButtons()}
				hSize="md"
				vSize={100}
			>
				<StyledErrorMessageWrapper>
					<StyledInfoPanel>
						{/*<StyledIconWrapper>
							<Icon icon='faExclamationTriangle' size='normal' color='yellowMid' type='solid' />
						</StyledIconWrapper>*/}
						<Text
							tid="shareholders.template_upload.modal.error_message.info"
							color="white"
						/>
					</StyledInfoPanel>
					<div>{errorData.map(this.renderErrorData)}</div>
				</StyledErrorMessageWrapper>
			</Modal>
		);
	};
}
