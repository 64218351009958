import { convertFromHTML, ContentState, convertToRaw } from "draft-js";

export const convertBodyHTMLToDraftJsInArray = (templatesArray) => {
	return templatesArray.map((template) => {
		let templateBody = template.get("body");

		const blocksFromHTML = convertFromHTML(templateBody);
		const contentState = ContentState.createFromBlockArray(
			blocksFromHTML.contentBlocks,
			blocksFromHTML.entityMap,
		);

		return template.set("body", convertToRaw(contentState));
	});
};
