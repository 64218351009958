import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { Map } from "immutable";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";

import { NUMBER_FORMAT_INTEGER } from "/shared/constants";

class TransferOfSharesView extends Component {
	componentDidMount() {}

	shouldComponentUpdate(nextProps) {
		const { transaction, investmentIdFrom, sequences } = this.props;
		let shouldUpdate = false;

		if (nextProps.transaction !== transaction) {
			shouldUpdate = true;
		}

		if (nextProps.investmentIdFrom !== investmentIdFrom) {
			shouldUpdate = true;
		}

		if (nextProps.sequences !== sequences) {
			shouldUpdate = true;
		}

		return shouldUpdate;
	}

	getShareTypeName(type) {
		const { i18n } = this.props;
		const [shareClass, shareType] = type.get("type").split("$");
		return (
			(i18n.messages[`share_class.${shareClass}`] ||
				decodeURIComponent(shareClass)) +
			" " +
			(i18n.messages[`share_type.${shareType}`] ||
				decodeURIComponent(shareType))
		);
	}

	calculateNumOfSharesAfter(shareType, amountBefore) {
		const { sequences } = this.props;
		const type = shareType.get("type");
		let amount = 0;

		sequences &&
			sequences.forEach((val) => {
				if (val && val.get("shareType") === type) {
					let data = val.get("data");
					if (data) {
						data = data.toString();
						data = data.replace(/\s/g, "");
						const dataArr = data.split(",");

						dataArr.forEach((d) => {
							let [from, to] = d.split("-");
							from = parseInt(from);
							to = parseInt(to);
							amount += to - from + 1;
						});
					}
				}
			});
		return amountBefore - amount;
	}

	renderShareType(shareType, index) {
		const name = this.getShareTypeName(shareType);
		const amountBefore = shareType.get("shares");
		const amountAfter = this.calculateNumOfSharesAfter(shareType, amountBefore);

		if (amountBefore <= 0) {
			return null;
		}

		return (
			<div className="list__item" key={index}>
				<span className="list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left">
					{name}
				</span>
				<span className="list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right">
					{localeFormatNumber(amountBefore, NUMBER_FORMAT_INTEGER)}
				</span>
				<span className="list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right">
					{amountAfter &&
						localeFormatNumber(amountAfter, NUMBER_FORMAT_INTEGER)}
				</span>
			</div>
		);
	}

	getInvestor = () => {
		const { investors, investmentIdFrom } = this.props;
		if (investors && investmentIdFrom) {
			return investors.find((inv) => {
				return inv.get("id") === investmentIdFrom;
			});
		}
		return Map({});
	};

	getShareTypes = () => {
		const { transaction, investmentIdFrom } = this.props;
		if (transaction && investmentIdFrom) {
			return transaction.getIn(["balances", investmentIdFrom, "types"]);
		}
		return null;
	};

	render() {
		const { investmentIdFrom } = this.props;
		const investor = this.getInvestor();
		const shareTypes = this.getShareTypes();

		return (
			<div className="i-panel i-panel--white">
				<div className="i-panel__body i-panel__body i-panel__body--pad-hor">
					<div className="i-panel__heading--transaction-accordio i-panel__heading--pad-hor-0">
						{investmentIdFrom && (
							<h2 className="i-panel__title">
								{investor.getIn(["investorInformation", "name"])}{" "}
								<span className="text--muted">
									{investor.getIn(["investorInformation", "id"])}
								</span>
							</h2>
						)}
						{!investmentIdFrom && (
							<h2 className="i-panel__title">
								<FormattedMessage id="none_selected" />
							</h2>
						)}
					</div>
					<div className="i-panel__body i-panel__body i-panel__body--pad-btm-0">
						<div className="list__list-header">
							<div className="list__list-header__text-area list__list-header__text-area--x3 list__list-header__text-area--pad-lft list__list-header__text-area--pad-right">
								<FormattedMessage id="shares.class_of_shares" />
							</div>
							<div className="list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right">
								<FormattedMessage id="amount_before" />
							</div>
							<div className="list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right">
								<FormattedMessage id="amount_after" />
							</div>
						</div>
						<div className="list list--table list--striped">
							{shareTypes && shareTypes.map(this.renderShareType.bind(this))}
							{!shareTypes && (
								<div className="list__item">
									<div className="list__item__body">&nbsp;</div>
								</div>
							)}
						</div>
					</div>
					<FormattedHTMLMessage id="shares.transactions.transfer_of_shares.information" />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get("transaction"),
		investors: state.investors.get("list"),
		i18n: state.i18n,
		investmentIdFrom: state.transaction.getIn([
			"tmpTransaction",
			"TRANSFER-2",
			"handlerData",
			"investmentIdFrom",
		]),
		sequences: state.transaction.getIn([
			"tmpTransaction",
			"TRANSFER-2",
			"handlerData",
			"sequences",
		]),
	};
}

const mapActionsToProps = {};

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(TransferOfSharesView);
