import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";

import ConfirmContainer from "../../shared/confirm.container";

import { deleteVoting } from "../../../actions/votings.actions";
import { closeModal } from "../../../actions/modals.actions";

import { MEETINGS_VOTING_DELETE_CONFIRM_MODAL } from "../../../constants/modals";
import { MEETING_VOTING_COMPLETED } from "/shared/constants";

class ConfirmDeleteVotingContainer extends Component {
	state = {
		isLoading: false,
	};

	deleteVoting = () => {
		const { deleteVoting, meetingId, votingToBeDeleted } = this.props;
		const votingId = votingToBeDeleted.get("id");

		this.setState({ isLoading: true });

		deleteVoting(meetingId, votingId, () => {
			this.closeModal();
		});
	};

	closeModal = () => {
		const { closeModal } = this.props;
		this.setState({ isLoading: false });
		closeModal();
	};

	getTids = () => {
		const { votingToBeDeleted } = this.props;
		const votingIsCompleted =
			votingToBeDeleted.get("status") === MEETING_VOTING_COMPLETED;
		let titleTid = "meetings.voting.confirm_delete.title.delete_voting";
		let questionTid = "meetings.voting.confirm_delete.info.delete_voting";

		if (votingIsCompleted) {
			titleTid =
				"meetings.voting.confirm_delete.title.delete_voting_and_result";
			questionTid =
				"meetings.voting.confirm_delete.info.delete_voting_and_result";
		}

		return { titleTid, questionTid };
	};

	render = () => {
		const { isOpen } = this.props;
		const { isLoading } = this.state;

		if (!isOpen) {
			return null;
		}

		const { titleTid, questionTid } = this.getTids();

		return (
			<ConfirmContainer
				title={titleTid}
				question={questionTid}
				isOpen={true}
				onConfirm={this.deleteVoting}
				onDecline={this.closeModal}
				isLoading={isLoading}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		meetingId: store.meetings.getIn(["meeting", "id"]),
		isOpen:
			store.modals.getIn(["activeModal", "name"]) ===
			MEETINGS_VOTING_DELETE_CONFIRM_MODAL,
		votingToBeDeleted: store.modals.getIn(
			["activeModal", "options", "votingToBeDeleted"],
			Map(),
		),
	};
};

const mapActionsToProps = {
	deleteVoting,
	closeModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ConfirmDeleteVotingContainer);
