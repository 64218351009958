import React, { PureComponent } from "react";
import Panel from "../panel/panel";
import Text from "../text/text";

import { StyledListHeaderTemplate } from "../list/list-header.template";

import styled, { css } from "styled-components";
import { func, string, object, node } from "prop-types";
import { list } from "react-immutable-proptypes";

const StyledListWrapper = styled.div`
	display: flex;
	flex: 1;
	margin: ${(props) => props.theme.spacing[4]};
	flex-direction: column;

	${(props) =>
		props.bottomComponent &&
		css`
			margin-bottom: 0;
		`}
`;

const TemplateTickWidthCss = css`
	width: 50px;
`;

const TemplateNameWidthCss = css`
	flex: 1;
`;

const TemplateDateWidthCss = css`
	margin-left: ${(props) => props.theme.spacing[3]};
	margin-right: ${(props) => props.theme.spacing[3]};
	width: 150px;
`;

// Items
const StyledTick = styled.div`
	${TemplateTickWidthCss};
`;

const StyledName = styled.div`
	${TemplateNameWidthCss}
`;

const StyledDate = styled.div`
	${TemplateDateWidthCss}
	text-align: right;
`;

const StyledHeader = styled.div`
	${StyledListHeaderTemplate}
	border: none;
	padding-bottom: ${(props) => props.theme.spacing[3]};
`;

export const ListPanelHeader = ({ columnTid }) => (
	<StyledHeader>
		<StyledTick />
		<StyledName />
		<StyledDate>
			<Text tid={columnTid} />
		</StyledDate>
	</StyledHeader>
);

export default class ListPanel extends PureComponent {
	static propTypes = {
		title: string,
		columnTid: string,
		items: list,
		renderItemComponent: func,
		bottomComponent: node,
		panelProps: object,
	};

	static defaultProps = {
		panelProps: {},
	};

	render = () => {
		const {
			title,
			items,
			renderItemComponent,
			panelProps,
			columnTid,
			bottomComponent,
		} = this.props;

		return (
			<Panel title={title} {...panelProps}>
				<StyledListWrapper bottomComponent={bottomComponent}>
					<ListPanelHeader columnTid={columnTid} />
					{items && renderItemComponent && items.map(renderItemComponent)}
				</StyledListWrapper>
				{bottomComponent && bottomComponent}
			</Panel>
		);
	};
}
