import { fromJS } from "immutable";

import {
	SHARES_USER_LIST,
	SHARES_USER_CREATE,
	SHARES_USER_UPDATE,
	SHARES_USER_REMOVE,
	SHARES_FETCH,
	SHARES_SAVE,
	SHARES_COMPANY_SHAREHOLDER_LIST,
	SHARES_SELECT_SHAREHOLDER,
	SHARES_SAVE_SHAREHOLDER,
	SHARES_UPDATE_SHAREHOLDER,
	SHARES_REMOVE_SHAREHOLDER,
	SHARES_DETERMINE_READ_ONLY,
	COMPANY_RESET_REDUCER,
} from "../actions/types";

const INITIAL_STATE = fromJS({
	userShares: [],
	companyShareholders: [],
	selectedShareholder: null,
	shareCapital: [],
	readOnly: null,
	sortPdfObj: null,
});

/** Export the reducer */
export default function (state = INITIAL_STATE, action) {
	let index;

	switch (action.type) {
		case SHARES_USER_LIST:
			return state.set("userShares", action.payload);

		case SHARES_USER_CREATE:
			return state.update("userShares", (shares) => {
				return shares.push(action.payload);
			});

		case SHARES_USER_UPDATE:
			index = state.get("userShares").findIndex((share) => {
				return share.get("id") === action.payload.get("id");
			});
			return state.setIn(["userShares", index], action.payload);

		case SHARES_USER_REMOVE:
			index = state.get("userShares").findIndex((share) => {
				return share.get("id") === action.payload.get("id");
			});
			return state.deleteIn(["userShares", index]);

		case SHARES_FETCH:
			return state.set("shareCapital", action.payload);

		case SHARES_SAVE:
			return state.set("shareCapital", action.payload);

		case SHARES_SAVE_SHAREHOLDER:
			return state
				.updateIn(["companyShareholders"], (companyShareholders) => {
					return companyShareholders.push(action.payload);
				})
				.set("selectedShareholder", action.payload);

		case SHARES_UPDATE_SHAREHOLDER:
			index = state.get("companyShareholders").findIndex((share) => {
				return share.get("id") === action.payload.get("id");
			});
			return state
				.setIn(["companyShareholders", index], action.payload)
				.set("selectedShareholder", action.payload);

		case SHARES_REMOVE_SHAREHOLDER:
			index = state.get("companyShareholders").findIndex((share) => {
				return share.get("id") === action.payload;
			});
			return state.deleteIn(["companyShareholders", index]);

		case SHARES_SELECT_SHAREHOLDER:
			index = state.get("companyShareholders").findIndex((share) => {
				return share.get("id") === action.payload;
			});
			return state.set(
				"selectedShareholder",
				state.getIn(["companyShareholders", index]),
			);

		case SHARES_COMPANY_SHAREHOLDER_LIST:
			return state.set("companyShareholders", action.payload);

		case SHARES_DETERMINE_READ_ONLY:
			return state.set("readOnly", action.payload);

		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE;
		}
	}

	return state;
}
