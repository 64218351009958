import { string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function ReadMoreModalContent({ contentFile }) {
	const [content, setContent] = useState("");

	const language = useSelector((state) => state.i18n.language);

	useEffect(() => {
		(async () => {
			const textModule = await import(
				`../../i18n/read-more/${language.substring(0, 2)}/${contentFile}`
			);
			setContent(textModule.default);
		})();
	}, [language, contentFile]);

	return <div dangerouslySetInnerHTML={{ __html: content }} />;
}

ReadMoreModalContent.propTypes = {
	contentFile: string.isRequired,
};

export { ReadMoreModalContent };
