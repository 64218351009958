import React, { PureComponent } from "react";
import immutablePropTypes from "react-immutable-proptypes";
import { string, oneOfType, bool, func, array, object } from "prop-types";
import styled from "styled-components";
import { Margin } from "styled-components-spacing";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FilePreview from "../file-preview/file-preview";
import Dropzone from "../dropzone/dropzone";
import DocumentsHelper from "../../../components/helpers/documents.helper";
import {
	ATTACHMENTS_DISPLAY_STATUS_SHOWN,
	ATTACHMENTS_DISPLAY_STATUS_DURING_AFTER,
} from "/shared/constants";

const StyledWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

export default class LinkedFiles extends PureComponent {
	static propTypes = {
		fieldName: string,
		multiple: bool,
		accept: oneOfType([string, array]),
		readOnly: bool,
		onDrop: func.isRequired,
		documents: immutablePropTypes.list,
		onOpenFile: func,
		onDownloadFile: func,
		transparent: bool,
		onDragEnd: func,
		isDragDisabled: bool,
		isUploading: bool,
		dropdownRenderer: func,
		dropdownDeleteOnlyRenderer: func,
		preControlsRenderer: func,
		nakedStyle: bool,
		visibilityIcons: object, // {false: 'faEyeSlash', true: 'faEye'}, Default: undefined
		visibilityIconTooltipTids: object,
		documentToolTipTid: string,
		dropzoneTid1: string,
		dropzoneTid2: string,
		onSetDropzoneRef: func,
		invisible: bool,
		objId: string,
	};

	static defaultProps = {
		multiple: false,
		readOnly: false,
		isUploading: false,
	};

	renderFilePreview = (document, index) => {
		const {
			onOpenFile,
			onDownloadFile,
			transparent,
			readOnly,
			isDragDisabled,
			dropdownRenderer,
			dropdownDeleteOnlyRenderer,
			nakedStyle,
			preControlsRenderer,
			visibilityIcons,
			visibilityIconTooltipTids,
			documentToolTipTid,
			objId,
		} = this.props;
		const showViewer = DocumentsHelper.isViewableInBrowser(
			document.get("file"),
		);
		let fileIcon = "faFile";
		let fileIconTooltipTid;
		let notifyBadge;
		let notifyBadgeColor;

		const displayStatus = DocumentsHelper.findDisplayStatus(
			document.get("links"),
			objId,
		);

		if (visibilityIcons && displayStatus) {
			fileIcon = visibilityIcons[displayStatus];
			fileIconTooltipTid = visibilityIconTooltipTids[displayStatus];
			notifyBadge = true;
			notifyBadgeColor =
				displayStatus === ATTACHMENTS_DISPLAY_STATUS_SHOWN ||
				displayStatus === ATTACHMENTS_DISPLAY_STATUS_DURING_AFTER
					? "green"
					: "persianRed";
		}

		return (
			<Draggable
				draggableId={document.get("id")}
				index={index}
				isDragDisabled={readOnly || isDragDisabled}
				key={index}
			>
				{(provided) => (
					<div ref={provided.innerRef} {...provided.draggableProps}>
						<Margin bottom={2} key={index}>
							<FilePreview
								document={document}
								readOnly={readOnly}
								onOpenFile={showViewer && onOpenFile}
								onDownloadFile={onDownloadFile}
								transparent={transparent}
								dragHandleProps={provided.dragHandleProps}
								isDragDisabled={readOnly}
								dropdownRenderer={dropdownRenderer}
								dropdownDeleteOnlyRenderer={dropdownDeleteOnlyRenderer}
								preControlsRenderer={preControlsRenderer}
								nakedStyle={nakedStyle}
								fileIcon={fileIcon}
								fileIconTooltipTid={fileIconTooltipTid}
								documentToolTipTid={documentToolTipTid}
								notifyBadge={notifyBadge}
								notifyBadgeColor={notifyBadgeColor}
							/>
						</Margin>
					</div>
				)}
			</Draggable>
		);
	};

	renderDropzone = () => {
		const {
			fieldName,
			multiple,
			transparent,
			nakedStyle,
			accept,
			readOnly,
			onDrop,
			isUploading,
			dropzoneIcon,
			dropzoneTid1,
			dropzoneTid2,
			onSetDropzoneRef,
			invisible,
		} = this.props;

		return (
			<Dropzone
				size={invisible ? "full-screen" : "small"}
				name={fieldName}
				multiple={multiple}
				transparent={transparent || nakedStyle ? "true" : "false"} // Errors when passing BOOL to custom component
				accept={accept}
				readOnly={readOnly}
				onDrop={onDrop}
				isUploading={isUploading}
				dropzoneIcon={dropzoneIcon}
				dropzoneTid1={dropzoneTid1}
				dropzoneTid2={dropzoneTid2}
				dropzoneRef={onSetDropzoneRef}
				hidden={invisible}
			/>
		);
	};

	render = () => {
		const { documents, onDragEnd, invisible, showDropzone } = this.props;
		const marginBottom = documents.size === 0 ? 2 : 3;

		if (invisible) {
			return this.renderDropzone();
		}

		return (
			<StyledWrapper>
				{showDropzone && (
					<Margin bottom={marginBottom}>{this.renderDropzone()}</Margin>
				)}
				{documents && (
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="attachments">
							{(provided) => (
								<div ref={provided.innerRef}>
									{documents.map(this.renderFilePreview)}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				)}
			</StyledWrapper>
		);
	};
}
