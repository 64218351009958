import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import CollectionsNavItem from "../../dumb-components/navbar/collections/collections";
import CollectionsModalContainer from "../collections/collections-modal.container";
import CollectionsShareModalContainer from "../collections/share/share-modal.container";
import CollectionsMergePdfModalContainer from "../collections/merge-pdf/merge-pdf-modal.container";
import CollectionsManageSharedModalContainer from "../collections/manage-shared/manage-shared-modal.container";
import { openModal } from "../../actions/modals.actions";
import { fetchCollections } from "../../actions/collections.actions";
import DropdownMenuContainer from "../shared/dropdown-menu.container";
import DropdownIconItem from "../../dumb-components/shared/dropdown-item/dropdown-icon-item";

import {
	COLLECTIONS_MANAGE_MODAL,
	COLLECTIONS_MANAGE_SHARED_MODAL,
	COLLECTIONS_SHARE_MODAL,
	COLLECTIONS_MERGE_PDF_MODAL,
} from "../../constants/modals";
import useSubscriptionHelper from "@/hooks/useSubscriptionHelper";

const CollectionsNavItemContainer = ({
	companyId,
	collectionsModalIsOpen,
	companySelected,
	rootCollection,
	openModal,
	fetchCollections,
	shareModalIsOpen,
	mergePdfModalIsOpen,
	managePostModalIsOpen,
}) => {
	const dropdownRef = useRef(null);

	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;

	useEffect(() => {
		if (companyId) {
			fetchCollections();
		}
	}, [companyId]);

	useEffect(() => {
		if (collectionsModalIsOpen) {
			fetchCollections();
		}
	}, [collectionsModalIsOpen]);

	const setDropdownRef = (ref) => {
		dropdownRef.current = ref;
	};

	const closeDropdown = () => {
		dropdownRef.current?.onToggleMenu?.();
	};

	const openCollectionsModal = () => {
		closeDropdown();
		openModal(COLLECTIONS_MANAGE_MODAL);
	};

	const openManagePastCollectionsModal = () => {
		openModal(COLLECTIONS_MANAGE_SHARED_MODAL);
		closeDropdown();
	};

	if (!companySelected || !subscriptionHelper?.collectionsEnabled) {
		return null;
	}

	const hasDocumentsInRoot =
		rootCollection && rootCollection.get("documents").size > 0;

	return (
		<>
			<DropdownMenuContainer
				ref={setDropdownRef}
				renderRaw={
					<CollectionsNavItem hasDocumentsInRoot={hasDocumentsInRoot} />
				}
			>
				<DropdownIconItem
					icon="faBooksMedical"
					tid="collections.nav.dropdown_item.open_collections"
					onClick={openCollectionsModal}
				/>
				<DropdownIconItem
					icon="faBooks"
					tid="collections.nav.dropdown_item.manage_completed_actions"
					onClick={openManagePastCollectionsModal}
				/>
			</DropdownMenuContainer>

			{collectionsModalIsOpen && <CollectionsModalContainer />}
			{shareModalIsOpen && <CollectionsShareModalContainer />}
			{mergePdfModalIsOpen && <CollectionsMergePdfModalContainer />}
			{managePostModalIsOpen && <CollectionsManageSharedModalContainer />}
		</>
	);
};

const mapStoreToProps = (store) => {
	const companyId = store.company?.company?.id;
	const activeModalName = store.modals.getIn(["activeModal", "name"]);

	return {
		rootCollection: store.collections
			.get("collections")
			.find((c) => c.get("isRoot")),
		collectionsModalIsOpen: activeModalName === COLLECTIONS_MANAGE_MODAL,
		shareModalIsOpen: activeModalName === COLLECTIONS_SHARE_MODAL,
		mergePdfModalIsOpen: activeModalName === COLLECTIONS_MERGE_PDF_MODAL,
		managePostModalIsOpen: activeModalName === COLLECTIONS_MANAGE_SHARED_MODAL,
		companySelected: Boolean(companyId),
		companyId,
	};
};

const mapActionsToProps = {
	openModal,
	fetchCollections,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(CollectionsNavItemContainer);
