export const setGlobalRef = (ref, constantRefName) => {
	if (!ref || !constantRefName) {
		return;
	}

	if (!window.globalRefs) {
		window.globalRefs = {};
	}

	window.globalRefs[constantRefName] = ref;
};

export const getGlobalRef = (constantRefName) => {
	if (!window.globalRefs || !constantRefName) {
		return;
	}

	return window.globalRefs[constantRefName];
};
