export default {
	formValidator: {
		place: "left",
		event: "none",
		type: "error",
		delayShow: "instant",
	},
	hasNoPermissions: {
		tid: "tooltip.default.has_no_permissions",
		delayShow: "instant",
	},
	hasNoPermissionsSharedCompany: {
		tid: "tooltip.default.has_no_permissions_shared_company",
		delayShow: "instant",
	},
	noActionRequired: {
		tid: "tooltip.default.no_action_required",
		delayShow: "instant",
	},
	noAddTaskToMeeting: {
		tid: "tooltip.default.no_add_task_to_meeting",
		delayShow: "instant",
	},
	btnMoreActions: {
		tid: "tooltip.default.action.more_actions",
	},
	btnExpandSection: {
		tid: "tooltip.default.btn.expand_section",
	},
	btnCollapseSection: {
		tid: "tooltip.default.btn.collapse_section",
	},
	isComingSoon: {
		tid: "tooltip.default.is_upcoming_feature",
		delayShow: "instant",
	},
	noModeratorAdded: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.no_moderator.tooltip",
		delayShow: "instant",
	},
	noProxyAdded: {
		tid: "meetings.standard_meeting.attendees_panel.toolbar.company_has_no_proxy.tooltip",
		delayShow: "instant",
	},
};
